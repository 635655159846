import * as React from 'react';
import * as classNames from "classnames";
import './CategoriesPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactSelectClass from "react-select";
import ReactTable, { Column, RowInfo } from 'react-table';
import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";
import * as CategoryActions from '../../../actions/categories';
import * as PagingActions from '../../../actions/pages/pagingPage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput, FormFilterInput } from '../../../components/form';
import { ItemSettingsLayout } from '../../index';

interface ICategoriesPageProps {

    location: Location;
    history: History;

    // From state
    formFilter: IFormFilter;
    categories: Dtos.Category[];
    //activeCategories: Dtos.Category[];
    loadingCategories: boolean;
    loadCategoriesSuccess: boolean;
    loadCategoriesFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    formRoles?: Dtos.RoleType[];
}

interface ICategoriesPageActions {
    clearCategories: CategoryActions.ICategoryClearActionCreator;
    loadCategories: CategoryActions.ICategoryLoadActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    filter: CategoryActions.ICategoryFilterActionCreator;
}

type CategoriesPageProps = ICategoriesPageProps & ICategoriesPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}

class CategoriesPage extends React.PureComponent<CategoriesPageProps, any> {

    constructor(props: CategoriesPageProps) {
        super(props);

        this.clearCategories = this.clearCategories.bind(this);
    }

    componentDidMount() {
        const {
            loadCategories
        } = this.props;

        loadCategories();
    }

    componentWillReceiveProps(nextProps: CategoriesPageProps) {
    }

    componentDidUpdate(prevProps: CategoriesPageProps, prevState) {
        const {
            navigateReplace
        } = this.props
    }

    componentWillUpdate(nextProps: CategoriesPageProps) {
    }

    componentWillUnmount() {
        this.clearCategories();
    }

    clearCategories() {
        this.props.clearCategories();
    }

    showCategories(): boolean {
        const {
            loadingCategories,
            loadCategoriesSuccess
        } = this.props

        if (!loadingCategories && loadCategoriesSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingCategories
        } = this.props;

        return <ItemSettingsLayout
            permission={ Dtos.Permission.CategoriesView}
            loading={loadingCategories}>
            {this.renderContent()}
        </ItemSettingsLayout>;
    }

    renderContent() {
        const {
            formRoles,
            permissions
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-6 mb-2"}>
                    <h2>Categories</h2>
                </div>
                <div className="col-6" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e) } />                    
                    {AuthenticationHelper.hasPermission(permissions, Dtos.Permission.CategoriesCreate) && (
                        <Link className={"btn btn-primary"} url={"/settings/item/itemcategories/create"}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.showCategories() ?
                    "Loading Categories" :
                    null
            }
            {
                this.showCategories() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {

        const {
            categories
        } = this.props


        if (!categories || categories.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no categories to view.</span>
            </div>
        }

        const showPagination = categories.length > DEFAULT_PAGE_SIZE;
        
        return <div className="table-wrapper mb-3">
            <ReactTable
                data={categories}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : categories.length}
                pageSize={showPagination ? undefined : categories.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no categories to display"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {

                            const url: string = "/settings/item/itemcategories/" + rowInfo.original.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.LOOKUP} fixedWidth />
                    },
                    {
                        id: "value",
                        Header: 'Name',
                        accessor: 'value',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    }
                ]}
            />
        </div>

    }

}

//const filterCategoriesByActive = (categorys: Dtos.Category[], active: boolean): Dtos.Category[] => {

//    if (categorys) {
//        return categorys.filter(p => p.active == active);
//    }

//    return [];
//}

//const allCategories = (categorys: Dtos.Category[]): Dtos.Category[] => {

//    if (categorys) {
//        return categorys;
//    }

//    return [];
//}

const mapStateToProps = (state: ITmdState, ownProps: CategoriesPageProps): ICategoriesPageProps => {

    //const categories: Dtos.Category[] | undefined =
    //    state.categories.formData instanceof Array ?
    //        allCategories(state.categories.formData) :
    //        undefined

    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        history: ownProps.history,
        location: state.routing.location,

        //categories: categories!,
        formFilter: state.categories.formFilter,
        categories: state.categories.formFilteredData, //filterCategoriesByActive(categories!, true),

        loadingCategories: state.categories.loadState && state.categories.loadState.status == RequestState.Pending,
        loadCategoriesSuccess: state.categories.loadState && state.categories.loadState.status == RequestState.Success,
        loadCategoriesFailure: state.categories.loadState && state.categories.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles
    };
};

const mapDispatchToProps = (dispatch): ICategoriesPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadCategories: bindActionCreators(CategoryActions.LoadCategory, dispatch),
        clearCategories: bindActionCreators(CategoryActions.Clear, dispatch),

        filter: bindActionCreators(CategoryActions.Filter, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(CategoriesPage);
