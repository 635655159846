import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_TRIALITEMUPDATE_LOAD_BY_ID,

    ACTION_TRIALITEMUPDATE_SAVE,

    ITrialItemUpdateLoadByIdAction,
    ITrialItemUpdateLoadFailureAction,
    ITrialItemUpdateLoadSuccessAction,

    ITrialItemUpdateSaveAction,
    ITrialItemUpdateSaveSuccessAction,
    ITrialItemUpdateSaveFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

} from "../actions/trialItemUpdate";
import { trialItemUpdateApi } from "../services/trialItemUpdate";


export const
    getTrialItemUpdateByIdEpic = (action$): Observable<ITrialItemUpdateLoadSuccessAction | ITrialItemUpdateLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALITEMUPDATE_LOAD_BY_ID)
            .mergeMap((action: ITrialItemUpdateLoadByIdAction) => {
                return trialItemUpdateApi
                    .getTrialItemUpdateById(action.id, action.trialItemId)
                    .map(response =>
                        LoadSuccess(response.trialItemUpdate, response.formState, response.formProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setTrialItemUpdateEpic = (action$): Observable<ITrialItemUpdateSaveSuccessAction | ITrialItemUpdateSaveFailureAction> => {
        return action$
            .ofType(ACTION_TRIALITEMUPDATE_SAVE)
            .mergeMap((action: ITrialItemUpdateSaveAction) => {
                return trialItemUpdateApi
                    .setTrialItemUpdate(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.trialItemUpdate, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    };

