import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadTrialCoordCentre: ITrialCoordCentreLoadActionCreator = () => {
        return {
            type: ACTION_TRIALCOORDCENTRES_LOAD
        }
    },
    LoadTrialCoordCentreById: ITrialCoordCentreLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TRIALCOORDCENTRES_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ITrialCoordCentreLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALCOORDCENTRES_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrialCoordCentreLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALCOORDCENTRES_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTrialCoordCentre: ITrialCoordCentreSaveActionCreator = (formData) => {
        return {
            type: ACTION_TRIALCOORDCENTRES_SAVE,
            formData
        }
    },
    SaveSuccess: ITrialCoordCentreSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRIALCOORDCENTRES_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrialCoordCentreSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALCOORDCENTRES_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTrialCoordCentre: ITrialCoordCentreCreateActionCreator = () => {

        return {
            type: ACTION_TRIALCOORDCENTRES_CREATE
        }
    },
    CreateSuccess: ITrialCoordCentreCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_TRIALCOORDCENTRES_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: ITrialCoordCentreCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALCOORDCENTRES_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: ITrialCoordCentreClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRIALCOORDCENTRES_CLEAR,
            propertiesToClear
        }
    },
    Filter: ITrialCoordCentreFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRIALCOORDCENTRES_FILTER,
            formFilter
        }
    };



export const
    ACTION_TRIALCOORDCENTRES_LOAD = "ACTION_TRIALCOORDCENTRES_LOAD",
    ACTION_TRIALCOORDCENTRES_LOAD_BY_ID = "ACTION_TRIALCOORDCENTRES_LOAD_BY_ID",
    ACTION_TRIALCOORDCENTRES_LOAD_SUCCESS = "ACTION_TRIALCOORDCENTRES_LOAD_SUCCESS",
    ACTION_TRIALCOORDCENTRES_LOAD_FAILURE = "ACTION_TRIALCOORDCENTRES_LOAD_FAILURE",

    ACTION_TRIALCOORDCENTRES_SAVE = "ACTION_TRIALCOORDCENTRES_SAVE",
    ACTION_TRIALCOORDCENTRES_SAVE_SUCCESS = "ACTION_TRIALCOORDCENTRES_SAVE_SUCCESS",
    ACTION_TRIALCOORDCENTRES_SAVE_FAILURE = "ACTION_TRIALCOORDCENTRES_SAVE_FAILURE",

    ACTION_TRIALCOORDCENTRES_CREATE = "ACTION_TRIALCOORDCENTRES_CREATE",
    ACTION_TRIALCOORDCENTRES_CREATE_SUCCESS = "ACTION_TRIALCOORDCENTRES_CREATE_SUCCESS",
    ACTION_TRIALCOORDCENTRES_CREATE_FAILURE = "ACTION_TRIALCOORDCENTRES_CREATE_FAILURE",

    ACTION_TRIALCOORDCENTRES_CLEAR = "ACTION_TRIALCOORDCENTRES_CLEAR",

    ACTION_TRIALCOORDCENTRES_FILTER = "ACTION_TRIALCOORDCENTRES_FILTER";

export interface ITrialCoordCentreLoadAction {
    type: "ACTION_TRIALCOORDCENTRES_LOAD";
}

export interface ITrialCoordCentreLoadActionCreator {
    (): ITrialCoordCentreLoadAction;
}

export interface ITrialCoordCentreLoadSuccessAction {
    type: "ACTION_TRIALCOORDCENTRES_LOAD_SUCCESS";
    formData: Dtos.TrialCoordCentre | Dtos.TrialCoordCentre[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialCoordCentreLoadSuccessActionCreator {
    (
        formData: Dtos.TrialCoordCentre | Dtos.TrialCoordCentre[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialCoordCentreLoadSuccessAction;
}

export interface ITrialCoordCentreLoadFailureAction {
    type: "ACTION_TRIALCOORDCENTRES_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialCoordCentreLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialCoordCentreLoadFailureAction;
}

export interface ITrialCoordCentreLoadByIdAction {
    type: "ACTION_TRIALCOORDCENTRES_LOAD_BY_ID";
    id: number;
}

export interface ITrialCoordCentreLoadByIdActionCreator {
    (id: number): ITrialCoordCentreLoadByIdAction;
}


export interface ITrialCoordCentreSaveAction {
    type: "ACTION_TRIALCOORDCENTRES_SAVE";
    formData: Dtos.TrialCoordCentre;

}

export interface ITrialCoordCentreSaveActionCreator {
    (
        formData: Dtos.TrialCoordCentre
    ): ITrialCoordCentreSaveAction;
}

export interface ITrialCoordCentreSaveSuccessAction {
    type: "ACTION_TRIALCOORDCENTRES_SAVE_SUCCESS";
    formData: Dtos.TrialCoordCentre;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialCoordCentreSaveSuccessActionCreator {
    (
        formData: Dtos.TrialCoordCentre,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrialCoordCentreSaveSuccessAction;
}

export interface ITrialCoordCentreSaveFailureAction {
    type: "ACTION_TRIALCOORDCENTRES_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialCoordCentreSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialCoordCentreSaveFailureAction;
}

export interface ITrialCoordCentreCreateAction {
    type: "ACTION_TRIALCOORDCENTRES_CREATE"
}

export interface ITrialCoordCentreCreateActionCreator {
    (): ITrialCoordCentreCreateAction
}

export interface ITrialCoordCentreCreateSuccessAction {
    type: "ACTION_TRIALCOORDCENTRES_CREATE_SUCCESS";
    formData: Dtos.TrialCoordCentre;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface ITrialCoordCentreCreateSuccessActionCreator {
    (
        formData: Dtos.TrialCoordCentre,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): ITrialCoordCentreCreateSuccessAction;
}

export interface ITrialCoordCentreCreateFailureAction {
    type: "ACTION_TRIALCOORDCENTRES_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialCoordCentreCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialCoordCentreCreateFailureAction;
}

export interface ITrialCoordCentreClearAction {
    type: "ACTION_TRIALCOORDCENTRES_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrialCoordCentreClearActionCreator {
    (propertiesToClear?: string[]): ITrialCoordCentreClearAction;
}

export interface ITrialCoordCentreFilterActionCreator {
    (formFilter: IFormFilter): ITrialCoordCentreFilterAction;
}

export interface ITrialCoordCentreFilterAction {
    type: "ACTION_TRIALCOORDCENTRES_FILTER";
    formFilter: IFormFilter;
}

export type TrialCoordCentreAction =
    ITrialCoordCentreLoadAction |
    ITrialCoordCentreLoadByIdAction |
    ITrialCoordCentreLoadFailureAction |
    ITrialCoordCentreLoadSuccessAction |
    ITrialCoordCentreSaveAction |
    ITrialCoordCentreSaveSuccessAction |
    ITrialCoordCentreSaveFailureAction |
    ITrialCoordCentreCreateAction |
    ITrialCoordCentreCreateSuccessAction |
    ITrialCoordCentreCreateFailureAction |
    ITrialCoordCentreClearAction |
    ITrialCoordCentreFilterAction;
