import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const standardEmailTemplateApi = {
    getStandardEmailTemplates: (): Observable<Dtos.GetStandardEmailTemplatesResponse> => {
        //Create the request.
        let request: Dtos.GetStandardEmailTemplates = new Dtos.GetStandardEmailTemplates();

        //Send request.
        const response: Promise<Dtos.GetStandardEmailTemplatesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getStandardEmailTemplateById: (id: number): Observable<Dtos.GetStandardEmailTemplateByIdResponse> => {
        //Create the request.
        let request: Dtos.GetStandardEmailTemplateById = new Dtos.GetStandardEmailTemplateById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetStandardEmailTemplateByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setStandardEmailTemplate: (standardEmailTemplate: Dtos.StandardEmailTemplate): Observable<Dtos.SetStandardEmailTemplateResponse> => {

        let request: Dtos.SetStandardEmailTemplate = new Dtos.SetStandardEmailTemplate();

        request.standardEmailTemplate = standardEmailTemplate;

        // send request
        const response: Promise<Dtos.SetStandardEmailTemplateResponse> = client.post(request);

        return Observable.from(response);

    },
    createStandardEmailTemplate: (): Observable<Dtos.CreateStandardEmailTemplateResponse> => {
        //Create the request.
        let request: Dtos.CreateStandardEmailTemplate = new Dtos.CreateStandardEmailTemplate();

        //Send request.
        const response: Promise<Dtos.CreateStandardEmailTemplateResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}