import * as React from 'react';
import './QAEmailTemplatesPage.scss';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { RowInfo } from 'react-table';
import * as StandardEmailTemplateActions from '../../../actions/standardEmailTemplates';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { FormFilterInput } from '../../../components/form';
import { QALookupsLayout } from '../../index';

interface IQAEmailTemplatesPageProps {

    location: Location;
    history: History;

    // From state
    formFilter: IFormFilter;
    standardEmailTemplates: Dtos.StandardEmailTemplate[];
    loadingStandardEmailTemplates: boolean;
    loadStandardEmailTemplatesSuccess: boolean;
    loadStandardEmailTemplatesFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    formRoles?: Dtos.RoleType[];
}

interface IQAEmailTemplatesPageActions {
    clearStandardEmailTemplates: StandardEmailTemplateActions.IStandardEmailTemplateClearActionCreator;
    loadStandardEmailTemplates: StandardEmailTemplateActions.IStandardEmailTemplateLoadActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    filter: StandardEmailTemplateActions.IStandardEmailTemplateFilterActionCreator;
}

type QAEmailTemplatesPageProps = IQAEmailTemplatesPageProps & IQAEmailTemplatesPageActions;

class QAEmailTemplatesPage extends React.PureComponent<QAEmailTemplatesPageProps, any> {

    constructor(props: QAEmailTemplatesPageProps) {
        super(props);

    }

    componentDidMount() {
        const {
            loadStandardEmailTemplates
        } = this.props;

        loadStandardEmailTemplates();
    }

    componentWillUnmount() {
        this.props.clearStandardEmailTemplates();
    }

    showStandardEmailTemplates(): boolean {
        const {
            loadingStandardEmailTemplates,
            loadStandardEmailTemplatesSuccess
        } = this.props

        if (!loadingStandardEmailTemplates && loadStandardEmailTemplatesSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingStandardEmailTemplates
        } = this.props;

        return <QALookupsLayout loading={loadingStandardEmailTemplates} permission={Dtos.Permission.QAEmailTemplatesView}>
            {this.renderContent()}
        </QALookupsLayout>;
    }

    renderContent() {
        const {
            permissions
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-6 mb-2"}>
                    <h2>QA Email Templates</h2>
                </div>
                <div className="col-6" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e) } />
                    
                    {AuthenticationHelper.hasPermission(permissions, Dtos.Permission.QAEmailTemplatesCreate) && (
                        <Link className={"btn btn-primary"} url={"/settings/qa/qaEmailTemplates/create"}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.showStandardEmailTemplates() ?
                    "Loading Email Templates" :
                    null
            }
            {
                this.showStandardEmailTemplates() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {

        const {
            standardEmailTemplates
        } = this.props

        if (!standardEmailTemplates || standardEmailTemplates.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no records to view.</span>
            </div>
        }

        const showPagination = standardEmailTemplates.length > DEFAULT_PAGE_SIZE;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={standardEmailTemplates}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : standardEmailTemplates.length}
                pageSize={showPagination ? undefined : standardEmailTemplates.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no taskType to display"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {

                            const url: string = "/settings/qa/qaEmailTemplates/" + rowInfo.original.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.LOOKUP} fixedWidth />
                    },
                    {
                        id: "value",
                        Header: 'Name',
                        accessor: 'value',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    },
                    {
                        id: "subject",
                        Header: 'Subject',
                        accessor: 'subject',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    },
                    {
                        id: "body",
                        Header: 'Body',
                        accessor: 'body',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    }
                ]}
            />
        </div>

    }

}

const mapStateToProps = (state: ITmdState, ownProps: QAEmailTemplatesPageProps): IQAEmailTemplatesPageProps => {

    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];
    let allowedTypes: number[] = [1];
    let standardEmailTemplates: Dtos.StandardEmailTemplate[] | undefined = (state.standardEmailTemplates.formData instanceof Array) ?
        state.standardEmailTemplates.formData.filter(f => allowedTypes.includes(f.standardEmailTypeId))
        : undefined;

    return {

        history: ownProps.history,
        location: state.routing.location,

        formFilter: state.taskTypes.formFilter,
        standardEmailTemplates: standardEmailTemplates!, 

        loadingStandardEmailTemplates: state.standardEmailTemplates.loadState && state.standardEmailTemplates.loadState.status == RequestState.Pending,
        loadStandardEmailTemplatesSuccess: state.standardEmailTemplates.loadState && state.standardEmailTemplates.loadState.status == RequestState.Success,
        loadStandardEmailTemplatesFailure: state.standardEmailTemplates.loadState && state.standardEmailTemplates.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles
    };
};

const mapDispatchToProps = (dispatch): IQAEmailTemplatesPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadStandardEmailTemplates: bindActionCreators(StandardEmailTemplateActions.LoadStandardEmailTemplate, dispatch),
        clearStandardEmailTemplates: bindActionCreators(StandardEmailTemplateActions.Clear, dispatch),

        filter: bindActionCreators(StandardEmailTemplateActions.Filter, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(QAEmailTemplatesPage);
