import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadCategory: ICategoryLoadActionCreator = () => {
        return {
            type: ACTION_CATEGORIES_LOAD
        }
    },
    LoadCategoryById: ICategoryLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_CATEGORIES_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ICategoryLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_CATEGORIES_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ICategoryLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_CATEGORIES_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveCategory: ICategorySaveActionCreator = (formData) => {
        return {
            type: ACTION_CATEGORIES_SAVE,
            formData
        }
    },
    SaveSuccess: ICategorySaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_CATEGORIES_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ICategorySaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_CATEGORIES_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateCategory: ICategoryCreateActionCreator = () => {

        return {
            type: ACTION_CATEGORIES_CREATE
        }
    },
    CreateSuccess: ICategoryCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_CATEGORIES_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: ICategoryCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_CATEGORIES_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: ICategoryClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_CATEGORIES_CLEAR,
            propertiesToClear
        }
    },
    Filter: ICategoryFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_CATEGORIES_FILTER,
            formFilter
        }
    };



export const
    ACTION_CATEGORIES_LOAD = "ACTION_CATEGORIES_LOAD",
    ACTION_CATEGORIES_LOAD_BY_ID = "ACTION_CATEGORIES_LOAD_BY_ID",
    ACTION_CATEGORIES_LOAD_SUCCESS = "ACTION_CATEGORIES_LOAD_SUCCESS",
    ACTION_CATEGORIES_LOAD_FAILURE = "ACTION_CATEGORIES_LOAD_FAILURE",

    ACTION_CATEGORIES_SAVE = "ACTION_CATEGORIES_SAVE",
    ACTION_CATEGORIES_SAVE_SUCCESS = "ACTION_CATEGORIES_SAVE_SUCCESS",
    ACTION_CATEGORIES_SAVE_FAILURE = "ACTION_CATEGORIES_SAVE_FAILURE",

    ACTION_CATEGORIES_CREATE = "ACTION_CATEGORIES_CREATE",
    ACTION_CATEGORIES_CREATE_SUCCESS = "ACTION_CATEGORIES_CREATE_SUCCESS",
    ACTION_CATEGORIES_CREATE_FAILURE = "ACTION_CATEGORIES_CREATE_FAILURE",

    ACTION_CATEGORIES_CLEAR = "ACTION_CATEGORIES_CLEAR",

    ACTION_CATEGORIES_FILTER = "ACTION_CATEGORIES_FILTER";

export interface ICategoryLoadAction {
    type: "ACTION_CATEGORIES_LOAD";
}

export interface ICategoryLoadActionCreator {
    (): ICategoryLoadAction;
}

export interface ICategoryLoadSuccessAction {
    type: "ACTION_CATEGORIES_LOAD_SUCCESS";
    formData: Dtos.Category | Dtos.Category[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ICategoryLoadSuccessActionCreator {
    (
        formData: Dtos.Category | Dtos.Category[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ICategoryLoadSuccessAction;
}

export interface ICategoryLoadFailureAction {
    type: "ACTION_CATEGORIES_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ICategoryLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ICategoryLoadFailureAction;
}

export interface ICategoryLoadByIdAction {
    type: "ACTION_CATEGORIES_LOAD_BY_ID";
    id: number;
}

export interface ICategoryLoadByIdActionCreator {
    (id: number): ICategoryLoadByIdAction;
}


export interface ICategorySaveAction {
    type: "ACTION_CATEGORIES_SAVE";
    formData: Dtos.Category;

}

export interface ICategorySaveActionCreator {
    (
        formData: Dtos.Category
    ): ICategorySaveAction;
}

export interface ICategorySaveSuccessAction {
    type: "ACTION_CATEGORIES_SAVE_SUCCESS";
    formData: Dtos.Category;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ICategorySaveSuccessActionCreator {
    (
        formData: Dtos.Category,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ICategorySaveSuccessAction;
}

export interface ICategorySaveFailureAction {
    type: "ACTION_CATEGORIES_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ICategorySaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ICategorySaveFailureAction;
}

export interface ICategoryCreateAction {
    type: "ACTION_CATEGORIES_CREATE"
}

export interface ICategoryCreateActionCreator {
    (): ICategoryCreateAction
}

export interface ICategoryCreateSuccessAction {
    type: "ACTION_CATEGORIES_CREATE_SUCCESS";
    formData: Dtos.Category;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface ICategoryCreateSuccessActionCreator {
    (
        formData: Dtos.Category,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): ICategoryCreateSuccessAction;
}

export interface ICategoryCreateFailureAction {
    type: "ACTION_CATEGORIES_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ICategoryCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ICategoryCreateFailureAction;
}

export interface ICategoryClearAction {
    type: "ACTION_CATEGORIES_CLEAR";
    propertiesToClear?: string[];
}

export interface ICategoryClearActionCreator {
    (propertiesToClear?: string[]): ICategoryClearAction;
}

export interface ICategoryFilterActionCreator {
    (formFilter: IFormFilter): ICategoryFilterAction;
}

export interface ICategoryFilterAction {
    type: "ACTION_CATEGORIES_FILTER";
    formFilter: IFormFilter;
}

export type CategoryAction =
    ICategoryLoadAction |
    ICategoryLoadByIdAction |
    ICategoryLoadFailureAction |
    ICategoryLoadSuccessAction |
    ICategorySaveAction |
    ICategorySaveSuccessAction |
    ICategorySaveFailureAction |
    ICategoryCreateAction |
    ICategoryCreateSuccessAction |
    ICategoryCreateFailureAction |
    ICategoryClearAction |
    ICategoryFilterAction;
