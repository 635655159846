import * as React from 'react';
import './TrackerMilestonesPage.scss';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { RowInfo } from 'react-table';
import * as TrackerMilestoneActions from '../../../actions/trackerMilestones';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { FormFilterInput } from '../../../components/form';
import { QALookupsLayout } from '../../index';

interface ITrackerMilestonesPageProps {

    location: Location;
    history: History;

    // From state
    formFilter: IFormFilter;
    trackerMilestones: Dtos.TrackerMilestone[];
    loadingTrackerMilestones: boolean;
    loadTrackerMilestonesSuccess: boolean;
    loadTrackerMilestonesFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    formRoles?: Dtos.RoleType[];
}

interface ITrackerMilestonesPageActions {
    clearTrackerMilestones: TrackerMilestoneActions.ITrackerMilestoneClearActionCreator;
    loadTrackerMilestones: TrackerMilestoneActions.ITrackerMilestoneLoadActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    filter: TrackerMilestoneActions.ITrackerMilestoneFilterActionCreator;
}

type TrackerMilestonesPageProps = ITrackerMilestonesPageProps & ITrackerMilestonesPageActions;

class TrackerMilestonesPage extends React.PureComponent<TrackerMilestonesPageProps, any> {

    constructor(props: TrackerMilestonesPageProps) {
        super(props);

        this.clearTrackerMilestones = this.clearTrackerMilestones.bind(this);
    }

    componentDidMount() {
        const {
            loadTrackerMilestones
        } = this.props;

        loadTrackerMilestones();
    }

    componentWillReceiveProps(nextProps: TrackerMilestonesPageProps) {
    }

    componentDidUpdate(prevProps: TrackerMilestonesPageProps, prevState) {
        const {
            navigateReplace
        } = this.props
    }

    componentWillUpdate(nextProps: TrackerMilestonesPageProps) {
    }

    componentWillUnmount() {
        this.clearTrackerMilestones();
    }

    clearTrackerMilestones() {
        this.props.clearTrackerMilestones();
    }

    showTrackerMilestones(): boolean {
        const {
            loadingTrackerMilestones,
            loadTrackerMilestonesSuccess
        } = this.props

        if (!loadingTrackerMilestones && loadTrackerMilestonesSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrackerMilestones
        } = this.props;

        return <QALookupsLayout loading={loadingTrackerMilestones} permission={Dtos.Permission.TaskTypesView}>
            {this.renderContent()}
        </QALookupsLayout>;
    }

    renderContent() {
        const {
            permissions
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-6 mb-2"}>
                    <h2>Tracker Milestones</h2>
                </div>
                <div className="col-6" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e) } />
                    
                    {AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TaskTypesCreate) && (
                        <Link className={"btn btn-primary"} url={"/settings/qa/trackerMilestones/create"}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.showTrackerMilestones() ?
                    "Loading TrackerMilestones" :
                    null
            }
            {
                this.showTrackerMilestones() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {

        const {
            trackerMilestones
        } = this.props

        if (!trackerMilestones || trackerMilestones.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no trackerMilestones to view.</span>
            </div>
        }

        const showPagination = trackerMilestones.length > DEFAULT_PAGE_SIZE;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={trackerMilestones}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : trackerMilestones.length}
                pageSize={showPagination ? undefined : trackerMilestones.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no trackerMilestone to display"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {

                            const url: string = "/settings/qa/trackerMilestones/" + rowInfo.original.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.LOOKUP} fixedWidth />
                    },
                    {
                        id: "value",
                        Header: 'Name',
                        accessor: 'value',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    }
                ]}
            />
        </div>

    }

}

const mapStateToProps = (state: ITmdState, ownProps: TrackerMilestonesPageProps): ITrackerMilestonesPageProps => {

    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        history: ownProps.history,
        location: state.routing.location,

        formFilter: state.trackerMilestones.formFilter,
        trackerMilestones: state.trackerMilestones.formFilteredData, 

        loadingTrackerMilestones: state.trackerMilestones.loadState && state.trackerMilestones.loadState.status == RequestState.Pending,
        loadTrackerMilestonesSuccess: state.trackerMilestones.loadState && state.trackerMilestones.loadState.status == RequestState.Success,
        loadTrackerMilestonesFailure: state.trackerMilestones.loadState && state.trackerMilestones.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles
    };
};

const mapDispatchToProps = (dispatch): ITrackerMilestonesPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadTrackerMilestones: bindActionCreators(TrackerMilestoneActions.LoadTrackerMilestone, dispatch),
        clearTrackerMilestones: bindActionCreators(TrackerMilestoneActions.Clear, dispatch),

        filter: bindActionCreators(TrackerMilestoneActions.Filter, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrackerMilestonesPage);
