import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadGroup: IGroupLoadActionCreator = () => {
        return {
            type: ACTION_GROUPS_LOAD
        }
    },
    LoadGroupById: IGroupLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_GROUPS_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IGroupLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_GROUPS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IGroupLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_GROUPS_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveGroup: IGroupSaveActionCreator = (formData) => {
        return {
            type: ACTION_GROUPS_SAVE,
            formData
        }
    },
    SaveSuccess: IGroupSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_GROUPS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IGroupSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_GROUPS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateGroup: IGroupCreateActionCreator = () => {

        return {
            type: ACTION_GROUPS_CREATE
        }
    },
    CreateSuccess: IGroupCreateSuccessActionCreator = (formData, formState, formProperties, lookups) => {
        return {
            type: ACTION_GROUPS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups
        }
    },
    CreateFailure: IGroupCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_GROUPS_CREATE_FAILURE,
            responseStatus
        }
    },

    Clear: IGroupClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_GROUPS_CLEAR,
            propertiesToClear
        }
    },

    Filter: IGroupFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_GROUPS_FILTER,
            formFilter
        }
    },
    PersonnelRolesFilter: IGroupPersonnelRolesFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_GROUPS_PERSONNEL_ROLES_FILTER,
            formFilter
        }
    };



export const
    ACTION_GROUPS_LOAD = "ACTION_GROUPS_LOAD",
    ACTION_GROUPS_LOAD_BY_ID = "ACTION_GROUPS_LOAD_BY_ID",
    ACTION_GROUPS_LOAD_SUCCESS = "ACTION_GROUPS_LOAD_SUCCESS",
    ACTION_GROUPS_LOAD_FAILURE = "ACTION_GROUPS_LOAD_FAILURE",

    ACTION_GROUPS_SAVE = "ACTION_GROUPS_SAVE",
    ACTION_GROUPS_SAVE_SUCCESS = "ACTION_GROUPS_SAVE_SUCCESS",
    ACTION_GROUPS_SAVE_FAILURE = "ACTION_GROUPS_SAVE_FAILURE",

    ACTION_GROUPS_CREATE = "ACTION_GROUPS_CREATE",
    ACTION_GROUPS_CREATE_SUCCESS = "ACTION_GROUPS_CREATE_SUCCESS",
    ACTION_GROUPS_CREATE_FAILURE = "ACTION_GROUPS_CREATE_FAILURE",

    ACTION_GROUPS_CLEAR = "ACTION_GROUPS_CLEAR",

    ACTION_GROUPS_FILTER = "ACTION_GROUPS_FILTER",
    ACTION_GROUPS_PERSONNEL_ROLES_FILTER = "ACTION_GROUPS_PERSONNEL_ROLES_FILTER";

export interface IGroupLoadAction {
    type: "ACTION_GROUPS_LOAD";
}

export interface IGroupLoadActionCreator {
    (): IGroupLoadAction;
}

export interface IGroupLoadSuccessAction {
    type: "ACTION_GROUPS_LOAD_SUCCESS";
    formData: Dtos.Group | Dtos.Group[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IGroupLoadSuccessActionCreator {
    (
        formData: Dtos.Group | Dtos.Group[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IGroupLoadSuccessAction;
}

export interface IGroupLoadFailureAction {
    type: "ACTION_GROUPS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IGroupLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IGroupLoadFailureAction;
}

export interface IGroupLoadByIdAction {
    type: "ACTION_GROUPS_LOAD_BY_ID";
    id: number;
}

export interface IGroupLoadByIdActionCreator {
    (id: number): IGroupLoadByIdAction;
}


export interface IGroupSaveAction {
    type: "ACTION_GROUPS_SAVE";
    formData: Dtos.Group;

}

export interface IGroupSaveActionCreator {
    (
        formData: Dtos.Group,
    ): IGroupSaveAction;
}

export interface IGroupSaveSuccessAction {
    type: "ACTION_GROUPS_SAVE_SUCCESS";
    formData: Dtos.Group;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IGroupSaveSuccessActionCreator {
    (
        formData: Dtos.Group,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IGroupSaveSuccessAction;
}

export interface IGroupSaveFailureAction {
    type: "ACTION_GROUPS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IGroupSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IGroupSaveFailureAction;
}

export interface IGroupCreateAction {
    type: "ACTION_GROUPS_CREATE"
}

export interface IGroupCreateActionCreator {
    (): IGroupCreateAction
}

export interface IGroupCreateSuccessAction {
    type: "ACTION_GROUPS_CREATE_SUCCESS";
    formData: Dtos.Group;
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[]
}

export interface IGroupCreateSuccessActionCreator {
    (
        formData: Dtos.Group,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[]
    ): IGroupCreateSuccessAction;
}

export interface IGroupCreateFailureAction {
    type: "ACTION_GROUPS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IGroupCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IGroupCreateFailureAction;
}

export interface IGroupClearAction {
    type: "ACTION_GROUPS_CLEAR";
    propertiesToClear?: string[];
}

export interface IGroupClearActionCreator {
    (propertiesToClear?: string[]): IGroupClearAction;
}

export interface IGroupFilterActionCreator {
    (formFilter: IFormFilter): IGroupFilterAction;
}

export interface IGroupFilterAction {
    type: "ACTION_GROUPS_FILTER";
    formFilter: IFormFilter;
}

export interface IGroupPersonnelRolesFilterActionCreator {
    (formFilter: IFormFilter): IGroupPersonnelRolesFilterAction;
}

export interface IGroupPersonnelRolesFilterAction {
    type: "ACTION_GROUPS_PERSONNEL_ROLES_FILTER";
    formFilter: IFormFilter;
}

export type GroupAction =
    IGroupLoadAction |
    IGroupLoadByIdAction |
    IGroupLoadFailureAction |
    IGroupLoadSuccessAction |
    IGroupSaveAction |
    IGroupSaveSuccessAction |
    IGroupSaveFailureAction |
    IGroupCreateAction |
    IGroupCreateSuccessAction |
    IGroupCreateFailureAction |
    IGroupFilterAction | 
    IGroupClearAction |
    IGroupPersonnelRolesFilterAction;
