import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialGroupItemState: Dtos.GroupItem & IRegistrationFormState = {
    id: null,
    name: null,
    description: null,
    rank: null,
    categoryId: null,
    category: null,
    allowIntraVersions: null,
    pendingDelete: null,
    allowVersionChanges: null,
    collectionId: null,
    collection: null,
    active: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false,
    initialVersion: null
}

export interface IGroupItemReduxFormState {
    id: IFieldState<number>,
    name: IFieldState<string>,
    description: IFieldState<string>,
    rank: IFieldState<number>,
    categoryId: IFieldState<number>,
    category: IFieldState<Dtos.Category>,
    allowIntraVersions: IFieldState<boolean>,
    pendingDelete: IFieldState<boolean>,
    allowVersionChanges: IFieldState<boolean>,
    collectionId: IFieldState<number>,
    collection: IFieldState<Dtos.Collection>,
    active: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>,
    initialVersion: IFieldState<string>
}
