import * as React from "react";
import { ErrorsProps, CustomComponentProps } from "react-redux-form";
import * as Dtos from '../../../dtos/Tmd.dtos';

import './ValidationMessage.scss';
import './RegistrationValidationMessage.scss';
import * as classNames from "classNames";
import { ValidationMessage } from "../index";
import { AlertType } from "../../../enumerations/AlertType";

export interface IRegistrationValidationMessageProps {
    failures: Dtos.ResponseError[];
}

class RegistrationValidationMessage extends React.Component<IRegistrationValidationMessageProps, any> {
    render() {

        const {
            failures,
        } = this.props;

        let distinctFailures: Dtos.ResponseError[] = [];

        failures.forEach(failure => {
            if (!distinctFailures.some(f => f.errorCode == failure.errorCode)) {
                distinctFailures.push(failure);
            }
        })

        return <ValidationMessage className="min-registration-validation-message-width">
            {
                distinctFailures && distinctFailures.length > 0 ?
                    distinctFailures.map((failure, index) => {
                        return <span className="validation-message mb-2 mt-2" key={failure.fieldName + "-failure-" + index}>
                            {failure.errorCode + ": " + failure.message}
                        </span>
                    }) :
                    null
            }
        </ValidationMessage>


    }
}

export default RegistrationValidationMessage;
