import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_TRIALGROUP_LOAD_BY_ID,

    ACTION_TRIALGROUP_SAVE,

    ACTION_TRIALGROUP_CREATE,

    ITrialGroupLoadByIdAction,
    ITrialGroupLoadFailureAction,
    ITrialGroupLoadSuccessAction,

    ITrialGroupSaveAction,
    ITrialGroupSaveSuccessAction,
    ITrialGroupSaveFailureAction,

    ITrialGroupCreateAction,
    ITrialGroupCreateSuccessAction,
    ITrialGroupCreateFailureAction,

    LoadSuccess,
    LoadFailure,


    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,
} from "../actions/trialGroup";
import { trialGroupsApi } from "../services/trialGroups";


export const
    getTrialGroupsByIdEpic = (action$): Observable<ITrialGroupLoadSuccessAction | ITrialGroupLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALGROUP_LOAD_BY_ID)
            .mergeMap((action: ITrialGroupLoadByIdAction) => {
                return trialGroupsApi
                    .getTrialGroupById(action.id)
                    .map(response =>
                        LoadSuccess(response.trialGroup, response.formState, response.formProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setTrialGroupEpic = (action$): Observable<ITrialGroupSaveSuccessAction | ITrialGroupSaveFailureAction> => {
        return action$
            .ofType(ACTION_TRIALGROUP_SAVE)
            .mergeMap((action: ITrialGroupSaveAction) => {
                return trialGroupsApi
                    .setTrialGroup(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.trialGroup, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createTrialGroupEpic = (action$): Observable<ITrialGroupCreateSuccessAction | ITrialGroupCreateFailureAction> => {
        return action$
            .ofType(ACTION_TRIALGROUP_CREATE)
            .mergeMap((action: ITrialGroupCreateAction) => {
                return trialGroupsApi
                    .createTrialGroup(action.trialId)
                    .map(response =>
                        CreateSuccess(response.trialGroup, response.formState, response.formProperties, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



