import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialTrialTrackerState: Dtos.TrialTracker & IRegistrationFormState = {
    id: null,
    trialId: null,

    trackerMilestoneId: null,
    trackerMilestone: null,
    trackerStatus: null,
    trackerStatusId: null,
    trackerType: null,
    trackerTypeId: null,
    trial: null,
    assignedTo: null,
    assignedToId: null,
    subject: null,
    dateStarted: null,
    dateStopped: null,
    enteredByDisplay: null,
    recentComment: null,
    trialTrackerComments: [],
    addComment: null,
    sourceDocuments: [],

    active: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false,
}

export interface ITrialTrackerReduxFormState {
    id: IFieldState<number>,
    trialId: IFieldState<number>,

    trackerMilestoneId: IFieldState<number>,
    trackerStatusId: IFieldState<number>,
    trackerTypeId: IFieldState<number>,
    assignedToId: IFieldState<number>,
    subject: IFieldState<string>,
    dateStarted: IFieldState<string>,
    dateStopped: IFieldState<string>,
    addComment: IFieldState<string>,

    active: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    openedModals: IFieldState<string[]>,
    sourceDocuments: IFieldState<Dtos.SourceDocument[]>,
}
