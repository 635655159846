import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_NOTE_LOAD_BY_ID,

    ACTION_NOTE_SAVE,

    ACTION_NOTE_CREATE,

    INoteLoadByIdAction,
    INoteLoadFailureAction,
    INoteLoadSuccessAction,

    INoteSaveAction,
    INoteSaveSuccessAction,
    INoteSaveFailureAction,

    INoteCreateAction,
    INoteCreateSuccessAction,
    INoteCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/note";
import { notesApi } from "../services/notes";


export const
    getNoteByIdEpic = (action$): Observable<INoteLoadSuccessAction | INoteLoadFailureAction> => {
        return action$
            .ofType(ACTION_NOTE_LOAD_BY_ID)
            .mergeMap((action: INoteLoadByIdAction) => {
                return notesApi
                    .getNoteById(action.id)
                    .map(response =>
                        LoadSuccess(response.note, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setNoteEpic = (action$): Observable<INoteSaveSuccessAction | INoteSaveFailureAction> => {
        return action$
            .ofType(ACTION_NOTE_SAVE)
            .mergeMap((action: INoteSaveAction) => {
                return notesApi
                    .setNote(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.note, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createNoteEpic = (action$): Observable<INoteCreateSuccessAction | INoteCreateFailureAction> => {
        return action$
            .ofType(ACTION_NOTE_CREATE)
            .mergeMap((action: INoteCreateAction) => {
                return notesApi
                    .createNote(action.trialId, action.institutionId, action.trialInstitutionId, action.personnelId, action.groupId)
                    .map(response =>
                        CreateSuccess(
                            response.note,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



