import * as React from 'react';
import './TrialItemInstanceImportPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu, TruncatedCell } from "../../../components/layouts/index";
import { ITrialItemInstanceImportReduxFormState, initialTrialItemInstanceImportState } from "../../../reducers/reactReduxForms/trialItemInstanceImport";
import * as TrialActions from '../../../actions/trial';
import * as TrialItemInstanceImportActions from '../../../actions/trialItemInstanceImport';
import * as ToggleActions from '../../../actions/pages/togglePage';
import * as ToggleHelper from "../../../helpers/toggleHelper";
import { TogglePageItem } from '../../../reducers/pages/togglePage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInputFabric, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { Modal, NotificationAlert } from "../../../components/common/index";
import { Form, actions, Control } from 'react-redux-form';
import { TrialLayout } from '../../index';

interface ITrialItemInstanceImportPageParams {
    trialId: number;
}

interface ITrialItemInstanceImportPageProps {

    form: Dtos.TrialItemInstanceImport & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITrialItemInstanceImportReduxFormState;
    lookups: Dtos.Lookup[];
    validationFailures: Dtos.ResponseError[];
    selectedItems: number[];
    trialItemInstancesByCategory: Dtos.TrialItemInstanceCategory[];

    location: Location;
    trialId: number;
    sourceTrialId: number;
    match: match<ITrialItemInstanceImportPageParams>;
    history: History;

    // From state
    trialItemInstanceImport: Dtos.TrialItemInstanceImport;
    loading: boolean;
    loadSuccess: boolean;
    loadFailure: boolean;


    saving: boolean;
    saveSuccess: boolean;
    saveFailure: boolean;

    user: Dtos.User,

    toggleItems: TogglePageItem[]
}

interface ITrialItemInstanceImportPageActions {
    clear: TrialItemInstanceImportActions.ITrialItemInstanceImportClearActionCreator;
    load: TrialItemInstanceImportActions.ITrialItemInstanceImportLoadByIdActionCreator;
    save: TrialItemInstanceImportActions.ITrialItemInstanceImportSaveActionCreator;

    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    toggleExpand: ToggleActions.IToggleExpandedStateActionCreator;
}

type TrialItemInstanceImportPageProps = ITrialItemInstanceImportPageProps & ITrialItemInstanceImportPageActions;

const reduxFormName: string = "reduxForms.trialItemInstanceImport";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class TrialItemInstanceImportPage extends React.PureComponent<TrialItemInstanceImportPageProps, any> {

    constructor(props: TrialItemInstanceImportPageProps) {
        super(props);

        this.clear = this.clear.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount() {
        const {
            load,
            trialId,
            trialItemInstanceImport,
            loadTrial
        } = this.props;
        if (trialItemInstanceImport) {
            load(trialId, trialItemInstanceImport.trialId);
        } else {
            load(trialId, 0);
        }
        loadTrial(trialId);
    }

    componentDidUpdate(prevProps: TrialItemInstanceImportPageProps, prevState) {
        const {
            form,
            trialId,
            sourceTrialId,
            load,
            loading,
            loadSuccess,
            saving,
            saveSuccess,
            validationFailures,
            navigate
        } = this.props


        if ((trialId && prevProps.trialId != trialId)) {
            load(trialId, sourceTrialId);
        }

        if (!loading && prevProps.loading) {
            if (loadSuccess) {
                this.loadForm();
            }
        }

        if (!saving && prevProps.saving) {
            if (saveSuccess) {
                if (validationFailures && validationFailures.length > 0) {
                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);
                } else {
                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);
                }
                if (form.saveAndReturn) {
                    navigate("/trials/" + trialId + "/items")
                } else {
                    this.loadForm();
                }
            } else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }
    }

    componentWillUnmount() {
        this.clear();
    }

    clear() {
        this.props.clear();
        this.props.clearTrial();
    }

    show(): boolean {
        const {
            loading,
            loadSuccess
        } = this.props

        if (!loading && loadSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loading
        } = this.props

        return (
            <TrialLayout loading={loading} permission={Dtos.Permission.TrialItemInstanceCreate}>{this.renderContent()}</TrialLayout>
        );
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            trialId,
            selectedItems,
            load,
            saving,
            resetForm
        } = this.props;

        const formDisabled: boolean = saving || (formState && formState.formStatus == Dtos.FormStatus.Disabled);
        const formTrialSelected: boolean = !formDisabled && form.trialId && form.trialId > 0;
        const formCanSave: boolean = !formDisabled && formTrialSelected && selectedItems && selectedItems.length > 0;

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            trialId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("TrialItemInstanceImport", "TrialId", lookups), form.trialId)
                },
                inputLabel: "Select trial to import from",
                changeAction: (model, value, form, parentModel) => {
                    console.log("parentModel", parentModel)
                    changeForm(model, value);
                    if (value != form.trialId) {
                        resetForm(reduxFormName);
                        load(trialId, value);
                    }
                },
            },
            addGroups: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled || !form.trialId || form.trialId == 0,
                inputProps: {
                    label: "Link groups attached to selected items"
                },
                inputLabel: " ",
                hide: true
            },
        };


        return <div className="trail-item-import">
            <h3>Trial Item Import</h3>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "trialItemInstanceImport", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                {(form.trialId && form.trialId > 0) &&
                    <div className="mt-2">
                    {
                        this.renderTable()
                    }
                    </div>
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={!formCanSave}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>
                    <button disabled={!formCanSave}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
        </div>
    }

    renderTable() {
        const {
            trialItemInstanceImport,
            trialItemInstancesByCategory,
            toggleItems,
            toggleExpand
        } = this.props;

        if (!trialItemInstancesByCategory || trialItemInstancesByCategory.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no items to view.</span>
            </div>
        }

        const showPagination = trialItemInstancesByCategory.length > DEFAULT_PAGE_SIZE;
        const trialItemInstanceImportId = trialItemInstanceImport ? trialItemInstanceImport.id : 0;

        return <div>
            {trialItemInstancesByCategory.map((item, index) => {
                const key = "trial-items-page-" + item.categoryId + "-" + trialItemInstanceImportId;
                const expanded = ToggleHelper.isToggleExpanded(toggleItems, key);
                return (
                    <div key={"trial-items-category" + item.category.id}>
                        <div className={tableHeaderClass} style={tableStyleClass} onClick={() => toggleExpand(key)}>
                            <div>
                                <FontAwesomeIcon icon={!expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" />
                            </div>
                            <span>{item.category.value}</span>
                        </div>
                        {expanded && (
                            <ReactTable
                                data={item.trialItemInstanceSummaries}
                                pageSizeOptions={PAGE_SIZE_OPTIONS}
                                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : item.trialItemInstanceSummaries.length}
                                pageSize={showPagination ? undefined : item.trialItemInstanceSummaries.length}
                                showPagination={showPagination}
                                className="-striped -highlight -clickable mb-3"
                                noDataText="There are currently no trial items for this category"
                                resizable={false}
                                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                                    return {
                                        onClick: (e, handleOriginal) => {
                                            this.onSelectedItemChanged(rowInfo.original.id, !this.isItemSelected(rowInfo.original.id))

                                            if (handleOriginal) {
                                                handleOriginal()
                                            }

                                        }
                                    }
                                }}
                                columns={
                                    [
                                        {
                                            id: "id",
                                            Header: " ",
                                            accessor: "id",
                                            className: "d-flex align-items-center justify-content-center",
                                            headerClassName: "text-center",
                                            maxWidth: 50,
                                            Cell: (props) => <FontAwesomeIcon icon={this.isItemSelected(props.value) ? FontAwesomeIcons.Light.CHECK : FontAwesomeIcons.Light.UNCHECKED} fixedWidth />
                                        },
                                        {
                                            id: "id",
                                            Header: ' ',
                                            accessor: "id",
                                            className: "d-flex align-items-center justify-content-center",
                                            maxWidth: 50,
                                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.TRIALITEM} fixedWidth />
                                        },
                                        {
                                            id: "name",
                                            Header: "Name",
                                            accessor: "name",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            Cell: (props) => <TruncatedCell value={props.value} />
                                        },
                                        {
                                            id: "description",
                                            Header: "Description",
                                            accessor: "description",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            Cell: (props) => <TruncatedCell value={props.value} />
                                        },
                                        {
                                            id: "initialVersion",
                                            Header: "Initial Version",
                                            accessor: "initialVersion",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            maxWidth: 220,
                                            Cell: (props) => <TruncatedCell value={props.value} />
                                        }
                                    ]
                                }
                            />
                        )}
                    </div>
                )
            }
            )}
        </div>
    }

    renderErrorAlert(): any {
        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>
    }

    renderSaveSuccessAlert(): any {
        return <div>The items hves been successfully saved to the trial</div>
    }

    handleOnSubmit(data: Dtos.TrialItemInstanceImport) {
        const {
            save
        } = this.props;

        if (data) {
            let changes: Dtos.TrialItemInstanceImport = {} as Dtos.TrialItemInstanceImport;
            save(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.TrialInstitution) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onSelectedItemChanged(id: number, selected: boolean) {
        const {
            selectedItems,
            changeForm
        } = this.props

        if (selectedItems) {
            let newArray = [...selectedItems];
            var index = newArray.indexOf(id);
            if (selected) {
                // add if not exists
                if (index == -1) {
                    newArray.push(id);
                    changeForm(reduxFormName + '.selectedItems', newArray);
                }
            } else {
                // remove if not exists
                if (index > -1) {
                    newArray.splice(index, 1);
                    changeForm(reduxFormName + '.selectedItems', newArray);
                }
            }
        }
    }

    isItemSelected(id: number) {
        const {
            selectedItems,
        } = this.props

        if (selectedItems) {
            var index = selectedItems.indexOf(id);
            return index > -1;
        }
        return false
    }

    onCancel() {
        const {
            trialId,
            navigate
        } = this.props;

        navigate("/trials/" + trialId + "/items");
    }

    loadForm() {
        const {
            loadForm,
            trialItemInstanceImport,
        } = this.props

        let extraData: Dtos.TrialItemInstanceImport = {} as Dtos.TrialItemInstanceImport;

        loadForm(reduxFormName, Object.assign({ ...trialItemInstanceImport }, extraData));
    }
}

const mapStateToProps = (state: ITmdState, ownProps: TrialItemInstanceImportPageProps): ITrialItemInstanceImportPageProps => {

    return {

        form: state.reduxForms.trialItemInstanceImport,
        lookups: state.trialItemInstanceImport.lookups,
        formState: state.trialItemInstanceImport.formState,
        formProperties: state.trialItemInstanceImport.formProperties,
        reduxFormState: state.reduxForms.formState.trialItemInstanceImport,
        validationFailures: state.trialItemInstanceImport.validationFailures,
        selectedItems: state.reduxForms.trialItemInstanceImport.selectedItems,
        trialItemInstancesByCategory: state.reduxForms.trialItemInstanceImport &&
            state.reduxForms.trialItemInstanceImport.trialItemInstanceCategories ? state.reduxForms.trialItemInstanceImport.trialItemInstanceCategories : [],

        match: ownProps.match,
        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,
        sourceTrialId: state.reduxForms.trialItemInstanceImport ? state.reduxForms.trialItemInstanceImport.trialId : undefined,

        history: ownProps.history,
        location: state.routing.location,

        trialItemInstanceImport: state.trialItemInstanceImport.formData,

        loading: state.trialItemInstanceImport.loadState && state.trialItemInstanceImport.loadState.status == RequestState.Pending,
        loadSuccess: state.trialItemInstanceImport.loadState && state.trialItemInstanceImport.loadState.status == RequestState.Success,
        loadFailure: state.trialItemInstanceImport.loadState && state.trialItemInstanceImport.loadState.status == RequestState.Failure,

        saving: state.trialItemInstanceImport.saveState && state.trialItemInstanceImport.saveState.status == RequestState.Pending,
        saveSuccess: state.trialItemInstanceImport.saveState && state.trialItemInstanceImport.saveState.status == RequestState.Success,
        saveFailure: state.trialItemInstanceImport.saveState && state.trialItemInstanceImport.saveState.status == RequestState.Failure,

        user: state.user.data,

        toggleItems: state.togglePage.items
    };
};

const mapDispatchToProps = (dispatch): ITrialItemInstanceImportPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        load: bindActionCreators(TrialItemInstanceImportActions.Load, dispatch),
        clear: bindActionCreators(TrialItemInstanceImportActions.Clear, dispatch),
        save: bindActionCreators(TrialItemInstanceImportActions.Save, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch),

        toggleExpand: bindActionCreators(ToggleActions.ToggleExpanded, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialItemInstanceImportPage);
