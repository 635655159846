import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const personnelApi = {
    getPersonnel: (): Observable<Dtos.GetPersonnelResponse> => {
        //Create the request.
        let request: Dtos.GetPersonnel = new Dtos.GetPersonnel();

        //Send request.
        const response: Promise<Dtos.GetPersonnelResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getPersonnelById: (id: number): Observable<Dtos.GetPersonnelByIdResponse> => {
        //Create the request.
        let request: Dtos.GetPersonnelById = new Dtos.GetPersonnelById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetPersonnelByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getPersonnelSecurityById: (id: number): Observable<Dtos.GetPersonnelByIdResponse> => {
        //Create the request.
        let request: Dtos.GetPersonnelById = new Dtos.GetPersonnelSecurityById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetPersonnelByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setPersonnel: (personnel: Dtos.Personnel): Observable<Dtos.SetPersonnelResponse> => {

        let request: Dtos.SetPersonnel = new Dtos.SetPersonnel();

        request.personnel = personnel;

        // send request
        const response: Promise<Dtos.SetPersonnelResponse> = client.post(request);

        return Observable.from(response);

    },
    createPersonnel: (): Observable<Dtos.CreatePersonnelResponse> => {
        //Create the request.
        let request: Dtos.CreatePersonnel = new Dtos.CreatePersonnel();

        //Send request.
        const response: Promise<Dtos.CreatePersonnelResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setPersonnelActiveDirectory: (personnel: Dtos.Personnel): Observable<Dtos.SetPersonnelActiveDirectoryResponse> => {

        let request: Dtos.SetPersonnelActiveDirectory = new Dtos.SetPersonnelActiveDirectory();

        request.personnel = personnel;

        // send request
        const response: Promise<Dtos.SetPersonnelActiveDirectoryResponse> = client.post(request);

        return Observable.from(response);

    },
    resendEmail: (id: number): Observable<Dtos.PostPersonnelResendEmailResponse> => {

        let request: Dtos.PostPersonnelResendEmail = new Dtos.PostPersonnelResendEmail();

        request.id = id;

        // send request
        const response: Promise<Dtos.PostPersonnelResendEmailResponse> = client.post(request);

        return Observable.from(response);
    },
    getPersonnelPermissionsById: (id: number): Observable<Dtos.GetPersonnelPermissionsByIdResponse> => {
        //Create the request.
        let request: Dtos.GetPersonnelPermissionsById = new Dtos.GetPersonnelPermissionsById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetPersonnelPermissionsByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getPersonnelByRole: (): Observable<Dtos.GetPersonnelByRoleResponse> => {
        //Create the request.
        let request: Dtos.GetPersonnelByRole = new Dtos.GetPersonnelByRole();

        //Send request.
        const response: Promise<Dtos.GetPersonnelByRoleResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
}