import * as React from 'react';
import './HubspotExportPage.scss';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import Alert from 'react-s-alert';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu, TruncatedCell } from "../../../components/layouts/index";
import * as HubspotActions from '../../../actions/hubspot';
import * as PagingActions from '../../../actions/pages/pagingPage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { TextAreaFormInput, FormFilterInput } from '../../../components/form';
import { SettingsSubMenu } from '../../../helpers/subMenuHelper';
import { HubspotSettingsLayout } from '../../index';

interface IHubspotExportPageProps {

    location: Location;
    history: History;

    formFilter: IFormFilter;
    formLength: number;
    contacts: Dtos.Hubspot[];

    loading: boolean;
    loadSuccess: boolean;
    loadFailure: boolean;

    saving: boolean;
    saveSuccess: boolean;
    saveFailure: boolean;

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];

    pageSize: number
}

interface IHubspotExportPageActions {
    clear: HubspotActions.IHubspotClearActionCreator;
    load: HubspotActions.IHubspotLoadContactsToExportActionCreator;
    export: HubspotActions.IHubspotExportContactsActionCreator;
    filter: HubspotActions.IHubspotFilterActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
    
    setPageSize: PagingActions.IPagingPageSizeChangedStateActionCreator;
}

type HubspotExportPageProps = IHubspotExportPageProps & IHubspotExportPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class HubspotExportPage extends React.PureComponent<HubspotExportPageProps, any> {

    constructor(props: HubspotExportPageProps) {
        super(props);
        this.clear = this.clear.bind(this);
        this.export = this.export.bind(this);
    }

    componentDidMount() {
        const {
            load
        } = this.props;
        load();
    }

    componentWillReceiveProps(nextProps: HubspotExportPageProps) {
    }

    componentDidUpdate(prevProps: HubspotExportPageProps, prevState) {
        const {
            saving,
            saveSuccess,
            saveFailure
        } = this.props

        if (!saving && prevProps.saving) {
            if (saveSuccess) {
                Alert.success(<NotificationAlert
                    alertContent={renderRegistrationFormSaveSuccess()}
                    icon={FontAwesomeIcons.Solid.CHECK}
                />);
            } else if (saveFailure) {
                Alert.error(
                    <NotificationAlert
                        alertContent={<div>
                            The export failed.
                        </div>}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }
    }

    componentWillUpdate(nextProps: HubspotExportPageProps) {
    }

    componentWillUnmount() {
        this.clear();
    }

    clear() {
        this.props.clear();
    }

    export(username: string) {
        this.props.export(username);
    }

    show(): boolean {
        const {
            loading,
            loadSuccess,
            saving
        } = this.props

        if (!loading && loadSuccess && !saving) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loading,
            permissions
        } = this.props;

        return <HubspotSettingsLayout
            permission={Dtos.Permission.HubspotContactExportView}
            loading={loading}
        >
            {this.renderContent()}
        </HubspotSettingsLayout>;
    }

    renderContent() {
        const {
            navigate,
            permissions,
            loading,
            saving
        } = this.props;

        const canUpdate = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.HubspotContactExportUpdate);

        return <div>
            <div className="row">
                <div className={"col-6 mb-2"}>
                    <h2>Process new members</h2>
                </div>
                <div className="col-6" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} activeLabel="Exported" />
                    {canUpdate && (
                        <button disabled={loading || saving}
                            onClick={
                                () => {
                                    this.export("");
                                }
                            }
                            type="button"
                            className="btn btn-warning"
                        >
                            Run
                        </button>
                        )}
                </div>
            </div>
            {
                !this.show() ?
                    saving ? "Exporting Contacts from Hubspot" : "Loading Contacts from Hubspot" :
                    null
            }
            {
                this.show() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {

        const {
            contacts,
            permissions,
            pageSize,
            setPageSize,
            saving
        } = this.props

        if (!contacts || contacts.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no Hubspot contacts to export.</span>
            </div>
        }

        const canUpdate = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.HubspotContactExportUpdate);
        const showPagination = contacts.length > pageSize;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={contacts}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? pageSize : contacts.length}
                pageSize={showPagination ? pageSize : contacts.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no Hubspot contacts to display"
                resizable={false}
                onPageSizeChange={val => setPageSize(val)}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log('rowInfo', rowInfo);
                            //const url: string = "/settings/itemcomponents/" + rowInfo.original.id;
                            //this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: this.getHubpotDataValue(rowInfo.original.data, "Error") != "" ? 'red' : '#212529'
                        }
                    }
                }}
                columns={[
                    {
                        id: "email",
                        Header: 'Email',
                        accessor: 'data',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => {
                            console.log(props);
                            return <TruncatedCell value={this.getHubpotDataValue(props.value, "Email")} />;
                        }
                    },
                    {
                        id: "firstname",
                        Header: 'First Name',
                        accessor: 'data',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => {
                            console.log(props);
                            return <TruncatedCell value={this.getHubpotDataValue(props.value, "First Name")} />;
                        }
                    },
                    {
                        id: "lastname",
                        Header: 'Last Name',
                        accessor: 'data',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => {
                            console.log(props);
                            return <TruncatedCell value={this.getHubpotDataValue(props.value, "Last Name")} />;
                        }
                    },
                    {
                        id: "type",
                        Header: 'Contact Type',
                        accessor: 'data',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => {
                            console.log(props);
                            return <TruncatedCell value={this.getHubpotDataValue(props.value, "Contact type")} />;
                        }
                    },
                    {
                        id: "username",
                        Header: 'Username',
                        accessor: 'data',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => {
                            console.log(props);
                            return <TruncatedCell value={this.getHubpotDataValue(props.value, "Username")} />;
                        }
                    },
                    {
                        id: "groups",
                        Header: 'Groups',
                        accessor: 'data',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => {
                            console.log(props);
                            return <TruncatedCell value={this.getHubpotDataValue(props.value, "Groups")} />;
                        }
                    },
                    {
                        id: "action",
                        Header: 'Action',
                        accessor: 'data',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => {
                            console.log(props);
                            return <TruncatedCell value={this.getHubpotDataValue(props.value, "Action")} />;
                        }
                    },
                    {
                        id: "error",
                        Header: 'Error',
                        accessor: 'data',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => {
                            console.log(props);
                            return <TruncatedCell value={this.getHubpotDataValue(props.value, "Error")} />;
                        }
                    },
                    {
                        id: "id",
                        Header: '',
                        accessor: 'id',
                        className: "d-flex align-items-right justify-content-right",
                        headerClassName: "text-left",
                        Cell: (props) => {
                            return <>
                                {(canUpdate && this.getHubpotDataValue(props.original.data, "Action") == "None") && (
                                    <button disabled={saving}
                                        onClick={
                                            () => {
                                                this.export(this.getHubpotDataValue(props.original.data, "Username"));
                                            }
                                        }
                                        type="button"
                                        className="btn btn-warning btn-sm mr-2"
                                    >
                                        Email
                                    </button>
                                )}
                                {(canUpdate && this.getHubpotDataValue(props.original.data, "Action") != "None") && (
                                    <button disabled={saving}
                                        onClick={
                                            () => {
                                                this.export(this.getHubpotDataValue(props.original.data, "Username"));
                                            }
                                        }
                                        type="button"
                                        className="btn btn-outline-primary btn-sm mr-2"
                                    >
                                        Export
                                    </button>
                                )}
                                
                            </>
                        },
                        width: 70
                    }
                ]}
            />
        </div>

    }

    getHubpotDataValue(values, name) {
        var value = values.find(f => f.name == name);
        console.log(values, name, value);
        return value ? value.value : "";
    }

}

const mapStateToProps = (state: ITmdState, ownProps: HubspotExportPageProps): IHubspotExportPageProps => {

    let contacts: Dtos.Hubspot[] | undefined = (state.hubspot.formFilteredData instanceof Array) ? state.hubspot.formFilteredData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        history: ownProps.history,
        location: state.routing.location,

        contacts: contacts!,
        formFilter: state.hubspot.formFilter,
        formLength: state.hubspot.formLength,

        loading: state.hubspot.loadState && state.hubspot.loadState.status == RequestState.Pending,
        loadSuccess: state.hubspot.loadState && state.hubspot.loadState.status == RequestState.Success,
        loadFailure: state.hubspot.loadState && state.hubspot.loadState.status == RequestState.Failure,

        saving: state.hubspot.saveState && state.hubspot.saveState.status == RequestState.Pending,
        saveSuccess: state.hubspot.saveState && state.hubspot.saveState.status == RequestState.Success,
        saveFailure: state.hubspot.saveState && state.hubspot.saveState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        pageSize: state.pagingPage.pageSize
    };
};

const mapDispatchToProps = (dispatch): IHubspotExportPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        load: bindActionCreators(HubspotActions.LoadHubspotContactsToExport, dispatch),
        clear: bindActionCreators(HubspotActions.Clear, dispatch),
        export: bindActionCreators(HubspotActions.HubspotExportContacts, dispatch),
        filter: bindActionCreators(HubspotActions.Filter, dispatch),

        setPageSize: bindActionCreators(PagingActions.PageSizeChanged, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(HubspotExportPage);
