import { combineEpics } from "redux-observable";

import * as UserEpics from './users';
import * as WebsiteSettingsEpics from './websiteSettings';
import * as HomeEpics from './home';
import * as TrialEpics from './trials';
import * as TrialItemEpics from './trialItems';
import * as TrialGroupEpics from './trialGroups';
import * as TrialGroupItemEpics from './trialGroupItems';
import * as TrialInstitutionEpics from './trialInstitutions';
import * as TrialInstitutionItemEpics from './trialInstitutionItems';
import * as TrialInstitutionItemTemplateEpics from './trialInstitutionItemTemplates';
import * as ItemComponents from './itemComponents';
import * as CategoryEpics from './categories';
import * as GroupEpics from './groups';
import * as GroupItemEpics from './groupItems';
import * as CollectionEpics from './collections';
import * as InstitutionEpics from './institutions';
import * as CollectionInstanceEpics from './collectionInstances';
import * as PersonnelEpics from './personnel';
import * as PersonnelRoleEpics from './personnelRoles';
import * as SearchEpics from './search';
import * as CountryEpics from './countries';
import * as InstitutionCodingsEpics from './institutionCodings';
import * as NoteTypeEpics from './noteTypes';
import * as TrialTypeEpics from './trialTypes';
import * as ItemComponentTypeEpics from './itemComponentTypes';
import * as NoteEpics from './notes';
import * as TrialItemImportEpics from './trialItemImport';
import * as TrialItemInstanceImportEpics from './trialItemInstanceImport';
import * as TrialItemUpdateEpics from './trialItemUpdate';
import * as OpenClinicaUserRolesEpics from './openClinicaUserRoles';
import * as HubspotEpics from './hubspot';
import * as StateEpics from './states';
import * as InstitutionTypeEpics from './institutionTypes';
import * as SiteLocationEpics from './siteLocations';
import * as FacilityAllianceMemberEpics from './facilityAllianceMembers';
import * as KeyContactEpics from './keyContacts';
import * as TrialCategoryEpics from './trialCategorys';
import * as TumourGroupEpics from './tumourGroups';
import * as AllocatedWPEpics from './allocatedWPs';
import * as TrialPhaseEpics from './trialPhases';
import * as InvolvementEpics from './involvements';
import * as TrialPresentationTypeEpics from './trialPresentationTypes';
import * as TrialPublicationTypeEpics from './trialPublicationTypes';
import * as TrialAnalysisTypeEpics from './trialAnalysisTypes';
import * as TrialCoordCentreEpics from './trialCoordCentres';
import * as CollaboratingGroupEpics from './collaboratingGroups';
import * as GenderEpics from './genders';
import * as MembershipTypeEpics from './membershipTypes';
import * as ProfessionalDisciplineEpics from './professionalDisciplines';
import * as InterestEpics from './interests';
import * as SalutationEpics from './salutations';
import * as FormTemplateEpics from './formTemplates';
import * as PatientEpics from './patients';
import * as InstitutionFormEpics from './institutionForms';
import * as TrialFormEpics from './trialForms';
import * as RoleEpics from './roles';
import * as DepartmentEpics from './departments';
import * as DepartmentContactEpics from './departmentContacts';
import * as ReportingTagCategoryEpics from './reportingTagCategories';
import * as ReportingTagEpics from './reportingTags';
import * as TrialTreatmentEpics from './trialTreatments';
import * as TaskTypeEpics from './taskTypes';
import * as FormTemplateFileTypeEpics from './formTemplateFileTypes';
import * as TrackerTypeEpics from './trackerTypes';
import * as TrackerMilestoneEpics from './trackerMilestones';
import * as TrialTrackerEpics from './trialTrackers';
import * as StandardEmailTemplateEpics from './standardEmailTemplates';
import * as TemplateTrialItemsEpics from './templateTrialItems';
import * as TrialItemInstanceEpics from './trialItemInstances';

import { OpenClinicaUserRole, Personnel } from "../dtos/Tmd.dtos";

export const rootEpic = combineEpics<any, any, any>(
    UserEpics.getUserEpic,
    WebsiteSettingsEpics.getWebsiteSettingsEpic,
    WebsiteSettingsEpics.setWebsiteSettingsEpic,
    HomeEpics.getHomeEpic,
    TrialEpics.getTrialsEpic,
    TrialEpics.getTrialsCurrentUserEpic,
    TrialEpics.getTrialsByIdEpic,
    TrialEpics.createTrialEpic,
    TrialEpics.setTrialEpic,
    TrialEpics.getTrialWithTrialItemInstancesByIdEpic,
    TrialItemEpics.getTrialItemsByIdEpic,
    TrialItemEpics.createTrialItemEpic,
    TrialItemEpics.setTrialItemEpic,
    TrialGroupEpics.getTrialGroupsByIdEpic,
    TrialGroupEpics.createTrialGroupEpic,
    TrialGroupEpics.setTrialGroupEpic,
    TrialInstitutionEpics.getTrialInstitutionsEpic,
    TrialInstitutionEpics.getTrialInstitutionsByIdEpic,
    TrialInstitutionEpics.createTrialInstitutionEpic,
    TrialInstitutionEpics.setTrialInstitutionEpic,
    TrialTreatmentEpics.getTrialTreatmentsEpic,
    TrialTreatmentEpics.getTrialTreatmentsByIdEpic,
    TrialTreatmentEpics.createTrialTreatmentEpic,
    TrialTreatmentEpics.setTrialTreatmentEpic,
    TrialTreatmentEpics.syncTrialTreatmentEpic,
    DepartmentEpics.getDepartmentsEpic,
    DepartmentEpics.getDepartmentsByIdEpic,
    DepartmentEpics.createDepartmentEpic,
    DepartmentEpics.setDepartmentEpic,
    DepartmentContactEpics.getDepartmentContactsEpic,
    DepartmentContactEpics.getDepartmentContactsByIdEpic,
    DepartmentContactEpics.createDepartmentContactEpic,
    DepartmentContactEpics.setDepartmentContactEpic,
    TrialInstitutionItemEpics.getTrialInstitutionItemsByIdEpic,
    TrialInstitutionItemEpics.createTrialInstitutionItemEpic,
    TrialInstitutionItemEpics.setTrialInstitutionItemEpic,
    TrialItemInstanceEpics.getTrialItemInstancesByIdEpic,
    TrialItemInstanceEpics.createTrialItemInstanceEpic,
    TrialItemInstanceEpics.setTrialItemInstanceEpic,
    TrialItemInstanceEpics.getTrialItemInstanceCurrentUserEpic,
    TrialGroupItemEpics.getTrialGroupItemsByIdEpic,
    TrialGroupItemEpics.createTrialGroupItemEpic,
    TrialGroupItemEpics.setTrialGroupItemEpic,
    ItemComponents.getTrialsByIdEpic,
    ItemComponents.getTrialsEpic,
    ItemComponents.createItemComponentEpic,
    ItemComponents.setItemComponentEpic,
    CategoryEpics.getCategoriesEpic,
    CategoryEpics.getCategoryByIdEpic,
    CategoryEpics.setCategoryEpic,
    CategoryEpics.createCategoryEpic,
    GroupEpics.getGroupsEpic,
    GroupEpics.getGroupByIdEpic,
    GroupEpics.createGroupEpic,
    GroupEpics.setGroupEpic,
    GroupItemEpics.getGroupItemsByIdEpic,
    GroupItemEpics.createGroupItemEpic,
    GroupItemEpics.setGroupItemEpic,
    CollectionEpics.getCollectionsByIdEpic,
    CollectionEpics.createCollectionEpic,
    CollectionEpics.lockCollectionEpic,
    InstitutionEpics.getInstitutionsEpic,
    InstitutionEpics.getInstitutionByIdEpic,
    InstitutionEpics.createInstitutionEpic,
    InstitutionEpics.setInstitutionEpic,
    CollectionInstanceEpics.getCollectionInstanceByIdEpic,
    CollectionInstanceEpics.setCollectionInstanceEpic,
    PersonnelEpics.getPersonnelEpic,
    PersonnelEpics.getPersonnelByIdEpic,
    PersonnelEpics.getPersonnelSecurityByIdEpic,
    PersonnelEpics.createPersonnelEpic,
    PersonnelEpics.setPersonnelEpic,
    PersonnelEpics.setPersonnelActiveDirectoryEpic,
    PersonnelEpics.getPersonnelPermissionsByIdEpic,
    PersonnelEpics.postPersonnelResendEmailEpic,
    PersonnelEpics.getPersonnelByRoleEpic,
    PersonnelRoleEpics.postPersonnelRoleResendEmailEpic,
    PersonnelRoleEpics.getPersonnelRolesByIdEpic,
    PersonnelRoleEpics.createPersonnelRoleEpic,
    PersonnelRoleEpics.setPersonnelRoleEpic,
    SearchEpics.searchEpic,
    CountryEpics.getCountriesEpic,
    CountryEpics.getCountryByIdEpic,
    CountryEpics.setCountryEpic,
    CountryEpics.createCountryEpic,
    InstitutionCodingsEpics.getInstitutionCodingsEpic,
    InstitutionCodingsEpics.getInstitutionCodingByIdEpic,
    InstitutionCodingsEpics.setInstitutionCodingEpic,
    InstitutionCodingsEpics.createInstitutionCodingEpic,
    NoteTypeEpics.getNoteTypesEpic,
    NoteTypeEpics.getNoteTypeByIdEpic,
    NoteTypeEpics.setNoteTypeEpic,
    NoteTypeEpics.createNoteTypeEpic,
    TrialTypeEpics.getTrialTypesEpic,
    TrialTypeEpics.getTrialTypeByIdEpic,
    TrialTypeEpics.setTrialTypeEpic,
    TrialTypeEpics.createTrialTypeEpic,
    NoteEpics.getNoteByIdEpic,
    NoteEpics.createNoteEpic,
    NoteEpics.setNoteEpic,
    ItemComponentTypeEpics.getItemComponentTypesEpic,
    ItemComponentTypeEpics.getItemComponentTypeByIdEpic,
    ItemComponentTypeEpics.setItemComponentTypeEpic,
    ItemComponentTypeEpics.createItemComponentTypeEpic,
    TrialItemImportEpics.getTrialItemImportByIdEpic,
    TrialItemImportEpics.setTrialItemImportEpic,
    TrialItemInstanceImportEpics.getTrialItemInstanceImportByIdEpic,
    TrialItemInstanceImportEpics.setTrialItemInstanceImportEpic,
    TrialItemUpdateEpics.getTrialItemUpdateByIdEpic,
    TrialItemUpdateEpics.setTrialItemUpdateEpic,
    OpenClinicaUserRolesEpics.getOpenClinicaUserRolesEpic,
    OpenClinicaUserRolesEpics.setOpenClinicaUserRoleEpic,
    OpenClinicaUserRolesEpics.postOpenClinicaUserRoleChangeStatusEpic,
    OpenClinicaUserRolesEpics.postOpenClinicaUserRoleResendEmailEpic,
    OpenClinicaUserRolesEpics.postOpenClinicaUserRoleImportEpic,
    HubspotEpics.getHubspotByIdEpic,
    HubspotEpics.getHubspotContactsToExportEpic,
    HubspotEpics.postHubspotContactsToExportEpic,
    HubspotEpics.findHubspotContactEpic,
    StateEpics.getStatesEpic,
    StateEpics.getStateByIdEpic,
    StateEpics.setStateEpic,
    StateEpics.createStateEpic,    
    InstitutionTypeEpics.getInstitutionTypesEpic,
    InstitutionTypeEpics.getInstitutionTypeByIdEpic,
    InstitutionTypeEpics.setInstitutionTypeEpic,
    InstitutionTypeEpics.createInstitutionTypeEpic,
    SiteLocationEpics.getSiteLocationsEpic,
    SiteLocationEpics.getSiteLocationByIdEpic,
    SiteLocationEpics.setSiteLocationEpic,
    SiteLocationEpics.createSiteLocationEpic,
    FacilityAllianceMemberEpics.getFacilityAllianceMembersEpic,
    FacilityAllianceMemberEpics.getFacilityAllianceMemberByIdEpic,
    FacilityAllianceMemberEpics.setFacilityAllianceMemberEpic,
    FacilityAllianceMemberEpics.createFacilityAllianceMemberEpic,    
    KeyContactEpics.getKeyContactsEpic,
    KeyContactEpics.getKeyContactByIdEpic,
    KeyContactEpics.setKeyContactEpic,
    KeyContactEpics.createKeyContactEpic,    
    TrialCategoryEpics.getTrialCategorysEpic,
    TrialCategoryEpics.getTrialCategoryByIdEpic,
    TrialCategoryEpics.setTrialCategoryEpic,
    TrialCategoryEpics.createTrialCategoryEpic,    
    TumourGroupEpics.getTumourGroupsEpic,
    TumourGroupEpics.getTumourGroupByIdEpic,
    TumourGroupEpics.setTumourGroupEpic,
    TumourGroupEpics.createTumourGroupEpic,    
    AllocatedWPEpics.getAllocatedWPsEpic,
    AllocatedWPEpics.getAllocatedWPByIdEpic,
    AllocatedWPEpics.setAllocatedWPEpic,
    AllocatedWPEpics.createAllocatedWPEpic,    
    TrialPhaseEpics.getTrialPhasesEpic,
    TrialPhaseEpics.getTrialPhaseByIdEpic,
    TrialPhaseEpics.setTrialPhaseEpic,
    TrialPhaseEpics.createTrialPhaseEpic,    
    InvolvementEpics.getInvolvementsEpic,
    InvolvementEpics.getInvolvementByIdEpic,
    InvolvementEpics.setInvolvementEpic,
    InvolvementEpics.createInvolvementEpic,  
    TrialPresentationTypeEpics.getTrialPresentationTypesEpic,
    TrialPresentationTypeEpics.getTrialPresentationTypeByIdEpic,
    TrialPresentationTypeEpics.setTrialPresentationTypeEpic,
    TrialPresentationTypeEpics.createTrialPresentationTypeEpic,
    TrialPublicationTypeEpics.getTrialPublicationTypesEpic,
    TrialPublicationTypeEpics.getTrialPublicationTypeByIdEpic,
    TrialPublicationTypeEpics.setTrialPublicationTypeEpic,
    TrialPublicationTypeEpics.createTrialPublicationTypeEpic,
    TrialAnalysisTypeEpics.getTrialAnalysisTypesEpic,
    TrialAnalysisTypeEpics.getTrialAnalysisTypeByIdEpic,
    TrialAnalysisTypeEpics.setTrialAnalysisTypeEpic,
    TrialAnalysisTypeEpics.createTrialAnalysisTypeEpic,
    TrialCoordCentreEpics.getTrialCoordCentresEpic,
    TrialCoordCentreEpics.getTrialCoordCentreByIdEpic,
    TrialCoordCentreEpics.setTrialCoordCentreEpic,
    TrialCoordCentreEpics.createTrialCoordCentreEpic,    
    CollaboratingGroupEpics.getCollaboratingGroupsEpic,
    CollaboratingGroupEpics.getCollaboratingGroupByIdEpic,
    CollaboratingGroupEpics.setCollaboratingGroupEpic,
    CollaboratingGroupEpics.createCollaboratingGroupEpic,    
    GenderEpics.getGendersEpic,
    GenderEpics.getGenderByIdEpic,
    GenderEpics.setGenderEpic,
    GenderEpics.createGenderEpic,    
    MembershipTypeEpics.getMembershipTypesEpic,
    MembershipTypeEpics.getMembershipTypeByIdEpic,
    MembershipTypeEpics.setMembershipTypeEpic,
    MembershipTypeEpics.createMembershipTypeEpic,    
    ProfessionalDisciplineEpics.getProfessionalDisciplinesEpic,
    ProfessionalDisciplineEpics.getProfessionalDisciplineByIdEpic,
    ProfessionalDisciplineEpics.setProfessionalDisciplineEpic,
    ProfessionalDisciplineEpics.createProfessionalDisciplineEpic,    
    InterestEpics.getInterestsEpic,
    InterestEpics.getInterestByIdEpic,
    InterestEpics.setInterestEpic,
    InterestEpics.createInterestEpic,
    SalutationEpics.getSalutationsEpic,
    SalutationEpics.getSalutationByIdEpic,
    SalutationEpics.setSalutationEpic,
    SalutationEpics.createSalutationEpic,
    FormTemplateEpics.getFormTemplatesEpic,
    FormTemplateEpics.getFormTemplateByIdEpic,
    FormTemplateEpics.setFormTemplateEpic,
    FormTemplateEpics.createFormTemplateEpic,
    TemplateTrialItemsEpics.getTemplateTrialItemsEpic,
    TemplateTrialItemsEpics.getTemplateTrialItemsByCategoryEpic,
    TemplateTrialItemsEpics.getTemplateTrialItemByIdEpic,
    TemplateTrialItemsEpics.setTemplateTrialItemEpic,
    TemplateTrialItemsEpics.createTemplateTrialItemEpic,
    PatientEpics.getPatientsEpic,
    PatientEpics.postPatientsImportEpic,
    PatientEpics.getPatientByIdEpic,
    PatientEpics.createPatientEpic,
    PatientEpics.setPatientEpic,
    InstitutionFormEpics.getInstitutionFormsEpic,
    InstitutionFormEpics.getInstitutionFormCurrentUserEpic,
    InstitutionFormEpics.getInstitutionFormByIdEpic,
    InstitutionFormEpics.setInstitutionFormEpic,
    InstitutionFormEpics.createInstitutionFormEpic,
    InstitutionFormEpics.createVersionEpic,
    TrialFormEpics.getTrialFormsEpic,
    TrialFormEpics.getTrialFormCurrentUserEpic,
    TrialFormEpics.getTrialFormByIdEpic,
    TrialFormEpics.setTrialFormEpic,
    TrialFormEpics.createTrialFormEpic,
    TrialFormEpics.createVersionEpic,
    TrialFormEpics.getTrialFormReportEpic,
    TrialFormEpics.setTrialFormReportJustifiedEpic,
    TrialFormEpics.setTrialFormSendReminderEmailEpic,
    RoleEpics.getRolesEpic,
    RoleEpics.getRoleByIdEpic,
    RoleEpics.setRoleEpic,
    RoleEpics.createRoleEpic,
    ReportingTagCategoryEpics.getReportingTagCategorysEpic,
    ReportingTagCategoryEpics.getReportingTagCategoryByIdEpic,
    ReportingTagCategoryEpics.setReportingTagCategoryEpic,
    ReportingTagCategoryEpics.createReportingTagCategoryEpic,
    ReportingTagEpics.getReportingTagsEpic,
    ReportingTagEpics.getReportingTagByIdEpic,
    ReportingTagEpics.setReportingTagEpic,
    ReportingTagEpics.createReportingTagEpic,
    TaskTypeEpics.getTaskTypesEpic,
    TaskTypeEpics.getTaskTypeByIdEpic,
    TaskTypeEpics.setTaskTypeEpic,
    TaskTypeEpics.createTaskTypeEpic,
    FormTemplateFileTypeEpics.getFormTemplateFileTypesEpic,
    FormTemplateFileTypeEpics.getFormTemplateFileTypeByIdEpic,
    FormTemplateFileTypeEpics.setFormTemplateFileTypeEpic,
    FormTemplateFileTypeEpics.createFormTemplateFileTypeEpic,
    TrackerTypeEpics.getTrackerTypesEpic,
    TrackerTypeEpics.getTrackerTypeByIdEpic,
    TrackerTypeEpics.setTrackerTypeEpic,
    TrackerTypeEpics.createTrackerTypeEpic,
    TrackerMilestoneEpics.getTrackerMilestonesEpic,
    TrackerMilestoneEpics.getTrackerMilestoneByIdEpic,
    TrackerMilestoneEpics.setTrackerMilestoneEpic,
    TrackerMilestoneEpics.createTrackerMilestoneEpic,
    TrialTrackerEpics.getTrialTrackersEpic,
    TrialTrackerEpics.getTrialTrackersByIdEpic,
    TrialTrackerEpics.createTrialTrackerEpic,
    TrialTrackerEpics.setTrialTrackerEpic,
    StandardEmailTemplateEpics.getStandardEmailTemplatesEpic,
    StandardEmailTemplateEpics.getStandardEmailTemplateByIdEpic,
    StandardEmailTemplateEpics.setStandardEmailTemplateEpic,
    StandardEmailTemplateEpics.createStandardEmailTemplateEpic,
    TrialInstitutionItemTemplateEpics.getTrialInstitutionItemTemplatesEpic,
    TrialInstitutionItemTemplateEpics.getTrialInstitutionItemTemplateByIdEpic,
    TrialInstitutionItemTemplateEpics.setTrialInstitutionItemTemplateEpic,
    TrialInstitutionItemTemplateEpics.createTrialInstitutionItemTemplateEpic,
);