import * as React from 'react';

import * as classNames from "classnames";
import './TrialAccrualsPage.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';



import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";

import * as TrialActions from '../../../actions/trial';

import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../../components/form';
import { TrialSubMenu } from '../../../helpers/subMenuHelper';
import { TrialLayout } from '../../index';

interface ITrialAccrualsPageParams {
    trialId: number;
}

interface ITrialAccrualsPageProps {

    location: Location;
    trialId: number;
    match: match<ITrialAccrualsPageParams>;
    history: History;

    // From state
    trial: Dtos.Trial;
    trialRoles: Dtos.RoleType[];
    loadingTrial: boolean;
    loadTrialSuccess: boolean;
    loadTrialFailure: boolean;

    user: Dtos.User
}

interface ITrialAccrualsPageActions {
    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type TrialAccrualsPageProps = ITrialAccrualsPageProps & ITrialAccrualsPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class TrialAccrualsPage extends React.PureComponent<TrialAccrualsPageProps, any> {

    constructor(props: TrialAccrualsPageProps) {
        super(props);

        this.clearTrial = this.clearTrial.bind(this);
    }

    componentDidMount() {
        const {
            loadTrial,
            navigate,
            navigateReplace,
            trialId
        } = this.props;

        loadTrial(trialId);
    }

    componentWillReceiveProps(nextProps: TrialAccrualsPageProps) {
    }

    componentDidUpdate(prevProps: TrialAccrualsPageProps, prevState) {
        const {
            navigateReplace,
            loadTrial
        } = this.props
    }

    componentWillUpdate(nextProps: TrialAccrualsPageProps) {
    }

    componentWillUnmount() {
        this.clearTrial();
    }

    clearTrial() {
        this.props.clearTrial();
    }

    showTrial(): boolean {
        const {
            loadingTrial,
            loadTrialSuccess
        } = this.props

        if (!loadingTrial && loadTrialSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrial
        } = this.props

        return (
            <TrialLayout loading={loadingTrial} permission={Dtos.Permission.TrialView }>{this.renderContent()}</TrialLayout>
        );
    }

    renderContent() {
        const {
            trial,
            loadingTrial,
            loadTrialSuccess,
            loadTrialFailure,
            clearTrial,
            navigate,
            user
        } = this.props;

        return <div>
            <h1>Trial Accruals</h1>
            {
                !this.showTrial() ?
                    "Loading Trial Accruals" :
                    null
            }
            {
                this.showTrial() ?
                    <div className="mt-2">
                        <p>[TBD] Trial accruals management</p>
                    </div> :
                    null
            }
        </div>
    }

}


const mapStateToProps = (state: ITmdState, ownProps: TrialAccrualsPageProps): ITrialAccrualsPageProps => {

    let trial: Dtos.Trial | undefined = !(state.trials.formData instanceof Array) ? state.trials.formData : undefined;

    return {

        match: ownProps.match,
        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trial: trial!,
        trialRoles: state.trials.formRoles,

        loadingTrial: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,
        loadTrialSuccess: state.trials.loadState && state.trials.loadState.status == RequestState.Success,
        loadTrialFailure: state.trials.loadState && state.trials.loadState.status == RequestState.Failure,

        user: state.user.data
    };
};

const mapDispatchToProps = (dispatch): ITrialAccrualsPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialAccrualsPage);
