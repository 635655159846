import * as React from 'react';

import * as classNames from "classnames";
import './InstitutionDepartmentsPage.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';



import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";

import * as InstitutionActions from '../../../actions/institution';
import * as ToggleActions from '../../../actions/pages/togglePage';
import * as ToggleHelper from "../../../helpers/toggleHelper";
import { TogglePageItem } from '../../../reducers/pages/togglePage';

import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../../components/form';
import { SettingsSubMenu, InstitutionSubMenu } from '../../../helpers/subMenuHelper';

interface IInstitutionDepartmentPageParams {
    institutionId: number;
}

interface IInstitutionDepartmentPageProps {

    location: Location;
    institutionId: number;
    match: match<IInstitutionDepartmentPageParams>;
    history: History;

    // From state
    institution: Dtos.Institution;
    departments: Dtos.Department[];

    loadingInstitution: boolean;
    loadInstitutionSuccess: boolean;
    loadInstitutionFailure: boolean;

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];
    toggleItems: TogglePageItem[]
}

interface IInstitutionDepartmentPageActions {
    clearInstitution: InstitutionActions.IInstitutionClearActionCreator;
    loadInstitution: InstitutionActions.IInstitutionLoadByIdActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    toggleExpand: ToggleActions.IToggleExpandedStateActionCreator;
}

type InstitutionDepartmentPageProps = IInstitutionDepartmentPageProps & IInstitutionDepartmentPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class InstitutionDepartmentPage extends React.PureComponent<InstitutionDepartmentPageProps, any> {

    constructor(props: InstitutionDepartmentPageProps) {
        super(props);

        this.clearInstitution = this.clearInstitution.bind(this);
    }

    componentDidMount() {
        const {
            loadInstitution,
            navigate,
            navigateReplace,
            institutionId
        } = this.props;

        loadInstitution(institutionId);
    }

    componentWillReceiveProps(nextProps: InstitutionDepartmentPageProps) {
    }

    componentDidUpdate(prevProps: InstitutionDepartmentPageProps, prevState) {
        const {
            navigateReplace
        } = this.props
    }

    componentWillUpdate(nextProps: InstitutionDepartmentPageProps) {
    }

    componentWillUnmount() {
        this.clearInstitution();
    }

    clearInstitution() {
        this.props.clearInstitution();
    }

    showInstitution(): boolean {
        const {
            loadingInstitution,
            loadInstitutionSuccess
        } = this.props

        if (!loadingInstitution && loadInstitutionSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingInstitution,
            permissions,
            institution
        } = this.props;

        let id = institution ? institution.id : 0;

        return <RestrictedLayout
            permission={Dtos.Permission.InstitutionDepartmentView}
            subMenuTitle={institution ? institution.display : ""}
            subMenuItems={InstitutionSubMenu(id, permissions)}
            loading={loadingInstitution}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            institution,
            loadingInstitution,
            loadInstitutionSuccess,
            loadInstitutionFailure,
            clearInstitution,
            permissions,
            user,
            institutionId
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-9 mb-2"}>
                    <h2>Institution Departments</h2>
                </div>
                <div className="col-3" style={{ textAlign: 'right' }}>
                    {(AuthenticationHelper.hasPermission(permissions, Dtos.Permission.InstitutionDepartmentCreate, null, institutionId) && institution && institution.id > 0) && (
                        <>
                            <Link className={"btn btn-primary"} url={"/institutions/" + institution.id + '/departments/create'}>Create</Link>
                        </>
                    )}
                </div>
            </div>
            {
                loadingInstitution ?
                    "Loading Departments" :
                    null
            }
            {
                !loadingInstitution ?
                    <div className="mt-2">
                        {this.renderTable()}
                    </div> :
                    null
            }
        </div>
    }


    renderTable() {
        const {
            institution,
            departments,
            toggleItems,
            toggleExpand
        } = this.props;


        if (!departments || departments.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no departments to view.</span>
            </div>
        }

        const showPagination = departments.length > DEFAULT_PAGE_SIZE;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={departments}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : departments.length}
                pageSize={showPagination ? undefined : departments.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no departments to display"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            const url: string = "/institutions/" + rowInfo.original.institutionId + "/departments/" + rowInfo.original.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.LOOKUP} fixedWidth />
                    },
                    {
                        id: "name",
                        Header: 'Name',
                        accessor: 'name',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    },
                ]}
            />
        </div>
    }

    
}


const mapStateToProps = (state: ITmdState, ownProps: InstitutionDepartmentPageProps): IInstitutionDepartmentPageProps => {

    let institution: Dtos.Institution | undefined = !(state.institutions.formData instanceof Array) ? state.institutions.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        institutionId: ownProps.match ? ownProps.match.params.institutionId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        institution: institution!,
        departments: institution ? institution.departments : undefined,

        loadingInstitution: state.institutions.loadState && state.institutions.loadState.status == RequestState.Pending,
        loadInstitutionSuccess: state.institutions.loadState && state.institutions.loadState.status == RequestState.Success,
        loadInstitutionFailure: state.institutions.loadState && state.institutions.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        toggleItems: state.togglePage.items
    };
};

const mapDispatchToProps = (dispatch): IInstitutionDepartmentPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadInstitution: bindActionCreators(InstitutionActions.LoadInstitutionById, dispatch),
        clearInstitution: bindActionCreators(InstitutionActions.Clear, dispatch),

        toggleExpand: bindActionCreators(ToggleActions.ToggleExpanded, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(InstitutionDepartmentPage);
