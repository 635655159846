import * as React from 'react';
import './RolesPage.scss';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import * as ReactTooltip from 'react-tooltip';
import * as RoleActions from '../../../actions/role';
import * as PagingActions from '../../../actions/pages/pagingPage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { TextAreaFormInput, FormFilterInput } from '../../../components/form';
import { LookupsLayout } from '../../index';

interface IRolesPageProps {

    location: Location;
    history: History;

    // From state
    roles: Dtos.Role[];
    formFilter: IFormFilter;
    formLength: number;

    loadingRoles: boolean;
    loadRolesSuccess: boolean;
    loadRolesFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    formRoles?: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    modules: Dtos.ModulePermission[];

    pageSize: number;
}

interface IRolesPageActions {
    clearRoles: RoleActions.IRoleClearActionCreator;
    loadRoles: RoleActions.IRoleLoadActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    filter: RoleActions.IRoleFilterActionCreator;

    setPageSize: PagingActions.IPagingPageSizeChangedStateActionCreator;
}

type RolesPageProps = IRolesPageProps & IRolesPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}

class RolesPage extends React.PureComponent<RolesPageProps, any> {

    constructor(props: RolesPageProps) {
        super(props);

        this.clearRoles = this.clearRoles.bind(this);
    }

    componentDidMount() {
        const {
            loadRoles
        } = this.props;

        loadRoles();
    }

    componentWillReceiveProps(nextProps: RolesPageProps) {
    }

    componentDidUpdate(prevProps: RolesPageProps, prevState) {
        const {
            navigateReplace
        } = this.props
    }

    componentWillUpdate(nextProps: RolesPageProps) {
    }

    componentWillUnmount() {
        this.clearRoles();
    }

    clearRoles() {
        this.props.clearRoles();
    }

    showRoles(): boolean {
        const {
            loadingRoles,
            loadRolesSuccess
        } = this.props

        if (!loadingRoles && loadRolesSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingRoles
        } = this.props;

        return <LookupsLayout
            loading={loadingRoles}
            permission={Dtos.Permission.RolesView}
        >
            {this.renderContent()}
        </LookupsLayout>;
    }

    renderContent() {
        const {
            permissions
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-6 mb-2"}>
                    <h2>Roles</h2>
                </div>
                <div className="col-6" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} />
                    {AuthenticationHelper.hasPermission(permissions, Dtos.Permission.RolesCreate) && (
                        <Link className={"btn btn-primary"} url={"/settings/general/roles/create"}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.showRoles() ?
                    "Loading Roles" :
                    null
            }
            {
                this.showRoles() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {

        const {
            roles,
            pageSize,
            setPageSize,
            lookups,
            modules
        } = this.props

        if (!roles || roles.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no roles to view.</span>
            </div>
        }

        const showPagination = roles.length > pageSize;

        let columns: any[] = [];
        columns.push({
            id: "id",
            Header: ' ',
            accessor: "id",
            className: "d-flex align-items-center justify-content-center",
            maxWidth: 50,
            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.LOOKUP} fixedWidth />
        });
        columns.push({
            id: "value",
            Header: 'Name',
            accessor: 'value',
            className: "d-flex align-items-left justify-content-left",
            headerClassName: "text-left",
            minWidth: 220,
        });
        if (modules && modules.length > 0) {
            modules.forEach(module => {
                columns.push({
                    id: module.name,
                    Header: module.name,
                    accessor: module.name,
                    className: "d-flex align-items-center justify-content-center",
                    headerClassName: "text-center",
                    minWidth: 150,
                    Cell: (props) => <>
                        <span
                            data-for={props.original.id + "-" + props.column.id + "MarkToolTip"}
                            data-tip={this.getPermissions(props).replaceAll(",", ",<br />")}
                            className="truncate">{this.getPermissions(props) == "" ? "" : <FontAwesomeIcon icon={FontAwesomeIcons.Light.CHECK} fixedWidth />}</span>
                        <ReactTooltip
                            id={props.original.id + "-" + props.column.id + "MarkToolTip"}
                            multiline={true}
                            data-html={true}
                            insecure={true} /></>
                });
            });
        }

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={roles}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? pageSize : roles.length}
                pageSize={showPagination ? pageSize : roles.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no roles to display"
                resizable={false}
                onPageSizeChange={val => setPageSize(val)}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {

                            const url: string = "/settings/general/roles/" + rowInfo.original.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        }
                    }
                }}
                columns={columns}
            />
        </div>

    }

    getPermissions(props) {
        return props.original.modules[props.column.id] || "";
    }

}

const mapStateToProps = (state: ITmdState, ownProps: RolesPageProps): IRolesPageProps => {

    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        history: ownProps.history,
        location: state.routing.location,

        roles: state.roles.formFilteredData!,
        formFilter: state.roles.formFilter,
        formLength: state.roles.formLength,

        loadingRoles: state.roles.loadState && state.roles.loadState.status == RequestState.Pending,
        loadRolesSuccess: state.roles.loadState && state.roles.loadState.status == RequestState.Success,
        loadRolesFailure: state.roles.loadState && state.roles.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles,
        lookups: state.roles.lookups,
        modules: state.roles.modules,

        pageSize: state.pagingPage.pageSize
    };
};

const mapDispatchToProps = (dispatch): IRolesPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadRoles: bindActionCreators(RoleActions.LoadRole, dispatch),
        clearRoles: bindActionCreators(RoleActions.Clear, dispatch),

        filter: bindActionCreators(RoleActions.Filter, dispatch),

        setPageSize: bindActionCreators(PagingActions.PageSizeChanged, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(RolesPage);
