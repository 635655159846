import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const collectionInstancesApi = {
    getCollectionInstanceById: (id: number): Observable<Dtos.GetCollectionInstanceByIdResponse> => {
        //Create the request.
        let request: Dtos.GetCollectionInstanceById = new Dtos.GetCollectionInstanceById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetCollectionInstanceByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setCollectionInstance: (collectionInstance: Dtos.CollectionInstance): Observable<Dtos.SetCollectionInstanceResponse> => {

        let request: Dtos.SetCollectionInstance = new Dtos.SetCollectionInstance();

        request.collectionInstance = collectionInstance;

        // send request
        const response: Promise<Dtos.SetCollectionInstanceResponse> = client.post(request);

        return Observable.from(response);
    }
}