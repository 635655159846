import * as React from 'react';
import './TemplateTrialItemPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { ITemplateTrialItemReduxFormState, initialTemplateTrialItemState } from "../../../reducers/reactReduxForms/templateTrialItems";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as TemplateTrialItemActions from '../../../actions/templateTrialItems';
import * as CollectionActions from '../../../actions/collection';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert, Placeholder } from "../../../components/common/index";
import { Form, actions, Control } from 'react-redux-form';
import { ItemSettingsLayout } from '../../index';
import * as AuthenticationHelper from '../../../helpers/authentication';

import {
    CollectionPage
} from "../../../containers/index";

interface ITemplateTrialItemPageParams {
    templateTrialItemId: number;
}

interface ITemplateTrialItemPageProps {

    form: Dtos.TemplateTrialItem & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITemplateTrialItemReduxFormState;

    collectionFormState: Dtos.Collection & IRegistrationFormState;

    location: Location;
    templateTrialItemId: number;
    match: match<ITemplateTrialItemPageParams>;
    history: History;

    // From state
    templateTrialItem: Dtos.TemplateTrialItem;
    collection: Dtos.Collection;
    lookups: Dtos.Lookup[];

    loadingTemplateTrialItem: boolean;
    loadTemplateTrialItemSuccess: boolean;
    loadTemplateTrialItemFailure: boolean;

    savingTemplateTrialItem: boolean;
    saveTemplateTrialItemSuccess: boolean;
    saveTemplateTrialItemFailure: boolean;

    creatingTemplateTrialItem: boolean;
    createTemplateTrialItemSuccess: boolean;
    createTemplateTrialItemFailure: boolean;

    validationFailures: Dtos.ResponseError[];
    permissions?: Dtos.PersonnelTmdPermissions[];
    user: Dtos.User
}

interface ITemplateTrialItemPageActions {
    clearTemplateTrialItem: TemplateTrialItemActions.ITemplateTrialItemClearActionCreator;
    loadTemplateTrialItem: TemplateTrialItemActions.ITemplateTrialItemLoadByIdActionCreator;

    saveTemplateTrialItem: TemplateTrialItemActions.ITemplateTrialItemSaveActionCreator;
    createTemplateTrialItem: TemplateTrialItemActions.ITemplateTrialItemCreateActionCreator;

    loadCollection: CollectionActions.ICollectionLoadByIdActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type TemplateTrialItemPageProps = ITemplateTrialItemPageProps & ITemplateTrialItemPageActions;

const reduxFormName: string = "reduxForms.templateTrialItem";

class TemplateTrialItemPage extends React.PureComponent<TemplateTrialItemPageProps, any> {

    constructor(props: TemplateTrialItemPageProps) {
        super(props);

        this.clearTemplateTrialItem = this.clearTemplateTrialItem.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTemplateTrialItem,
            templateTrialItemId,
            createTemplateTrialItem
        } = this.props;

        if (templateTrialItemId) {
            loadTemplateTrialItem(templateTrialItemId);
        } else if (!templateTrialItemId) {
            createTemplateTrialItem();
        }
    }

    componentDidUpdate(prevProps: TemplateTrialItemPageProps, prevState) {
        const {
            templateTrialItemId,
            templateTrialItem,
            form,
            navigate,
            loadTemplateTrialItem,
            loadingTemplateTrialItem,
            loadTemplateTrialItemSuccess,
            createTemplateTrialItem,
            creatingTemplateTrialItem,
            createTemplateTrialItemSuccess,
            savingTemplateTrialItem,
            saveTemplateTrialItemSuccess,
            saveTemplateTrialItemFailure,
            validationFailures,
            loadCollection
        } = this.props;

        if (templateTrialItemId && prevProps.templateTrialItemId != templateTrialItemId) {
            loadTemplateTrialItem(templateTrialItemId);
        }
        else if (!templateTrialItemId && prevProps.templateTrialItemId) {
            createTemplateTrialItem();
        }

        if (!loadingTemplateTrialItem && prevProps.loadingTemplateTrialItem) {
            if (loadTemplateTrialItemSuccess) {
                this.loadForm();
            }
        }

        if (!savingTemplateTrialItem && prevProps.savingTemplateTrialItem) {
            if (saveTemplateTrialItemSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/settings/item/trialitemtemplates")
                } else if (!form.saveAndReturn && templateTrialItemId && templateTrialItemId > 0) {
                    this.loadForm();
                    if (form.collectionId !== undefined && form.collectionId > 0) {
                        loadCollection(form.collectionId);
                    }
                } else if (!form.saveAndReturn && !templateTrialItemId && templateTrialItem && templateTrialItem.id > 0) {
                    navigate("/settings/item/trialitemtemplates/" + templateTrialItem.id);
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingTemplateTrialItem && prevProps.creatingTemplateTrialItem) {
            if (createTemplateTrialItemSuccess) {
                this.loadForm();
            }
        }

    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearTemplateTrialItem();
        loadForm(reduxFormName, initialTemplateTrialItemState);
        resetForm(reduxFormName);
    }

    clearTemplateTrialItem() {
        this.props.clearTemplateTrialItem();
    }

    showTemplateTrialItem(): boolean {
        const {
            loadingTemplateTrialItem,
            loadTemplateTrialItemSuccess
        } = this.props

        if (!loadingTemplateTrialItem && loadTemplateTrialItemSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingTemplateTrialItem
        } = this.props;

        return <ItemSettingsLayout loading={loadingTemplateTrialItem} permission={Dtos.Permission.TemplateTrialItemsView}>
            {this.renderContent()}
        </ItemSettingsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            loadingTemplateTrialItem,
            savingTemplateTrialItem,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.TemplateTrialItemsUpdate, Dtos.Permission.TemplateTrialItemsCreate);
        const formDisabled: boolean = !hasPermission || savingTemplateTrialItem || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            name: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            categoryId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("TemplateTrialItem", "CategoryId", lookups))
                },
                changeAction: (model, value, form, parentModel) => {
                    changeForm(model, value);
                    changeForm(reduxFormName + ".category", null);
                },
            },
            description: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            /*templateTrialItemGroups: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    multi: true,
                    value: form.templateTrialItemGroups ? form.templateTrialItemGroups.filter(f => f.active).map(val => val.groupId) : [],
                    options: generateOptionsFromLookup(findLookup("TemplateTrialItem", "TemplateTrialItemGroups", lookups)),
                },
                changeAction: (model, value, form, parentModel) => {
                    changeForm(model, value.map(v => { return { active: 1, groupId: v }; }));
                    console.log('changeAction', model, value, form, parentModel);
                },
            },*/
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            collectionGroup: {
                type: RegistrationComponent.RegistrationElement,
                component: this.renderCollection()
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            },
            allowIntraVersions: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Allow Intra Version"
                },
                inputLabel: " ",
            },
            initialVersion: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            }
        };

        return <div>
            <h1>Trial Item Template</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "templateTrialItem", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
            

        </div>

        /*
        <Form model={"reduxForms.collection"}>
                {form && !loadingTemplateTrialItem && (
                    <CollectionPage
                        qaItemComponents={false}
                        itemComponentTypeId={0}
                        showSections={false}
                        readonly={formDisabled}
                        collectionId={form.collectionId || 0} />
                )}
            </Form>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
        */
    }

    renderCollection() {
        const {
            form,
            formState,
            loadingTemplateTrialItem,
            savingTemplateTrialItem,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.TemplateTrialItemsUpdate, Dtos.Permission.TemplateTrialItemsCreate);
        const formDisabled: boolean = !hasPermission || savingTemplateTrialItem || (formState && formState.formStatus == Dtos.FormStatus.Disabled);


        if (form && !loadingTemplateTrialItem) {
            return <CollectionPage
                qaItemComponents={false}
                itemComponentTypeId={0}
                showSections={false}
                readonly={formDisabled}
                collectionId={form.collectionId || 0} />
        }
    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
        </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.TemplateTrialItem) {
        const {
            saveTemplateTrialItem,
            collection
        } = this.props;

        if (data) {
            let changes: Dtos.TemplateTrialItem = {} as Dtos.TemplateTrialItem;
            let collectionChanges: Dtos.Collection = {} as Dtos.Collection;
            saveTemplateTrialItem(Object.assign({ ...data }, changes), Object.assign({ ...collection }, collectionChanges));
        }
    }

    handleOnSubmitFailed(data: Dtos.TemplateTrialItem) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/item/trialitemtemplates");
    }

    loadForm() {
        const {
            loadForm,
            templateTrialItem,
        } = this.props

        let extraData: Dtos.TemplateTrialItem = {} as Dtos.TemplateTrialItem;

        loadForm(reduxFormName, Object.assign({ ...templateTrialItem }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: TemplateTrialItemPageProps): ITemplateTrialItemPageProps => {

    let templateTrialItem: Dtos.TemplateTrialItem | undefined = !(state.templateTrialItems.formData instanceof Array) ? state.templateTrialItems.formData : undefined;
    let collection: Dtos.Collection | undefined = !(state.reduxForms.collection instanceof Array) ? state.reduxForms.collection : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        templateTrialItemId: ownProps.match && ownProps.match.params.templateTrialItemId && ownProps.match.params.templateTrialItemId.toString() != "create" ? ownProps.match.params.templateTrialItemId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        templateTrialItem: templateTrialItem!,
        collection: collection!,

        loadingTemplateTrialItem: state.templateTrialItems.loadState && state.templateTrialItems.loadState.status == RequestState.Pending,
        loadTemplateTrialItemSuccess: state.templateTrialItems.loadState && state.templateTrialItems.loadState.status == RequestState.Success,
        loadTemplateTrialItemFailure: state.templateTrialItems.loadState && state.templateTrialItems.loadState.status == RequestState.Failure,

        savingTemplateTrialItem: state.templateTrialItems.saveState && state.templateTrialItems.saveState.status == RequestState.Pending,
        saveTemplateTrialItemSuccess: state.templateTrialItems.saveState && state.templateTrialItems.saveState.status == RequestState.Success,
        saveTemplateTrialItemFailure: state.templateTrialItems.saveState && state.templateTrialItems.saveState.status == RequestState.Failure,

        creatingTemplateTrialItem: state.templateTrialItems.createState && state.templateTrialItems.createState.status == RequestState.Pending,
        createTemplateTrialItemSuccess: state.templateTrialItems.createState && state.templateTrialItems.createState.status == RequestState.Success,
        createTemplateTrialItemFailure: state.templateTrialItems.createState && state.templateTrialItems.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: state.templateTrialItems.lookups,

        form: state.reduxForms.templateTrialItem,
        formState: state.templateTrialItems.formState,
        formProperties: state.templateTrialItems.formProperties,

        collectionFormState: state.reduxForms.collection,

        reduxFormState: state.reduxForms.formState.templateTrialItem,
        validationFailures: templateTrialItem ?
            state.templateTrialItems.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): ITemplateTrialItemPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadTemplateTrialItem: bindActionCreators(TemplateTrialItemActions.LoadTemplateTrialItemById, dispatch),
        clearTemplateTrialItem: bindActionCreators(TemplateTrialItemActions.Clear, dispatch),

        loadCollection: bindActionCreators(CollectionActions.LoadCollectionById, dispatch),

        saveTemplateTrialItem: bindActionCreators(TemplateTrialItemActions.SaveTemplateTrialItem, dispatch),
        createTemplateTrialItem: bindActionCreators(TemplateTrialItemActions.CreateTemplateTrialItem, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TemplateTrialItemPage);
