import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialCollectionSectionState: Dtos.CollectionSection = {
    id: null,
    collectionId: null,
    name: null,
    rank: null,
    isNaAllowed: null,
    canAssign: null,
    active: true,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
}

export const initialCollectionItemComponentState: Dtos.CollectionItemComponent = {
    id: null,
    collectionId: null,
    itemComponentId: null,
    itemComponent: null,
    collectionSectionId: null,
    collectionSection: null,
    rank: 1,
    column: 1,
    active: true,
    isNaAllowed: false,
    isRequired: false,
    instructions: null,
    overrideLabel: null,
    reportingTagId: null,
    reportingTag: null,
    baselineAmount: 0,
    minValue: null,
    maxValue: null,
    calculationMethod: 1,
    calculation1CollectionItemComponentId: null,
    calculation2CollectionItemComponentId: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    isLocked: null
}

export const initialCollectionState: Dtos.Collection & IRegistrationFormState = {
    id: null,
    collectionItemComponents: [],
    collectionSections: [],
    active: null,
    locked: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false
}

export interface ICollectionReduxFormState {
    id: IFieldState<number>,
    collectionItemComponents: IFieldState<Dtos.CollectionItemComponent[]>,
    collectionSections: IFieldState<Dtos.CollectionSection[]>,
    active: IFieldState<boolean>,
    locked: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>
}
