import * as React from 'react';
import './InstitutionCodingPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { IInstitutionCodingReduxFormState, initialInstitutionCodingState } from "../../../reducers/reactReduxForms/institutionCoding";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as InstitutionCodingActions from '../../../actions/institutionCoding';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { Form, actions, Control } from 'react-redux-form';
import { InstitutionLookupsLayout } from '../../index';

interface IInstitutionCodingPageParams {
    institutionCodingId: number;
}

interface IInstitutionCodingPageProps {

    form: Dtos.InstitutionCoding & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IInstitutionCodingReduxFormState;

    location: Location;
    institutionCodingId: number;
    match: match<IInstitutionCodingPageParams>;
    history: History;

    // From state
    institutionCoding: Dtos.InstitutionCoding;
    lookups: Dtos.Lookup[];

    loadingInstitutionCoding: boolean;
    loadInstitutionCodingSuccess: boolean;
    loadInstitutionCodingFailure: boolean;

    savingInstitutionCoding: boolean;
    saveInstitutionCodingSuccess: boolean;
    saveInstitutionCodingFailure: boolean;

    creatingInstitutionCoding: boolean;
    createInstitutionCodingSuccess: boolean;
    createInstitutionCodingFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface IInstitutionCodingPageActions {
    clearInstitutionCoding: InstitutionCodingActions.IInstitutionCodingClearActionCreator;
    loadInstitutionCoding: InstitutionCodingActions.IInstitutionCodingLoadByIdActionCreator;

    saveInstitutionCoding: InstitutionCodingActions.IInstitutionCodingSaveActionCreator;
    createInstitutionCoding: InstitutionCodingActions.IInstitutionCodingCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type InstitutionCodingPageProps = IInstitutionCodingPageProps & IInstitutionCodingPageActions;


const formName: string = "institutionCoding";
const reduxFormName: string = "reduxForms.institutionCoding";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class InstitutionCodingPage extends React.PureComponent<InstitutionCodingPageProps, any> {

    constructor(props: InstitutionCodingPageProps) {
        super(props);

        this.clearInstitutionCoding = this.clearInstitutionCoding.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadInstitutionCoding,
            navigate,
            navigateReplace,
            institutionCodingId,
            createInstitutionCoding
        } = this.props;

        if (institutionCodingId) {
            loadInstitutionCoding(institutionCodingId);
        } else if (!institutionCodingId) {
            createInstitutionCoding();
        }
    }

    componentWillReceiveProps(nextProps: InstitutionCodingPageProps) {
    }

    componentDidUpdate(prevProps: InstitutionCodingPageProps, prevState) {
        const {
            navigateReplace,
            institutionCodingId,
            institutionCoding,
            form,
            navigate,
            loadInstitutionCoding,
            loadingInstitutionCoding,
            loadInstitutionCodingSuccess,
            createInstitutionCoding,
            creatingInstitutionCoding,
            createInstitutionCodingSuccess,
            savingInstitutionCoding,
            saveInstitutionCodingSuccess,
            saveInstitutionCodingFailure,
            validationFailures
        } = this.props;

        if (institutionCodingId && prevProps.institutionCodingId != institutionCodingId) {
            loadInstitutionCoding(institutionCodingId);
        }
        else if (!institutionCodingId && prevProps.institutionCodingId) {
            createInstitutionCoding();
        }

        if (!loadingInstitutionCoding && prevProps.loadingInstitutionCoding) {
            if (loadInstitutionCodingSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingInstitutionCoding && prevProps.savingInstitutionCoding) {
            if (saveInstitutionCodingSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/settings/institution/institutioncodings")
                } else if (!form.saveAndReturn && institutionCodingId && institutionCodingId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !institutionCodingId && institutionCoding && institutionCoding.id > 0) {
                    navigate("/settings/institution/institutioncodings/" + institutionCoding.id);
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingInstitutionCoding && prevProps.creatingInstitutionCoding) {
            if (createInstitutionCodingSuccess) {
                this.loadForm();
            }
            else {

            }
        }

    }

    componentWillUpdate(nextProps: InstitutionCodingPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearInstitutionCoding();
        loadForm(reduxFormName, initialInstitutionCodingState);
        resetForm(reduxFormName);
    }

    clearInstitutionCoding() {
        this.props.clearInstitutionCoding();
    }

    showInstitutionCoding(): boolean {
        const {
            loadingInstitutionCoding,
            loadInstitutionCodingSuccess
        } = this.props

        if (!loadingInstitutionCoding && loadInstitutionCodingSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingInstitutionCoding
        } = this.props;

        return <InstitutionLookupsLayout loading={loadingInstitutionCoding} permission={Dtos.Permission.InstitutionCodingView}>
            {this.renderContent()}
        </InstitutionLookupsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingInstitutionCoding,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.InstitutionCodingUpdate, Dtos.Permission.InstitutionCodingCreate);
        const formDisabled: boolean = !hasPermission || savingInstitutionCoding || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            value: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            }
        };

        

        return <div>
            <h1>Institution Coding</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "institutionCoding", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.InstitutionCoding) {
        const {
            saveInstitutionCoding
        } = this.props;

        if (data) {
            let changes: Dtos.Institution = {} as Dtos.Institution;
            saveInstitutionCoding(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.InstitutionCoding) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/institution/institutioncodings");
    }

    loadForm() {
        const {
            loadForm,
            institutionCoding,
        } = this.props

        let extraData: Dtos.InstitutionCoding = {} as Dtos.InstitutionCoding;

        loadForm(reduxFormName, Object.assign({ ...institutionCoding }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: InstitutionCodingPageProps): IInstitutionCodingPageProps => {

    let institutionCoding: Dtos.InstitutionCoding | undefined = !(state.institutionCodings.formData instanceof Array) ? state.institutionCodings.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        institutionCodingId: ownProps.match && ownProps.match.params.institutionCodingId && ownProps.match.params.institutionCodingId.toString() != "create" ? ownProps.match.params.institutionCodingId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        institutionCoding: institutionCoding!,

        loadingInstitutionCoding: state.institutionCodings.loadState && state.institutionCodings.loadState.status == RequestState.Pending,
        loadInstitutionCodingSuccess: state.institutionCodings.loadState && state.institutionCodings.loadState.status == RequestState.Success,
        loadInstitutionCodingFailure: state.institutionCodings.loadState && state.institutionCodings.loadState.status == RequestState.Failure,

        savingInstitutionCoding: state.institutionCodings.saveState && state.institutionCodings.saveState.status == RequestState.Pending,
        saveInstitutionCodingSuccess: state.institutionCodings.saveState && state.institutionCodings.saveState.status == RequestState.Success,
        saveInstitutionCodingFailure: state.institutionCodings.saveState && state.institutionCodings.saveState.status == RequestState.Failure,

        creatingInstitutionCoding: state.institutionCodings.createState && state.institutionCodings.createState.status == RequestState.Pending,
        createInstitutionCodingSuccess: state.institutionCodings.createState && state.institutionCodings.createState.status == RequestState.Success,
        createInstitutionCodingFailure: state.institutionCodings.createState && state.institutionCodings.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: [],

        form: state.reduxForms.institutionCoding,
        formState: state.institutionCodings.formState,
        formProperties: state.institutionCodings.formProperties,

        reduxFormState: state.reduxForms.formState.institutionCoding,
        validationFailures: institutionCoding ?
            state.institutionCodings.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): IInstitutionCodingPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadInstitutionCoding: bindActionCreators(InstitutionCodingActions.LoadInstitutionCodingById, dispatch),
        clearInstitutionCoding: bindActionCreators(InstitutionCodingActions.Clear, dispatch),

        saveInstitutionCoding: bindActionCreators(InstitutionCodingActions.SaveInstitutionCoding, dispatch),
        createInstitutionCoding: bindActionCreators(InstitutionCodingActions.CreateInstitutionCoding, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(InstitutionCodingPage);
