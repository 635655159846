import * as React from 'react';

import * as classNames from "classnames";
import './OpenClinicaUserRolesPage.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';



import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu, TruncatedCell } from "../../../components/layouts/index";

import * as OpenClinicaUserRoleActions from '../../../actions/openClinicaUserRoles';
import * as PagingActions from '../../../actions/pages/pagingPage';

import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput, FormFilterInput } from '../../../components/form';
import { SettingsSubMenu } from '../../../helpers/subMenuHelper';

interface IOpenClinicaUserRolesPageProps {

    location: Location;
    history: History;

    // From state
    openClinicaUserRoles: Dtos.OpenClinicaUserRole[];
    formLength: number;
    formFilter: IFormFilter;

    loading: boolean;
    loadSuccess: boolean;
    loadFailure: boolean;

    rebuilding: boolean;
    rebuildSuccess: boolean;
    rebuildFailure: boolean;

    resending: boolean;
    resendSuccess: boolean;
    resendFailure: boolean;

    saving: boolean;
    saveSuccess: boolean;
    saveFailure: boolean;


    importing: boolean;
    importSuccess: boolean;
    importFailure: boolean;

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];

    pageSize: number
}

interface IOpenClinicaUserRolesPageActions {
    clearOpenClinicaUserRoles: OpenClinicaUserRoleActions.IOpenClinicaUserRolesClearActionCreator;
    loadOpenClinicaUserRoles: OpenClinicaUserRoleActions.IOpenClinicaUserRolesLoadActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    changeStatus: OpenClinicaUserRoleActions.IOpenClinicaUserRolesChangeStatusActionCreator;
    rebuild: OpenClinicaUserRoleActions.IOpenClinicaUserRolesRebuildActionCreator;
    filter: OpenClinicaUserRoleActions.IOpenClinicaUserRolesFilterActionCreator;
    resend: OpenClinicaUserRoleActions.IOpenClinicaUserRolesResendEmailActionCreator;
    importUsers: OpenClinicaUserRoleActions.IOpenClinicaUserRolesImportActionCreator;

    setPageSize: PagingActions.IPagingPageSizeChangedStateActionCreator;
}

type OpenClinicaUserRolesPageProps = IOpenClinicaUserRolesPageProps & IOpenClinicaUserRolesPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class OpenClinicaUserRolesPage extends React.PureComponent<OpenClinicaUserRolesPageProps, any> {

    constructor(props: OpenClinicaUserRolesPageProps) {
        super(props);

        this.clearOpenClinicaUserRoles = this.clearOpenClinicaUserRoles.bind(this);
    }

    componentDidMount() {
        const {
            loadOpenClinicaUserRoles,
            navigate,
            navigateReplace
        } = this.props;

        loadOpenClinicaUserRoles();
    }

    componentWillReceiveProps(nextProps: OpenClinicaUserRolesPageProps) {
    }

    componentDidUpdate(prevProps: OpenClinicaUserRolesPageProps, prevState) {
        const {
            navigateReplace,
            loadOpenClinicaUserRoles,
            rebuilding,
            rebuildSuccess,
            rebuildFailure,
            saving,
            saveSuccess,
            saveFailure,
            resending,
            resendSuccess,
            resendFailure,
            importing,
            importSuccess,
            importFailure
        } = this.props

        if (!rebuilding && prevProps.rebuilding) {
            if (rebuildSuccess) {
                Alert.success(<NotificationAlert
                    alertContent={<div>OpenClincia User Roles Refreshed</div>}
                    icon={FontAwesomeIcons.Solid.CHECK}
                />);
                loadOpenClinicaUserRoles();
            }
            if (rebuildFailure) {
                Alert.error(
                    <NotificationAlert
                        alertContent={<div>The form was <strong> not </strong> saved.</div>}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

        if (!saving && prevProps.saving) {
            if (saveSuccess) {
                Alert.success(<NotificationAlert
                    alertContent={<div>OpenClincia User Role Saved</div>}
                    icon={FontAwesomeIcons.Solid.CHECK}
                />);
                loadOpenClinicaUserRoles();
            }
            if (saveFailure) {
                Alert.error(
                    <NotificationAlert
                        alertContent={<div>The form was <strong> not </strong> saved.</div>}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

        if (!resending && prevProps.resending) {
            if (resendSuccess) {
                Alert.success(<NotificationAlert
                    alertContent={<div>OpenClincia role assigned email sent</div>}
                    icon={FontAwesomeIcons.Solid.CHECK}
                />);
            }
            if (resendFailure) {
                Alert.error(
                    <NotificationAlert
                        alertContent={<div>The OpenClinca role assigned was <strong> not </strong> sent</div>}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

        if (!importing && prevProps.importing) {
            if (importSuccess) {
                Alert.success(<NotificationAlert
                    alertContent={<div>OpenClincia roles imported</div>}
                    icon={FontAwesomeIcons.Solid.CHECK}
                />);
            }
            if (importFailure) {
                Alert.error(
                    <NotificationAlert
                        alertContent={<div>The OpenClinca import failed</div>}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }
    }

    componentWillUpdate(nextProps: OpenClinicaUserRolesPageProps) {
    }

    componentWillUnmount() {
        this.clearOpenClinicaUserRoles();
    }

    clearOpenClinicaUserRoles() {
        this.props.clearOpenClinicaUserRoles();
    }

    showOpenClinicaUserRoles(): boolean {
        const {
            loading,
            loadSuccess
        } = this.props

        if (!loading && loadSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loading,
            permissions
        } = this.props;

        return <RestrictedLayout
            loading={loading }
            permission={Dtos.Permission.OpenClinicaUserRolesView }
            subMenuItems={SettingsSubMenu(permissions)}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            navigate,
            user,
            rebuild,
            loading,
            rebuilding,
            importUsers,
            importing,
            permissions
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-6 mb-2"}>
                    <h2>OpenClinica User Roles</h2>
                </div>
                <div className="col-6" style={{textAlign: 'right'}}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} />
                    {AuthenticationHelper.hasPermission(permissions, Dtos.Permission.OpenClinicaUserRolesCreate) && (
                        <button disabled={loading || rebuilding || importing}
                            style={{ "marginRight": "10px" }}
                            onClick={
                                () => {
                                    importUsers();
                                }
                            }
                            type="button"
                            className="btn btn-warning"
                        >
                            Import
                        </button>
                    )}
                    {AuthenticationHelper.hasPermission(permissions, Dtos.Permission.OpenClinicaUserRolesUpdate) && (
                        <button disabled={loading || rebuilding || importing}
                            onClick={
                                () => {
                                    rebuild([]);
                                }
                            }
                            type="button"
                            className="btn btn-warning"
                        >
                            Run
                        </button>
                    )}
                </div>
            </div>
            {
                !this.showOpenClinicaUserRoles() ?
                    "Loading OpenClinica User Roles" :
                    null
            }
            {
                this.showOpenClinicaUserRoles() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {

        const {
            openClinicaUserRoles,
            permissions,
            pageSize,
            setPageSize,
            rebuilding,
            saving,
            changeStatus,
            resending,
            resend
        } = this.props

        const canUpdate = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.OpenClinicaUserRolesUpdate);

        if (!openClinicaUserRoles || openClinicaUserRoles.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no OpenClinica user roles to view.</span>
            </div>
        }

        const showPagination = openClinicaUserRoles.length > pageSize;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={openClinicaUserRoles}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? pageSize : openClinicaUserRoles.length}
                pageSize={showPagination ? pageSize : openClinicaUserRoles.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no item components to display"
                resizable={false}
                onPageSizeChange={val => setPageSize(val)}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            
                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: rowInfo && rowInfo.original ? rowInfo.original.errorMessage == "" ? '#212529' : 'red' : '#212529'
                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.ROLE} fixedWidth />
                    },
                    {
                        id: "personnel",
                        Header: 'Personnel',
                        accessor: 'personnel',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value.display} />
                    },
                    {
                        id: "trial",
                        Header: 'Trial',
                        accessor: 'trial',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value.display} />,
                        width: 120
                    },
                    {
                        id: "institution",
                        Header: 'Institution',
                        accessor: 'institution',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value ? props.value.display : ""} />
                    },
                    {
                        id: "role",
                        Header: 'OC Role',
                        accessor: 'role',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value} />
                    },
                    {
                        id: "studyIdentifier",
                        Header: 'Study',
                        accessor: 'studyIdentifier',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value} />,
                        width: 120
                    },
                    {
                        id: "status",
                        Header: 'Status',
                        accessor: 'status',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value} />,
                        width: 140
                    },
                    {
                        id: "errorMessage",
                        Header: 'Error',
                        accessor: 'errorMessage',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value} />
                    },
                    {
                        id: "id",
                        Header: '',
                        accessor: 'id',
                        className: "d-flex align-items-right justify-content-right",
                        headerClassName: "text-left",
                        Cell: (props) => {
                            return <>
                                {(canUpdate && props.original.active) && (
                                    <button disabled={rebuilding || saving}
                                        onClick={
                                            () => {
                                                changeStatus(props.original.id, Dtos.OCSyncStatus.Pending);
                                            }
                                        }
                                        type="button"
                                        className="btn btn-outline-primary btn-sm mr-2"
                                    >
                                        Re-process
                                    </button>
                                )}
                                {(canUpdate && props.original.active) && (
                                    <button disabled={resending}
                                        onClick={
                                            () => {
                                                resend(props.original.id);
                                            }
                                        }
                                        type="button"
                                        className="btn btn-outline-primary btn-sm mr-2"
                                    >
                                        <FontAwesomeIcon data-tip={"Resend OpenClinca Role assigned email"} icon={FontAwesomeIcons.Light.EMAIL} fixedWidth /><ReactTooltip />
                                    </button>
                                )}
                                {(canUpdate && props.original.active) && (
                                    <button disabled={rebuilding || saving}
                                        onClick={
                                            () => {
                                                changeStatus(props.original.id, Dtos.OCSyncStatus.Removed);
                                            }
                                        }
                                        type="button"
                                        className="btn btn-outline-danger btn-sm mr-2"
                                    >
                                        <FontAwesomeIcon icon={FontAwesomeIcons.Light.TRASH} fixedWidth />
                                    </button>
                                )}
                            </>
                        },
                        width: 140
                    }
                ]}
            />
        </div>

    }


}

const mapStateToProps = (state: ITmdState, ownProps: OpenClinicaUserRolesPageProps): IOpenClinicaUserRolesPageProps => {
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        history: ownProps.history,
        location: state.routing.location,

        openClinicaUserRoles: state.openClinicaUserRole.formFilteredData!,
        formFilter: state.openClinicaUserRole.formFilter,
        formLength: state.openClinicaUserRole.formLength,

        loading: state.openClinicaUserRole.loadState && state.openClinicaUserRole.loadState.status == RequestState.Pending,
        loadSuccess: state.openClinicaUserRole.loadState && state.openClinicaUserRole.loadState.status == RequestState.Success,
        loadFailure: state.openClinicaUserRole.loadState && state.openClinicaUserRole.loadState.status == RequestState.Failure,

        rebuilding: state.openClinicaUserRole.runningState && state.openClinicaUserRole.runningState.status == RequestState.Pending,
        rebuildSuccess: state.openClinicaUserRole.runningState && state.openClinicaUserRole.runningState.status == RequestState.Success,
        rebuildFailure: state.openClinicaUserRole.runningState && state.openClinicaUserRole.runningState.status == RequestState.Failure,

        resending: state.openClinicaUserRole.resendingState && state.openClinicaUserRole.resendingState.status == RequestState.Pending,
        resendSuccess: state.openClinicaUserRole.resendingState && state.openClinicaUserRole.resendingState.status == RequestState.Success,
        resendFailure: state.openClinicaUserRole.resendingState && state.openClinicaUserRole.resendingState.status == RequestState.Failure,

        saving: state.openClinicaUserRole.saveState && state.openClinicaUserRole.saveState.status == RequestState.Pending,
        saveSuccess: state.openClinicaUserRole.saveState && state.openClinicaUserRole.saveState.status == RequestState.Success,
        saveFailure: state.openClinicaUserRole.saveState && state.openClinicaUserRole.saveState.status == RequestState.Failure,

        importing: state.openClinicaUserRole.importingState && state.openClinicaUserRole.importingState.status == RequestState.Pending,
        importSuccess: state.openClinicaUserRole.importingState && state.openClinicaUserRole.importingState.status == RequestState.Success,
        importFailure: state.openClinicaUserRole.importingState && state.openClinicaUserRole.importingState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,

        pageSize: state.pagingPage.pageSize
    };
};

const mapDispatchToProps = (dispatch): IOpenClinicaUserRolesPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadOpenClinicaUserRoles: bindActionCreators(OpenClinicaUserRoleActions.Load, dispatch),
        clearOpenClinicaUserRoles: bindActionCreators(OpenClinicaUserRoleActions.Clear, dispatch),

        changeStatus: bindActionCreators(OpenClinicaUserRoleActions.ChangeStatus, dispatch),
        filter: bindActionCreators(OpenClinicaUserRoleActions.Filter, dispatch),
        rebuild: bindActionCreators(OpenClinicaUserRoleActions.Rebuild, dispatch),
        resend: bindActionCreators(OpenClinicaUserRoleActions.ResendEmail, dispatch),
        importUsers: bindActionCreators(OpenClinicaUserRoleActions.ImportUsers, dispatch),

        setPageSize: bindActionCreators(PagingActions.PageSizeChanged, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(OpenClinicaUserRolesPage);
