import * as React from "react";
import * as classNames from "classnames";
import { IBaseFormInputProps } from '../';

import './DatePickerPartialFormInputFabric.scss';

import { FontAwesomeIcons, FontAwesomeIcon } from "../../../../constants/fontAwesomeIcons";

//import { DateRangePicker, SingleDatePicker, DayPickerRangeController, } from 'react-dates';
import { DatePicker, DayOfWeek, IDatePickerStrings } from "office-ui-fabric-react/lib/DatePicker";
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import { ICalendarFormatDateCallbacks } from "office-ui-fabric-react/lib/Calendar";
import * as Moment from 'moment';
import { DEFAULT_DATE_FORMAT } from "../../../../constants/dateFormats";


import { Icon } from 'office-ui-fabric-react/lib/Icon'
import { FabricIcons } from '../../../../constants/fabricIcons';
import { min } from "moment";
import { IDatePicker } from "office-ui-fabric-react/lib/components/DatePicker";

export interface IDatePickerPartialFormInputFabricProps {
    feedbackIcon?: string;
    maxDate?: Date;
    minDate?: Date;
    label?: string;
    isMonthPickerVisible?: boolean;
    includeTime?: boolean;
}

export interface IDatePickerPartialFormInputFabricState {
    resetting: boolean;
}

export interface IDatePickerFormatExampleState {
    firstDayOfWeek?: DayOfWeek;
    value?: Date | null;
}

//"yyyy'-'MM'-'dd'T'HH':'mm':'ss'.'fffffff'Z'"
// Constants

const dateFormat: string = "YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[.]SSSSSSS[Z]";

const DayPickerStrings: IDatePickerStrings = {
    months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ],

    shortMonths: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ],

    days: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ],

    shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

    goToToday: 'Go to today',
    prevMonthAriaLabel: 'Go to previous month',
    nextMonthAriaLabel: 'Go to next month',
    prevYearAriaLabel: 'Go to previous year',
    nextYearAriaLabel: 'Go to next year'
};



class DatePickerPartialFormInputFabric extends React.Component<IDatePickerPartialFormInputFabricProps & IBaseFormInputProps, IDatePickerPartialFormInputFabricState> {

    // @ts-ignore
    private container: HTMLDivElement;
    // @ts-ignore
    private editor: IDatePicker;

    constructor(props) {
        super(props)

        this.onDateChange = this.onDateChange.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);

        this.state = { resetting: false };

    }

    componentDidUpdate(prevProps: IDatePickerPartialFormInputFabricProps & IBaseFormInputProps) {

        const {
            value,
            onChange,
            focused,
            name,
            onFocus
        } = this.props

        const {
            resetting
        } = this.state;
    }

    render() {

        const {
            valid,
            invalid,
            className,
            name,
            onBlur,
            onFocus,
            value,
            disabled,
            focused,
            feedbackIcon,
            isMonthPickerVisible,
            maxDate,
            minDate,
            label,
            includeTime
        } = this.props


        const derivedClassName = className ? className : undefined;
        const derivedLabel = '';
        const derivedIsMonthPickerVisible = isMonthPickerVisible ? isMonthPickerVisible : true;
        const derivedMaxDate = maxDate ? maxDate : undefined;
        const derivedMinDate = minDate ? minDate : new Date(0, 0);

        const date = value;

        const desc = 'This field is required. One of the support input formats is year dash month dash day.';
        return (
            <div ref={ref => (this.container = ref!)} className={classNames(
                "form-date-group",
                { "focused": focused },
                { "is-invalid": invalid && !disabled },
                { "is-valid": !invalid && !disabled })}>
                <DatePicker
                    isRequired={false}
                    allowTextInput={true}
                    ariaLabel={desc}
                    firstDayOfWeek={DayOfWeek.Sunday}
                    strings={DayPickerStrings}
                    value={date!}
                    onSelectDate={this._onSelectDate}
                    //formatDate={this._onFormatDate}
                    //parseDateFromString={this._onParseDateFromString}
                    disableAutoFocus={true}
                    disabled={disabled}
                    className={derivedClassName}
                    highlightCurrentMonth={false}
                    highlightSelectedMonth={true}
                    minDate={derivedMinDate}
                    maxDate={derivedMaxDate}
                    label={derivedLabel}
                    isMonthPickerVisible={derivedIsMonthPickerVisible}
                    componentRef={ref => (this.editor = ref!)}
                />
                {
                    invalid && !disabled ?
                        <span className={classNames("form-control-feedback pr-8")}>
                            <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE} fixedWidth />
                        </span> :
                        null
                }
            </div>

        );
    }


    onDateChange(val: any) {
        if (!val) {
            this.props.onChange(null);
        }
        else {

            this.props.onChange(val);
        }
    }

    checkFocus() {
        console.log("checking focus");
        if (this.container) {
            console.log(this.container);
        }
        else {
            console.log("container does not exist!");
        }

    }

    onFocusChange(event: { focused: boolean }) {

        const {
            onFocus,
            onBlur,
            onChange,
            value

        } = this.props

        if (event.focused && onFocus) {
            onFocus();
        }
        else if (onBlur) {
            onBlur();
        }
    }

    private _onFormatDate = (date: Date): string | undefined => {

        //if (this.isValidDate(date)) {
        //    if (this.props.includeTime) {
        //        return (
        //            Moment(date.toISOString()).format(DEFAULT_DATE_FORMAT)
        //        );
        //    }

        //    return (
        //        Moment(date.toISOString()).utcOffset('+1000').format(DEFAULT_DATE_FORMAT)
        //    );
        //}
        //return undefined;
        return undefined;

    }

    private _onSelectDate = (date: Date | null | undefined | string): void => {

        const {
            onChange,
            value,
            maxDate,
            minDate
        } = this.props;


        this.props.onChange(date);
        //    if (!date) {
        //        this.props.onChange(null);
        //    }
        //    else {
        //        if (this.isValidDate(date)) {
        //            if (this.props.includeTime) {
        //                this.props.onChange(Moment(date).utc().format(dateFormat));
        //            }
        //            else {
        //                let mDate: Moment.Moment = Moment(date.toISOString()).utcOffset("+1000");
        //                let mDate2: Moment.Moment = Moment(date).set({ hour: 0 });

        //                this.props.onChange(Moment(mDate2).format(dateFormat));
        //            }
        //        }
        //        else {
        //            this.props.onChange(null);
        //        }
        //    }

        //}

        //private _onParseDateFromString = (val: string): Date => {

        //    //const {
        //    //    value,
        //    //    includeTime
        //    //} = this.props;

        //    //const date = value ?
        //    //    includeTime ?
        //    //        Moment(value).toDate() :
        //    //        new Date(Moment(value).utc().year(), Moment(value).utc().month(), Moment(value).utc().date()) :
        //    //    new Date();

        //    //const values = (val || '').trim().split('/');
        //    //const day =
        //    //    values.length > 0
        //    //        ? Math.max(1, Math.min(31, parseInt(values[0], 10)))
        //    //        : date.getDate();
        //    //const month =
        //    //    values.length > 1
        //    //        ? Math.max(1, Math.min(12, parseInt(values[1], 10))) - 1
        //    //        : date.getMonth();
        //    //let year = values.length > 2 ? parseInt(values[2], 10) : date.getFullYear();
        //    //if (year < 100) {
        //    //    year += date.getFullYear() - date.getFullYear() % 100;
        //    //}

        //    //return new Date(year, month, day);
        //}

        //private isValidDate(date: Date): boolean {
        //    const {
        //        maxDate,
        //        minDate,
        //        onChange,
        //        value

        //    } = this.props;

        //    const derivedMaxDate = maxDate ? maxDate : undefined;
        //    const derivedMinDate = minDate ? minDate : new Date(0, 0);


        //    let validDate: boolean = true;

        //    if (derivedMaxDate) {
        //        if (date > derivedMaxDate) {
        //            validDate = false;
        //        }
        //    }

        //    if (derivedMinDate) {
        //        if (date < derivedMinDate) {
        //            validDate = false;
        //        }
        //    }
        //    return validDate;
        //}

    }
}

export default DatePickerPartialFormInputFabric;