import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trialGroupItemsApi = {
    getTrialGroupItemById: (id: number): Observable<Dtos.GetTrialGroupItemByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrialGroupItemById = new Dtos.GetTrialGroupItemById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTrialGroupItemByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrialGroupItem: (trialGroupItem: Dtos.TrialGroupItem): Observable<Dtos.SetTrialGroupItemResponse> => {

        let request: Dtos.SetTrialGroupItem = new Dtos.SetTrialGroupItem();

        request.trialGroupItem = trialGroupItem;

        // send request
        const response: Promise<Dtos.SetTrialGroupItemResponse> = client.post(request);

        return Observable.from(response);

    },
    createTrialGroupItem: (trialGroupId: number, groupItemId: number): Observable<Dtos.CreateTrialGroupItemResponse> => {
        //Create the request.
        let request: Dtos.CreateTrialGroupItem = new Dtos.CreateTrialGroupItem();

        request.trialGroupId = trialGroupId;
        request.groupItemId = groupItemId;

        //Send request.
        const response: Promise<Dtos.CreateTrialGroupItemResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}