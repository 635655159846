import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const hubspotApi = {
    getHubspotById: (id: number): Observable<Dtos.GetHubspotByIdResponse> => {
        //Create the request.
        let request: Dtos.GetHubspotById = new Dtos.GetHubspotById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetHubspotByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getHubspotContactsToExport: (): Observable<Dtos.GetHubspotContactsToExportResponse> => {
        //Create the request.
        let request: Dtos.GetHubspotContactsToExport = new Dtos.GetHubspotContactsToExport();

        //Send request.
        const response: Promise<Dtos.GetHubspotContactsToExportResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    postHubspotContactExport: (username: string): Observable<Dtos.PostHubspotContactExportResponse> => {
        //Create the request.
        let request: Dtos.PostHubspotContactExport = new Dtos.PostHubspotContactExport();

        request.username = username;

        //Send request.
        const response: Promise<Dtos.PostHubspotContactExportResponse> = client.post(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    findHubspotContact: (email: string, action: string, newEmail: string, emailUpdateOptions: Dtos.EmailUpdateOptions): Observable<Dtos.FindHubspotContactResponse> => {
        //Create the request.
        let request: Dtos.FindHubspotContact = new Dtos.FindHubspotContact();

        request.email = email;
        request.action = action;
        request.newEmail = newEmail;
        request.emailUpdateOptions = emailUpdateOptions;

        //Send request.
        const response: Promise<Dtos.FindHubspotContactResponse> = client.post(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}