import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_OPENCLINICAUSERROLES_LOAD,

    ACTION_OPENCLINICAUSERROLES_REBUILD,
    ACTION_OPENCLINICAUSERROLES_CHANGESTATUS,
    ACTION_OPENCLINICAUSERROLES_RESENDEMAIL,
    ACTION_OPENCLINICAUSERROLES_IMPORT,

    IOpenClinicaUserRolesLoadAction,
    IOpenClinicaUserRolesLoadFailureAction,
    IOpenClinicaUserRolesLoadSuccessAction,

    IOpenClinicaUserRolesRebuildAction,
    IOpenClinicaUserRolesRebuildSuccessAction,
    IOpenClinicaUserRolesRebuildFailureAction,

    IOpenClinicaUserRolesChangeStatusAction,
    IOpenClinicaUserRolesChangeStatusSuccessAction,
    IOpenClinicaUserRolesChangeStatusFailureAction,

    IOpenClinicaUserRolesResendEmailAction,
    IOpenClinicaUserRolesResendEmailSuccessAction,
    IOpenClinicaUserRolesResendEmailFailureAction,


    IOpenClinicaUserRolesImportAction,
    IOpenClinicaUserRolesImportSuccessAction,
    IOpenClinicaUserRolesImportFailureAction,

    LoadSuccess,
    LoadFailure,

    RebuildSuccess,
    RebuildFailure,

    ChangeStatusSuccess,
    ChangeStatusFailure,

    ResendEmailSuccess,
    ResendEmailFailure,

    ImportUsersSuccess,
    ImportUsersFailure

    

} from "../actions/openClinicaUserRoles";
import { openClinicaUserRolesApi } from "../services/openClinicaUserRoles";


export const
    getOpenClinicaUserRolesEpic = (action$): Observable<IOpenClinicaUserRolesLoadSuccessAction | IOpenClinicaUserRolesLoadFailureAction> => {
        return action$
            .ofType(ACTION_OPENCLINICAUSERROLES_LOAD)
            .mergeMap((action: IOpenClinicaUserRolesLoadAction) => {
                return openClinicaUserRolesApi
                    .getOpenClinicaUserRoles()
                    .map(response =>
                        LoadSuccess(response.openClinicaUserRoles, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setOpenClinicaUserRoleEpic = (action$): Observable<IOpenClinicaUserRolesRebuildSuccessAction | IOpenClinicaUserRolesRebuildFailureAction> => {
        return action$
            .ofType(ACTION_OPENCLINICAUSERROLES_REBUILD)
            .mergeMap((action: IOpenClinicaUserRolesRebuildAction) => {
                return openClinicaUserRolesApi
                    .rebuildtOpenClinicaUserRole(action.personnelIds)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return RebuildFailure(response.responseStatus)
                        }

                        return RebuildSuccess(response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(RebuildFailure(error.responseStatus))
                    )
            });
    },
    postOpenClinicaUserRoleChangeStatusEpic = (action$): Observable<IOpenClinicaUserRolesChangeStatusSuccessAction | IOpenClinicaUserRolesChangeStatusFailureAction> => {
        return action$
            .ofType(ACTION_OPENCLINICAUSERROLES_CHANGESTATUS)
            .mergeMap((action: IOpenClinicaUserRolesChangeStatusAction) => {
                return openClinicaUserRolesApi
                    .changeStatusOpenClinicaUserRole(action.id, action.ocSyncStatus)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return ChangeStatusFailure(response.responseStatus)
                        }

                        return ChangeStatusSuccess(response.openClinicaUserRole, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(ChangeStatusFailure(error.responseStatus))
                    )
            });
    },
    postOpenClinicaUserRoleResendEmailEpic = (action$): Observable<IOpenClinicaUserRolesResendEmailSuccessAction | IOpenClinicaUserRolesResendEmailFailureAction> => {
        return action$
            .ofType(ACTION_OPENCLINICAUSERROLES_RESENDEMAIL)
            .mergeMap((action: IOpenClinicaUserRolesResendEmailAction) => {
                return openClinicaUserRolesApi
                    .resendEmailOpenClinicaUserRole(action.id)
                    .map(response => {
                        return ResendEmailSuccess(response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(ResendEmailFailure(error.responseStatus))
                    )
            });
    },
    postOpenClinicaUserRoleImportEpic = (action$): Observable<IOpenClinicaUserRolesRebuildSuccessAction | IOpenClinicaUserRolesRebuildFailureAction> => {
        return action$
            .ofType(ACTION_OPENCLINICAUSERROLES_IMPORT)
            .mergeMap((action: IOpenClinicaUserRolesImportAction) => {
                return openClinicaUserRolesApi
                    .importOpenClinciaUsers()
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return ImportUsersFailure(response.responseStatus)
                        }

                        return ImportUsersSuccess(response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(ImportUsersFailure(error.responseStatus))
                    )
            });
    }



