import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";


export const salutationsApi = {
    getSalutations: (): Observable<Dtos.GetSalutationsResponse> => {
        //Create the request.
        let request: Dtos.GetSalutations = new Dtos.GetSalutations();

        //Send request.
        const response: Promise<Dtos.GetSalutationsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getSalutationById: (id: number): Observable<Dtos.GetSalutationByIdResponse> => {
        //Create the request.
        let request: Dtos.GetSalutationById = new Dtos.GetSalutationById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetSalutationByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setSalutation: (salutation: Dtos.Salutation): Observable<Dtos.SetSalutationResponse> => {

        let request: Dtos.SetSalutation = new Dtos.SetSalutation();

        request.salutation = salutation;

        // send request
        const response: Promise<Dtos.SetSalutationResponse> = client.post(request);

        return Observable.from(response);

    },
    createSalutation: (): Observable<Dtos.CreateSalutationResponse> => {
        //Create the request.
        let request: Dtos.CreateSalutation = new Dtos.CreateSalutation();

        //Send request.
        const response: Promise<Dtos.CreateSalutationResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}