import * as React from 'react';
import './FormTemplatePage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { IFormTemplateReduxFormState, initialFormTemplateState } from "../../../reducers/reactReduxForms/formTemplate";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as FormTemplateActions from '../../../actions/formTemplates';
import * as CollectionActions from '../../../actions/collection';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert, Placeholder } from "../../../components/common/index";
import { Form, actions, Control } from 'react-redux-form';
import { QALookupsLayout } from '../../index';
import * as AuthenticationHelper from '../../../helpers/authentication';

import {
    CollectionPage
} from "../../../containers/index";

interface IFormTemplatePageParams {
    formTemplateId: number;
}

interface IFormTemplatePageProps {

    form: Dtos.FormTemplate & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IFormTemplateReduxFormState;

    collectionFormState: Dtos.Collection & IRegistrationFormState;

    location: Location;
    formTemplateId: number;
    match: match<IFormTemplatePageParams>;
    history: History;

    // From state
    formTemplate: Dtos.FormTemplate;
    collection: Dtos.Collection;
    lookups: Dtos.Lookup[];

    loadingFormTemplate: boolean;
    loadFormTemplateSuccess: boolean;
    loadFormTemplateFailure: boolean;

    savingFormTemplate: boolean;
    saveFormTemplateSuccess: boolean;
    saveFormTemplateFailure: boolean;

    creatingFormTemplate: boolean;
    createFormTemplateSuccess: boolean;
    createFormTemplateFailure: boolean;

    validationFailures: Dtos.ResponseError[];
    permissions?: Dtos.PersonnelTmdPermissions[];
    user: Dtos.User
}

interface IFormTemplatePageActions {
    clearFormTemplate: FormTemplateActions.IFormTemplateClearActionCreator;
    loadFormTemplate: FormTemplateActions.IFormTemplateLoadByIdActionCreator;

    saveFormTemplate: FormTemplateActions.IFormTemplateSaveActionCreator;
    createFormTemplate: FormTemplateActions.IFormTemplateCreateActionCreator;

    loadCollection: CollectionActions.ICollectionLoadByIdActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type FormTemplatePageProps = IFormTemplatePageProps & IFormTemplatePageActions;


const formName: string = "formtemplate";
const reduxFormName: string = "reduxForms.formTemplate";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class FormTemplatePage extends React.PureComponent<FormTemplatePageProps, any> {

    constructor(props: FormTemplatePageProps) {
        super(props);

        this.clearFormTemplate = this.clearFormTemplate.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadFormTemplate,
            navigate,
            navigateReplace,
            formTemplateId,
            createFormTemplate
        } = this.props;

        if (formTemplateId) {
            loadFormTemplate(formTemplateId);
        } else if (!formTemplateId) {
            createFormTemplate();
        }
    }

    componentWillReceiveProps(nextProps: FormTemplatePageProps) {
    }

    componentDidUpdate(prevProps: FormTemplatePageProps, prevState) {
        const {
            navigateReplace,
            formTemplateId,
            formTemplate,
            form,
            navigate,
            loadFormTemplate,
            loadingFormTemplate,
            loadFormTemplateSuccess,
            createFormTemplate,
            creatingFormTemplate,
            createFormTemplateSuccess,
            savingFormTemplate,
            saveFormTemplateSuccess,
            saveFormTemplateFailure,
            validationFailures,
            loadCollection
        } = this.props;

        if (formTemplateId && prevProps.formTemplateId != formTemplateId) {
            loadFormTemplate(formTemplateId);
        }
        else if (!formTemplateId && prevProps.formTemplateId) {
            createFormTemplate();
        }

        if (!loadingFormTemplate && prevProps.loadingFormTemplate) {
            if (loadFormTemplateSuccess) {
                this.loadForm();
            }
            else {

            }
        }

        if (!savingFormTemplate && prevProps.savingFormTemplate) {
            if (saveFormTemplateSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/settings/qa/formtemplates")
                } else if (!form.saveAndReturn && formTemplateId && formTemplateId > 0) {
                    this.loadForm();
                    if (form.collectionId !== undefined && form.collectionId > 0) {
                        loadCollection(form.collectionId);
                    }
                } else if (!form.saveAndReturn && !formTemplateId && formTemplate && formTemplate.id > 0) {
                    navigate("/settings/qa/formtemplates/" + formTemplate.id);
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingFormTemplate && prevProps.creatingFormTemplate) {
            if (createFormTemplateSuccess) {
                this.loadForm();
            }
            else {

            }
        }

    }

    componentWillUpdate(nextProps: FormTemplatePageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearFormTemplate();
        loadForm(reduxFormName, initialFormTemplateState);
        resetForm(reduxFormName);
    }

    clearFormTemplate() {
        this.props.clearFormTemplate();
    }

    showFormTemplate(): boolean {
        const {
            loadingFormTemplate,
            loadFormTemplateSuccess
        } = this.props

        if (!loadingFormTemplate && loadFormTemplateSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingFormTemplate
        } = this.props;

        return <QALookupsLayout loading={loadingFormTemplate} permission={Dtos.Permission.FormTemplatesView}>
            {this.renderContent()}
        </QALookupsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            loadingFormTemplate,
            savingFormTemplate,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.FormTemplatesUpdate, Dtos.Permission.FormTemplatesCreate);
        const formDisabled: boolean = !hasPermission || savingFormTemplate || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            name: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            description: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            itemComponentTypeId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("FormTemplate", "ItemComponentTypeId", lookups), form.itemComponentTypeId)
                },
                // groupClassName: "row bottom-0 border-top-0 pb-0 pt-4"
            },
            formTemplateCategoryId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("FormTemplate", "FormTemplateCategoryId", lookups), form.formTemplateCategoryId)
                },
                // groupClassName: "row bottom-0 border-top-0 pb-0 pt-4"
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            },
        };

        let formCollectionComponents: { [index: string]: RegistrationFormComponent } = {
            collectionGroup: {
                type: RegistrationComponent.RegistrationElement,
                component: this.renderCollection()
            },
        };

        return <div>
            <h1>Form Template</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "formtemplate", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                {false &&
                    renderFormControls(form, "collection", formCollectionComponents, lookups, validationFailures, formProperties, changeForm)
                }
            </Form>
            <Form model={"reduxForms.collection"}>
                {form && !loadingFormTemplate && (
                    <CollectionPage
                        qaItemComponents={true}
                        itemComponentTypeId={form.itemComponentTypeId || 0}
                        showSections={true}
                        readonly={formDisabled}
                        collectionId={form.collectionId || 0} />
                )}
            </Form>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderCollection() {
        const {
            form,
            formState,
            loadingFormTemplate,
            savingFormTemplate,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.FormTemplatesUpdate, Dtos.Permission.FormTemplatesCreate);
        const formDisabled: boolean = !hasPermission || savingFormTemplate || (formState && formState.formStatus == Dtos.FormStatus.Disabled);


        if (form && !loadingFormTemplate) {
            return <CollectionPage
                qaItemComponents={true}
                itemComponentTypeId={form.itemComponentTypeId || 0}
                showSections={true}
                readonly={formDisabled}
                collectionId={form.collectionId || 0} />
        }
    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
        </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.FormTemplate) {
        const {
            saveFormTemplate,
            collection
        } = this.props;

        if (data) {
            let changes: Dtos.Institution = {} as Dtos.Institution;
            let collectionChanges: Dtos.Collection = {} as Dtos.Collection;
            saveFormTemplate(Object.assign({ ...data }, changes), Object.assign({ ...collection }, collectionChanges));
        }
    }

    handleOnSubmitFailed(data: Dtos.FormTemplate) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/qa/formtemplates");
    }

    loadForm() {
        const {
            loadForm,
            formTemplate,
        } = this.props

        let extraData: Dtos.FormTemplate = {} as Dtos.FormTemplate;

        loadForm(reduxFormName, Object.assign({ ...formTemplate }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: FormTemplatePageProps): IFormTemplatePageProps => {

    let formTemplate: Dtos.FormTemplate | undefined = !(state.formTemplates.formData instanceof Array) ? state.formTemplates.formData : undefined;
    let collection: Dtos.Collection | undefined = !(state.reduxForms.collection instanceof Array) ? state.reduxForms.collection : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        formTemplateId: ownProps.match && ownProps.match.params.formTemplateId && ownProps.match.params.formTemplateId.toString() != "create" ? ownProps.match.params.formTemplateId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        formTemplate: formTemplate!,
        collection: collection!,

        loadingFormTemplate: state.formTemplates.loadState && state.formTemplates.loadState.status == RequestState.Pending,
        loadFormTemplateSuccess: state.formTemplates.loadState && state.formTemplates.loadState.status == RequestState.Success,
        loadFormTemplateFailure: state.formTemplates.loadState && state.formTemplates.loadState.status == RequestState.Failure,

        savingFormTemplate: state.formTemplates.saveState && state.formTemplates.saveState.status == RequestState.Pending,
        saveFormTemplateSuccess: state.formTemplates.saveState && state.formTemplates.saveState.status == RequestState.Success,
        saveFormTemplateFailure: state.formTemplates.saveState && state.formTemplates.saveState.status == RequestState.Failure,

        creatingFormTemplate: state.formTemplates.createState && state.formTemplates.createState.status == RequestState.Pending,
        createFormTemplateSuccess: state.formTemplates.createState && state.formTemplates.createState.status == RequestState.Success,
        createFormTemplateFailure: state.formTemplates.createState && state.formTemplates.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: state.formTemplates.lookups,

        form: state.reduxForms.formTemplate,
        formState: state.formTemplates.formState,
        formProperties: state.formTemplates.formProperties,

        collectionFormState: state.reduxForms.collection,

        reduxFormState: state.reduxForms.formState.formTemplate,
        validationFailures: formTemplate ?
            state.formTemplates.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): IFormTemplatePageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadFormTemplate: bindActionCreators(FormTemplateActions.LoadFormTemplateById, dispatch),
        clearFormTemplate: bindActionCreators(FormTemplateActions.Clear, dispatch),

        loadCollection: bindActionCreators(CollectionActions.LoadCollectionById, dispatch),

        saveFormTemplate: bindActionCreators(FormTemplateActions.SaveFormTemplate, dispatch),
        createFormTemplate: bindActionCreators(FormTemplateActions.CreateFormTemplate, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(FormTemplatePage);
