import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_REPORTINGTAGCATEGORY_LOAD,
    ACTION_REPORTINGTAGCATEGORY_LOAD_BY_ID,

    ACTION_REPORTINGTAGCATEGORY_SAVE,

    ACTION_REPORTINGTAGCATEGORY_CREATE,

    IReportingTagCategoryLoadAction,
    IReportingTagCategoryLoadByIdAction,
    IReportingTagCategoryLoadFailureAction,
    IReportingTagCategoryLoadSuccessAction,

    IReportingTagCategorySaveAction,
    IReportingTagCategorySaveSuccessAction,
    IReportingTagCategorySaveFailureAction,

    IReportingTagCategoryCreateAction,
    IReportingTagCategoryCreateSuccessAction,
    IReportingTagCategoryCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/reportingTagCategories";
import { reportingCategoryApi } from "../services/reportingTagCategory";


export const
    getReportingTagCategorysEpic = (action$): Observable<IReportingTagCategoryLoadSuccessAction | IReportingTagCategoryLoadFailureAction> => {
        return action$
            .ofType(ACTION_REPORTINGTAGCATEGORY_LOAD)
            .mergeMap((action: IReportingTagCategoryLoadAction) => {
                return reportingCategoryApi
                    .getReportingTagCategorys()
                    .map(response =>
                        LoadSuccess(response.reportingTagCategories, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getReportingTagCategoryByIdEpic = (action$): Observable<IReportingTagCategoryLoadSuccessAction | IReportingTagCategoryLoadFailureAction> => {
        return action$
            .ofType(ACTION_REPORTINGTAGCATEGORY_LOAD_BY_ID)
            .mergeMap((action: IReportingTagCategoryLoadByIdAction) => {
                return reportingCategoryApi
                    .getReportingTagCategoryById(action.id)
                    .map(response =>
                        LoadSuccess(response.reportingTagCategory, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setReportingTagCategoryEpic = (action$): Observable<IReportingTagCategorySaveSuccessAction | IReportingTagCategorySaveFailureAction> => {
        return action$
            .ofType(ACTION_REPORTINGTAGCATEGORY_SAVE)
            .mergeMap((action: IReportingTagCategorySaveAction) => {
                return reportingCategoryApi
                    .setReportingTagCategory(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.reportingTagCategory, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createReportingTagCategoryEpic = (action$): Observable<IReportingTagCategoryCreateSuccessAction | IReportingTagCategoryCreateFailureAction> => {
        return action$
            .ofType(ACTION_REPORTINGTAGCATEGORY_CREATE)
            .mergeMap((action: IReportingTagCategoryCreateAction) => {
                return reportingCategoryApi
                    .createReportingTagCategory()
                    .map(response =>
                        CreateSuccess(
                            response.reportingTagCategory,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



