import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadTrialType: ITrialTypeLoadActionCreator = () => {
        return {
            type: ACTION_TRIALTYPES_LOAD
        }
    },
    LoadTrialTypeById: ITrialTypeLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TRIALTYPES_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ITrialTypeLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALTYPES_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrialTypeLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALTYPES_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTrialType: ITrialTypeSaveActionCreator = (formData) => {
        return {
            type: ACTION_TRIALTYPES_SAVE,
            formData
        }
    },
    SaveSuccess: ITrialTypeSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRIALTYPES_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrialTypeSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALTYPES_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTrialType: ITrialTypeCreateActionCreator = () => {

        return {
            type: ACTION_TRIALTYPES_CREATE
        }
    },
    CreateSuccess: ITrialTypeCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_TRIALTYPES_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: ITrialTypeCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALTYPES_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: ITrialTypeClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRIALTYPES_CLEAR,
            propertiesToClear
        }
    },

    Filter: ITrialTypeFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRIALTYPES_FILTER,
            formFilter
        }
    };



export const
    ACTION_TRIALTYPES_LOAD = "ACTION_TRIALTYPES_LOAD",
    ACTION_TRIALTYPES_LOAD_BY_ID = "ACTION_TRIALTYPES_LOAD_BY_ID",
    ACTION_TRIALTYPES_LOAD_SUCCESS = "ACTION_TRIALTYPES_LOAD_SUCCESS",
    ACTION_TRIALTYPES_LOAD_FAILURE = "ACTION_TRIALTYPES_LOAD_FAILURE",

    ACTION_TRIALTYPES_SAVE = "ACTION_TRIALTYPES_SAVE",
    ACTION_TRIALTYPES_SAVE_SUCCESS = "ACTION_TRIALTYPES_SAVE_SUCCESS",
    ACTION_TRIALTYPES_SAVE_FAILURE = "ACTION_TRIALTYPES_SAVE_FAILURE",

    ACTION_TRIALTYPES_CREATE = "ACTION_TRIALTYPES_CREATE",
    ACTION_TRIALTYPES_CREATE_SUCCESS = "ACTION_TRIALTYPES_CREATE_SUCCESS",
    ACTION_TRIALTYPES_CREATE_FAILURE = "ACTION_TRIALTYPES_CREATE_FAILURE",

    ACTION_TRIALTYPES_CLEAR = "ACTION_TRIALTYPES_CLEAR",

    ACTION_TRIALTYPES_FILTER = "ACTION_TRIALTYPES_FILTER";

export interface ITrialTypeLoadAction {
    type: "ACTION_TRIALTYPES_LOAD";
}

export interface ITrialTypeLoadActionCreator {
    (): ITrialTypeLoadAction;
}

export interface ITrialTypeLoadSuccessAction {
    type: "ACTION_TRIALTYPES_LOAD_SUCCESS";
    formData: Dtos.TrialType | Dtos.TrialType[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialTypeLoadSuccessActionCreator {
    (
        formData: Dtos.TrialType | Dtos.TrialType[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialTypeLoadSuccessAction;
}

export interface ITrialTypeLoadFailureAction {
    type: "ACTION_TRIALTYPES_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialTypeLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialTypeLoadFailureAction;
}

export interface ITrialTypeLoadByIdAction {
    type: "ACTION_TRIALTYPES_LOAD_BY_ID";
    id: number;
}

export interface ITrialTypeLoadByIdActionCreator {
    (id: number): ITrialTypeLoadByIdAction;
}


export interface ITrialTypeSaveAction {
    type: "ACTION_TRIALTYPES_SAVE";
    formData: Dtos.TrialType;

}

export interface ITrialTypeSaveActionCreator {
    (
        formData: Dtos.TrialType
    ): ITrialTypeSaveAction;
}

export interface ITrialTypeSaveSuccessAction {
    type: "ACTION_TRIALTYPES_SAVE_SUCCESS";
    formData: Dtos.TrialType;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialTypeSaveSuccessActionCreator {
    (
        formData: Dtos.TrialType,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrialTypeSaveSuccessAction;
}

export interface ITrialTypeSaveFailureAction {
    type: "ACTION_TRIALTYPES_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialTypeSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialTypeSaveFailureAction;
}

export interface ITrialTypeCreateAction {
    type: "ACTION_TRIALTYPES_CREATE"
}

export interface ITrialTypeCreateActionCreator {
    (): ITrialTypeCreateAction
}

export interface ITrialTypeCreateSuccessAction {
    type: "ACTION_TRIALTYPES_CREATE_SUCCESS";
    formData: Dtos.TrialType;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface ITrialTypeCreateSuccessActionCreator {
    (
        formData: Dtos.TrialType,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): ITrialTypeCreateSuccessAction;
}

export interface ITrialTypeCreateFailureAction {
    type: "ACTION_TRIALTYPES_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialTypeCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialTypeCreateFailureAction;
}

export interface ITrialTypeClearAction {
    type: "ACTION_TRIALTYPES_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrialTypeClearActionCreator {
    (propertiesToClear?: string[]): ITrialTypeClearAction;
}

export interface ITrialTypeFilterActionCreator {
    (formFilter: IFormFilter): ITrialTypeFilterAction;
}

export interface ITrialTypeFilterAction {
    type: "ACTION_TRIALTYPES_FILTER";
    formFilter: IFormFilter;
}

export type TrialTypeAction =
    ITrialTypeLoadAction |
    ITrialTypeLoadByIdAction |
    ITrialTypeLoadFailureAction |
    ITrialTypeLoadSuccessAction |
    ITrialTypeSaveAction |
    ITrialTypeSaveSuccessAction |
    ITrialTypeSaveFailureAction |
    ITrialTypeCreateAction |
    ITrialTypeCreateSuccessAction |
    ITrialTypeCreateFailureAction |
    ITrialTypeFilterAction | 
    ITrialTypeClearAction;
