import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadTrialTracker: ITrialTrackerLoadActionCreator = (trialId) => {
        return {
            type: ACTION_TRIALTRACKER_LOAD,
            trialId
        }
    },
    LoadTrialTrackerById: ITrialTrackerLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TRIALTRACKER_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ITrialTrackerLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALTRACKER_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrialTrackerLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALTRACKER_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTrialTracker: ITrialTrackerSaveActionCreator = (formData) => {
        return {
            type: ACTION_TRIALTRACKER_SAVE,
            formData
        }
    },
    SaveSuccess: ITrialTrackerSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRIALTRACKER_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrialTrackerSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALTRACKER_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTrialTracker: ITrialTrackerCreateActionCreator = (trialId) => {

        return {
            type: ACTION_TRIALTRACKER_CREATE,
            trialId
        }
    },
    CreateSuccess: ITrialTrackerCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_TRIALTRACKER_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: ITrialTrackerCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALTRACKER_CREATE_FAILURE,
            responseStatus
        }
    },

    SyncTrialTracker: ITrialTrackerSyncActionCreator = (trialId) => {

        return {
            type: ACTION_TRIALTRACKER_SYNC,
            trialId
        }
    },
    SyncSuccess: ITrialTrackerSyncSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALTRACKER_SYNC_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    SyncFailure: ITrialTrackerSyncFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALTRACKER_SYNC_FAILURE,
            responseStatus
        }
    },

    Clear: ITrialTrackerClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRIALTRACKER_CLEAR,
            propertiesToClear
        }
    },

    Filter: ITrialTrackerFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRIALTRACKER_FILTER,
            formFilter
        }
    };



export const
    ACTION_TRIALTRACKER_LOAD = "ACTION_TRIALTRACKER_LOAD",
    ACTION_TRIALTRACKER_LOAD_BY_ID = "ACTION_TRIALTRACKER_LOAD_BY_ID",
    ACTION_TRIALTRACKER_LOAD_SUCCESS = "ACTION_TRIALTRACKER_LOAD_SUCCESS",
    ACTION_TRIALTRACKER_LOAD_FAILURE = "ACTION_TRIALTRACKER_LOAD_FAILURE",

    ACTION_TRIALTRACKER_SAVE = "ACTION_TRIALTRACKER_SAVE",
    ACTION_TRIALTRACKER_SAVE_SUCCESS = "ACTION_TRIALTRACKER_SAVE_SUCCESS",
    ACTION_TRIALTRACKER_SAVE_FAILURE = "ACTION_TRIALTRACKER_SAVE_FAILURE",

    ACTION_TRIALTRACKER_CREATE = "ACTION_TRIALTRACKER_CREATE",
    ACTION_TRIALTRACKER_CREATE_SUCCESS = "ACTION_TRIALTRACKER_CREATE_SUCCESS",
    ACTION_TRIALTRACKER_CREATE_FAILURE = "ACTION_TRIALTRACKER_CREATE_FAILURE",

    ACTION_TRIALTRACKER_SYNC = "ACTION_TRIALTRACKER_SYNC",
    ACTION_TRIALTRACKER_SYNC_SUCCESS = "ACTION_TRIALTRACKER_SYNC_SUCCESS",
    ACTION_TRIALTRACKER_SYNC_FAILURE = "ACTION_TRIALTRACKER_SYNC_FAILURE",

    ACTION_TRIALTRACKER_CLEAR = "ACTION_TRIALTRACKER_CLEAR",

    ACTION_TRIALTRACKER_FILTER = "ACTION_TRIALTRACKER_FILTER";

export interface ITrialTrackerLoadAction {
    type: "ACTION_TRIALTRACKER_LOAD";
    trialId: number;
}

export interface ITrialTrackerLoadActionCreator {
    (trialId: number): ITrialTrackerLoadAction;
}

export interface ITrialTrackerLoadSuccessAction {
    type: "ACTION_TRIALTRACKER_LOAD_SUCCESS";
    formData: Dtos.TrialTracker | Dtos.TrialTracker[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialTrackerLoadSuccessActionCreator {
    (
        formData: Dtos.TrialTracker | Dtos.TrialTracker[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialTrackerLoadSuccessAction;
}

export interface ITrialTrackerLoadFailureAction {
    type: "ACTION_TRIALTRACKER_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialTrackerLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialTrackerLoadFailureAction;
}

export interface ITrialTrackerLoadByIdAction {
    type: "ACTION_TRIALTRACKER_LOAD_BY_ID";
    id: number;
}

export interface ITrialTrackerLoadByIdActionCreator {
    (id: number): ITrialTrackerLoadByIdAction;
}

export interface ITrialTrackerSaveAction {
    type: "ACTION_TRIALTRACKER_SAVE";
    formData: Dtos.TrialTracker;

}

export interface ITrialTrackerSaveActionCreator {
    (
        formData: Dtos.TrialTracker
    ): ITrialTrackerSaveAction;
}

export interface ITrialTrackerSaveSuccessAction {
    type: "ACTION_TRIALTRACKER_SAVE_SUCCESS";
    formData: Dtos.TrialTracker;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialTrackerSaveSuccessActionCreator {
    (
        formData: Dtos.TrialTracker,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrialTrackerSaveSuccessAction;
}

export interface ITrialTrackerSaveFailureAction {
    type: "ACTION_TRIALTRACKER_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialTrackerSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialTrackerSaveFailureAction;
}

export interface ITrialTrackerCreateAction {
    type: "ACTION_TRIALTRACKER_CREATE",
    trialId: number
}

export interface ITrialTrackerCreateActionCreator {
    (trialId: number): ITrialTrackerCreateAction
}

export interface ITrialTrackerCreateSuccessAction {
    type: "ACTION_TRIALTRACKER_CREATE_SUCCESS";
    formData: Dtos.TrialTracker;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface ITrialTrackerCreateSuccessActionCreator {
    (
        formData: Dtos.TrialTracker,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): ITrialTrackerCreateSuccessAction;
}

export interface ITrialTrackerCreateFailureAction {
    type: "ACTION_TRIALTRACKER_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialTrackerCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialTrackerCreateFailureAction;
}

export interface ITrialTrackerClearAction {
    type: "ACTION_TRIALTRACKER_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrialTrackerClearActionCreator {
    (propertiesToClear?: string[]): ITrialTrackerClearAction;
}

export interface ITrialTrackerFilterActionCreator {
    (formFilter: IFormFilter): ITrialTrackerFilterAction;
}

export interface ITrialTrackerFilterAction {
    type: "ACTION_TRIALTRACKER_FILTER";
    formFilter: IFormFilter;
}

export interface ITrialTrackerSyncAction {
    type: "ACTION_TRIALTRACKER_SYNC",
    trialId: number
}

export interface ITrialTrackerSyncActionCreator {
    (trialId: number): ITrialTrackerSyncAction
}

export interface ITrialTrackerSyncSuccessAction {
    type: "ACTION_TRIALTRACKER_SYNC_SUCCESS";
    formData: Dtos.TrialTracker[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialTrackerSyncSuccessActionCreator {
    (
        formData: Dtos.TrialTracker[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialTrackerSyncSuccessAction;
}

export interface ITrialTrackerSyncFailureAction {
    type: "ACTION_TRIALTRACKER_SYNC_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialTrackerSyncFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialTrackerSyncFailureAction;
}

export type TrialTrackerAction =
    ITrialTrackerLoadAction |
    ITrialTrackerLoadByIdAction |
    ITrialTrackerLoadFailureAction |
    ITrialTrackerLoadSuccessAction |
    ITrialTrackerSaveAction |
    ITrialTrackerSaveSuccessAction |
    ITrialTrackerSaveFailureAction |
    ITrialTrackerCreateAction |
    ITrialTrackerCreateSuccessAction |
    ITrialTrackerCreateFailureAction |
    ITrialTrackerSyncAction |
    ITrialTrackerSyncSuccessAction |
    ITrialTrackerSyncFailureAction |
    ITrialTrackerClearAction | 
    ITrialTrackerFilterAction;
