import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialTrialInstitutionItemTemplateState: Dtos.TrialInstitutionItemTemplate & IRegistrationFormState = {
    id: null,
    trialId: null,
    name: null,
    description: null,
    rank: null,
    categoryId: null,
    category: null,
    auditOnSave: null,
    allowVersions: null,
    allowVersionChanges: null,
    initialVersion: null,
    collectionId: null,
    collection: null,
    active: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false
}

export interface ITrialInstitutionItemTemplateReduxFormState {
    id: IFieldState<number>,
    trialId: IFieldState<number>,
    name: IFieldState<string>,
    description: IFieldState<string>,
    rank: IFieldState<number>,
    categoryId: IFieldState<number>,
    auditOnSave: IFieldState<boolean>,
    allowVersions: IFieldState<boolean>,
    allowVersionChanges: IFieldState<boolean>,
    initialVersion: IFieldState<string>,
    active: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>,
    collectionId: IFieldState<number>,
    collection: IFieldState<Dtos.Collection>,
}
