import * as React from "react";

export interface IEmailSearchInputProps {
    email1: string;
    email2: string;
    onSearch: (email1: string, email2: string) => void;
}

class EmailSearchInput extends React.PureComponent<IEmailSearchInputProps, any> {

    constructor(props: IEmailSearchInputProps) {
        super(props);

        this.state = {
            email1: this.props.email1,
            email2: this.props.email2
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.email1 != this.props.email1 || prevProps.email2 != this.props.email2) {
            this.setState({ email1: this.props.email1, email2: this.props.email2 });
        }
    }

    render() {
        const {
            onSearch,
        } = this.props;

        let self = this;

        return <div className="generic-form-field">
            <div className="input-group">
                <input type="text"
                    placeholder={"Existing email..."} className="form-control" style={{ width: "320px" }}
                    name="email1"
                    id="email1"
                    value={this.state.email1}
                    onChange={e => {
                        self.setState({ email1: e.target.value });
                    }} />
                <input type="text"
                    placeholder={"Replacement email..."} className="form-control" style={{ width: "320px" }}
                    name="email2"
                    id="email2"
                    value={this.state.email2}
                    onChange={e => {
                        self.setState({ email2: e.target.value });
                    }} />
                <div className="input-group-append">
                    <button
                        onClick={() => onSearch(this.state.email1, this.state.email2)}
                        type="button"
                        className="btn btn-secondary"
                    >
                        {"Search"}
                    </button>
                </div>
            </div>
        </div>;
    }
}

export default EmailSearchInput;