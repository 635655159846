import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadTrialPresentationType: ITrialPresentationTypeLoadActionCreator = () => {
        return {
            type: ACTION_TRIALPRESENTATIONTYPES_LOAD
        }
    },
    LoadTrialPresentationTypeById: ITrialPresentationTypeLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TRIALPRESENTATIONTYPES_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ITrialPresentationTypeLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALPRESENTATIONTYPES_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrialPresentationTypeLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALPRESENTATIONTYPES_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTrialPresentationType: ITrialPresentationTypeSaveActionCreator = (formData) => {
        return {
            type: ACTION_TRIALPRESENTATIONTYPES_SAVE,
            formData
        }
    },
    SaveSuccess: ITrialPresentationTypeSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRIALPRESENTATIONTYPES_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrialPresentationTypeSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALPRESENTATIONTYPES_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTrialPresentationType: ITrialPresentationTypeCreateActionCreator = () => {

        return {
            type: ACTION_TRIALPRESENTATIONTYPES_CREATE
        }
    },
    CreateSuccess: ITrialPresentationTypeCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_TRIALPRESENTATIONTYPES_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: ITrialPresentationTypeCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALPRESENTATIONTYPES_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: ITrialPresentationTypeClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRIALPRESENTATIONTYPES_CLEAR,
            propertiesToClear
        }
    },
    Filter: ITrialPresentationTypeFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRIALPRESENTATIONTYPES_FILTER,
            formFilter
        }
    };



export const
    ACTION_TRIALPRESENTATIONTYPES_LOAD = "ACTION_TRIALPRESENTATIONTYPES_LOAD",
    ACTION_TRIALPRESENTATIONTYPES_LOAD_BY_ID = "ACTION_TRIALPRESENTATIONTYPES_LOAD_BY_ID",
    ACTION_TRIALPRESENTATIONTYPES_LOAD_SUCCESS = "ACTION_TRIALPRESENTATIONTYPES_LOAD_SUCCESS",
    ACTION_TRIALPRESENTATIONTYPES_LOAD_FAILURE = "ACTION_TRIALPRESENTATIONTYPES_LOAD_FAILURE",

    ACTION_TRIALPRESENTATIONTYPES_SAVE = "ACTION_TRIALPRESENTATIONTYPES_SAVE",
    ACTION_TRIALPRESENTATIONTYPES_SAVE_SUCCESS = "ACTION_TRIALPRESENTATIONTYPES_SAVE_SUCCESS",
    ACTION_TRIALPRESENTATIONTYPES_SAVE_FAILURE = "ACTION_TRIALPRESENTATIONTYPES_SAVE_FAILURE",

    ACTION_TRIALPRESENTATIONTYPES_CREATE = "ACTION_TRIALPRESENTATIONTYPES_CREATE",
    ACTION_TRIALPRESENTATIONTYPES_CREATE_SUCCESS = "ACTION_TRIALPRESENTATIONTYPES_CREATE_SUCCESS",
    ACTION_TRIALPRESENTATIONTYPES_CREATE_FAILURE = "ACTION_TRIALPRESENTATIONTYPES_CREATE_FAILURE",

    ACTION_TRIALPRESENTATIONTYPES_CLEAR = "ACTION_TRIALPRESENTATIONTYPES_CLEAR",

    ACTION_TRIALPRESENTATIONTYPES_FILTER = "ACTION_TRIALPRESENTATIONTYPES_FILTER";

export interface ITrialPresentationTypeLoadAction {
    type: "ACTION_TRIALPRESENTATIONTYPES_LOAD";
}

export interface ITrialPresentationTypeLoadActionCreator {
    (): ITrialPresentationTypeLoadAction;
}

export interface ITrialPresentationTypeLoadSuccessAction {
    type: "ACTION_TRIALPRESENTATIONTYPES_LOAD_SUCCESS";
    formData: Dtos.TrialPresentationType | Dtos.TrialPresentationType[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialPresentationTypeLoadSuccessActionCreator {
    (
        formData: Dtos.TrialPresentationType | Dtos.TrialPresentationType[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialPresentationTypeLoadSuccessAction;
}

export interface ITrialPresentationTypeLoadFailureAction {
    type: "ACTION_TRIALPRESENTATIONTYPES_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialPresentationTypeLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialPresentationTypeLoadFailureAction;
}

export interface ITrialPresentationTypeLoadByIdAction {
    type: "ACTION_TRIALPRESENTATIONTYPES_LOAD_BY_ID";
    id: number;
}

export interface ITrialPresentationTypeLoadByIdActionCreator {
    (id: number): ITrialPresentationTypeLoadByIdAction;
}


export interface ITrialPresentationTypeSaveAction {
    type: "ACTION_TRIALPRESENTATIONTYPES_SAVE";
    formData: Dtos.TrialPresentationType;

}

export interface ITrialPresentationTypeSaveActionCreator {
    (
        formData: Dtos.TrialPresentationType
    ): ITrialPresentationTypeSaveAction;
}

export interface ITrialPresentationTypeSaveSuccessAction {
    type: "ACTION_TRIALPRESENTATIONTYPES_SAVE_SUCCESS";
    formData: Dtos.TrialPresentationType;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialPresentationTypeSaveSuccessActionCreator {
    (
        formData: Dtos.TrialPresentationType,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrialPresentationTypeSaveSuccessAction;
}

export interface ITrialPresentationTypeSaveFailureAction {
    type: "ACTION_TRIALPRESENTATIONTYPES_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialPresentationTypeSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialPresentationTypeSaveFailureAction;
}

export interface ITrialPresentationTypeCreateAction {
    type: "ACTION_TRIALPRESENTATIONTYPES_CREATE"
}

export interface ITrialPresentationTypeCreateActionCreator {
    (): ITrialPresentationTypeCreateAction
}

export interface ITrialPresentationTypeCreateSuccessAction {
    type: "ACTION_TRIALPRESENTATIONTYPES_CREATE_SUCCESS";
    formData: Dtos.TrialPresentationType;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface ITrialPresentationTypeCreateSuccessActionCreator {
    (
        formData: Dtos.TrialPresentationType,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): ITrialPresentationTypeCreateSuccessAction;
}

export interface ITrialPresentationTypeCreateFailureAction {
    type: "ACTION_TRIALPRESENTATIONTYPES_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialPresentationTypeCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialPresentationTypeCreateFailureAction;
}

export interface ITrialPresentationTypeClearAction {
    type: "ACTION_TRIALPRESENTATIONTYPES_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrialPresentationTypeClearActionCreator {
    (propertiesToClear?: string[]): ITrialPresentationTypeClearAction;
}

export interface ITrialPresentationTypeFilterActionCreator {
    (formFilter: IFormFilter): ITrialPresentationTypeFilterAction;
}

export interface ITrialPresentationTypeFilterAction {
    type: "ACTION_TRIALPRESENTATIONTYPES_FILTER";
    formFilter: IFormFilter;
}

export type TrialPresentationTypeAction =
    ITrialPresentationTypeLoadAction |
    ITrialPresentationTypeLoadByIdAction |
    ITrialPresentationTypeLoadFailureAction |
    ITrialPresentationTypeLoadSuccessAction |
    ITrialPresentationTypeSaveAction |
    ITrialPresentationTypeSaveSuccessAction |
    ITrialPresentationTypeSaveFailureAction |
    ITrialPresentationTypeCreateAction |
    ITrialPresentationTypeCreateSuccessAction |
    ITrialPresentationTypeCreateFailureAction |
    ITrialPresentationTypeClearAction |
    ITrialPresentationTypeFilterAction;
