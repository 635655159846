import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_STATES_LOAD,
    ACTION_STATES_LOAD_BY_ID,

    ACTION_STATES_SAVE,

    ACTION_STATES_CREATE,

    IStateLoadAction,
    IStateLoadByIdAction,
    IStateLoadFailureAction,
    IStateLoadSuccessAction,

    IStateSaveAction,
    IStateSaveSuccessAction,
    IStateSaveFailureAction,

    IStateCreateAction,
    IStateCreateSuccessAction,
    IStateCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/states";
import { statesApi } from "../services/states";


export const
    getStatesEpic = (action$): Observable<IStateLoadSuccessAction | IStateLoadFailureAction> => {
        return action$
            .ofType(ACTION_STATES_LOAD)
            .mergeMap((action: IStateLoadAction) => {
                return statesApi
                    .getStates()
                    .map(response =>
                        LoadSuccess(response.states, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getStateByIdEpic = (action$): Observable<IStateLoadSuccessAction | IStateLoadFailureAction> => {
        return action$
            .ofType(ACTION_STATES_LOAD_BY_ID)
            .mergeMap((action: IStateLoadByIdAction) => {
                return statesApi
                    .getStateById(action.id)
                    .map(response =>
                        LoadSuccess(response.state, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setStateEpic = (action$): Observable<IStateSaveSuccessAction | IStateSaveFailureAction> => {
        return action$
            .ofType(ACTION_STATES_SAVE)
            .mergeMap((action: IStateSaveAction) => {
                return statesApi
                    .setState(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.state, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createStateEpic = (action$): Observable<IStateCreateSuccessAction | IStateCreateFailureAction> => {
        return action$
            .ofType(ACTION_STATES_CREATE)
            .mergeMap((action: IStateCreateAction) => {
                return statesApi
                    .createState()
                    .map(response =>
                        CreateSuccess(
                            response.state,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



