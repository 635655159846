import * as React from "react";
import { IFileUploadFormInputRender } from "./FileUploadFormInput";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../../constants/fontAwesomeIcons";
import * as classNames from "classnames";
import { FileState } from "../../../../dtos/Tmd.dtos";

export const
    DEFAULT_FILE_UPLOAD_VIEW: IFileUploadFormInputRender = (fileInformation, id, createUpload, clear, cancel, invalid, disabled) => {
        return createUpload(<div className="btn btn-primary">
            <span>This is a button</span>
        </div>);
    }

export const
    ATTACH_DOCUMENT_UPLOAD_VIEW: IFileUploadFormInputRender = (fileInformation, id, createUpload, clear, cancel, invalid, disabled) => {

        const hasFileInformation: boolean = fileInformation != undefined;
        const hasTemporaryFile: boolean = hasFileInformation && fileInformation.temporaryFile != undefined;
        const labelClassNames = "attach-document-upload";
        const labelStyles = { display: "block" }

        const progressBar = <div className="progress-section" onClick={(event) => { event.stopPropagation(); event.preventDefault(); }}>
            {
                hasFileInformation ?
                    <div className="progress-view">
                        <div className="progress" style={{ height: "38px" }}>
                            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: fileInformation.progress, transition: "none" }} aria-valuenow={fileInformation.progress as any} aria-valuemax={100}>
                            </div>
                            <span style={{ width: "40px", height: "18px", top: "0", left: "0", right: "0", bottom: "0" }} className=" d-flex align-items-center justify-content-center text-center m-auto position-absolute badge badge-secondary">{fileInformation.progress}</span>
                        </div>
                    </div> :
                    null
            }
        </div>

        const feedback = invalid && !disabled ?
            <span className={classNames("form-control-feedback pr-2")}>
                <FontAwesomeIcon icon={FontAwesomeIcons.Solid.BAN} fixedWidth />
            </span> :
            null;

        const uploadButton =
            <div>
                <div className="file-upload">
                    <div className="input-group" style={{ height: "38px" }}>
                        <div className="input-group-prepend">
                            <span className="input-group-text bg-primary text-white">
                                <FontAwesomeIcon icon={FontAwesomeIcons.Solid.CLOUD_UPLOAD} fixedWidth />
                            </span>
                        </div>
                        <div className={classNames("form-control rounded-right", { "is-invalid": invalid })}>
                            <div className="d-flex align-items-center position-absolute w-100 pl-2" style={{ top: "0", left: "0", right: "0", bottom: "0" }}>
                                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "no-wrap" } as any}>
                                    {
                                        hasFileInformation ?
                                            hasTemporaryFile && fileInformation.temporaryFile.name ?
                                                fileInformation.temporaryFile.name :
                                                fileInformation.state == FileState.Empty ? "Browse..." :
                                                    fileInformation.state == FileState.Success ? "Replace" :
                                                        null :
                                            "Replace"
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{ position: "relative" }}>
                            {feedback}
                        </div>
                    </div>
                </div>

            </div>



        const renderUpload = <div>
            {
                hasFileInformation ?
                    fileInformation.state == FileState.Uploading ?
                        progressBar :
                        uploadButton :
                    uploadButton
            }
        </div>

        return createUpload(renderUpload, labelClassNames, labelStyles);
    } 

export const
    SOURCE_DOCUMENT_UPLOAD_VIEW: IFileUploadFormInputRender = (fileInformation, id, createUpload, clear, cancel, invalid, disabled) => {

        const hasFileInformation: boolean = fileInformation != undefined;
        const hasTemporaryFile: boolean = hasFileInformation && fileInformation.temporaryFile != undefined;
        const labelClassNames = "source-document-upload";
        const labelStyles = { display: "block" }

        const progressBar = <div className="progress-section" onClick={(event) => { event.stopPropagation(); event.preventDefault(); }}>
            {
                hasFileInformation ?
                    <div className="progress-view">
                        <div className="progress" style={{ height: "38px" }}>
                            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: fileInformation.progress, transition: "none" }} aria-valuenow={fileInformation.progress as any} aria-valuemax={100}/>
                            <span style={{ width: "40px", height: "18px", top: "0", left: "0", right: "0", bottom: "0" }} className=" d-flex align-items-center justify-content-center text-center m-auto position-absolute badge badge-secondary">{fileInformation.progress}</span>
                        </div>
                    </div> :
                    null
            }
        </div>

        //const progressBar = <div className="progress-section" onClick={(event) => { event.stopPropagation(); event.preventDefault(); }}>
        //    {
        //        hasFileInformation ?
        //            <div className="progress-view">
        //                <div className="progress" style={{ height: "38px" }}>
        //                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: fileInformation.progress, transition: "none" }} aria-valuenow={fileInformation.progress as any} aria-valuemax={100}>
        //                    </div>
        //                    <span style={{ width: "40px", height: "18px", top: "0", left: "0", right: "0", bottom: "0"}} className=" d-flex align-items-center justify-content-center text-center m-auto position-absolute badge badge-secondary">{fileInformation.progress}</span>
        //                </div>
        //            </div> :
        //            null
        //    }
        //</div>

        const feedback = invalid && !disabled ?
            <span className={classNames("form-control-feedback pr-2")}>
                <FontAwesomeIcon icon={FontAwesomeIcons.Solid.BAN} fixedWidth />
            </span> :
            null;

        const uploadButton =
            <div>
                <div className="file-upload">
                    <div className="input-group" style={{ height: "38px" }}>
                        <div className="input-group-prepend">
                            <span className="input-group-text bg-primary text-white">
                                <FontAwesomeIcon icon={FontAwesomeIcons.Solid.CLOUD_UPLOAD} fixedWidth />
                            </span>
                        </div>
                        <div className={classNames("form-control rounded-right", { "is-invalid": invalid })}>
                            <div className="d-flex align-items-center position-absolute w-100 pl-2" style={{ top: "0", left: "0", right: "0", bottom: "0" }}>
                                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "no-wrap" } as any}>
                                    {
                                        hasFileInformation ?
                                            hasTemporaryFile && fileInformation.temporaryFile.name ?
                                                fileInformation.temporaryFile.name :
                                                fileInformation.state == FileState.Empty ?  "Browse..." :
                                                    fileInformation.state == FileState.Success ? "Replace" :
                                                        null :
                                            "Replace"
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{ position: "relative" }}>
                            {feedback}
                        </div>
                    </div>
                </div>

            </div>



        const renderUpload = <div>
            {
                hasFileInformation ?
                    fileInformation.state == FileState.Uploading ?
                        progressBar :
                        uploadButton :
                    uploadButton
            }
        </div>

        return createUpload(renderUpload, labelClassNames, labelStyles);
    } 