import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_TRIALS_LOAD,
    ACTION_TRIALS_LOAD_BY_ID,
    ACTION_TRIALS_LOAD_CURRENT_USER,
    ACTION_TRIALS_WITH_TRIALITEMINSTANCES_LOAD_BY_ID,
    ACTION_TRIALS_SAVE,

    ACTION_TRIALS_CREATE,

    ITrialLoadAction,
    ITrialLoadCurrentUserAction,
    ITrialWithTrialItemInstancesLoadByIdAction,
    ITrialWithTrialItemInstancesLoadSuccessAction,
    ITrialLoadByIdAction,
    ITrialLoadFailureAction,
    ITrialLoadSuccessAction,

    ITrialSaveAction,
    ITrialSaveSuccessAction,
    ITrialSaveFailureAction,

    ITrialCreateAction,
    ITrialCreateSuccessAction,
    ITrialCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,
    LoadWithTrialItemInstancesSuccess,

} from "../actions/trial";
import { trialsApi } from "../services/trials";
import { trialItemInstancesApi } from '../services/trialItemInstances';


export const
    getTrialsEpic = (action$): Observable<ITrialLoadSuccessAction | ITrialLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALS_LOAD)
            .mergeMap((action: ITrialLoadAction) => {
                return trialsApi
                    .getTrials()
                    .map(response =>
                        LoadSuccess(response.trials, undefined, [], [], response.lookups, undefined)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTrialsCurrentUserEpic = (action$): Observable<ITrialLoadSuccessAction | ITrialLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALS_LOAD_CURRENT_USER)
            .mergeMap((action: ITrialLoadCurrentUserAction) => {
                return trialsApi
                    .getTrialsByCurrentUser()
                    .map(response =>
                        LoadSuccess(response.trials, undefined, [], [], response.lookups, undefined)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTrialsByIdEpic = (action$): Observable<ITrialLoadSuccessAction | ITrialLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALS_LOAD_BY_ID)
            .mergeMap((action: ITrialLoadByIdAction) => {
                return trialsApi
                    .getTrialById(action.id)
                    .map(response =>
                        LoadSuccess(response.trial, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTrialWithTrialItemInstancesByIdEpic = (action$): Observable<ITrialWithTrialItemInstancesLoadSuccessAction | ITrialLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALS_WITH_TRIALITEMINSTANCES_LOAD_BY_ID)
            .mergeMap((action: ITrialWithTrialItemInstancesLoadByIdAction) => {
                return trialItemInstancesApi
                    .getTrialItemInstancesByCategory(action.id)
                    .map(response =>
                        LoadWithTrialItemInstancesSuccess(response.trial, response.formState, response.formProperties, response.formRoles, response.lookups, response.trialItemInstanceCategories, response.templateTrialItems, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setTrialEpic = (action$): Observable<ITrialSaveSuccessAction | ITrialSaveFailureAction> => {
        return action$
            .ofType(ACTION_TRIALS_SAVE)
            .mergeMap((action: ITrialSaveAction) => {
                return trialsApi
                    .setTrial(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.trial, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createTrialEpic = (action$): Observable<ITrialCreateSuccessAction | ITrialCreateFailureAction> => {
        return action$
            .ofType(ACTION_TRIALS_CREATE)
            .mergeMap((action: ITrialCreateAction) => {
                return trialsApi
                    .createTrial()
                    .map(response =>
                        CreateSuccess(response.trial, response.formState, response.formProperties, response.formRoles, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



