import * as React from 'react';
import './TrialItemInstancePage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';
import { ITrialItemInstanceReduxFormState, initialTrialItemInstanceState } from "../../../reducers/reactReduxForms/trialItemInstance";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, TextAreaFormInput, DatePickerFormInputFabric, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as TrialItemInstanceActions from '../../../actions/trialItemInstance';
import * as TrialActions from '../../../actions/trial';
import * as TrialItemInstancesPageActions from '../../../actions/pages/trialItemInstancePage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TrialLayout } from '../../index';
import * as FormHelper from '../../../helpers/formHelper';
import { GridRemove } from "../../../enumerations/GridRemove";

import {
    CollectionInstancePage
} from "../../../containers/index";

interface ITrialItemInstancePageParams {
    trialId: number;
    trialItemInstanceId: number;
}

interface ITrialItemInstancePageProps {

    form: Dtos.TrialItemInstance & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITrialItemInstanceReduxFormState;

    location: Location;
    trialId: number;
    trialItemInstanceId: number;
    templateTrialItems: Dtos.TemplateTrialItem[];
    match: match<ITrialItemInstancePageParams>;
    history: History;

    // modals
    modalTitle: string,
    modalDescription: string,
    editModalOpen: boolean,
    collectionInstanceId: number,
    createVersionModalOpen: boolean,

    // From state
    trialItemInstance: Dtos.TrialItemInstance;
    trial: Dtos.Trial;
    lookups: Dtos.Lookup[];

    loadingTrial: boolean;

    loadingTrialItemInstance: boolean;
    loadTrialItemInstanceSuccess: boolean;
    loadTrialItemInstanceFailure: boolean;

    savingTrialItemInstance: boolean;
    saveTrialItemInstanceSuccess: boolean;
    saveTrialItemInstanceFailure: boolean;

    creatingTrialItemInstance: boolean;
    createTrialItemInstanceSuccess: boolean;
    createTrialItemInstanceFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface ITrialItemInstancePageActions {
    clearTrialItemInstance: TrialItemInstanceActions.ITrialItemInstanceClearActionCreator;
    loadTrialItemInstance: TrialItemInstanceActions.ITrialItemInstanceLoadByIdActionCreator;
    saveTrialItemInstance: TrialItemInstanceActions.ITrialItemInstanceSaveActionCreator;
    createTrialItemInstance: TrialItemInstanceActions.ITrialItemInstanceCreateActionCreator;

    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    setEditModelViewState: TrialItemInstancesPageActions.ITrialItemInstancesPageSetEditModalViewStateActionCreator;
    setCreateVersionModelViewState: TrialItemInstancesPageActions.ITrialItemInstancesPageSetCreateVersionModalViewStateActionCreator;

}

type TrialItemInstancePageProps = ITrialItemInstancePageProps & ITrialItemInstancePageActions;

const reduxFormName: string = "reduxForms.trialItemInstance";

class TrialItemInstancePage extends React.PureComponent<TrialItemInstancePageProps, any> {

    constructor(props: TrialItemInstancePageProps) {
        super(props);

        this.clearTrialItemInstance = this.clearTrialItemInstance.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTrialItemInstance,
            createTrialItemInstance,
            trialItemInstanceId,
            trialId,
            loadTrial
        } = this.props;

        if (trialItemInstanceId) {
            loadTrialItemInstance(trialItemInstanceId);
        } else if (!trialItemInstanceId) {
            createTrialItemInstance(trialId, 0, 0);
        }
        loadTrial(trialId);
    }

    componentDidUpdate(prevProps: TrialItemInstancePageProps, prevState) {
        const {
            form,
            trialId,
            trialItemInstanceId,
            trialItemInstance,
            loadTrialItemInstance,
            loadTrial,
            loadingTrialItemInstance,
            loadTrialItemInstanceSuccess,
            savingTrialItemInstance,
            saveTrialItemInstanceSuccess,
            createTrialItemInstance,
            validationFailures,
            creatingTrialItemInstance,
            createTrialItemInstanceSuccess,
            navigate
        } = this.props;

        if (trialId && prevProps.trialId != trialId) {
            loadTrial(trialId);
        }

        if (trialItemInstanceId && prevProps.trialItemInstanceId != trialItemInstanceId) {
            loadTrialItemInstance(trialItemInstanceId);
        }
        else if (!trialItemInstanceId && prevProps.trialItemInstanceId) {
            createTrialItemInstance(trialId, 0, 0);
        }

        if (!loadingTrialItemInstance && prevProps.loadingTrialItemInstance) {
            if (loadTrialItemInstanceSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingTrialItemInstance && prevProps.savingTrialItemInstance) {
            if (saveTrialItemInstanceSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/trials/" + trialId + "/items")
                } else if (!form.saveAndReturn && trialItemInstanceId && trialItemInstanceId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !trialItemInstanceId && trialItemInstance && trialItemInstance.id > 0) {
                    navigate("/trials/" + trialId + "/items/" + trialItemInstance.id);
                    this.loadForm();
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingTrialItemInstance && prevProps.creatingTrialItemInstance) {
            if (createTrialItemInstanceSuccess) {
                this.loadForm();
            }
            else {

            }
        }
    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearTrialItemInstance();
        loadForm(reduxFormName, initialTrialItemInstanceState);
        resetForm(reduxFormName);
    }

    clearTrialItemInstance() {
        this.props.clearTrialItemInstance();
        this.props.clearTrial();
    }

    showTrialItemInstance(): boolean {
        const {
            loadingTrial,
            loadingTrialItemInstance,
            loadTrialItemInstanceSuccess
        } = this.props

        if (!loadingTrial && !loadingTrialItemInstance && loadTrialItemInstanceSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrial,
            loadingTrialItemInstance,
        } = this.props;

        return <TrialLayout loading={loadingTrial || loadingTrialItemInstance} permission={Dtos.Permission.TrialItemInstanceView}>
            {this.renderContent()}
            {this.renderItemModal()}
        </TrialLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingTrialItemInstance,
            permissions,
            templateTrialItems
        } = this.props;

        let noPermissionToEditMessage = false;
        if ((form.id && form.id > 0) && validationFailures && validationFailures.filter(f => f.errorCode == "PR-005").length > 0) {
            noPermissionToEditMessage = true;
        }

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.TrialItemInstanceUpdate, Dtos.Permission.TrialItemInstanceCreate);
        const hasVersionUpdatePermission: boolean = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialItemInstanceVersionUpdate, form.id);
        const formNew: boolean = FormHelper.isNew(formState, form);

        const formDisabled: boolean = !hasPermission || savingTrialItemInstance || (formState && formState.formStatus == Dtos.FormStatus.Disabled) || noPermissionToEditMessage;

        let canEditVersion: boolean = (formNew) || (form && form.templateTrialItem && form.templateTrialItem.allowVersionChanges);
        let hideVersion: boolean = false;
        let placeholderDescription: string = form.originalDescription;
        const currentTemplateTrialItem = templateTrialItems == undefined ? undefined : templateTrialItems.find(f => f.id == form.templateTrialItemId);
        if (currentTemplateTrialItem) {// && currentTemplateTrialItem.allowIntraVersions) {
        //    hideVersion = false;
            placeholderDescription = currentTemplateTrialItem.description;
        }

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            templateTrialItem: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    templateTrialItemId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: !formNew,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialItemInstance", "TemplateTrialItemId", lookups), form.templateTrialItemId)
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            //debugger;

                            // Set the default version based on the template
                            if (templateTrialItems && value != undefined && value != form.templateTrialItemId) {
                                let templateTrialItem = templateTrialItems.find(f => f.id == value);
                                if (templateTrialItem) {
                                    changeForm(reduxFormName + parentModel + '.version', templateTrialItem.initialVersion);
                                }
                            }
                        },
                        size: 1
                    },
                }
            },
            descriptionGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    description: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextAreaFormInput,
                        inputDisabled: formDisabled,
                        size: 1,
                        hide: hideVersion,
                        inputProps: {
                            placeholder: placeholderDescription
                        }
                    },
                },
                hide: hideVersion,
            },
            currentVersion: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    /*trialItemInstanceStatusId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialItemInstance", "TrialItemInstanceStatusId", lookups), form.trialItemInstanceStatusId)
                        },
                        size: 2,
                    },*/
                    version: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled || !canEditVersion,
                        size: 2,
                        hide: hideVersion
                    },
                    versionDate: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: "Version Date",
                        size: 2
                    },
                },
                hide: hideVersion,
            },
            assign: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    assignmentTypeId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialItemInstance", "AssignmentTypeId", lookups), form.assignmentTypeId)
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            //debugger;
                            if (value && value == 1) {
                                changeForm(reduxFormName + parentModel + '.assignedToId', null);
                            } else if (value && value == 2) {
                                changeForm(reduxFormName + parentModel + '.assignedRoleId', null);
                            } else {
                                changeForm(reduxFormName + parentModel + '.assignedToId', null);
                                changeForm(reduxFormName + parentModel + '.assignedRoleId', null);
                            }
                        },
                        size: 2
                    },
                    assignedToId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialItemInstance", "AssignedToId", lookups), form.assignedToId)
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + '.assignedTo', null);
                            changeForm(reduxFormName + parentModel + '.assignedRole', null);
                        },
                        size: 2,
                        hide: form.assignmentTypeId != 2
                    },
                    assignedRoleId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialItemInstance", "AssignedRoleId", lookups), form.assignedRoleId)
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + '.assignedTo', null);
                            changeForm(reduxFormName + parentModel + '.assignedRole', null);
                        },
                        size: 2,
                        hide: form.assignmentTypeId != 1
                    },
                }
            },
            activeGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    active: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: CheckboxFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            label: "Active"
                        },
                        inputLabel: " ",
                        size: 2
                    },
                },
            },
            trialItemPreviousInstancesHeader: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="border-0 d-flex pt-4 ">
                    <h3>Previous Versions</h3>
                </div>
            },
            sortOrderGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    sortOrderId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialItemInstance", "SortOrderId", lookups), form.sortOrderId)
                        },
                        size: 2
                    },
                }
            },
            trialItemPreviousInstances: {
                type: RegistrationComponent.RegistrationGrid,
                label: "Versions",
                disabled: formDisabled || !hasVersionUpdatePermission,
                formName: "TrialItemPreviousInstance",
                className: "",
                minRows: 0,
                columnStyles: [
                    {
                        width: "340px"
                    },
                    {
                        width: 'auto'
                    },
                    {
                        width: "220px"
                    },
                    {
                        width: "125px"
                    },
                ],
                removeConfig: !hasVersionUpdatePermission ? GridRemove.None : GridRemove.All,
                hideAdd: true,
                components: {
                    version: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled || !hasVersionUpdatePermission,
                        inputLabel: getFormPropertyLabel("TrialItemPreviousInstance", "Version", formProperties),
                    },
                    description: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled || !hasVersionUpdatePermission,
                        inputLabel: getFormPropertyLabel("TrialItemPreviousInstance", "Description", formProperties),
                    },
                    versionDate: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled || !hasVersionUpdatePermission,
                        inputLabel: getFormPropertyLabel("TrialItemPreviousInstance", "Version Date", formProperties),
                    },
                },
                initialState: initialTrialItemInstanceState,
                onRemove: (model: string) => {
                    console.log(model);
                    changeForm(model + ".active", false);
                    return true; // this was handled here
                },
                filterRow: (data: any) => {
                    if (data.active != true) {
                        return false
                    }
                    return true;
                }
            },
        };

        return <div>
            <div className="row">
                <div className={"col mb-2"}>
                    <h3>Trial Item</h3>
                </div>
            </div>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "trialItemInstance", formComponents, lookups, validationFailures, formProperties, changeForm)
                }

                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
        </div>

    }


    renderItemModal() {
        const {
            editModalOpen,
            setEditModelViewState,
            collectionInstanceId,
            loadTrialItemInstance,
            trialItemInstanceId,
            trialId,
            modalTitle,
            modalDescription,
            permissions,
            savingTrialItemInstance,
            form,
            formState
        } = this.props

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.TrialItemInstanceUpdate, Dtos.Permission.TrialItemInstanceCreate);
        const formDisabled: boolean = !hasPermission || savingTrialItemInstance || (formState && formState.formStatus == Dtos.FormStatus.Disabled);


        if (collectionInstanceId && collectionInstanceId > 0) {
            return <Modal
                open={editModalOpen}
                disableCloseOnOverlayClick={true}
                onClose={() => {
                    setEditModelViewState(false, null, "", "");
                    loadTrialItemInstance(trialItemInstanceId);
                }}
                size={ModalSize.Xl}>
                <div className="modal-header">
                    <h5 className="modal-title">{setModalTitle(undefined, modalTitle)}{modalDescription ? <><br /><small style={{ color: '#444' }}>{modalDescription}</small></> : undefined}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => {
                        setEditModelViewState(false, null, "", "");
                        loadTrialItemInstance(trialItemInstanceId);
                    }}>
                        <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                    </button>

                </div>
                <div className="modal-body">
                    <CollectionInstancePage
                        readonly={formDisabled}
                        collectionInstanceId={collectionInstanceId}
                        onClose={() => {
                            setEditModelViewState(false, null, "", "");
                            loadTrialItemInstance(trialItemInstanceId);
                        }} />
                </div>
            </Modal>
        }
    }


    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
        </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.TrialItemInstance) {
        const {
            saveTrialItemInstance
        } = this.props;

        if (data) {
            let changes: Dtos.TrialItemInstance = {} as Dtos.TrialItemInstance;
            saveTrialItemInstance(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.Trial) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            trialId,
            navigate
        } = this.props;

        navigate("/trials/" + trialId + "/items");
    }

    loadForm() {
        const {
            loadForm,
            trialItemInstance,
        } = this.props

        let extraData: Dtos.TrialItemInstance = {} as Dtos.TrialItemInstance;

        loadForm(reduxFormName, Object.assign({ ...trialItemInstance }, extraData));
    }
}


const mapStateToProps = (state: ITmdState, ownProps: TrialItemInstancePageProps): ITrialItemInstancePageProps => {

    let trialItemInstance: Dtos.TrialItemInstance | undefined = !(state.trialItemInstance.formData instanceof Array) ? state.trialItemInstance.formData : undefined;
    let trial: Dtos.Trial | undefined = !(state.trials.formData instanceof Array) ? state.trials.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,
        trialItemInstanceId: ownProps.match && ownProps.match.params.trialItemInstanceId.toString() != "create" ? ownProps.match.params.trialItemInstanceId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trialItemInstance: trialItemInstance!,
        templateTrialItems: state.trialItemInstance.templateTrialItems,
        trial: trial!,

        modalTitle: state.trialItemInstancePage.name,
        modalDescription: state.trialItemInstancePage.description,

        editModalOpen: state.trialItemInstancePage.editModalOpen,
        collectionInstanceId: state.trialItemInstancePage.collectionInstanceId,

        createVersionModalOpen: state.trialItemInstancePage.createVersionModalOpen,

        loadingTrialItemInstance: state.trialItemInstance.loadState && state.trialItemInstance.loadState.status == RequestState.Pending,
        loadTrialItemInstanceSuccess: state.trialItemInstance.loadState && state.trialItemInstance.loadState.status == RequestState.Success,
        loadTrialItemInstanceFailure: state.trialItemInstance.loadState && state.trialItemInstance.loadState.status == RequestState.Failure,

        savingTrialItemInstance: state.trialItemInstance.saveState && state.trialItemInstance.saveState.status == RequestState.Pending,
        saveTrialItemInstanceSuccess: state.trialItemInstance.saveState && state.trialItemInstance.saveState.status == RequestState.Success,
        saveTrialItemInstanceFailure: state.trialItemInstance.saveState && state.trialItemInstance.saveState.status == RequestState.Failure,

        creatingTrialItemInstance: state.trialItemInstance.createState && state.trialItemInstance.createState.status == RequestState.Pending,
        createTrialItemInstanceSuccess: state.trialItemInstance.createState && state.trialItemInstance.createState.status == RequestState.Success,
        createTrialItemInstanceFailure: state.trialItemInstance.createState && state.trialItemInstance.createState.status == RequestState.Failure,

        loadingTrial: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,

        user: state.user.data,
        permissions: permissions,
        lookups: state.trialItemInstance.lookups,

        form: state.reduxForms.trialItemInstance,
        formState: state.trialItemInstance.formState,
        formProperties: state.trialItemInstance.formProperties,

        reduxFormState: state.reduxForms.formState.trialItemInstance,
        validationFailures: trialItemInstance ?
            state.trialItemInstance.validationFailures :
            undefined,

    };
};

const mapDispatchToProps = (dispatch): ITrialItemInstancePageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadTrialItemInstance: bindActionCreators(TrialItemInstanceActions.LoadTrialItemInstanceById, dispatch),
        clearTrialItemInstance: bindActionCreators(TrialItemInstanceActions.Clear, dispatch),
        createTrialItemInstance: bindActionCreators(TrialItemInstanceActions.CreateTrialItemInstance, dispatch),
        saveTrialItemInstance: bindActionCreators(TrialItemInstanceActions.SaveTrialItemInstance, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch),

        setEditModelViewState: bindActionCreators(TrialItemInstancesPageActions.setEditModalViewState, dispatch),
        setCreateVersionModelViewState: bindActionCreators(TrialItemInstancesPageActions.setCreateVersionModalViewState, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialItemInstancePage);
