import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_STANDARDEMAILTEMPLATE_LOAD,
    ACTION_STANDARDEMAILTEMPLATE_LOAD_BY_ID,

    ACTION_STANDARDEMAILTEMPLATE_SAVE,

    ACTION_STANDARDEMAILTEMPLATE_CREATE,

    IStandardEmailTemplateLoadAction,
    IStandardEmailTemplateLoadByIdAction,
    IStandardEmailTemplateLoadFailureAction,
    IStandardEmailTemplateLoadSuccessAction,

    IStandardEmailTemplateSaveAction,
    IStandardEmailTemplateSaveSuccessAction,
    IStandardEmailTemplateSaveFailureAction,

    IStandardEmailTemplateCreateAction,
    IStandardEmailTemplateCreateSuccessAction,
    IStandardEmailTemplateCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/standardEmailTemplates";
import { standardEmailTemplateApi } from "../services/standardEmailTemplate";

export const
    getStandardEmailTemplatesEpic = (action$): Observable<IStandardEmailTemplateLoadSuccessAction | IStandardEmailTemplateLoadFailureAction> => {
        return action$
            .ofType(ACTION_STANDARDEMAILTEMPLATE_LOAD)
            .mergeMap((action: IStandardEmailTemplateLoadAction) => {
                return standardEmailTemplateApi
                    .getStandardEmailTemplates()
                    .map(response =>
                        LoadSuccess(response.standardEmailTemplates, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getStandardEmailTemplateByIdEpic = (action$): Observable<IStandardEmailTemplateLoadSuccessAction | IStandardEmailTemplateLoadFailureAction> => {
        return action$
            .ofType(ACTION_STANDARDEMAILTEMPLATE_LOAD_BY_ID)
            .mergeMap((action: IStandardEmailTemplateLoadByIdAction) => {
                return standardEmailTemplateApi
                    .getStandardEmailTemplateById(action.id)
                    .map(response =>
                        LoadSuccess(response.standardEmailTemplate, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setStandardEmailTemplateEpic = (action$): Observable<IStandardEmailTemplateSaveSuccessAction | IStandardEmailTemplateSaveFailureAction> => {
        return action$
            .ofType(ACTION_STANDARDEMAILTEMPLATE_SAVE)
            .mergeMap((action: IStandardEmailTemplateSaveAction) => {
                return standardEmailTemplateApi
                    .setStandardEmailTemplate(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.standardEmailTemplate, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createStandardEmailTemplateEpic = (action$): Observable<IStandardEmailTemplateCreateSuccessAction | IStandardEmailTemplateCreateFailureAction> => {
        return action$
            .ofType(ACTION_STANDARDEMAILTEMPLATE_CREATE)
            .mergeMap((action: IStandardEmailTemplateCreateAction) => {
                return standardEmailTemplateApi
                    .createStandardEmailTemplate()
                    .map(response =>
                        CreateSuccess(
                            response.standardEmailTemplate,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



