import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const sitelocationsApi = {
    getSiteLocations: (): Observable<Dtos.GetSiteLocationsResponse> => {
        //Create the request.
        let request: Dtos.GetSiteLocations = new Dtos.GetSiteLocations();

        //Send request.
        const response: Promise<Dtos.GetSiteLocationsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getSiteLocationById: (id: number): Observable<Dtos.GetSiteLocationByIdResponse> => {
        //Create the request.
        let request: Dtos.GetSiteLocationById = new Dtos.GetSiteLocationById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetSiteLocationByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setSiteLocation: (siteLocation: Dtos.SiteLocation): Observable<Dtos.SetSiteLocationResponse> => {

        let request: Dtos.SetSiteLocation = new Dtos.SetSiteLocation();

        request.siteLocation = siteLocation;

        // send request
        const response: Promise<Dtos.SetSiteLocationResponse> = client.post(request);

        return Observable.from(response);

    },
    createSiteLocation: (): Observable<Dtos.CreateSiteLocationResponse> => {
        //Create the request.
        let request: Dtos.CreateSiteLocation = new Dtos.CreateSiteLocation();

        //Send request.
        const response: Promise<Dtos.CreateSiteLocationResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}