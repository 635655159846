import * as React from 'react';

import * as classNames from "classnames";
import './TrialItemImportPage.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";


import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu, TruncatedCell } from "../../../components/layouts/index";
import { ITrialItemImportReduxFormState, initialTrialItemImportState } from "../../../reducers/reactReduxForms/trialItemImport";
import * as TrialActions from '../../../actions/trial';
import * as TrialItemImportActions from '../../../actions/trialItemImport';
import * as ToggleActions from '../../../actions/pages/togglePage';
import * as ToggleHelper from "../../../helpers/toggleHelper";
import { TogglePageItem } from '../../../reducers/pages/togglePage';

import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInputFabric, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";

import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../../components/form';
import { TrialSubMenu } from '../../../helpers/subMenuHelper';
import { TrialLayout } from '../../index';

interface ITrialItemImportPageParams {
    trialId: number;
}

interface ITrialItemImportPageProps {

    form: Dtos.TrialItemImport & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITrialItemImportReduxFormState;
    lookups: Dtos.Lookup[];
    validationFailures: Dtos.ResponseError[];
    selectedItems: number[];
    trialItemsByCategory: Dtos.TrialItemCategory[];

    location: Location;
    trialId: number;
    sourceTrialId: number;
    match: match<ITrialItemImportPageParams>;
    history: History;

    // From state
    trialItemImport: Dtos.TrialItemImport;
    loading: boolean;
    loadSuccess: boolean;
    loadFailure: boolean;


    saving: boolean;
    saveSuccess: boolean;
    saveFailure: boolean;

    user: Dtos.User,

    toggleItems: TogglePageItem[]
}

interface ITrialItemImportPageActions {
    clear: TrialItemImportActions.ITrialItemImportClearActionCreator;
    load: TrialItemImportActions.ITrialItemImportLoadByIdActionCreator;
    save: TrialItemImportActions.ITrialItemImportSaveActionCreator;

    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    toggleExpand: ToggleActions.IToggleExpandedStateActionCreator;
}

type TrialItemImportPageProps = ITrialItemImportPageProps & ITrialItemImportPageActions;

const formName: string = "trialItemImport";
const reduxFormName: string = "reduxForms.trialItemImport";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class TrialItemImportPage extends React.PureComponent<TrialItemImportPageProps, any> {

    constructor(props: TrialItemImportPageProps) {
        super(props);

        this.clear = this.clear.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount() {
        const {
            load,
            navigate,
            navigateReplace,
            trialId,
            trialItemImport,
            loadTrial
        } = this.props;
        if (trialItemImport) {
            load(trialId, trialItemImport.trialId);
        } else {
            load(trialId, 0);
        }
        loadTrial(trialId);
    }

    componentWillReceiveProps(nextProps: TrialItemImportPageProps) {
    }

    componentDidUpdate(prevProps: TrialItemImportPageProps, prevState) {
        const {
            form,
            trialId,
            sourceTrialId,
            trialItemImport,
            navigateReplace,
            load,
            loading,
            loadSuccess,
            saving,
            saveSuccess,
            saveFailure,
            validationFailures,
            navigate
        } = this.props


        if ((trialId && prevProps.trialId != trialId)) {
            load(trialId, sourceTrialId);
        }

        if (!loading && prevProps.loading) {
            if (loadSuccess) {
                this.loadForm();
            }
        }

        if (!saving && prevProps.saving) {
            if (saveSuccess) {
                if (validationFailures && validationFailures.length > 0) {
                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);
                } else {
                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);
                }
                if (form.saveAndReturn) {
                    navigate("/trials/" + trialId + "/trialitems")
                } else {
                    this.loadForm();
                }
            } else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }
    }

    componentWillUpdate(nextProps: TrialItemImportPageProps) {
    }

    componentWillUnmount() {
        this.clear();
    }

    clear() {
        this.props.clear();
        this.props.clearTrial();
    }

    show(): boolean {
        const {
            loading,
            loadSuccess
        } = this.props

        if (!loading && loadSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loading
        } = this.props

        return (
            <TrialLayout loading={loading} permission={Dtos.Permission.TrialInstitutionItemTemplateImport}>{this.renderContent()}</TrialLayout>
        );
    }

    renderContent() {
        const {
            form,
            loading,
            trialItemImport,
            navigate,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            user,
            trialId,
            selectedItems,
            load,
            saving,
            resetForm
        } = this.props;

        const formDisabled: boolean = saving || (formState && formState.formStatus == Dtos.FormStatus.Disabled);
        const formTrialSelected: boolean = !formDisabled && form.trialId && form.trialId > 0;
        const formCanSave: boolean = !formDisabled && selectedItems && selectedItems.length > 0;

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            trialId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("TrialItemImport", "TrialId", lookups), form.trialId)
                },
                inputLabel: "Import from the item templates or select a trial to copy from",
                changeAction: (model, value, form, parentModel) => {
                    console.log("parentModel", parentModel)
                    changeForm(model, value);
                    if (value != form.trialId) {
                        resetForm(reduxFormName);
                        load(trialId, value);
                    }
                },
            },
            addGroups: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled || !form.trialId || form.trialId == 0,
                inputProps: {
                    label: "Link groups attached to selected items"
                },
                inputLabel: " ",
                hide: !formTrialSelected
            },
        };

        const showTemplates = form.trialId ? false : true;

        return <div className="trail-item-import">
            <h3>Institution Item Template Import</h3>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "trialItemImport", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-2">
                    {!showTemplates &&
                        this.renderTable()
                    }
                    {(showTemplates) &&
                        <>
                            {
                                this.renderTemplateTable()
                            }
                        </>
                    }
                </div>
                <div className="mt-4 mb-4 text-right">
                    <button disabled={!formCanSave}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>
                    <button disabled={!formCanSave}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
        </div>
    }

    renderTable() {
        const {
            trialItemImport,
            trialItemsByCategory,
            toggleItems,
            toggleExpand
        } = this.props;



        if (!trialItemsByCategory || trialItemsByCategory.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no items to view.</span>
            </div>
        }

        const showPagination = trialItemsByCategory.length > DEFAULT_PAGE_SIZE;
        const trialItemImportId = trialItemImport ? trialItemImport.id : 0;

        return <div>
            {trialItemsByCategory.map((item, index) => {
                const key = "trial-items-page-" + item.categoryId + "-" + trialItemImportId;
                const expanded = ToggleHelper.isToggleExpanded(toggleItems, key);
                return (
                    <div key={"trial-items-category" + item.category.id}>
                        <div className={tableHeaderClass} style={tableStyleClass} onClick={() => toggleExpand(key)}>
                            <div>
                                <FontAwesomeIcon icon={!expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" />
                            </div>
                            <span>{item.category.value}</span>
                        </div>
                        {expanded && (
                            <ReactTable
                                data={item.trialItemSummaries}
                                pageSizeOptions={PAGE_SIZE_OPTIONS}
                                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : item.trialItemSummaries.length}
                                pageSize={showPagination ? undefined : item.trialItemSummaries.length}
                                showPagination={showPagination}
                                className="-striped -highlight -clickable mb-3"
                                noDataText="There are currently no trial items for this category"
                                resizable={false}
                                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                                    return {
                                        onClick: (e, handleOriginal) => {
                                            this.onSelectedItemChanged(rowInfo.original.id, !this.isItemSelected(rowInfo.original.id))

                                            if (handleOriginal) {
                                                handleOriginal()
                                            }

                                        }
                                    }
                                }}
                                columns={
                                    [
                                        {
                                            id: "id",
                                            Header: " ",
                                            accessor: "id",
                                            className: "d-flex align-items-center justify-content-center",
                                            headerClassName: "text-center",
                                            maxWidth: 50,
                                            Cell: (props) => <FontAwesomeIcon icon={this.isItemSelected(props.value) ? FontAwesomeIcons.Light.CHECK : FontAwesomeIcons.Light.UNCHECKED} fixedWidth />
                                        },
                                        {
                                            id: "id",
                                            Header: ' ',
                                            accessor: "id",
                                            className: "d-flex align-items-center justify-content-center",
                                            maxWidth: 50,
                                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.TRIALITEM} fixedWidth />
                                        },
                                        {
                                            id: "name",
                                            Header: "Name",
                                            accessor: "name",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            Cell: (props) => <TruncatedCell value={props.value} />
                                        },
                                        {
                                            id: "description",
                                            Header: "Description",
                                            accessor: "description",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            Cell: (props) => <TruncatedCell value={props.value} />
                                        },
                                        {
                                            id: "groupDisplay",
                                            Header: "Groups",
                                            accessor: "groupDisplay",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            maxWidth: 320,
                                            Cell: (props) => <TruncatedCell value={props.value} />
                                        },
                                        {
                                            id: "initialVersion",
                                            Header: "Initial Version",
                                            accessor: "initialVersion",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            maxWidth: 220,
                                            Cell: (props) => <TruncatedCell value={props.value} />
                                        }
                                    ]
                                }
                            />
                        )}
                    </div>
                )
            }
            )}
        </div>
    }

    renderTemplateTable() {
        const {
            trialItemImport,
            toggleItems,
            toggleExpand
        } = this.props;



        if (!trialItemImport || trialItemImport.templateCategories.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no items to view.</span>
            </div>
        }

        const categories = trialItemImport.templateCategories;
        const showPagination = false;
        const trialItemImportId = trialItemImport ? trialItemImport.id : 0;

        return <div>
            {categories.map((item, index) => {
                const key = "trial-items-page-" + item.id + "-" + trialItemImportId;
                const expanded = ToggleHelper.isToggleExpanded(toggleItems, key);
                return (
                    <div key={"trial-items-category" + item.id}>
                        <div className={tableHeaderClass} style={tableStyleClass} onClick={() => toggleExpand(key)}>
                            <div>
                                <FontAwesomeIcon icon={!expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" />
                            </div>
                            <span>{item.value}</span>
                        </div>
                        {(expanded && item.trialInstitutionItemTemplates) && (
                            <ReactTable
                                data={item.trialInstitutionItemTemplates}
                                pageSizeOptions={PAGE_SIZE_OPTIONS}
                                defaultPageSize={item.trialInstitutionItemTemplates.length}
                                pageSize={showPagination ? undefined : item.trialInstitutionItemTemplates.length}
                                showPagination={showPagination}
                                className="-striped -highlight -clickable mb-3"
                                noDataText="There are currently no trial items for this category"
                                resizable={false}
                                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                                    return {
                                        onClick: (e, handleOriginal) => {
                                            this.onSelectedItemChanged(rowInfo.original.id, !this.isItemSelected(rowInfo.original.id))

                                            if (handleOriginal) {
                                                handleOriginal()
                                            }

                                        }
                                    }
                                }}
                                columns={
                                    [
                                        {
                                            id: "id",
                                            Header: " ",
                                            accessor: "id",
                                            className: "d-flex align-items-center justify-content-center",
                                            headerClassName: "text-center",
                                            maxWidth: 50,
                                            Cell: (props) => <FontAwesomeIcon icon={this.isItemSelected(props.value) ? FontAwesomeIcons.Light.CHECK : FontAwesomeIcons.Light.UNCHECKED} fixedWidth />
                                        },
                                        {
                                            id: "id",
                                            Header: ' ',
                                            accessor: "id",
                                            className: "d-flex align-items-center justify-content-center",
                                            maxWidth: 50,
                                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.TRIALITEM} fixedWidth />
                                        },
                                        {
                                            id: "name",
                                            Header: "Name",
                                            accessor: "name",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            Cell: (props) => <TruncatedCell value={props.value} />
                                        },
                                        {
                                            id: "description",
                                            Header: "Description",
                                            accessor: "description",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            Cell: (props) => <TruncatedCell value={props.value} />
                                        },
                                        {
                                            id: "initialVersion",
                                            Header: "Initial Version",
                                            accessor: "initialVersion",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            maxWidth: 220,
                                            Cell: (props) => <TruncatedCell value={props.value} />
                                        }
                                    ]
                                }
                            />
                        )}
                    </div>
                )
            }
            )}
        </div>
    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
        </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The items hves been successfully saved to the trial</div>
    }

    handleOnSubmit(data: Dtos.TrialItemImport) {
        const {
            save
        } = this.props;

        if (data) {
            let changes: Dtos.TrialItemImport = {} as Dtos.TrialItemImport;
            save(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.TrialInstitution) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onSelectedItemChanged(id: number, selected: boolean) {
        const {
            selectedItems,
            changeForm
        } = this.props

        if (selectedItems) {
            let newArray = [...selectedItems];
            var index = newArray.indexOf(id);
            if (selected) {
                // add if not exists
                if (index == -1) {
                    newArray.push(id);
                    changeForm(reduxFormName + '.selectedItems', newArray);
                }
            } else {
                // remove if not exists
                if (index > -1) {
                    newArray.splice(index, 1);
                    changeForm(reduxFormName + '.selectedItems', newArray);
                }
            }
        }
    }

    isItemSelected(id: number) {
        const {
            selectedItems,
        } = this.props

        if (selectedItems) {
            var index = selectedItems.indexOf(id);
            return index > -1;
        }
        return false
    }

    onCancel() {
        const {
            trialId,
            navigate
        } = this.props;

        navigate("/trials/" + trialId + "/trialitems");
    }

    loadForm() {
        const {
            loadForm,
            trialItemImport,
        } = this.props

        let extraData: Dtos.TrialItemImport = {} as Dtos.TrialItemImport;

        loadForm(reduxFormName, Object.assign({ ...trialItemImport }, extraData));
    }
}

const mapStateToProps = (state: ITmdState, ownProps: TrialItemImportPageProps): ITrialItemImportPageProps => {

    return {

        form: state.reduxForms.trialItemImport,
        lookups: state.trialItemImport.lookups,
        formState: state.trialItemImport.formState,
        formProperties: state.trialItemImport.formProperties,
        reduxFormState: state.reduxForms.formState.trialItemImport,
        validationFailures: state.trialItemImport.validationFailures,
        selectedItems: state.reduxForms.trialItemImport.selectedItems,
        trialItemsByCategory: state.reduxForms.trialItemImport && state.reduxForms.trialItemImport.trial ? state.reduxForms.trialItemImport.trial.trialItemCategories : [],

        match: ownProps.match,
        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,
        sourceTrialId: state.reduxForms.trialItemImport ? state.reduxForms.trialItemImport.trialId : undefined,

        history: ownProps.history,
        location: state.routing.location,

        trialItemImport: state.trialItemImport.formData,

        loading: state.trialItemImport.loadState && state.trialItemImport.loadState.status == RequestState.Pending,
        loadSuccess: state.trialItemImport.loadState && state.trialItemImport.loadState.status == RequestState.Success,
        loadFailure: state.trialItemImport.loadState && state.trialItemImport.loadState.status == RequestState.Failure,

        saving: state.trialItemImport.saveState && state.trialItemImport.saveState.status == RequestState.Pending,
        saveSuccess: state.trialItemImport.saveState && state.trialItemImport.saveState.status == RequestState.Success,
        saveFailure: state.trialItemImport.saveState && state.trialItemImport.saveState.status == RequestState.Failure,

        user: state.user.data,

        toggleItems: state.togglePage.items
    };
};

const mapDispatchToProps = (dispatch): ITrialItemImportPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        load: bindActionCreators(TrialItemImportActions.Load, dispatch),
        clear: bindActionCreators(TrialItemImportActions.Clear, dispatch),
        save: bindActionCreators(TrialItemImportActions.Save, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch),

        toggleExpand: bindActionCreators(ToggleActions.ToggleExpanded, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialItemImportPage);
