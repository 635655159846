import * as Dtos from "../dtos/Tmd.dtos";

export const
    LoadPersonnelRoleById: IPersonnelRoleLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_PERSONNELROLE_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IPersonnelRoleLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_PERSONNELROLE_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IPersonnelRoleLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PERSONNELROLE_LOAD_FAILURE,
            responseStatus
        }
    },

    CreatePersonnelRole: IPersonnelRoleCreateActionCreator = (personnelId, trialId, institutionId, groupId, departmentId) => {

        return {
            type: ACTION_PERSONNELROLE_CREATE,
            personnelId, 
            trialId, 
            institutionId, 
            groupId,
            departmentId
        }
    },
    CreateSuccess: IPersonnelRoleCreateSuccessActionCreator = (formData, formState, formProperties, lookups) => {
        return {
            type: ACTION_PERSONNELROLE_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups
        }
    },
    CreateFailure: IPersonnelRoleCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PERSONNELROLE_CREATE_FAILURE,
            responseStatus
        }
    },

    SavePersonnelRole: IPersonnelRoleSaveActionCreator = (formData) => {
        return {
            type: ACTION_PERSONNELROLE_SAVE,
            formData
        }
    },
    SaveSuccess: IPersonnelRoleSaveSuccessActionCreator = (formData, formState, lookups, responseStatus) => {
        return {
            type: ACTION_PERSONNELROLE_SAVE_SUCCESS,
            formData,
            formState,
            lookups,
            responseStatus
        }
    },
    SaveFailure: IPersonnelRoleSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PERSONNELROLE_SAVE_FAILURE,
            responseStatus
        }
    },
    ResendEmail: IPersonnelRoleResendEmailActionCreator = (id) => {
        return {
            type: ACTION_PERSONNELROLE_RESENDEMAIL,
            id
        }
    },
    ResendEmailSuccess: IPersonnelRoleResendEmailSuccessActionCreator = (responseStatus) => {
        return {
            type: ACTION_PERSONNELROLE_RESENDEMAIL_SUCCESS,
            responseStatus
        }
    },
    ResendEmailFailure: IPersonnelRoleResendEmailFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PERSONNELROLE_RESENDEMAIL_FAILURE,
            responseStatus
        }
    },
    Clear: IPersonnelRoleClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_PERSONNELROLE_CLEAR,
            propertiesToClear
        }
    };



export const
    ACTION_PERSONNELROLE_LOAD_BY_ID = "ACTION_PERSONNELROLE_LOAD_BY_ID",
    ACTION_PERSONNELROLE_LOAD_SUCCESS = "ACTION_PERSONNELROLE_LOAD_SUCCESS",
    ACTION_PERSONNELROLE_LOAD_FAILURE = "ACTION_PERSONNELROLE_LOAD_FAILURE",
    
    ACTION_PERSONNELROLE_CREATE = "ACTION_PERSONNELROLE_CREATE",
    ACTION_PERSONNELROLE_CREATE_SUCCESS = "ACTION_PERSONNELROLE_CREATE_SUCCESS",
    ACTION_PERSONNELROLE_CREATE_FAILURE = "ACTION_PERSONNELROLE_CREATE_FAILURE",

    ACTION_PERSONNELROLE_SAVE = "ACTION_PERSONNELROLE_SAVE",
    ACTION_PERSONNELROLE_SAVE_SUCCESS = "ACTION_PERSONNELROLE_SAVE_SUCCESS",
    ACTION_PERSONNELROLE_SAVE_FAILURE = "ACTION_PERSONNELROLE_SAVE_FAILURE",

    ACTION_PERSONNELROLE_RESENDEMAIL = "ACTION_PERSONNELROLE_RESENDEMAIL",
    ACTION_PERSONNELROLE_RESENDEMAIL_SUCCESS = "ACTION_PERSONNELROLE_RESENDEMAIL_SUCCESS",
    ACTION_PERSONNELROLE_RESENDEMAIL_FAILURE = "ACTION_PERSONNELROLE_RESENDEMAIL_FAILURE",

    ACTION_PERSONNELROLE_CLEAR = "ACTION_PERSONNELROLE_CLEAR";


export interface IPersonnelRoleLoadSuccessAction {
    type: "ACTION_PERSONNELROLE_LOAD_SUCCESS";
    formData: Dtos.PersonnelRole;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IPersonnelRoleLoadSuccessActionCreator {
    (
        formData: Dtos.PersonnelRole,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IPersonnelRoleLoadSuccessAction;
}

export interface IPersonnelRoleLoadFailureAction {
    type: "ACTION_PERSONNELROLE_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IPersonnelRoleLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IPersonnelRoleLoadFailureAction;
}

export interface IPersonnelRoleLoadByIdAction {
    type: "ACTION_PERSONNELROLE_LOAD_BY_ID";
    id: number;
}

export interface IPersonnelRoleLoadByIdActionCreator {
    (id: number): IPersonnelRoleLoadByIdAction;
}

export interface IPersonnelRoleCreateAction {
    type: "ACTION_PERSONNELROLE_CREATE";
    personnelId: number;
    trialId: number;
    institutionId: number;
    groupId: number;
    departmentId: number;
}

export interface IPersonnelRoleCreateActionCreator {
    (
        personnelId: number,
        trialId: number, 
        institutionId: number, 
        groupId: number,
        departmentId: number
    ): IPersonnelRoleCreateAction
}

export interface IPersonnelRoleCreateSuccessAction {
    type: "ACTION_PERSONNELROLE_CREATE_SUCCESS";
    formData: Dtos.PersonnelRole;
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[]
}

export interface IPersonnelRoleCreateSuccessActionCreator {
    (
        formData: Dtos.PersonnelRole,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[]
    ): IPersonnelRoleCreateSuccessAction;
}

export interface IPersonnelRoleCreateFailureAction {
    type: "ACTION_PERSONNELROLE_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IPersonnelRoleCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IPersonnelRoleCreateFailureAction;
}


export interface IPersonnelRoleSaveAction {
    type: "ACTION_PERSONNELROLE_SAVE";
    formData: Dtos.PersonnelRole;

}

export interface IPersonnelRoleSaveActionCreator {
    (
        formData: Dtos.PersonnelRole,
    ): IPersonnelRoleSaveAction;
}

export interface IPersonnelRoleSaveSuccessAction {
    type: "ACTION_PERSONNELROLE_SAVE_SUCCESS";
    formData: Dtos.PersonnelRole;
    formState: Dtos.FormState,
    lookups: Dtos.Lookup[],
    responseStatus: Dtos.ResponseStatus;
}

export interface IPersonnelRoleSaveSuccessActionCreator {
    (
        formData: Dtos.PersonnelRole,
        formState: Dtos.FormState,
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IPersonnelRoleSaveSuccessAction;
}

export interface IPersonnelRoleSaveFailureAction {
    type: "ACTION_PERSONNELROLE_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IPersonnelRoleSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IPersonnelRoleSaveFailureAction;
}

export interface IPersonnelRoleClearAction {
    type: "ACTION_PERSONNELROLE_CLEAR";
    propertiesToClear?: string[];
}


export interface IPersonnelRoleResendEmailAction {
    type: "ACTION_PERSONNELROLE_RESENDEMAIL";
    id: number;
}

export interface IPersonnelRoleResendEmailActionCreator {
    (id: number): IPersonnelRoleResendEmailAction;
}

export interface IPersonnelRoleResendEmailSuccessAction {
    type: "ACTION_PERSONNELROLE_RESENDEMAIL_SUCCESS";
    responseStatus: Dtos.ResponseStatus;
}

export interface IPersonnelRoleResendEmailSuccessActionCreator {
    (
        responseStatus: Dtos.ResponseStatus
    ): IPersonnelRoleResendEmailSuccessAction;
}

export interface IPersonnelRoleResendEmailFailureAction {
    type: "ACTION_PERSONNELROLE_RESENDEMAIL_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IPersonnelRoleResendEmailFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IPersonnelRoleResendEmailFailureAction;
}

export interface IPersonnelRoleClearActionCreator {
    (propertiesToClear?: string[]): IPersonnelRoleClearAction;
}

export type PersonnelRoleAction =
    IPersonnelRoleLoadByIdAction |
    IPersonnelRoleLoadFailureAction |
    IPersonnelRoleLoadSuccessAction |
    IPersonnelRoleCreateAction |
    IPersonnelRoleCreateSuccessAction |
    IPersonnelRoleCreateFailureAction |
    IPersonnelRoleClearAction |
    IPersonnelRoleSaveAction |
    IPersonnelRoleSaveSuccessAction |
    IPersonnelRoleSaveFailureAction |
    IPersonnelRoleResendEmailAction |
    IPersonnelRoleResendEmailSuccessAction |
    IPersonnelRoleResendEmailFailureAction;
