import * as React from 'react';
import * as classNames from "classnames";
import './Tree.scss';
import { TreeNode } from "../index";
import AnimateHeight from 'react-animate-height';

interface ITreeProps {
    treeData: ITree
    onToggle: (id: string) => void;
    toggleState: string[];
    subTree?: boolean;
    toggled?: boolean;
}

export interface ITree {
    children: ITreeNode<any>[];
}

export interface ITreeNode<T> {
    id: string;
    data: T;
    decorator?: (node: ITreeNode<T>, toggled: boolean, onToggle: (id: string) => void) => React.ReactElement<any>;
    subTree?: ITree;
    onToggle?: (id: string) => void;
}

class Tree extends React.PureComponent<ITreeProps, any> {

    constructor(props) {
        super(props);
    }

    render() {

        const {
            treeData,
            onToggle,
            toggleState,
            subTree,
            toggled
        } = this.props

        if (!treeData) {
            return null;
        }

        const tree =
            <ul className={classNames({ "tree": !subTree }, { "sub-tree": subTree })}>
                {
                    treeData.children ?
                        treeData.children.map((treeNode, index) => {
                            const itemToggled = toggleState.some(id => id == treeNode.id);
                            // @ts-ignore
                            let node: React.ReactElement<any> = null;
                            const toggle = treeNode.onToggle ? treeNode.onToggle : onToggle;

                            if (treeNode.decorator) {
                                node = treeNode.decorator(treeNode, itemToggled, toggle);
                            }
                            else {
                                node = <TreeNode
                                    id={treeNode.id}
                                    name={treeNode.data}
                                    onToggle={onToggle}
                                    toggled={itemToggled}
                                />
                            }

                            return <li key={treeNode.id}>
                                {
                                    node

                                }
                                {
                                    treeNode.subTree ?
                                        <Tree
                                            treeData={treeNode.subTree}
                                            onToggle={toggle}
                                            toggleState={toggleState}
                                            subTree={true}
                                            toggled={itemToggled}
                                        /> :
                                        null
                                }
                            </li>
                        }) :
                        null
                }
            </ul>

        if (subTree) {
            return <AnimateHeight
                duration={500}
                height={toggled ? "auto" : "0"}
            >
                {
                    tree
                }
            </AnimateHeight>
        }

        return tree;
    }
}

export default Tree;