import * as React from 'react';
import './TrialFormCreateVersion.scss';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Alert from 'react-s-alert';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu, SubPageLayout } from "../../../components/layouts/index";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as TrialFormActions from '../../../actions/trialForm';
import * as TrialFormsPageActions from '../../../actions/pages/trialFormsPage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import { Form, actions, Control } from 'react-redux-form';

interface ITrialFormCreateVersionProps {
    trialFormId?: number;
    version?: string;
    copyData?: boolean;

    creatingVersion?: boolean;
    creatingVersionSuccess?: boolean;
    creatingVersionFailure?: boolean;

    validationFailures?: [];

    onClose?: Function;
    user?: Dtos.User
}

interface ITrialFormCreateVersionActions {
    setVersionState?: TrialFormsPageActions.ITrialFormsPageSetCreateVersionModalViewStateActionCreator;
    createVersion?: TrialFormActions.ITrialFormCreateVersionActionCreator;
}

type TrialFormCreateVersionProps = ITrialFormCreateVersionProps & ITrialFormCreateVersionActions;

const reduxFormName: string = "reduxForms.trialForm";

class TrialFormCreateVersion extends React.PureComponent<TrialFormCreateVersionProps, any> {

    constructor(props: TrialFormCreateVersionProps) {
        super(props);

        this.clearTrialForm = this.clearTrialForm.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidUpdate(prevProps: TrialFormCreateVersionProps, prevState) {
        const {
            creatingVersion,
            creatingVersionSuccess,
            validationFailures,
        } = this.props;

        if (!creatingVersion && prevProps.creatingVersion) {
            if (creatingVersionSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);
                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);
                }
                this.onCancel();
            }
            else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }
    }

    componentWillUnmount() {
        this.clearTrialForm();
    }

    clearTrialForm() {
    }

    showTrialForm(): boolean {
        return true;
    }

    render() {
        return <SubPageLayout loading={false}>
            {this.renderContent()}
        </SubPageLayout>;
    }

    renderContent() {
        const {
            version,
            trialFormId,
            setVersionState,
            copyData
        } = this.props;

        const formDisabled: boolean = false;

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            version: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
        };

        let form = {
            version: version
        }

        return <div>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                <div className="alert alert-warning mt-4" role="alert">
                    The new version will be the default, older versions will be available from the details page.
                </div>
                <div className="form-group row border border-bottom-1 border-top-0 pb-4 pt-4">
                    <label className="col-form-label col-sm-6" htmlFor="version">Version</label>
                    <div className="col-sm-6 generic-form-field">
                        <div style={{ position: 'relative' }}>
                            <input type="text"
                                className="form-control"
                                name="version"
                                id="version"
                                value={version || ""}
                                onChange={e => setVersionState(true, trialFormId, e.target.value, copyData)}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group row border border-bottom-1 border-top-0 pb-4 pt-4">
                    <div className="col-sm-12 generic-form-field">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox"
                                name="copyData"
                                id="copyData"
                                className="custom-control-input"
                                checked={(copyData === null ? false : copyData)  === true}
                                onChange={e => setVersionState(true, trialFormId, version, copyData ? false : true)}
                            />
                            <label htmlFor="copyData" className="custom-control-label">Copy entered data from the previous version</label>
                        </div>
                    </div>
                </div>
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {
        return <div>
            The form was <strong> not </strong> saved.
        </div>

    }

    renderWarningAlert(): any {
        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>
    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.TrialForm) {
        const {
            createVersion,
            version,
            trialFormId,
            copyData
        } = this.props;

        createVersion(trialFormId, version, copyData);
    }

    handleOnSubmitFailed(data: Dtos.TrialForm) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        if (this.props.onClose) { this.props.onClose() }
    }
}


const mapStateToProps = (state: ITmdState, ownProps: TrialFormCreateVersionProps): ITrialFormCreateVersionProps => {

    let trialForm: Dtos.TrialForm | undefined = !(state.trialForm.formData instanceof Array) ? state.trialForm.formData : undefined;

    return {
        trialFormId: state.trialFormsPage.trialFormId,
        version: state.trialFormsPage.version,
        copyData: state.trialFormsPage.copyData,

        creatingVersion: state.trialForm.createState && state.trialForm.createState.status == RequestState.Pending,
        creatingVersionSuccess: state.trialForm.createState && state.trialForm.createState.status == RequestState.Success,
        creatingVersionFailure: state.trialForm.createState && state.trialForm.createState.status == RequestState.Failure,

        user: state.user.data,

        validationFailures: [],

    };
};

const mapDispatchToProps = (dispatch): ITrialFormCreateVersionActions => {
    return {
        setVersionState: bindActionCreators(TrialFormsPageActions.setCreateVersionModalViewState, dispatch),
        createVersion: bindActionCreators(TrialFormActions.CreateVersion, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialFormCreateVersion);
