import { Reducer } from 'redux';
import { update } from '../../helpers/immutabilityHelper'
import * as Dtos from '../../dtos/Tmd.dtos';

import {
    ACTION_TRIALFORMS_PAGE_SET_EDIT_MODAL_VIEW_STATE,
    ACTION_TRIALFORMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE,
    TrialFormsPageAction
} from '../../actions/pages/trialFormsPage';


export interface ITrialFormsPageState {
    editModalOpen: boolean;
    collectionInstanceId: number;
    name: string;
    description: string;
    createVersionModalOpen: boolean;
    trialFormId: number;
    version: string;
    copyData: boolean;
}

const initialState: ITrialFormsPageState = {
    editModalOpen: false,
    collectionInstanceId: null,
    name: "",
    description: "",
    createVersionModalOpen: false,
    trialFormId: null,
    version: "",
    copyData: false
};


function setEditModalViewState(state: ITrialFormsPageState, open: boolean, collectionInstanceId: number, name: string, description: string): ITrialFormsPageState {
    const newState: ITrialFormsPageState = update(state, {
        editModalOpen: {
            $set: open
        },
        collectionInstanceId: {
            $set: collectionInstanceId
        },
        name: {
            $set: name
        },
        description: {
            $set: description
        },
        copyData: {
            $set: false 
        }
    });
    return newState;
}

function setCreateVersionModalViewState(state: ITrialFormsPageState, open: boolean, trialFormId: number, version: string, copyData: boolean): ITrialFormsPageState {
    const newState: ITrialFormsPageState = update(state, {
        createVersionModalOpen: {
            $set: open
        },
        trialFormId: {
            $set: trialFormId
        },
        version: {
            $set: version
        },
        copyData: {
            $set: copyData
        }
    });
    return newState;
}

const TrialFormsPageReducer: Reducer<ITrialFormsPageState> = (state: ITrialFormsPageState = initialState, action) => { 
    switch (action.type) {
        case ACTION_TRIALFORMS_PAGE_SET_EDIT_MODAL_VIEW_STATE:
            return setEditModalViewState(state, action.open, action.collectionInstanceId, action.name, action.description);
        case ACTION_TRIALFORMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE:
            return setCreateVersionModalViewState(state, action.open, action.trialFormId, action.version, action.copyData);
    }

    return state;
}

export default TrialFormsPageReducer;