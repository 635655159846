import * as React from 'react';
import './TrialInstitutionItemsPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu, TruncatedCell } from "../../../components/layouts/index";
import { ITrialInstitutionReduxFormState, initialTrialInstitutionState } from "../../../reducers/reactReduxForms/trialInstitution";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as TrialInstitutionActions from '../../../actions/trialInstitution';
import * as TrialInstitutionItemActions from '../../../actions/trialInstitutionItem';
import * as TrialInstitutionItemsPageActions from '../../../actions/pages/trialInstitutionItemsPage';
import * as TrialActions from '../../../actions/trial';
import * as ToggleActions from '../../../actions/pages/togglePage';
import * as ToggleHelper from "../../../helpers/toggleHelper";
import { TogglePageItem } from '../../../reducers/pages/togglePage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TrialInstitutionLayout } from '../../index';
import { FormFilterInput } from '../../../components/form';

import {
    TrialInstitutionItemCreateVersion,
    CollectionInstancePage
} from "../../../containers/index";

interface ITrialInstitutionItemsPageParams {
    trialId: number;
    trialInstitutionId: number;
}

interface ITrialInstitutionItemsPageProps {

    form: Dtos.TrialInstitution & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formFilter: IFormFilter;
    reduxFormState: ITrialInstitutionReduxFormState;

    location: Location;
    trialId: number;
    trialInstitutionId: number;
    match: match<ITrialInstitutionItemsPageParams>;
    history: History;

    editModalOpen: boolean,
    collectionInstanceId: number,
    modalTitle: string,
    modalDescription: string,

    createVersionModalOpen: boolean,
    createVersionTrialInstitutionId: number,
    createVersionTrialItemId: number,

    // From state
    trialInstitution: Dtos.TrialInstitution;
    trialItemsByCategory: Dtos.TrialItemCategory[];

    lookups: Dtos.Lookup[];

    loadingTrialInstitution: boolean;
    loadTrialInstitutionSuccess: boolean;
    loadTrialInstitutionFailure: boolean;

    savingTrialInstitution: boolean;
    saveTrialInstitutionSuccess: boolean;
    saveTrialInstitutionFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];
    toggleItems: TogglePageItem[]
}

interface ITrialInstitutionItemsPageActions {
    clearTrialInstitution: TrialInstitutionActions.ITrialInstitutionClearActionCreator;
    loadTrialInstitution: TrialInstitutionActions.ITrialInstitutionLoadByIdActionCreator;
    saveTrialInstitution: TrialInstitutionActions.ITrialInstitutionSaveActionCreator;

    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    setEditModelViewState: TrialInstitutionItemsPageActions.ITrialInstitutionItemsPageSetEditModalViewStateActionCreator;
    setCreateVersionModelViewState: TrialInstitutionItemsPageActions.ITrialInstitutionItemsPageSetCreateVersionModalViewStateActionCreator;

    toggleExpand: ToggleActions.IToggleExpandedStateActionCreator;

    filter: TrialInstitutionItemActions.ITrialInstitutionItemFilterActionCreator;
}

type TrialInstitutionItemsPageProps = ITrialInstitutionItemsPageProps & ITrialInstitutionItemsPageActions;

const formName: string = "trialInstitution";
const reduxFormName: string = "reduxForms.trialInstitution";
const reduxFormNameCollectionInstance: string = "reduxForms.collectionInstance";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class TrialInstitutionItemsPage extends React.PureComponent<TrialInstitutionItemsPageProps, any> {

    constructor(props: TrialInstitutionItemsPageProps) {
        super(props);

        this.clearTrialInstitution = this.clearTrialInstitution.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTrial,
            loadTrialInstitution,
            navigate,
            navigateReplace,
            trialId,
            trialInstitutionId
        } = this.props;

        if (trialInstitutionId) {
            loadTrialInstitution(trialInstitutionId);

            if (trialId) {
                loadTrial(trialId);
            }
        } else {
            // redirect
            navigate("/trials/" + trialId + "/institutions");
        }
    }

    componentWillReceiveProps(nextProps: TrialInstitutionItemsPageProps) {
    }

    componentDidUpdate(prevProps: TrialInstitutionItemsPageProps, prevState) {
        const {
            form,
            trialId,
            trialInstitutionId,
            trialInstitution,
            navigateReplace,
            loadTrial,
            loadTrialInstitution,
            loadingTrialInstitution,
            loadTrialInstitutionSuccess,
            savingTrialInstitution,
            saveTrialInstitutionSuccess,
            saveTrialInstitutionFailure,
            validationFailures,
            navigate
        } = this.props;


        if (trialInstitutionId && prevProps.trialInstitutionId != trialInstitutionId) {
            loadTrialInstitution(trialInstitutionId);
        }

        if (trialId && prevProps.trialId != trialId) {
            loadTrial(trialId);
        }

        if (!loadingTrialInstitution && prevProps.loadingTrialInstitution) {
            if (loadTrialInstitutionSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingTrialInstitution && prevProps.savingTrialInstitution) {
            if (saveTrialInstitutionSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/trials/" + trialId + "/institutions")
                } else if (!form.saveAndReturn && trialInstitutionId && trialInstitutionId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !trialInstitutionId && trialInstitution && trialInstitution.id > 0) {
                    navigate("/trials/" + trialId + "/trialinstitutions/" + trialInstitution.id + "/items")
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

    }

    componentWillUpdate(nextProps: TrialInstitutionItemsPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearTrialInstitution();
        loadForm(reduxFormName, initialTrialInstitutionState);
        resetForm(reduxFormName);
    }

    clearTrialInstitution() {
        this.props.clearTrialInstitution();
        this.props.clearTrial();
        this.props.setEditModelViewState(false, null, null, null);
    }

    showTrialInstitution(): boolean {
        const {
            loadingTrialInstitution,
            loadTrialInstitutionSuccess
        } = this.props

        if (!loadingTrialInstitution && loadTrialInstitutionSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrialInstitution,
        } = this.props

        return <TrialInstitutionLayout loading={loadingTrialInstitution} permission={Dtos.Permission.TrialInstitutionItemInstanceView}>
            {this.renderContent()}
            {
                this.renderItemModal()
            }
            {
                this.renderCreateVersionModal()
            }
        </TrialInstitutionLayout>;

    }

    renderContent() {
        const {
            trialInstitution,
            permissions
        } = this.props

        return <div>
            <div className="row">
                <div className={"col mb-2"}>
                    <h2>Trial Institution Items</h2>
                </div>
                <div className="col-auto" style={{ textAlign: 'right' }}>
                    {(trialInstitution && trialInstitution.trialId > 0 && AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialInstitutionItemInstanceExport, trialInstitution.trialId)) &&
                        <a className="btn btn-info mr-2"
                            href={encodeURI("/api/trialinstitutionitem/export/" + trialInstitution.trialId + "/" + trialInstitution.institutionId)}
                        >
                            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.CLOUD_DOWNLOAD} fixedWidth />
                        </a>
                    }
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} />
                </div>
            </div>
            {
                this.showTrialInstitution() ?
                    <div className="mt-2">
                        {this.renderTable()}
                    </div> :
                    null
            }
        </div>

    }


    renderTable() {
        const {
            trialInstitutionId,
            trialItemsByCategory,
            setEditModelViewState,
            setCreateVersionModelViewState,
            toggleItems,
            toggleExpand,
            permissions
        } = this.props;

        if (!trialItemsByCategory || trialItemsByCategory.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no items to view.</span>
            </div>
        }

        const showPagination = trialItemsByCategory.length > DEFAULT_PAGE_SIZE;

        


        return <div>
            {trialItemsByCategory.map((item, index) => {
                const key = "trial-institution-items-page-" + item.categoryId + "-" + trialInstitutionId;
                const expanded = ToggleHelper.isToggleExpanded(toggleItems, key);
                return (
                    <div key={"trial-items-category" + item.category.id}>
                        <div className={tableHeaderClass} style={tableStyleClass} onClick={() => toggleExpand(key)}>
                            <div>
                                <FontAwesomeIcon icon={!expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" />
                            </div>
                            <span>{item.category.value}</span>
                        </div>
                        {expanded && (
                            <ReactTable
                                data={item.trialItemSummaries}
                                pageSizeOptions={PAGE_SIZE_OPTIONS}
                                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : item.trialItemSummaries.length}
                                pageSize={showPagination ? undefined : item.trialItemSummaries.length}
                                showPagination={showPagination}
                                className="-striped -highlight -clickable mb-3"
                                noDataText="There are currently no trial items for this category"
                                resizable={false}
                                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                                    console.log(rowInfo);
                                    return {
                                        onClick: (e, handleOriginal) => {
                                            if (rowInfo.original.collectionInstanceId && rowInfo.original.collectionInstanceId > 0) {
                                                setEditModelViewState(true, rowInfo.original.collectionInstanceId, rowInfo.original.name, rowInfo.original.description);
                                            }

                                            if (handleOriginal) {
                                                handleOriginal()
                                            }

                                        },
                                        style: {
                                            color: (rowInfo && rowInfo.original && rowInfo.original.dataEntered) ? '#212529' : '#9e9e9e'
                                        }
                                    }
                                }}
                                columns={
                                    [
                                        {
                                            id: "id",
                                            Header: ' ',
                                            accessor: "id",
                                            className: "d-flex align-items-center justify-content-center",
                                            maxWidth: 50,
                                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.TRIALITEM} fixedWidth />
                                        },
                                        {
                                            id: "name",
                                            Header: "Name",
                                            accessor: "name",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            Cell: (props) => <TruncatedCell value={props.value} />
                                        },
                                        {
                                            id: "description",
                                            Header: "Description",
                                            accessor: "description",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            Cell: (props) => <TruncatedCell value={props.value} />
                                        },
                                        {
                                            id: "version",
                                            Header: "Version",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            maxWidth: 320,
                                            style: { whiteSpace: 'break-spaces' },
                                            Cell: row => (
                                                <div>
                                                    {row.original.version && (
                                                        <button disabled={AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialInstitutionItemInstanceUpdate, row.original.trialId, row.original.insitutionId)}
                                                            type="button"
                                                            className="btn btn-outline-secondary btn-sm btn-row-version"
                                                        >
                                                            <span>{row.original.version}</span>
                                                        </button>
                                                    )}
                                                    {row.original.trialItemSummaryVersions.map((item, index) =>
                                                        <button disabled={!AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialInstitutionItemInstanceUpdate, row.original.trialId, row.original.insitutionId)}
                                                            onClick={
                                                                (e) => {
                                                                    e.stopPropagation();
                                                                    console.log(row);
                                                                    if (item.collectionInstanceId && item.collectionInstanceId > 0) {
                                                                        setEditModelViewState(true, item.collectionInstanceId, row.original.name + " [" + item.version + "]", row.original.name);
                                                                    }
                                                                }
                                                            }
                                                            type="button"
                                                            className="btn btn-secondary btn-sm btn-row-version"
                                                        >
                                                            <span>{item.version}</span>
                                                        </button>
                                                    )}
                                                </div>
                                            )
                                        },
                                        {
                                            id: "create",
                                            Header: "",
                                            className: "d-flex align-items-right",
                                            maxWidth: 120,
                                            style: { textAlign: 'right' },
                                            Cell: row => (
                                                <div>
                                                    {row.original.allowVersions && (
                                                        <button disabled={!AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialInstitutionItemInstanceUpdate, row.original.trialId, row.original.insitutionId)}
                                                            onClick={
                                                                (e) => {
                                                                    e.stopPropagation();
                                                                    console.log(row)
                                                                    setCreateVersionModelViewState(true, row.original.trialInstitutionId, row.original.trialItemId)
                                                                }
                                                            }
                                                            type="button"
                                                            className="btn btn-outline-secondary mr-2 btn-sm btn-row-version"
                                                        >
                                                            <span>New version</span>
                                                        </button>
                                                    )}
                                                </div>
                                            )
                                        }
                                    ]
                                }
                            />
                        )}
                    </div>
                )
            }
            )}
        </div>
    }

    renderItemModal() {
        const {
            trialInstitutionId,
            trialInstitution,
            loadingTrialInstitution,
            editModalOpen,
            setEditModelViewState,
            collectionInstanceId,
            loadTrialInstitution,
            modalTitle,
            modalDescription,
            savingTrialInstitution,
            form,
            permissions,
            formState
        } = this.props

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.TrialInstitutionItemInstanceUpdate, Dtos.Permission.TrialInstitutionItemInstanceUpdate);
        const formDisabled: boolean = !hasPermission || savingTrialInstitution || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        if (trialInstitution && !loadingTrialInstitution && collectionInstanceId && collectionInstanceId > 0) {
            return <Modal
                open={editModalOpen}
                disableCloseOnOverlayClick={true}
                onClose={() => {
                    setEditModelViewState(false, null, "", "");
                    loadTrialInstitution(trialInstitutionId);
                }}
                size={ModalSize.Xl}>
                <div className="modal-header">
                    <h5 className="modal-title">{setModalTitle(undefined, modalTitle)}{modalDescription ? <><br /><small style={{ color: '#444' }}>{modalDescription}</small></> : undefined}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => { setEditModelViewState(false, null, "", ""); loadTrialInstitution(trialInstitutionId); }}>
                        <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                    </button>

                </div>
                <div className="modal-body">
                    <CollectionInstancePage
                        collectionInstanceId={collectionInstanceId}
                        readonly={formDisabled }
                        onClose={() => { setEditModelViewState(false, null, "", ""); loadTrialInstitution(trialInstitutionId); }} />
                </div>
            </Modal>
        }
    }

    renderCreateVersionModal() {
        const {
            trialInstitutionId,
            trialInstitution,
            loadingTrialInstitution,
            createVersionModalOpen,
            setCreateVersionModelViewState,
            loadTrialInstitution,
            createVersionTrialInstitutionId,
            createVersionTrialItemId,
        } = this.props

        if (trialInstitution && !loadingTrialInstitution) {
            return <Modal open={createVersionModalOpen}
                disableCloseOnOverlayClick={true}
                onClose={() => { setCreateVersionModelViewState(false, null, null); loadTrialInstitution(trialInstitutionId); }}
                size={ModalSize.Md}>
                <div className="modal-header">
                    <h5 className="modal-title">{setModalTitle(undefined, "Create Version")}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => { setCreateVersionModelViewState(false, null, null); loadTrialInstitution(trialInstitutionId); }}>
                        <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                    </button>
                </div>
                <div className="modal-body" style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <TrialInstitutionItemCreateVersion
                        trialInstitutionId={createVersionTrialInstitutionId}
                        trialItemId={createVersionTrialItemId}
                        onClose={() => { setCreateVersionModelViewState(false, null, null); loadTrialInstitution(trialInstitutionId); }} />
                </div>
            </Modal>
        }
        // 
    }

    toggle(categoryId: number) {

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.TrialInstitution) {
        const {
            saveTrialInstitution
        } = this.props;

        if (data) {
            // let changes: Dtos.TrialInstitution = {} as Dtos.TrialInstitution;
            // saveTrialInstitution(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.TrialInstitution) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            trialId,
            trialInstitutionId,
            navigate
        } = this.props;

        navigate("/trials/" + trialId + "/trialinstitution/" + trialInstitutionId);
    }

    loadForm() {
        const {
            loadForm,
            trialInstitution,
        } = this.props

        let extraData: Dtos.TrialInstitution = {} as Dtos.TrialInstitution;

        loadForm(reduxFormName, Object.assign({ ...trialInstitution }, extraData));
    }
}


const mapStateToProps = (state: ITmdState, ownProps: TrialInstitutionItemsPageProps): ITrialInstitutionItemsPageProps => {

    let trialInstitution: Dtos.TrialInstitution | undefined = !(state.trialInstitution.formData instanceof Array) ? state.trialInstitution.formData : undefined;
    //let trialItemsByCategory: Dtos.TrialItemCategory[] | undefined = trialInstitution == undefined ? undefined : trialInstitution.trialItemCategories;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,
        trialInstitutionId: ownProps.match && ownProps.match.params.trialInstitutionId.toString() != "create" ? ownProps.match.params.trialInstitutionId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trialInstitution: trialInstitution!,
        trialItemsByCategory: state.trialInstitutionItem.formFilteredData,

        editModalOpen: state.trialInstitutionItemsPage.editModalOpen,
        collectionInstanceId: state.trialInstitutionItemsPage.collectionInstanceId,
        modalTitle: state.trialInstitutionItemsPage.name,
        modalDescription: state.trialInstitutionItemsPage.description,

        createVersionModalOpen: state.trialInstitutionItemsPage.createVersionModalOpen,
        createVersionTrialInstitutionId: state.trialInstitutionItemsPage.trialInstitutionId,
        createVersionTrialItemId: state.trialInstitutionItemsPage.trialItemId,

        loadingTrialInstitution: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Pending,
        loadTrialInstitutionSuccess: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Success,
        loadTrialInstitutionFailure: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Failure,

        savingTrialInstitution: state.trialInstitution.saveState && state.trialInstitution.saveState.status == RequestState.Pending,
        saveTrialInstitutionSuccess: state.trialInstitution.saveState && state.trialInstitution.saveState.status == RequestState.Success,
        saveTrialInstitutionFailure: state.trialInstitution.saveState && state.trialInstitution.saveState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: state.trialInstitution.lookups,

        form: state.reduxForms.trialInstitution,
        formState: state.trialInstitution.formState,
        formProperties: state.trialInstitution.formProperties,
        formFilter: state.trialInstitutionItem.formFilter,

        reduxFormState: state.reduxForms.formState.trialInstitution,
        validationFailures: trialInstitution ?
            state.trialInstitution.validationFailures :
            undefined,

        toggleItems: state.togglePage.items

    };
};

const mapDispatchToProps = (dispatch): ITrialInstitutionItemsPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadTrialInstitution: bindActionCreators(TrialInstitutionActions.LoadTrialInstitutionById, dispatch),
        clearTrialInstitution: bindActionCreators(TrialInstitutionActions.Clear, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch),

        saveTrialInstitution: bindActionCreators(TrialInstitutionActions.SaveTrialInstitution, dispatch),

        setEditModelViewState: bindActionCreators(TrialInstitutionItemsPageActions.setEditModalViewState, dispatch),
        setCreateVersionModelViewState: bindActionCreators(TrialInstitutionItemsPageActions.setCreateVersionModalViewState, dispatch),

        toggleExpand: bindActionCreators(ToggleActions.ToggleExpanded, dispatch),

        filter: bindActionCreators(TrialInstitutionItemActions.Filter, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialInstitutionItemsPage);
