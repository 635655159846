import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_INSTITUTIONTYPES_LOAD,
    ACTION_INSTITUTIONTYPES_LOAD_BY_ID,

    ACTION_INSTITUTIONTYPES_SAVE,

    ACTION_INSTITUTIONTYPES_CREATE,

    IInstitutionTypeLoadAction,
    IInstitutionTypeLoadByIdAction,
    IInstitutionTypeLoadFailureAction,
    IInstitutionTypeLoadSuccessAction,

    IInstitutionTypeSaveAction,
    IInstitutionTypeSaveSuccessAction,
    IInstitutionTypeSaveFailureAction,

    IInstitutionTypeCreateAction,
    IInstitutionTypeCreateSuccessAction,
    IInstitutionTypeCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/institutionTypes";
import { institutiontypesApi } from "../services/institutionType";


export const
    getInstitutionTypesEpic = (action$): Observable<IInstitutionTypeLoadSuccessAction | IInstitutionTypeLoadFailureAction> => {
        return action$
            .ofType(ACTION_INSTITUTIONTYPES_LOAD)
            .mergeMap((action: IInstitutionTypeLoadAction) => {
                return institutiontypesApi
                    .getInstitutionTypes()
                    .map(response =>
                        LoadSuccess(response.institutionTypes, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getInstitutionTypeByIdEpic = (action$): Observable<IInstitutionTypeLoadSuccessAction | IInstitutionTypeLoadFailureAction> => {
        return action$
            .ofType(ACTION_INSTITUTIONTYPES_LOAD_BY_ID)
            .mergeMap((action: IInstitutionTypeLoadByIdAction) => {
                return institutiontypesApi
                    .getInstitutionTypeById(action.id)
                    .map(response =>
                        LoadSuccess(response.institutionType, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setInstitutionTypeEpic = (action$): Observable<IInstitutionTypeSaveSuccessAction | IInstitutionTypeSaveFailureAction> => {
        return action$
            .ofType(ACTION_INSTITUTIONTYPES_SAVE)
            .mergeMap((action: IInstitutionTypeSaveAction) => {
                return institutiontypesApi
                    .setInstitutionType(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.institutionType, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createInstitutionTypeEpic = (action$): Observable<IInstitutionTypeCreateSuccessAction | IInstitutionTypeCreateFailureAction> => {
        return action$
            .ofType(ACTION_INSTITUTIONTYPES_CREATE)
            .mergeMap((action: IInstitutionTypeCreateAction) => {
                return institutiontypesApi
                    .createInstitutionType()
                    .map(response =>
                        CreateSuccess(
                            response.institutionType,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



