import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadItemComponentType: IItemComponentTypeLoadActionCreator = () => {
        return {
            type: ACTION_ITEMCOMPONENTTYPES_LOAD
        }
    },
    LoadItemComponentTypeById: IItemComponentTypeLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_ITEMCOMPONENTTYPES_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IItemComponentTypeLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_ITEMCOMPONENTTYPES_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IItemComponentTypeLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_ITEMCOMPONENTTYPES_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveItemComponentType: IItemComponentTypeSaveActionCreator = (formData) => {
        return {
            type: ACTION_ITEMCOMPONENTTYPES_SAVE,
            formData
        }
    },
    SaveSuccess: IItemComponentTypeSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_ITEMCOMPONENTTYPES_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IItemComponentTypeSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_ITEMCOMPONENTTYPES_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateItemComponentType: IItemComponentTypeCreateActionCreator = () => {

        return {
            type: ACTION_ITEMCOMPONENTTYPES_CREATE
        }
    },
    CreateSuccess: IItemComponentTypeCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_ITEMCOMPONENTTYPES_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: IItemComponentTypeCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_ITEMCOMPONENTTYPES_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IItemComponentTypeClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_ITEMCOMPONENTTYPES_CLEAR,
            propertiesToClear
        }
    },

    Filter: IItemComponentTypeFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_ITEMCOMPONENTTYPES_FILTER,
            formFilter
        }
    };



export const
    ACTION_ITEMCOMPONENTTYPES_LOAD = "ACTION_ITEMCOMPONENTTYPES_LOAD",
    ACTION_ITEMCOMPONENTTYPES_LOAD_BY_ID = "ACTION_ITEMCOMPONENTTYPES_LOAD_BY_ID",
    ACTION_ITEMCOMPONENTTYPES_LOAD_SUCCESS = "ACTION_ITEMCOMPONENTTYPES_LOAD_SUCCESS",
    ACTION_ITEMCOMPONENTTYPES_LOAD_FAILURE = "ACTION_ITEMCOMPONENTTYPES_LOAD_FAILURE",

    ACTION_ITEMCOMPONENTTYPES_SAVE = "ACTION_ITEMCOMPONENTTYPES_SAVE",
    ACTION_ITEMCOMPONENTTYPES_SAVE_SUCCESS = "ACTION_ITEMCOMPONENTTYPES_SAVE_SUCCESS",
    ACTION_ITEMCOMPONENTTYPES_SAVE_FAILURE = "ACTION_ITEMCOMPONENTTYPES_SAVE_FAILURE",

    ACTION_ITEMCOMPONENTTYPES_CREATE = "ACTION_ITEMCOMPONENTTYPES_CREATE",
    ACTION_ITEMCOMPONENTTYPES_CREATE_SUCCESS = "ACTION_ITEMCOMPONENTTYPES_CREATE_SUCCESS",
    ACTION_ITEMCOMPONENTTYPES_CREATE_FAILURE = "ACTION_ITEMCOMPONENTTYPES_CREATE_FAILURE",

    ACTION_ITEMCOMPONENTTYPES_CLEAR = "ACTION_ITEMCOMPONENTTYPES_CLEAR",

    ACTION_ITEMCOMPONENTTYPES_FILTER = "ACTION_ITEMCOMPONENTTYPES_FILTER";

export interface IItemComponentTypeLoadAction {
    type: "ACTION_ITEMCOMPONENTTYPES_LOAD";
}

export interface IItemComponentTypeLoadActionCreator {
    (): IItemComponentTypeLoadAction;
}

export interface IItemComponentTypeLoadSuccessAction {
    type: "ACTION_ITEMCOMPONENTTYPES_LOAD_SUCCESS";
    formData: Dtos.ItemComponentType | Dtos.ItemComponentType[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IItemComponentTypeLoadSuccessActionCreator {
    (
        formData: Dtos.ItemComponentType | Dtos.ItemComponentType[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IItemComponentTypeLoadSuccessAction;
}

export interface IItemComponentTypeLoadFailureAction {
    type: "ACTION_ITEMCOMPONENTTYPES_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IItemComponentTypeLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IItemComponentTypeLoadFailureAction;
}

export interface IItemComponentTypeLoadByIdAction {
    type: "ACTION_ITEMCOMPONENTTYPES_LOAD_BY_ID";
    id: number;
}

export interface IItemComponentTypeLoadByIdActionCreator {
    (id: number): IItemComponentTypeLoadByIdAction;
}


export interface IItemComponentTypeSaveAction {
    type: "ACTION_ITEMCOMPONENTTYPES_SAVE";
    formData: Dtos.ItemComponentType;

}

export interface IItemComponentTypeSaveActionCreator {
    (
        formData: Dtos.ItemComponentType
    ): IItemComponentTypeSaveAction;
}

export interface IItemComponentTypeSaveSuccessAction {
    type: "ACTION_ITEMCOMPONENTTYPES_SAVE_SUCCESS";
    formData: Dtos.ItemComponentType;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IItemComponentTypeSaveSuccessActionCreator {
    (
        formData: Dtos.ItemComponentType,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IItemComponentTypeSaveSuccessAction;
}

export interface IItemComponentTypeSaveFailureAction {
    type: "ACTION_ITEMCOMPONENTTYPES_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IItemComponentTypeSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IItemComponentTypeSaveFailureAction;
}

export interface IItemComponentTypeCreateAction {
    type: "ACTION_ITEMCOMPONENTTYPES_CREATE"
}

export interface IItemComponentTypeCreateActionCreator {
    (): IItemComponentTypeCreateAction
}

export interface IItemComponentTypeCreateSuccessAction {
    type: "ACTION_ITEMCOMPONENTTYPES_CREATE_SUCCESS";
    formData: Dtos.ItemComponentType;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface IItemComponentTypeCreateSuccessActionCreator {
    (
        formData: Dtos.ItemComponentType,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): IItemComponentTypeCreateSuccessAction;
}

export interface IItemComponentTypeCreateFailureAction {
    type: "ACTION_ITEMCOMPONENTTYPES_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IItemComponentTypeCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IItemComponentTypeCreateFailureAction;
}

export interface IItemComponentTypeClearAction {
    type: "ACTION_ITEMCOMPONENTTYPES_CLEAR";
    propertiesToClear?: string[];
}

export interface IItemComponentTypeClearActionCreator {
    (propertiesToClear?: string[]): IItemComponentTypeClearAction;
}

export interface IItemComponentTypeFilterActionCreator {
    (formFilter: IFormFilter): IItemComponentTypeFilterAction;
}

export interface IItemComponentTypeFilterAction {
    type: "ACTION_ITEMCOMPONENTTYPES_FILTER";
    formFilter: IFormFilter;
}

export type ItemComponentTypeAction =
    IItemComponentTypeLoadAction |
    IItemComponentTypeLoadByIdAction |
    IItemComponentTypeLoadFailureAction |
    IItemComponentTypeLoadSuccessAction |
    IItemComponentTypeSaveAction |
    IItemComponentTypeSaveSuccessAction |
    IItemComponentTypeSaveFailureAction |
    IItemComponentTypeCreateAction |
    IItemComponentTypeCreateSuccessAction |
    IItemComponentTypeCreateFailureAction |
    IItemComponentTypeFilterAction | 
    IItemComponentTypeClearAction;
