import * as React from 'react';
import './CollectionPage.scss';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../reducers/reactReduxForms/index";
import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';
import { SubControlLayout } from "../../components/layouts/index";
import { ICollectionReduxFormState, initialCollectionState, initialCollectionItemComponentState, initialCollectionSectionState } from "../../reducers/reactReduxForms/collection";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../helpers/alert";
import { TextFormInput, TextAreaFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../components/form/index";
import * as CollectionActions from '../../actions/collection';
import * as CollectionPageActions from '../../actions/pages/collectionPage';
import * as ItemComponentActions from '../../actions/itemComponents';
import * as ToggleActions from '../../actions/pages/togglePage';
import { TogglePageItem } from '../../reducers/pages/togglePage';
import * as Dtos from '../../dtos/Tmd.dtos';
import { GridRemove } from "../../enumerations/GridRemove";
import { ITmdState } from "../../reducers/index";
import { RequestState } from "../../enumerations/RequestState";
import { findLookup, findLookupValue, generateOptionsFromLookup } from "../../helpers/lookupHelper";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../constants/fontAwesomeIcons";
import { setModalTitle } from "../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../components/common/index";
import { ModalSize } from '../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { PrimitiveFormDesigner, PrimitiveFormInput, CustomFormInput } from '../../components/form/index';
import * as AuthenticationHelper from '../../helpers/authentication';
import { read } from 'fs';

enum MoveDirection {
    Up = 1,
    Down = 2,
    Left = 3,
    Right = 4
}

interface ICollectionPageProps {
    readonly?: boolean;
    qaItemComponents: boolean;
    itemComponentTypeId: number;

    form: Dtos.Collection & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ICollectionReduxFormState;

    location: Location;
    collectionId: number;
    history: History;
    showSections: boolean,

    addModalOpen: boolean,
    addFilter: string,
    sectionId: number,
    addSelected: Map<number, number>,
    editModalOpen: boolean,
    previewModalOpen: boolean,
    previewComponentModalOpen: boolean,
    previewItemComponent: Dtos.ItemComponent;
    sectionsExpanded: boolean,
    sectionExpanded: number;

    // From state
    collection: Dtos.Collection;
    collectionSections: Dtos.CollectionSection[],
    lookups: Dtos.Lookup[];

    loadingCollection: boolean;
    loadCollectionSuccess: boolean;
    loadCollectionFailure: boolean;

    creatingCollection: boolean;
    createCollectionSuccess: boolean;
    createCollectionFailure: boolean;

    lockingCollection: boolean;
    lockCollectionSuccess: boolean;
    lockCollectionFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    // Item components from state
    itemComponents: Dtos.ItemComponent[];
    activeItemComponents: Dtos.ItemComponent[];
    loadingItemComponents: boolean;

    // column items
    column1: Dtos.CollectionItemComponent[];
    column2: Dtos.CollectionItemComponent[];
    column3: Dtos.CollectionItemComponent[];
    column1preview: Dtos.CollectionItemComponent[];
    column2preview: Dtos.CollectionItemComponent[];
    column3preview: Dtos.CollectionItemComponent[];
    documents: Dtos.CollectionItemComponent[];

    permissions?: Dtos.PersonnelTmdPermissions[];
    user: Dtos.User,

    toggleItems: TogglePageItem[]
}

interface ICollectionPageActions {
    clearCollection: CollectionActions.ICollectionClearActionCreator;
    loadCollection: CollectionActions.ICollectionLoadByIdActionCreator;
    createCollection: CollectionActions.ICollectionCreateActionCreator;
    lockCollection: CollectionActions.ICollectionLockActionCreator;

    clearItemComponents: ItemComponentActions.IItemComponentClearActionCreator;
    loadItemComponents: ItemComponentActions.IItemComponentLoadActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    setAddModalViewState: CollectionPageActions.ICollectionPageSetAddModalViewStateActionCreator;
    setAddFilter: CollectionPageActions.ICollectionPageSetAddFilterActionCreator;
    setSectionId: CollectionPageActions.ICollectionPageSetSectionActionCreator;
    setAddSelected: CollectionPageActions.ICollectionPageSetAddSelectedActionCreator;
    setEditModalViewState: CollectionPageActions.ICollectionPageSetEditModalViewStateActionCreator;
    setPreviewModalViewState: CollectionPageActions.ICollectionPageSetPreviewModalViewStateActionCreator;
    setPreviewComponentModalViewState: CollectionPageActions.ICollectionPageSetPreviewComponentModalViewStateActionCreator;
    toggleSectionsExpanded: CollectionPageActions.ICollectionPageToggleSectionsExpandedActionCreator;
    setSectionsExpanded: CollectionPageActions.ICollectionPageSetSectionsExpandedActionCreator;
    setSectionExpanded: CollectionPageActions.ICollectionPageSetSectionExpandedActionCreator;

    toggleExpand: ToggleActions.IToggleExpandedStateActionCreator;
}

type CollectionPageProps = ICollectionPageProps & ICollectionPageActions;

const reduxFormName: string = "reduxForms.collection";
const reduxFormNameCollectionItemComponent: string = "reduxForms.collection.collectionItemComponents";

class CollectionPage extends React.PureComponent<CollectionPageProps, any> {

    constructor(props: CollectionPageProps) {
        super(props);

        this.clearCollection = this.clearCollection.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
        this.handleAddItemComponent = this.handleAddItemComponent.bind(this);
        this.handleRemoveItemComponent = this.handleRemoveItemComponent.bind(this);
        this.handleMoveItemComponent = this.handleMoveItemComponent.bind(this);
        this.handleAddItemComponents = this.handleAddItemComponents.bind(this);
        this.handleEditItemComponent = this.handleEditItemComponent.bind(this);
        this.renderEditModal = this.renderEditModal.bind(this);

        this.state = {
            collectionItemComponentIndex: -1
        }
    }

    componentDidMount() {
        const {
            loadCollection,
            loadItemComponents,
            collectionId,
            createCollection,
            qaItemComponents,
            itemComponentTypeId
        } = this.props;

        loadItemComponents(qaItemComponents, itemComponentTypeId);

        if (collectionId) {
            loadCollection(collectionId);
        } else if (collectionId == 0) {
            createCollection();
        }
    }

    componentDidUpdate(prevProps: CollectionPageProps, prevState) {
        const {
            collectionId,
            collection,
            loadCollection,
            loadingCollection,
            loadCollectionSuccess,
            createCollection,
            creatingCollection,
            createCollectionSuccess,
            qaItemComponents,
            loadItemComponents,
            itemComponentTypeId,
            setSectionsExpanded,
            lockingCollection,
            lockCollectionSuccess,
            lockCollectionFailure
        } = this.props;

        if (collectionId && prevProps.collectionId != collectionId) {
            if (collectionId > 0) {
                loadCollection(collectionId);
            } else {
                createCollection();
            }
        }
        else if (collectionId == 0 && prevProps.collectionId != collectionId) {
            createCollection();
        }

        if (!loadingCollection && prevProps.loadingCollection) {
            if (loadCollectionSuccess) {
                this.loadForm();
                // if this has sections, close by default
                var expanded = true;
                if (collection && collection.id > 0 && collection.collectionSections.length > 0) {
                    expanded = false;
                }
                if (collection && collection.id > 9 && collection.collectionSections.length == 0 && collection.collectionItemComponents.length > 0) {
                    expanded = false;
                }
                setSectionsExpanded(expanded)
            }
        }

        if (!creatingCollection && prevProps.creatingCollection) {
            if (createCollectionSuccess) {
                this.loadForm();
                setSectionsExpanded(true);
            }
        }

        if (!lockingCollection && prevProps.lockingCollection) {
            if (lockCollectionSuccess) {
                this.loadForm();
                setSectionsExpanded(true);
            }
        }

        if (qaItemComponents != prevProps.qaItemComponents || itemComponentTypeId != prevProps.itemComponentTypeId) {
            loadItemComponents(qaItemComponents, itemComponentTypeId);
        }
    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearCollection();
        loadForm(reduxFormName, initialCollectionState);
        resetForm(reduxFormName);
    }

    clearCollection() {
        this.props.clearCollection();
        this.props.clearItemComponents();
    }

    showCollection(): boolean {
        const {
            loadingCollection,
            loadingItemComponents,
            creatingCollection
        } = this.props

        if (!loadingCollection && !creatingCollection && !loadingItemComponents) {
            return true;
        }

        return false;
    }

    render() {
        return <SubControlLayout loading={!this.showCollection()}>
            {this.renderContent()}
            {
                this.renderAddModal()
            }
            {
                this.renderEditModal()
            }
            {
                this.renderPreviewModal()
            }
            {
                this.renderPreviewComponentModal()
            }
        </SubControlLayout>;
    }

    renderContent() {
        const {
            form,
            setAddModalViewState,
            setPreviewModalViewState,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            column1,
            column2,
            column3,
            showSections,
            collectionSections,
            sectionsExpanded,
            toggleSectionsExpanded,
            sectionExpanded,
            setSectionExpanded,
            readonly,
            lockingCollection,
            permissions,
            lockCollection
            
        } = this.props;

        const isLocked = (form && form.locked);
        const formDisabled: boolean = readonly || ((formState && formState.formStatus == Dtos.FormStatus.Disabled)) || lockingCollection || isLocked;
        const hasLockPermission: boolean = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialItemTemplateLock, undefined, undefined);
        const hasUnLockPermission: boolean = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialItemTemplateUnLock, undefined, undefined);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
        };

        let footerFormComponents: { [index: string]: RegistrationFormComponent } = {
            header: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="border-0 d-flex pt-2 ">
                    <div className="container-fluid" style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <div className="row no-gutters">
                            <div className="col" style={{ textAlign: 'right' }}>
                                {(form && form.locked && !hasUnLockPermission) &&
                                    <span className="mr-2"><FontAwesomeIcon icon={FontAwesomeIcons.Regular.LOCKED} fixedWidth /> Locked and cannot be edited</span>
                                }
                                {(form && !form.locked && hasLockPermission) &&
                                    <button type="button" className="btn btn-secondary mr-2 btn-sm" onClick={() => lockCollection(form.id, true)} disabled={lockingCollection}>
                                        <FontAwesomeIcon icon={FontAwesomeIcons.Regular.LOCKED} fixedWidth />
                                        &nbsp;Lock
                                    </button>
                                }
                                {(form && form.locked && hasUnLockPermission) &&
                                    <button type="button" className="btn btn-secondary mr-2 btn-sm" onClick={() => lockCollection(form.id, false)} disabled={lockingCollection}>
                                        <FontAwesomeIcon icon={FontAwesomeIcons.Regular.UNLOCKED} fixedWidth />
                                        &nbsp;Unlock
                                    </button>
                                }
                                {!isLocked && collectionSections.length === 0 && (
                                    <button disabled={readonly}
                                        onClick={
                                            () => {
                                                setAddModalViewState(true);
                                            }
                                        }
                                        type="button"
                                        className="btn btn-outline-secondary  mr-2 btn-sm"
                                    >
                                        <span>Add Components</span>
                                    </button>
                                )}
                                <button 
                                    onClick={
                                        () => {
                                            setPreviewModalViewState(true);
                                        }
                                    }
                                    type="button"
                                    className="btn btn-outline-secondary btn-sm"
                                >
                                    <span>Preview</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            },
        };

        if (showSections) {
            formComponents = {
                collectionSectionsHeader: {
                    type: RegistrationComponent.RegistrationElement,
                    component: <div className="border-0 d-flex pt-4 justify-content-between">
                        <h3>{(collectionSections.length > 1 ? collectionSections.length.toString() + " Sections" : collectionSections.length === 1? "Section" : "No Sections")}</h3>
                        <button disabled={readonly} type="button" className="btn btn-sm" onClick={() => toggleSectionsExpanded()}><FontAwesomeIcon icon={sectionsExpanded ? FontAwesomeIcons.Solid.ANGLE_UP : FontAwesomeIcons.Light.EDIT} fixedWidth /></button>
                    </div>
                },
                collectionSections: {
                    type: RegistrationComponent.RegistrationGrid,
                    label: "Sections",
                    disabled: formDisabled,
                    hide: !sectionsExpanded,
                    formName: "CollectionSection",
                    className: "",
                    minRows: 0,
                    columnStyles: [
                        {
                            width: undefined,
                            minWidth: "250px"
                        },
                        {
                            width: undefined,
                            minWidth: "130px"
                        },
                        {
                            width: undefined,
                            minWidth: "100px"
                        },
                        {
                            width: undefined,
                            minWidth: "100px"
                        },
                        {
                            width: "125px"
                        },
                    ],
                    removeConfig: GridRemove.All,
                    addRow: (gridComponent, index, array) => {
                        const state: Dtos.CollectionSection = Object.assign(
                            { ...gridComponent.initialState }
                        );
                        return state;
                    },
                    components: {
                        name: {
                            type: RegistrationComponent.RegistrationControl,
                            inputType: TextFormInput,
                            inputDisabled: formDisabled,
                            inputLabel: getFormPropertyLabel("CollectionSection", "Name", formProperties),

                        },
                        rank: {
                            type: RegistrationComponent.RegistrationControl,
                            inputType: TextFormInput,
                            inputDisabled: formDisabled,
                            inputLabel: getFormPropertyLabel("CollectionSection", "Rank", formProperties),
                        },
                        isNaAllowed: {
                            type: RegistrationComponent.RegistrationControl,
                            inputType: CheckboxFormInput,
                            inputDisabled: formDisabled,
                            inputLabel: "N/A",
                        },
                        active: {
                            type: RegistrationComponent.RegistrationControl,
                            inputType: CheckboxFormInput,
                            inputDisabled: formDisabled,
                            inputLabel: getFormPropertyLabel("CollectionSection", "Active", formProperties),
                        },
                    },
                    initialState: initialCollectionSectionState,
                    onRemove: (model: string) => {
                        console.log('collectionSections', model);
                        changeForm(model + "active", false);
                        return true; // this was handled here
                    },
                    filterRow: (data: any) => {
                        if (data.active != true) {
                            return false
                        }
                        return true;
                    }
                },
                ...formComponents,
            };
        }

        const blankSection = new Dtos.CollectionSection();
        blankSection.id = 0;
        let sections: Dtos.CollectionSection[] = [blankSection, ...collectionSections.filter(f => f.active == true)];

        return <div>
            {
                renderFormControls(form, "collection", formComponents, lookups, validationFailures, formProperties, changeForm)
            }
            <div className="container-fluid">
                {sections.map((section) => {
                    const sectionColumn1 = column1.filter(f => f.collectionSectionId == section.id);
                    const sectionColumn2 = column2.filter(f => f.collectionSectionId == section.id);
                    const sectionColumn3 = column3.filter(f => f.collectionSectionId == section.id);
                    const empty = section.id > 0 && sectionColumn1.length == 0 && sectionColumn2.length == 0 && sectionColumn3.length == 0;
                    return (
                        <>
                            {section.id > 0 && (
                                <div key={"item-components-section-name" + section.id} className="row" style={{
                                    marginTop: '15px',
                                    padding: '15px 3px',
                                    backgroundColor: '#00447A',
                                    border: 'none',
                                    fontWeight: 'bold',
                                    color: 'white'
                                }}>
                                    <div className="col ">
                                        {section.name}
                                    </div>
                                    <div className="col-auto">
                                        {(!isLocked && sectionExpanded === section.id) && (<button disabled={readonly}
                                            onClick={
                                                () => {
                                                    setAddModalViewState(true);
                                                }
                                            }
                                            type="button"
                                            className="btn btn-outline-secondary mr-2 btn-sm"
                                        >
                                            <span>Add Component</span>
                                        </button>
                                        )}
                                        <button type="button" disabled={readonly} className="btn btn-dark  btn-sm" onClick={() => sectionExpanded === section.id ? setSectionExpanded(0) : setSectionExpanded(section.id)}><FontAwesomeIcon icon={sectionExpanded === section.id ? FontAwesomeIcons.Solid.ANGLE_UP : FontAwesomeIcons.Solid.ANGLE_DOWN} fixedWidth /></button>
                                    </div>
                                </div>
                            )}
                            {sectionExpanded === section.id && (
                                <div key={"item-components-section" + section.id} className="row no-gutters">
                                    <div className="col ">
                                        {sectionColumn1 && sectionColumn1.length > 0 && (
                                            <div className="item-components-column">
                                                {sectionColumn1.map((item, index) => this.renderItemComponent(item, index == 0, index == sectionColumn1.length - 1, true, false))}
                                            </div>
                                        )}
                                        {(!isLocked && empty) && <>
                                            <p className="text-center pt-3">
                                                Empty, <button disabled={readonly}
                                                    onClick={
                                                        () => {
                                                            setAddModalViewState(true);
                                                        }
                                                    }
                                                    type="button"
                                                    className="btn btn-outline-secondary mr-2 btn-sm"
                                                >
                                                    <span>Add Components</span>
                                                </button>
                                            </p>
                                        </>}
                                        {(!isLocked && !empty && sectionExpanded === section.id && section.id > 0 ) && <>
                                            <p className="text-center pt-3">
                                                <button disabled={readonly}
                                                    onClick={
                                                        () => {
                                                            setAddModalViewState(true);
                                                        }
                                                    }
                                                    type="button"
                                                    className="btn btn-outline-secondary mr-2 btn-sm"
                                                >
                                                    <span>Add Components</span>
                                                </button>
                                            </p>
                                        </>}
                                    </div>
                                    {((sectionColumn2 && sectionColumn2.length > 0) || (sectionColumn3 && sectionColumn3.length > 0)) && (<>
                                        <div className="col">
                                            {sectionColumn2 && sectionColumn2.length > 0 && (
                                                <div className="item-components-column">
                                                    {sectionColumn2.map((item, index) => this.renderItemComponent(item, index == 0, index == sectionColumn2.length - 1, false, false))}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col">
                                            {sectionColumn3 && sectionColumn3.length > 0 && (
                                                <div className="item-components-column">
                                                    {sectionColumn3.map((item, index) => this.renderItemComponent(item, index == 0, index == sectionColumn3.length - 1, false, true))}
                                                </div>
                                            )}
                                        </div>
                                    </>)}
                                </div>
                            )}
                        </>
                    );
                })}
            </div>
            {
                renderFormControls(form, "collection", footerFormComponents, lookups, validationFailures, formProperties, changeForm)
            }

        </div>
    }

    // refactor to control
    renderItemComponent(collectionItemComponent: Dtos.CollectionItemComponent, top: boolean, bottom: boolean, first: boolean, last: boolean) {
        const {
            itemComponents,
            qaItemComponents,
            lookups,
            readonly,
            form
        } = this.props;

        if (itemComponents) {
            // find the item component
            // @ts-ignore
            const itemComponent: Dtos.ItemComponent = itemComponents.find(i => i.id == collectionItemComponent.itemComponentId);
            const isLocked = form && form.locked;

            return <div className="item-components-border-padded">
                <div className="x-container">
                    <div className="row no-gutters item-components-controls">
                        <div className="col" style={{ textAlign: 'right' }}>
                            {!isLocked &&
                                <div className="btn-group" role="group" aria-label="Item component controls">
                                    <button disabled={top}
                                        onClick={
                                            () => {
                                                this.handleMoveItemComponent(collectionItemComponent, MoveDirection.Up);
                                            }
                                        }
                                        type="button"
                                        className="btn btn-light btn-sm"
                                    >
                                        <FontAwesomeIcon icon={FontAwesomeIcons.Light.UP} fixedWidth />
                                    </button>
                                    <button disabled={bottom}
                                        onClick={
                                            () => {
                                                this.handleMoveItemComponent(collectionItemComponent, MoveDirection.Down);
                                            }
                                        }
                                        type="button"
                                        className="btn btn-light btn-sm"
                                    >
                                        <FontAwesomeIcon icon={FontAwesomeIcons.Light.DOWN} fixedWidth />
                                    </button>
                                    <button disabled={first}
                                        onClick={
                                            () => {
                                                this.handleMoveItemComponent(collectionItemComponent, MoveDirection.Left);
                                            }
                                        }
                                        type="button"
                                        className="btn btn-light btn-sm"
                                    >
                                        <FontAwesomeIcon icon={FontAwesomeIcons.Light.LEFT} fixedWidth />
                                    </button>
                                    <button disabled={last}
                                        onClick={
                                            () => {
                                                this.handleMoveItemComponent(collectionItemComponent, MoveDirection.Right);
                                            }
                                        }
                                        type="button"
                                        className="btn btn-light mr-2 btn-sm"
                                    >
                                        <FontAwesomeIcon icon={FontAwesomeIcons.Light.RIGHT} fixedWidth />
                                    </button>
                                    {qaItemComponents && <button disabled={readonly}
                                        onClick={() => this.handleEditItemComponent(collectionItemComponent)}
                                        type="button"
                                        className="btn btn-light mr-2  btn-sm"
                                    >
                                        <FontAwesomeIcon icon={FontAwesomeIcons.Light.SETTINGS} fixedWidth />
                                    </button>
                                    }

                                    <button disabled={readonly || collectionItemComponent.isLocked}
                                        onClick={
                                            () => {
                                                this.handleRemoveItemComponent(collectionItemComponent);
                                            }
                                        }
                                        type="button"
                                        className="btn btn-light btn-sm"
                                    >
                                        <FontAwesomeIcon icon={FontAwesomeIcons.Light.TRASH} fixedWidth />
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="row no-gutters" style={{ paddingBottom: '10px', position: 'relative' }}>
                        {collectionItemComponent.isRequired && (<div className="col-auto">
                            <p className="p-1 pt-4 text-center"><FontAwesomeIcon icon={FontAwesomeIcons.Solid.ASTERISK} fixedWidth /></p>
                        </div>)}
                        <div className="item-components-overlay" ></div>
                        <div className="col mr-3 mt-3 mb-3 ml-3">
                            {itemComponent && itemComponent.itemComponentPrimitives && itemComponent.itemComponentPrimitives.map((item, index) => {
                                const name = "itemComponentInputPrimitives_" + item.id + "_" + index;
                                let primitiveItem = { ...item };
                                if (collectionItemComponent.overrideLabel) {
                                    primitiveItem.label = collectionItemComponent.overrideLabel;
                                }
                                return <PrimitiveFormInput item={primitiveItem} id={name} name={name} />
                            }
                            )}
                            {itemComponent && itemComponent.customInputControlName != undefined && itemComponent.customInputControlName != '' && (
                                <CustomFormInput item={itemComponent} id={"itemComponent_" + itemComponent.id + "_" + itemComponent.customInputControlName} name={"itemComponent_" + itemComponent.id + "_" + itemComponent.customInputControlName} />
                            )}
                        </div>
                        {(collectionItemComponent.reportingTagId != undefined && collectionItemComponent.reportingTagId > 0) && (<div className="col-auto" style={{ zIndex: 1 }}
                            data-tip={findLookupValue("CollectionItemComponent", "ReportingTagId", collectionItemComponent.reportingTagId, lookups)}>
                            <p className="p-1 pt-4 text-center"><FontAwesomeIcon icon={FontAwesomeIcons.Regular.TAG} fixedWidth /></p>
                            <ReactTooltip />
                        </div>)}
                        {(collectionItemComponent.instructions != undefined && collectionItemComponent.instructions != "") && (<div className="col-auto" style={{ zIndex: 1 }}
                            data-tip={collectionItemComponent.instructions}>
                            <p className="p-1 pt-4 text-center"><FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_CIRCLE} fixedWidth /></p>
                            <ReactTooltip />
                        </div>)}

                        {collectionItemComponent.isNaAllowed && <div className="col-auto">
                            <p className="p-1 pt-4 text-center"><span className="p-3"><FontAwesomeIcon icon={FontAwesomeIcons.Solid.SQUARE} fixedWidth /></span> <span className="py-3">N/A</span></p>
                        </div>}
                    </div>
                </div>
            </div>;
        }

    }

    renderPreviewModal() {
        const {
            previewModalOpen,
            setPreviewModalViewState,
            column1preview,
            column2preview,
            column3preview,
            documents,
            collectionSections,
        } = this.props

        if (true) { // replace with checks for data

            const blankSection = new Dtos.CollectionSection();
            blankSection.id = 0;
            let sections: Dtos.CollectionSection[] = [blankSection, ...collectionSections.filter(f => f.active == true)];




            return <Modal open={previewModalOpen} disableCloseOnOverlayClick={true} onClose={() => { setPreviewModalViewState(false); }} size={ModalSize.Xl}>
                <div className="modal-header">
                    <h5 className="modal-title">{setModalTitle(undefined, "Preview")}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => { setPreviewModalViewState(false); }}>
                        <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                    </button>
                </div>
                <div className="modal-body">
                    {sections.map((section) => {
                        const sectionColumn1preview = column1preview.filter(f => f.collectionSectionId == section.id);
                        const sectionColumn2preview = column2preview.filter(f => f.collectionSectionId == section.id);
                        const sectionColumn3preview = column3preview.filter(f => f.collectionSectionId == section.id);
                        const sectionDocuments = documents.filter(f => f.collectionSectionId == section.id);

                        let isOneColumnWithDocs = false;

                        if ((sectionColumn1preview && sectionColumn1preview.length > 0) &&
                            (!sectionColumn2preview || sectionColumn2preview.length == 0) &&
                            (!sectionColumn3preview || sectionColumn3preview.length == 0) &&
                            (sectionDocuments && sectionDocuments.length > 0)
                        ) {
                            isOneColumnWithDocs = true;
                        }

                        return (
                            <>
                                {section.id > 0 && (
                                    <div key={"item-components-section-name" + section.id} className="row" style={{
                                        margin: '15px 10px 10px 3px',
                                        padding: '15px 3px',
                                        backgroundColor: '#00447A',
                                        border: 'none',
                                        fontWeight: 'bold',
                                        color: 'white'
                                    }}>
                                        <div className="col ">
                                            {section.name}
                                        </div>
                                    </div>
                                )}
                                {isOneColumnWithDocs && (<>
                                    <div className="x-container-fluid">
                                        <div className="row">
                                            {sectionColumn1preview && sectionColumn1preview.length > 0 && (
                                                <div className="col-4">
                                                    <div className="x-item-components-column">
                                                        {sectionColumn1preview.map((item, index) => this.renderItemComponentPreview(item))}
                                                    </div>
                                                </div>
                                            )}
                                            {sectionDocuments && sectionDocuments.length > 0 && (
                                                <div className="col-8">
                                                    <div className="x-item-components-column">
                                                        {sectionDocuments.map((item, index) => this.renderItemComponentDocumentPreview(item))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                                )}
                                {!isOneColumnWithDocs && (<>
                                    <div className="x-container-fluid">
                                        <div className="row">
                                            {sectionColumn1preview && sectionColumn1preview.length > 0 && (
                                                <div className="col ">
                                                    <div className="x-item-components-column">
                                                        {sectionColumn1preview.map((item, index) => this.renderItemComponentPreview(item))}
                                                    </div>
                                                </div>
                                            )}
                                            {sectionColumn2preview && sectionColumn2preview.length > 0 && (
                                                <div className="col ">
                                                    <div className="x-item-components-column">
                                                        {sectionColumn2preview.map((item, index) => this.renderItemComponentPreview(item))}
                                                    </div>
                                                </div>
                                            )}
                                            {sectionColumn3preview && sectionColumn3preview.length > 0 && (
                                                <div className="col ">
                                                    <div className="x-item-components-column">
                                                        {sectionColumn3preview.map((item, index) => this.renderItemComponentPreview(item))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="x-container-fluid">
                                        <div className="row">
                                            <div className="col ">
                                                {sectionDocuments && sectionDocuments.length > 0 && (
                                                    <div className="x-item-components-column">
                                                        {sectionDocuments.map((item, index) => this.renderItemComponentDocumentPreview(item))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                                )}
                            </>
                        )
                    })}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger" onClick={() => { setPreviewModalViewState(false); }}>Close</button>
                </div>
            </Modal>
        }
    }

    renderItemComponentPreview(collectionItemComponent: Dtos.CollectionItemComponent) {
        const {
            itemComponents
        } = this.props;

        if (itemComponents) {
            // find the item component
            // @ts-ignore
            const itemComponent: Dtos.ItemComponent = itemComponents.find(i => i.id == collectionItemComponent.itemComponentId);

            return <div className="x-item-components-border-padded">
                <div className="x-container">
                    <div className="row no-gutters">
                        <div className="col">
                            {itemComponent && itemComponent.itemComponentPrimitives && itemComponent.itemComponentPrimitives.filter(f => f.itemPrimitive != Dtos.ItemPrimitive.Documents).map((item, index) => {
                                const name = "itemComponentInputPrimitives_" + item.id + "_" + index;
                                let primitiveItem = { ...item };
                                if (collectionItemComponent.overrideLabel) {
                                    primitiveItem.label = collectionItemComponent.overrideLabel;
                                }
                                return <PrimitiveFormInput item={primitiveItem} id={name} name={name} />
                            }
                            )}
                            {itemComponent && itemComponent.customInputControlName != '' && (
                                <CustomFormInput item={itemComponent} id={"itemComponent_" + itemComponent.id + "_" + itemComponent.customInputControlName} name={"itemComponent_" + itemComponent.id + "_" + itemComponent.customInputControlName} />
                            )}
                        </div>
                    </div>
                </div>
            </div>;
        }
    }

    renderItemComponentDocumentPreview(collectionItemComponent: Dtos.CollectionItemComponent) {
        const {
            itemComponents
        } = this.props;

        if (itemComponents) {
            // find the item component
            // @ts-ignore
            const itemComponent: Dtos.ItemComponent = itemComponents.find(i => i.id == collectionItemComponent.itemComponentId);

            return <div className="x-item-components-border-padded">
                <div className="x-container">
                    <div className="row no-gutters">
                        <div className="col">
                            {itemComponent.itemComponentPrimitives && itemComponent.itemComponentPrimitives.filter(f => f.itemPrimitive == Dtos.ItemPrimitive.Documents).map((item, index) => {
                                const name = "itemComponentInputPrimitives_" + item.id + "_" + index;
                                return <PrimitiveFormInput item={item} id={name} name={name} />
                            }
                            )}
                        </div>
                    </div>
                </div>
            </div>;
        }
    }

    renderPreviewComponentModal() {
        const {
            previewComponentModalOpen,
            setPreviewComponentModalViewState,
            previewItemComponent
        } = this.props

        if (previewItemComponent) { // replace with checks for data
            
            return <Modal open={previewComponentModalOpen} disableCloseOnOverlayClick={true} onClose={() => { setPreviewComponentModalViewState(false, null); }} size={ModalSize.Md}>
                <div className="modal-header">
                    <h5 className="modal-title">{setModalTitle(undefined, "Preview Component")}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => { setPreviewComponentModalViewState(false, null); }}>
                        <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                    </button>
                </div>
                <div className="modal-body">
                    {previewItemComponent.itemComponentPrimitives && previewItemComponent.itemComponentPrimitives.map((item, index) => {
                        const name = "itemComponentInputPrimitives_" + item.id + "_" + index;
                        return <PrimitiveFormInput item={item} id={name} name={name} />
                    })}
                    {previewItemComponent.customInputControlName != '' && (
                        <CustomFormInput item={previewItemComponent} id={"itemComponent_" + previewItemComponent.id + "_" + previewItemComponent.customInputControlName} name={"itemComponent_" + previewItemComponent.id + "_" + previewItemComponent.customInputControlName} />
                    )}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger" onClick={() => { setPreviewComponentModalViewState(false, null); }}>Close</button>
                </div>
            </Modal>
        }
    }

    renderAddModal() {
        const {
            addModalOpen,
            setAddFilter,
            addFilter,
            setAddModalViewState,
            itemComponents,
            setPreviewComponentModalViewState,
            setAddSelected,
            addSelected,
            showSections,
            collectionSections,
            sectionId,
            setSectionId
        } = this.props

        let filterFunction = (f: string, item: Dtos.ItemComponent) => {
            if (!item.active) {
                return false;
            }
            //debugger;
            if (addSelected.has(item.id)) {
                return true;
            }
            if (f == "") {
                return true;
            }
            if (item.name && item.name.toLowerCase().indexOf(f.toLowerCase()) > -1) {
                return true;
            }
            if (item.description && item.description.toLowerCase().indexOf(f.toLowerCase()) > -1) {
                return true;
            }
            if (item.itemComponentType && item.itemComponentType.value && item.itemComponentType.value.toLowerCase().indexOf(f.toLowerCase()) > -1) {
                return true;
            }
            return false;
        };

        //let isSelectedFunction = (itemIds, item) => {
        //    return itemIds.has(item.id);
        //};

        let actionSections = collectionSections ? collectionSections.filter(f => f.active) : [];

        let canSave = true;
        if (showSections && actionSections.length > 0 && sectionId == 0) {
            canSave = false;
        }

        if (true) { // replace with checks for data
            var self = this;
            return <Modal open={addModalOpen} disableCloseOnOverlayClick={true} onClose={() => { setAddModalViewState(false); }} size={ModalSize.Lg} >
                <div className="modal-header">
                    <h5 className="modal-title">{setModalTitle(undefined, "Add item components")}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => { setAddModalViewState(false); }}>
                        <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                    </button>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <div className="form-group mb-3 mt-3">
                                <input type="text" className="form-control" id="filter" aria-describedby="filter" placeholder="Filter..." value={addFilter} onChange={e => setAddFilter(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
                {(showSections && actionSections.length > 0) && (
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <div className="form-group mb-3 mt-3">
                                    <select className="form-control" value={sectionId} onChange={e => setSectionId(parseInt(e.target.value))}>
                                        <option value={0}>Select a section for the component...</option>
                                        {actionSections.map((section, indexSection) => (
                                            <option key={"section-selection-" + section.id} value={section.id}>{section.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="modal-body">
                    <div className="container-fluid" style={{ height: '500px', paddingLeft: 0, paddingRight: 0 }}>
                        {itemComponents && itemComponents.filter(f => filterFunction(addFilter, f)).map((item, index) =>
                            <div className="row row-component-item pt-3">
                                <div className="col-1" onClick={() => setAddSelected(item.id, !addSelected.has(item.id), 1)}>
                                    <FontAwesomeIcon fixedWidth icon={addSelected.has(item.id) ? FontAwesomeIcons.Solid.CHECK : FontAwesomeIcons.Light.UNCHECKED} />
                                </div>
                                <div className="col-6">
                                    <b>{item.name}</b>
                                    {item.description ? <><br /><small>{item.description}</small></> : <></>}
                                </div>
                                <div className="col-2">
                                    {item.itemComponentType ? item.itemComponentType.value : <></>}
                                </div>
                                <div className="col-1 p-0">
                                    {addSelected.has(item.id) && (
                                        <>
                                            <input
                                                autoFocus={false}
                                                value={addSelected.get(item.id)}
                                                type={"text"}
                                                className={"form-control"}
                                                name={item.id.toString() + "_count"}
                                                id={item.id.toString() + "_count"}
                                                onChange={(event) => {
                                                    const num = parseInt(event.target.value);
                                                    if (!isNaN(num)) {
                                                        setAddSelected(item.id, true, num);
                                                    }
                                                }}
                                                maxLength={100}
                                                disabled={false}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className="col-2">
                                    <button disabled={false}
                                        onClick={() => {
                                            setPreviewComponentModalViewState(true, item);
                                        }
                                        }
                                        type="button"
                                        className="btn btn-sm btn-block btn-outline-info mb-2"
                                    >
                                        Preview
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={() => { this.handleAddItemComponents() }} disabled={!canSave || addSelected.size == 0}>Add</button>
                    <button type="button" className="btn btn-danger" onClick={() => { setAddModalViewState(false); }}>Close</button>
                </div>
            </Modal>
        }
    }

    renderEditModal() {
        const {
            editModalOpen,
            setEditModalViewState,
            lookups,
            validationFailures,
            formProperties,
            form,
            collection,
            changeForm
        } = this.props

        if (this.state.collectionItemComponentIndex > -1) {
            let collectionItemComponent = form.collectionItemComponents[this.state.collectionItemComponentIndex];
            let formDisabled = collectionItemComponent.isLocked;

            // build the lookup values, use the collection as it has saved items
            let itemComponentLookup = new Dtos.Lookup();
            itemComponentLookup.type = "CollectionItemComponent";
            itemComponentLookup.propertyName = "CollectionItemComponentId";
            itemComponentLookup.values = [];
            if (collection.collectionItemComponents !== undefined && collection.collectionItemComponents.length > 0) {
                collection.collectionItemComponents.forEach(item => {
                    let label = "";
                    if (item.itemComponent && item.itemComponent.itemComponentPrimitives && item.itemComponent.itemComponentPrimitives.length > 0) {
                        label = item.itemComponent.itemComponentPrimitives[0].label;
                    }
                    if (item.overrideLabel !== undefined && item.overrideLabel !== '') {
                        label = item.overrideLabel;
                    }
                    if (item.collectionSection && item.collectionSection.name !== "") {
                        itemComponentLookup.values.push({ id: item.id, rank: item.rank, active: item.active, value: "[" + item.collectionSection.name + "] " + label });
                    } else {
                        itemComponentLookup.values.push({ id: item.id, rank: item.rank, active: item.active, value: label });
                    }
                });
            }

            let itemComponentReduxFormName = "reduxForms.collection.collectionItemComponents[" + this.state.collectionItemComponentIndex.toString() + "]";

            let formComponents: { [index: string]: RegistrationFormComponent } = {
                overrideLabel: {
                    type: RegistrationComponent.RegistrationControl,
                    inputType: TextFormInput,
                    inputDisabled: false,
                    inputLabel: "Label (optional override)",
                    changeAction: (model, value, form, parentModel) => {
                        changeForm(itemComponentReduxFormName + ".overrideLabel", value);
                        console.log('changeAction', model, value, form, parentModel);
                    }
                },
                instructions: {
                    type: RegistrationComponent.RegistrationControl,
                    inputType: TextAreaFormInput,
                    inputDisabled: false,
                    inputLabel: "Instructions",
                    changeAction: (model, value, form, parentModel) => {
                        changeForm(itemComponentReduxFormName + ".instructions", value);
                        console.log('changeAction', model, value, form, parentModel);
                    }
                },
                reportingTagId: {
                    type: RegistrationComponent.RegistrationControl,
                    inputType: SelectFormInput,
                    inputDisabled: false,
                    inputLabel: "Reporting Tag",
                    inputProps: {
                        options: generateOptionsFromLookup(findLookup("CollectionItemComponent", "ReportingTagId", lookups), collectionItemComponent ? collectionItemComponent.reportingTagId : 0)
                    },
                    changeAction: (model, value, form, parentModel) => {
                        changeForm(itemComponentReduxFormName + ".reportingTagId", value);
                        changeForm(itemComponentReduxFormName + ".reportingTag", null);
                    },
                },
                calculationMethod: {
                    type: RegistrationComponent.RegistrationControl,
                    inputType: SelectFormInput,
                    inputDisabled: false,
                    inputLabel: "Calculation Method",
                    inputProps: {
                        options: generateOptionsFromLookup(findLookup("CollectionItemComponent", "CalculationMethod", lookups), collectionItemComponent ? collectionItemComponent.calculationMethod : 0)
                    },
                },
                baselineAmount: {
                    type: RegistrationComponent.RegistrationControl,
                    inputType: NumberFormInput,
                    inputLabel: "Baseline",
                    inputProps: {
                        precision: 4
                    }
                },
                maxValue: {
                    type: RegistrationComponent.RegistrationControl,
                    inputType: NumberFormInput,
                    inputLabel: "Acceptable Max Limit",
                    inputProps: {
                        precision: 4
                    }
                },
                minValue: {
                    type: RegistrationComponent.RegistrationControl,
                    inputType: NumberFormInput,
                    inputLabel: "Major Variation Min Limit",
                    inputProps: {
                        precision: 4
                    }
                },
                calculation1CollectionItemComponentId: {
                    type: RegistrationComponent.RegistrationControl,
                    inputType: SelectFormInput,
                    inputDisabled: false,
                    inputLabel: "Calculated Field 1",
                    inputProps: {
                        options: generateOptionsFromLookup(itemComponentLookup, collectionItemComponent ? collectionItemComponent.calculation1CollectionItemComponentId : 0)
                    },
                    changeAction: (model, value, form, parentModel) => {
                        changeForm(itemComponentReduxFormName + ".calculation1CollectionItemComponentId", value);
                    },
                },
                calculation2CollectionItemComponentId: {
                    type: RegistrationComponent.RegistrationControl,
                    inputType: SelectFormInput,
                    inputDisabled: false,
                    inputLabel: "Calculated Field 2",
                    inputProps: {
                        options: generateOptionsFromLookup(itemComponentLookup, collectionItemComponent ? collectionItemComponent.calculation2CollectionItemComponentId : 0)
                    },
                    changeAction: (model, value, form, parentModel) => {
                        changeForm(itemComponentReduxFormName + ".calculation2CollectionItemComponentId", value);
                    },
                },
                active: {
                    type: RegistrationComponent.RegistrationControl,
                    inputType: CheckboxFormInput,
                    inputDisabled: formDisabled,
                    inputProps: {
                        label: "Active"
                    },
                    inputLabel: " ",
                    changeAction: (model, value, form, parentModel) => {
                        changeForm(itemComponentReduxFormName + ".active", value);
                        console.log('changeAction', model, value, form, parentModel);
                    }
                },
                isRequired: {
                    type: RegistrationComponent.RegistrationControl,
                    inputType: CheckboxFormInput,
                    inputDisabled: false,
                    inputProps: {
                        label: "Required"
                    },
                    inputLabel: " ",
                    changeAction: (model, value, form, parentModel) => {
                        changeForm(itemComponentReduxFormName + ".isRequired", value);
                        console.log('changeAction', model, value, form, parentModel);
                    }
                },
                isNaAllowed: {
                    type: RegistrationComponent.RegistrationControl,
                    inputType: CheckboxFormInput,
                    inputDisabled: false,
                    inputProps: {
                        label: "N/A Allowed"
                    },
                    inputLabel: " ",
                    changeAction: (model, value, form, parentModel) => {
                        changeForm(itemComponentReduxFormName + ".isNaAllowed", value);
                        console.log('changeAction', model, value, form, parentModel);
                    }
                },
            };

            return <Modal open={editModalOpen} disableCloseOnOverlayClick={true} onClose={() => { setEditModalViewState(false); }} size={ModalSize.Lg}>
                <div className="modal-header">
                    <h5 className="modal-title">Settings</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => { setEditModalViewState(false); }}>
                        <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                    </button>
                </div>
                <div className="modal-body py-0">
                    <Form model={itemComponentReduxFormName}
                        onSubmit={(val, event) => setEditModalViewState(false)}
                        onSubmitFailed={(val) => { }}
                    >
                        {formDisabled && <div className="form-group row pt-2 pb-0 border border-bottom-1 border-top-0">
                            <div className="col-md-12 mt-2">
                                <div className="alert alert-warning d-flex">
                                    <div className="mr-2">
                                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE} fixedWidth />
                                    </div>
                                    <span>This item component has be used on a trial form</span>
                                </div>
                            </div>
                        </div>}
                        {
                            renderFormControls(collectionItemComponent, "collectionItemComponent", formComponents, lookups, validationFailures, formProperties, changeForm)
                        }
                    </Form>
                </div>
            </Modal>
        }
    }

    handleAddItemComponents() {
        const {
            setAddModalViewState,
            form,
            changeForm,
            addSelected,
            sectionId
        } = this.props;

        let array: any[] = [...form["collectionItemComponents"]];
        let newRank = 1;
        if (form.collectionItemComponents.length > 0) {
            form.collectionItemComponents.forEach(p => {
                if (p.rank >= newRank) {
                    newRank = p.rank;
                }
            });
        }
        newRank = newRank + 1;

        for (let [id, count] of addSelected) {
            for (var i = 0; i < count; i++) {
                // @ts-ignore
                const newItem: Dtos.CollectionItemComponent = Object.assign({ ...initialCollectionItemComponentState });
                newItem.itemComponentId = id;
                newItem.rank = newRank;
                newItem.collectionSectionId = 0;
                newRank = newRank + 1;
                if (sectionId > 0) {
                    newItem.collectionSectionId = sectionId;
                }
                array.push(newItem);
            }
        }
        changeForm(reduxFormNameCollectionItemComponent, array);
        setAddModalViewState(false);
    }

    handleAddItemComponent(item: Dtos.ItemComponent) {
        const {
            setAddModalViewState,
            form,
            changeForm,
        } = this.props

        // @ts-ignore
        const newItem: Dtos.CollectionItemComponent = Object.assign({ ...initialCollectionItemComponentState });
        newItem.itemComponentId = item.id;
        newItem.rank = 1;
        let array: any[] = [];
        if (form.collectionItemComponents.length > 0) {
            let newRank = 1;
            form.collectionItemComponents.forEach(p => { if (p.rank >= newRank) { newRank = p.rank; } });
            newItem.rank = newRank + 1;
            array = [...form["collectionItemComponents"], newItem];
        } else {
            array = [newItem];
        }

        changeForm(reduxFormNameCollectionItemComponent, array);

        setAddModalViewState(false);
    }

    handleRemoveItemComponent(collectionItemComponent: Dtos.CollectionItemComponent) {
        const {
            form,
            changeForm,
        } = this.props

        // @ts-ignore
        let found: Dtos.CollectionItemComponent = form["collectionItemComponents"].find(f =>
            f.rank == collectionItemComponent.rank &&
            f.column == collectionItemComponent.column &&
            f.itemComponentId == collectionItemComponent.itemComponentId);

        // Find it in the list
        if (found) {
            const index = form["collectionItemComponents"].indexOf(found);
            if (index > -1) {
                changeForm(reduxFormNameCollectionItemComponent + "[" + index.toString() + "].active", false);
            }
        }
    }

    handleMoveItemComponent(collectionItemComponent: Dtos.CollectionItemComponent, direction: MoveDirection) {
        const {
            form,
            changeForm,
        } = this.props

        if (direction == MoveDirection.Up) {
            // find the previous rank, and then swap
            let swapIndex = -1;
            let currentIndex = -1
            let swapRank = -1;
            let newArray = [...form.collectionItemComponents].sort((a, b) => a.rank - b.rank);
            newArray.forEach((swapItem, i) => {
                if (swapItem.rank < collectionItemComponent.rank && swapItem.column == collectionItemComponent.column && swapItem.active) {
                    swapIndex = i;
                    swapRank = swapItem.rank;
                } else if (swapItem.rank == collectionItemComponent.rank && swapItem.column == collectionItemComponent.column && swapItem.active) {
                    currentIndex = i;
                }
            });
            if (swapIndex > -1 && currentIndex > -1) {
                // @ts-ignore
                newArray[swapIndex] = Object.assign({ ...newArray[swapIndex], rank: collectionItemComponent.rank });
                // @ts-ignore
                newArray[currentIndex] = Object.assign({ ...newArray[currentIndex], rank: swapRank });
                changeForm(reduxFormNameCollectionItemComponent, newArray);
            }
        } else if (direction == MoveDirection.Down) {
            // find the previous rank, and then swap
            let swapIndex = -1;
            let swapRank = -1;
            let currentIndex = -1
            let newArray = [...form.collectionItemComponents].sort((a, b) => a.rank - b.rank);
            newArray.forEach((swapItem, i) => {
                if (swapItem.rank > collectionItemComponent.rank && swapItem.column == collectionItemComponent.column && swapItem.active && swapIndex == -1) {
                    swapIndex = i;
                    swapRank = swapItem.rank;
                } else if (swapItem.rank == collectionItemComponent.rank && swapItem.column == collectionItemComponent.column && swapItem.active) {
                    currentIndex = i;
                }
            });
            if (swapIndex > -1 && currentIndex > -1) {
                // @ts-ignore
                newArray[swapIndex] = Object.assign({ ...newArray[swapIndex], rank: collectionItemComponent.rank });
                // @ts-ignore
                newArray[currentIndex] = Object.assign({ ...newArray[currentIndex], rank: swapRank });
                changeForm(reduxFormNameCollectionItemComponent, newArray);
            }
        } else if (direction == MoveDirection.Left) {
            // move to the next column
            // @ts-ignore
            let found: Dtos.CollectionItemComponent = form["collectionItemComponents"].find(f =>
                f.rank == collectionItemComponent.rank &&
                f.column == collectionItemComponent.column &&
                f.itemComponentId == collectionItemComponent.itemComponentId);

            // Find it in the list
            if (found && collectionItemComponent.column > 1) {
                // @ts-ignore
                const index = form["collectionItemComponents"].indexOf(found);
                if (index > -1) {
                    changeForm(reduxFormNameCollectionItemComponent + "[" + index.toString() + "].column", collectionItemComponent.column - 1);
                }
            }
        } else if (direction == MoveDirection.Right) {
            // move to the next column
            // @ts-ignore
            let found: Dtos.CollectionItemComponent = form["collectionItemComponents"].find(f =>
                f.rank == collectionItemComponent.rank &&
                f.column == collectionItemComponent.column &&
                f.itemComponentId == collectionItemComponent.itemComponentId);

            // Find it in the list
            if (found && collectionItemComponent.column < 3) {
                const index = form["collectionItemComponents"].indexOf(found);
                if (index > -1) {
                    changeForm(reduxFormNameCollectionItemComponent + "[" + index.toString() + "].column", collectionItemComponent.column + 1);
                }
            }
        }
    }

    handleEditItemComponent(collectionItemComponent: Dtos.CollectionItemComponent) {
        const {
            setEditModalViewState,
            form
        } = this.props

        let collectionItemComponentIndex = form.collectionItemComponents.indexOf(collectionItemComponent);

        this.setState({
            collectionItemComponent,
            collectionItemComponentIndex
        }, () => {
            setEditModalViewState(true);
        });
    }

    handleOnSubmit(data: Dtos.Collection) {
    }

    handleOnSubmitFailed(data: Dtos.Collection) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/itemcomponents");
    }

    loadForm() {
        const {
            loadForm,
            collection,
        } = this.props

        let extraData: Dtos.Collection = {} as Dtos.Collection;

        // @ts-ignore
        loadForm(reduxFormName, Object.assign({ ...collection }, extraData));
    }

}

const filterItemComponentsByActive = (itemComponents: Dtos.ItemComponent[], active: boolean): Dtos.ItemComponent[] => {

    if (itemComponents) {
        return itemComponents.filter(p => p.active == active);
    }

    return [];
}

const allItemComponents = (itemComponents: Dtos.ItemComponent[]): Dtos.ItemComponent[] => {

    if (itemComponents) {
        return itemComponents;
    }

    return [];
}

const filterCollectionItemComponentsByColumn = (collection: Dtos.Collection, column: number): Dtos.CollectionItemComponent[] => {

    if (collection && collection.collectionItemComponents) {
        return collection.collectionItemComponents.filter(p => p.active == true && p.column == column).sort((a, b) => a.rank - b.rank);
    }

    return [];
}

const isOnlyDocuments = (collectionItemComponent: Dtos.CollectionItemComponent, itemComponents: Dtos.ItemComponent[]): boolean => {
    if (!itemComponents) return false;
    // @ts-ignore
    const itemComponent: Dtos.ItemComponent = itemComponents.find(f => f.id == collectionItemComponent.itemComponentId);
    if (itemComponent && (!itemComponent.customInputControlName || itemComponent.customInputControlName == '')) {
        if (itemComponent.itemComponentPrimitives.length > 0 &&
            itemComponent.itemComponentPrimitives.filter(f => f.itemPrimitive != Dtos.ItemPrimitive.Documents).length == 0) {
            return true;
        }
    }
    return false;
}

const hasDocuments = (collectionItemComponent: Dtos.CollectionItemComponent, itemComponents: Dtos.ItemComponent[]): boolean => {
    if (!itemComponents) return false;
    // @ts-ignore
    const itemComponent: Dtos.ItemComponent = itemComponents.find(f => f.id == collectionItemComponent.itemComponentId);

    if (itemComponent && (!itemComponent.customInputControlName || itemComponent.customInputControlName == '')) {
        if (itemComponent.itemComponentPrimitives.filter(f => f.itemPrimitive == Dtos.ItemPrimitive.Documents).length > 0) {
            return true;
        }
    }
    return false;
}

const filterCollectionItemComponentsByColumnPreview = (collection: Dtos.Collection, column: number, itemComponents: Dtos.ItemComponent[]): Dtos.CollectionItemComponent[] => {

    if (collection && collection.collectionItemComponents) {
        return collection.collectionItemComponents.filter(p =>
            p.active == true &&
            p.column == column &&
            !isOnlyDocuments(p, itemComponents)
        ).sort((a, b) => a.rank - b.rank);
    }

    return [];
}

const filterCollectionItemComponentsByDocumentsPreview = (collection: Dtos.Collection, itemComponents: Dtos.ItemComponent[]): Dtos.CollectionItemComponent[] => {

    if (collection && collection.collectionItemComponents) {
        return collection.collectionItemComponents.filter(p =>
            p.active == true &&
            hasDocuments(p, itemComponents)
        ).sort((a, b) => a.rank - b.rank);
    }

    return [];
}

const mapStateToProps = (state: ITmdState, ownProps): ICollectionPageProps => {

    let collection: Dtos.Collection | undefined = !(state.collection.formData instanceof Array) ? state.collection.formData : undefined;

    const itemComponents: Dtos.ItemComponent[] | undefined = state.itemComponent.formData instanceof Array ? allItemComponents(state.itemComponent.formData) : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {
        readonly: ownProps.readonly,
        qaItemComponents: ownProps.qaItemComponents !== undefined ? ownProps.qaItemComponents : false,
        itemComponentTypeId: ownProps.itemComponentTypeId !== undefined ? ownProps.itemComponentTypeId : 0,

        collectionId: ownProps.collectionId ? ownProps.collectionId : 0,
        showSections: ownProps.showSections ? ownProps.showSections : false,

        history: ownProps.history,
        location: state.routing.location,

        collection: collection!,
        collectionSections: collection ? collection.collectionSections : [],

        loadingCollection: state.collection.loadState && state.collection.loadState.status == RequestState.Pending,
        loadCollectionSuccess: state.collection.loadState && state.collection.loadState.status == RequestState.Success,
        loadCollectionFailure: state.collection.loadState && state.collection.loadState.status == RequestState.Failure,

        creatingCollection: state.collection.createState && state.collection.createState.status == RequestState.Pending,
        createCollectionSuccess: state.collection.createState && state.collection.createState.status == RequestState.Success,
        createCollectionFailure: state.collection.createState && state.collection.createState.status == RequestState.Failure,

        lockingCollection: state.collection.lockState && state.collection.lockState.status == RequestState.Pending,
        lockCollectionSuccess: state.collection.lockState && state.collection.lockState.status == RequestState.Success,
        lockCollectionFailure: state.collection.lockState && state.collection.lockState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: state.collection.lookups,

        form: state.reduxForms.collection,
        formState: state.collection.formState,
        formProperties: state.collection.formProperties,

        reduxFormState: state.reduxForms.formState.collection,
        validationFailures: collection ?
            state.collection.validationFailures :
            undefined,

        addModalOpen: state.collectionPage.addModalOpen,
        addFilter: state.collectionPage.addFilter,
        sectionId: state.collectionPage.sectionId,
        addSelected: state.collectionPage.selected,
        editModalOpen: state.collectionPage.editModalOpen,
        previewModalOpen: state.collectionPage.previewModalOpen,
        previewComponentModalOpen: state.collectionPage.previewComponentModalOpen,
        previewItemComponent: state.collectionPage.previewItemComponent,
        sectionsExpanded: state.collectionPage.sectionsExpanded,
        sectionExpanded: state.collectionPage.sectionExpanded,

        itemComponents: itemComponents,
        activeItemComponents: filterItemComponentsByActive(itemComponents!, true),
        loadingItemComponents: state.itemComponent.loadState && state.itemComponent.loadState.status == RequestState.Pending,

        column1: filterCollectionItemComponentsByColumn(state.reduxForms.collection, 1),
        column2: filterCollectionItemComponentsByColumn(state.reduxForms.collection, 2),
        column3: filterCollectionItemComponentsByColumn(state.reduxForms.collection, 3),
        column1preview: filterCollectionItemComponentsByColumnPreview(state.reduxForms.collection, 1, itemComponents),
        column2preview: filterCollectionItemComponentsByColumnPreview(state.reduxForms.collection, 2, itemComponents),
        column3preview: filterCollectionItemComponentsByColumnPreview(state.reduxForms.collection, 3, itemComponents),
        documents: filterCollectionItemComponentsByDocumentsPreview(state.reduxForms.collection, itemComponents),

        toggleItems: state.togglePage.items
    };
};

const mapDispatchToProps = (dispatch): ICollectionPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadCollection: bindActionCreators(CollectionActions.LoadCollectionById, dispatch),
        clearCollection: bindActionCreators(CollectionActions.Clear, dispatch),
        createCollection: bindActionCreators(CollectionActions.CreateCollection, dispatch),
        lockCollection: bindActionCreators(CollectionActions.LockCollection, dispatch),

        setAddModalViewState: bindActionCreators(CollectionPageActions.setAddModalViewState, dispatch),
        setAddFilter: bindActionCreators(CollectionPageActions.setAddFilter, dispatch),
        setSectionId: bindActionCreators(CollectionPageActions.setSection, dispatch),
        setAddSelected: bindActionCreators(CollectionPageActions.setAddSelected, dispatch),
        setEditModalViewState: bindActionCreators(CollectionPageActions.setEditModalViewState, dispatch),
        setPreviewModalViewState: bindActionCreators(CollectionPageActions.setPreviewModalViewState, dispatch),
        setPreviewComponentModalViewState: bindActionCreators(CollectionPageActions.setPreviewComponentModalViewState, dispatch),
        toggleSectionsExpanded: bindActionCreators(CollectionPageActions.toggleSectionsExpanded, dispatch),
        setSectionsExpanded: bindActionCreators(CollectionPageActions.setSectionsExpanded, dispatch),
        setSectionExpanded: bindActionCreators(CollectionPageActions.setSectionExpanded, dispatch),

        loadItemComponents: bindActionCreators(ItemComponentActions.LoadItemComponent, dispatch),
        clearItemComponents: bindActionCreators(ItemComponentActions.Clear, dispatch),

        toggleExpand: bindActionCreators(ToggleActions.ToggleExpanded, dispatch)
    };
};

const CollectionPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CollectionPage)

export default CollectionPageContainer;

