import * as React from 'react';
import './GroupItemPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import {  RestrictedLayout } from "../../../components/layouts/index";
import { IGroupItemReduxFormState, initialGroupItemState } from "../../../reducers/reactReduxForms/groupItem";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as GroupItemActions from '../../../actions/groupItem';
import * as GroupActions from '../../../actions/groups';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../../components/form';
import { SettingsSubMenu, GroupSubMenu } from '../../../helpers/subMenuHelper';
import * as FormHelper from '../../../helpers/formHelper';

import {
    CollectionPage
} from "../../../containers/index";

interface IGroupItemPageParams {
    groupId: number;
    groupItemId: number;
}

interface IGroupItemPageProps {

    form: Dtos.GroupItem & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IGroupItemReduxFormState;

    collectionFormState: Dtos.Collection & IRegistrationFormState;

    location: Location;
    groupId: number;
    groupItemId: number;
    match: match<IGroupItemPageParams>;
    history: History;

    // From state
    groupItem: Dtos.GroupItem;
    group: Dtos.Group;
    collection: Dtos.Collection;
    lookups: Dtos.Lookup[];

    loadingGroup: boolean;

    loadingGroupItem: boolean;
    loadGroupItemSuccess: boolean;
    loadGroupItemFailure: boolean;

    savingGroupItem: boolean;
    saveGroupItemSuccess: boolean;
    saveGroupItemFailure: boolean;

    creatingGroupItem: boolean;
    createGroupItemSuccess: boolean;
    createGroupItemFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface IGroupItemPageActions {
    clearGroupItem: GroupItemActions.IGroupItemClearActionCreator;
    loadGroupItem: GroupItemActions.IGroupItemLoadByIdActionCreator;
    saveGroupItem: GroupItemActions.IGroupItemSaveActionCreator;
    createGroupItem: GroupItemActions.IGroupItemCreateActionCreator;

    clearGroup: GroupActions.IGroupClearActionCreator;
    loadGroup: GroupActions.IGroupLoadByIdActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type GroupItemPageProps = IGroupItemPageProps & IGroupItemPageActions;

const formName: string = "GroupItem";
const reduxFormName: string = "reduxForms.groupItem";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class GroupItemPage extends React.PureComponent<GroupItemPageProps, any> {

    constructor(props: GroupItemPageProps) {
        super(props);

        this.clearGroupItem = this.clearGroupItem.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadGroupItem,
            navigate,
            navigateReplace,
            groupItemId,
            groupId,
            loadGroup,
            createGroupItem
        } = this.props;

        if (groupItemId) {
            loadGroupItem(groupItemId);
        } else {
            createGroupItem(groupId)
        }
        loadGroup(groupId);
    }

    componentWillReceiveProps(nextProps: GroupItemPageProps) {
    }

    componentDidUpdate(prevProps: GroupItemPageProps, prevState) {
        const {
            form,
            groupId,
            groupItemId,
            groupItem,
            navigateReplace,
            loadGroupItem,
            loadGroup,
            loadingGroupItem,
            loadGroupItemSuccess,
            createGroupItem,
            creatingGroupItem,
            createGroupItemSuccess,
            savingGroupItem,
            saveGroupItemSuccess,
            saveGroupItemFailure,
            validationFailures,
            navigate
        } = this.props;

        if (groupId && prevProps.groupId != groupId) {
            loadGroup(groupId);
        }

        if (groupItemId && prevProps.groupItemId != groupItemId) {
            loadGroupItem(groupItemId);
        }
        else if (!groupItemId && prevProps.groupItemId) {
            createGroupItem(groupId);
        }

        if (!loadingGroupItem && prevProps.loadingGroupItem) {
            if (loadGroupItemSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingGroupItem && prevProps.savingGroupItem) {
            if (saveGroupItemSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/groups/" + groupId + "/groupitems")
                } else if (!form.saveAndReturn && groupItemId && groupItemId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !groupItemId && groupItem && groupItem.id > 0) {
                    navigate("/groups/" + groupId + "/groupitems/" + groupItem.id);
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingGroupItem && prevProps.creatingGroupItem) {
            if (createGroupItemSuccess) {
                this.loadForm();
            }
            else {

            }
        }
    }

    componentWillUpdate(nextProps: GroupItemPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearGroupItem();
        loadForm(reduxFormName, initialGroupItemState);
        resetForm(reduxFormName);
    }

    clearGroupItem() {
        this.props.clearGroupItem();
        this.props.clearGroup();
    }

    showGroupItem(): boolean {
        const {
            loadingGroup,
            loadingGroupItem,
            loadGroupItemSuccess
        } = this.props

        if (!loadingGroup && !loadingGroupItem && loadGroupItemSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingGroup,
            loadingGroupItem,
            groupItemId,
            permissions,
            group
        } = this.props;

        let id = group ? group.id : 0;

        return <RestrictedLayout
            permission={Dtos.Permission.GroupItemsView }
            subMenuItems={GroupSubMenu(id, permissions)}
            loading={loadingGroup || loadingGroupItem}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            form,
            permissions,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingGroupItem
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.GroupItemsUpdate, Dtos.Permission.GroupItemsCreate);
        const formDisabled: boolean = !hasPermission || savingGroupItem || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            name: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            categoryId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("GroupItem", "CategoryId", lookups), form.categoryId)
                },
                changeAction: (model, value, form, parentModel) => {
                    changeForm(model, value);
                    changeForm(reduxFormName + ".category", null);
                },
            },
            description: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextAreaFormInput,
                inputDisabled: formDisabled,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            groupItemsHeader: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="border-0 d-flex pt-4 ">
                    <h3>Group Item Components</h3>
                </div>
            },
            collectionGroup: {
                type: RegistrationComponent.RegistrationElement,
                component: this.renderCollection()
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            },
            allowIntraVersions: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Allow Intra Version"
                },
                inputLabel: " ",
            },
            initialVersion: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            }
        };

        return <div>
            <h1>Group Item</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "groupItem", formComponents, lookups, validationFailures, formProperties, changeForm)
                }

                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
        </div>

    }

    renderCollection() {
        const {
            form,
            loadingGroupItem,
            savingGroupItem,
            permissions,
            formState
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.GroupItemsUpdate, Dtos.Permission.GroupItemsCreate);
        const formDisabled: boolean = !hasPermission || savingGroupItem || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        if (form && !loadingGroupItem) {
            return <CollectionPage
                collectionId={form.collectionId || 0}
                readonly={formDisabled} />
        }
    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.GroupItem) {
        const {
            saveGroupItem,
            collection
        } = this.props;

        if (data) {
            let changes: Dtos.GroupItem = {} as Dtos.GroupItem;
            let collectionChanges: Dtos.Collection = {} as Dtos.Collection;
            saveGroupItem(Object.assign({ ...data }, changes), Object.assign({ ...collection }, collectionChanges));
        }
    }

    handleOnSubmitFailed(data: Dtos.GroupItem) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            groupId,
            navigate
        } = this.props;

        navigate("/groups/" + groupId + "/groupitems");
    }

    loadForm() {
        const {
            loadForm,
            groupItem,
        } = this.props

        let extraData: Dtos.GroupItem = {} as Dtos.GroupItem;

        loadForm(reduxFormName, Object.assign({ ...groupItem }, extraData));
    }
}


const mapStateToProps = (state: ITmdState, ownProps: GroupItemPageProps): IGroupItemPageProps => {

    let groupItem: Dtos.GroupItem | undefined = !(state.groupItem.formData instanceof Array) ? state.groupItem.formData : undefined;
    let group: Dtos.Group | undefined = !(state.groups.formData instanceof Array) ? state.groups.formData : undefined;
    let collection: Dtos.Collection | undefined = !(state.reduxForms.collection instanceof Array) ? state.reduxForms.collection : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        groupId: ownProps.match ? ownProps.match.params.groupId : undefined!,
        groupItemId: ownProps.match && ownProps.match.params.groupItemId.toString() != "create" ? ownProps.match.params.groupItemId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        groupItem: groupItem!,
        collection: collection!,

        group: group!,

        loadingGroupItem: state.groupItem.loadState && state.groupItem.loadState.status == RequestState.Pending,
        loadGroupItemSuccess: state.groupItem.loadState && state.groupItem.loadState.status == RequestState.Success,
        loadGroupItemFailure: state.groupItem.loadState && state.groupItem.loadState.status == RequestState.Failure,

        loadingGroup: state.groups.loadState && state.groups.loadState.status == RequestState.Pending,

        savingGroupItem: state.groupItem.saveState && state.groupItem.saveState.status == RequestState.Pending,
        saveGroupItemSuccess: state.groupItem.saveState && state.groupItem.saveState.status == RequestState.Success,
        saveGroupItemFailure: state.groupItem.saveState && state.groupItem.saveState.status == RequestState.Failure,

        creatingGroupItem: state.groupItem.createState && state.groupItem.createState.status == RequestState.Pending,
        createGroupItemSuccess: state.groupItem.createState && state.groupItem.createState.status == RequestState.Success,
        createGroupItemFailure: state.groupItem.createState && state.groupItem.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: state.groupItem.lookups,

        form: state.reduxForms.groupItem,
        formState: state.groupItem.formState,
        formProperties: state.groupItem.formProperties,

        collectionFormState: state.reduxForms.collection,

        reduxFormState: state.reduxForms.formState.groupItem,
        validationFailures: groupItem ?
            state.groupItem.validationFailures :
            undefined,

    };
};

const mapDispatchToProps = (dispatch): IGroupItemPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadGroupItem: bindActionCreators(GroupItemActions.LoadGroupItemById, dispatch),
        clearGroupItem: bindActionCreators(GroupItemActions.Clear, dispatch),

        saveGroupItem: bindActionCreators(GroupItemActions.SaveGroupItem, dispatch),
        createGroupItem: bindActionCreators(GroupItemActions.CreateGroupItem, dispatch),

        loadGroup: bindActionCreators(GroupActions.LoadGroupById, dispatch),
        clearGroup: bindActionCreators(GroupActions.Clear, dispatch)

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(GroupItemPage);
