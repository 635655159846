import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_INSTITUTIONS_LOAD,
    ACTION_INSTITUTIONS_LOAD_BY_ID,

    ACTION_INSTITUTIONS_SAVE,

    ACTION_INSTITUTIONS_CREATE,

    IInstitutionLoadAction,
    IInstitutionLoadByIdAction,
    IInstitutionLoadFailureAction,
    IInstitutionLoadSuccessAction,

    IInstitutionSaveAction,
    IInstitutionSaveSuccessAction,
    IInstitutionSaveFailureAction,

    IInstitutionCreateAction,
    IInstitutionCreateSuccessAction,
    IInstitutionCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/institution";
import { institutionsApi } from "../services/institutions";


export const
    getInstitutionsEpic = (action$): Observable<IInstitutionLoadSuccessAction | IInstitutionLoadFailureAction> => {
        return action$
            .ofType(ACTION_INSTITUTIONS_LOAD)
            .mergeMap((action: IInstitutionLoadAction) => {
                return institutionsApi
                    .getInstitutions()
                    .map(response =>
                        LoadSuccess(response.institutions, undefined, undefined, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getInstitutionByIdEpic = (action$): Observable<IInstitutionLoadSuccessAction | IInstitutionLoadFailureAction> => {
        return action$
            .ofType(ACTION_INSTITUTIONS_LOAD_BY_ID)
            .mergeMap((action: IInstitutionLoadByIdAction) => {
                return institutionsApi
                    .getInstitutionById(action.id)
                    .map(response =>
                        LoadSuccess(response.institution, response.formState, response.formProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setInstitutionEpic = (action$): Observable<IInstitutionSaveSuccessAction | IInstitutionSaveFailureAction> => {
        return action$
            .ofType(ACTION_INSTITUTIONS_SAVE)
            .mergeMap((action: IInstitutionSaveAction) => {
                return institutionsApi
                    .setInstitution(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.institution, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createInstitutionEpic = (action$): Observable<IInstitutionCreateSuccessAction | IInstitutionCreateFailureAction> => {
        return action$
            .ofType(ACTION_INSTITUTIONS_CREATE)
            .mergeMap((action: IInstitutionCreateAction) => {
                return institutionsApi
                    .createInstitution()
                    .map(response =>
                        CreateSuccess(response.institution, response.formState, response.formProperties, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



