import * as Dtos from '../dtos/Tmd.dtos';

export type AccessorFunction<T> = (data: T) => any;

/** @description Searches lookup array for a particular Lookup and returns it if found
 * @param {string} type the name of the object type.
 * @param {string} propertyName the string representation of the lookup to retrieve.
 * @param {Lookup[]} lookups the lookup array to search
 * @return {Lookup}
 */
export function findLookup(type: string, propertyName: string, lookups: Dtos.Lookup[]): Dtos.Lookup | undefined {

    if (type && propertyName && lookups) {
        const lookUp: Dtos.Lookup| undefined = lookups.find(l => l.type.toLocaleLowerCase() == type.toLocaleLowerCase() && l.propertyName.toLocaleLowerCase() == propertyName.toLocaleLowerCase());

        return lookUp;
    }

    return undefined;
}

/** @description Searches lookup array for a particular Lookup and returns it if found
 * @param {string} type the name of the object type.
 * @param {string} propertyName the string representation of the lookup to retrieve.
 * @param {number} id the id of the value to retrieve.
 * @param {Lookup[]} lookups the lookup array to search
 * @return {string}
 */
export function findLookupValue(type: string, propertyName: string, id: number, lookups: Dtos.Lookup[]): string| undefined {

    if (type && propertyName && lookups && id != undefined) {
        const lookUp: Dtos.Lookup | undefined= lookups.find(l => l.type.toLocaleLowerCase() == type.toLocaleLowerCase() && l.propertyName.toLocaleLowerCase() == propertyName.toLocaleLowerCase());

        if (lookUp &&
            lookUp.values) {
            const lookUpValue: Dtos.LookupValue| undefined = lookUp.values.find(lv => lv.id == id);

            if (lookUpValue) {
                return lookUpValue.value;
            }
        }
    }

    return undefined;
}

/** @description Searches lookup array for a particular Lookup and returns it if found.
 * @param {Lookup} lookup the lookup to generate options for.
 * @return {any[]} The array of options to push into the input component (radio button)
 */
export function generateOptionsFromLookup(lookup: Dtos.Lookup, currentValue?: number) {

    if (lookup) {
        var values = lookup.values.filter(f => f.active || (currentValue && f.id == currentValue));
        return generateOptionsFromObject(values, "value", "id");
    }

    return [];
}

export function generateOptionsFromObject<T>(object: T[],
    labelField: string | AccessorFunction<T>,
    valueField: string | AccessorFunction<T>,
    disabled: boolean | AccessorFunction<T> = false): any[] {

    if (object &&
        object.length > 0) {

        return object.map((value, index) => {
            return {
                label: typeof labelField === 'string' ? value[labelField] : labelField(value),
                value: typeof valueField === 'string' ? value[valueField] : valueField(value),
                disabled: typeof disabled === 'boolean' ? disabled : disabled(value)
            };
        });
    }

    return [];
}