import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadStandardEmailTemplate: IStandardEmailTemplateLoadActionCreator = () => {
        return {
            type: ACTION_STANDARDEMAILTEMPLATE_LOAD
        }
    },
    LoadStandardEmailTemplateById: IStandardEmailTemplateLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_STANDARDEMAILTEMPLATE_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IStandardEmailTemplateLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_STANDARDEMAILTEMPLATE_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IStandardEmailTemplateLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_STANDARDEMAILTEMPLATE_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveStandardEmailTemplate: IStandardEmailTemplateSaveActionCreator = (formData) => {
        return {
            type: ACTION_STANDARDEMAILTEMPLATE_SAVE,
            formData
        }
    },
    SaveSuccess: IStandardEmailTemplateSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_STANDARDEMAILTEMPLATE_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IStandardEmailTemplateSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_STANDARDEMAILTEMPLATE_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateStandardEmailTemplate: IStandardEmailTemplateCreateActionCreator = () => {

        return {
            type: ACTION_STANDARDEMAILTEMPLATE_CREATE
        }
    },
    CreateSuccess: IStandardEmailTemplateCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_STANDARDEMAILTEMPLATE_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: IStandardEmailTemplateCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_STANDARDEMAILTEMPLATE_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IStandardEmailTemplateClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_STANDARDEMAILTEMPLATE_CLEAR,
            propertiesToClear
        }
    },
    Filter: IStandardEmailTemplateFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_STANDARDEMAILTEMPLATE_FILTER,
            formFilter
        }
    };



export const
    ACTION_STANDARDEMAILTEMPLATE_LOAD = "ACTION_STANDARDEMAILTEMPLATE_LOAD",
    ACTION_STANDARDEMAILTEMPLATE_LOAD_BY_ID = "ACTION_STANDARDEMAILTEMPLATE_LOAD_BY_ID",
    ACTION_STANDARDEMAILTEMPLATE_LOAD_SUCCESS = "ACTION_STANDARDEMAILTEMPLATE_LOAD_SUCCESS",
    ACTION_STANDARDEMAILTEMPLATE_LOAD_FAILURE = "ACTION_STANDARDEMAILTEMPLATE_LOAD_FAILURE",

    ACTION_STANDARDEMAILTEMPLATE_SAVE = "ACTION_STANDARDEMAILTEMPLATE_SAVE",
    ACTION_STANDARDEMAILTEMPLATE_SAVE_SUCCESS = "ACTION_STANDARDEMAILTEMPLATE_SAVE_SUCCESS",
    ACTION_STANDARDEMAILTEMPLATE_SAVE_FAILURE = "ACTION_STANDARDEMAILTEMPLATE_SAVE_FAILURE",

    ACTION_STANDARDEMAILTEMPLATE_CREATE = "ACTION_STANDARDEMAILTEMPLATE_CREATE",
    ACTION_STANDARDEMAILTEMPLATE_CREATE_SUCCESS = "ACTION_STANDARDEMAILTEMPLATE_CREATE_SUCCESS",
    ACTION_STANDARDEMAILTEMPLATE_CREATE_FAILURE = "ACTION_STANDARDEMAILTEMPLATE_CREATE_FAILURE",

    ACTION_STANDARDEMAILTEMPLATE_CLEAR = "ACTION_STANDARDEMAILTEMPLATE_CLEAR",

    ACTION_STANDARDEMAILTEMPLATE_FILTER = "ACTION_STANDARDEMAILTEMPLATE_FILTER";

export interface IStandardEmailTemplateLoadAction {
    type: "ACTION_STANDARDEMAILTEMPLATE_LOAD";
}

export interface IStandardEmailTemplateLoadActionCreator {
    (): IStandardEmailTemplateLoadAction;
}

export interface IStandardEmailTemplateLoadSuccessAction {
    type: "ACTION_STANDARDEMAILTEMPLATE_LOAD_SUCCESS";
    formData: Dtos.StandardEmailTemplate | Dtos.StandardEmailTemplate[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IStandardEmailTemplateLoadSuccessActionCreator {
    (
        formData: Dtos.StandardEmailTemplate | Dtos.StandardEmailTemplate[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IStandardEmailTemplateLoadSuccessAction;
}

export interface IStandardEmailTemplateLoadFailureAction {
    type: "ACTION_STANDARDEMAILTEMPLATE_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IStandardEmailTemplateLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IStandardEmailTemplateLoadFailureAction;
}

export interface IStandardEmailTemplateLoadByIdAction {
    type: "ACTION_STANDARDEMAILTEMPLATE_LOAD_BY_ID";
    id: number;
}

export interface IStandardEmailTemplateLoadByIdActionCreator {
    (id: number): IStandardEmailTemplateLoadByIdAction;
}


export interface IStandardEmailTemplateSaveAction {
    type: "ACTION_STANDARDEMAILTEMPLATE_SAVE";
    formData: Dtos.StandardEmailTemplate;

}

export interface IStandardEmailTemplateSaveActionCreator {
    (
        formData: Dtos.StandardEmailTemplate
    ): IStandardEmailTemplateSaveAction;
}

export interface IStandardEmailTemplateSaveSuccessAction {
    type: "ACTION_STANDARDEMAILTEMPLATE_SAVE_SUCCESS";
    formData: Dtos.StandardEmailTemplate;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IStandardEmailTemplateSaveSuccessActionCreator {
    (
        formData: Dtos.StandardEmailTemplate,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IStandardEmailTemplateSaveSuccessAction;
}

export interface IStandardEmailTemplateSaveFailureAction {
    type: "ACTION_STANDARDEMAILTEMPLATE_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IStandardEmailTemplateSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IStandardEmailTemplateSaveFailureAction;
}

export interface IStandardEmailTemplateCreateAction {
    type: "ACTION_STANDARDEMAILTEMPLATE_CREATE"
}

export interface IStandardEmailTemplateCreateActionCreator {
    (): IStandardEmailTemplateCreateAction
}

export interface IStandardEmailTemplateCreateSuccessAction {
    type: "ACTION_STANDARDEMAILTEMPLATE_CREATE_SUCCESS";
    formData: Dtos.StandardEmailTemplate;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface IStandardEmailTemplateCreateSuccessActionCreator {
    (
        formData: Dtos.StandardEmailTemplate,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): IStandardEmailTemplateCreateSuccessAction;
}

export interface IStandardEmailTemplateCreateFailureAction {
    type: "ACTION_STANDARDEMAILTEMPLATE_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IStandardEmailTemplateCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IStandardEmailTemplateCreateFailureAction;
}

export interface IStandardEmailTemplateClearAction {
    type: "ACTION_STANDARDEMAILTEMPLATE_CLEAR";
    propertiesToClear?: string[];
}

export interface IStandardEmailTemplateClearActionCreator {
    (propertiesToClear?: string[]): IStandardEmailTemplateClearAction;
}

export interface IStandardEmailTemplateFilterActionCreator {
    (formFilter: IFormFilter): IStandardEmailTemplateFilterAction;
}

export interface IStandardEmailTemplateFilterAction {
    type: "ACTION_STANDARDEMAILTEMPLATE_FILTER";
    formFilter: IFormFilter;
}

export type StandardEmailTemplateAction =
    IStandardEmailTemplateLoadAction |
    IStandardEmailTemplateLoadByIdAction |
    IStandardEmailTemplateLoadFailureAction |
    IStandardEmailTemplateLoadSuccessAction |
    IStandardEmailTemplateSaveAction |
    IStandardEmailTemplateSaveSuccessAction |
    IStandardEmailTemplateSaveFailureAction |
    IStandardEmailTemplateCreateAction |
    IStandardEmailTemplateCreateSuccessAction |
    IStandardEmailTemplateCreateFailureAction |
    IStandardEmailTemplateClearAction |
    IStandardEmailTemplateFilterAction;
