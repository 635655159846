import * as React from 'react';

import * as classNames from "classnames";
import './SearchPage.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';

import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../components/layouts/index";

import * as SearchActions from '../../actions/search';

import * as Dtos from '../../dtos/Tmd.dtos';
import { ITmdState } from "../../reducers/index";
import { RequestState } from "../../enumerations/RequestState";
import { Link } from "../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../components/routing/Link";
import { INavItem } from "../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../constants/reactTableConstants";
import { setModalTitle } from "../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../components/common/index";

import * as AuthenticationHelper from '../../helpers/authentication';
import * as AuthorisationDefinitions from '../../constants/authorisationDefinitions';
import { ModalSize } from '../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../components/form';
import { IRegistrationFormState } from "../../reducers/reactReduxForms/index";
import { ISearchResultReduxFormState, initialSearchResultState } from "../../reducers/reactReduxForms/searchResult";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../helpers/formHelper";
import { TextFormInput, DatePickerFormInputFabric, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../components/form/index";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../helpers/alert";


interface ISearchPageParams {
}

interface ISearchPageProps {

    form: Dtos.SearchResult & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ISearchResultReduxFormState;
    lookups: Dtos.Lookup[];
    validationFailures: Dtos.ResponseError[];

    location: Location;
    match: match<ISearchPageParams>;
    history: History;

    // From state
    searchResult: Dtos.SearchResult;

    searching: boolean;
    searchSuccess: boolean;
    searchFailure: boolean;

    user: Dtos.User
}

interface ISearchPageActions {
    clearSearch: SearchActions.ISearchClearActionCreator;
    search: SearchActions.ISearchActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type SearchPageProps = ISearchPageProps & ISearchPageActions;

const formName: string = "searchResult";
const reduxFormName: string = "reduxForms.searchResult";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class SearchPage extends React.PureComponent<SearchPageProps, any> {

    constructor(props: SearchPageProps) {
        super(props);
    }

    componentDidMount() {
        this.loadForm();

    }

    componentWillReceiveProps(nextProps: SearchPageProps) {
    }

    componentDidUpdate(prevProps: SearchPageProps, prevState) {

        const {
            searching,
            searchSuccess,
            validationFailures,
            navigate,
            form
        } = this.props;

        if (!searching && prevProps.searching) {
            if (searchSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/")
                } else {
                    this.loadForm();
                }
            }
            else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

    }

    componentWillUpdate(nextProps: SearchPageProps) {
    }

    render() {

        const {
            searching
        } = this.props;

        return <RestrictedLayout
            loading={ searching }
            noTopMargin={true}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            searching,
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm
        } = this.props;

        const formDisabled: boolean = searching;

        let formComponents: { [index: string]: RegistrationFormComponent } = {

            searchFor: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: " ",
                    addOnRightButton: <button className="btn btn-secondary" type="submit" id="button-addon2" style={{ padding: '4px 10px'}}>Submit</button>
                },
                inputLabel: "Search"
            },
        };

        return <div>

                <Form model={reduxFormName}
                    onSubmit={(val, event) => this.handleOnSubmit(val)}
                    onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
                >
                    <div className="row">
                        <div className={"col-12 mb-2"}>
                            {
                                renderFormControls(form, "searchResult", formComponents, lookups, validationFailures, formProperties, changeForm)
                            }
                        </div>

                    </div>

                </Form>
                <div className="mt-2">
                    {
                        !searching ? <div>
                            {this.renderMessage()}
                            {this.renderTable()}
                        </div>
                            : "Searching..."
                    }
                </div>
            </div>
    }

    renderMessage() {
        const {
                searching,
                searchResult
            } = this.props;

        if (!searching &&
            searchResult &&
            searchResult.searchFor != "" &&
            searchResult.institutionTrials &&
            searchResult.institutionTrials.length == 0 &&
            searchResult.trials &&
            searchResult.trials.length == 0 &&
            searchResult.institutions &&
            searchResult.institutions.length == 0 &&
            searchResult.personnel &&
            searchResult.personnel.length == 0) {
            return (<div>
                <div className="alert alert-info d-flex">
                    <div className="mr-2">
                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                    </div>
                    <span>Nothing found.</span>
                </div>
            </div>);
        }

        if (!searching &&
            (!searchResult || (searchResult && searchResult.searchFor == "") )) {
            return (<div>
                <div className="alert alert-info d-flex">
                    <div className="mr-2">
                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                    </div>
                    <span>Search trials, institutions and personnel.</span>
                </div>
            </div>);
        }
    }

    renderTable() {
        const {
                searchResult
            } = this.props;

        return <div>
                {searchResult && searchResult.institutionTrials && searchResult.institutionTrials.length > 0 && (
                    <div key={"institution-trials"}>
                        <div className={tableHeaderClass} style={tableStyleClass}>
                            <span>Trial Institutions</span>
                        </div>
                        {
                            this.renderTrialInstitutions(searchResult.institutionTrials)
                        }
                    </div>
                )}

                {searchResult && searchResult.trials && searchResult.trials.length > 0 && (
                    <div key={"ttrials"}>
                        <div className={tableHeaderClass} style={tableStyleClass}>
                            <span>Trials</span>
                        </div>
                        {
                            this.renderTrials(searchResult.trials)
                        }
                    </div>
                )}

                {searchResult && searchResult.institutions && searchResult.institutions.length > 0 && (
                    <div key={"institutions"}>
                        <div className={tableHeaderClass} style={tableStyleClass}>
                            <span>Institutions</span>
                        </div>
                        {
                            this.renderInstitutions(searchResult.institutions)
                        }
                    </div>
                )}

                {searchResult && searchResult.personnel && searchResult.personnel.length > 0 && (
                    <div key={"personnel"}>
                        <div className={tableHeaderClass} style={tableStyleClass}>
                            <span>Personnel</span>
                        </div>
                        {
                            this.renderPersonnel(searchResult.personnel)
                        }
                    </div>
                )}
            </div>
    }

    renderTrialInstitutions(items: Dtos.TrialInstitution[]) {
        const showPagination = false;
        const {
            } = this.props;

        return (
            <ReactTable
                data={items}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : items.length}
                pageSize={showPagination ? undefined : items.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="Nothing found"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log(rowInfo);
                            const url: string = "/trials/" + rowInfo.original.trialId + "/trialinstitution/" + rowInfo.original.id + "/details";
                            //const url: string = "/institutiontrials/" + rowInfo.original.id + "/details";
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        }
                    }
                }}
                columns={
                    [
                        {
                            id: "id",
                            Header: ' ',
                            accessor: "id",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 50,
                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.TRIALINSTITUTION} fixedWidth />
                        },
                        {
                            id: "protocolID",
                            Header: 'TROG Number',
                            accessor: 'trial.protocolID',
                            maxWidth: 180,
                            className: "d-flex align-items-center justify-content-center"
                        },
                        {
                            id: "title",
                            Header: 'Scientific Trial',
                            accessor: 'trial.title',
                            className: "d-flex align-items-center justify-content-center"
                        },
                        {
                            id: "name",
                            Header: 'Institution',
                            accessor: 'institution.name',
                            className: "d-flex align-items-center justify-content-center"
                        }
                    ]
                }
            />
        );
    }

    renderTrials(items: Dtos.Trial[]) {
        const showPagination = false;
        const {
            } = this.props;

        return (
            <ReactTable
                data={items}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : items.length}
                pageSize={showPagination ? undefined : items.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="Nothing found"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log(rowInfo);
                            const url: string = "/trials/" + rowInfo.original.id + "/details";
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        }
                    }
                }}
                columns={
                    [
                        {
                            id: "id",
                            Header: ' ',
                            accessor: "id",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 50,
                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.TRIAL} fixedWidth />
                        },
                        {
                            id: "protocolID",
                            Header: 'TROG Number',
                            accessor: 'protocolID',
                            className: "d-flex align-items-center justify-content-center"
                        },
                        {
                            id: "title",
                            Header: 'Scientific Title',
                            accessor: "title",
                            className: "d-flex align-items-center justify-content-center"
                        },
                        {
                            id: "shortTitle",
                            Header: 'Short Title',
                            accessor: 'shortTitle',
                            className: "d-flex align-items-center justify-content-center"
                        }
                    ]
                }
            />
        );
    }

    renderInstitutions(items: Dtos.Institution[]) {
        const showPagination = false;
        const {
            } = this.props;

        return (
            <ReactTable
                data={items}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : items.length}
                pageSize={showPagination ? undefined : items.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="Nothing found"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log(rowInfo);
                            const url: string = "/institutions/" + rowInfo.original.id + "/details";
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        }
                    }
                }}
                columns={
                    [
                        {
                            id: "id",
                            Header: ' ',
                            accessor: "id",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 50,
                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.INSTITUTION} fixedWidth />
                        },
                        {
                            id: "name",
                            Header: 'Name',
                            accessor: 'name',
                            className: "d-flex align-items-center justify-content-center"
                        },
                        {
                            id: "code",
                            Header: 'Code',
                            accessor: 'code',
                            maxWidth: 180,
                            className: "d-flex align-items-center justify-content-center"
                        },
                        {
                            id: "country",
                            Header: 'Country',
                            accessor: 'country',
                            maxWidth: 180,
                            className: "d-flex align-items-center justify-content-center"
                        },
                        {
                            id: "state",
                            Header: 'State',
                            accessor: 'state',
                            maxWidth: 180,
                            className: "d-flex align-items-center justify-content-center"
                        }
                    ]
                }
            />
        );
    }

    renderPersonnel(items: Dtos.Personnel[]) {
        const showPagination = false;
        const {
            } = this.props;

        return (
            <ReactTable
                data={items}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : items.length}
                pageSize={showPagination ? undefined : items.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="Nothing found"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log(rowInfo);
                            const url: string = "/personnel/" + rowInfo.original.id + "/details";
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        }
                    }
                }}
                columns={
                    [
                        {
                            id: "id",
                            Header: ' ',
                            accessor: "id",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 50,
                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.ROLE} fixedWidth />
                        },
                        {
                            id: "firstName",
                            Header: 'First Name',
                            accessor: 'firstName',
                            className: "d-flex align-items-center justify-content-center"
                        },
                        {
                            id: "lastName",
                            Header: 'Last Name',
                            accessor: 'lastName',
                            className: "d-flex align-items-center justify-content-center"
                        },
                        {
                            id: "email",
                            Header: 'Email',
                            accessor: 'email',
                            className: "d-flex align-items-center justify-content-center"
                        },
                        {
                            id: "userName",
                            Header: 'User Name',
                            accessor: 'userName',
                            className: "d-flex align-items-center justify-content-center"
                        }
                    ]
                }
            />
        );
    }

    renderErrorAlert(): any {

        const {
                validationFailures,
        } = this.props

        return <div>
                The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
                validationFailures,
        } = this.props

        return <div>
                The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.SearchResult) {
        const {
                search
            } = this.props;

        if (data) {
                search(data.searchFor);
        }
    }

    handleOnSubmitFailed(data: Dtos.TrialInstitution) {
                Alert.error(<NotificationAlert
                    minWidth={500}
                    alertContent={renderRegistrationFormSaveBlock()}
                    icon={FontAwesomeIcons.Solid.BAN}
                />);
    }

    loadForm() {
        const {
                loadForm,
                searchResult,
        } = this.props

        let extraData: Dtos.SearchResult = { } as Dtos.SearchResult;

        loadForm(reduxFormName, Object.assign({...searchResult}, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: SearchPageProps): ISearchPageProps => {

                let searchResult: Dtos.SearchResult | undefined = !(state.search.formData instanceof Array) ? state.search.formData : undefined;

    return {
                lookups: state.search.lookups,

        form: state.reduxForms.searchResult,
        formState: state.search.formState,
        formProperties: state.search.formProperties,

        reduxFormState: state.reduxForms.formState.searchResult,
        validationFailures: searchResult ?
            state.search.validationFailures :
            undefined,

        match: ownProps.match,

        history: ownProps.history,
        location: state.routing.location,

        searchResult: searchResult!,

        searching: state.search.loadState && state.search.loadState.status == RequestState.Pending,
        searchSuccess: state.search.loadState && state.search.loadState.status == RequestState.Success,
        searchFailure: state.search.loadState && state.search.loadState.status == RequestState.Failure,

        user: state.user.data
    };
};

const mapDispatchToProps = (dispatch): ISearchPageActions => {
    return {
                navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        search: bindActionCreators(SearchActions.Search, dispatch),
        clearSearch: bindActionCreators(SearchActions.Clear, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(SearchPage);
