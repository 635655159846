import * as React from 'react';
import './TrialFormReportPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu, TruncatedCell } from "../../../components/layouts/index";
import { ITrialFormReduxFormState, initialTrialFormState } from "../../../reducers/reactReduxForms/trialForm";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, DatePickerFormInputFabric, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as TrialFormActions from '../../../actions/trialForm';
import * as TrialActions from '../../../actions/trial';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { Form, actions, Control } from 'react-redux-form';
import { TrialLayout } from '../../index';

interface ITrialFormReportPageParams {
    trialId: number;
    trialFormId: number;
}

interface ITrialFormReportPageProps {

    form: Dtos.TrialForm & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITrialFormReduxFormState;

    location: Location;
    trialId: number;
    trialFormId: number;
    match: match<ITrialFormReportPageParams>;
    history: History;

    // From state
    trialForm: Dtos.TrialForm;
    trial: Dtos.Trial;
    reportData: Dtos.MeasurementReport[];
    lookups: Dtos.Lookup[];

    loadingTrial: boolean;

    loadingTrialForm: boolean;
    loadTrialFormSuccess: boolean;
    loadTrialFormFailure: boolean;

    loadingTrialFormReport: boolean;
    loadTrialFormReportSuccess: boolean;
    loadTrialFormReportFailure: boolean;

    justifyingTrialFormReport: boolean;
    justifyTrialFormReportSuccess: boolean;
    justifyTrialFormReportFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface ITrialFormReportPageActions {
    clearTrialForm: TrialFormActions.ITrialFormClearActionCreator;
    loadTrialForm: TrialFormActions.ITrialFormLoadByIdActionCreator;

    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    loadTrialFormReport: TrialFormActions.ITrialFormReportLoadActionCreator;
    justifyTrialFormReport: TrialFormActions.ITrialFormReportJustifiedActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type TrialFormReportPageProps = ITrialFormReportPageProps & ITrialFormReportPageActions;

const reduxFormName: string = "reduxForms.trialForm";

class TrialFormReportPage extends React.PureComponent<TrialFormReportPageProps, any> {

    constructor(props: TrialFormReportPageProps) {
        super(props);

        this.clearTrialForm = this.clearTrialForm.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTrialForm,
            loadTrialFormReport,
            trialFormId,
            trialId,
            loadTrial
        } = this.props;

        if (trialFormId) {
            loadTrialForm(trialFormId);
            loadTrialFormReport(trialFormId, "1");
        } else if (!trialFormId) {
            this.onCancel();
        }
        loadTrial(trialId);
    }

    componentDidUpdate(prevProps: TrialFormReportPageProps, prevState) {
        const {
            trialId,
            trialFormId,
            loadTrialForm,
            loadTrial,
            loadingTrialForm,
            loadTrialFormSuccess,
            justifyingTrialFormReport,
            justifyTrialFormReportSuccess,
            justifyTrialFormReportFailure
        } = this.props;

        if (trialId && prevProps.trialId != trialId) {
            loadTrial(trialId);
        }

        if (trialFormId && prevProps.trialFormId != trialFormId) {
            loadTrialForm(trialFormId);
        }

        if (!loadingTrialForm && prevProps.loadingTrialForm) {
            if (loadTrialFormSuccess) {
                this.loadForm();
            }
        }

        if (!justifyingTrialFormReport && prevProps.justifyingTrialFormReport) {
            if (justifyTrialFormReportSuccess) {
                Alert.success(<NotificationAlert
                    alertContent={renderRegistrationFormSaveSuccess()}
                    icon={FontAwesomeIcons.Solid.CHECK}
                />);
            } else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }
    }

    componentWillUnmount() {
        const {
            loadForm
        } = this.props

        this.clearTrialForm();
        loadForm(reduxFormName, initialTrialFormState);
    }

    clearTrialForm() {
        this.props.clearTrialForm();
        this.props.clearTrial();
    }

    showTrialFormReport(): boolean {
        const {
            loadingTrial,
            loadingTrialForm,
            loadingTrialFormReport,
            loadTrialFormSuccess,
            loadTrialFormReportSuccess
        } = this.props

        if (!loadingTrial && !loadingTrialForm && loadTrialFormSuccess && !loadingTrialFormReport && loadTrialFormReportSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrial,
            loadingTrialForm,
            loadingTrialFormReport,
            justifyingTrialFormReport,
        } = this.props;

        return <TrialLayout
            permission={Dtos.Permission.TrialFormView}

            loading={loadingTrial || loadingTrialForm || loadingTrialFormReport || justifyingTrialFormReport}>
            {this.renderContent()}
        </TrialLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            formProperties,
            changeForm,
            permissions
        } = this.props;

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            headerGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    formTemplateId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: true, //!formNew,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialForm", "FormTemplateId", lookups), form.formTemplateId)
                        },
                        size: 2,
                    },
                    patientId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: true, //formDisabled || !formNew,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialForm", "PatientId", lookups), form.patientId)
                        },
                        size: 2,
                    },
                    assignedToId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: true, //formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialForm", "AssignedToId", lookups), form.assignedToId)
                        },
                        size: 2,
                    },
                    formStatusId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: true, //formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialForm", "FormStatusId", lookups), form.formStatusId)
                        },
                        size: 2,
                    }
                }
            },
        };

        return <div>
            <div className="row border border-bottom-1 border-top-0">
                <div className={"col mb-2"}>
                    <h3>Trial Form Report</h3>
                </div>
                <div className={"col-auto pr-0"}>
                    <Link className={"btn btn-secondary"} url={"/trials/" + form.trialId + '/forms/' + form.id}>Details</Link>
                </div>
                <div className={"col-auto"}>
                    <Link className={"btn btn-warning"} url={"/trials/" + form.trialId + '/forms/' + form.id + '/entry'}>
                        {!AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.TrialFormUpdate, Dtos.Permission.TrialFormCreate) ? "View Data" : "Enter Data"}
                    </Link>
                </div>
            </div>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "trialForm", formComponents, lookups, undefined, formProperties, changeForm)
                }
            </Form>
            {
                !this.showTrialFormReport() ?
                    "Loading Trial Form Report" :
                    null
            }
            {
                this.showTrialFormReport() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {
        const {
            reportData,
            justifyTrialFormReport,
            permissions,
            form,
            trialFormId
        } = this.props

        if (!reportData || reportData.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no trial form report items</span>
            </div>
        }

        const showPagination = reportData.length > DEFAULT_PAGE_SIZE;
        const canEdit = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.TrialFormUpdate, Dtos.Permission.TrialFormCreate);

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={reportData}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : reportData.length}
                pageSize={showPagination ? undefined : reportData.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no trial form report items to display"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            if (handleOriginal) {
                                handleOriginal()
                            }
                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.TRIALITEM} fixedWidth />
                    },
                    {
                        id: "sectionName",
                        Header: 'Section',
                        accessor: 'sectionName',
                        className: "d-flex align-items-center justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value} />
                    },
                    {
                        id: "label",
                        Header: 'Label',
                        accessor: 'label',
                        className: "d-flex align-items-center justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value} />
                    },
                    {
                        id: "reportingTagCategory",
                        Header: 'Tag Category',
                        accessor: 'reportingTagCategory',
                        className: "d-flex align-items-center justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value} />
                    },
                    {
                        id: "acceptable",
                        Header: 'Acceptable',
                        accessor: 'acceptable',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 120,
                        Cell: (props) => {
                            return <>
                                <span
                                    className="truncate">{props.value.toString() != "1" ? "" : <FontAwesomeIcon icon={FontAwesomeIcons.Regular.CHECK} fixedWidth />}</span>
                            </>
                        }
                    },
                    {
                        id: "minorVariations",
                        Header: 'Minor Var.',
                        accessor: 'minorVariations',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 120,
                        Cell: (props) => {
                            return <>
                                <span
                                    className="truncate">{props.value.toString() != "1" ? "" : <FontAwesomeIcon icon={FontAwesomeIcons.Regular.CHECK} fixedWidth />}</span>
                            </>
                        }
                    },
                    {
                        id: "majorVariations",
                        Header: 'Major Var.',
                        accessor: 'majorVariations',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 120,
                        Cell: (props) => {
                            return <>
                                <span
                                    className="truncate">{props.value.toString() != "1" ? "" : <FontAwesomeIcon icon={FontAwesomeIcons.Regular.CHECK} fixedWidth />}</span>
                            </>
                        }
                    },
                    {
                        id: "justified",
                        Header: 'Justified',
                        accessor: 'justified',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 120,
                        Cell: (props) => {
                            if (canEdit) {
                                console.log('Justified', props);
                                return <button className="btn btn-sm btn-light" onClick={() => justifyTrialFormReport(trialFormId, props.original) }>
                                    {props.value.toString() != "1" ?
                                        <FontAwesomeIcon icon={FontAwesomeIcons.Light.UNCHECKED} fixedWidth /> :
                                        <FontAwesomeIcon icon={FontAwesomeIcons.Light.CHECK} fixedWidth />}
                                </button>
                            }
                            return <>
                                <span
                                    className="truncate">{props.value.toString() != "1" ? "" : <FontAwesomeIcon icon={FontAwesomeIcons.Regular.CHECK} fixedWidth />}</span>
                            </>
                        }
                    },
                    {
                        id: "missing",
                        Header: 'Missing',
                        accessor: 'missing',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 120,
                        Cell: (props) => {
                            return <>
                                <span
                                    className="truncate">{props.value.toString() != "1" ? "" : <FontAwesomeIcon icon={FontAwesomeIcons.Regular.CHECK} fixedWidth />}</span>
                            </>
                        }
                    },
                    {
                        id: "na",
                        Header: 'Na',
                        accessor: 'na',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 120,
                        Cell: (props) => {
                            return <>
                                <span
                                    className="truncate">{props.value.toString() != "1" ? "" : <FontAwesomeIcon icon={FontAwesomeIcons.Regular.CHECK} fixedWidth />}</span>
                            </>
                        }
                    },
                ]}
            />
        </div>

    }

    renderErrorAlert(): any {

        const {
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.TrialForm) {
        const {
            // saveTrialForm
        } = this.props;

        if (data) {
            //let changes: Dtos.TrialForm = {} as Dtos.TrialForm;
            //saveTrialForm(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.Trial) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            trialId,
            navigate
        } = this.props;

        navigate("/trials/" + trialId + "/forms");
    }

    loadForm() {
        const {
            loadForm,
            trialForm,
        } = this.props

        let extraData: Dtos.TrialForm = {} as Dtos.TrialForm;

        loadForm(reduxFormName, Object.assign({ ...trialForm }, extraData));
    }
}


const mapStateToProps = (state: ITmdState, ownProps: TrialFormReportPageProps): ITrialFormReportPageProps => {

    let trialForm: Dtos.TrialForm | undefined = !(state.trialForm.formData instanceof Array) ? state.trialForm.formData : undefined;
    let trial: Dtos.Trial | undefined = !(state.trials.formData instanceof Array) ? state.trials.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,
        trialFormId: ownProps.match && ownProps.match.params.trialFormId.toString() != "create" ? ownProps.match.params.trialFormId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trialForm: trialForm!,

        trial: trial!,
        reportData: state.trialForm.reportData,


        loadingTrialForm: state.trialForm.loadState && state.trialForm.loadState.status == RequestState.Pending,
        loadTrialFormSuccess: state.trialForm.loadState && state.trialForm.loadState.status == RequestState.Success,
        loadTrialFormFailure: state.trialForm.loadState && state.trialForm.loadState.status == RequestState.Failure,

        loadingTrial: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,

        user: state.user.data,
        permissions: permissions,
        lookups: state.trialForm.lookups,

        form: state.reduxForms.trialForm,
        formState: state.trialForm.formState,
        formProperties: state.trialForm.formProperties,

        reduxFormState: state.reduxForms.formState.trialForm,

        loadingTrialFormReport: state.trialForm.reportLoadState && state.trialForm.reportLoadState.status == RequestState.Pending,
        loadTrialFormReportSuccess: state.trialForm.reportLoadState && state.trialForm.reportLoadState.status == RequestState.Success,
        loadTrialFormReportFailure: state.trialForm.reportLoadState && state.trialForm.reportLoadState.status == RequestState.Failure,

        justifyingTrialFormReport: state.trialForm.justifyingState && state.trialForm.justifyingState.status == RequestState.Pending,
        justifyTrialFormReportSuccess: state.trialForm.justifyingState && state.trialForm.justifyingState.status == RequestState.Success,
        justifyTrialFormReportFailure: state.trialForm.justifyingState && state.trialForm.justifyingState.status == RequestState.Failure,
    };
};

const mapDispatchToProps = (dispatch): ITrialFormReportPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),

        loadTrialForm: bindActionCreators(TrialFormActions.LoadTrialFormById, dispatch),
        clearTrialForm: bindActionCreators(TrialFormActions.Clear, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch),

        loadTrialFormReport: bindActionCreators(TrialFormActions.LoadTrialFormReport, dispatch),
        justifyTrialFormReport: bindActionCreators(TrialFormActions.SetTrialFormReportJustified, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialFormReportPage);
