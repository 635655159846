import * as React from 'react';
import { InlineLoading, OverlayLoading } from "../common/index";

interface ISubPageLayoutProps {
    loading?: boolean;
    saving?: boolean;
}

class SubPageLayout extends React.PureComponent<ISubPageLayoutProps, any> {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps: any) {
    }

    componentDidUpdate(prevProps: any) {

    }

    componentWillUpdate(nextProps: any) {
    }

    componentWillUnmount() {
    }

    render() {
        const {
            loading,
            saving
        } = this.props;

        return <div className="bg-white" style={{ position: loading ? "relative" : undefined, minHeight: '140px' }}>
            {
                loading ?
                    <InlineLoading absoluteCenter={true} size={40} /> :
                    <div>
                        {
                            saving ?
                                <OverlayLoading size={40} /> :
                                null
                        }
                        {this.props.children}
                    </div>
            }
        </div>;
    }
}

export default SubPageLayout;