import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadReportingTag: IReportingTagLoadActionCreator = () => {
        return {
            type: ACTION_REPORTINGTAG_LOAD
        }
    },
    LoadReportingTagById: IReportingTagLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_REPORTINGTAG_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IReportingTagLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_REPORTINGTAG_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IReportingTagLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_REPORTINGTAG_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveReportingTag: IReportingTagSaveActionCreator = (formData) => {
        return {
            type: ACTION_REPORTINGTAG_SAVE,
            formData
        }
    },
    SaveSuccess: IReportingTagSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_REPORTINGTAG_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IReportingTagSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_REPORTINGTAG_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateReportingTag: IReportingTagCreateActionCreator = () => {

        return {
            type: ACTION_REPORTINGTAG_CREATE
        }
    },
    CreateSuccess: IReportingTagCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_REPORTINGTAG_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: IReportingTagCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_REPORTINGTAG_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IReportingTagClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_REPORTINGTAG_CLEAR,
            propertiesToClear
        }
    },
    Filter: IReportingTagFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_REPORTINGTAG_FILTER,
            formFilter
        }
    };



export const
    ACTION_REPORTINGTAG_LOAD = "ACTION_REPORTINGTAG_LOAD",
    ACTION_REPORTINGTAG_LOAD_BY_ID = "ACTION_REPORTINGTAG_LOAD_BY_ID",
    ACTION_REPORTINGTAG_LOAD_SUCCESS = "ACTION_REPORTINGTAG_LOAD_SUCCESS",
    ACTION_REPORTINGTAG_LOAD_FAILURE = "ACTION_REPORTINGTAG_LOAD_FAILURE",

    ACTION_REPORTINGTAG_SAVE = "ACTION_REPORTINGTAG_SAVE",
    ACTION_REPORTINGTAG_SAVE_SUCCESS = "ACTION_REPORTINGTAG_SAVE_SUCCESS",
    ACTION_REPORTINGTAG_SAVE_FAILURE = "ACTION_REPORTINGTAG_SAVE_FAILURE",

    ACTION_REPORTINGTAG_CREATE = "ACTION_REPORTINGTAG_CREATE",
    ACTION_REPORTINGTAG_CREATE_SUCCESS = "ACTION_REPORTINGTAG_CREATE_SUCCESS",
    ACTION_REPORTINGTAG_CREATE_FAILURE = "ACTION_REPORTINGTAG_CREATE_FAILURE",

    ACTION_REPORTINGTAG_CLEAR = "ACTION_REPORTINGTAG_CLEAR",

    ACTION_REPORTINGTAG_FILTER = "ACTION_REPORTINGTAG_FILTER";

export interface IReportingTagLoadAction {
    type: "ACTION_REPORTINGTAG_LOAD";
}

export interface IReportingTagLoadActionCreator {
    (): IReportingTagLoadAction;
}

export interface IReportingTagLoadSuccessAction {
    type: "ACTION_REPORTINGTAG_LOAD_SUCCESS";
    formData: Dtos.ReportingTag | Dtos.ReportingTag[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IReportingTagLoadSuccessActionCreator {
    (
        formData: Dtos.ReportingTag | Dtos.ReportingTag[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IReportingTagLoadSuccessAction;
}

export interface IReportingTagLoadFailureAction {
    type: "ACTION_REPORTINGTAG_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IReportingTagLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IReportingTagLoadFailureAction;
}

export interface IReportingTagLoadByIdAction {
    type: "ACTION_REPORTINGTAG_LOAD_BY_ID";
    id: number;
}

export interface IReportingTagLoadByIdActionCreator {
    (id: number): IReportingTagLoadByIdAction;
}


export interface IReportingTagSaveAction {
    type: "ACTION_REPORTINGTAG_SAVE";
    formData: Dtos.ReportingTag;

}

export interface IReportingTagSaveActionCreator {
    (
        formData: Dtos.ReportingTag
    ): IReportingTagSaveAction;
}

export interface IReportingTagSaveSuccessAction {
    type: "ACTION_REPORTINGTAG_SAVE_SUCCESS";
    formData: Dtos.ReportingTag;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IReportingTagSaveSuccessActionCreator {
    (
        formData: Dtos.ReportingTag,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IReportingTagSaveSuccessAction;
}

export interface IReportingTagSaveFailureAction {
    type: "ACTION_REPORTINGTAG_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IReportingTagSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IReportingTagSaveFailureAction;
}

export interface IReportingTagCreateAction {
    type: "ACTION_REPORTINGTAG_CREATE"
}

export interface IReportingTagCreateActionCreator {
    (): IReportingTagCreateAction
}

export interface IReportingTagCreateSuccessAction {
    type: "ACTION_REPORTINGTAG_CREATE_SUCCESS";
    formData: Dtos.ReportingTag;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface IReportingTagCreateSuccessActionCreator {
    (
        formData: Dtos.ReportingTag,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): IReportingTagCreateSuccessAction;
}

export interface IReportingTagCreateFailureAction {
    type: "ACTION_REPORTINGTAG_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IReportingTagCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IReportingTagCreateFailureAction;
}

export interface IReportingTagClearAction {
    type: "ACTION_REPORTINGTAG_CLEAR";
    propertiesToClear?: string[];
}

export interface IReportingTagClearActionCreator {
    (propertiesToClear?: string[]): IReportingTagClearAction;
}

export interface IReportingTagFilterActionCreator {
    (formFilter: IFormFilter): IReportingTagFilterAction;
}

export interface IReportingTagFilterAction {
    type: "ACTION_REPORTINGTAG_FILTER";
    formFilter: IFormFilter;
}

export type ReportingTagAction =
    IReportingTagLoadAction |
    IReportingTagLoadByIdAction |
    IReportingTagLoadFailureAction |
    IReportingTagLoadSuccessAction |
    IReportingTagSaveAction |
    IReportingTagSaveSuccessAction |
    IReportingTagSaveFailureAction |
    IReportingTagCreateAction |
    IReportingTagCreateSuccessAction |
    IReportingTagCreateFailureAction |
    IReportingTagClearAction |
    IReportingTagFilterAction;
