import * as Dtos from "../../dtos/Tmd.dtos";

export const

    ToggleExpanded: IToggleExpandedStateActionCreator = (key: string) => {
        return {
            type: ACTION_TOGGLE_EXPANDED,
            key
        }
    }

export const
    ACTION_TOGGLE_EXPANDED = "ACTION_TOGGLE_EXPANDED"

/* Actions */

export interface IToggleExpandedStateAction {
    type: "ACTION_TOGGLE_EXPANDED";
    key: string;
}

/* Action Creators */

export interface IToggleExpandedStateActionCreator {
    (key: string): IToggleExpandedStateAction;
}

/* Final Export of actions */
export type ToggleExpandedAction = 
    IToggleExpandedStateAction;
