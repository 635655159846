import * as React from 'react';
import './InstitutionTypePage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { IInstitutionTypeReduxFormState, initialInstitutionTypeState } from "../../../reducers/reactReduxForms/institutionType";
import { RegistrationFormComponent } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, NumberFormInput, CheckboxFormInput } from "../../../components/form/index";
import * as InstitutionTypeActions from '../../../actions/institutionTypes';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { NotificationAlert } from "../../../components/common/index";
import { Form, actions } from 'react-redux-form';
import { InstitutionLookupsLayout } from '../../index';
import * as AuthenticationHelper from '../../../helpers/authentication';

interface IInstitutionTypePageParams {
    institutionTypeId: number;
}

interface IInstitutionTypePageProps {

    form: Dtos.InstitutionType & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IInstitutionTypeReduxFormState;

    location: Location;
    institutionTypeId: number;
    match: match<IInstitutionTypePageParams>;
    history: History;

    // From state
    institutionType: Dtos.InstitutionType;
    lookups: Dtos.Lookup[];

    loadingInstitutionType: boolean;
    loadInstitutionTypeSuccess: boolean;
    loadInstitutionTypeFailure: boolean;

    savingInstitutionType: boolean;
    saveInstitutionTypeSuccess: boolean;
    saveInstitutionTypeFailure: boolean;

    creatingInstitutionType: boolean;
    createInstitutionTypeSuccess: boolean;
    createInstitutionTypeFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface IInstitutionTypePageActions {
    clearInstitutionType: InstitutionTypeActions.IInstitutionTypeClearActionCreator;
    loadInstitutionType: InstitutionTypeActions.IInstitutionTypeLoadByIdActionCreator;

    saveInstitutionType: InstitutionTypeActions.IInstitutionTypeSaveActionCreator;
    createInstitutionType: InstitutionTypeActions.IInstitutionTypeCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type InstitutionTypePageProps = IInstitutionTypePageProps & IInstitutionTypePageActions;

const reduxFormName: string = "reduxForms.institutionType";

class InstitutionTypePage extends React.PureComponent<InstitutionTypePageProps, any> {

    constructor(props: InstitutionTypePageProps) {
        super(props);

        this.clearInstitutionType = this.clearInstitutionType.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadInstitutionType,
            institutionTypeId,
            createInstitutionType
        } = this.props;

        if (institutionTypeId) {
            loadInstitutionType(institutionTypeId);
        } else if (!institutionTypeId) {
            createInstitutionType();
        }
    }

    componentWillReceiveProps(nextProps: InstitutionTypePageProps) {
    }

    componentDidUpdate(prevProps: InstitutionTypePageProps, prevState) {
        const {
            institutionTypeId,
            institutionType,
            form,
            navigate,
            loadInstitutionType,
            loadingInstitutionType,
            loadInstitutionTypeSuccess,
            createInstitutionType,
            creatingInstitutionType,
            createInstitutionTypeSuccess,
            savingInstitutionType,
            saveInstitutionTypeSuccess,
            validationFailures
        } = this.props;

        if (institutionTypeId && prevProps.institutionTypeId != institutionTypeId) {
            loadInstitutionType(institutionTypeId);
        }
        else if (!institutionTypeId && prevProps.institutionTypeId) {
            createInstitutionType();
        }

        if (!loadingInstitutionType && prevProps.loadingInstitutionType) {
            if (loadInstitutionTypeSuccess) {
                this.loadForm();
            }
        }

        if (!savingInstitutionType && prevProps.savingInstitutionType) {
            if (saveInstitutionTypeSuccess) {
                if (validationFailures && validationFailures.length > 0) {
                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {
                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);
                }
                if (form.saveAndReturn) {
                    navigate("/settings/institution/institutiontypes")
                } else if (!form.saveAndReturn && institutionTypeId && institutionTypeId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !institutionTypeId && institutionType && institutionType.id > 0) {
                    navigate("/settings/institution/institutiontypes/" + institutionType.id);
                }
            }
            else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

        if (!creatingInstitutionType && prevProps.creatingInstitutionType) {
            if (createInstitutionTypeSuccess) {
                this.loadForm();
            }
        }
    }

    componentWillUpdate(nextProps: InstitutionTypePageProps) {
    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearInstitutionType();
        loadForm(reduxFormName, initialInstitutionTypeState);
        resetForm(reduxFormName);
    }

    clearInstitutionType() {
        this.props.clearInstitutionType();
    }

    showInstitutionType(): boolean {
        const {
            loadingInstitutionType,
            loadInstitutionTypeSuccess
        } = this.props

        if (!loadingInstitutionType && loadInstitutionTypeSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingInstitutionType
        } = this.props;

        return <InstitutionLookupsLayout loading={loadingInstitutionType} permission={Dtos.Permission.InstitutionTypesView}>
            {this.renderContent()}
        </InstitutionLookupsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingInstitutionType,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.InstitutionTypesUpdate, Dtos.Permission.InstitutionTypesCreate);
        const formDisabled: boolean = !hasPermission || savingInstitutionType || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            value: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            }
        };

        return <div>
            <h1>Institution Type</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "institutionType", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.InstitutionType) {
        const {
            saveInstitutionType
        } = this.props;

        if (data) {
            let changes: Dtos.Institution = {} as Dtos.Institution;
            saveInstitutionType(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.InstitutionType) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/institution/institutiontypes");
    }

    loadForm() {
        const {
            loadForm,
            institutionType,
        } = this.props

        let extraData: Dtos.InstitutionType = {} as Dtos.InstitutionType;

        loadForm(reduxFormName, Object.assign({ ...institutionType }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: InstitutionTypePageProps): IInstitutionTypePageProps => {

    let institutionType: Dtos.InstitutionType | undefined = !(state.institutionTypes.formData instanceof Array) ? state.institutionTypes.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        institutionTypeId: ownProps.match && ownProps.match.params.institutionTypeId && ownProps.match.params.institutionTypeId.toString() != "create" ? ownProps.match.params.institutionTypeId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        institutionType: institutionType!,

        loadingInstitutionType: state.institutionTypes.loadState && state.institutionTypes.loadState.status == RequestState.Pending,
        loadInstitutionTypeSuccess: state.institutionTypes.loadState && state.institutionTypes.loadState.status == RequestState.Success,
        loadInstitutionTypeFailure: state.institutionTypes.loadState && state.institutionTypes.loadState.status == RequestState.Failure,

        savingInstitutionType: state.institutionTypes.saveState && state.institutionTypes.saveState.status == RequestState.Pending,
        saveInstitutionTypeSuccess: state.institutionTypes.saveState && state.institutionTypes.saveState.status == RequestState.Success,
        saveInstitutionTypeFailure: state.institutionTypes.saveState && state.institutionTypes.saveState.status == RequestState.Failure,

        creatingInstitutionType: state.institutionTypes.createState && state.institutionTypes.createState.status == RequestState.Pending,
        createInstitutionTypeSuccess: state.institutionTypes.createState && state.institutionTypes.createState.status == RequestState.Success,
        createInstitutionTypeFailure: state.institutionTypes.createState && state.institutionTypes.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: [],

        form: state.reduxForms.institutionType,
        formState: state.institutionTypes.formState,
        formProperties: state.institutionTypes.formProperties,

        reduxFormState: state.reduxForms.formState.institutionType,
        validationFailures: institutionType ?
            state.institutionTypes.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): IInstitutionTypePageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadInstitutionType: bindActionCreators(InstitutionTypeActions.LoadInstitutionTypeById, dispatch),
        clearInstitutionType: bindActionCreators(InstitutionTypeActions.Clear, dispatch),

        saveInstitutionType: bindActionCreators(InstitutionTypeActions.SaveInstitutionType, dispatch),
        createInstitutionType: bindActionCreators(InstitutionTypeActions.CreateInstitutionType, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(InstitutionTypePage);
