import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const noteTypesApi = {
    getNoteTypes: (): Observable<Dtos.GetNoteTypesResponse> => {
        //Create the request.
        let request: Dtos.GetNoteTypes = new Dtos.GetNoteTypes();

        //Send request.
        const response: Promise<Dtos.GetNoteTypesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getNoteTypeById: (id: number): Observable<Dtos.GetNoteTypeByIdResponse> => {
        //Create the request.
        let request: Dtos.GetNoteTypeById = new Dtos.GetNoteTypeById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetNoteTypeByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setNoteType: (noteType: Dtos.NoteType): Observable<Dtos.SetNoteTypeResponse> => {

        let request: Dtos.SetNoteType = new Dtos.SetNoteType();

        request.noteType = noteType;

        // send request
        const response: Promise<Dtos.SetNoteTypeResponse> = client.post(request);

        return Observable.from(response);

    },
    createNoteType: (): Observable<Dtos.CreateNoteTypeResponse> => {
        //Create the request.
        let request: Dtos.CreateNoteType = new Dtos.CreateNoteType();

        //Send request.
        const response: Promise<Dtos.CreateNoteTypeResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}