import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const categoriesApi = {
    getCategories: (): Observable<Dtos.GetCategoriesResponse> => {
        //Create the request.
        let request: Dtos.GetCategories = new Dtos.GetCategories();

        //Send request.
        const response: Promise<Dtos.GetCategoriesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getCategoryById: (id: number): Observable<Dtos.GetCategoryByIdResponse> => {
        //Create the request.
        let request: Dtos.GetCategoryById = new Dtos.GetCategoryById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetCategoryByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setCategory: (category: Dtos.Category): Observable<Dtos.SetCategoryResponse> => {

        let request: Dtos.SetCategory = new Dtos.SetCategory();

        request.category = category;

        // send request
        const response: Promise<Dtos.SetCategoryResponse> = client.post(request);

        return Observable.from(response);

    },
    createCategory: (): Observable<Dtos.CreateCategoryResponse> => {
        //Create the request.
        let request: Dtos.CreateCategory = new Dtos.CreateCategory();

        //Send request.
        const response: Promise<Dtos.CreateCategoryResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}