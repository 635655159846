import './Placeholder.scss';

import * as React from 'react';
import * as ReactTooltip from 'react-tooltip';

import * as classNames from "classnames";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

interface IPlaceholderProps {
    moduleName?: string;
}

class Placeholder extends React.PureComponent<IPlaceholderProps, any> {

    render() {

        const {
            moduleName
        } = this.props

        

        return <div className="col">
            <div className={classNames("text-white rounded d-flex bg-tertiary mb-2") }>
                <div className="p-3 bg-overlay text-center">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_CIRCLE} fixedWidth size="1x" />
                </div>
                <div className="p-4 d-flex flex-column justify-content-between info-panel-text-container">
                    <div className="info-panel-text">
                        This module {moduleName}, is under development and is provided for feedback purposes only
                    </div>
                </div>
            </div>
        </div>
    }
}
export default Placeholder