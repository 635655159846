import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const itemComponentTypesApi = {
    getItemComponentTypes: (): Observable<Dtos.GetItemComponentTypesResponse> => {
        //Create the request.
        let request: Dtos.GetItemComponentTypes = new Dtos.GetItemComponentTypes();

        //Send request.
        const response: Promise<Dtos.GetItemComponentTypesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getItemComponentTypeById: (id: number): Observable<Dtos.GetItemComponentTypeByIdResponse> => {
        //Create the request.
        let request: Dtos.GetItemComponentTypeById = new Dtos.GetItemComponentTypeById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetItemComponentTypeByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setItemComponentType: (itemComponentType: Dtos.ItemComponentType): Observable<Dtos.SetItemComponentTypeResponse> => {

        let request: Dtos.SetItemComponentType = new Dtos.SetItemComponentType();

        request.itemComponentType = itemComponentType;

        // send request
        const response: Promise<Dtos.SetItemComponentTypeResponse> = client.post(request);

        return Observable.from(response);

    },
    createItemComponentType: (): Observable<Dtos.CreateItemComponentTypeResponse> => {
        //Create the request.
        let request: Dtos.CreateItemComponentType = new Dtos.CreateItemComponentType();

        //Send request.
        const response: Promise<Dtos.CreateItemComponentTypeResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}