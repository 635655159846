import * as React from 'react';
import * as ReactTooltip from 'react-tooltip';

interface ITruncatedCellProps {
    value?: any;
}

type TruncatedCellProps = ITruncatedCellProps;

export default class TruncatedCell extends React.Component<ITruncatedCellProps, any> {
    render() {
        const {
            value
        } = this.props

        return <><span data-tip={value} className="truncate">{value}</span><ReactTooltip multiline={true} /></>
    }
}
