import * as React from 'react';
import './InvolvementPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { IInvolvementReduxFormState, initialInvolvementState } from "../../../reducers/reactReduxForms/involvement";
import { RegistrationFormComponent } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, NumberFormInput, CheckboxFormInput } from "../../../components/form/index";
import * as InvolvementActions from '../../../actions/involvements';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { NotificationAlert } from "../../../components/common/index";
import { Form, actions } from 'react-redux-form';
import { TrialLookupsLayout } from '../../index';
import * as AuthenticationHelper from '../../../helpers/authentication';

interface IInvolvementPageParams {
    involvementId: number;
}

interface IInvolvementPageProps {

    form: Dtos.Involvement & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IInvolvementReduxFormState;

    location: Location;
    involvementId: number;
    match: match<IInvolvementPageParams>;
    history: History;

    // From state
    involvement: Dtos.Involvement;
    lookups: Dtos.Lookup[];

    loadingInvolvement: boolean;
    loadInvolvementSuccess: boolean;
    loadInvolvementFailure: boolean;

    savingInvolvement: boolean;
    saveInvolvementSuccess: boolean;
    saveInvolvementFailure: boolean;

    creatingInvolvement: boolean;
    createInvolvementSuccess: boolean;
    createInvolvementFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface IInvolvementPageActions {
    clearInvolvement: InvolvementActions.IInvolvementClearActionCreator;
    loadInvolvement: InvolvementActions.IInvolvementLoadByIdActionCreator;

    saveInvolvement: InvolvementActions.IInvolvementSaveActionCreator;
    createInvolvement: InvolvementActions.IInvolvementCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type InvolvementPageProps = IInvolvementPageProps & IInvolvementPageActions;

const reduxFormName: string = "reduxForms.involvement";

class InvolvementPage extends React.PureComponent<InvolvementPageProps, any> {

    constructor(props: InvolvementPageProps) {
        super(props);

        this.clearInvolvement = this.clearInvolvement.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadInvolvement,
            involvementId,
            createInvolvement
        } = this.props;

        if (involvementId) {
            loadInvolvement(involvementId);
        } else if (!involvementId) {
            createInvolvement();
        }
    }

    componentWillReceiveProps(nextProps: InvolvementPageProps) {
    }

    componentDidUpdate(prevProps: InvolvementPageProps, prevState) {
        const {
            involvementId,
            involvement,
            form,
            navigate,
            loadInvolvement,
            loadingInvolvement,
            loadInvolvementSuccess,
            createInvolvement,
            creatingInvolvement,
            createInvolvementSuccess,
            savingInvolvement,
            saveInvolvementSuccess,
            validationFailures
        } = this.props;

        if (involvementId && prevProps.involvementId != involvementId) {
            loadInvolvement(involvementId);
        }
        else if (!involvementId && prevProps.involvementId) {
            createInvolvement();
        }

        if (!loadingInvolvement && prevProps.loadingInvolvement) {
            if (loadInvolvementSuccess) {
                this.loadForm();
            }
        }

        if (!savingInvolvement && prevProps.savingInvolvement) {
            if (saveInvolvementSuccess) {
                if (validationFailures && validationFailures.length > 0) {
                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {
                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);
                }
                if (form.saveAndReturn) {
                    navigate("/settings/trial/involvements")
                } else if (!form.saveAndReturn && involvementId && involvementId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !involvementId && involvement && involvement.id > 0) {
                    navigate("/settings/trial/involvements/" + involvement.id);
                }
            }
            else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

        if (!creatingInvolvement && prevProps.creatingInvolvement) {
            if (createInvolvementSuccess) {
                this.loadForm();
            }
        }
    }

    componentWillUpdate(nextProps: InvolvementPageProps) {
    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearInvolvement();
        loadForm(reduxFormName, initialInvolvementState);
        resetForm(reduxFormName);
    }

    clearInvolvement() {
        this.props.clearInvolvement();
    }

    showInvolvement(): boolean {
        const {
            loadingInvolvement,
            loadInvolvementSuccess
        } = this.props

        if (!loadingInvolvement && loadInvolvementSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingInvolvement
        } = this.props;

        return <TrialLookupsLayout loading={loadingInvolvement} permission={Dtos.Permission.InvolvementsView}>
            {this.renderContent()}
        </TrialLookupsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingInvolvement,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.InvolvementsUpdate, Dtos.Permission.InvolvementsCreate);
        const formDisabled: boolean = !hasPermission || savingInvolvement || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            value: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            }
        };

        return <div>
            <h1>Involvement</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "involvement", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.Involvement) {
        const {
            saveInvolvement
        } = this.props;

        if (data) {
            let changes: Dtos.Involvement = {} as Dtos.Involvement;
            saveInvolvement(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.Involvement) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/trial/involvements");
    }

    loadForm() {
        const {
            loadForm,
            involvement,
        } = this.props

        let extraData: Dtos.Involvement = {} as Dtos.Involvement;

        loadForm(reduxFormName, Object.assign({ ...involvement }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: InvolvementPageProps): IInvolvementPageProps => {

    let involvement: Dtos.Involvement | undefined = !(state.involvements.formData instanceof Array) ? state.involvements.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        involvementId: ownProps.match && ownProps.match.params.involvementId && ownProps.match.params.involvementId.toString() != "create" ? ownProps.match.params.involvementId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        involvement: involvement!,

        loadingInvolvement: state.involvements.loadState && state.involvements.loadState.status == RequestState.Pending,
        loadInvolvementSuccess: state.involvements.loadState && state.involvements.loadState.status == RequestState.Success,
        loadInvolvementFailure: state.involvements.loadState && state.involvements.loadState.status == RequestState.Failure,

        savingInvolvement: state.involvements.saveState && state.involvements.saveState.status == RequestState.Pending,
        saveInvolvementSuccess: state.involvements.saveState && state.involvements.saveState.status == RequestState.Success,
        saveInvolvementFailure: state.involvements.saveState && state.involvements.saveState.status == RequestState.Failure,

        creatingInvolvement: state.involvements.createState && state.involvements.createState.status == RequestState.Pending,
        createInvolvementSuccess: state.involvements.createState && state.involvements.createState.status == RequestState.Success,
        createInvolvementFailure: state.involvements.createState && state.involvements.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: [],

        form: state.reduxForms.involvement,
        formState: state.involvements.formState,
        formProperties: state.involvements.formProperties,

        reduxFormState: state.reduxForms.formState.involvement,
        validationFailures: involvement ?
            state.involvements.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): IInvolvementPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadInvolvement: bindActionCreators(InvolvementActions.LoadInvolvementById, dispatch),
        clearInvolvement: bindActionCreators(InvolvementActions.Clear, dispatch),

        saveInvolvement: bindActionCreators(InvolvementActions.SaveInvolvement, dispatch),
        createInvolvement: bindActionCreators(InvolvementActions.CreateInvolvement, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(InvolvementPage);
