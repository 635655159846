import './styles/app.scss';
import 'react-dates/initialize';
import "core-js";
import "media-match/media.match"
import * as ReactDOM from 'react-dom';
import * as React from 'react';
import * as Dtos from './dtos/Tmd.dtos';
import { initializeIcons } from '@uifabric/icons';
import { bindActionCreators } from "redux";
import { Provider, connect } from "react-redux";
import { Route, Switch, Redirect, match } from 'react-router';
import { ConnectedRouter } from 'react-router-redux';
import { routerActions } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import reducers, { ITmdState } from './reducers/';
import * as UserActions from './actions/user';
import { configureStore } from "./store/";
import { rootEpic } from "./epics/";
import {
    WebsiteSettingsPage,
    ErrorPage,
    HomePage,
    DashboardPage,
    TrialsPage,
    TrialPage,
    TrialMilestonesPage,
    TrialApiPage,
    TrialGroupPage,
    TrialItemPage,
    TrialItemsPage,
    TrialItemInstancePage,
    TrialItemInstancesPage,
    TrialItemInstanceImportPage,
    TrialInstitutionsPage,
    TrialInstitutionPage,
    TrialTreatmentsPage,
    TrialTreatmentPage,
    TrialInstitutionItemsPage,
    TrialInstitutionNotesPage,
    TrialInstitutionPersonnelPage,
    TrialInstitutionPersonnelEditPage,
    TrialInstitutionPatientsPage,
    TrialAccrualsPage,
    TrialNotesPage,
    TrialGroupsPage,
    TrialPersonnelPage,
    TrialPersonnelEditPage,
    TrialItemImportPage,
    TrialItemUpdatePage,
    TrialPatientPage,
    TrialPatientsPage,
    ItemComponentsPage,
    ItemComponentPage,
    CategoriesPage,
    CategoryPage,
    GroupPage,
    GroupsPage,
    GroupItemPage,
    GroupItemsPage,
    GroupPersonnelPage,
    GroupPersonnelEditPage,
    GroupTrialsPage,
    GroupNotesPage,
    InstitutionsPage,
    InstitutionNotesPage,
    InstitutionPage,
    PersonnelNotesPage,
    PersonnelRoleEditPage,
    PersonnelPermissionsPage,
    PersonnelRolesPage,
    PersonnelFilesPage,
    PersonnelPage,
    PersonnelsPage,
    PersonnelByRolesPage,
    PersonnelOCRolesPage,
    PersonnelActiveDirectoryPage,
    InstitutionPersonnelPage,
    InstitutionPersonnelEditPage,
    SearchPage,
    CountryPage,
    CountriesPage,
    InstitutionCodingPage,
    InstitutionCodingsPage,
    NoteTypePage,
    NoteTypesPage,
    TrialTypePage,
    TrialTypesPage,
    NotePage,
    ItemComponentTypePage,
    ItemComponentTypesPage,
    OpenClinicaUserRolesPage,
    PersonnelHubspotPage,
    MyTrialsPage,
    StatePage,
    StatesPage,
    SiteLocationsPage,
    SiteLocationPage,
    KeyContactsPage,
    KeyContactPage,
    FacilityAllianceMembersPage,
    FacilityAllianceMemberPage,
    InstitutionTypesPage,
    InstitutionTypePage,
    TrialCategorysPage,
    TrialCategoryPage,
    TumourGroupsPage,
    TumourGroupPage,
    AllocatedWPsPage,
    AllocatedWPPage,
    TrialPhasesPage,
    TrialPhasePage,
    InvolvementsPage,
    InvolvementPage,
    CollaboratingGroupsPage,
    CollaboratingGroupPage,
    TrialCoordCentresPage,
    TrialCoordCentrePage,
    GenderPage,
    GendersPage,
    InterestPage,
    InterestsPage,
    MembershipTypePage,
    MembershipTypesPage,
    ProfessionalDisciplinePage,
    ProfessionalDisciplinesPage,
    SalutationPage,
    SalutationsPage,
    HubspotExportPage,
    HubspotMemberPage,
    FormTemplatesPage,
    FormTemplatePage,
    InstitutionFormsPage,
    InstitutionFormPage,
    InstitutionFormEntryPage,
    TrialFormsPage,
    TrialFormPage,
    RolePage,
    RolesPage,
    InstitutionDepartmentsPage,
    InstitutionDepartmentEditPage,
    InstitutionDepartmentContactEditPage,
    InstitutionDepartmentPersonnelEditPage,
    TrialFormEntryPage,
    ReportingTagCategoriesPage,
    ReportingTagCategoryPage,
    ReportingTagsPage,
    ReportingTagPage,
    TrialFormReportPage,
    TaskTypesPage,
    TaskTypePage,
    FormTemplateFileTypesPage,
    FormTemplateFileTypePage,
    TrackerTypesPage,
    TrackerTypePage,
    TrackerMilestonesPage,
    TrackerMilestonePage,
    TrialTrackerPage,
    TrialTrackersPage,
    QAEmailTemplatesPage,
    QAEmailTemplatePage,
    TemplateTrialItemPage,
    TemplateTrialItemsPage,
    TrialInstitutionItemTemplatePage,
    TrialInstitutionItemTemplatesPage,
    HubspotActiveMembersPage,
    TrialPresentationTypePage,
    TrialPresentationTypesPage,
    TrialPublicationTypePage,
    TrialPublicationTypesPage,
    TrialAnalysisTypePage,
    TrialAnalysisTypesPage
} from "./containers/index";

import Alert from 'react-s-alert';
import { RequestState } from './enumerations/RequestState';
import AuthenticatedFn from './components/common/Authentication/AuthenticatedFn';
import { OverlayLoading, NoAccessMessage } from './components/common';
import * as Tracking from './helpers/analytics';

// Register icons and pull the fonts from the default SharePoint cdn.
initializeIcons();

const reducer = reducers;

export const browserHistory = createBrowserHistory();

Tracking.initGa(browserHistory);
export const store = configureStore(reducer, rootEpic, browserHistory);

interface IAppActions {
    loadUser?: UserActions.IUserLoadActionCreator;
    navigate?: Function;
}

interface IAppProps {

    user?: Dtos.User;
    loadingUser?: boolean;
    loadUserSuccess?: boolean;
    loadUserFailure?: boolean;

    checkedAuthentication?: boolean;
    match?: match<any>
}

type AppProps = IAppProps & IAppActions;

interface IAuthenticatedRouteProps {
    user?: Dtos.User;
    location?: Location;
    loadingUser?: boolean;
    loadUserSuccess?: boolean;
    loadUserFailure?: boolean;
    checkedAuthentication?: boolean;
    match?: match<any>;
}

export default class App extends React.Component<AppProps, any> {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        const {
            user,
            loadUser

        } = this.props

        if (!user && loadUser) {
            loadUser();
        }
    }

    render() {

        const {
            user,
            loadingUser,
            loadUserFailure,
            loadUserSuccess,
            checkedAuthentication
        } = this.props

        const AuthenticatedRouteProps: IAuthenticatedRouteProps = {
            user: user,
            location: window.location,
            loadingUser: loadingUser,
            loadUserSuccess: loadUserSuccess,
            loadUserFailure: loadUserFailure,
            checkedAuthentication: checkedAuthentication,
            match: this.props.match

        }
        return <div>
            <ConnectedRouter history={browserHistory}>
                <Switch>
                    {
                        // Error Route
                    }
                    <Route path="/error/:errorCode" component={ErrorPage} />
                    {
                        // website settings Route
                    }
                    {
                        // home Route
                    }
                    <Route path="/dashboard" component={DashboardPage } />
                    <Route path="/home" component={HomePage} />
                    <Route path="/search" component={SearchPage} />
                    <Route path="/trials/create" component={TrialPage} />
                    <Route path="/trials/:trialId/trialinstitution/:trialInstitutionId/notes/:noteId" component={NotePage} />
                    <Route path="/trials/:trialId/trialinstitution/:trialInstitutionId/notes" component={TrialInstitutionNotesPage} />
                    <Route path="/trials/:trialId/trialinstitution/:trialInstitutionId/personnel/:personnelRoleId" component={TrialInstitutionPersonnelEditPage} />
                    <Route path="/trials/:trialId/trialinstitution/:trialInstitutionId/personnel" component={TrialInstitutionPersonnelPage} />
                    <Route path="/trials/:trialId/trialinstitution/:trialInstitutionId/items" component={TrialInstitutionItemsPage} />
                    <Route path="/trials/:trialId/trialinstitution/:trialInstitutionId/details" component={TrialInstitutionPage} />
                    <Route path="/trials/:trialId/trialinstitution/:trialInstitutionId/participants/:patientId" component={TrialPatientPage} />
                    <Route path="/trials/:trialId/trialinstitution/:trialInstitutionId/participants" component={TrialInstitutionPatientsPage} />
                    {
                        /*
                    <Route path="/trials/:trialId/institutions/:trialInstitutionId/notes" component={TrialInstitutionPage} />
                    <Route path="/trials/:trialId/institutions/:trialInstitutionId" component={TrialInstitutionPage} />
                         */
                    }
                    <Route path="/trials/:trialId/institutions" component={TrialInstitutionsPage} />
                    <Route path="/trials/:trialId/accruals" component={TrialAccrualsPage} />
                    <Route path="/trials/:trialId/personnel/:personnelRoleId" component={TrialPersonnelEditPage} />
                    <Route path="/trials/:trialId/personnel" component={TrialPersonnelPage} />
                    <Route path="/trials/:trialId/groups/:trialGroupId" component={TrialGroupPage} />
                    <Route path="/trials/:trialId/groups" component={TrialGroupsPage} />
                    <Route path="/trials/:trialId/treatments/:trialTreatmentId" component={TrialTreatmentPage} />
                    <Route path="/trials/:trialId/treatments" component={TrialTreatmentsPage } />
                    <Route path="/trials/:trialId/forms/:trialFormId/report" component={TrialFormReportPage} />
                    <Route path="/trials/:trialId/forms/:trialFormId/entry" component={TrialFormEntryPage} />
                    <Route path="/trials/:trialId/forms/:trialFormId" component={TrialFormPage} />
                    <Route path="/trials/:trialId/forms" component={TrialFormsPage} />
                    <Route path="/trials/:trialId/itemsimport" component={TrialItemInstanceImportPage} />
                    <Route path="/trials/:trialId/items/:trialItemInstanceId" component={TrialItemInstancePage} />
                    <Route path="/trials/:trialId/items" component={TrialItemInstancesPage} />
                    <Route path="/trials/:trialId/participants/:patientId" component={TrialPatientPage} />
                    <Route path="/trials/:trialId/participants" component={TrialPatientsPage } />
                    <Route path="/trials/:trialId/notes/:noteId" component={NotePage} />
                    <Route path="/trials/:trialId/notes" component={TrialNotesPage} />
                    <Route path="/trials/:trialId/trialitems/:trialItemId" component={TrialItemPage} />
                    <Route path="/trials/:trialId/trialitems" component={TrialItemsPage} />
                    <Route path="/trials/:trialId/trialitemimport" component={TrialItemImportPage} />
                    <Route path="/trials/:trialId/trialitemupdate" component={TrialItemUpdatePage} />
                    <Route path="/trials/:trialId/api" component={TrialApiPage} />
                    <Route path="/trials/:trialId/trackers/:trialTrackerId" component={TrialTrackerPage} />
                    <Route path="/trials/:trialId/trackers" component={TrialTrackersPage} />
                    <Route path="/trials/:trialId/milestones" component={TrialMilestonesPage} />
                    <Route path="/trials/:trialId" component={TrialPage} />
                    <Route path="/trials" component={TrialsPage} />
                    <Route path="/mytrials" component={MyTrialsPage} />
                    
                    <Route path="/institutions/create" component={InstitutionPage} />
                    <Route path="/institutions/:institutionId/forms/:institutionFormId/entry" component={InstitutionFormEntryPage} />
                    <Route path="/institutions/:institutionId/forms/:institutionFormId" component={InstitutionFormPage} />
                    <Route path="/institutions/:institutionId/forms" component={InstitutionFormsPage} />
                    <Route path="/institutions/:institutionId/notes/:noteId" component={NotePage} />
                    <Route path="/institutions/:institutionId/notes" component={InstitutionNotesPage} />
                    <Route path="/institutions/:institutionId/details" component={InstitutionPage} />
                    <Route path="/institutions/:institutionId/personnel/:personnelRoleId" component={InstitutionPersonnelEditPage} />
                    <Route path="/institutions/:institutionId/personnel" component={InstitutionPersonnelPage} />
                    <Route path="/institutions/:institutionId/details" component={InstitutionPage} />
                    <Route path="/institutions/:institutionId/departments/:departmentId/personnel/:personnelRoleId" component={InstitutionDepartmentPersonnelEditPage} />
                    <Route path="/institutions/:institutionId/departments/:departmentId/contacts/:departmentContactId" component={InstitutionDepartmentContactEditPage} />
                    <Route path="/institutions/:institutionId/departments/:departmentId" component={InstitutionDepartmentEditPage} />
                    <Route path="/institutions/:institutionId/departments" component={InstitutionDepartmentsPage } />
                    <Route path="/institutions" component={InstitutionsPage} />

                    <Route path="/personnel/create" component={PersonnelPage} />
                    <Route path="/personnel/byroles" component={PersonnelByRolesPage} />
                    <Route path="/personnel/:personnelId/notes/:noteId" component={NotePage} />
                    <Route path="/personnel/:personnelId/notes" component={PersonnelNotesPage} />
                    <Route path="/personnel/:personnelId/roles/:personnelRoleId" component={PersonnelRoleEditPage} />
                    <Route path="/personnel/:personnelId/activedirectory" component={PersonnelActiveDirectoryPage} />
                    <Route path="/personnel/:personnelId/openclinica" component={PersonnelOCRolesPage} />
                    <Route path="/personnel/:personnelId/permissions" component={PersonnelPermissionsPage} />
                    <Route path="/personnel/:personnelId/roles" component={PersonnelRolesPage} />
                    <Route path="/personnel/:personnelId/items" component={PersonnelFilesPage} />
                    <Route path="/personnel/:personnelId/hubspot" component={PersonnelHubspotPage} />
                    <Route path="/personnel/:personnelId/details" component={PersonnelPage} />
                    <Route path="/personnel" component={PersonnelsPage} />
                    <Route path="/settings/itemcomponents/:itemComponentId/:cloneId" component={ItemComponentPage} />
                    <Route path="/settings/itemcomponents/:itemComponentId" component={ItemComponentPage} />
                    <Route path="/settings/itemcomponents" component={ItemComponentsPage} />
                    <Route path="/groups/:groupId/personnel/:personnelRoleId" component={GroupPersonnelEditPage} />
                    <Route path="/groups/:groupId/personnel" component={GroupPersonnelPage} />
                    <Route path="/groups/:groupId/groupItems/:groupItemId" component={GroupItemPage} />
                    <Route path="/groups/:groupId/groupItems" component={GroupItemsPage} />
                    <Route path="/groups/:groupId/notes/:noteId" component={NotePage} />
                    <Route path="/groups/:groupId/notes" component={GroupNotesPage} />
                    <Route path="/groups/:groupId/trials" component={GroupTrialsPage} />
                    <Route path="/groups/:groupId/details" component={GroupPage} />
                    <Route path="/groups" component={GroupsPage} />

                    <Route path="/settings/general/countries/:countryId" component={CountryPage} />
                    <Route path="/settings/general/countries" component={CountriesPage} />
                    <Route path="/settings/general/states/:stateId" component={StatePage} />
                    <Route path="/settings/general/states" component={StatesPage} />
                    <Route path="/settings/general/itemcomponenttypes/:itemComponentTypeId" component={ItemComponentTypePage} />
                    <Route path="/settings/general/itemcomponenttypes" component={ItemComponentTypesPage} />
                    <Route path="/settings/general/notetypes/:noteTypeId" component={NoteTypePage} />
                    <Route path="/settings/general/notetypes" component={NoteTypesPage} />
                    <Route path="/settings/general/genders/:genderId" component={GenderPage} />
                    <Route path="/settings/general/genders" component={GendersPage} />
                    <Route path="/settings/general/interests/:interestId" component={InterestPage} />
                    <Route path="/settings/general/interests" component={InterestsPage} />
                    <Route path="/settings/general/membershiptypes/:membershipTypeId" component={MembershipTypePage} />
                    <Route path="/settings/general/membershiptypes" component={MembershipTypesPage} />
                    <Route path="/settings/general/professionaldisciplines/:professionalDisciplineId" component={ProfessionalDisciplinePage} />
                    <Route path="/settings/general/professionaldisciplines" component={ProfessionalDisciplinesPage} />
                    <Route path="/settings/general/salutations/:salutationId" component={SalutationPage} />
                    <Route path="/settings/general/salutations" component={SalutationsPage} />
                    <Route path="/settings/general/roles/:roleId" component={RolePage} />
                    <Route path="/settings/general/roles" component={RolesPage} />

                    <Route path="/settings/institution/sitelocations/:siteLocationId" component={SiteLocationPage} />
                    <Route path="/settings/institution/sitelocations" component={SiteLocationsPage} />
                    <Route path="/settings/institution/keycontacts/:keyContactId" component={KeyContactPage} />
                    <Route path="/settings/institution/keycontacts" component={KeyContactsPage} />
                    <Route path="/settings/institution/facilityalliancemembers/:facilityAllianceMemberId" component={FacilityAllianceMemberPage} />
                    <Route path="/settings/institution/facilityalliancemembers" component={FacilityAllianceMembersPage} />
                    <Route path="/settings/institution/institutiontypes/:institutionTypeId" component={InstitutionTypePage} />
                    <Route path="/settings/institution/institutiontypes" component={InstitutionTypesPage} />
                    <Route path="/settings/institution/institutioncodings/:institutionCodingId" component={InstitutionCodingPage} />
                    <Route path="/settings/institution/institutioncodings" component={InstitutionCodingsPage} />

                    <Route path="/settings/trial/trialcategorys/:trialCategoryId" component={TrialCategoryPage} />
                    <Route path="/settings/trial/trialcategorys" component={TrialCategorysPage} />
                    <Route path="/settings/trial/tumourgroups/:tumourGroupId" component={TumourGroupPage} />
                    <Route path="/settings/trial/tumourgroups" component={TumourGroupsPage} />
                    <Route path="/settings/trial/allocatedwps/:allocatedWPId" component={AllocatedWPPage} />
                    <Route path="/settings/trial/allocatedwps" component={AllocatedWPsPage} />
                    <Route path="/settings/trial/trialphases/:trialPhaseId" component={TrialPhasePage} />
                    <Route path="/settings/trial/trialphases" component={TrialPhasesPage} />
                    <Route path="/settings/trial/involvements/:involvementId" component={InvolvementPage} />
                    <Route path="/settings/trial/involvements" component={InvolvementsPage} />
                    <Route path="/settings/trial/collaboratinggroups/:collaboratingGroupId" component={CollaboratingGroupPage} />
                    <Route path="/settings/trial/collaboratinggroups" component={CollaboratingGroupsPage} />
                    <Route path="/settings/trial/trialcoordcentres/:trialCoordCentreId" component={TrialCoordCentrePage} />
                    <Route path="/settings/trial/trialcoordcentres" component={TrialCoordCentresPage} />
                    <Route path="/settings/trial/trialtypes/:trialTypeId" component={TrialTypePage} />
                    <Route path="/settings/trial/trialtypes" component={TrialTypesPage} />
                    <Route path="/settings/trial/trialPresentationTypes/:trialPresentationTypeId" component={TrialPresentationTypePage} />
                    <Route path="/settings/trial/trialPresentationTypes" component={TrialPresentationTypesPage} />
                    <Route path="/settings/trial/trialPublicationTypes/:trialPublicationTypeId" component={TrialPublicationTypePage} />
                    <Route path="/settings/trial/trialPublicationTypes" component={TrialPublicationTypesPage} />
                    <Route path="/settings/trial/trialAnalysisTypes/:trialAnalysisTypeId" component={TrialAnalysisTypePage} />
                    <Route path="/settings/trial/trialAnalysisTypes" component={TrialAnalysisTypesPage} />
                    
                    
                    <Route path="/settings/qa/formtemplates/:formTemplateId" component={FormTemplatePage} />
                    <Route path="/settings/qa/formtemplates" component={FormTemplatesPage} />
                    <Route path="/settings/qa/formcomponents/:itemComponentId/:cloneId" component={ItemComponentPage} />
                    <Route path="/settings/qa/formcomponents/:itemComponentId" component={ItemComponentPage} />
                    <Route path="/settings/qa/formcomponents" component={ItemComponentsPage} />
                    <Route path="/settings/qa/reportingTagCategories/:reportingTagCategoryId" component={ReportingTagCategoryPage} />
                    <Route path="/settings/qa/reportingTagCategories" component={ReportingTagCategoriesPage} />
                    <Route path="/settings/qa/reportingTags/:reportingTagId" component={ReportingTagPage} />
                    <Route path="/settings/qa/reportingTags" component={ReportingTagsPage} />
                    <Route path="/settings/qa/taskTypes/:taskTypeId" component={TaskTypePage} />
                    <Route path="/settings/qa/taskTypes" component={TaskTypesPage} />
                    <Route path="/settings/qa/trackertypes/:trackerTypeId" component={TrackerTypePage} />
                    <Route path="/settings/qa/trackertypes" component={TrackerTypesPage} />
                    <Route path="/settings/qa/trackermilestones/:trackerMilestoneId" component={TrackerMilestonePage} />
                    <Route path="/settings/qa/trackermilestones" component={TrackerMilestonesPage} />
                    <Route path="/settings/qa/formTemplateFileTypes/:formTemplateFileTypeId" component={FormTemplateFileTypePage} />
                    <Route path="/settings/qa/formTemplateFileTypes" component={FormTemplateFileTypesPage} />

                    <Route path="/settings/qa/qaEmailTemplates/:standardEmailTemplateId" component={QAEmailTemplatePage} />
                    <Route path="/settings/qa/qaEmailTemplates" component={QAEmailTemplatesPage} />

                    <Route path="/settings/item/trialitemtemplates/:templateTrialItemId" component={TemplateTrialItemPage} />
                    <Route path="/settings/item/trialitemtemplates" component={TemplateTrialItemsPage} />
                    <Route path="/settings/item/itemcategories/:categoryId" component={CategoryPage} />
                    <Route path="/settings/item/itemcategories" component={CategoriesPage} />
                    <Route path="/settings/item/itemtemplates/:trialInstitutionItemTemplateId" component={TrialInstitutionItemTemplatePage} />
                    <Route path="/settings/item/itemtemplates" component={TrialInstitutionItemTemplatesPage} />
                    <Route path="/settings/item/itemcomponents/:itemComponentId/:cloneId" component={ItemComponentPage} />
                    <Route path="/settings/item/itemcomponents/:itemComponentId" component={ItemComponentPage} />
                    <Route path="/settings/item/itemcomponents" component={ItemComponentsPage} />


                    <Route path="/settings/openclinicauserroles" component={OpenClinicaUserRolesPage} />
                    <Route path="/settings/hubspot/activemembers" component={HubspotActiveMembersPage } />
                    <Route path="/settings/hubspot/import" component={HubspotExportPage} />
                    <Route path="/settings/hubspot/emailupdate" component={HubspotMemberPage} />
                    <Redirect exact  from="/settings" to="/settings/general/categories" />
                    <AuthenticatedFn {...AuthenticatedRouteProps}>
                        {
                            (user, authenticationHelpers) => {
                                if (user) {
                                    // temporary redirect for dev, change when in test
                                    return <Redirect from="*" to="/dashboard" />
                                }
                                else {
                                    window.location.href = "/auth/openiddict?ReturnUrl=" + encodeURIComponent(window.location.pathname + window.location.search);
                                    return <OverlayLoading />;
                                }
                            }
                        }
                    </AuthenticatedFn>
                </Switch>
            </ConnectedRouter>
            <Alert stack={{ limit: 5 }} html={false} effect='scale' position='bottom-right' timeout={5000} />
        </div>
    }
}

//  <Route path="/settings" component={WebsiteSettingsPage} />


const mapStateToProps = (state: ITmdState, ownProps: AppProps): IAppProps => {

    return {
        user: state.user.data,
        loadingUser: state.user.loadState && state.user.loadState.status == RequestState.Pending,
        loadUserSuccess: state.user.loadState && state.user.loadState.status == RequestState.Success,
        loadUserFailure: state.user.loadState && state.user.loadState.status == RequestState.Failure,
        checkedAuthentication: state.user.checked,
        match: ownProps.match
    };
};

const mapDispatchToProps = (dispatch): IAppActions => {
    return {
        loadUser: bindActionCreators(UserActions.LoadUser, dispatch),
        navigate: bindActionCreators(routerActions.push, dispatch)
    }
};

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App)

ReactDOM.render(
    <Provider store={store as any}>
        <ConnectedApp />
    </Provider>,
    document.getElementById('app'));
