import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Tmd.dtos';

import * as trialItemInstanceActions from '../actions/trialItemInstance';
import * as trialActions from '../actions/trial';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState, IFormFilter } from "./index";
import { updateFailureIndexToId } from "../helpers/formErrorHelper";

export interface ITrialItemInstanceState {
    formData: Dtos.TrialItemInstance | Dtos.TrialItemInstance[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    validationFailures: Dtos.ResponseError[];
    lookups: Dtos.Lookup[],
    loadState: IRequestState;
    saveState: IRequestState;
    updateState: IRequestState;
    createState: IRequestState;
    templateTrialItems: Dtos.TemplateTrialItem[];
    formFilter: IFormFilter;
    formDataByCategory: Dtos.TrialItemInstanceCategory[];
    formFilteredData: Dtos.TrialItemInstanceCategory[];
}

const initialState: ITrialItemInstanceState = {
    formData: undefined,
    formState: undefined,
    formProperties: undefined,
    validationFailures: undefined,
    lookups: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    updateState: {
        status: RequestState.None
    } as IRequestState,
    createState: {
        status: RequestState.None
    } as IRequestState,
    templateTrialItems: undefined,
    formFilter: {
        search: "",
        showInActive: false
    },
    formDataByCategory: [],
    formFilteredData: []
};


function loading(state: ITrialItemInstanceState, isLoading: boolean): ITrialItemInstanceState {
    const newState: ITrialItemInstanceState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(
    state: ITrialItemInstanceState,
    formData: Dtos.TrialItemInstance | Dtos.TrialItemInstance[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[],
    responseStatus: Dtos.ResponseStatus
): ITrialItemInstanceState {
    const newState: ITrialItemInstanceState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        loadState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function loadFailure(state: ITrialItemInstanceState, responseStatus: Dtos.ResponseStatus): ITrialItemInstanceState {
    const newState: ITrialItemInstanceState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function loadTrialSuccess(
    state: ITrialItemInstanceState,
    trialItemInstances: Dtos.TrialItemInstanceCategory[]
): ITrialItemInstanceState {

    const newState: ITrialItemInstanceState = update(state, {
        formDataByCategory: {
            $set: trialItemInstances
        }
    });

    return filter(newState, state.formFilter);
}

function filter(state: ITrialItemInstanceState, formFilter: IFormFilter): ITrialItemInstanceState {

    let formFilteredData: Dtos.TrialItemInstanceCategory[] = [];

    const formData: Dtos.TrialItemInstanceCategory[] = state.formDataByCategory instanceof Array ? state.formDataByCategory : undefined;

    if (!formData) return state;

    // filter the sub items
    formData.forEach(item => {
        let newItem = { ...item };
        if (newItem.trialItemInstanceSummaries) {
            let trialItemInstanceSummaries = [...newItem.trialItemInstanceSummaries];
            trialItemInstanceSummaries = trialItemInstanceSummaries.filter(p =>
                (p.active == true || formFilter.showInActive) &&
                (formFilter.search == "" || p.name.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1 || p.description.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1)
            );
            newItem.trialItemInstanceSummaries = trialItemInstanceSummaries;
        }
        formFilteredData.push(newItem);
    });

    // filter the categories
    formFilteredData = formFilteredData.filter(p =>
        (p.trialItemInstanceSummaries && p.trialItemInstanceSummaries.length > 0) //||
        //(formFilter.search == "" || p.category.value.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1)
    );

    const newState: ITrialItemInstanceState = update(state, {
        formFilter: {
            $set: formFilter
        },
        formFilteredData: {
            $set: formFilteredData
        }
    });

    return newState;
}

function clear(state: ITrialItemInstanceState, propertiesToClear?: string[]): ITrialItemInstanceState {

    if (!propertiesToClear) {
        const newState: ITrialItemInstanceState = update(initialState, {});
        return newState;
    }

    let stateModifier: any = {};

    propertiesToClear.forEach(propertyToClear => {
        stateModifier[propertyToClear] = { $set: initialState[propertyToClear] }
    });

    const newState = update(state, stateModifier);

    return newState;
}

function saving(state: ITrialItemInstanceState, isSaving: boolean): ITrialItemInstanceState {
    const newState: ITrialItemInstanceState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function saveSuccess(
    state: ITrialItemInstanceState,
    formData: Dtos.TrialItemInstance | Dtos.TrialItemInstance[],
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus
): ITrialItemInstanceState {
    const newState: ITrialItemInstanceState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        saveState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function saveFailure(state: ITrialItemInstanceState, responseStatus: Dtos.ResponseStatus): ITrialItemInstanceState {
    const newState: ITrialItemInstanceState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                meta: responseStatus.meta
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, state.formData) : undefined
        }
    });

    return newState;
}

function creating(state: ITrialItemInstanceState, isCreating: boolean): ITrialItemInstanceState {
    const newState: ITrialItemInstanceState = update(state, {
        createState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function createSuccess(
    state: ITrialItemInstanceState,
    formData: Dtos.TrialItemInstance,
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[],
    templateTrialItems: Dtos.TemplateTrialItem[]
): ITrialItemInstanceState {
    const newState: ITrialItemInstanceState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        createState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        },
        templateTrialItems: {
            $set: templateTrialItems
        },
    });

    return newState;
}

function createFailure(state: ITrialItemInstanceState, responseStatus: Dtos.ResponseStatus): ITrialItemInstanceState {
    const newState: ITrialItemInstanceState = update(state, {
        createState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

const TrialItemInstanceReducer: Reducer<ITrialItemInstanceState> = (state: ITrialItemInstanceState = initialState, action: trialItemInstanceActions.TrialItemInstanceAction | trialActions.TrialAction) => {
    switch (action.type) {
        case trialActions.ACTION_TRIALS_WITH_TRIALITEMINSTANCES_LOAD_SUCCESS:
            return loadTrialSuccess(state, action.trialItemInstances);
        case trialItemInstanceActions.ACTION_TRIALITEMINSTANCE_LOAD_BY_ID:
        case trialItemInstanceActions.ACTION_TRIALITEMINSTANCE_LOAD_CURRENT_USER:
            return loading(state, true);
        case trialItemInstanceActions.ACTION_TRIALITEMINSTANCE_LOAD_SUCCESS:
            return loadSuccess(state, action.formData, action.formState, action.formProperties, action.lookups, action.responseStatus);
        case trialItemInstanceActions.ACTION_TRIALITEMINSTANCE_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case trialItemInstanceActions.ACTION_TRIALITEMINSTANCE_CLEAR:
            return clear(state, action.propertiesToClear);
        case trialItemInstanceActions.ACTION_TRIALITEMINSTANCE_SAVE:
            return saving(state, true);
        case trialItemInstanceActions.ACTION_TRIALITEMINSTANCE_SAVE_SUCCESS:
            return saveSuccess(state, action.formData, action.formState, action.responseStatus);
        case trialItemInstanceActions.ACTION_TRIALITEMINSTANCE_SAVE_FAILURE:
            return saveFailure(state, action.responseStatus);
        case trialItemInstanceActions.ACTION_TRIALITEMINSTANCE_CREATE:
            return creating(state, true);
        case trialItemInstanceActions.ACTION_TRIALITEMINSTANCE_CREATE_SUCCESS:
            return createSuccess(state, action.formData, action.formState, action.formProperties, action.lookups, action.templateTrialItems);
        case trialItemInstanceActions.ACTION_TRIALITEMINSTANCE_CREATE_FAILURE:
            return createFailure(state, action.responseStatus);
        case trialItemInstanceActions.ACTION_TRIALITEMINSTANCE_FILTER:
            return filter(state, action.formFilter);
    }

    return state;
}

export default TrialItemInstanceReducer;