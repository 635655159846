import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_ALLOCATEDWPS_LOAD,
    ACTION_ALLOCATEDWPS_LOAD_BY_ID,

    ACTION_ALLOCATEDWPS_SAVE,

    ACTION_ALLOCATEDWPS_CREATE,

    IAllocatedWPLoadAction,
    IAllocatedWPLoadByIdAction,
    IAllocatedWPLoadFailureAction,
    IAllocatedWPLoadSuccessAction,

    IAllocatedWPSaveAction,
    IAllocatedWPSaveSuccessAction,
    IAllocatedWPSaveFailureAction,

    IAllocatedWPCreateAction,
    IAllocatedWPCreateSuccessAction,
    IAllocatedWPCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/allocatedWPs";
import { allocatedWPApi } from "../services/allocatedWP";



export const
    getAllocatedWPsEpic = (action$): Observable<IAllocatedWPLoadSuccessAction | IAllocatedWPLoadFailureAction> => {
        return action$
            .ofType(ACTION_ALLOCATEDWPS_LOAD)
            .mergeMap((action: IAllocatedWPLoadAction) => {
                return allocatedWPApi
                    .getAllocatedWPs()
                    .map(response =>
                        LoadSuccess(response.allocatedWPs, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getAllocatedWPByIdEpic = (action$): Observable<IAllocatedWPLoadSuccessAction | IAllocatedWPLoadFailureAction> => {
        return action$
            .ofType(ACTION_ALLOCATEDWPS_LOAD_BY_ID)
            .mergeMap((action: IAllocatedWPLoadByIdAction) => {
                return allocatedWPApi
                    .getAllocatedWPById(action.id)
                    .map(response =>
                        LoadSuccess(response.allocatedWP, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setAllocatedWPEpic = (action$): Observable<IAllocatedWPSaveSuccessAction | IAllocatedWPSaveFailureAction> => {
        return action$
            .ofType(ACTION_ALLOCATEDWPS_SAVE)
            .mergeMap((action: IAllocatedWPSaveAction) => {
                return allocatedWPApi
                    .setAllocatedWP(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.allocatedWP, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createAllocatedWPEpic = (action$): Observable<IAllocatedWPCreateSuccessAction | IAllocatedWPCreateFailureAction> => {
        return action$
            .ofType(ACTION_ALLOCATEDWPS_CREATE)
            .mergeMap((action: IAllocatedWPCreateAction) => {
                return allocatedWPApi
                    .createAllocatedWP()
                    .map(response =>
                        CreateSuccess(
                            response.allocatedWP,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



