import * as React from 'react';
import './TrialApiPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { ITrialReduxFormState, initialTrialState } from "../../../reducers/reactReduxForms/trial";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as TrialActions from '../../../actions/trial';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { Form, actions, Control } from 'react-redux-form';
import { TrialLayout } from '../../index';

interface ITrialApiPageParams {
    trialId: number;
}

interface ITrialApiPageProps {

    form: Dtos.Trial & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITrialReduxFormState;

    location: Location;
    trialId: number;
    match: match<ITrialApiPageParams>;
    history: History;

    // From state
    trial: Dtos.Trial;
    trialRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];


    loadingTrial: boolean;
    loadTrialSuccess: boolean;
    loadTrialFailure: boolean;

    savingTrial: boolean;
    saveTrialSuccess: boolean;
    saveTrialFailure: boolean;

    creatingTrial: boolean;
    createTrialSuccess: boolean;
    createTrialFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface ITrialApiPageActions {
    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    saveTrial: TrialActions.ITrialSaveActionCreator;
    createTrial: TrialActions.ITrialCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type TrialApiPageProps = ITrialApiPageProps & ITrialApiPageActions;

const formName: string = "trial";
const reduxFormName: string = "reduxForms.trial";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class TrialApiPage extends React.PureComponent<TrialApiPageProps, any> {

    constructor(props: TrialApiPageProps) {
        super(props);

        this.clearTrial = this.clearTrial.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTrial,
            navigate,
            navigateReplace,
            trialId,
            createTrial
        } = this.props;

        if (trialId) {
            loadTrial(trialId);
        } else if (!trialId) {
            createTrial();
        }
    }

    componentWillReceiveProps(nextProps: TrialApiPageProps) {
    }

    componentDidUpdate(prevProps: TrialApiPageProps, prevState) {
        const {
            navigateReplace,
            trial,
            trialId,
            form,
            navigate,
            loadTrial,
            loadingTrial,
            loadTrialSuccess,
            createTrial,
            creatingTrial,
            createTrialSuccess,
            savingTrial,
            saveTrialSuccess,
            saveTrialFailure,
            validationFailures
        } = this.props;

        if (trialId && prevProps.trialId != trialId) {
            loadTrial(trialId);
        }
        else if (!trialId && prevProps.trialId) {
            createTrial();
        }

        if (!loadingTrial && prevProps.loadingTrial) {
            if (loadTrialSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingTrial && prevProps.savingTrial) {
            if (saveTrialSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/trials")
                } else if (!form.saveAndReturn && trialId && trialId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !trialId && trial && trial.id > 0) {
                    navigate("/trials/" + trial.id + "/api");
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingTrial && prevProps.creatingTrial) {
            if (createTrialSuccess) {
                this.loadForm();
            }
            else {

            }
        }
    }

    componentWillUpdate(nextProps: TrialApiPageProps) {
    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearTrial();
        loadForm(reduxFormName, initialTrialState);
        resetForm(reduxFormName);
    }

    clearTrial() {
        this.props.clearTrial();
    }

    showTrial(): boolean {
        const {
            loadingTrial,
            loadTrialSuccess
        } = this.props

        if (!loadingTrial && loadTrialSuccess) {
            return true;
        }

        return false;
    }

    render() {
        return (
            <TrialLayout loading={this.props.loadingTrial} permission={ Dtos.Permission.TrialApisView }>{this.renderContent()}</TrialLayout>
        );
    }

    renderContent() {
        const {
            form,
            loadingTrial,
            loadTrialSuccess,
            loadTrialFailure,
            clearTrial,
            navigate,
            permissions,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            trialRoles,
            savingTrial
        } = this.props;

        const canEdit: boolean = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialApisView);
        const formDisabled: boolean = !canEdit || savingTrial|| (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            registrationWebsite: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            openClinciaProtocolID: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            openClinciaInstitutionPrefix: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            patientDetailsCRF: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            initialsCrfItemName: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            treatmentCrfItemName: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            apiKey: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: true,
            },
        };



        return <div>
            <h1>Trial Api <small>(NewtonGreen use only)</small></h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "trial", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }


    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.Trial) {
        const {
            saveTrial
        } = this.props;

        if (data) {
            let changes: Dtos.Trial = {} as Dtos.Trial;
            saveTrial(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.Trial) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }


    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/trials");
    }

    loadForm() {
        const {
            loadForm,
            trial,
        } = this.props

        let extraData: Dtos.Trial = {} as Dtos.Trial;

        loadForm(reduxFormName, Object.assign({ ...trial }, extraData));
    }
}


const mapStateToProps = (state: ITmdState, ownProps: TrialApiPageProps): ITrialApiPageProps => {

    let trial: Dtos.Trial | undefined = !(state.trials.formData instanceof Array) ? state.trials.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialId: ownProps.match && ownProps.match.params.trialId && ownProps.match.params.trialId.toString() != "create" ? ownProps.match.params.trialId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trial: trial!,
        trialRoles: state.trials.formRoles,

        loadingTrial: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,
        loadTrialSuccess: state.trials.loadState && state.trials.loadState.status == RequestState.Success,
        loadTrialFailure: state.trials.loadState && state.trials.loadState.status == RequestState.Failure,

        savingTrial: state.trials.saveState && state.trials.saveState.status == RequestState.Pending,
        saveTrialSuccess: state.trials.saveState && state.trials.saveState.status == RequestState.Success,
        saveTrialFailure: state.trials.saveState && state.trials.saveState.status == RequestState.Failure,

        creatingTrial: state.trials.createState && state.trials.createState.status == RequestState.Pending,
        createTrialSuccess: state.trials.createState && state.trials.createState.status == RequestState.Success,
        createTrialFailure: state.trials.createState && state.trials.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: state.trials.lookups,

        form: state.reduxForms.trial,
        formState: state.trials.formState,
        formProperties: state.trials.formProperties,

        reduxFormState: state.reduxForms.formState.trial,
        validationFailures: trial ?
            state.trials.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): ITrialApiPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch),

        saveTrial: bindActionCreators(TrialActions.SaveTrial, dispatch),
        createTrial: bindActionCreators(TrialActions.CreateTrial, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialApiPage);
