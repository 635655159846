import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Tmd.dtos';

import {
    ACTION_OPENCLINICAUSERROLES_LOAD,
    ACTION_OPENCLINICAUSERROLES_LOAD_FAILURE,
    ACTION_OPENCLINICAUSERROLES_LOAD_SUCCESS,

    ACTION_OPENCLINICAUSERROLES_REBUILD,
    ACTION_OPENCLINICAUSERROLES_REBUILD_SUCCESS,
    ACTION_OPENCLINICAUSERROLES_REBUILD_FAILURE,

    ACTION_OPENCLINICAUSERROLES_CHANGESTATUS,
    ACTION_OPENCLINICAUSERROLES_CHANGESTATUS_SUCCESS,
    ACTION_OPENCLINICAUSERROLES_CHANGESTATUS_FAILURE,

    ACTION_OPENCLINICAUSERROLES_RESENDEMAIL,
    ACTION_OPENCLINICAUSERROLES_RESENDEMAIL_SUCCESS,
    ACTION_OPENCLINICAUSERROLES_RESENDEMAIL_FAILURE,


    ACTION_OPENCLINICAUSERROLES_IMPORT,
    ACTION_OPENCLINICAUSERROLES_IMPORT_FAILURE,
    ACTION_OPENCLINICAUSERROLES_IMPORT_SUCCESS,

    ACTION_OPENCLINICAUSERROLES_CLEAR,
    ACTION_OPENCLINICAUSERROLES_FILTER,

    OpenClinicaUserRolesAction
} from '../actions/openClinicaUserRoles';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState, IFormFilter } from "./index";
import { updateFailureIndexToId } from "../helpers/formErrorHelper";

export interface IOpenClinicaUserRoleState {
    formData: Dtos.OpenClinicaUserRole[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[],
    validationFailures: Dtos.ResponseError[];
    lookups: Dtos.Lookup[];
    loadState: IRequestState;
    runningState: IRequestState;
    importingState: IRequestState;
    resendingState: IRequestState;
    saveState: IRequestState;
    updateState: IRequestState;
    createState: IRequestState;
    formFilter: IFormFilter;
    formFilteredData: Dtos.OpenClinicaUserRole[];
    formLength: number;
}

const initialState: IOpenClinicaUserRoleState = {
    formData: undefined,
    formState: undefined,
    formProperties: undefined,
    formRoles: undefined,
    validationFailures: undefined,
    lookups: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    runningState: {
        status: RequestState.None
    } as IRequestState,
    importingState: {
        status: RequestState.None
    } as IRequestState,
    resendingState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    updateState: {
        status: RequestState.None
    } as IRequestState,
    createState: {
        status: RequestState.None
    } as IRequestState,
    formFilter: {
        search: "",
        showInActive: false
    },
    formFilteredData: [],
    formLength: 0
};

function loading(state: IOpenClinicaUserRoleState, isLoading: boolean): IOpenClinicaUserRoleState {
    const newState: IOpenClinicaUserRoleState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(state: IOpenClinicaUserRoleState,
    formData: Dtos.OpenClinicaUserRole | Dtos.OpenClinicaUserRole[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    formRoles: Dtos.RoleType[],
    lookups: Dtos.Lookup[],
    responseStatus: Dtos.ResponseStatus
): IOpenClinicaUserRoleState {
    const newState: IOpenClinicaUserRoleState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        formRoles: {
            $set: formRoles
        },
        lookups: {
            $set: lookups
        },
        loadState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return filter(newState, state.formFilter);
}

function filter(state: IOpenClinicaUserRoleState, formFilter: IFormFilter): IOpenClinicaUserRoleState {

    const items: Dtos.OpenClinicaUserRole[] | undefined =
        state.formData instanceof Array ?
            state.formData :
            undefined;

    const formLength = items ? items.length : 0;

    const formFilteredData = items ? items.filter(p => (p.active == true || formFilter.showInActive) &&
        (formFilter.search == "" || p.search.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1)) : undefined;

    const newState: IOpenClinicaUserRoleState = update(state, {
        formFilter: {
            $set: formFilter
        },
        formFilteredData: {
            $set: formFilteredData
        },
        formLength: {
            $set: formLength
        }
    });

    return newState;
}

function loadFailure(state: IOpenClinicaUserRoleState, responseStatus: Dtos.ResponseStatus): IOpenClinicaUserRoleState {
    const newState: IOpenClinicaUserRoleState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: IOpenClinicaUserRoleState, propertiesToClear?: string[]): IOpenClinicaUserRoleState {

    if (!propertiesToClear) {
        let formFilter = state.formFilter;
        const newState: IOpenClinicaUserRoleState = update(initialState, {
            formFilter: {
                $set: formFilter
            }
        });
        return newState;
    }

    let stateModifier: any = {};

    propertiesToClear.forEach(propertyToClear => {
        stateModifier[propertyToClear] = { $set: initialState[propertyToClear] }
    });

    const newState = update(state, stateModifier);

    return newState;
}

function saving(state: IOpenClinicaUserRoleState, isSaving: boolean): IOpenClinicaUserRoleState {
    const newState: IOpenClinicaUserRoleState = update(state, {
        saveState: {
            $set: {
                status: isSaving ? RequestState.Pending : RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function running(state: IOpenClinicaUserRoleState, isRunning: boolean): IOpenClinicaUserRoleState {
    const newState: IOpenClinicaUserRoleState = update(state, {
        runningState: {
            $set: {
                status: isRunning ? RequestState.Pending : RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function runningFailure(state: IOpenClinicaUserRoleState, responseStatus: Dtos.ResponseStatus): IOpenClinicaUserRoleState {
    const newState: IOpenClinicaUserRoleState = update(state, {
        runningState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                meta: responseStatus.meta
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, state.formData) : undefined
        }
    });

    return newState;
}

function saveFailure(state: IOpenClinicaUserRoleState, responseStatus: Dtos.ResponseStatus): IOpenClinicaUserRoleState {
    const newState: IOpenClinicaUserRoleState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                meta: responseStatus.meta
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, state.formData) : undefined
        }
    });

    return newState;
}

function resending(state: IOpenClinicaUserRoleState, isRunning: boolean): IOpenClinicaUserRoleState {
    const newState: IOpenClinicaUserRoleState = update(state, {
        resendingState: {
            $set: {
                status: isRunning ? RequestState.Pending : RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function resendingFailure(state: IOpenClinicaUserRoleState, responseStatus: Dtos.ResponseStatus): IOpenClinicaUserRoleState {
    const newState: IOpenClinicaUserRoleState = update(state, {
        resendingState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                meta: responseStatus.meta
            } as IRequestState
        }
    });

    return newState;
}

function importing(state: IOpenClinicaUserRoleState, isLoading: boolean): IOpenClinicaUserRoleState {
    const newState: IOpenClinicaUserRoleState = update(state, {
        importingState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function importSuccess(state: IOpenClinicaUserRoleState,
    responseStatus: Dtos.ResponseStatus
): IOpenClinicaUserRoleState {
    const newState: IOpenClinicaUserRoleState = update(state, {
        importingState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        }
    });

    return filter(newState, state.formFilter);
}

function importFailure(state: IOpenClinicaUserRoleState, responseStatus: Dtos.ResponseStatus): IOpenClinicaUserRoleState {
    const newState: IOpenClinicaUserRoleState = update(state, {
        importingState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}


const OpenClinicaUserRoleReducer: Reducer<IOpenClinicaUserRoleState> = (state: IOpenClinicaUserRoleState = initialState, action: OpenClinicaUserRolesAction) => {
    switch (action.type) {
        case ACTION_OPENCLINICAUSERROLES_LOAD:
            return loading(state, true);
        case ACTION_OPENCLINICAUSERROLES_LOAD_SUCCESS:
            return loadSuccess(state, action.formData, action.formState, action.formProperties, action.formRoles, action.lookups, action.responseStatus);
       case ACTION_OPENCLINICAUSERROLES_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_OPENCLINICAUSERROLES_CLEAR:
            return clear(state, action.propertiesToClear);
        case ACTION_OPENCLINICAUSERROLES_REBUILD:
            return running(state, true);
        case ACTION_OPENCLINICAUSERROLES_REBUILD_SUCCESS:
            return running(state, false);
        case ACTION_OPENCLINICAUSERROLES_REBUILD_FAILURE:
            return runningFailure(state, action.responseStatus);
        case ACTION_OPENCLINICAUSERROLES_CHANGESTATUS:
            return saving(state, true);
        case ACTION_OPENCLINICAUSERROLES_CHANGESTATUS_SUCCESS:
            return saving(state, false);
        case ACTION_OPENCLINICAUSERROLES_CHANGESTATUS_FAILURE:
            return saveFailure(state, action.responseStatus);
        case ACTION_OPENCLINICAUSERROLES_FILTER:
            return filter(state, action.formFilter);
        case ACTION_OPENCLINICAUSERROLES_RESENDEMAIL:
            return resending(state, true);
        case ACTION_OPENCLINICAUSERROLES_RESENDEMAIL_SUCCESS:
            return resending(state, false);
        case ACTION_OPENCLINICAUSERROLES_RESENDEMAIL_FAILURE:
            return resendingFailure(state, action.responseStatus);
        case ACTION_OPENCLINICAUSERROLES_IMPORT:
            return importing(state, true);
        case ACTION_OPENCLINICAUSERROLES_IMPORT_SUCCESS:
            return importSuccess(state, action.responseStatus);
        case ACTION_OPENCLINICAUSERROLES_IMPORT_FAILURE:
            return importFailure(state, action.responseStatus);
    }

    return state;
}

export default OpenClinicaUserRoleReducer;