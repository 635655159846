import * as React from 'react';

import * as classNames from "classnames";
import './PersonnelHubspotPage.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";



import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu, TruncatedCell } from "../../../components/layouts/index";
import { IHubspotReduxFormState, initialHubspotState } from "../../../reducers/reactReduxForms/hubspot";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";

import * as HubspotActions from '../../../actions/hubspot';
import * as ToggleActions from '../../../actions/pages/togglePage';
import * as ToggleHelper from "../../../helpers/toggleHelper";
import { TogglePageItem } from '../../../reducers/pages/togglePage';

import * as PagingActions from '../../../actions/pages/pagingPage';

import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../../components/form';
import { PrimitiveFormDesigner, PrimitiveFormInput } from '../../../components/form/index';
import { SettingsSubMenu, PersonnelSubMenu } from '../../../helpers/subMenuHelper';

interface IPersonnelHubspotPageParams {
    personnelId: number;
}

interface IPersonnelHubspotPageProps {

    form: Dtos.Hubspot & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IHubspotReduxFormState;

    location: Location;
    personnelId: number;
    match: match<IPersonnelHubspotPageParams>;
    history: History;

    // From state
    hubspot: Dtos.Hubspot;
    lookups: Dtos.Lookup[];

    loading: boolean;
    loadSuccess: boolean;
    loadFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];
    formRoles: Dtos.RoleType[],

    toggleItems: TogglePageItem[],

    pageSize: number
}

interface IPersonnelHubspotPageActions {
    clearHubspot: HubspotActions.IHubspotClearActionCreator;
    loadHubspot: HubspotActions.IHubspotLoadByIdActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    toggleExpand: ToggleActions.IToggleExpandedStateActionCreator;

    setPageSize: PagingActions.IPagingPageSizeChangedStateActionCreator;
}

type PersonnelHubspotPageProps = IPersonnelHubspotPageProps & IPersonnelHubspotPageActions;


const formName: string = "hubspot";
const reduxFormName: string = "reduxForms.hubspot";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class PersonnelHubspotPage extends React.PureComponent<PersonnelHubspotPageProps, any> {

    constructor(props: PersonnelHubspotPageProps) {
        super(props);

        this.clearHubspot = this.clearHubspot.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.handleOnSubmitFailed = this.handleOnSubmitFailed.bind(this);
        this.onOpen = this.onOpen.bind(this);
    }

    componentDidMount() {
        const {
            loadHubspot,
            navigate,
            navigateReplace,
            personnelId
        } = this.props;

        if (personnelId) {
            loadHubspot(personnelId);
        } else if (!personnelId) {
            this.onCancel();
        }
    }

    componentWillReceiveProps(nextProps: PersonnelHubspotPageProps) {
    }

    componentDidUpdate(prevProps: PersonnelHubspotPageProps, prevState) {
        const {
            navigateReplace,
            hubspot,
            personnelId,
            form,
            navigate,
            loadHubspot,
            loading,
            loadSuccess,
            validationFailures
        } = this.props;

        if (personnelId && prevProps.personnelId != personnelId) {
            loadHubspot(personnelId);
        }
        else if (!personnelId && prevProps.personnelId) {
            this.onCancel();
        }

        if (!loading && prevProps.loading) {
            if (loadSuccess) {
                this.loadForm();
            }
        }

    }

    componentWillUpdate(nextProps: PersonnelHubspotPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearHubspot();
        loadForm(reduxFormName, initialHubspotState);
        resetForm(reduxFormName);
    }

    clearHubspot() {
        this.props.clearHubspot();
    }

    showHubspot(): boolean {
        const {
            loading,
            loadSuccess
        } = this.props

        if (!loading && loadSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            form,
            loading,
            personnelId,
            hubspot,
            formRoles,
            permissions
        } = this.props;

        return <RestrictedLayout
            permission={Dtos.Permission.PersonnelHubspotView}
            subMenuTitle={hubspot && hubspot.personnel ? hubspot.personnel.display : ""}
            subMenuItems={personnelId == undefined ? undefined : PersonnelSubMenu(personnelId, permissions)}
            loading={loading}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            form,
            hubspot,
            loading,
            navigate,
            user,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm
        } = this.props;

        const formDisabled: boolean = (true || formState && formState.formStatus == Dtos.FormStatus.Disabled);
        let formComponents: { [index: string]: RegistrationFormComponent } = {
            detailsGroup: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="form-group row pt-4 pb-4 border border-bottom-1 border-top-0">
                    {hubspot && hubspot.data && hubspot.data.map((item, index) => {
                        return <div className="col-md-6">
                            <div className="row bottom-0 border-top-0 pb-0 pt-4">
                                <label className="col-form-label col-sm-6">{item.name}</label>
                                <div className="col-sm-6 generic-form-field">
                                    <div style={{ position: 'relative' }}>
                                        <input readOnly disabled type="text" className="form-control" value={item.value} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            }
        };

        return <div>
            <div className="row">
                <div className={"col-9 mb-2"}>
                    <h2>Hubspot Details</h2>
                </div>
                <div className="col-3" style={{ textAlign: 'right' }}>

                </div>
            </div>
            {
                loading ?
                    "Loading Hubspot details..." :
                    null
            }
            {
                !loading && hubspot ?
                    <div className="mt-2">
                        {!hubspot.hasHubspotRecord && (
                            <div className="alert alert-info d-flex">
                                <div className="mr-2">
                                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                                </div>
                                <span>There is currently no Hubspot record to view.</span>
                            </div>
                        )}
                        {hubspot.hasHubspotRecord && (
                            <Form model={reduxFormName}
                                onSubmit={(val, event) => this.handleOnSubmit(val)}
                                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
                            >
                                {
                                    renderFormControls(form, "hubspot", formComponents, lookups, validationFailures, formProperties, changeForm)
                                }
                                <div className="mt-4 mb-4 text-right">
                                    <a target="_blank" href={form.link} className="btn btn-warning mr-2" > Open in Hubspot </a>
                                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Close </button>
                                </div>
                            </Form>
                        )}
                    </div> :
                    null
            }
        </div>

    }

    toggle(personnelRoleSummary: Dtos.PersonnelRoleSummary) {

    }

    onOpen() {

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.Personnel) {
        //const {
        //    savePersonnel
        //} = this.props;

        //if (data) {
        //    let changes: Dtos.Personnel = {} as Dtos.Personnel;
        //    savePersonnel(Object.assign({ ...data }, changes));
        //}
    }


    handleOnSubmitFailed(data: Dtos.Personnel) {
        //Alert.error(<NotificationAlert
        //    minWidth={500}
        //    alertContent={renderRegistrationFormSaveBlock()}
        //    icon={FontAwesomeIcons.Solid.BAN}
        ///>);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/personnel");
    }

    loadForm() {
        const {
            loadForm,
            hubspot,
        } = this.props

        let extraData: Dtos.Hubspot = {} as Dtos.Hubspot;

        loadForm(reduxFormName, Object.assign({ ...hubspot }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: PersonnelHubspotPageProps): IPersonnelHubspotPageProps => {

    let hubspot: Dtos.Hubspot | undefined = !(state.hubspot.formData instanceof Array) ? state.hubspot.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        personnelId: ownProps.match && ownProps.match.params.personnelId.toString() != "create" ? ownProps.match.params.personnelId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        hubspot: hubspot!,

        loading: state.hubspot.loadState && state.hubspot.loadState.status == RequestState.Pending,
        loadSuccess: state.hubspot.loadState && state.hubspot.loadState.status == RequestState.Success,
        loadFailure: state.hubspot.loadState && state.hubspot.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles,
        lookups: [],

        form: state.reduxForms.hubspot,
        formState: state.hubspot.formState,
        formProperties: state.hubspot.formProperties,

        reduxFormState: state.reduxForms.formState.hubspot,
        validationFailures: hubspot ?
            state.personnel.validationFailures :
            undefined,

        toggleItems: state.togglePage.items,

        pageSize: state.pagingPage.pageSize
    };
};

const mapDispatchToProps = (dispatch): IPersonnelHubspotPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadHubspot: bindActionCreators(HubspotActions.LoadHubspotById, dispatch),
        clearHubspot: bindActionCreators(HubspotActions.Clear, dispatch),

        toggleExpand: bindActionCreators(ToggleActions.ToggleExpanded, dispatch),

        setPageSize: bindActionCreators(PagingActions.PageSizeChanged, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(PersonnelHubspotPage);
