import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadTemplateTrialItem: ITemplateTrialItemLoadActionCreator = () => {
        return {
            type: ACTION_TEMPLATETRIALITEMS_LOAD
        }
    },
    LoadTemplateTrialItemByCategory: ITemplateTrialItemLoadByCategoryActionCreator = () => {
        return {
            type: ACTION_TEMPLATETRIALITEMS_LOAD_BY_CATEGORY
        }
    },
    LoadTemplateTrialItemById: ITemplateTrialItemLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TEMPLATETRIALITEMS_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ITemplateTrialItemLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_TEMPLATETRIALITEMS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITemplateTrialItemLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TEMPLATETRIALITEMS_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTemplateTrialItem: ITemplateTrialItemSaveActionCreator = (formData, collectionFormData) => {
        return {
            type: ACTION_TEMPLATETRIALITEMS_SAVE,
            formData,
            collectionFormData
        }
    },
    SaveSuccess: ITemplateTrialItemSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TEMPLATETRIALITEMS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITemplateTrialItemSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TEMPLATETRIALITEMS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTemplateTrialItem: ITemplateTrialItemCreateActionCreator = () => {

        return {
            type: ACTION_TEMPLATETRIALITEMS_CREATE
        }
    },
    CreateSuccess: ITemplateTrialItemCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_TEMPLATETRIALITEMS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: ITemplateTrialItemCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TEMPLATETRIALITEMS_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: ITemplateTrialItemClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TEMPLATETRIALITEMS_CLEAR,
            propertiesToClear
        }
    },
    Filter: ITemplateTrialItemFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TEMPLATETRIALITEMS_FILTER,
            formFilter
        }
    };



export const
    ACTION_TEMPLATETRIALITEMS_LOAD = "ACTION_TEMPLATETRIALITEMS_LOAD",
    ACTION_TEMPLATETRIALITEMS_LOAD_BY_CATEGORY = "ACTION_TEMPLATETRIALITEMS_LOAD_BY_CATEGORY",
    ACTION_TEMPLATETRIALITEMS_LOAD_BY_ID = "ACTION_TEMPLATETRIALITEMS_LOAD_BY_ID",
    ACTION_TEMPLATETRIALITEMS_LOAD_SUCCESS = "ACTION_TEMPLATETRIALITEMS_LOAD_SUCCESS",
    ACTION_TEMPLATETRIALITEMS_LOAD_FAILURE = "ACTION_TEMPLATETRIALITEMS_LOAD_FAILURE",

    ACTION_TEMPLATETRIALITEMS_SAVE = "ACTION_TEMPLATETRIALITEMS_SAVE",
    ACTION_TEMPLATETRIALITEMS_SAVE_SUCCESS = "ACTION_TEMPLATETRIALITEMS_SAVE_SUCCESS",
    ACTION_TEMPLATETRIALITEMS_SAVE_FAILURE = "ACTION_TEMPLATETRIALITEMS_SAVE_FAILURE",

    ACTION_TEMPLATETRIALITEMS_CREATE = "ACTION_TEMPLATETRIALITEMS_CREATE",
    ACTION_TEMPLATETRIALITEMS_CREATE_SUCCESS = "ACTION_TEMPLATETRIALITEMS_CREATE_SUCCESS",
    ACTION_TEMPLATETRIALITEMS_CREATE_FAILURE = "ACTION_TEMPLATETRIALITEMS_CREATE_FAILURE",

    ACTION_TEMPLATETRIALITEMS_CLEAR = "ACTION_TEMPLATETRIALITEMS_CLEAR",

    ACTION_TEMPLATETRIALITEMS_FILTER = "ACTION_TEMPLATETRIALITEMS_FILTER";

export interface ITemplateTrialItemLoadAction {
    type: "ACTION_TEMPLATETRIALITEMS_LOAD";
}

export interface ITemplateTrialItemLoadActionCreator {
    (): ITemplateTrialItemLoadAction;
}

export interface ITemplateTrialItemLoadByCategoryAction {
    type: "ACTION_TEMPLATETRIALITEMS_LOAD_BY_CATEGORY";
}

export interface ITemplateTrialItemLoadByCategoryActionCreator {
    (): ITemplateTrialItemLoadByCategoryAction;
}

export interface ITemplateTrialItemLoadSuccessAction {
    type: "ACTION_TEMPLATETRIALITEMS_LOAD_SUCCESS";
    formData: Dtos.TemplateTrialItem | Dtos.TemplateTrialItemCategory[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITemplateTrialItemLoadSuccessActionCreator {
    (
        formData: Dtos.TemplateTrialItem | Dtos.TemplateTrialItemCategory[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITemplateTrialItemLoadSuccessAction;
}

export interface ITemplateTrialItemLoadFailureAction {
    type: "ACTION_TEMPLATETRIALITEMS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITemplateTrialItemLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITemplateTrialItemLoadFailureAction;
}

export interface ITemplateTrialItemLoadByIdAction {
    type: "ACTION_TEMPLATETRIALITEMS_LOAD_BY_ID";
    id: number;
}

export interface ITemplateTrialItemLoadByIdActionCreator {
    (id: number): ITemplateTrialItemLoadByIdAction;
}


export interface ITemplateTrialItemSaveAction {
    type: "ACTION_TEMPLATETRIALITEMS_SAVE";
    formData: Dtos.TemplateTrialItem;
    collectionFormData: Dtos.Collection;

}

export interface ITemplateTrialItemSaveActionCreator {
    (
        formData: Dtos.TemplateTrialItem,
        collectionFormData: Dtos.Collection
    ): ITemplateTrialItemSaveAction;
}

export interface ITemplateTrialItemSaveSuccessAction {
    type: "ACTION_TEMPLATETRIALITEMS_SAVE_SUCCESS";
    formData: Dtos.TemplateTrialItem;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITemplateTrialItemSaveSuccessActionCreator {
    (
        formData: Dtos.TemplateTrialItem,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITemplateTrialItemSaveSuccessAction;
}

export interface ITemplateTrialItemSaveFailureAction {
    type: "ACTION_TEMPLATETRIALITEMS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITemplateTrialItemSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITemplateTrialItemSaveFailureAction;
}

export interface ITemplateTrialItemCreateAction {
    type: "ACTION_TEMPLATETRIALITEMS_CREATE"
}

export interface ITemplateTrialItemCreateActionCreator {
    (): ITemplateTrialItemCreateAction
}

export interface ITemplateTrialItemCreateSuccessAction {
    type: "ACTION_TEMPLATETRIALITEMS_CREATE_SUCCESS";
    formData: Dtos.TemplateTrialItem;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface ITemplateTrialItemCreateSuccessActionCreator {
    (
        formData: Dtos.TemplateTrialItem,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): ITemplateTrialItemCreateSuccessAction;
}

export interface ITemplateTrialItemCreateFailureAction {
    type: "ACTION_TEMPLATETRIALITEMS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITemplateTrialItemCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITemplateTrialItemCreateFailureAction;
}

export interface ITemplateTrialItemClearAction {
    type: "ACTION_TEMPLATETRIALITEMS_CLEAR";
    propertiesToClear?: string[];
}

export interface ITemplateTrialItemClearActionCreator {
    (propertiesToClear?: string[]): ITemplateTrialItemClearAction;
}

export interface ITemplateTrialItemFilterActionCreator {
    (formFilter: IFormFilter): ITemplateTrialItemFilterAction;
}

export interface ITemplateTrialItemFilterAction {
    type: "ACTION_TEMPLATETRIALITEMS_FILTER";
    formFilter: IFormFilter;
}

export type TemplateTrialItemAction =
    ITemplateTrialItemLoadAction |
    ITemplateTrialItemLoadByCategoryAction |
    ITemplateTrialItemLoadByIdAction |
    ITemplateTrialItemLoadFailureAction |
    ITemplateTrialItemLoadSuccessAction |
    ITemplateTrialItemSaveAction |
    ITemplateTrialItemSaveSuccessAction |
    ITemplateTrialItemSaveFailureAction |
    ITemplateTrialItemCreateAction |
    ITemplateTrialItemCreateSuccessAction |
    ITemplateTrialItemCreateFailureAction |
    ITemplateTrialItemClearAction |
    ITemplateTrialItemFilterAction;
