import * as React from 'react';
import './TrialPhasesPage.scss';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { RowInfo } from 'react-table';
import * as TrialPhaseActions from '../../../actions/trialPhases';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { FormFilterInput } from '../../../components/form';
import { TrialLookupsLayout } from '../../index';

interface ITrialPhasesPageProps {

    location: Location;
    history: History;

    // From state
    formFilter: IFormFilter;
    trialPhase: Dtos.TrialPhase[];
    loadingTrialPhases: boolean;
    loadTrialPhasesSuccess: boolean;
    loadTrialPhasesFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    formRoles?: Dtos.RoleType[];
}

interface ITrialPhasesPageActions {
    clearTrialPhases: TrialPhaseActions.ITrialPhaseClearActionCreator;
    loadTrialPhases: TrialPhaseActions.ITrialPhaseLoadActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    filter: TrialPhaseActions.ITrialPhaseFilterActionCreator;
}

type TrialPhasesPageProps = ITrialPhasesPageProps & ITrialPhasesPageActions;

class TrialPhasesPage extends React.PureComponent<TrialPhasesPageProps, any> {

    constructor(props: TrialPhasesPageProps) {
        super(props);

        this.clearTrialPhases = this.clearTrialPhases.bind(this);
    }

    componentDidMount() {
        const {
            loadTrialPhases
        } = this.props;

        loadTrialPhases();
    }

    componentWillReceiveProps(nextProps: TrialPhasesPageProps) {
    }

    componentDidUpdate(prevProps: TrialPhasesPageProps, prevState) {
        const {
            navigateReplace
        } = this.props
    }

    componentWillUpdate(nextProps: TrialPhasesPageProps) {
    }

    componentWillUnmount() {
        this.clearTrialPhases();
    }

    clearTrialPhases() {
        this.props.clearTrialPhases();
    }

    showTrialPhases(): boolean {
        const {
            loadingTrialPhases,
            loadTrialPhasesSuccess
        } = this.props

        if (!loadingTrialPhases && loadTrialPhasesSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrialPhases
        } = this.props;

        return <TrialLookupsLayout loading={loadingTrialPhases} permission={Dtos.Permission.TrialPhasesView}>
            {this.renderContent()}
        </TrialLookupsLayout>;
    }

    renderContent() {
        const {
            permissions
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-6 mb-2"}>
                    <h2>Trial Phases</h2>
                </div>
                <div className="col-6" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e) } />
                    
                    {AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialPhasesCreate) && (
                        <Link className={"btn btn-primary"} url={"/settings/trial/trialphases/create"}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.showTrialPhases() ?
                    "Loading Trial Phases" :
                    null
            }
            {
                this.showTrialPhases() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {

        const {
            trialPhase
        } = this.props

        if (!trialPhase || trialPhase.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no trial phases to view.</span>
            </div>
        }

        const showPagination = trialPhase.length > DEFAULT_PAGE_SIZE;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={trialPhase}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : trialPhase.length}
                pageSize={showPagination ? undefined : trialPhase.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no trialPhase to display"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {

                            const url: string = "/settings/trial/trialphases/" + rowInfo.original.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.LOOKUP} fixedWidth />
                    },
                    {
                        id: "value",
                        Header: 'Name',
                        accessor: 'value',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    }
                ]}
            />
        </div>

    }

}

const mapStateToProps = (state: ITmdState, ownProps: TrialPhasesPageProps): ITrialPhasesPageProps => {

    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        history: ownProps.history,
        location: state.routing.location,

        formFilter: state.trialPhases.formFilter,
        trialPhase: state.trialPhases.formFilteredData, 

        loadingTrialPhases: state.trialPhases.loadState && state.trialPhases.loadState.status == RequestState.Pending,
        loadTrialPhasesSuccess: state.trialPhases.loadState && state.trialPhases.loadState.status == RequestState.Success,
        loadTrialPhasesFailure: state.trialPhases.loadState && state.trialPhases.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles
    };
};

const mapDispatchToProps = (dispatch): ITrialPhasesPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadTrialPhases: bindActionCreators(TrialPhaseActions.LoadTrialPhase, dispatch),
        clearTrialPhases: bindActionCreators(TrialPhaseActions.Clear, dispatch),

        filter: bindActionCreators(TrialPhaseActions.Filter, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialPhasesPage);
