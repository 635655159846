import * as React from 'react';
import './PersonnelByRolesPage.scss';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { RowInfo } from 'react-table';
import { RestrictedLayout } from "../../../components/layouts/index";
import * as PersonnelActions from '../../../actions/personnel';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { FormFilterInput } from '../../../components/form';
import * as ToggleActions from '../../../actions/pages/togglePage';
import * as ToggleHelper from "../../../helpers/toggleHelper";
import { TogglePageItem } from '../../../reducers/pages/togglePage';
import { Modal } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { ModalSize } from '../../../enumerations/ModalSize';


interface IPersonnelByRolesPageProps {
    location: Location;
    history: History;

    // From state
    rolePersonnelGrouping: Dtos.RolePersonnelGrouping[];
    formFilter: IFormFilter;

    loadingPersonnel: boolean;
    loadPersonnelSuccess: boolean;
    loadPersonnelFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    toggleItems: TogglePageItem[];
}

interface IPersonnelByRolesPageActions {
    loadPersonnelByRole: PersonnelActions.IPersonnelByRoleLoadActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    filter: PersonnelActions.IPersonnelFilterByRoleActionCreator;

    toggleExpand: ToggleActions.IToggleExpandedStateActionCreator;
}

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}

type PersonnelByRolesPageProps = IPersonnelByRolesPageProps & IPersonnelByRolesPageActions;

class PersonnelByRolesPage extends React.PureComponent<PersonnelByRolesPageProps, any> {

    constructor(props: PersonnelByRolesPageProps) {
        super(props);

        this.toggleExportMenu = this.toggleExportMenu.bind(this);

        this.state = {
            exportToggle: false
        };
    }

    componentDidMount() {
        const {
            loadPersonnelByRole,
        } = this.props;

        loadPersonnelByRole();
    }

    showPersonnel(): boolean {
        const {
            loadingPersonnel,
            loadPersonnelSuccess
        } = this.props

        if (!loadingPersonnel && loadPersonnelSuccess) {
            return true;
        }

        return false;
    }

    toggleExportMenu() {
        this.setState({
            exportToggle: !this.state.exportToggle
        });
    }

    render() {
        const {
            loadingPersonnel
        } = this.props;

        return <RestrictedLayout
            permission={Dtos.Permission.PersonnelView}
            loading={loadingPersonnel }
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            permissions
        } = this.props;
        
        return <div>
            <div className="row">
                <div className={"col col-auto mb-2"}>
                    <h2>Personnel by Roles</h2>
                </div>
                <div className="col" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} hideActiveToggle={true} />
                    {AuthenticationHelper.hasPermission(permissions, Dtos.Permission.PersonnelUpdate) && (
                        <div className="btn-group">
                            <button type="button" className="btn btn-info" onClick={() => this.toggleExportMenu()}>
                                <FontAwesomeIcon icon={FontAwesomeIcons.Solid.CLOUD_DOWNLOAD} fixedWidth />
                            </button>
                            <Modal open={this.state.exportToggle} disableCloseOnOverlayClick={true} onClose={() => { this.toggleExportMenu() }} size={ModalSize.Md}>
                                <div className="modal-header">
                                    <h5 className="modal-title">Download</h5>
                                    <button type="button" className="close" aria-label="Close" onClick={() => { this.toggleExportMenu() }}>
                                        <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                                    </button>
                                </div>
                                <div className="modal-body p-3">
                                    <a className="btn btn-outline-primary btn-block mt-3"
                                        onClick={() => this.toggleExportMenu()}
                                        href={encodeURI("/api/personnelrole/export/0/0?groupByRole=1&filter=" + this.props.formFilter.search + "&showInactive=" + this.props.formFilter.showInActive)}
                                    ><b>Personnel Roles List</b><br /><small>The listed personnel with roles including trials and trial institutions as a CSV file</small></a>
                                </div>
                            </Modal>
                        </div>
                    )}
                    <Link className={"btn btn-secondary ml-2"} url={"/personnel"}>Show List</Link>
                </div>
            </div>
            {
                !this.showPersonnel() ?
                    "Loading Personnel by Roles" :
                    null
            }
            {
                this.showPersonnel() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {
        const {
            rolePersonnelGrouping,
            toggleItems,
            toggleExpand
        } = this.props

        if (!rolePersonnelGrouping || rolePersonnelGrouping.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no records to view.</span>
            </div>
        }

        return <div>
            {rolePersonnelGrouping && rolePersonnelGrouping.map((item, index) => {
                const key = "personnel-by-roles-page-" + item.roleId + "-" + item.roleRank;
                const expanded = ToggleHelper.isToggleExpanded(toggleItems, key);
                return (
                    <div key={"parent-category" + index}>
                        <div className={tableHeaderClass} style={tableStyleClass} onClick={() => toggleExpand(key)}>
                            <div>
                                <FontAwesomeIcon icon={!expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" />
                            </div>
                            <span>{item.roleName}</span>
                        </div>
                        {expanded && (
                            this.renderRolePersonnel(item.rolePersonnels)
                        )}
                    </div>
                )
            }
            )}
        </div>
    }

    renderRolePersonnel(rolePersonnel: Dtos.RolePersonnel[]) {
        
        return (
            <ReactTable
                data={rolePersonnel}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={rolePersonnel.length}
                pageSize={rolePersonnel.length}
                showPagination={false}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no personnel for this role"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log(rowInfo);
                            const url: string = "/personnel/" + rowInfo.original.personnelId;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }
                        }
                    }
                }}
                columns={
                    [
                        {
                            id: "id",
                            Header: ' ',
                            accessor: "id",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 50,
                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.USER} fixedWidth />
                        },
                        {
                            id: "personnelDisplay",
                            Header: "Personnel",
                            accessor: "personnelDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "isGlobal",
                            Header: "Global",
                            accessor: "isGlobal",
                            className: "d-flex align-items-left justify-content-center",
                            headerClassName: "text-center",
                            maxWidth: 100,
                            Cell: (props) => props.value ? <FontAwesomeIcon icon={FontAwesomeIcons.Light.CHECK} fixedWidth /> : <></>
                        },
                        {
                            id: "trialDisplay",
                            Header: "Trial",
                            accessor: "trialDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "institutionDisplay",
                            Header: "Institution",
                            accessor: "institutionDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        }
                    ]
                }
            />
        );
    }

}

const mapStateToProps = (state: ITmdState, ownProps: PersonnelByRolesPageProps): IPersonnelByRolesPageProps => {

    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        history: ownProps.history,
        location: state.routing.location,

        rolePersonnelGrouping: state.personnel.rolePersonnelGroupingsFiltered!,
        formFilter: state.personnel.formFilterByRole,

        loadingPersonnel: state.personnel.loadState && state.personnel.loadState.status == RequestState.Pending,
        loadPersonnelSuccess: state.personnel.loadState && state.personnel.loadState.status == RequestState.Success,
        loadPersonnelFailure: state.personnel.loadState && state.personnel.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,

        toggleItems: state.togglePage.items
    };
};

const mapDispatchToProps = (dispatch): IPersonnelByRolesPageActions => {
    return {
        loadPersonnelByRole: bindActionCreators(PersonnelActions.LoadPersonnelByRole, dispatch),

        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),


        filter: bindActionCreators(PersonnelActions.FilterByRole, dispatch),

        toggleExpand: bindActionCreators(ToggleActions.ToggleExpanded, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(PersonnelByRolesPage);
