import './SearchInput.scss';
import * as React from "react";

export interface ISearchInputProps {
    defaultSearch: string;
    label?: string;
    onSearch: (search: string) => void;
}

class SearchInput extends React.PureComponent<ISearchInputProps, any> {

    constructor(props: ISearchInputProps) {
        super(props);

        this.state = {
            search: this.props.defaultSearch
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.defaultSearch != this.props.defaultSearch) {
            this.setState({ search: this.props.defaultSearch });
        }
    }

    render() {
        const {
            label,
            onSearch,
        } = this.props;

        let self = this;

        return <div className="generic-form-field">
            <div className="input-group">
                <input type="text" placeholder={((label ? label : "Search") + "...")} className="form-control" style={{ width: "320px" }}
                    name="filter"
                    id="filter.search"
                    value={this.state.search}
                    onChange={e => {
                        self.setState({ search: e.target.value });
                    }} />
                <div className="input-group-append">
                    <button
                        onClick={() => onSearch(this.state.search)}
                        type="button"
                        className="btn btn-secondary"
                    >
                        {(label ? label : "Search")}
                    </button>
                </div>
            </div>
        </div>;
    }
}

export default SearchInput;