import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadTrialItemById: ITrialItemLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TRIALITEM_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ITrialItemLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALITEM_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrialItemLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALITEM_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTrialItem: ITrialItemSaveActionCreator = (formData, collectionFormData) => {
        return {
            type: ACTION_TRIALITEM_SAVE,
            formData,
            collectionFormData
        }
    },
    SaveSuccess: ITrialItemSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRIALITEM_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrialItemSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALITEM_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTrialItem: ITrialItemCreateActionCreator = (trialId) => {

        return {
            type: ACTION_TRIALITEM_CREATE,
            trialId
        }
    },
    CreateSuccess: ITrialItemCreateSuccessActionCreator = (formData, formState, formProperties, lookups) => {
        return {
            type: ACTION_TRIALITEM_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups
        }
    },
    CreateFailure: ITrialItemCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALITEM_CREATE_FAILURE,
            responseStatus
        }
    },

    Clear: ITrialItemClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRIALITEM_CLEAR,
            propertiesToClear
        }
    },
    Filter: ITrialItemFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRIALITEM_FILTER,
            formFilter
        }
    };



export const
    ACTION_TRIALITEM_LOAD_BY_ID = "ACTION_TRIALITEM_LOAD_BY_ID",
    ACTION_TRIALITEM_LOAD_SUCCESS = "ACTION_TRIALITEM_LOAD_SUCCESS",
    ACTION_TRIALITEM_LOAD_FAILURE = "ACTION_TRIALITEM_LOAD_FAILURE",

    ACTION_TRIALITEM_SAVE = "ACTION_TRIALITEM_SAVE",
    ACTION_TRIALITEM_SAVE_SUCCESS = "ACTION_TRIALITEM_SAVE_SUCCESS",
    ACTION_TRIALITEM_SAVE_FAILURE = "ACTION_TRIALITEM_SAVE_FAILURE",

    ACTION_TRIALITEM_CREATE = "ACTION_TRIALITEM_CREATE",
    ACTION_TRIALITEM_CREATE_SUCCESS = "ACTION_TRIALITEM_CREATE_SUCCESS",
    ACTION_TRIALITEM_CREATE_FAILURE = "ACTION_TRIALITEM_CREATE_FAILURE",

    ACTION_TRIALITEM_FILTER = "ACTION_TRIALITEM_FILTER",

    ACTION_TRIALITEM_CLEAR = "ACTION_TRIALITEM_CLEAR";


export interface ITrialItemLoadSuccessAction {
    type: "ACTION_TRIALITEM_LOAD_SUCCESS";
    formData: Dtos.TrialItem;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialItemLoadSuccessActionCreator {
    (
        formData: Dtos.TrialItem,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialItemLoadSuccessAction;
}

export interface ITrialItemLoadFailureAction {
    type: "ACTION_TRIALITEM_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialItemLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialItemLoadFailureAction;
}

export interface ITrialItemLoadByIdAction {
    type: "ACTION_TRIALITEM_LOAD_BY_ID";
    id: number;
}

export interface ITrialItemLoadByIdActionCreator {
    (id: number): ITrialItemLoadByIdAction;
}


export interface ITrialItemSaveAction {
    type: "ACTION_TRIALITEM_SAVE";
    formData: Dtos.TrialItem;
    collectionFormData: Dtos.Collection;

}

export interface ITrialItemSaveActionCreator {
    (
        formData: Dtos.TrialItem,
        collectionFormData: Dtos.Collection
    ): ITrialItemSaveAction;
}

export interface ITrialItemSaveSuccessAction {
    type: "ACTION_TRIALITEM_SAVE_SUCCESS";
    formData: Dtos.TrialItem;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialItemSaveSuccessActionCreator {
    (
        formData: Dtos.TrialItem,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrialItemSaveSuccessAction;
}

export interface ITrialItemSaveFailureAction {
    type: "ACTION_TRIALITEM_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialItemSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialItemSaveFailureAction;
}

export interface ITrialItemCreateAction {
    type: "ACTION_TRIALITEM_CREATE",
    trialId: number
}

export interface ITrialItemCreateActionCreator {
    (trialId: number): ITrialItemCreateAction
}

export interface ITrialItemCreateSuccessAction {
    type: "ACTION_TRIALITEM_CREATE_SUCCESS";
    formData: Dtos.TrialItem;
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[]
}

export interface ITrialItemCreateSuccessActionCreator {
    (
        formData: Dtos.TrialItem,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[]
    ): ITrialItemCreateSuccessAction;
}

export interface ITrialItemCreateFailureAction {
    type: "ACTION_TRIALITEM_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialItemCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialItemCreateFailureAction;
}

export interface ITrialItemClearAction {
    type: "ACTION_TRIALITEM_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrialItemClearActionCreator {
    (propertiesToClear?: string[]): ITrialItemClearAction;
}

export interface ITrialItemFilterActionCreator {
    (formFilter: IFormFilter): ITrialItemFilterAction;
}

export interface ITrialItemFilterAction {
    type: "ACTION_TRIALITEM_FILTER";
    formFilter: IFormFilter;
}

export type TrialItemAction =
    ITrialItemLoadByIdAction |
    ITrialItemLoadFailureAction |
    ITrialItemLoadSuccessAction |
    ITrialItemSaveAction |
    ITrialItemSaveSuccessAction |
    ITrialItemSaveFailureAction |
    ITrialItemCreateAction |
    ITrialItemCreateSuccessAction |
    ITrialItemCreateFailureAction |
    ITrialItemFilterAction |
    ITrialItemClearAction;
