import * as React from 'react';

import * as classNames from "classnames";
import './GroupsPage.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';



import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";

import * as GroupActions from '../../../actions/groups';
import * as PagingActions from '../../../actions/pages/pagingPage';

import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput, FormFilterInput } from '../../../components/form';

interface IGroupsPageProps {

    location: Location;
    history: History;

    // From state
    groups: Dtos.Group[];
    formFilter: IFormFilter;
    formLength: number;

    loadingGroups: boolean;
    loadGroupsSuccess: boolean;
    loadGroupsFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    pageSize: number;
}

interface IGroupsPageActions {
    clearGroups: GroupActions.IGroupClearActionCreator;
    loadGroups: GroupActions.IGroupLoadActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    filter: GroupActions.IGroupFilterActionCreator;

    setPageSize: PagingActions.IPagingPageSizeChangedStateActionCreator;
}

type GroupsPageProps = IGroupsPageProps & IGroupsPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}

class GroupsPage extends React.PureComponent<GroupsPageProps, any> {

    constructor(props: GroupsPageProps) {
        super(props);

        this.clearGroups = this.clearGroups.bind(this);
    }

    componentDidMount() {
        const {
            loadGroups,
            navigate,
            navigateReplace
        } = this.props;

        loadGroups();
    }

    componentWillReceiveProps(nextProps: GroupsPageProps) {
    }

    componentDidUpdate(prevProps: GroupsPageProps, prevState) {
        const {
            navigateReplace,
            loadGroups
        } = this.props
    }

    componentWillUpdate(nextProps: GroupsPageProps) {
    }

    componentWillUnmount() {
        this.clearGroups();
    }

    clearGroups() {
        this.props.clearGroups();
    }

    showGroups(): boolean {
        const {
            loadingGroups,
            loadGroupsSuccess
        } = this.props

        if (!loadingGroups && loadGroupsSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingGroups
        } = this.props;

        return <RestrictedLayout
            loading={loadingGroups }
            permission={Dtos.Permission.GroupView}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            permissions
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-6 mb-2"}>
                    <h2>Groups</h2>
                </div>
                <div className="col-6" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} />
                    {AuthenticationHelper.hasPermission(permissions, Dtos.Permission.GroupCreate) && (
                        <Link className={"btn btn-primary"} url={"/groups/create/details"}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.showGroups() ?
                    "Loading Groups" :
                    null
            }
            {
                this.showGroups() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {

        const {
            groups,
            user,
            pageSize,
            setPageSize
        } = this.props

        if (!groups || groups.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no groups to view.</span>
            </div>
        }

        const showPagination = groups.length > pageSize;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={groups}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? pageSize : groups.length}
                pageSize={showPagination ? pageSize : groups.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no groups to display"
                resizable={false}
                onPageSizeChange={val => setPageSize(val)}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {

                            const url: string = "/groups/" + rowInfo.original.id + "/details";
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.GROUP} fixedWidth />
                    },
                    {
                        id: "name",
                        Header: 'Group Name',
                        accessor: 'name',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    },
                    {
                        id: "isDefault",
                        Header: "Default",
                        accessor: "isDefault",
                        className: "d-flex align-items-center justify-content-center",
                        headerClassName: "text-center",
                        Cell: (props) => {
                            return <>
                                <span
                                    className="truncate">{props.value == "" ? "" : <FontAwesomeIcon icon={FontAwesomeIcons.Light.CHECK} fixedWidth />}</span>
                            </>
                        }
                    }
                ]}
            />
        </div>

    }

}

const mapStateToProps = (state: ITmdState, ownProps: GroupsPageProps): IGroupsPageProps => {

    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        history: ownProps.history,
        location: state.routing.location,

        groups: state.groups.formFilteredData,
        formFilter: state.groups.formFilter,
        formLength: state.groups.formLength,

        loadingGroups: state.groups.loadState && state.groups.loadState.status == RequestState.Pending,
        loadGroupsSuccess: state.groups.loadState && state.groups.loadState.status == RequestState.Success,
        loadGroupsFailure: state.groups.loadState && state.groups.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        pageSize: state.pagingPage.pageSize
    };
};

const mapDispatchToProps = (dispatch): IGroupsPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadGroups: bindActionCreators(GroupActions.LoadGroup, dispatch),
        clearGroups: bindActionCreators(GroupActions.Clear, dispatch),

        filter: bindActionCreators(GroupActions.Filter, dispatch),

        setPageSize: bindActionCreators(PagingActions.PageSizeChanged, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(GroupsPage);
