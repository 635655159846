import * as React from "react";
import * as classNames from "classnames";
import * as Dtos from '../../../dtos/Tmd.dtos';
import { actions, FieldAction, ChangeOptions, ModelAction } from 'react-redux-form';
import { IBaseFormInputProps, ValidationMessage, RegistrationValidationMessage } from "../index";

import { FontAwesomeIcons, FontAwesomeIcon } from "../../../constants/fontAwesomeIcons";
import { GenericFormGroup } from "./index";
import { ISoftError } from "../../../interfaces/forms/ISoftError";

export interface IRegistrationGenericFormGroupProps {
    groupClassName: string;
    labelClassName: string;
    fieldClassName: string;
    validate: boolean;
    validateFn: (touched: boolean, focused: boolean) => boolean
    valid: boolean;
    touched: boolean;
    focused: boolean;
    label: React.ReactNode;
    validationFailures: Dtos.ResponseError[];
    softErrors: ISoftError[];
    hardErrors: { [index: string]: string };
    onKeyPress: (value: any) => void;
    onBlur: () => FieldAction;
    onChange: (value: any, options?: ChangeOptions) => ModelAction;
    onFocus: () => FieldAction;

    inputId: string;
    inputName: string;
    inputValue: any;
    inputDisabled: any;
    inputType: React.ComponentType<IBaseFormInputProps>;
    inputClassName: string;
    inputProps: any;

    disableEnter?: boolean;

    validationMessageAbove: boolean;

    removeContainer?: boolean;
}

class RegistrationGenericFormGroup extends React.PureComponent<IRegistrationGenericFormGroupProps, any> {

    constructor(props) {
        super(props);
    }

    render() {

        const {
            groupClassName,
            inputClassName,
            fieldClassName,
            labelClassName,
            valid,
            validate,
            disableEnter,
            focused,
            inputDisabled,
            inputId,
            inputName,
            label,
            onBlur,
            onChange,
            onFocus,
            onKeyPress,
            touched,
            inputType,
            inputProps,
            inputValue,
            children,
            validationFailures,
            softErrors,
            hardErrors,
            validateFn,
            validationMessageAbove,
            removeContainer
        } = this.props

        const HardError = !valid;
        const hasValidationFailures = validationFailures && validationFailures.length > 0
        const hasSoftErrorsDefined = softErrors && softErrors.length > 0;

        const isInvalid = (hasValidationFailures) || (!valid && touched);
        const isValid = (!validationFailures || validationFailures.length == 0) && valid;

        return <GenericFormGroup
                disableEnter={disableEnter}
                fieldClassName={fieldClassName ? fieldClassName : removeContainer ? "" : "col-sm-6"}
                focused={focused}
                groupClassName={groupClassName ? groupClassName : "form-group row border border-bottom-1 border-top-0 pb-4 pt-4"}
                inputClassName={inputClassName}
                inputDisabled={inputDisabled}
                inputId={inputId}
                inputName={inputName}
                inputProps={inputProps}
                inputType={inputType}
                inputValue={inputValue}
                label={label}
                labelClassName={labelClassName ? labelClassName : "col-form-label col-sm-6"}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                onKeyPress={onKeyPress}
                removeContainer={removeContainer}
                showValidation={isInvalid}
                showSoftErrors={this.checkSoftErrors()}
                showHardErrors={HardError}
                touched={touched}
                valid={isValid}
                validate={validate}
                validateFn={validateFn}
                validationMessageAbove={validationMessageAbove}
            >

                {
                    hasValidationFailures ?
                        <RegistrationValidationMessage
                            failures={validationFailures}
                        /> :
                        null
                }

                {
                    hasSoftErrorsDefined ?
                        softErrors.map((softError, index) => {
                            {
                                if (this.hasSoftError(softError)) {
                                    return <ValidationMessage alertType='alert-warning' key={index + "-soft-error-validation-container"}>
                                        {
                                            <span className="validation-message mb-2 mt-2" key={index + "-soft-error-validation-message"}>
                                                {softError.errorMessage}
                                            </span>
                                        }
                                    </ValidationMessage>
                                }
                            }
                        }) :
                        null
                }

                {children}
            </GenericFormGroup>
    }

    hasSoftError(softError: ISoftError): boolean {
        const { inputValue } = this.props;

        if (!softError.errorFunction(inputValue)) {
            return true;
        }

        return false;
    }

    checkSoftErrors(): boolean {
        const {
            softErrors,
            inputValue
        } = this.props;
        if (softErrors) {
            for (let softError of softErrors) {
                if (this.hasSoftError(softError)) {
                    return true;
                }
            }
        }


        return false;
    }
}

export default RegistrationGenericFormGroup;