import * as React from 'react';
import './TrialMilestonesPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { ITrialReduxFormState, initialTrialState, initialTrialAnalysisResultState, initialTrialPresentationState, initialTrialPublicationState } from "../../../reducers/reactReduxForms/trial";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as TrialActions from '../../../actions/trial';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput, DatePickerFormInputFabric } from '../../../components/form';
import { TrialLayout } from '../../index';
import { GridRemove } from "../../../enumerations/GridRemove";

interface ITrialMilestonesPageParams {
    trialId: number;
}

interface ITrialMilestonesPageProps {

    form: Dtos.Trial & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITrialReduxFormState;

    location: Location;
    trialId: number;
    match: match<ITrialMilestonesPageParams>;
    history: History;

    // From state
    trial: Dtos.Trial;
    trialRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];

    loadingTrial: boolean;
    loadTrialSuccess: boolean;
    loadTrialFailure: boolean;

    savingTrial: boolean;
    saveTrialSuccess: boolean;
    saveTrialFailure: boolean;

    validationFailures: Dtos.ResponseError[];
    permissions?: Dtos.PersonnelTmdPermissions[];
    user: Dtos.User;
}

interface ITrialMilestonesPageActions {
    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    saveTrial: TrialActions.ITrialSaveActionCreator;
    
    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type TrialMilestonesPageProps = ITrialMilestonesPageProps & ITrialMilestonesPageActions;

const reduxFormName: string = "reduxForms.trial";

class TrialMilestonesPage extends React.PureComponent<TrialMilestonesPageProps, any> {

    constructor(props: TrialMilestonesPageProps) {
        super(props);

        this.clearTrial = this.clearTrial.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTrial,
            trialId
        } = this.props;

        if (trialId) {
            loadTrial(trialId);
        } else {
            this.onCancel();
        }
    }

    componentDidUpdate(prevProps: TrialMilestonesPageProps, prevState) {
        const {
            trial,
            trialId,
            form,
            navigate,
            loadTrial,
            loadingTrial,
            loadTrialSuccess,
            savingTrial,
            saveTrialSuccess,
            validationFailures
        } = this.props;

        if (trialId && prevProps.trialId != trialId) {
            loadTrial(trialId);
        }
        else if (!trialId && prevProps.trialId) {
            this.onCancel();
        }

        if (!loadingTrial && prevProps.loadingTrial) {
            if (loadTrialSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingTrial && prevProps.savingTrial) {
            if (saveTrialSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/trials")
                } else if (!form.saveAndReturn && trialId && trialId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !trialId && trial && trial.id > 0) {
                    navigate("/trials/" + trial.id + "/details");
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }
    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearTrial();
        loadForm(reduxFormName, initialTrialState);
        resetForm(reduxFormName);
    }

    clearTrial() {
        this.props.clearTrial();
    }

    showTrial(): boolean {
        const {
            loadingTrial,
            loadTrialSuccess
        } = this.props

        if (!loadingTrial && loadTrialSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrial
        } = this.props

        return (
            <TrialLayout loading={loadingTrial} permission={ Dtos.Permission.TrialView }>{this.renderContent()}</TrialLayout>
        );
    }

    renderContent() {
        const {
            form,
            trialId,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            permissions,
            savingTrial
        } = this.props;

        const canViewDetails = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialDetailsView, trialId, undefined);
        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermissionFor(trialId, undefined, permissions, Dtos.Permission.TrialUpdate, Dtos.Permission.TrialCreate);
        const formDisabled: boolean = !hasPermission || savingTrial || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            detailsGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    tscDetailsHeader: {
                        type: RegistrationComponent.RegistrationElement,
                        component: <div className="border-0 d-flex pt-4 ">
                            <h3>TSC Details</h3>
                        </div>,
                        size: 1
                    },
                    dateTSCApprovedForDevelopment: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("Trial", "DateTSCApprovedForDevelopment", formProperties),
                        size: 2
                    },
                    dateTSCTROGNumberAllocated: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("Trial", "DateTSCTROGNumberAllocated", formProperties),
                        size: 2
                    },
                    dateTSCFinalProtocol: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("Trial", "DateTSCFinalProtocol", formProperties),
                        size: 2
                    },
                    dateTSCHRECApprovalReveived: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("Trial", "DateTSCHRECApprovalReveived", formProperties),
                        size: 2
                    },
                }
            },
            trogTrialDetailsGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    trogTrialDetailsHeader: {
                        type: RegistrationComponent.RegistrationElement,
                        component: <div className="border-0 d-flex pt-4 ">
                            <h3>TROG Trial Details</h3>
                        </div>,
                        size: 1
                    },
                    targetAccrual: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: NumberFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    totalAccrual: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: NumberFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    targetSitesTotal: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: NumberFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    actualSitesTotal: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: NumberFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    targetSitesAUS: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: NumberFormInput,
                        inputDisabled: formDisabled,
                        textRight: true,
                        size: 2
                    },
                    actualSitesAUS: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: NumberFormInput,
                        inputDisabled: formDisabled,
                        textRight: true,
                        size: 2
                    },
                    targetSitesNZ: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: NumberFormInput,
                        inputDisabled: formDisabled,
                        textRight: true,
                        size: 2
                    },
                    actualSitesNZ: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: NumberFormInput,
                        inputDisabled: formDisabled,
                        textRight: true,
                        size: 2
                    },
                    dateActivated: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("Trial", "DateActivated", formProperties),
                        size: 2
                    },
                    dateFirstPatient: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("Trial", "DateFirstPatient", formProperties),
                        size: 2
                    },
                    targetDateAccrualCompleted: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("Trial", "TargetDateAccrualCompleted", formProperties),
                        size: 2
                    },
                    dateAccrualComplete: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("Trial", "DateAccrualComplete", formProperties),
                        size: 2
                    },
                    targetDateFollowupCompletion: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("Trial", "TargetDateFollowupCompletion", formProperties),
                        size: 2
                    },
                    dateStudyClosed: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("Trial", "DateStudyClosed", formProperties),
                        size: 2
                    },
                    dateExpectedCompletion: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("Trial", "DateExpectedCompletion", formProperties),
                        size: 2
                    },
                    dateCompletedWithdrawn: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("Trial", "DateCompletedWithdrawn", formProperties),
                        size: 2
                    },
                }
            },
            internationalTrialDetailsGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    internationalTrialDetailsHeader: {
                        type: RegistrationComponent.RegistrationElement,
                        component: <div className="border-0 d-flex pt-4 ">
                            <h3>International Trial Details</h3>
                        </div>,
                        size: 1
                    },
                    internationalTargetAccrual: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: NumberFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    internationalTotalAccrual: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: NumberFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    internationalTargetSites: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: NumberFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    internationalActualSites: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: NumberFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    internationalDateActivated: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("Trial", "InternationalDateActivated", formProperties),
                        size: 2
                    },
                    internationalDateCompletedWithdrawn: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("Trial", "InternationalDateCompletedWithdrawn", formProperties),
                        size: 2
                    },
                    internationalTargetDateAccrualCompleted: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("Trial", "InternationalTargetDateAccrualCompleted", formProperties),
                        size: 2
                    },
                    internationalActualDateAccrualCompleted: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("Trial", "InternationalActualDateAccrualCompleted", formProperties),
                        size: 2
                    },
                    internationalTargetDateFollowupCompletion: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("Trial", "InternationalTargetDateFollowupCompletion", formProperties),
                        size: 2
                    },
                    internationalActualDateFollowupCompletion: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("Trial", "InternationalActualDateFollowupCompletion", formProperties),
                        size: 2
                    },
                }
            },
            trialAnalysisResultHeader: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="border-0 d-flex pt-4 ">
                    <h3>Analysis &amp; Results</h3>
                </div>
            },
            trialAnalysisResults: {
                type: RegistrationComponent.RegistrationGrid,
                label: "Analysis Result",
                disabled: formDisabled,
                formName: "TrialAnalysisResult",
                className: "",
                minRows: 0,
                columnStyles: [
                    {
                        width: 'auto'
                    },
                    {
                        width: "180px"
                    },
                    {
                        width: "180px"
                    },
                    {
                        width: "220px"
                    },
                    {
                        width: "180px"
                    },
                    {
                        width: "220px"
                    },
                    {
                        width: "125px"
                    },
                ],
                removeConfig: GridRemove.All,
                addRow: (gridComponent, index, array) => {
                    const state: Dtos.TrialAnalysisResult = Object.assign(
                        { ...gridComponent.initialState }
                    );
                    return state;
                },
                components: {
                    trialAnalysisTypeId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialAnalysisResult", "TrialAnalysisTypeId", formProperties),
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialAnalysisResult", "TrialAnalysisTypeId", lookups)),
                            feedbackIcon: FontAwesomeIcons.Solid.BAN
                        },
                        validators: {
                            required: value => value != undefined
                        },
                        errors: {
                            required: "Registry is required."
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + ".trialAnalysisType", undefined);
                            if (value != 5) {
                                changeForm(reduxFormName + parentModel + ".trialAnalysisTypeOther", undefined);
                            }
                        },
                        errorComponent: ValidationMessage
                    },
                    trialAnalysisTypeOther: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialAnalysisResult", "TrialAnalysisTypeOther", formProperties),
                        disabledFn: (form: Dtos.TrialAnalysisResult, arrayKey: string) => {
                            if (form) {
                                if (form.trialAnalysisTypeId == 5) {
                                    return false;
                                }
                            }
                            return true;
                        },
                    },
                    targetStartDate: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialAnalysisResult", "TargetStartDate", formProperties),
                    },
                    targetCompletionDate: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialAnalysisResult", "TargetCompletionDate", formProperties),
                    },
                    actualStartDate: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialAnalysisResult", "ActualStartDate", formProperties),
                    },
                    actualCompletionDate: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialAnalysisResult", "ActualCompletionDate", formProperties),
                    },
                    trialPublicationId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialAnalysisResult", "TrialPublicationId", formProperties),
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialAnalysisResult", "TrialPublicationId", lookups)),
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + ".trialPublication", undefined);
                        },
                    },
                },
                initialState: initialTrialAnalysisResultState,
                onRemove: (model: string) => {
                    changeForm(model + "active", false);
                    return true; // this was handled here
                },
                filterRow: (data: any) => {
                    if (data.active != true) {
                        return false
                    }
                    return true;
                }
            },
            trialPresentationResultHeader: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="border-0 d-flex pt-4 ">
                    <h3>Presentations</h3>
                </div>
            },
            trialPresentations: {
                type: RegistrationComponent.RegistrationGrid,
                label: "Presentations",
                disabled: formDisabled,
                formName: "TrialPresentation",
                className: "",
                minRows: 0,
                columnStyles: [
                    {
                        width: "180px"
                    },
                    {
                        width: "180px"
                    },
                    {
                        width: "220px"
                    },
                    {
                        width: 'auto'
                    },
                    {
                        width: "220px"
                    },
                    {
                        width: "125px"
                    },
                ],
                removeConfig: GridRemove.All,
                addRow: (gridComponent, index, array) => {
                    const state: Dtos.TrialAnalysisResult = Object.assign(
                        { ...gridComponent.initialState }
                    );
                    return state;
                },
                components: {
                    trialPresentationTypeId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialPresentation", "TrialPresentationTypeId", formProperties),
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialPresentation", "TrialPresentationTypeId", lookups)),
                            feedbackIcon: FontAwesomeIcons.Solid.BAN
                        },
                        validators: {
                            required: value => value != undefined
                        },
                        errors: {
                            required: "Presentation type is required."
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + ".trialPresentationType", undefined);
                        },
                        errorComponent: ValidationMessage
                    },
                    title: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialPresentation", "Title", formProperties),
                    },
                    presenterName: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialPresentation", "PresenterName", formProperties),
                    },
                    meeting: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialPresentation", "Meeting", formProperties),
                    },
                    meetingDate: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialPresentation", "MeetingDate", formProperties),
                    },
                    publishedAsAnAbstractId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialPresentation", "PublishedAsAnAbstractId", formProperties),
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialPresentation", "PublishedAsAnAbstractId", lookups)),
                        },
                    },
                },
                initialState: initialTrialPresentationState,
                onRemove: (model: string) => {
                    changeForm(model + "active", false);
                    return true; // this was handled here
                },
                filterRow: (data: any) => {
                    if (data.active != true) {
                        return false
                    }
                    return true;
                }
            },
            trialPublicationResultHeader: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="border-0 d-flex pt-4 ">
                    <h3>Publications</h3>
                </div>
            },
            trialPublications: {
                type: RegistrationComponent.RegistrationGrid,
                label: "Publications",
                disabled: formDisabled,
                formName: "TrialPublication",
                className: "",
                minRows: 0,
                columnStyles: [
                    {
                        width: "180px"
                    },
                    {
                        width: "180px"
                    },
                    {
                        width: "220px"
                    },
                    {
                        width: 'auto'
                    },
                    {
                        width: "220px"
                    },
                    {
                        width: "125px"
                    },
                ],
                removeConfig: GridRemove.All,
                addRow: (gridComponent, index, array) => {
                    const state: Dtos.TrialAnalysisResult = Object.assign(
                        { ...gridComponent.initialState }
                    );
                    return state;
                },
                components: {
                    trialPublicationTypeId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialPublication", "TrialPublicationTypeId", formProperties),
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialPublication", "TrialPublicationTypeId", lookups)),
                            feedbackIcon: FontAwesomeIcons.Solid.BAN
                        },
                        validators: {
                            required: value => value != undefined
                        },
                        errors: {
                            required: "Publication type is required."
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + ".trialPublicationType", undefined);
                            if (value != 4) {
                                changeForm(reduxFormName + parentModel + ".trialPublicationTypeOther", undefined);
                            }
                        },
                        errorComponent: ValidationMessage
                    },
                    trialPublicationTypeOther: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialPublication", "TrialPublicationTypeOther", formProperties),
                        disabledFn: (form: Dtos.TrialPublication, arrayKey: string) => {
                            if (form) {
                                if (form.trialPublicationTypeId == 4) {
                                    return false;
                                }
                            }
                            return true;
                        },
                    },
                    trialPublicationStatusId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputLabel: getFormPropertyLabel("TrialPublication", "TrialPublicationStatusId", formProperties),
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialPublication", "TrialPublicationStatusId", lookups)),
                            feedbackIcon: FontAwesomeIcons.Solid.BAN
                        },
                        inputDisabled: formDisabled,
                        validators: {
                            required: value => value != undefined
                        },
                        errors: {
                            required: "Publication status is required."
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + ".trialPublicationStatus", undefined);
                        },
                        errorComponent: ValidationMessage
                    },
                    details: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialPublication", "Details", formProperties),
                    },
                    tpcReviewedId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialPublication", "TPCReviewedId", formProperties),
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialPublication", "TPCReviewedId", lookups)),
                        },
                        validators: {
                            required: value => value != undefined
                        },
                        errors: {
                            required: "TPC Reviewed type is required."
                        },
                    },
                    yearPublished: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: NumberFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialPublication", "YearPublished", formProperties),
                    },
                },
                initialState: initialTrialPublicationState,
                onRemove: (model: string) => {
                    changeForm(model + "active", false);
                    return true; // this was handled here
                },
                filterRow: (data: any) => {
                    if (data.active != true) {
                        return false
                    }
                    return true;
                }
            },
            commentsGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    comments: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextAreaFormInput,
                        inputDisabled: formDisabled,
                        hide: !canViewDetails,
                        size: 1
                    }
                }
            }
        };

        return <div>
            <h1>Trial Milestones</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "trial", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
        </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.Trial) {
        const {
            saveTrial
        } = this.props;

        if (data) {
            let changes: Dtos.Trial = {} as Dtos.Trial;
            saveTrial(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.Trial) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/trials");
    }

    loadForm() {
        const {
            loadForm,
            trial,
        } = this.props

        let extraData: Dtos.Trial = {} as Dtos.Trial;

        loadForm(reduxFormName, Object.assign({ ...trial }, extraData));
    }
}


const mapStateToProps = (state: ITmdState, ownProps: TrialMilestonesPageProps): ITrialMilestonesPageProps => {

    let trial: Dtos.Trial | undefined = !(state.trials.formData instanceof Array) ? state.trials.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialId: ownProps.match && ownProps.match.params.trialId && ownProps.match.params.trialId.toString() != "create" ? ownProps.match.params.trialId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trial: trial!,
        trialRoles: state.trials.formRoles,

        loadingTrial: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,
        loadTrialSuccess: state.trials.loadState && state.trials.loadState.status == RequestState.Success,
        loadTrialFailure: state.trials.loadState && state.trials.loadState.status == RequestState.Failure,

        savingTrial: state.trials.saveState && state.trials.saveState.status == RequestState.Pending,
        saveTrialSuccess: state.trials.saveState && state.trials.saveState.status == RequestState.Success,
        saveTrialFailure: state.trials.saveState && state.trials.saveState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: state.trials.lookups,

        form: state.reduxForms.trial,
        formState: state.trials.formState,
        formProperties: state.trials.formProperties,

        reduxFormState: state.reduxForms.formState.trial,
        validationFailures: trial ?
            state.trials.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): ITrialMilestonesPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch),

        saveTrial: bindActionCreators(TrialActions.SaveTrial, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialMilestonesPage);
