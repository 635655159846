import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadAllocatedWP: IAllocatedWPLoadActionCreator = () => {
        return {
            type: ACTION_ALLOCATEDWPS_LOAD
        }
    },
    LoadAllocatedWPById: IAllocatedWPLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_ALLOCATEDWPS_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IAllocatedWPLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_ALLOCATEDWPS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IAllocatedWPLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_ALLOCATEDWPS_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveAllocatedWP: IAllocatedWPSaveActionCreator = (formData) => {
        return {
            type: ACTION_ALLOCATEDWPS_SAVE,
            formData
        }
    },
    SaveSuccess: IAllocatedWPSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_ALLOCATEDWPS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IAllocatedWPSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_ALLOCATEDWPS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateAllocatedWP: IAllocatedWPCreateActionCreator = () => {

        return {
            type: ACTION_ALLOCATEDWPS_CREATE
        }
    },
    CreateSuccess: IAllocatedWPCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_ALLOCATEDWPS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: IAllocatedWPCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_ALLOCATEDWPS_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IAllocatedWPClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_ALLOCATEDWPS_CLEAR,
            propertiesToClear
        }
    },
    Filter: IAllocatedWPFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_ALLOCATEDWPS_FILTER,
            formFilter
        }
    };



export const
    ACTION_ALLOCATEDWPS_LOAD = "ACTION_ALLOCATEDWPS_LOAD",
    ACTION_ALLOCATEDWPS_LOAD_BY_ID = "ACTION_ALLOCATEDWPS_LOAD_BY_ID",
    ACTION_ALLOCATEDWPS_LOAD_SUCCESS = "ACTION_ALLOCATEDWPS_LOAD_SUCCESS",
    ACTION_ALLOCATEDWPS_LOAD_FAILURE = "ACTION_ALLOCATEDWPS_LOAD_FAILURE",

    ACTION_ALLOCATEDWPS_SAVE = "ACTION_ALLOCATEDWPS_SAVE",
    ACTION_ALLOCATEDWPS_SAVE_SUCCESS = "ACTION_ALLOCATEDWPS_SAVE_SUCCESS",
    ACTION_ALLOCATEDWPS_SAVE_FAILURE = "ACTION_ALLOCATEDWPS_SAVE_FAILURE",

    ACTION_ALLOCATEDWPS_CREATE = "ACTION_ALLOCATEDWPS_CREATE",
    ACTION_ALLOCATEDWPS_CREATE_SUCCESS = "ACTION_ALLOCATEDWPS_CREATE_SUCCESS",
    ACTION_ALLOCATEDWPS_CREATE_FAILURE = "ACTION_ALLOCATEDWPS_CREATE_FAILURE",

    ACTION_ALLOCATEDWPS_CLEAR = "ACTION_ALLOCATEDWPS_CLEAR",

    ACTION_ALLOCATEDWPS_FILTER = "ACTION_ALLOCATEDWPS_FILTER";

export interface IAllocatedWPLoadAction {
    type: "ACTION_ALLOCATEDWPS_LOAD";
}

export interface IAllocatedWPLoadActionCreator {
    (): IAllocatedWPLoadAction;
}

export interface IAllocatedWPLoadSuccessAction {
    type: "ACTION_ALLOCATEDWPS_LOAD_SUCCESS";
    formData: Dtos.AllocatedWP | Dtos.AllocatedWP[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IAllocatedWPLoadSuccessActionCreator {
    (
        formData: Dtos.AllocatedWP | Dtos.AllocatedWP[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IAllocatedWPLoadSuccessAction;
}

export interface IAllocatedWPLoadFailureAction {
    type: "ACTION_ALLOCATEDWPS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IAllocatedWPLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IAllocatedWPLoadFailureAction;
}

export interface IAllocatedWPLoadByIdAction {
    type: "ACTION_ALLOCATEDWPS_LOAD_BY_ID";
    id: number;
}

export interface IAllocatedWPLoadByIdActionCreator {
    (id: number): IAllocatedWPLoadByIdAction;
}


export interface IAllocatedWPSaveAction {
    type: "ACTION_ALLOCATEDWPS_SAVE";
    formData: Dtos.AllocatedWP;

}

export interface IAllocatedWPSaveActionCreator {
    (
        formData: Dtos.AllocatedWP
    ): IAllocatedWPSaveAction;
}

export interface IAllocatedWPSaveSuccessAction {
    type: "ACTION_ALLOCATEDWPS_SAVE_SUCCESS";
    formData: Dtos.AllocatedWP;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IAllocatedWPSaveSuccessActionCreator {
    (
        formData: Dtos.AllocatedWP,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IAllocatedWPSaveSuccessAction;
}

export interface IAllocatedWPSaveFailureAction {
    type: "ACTION_ALLOCATEDWPS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IAllocatedWPSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IAllocatedWPSaveFailureAction;
}

export interface IAllocatedWPCreateAction {
    type: "ACTION_ALLOCATEDWPS_CREATE"
}

export interface IAllocatedWPCreateActionCreator {
    (): IAllocatedWPCreateAction
}

export interface IAllocatedWPCreateSuccessAction {
    type: "ACTION_ALLOCATEDWPS_CREATE_SUCCESS";
    formData: Dtos.AllocatedWP;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface IAllocatedWPCreateSuccessActionCreator {
    (
        formData: Dtos.AllocatedWP,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): IAllocatedWPCreateSuccessAction;
}

export interface IAllocatedWPCreateFailureAction {
    type: "ACTION_ALLOCATEDWPS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IAllocatedWPCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IAllocatedWPCreateFailureAction;
}

export interface IAllocatedWPClearAction {
    type: "ACTION_ALLOCATEDWPS_CLEAR";
    propertiesToClear?: string[];
}

export interface IAllocatedWPClearActionCreator {
    (propertiesToClear?: string[]): IAllocatedWPClearAction;
}

export interface IAllocatedWPFilterActionCreator {
    (formFilter: IFormFilter): IAllocatedWPFilterAction;
}

export interface IAllocatedWPFilterAction {
    type: "ACTION_ALLOCATEDWPS_FILTER";
    formFilter: IFormFilter;
}

export type AllocatedWPAction =
    IAllocatedWPLoadAction |
    IAllocatedWPLoadByIdAction |
    IAllocatedWPLoadFailureAction |
    IAllocatedWPLoadSuccessAction |
    IAllocatedWPSaveAction |
    IAllocatedWPSaveSuccessAction |
    IAllocatedWPSaveFailureAction |
    IAllocatedWPCreateAction |
    IAllocatedWPCreateSuccessAction |
    IAllocatedWPCreateFailureAction |
    IAllocatedWPClearAction |
    IAllocatedWPFilterAction;

