import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_COLLABORATINGGROUPS_LOAD,
    ACTION_COLLABORATINGGROUPS_LOAD_BY_ID,

    ACTION_COLLABORATINGGROUPS_SAVE,

    ACTION_COLLABORATINGGROUPS_CREATE,

    ICollaboratingGroupLoadAction,
    ICollaboratingGroupLoadByIdAction,
    ICollaboratingGroupLoadFailureAction,
    ICollaboratingGroupLoadSuccessAction,

    ICollaboratingGroupSaveAction,
    ICollaboratingGroupSaveSuccessAction,
    ICollaboratingGroupSaveFailureAction,

    ICollaboratingGroupCreateAction,
    ICollaboratingGroupCreateSuccessAction,
    ICollaboratingGroupCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/collaboratingGroups";
import { collaboratinggroupsApi } from "../services/collaboratingGroup";

export const
    getCollaboratingGroupsEpic = (action$): Observable<ICollaboratingGroupLoadSuccessAction | ICollaboratingGroupLoadFailureAction> => {
        return action$
            .ofType(ACTION_COLLABORATINGGROUPS_LOAD)
            .mergeMap((action: ICollaboratingGroupLoadAction) => {
                return collaboratinggroupsApi
                    .getCollaboratingGroups()
                    .map(response =>
                        LoadSuccess(response.collaboratingGroups, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getCollaboratingGroupByIdEpic = (action$): Observable<ICollaboratingGroupLoadSuccessAction | ICollaboratingGroupLoadFailureAction> => {
        return action$
            .ofType(ACTION_COLLABORATINGGROUPS_LOAD_BY_ID)
            .mergeMap((action: ICollaboratingGroupLoadByIdAction) => {
                return collaboratinggroupsApi
                    .getCollaboratingGroupById(action.id)
                    .map(response =>
                        LoadSuccess(response.collaboratingGroup, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setCollaboratingGroupEpic = (action$): Observable<ICollaboratingGroupSaveSuccessAction | ICollaboratingGroupSaveFailureAction> => {
        return action$
            .ofType(ACTION_COLLABORATINGGROUPS_SAVE)
            .mergeMap((action: ICollaboratingGroupSaveAction) => {
                return collaboratinggroupsApi
                    .setCollaboratingGroup(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.collaboratingGroup, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createCollaboratingGroupEpic = (action$): Observable<ICollaboratingGroupCreateSuccessAction | ICollaboratingGroupCreateFailureAction> => {
        return action$
            .ofType(ACTION_COLLABORATINGGROUPS_CREATE)
            .mergeMap((action: ICollaboratingGroupCreateAction) => {
                return collaboratinggroupsApi
                    .createCollaboratingGroup()
                    .map(response =>
                        CreateSuccess(
                            response.collaboratingGroup,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



