import { Reducer } from 'redux';
import { update } from '../../helpers/immutabilityHelper'
import * as Dtos from '../../dtos/Tmd.dtos';

import {
    ACTION_INSTITUTIONFORMS_PAGE_SET_EDIT_MODAL_VIEW_STATE,
    ACTION_INSTITUTIONFORMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE,
    InstitutionFormsPageAction
} from '../../actions/pages/institutionFormsPage';


export interface IInstitutionFormsPageState {
    editModalOpen: boolean;
    collectionInstanceId: number;
    name: string;
    description: string;
    createVersionModalOpen: boolean;
    institutionFormId: number;
    version: string;
}

const initialState: IInstitutionFormsPageState = {
    editModalOpen: false,
    collectionInstanceId: null,
    name: "",
    description: "",
    createVersionModalOpen: false,
    institutionFormId: null,
    version: "",
};


function setEditModalViewState(state: IInstitutionFormsPageState, open: boolean, collectionInstanceId: number, name: string, description: string): IInstitutionFormsPageState {
    const newState: IInstitutionFormsPageState = update(state, {
        editModalOpen: {
            $set: open
        },
        collectionInstanceId: {
            $set: collectionInstanceId
        },
        name: {
            $set: name
        },
        description: {
            $set: description
        }
    });
    return newState;
}

function setCreateVersionModalViewState(state: IInstitutionFormsPageState, open: boolean, institutionFormId: number, version: string): IInstitutionFormsPageState {
    const newState: IInstitutionFormsPageState = update(state, {
        createVersionModalOpen: {
            $set: open
        },
        institutionFormId: {
            $set: institutionFormId
        },
        version: {
            $set: version
        }
    });
    return newState;
}

const InstitutionFormsPageReducer: Reducer<IInstitutionFormsPageState> = (state: IInstitutionFormsPageState = initialState, action) => { 
    switch (action.type) {
        case ACTION_INSTITUTIONFORMS_PAGE_SET_EDIT_MODAL_VIEW_STATE:
            return setEditModalViewState(state, action.open, action.collectionInstanceId, action.name, action.description);
        case ACTION_INSTITUTIONFORMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE:
            return setCreateVersionModalViewState(state, action.open, action.institutionFormId, action.version);
    }

    return state;
}

export default InstitutionFormsPageReducer;