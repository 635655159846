import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_TRIALPHASES_LOAD,
    ACTION_TRIALPHASES_LOAD_BY_ID,

    ACTION_TRIALPHASES_SAVE,

    ACTION_TRIALPHASES_CREATE,

    ITrialPhaseLoadAction,
    ITrialPhaseLoadByIdAction,
    ITrialPhaseLoadFailureAction,
    ITrialPhaseLoadSuccessAction,

    ITrialPhaseSaveAction,
    ITrialPhaseSaveSuccessAction,
    ITrialPhaseSaveFailureAction,

    ITrialPhaseCreateAction,
    ITrialPhaseCreateSuccessAction,
    ITrialPhaseCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/trialPhases";
import { trialphasesApi } from "../services/trialPhase";

export const
    getTrialPhasesEpic = (action$): Observable<ITrialPhaseLoadSuccessAction | ITrialPhaseLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALPHASES_LOAD)
            .mergeMap((action: ITrialPhaseLoadAction) => {
                return trialphasesApi
                    .getTrialPhases()
                    .map(response =>
                        LoadSuccess(response.trialPhases, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTrialPhaseByIdEpic = (action$): Observable<ITrialPhaseLoadSuccessAction | ITrialPhaseLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALPHASES_LOAD_BY_ID)
            .mergeMap((action: ITrialPhaseLoadByIdAction) => {
                return trialphasesApi
                    .getTrialPhaseById(action.id)
                    .map(response =>
                        LoadSuccess(response.trialPhase, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setTrialPhaseEpic = (action$): Observable<ITrialPhaseSaveSuccessAction | ITrialPhaseSaveFailureAction> => {
        return action$
            .ofType(ACTION_TRIALPHASES_SAVE)
            .mergeMap((action: ITrialPhaseSaveAction) => {
                return trialphasesApi
                    .setTrialPhase(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.trialPhase, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createTrialPhaseEpic = (action$): Observable<ITrialPhaseCreateSuccessAction | ITrialPhaseCreateFailureAction> => {
        return action$
            .ofType(ACTION_TRIALPHASES_CREATE)
            .mergeMap((action: ITrialPhaseCreateAction) => {
                return trialphasesApi
                    .createTrialPhase()
                    .map(response =>
                        CreateSuccess(
                            response.trialPhase,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



