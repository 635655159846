import { combineReducers } from "redux";
import { routerReducer, RouterState } from "react-router-redux";
import { combineForms, FormState, FieldState } from 'react-redux-form';

/* Common */
import user, { IUserState } from './user';
import home, { IHomeState } from './home';
import websiteSettings, { IWebsiteSettingsState } from './websiteSettings';
import trials, { ITrialState } from './trials';
import search, { ISearchState } from './search';
import itemComponents, { IItemComponentState } from './itemComponents';
import categories, { ICategoryState } from './categories';
import personnel, { IPersonnelState } from './personnel';
import personnelRole, { IPersonnelRoleState } from './personnelRoles';
import groups, { IGroupState } from './groups';
import groupItem, { IGroupItemState } from './groupItems';
import institutions, { IInstitutionState } from './institutions';
import trialItem, { ITrialItemState } from './trialItems';
import trialGroup, { ITrialGroupState } from './trialGroups';
import trialGroupItem, { ITrialGroupItemState } from './trialGroupItems';
import trialInstitution, { ITrialInstitutionState } from './trialInstitutions';
import trialTreatment, { ITrialTreatmentState } from './trialTreatments';
import trialTracker, { ITrialTrackerState } from './trialTrackers';
import trialInstitutionItem, { ITrialInstitutionItemState } from './trialInstitutionItems';
import trialInstitutionItemTemplate, { ITrialInstitutionItemTemplateState } from './trialInstitutionItemTemplates';
import collection, { ICollectionState } from './collections';
import collectionInstance, { ICollectionInstanceState } from './collectionInstance';
import countries, { ICountryState } from './countries';
import institutionCodings, { IInstitutionCodingState } from './institutionCodings';
import noteTypes, { INoteTypeState } from './noteTypes';
import trialTypes, { ITrialTypeState } from './trialTypes';
import itemComponentTypes, { IItemComponentTypeState } from './itemComponentTypes';
import notes, { INoteState } from './notes';
import trialItemImport, { ITrialItemImportState } from './trialItemImport';
import trialItemInstanceImport, { ITrialItemInstanceImportState } from './trialItemInstanceImport';
import trialItemUpdate, { ITrialItemUpdateState } from './trialItemUpdate';
import openClinicaUserRole, { IOpenClinicaUserRoleState } from './openClinicaUserRoles';
import hubspot, { IHubspotState } from './hubspot';
import states, { IStateState } from './states';
import institutionTypes, { IInstitutionTypeState } from './institutionTypes';
import siteLocations, { ISiteLocationState } from './siteLocation';
import facilityAllianceMembers, { IFacilityAllianceMemberState } from './facilityAllianceMembers';
import keyContacts, { IKeyContactState } from './keyContacts';
import trialCategorys, { ITrialCategoryState } from './trialCategorys';
import tumourGroups, { ITumourGroupState } from './tumourGroup';
import allocatedWPs, { IAllocatedWPState } from './allocatedWPs';
import trialPhases, { ITrialPhaseState } from './trialPhases';
import involvements, { IInvolvementState } from './involvements';
import trialCoordCentres, { ITrialCoordCentreState } from './trialCoordCentres';
import collaboratingGroups, { ICollaboratingGroupState } from './collaboratingGroups';
import genders, { IGenderState } from './genders';
import membershipTypes, { IMembershipTypeState } from './membershipTypes';
import professionalDisciplines, { IProfessionalDisciplineState } from './professionalDiscipline';
import interests, { IInterestState } from './interests';
import salutations, { ISalutationState } from './salutations';
import formTemplates, { IFormTemplateState } from './formTemplates';
import templateTrialItems, { ITemplateTrialItemState } from './templateTrialItems';
import patient, { IPatientState } from './patients';
import institutionForm, { IInstitutionFormState } from './institutionForms';
import trialForm, { ITrialFormState } from './trialForms';
import roles, { IRoleState } from './roles';
import departments, { IDepartmentState } from './departments';
import departmentContacts, { IDepartmentContactState } from './departmentContacts';
import reportingTagCategories, { IReportingTagCategoryState } from './reportingTagCategories';
import reportingTags, { IReportingTagState } from './reportingTags';
import taskTypes, { ITaskTypeState } from './taskTypes';
import formTemplateFileTypes, { IFormTemplateFileTypeState } from './formTemplateFileTypes';
import trackerTypes, { ITrackerTypeState } from './trackerTypes';
import trackerMilestones, { ITrackerMilestoneState } from './trackerMilestone';
import standardEmailTemplates, { IStandardEmailTemplateState } from './standardEmailTemplate';
import trialItemInstance, { ITrialItemInstanceState } from './trialItemInstances';
import trialPresentationType, { ITrialPresentationTypeState } from './trialPresentationTypes';
import trialPublicationType, { ITrialPublicationTypeState } from './trialPublicationTypes';
import trialAnalysisType, { ITrialAnalysisTypeState } from './trialAnalysisTypes';

/* Pages */
import itemComponentPage, { IItemComponentPageState } from './pages/itemComponentPage';
import collectionPage, { ICollectionPageState } from './pages/collectionPage';
import trialInstitutionItemsPage, { ITrailInstitutionItemsPageState } from './pages/trialInstitutionItemsPage';
import trialItemInstancePage, { ITrailItemInstancePageState } from './pages/trialItemInstancePage';
import institutionFormsPage, { IInstitutionFormsPageState } from './pages/institutionFormsPage';
import trialFormsPage, { ITrialFormsPageState } from './pages/trialFormsPage';
import trialsPage, { ITrialsPageState } from './pages/trialsPage';
import trialGroupItemsPage, { ITrailGroupItemsPageState } from './pages/trialGroupItemsPage';
import togglePage, { ITogglePageState } from './pages/togglePage';
import pagingPage, { IPagingPageState } from './pages/pagingPage';


/* Registration forms + other related data */
import { initialHomeFormState, IHomeReduxFormState } from "./reactReduxForms/homeForm";
import { initialCollectionState, ICollectionReduxFormState } from "./reactReduxForms/collection";
import { initialCollectionInstanceState, ICollectionInstanceReduxFormState } from "./reactReduxForms/collectionInstance";
import { initialTrialState, ITrialReduxFormState } from "./reactReduxForms/trial";
import { initialTrialItemState, ITrialItemReduxFormState } from "./reactReduxForms/trialItem";
import { initialTrialGroupState, ITrialGroupReduxFormState } from "./reactReduxForms/trialGroup";
import { initialTrialGroupItemState, ITrialGroupItemReduxFormState } from "./reactReduxForms/trialGroupItem";
import { initialWebsiteSettingFormState, IWebsiteSettingReduxFormState } from "./reactReduxForms/websiteSettingsForm";
import { initialItemComponentState, IItemComponentReduxFormState } from "./reactReduxForms/itemComponent";
import { initialGroupState, IGroupReduxFormState } from "./reactReduxForms/group";
import { initialGroupItemState, IGroupItemReduxFormState } from "./reactReduxForms/groupItem";
import { initialCategoryState, ICategoryReduxFormState } from "./reactReduxForms/category";
import { initialInstitutionState, IInstitutionReduxFormState } from "./reactReduxForms/institution";
import { initialTrialInstitutionState, ITrialInstitutionReduxFormState } from "./reactReduxForms/trialInstitution";
import { initialTrialTreatmentState, ITrialTreatmentReduxFormState } from "./reactReduxForms/trialTreatment";
import { initialTrialTrackerState, ITrialTrackerReduxFormState } from "./reactReduxForms/trialTracker";
import { initialTrialInstitutionItemState, ITrialInstitutionItemReduxFormState } from "./reactReduxForms/trialInstitutionItem";
import { initialTrialInstitutionItemTemplateState, ITrialInstitutionItemTemplateReduxFormState } from "./reactReduxForms/trialInstitutionItemTemplate";
import { initialTrialItemInstanceState, ITrialItemInstanceReduxFormState } from "./reactReduxForms/trialItemInstance";
import { initialPersonnelState, IPersonnelReduxFormState } from "./reactReduxForms/personnel";
import { initialPersonnelRoleState, IPersonnelRoleReduxFormState } from "./reactReduxForms/personnelRole";
import { initialSearchResultState, ISearchResultReduxFormState } from "./reactReduxForms/searchResult";
import { initialCountryState, ICountryReduxFormState } from "./reactReduxForms/country";
import { initialInstitutionCodingState, IInstitutionCodingReduxFormState } from "./reactReduxForms/institutionCoding";
import { initialNoteTypeState, INoteTypeReduxFormState } from "./reactReduxForms/noteType";
import { initialTrialTypeState, ITrialTypeReduxFormState } from "./reactReduxForms/trialType";
import { initialItemComponentTypeState, IItemComponentTypeReduxFormState } from "./reactReduxForms/itemComponentType";
import { initialNoteState, INoteReduxFormState } from "./reactReduxForms/note";
import { initialTrialItemImportState, ITrialItemImportReduxFormState } from "./reactReduxForms/trialItemImport";
import { initialTrialItemInstanceImportState, ITrialItemInstanceImportReduxFormState } from "./reactReduxForms/trialItemInstanceImport";
import { initialTrialItemUpdateState, ITrialItemUpdateReduxFormState } from "./reactReduxForms/trialItemUpdate";
import { initialOpenClinicaUserRoleState, IOpenClinicaUserRoleReduxFormState } from "./reactReduxForms/openClinicaUserRole";
import { initialHubspotState, IHubspotReduxFormState } from "./reactReduxForms/hubspot";
import { initialStateState, IStateReduxFormState } from "./reactReduxForms/state";
import { initialInstitutionTypeState, IInstitutionTypeReduxFormState } from "./reactReduxForms/institutionType";
import { initialSiteLocationState, ISiteLocationReduxFormState } from "./reactReduxForms/siteLocation";
import { initialFacilityAllianceMemberState, IFacilityAllianceMemberReduxFormState } from "./reactReduxForms/facilityAllianceMember";
import { initialKeyContactState, IKeyContactReduxFormState } from "./reactReduxForms/keyContact";
import { initialTrialCategoryState, ITrialCategoryReduxFormState } from "./reactReduxForms/trialCategory";
import { initialTumourGroupState, ITumourGroupReduxFormState } from "./reactReduxForms/tumourGroup";
import { initialAllocatedWPState, IAllocatedWPReduxFormState } from "./reactReduxForms/allocatedWP";
import { initialTrialPhaseState, ITrialPhaseReduxFormState } from "./reactReduxForms/trialPhase";
import { initialInvolvementState, IInvolvementReduxFormState } from "./reactReduxForms/involvement";
import { initialTrialCoordCentreState, ITrialCoordCentreReduxFormState } from "./reactReduxForms/trialCoordCentre";
import { initialCollaboratingGroupState, ICollaboratingGroupReduxFormState } from "./reactReduxForms/collaboratingGroup";
import { initialGenderState, IGenderReduxFormState } from "./reactReduxForms/gender";
import { initialMembershipTypeState, IMembershipTypeReduxFormState } from "./reactReduxForms/membershipType";
import { initialProfessionalDisciplineState, IProfessionalDisciplineReduxFormState } from "./reactReduxForms/professionalDiscipline";
import { initialInterestState, IInterestReduxFormState } from "./reactReduxForms/interest";
import { initialSalutationState, ISalutationReduxFormState } from "./reactReduxForms/salutations";
import { initialFormTemplateState, IFormTemplateReduxFormState } from "./reactReduxForms/formTemplate";
import { initialTemplateTrialItemState, ITemplateTrialItemReduxFormState } from "./reactReduxForms/templateTrialItems";
import { initialPatientState, IPatientReduxFormState } from "./reactReduxForms/patient";
import { initialInstitutionFormState, IInstitutionFormReduxFormState } from "./reactReduxForms/institutionForm";
import { initialTrialFormState, ITrialFormReduxFormState } from "./reactReduxForms/trialForm";
import { initialRoleState, IRoleReduxFormState } from "./reactReduxForms/role";
import { initialDepartmentState, IDepartmentReduxFormState } from "./reactReduxForms/department";
import { initialDepartmentContactState, IDepartmentContactReduxFormState } from "./reactReduxForms/departmentContact";
import { initialReportingTagCategoryState, IReportingTagCategoryReduxFormState } from "./reactReduxForms/reportingTagCategory";
import { initialReportingTagState, IReportingTagReduxFormState } from "./reactReduxForms/reportingTag";
import { initialTaskTypeState, ITaskTypeReduxFormState } from "./reactReduxForms/taskType";
import { initialFormTemplateFileTypeState, IFormTemplateFileTypeReduxFormState } from "./reactReduxForms/formTemplateFileType";
import { initialTrackerTypeState, ITrackerTypeReduxFormState } from "./reactReduxForms/trackerType";
import { initialTrackerMilestoneState, ITrackerMilestoneReduxFormState } from "./reactReduxForms/trackerMilestone";
import { initialStandardEmailTemplateState, IStandardEmailTemplateReduxFormState } from "./reactReduxForms/standardEmailTemplate";
import { initialTrialPresentationTypeState, ITrialPresentationTypeReduxFormState } from "./reactReduxForms/trialPresentationType";
import { initialTrialPublicationTypeState, ITrialPublicationTypeReduxFormState } from "./reactReduxForms/trialPublicationType";
import { initialTrialAnalysisTypeState, ITrialAnalysisTypeReduxFormState } from "./reactReduxForms/trialAnalysisType";

import { RequestState } from "../enumerations/RequestState";
import * as Dtos from "../dtos/Tmd.dtos";

import { IRegistrationFormState, ILogDataState } from "./reactReduxForms/index";


export interface IRequestState {
    status: RequestState;
    errorCode: string;
    errorMessage: string;
    errors: Dtos.ResponseError[];
    meta: {[index: string]: string};
}

export interface IFormFilter {
    search: string;
    showInActive: boolean;
}

interface IRegistrationFormsState {
    websiteSettings: IWebsiteSettingsState;
    home: IHomeState;
    trials: ITrialState;
    itemComponent: IItemComponentState;
    categories: ICategoryState;
    countries: ICountryState;
    institutionCodings: IInstitutionCodingState;
    noteTypes: INoteTypeState;
    notes: INoteState;
    trialTypes: ITrialTypeState;
    itemComponentTypes: IItemComponentTypeState;
    groups: IGroupState;
    groupItem: IGroupItemState;
    collection: ICollectionState;
    collectionInstance: ICollectionInstanceState;
    trialItem: ITrialItemState;
    trialGroup: ITrialGroupState;
    trialGroupItem: ITrialGroupItemState;
    institutions: IInstitutionState;
    trialInstitution: ITrialInstitutionState;
    trialTreatment: ITrialTreatmentState;
    trialTracker: ITrialTrackerState;
    trialInstitutionItem: ITrialInstitutionItemState;
    trialInstitutionItemTemplate: ITrialInstitutionItemTemplateState;
    trialItemInstance: ITrialItemInstanceState;
    personnel: IPersonnelState;
    personnelRole: IPersonnelRoleState;
    search: ISearchState;
    trialItemImport: ITrialItemImportState;
    trialItemInstanceImport: ITrialItemInstanceImportState;
    trialItemUpdate: ITrialItemUpdateState;
    openClinicaUserRoles: IOpenClinicaUserRoleState;
    hubspot: IHubspotState;
    states: IStateState;
    institutionTypes: IInstitutionTypeState;
    siteLocations: ISiteLocationState;
    facilityAllianceMembers: IFacilityAllianceMemberState;
    keyContacts: IKeyContactState;
    trialCategorys: ITrialCategoryState;
    tumourGroups: ITumourGroupState;
    allocatedWPs: IAllocatedWPState;
    trialPhases: ITrialPhaseState;
    involvements: IInvolvementState;
    trialCoordCentres: ITrialCoordCentreState;
    collaboratingGroups: ICollaboratingGroupState;
    genders: IGenderState;
    membershipTypes: IMembershipTypeState;
    professionalDisciplines: IProfessionalDisciplineState;
    interests: IInterestState;
    salutations: ISalutationState;
    formTemplates: IFormTemplateState;
    templateTrialItems: ITemplateTrialItemState;
    patient: IPatientState;
    institutionForm: IInstitutionFormState;
    trialForm: ITrialFormState;
    roles: IRoleState;
    departments: IDepartmentState;
    departmentContacts: IDepartmentContactState;
    reportingTagCategories: IReportingTagCategoryState;
    reportingTags: IReportingTagState;
    taskTypes: ITaskTypeState;
    formTemplateFileTypes: IFormTemplateFileTypeState;
    trackerTypes: ITrackerTypeState;
    trackerMilestones: ITrackerMilestoneState;
    standardEmailTemplates: IStandardEmailTemplateState;
    trialPresentationTypes: ITrialPresentationTypeState;
    trialPublicationTypes: ITrialPublicationTypeState;
    trialAnalysisTypes: ITrialAnalysisTypeState;
}

interface IReduxFormsState {
    websiteSetting: Dtos.WebsiteSetting & IRegistrationFormState;
    home: Dtos.Home & IRegistrationFormState;
    itemComponent: Dtos.ItemComponent & IRegistrationFormState;
    collection: Dtos.Collection & IRegistrationFormState;
    collectionInstance: Dtos.CollectionInstance & IRegistrationFormState;
    trial: Dtos.Trial & IRegistrationFormState;
    trialItem: Dtos.TrialItem & IRegistrationFormState;
    trialGroup: Dtos.TrialGroup & IRegistrationFormState;
    trialGroupItem: Dtos.TrialGroupItem & IRegistrationFormState;
    group: Dtos.Group & IRegistrationFormState;
    groupItem: Dtos.GroupItem & IRegistrationFormState;
    category: Dtos.Category & IRegistrationFormState;
    country: Dtos.Country & IRegistrationFormState;
    institutionCoding: Dtos.InstitutionCoding & IRegistrationFormState;
    noteType: Dtos.NoteType & IRegistrationFormState;
    note: Dtos.Note & IRegistrationFormState;
    trialType: Dtos.TrialType & IRegistrationFormState;
    itemComponentType: Dtos.ItemComponentType & IRegistrationFormState;
    institution: Dtos.Institution & IRegistrationFormState;
    trialInstitution: Dtos.TrialInstitution & IRegistrationFormState;
    trialTreatment: Dtos.TrialTreatment & IRegistrationFormState;
    trialTracker: Dtos.TrialTracker & IRegistrationFormState;
    trialTrackerComment: Dtos.TrialTrackerComment & IRegistrationFormState;
    trialInstitutionItem: Dtos.TrialInstitutionItem & IRegistrationFormState;
    trialInstitutionItemTemplate: Dtos.TrialInstitutionItemTemplate & IRegistrationFormState;
    trialItemInstance: Dtos.TrialItemInstance & IRegistrationFormState;
    personnel: Dtos.Personnel & IRegistrationFormState;
    personnelRole: Dtos.PersonnelRole & IRegistrationFormState;
    searchResult: Dtos.SearchResult & IRegistrationFormState;
    trialItemImport: Dtos.TrialItemImport & IRegistrationFormState;
    trialItemInstanceImport: Dtos.TrialItemInstanceImport & IRegistrationFormState;
    trialItemUpdate: Dtos.TrialItemUpdate & IRegistrationFormState;
    openClinciaUserRole: Dtos.OpenClinicaUserRole & IRegistrationFormState;
    hubspot: Dtos.Hubspot & IRegistrationFormState;
    state: Dtos.State & IRegistrationFormState;
    institutionType: Dtos.InstitutionType & IRegistrationFormState;
    siteLocation: Dtos.SiteLocation & IRegistrationFormState;
    facilityAllianceMember: Dtos.FacilityAllianceMember & IRegistrationFormState;
    keyContact: Dtos.KeyContact & IRegistrationFormState;
    trialCategory: Dtos.TrialCategory & IRegistrationFormState;
    tumourGroup: Dtos.TumourGroup & IRegistrationFormState;
    allocatedWP: Dtos.AllocatedWP & IRegistrationFormState;
    trialPhase: Dtos.TrialPhase & IRegistrationFormState;
    involvement: Dtos.Involvement & IRegistrationFormState;
    trialCoordCentre: Dtos.TrialCoordCentre & IRegistrationFormState;
    collaboratingGroup: Dtos.CollaboratingGroup & IRegistrationFormState;
    gender: Dtos.Gender & IRegistrationFormState;
    membershipType: Dtos.MembershipType & IRegistrationFormState;
    professionalDiscipline: Dtos.ProfessionalDiscipline & IRegistrationFormState;
    interest: Dtos.Interest & IRegistrationFormState;
    salutation: Dtos.Salutation & IRegistrationFormState;
    formTemplate: Dtos.FormTemplate & IRegistrationFormState;
    templateTrialItem: Dtos.TemplateTrialItem & IRegistrationFormState;
    patient: Dtos.Patient & IRegistrationFormState;
    institutionForm: Dtos.InstitutionForm & IRegistrationFormState;
    trialForm: Dtos.TrialForm & IRegistrationFormState;
    role: Dtos.Role & IRegistrationFormState;
    department: Dtos.Department & IRegistrationFormState;
    departmentContact: Dtos.DepartmentContact & IRegistrationFormState;
    reportingTagCategory: Dtos.ReportingTagCategory & IRegistrationFormState;
    reportingTag: Dtos.ReportingTag & IRegistrationFormState;
    taskType: Dtos.TaskType & IRegistrationFormState;
    formTemplateFileType: Dtos.FormTemplateFileType & IRegistrationFormState;
    trackerType: Dtos.TrackerType & IRegistrationFormState;
    trackerMilestone: Dtos.TrackerMilestone & IRegistrationFormState;
    standardEmailTemplate: Dtos.StandardEmailTemplate & IRegistrationFormState;
    trialPresentationType: Dtos.TrialPresentationType & IRegistrationFormState;
    trialPublicationType: Dtos.TrialPublicationType & IRegistrationFormState;
    trialAnalysisType: Dtos.TrialAnalysisType & IRegistrationFormState;

    formState: {
        itemComponent: IItemComponentReduxFormState;
        websiteSetting: IWebsiteSettingReduxFormState;
        home: IHomeReduxFormState;
        collection: ICollectionReduxFormState
        collectionInstance: ICollectionInstanceReduxFormState;
        trial: ITrialReduxFormState;
        trialItem: ITrialItemReduxFormState;
        trialGroup: ITrialGroupReduxFormState;
        trialGroupItem: ITrialGroupItemReduxFormState;
        group: IGroupReduxFormState;
        groupItem: IGroupItemReduxFormState;
        category: ICategoryReduxFormState;
        country: ICountryReduxFormState;
        institutionCoding: IInstitutionCodingReduxFormState;
        noteType: INoteTypeReduxFormState;
        note: INoteReduxFormState;
        trialType: ITrialTypeReduxFormState;
        itemComponentType: IItemComponentTypeReduxFormState;
        institution: IInstitutionReduxFormState;
        trialInstitution: ITrialInstitutionReduxFormState;
        trialTreatment: ITrialTreatmentReduxFormState;
        trialTracker: ITrialTrackerReduxFormState;
        trialInstitutionItem: ITrialInstitutionItemReduxFormState;
        trialInstitutionItemTemplate: ITrialInstitutionItemTemplateReduxFormState;
        trialItemInstance: ITrialItemInstanceReduxFormState;
        personnel: IPersonnelReduxFormState;
        personnelRole: IPersonnelRoleReduxFormState;
        searchResult: ISearchResultReduxFormState;
        trialItemImport: ITrialItemImportReduxFormState;
        trialItemInstanceImport: ITrialItemInstanceImportReduxFormState;
        trialItemUpdate: ITrialItemUpdateReduxFormState;
        openClinicaUserRole: IOpenClinicaUserRoleReduxFormState;
        hubspot: IHubspotReduxFormState;
        state: IStateReduxFormState;
        institutionType: IInstitutionTypeReduxFormState;
        siteLocation: ISiteLocationReduxFormState;
        facilityAllianceMember: IFacilityAllianceMemberReduxFormState;
        keyContact: IKeyContactReduxFormState;
        trialCategory: ITrialCategoryReduxFormState;
        tumourGroup: ITumourGroupReduxFormState;
        allocatedWP: IAllocatedWPReduxFormState;
        trialPhase: ITrialPhaseReduxFormState;
        involvement: IInvolvementReduxFormState;
        trialCoordCentre: ITrialCoordCentreReduxFormState;
        collaboratingGroup: ICollaboratingGroupReduxFormState;
        gender: IGenderReduxFormState;
        membershipType: IMembershipTypeReduxFormState;
        professionalDiscipline: IProfessionalDisciplineReduxFormState;
        interest: IInterestReduxFormState;
        salutation: ISalutationReduxFormState;
        formTemplate: IFormTemplateReduxFormState;
        templateTrialItem: ITemplateTrialItemReduxFormState;
        patient: IPatientReduxFormState;
        institutionForm: IInstitutionFormReduxFormState;
        trialForm: ITrialFormReduxFormState;
        role: IRoleReduxFormState;
        department: IDepartmentReduxFormState;
        departmentContact: IDepartmentContactReduxFormState;
        reportingTagCategory: IReportingTagCategoryReduxFormState;
        reportingTag: IReportingTagReduxFormState;
        taskType: ITaskTypeReduxFormState;
        formTemplateFileType: IFormTemplateFileTypeReduxFormState;
        trackerType: ITrackerTypeReduxFormState;
        trackerMilestone: ITrackerMilestoneReduxFormState;
        standardEmailTemplate: IStandardEmailTemplateReduxFormState;
        trialPresentationType: ITrialPresentationTypeReduxFormState;
        trialPublicationType: ITrialPublicationTypeReduxFormState;
        trialAnalysisType: ITrialAnalysisTypeReduxFormState;
    };

}

export interface ITmdState {
    // React states
    routing: RouterState;

    user: IUserState;

    // Container states
    itemComponentPage: IItemComponentPageState;
    collectionPage: ICollectionPageState;
    trialInstitutionItemsPage: ITrailInstitutionItemsPageState;
    trialItemInstancePage: ITrailItemInstancePageState;
    institutionFormsPage: IInstitutionFormsPageState;
    trialFormsPage: ITrialFormsPageState;
    trialsPage: ITrialsPageState;
    trialGroupItemsPage: ITrailGroupItemsPageState;
    togglePage: ITogglePageState;
    pagingPage: IPagingPageState;

    // Model states 
    registrationForms: IRegistrationFormsState;
    reduxForms: IReduxFormsState;
    websiteSettings: IWebsiteSettingsState;
    home: IHomeState;
    itemComponent: IItemComponentState;
    categories: ICategoryState;
    countries: ICountryState;
    institutionCodings: IInstitutionCodingState;
    noteTypes: INoteTypeState;
    notes: INoteState;
    trialTypes: ITrialTypeState;
    itemComponentTypes: IItemComponentTypeState;
    groups: IGroupState;
    groupItem: IGroupItemState;
    collection: ICollectionState;
    collectionInstance: ICollectionInstanceState;
    trials: ITrialState;
    trialItem: ITrialItemState;
    trialGroup: ITrialGroupState;
    trialGroupItem: ITrialGroupItemState;
    institutions: IInstitutionState;
    trialInstitution: ITrialInstitutionState;
    trialTreatment: ITrialTreatmentState;
    trialTracker: ITrialTrackerState;
    trialInstitutionItem: ITrialInstitutionItemState;
    trialInstitutionItemTemplate: ITrialInstitutionItemTemplateState;
    trialItemInstance: ITrialItemInstanceState;
    personnel: IPersonnelState;
    personnelRole: IPersonnelRoleState;
    search: ISearchState;
    trialItemImport: ITrialItemImportState;
    trialItemInstanceImport: ITrialItemInstanceImportState;
    trialItemUpdate: ITrialItemUpdateState;
    openClinicaUserRole: IOpenClinicaUserRoleState;
    hubspot: IHubspotState;
    states: IStateState;
    institutionTypes: IInstitutionTypeState;
    siteLocations: ISiteLocationState;
    facilityAllianceMembers: IFacilityAllianceMemberState;
    keyContacts: IKeyContactState;
    trialCategorys: ITrialCategoryState;
    tumourGroups: ITumourGroupState;
    allocatedWPs: IAllocatedWPState;
    trialPhases: ITrialPhaseState;
    involvements: IInvolvementState;
    trialCoordCentres: ITrialCoordCentreState;
    collaboratingGroups: ICollaboratingGroupState;
    genders: IGenderState;
    membershipTypes: IMembershipTypeState;
    professionalDisciplines: IProfessionalDisciplineState;
    interests: IInterestState;
    salutations: ISalutationState;
    formTemplates: IFormTemplateState;
    templateTrialItems: ITemplateTrialItemState;
    patient: IPatientState;
    institutionForm: IInstitutionFormState;
    trialForm: ITrialFormState;
    roles: IRoleState;
    departments: IDepartmentState;
    departmentContacts: IDepartmentContactState;
    reportingTagCategories: IReportingTagCategoryState;
    reportingTags: IReportingTagState;
    taskTypes: ITaskTypeState;
    formTemplateFileTypes: IFormTemplateFileTypeState;
    trackerTypes: ITrackerTypeState;
    trackerMilestones: ITrackerMilestoneState;
    standardEmailTemplates: IStandardEmailTemplateState;
    trialPresentationTypes: ITrialPresentationTypeState;
    trialPublicationTypes: ITrialPublicationTypeState;
    trialAnalysisTypes: ITrialAnalysisTypeState;
}

/** Combines all reducers into one for the overall state.
*   We can place all related reducers together (in order) so that they are
*   easier to find whilst debugging (redux dev tools)
*/
export default combineReducers({
    // React reducers
    routing: routerReducer,

    user: user,

    // Container reducers
    itemComponentPage: itemComponentPage,
    collectionPage: collectionPage,
    trialInstitutionItemsPage: trialInstitutionItemsPage,
    trialItemInstancePage: trialItemInstancePage,
    institutionFormsPage: institutionFormsPage,
    trialFormsPage: trialFormsPage,
    trialsPage: trialsPage,
    trialGroupItemsPage: trialGroupItemsPage,
    togglePage: togglePage,
    pagingPage: pagingPage,

    // API Model reducers
    websiteSettings: websiteSettings,
    home: home,
    trials: trials,
    itemComponent: itemComponents,
    categories: categories,
    countries: countries,
    institutionCodings: institutionCodings,
    noteTypes: noteTypes,
    notes: notes,
    trialTypes: trialTypes,
    itemComponentTypes: itemComponentTypes,
    groups: groups,
    groupItem: groupItem,
    collection: collection,
    collectionInstance: collectionInstance,
    trialItem: trialItem,
    trialGroup: trialGroup,
    trialGroupItem: trialGroupItem,
    institutions: institutions,
    trialInstitution: trialInstitution,
    trialTreatment: trialTreatment,
    trialTracker: trialTracker,
    trialInstitutionItem: trialInstitutionItem,
    trialInstitutionItemTemplate: trialInstitutionItemTemplate,
    trialItemInstance: trialItemInstance,
    personnel: personnel,
    personnelRole: personnelRole,
    search: search,
    trialItemImport: trialItemImport,
    trialItemInstanceImport: trialItemInstanceImport,
    trialItemUpdate: trialItemUpdate,
    openClinicaUserRole: openClinicaUserRole,
    hubspot: hubspot,
    states: states,
    institutionTypes: institutionTypes,
    siteLocations: siteLocations,
    facilityAllianceMembers: facilityAllianceMembers,
    keyContacts: keyContacts,
    trialCategorys: trialCategorys,
    tumourGroups: tumourGroups,
    allocatedWPs: allocatedWPs,
    trialPhases: trialPhases,
    involvements: involvements,
    trialCoordCentres: trialCoordCentres,
    collaboratingGroups: collaboratingGroups,
    genders: genders,
    membershipTypes: membershipTypes,
    professionalDisciplines: professionalDisciplines,
    interests: interests,
    salutations: salutations,
    formTemplates: formTemplates,
    templateTrialItems: templateTrialItems,
    patient: patient,
    institutionForm: institutionForm,
    trialForm: trialForm,
    roles: roles,
    departments: departments,
    departmentContacts: departmentContacts,
    reportingTagCategories: reportingTagCategories,
    reportingTags: reportingTags,
    taskTypes: taskTypes,
    formTemplateFileTypes: formTemplateFileTypes,
    trackerTypes: trackerTypes,
    trackerMilestones: trackerMilestones,
    standardEmailTemplates: standardEmailTemplates,
    trialPresentationTypes: trialPresentationType,
    trialPublicationTypes: trialPublicationType,
    trialAnalysisTypes: trialAnalysisType,

    // registrationForms: combineReducers({
    //     preRegistrationForms
    // }),
    // Redux Forms. Avoid clutter in dev tools by placing down the bottom.
    reduxForms: combineForms({
        itemComponent: initialItemComponentState,
        websiteSetting: initialWebsiteSettingFormState,
        home: initialHomeFormState,
        collection: initialCollectionState,
        collectionInstance: initialCollectionInstanceState,
        trial: initialTrialState,
        trialItem: initialTrialItemState,
        trialGroup: initialTrialGroupState,
        trialGroupItem: initialTrialGroupItemState,
        group: initialGroupState,
        groupItem: initialGroupItemState,
        category: initialCategoryState,
        country: initialCountryState,
        institutionCoding: initialInstitutionCodingState,
        noteType: initialNoteTypeState,
        note: initialNoteState,
        trialType: initialTrialTypeState,
        itemComponentType: initialItemComponentTypeState,
        institution: initialInstitutionState,
        trialInstitution: initialTrialInstitutionState,
        trialTreatment: initialTrialTreatmentState,
        trialTracker: initialTrialTrackerState,
        trialInstitutionItem: initialTrialInstitutionItemState,
        trialInstitutionItemTemplate: initialTrialInstitutionItemTemplateState,
        trialItemInstance: initialTrialItemInstanceState,
        personnel: initialPersonnelState,
        personnelRole: initialPersonnelRoleState,
        searchResult: initialSearchResultState,
        trialItemImport: initialTrialItemImportState,
        trialItemInstanceImport: initialTrialItemInstanceImportState,
        trialItemUpdate: initialTrialItemUpdateState,
        openClinicaUserRole: initialOpenClinicaUserRoleState,
        hubspot: initialHubspotState,
        state: initialStateState,
        institutionType: initialInstitutionTypeState,
        siteLocation: initialSiteLocationState,
        facilityAllianceMember: initialFacilityAllianceMemberState,
        keyContact: initialKeyContactState,
        trialCategory: initialTrialCategoryState,
        tumourGroup: initialTumourGroupState,
        allocatedWP: initialAllocatedWPState,
        trialPhase: initialTrialPhaseState,
        involvement: initialInvolvementState,
        trialCoordCentre: initialTrialCoordCentreState,
        collaboratingGroup: initialCollaboratingGroupState,
        gender: initialGenderState,
        membershipType: initialMembershipTypeState,
        professionalDiscipline: initialProfessionalDisciplineState,
        interest: initialInterestState,
        salutation: initialSalutationState,
        formTemplate: initialFormTemplateState,
        templateTrialItem: initialTemplateTrialItemState,
        patient: initialPatientState,
        institutionForm: initialInstitutionFormState,
        trialForm: initialTrialFormState,
        role: initialRoleState,
        department: initialDepartmentState,
        departmentContact: initialDepartmentContactState,
        reportingTagCategory: initialReportingTagCategoryState,
        reportingTag: initialReportingTagState,
        taskType: initialTaskTypeState,
        formTemplateFileType: initialFormTemplateFileTypeState,
        trackerType: initialTrackerTypeState,
        trackerMilestone: initialTrackerMilestoneState,
        standardEmailTemplate: initialStandardEmailTemplateState,
        trialPresentationType: initialTrialPresentationTypeState,
        trialPublicationType: initialTrialPublicationTypeState,
        trialAnalysisType: initialTrialAnalysisTypeState,
    }, 'reduxForms', { key: 'formState' })
});