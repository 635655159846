import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trialItemUpdateApi = {
    getTrialItemUpdateById: (id: number, trialItemId: number): Observable<Dtos.GetTrialItemUpdateByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrialItemUpdateById = new Dtos.GetTrialItemUpdateById();

        request.id = id;
        request.trialItemId = trialItemId;

        //Send request.
        const response: Promise<Dtos.GetTrialItemUpdateByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrialItemUpdate: (trialItemUpdate: Dtos.TrialItemUpdate): Observable<Dtos.SetTrialItemUpdateResponse> => {
        //Create the request.
        let request: Dtos.SetTrialItemUpdate = new Dtos.SetTrialItemUpdate();

        request.id = trialItemUpdate.id;
        request.trialItemId = trialItemUpdate.trialItemId;
        request.trialItemUpdate = trialItemUpdate;

        // send request
        const response: Promise<Dtos.SetTrialItemUpdateResponse> = client.post(request);

        return Observable.from(response);
    }
}