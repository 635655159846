import * as Dtos from "../dtos/Tmd.dtos";

export const
    LoadNoteById: INoteLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_NOTE_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: INoteLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_NOTE_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: INoteLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_NOTE_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveNote: INoteSaveActionCreator = (formData) => {
        return {
            type: ACTION_NOTE_SAVE,
            formData
        }
    },
    SaveSuccess: INoteSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_NOTE_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: INoteSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_NOTE_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateNote: INoteCreateActionCreator = (trialId, institutionId, trialInstitutionId, personnelId, groupId) => {

        return {
            type: ACTION_NOTE_CREATE,
            trialId,
            institutionId,
            trialInstitutionId,
            personnelId,
            groupId
        }
    },
    CreateSuccess: INoteCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_NOTE_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: INoteCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_NOTE_CREATE_FAILURE,
            responseStatus
        }
    },

    Clear: INoteClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_NOTE_CLEAR,
            propertiesToClear
        }
    };



export const
    ACTION_NOTE_LOAD_BY_ID = "ACTION_NOTE_LOAD_BY_ID",
    ACTION_NOTE_LOAD_SUCCESS = "ACTION_NOTE_LOAD_SUCCESS",
    ACTION_NOTE_LOAD_FAILURE = "ACTION_NOTE_LOAD_FAILURE",

    ACTION_NOTE_SAVE = "ACTION_NOTE_SAVE",
    ACTION_NOTE_SAVE_SUCCESS = "ACTION_NOTE_SAVE_SUCCESS",
    ACTION_NOTE_SAVE_FAILURE = "ACTION_NOTE_SAVE_FAILURE",
    
    ACTION_NOTE_CREATE = "ACTION_NOTE_CREATE",
    ACTION_NOTE_CREATE_SUCCESS = "ACTION_NOTE_CREATE_SUCCESS",
    ACTION_NOTE_CREATE_FAILURE = "ACTION_NOTE_CREATE_FAILURE",

    ACTION_NOTE_CLEAR = "ACTION_NOTE_CLEAR";


export interface INoteLoadSuccessAction {
    type: "ACTION_NOTE_LOAD_SUCCESS";
    formData: Dtos.Note;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface INoteLoadSuccessActionCreator {
    (
        formData: Dtos.Note,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): INoteLoadSuccessAction;
}

export interface INoteLoadFailureAction {
    type: "ACTION_NOTE_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface INoteLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): INoteLoadFailureAction;
}

export interface INoteLoadByIdAction {
    type: "ACTION_NOTE_LOAD_BY_ID";
    id: number;
}

export interface INoteLoadByIdActionCreator {
    (id: number): INoteLoadByIdAction;
}

export interface INoteCreateAction {
    type: "ACTION_NOTE_CREATE";
    trialId: number;
    institutionId: number;
    trialInstitutionId: number;
    personnelId: number;
    groupId: number;
}

export interface INoteCreateActionCreator {
    (
        trialId: number,
        institutionId: number,
        trialInstitutionId: number,
        personnelId: number,
        groupId: number,
    ): INoteCreateAction
}

export interface INoteCreateSuccessAction {
    type: "ACTION_NOTE_CREATE_SUCCESS";
    formData: Dtos.Note;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface INoteCreateSuccessActionCreator {
    (
        formData: Dtos.Note,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): INoteCreateSuccessAction;
}

export interface INoteCreateFailureAction {
    type: "ACTION_NOTE_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface INoteCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): INoteCreateFailureAction;
}

export interface INoteClearAction {
    type: "ACTION_NOTE_CLEAR";
    propertiesToClear?: string[];
}

export interface INoteClearActionCreator {
    (propertiesToClear?: string[]): INoteClearAction;
}

export interface INoteSaveAction {
    type: "ACTION_NOTE_SAVE";
    formData: Dtos.Note;

}

export interface INoteSaveActionCreator {
    (
        formData: Dtos.Note
    ): INoteSaveAction;
}

export interface INoteSaveSuccessAction {
    type: "ACTION_NOTE_SAVE_SUCCESS";
    formData: Dtos.Note;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface INoteSaveSuccessActionCreator {
    (
        formData: Dtos.Note,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): INoteSaveSuccessAction;
}

export interface INoteSaveFailureAction {
    type: "ACTION_NOTE_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface INoteSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): INoteSaveFailureAction;
}


export type NoteAction =
    INoteLoadByIdAction |
    INoteLoadFailureAction |
    INoteLoadSuccessAction |
    INoteCreateAction |
    INoteCreateSuccessAction |
    INoteCreateFailureAction |
    INoteSaveAction |
    INoteSaveSuccessAction |
    INoteSaveFailureAction |
    INoteClearAction;
