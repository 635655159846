import * as Dtos from "../../dtos/Tmd.dtos";

export const
    setEditModalViewState: IInstitutionFormsPageSetEditModalViewStateActionCreator = (open, collectionInstanceId, name, description) => {
        return {
            type: ACTION_INSTITUTIONFORMS_PAGE_SET_EDIT_MODAL_VIEW_STATE,
            open,
            collectionInstanceId,
            name,
            description
        }
    },
    setCreateVersionModalViewState: IInstitutionFormsPageSetCreateVersionModalViewStateActionCreator = (open, institutionFormId, version) => {
        return {
            type: ACTION_INSTITUTIONFORMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE,
            open,
            institutionFormId,
            version
        }
    }

export const
    ACTION_INSTITUTIONFORMS_PAGE_SET_EDIT_MODAL_VIEW_STATE = "ACTION_INSTITUTIONFORMS_PAGE_SET_EDIT_MODAL_VIEW_STATE",
    ACTION_INSTITUTIONFORMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE = "ACTION_INSTITUTIONFORMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE"

/* Actions */

export interface IInstitutionFormsPageSetEditModalViewStateAction {
    type: "ACTION_INSTITUTIONFORMS_PAGE_SET_EDIT_MODAL_VIEW_STATE",
    open: boolean;
    collectionInstanceId: number;
    name: string;
    description: string;
}


export interface IInstitutionFormsPageSetCreateVersionModalViewStateAction {
    type: "ACTION_INSTITUTIONFORMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE",
    open: boolean;
    institutionFormId: number;
    version: string;
}

/* Action Creators */

export interface IInstitutionFormsPageSetEditModalViewStateActionCreator {
    (
        open: boolean,
        collectionIntanceId: number,
        name: string,
        description: string
    ): IInstitutionFormsPageSetEditModalViewStateAction;
}

export interface IInstitutionFormsPageSetCreateVersionModalViewStateActionCreator {
    (
        open: boolean,
        institutionFormId: number,
        version: string,
    ): IInstitutionFormsPageSetCreateVersionModalViewStateAction;
}


/* Final Export of actions */
export type InstitutionFormsPageAction =
    IInstitutionFormsPageSetEditModalViewStateAction |
    IInstitutionFormsPageSetCreateVersionModalViewStateAction;
