import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {

    ACTION_SEARCH,

    ISearchAction,
    ISearchSuccessAction,
    ISearchFailureAction,

    SearchSuccess,
    SearchFailure,

} from "../actions/search";
import { searchApi } from "../services/search";


export const
    searchEpic = (action$): Observable<ISearchSuccessAction | ISearchFailureAction> => {
        return action$
            .ofType(ACTION_SEARCH)
            .mergeMap((action: ISearchAction) => {
                return searchApi
                    .search(action.searchFor)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SearchFailure(response.responseStatus)
                        }

                        return SearchSuccess(response.search, response.formState, response.formProperties, response.lookups, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SearchFailure(error.responseStatus))
                    )
            });
    }



