import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadRole: IRoleLoadActionCreator = () => {
        return {
            type: ACTION_ROLES_LOAD
        }
    },
    LoadRoleById: IRoleLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_ROLES_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IRoleLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, modules, responseStatus) => {
        return {
            type: ACTION_ROLES_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            modules,
            responseStatus
        }
    },
    LoadFailure: IRoleLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_ROLES_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveRole: IRoleSaveActionCreator = (formData) => {
        return {
            type: ACTION_ROLES_SAVE,
            formData
        }
    },
    SaveSuccess: IRoleSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_ROLES_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IRoleSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_ROLES_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateRole: IRoleCreateActionCreator = () => {

        return {
            type: ACTION_ROLES_CREATE
        }
    },
    CreateSuccess: IRoleCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_ROLES_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: IRoleCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_ROLES_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IRoleClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_ROLES_CLEAR,
            propertiesToClear
        }
    },

    Filter: IRoleFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_ROLES_FILTER,
            formFilter
        }
    };



export const
    ACTION_ROLES_LOAD = "ACTION_ROLES_LOAD",
    ACTION_ROLES_LOAD_BY_ID = "ACTION_ROLES_LOAD_BY_ID",
    ACTION_ROLES_LOAD_SUCCESS = "ACTION_ROLES_LOAD_SUCCESS",
    ACTION_ROLES_LOAD_FAILURE = "ACTION_ROLES_LOAD_FAILURE",

    ACTION_ROLES_SAVE = "ACTION_ROLES_SAVE",
    ACTION_ROLES_SAVE_SUCCESS = "ACTION_ROLES_SAVE_SUCCESS",
    ACTION_ROLES_SAVE_FAILURE = "ACTION_ROLES_SAVE_FAILURE",

    ACTION_ROLES_CREATE = "ACTION_ROLES_CREATE",
    ACTION_ROLES_CREATE_SUCCESS = "ACTION_ROLES_CREATE_SUCCESS",
    ACTION_ROLES_CREATE_FAILURE = "ACTION_ROLES_CREATE_FAILURE",

    ACTION_ROLES_CLEAR = "ACTION_ROLES_CLEAR",

    ACTION_ROLES_FILTER = "ACTION_ROLES_FILTER";

export interface IRoleLoadAction {
    type: "ACTION_ROLES_LOAD";
}

export interface IRoleLoadActionCreator {
    (): IRoleLoadAction;
}

export interface IRoleLoadSuccessAction {
    type: "ACTION_ROLES_LOAD_SUCCESS";
    formData: Dtos.Role | Dtos.Role[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    modules: Dtos.ModulePermission[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IRoleLoadSuccessActionCreator {
    (
        formData: Dtos.Role | Dtos.Role[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        modules: Dtos.ModulePermission[],
        responseStatus: Dtos.ResponseStatus
    ): IRoleLoadSuccessAction;
}

export interface IRoleLoadFailureAction {
    type: "ACTION_ROLES_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IRoleLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IRoleLoadFailureAction;
}

export interface IRoleLoadByIdAction {
    type: "ACTION_ROLES_LOAD_BY_ID";
    id: number;
}

export interface IRoleLoadByIdActionCreator {
    (id: number): IRoleLoadByIdAction;
}


export interface IRoleSaveAction {
    type: "ACTION_ROLES_SAVE";
    formData: Dtos.Role;

}

export interface IRoleSaveActionCreator {
    (
        formData: Dtos.Role
    ): IRoleSaveAction;
}

export interface IRoleSaveSuccessAction {
    type: "ACTION_ROLES_SAVE_SUCCESS";
    formData: Dtos.Role;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IRoleSaveSuccessActionCreator {
    (
        formData: Dtos.Role,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IRoleSaveSuccessAction;
}

export interface IRoleSaveFailureAction {
    type: "ACTION_ROLES_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IRoleSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IRoleSaveFailureAction;
}

export interface IRoleCreateAction {
    type: "ACTION_ROLES_CREATE"
}

export interface IRoleCreateActionCreator {
    (): IRoleCreateAction
}

export interface IRoleCreateSuccessAction {
    type: "ACTION_ROLES_CREATE_SUCCESS";
    formData: Dtos.Role;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface IRoleCreateSuccessActionCreator {
    (
        formData: Dtos.Role,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): IRoleCreateSuccessAction;
}

export interface IRoleCreateFailureAction {
    type: "ACTION_ROLES_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IRoleCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IRoleCreateFailureAction;
}

export interface IRoleClearAction {
    type: "ACTION_ROLES_CLEAR";
    propertiesToClear?: string[];
}

export interface IRoleClearActionCreator {
    (propertiesToClear?: string[]): IRoleClearAction;
}

export interface IRoleFilterActionCreator {
    (formFilter: IFormFilter): IRoleFilterAction;
}

export interface IRoleFilterAction {
    type: "ACTION_ROLES_FILTER";
    formFilter: IFormFilter;
}

export type RoleAction =
    IRoleLoadAction |
    IRoleLoadByIdAction |
    IRoleLoadFailureAction |
    IRoleLoadSuccessAction |
    IRoleSaveAction |
    IRoleSaveSuccessAction |
    IRoleSaveFailureAction |
    IRoleCreateAction |
    IRoleCreateSuccessAction |
    IRoleCreateFailureAction |
    IRoleFilterAction | 
    IRoleClearAction;
