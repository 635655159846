import './Footer.scss';

import * as React from 'react';

interface IFooterProps {

}

class Footer extends React.PureComponent<IFooterProps, any> {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps: any) {
    }

    componentDidUpdate(prevProps: any) {

    }

    componentWillUpdate(nextProps: any) {
    }

    componentWillUnmount() {
    }

    render() {

        return (<div className="footer mb-4 py-2 px-4">
            <div className="d-flex justify-content-center">
                <small>Copyright&nbsp;&copy;&nbsp;{ new Date().getFullYear().toString() } Trans Tasman Radiation Oncology Group (TROG).</small>
            </div>
        </div>)
    }
}

export default Footer;