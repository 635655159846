import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_PERSONNEL_LOAD,
    ACTION_PERSONNEL_LOAD_BY_ID,
    ACTION_PERSONNEL_SECURITY_LOAD_BY_ID,

    ACTION_PERSONNEL_SAVE,

    ACTION_PERSONNEL_SAVE_ACTIVE_DIRECTORY,

    ACTION_PERSONNEL_CREATE,

    ACTION_PERSONNEL_RESENDEMAIL,

    ACTION_PERSONNEL_PERMISSIONS_LOAD_BY_ID,

    ACTION_PERSONNEL_BY_ROLE_LOAD,

    IPersonnelLoadAction,
    IPersonnelLoadByIdAction,
    IPersonnelSecurityLoadByIdAction,
    IPersonnelLoadFailureAction,
    IPersonnelLoadSuccessAction,

    IPersonnelaveAction,
    IPersonnelaveSuccessAction,
    IPersonnelaveFailureAction,

    IPersonnelSaveActiveDirectoryAction,
    IPersonnelSaveActiveDirectorySuccessAction,
    IPersonnelSaveActiveDirectoryFailureAction,

    IPersonnelCreateAction,
    IPersonnelCreateSuccessAction,
    IPersonnelCreateFailureAction,

    IPersonnelResendEmailAction,
    IPersonnelResendEmailSuccessAction,
    IPersonnelResendEmailFailureAction,

    IPersonnelPermissionsLoadByIdAction,
    IPersonnelPermissionsLoadFailureAction,
    IPersonnelPermissionsLoadSuccessAction,

    IPersonnelByRoleLoadAction,
    IPersonnelByRoleLoadSuccessAction,
    IPersonnelByRoleLoadFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    SaveActiveDirectory,
    SaveActiveDirectorySuccess,
    SaveActiveDirectoryFailure,

    CreateSuccess,
    CreateFailure,

    ResendEmailSuccess,
    ResendEmailFailure,

    LoadPermissionsSuccess,
    LoadPermissionsFailure,

    LoadPersonnelByRoleSuccess,
    LoadPersonnelByRoleFailure

} from "../actions/personnel";
import { personnelApi } from "../services/personnel";


export const
    getPersonnelEpic = (action$): Observable<IPersonnelLoadSuccessAction | IPersonnelLoadFailureAction> => {
        return action$
            .ofType(ACTION_PERSONNEL_LOAD)
            .mergeMap((action: IPersonnelLoadAction) => {
                return personnelApi
                    .getPersonnel()
                    .map(response =>
                        LoadSuccess(response.personnel, undefined, undefined, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getPersonnelByIdEpic = (action$): Observable<IPersonnelLoadSuccessAction | IPersonnelLoadFailureAction> => {
        return action$
            .ofType(ACTION_PERSONNEL_LOAD_BY_ID)
            .mergeMap((action: IPersonnelLoadByIdAction) => {
                return personnelApi
                    .getPersonnelById(action.id)
                    .map(response =>
                        LoadSuccess(response.personnel, response.formState, response.formProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getPersonnelSecurityByIdEpic = (action$): Observable<IPersonnelLoadSuccessAction | IPersonnelLoadFailureAction> => {
        return action$
            .ofType(ACTION_PERSONNEL_SECURITY_LOAD_BY_ID)
            .mergeMap((action: IPersonnelSecurityLoadByIdAction) => {
                return personnelApi
                    .getPersonnelSecurityById(action.id)
                    .map(response =>
                        LoadSuccess(response.personnel, response.formState, response.formProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setPersonnelEpic = (action$): Observable<IPersonnelaveSuccessAction | IPersonnelaveFailureAction> => {
        return action$
            .ofType(ACTION_PERSONNEL_SAVE)
            .mergeMap((action: IPersonnelaveAction) => {
                return personnelApi
                    .setPersonnel(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.personnel, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createPersonnelEpic = (action$): Observable<IPersonnelCreateSuccessAction | IPersonnelCreateFailureAction> => {
        return action$
            .ofType(ACTION_PERSONNEL_CREATE)
            .mergeMap((action: IPersonnelCreateAction) => {
                return personnelApi
                    .createPersonnel()
                    .map(response =>
                        CreateSuccess(response.personnel, response.formState, response.formProperties, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    },
    setPersonnelActiveDirectoryEpic = (action$): Observable<IPersonnelSaveActiveDirectorySuccessAction | IPersonnelSaveActiveDirectoryFailureAction> => {
        return action$
            .ofType(ACTION_PERSONNEL_SAVE_ACTIVE_DIRECTORY)
            .mergeMap((action: IPersonnelSaveActiveDirectoryAction) => {
            return personnelApi
                .setPersonnelActiveDirectory(action.formData)
                .map(response => {
                    if (response.responseStatus &&
                        response.responseStatus.meta &&
                        response.responseStatus.meta.blockSave &&
                        response.responseStatus.meta.blockSave == "1") {
                        return SaveActiveDirectoryFailure(response.responseStatus)
                    }
                    return SaveActiveDirectorySuccess(response.personnel, response.formState, response.responseStatus)
                })
                .catch(error =>
                    Observable.of(SaveActiveDirectoryFailure(error.responseStatus))
                )
        });
    },
    postPersonnelResendEmailEpic = (action$): Observable<IPersonnelResendEmailSuccessAction | IPersonnelResendEmailFailureAction> => {
        return action$
            .ofType(ACTION_PERSONNEL_RESENDEMAIL)
            .mergeMap((action: IPersonnelResendEmailAction) => {
                return personnelApi
                    .resendEmail(action.id)
                    .map(response => {
                        return ResendEmailSuccess(response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(ResendEmailFailure(error.responseStatus))
                    )
            });
    },
    getPersonnelPermissionsByIdEpic = (action$): Observable<IPersonnelPermissionsLoadSuccessAction | IPersonnelPermissionsLoadFailureAction> => {
        return action$
            .ofType(ACTION_PERSONNEL_PERMISSIONS_LOAD_BY_ID)
            .mergeMap((action: IPersonnelPermissionsLoadByIdAction) => {
                return personnelApi
                    .getPersonnelPermissionsById(action.id)
                    .map(response =>
                        LoadPermissionsSuccess(response.groupedPersonnelPermissions, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadPermissionsFailure(error.responseStatus))
                    )
            });
    },
    getPersonnelByRoleEpic = (action$): Observable<IPersonnelByRoleLoadSuccessAction | IPersonnelByRoleLoadFailureAction> => {
        return action$
            .ofType(ACTION_PERSONNEL_BY_ROLE_LOAD)
            .mergeMap((action: IPersonnelByRoleLoadAction) => {
                return personnelApi
                    .getPersonnelByRole()
                    .map(response =>
                        LoadPersonnelByRoleSuccess(response.rolePersonnelGroupings, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadPersonnelByRoleFailure(error.responseStatus))
                    )
            });
    }



