import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";


export const
    LoadSalutation: ISalutationLoadActionCreator = () => {
        return {
            type: ACTION_SALUTATIONS_LOAD
        }
    },
    LoadSalutationById: ISalutationLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_SALUTATIONS_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ISalutationLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_SALUTATIONS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ISalutationLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_SALUTATIONS_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveSalutation: ISalutationSaveActionCreator = (formData) => {
        return {
            type: ACTION_SALUTATIONS_SAVE,
            formData
        }
    },
    SaveSuccess: ISalutationSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_SALUTATIONS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ISalutationSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_SALUTATIONS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateSalutation: ISalutationCreateActionCreator = () => {

        return {
            type: ACTION_SALUTATIONS_CREATE
        }
    },
    CreateSuccess: ISalutationCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_SALUTATIONS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: ISalutationCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_SALUTATIONS_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: ISalutationClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_SALUTATIONS_CLEAR,
            propertiesToClear
        }
    },
    Filter: ISalutationFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_SALUTATIONS_FILTER,
            formFilter
        }
    };



export const
    ACTION_SALUTATIONS_LOAD = "ACTION_SALUTATIONS_LOAD",
    ACTION_SALUTATIONS_LOAD_BY_ID = "ACTION_SALUTATIONS_LOAD_BY_ID",
    ACTION_SALUTATIONS_LOAD_SUCCESS = "ACTION_SALUTATIONS_LOAD_SUCCESS",
    ACTION_SALUTATIONS_LOAD_FAILURE = "ACTION_SALUTATIONS_LOAD_FAILURE",

    ACTION_SALUTATIONS_SAVE = "ACTION_SALUTATIONS_SAVE",
    ACTION_SALUTATIONS_SAVE_SUCCESS = "ACTION_SALUTATIONS_SAVE_SUCCESS",
    ACTION_SALUTATIONS_SAVE_FAILURE = "ACTION_SALUTATIONS_SAVE_FAILURE",

    ACTION_SALUTATIONS_CREATE = "ACTION_SALUTATIONS_CREATE",
    ACTION_SALUTATIONS_CREATE_SUCCESS = "ACTION_SALUTATIONS_CREATE_SUCCESS",
    ACTION_SALUTATIONS_CREATE_FAILURE = "ACTION_SALUTATIONS_CREATE_FAILURE",

    ACTION_SALUTATIONS_CLEAR = "ACTION_SALUTATIONS_CLEAR",

    ACTION_SALUTATIONS_FILTER = "ACTION_SALUTATIONS_FILTER";

export interface ISalutationLoadAction {
    type: "ACTION_SALUTATIONS_LOAD";
}

export interface ISalutationLoadActionCreator {
    (): ISalutationLoadAction;
}

export interface ISalutationLoadSuccessAction {
    type: "ACTION_SALUTATIONS_LOAD_SUCCESS";
    formData: Dtos.Salutation | Dtos.Salutation[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ISalutationLoadSuccessActionCreator {
    (
        formData: Dtos.Salutation | Dtos.Salutation[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ISalutationLoadSuccessAction;
}

export interface ISalutationLoadFailureAction {
    type: "ACTION_SALUTATIONS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ISalutationLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ISalutationLoadFailureAction;
}

export interface ISalutationLoadByIdAction {
    type: "ACTION_SALUTATIONS_LOAD_BY_ID";
    id: number;
}

export interface ISalutationLoadByIdActionCreator {
    (id: number): ISalutationLoadByIdAction;
}


export interface ISalutationSaveAction {
    type: "ACTION_SALUTATIONS_SAVE";
    formData: Dtos.Salutation;

}

export interface ISalutationSaveActionCreator {
    (
        formData: Dtos.Salutation
    ): ISalutationSaveAction;
}

export interface ISalutationSaveSuccessAction {
    type: "ACTION_SALUTATIONS_SAVE_SUCCESS";
    formData: Dtos.Salutation;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ISalutationSaveSuccessActionCreator {
    (
        formData: Dtos.Salutation,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ISalutationSaveSuccessAction;
}

export interface ISalutationSaveFailureAction {
    type: "ACTION_SALUTATIONS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ISalutationSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ISalutationSaveFailureAction;
}

export interface ISalutationCreateAction {
    type: "ACTION_SALUTATIONS_CREATE"
}

export interface ISalutationCreateActionCreator {
    (): ISalutationCreateAction
}

export interface ISalutationCreateSuccessAction {
    type: "ACTION_SALUTATIONS_CREATE_SUCCESS";
    formData: Dtos.Salutation;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface ISalutationCreateSuccessActionCreator {
    (
        formData: Dtos.Salutation,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): ISalutationCreateSuccessAction;
}

export interface ISalutationCreateFailureAction {
    type: "ACTION_SALUTATIONS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ISalutationCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ISalutationCreateFailureAction;
}

export interface ISalutationClearAction {
    type: "ACTION_SALUTATIONS_CLEAR";
    propertiesToClear?: string[];
}

export interface ISalutationClearActionCreator {
    (propertiesToClear?: string[]): ISalutationClearAction;
}

export interface ISalutationFilterActionCreator {
    (formFilter: IFormFilter): ISalutationFilterAction;
}

export interface ISalutationFilterAction {
    type: "ACTION_SALUTATIONS_FILTER";
    formFilter: IFormFilter;
}

export type SalutationAction =
    ISalutationLoadAction |
    ISalutationLoadByIdAction |
    ISalutationLoadFailureAction |
    ISalutationLoadSuccessAction |
    ISalutationSaveAction |
    ISalutationSaveSuccessAction |
    ISalutationSaveFailureAction |
    ISalutationCreateAction |
    ISalutationCreateSuccessAction |
    ISalutationCreateFailureAction |
    ISalutationClearAction |
    ISalutationFilterAction;
