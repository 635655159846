import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadInterest: IInterestLoadActionCreator = () => {
        return {
            type: ACTION_INTERESTS_LOAD
        }
    },
    LoadInterestById: IInterestLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_INTERESTS_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IInterestLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_INTERESTS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IInterestLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_INTERESTS_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveInterest: IInterestSaveActionCreator = (formData) => {
        return {
            type: ACTION_INTERESTS_SAVE,
            formData
        }
    },
    SaveSuccess: IInterestSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_INTERESTS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IInterestSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_INTERESTS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateInterest: IInterestCreateActionCreator = () => {

        return {
            type: ACTION_INTERESTS_CREATE
        }
    },
    CreateSuccess: IInterestCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_INTERESTS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: IInterestCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_INTERESTS_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IInterestClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_INTERESTS_CLEAR,
            propertiesToClear
        }
    },
    Filter: IInterestFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_INTERESTS_FILTER,
            formFilter
        }
    };



export const
    ACTION_INTERESTS_LOAD = "ACTION_INTERESTS_LOAD",
    ACTION_INTERESTS_LOAD_BY_ID = "ACTION_INTERESTS_LOAD_BY_ID",
    ACTION_INTERESTS_LOAD_SUCCESS = "ACTION_INTERESTS_LOAD_SUCCESS",
    ACTION_INTERESTS_LOAD_FAILURE = "ACTION_INTERESTS_LOAD_FAILURE",

    ACTION_INTERESTS_SAVE = "ACTION_INTERESTS_SAVE",
    ACTION_INTERESTS_SAVE_SUCCESS = "ACTION_INTERESTS_SAVE_SUCCESS",
    ACTION_INTERESTS_SAVE_FAILURE = "ACTION_INTERESTS_SAVE_FAILURE",

    ACTION_INTERESTS_CREATE = "ACTION_INTERESTS_CREATE",
    ACTION_INTERESTS_CREATE_SUCCESS = "ACTION_INTERESTS_CREATE_SUCCESS",
    ACTION_INTERESTS_CREATE_FAILURE = "ACTION_INTERESTS_CREATE_FAILURE",

    ACTION_INTERESTS_CLEAR = "ACTION_INTERESTS_CLEAR",

    ACTION_INTERESTS_FILTER = "ACTION_INTERESTS_FILTER";

export interface IInterestLoadAction {
    type: "ACTION_INTERESTS_LOAD";
}

export interface IInterestLoadActionCreator {
    (): IInterestLoadAction;
}

export interface IInterestLoadSuccessAction {
    type: "ACTION_INTERESTS_LOAD_SUCCESS";
    formData: Dtos.Interest | Dtos.Interest[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IInterestLoadSuccessActionCreator {
    (
        formData: Dtos.Interest | Dtos.Interest[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IInterestLoadSuccessAction;
}

export interface IInterestLoadFailureAction {
    type: "ACTION_INTERESTS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IInterestLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IInterestLoadFailureAction;
}

export interface IInterestLoadByIdAction {
    type: "ACTION_INTERESTS_LOAD_BY_ID";
    id: number;
}

export interface IInterestLoadByIdActionCreator {
    (id: number): IInterestLoadByIdAction;
}


export interface IInterestSaveAction {
    type: "ACTION_INTERESTS_SAVE";
    formData: Dtos.Interest;

}

export interface IInterestSaveActionCreator {
    (
        formData: Dtos.Interest
    ): IInterestSaveAction;
}

export interface IInterestSaveSuccessAction {
    type: "ACTION_INTERESTS_SAVE_SUCCESS";
    formData: Dtos.Interest;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IInterestSaveSuccessActionCreator {
    (
        formData: Dtos.Interest,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IInterestSaveSuccessAction;
}

export interface IInterestSaveFailureAction {
    type: "ACTION_INTERESTS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IInterestSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IInterestSaveFailureAction;
}

export interface IInterestCreateAction {
    type: "ACTION_INTERESTS_CREATE"
}

export interface IInterestCreateActionCreator {
    (): IInterestCreateAction
}

export interface IInterestCreateSuccessAction {
    type: "ACTION_INTERESTS_CREATE_SUCCESS";
    formData: Dtos.Interest;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface IInterestCreateSuccessActionCreator {
    (
        formData: Dtos.Interest,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): IInterestCreateSuccessAction;
}

export interface IInterestCreateFailureAction {
    type: "ACTION_INTERESTS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IInterestCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IInterestCreateFailureAction;
}

export interface IInterestClearAction {
    type: "ACTION_INTERESTS_CLEAR";
    propertiesToClear?: string[];
}

export interface IInterestClearActionCreator {
    (propertiesToClear?: string[]): IInterestClearAction;
}

export interface IInterestFilterActionCreator {
    (formFilter: IFormFilter): IInterestFilterAction;
}

export interface IInterestFilterAction {
    type: "ACTION_INTERESTS_FILTER";
    formFilter: IFormFilter;
}

export type InterestAction =
    IInterestLoadAction |
    IInterestLoadByIdAction |
    IInterestLoadFailureAction |
    IInterestLoadSuccessAction |
    IInterestSaveAction |
    IInterestSaveSuccessAction |
    IInterestSaveFailureAction |
    IInterestCreateAction |
    IInterestCreateSuccessAction |
    IInterestCreateFailureAction |
    IInterestClearAction |
    IInterestFilterAction;
