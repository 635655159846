import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialSearchResultState: Dtos.SearchResult & IRegistrationFormState = {
    searchFor: null,
    trials: [],
    institutions: [],
    institutionTrials: [],
    personnel: [],
    saveAndReturn: false,
    active: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null
}

export interface ISearchResultReduxFormState {
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>,
    institutions: IFieldState<Dtos.Institution[]>,
    trials: IFieldState<Dtos.Trial[]>,
    institutionTrials: IFieldState<Dtos.TrialInstitution[]>,
    personnel: IFieldState<Dtos.Personnel[]>,
}
