import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialDepartmentState: Dtos.Department & IRegistrationFormState = {
    id: null,
    institutionId: null,
    institution: null,
    name: null,
    address1: null,
    address2: null,
    suburb: null,
    postcode: null,
    stateId: null,
    state: null,
    stateOther: null,
    countryId: null,
    country: null,
    mailAddress1: null,
    mailAddress2: null,
    mailSuburb: null,
    mailPostcode: null,
    mailStateId: null,
    mailState: null,
    mailStateOther: null,
    mailCountryId: null,
    mailCountry: null,
    phone: null,
    fax: null,
    departmentContacts: [],
    personnelRoles: [],
    active: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false
}

export interface IDepartmentReduxFormState {
    id: IFieldState<number>,
    institutionId: IFieldState<number>,
    institution: IFieldState<Dtos.Institution>,
    name: IFieldState<string>,
    address1: IFieldState<string>,
    address2: IFieldState<string>,
    suburb: IFieldState<string>,
    postcode: IFieldState<string>,
    stateId: IFieldState<number>,
    stateOther: IFieldState<string>,
    countryId: IFieldState<number>,
    mailAddress1: IFieldState<string>,
    mailAddress2: IFieldState<string>,
    mailSuburb: IFieldState<string>,
    mailPostcode: IFieldState<string>,
    mailStateId: IFieldState<number>,
    mailStateOther: IFieldState<string>,
    mailCountryId: IFieldState<number>,
    phone: IFieldState<string>,
    fax: IFieldState<string>,
    departmentContacts: IFieldState<Dtos.DepartmentContact[]>,
    personnelRoles: IFieldState<Dtos.PersonnelRole[]>,
    active: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
}
