import './RestrictedLayout.scss';

import * as React from 'react';
import * as Dtos from '../../dtos/Tmd.dtos';
import { DefaultLayout, INavItem, SubMenu, SubMenu2, Footer } from "./index";
import { InlineLoading, NoAccessMessage, OverlayLoading } from "../common/index";
import { connect } from "react-redux";
import { ITmdState } from "../../reducers/index";
import * as AuthenticationHelper from '../../helpers/authentication';
import { FontAwesomeIcon, FontAwesomeIcons } from "../../constants/fontAwesomeIcons";

interface IRestrictedLayoutProps {
    subMenuTitle?: string;
    subMenuItems?: INavItem[];
    sub2MenuTitle?: string;
    sub2MenuItems?: INavItem[];
    loading?: boolean;
    saving?: boolean;
    noTopMargin?: boolean;
    permissions?: Dtos.PersonnelTmdPermissions[];
    permission?: Dtos.Permission;
    trialId?: number;
    institutionId?: number;
    anyMatchpermission?: Dtos.Permission;
    children?: any;
}

interface IRestrictedLayoutActions {

}

type RestrictedLayoutProps = IRestrictedLayoutProps & IRestrictedLayoutActions;

class RestrictedLayout extends React.PureComponent<RestrictedLayoutProps, any> {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps: any) {
    }

    componentDidUpdate(prevProps: any) {

    }

    componentWillUpdate(nextProps: any) {
    }

    componentWillUnmount() {
    }

    render() {
        const {
            subMenuTitle,
            subMenuItems,
            sub2MenuTitle,
            sub2MenuItems,
            loading,
            saving,
            noTopMargin,
            permission,
            anyMatchpermission,
            permissions,
            trialId,
            institutionId
        } = this.props;

        const compactMenu1 = subMenuTitle === undefined;
        const compactMenu2 = sub2MenuTitle === undefined;
        let permissionDenied = false;
        if (permissions && permissions.length == 0 && permission) {
            permissionDenied = true;
        }
        if (permissions.length > 0 && permission && !AuthenticationHelper.hasPermission(permissions, permission, trialId, institutionId)) {
            permissionDenied = true;
        }
        if (permissions.length > 0 && anyMatchpermission && !AuthenticationHelper.hasPermissionAnyMatch(permissions, anyMatchpermission)) {
            permissionDenied = true;
        }

        if (permissions && permissions.length == 0) {
            return <DefaultLayout>
                <NoAccessMessage text={ ""} />
            </DefaultLayout>;
        }

        return <DefaultLayout>
            {
                !loading && subMenuItems && subMenuItems.length > 0 ?
                    <SubMenu loading={loading} items={subMenuItems} subMenuTitle={subMenuTitle} /> :
                    subMenuItems ? compactMenu1 ? <div className="container"><div className="sub-menu-place-holder-compact"></div></div> : <div className="container"><div className="sub-menu-place-holder"></div></div> : undefined
            }
            {
                !loading && sub2MenuItems && sub2MenuItems.length > 0 ?
                    <SubMenu2 loading={loading} items={sub2MenuItems} subMenuTitle={sub2MenuTitle} /> :
                    sub2MenuItems ? compactMenu2 ? <div className="container"><div className="sub-menu-2-place-holder-compact"></div></div>  : <div className="container"><div className="sub-menu-2-place-holder"></div></div> : undefined
            }
            <div className={noTopMargin ? "container bg-white pb-3 box-shadow-x" : "container bg-white py-3 box-shadow-x"} style={{ minHeight: "300px", position: loading ? "relative" : undefined }}>
                {
                    loading ?
                        <InlineLoading absoluteCenter={true} size={40} /> :
                        <div>
                            {
                                saving ? 
                                    <OverlayLoading size={40} /> :
                                    null
                            }
                            { permissionDenied ?
                                <div className="alert alert-warning d-flex">
                                    <div className="mr-2">
                                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                                    </div>
                                    <span>Permission Denied</span>
                                </div>
                                : this.props.children}
                        </div>
                }
            </div>
            <div className="container bg-white p-0 box-shadow-x">
                <Footer></Footer>
            </div>
        </DefaultLayout>
    }
}


const mapStateToProps = (state: ITmdState, ownProps: RestrictedLayoutProps): IRestrictedLayoutProps => {
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];
    return {
        permissions: permissions,
        permission: ownProps.permission,
        loading: ownProps.loading,
        children: ownProps.children,
        subMenuTitle: ownProps.subMenuTitle,
        subMenuItems: ownProps.subMenuItems,
        sub2MenuTitle: ownProps.sub2MenuTitle,
        sub2MenuItems: ownProps.sub2MenuItems,
        saving: ownProps.saving, 
        noTopMargin: ownProps.noTopMargin,
    };
};

const mapDispatchToProps = (dispatch): IRestrictedLayoutActions => {
    return {

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(RestrictedLayout);

//export default RestrictedLayout;