import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Tmd.dtos';

import {
    ACTION_STATES_LOAD,
    ACTION_STATES_LOAD_BY_ID,
    ACTION_STATES_LOAD_FAILURE,
    ACTION_STATES_LOAD_SUCCESS,

    ACTION_STATES_SAVE,
    ACTION_STATES_SAVE_SUCCESS,
    ACTION_STATES_SAVE_FAILURE,

    ACTION_STATES_CREATE,
    ACTION_STATES_CREATE_SUCCESS,
    ACTION_STATES_CREATE_FAILURE,

    ACTION_STATES_CLEAR,
    ACTION_STATES_FILTER,

    StateAction
} from '../actions/states';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState, IFormFilter } from "./index";
import { updateFailureIndexToId } from "../helpers/formErrorHelper";

export interface IStateState {
    formData: Dtos.State | Dtos.State[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[],
    validationFailures: Dtos.ResponseError[];
    lookups: Dtos.Lookup[];
    loadState: IRequestState;
    saveState: IRequestState;
    updateState: IRequestState;
    createState: IRequestState;
    formFilter: IFormFilter;
    formFilteredData: Dtos.State[];
}

const initialState: IStateState = {
    formData: undefined,
    formState: undefined,
    formProperties: undefined,
    formRoles: undefined,
    validationFailures: undefined,
    lookups: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    updateState: {
        status: RequestState.None
    } as IRequestState,
    createState: {
        status: RequestState.None
    } as IRequestState,
    formFilter: {
        search: "",
        showInActive: false
    },
    formFilteredData: []
};


function loading(state: IStateState, isLoading: boolean): IStateState {
    const newState: IStateState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(state: IStateState,
    formData: Dtos.State | Dtos.State[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    formRoles: Dtos.RoleType[],
    lookups: Dtos.Lookup[],
    responseStatus: Dtos.ResponseStatus
): IStateState {
    const newState: IStateState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        formRoles: {
            $set: formRoles
        },
        lookups: {
            $set: lookups
        },
        loadState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return filter(newState, state.formFilter);
}

function filter(state: IStateState, formFilter: IFormFilter): IStateState {

    const states: Dtos.State[] | undefined =
        state.formData instanceof Array ?
            state.formData :
            undefined;

    const formFilteredData = states ? states.filter(p => (p.active == true || formFilter.showInActive) &&
        (formFilter.search == "" || p.value.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1)) : undefined;

    const newState: IStateState = update(state, {
        formFilter: {
            $set: formFilter
        },
        formFilteredData: {
            $set: formFilteredData
        }
    });

    return newState;
}

function loadFailure(state: IStateState, responseStatus: Dtos.ResponseStatus): IStateState {
    const newState: IStateState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: IStateState, propertiesToClear?: string[]): IStateState {

    if (!propertiesToClear) {
        let formFilter = state.formFilter;
        const newState: IStateState = update(initialState, {
            formFilter: {
                $set: formFilter
            }
        });
        return newState;
    }

    let stateModifier: any = {};

    propertiesToClear.forEach(propertyToClear => {
        stateModifier[propertyToClear] = { $set: initialState[propertyToClear] }
    });

    const newState = update(state, stateModifier);

    return newState;
}

function saving(state: IStateState, isSaving: boolean): IStateState {
    const newState: IStateState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function saveSuccess(
    state: IStateState,
    formData: Dtos.State | Dtos.State[],
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus
): IStateState {
    const newState: IStateState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        saveState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function saveFailure(state: IStateState, responseStatus: Dtos.ResponseStatus): IStateState {
    const newState: IStateState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                meta: responseStatus.meta
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, state.formData) : undefined
        }
    });

    return newState;
}

function creating(state: IStateState, isCreating: boolean): IStateState {
    const newState: IStateState = update(state, {
        createState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function createSuccess(
    state: IStateState,
    formData: Dtos.State,
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    formRoles: Dtos.RoleType[],
    lookups: Dtos.Lookup[]
): IStateState {
    const newState: IStateState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        formRoles: {
            $set: formRoles
        },
        lookups: {
            $set: lookups
        },
        createState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function createFailure(state: IStateState, responseStatus: Dtos.ResponseStatus): IStateState {
    const newState: IStateState = update(state, {
        createState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

const StateReducer: Reducer<IStateState> = (state: IStateState = initialState, action: StateAction) => {
    switch (action.type) {
        case ACTION_STATES_LOAD:
        case ACTION_STATES_LOAD_BY_ID:
            return loading(state, true);
        case ACTION_STATES_LOAD_SUCCESS:
            return loadSuccess(state, action.formData, action.formState, action.formProperties, action.formRoles, action.lookups, action.responseStatus);
       case ACTION_STATES_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_STATES_CLEAR:
            return clear(state, action.propertiesToClear);
        case ACTION_STATES_SAVE:
            return saving(state, true);
        case ACTION_STATES_SAVE_SUCCESS:
            return saveSuccess(state, action.formData, action.formState, action.responseStatus);
        case ACTION_STATES_SAVE_FAILURE:
            return saveFailure(state, action.responseStatus);
        case ACTION_STATES_CREATE:
            return creating(state, true);
        case ACTION_STATES_CREATE_SUCCESS:
            return createSuccess(state, action.formData, action.formState, action.formProperties, action.formRoles, action.lookups);
        case ACTION_STATES_CREATE_FAILURE:
            return createFailure(state, action.responseStatus);
        case ACTION_STATES_FILTER:
            return filter(state, action.formFilter);

    }

    return state;
}

export default StateReducer;