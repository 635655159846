import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_COLLECTION_LOAD_BY_ID,
    ACTION_COLLECTION_CREATE,
    ACTION_COLLECTION_LOCK,

    ICollectionLoadByIdAction,
    ICollectionLoadFailureAction,
    ICollectionLoadSuccessAction,

    ICollectionCreateAction,
    ICollectionCreateFailureAction,
    ICollectionCreateSuccessAction,

    ICollectionLockAction,
    ICollectionLockFailureAction,
    ICollectionLockSuccessAction,

    LoadSuccess,
    LoadFailure,

    CreateSuccess,
    CreateFailure,

    LockSuccess,
    LockFailure,

} from "../actions/collection";
import { collectionsApi } from "../services/collections";


export const
    getCollectionsByIdEpic = (action$): Observable<ICollectionLoadSuccessAction | ICollectionLoadFailureAction> => {
        return action$
            .ofType(ACTION_COLLECTION_LOAD_BY_ID)
            .mergeMap((action: ICollectionLoadByIdAction) => {
                return collectionsApi
                    .getCollectionById(action.id)
                    .map(response =>
                        LoadSuccess(response.collection, response.formState, response.formProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    createCollectionEpic = (action$): Observable<ICollectionCreateSuccessAction | ICollectionCreateFailureAction> => {
        return action$
            .ofType(ACTION_COLLECTION_CREATE)
            .mergeMap((action: ICollectionCreateAction) => {
                return collectionsApi
                    .createCollection()
                    .map(response =>
                        CreateSuccess(response.collection, response.formState, response.formProperties, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    },
    lockCollectionEpic = (action$): Observable<ICollectionLockSuccessAction | ICollectionLockFailureAction> => {
        return action$
            .ofType(ACTION_COLLECTION_LOCK)
            .mergeMap((action: ICollectionLockAction) => {
                return collectionsApi
                    .lockCollection(action.id, action.lock)
                    .map(response =>
                        LockSuccess(response.collection, response.formState, response.formProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LockFailure(error.responseStatus))
                    )
            });
    }



