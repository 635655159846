import * as Dtos from "../../dtos/Tmd.dtos";

export const
    setEditModalViewState: ITrialInstitutionItemsPageSetEditModalViewStateActionCreator = (open, collectionInstanceId, name, description) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEMS_PAGE_SET_EDIT_MODAL_VIEW_STATE,
            open,
            collectionInstanceId,
            name,
            description
        }
    },
    setCreateVersionModalViewState: ITrialInstitutionItemsPageSetCreateVersionModalViewStateActionCreator = (open, trialInstitutionId, trialItemId) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE,
            open,
            trialInstitutionId,
            trialItemId
        }
    }

export const
    ACTION_TRIALINSTITUTIONITEMS_PAGE_SET_EDIT_MODAL_VIEW_STATE = "ACTION_TRIALINSTITUTIONITEMS_PAGE_SET_EDIT_MODAL_VIEW_STATE",
    ACTION_TRIALINSTITUTIONITEMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE = "ACTION_TRIALINSTITUTIONITEMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE"

/* Actions */

export interface ITrialInstitutionItemsPageSetEditModalViewStateAction {
    type: "ACTION_TRIALINSTITUTIONITEMS_PAGE_SET_EDIT_MODAL_VIEW_STATE",
    open: boolean;
    collectionInstanceId: number;
    name: string;
    description: string;
}


export interface ITrialInstitutionItemsPageSetCreateVersionModalViewStateAction {
    type: "ACTION_TRIALINSTITUTIONITEMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE",
    open: boolean;
    trialInstitutionId: number;
    trialItemId: number;
}

/* Action Creators */

export interface ITrialInstitutionItemsPageSetEditModalViewStateActionCreator {
    (
        open: boolean,
        collectionIntanceId: number,
        name: string,
        description: string
    ): ITrialInstitutionItemsPageSetEditModalViewStateAction;
}

export interface ITrialInstitutionItemsPageSetCreateVersionModalViewStateActionCreator {
    (
        open: boolean,
        trialInstitutionId: number,
        trialItemId: number
    ): ITrialInstitutionItemsPageSetCreateVersionModalViewStateAction;
}


/* Final Export of actions */
export type TrialInstitutionItemsPageAction =
    ITrialInstitutionItemsPageSetEditModalViewStateAction |
    ITrialInstitutionItemsPageSetCreateVersionModalViewStateAction;
