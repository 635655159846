import * as React from 'react';
import './TrialCoordCentrePage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { ITrialCoordCentreReduxFormState, initialTrialCoordCentreState } from "../../../reducers/reactReduxForms/trialCoordCentre";
import { RegistrationFormComponent } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, NumberFormInput, CheckboxFormInput } from "../../../components/form/index";
import * as TrialCoordCentreActions from '../../../actions/trialCoordCentres';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { NotificationAlert } from "../../../components/common/index";
import { Form, actions } from 'react-redux-form';
import { TrialLookupsLayout } from '../../index';
import * as AuthenticationHelper from '../../../helpers/authentication';

interface ITrialCoordCentrePageParams {
    trialCoordCentreId: number;
}

interface ITrialCoordCentrePageProps {

    form: Dtos.TrialCoordCentre & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITrialCoordCentreReduxFormState;

    location: Location;
    trialCoordCentreId: number;
    match: match<ITrialCoordCentrePageParams>;
    history: History;

    // From state
    trialCoordCentre: Dtos.TrialCoordCentre;
    lookups: Dtos.Lookup[];

    loadingTrialCoordCentre: boolean;
    loadTrialCoordCentreSuccess: boolean;
    loadTrialCoordCentreFailure: boolean;

    savingTrialCoordCentre: boolean;
    saveTrialCoordCentreSuccess: boolean;
    saveTrialCoordCentreFailure: boolean;

    creatingTrialCoordCentre: boolean;
    createTrialCoordCentreSuccess: boolean;
    createTrialCoordCentreFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface ITrialCoordCentrePageActions {
    clearTrialCoordCentre: TrialCoordCentreActions.ITrialCoordCentreClearActionCreator;
    loadTrialCoordCentre: TrialCoordCentreActions.ITrialCoordCentreLoadByIdActionCreator;

    saveTrialCoordCentre: TrialCoordCentreActions.ITrialCoordCentreSaveActionCreator;
    createTrialCoordCentre: TrialCoordCentreActions.ITrialCoordCentreCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type TrialCoordCentrePageProps = ITrialCoordCentrePageProps & ITrialCoordCentrePageActions;

const reduxFormName: string = "reduxForms.trialCoordCentre";

class TrialCoordCentrePage extends React.PureComponent<TrialCoordCentrePageProps, any> {

    constructor(props: TrialCoordCentrePageProps) {
        super(props);

        this.clearTrialCoordCentre = this.clearTrialCoordCentre.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTrialCoordCentre,
            trialCoordCentreId,
            createTrialCoordCentre
        } = this.props;

        if (trialCoordCentreId) {
            loadTrialCoordCentre(trialCoordCentreId);
        } else if (!trialCoordCentreId) {
            createTrialCoordCentre();
        }
    }

    componentWillReceiveProps(nextProps: TrialCoordCentrePageProps) {
    }

    componentDidUpdate(prevProps: TrialCoordCentrePageProps, prevState) {
        const {
            trialCoordCentreId,
            trialCoordCentre,
            form,
            navigate,
            loadTrialCoordCentre,
            loadingTrialCoordCentre,
            loadTrialCoordCentreSuccess,
            createTrialCoordCentre,
            creatingTrialCoordCentre,
            createTrialCoordCentreSuccess,
            savingTrialCoordCentre,
            saveTrialCoordCentreSuccess,
            validationFailures
        } = this.props;

        if (trialCoordCentreId && prevProps.trialCoordCentreId != trialCoordCentreId) {
            loadTrialCoordCentre(trialCoordCentreId);
        }
        else if (!trialCoordCentreId && prevProps.trialCoordCentreId) {
            createTrialCoordCentre();
        }

        if (!loadingTrialCoordCentre && prevProps.loadingTrialCoordCentre) {
            if (loadTrialCoordCentreSuccess) {
                this.loadForm();
            }
        }

        if (!savingTrialCoordCentre && prevProps.savingTrialCoordCentre) {
            if (saveTrialCoordCentreSuccess) {
                if (validationFailures && validationFailures.length > 0) {
                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {
                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);
                }
                if (form.saveAndReturn) {
                    navigate("/settings/trial/trialcoordcentres")
                } else if (!form.saveAndReturn && trialCoordCentreId && trialCoordCentreId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !trialCoordCentreId && trialCoordCentre && trialCoordCentre.id > 0) {
                    navigate("/settings/trial/trialcoordcentres/" + trialCoordCentre.id);
                }
            }
            else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

        if (!creatingTrialCoordCentre && prevProps.creatingTrialCoordCentre) {
            if (createTrialCoordCentreSuccess) {
                this.loadForm();
            }
        }
    }

    componentWillUpdate(nextProps: TrialCoordCentrePageProps) {
    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearTrialCoordCentre();
        loadForm(reduxFormName, initialTrialCoordCentreState);
        resetForm(reduxFormName);
    }

    clearTrialCoordCentre() {
        this.props.clearTrialCoordCentre();
    }

    showTrialCoordCentre(): boolean {
        const {
            loadingTrialCoordCentre,
            loadTrialCoordCentreSuccess
        } = this.props

        if (!loadingTrialCoordCentre && loadTrialCoordCentreSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrialCoordCentre
        } = this.props;

        return <TrialLookupsLayout loading={loadingTrialCoordCentre} permission={Dtos.Permission.TrialCoordCentresView}>
            {this.renderContent()}
        </TrialLookupsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingTrialCoordCentre,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.TrialCoordCentresUpdate, Dtos.Permission.TrialCoordCentresCreate);
        const formDisabled: boolean = !hasPermission || savingTrialCoordCentre || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            value: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            }
        };

        return <div>
            <h1>Trial Coord Centre</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "trialCoordCentre", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.TrialCoordCentre) {
        const {
            saveTrialCoordCentre
        } = this.props;

        if (data) {
            let changes: Dtos.TrialCoordCentre = {} as Dtos.TrialCoordCentre;
            saveTrialCoordCentre(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.TrialCoordCentre) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/trial/trialcoordcentres");
    }

    loadForm() {
        const {
            loadForm,
            trialCoordCentre,
        } = this.props

        let extraData: Dtos.TrialCoordCentre = {} as Dtos.TrialCoordCentre;

        loadForm(reduxFormName, Object.assign({ ...trialCoordCentre }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: TrialCoordCentrePageProps): ITrialCoordCentrePageProps => {

    let trialCoordCentre: Dtos.TrialCoordCentre | undefined = !(state.trialCoordCentres.formData instanceof Array) ? state.trialCoordCentres.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialCoordCentreId: ownProps.match && ownProps.match.params.trialCoordCentreId && ownProps.match.params.trialCoordCentreId.toString() != "create" ? ownProps.match.params.trialCoordCentreId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trialCoordCentre: trialCoordCentre!,

        loadingTrialCoordCentre: state.trialCoordCentres.loadState && state.trialCoordCentres.loadState.status == RequestState.Pending,
        loadTrialCoordCentreSuccess: state.trialCoordCentres.loadState && state.trialCoordCentres.loadState.status == RequestState.Success,
        loadTrialCoordCentreFailure: state.trialCoordCentres.loadState && state.trialCoordCentres.loadState.status == RequestState.Failure,

        savingTrialCoordCentre: state.trialCoordCentres.saveState && state.trialCoordCentres.saveState.status == RequestState.Pending,
        saveTrialCoordCentreSuccess: state.trialCoordCentres.saveState && state.trialCoordCentres.saveState.status == RequestState.Success,
        saveTrialCoordCentreFailure: state.trialCoordCentres.saveState && state.trialCoordCentres.saveState.status == RequestState.Failure,

        creatingTrialCoordCentre: state.trialCoordCentres.createState && state.trialCoordCentres.createState.status == RequestState.Pending,
        createTrialCoordCentreSuccess: state.trialCoordCentres.createState && state.trialCoordCentres.createState.status == RequestState.Success,
        createTrialCoordCentreFailure: state.trialCoordCentres.createState && state.trialCoordCentres.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: [],

        form: state.reduxForms.trialCoordCentre,
        formState: state.trialCoordCentres.formState,
        formProperties: state.trialCoordCentres.formProperties,

        reduxFormState: state.reduxForms.formState.trialCoordCentre,
        validationFailures: trialCoordCentre ?
            state.trialCoordCentres.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): ITrialCoordCentrePageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadTrialCoordCentre: bindActionCreators(TrialCoordCentreActions.LoadTrialCoordCentreById, dispatch),
        clearTrialCoordCentre: bindActionCreators(TrialCoordCentreActions.Clear, dispatch),

        saveTrialCoordCentre: bindActionCreators(TrialCoordCentreActions.SaveTrialCoordCentre, dispatch),
        createTrialCoordCentre: bindActionCreators(TrialCoordCentreActions.CreateTrialCoordCentre, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialCoordCentrePage);
