import * as React from 'react';
import './MembershipTypePage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { IMembershipTypeReduxFormState, initialMembershipTypeState } from "../../../reducers/reactReduxForms/membershipType";
import { RegistrationFormComponent } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, NumberFormInput, CheckboxFormInput } from "../../../components/form/index";
import * as MembershipTypeActions from '../../../actions/membershipTypes';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { NotificationAlert } from "../../../components/common/index";
import { Form, actions } from 'react-redux-form';
import { LookupsLayout } from '../../index';
import * as AuthenticationHelper from '../../../helpers/authentication';

interface IMembershipTypePageParams {
    membershipTypeId: number;
}

interface IMembershipTypePageProps {

    form: Dtos.MembershipType & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IMembershipTypeReduxFormState;

    location: Location;
    membershipTypeId: number;
    match: match<IMembershipTypePageParams>;
    history: History;

    // From state
    membershipType: Dtos.MembershipType;
    lookups: Dtos.Lookup[];

    loadingMembershipType: boolean;
    loadMembershipTypeSuccess: boolean;
    loadMembershipTypeFailure: boolean;

    savingMembershipType: boolean;
    saveMembershipTypeSuccess: boolean;
    saveMembershipTypeFailure: boolean;

    creatingMembershipType: boolean;
    createMembershipTypeSuccess: boolean;
    createMembershipTypeFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface IMembershipTypePageActions {
    clearMembershipType: MembershipTypeActions.IMembershipTypeClearActionCreator;
    loadMembershipType: MembershipTypeActions.IMembershipTypeLoadByIdActionCreator;

    saveMembershipType: MembershipTypeActions.IMembershipTypeSaveActionCreator;
    createMembershipType: MembershipTypeActions.IMembershipTypeCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type MembershipTypePageProps = IMembershipTypePageProps & IMembershipTypePageActions;

const reduxFormName: string = "reduxForms.membershipType";

class MembershipTypePage extends React.PureComponent<MembershipTypePageProps, any> {

    constructor(props: MembershipTypePageProps) {
        super(props);

        this.clearMembershipType = this.clearMembershipType.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadMembershipType,
            membershipTypeId,
            createMembershipType
        } = this.props;

        if (membershipTypeId) {
            loadMembershipType(membershipTypeId);
        } else if (!membershipTypeId) {
            createMembershipType();
        }
    }

    componentWillReceiveProps(nextProps: MembershipTypePageProps) {
    }

    componentDidUpdate(prevProps: MembershipTypePageProps, prevState) {
        const {
            membershipTypeId,
            membershipType,
            form,
            navigate,
            loadMembershipType,
            loadingMembershipType,
            loadMembershipTypeSuccess,
            createMembershipType,
            creatingMembershipType,
            createMembershipTypeSuccess,
            savingMembershipType,
            saveMembershipTypeSuccess,
            validationFailures
        } = this.props;

        if (membershipTypeId && prevProps.membershipTypeId != membershipTypeId) {
            loadMembershipType(membershipTypeId);
        }
        else if (!membershipTypeId && prevProps.membershipTypeId) {
            createMembershipType();
        }

        if (!loadingMembershipType && prevProps.loadingMembershipType) {
            if (loadMembershipTypeSuccess) {
                this.loadForm();
            }
        }

        if (!savingMembershipType && prevProps.savingMembershipType) {
            if (saveMembershipTypeSuccess) {
                if (validationFailures && validationFailures.length > 0) {
                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {
                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);
                }
                if (form.saveAndReturn) {
                    navigate("/settings/general/membershiptypes")
                } else if (!form.saveAndReturn && membershipTypeId && membershipTypeId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !membershipTypeId && membershipType && membershipType.id > 0) {
                    navigate("/settings/general/membershiptypes/" + membershipType.id);
                }
            }
            else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

        if (!creatingMembershipType && prevProps.creatingMembershipType) {
            if (createMembershipTypeSuccess) {
                this.loadForm();
            }
        }
    }

    componentWillUpdate(nextProps: MembershipTypePageProps) {
    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearMembershipType();
        loadForm(reduxFormName, initialMembershipTypeState);
        resetForm(reduxFormName);
    }

    clearMembershipType() {
        this.props.clearMembershipType();
    }

    showMembershipType(): boolean {
        const {
            loadingMembershipType,
            loadMembershipTypeSuccess
        } = this.props

        if (!loadingMembershipType && loadMembershipTypeSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingMembershipType
        } = this.props;

        return <LookupsLayout loading={loadingMembershipType} permission={Dtos.Permission.MembershipTypesView}>
            {this.renderContent()}
        </LookupsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingMembershipType,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.MembershipTypesUpdate, Dtos.Permission.MembershipTypesCreate);
        const formDisabled: boolean = !hasPermission || savingMembershipType || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            value: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            }
        };

        return <div>
            <h1>Membership Type</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "membershipType", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.MembershipType) {
        const {
            saveMembershipType
        } = this.props;

        if (data) {
            let changes: Dtos.MembershipType = {} as Dtos.MembershipType;
            saveMembershipType(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.MembershipType) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/general/membershiptypes");
    }

    loadForm() {
        const {
            loadForm,
            membershipType,
        } = this.props

        let extraData: Dtos.MembershipType = {} as Dtos.MembershipType;

        loadForm(reduxFormName, Object.assign({ ...membershipType }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: MembershipTypePageProps): IMembershipTypePageProps => {

    let membershipType: Dtos.MembershipType | undefined = !(state.membershipTypes.formData instanceof Array) ? state.membershipTypes.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        membershipTypeId: ownProps.match && ownProps.match.params.membershipTypeId && ownProps.match.params.membershipTypeId.toString() != "create" ? ownProps.match.params.membershipTypeId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        membershipType: membershipType!,

        loadingMembershipType: state.membershipTypes.loadState && state.membershipTypes.loadState.status == RequestState.Pending,
        loadMembershipTypeSuccess: state.membershipTypes.loadState && state.membershipTypes.loadState.status == RequestState.Success,
        loadMembershipTypeFailure: state.membershipTypes.loadState && state.membershipTypes.loadState.status == RequestState.Failure,

        savingMembershipType: state.membershipTypes.saveState && state.membershipTypes.saveState.status == RequestState.Pending,
        saveMembershipTypeSuccess: state.membershipTypes.saveState && state.membershipTypes.saveState.status == RequestState.Success,
        saveMembershipTypeFailure: state.membershipTypes.saveState && state.membershipTypes.saveState.status == RequestState.Failure,

        creatingMembershipType: state.membershipTypes.createState && state.membershipTypes.createState.status == RequestState.Pending,
        createMembershipTypeSuccess: state.membershipTypes.createState && state.membershipTypes.createState.status == RequestState.Success,
        createMembershipTypeFailure: state.membershipTypes.createState && state.membershipTypes.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: [],

        form: state.reduxForms.membershipType,
        formState: state.membershipTypes.formState,
        formProperties: state.membershipTypes.formProperties,

        reduxFormState: state.reduxForms.formState.membershipType,
        validationFailures: membershipType ?
            state.membershipTypes.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): IMembershipTypePageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadMembershipType: bindActionCreators(MembershipTypeActions.LoadMembershipTypeById, dispatch),
        clearMembershipType: bindActionCreators(MembershipTypeActions.Clear, dispatch),

        saveMembershipType: bindActionCreators(MembershipTypeActions.SaveMembershipType, dispatch),
        createMembershipType: bindActionCreators(MembershipTypeActions.CreateMembershipType, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(MembershipTypePage);
