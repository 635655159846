import * as React from 'react';

import * as classNames from "classnames";
import './InstitutionCodingsPage.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';
import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";
import * as InstitutionCodingActions from '../../../actions/institutionCoding';
import * as PagingActions from '../../../actions/pages/pagingPage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter  } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput, FormFilterInput } from '../../../components/form';

import { InstitutionLookupsLayout } from '../../index';

interface IInstitutionCodingsPageProps {

    location: Location;
    history: History;

    // From state
    institutionCodings: Dtos.InstitutionCoding[];
    formFilter: IFormFilter;
    formLength: number;

    loadingInstitutionCodings: boolean;
    loadInstitutionCodingsSuccess: boolean;
    loadInstitutionCodingsFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    formRoles?: Dtos.RoleType[];

    pageSize: number;
}

interface IInstitutionCodingsPageActions {
    clearInstitutionCodings: InstitutionCodingActions.IInstitutionCodingClearActionCreator;
    loadInstitutionCodings: InstitutionCodingActions.IInstitutionCodingLoadActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    filter: InstitutionCodingActions.IInstitutionCodingFilterActionCreator;

    setPageSize: PagingActions.IPagingPageSizeChangedStateActionCreator;
}

type InstitutionCodingsPageProps = IInstitutionCodingsPageProps & IInstitutionCodingsPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}

class InstitutionCodingsPage extends React.PureComponent<InstitutionCodingsPageProps, any> {

    constructor(props: InstitutionCodingsPageProps) {
        super(props);

        this.clearInstitutionCodings = this.clearInstitutionCodings.bind(this);
    }

    componentDidMount() {
        const {
            loadInstitutionCodings
        } = this.props;

        loadInstitutionCodings();
    }

    componentWillReceiveProps(nextProps: InstitutionCodingsPageProps) {
    }

    componentDidUpdate(prevProps: InstitutionCodingsPageProps, prevState) {
        const {
            navigateReplace
        } = this.props
    }

    componentWillUpdate(nextProps: InstitutionCodingsPageProps) {
    }

    componentWillUnmount() {
        this.clearInstitutionCodings();
    }

    clearInstitutionCodings() {
        this.props.clearInstitutionCodings();
    }

    showInstitutionCodings(): boolean {
        const {
            loadingInstitutionCodings,
            loadInstitutionCodingsSuccess
        } = this.props

        if (!loadingInstitutionCodings && loadInstitutionCodingsSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingInstitutionCodings
        } = this.props;

        return <InstitutionLookupsLayout loading={loadingInstitutionCodings} permission={Dtos.Permission.InstitutionCodingView}>
            {this.renderContent()}
        </InstitutionLookupsLayout>;
    }

    renderContent() {
        const {
            permissions
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-6 mb-2"}>
                    <h2>Institution Codings</h2>
                </div>
                <div className="col-6" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} />
                    {AuthenticationHelper.hasPermission(permissions, Dtos.Permission.InstitutionCodingCreate) && (
                        <Link className={"btn btn-primary"} url={"/settings/institution/institutioncodings/create"}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.showInstitutionCodings() ?
                    "Loading Institution Codings" :
                    null
            }
            {
                this.showInstitutionCodings() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {

        const {
            institutionCodings,
            pageSize,
            setPageSize
        } = this.props

        if (!institutionCodings || institutionCodings.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no institutionCodings to view.</span>
            </div>
        }

        const showPagination = institutionCodings.length > pageSize;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={institutionCodings}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? pageSize : institutionCodings.length}
                pageSize={showPagination ? pageSize : institutionCodings.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no institution codings to display"
                resizable={false}
                onPageSizeChange={val => setPageSize(val)}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {

                            const url: string = "/settings/institution/institutioncodings/" + rowInfo.original.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.LOOKUP} fixedWidth />
                    },
                    {
                        id: "value",
                        Header: 'Name',
                        accessor: 'value',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    }
                ]}
            />
        </div>

    }

}

const mapStateToProps = (state: ITmdState, ownProps: InstitutionCodingsPageProps): IInstitutionCodingsPageProps => {

    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        history: ownProps.history,
        location: state.routing.location,

        institutionCodings: state.institutionCodings.formFilteredData!,
        formFilter: state.institutionCodings.formFilter,
        formLength: state.institutionCodings.formLength,

        loadingInstitutionCodings: state.institutionCodings.loadState && state.institutionCodings.loadState.status == RequestState.Pending,
        loadInstitutionCodingsSuccess: state.institutionCodings.loadState && state.institutionCodings.loadState.status == RequestState.Success,
        loadInstitutionCodingsFailure: state.institutionCodings.loadState && state.institutionCodings.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles,

        pageSize: state.pagingPage.pageSize
    };
};

const mapDispatchToProps = (dispatch): IInstitutionCodingsPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadInstitutionCodings: bindActionCreators(InstitutionCodingActions.LoadInstitutionCoding, dispatch),
        clearInstitutionCodings: bindActionCreators(InstitutionCodingActions.Clear, dispatch),

        filter: bindActionCreators(InstitutionCodingActions.Filter, dispatch),

        setPageSize: bindActionCreators(PagingActions.PageSizeChanged, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(InstitutionCodingsPage);
