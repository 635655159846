import * as React from 'react';
import * as ReactTooltip from 'react-tooltip';

import * as classNames from "classnames";
import { FontAwesomeIcon } from "../../../constants/fontAwesomeIcons";
import { FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";

interface INoAccessMessageProps {
    text: string;
}

class NoAccessMessage extends React.PureComponent<INoAccessMessageProps, any> {

    render() {

        const {
            text
        } = this.props



        return <div className="container bg-white py-3 box-shadow-x">
            <div className="row">
                <div className="col text-center">
                    <h1 className="text-danger p-3">
                        <FontAwesomeIcon icon={FontAwesomeIcons.Light.IDBADGE} fixedWidth />&nbsp;No Access</h1>
                    <h5 className="p-3">This user account has not been assigned any roles for this website.</h5>
                    <h6 className="p-3">
                        If you believe that you should have access please contact TROG for help.
                    </h6>
                </div>
            </div>
        </div>
    }
}
export default NoAccessMessage