import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadDepartmentContact:IDepartmentContactLoadActionCreator = () => {
        return {
            type: ACTION_DEPARTMENTCONTACT_LOAD
        }
    },
    LoadDepartmentContactById:IDepartmentContactLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_DEPARTMENTCONTACT_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess:IDepartmentContactLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_DEPARTMENTCONTACT_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure:IDepartmentContactLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_DEPARTMENTCONTACT_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveDepartmentContact:IDepartmentContactSaveActionCreator = (formData) => {
        return {
            type: ACTION_DEPARTMENTCONTACT_SAVE,
            formData
        }
    },
    SaveSuccess:IDepartmentContactSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_DEPARTMENTCONTACT_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure:IDepartmentContactSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_DEPARTMENTCONTACT_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateDepartmentContact:IDepartmentContactCreateActionCreator = (departmentId) => {

        return {
            type: ACTION_DEPARTMENTCONTACT_CREATE,
            departmentId
        }
    },
    CreateSuccess:IDepartmentContactCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_DEPARTMENTCONTACT_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure:IDepartmentContactCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_DEPARTMENTCONTACT_CREATE_FAILURE,
            responseStatus
        }
    },

    Clear:IDepartmentContactClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_DEPARTMENTCONTACT_CLEAR,
            propertiesToClear
        }
    },

    Filter:IDepartmentContactFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_DEPARTMENTCONTACT_FILTER,
            formFilter
        }
    };



export const
    ACTION_DEPARTMENTCONTACT_LOAD = "ACTION_DEPARTMENTCONTACT_LOAD",
    ACTION_DEPARTMENTCONTACT_LOAD_BY_ID = "ACTION_DEPARTMENTCONTACT_LOAD_BY_ID",
    ACTION_DEPARTMENTCONTACT_LOAD_SUCCESS = "ACTION_DEPARTMENTCONTACT_LOAD_SUCCESS",
    ACTION_DEPARTMENTCONTACT_LOAD_FAILURE = "ACTION_DEPARTMENTCONTACT_LOAD_FAILURE",

    ACTION_DEPARTMENTCONTACT_SAVE = "ACTION_DEPARTMENTCONTACT_SAVE",
    ACTION_DEPARTMENTCONTACT_SAVE_SUCCESS = "ACTION_DEPARTMENTCONTACT_SAVE_SUCCESS",
    ACTION_DEPARTMENTCONTACT_SAVE_FAILURE = "ACTION_DEPARTMENTCONTACT_SAVE_FAILURE",

    ACTION_DEPARTMENTCONTACT_CREATE = "ACTION_DEPARTMENTCONTACT_CREATE",
    ACTION_DEPARTMENTCONTACT_CREATE_SUCCESS = "ACTION_DEPARTMENTCONTACT_CREATE_SUCCESS",
    ACTION_DEPARTMENTCONTACT_CREATE_FAILURE = "ACTION_DEPARTMENTCONTACT_CREATE_FAILURE",

    ACTION_DEPARTMENTCONTACT_CLEAR = "ACTION_DEPARTMENTCONTACT_CLEAR",

    ACTION_DEPARTMENTCONTACT_FILTER = "ACTION_DEPARTMENTCONTACT_FILTER";

export interface IDepartmentContactLoadAction {
    type: "ACTION_DEPARTMENTCONTACT_LOAD";
}

export interface IDepartmentContactLoadActionCreator {
    ():IDepartmentContactLoadAction;
}

export interface IDepartmentContactLoadSuccessAction {
    type: "ACTION_DEPARTMENTCONTACT_LOAD_SUCCESS";
    formData: Dtos.DepartmentContact | Dtos.DepartmentContact[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IDepartmentContactLoadSuccessActionCreator {
    (
        formData: Dtos.DepartmentContact | Dtos.DepartmentContact[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ):IDepartmentContactLoadSuccessAction;
}

export interface IDepartmentContactLoadFailureAction {
    type: "ACTION_DEPARTMENTCONTACT_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IDepartmentContactLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus):IDepartmentContactLoadFailureAction;
}

export interface IDepartmentContactLoadByIdAction {
    type: "ACTION_DEPARTMENTCONTACT_LOAD_BY_ID";
    id: number;
}

export interface IDepartmentContactLoadByIdActionCreator {
    (id: number):IDepartmentContactLoadByIdAction;
}


export interface IDepartmentContactSaveAction {
    type: "ACTION_DEPARTMENTCONTACT_SAVE";
    formData: Dtos.DepartmentContact;

}

export interface IDepartmentContactSaveActionCreator {
    (
        formData: Dtos.DepartmentContact
    ):IDepartmentContactSaveAction;
}

export interface IDepartmentContactSaveSuccessAction {
    type: "ACTION_DEPARTMENTCONTACT_SAVE_SUCCESS";
    formData: Dtos.DepartmentContact;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IDepartmentContactSaveSuccessActionCreator {
    (
        formData: Dtos.DepartmentContact,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ):IDepartmentContactSaveSuccessAction;
}

export interface IDepartmentContactSaveFailureAction {
    type: "ACTION_DEPARTMENTCONTACT_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IDepartmentContactSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus):IDepartmentContactSaveFailureAction;
}

export interface IDepartmentContactCreateAction {
    type: "ACTION_DEPARTMENTCONTACT_CREATE",
    departmentId: number
}

export interface IDepartmentContactCreateActionCreator {
    (departmentId: number):IDepartmentContactCreateAction
}

export interface IDepartmentContactCreateSuccessAction {
    type: "ACTION_DEPARTMENTCONTACT_CREATE_SUCCESS";
    formData: Dtos.DepartmentContact;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface IDepartmentContactCreateSuccessActionCreator {
    (
        formData: Dtos.DepartmentContact,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ):IDepartmentContactCreateSuccessAction;
}

export interface IDepartmentContactCreateFailureAction {
    type: "ACTION_DEPARTMENTCONTACT_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IDepartmentContactCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus):IDepartmentContactCreateFailureAction;
}

export interface IDepartmentContactClearAction {
    type: "ACTION_DEPARTMENTCONTACT_CLEAR";
    propertiesToClear?: string[];
}

export interface IDepartmentContactClearActionCreator {
    (propertiesToClear?: string[]):IDepartmentContactClearAction;
}
 
export interface IDepartmentContactFilterActionCreator {
    (formFilter: IFormFilter):IDepartmentContactFilterAction;
}

export interface IDepartmentContactFilterAction {
    type: "ACTION_DEPARTMENTCONTACT_FILTER";
    formFilter: IFormFilter;
}

export type DepartmentContactAction =
   IDepartmentContactLoadAction |
   IDepartmentContactLoadByIdAction |
   IDepartmentContactLoadFailureAction |
   IDepartmentContactLoadSuccessAction |
   IDepartmentContactSaveAction |
   IDepartmentContactSaveSuccessAction |
   IDepartmentContactSaveFailureAction |
   IDepartmentContactCreateAction |
   IDepartmentContactCreateSuccessAction |
   IDepartmentContactCreateFailureAction |
   IDepartmentContactClearAction | 
   IDepartmentContactFilterAction;
