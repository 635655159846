import * as React from 'react';
import './QALookupsLayout.scss';
import { connect } from "react-redux";
import { RestrictedLayout } from "../../../components/layouts/index";
import { ITmdState } from "../../../reducers/index";
import * as Dtos from '../../../dtos/Tmd.dtos';
import { SettingsSubMenu } from '../../../helpers/subMenuHelper';
import * as AuthenticationHelper from '../../../helpers/authentication';
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";

interface IQALookupsLayoutProps {
    permissions?: Dtos.PersonnelTmdPermissions[];
    permission?: Dtos.Permission;
    loading?: boolean;
    children?: any;
}

interface IQALookupsLayoutActions {

}

type QALookupsLayoutProps = IQALookupsLayoutProps & IQALookupsLayoutActions;

class QALookupsLayout extends React.PureComponent<QALookupsLayoutProps, any> {

    constructor(props: QALookupsLayoutProps) {
        super(props);

    }

    render() {
        const {
            loading,
            permissions,
            permission
        } = this.props;

        let menu: any = []; 
        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.FormTemplatesView)) {
            menu.push({
                icon: undefined,
                title: "Form Templates",
                url: "/settings/qa/formtemplates"
            });
        }
        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.FormComponentsView)) {
            menu.push({
                icon: undefined,
                title: "Form Components",
                url: "/settings/qa/formcomponents"
            });
        }
        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.ReportingTagsView)) {
            menu.push({
                icon: undefined,
                title: "Reporting Tags",
                url: "/settings/qa/reportingtags"
            });
        }
        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.ReportingTagCategoriesView)) {
            menu.push({
                icon: undefined,
                title: "Reporting Tag Categories",
                url: "/settings/qa/reportingtagcategories"
            });
        }
        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TaskTypesView)) {
            menu.push({
                icon: undefined,
                title: "Task Types",
                url: "/settings/qa/tasktypes"
            });
        }
        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TaskTypesView)) {
            menu.push({
                icon: undefined,
                title: "Tracker Types",
                url: "/settings/qa/trackertypes"
            });
        }
        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TaskTypesView)) {
            menu.push({
                icon: undefined,
                title: "Tracker Milestones",
                url: "/settings/qa/trackermilestones"
            });
        }
        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.FormTemplateFileTypesView)) {
            menu.push({
                icon: undefined,
                title: "File Types",
                url: "/settings/qa/formtemplatefiletypes"
            });
        }
        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.QAEmailTemplatesView)) {
            menu.push({
                icon: undefined,
                title: "Email Templates",
                url: "/settings/qa/qaEmailTemplates"
            });
        }

        return <RestrictedLayout
            subMenuItems={loading ? [] : SettingsSubMenu(permissions)}
            sub2MenuItems={loading ? [] : menu}
            loading={loading}
        >
            {permissions.length > 0 && permission && !AuthenticationHelper.hasPermission(permissions, permission) ?
                <div className="alert alert-warning d-flex">
                    <div className="mr-2">
                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                    </div>
                    <span>Permission Denied</span>
                </div>
                : this.props.children}
        </RestrictedLayout>;
    }

}


const mapStateToProps = (state: ITmdState, ownProps: QALookupsLayoutProps): IQALookupsLayoutProps => {
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];
    return {
        permissions: permissions,
        permission: ownProps.permission,
        loading: ownProps.loading,
        children: ownProps.children
    };
};

const mapDispatchToProps = (dispatch): IQALookupsLayoutActions => {
    return {
        
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(QALookupsLayout);
