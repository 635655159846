import * as React from 'react';
import * as classNames from 'classnames';
import { IBaseFormInputProps } from '../';

import './DatePickerFormInputFabric.scss';

import { FontAwesomeIcons, FontAwesomeIcon } from '../../../../constants/fontAwesomeIcons';

//import { DateRangePicker, SingleDatePicker, DayPickerRangeController, } from 'react-dates';
import { DatePicker, DayOfWeek, IDatePickerStrings } from 'office-ui-fabric-react/lib/DatePicker';
import * as Moment from 'moment';
import { DEFAULT_DATE_FORMAT } from '../../../../constants/dateFormats';


import { IDatePicker } from 'office-ui-fabric-react/lib/components/DatePicker';

export interface IDatePickerFormInputProps {
    feedbackIcon?: string;
    maxDate?: Date;
    minDate?: Date;
    label?: string;
    isMonthPickerVisible?: boolean;
    includeTime?: boolean;
}

export interface IDatePickerFormInputState {
    resetting: boolean;
}

export interface IDatePickerFormatExampleState {
    firstDayOfWeek?: DayOfWeek;
    value?: Date | null;
}

//"yyyy'-'MM'-'dd'T'HH':'mm':'ss'.'fffffff'Z'"
// Constants

const dateFormat: string = 'YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[.]SSSSSSS[Z]';

const DayPickerStrings: IDatePickerStrings = {
    months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ],

    shortMonths: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ],

    days: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ],

    shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

    goToToday: 'Go to today',
    prevMonthAriaLabel: 'Go to previous month',
    nextMonthAriaLabel: 'Go to next month',
    prevYearAriaLabel: 'Go to previous year',
    nextYearAriaLabel: 'Go to next year'
};



class DatePickerFormInputFabric extends React.Component<IDatePickerFormInputProps & IBaseFormInputProps, IDatePickerFormInputState> {

    // @ts-ignore
    private container: HTMLDivElement;
    // @ts-ignore
    private editor: IDatePicker;

    constructor(props) {
        super(props);

        this.onDateChange = this.onDateChange.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);

        this.state = { resetting: false };

    }

    componentDidUpdate(prevProps: IDatePickerFormInputProps & IBaseFormInputProps) {

        const {
            value,
            onChange,
            focused,
            name,
            onFocus
        } = this.props

        const {
            resetting
        } = this.state;
    }

    render() {

        const {
            valid,
            invalid,
            className,
            name,
            onBlur,
            onFocus,
            value,
            disabled,
            focused,
            feedbackIcon,
            isMonthPickerVisible,
            maxDate,
            minDate,
            label,
            includeTime,
            hasSoftError,
            hasHardError
        } = this.props;

        const derivedClassName = className ? className : undefined;
        const derivedLabel = '';
        const derivedIsMonthPickerVisible = isMonthPickerVisible ? isMonthPickerVisible : true;
        const derivedMaxDate = maxDate ? maxDate : undefined;
        const derivedMinDate = minDate ? minDate : new Date(0, 0);

        const date = value ?
            includeTime ?
                Moment.default(value).toDate() :
                new Date(Moment.default(value).utc().year(), Moment.default(value).utc().month(), Moment.default(value).utc().date()) :
            undefined;

        const desc = 'This field is required. One of the support input formats is year dash month dash day.';

        const hardFeedback = hasHardError && !disabled ?
            <span className={classNames("form-control-feedback block pr-8")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.BAN} fixedWidth />
            </span> :
            null;


        const feedback = (invalid && !disabled) && !hasHardError ?
            <span className={classNames("form-control-feedback pr-8")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE} fixedWidth />
            </span> :
            null;

        const softFeedback = hasSoftError && !disabled && (!invalid) ?
            <span className={classNames("form-control-feedback warning pr-8")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.EXCLAMATION_TRIANGLE} fixedWidth />
            </span> :
            null;

        const derivedIsInvalid = invalid && !disabled;
        const derivedIsWarning = hasSoftError && !derivedIsInvalid;
        const derivedIsValid = !derivedIsInvalid && !derivedIsWarning;


        return (
            <div ref={ref => (this.container = ref!)} className={classNames(
                'form-date-group',
                { "focused": focused },
                { "is-invalid": derivedIsInvalid },
                { "is-valid": derivedIsValid },
                { "is-warning": derivedIsWarning})}>
                <DatePicker
                    isRequired={false}
                    allowTextInput={true}
                    ariaLabel={desc}
                    firstDayOfWeek={DayOfWeek.Sunday}
                    strings={DayPickerStrings}
                    value={date!}
                    onSelectDate={this._onSelectDate}
                    formatDate={this._onFormatDate}
                    parseDateFromString={this._onParseDateFromString}
                    disableAutoFocus={true}
                    disabled={disabled}
                    className={derivedClassName}
                    highlightCurrentMonth={false}
                    highlightSelectedMonth={true}
                    minDate={derivedMinDate}
                    maxDate={derivedMaxDate}
                    label={derivedLabel}
                    isMonthPickerVisible={derivedIsMonthPickerVisible}
                    componentRef={ref => (this.editor = ref!)}
                />
                {hardFeedback}
                {feedback}
                {softFeedback}
            </div>

        );
    }


    onDateChange(val: any) {
        if (!val) {
            this.props.onChange(null);
        }
        else {

            this.props.onChange(val.format(dateFormat));
        }
    }

    checkFocus() {
        console.log('checking focus');
        if (this.container) {
            console.log(this.container);
        }
        else {
            console.log('container does not exist!');
        }

    }

    onFocusChange(event: { focused: boolean }) {

        const {
            onFocus,
            onBlur,
            onChange,
            value

        } = this.props;

        if (event.focused  && onFocus) {
            onFocus();
        }
        else if (onBlur) {
            onBlur();
        }
    }

    private _onFormatDate = (date?: Date | undefined): string => {
        if (date) {
            if (this.isValidDate(date)) {
                if (this.props.includeTime) {
                    return (
                        Moment.default(date.toISOString()).format(DEFAULT_DATE_FORMAT)
                    );
                }
                let mDate2: Moment.Moment = Moment.default(date).set({ hour: 0 });
                return mDate2.format((DEFAULT_DATE_FORMAT));
            }
        }
        return '';
    }

    private _onSelectDate = (date: Date | null | undefined): void => {

        const {
            onChange,
            value,
            maxDate,
            minDate,
             onFocus
        } = this.props;

        if (!date) {
            this.props.onChange(null);
        }
        else {
            if (this.isValidDate(date)) {
                if (this.props.includeTime) {
                    this.props.onChange(Moment.default(date).utc().format(dateFormat));
                }
                else {
                    let mDate: Moment.Moment = Moment.default(date.toISOString()).utcOffset('+1000');
                    let mDate2: Moment.Moment = Moment.default(date).set({ hour: 0 });

                    this.props.onChange(Moment.default(mDate2).format(dateFormat));
                }
            }
            else {
                this.props.onChange(date);
            }
        }

    }

    private _onParseDateFromString = (val: string): Date => {

        const {
            value,
            includeTime
        } = this.props;

        const date = value ?
            includeTime ?
                Moment.default(value).toDate() :
                new Date(Moment.default(value).utc().year(), Moment.default(value).utc().month(), Moment.default(value).utc().date()) :
            new Date();

        const values = (val || '').trim().split('/');
        const day =
            values.length > 0
                ? Math.max(1, Math.min(31, parseInt(values[0], 10)))
                : date.getDate();
        const month =
            values.length > 1
                ? Math.max(1, Math.min(12, parseInt(values[1], 10))) - 1
                : date.getMonth();
        let year = values.length > 2 ? parseInt(values[2], 10) : date.getFullYear();
        if (year < 100) {
            year += date.getFullYear() - date.getFullYear() % 100;
        }

        return new Date(year, month, day);
    }

    private isValidDate(date: Date): boolean {
        const {
            maxDate,
            minDate,
            onChange,
            value,
            includeTime

        } = this.props;

        if (!date) {
            return false;
        }

        const derivedMaxDate = maxDate ? maxDate : undefined;
        const derivedMinDate = minDate ? minDate : new Date(0, 0);


        let validDate: boolean = true;

        if (includeTime) {
            if (derivedMaxDate) {
                if (date > derivedMaxDate) {
                    validDate = false;
                }
            }

            if (derivedMinDate) {
                if (date < derivedMinDate) {
                    validDate = false;
                }
            }
        }
        else {
            date.setHours(0, 0, 0, 0);

            if (derivedMaxDate) {

                derivedMaxDate.setHours(0, 0, 0, 0);

                if (date > derivedMaxDate) {
                    validDate = false;
                }
            }

            if (derivedMinDate) {

                derivedMinDate.setHours(0, 0, 0, 0);

                if (date < derivedMinDate) {
                    validDate = false;
                }
            }
        }


        return validDate;
    }

}

export default DatePickerFormInputFabric;