import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadInvolvement: IInvolvementLoadActionCreator = () => {
        return {
            type: ACTION_INVOLVEMENTS_LOAD
        }
    },
    LoadInvolvementById: IInvolvementLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_INVOLVEMENTS_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IInvolvementLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_INVOLVEMENTS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IInvolvementLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_INVOLVEMENTS_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveInvolvement: IInvolvementSaveActionCreator = (formData) => {
        return {
            type: ACTION_INVOLVEMENTS_SAVE,
            formData
        }
    },
    SaveSuccess: IInvolvementSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_INVOLVEMENTS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IInvolvementSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_INVOLVEMENTS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateInvolvement: IInvolvementCreateActionCreator = () => {

        return {
            type: ACTION_INVOLVEMENTS_CREATE
        }
    },
    CreateSuccess: IInvolvementCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_INVOLVEMENTS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: IInvolvementCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_INVOLVEMENTS_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IInvolvementClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_INVOLVEMENTS_CLEAR,
            propertiesToClear
        }
    },
    Filter: IInvolvementFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_INVOLVEMENTS_FILTER,
            formFilter
        }
    };



export const
    ACTION_INVOLVEMENTS_LOAD = "ACTION_INVOLVEMENTS_LOAD",
    ACTION_INVOLVEMENTS_LOAD_BY_ID = "ACTION_INVOLVEMENTS_LOAD_BY_ID",
    ACTION_INVOLVEMENTS_LOAD_SUCCESS = "ACTION_INVOLVEMENTS_LOAD_SUCCESS",
    ACTION_INVOLVEMENTS_LOAD_FAILURE = "ACTION_INVOLVEMENTS_LOAD_FAILURE",

    ACTION_INVOLVEMENTS_SAVE = "ACTION_INVOLVEMENTS_SAVE",
    ACTION_INVOLVEMENTS_SAVE_SUCCESS = "ACTION_INVOLVEMENTS_SAVE_SUCCESS",
    ACTION_INVOLVEMENTS_SAVE_FAILURE = "ACTION_INVOLVEMENTS_SAVE_FAILURE",

    ACTION_INVOLVEMENTS_CREATE = "ACTION_INVOLVEMENTS_CREATE",
    ACTION_INVOLVEMENTS_CREATE_SUCCESS = "ACTION_INVOLVEMENTS_CREATE_SUCCESS",
    ACTION_INVOLVEMENTS_CREATE_FAILURE = "ACTION_INVOLVEMENTS_CREATE_FAILURE",

    ACTION_INVOLVEMENTS_CLEAR = "ACTION_INVOLVEMENTS_CLEAR",

    ACTION_INVOLVEMENTS_FILTER = "ACTION_INVOLVEMENTS_FILTER";

export interface IInvolvementLoadAction {
    type: "ACTION_INVOLVEMENTS_LOAD";
}

export interface IInvolvementLoadActionCreator {
    (): IInvolvementLoadAction;
}

export interface IInvolvementLoadSuccessAction {
    type: "ACTION_INVOLVEMENTS_LOAD_SUCCESS";
    formData: Dtos.Involvement | Dtos.Involvement[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IInvolvementLoadSuccessActionCreator {
    (
        formData: Dtos.Involvement | Dtos.Involvement[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IInvolvementLoadSuccessAction;
}

export interface IInvolvementLoadFailureAction {
    type: "ACTION_INVOLVEMENTS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IInvolvementLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IInvolvementLoadFailureAction;
}

export interface IInvolvementLoadByIdAction {
    type: "ACTION_INVOLVEMENTS_LOAD_BY_ID";
    id: number;
}

export interface IInvolvementLoadByIdActionCreator {
    (id: number): IInvolvementLoadByIdAction;
}


export interface IInvolvementSaveAction {
    type: "ACTION_INVOLVEMENTS_SAVE";
    formData: Dtos.Involvement;

}

export interface IInvolvementSaveActionCreator {
    (
        formData: Dtos.Involvement
    ): IInvolvementSaveAction;
}

export interface IInvolvementSaveSuccessAction {
    type: "ACTION_INVOLVEMENTS_SAVE_SUCCESS";
    formData: Dtos.Involvement;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IInvolvementSaveSuccessActionCreator {
    (
        formData: Dtos.Involvement,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IInvolvementSaveSuccessAction;
}

export interface IInvolvementSaveFailureAction {
    type: "ACTION_INVOLVEMENTS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IInvolvementSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IInvolvementSaveFailureAction;
}

export interface IInvolvementCreateAction {
    type: "ACTION_INVOLVEMENTS_CREATE"
}

export interface IInvolvementCreateActionCreator {
    (): IInvolvementCreateAction
}

export interface IInvolvementCreateSuccessAction {
    type: "ACTION_INVOLVEMENTS_CREATE_SUCCESS";
    formData: Dtos.Involvement;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface IInvolvementCreateSuccessActionCreator {
    (
        formData: Dtos.Involvement,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): IInvolvementCreateSuccessAction;
}

export interface IInvolvementCreateFailureAction {
    type: "ACTION_INVOLVEMENTS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IInvolvementCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IInvolvementCreateFailureAction;
}

export interface IInvolvementClearAction {
    type: "ACTION_INVOLVEMENTS_CLEAR";
    propertiesToClear?: string[];
}

export interface IInvolvementClearActionCreator {
    (propertiesToClear?: string[]): IInvolvementClearAction;
}

export interface IInvolvementFilterActionCreator {
    (formFilter: IFormFilter): IInvolvementFilterAction;
}

export interface IInvolvementFilterAction {
    type: "ACTION_INVOLVEMENTS_FILTER";
    formFilter: IFormFilter;
}

export type InvolvementAction =
    IInvolvementLoadAction |
    IInvolvementLoadByIdAction |
    IInvolvementLoadFailureAction |
    IInvolvementLoadSuccessAction |
    IInvolvementSaveAction |
    IInvolvementSaveSuccessAction |
    IInvolvementSaveFailureAction |
    IInvolvementCreateAction |
    IInvolvementCreateSuccessAction |
    IInvolvementCreateFailureAction |
    IInvolvementClearAction |
    IInvolvementFilterAction;
