import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Tmd.dtos';

import {
    ACTION_HOME_LOAD,
    ACTION_HOME_LOAD_SUCCESS,
    ACTION_HOME_LOAD_FAILURE,
    ACTION_HOME_CLEAR,
    HomeAction
} from '../actions/home';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState } from "./index";

export interface IHomeState {
    formData: [],
    formState: null,
    formProperties: [],
    validationFailures: Dtos.ResponseError[];
    data: Dtos.Home;
    loadState: IRequestState;
    saveState: IRequestState;
    updateState: IRequestState;
}

const initialState: IHomeState = {
    formData: undefined,
    formState: undefined,
    formProperties: undefined,
    validationFailures: undefined,
    data: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    updateState: {
        status: RequestState.None
    } as IRequestState
};


function loading(state: IHomeState, isLoading: boolean): IHomeState {
    const newState: IHomeState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(state: IHomeState, data: Dtos.Home
    ): IHomeState {
    const newState: IHomeState = update(state, {
        data: {
            $set: data
        },
        loadState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function loadFailure(state: IHomeState, responseStatus: Dtos.ResponseStatus): IHomeState {
    const newState: IHomeState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: IHomeState): IHomeState {
    const newState: IHomeState = update(initialState, {});

    return newState;
}


function setHome(state: IHomeState): IHomeState {
    const newState: IHomeState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function setHomeSuccess(state: IHomeState, data: Dtos.Home): IHomeState {
    const newState: IHomeState = update(state, {
        data: {
            $set: data
        },
        saveState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function setHomeFailure(state: IHomeState, responseStatus: Dtos.ResponseStatus): IHomeState {
    const newState: IHomeState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}


const HomeReducer: Reducer<IHomeState> = (state: IHomeState = initialState, action: HomeAction) => {
    switch (action.type) {
        case ACTION_HOME_LOAD:
            return loading(state, true);
        case ACTION_HOME_LOAD_SUCCESS:
            return loadSuccess(state, action.home);
        case ACTION_HOME_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_HOME_CLEAR:
            return clear(state);
    }

    return state;
}

export default HomeReducer;