import * as React from 'react';
import './GroupTrialsPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { RowInfo } from 'react-table';
import { RestrictedLayout } from "../../../components/layouts/index";
import * as GroupActions from '../../../actions/groups';
import * as ToggleActions from '../../../actions/pages/togglePage';
import { TogglePageItem } from '../../../reducers/pages/togglePage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { GroupSubMenu } from '../../../helpers/subMenuHelper';

interface IGroupTrialsPageParams {
    groupId: number;
}

interface IGroupTrialsPageProps {

    location: Location;
    groupId: number;
    match: match<IGroupTrialsPageParams>;
    history: History;

    // From state
    group: Dtos.Group;
    groupTrialSummaries: Dtos.GroupTrialSummary[];

    loadingGroup: boolean;
    loadGroupSuccess: boolean;
    loadGroupFailure: boolean;

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];
    toggleItems: TogglePageItem[]
}

interface IGroupTrialsPageActions {
    clearGroup: GroupActions.IGroupClearActionCreator;
    loadGroup: GroupActions.IGroupLoadByIdActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    toggleExpand: ToggleActions.IToggleExpandedStateActionCreator;
}

type GroupTrialsPageProps = IGroupTrialsPageProps & IGroupTrialsPageActions;

class GroupTrialsPage extends React.PureComponent<GroupTrialsPageProps, any> {

    constructor(props: GroupTrialsPageProps) {
        super(props);

        this.clearGroup = this.clearGroup.bind(this);
    }

    componentDidMount() {
        const {
            loadGroup,
            groupId
        } = this.props;

        loadGroup(groupId);
    }

    componentWillUnmount() {
        this.clearGroup();
    }

    clearGroup() {
        this.props.clearGroup();
    }

    showGroup(): boolean {
        const {
            loadingGroup,
            loadGroupSuccess
        } = this.props

        if (!loadingGroup && loadGroupSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingGroup,
            permissions,
            group
        } = this.props;

        let id = group ? group.id : 0;

        return <RestrictedLayout
            permission={Dtos.Permission.TrialView}
            subMenuItems={GroupSubMenu(id, permissions)}
            loading={loadingGroup}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            loadingGroup,
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-9 mb-2"}>
                    <h2>Group Trials</h2>
                </div>
                <div className="col-3" style={{ textAlign: 'right' }}>
                   
                </div>
            </div>
            {
                loadingGroup ?
                    "Loading Group Trials" :
                    null
            }
            {
                !loadingGroup ?
                    <div className="mt-2">
                        {this.renderTable()}
                    </div> :
                    null
            }
        </div>
    }

    renderTable() {
        const {
            permissions,
            groupTrialSummaries
        } = this.props;

        if (!groupTrialSummaries || groupTrialSummaries.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no trials to view.</span>
            </div>
        }

        const showPagination = groupTrialSummaries.length > DEFAULT_PAGE_SIZE;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={groupTrialSummaries}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : groupTrialSummaries.length}
                pageSize={showPagination ? undefined : groupTrialSummaries.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no departments to display"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            if (rowInfo.original.trialId > 0 && rowInfo.original.trialInstitutionId > 0) {
                                if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialInstitutionView, rowInfo.original.trialId, rowInfo.original.institutionId)) {
                                    const url: string = "/trials/" + rowInfo.original.trialId + "/trialinstitution/" + rowInfo.original.trialInstitutionId + "/details";
                                    this.props.navigate(url);
                                }
                            }
                            else if (rowInfo.original.trialId > 0) {
                                if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialView, rowInfo.original.trialId, null)) {
                                    const url: string = "/trials/" + rowInfo.original.trialId + "/details";
                                    this.props.navigate(url);
                                }
                            }
                            if (handleOriginal) {
                                handleOriginal()
                            }
                        },
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={ FontAwesomeIcons.Light.TRIAL} fixedWidth />
                    },
                    {
                        id: "trialDisplay",
                        Header: 'Trial',
                        accessor: 'trialDisplay',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    },
                ]}
            />
        </div>
    }

}


const mapStateToProps = (state: ITmdState, ownProps: GroupTrialsPageProps): IGroupTrialsPageProps => {

    let group: Dtos.Group | undefined = !(state.groups.formData instanceof Array) ? state.groups.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        groupId: ownProps.match ? ownProps.match.params.groupId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        group: group!,
        groupTrialSummaries: group ? group.groupTrialSummaries : undefined,

        loadingGroup: state.groups.loadState && state.groups.loadState.status == RequestState.Pending,
        loadGroupSuccess: state.groups.loadState && state.groups.loadState.status == RequestState.Success,
        loadGroupFailure: state.groups.loadState && state.groups.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        toggleItems: state.togglePage.items
    };
};

const mapDispatchToProps = (dispatch): IGroupTrialsPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadGroup: bindActionCreators(GroupActions.LoadGroupById, dispatch),
        clearGroup: bindActionCreators(GroupActions.Clear, dispatch),

        toggleExpand: bindActionCreators(ToggleActions.ToggleExpanded, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(GroupTrialsPage);
