import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trialphasesApi = {
    getTrialPhases: (): Observable<Dtos.GetTrialPhasesResponse> => {
        //Create the request.
        let request: Dtos.GetTrialPhases = new Dtos.GetTrialPhases();

        //Send request.
        const response: Promise<Dtos.GetTrialPhasesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrialPhaseById: (id: number): Observable<Dtos.GetTrialPhaseByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrialPhaseById = new Dtos.GetTrialPhaseById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTrialPhaseByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrialPhase: (trialPhase: Dtos.TrialPhase): Observable<Dtos.SetTrialPhaseResponse> => {

        let request: Dtos.SetTrialPhase = new Dtos.SetTrialPhase();

        request.trialPhase = trialPhase;

        // send request
        const response: Promise<Dtos.SetTrialPhaseResponse> = client.post(request);

        return Observable.from(response);

    },
    createTrialPhase: (): Observable<Dtos.CreateTrialPhaseResponse> => {
        //Create the request.
        let request: Dtos.CreateTrialPhase = new Dtos.CreateTrialPhase();

        //Send request.
        const response: Promise<Dtos.CreateTrialPhaseResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}