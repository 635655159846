import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_TRIALITEM_LOAD_BY_ID,

    ACTION_TRIALITEM_SAVE,

    ACTION_TRIALITEM_CREATE,

    ITrialItemLoadByIdAction,
    ITrialItemLoadFailureAction,
    ITrialItemLoadSuccessAction,

    ITrialItemSaveAction,
    ITrialItemSaveSuccessAction,
    ITrialItemSaveFailureAction,

    ITrialItemCreateAction,
    ITrialItemCreateSuccessAction,
    ITrialItemCreateFailureAction,

    LoadSuccess,
    LoadFailure,


    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,
} from "../actions/trialItem";
import { trialItemsApi } from "../services/trialItems";


export const
    getTrialItemsByIdEpic = (action$): Observable<ITrialItemLoadSuccessAction | ITrialItemLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALITEM_LOAD_BY_ID)
            .mergeMap((action: ITrialItemLoadByIdAction) => {
                return trialItemsApi
                    .getTrailItemById(action.id)
                    .map(response =>
                        LoadSuccess(response.trialItem, response.formState, response.formProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setTrialItemEpic = (action$): Observable<ITrialItemSaveSuccessAction | ITrialItemSaveFailureAction> => {
        return action$
            .ofType(ACTION_TRIALITEM_SAVE)
            .mergeMap((action: ITrialItemSaveAction) => {
                return trialItemsApi
                    .setTrialItem(action.formData, action.collectionFormData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.trialItem, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createTrialItemEpic = (action$): Observable<ITrialItemCreateSuccessAction | ITrialItemCreateFailureAction> => {
        return action$
            .ofType(ACTION_TRIALITEM_CREATE)
            .mergeMap((action: ITrialItemCreateAction) => {
                return trialItemsApi
                    .createTrialItem(action.trialId)
                    .map(response =>
                        CreateSuccess(response.trialItem, response.formState, response.formProperties, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



