import * as React from 'react';
import './PersonnelPermissionsPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";
import { IPersonnelReduxFormState, initialPersonnelState } from "../../../reducers/reactReduxForms/personnel";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import * as PersonnelActions from '../../../actions/personnel';
import * as ToggleActions from '../../../actions/pages/togglePage';
import * as ToggleHelper from "../../../helpers/toggleHelper";
import { TogglePageItem } from '../../../reducers/pages/togglePage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { Form, actions, Control } from 'react-redux-form';
import { SettingsSubMenu, PersonnelSubMenu } from '../../../helpers/subMenuHelper';

interface IPersonnelPermissionsPageParams {
    personnelId: number;
}

interface IPersonnelPermissionsPageProps {

    form: Dtos.Personnel & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IPersonnelReduxFormState;

    location: Location;
    personnelId: number;
    match: match<IPersonnelPermissionsPageParams>;
    history: History;

    // From state
    personnel: Dtos.Personnel;
    personnelPermissionGroups: Dtos.PersonnelPermissionGroup[];

    loadingPersonnel: boolean;
    loadPersonneluccess: boolean;
    loadPersonnelFailure: boolean;

    loadingPersonnelPermissions: boolean;
    loadPersonnelPermissionsSuccess: boolean;
    loadPersonnelPermissionsFailure: boolean;


    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];
    toggleItems: TogglePageItem[];
}

interface IPersonnelPermissionsPageActions {
    clearPersonnel: PersonnelActions.IPersonnelClearActionCreator;
    loadPersonnel: PersonnelActions.IPersonnelLoadByIdActionCreator;
    loadPersonnelPermissions: PersonnelActions.IPersonnelPermissionsLoadByIdActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    toggleExpand: ToggleActions.IToggleExpandedStateActionCreator;
}

type PersonnelPermissionsPageProps = IPersonnelPermissionsPageProps & IPersonnelPermissionsPageActions;

const reduxFormName: string = "reduxForms.personnel";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class PersonnelPermissionsPage extends React.PureComponent<PersonnelPermissionsPageProps, any> {

    constructor(props: PersonnelPermissionsPageProps) {
        super(props);

        this.clearPersonnel = this.clearPersonnel.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadPersonnel,
            loadPersonnelPermissions,
            personnelId,
        } = this.props;

        if (personnelId) {
            loadPersonnel(personnelId);
            loadPersonnelPermissions(personnelId);
        } 
    }

    componentDidUpdate(prevProps: PersonnelPermissionsPageProps, prevState) {
        const {
            personnelId,
            loadPersonnel,
            loadPersonnelPermissions,
            loadingPersonnel,
            loadPersonneluccess,
        } = this.props;

        if (personnelId && prevProps.personnelId != personnelId) {
            loadPersonnel(personnelId);
            loadPersonnelPermissions(personnelId);
        }

        if (!loadingPersonnel && prevProps.loadingPersonnel) {
            if (loadPersonneluccess) {
                this.loadForm();
            }
        }
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearPersonnel();
        loadForm(reduxFormName, initialPersonnelState);
        resetForm(reduxFormName);
    }

    clearPersonnel() {
        this.props.clearPersonnel();
    }

    showPersonnel(): boolean {
        const {
            loadingPersonnel,
            loadPersonneluccess
        } = this.props

        if (!loadingPersonnel && loadPersonneluccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingPersonnel,
            loadingPersonnelPermissions,
            personnelId,
            personnel,
            permissions
        } = this.props;

        return <RestrictedLayout
            permission={Dtos.Permission.PersonnelRoleView}
            subMenuTitle={personnel ? personnel.display : ""}
            subMenuItems={personnelId == undefined ? undefined : PersonnelSubMenu(personnelId, permissions)}
            loading={loadingPersonnel || loadingPersonnelPermissions}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            personnel,
            loadingPersonnel,
            loadingPersonnelPermissions,
            personnelPermissionGroups
        } = this.props;

        
        let formComponents: { [index: string]: RegistrationFormComponent } = {

        };



        return <div>
            <div className="row">
                <div className={"col"}>
                    <h2>Personnel Permissions</h2>
                </div>
            </div>
            {
                (loadingPersonnel || loadingPersonnelPermissions) ?
                    "Loading Personnel" :
                    null
            }
            {
                (!loadingPersonnel && !loadingPersonnelPermissions) ?
                    <div className="mt-2">
                        {this.renderTable()}
                    </div> :
                    null
            }
        </div>

    }

    toggle(personnelPermissionsummary: Dtos.PersonnelPermissionGroup) {

    }


    renderTable() {
        const {
            personnel,
            personnelPermissionGroups,
            toggleItems,
            toggleExpand
        } = this.props;


        if (!personnelPermissionGroups || personnelPermissionGroups.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no Permissions to view.</span>
            </div>
        }

        const showPagination = false; //personnelPermissionsummaries.length > DEFAULT_PAGE_SIZE;

        return <div>
            {personnelPermissionGroups && personnelPermissionGroups.map((item, index) => {
                const key = "personnel-Permissions-page-" + item.name;
                const expanded = !ToggleHelper.isToggleExpanded(toggleItems, key);
                return (
                    <div key={"parent-category" + index}>
                        <div className={tableHeaderClass} style={tableStyleClass} onClick={() => toggleExpand(key)}>
                            <div>
                                <FontAwesomeIcon icon={!expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" />
                            </div>
                            <span>{item.name}</span>
                        </div>
                        { expanded && (
                            this.renderGeneralPermissions(item)
                        )}
                    </div>
                )
            }
            )}
        </div>
    }

    renderGeneralPermissions(groupItem: Dtos.PersonnelPermissionGroup) {
        const showPagination = false; const {
            personnel
        } = this.props;

        return (
            <ReactTable
                data={groupItem.personnelPermissions}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : groupItem.personnelPermissions.length}
                pageSize={showPagination ? undefined : groupItem.personnelPermissions.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no Permissions for this type"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            
                            if (handleOriginal) {
                                handleOriginal()
                            }

                        }
                    }
                }}
                columns={
                    [
                        {
                            id: "id",
                            Header: ' ',
                            accessor: "id",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 50,
                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.IDBADGE} fixedWidth />
                        },
                        /*{
                            id: "permissionName",
                            Header: "Name",
                            accessor: "permissionName",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },*/
                        {
                            id: "permissionDescription",
                            Header: "Description",
                            accessor: "permissionDescription",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        }
                    ]
                }
            />
        );
    }

    renderErrorAlert(): any {

        const {
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
        } = this.props

        return <div>
            The form was saved but was invalid with errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }


    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/personnel");
    }

    loadForm() {
        const {
            loadForm,
            personnel,
        } = this.props

        let extraData: Dtos.Personnel = {} as Dtos.Personnel;

        loadForm(reduxFormName, Object.assign({ ...personnel }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: PersonnelPermissionsPageProps): IPersonnelPermissionsPageProps => {

    let personnel: Dtos.Personnel | undefined = !(state.personnel.formData instanceof Array) ? state.personnel.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        personnelId: ownProps.match && ownProps.match.params.personnelId.toString() != "create" ? ownProps.match.params.personnelId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        personnel: personnel!,
        personnelPermissionGroups: state.personnel.groupedPersonnelPermissions,

        loadingPersonnel: state.personnel.loadState && state.personnel.loadState.status == RequestState.Pending,
        loadPersonneluccess: state.personnel.loadState && state.personnel.loadState.status == RequestState.Success,
        loadPersonnelFailure: state.personnel.loadState && state.personnel.loadState.status == RequestState.Failure,

        loadingPersonnelPermissions: state.personnel.loadPermissionsState && state.personnel.loadPermissionsState.status == RequestState.Pending,
        loadPersonnelPermissionsSuccess: state.personnel.loadPermissionsState && state.personnel.loadPermissionsState.status == RequestState.Success,
        loadPersonnelPermissionsFailure: state.personnel.loadPermissionsState && state.personnel.loadPermissionsState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,

        form: state.reduxForms.personnel,
        formState: state.personnel.formState,
        formProperties: state.personnel.formProperties,

        reduxFormState: state.reduxForms.formState.personnel,

        toggleItems: state.togglePage.items
    };
};

const mapDispatchToProps = (dispatch): IPersonnelPermissionsPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadPersonnel: bindActionCreators(PersonnelActions.LoadPersonnelById, dispatch),
        clearPersonnel: bindActionCreators(PersonnelActions.Clear, dispatch),

        loadPersonnelPermissions: bindActionCreators(PersonnelActions.LoadPersonnelPermissionsById, dispatch),

        toggleExpand: bindActionCreators(ToggleActions.ToggleExpanded, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(PersonnelPermissionsPage);
