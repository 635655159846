import * as React from 'react';

import * as classNames from "classnames";

import { Header, NavMenu, DefaultLayout, INavItem, Footer, SubMenu } from "./index";

interface IUnrestrictedLayoutProps {
    subMenuItems?: INavItem[]; 
}

class UnrestrictedLayout extends React.PureComponent<IUnrestrictedLayoutProps, any> {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps: any) {
    }

    componentDidUpdate(prevProps: any) {

    }

    componentWillUpdate(nextProps: any) {
    }

    componentWillUnmount() {
    }

    render() {

        const { subMenuItems } = this.props;

        return <DefaultLayout>
            {
                subMenuItems && subMenuItems.length > 0 ?
                    <SubMenu items={subMenuItems} /> :
                    null
            }
            <div>
                {this.props.children}
                <Footer></Footer>
            </div>
        </DefaultLayout>
    }
}

export default UnrestrictedLayout;