import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Tmd.dtos';

import {
    ACTION_TRIALITEMUPDATE_LOAD_BY_ID,
    ACTION_TRIALITEMUPDATE_LOAD_FAILURE,
    ACTION_TRIALITEMUPDATE_LOAD_SUCCESS,

    ACTION_TRIALITEMUPDATE_SAVE,
    ACTION_TRIALITEMUPDATE_SAVE_SUCCESS,
    ACTION_TRIALITEMUPDATE_SAVE_FAILURE,

    ACTION_TRIALITEMUPDATE_CLEAR,
    ACTION_TRIALITEMUPDATE_FILTER,

    TrialItemUpdateAction
} from '../actions/trialItemUpdate';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState, IFormFilter } from "./index";
import { updateFailureIndexToId } from "../helpers/formErrorHelper";

export interface ITrialItemUpdateState {
    formData: Dtos.TrialItemUpdate,
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    validationFailures: Dtos.ResponseError[];
    lookups: Dtos.Lookup[],
    loadState: IRequestState;
    saveState: IRequestState;
    updateState: IRequestState;
    createState: IRequestState;
    formFilter: IFormFilter;
    formFilteredData: Dtos.Trial[];
    formLength: number;
}

const initialState: ITrialItemUpdateState = {
    formData: undefined,
    formState: undefined,
    formProperties: undefined,
    validationFailures: undefined,
    lookups: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    updateState: {
        status: RequestState.None
    } as IRequestState,
    createState: {
        status: RequestState.None
    } as IRequestState,
    formFilter: {
        search: "",
        showInActive: false
    },
    formFilteredData: [],
    formLength: 0
};


function loading(state: ITrialItemUpdateState, isLoading: boolean): ITrialItemUpdateState {
    const newState: ITrialItemUpdateState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(state: ITrialItemUpdateState,
    formData: Dtos.TrialItemUpdate,
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[],
    responseStatus: Dtos.ResponseStatus
): ITrialItemUpdateState {
    const newState: ITrialItemUpdateState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        loadState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return filter(newState, state.formFilter);
}

function filter(state: ITrialItemUpdateState, formFilter: IFormFilter): ITrialItemUpdateState {
    /*
    const trials: Dtos.Trial[] | undefined =
        state.formData instanceof Array ?
            state.formData :
            undefined;

    const formLength = trials ? trials.length : 0;

    const formFilteredData = trials ? trials.filter(p => (p.active == true || formFilter.showInActive) &&
        (formFilter.search == "" || p.display.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1)) : undefined;
    */
    const newState: ITrialItemUpdateState = update(state, {
        formFilter: {
            $set: formFilter
        },
        //formFilteredData: {
        //    $set: formFilteredData
        //},
        //formLength: {
        //    $set: formLength
        //}
    });

    return newState;
}

function loadFailure(state: ITrialItemUpdateState, responseStatus: Dtos.ResponseStatus): ITrialItemUpdateState {
    const newState: ITrialItemUpdateState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: ITrialItemUpdateState, propertiesToClear?: string[]): ITrialItemUpdateState {

    if (!propertiesToClear) {
        const newState: ITrialItemUpdateState = update(initialState, {});
        return newState;
    }

    let stateModifier: any = {};

    propertiesToClear.forEach(propertyToClear => {
        stateModifier[propertyToClear] = { $set: initialState[propertyToClear] }
    });

    const newState = update(state, stateModifier);

    return newState;
}

function saving(state: ITrialItemUpdateState, isSaving: boolean): ITrialItemUpdateState {
    const newState: ITrialItemUpdateState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function saveSuccess(
    state: ITrialItemUpdateState,
    formData: Dtos.TrialItemUpdate,
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus
): ITrialItemUpdateState {
    const newState: ITrialItemUpdateState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        saveState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function saveFailure(state: ITrialItemUpdateState, responseStatus: Dtos.ResponseStatus): ITrialItemUpdateState {
    const newState: ITrialItemUpdateState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                meta: responseStatus.meta
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? responseStatus.errors : undefined
        }
    });

    return newState;
}

const TrialItemUpdateReducer: Reducer<ITrialItemUpdateState> = (state: ITrialItemUpdateState = initialState, action: TrialItemUpdateAction) => {
    switch (action.type) {
        case ACTION_TRIALITEMUPDATE_LOAD_BY_ID:
            return loading(state, true);
        case ACTION_TRIALITEMUPDATE_LOAD_SUCCESS:
            return loadSuccess(state, action.formData, action.formState, action.formProperties, action.lookups, action.responseStatus);
        case ACTION_TRIALITEMUPDATE_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_TRIALITEMUPDATE_CLEAR:
            return clear(state, action.propertiesToClear);
        case ACTION_TRIALITEMUPDATE_SAVE:
            return saving(state, true);
        case ACTION_TRIALITEMUPDATE_SAVE_SUCCESS:
            return saveSuccess(state, action.formData, action.formState, action.responseStatus);
        case ACTION_TRIALITEMUPDATE_SAVE_FAILURE:
            return saveFailure(state, action.responseStatus);
        case ACTION_TRIALITEMUPDATE_FILTER:
            return filter(state, action.formFilter);
    }

    return state;
}

export default TrialItemUpdateReducer;