import * as Dtos from "../../dtos/Tmd.dtos";

export const

    setAddModalViewState: ICollectionPageSetAddModalViewStateActionCreator = (open) => {
        return {
            type: ACTION_COLLECTION_PAGE_SET_ADD_MODAL_VIEW_STATE,
            open
        }
    },

    setAddFilter: ICollectionPageSetAddFilterActionCreator = (filter) => {
        return {
            type: ACTION_COLLECTION_PAGE_SET_FILTER,
            filter
        }
    },

    setSection: ICollectionPageSetSectionActionCreator = (sectionId) => {
        return {
            type: ACTION_COLLECTION_PAGE_SET_SECTION,
            sectionId
        }
    },

    toggleSectionsExpanded: ICollectionPageToggleSectionsExpandedActionCreator = () => {
        return {
            type: ACTION_COLLECTION_PAGE_TOGGLE_SECTIONS
        }
    },

    setSectionsExpanded: ICollectionPageSetSectionsExpandedActionCreator = (expanded) => {
        return {
            type: ACTION_COLLECTION_PAGE_SET_SECTIONS,
            expanded
        }
    },

    setSectionExpanded: ICollectionPageSetSectionExpandedActionCreator = (index) => {
        return {
            type: ACTION_COLLECTION_PAGE_SET_SECTION_EXPANDED,
            index
        }
    },

    setAddSelected: ICollectionPageSetAddSelectedActionCreator = (id, isSelected, count) => {
        return {
            type: ACTION_COLLECTION_PAGE_SET_ADD_SELECTED,
            id,
            isSelected,
            count
        }
    },

    setEditModalViewState: ICollectionPageSetEditModalViewStateActionCreator = (open) => {
        return {
            type: ACTION_COLLECTION_PAGE_SET_EDIT_MODAL_VIEW_STATE,
            open
        }
    },

    setPreviewModalViewState: ICollectionPageSetPreviewModalViewStateActionCreator = (open) => {
        return {
            type: ACTION_COLLECTION_PAGE_SET_PREVIEW_MODAL_VIEW_STATE,
            open
        }
    },

    setPreviewComponentModalViewState: ICollectionPageSetPreviewComponentModalViewStateActionCreator = (open, previewItemComponent) => {
        return {
            type: ACTION_COLLECTION_PAGE_SET_PREVIEW_COMPONENT_MODAL_VIEW_STATE,
            open,
            previewItemComponent
        }
    }

export const
    ACTION_COLLECTION_PAGE_SET_ADD_MODAL_VIEW_STATE = "ACTION_COLLECTION_PAGE_SET_ADD_MODAL_VIEW_STATE",
    ACTION_COLLECTION_PAGE_SET_FILTER = "ACTION_COLLECTION_PAGE_SET_FILTER",
    ACTION_COLLECTION_PAGE_SET_SECTION = "ACTION_COLLECTION_PAGE_SET_SECTION",
    ACTION_COLLECTION_PAGE_TOGGLE_SECTIONS = "ACTION_COLLECTION_PAGE_TOGGLE_SECTIONS",
    ACTION_COLLECTION_PAGE_SET_SECTIONS = "ACTION_COLLECTION_PAGE_SET_SECTIONS",
    ACTION_COLLECTION_PAGE_SET_SECTION_EXPANDED = "ACTION_COLLECTION_PAGE_SET_SECTION_EXPANDED",
    ACTION_COLLECTION_PAGE_SET_ADD_SELECTED = "ACTION_COLLECTION_PAGE_SET_ADD_SELECTED",
    ACTION_COLLECTION_PAGE_SET_EDIT_MODAL_VIEW_STATE = "ACTION_COLLECTION_PAGE_SET_EDIT_MODAL_VIEW_STATE",
    ACTION_COLLECTION_PAGE_SET_PREVIEW_MODAL_VIEW_STATE = "ACTION_COLLECTION_PAGE_SET_PREVIEW_MODAL_VIEW_STATE",
    ACTION_COLLECTION_PAGE_SET_PREVIEW_COMPONENT_MODAL_VIEW_STATE = "ACTION_COLLECTION_PAGE_SET_PREVIEW_COMPONENT_MODAL_VIEW_STATE"

/* Actions */

export interface ICollectionPageSetAddModalViewStateAction {
    type: "ACTION_COLLECTION_PAGE_SET_ADD_MODAL_VIEW_STATE",
    open: boolean;
}

export interface ICollectionPageSetAddFilterAction {
    type: "ACTION_COLLECTION_PAGE_SET_FILTER",
    filter: string;
}

export interface ICollectionPageSetSectionAction {
    type: "ACTION_COLLECTION_PAGE_SET_SECTION",
    sectionId: number;
}

export interface ICollectionPageToggleSectionsExpandedAction {
    type: "ACTION_COLLECTION_PAGE_TOGGLE_SECTIONS",
}

export interface ICollectionPageSetSectionsExpandedAction {
    type: "ACTION_COLLECTION_PAGE_SET_SECTIONS",
    expanded: boolean;
}

export interface ICollectionPageSetSectionExpandedAction {
    type: "ACTION_COLLECTION_PAGE_SET_SECTION_EXPANDED",
    index: number;
}

export interface ICollectionPageSetAddSelectedAction {
    type: "ACTION_COLLECTION_PAGE_SET_ADD_SELECTED",
    id: number;
    isSelected: boolean;
    count: number;
}

export interface ICollectionPageSetEditModalViewStateAction {
    type: "ACTION_COLLECTION_PAGE_SET_EDIT_MODAL_VIEW_STATE",
    open: boolean;
}

export interface ICollectionPageSetPreviewModalViewStateAction {
    type: "ACTION_COLLECTION_PAGE_SET_PREVIEW_MODAL_VIEW_STATE",
    open: boolean;
}

export interface ICollectionPageSetPreviewComponentModalViewStateAction {
    type: "ACTION_COLLECTION_PAGE_SET_PREVIEW_COMPONENT_MODAL_VIEW_STATE",
    open: boolean;
    previewItemComponent: Dtos.ItemComponent
}

/* Action Creators */

export interface ICollectionPageSetAddModalViewStateActionCreator {
    (open: boolean): ICollectionPageSetAddModalViewStateAction;
}

export interface ICollectionPageSetAddFilterActionCreator {
    (filter: string): ICollectionPageSetAddFilterAction;
}

export interface ICollectionPageSetSectionActionCreator {
    (sectionId: number): ICollectionPageSetSectionAction;
}

export interface ICollectionPageToggleSectionsExpandedActionCreator {
    (): ICollectionPageToggleSectionsExpandedAction;
}

export interface ICollectionPageSetSectionsExpandedActionCreator {
    (expanded: boolean): ICollectionPageSetSectionsExpandedAction;
}

export interface ICollectionPageSetSectionExpandedActionCreator {
    (index: number): ICollectionPageSetSectionExpandedAction;
}

export interface ICollectionPageSetAddSelectedActionCreator {
    (id: number, isSelected: boolean, count: number): ICollectionPageSetAddSelectedAction;
}

export interface ICollectionPageSetEditModalViewStateActionCreator {
    (open: boolean): ICollectionPageSetEditModalViewStateAction;
}

export interface ICollectionPageSetPreviewModalViewStateActionCreator {
    (open: boolean): ICollectionPageSetPreviewModalViewStateAction;
}

export interface ICollectionPageSetPreviewComponentModalViewStateActionCreator {
    (open: boolean, previewItemComponent: Dtos.ItemComponent): ICollectionPageSetPreviewComponentModalViewStateAction;
}


/* Final Export of actions */
export type CollectionPageAction =
    ICollectionPageSetAddModalViewStateAction |
    ICollectionPageSetAddFilterAction |
    ICollectionPageSetSectionActionCreator |
    ICollectionPageSetAddSelectedAction | 
    ICollectionPageSetEditModalViewStateAction |
    ICollectionPageSetPreviewModalViewStateAction |
    ICollectionPageSetPreviewComponentModalViewStateAction |
    ICollectionPageSetSectionExpandedAction |
    ICollectionPageToggleSectionsExpandedAction |
    ICollectionPageSetSectionsExpandedAction;
