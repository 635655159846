import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialGroupState: Dtos.Group & IRegistrationFormState = {
    id: null,
    name: null,

    otherNames: null,
    code: null,
    groupTypeId: null,
    groupType: null,
    groupTypeOther: null,
    address: null,
    address2: null,
    suburb: null,
    postcode: null,
    stateId: null,
    state: null,
    stateOther: null,
    countryId: null,
    country: null,
    mailAddress: null,
    mailAddress2: null,
    mailSuburb: null,
    mailPostcode: null,
    mailStateId: null,
    mailState: null,
    mailStateOther: null,
    mailCountryId: null,
    mailCountry: null,
    phone: null,
    fax: null,
    website: null,
    legalName: null,
    agreementAddressOptionId: null,
    addressOption: null,
    contractingPartyAddress: null,
    contractingPartyAddress2: null,
    contractingPartySuburb: null,
    contractingPartyPostcode: null,
    contractingPartyStateId: null,
    contractingPartyState: null,
    contractingPartyStateOther: null,
    contractingPartyCountryId: null,
    contractingPartyCountry: null,
    abn: null,
    acn: null,
    contactNotice: null,
    emailNotice: null,
    phoneNotice: null,
    faxNotice: null,
    acceptElectronicSignature: null,
    notes: [],

    rank: null,
    isDefault: null,
    active: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false,
    groupItems: [],
    groupItemCategories: [],
    personnelRoleSummaries: [],
    groupTrialSummaries: [],
}

export interface IGroupReduxFormState {
    id: IFieldState<number>,
    name: IFieldState<string>,

    otherNames: IFieldState<string>,
    code: IFieldState<string>,
    groupTypeId: IFieldState<number>,
    groupTypeOther: IFieldState<string>,
    address: IFieldState<string>,
    address2: IFieldState<string>,
    suburb: IFieldState<string>,
    postcode: IFieldState<string>,
    stateId: IFieldState<number>,
    countryId: IFieldState<number>,
    mailAddress: IFieldState<string>,
    mailAddress2: IFieldState<string>,
    mailSuburb: IFieldState<string>,
    mailPostcode: IFieldState<string>,
    mailStateId: IFieldState<number>,
    mailCountryId: IFieldState<number>,
    phone: IFieldState<string>,
    fax: IFieldState<string>,
    website: IFieldState<string>,
    legalName: IFieldState<string>,
    agreementAddressOptionId: IFieldState<number>,
    contractingPartyAddress: IFieldState<string>,
    contractingPartyAddress2: IFieldState<string>,
    contractingPartySuburb: IFieldState<string>,
    contractingPartyPostcode: IFieldState<string>,
    contractingPartyStateId: IFieldState<number>,
    contractingPartyCountryId: IFieldState<number>,
    abn: IFieldState<string>,
    acn: IFieldState<string>,
    contactNotice: IFieldState<string>,
    emailNotice: IFieldState<string>,
    phoneNotice: IFieldState<string>,
    faxNotice: IFieldState<string>,
    acceptElectronicSignature: IFieldState<number>,

    rank: IFieldState<number>,
    isDefault: IFieldState<boolean>,
    active: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>
    groupItems: IFieldState<Dtos.GroupItem[]>
    groupItemCategories: IFieldState<Dtos.GroupItemCategory[]>,
    personnelRoleSummaries: IFieldState<Dtos.PersonnelRoleSummary[]>
}
