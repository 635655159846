import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trialTreatmentsApi = {
    getTrialTreatments: (trialId: number): Observable<Dtos.GetTrialTreatmentsResponse> => {
        //Create the request.
        let request: Dtos.GetTrialTreatments = new Dtos.GetTrialTreatments();
        request.trialId = trialId;

        //Send request.
        const response: Promise<Dtos.GetTrialTreatmentsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrailTreatmentById: (id: number): Observable<Dtos.GetTrialTreatmentByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrialTreatmentById = new Dtos.GetTrialTreatmentById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTrialTreatmentByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrialTreatment: (trialTreatment: Dtos.TrialTreatment): Observable<Dtos.SetTrialTreatmentResponse> => {

        let request: Dtos.SetTrialTreatment = new Dtos.SetTrialTreatment();

        request.trialTreatment = trialTreatment;

        // send request
        const response: Promise<Dtos.SetTrialTreatmentResponse> = client.post(request);

        return Observable.from(response);
    },
    createTrialTreatment: (trialId: number): Observable<Dtos.CreateTrialTreatmentResponse> => {
        //Create the request.
        let request: Dtos.CreateTrialTreatment = new Dtos.CreateTrialTreatment();

        request.trialId = trialId;

        //Send request.
        const response: Promise<Dtos.CreateTrialTreatmentResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    syncTrialTreatment: (trialId: number): Observable<Dtos.SyncTrialTreatmentResponse> => {

        let request: Dtos.SyncTrialTreatment = new Dtos.SyncTrialTreatment();

        request.trialId = trialId;

        // send request
        const response: Promise<Dtos.SyncTrialTreatmentResponse> = client.post(request);

        return Observable.from(response);
    }
}