import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trialInstitutionsApi = {
    getTrialInstitutions: (): Observable<Dtos.GetTrialInstitutionsResponse> => {
        //Create the request.
        let request: Dtos.GetTrialInstitutions = new Dtos.GetTrialInstitutions();

        //Send request.
        const response: Promise<Dtos.GetTrialInstitutionsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrailItemById: (id: number): Observable<Dtos.GetTrialInstitutionByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrialInstitutionById = new Dtos.GetTrialInstitutionById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTrialInstitutionByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrialInstitution: (trialInstitution: Dtos.TrialInstitution): Observable<Dtos.SetTrialInstitutionResponse> => {

        let request: Dtos.SetTrialInstitution = new Dtos.SetTrialInstitution();

        request.trialInstitution = trialInstitution;

        // send request
        const response: Promise<Dtos.SetTrialInstitutionResponse> = client.post(request);

        return Observable.from(response);

    },
    createTrialInstitution: (trialId: number): Observable<Dtos.CreateTrialInstitutionResponse> => {
        //Create the request.
        let request: Dtos.CreateTrialInstitution = new Dtos.CreateTrialInstitution();

        request.trialId = trialId;

        //Send request.
        const response: Promise<Dtos.CreateTrialInstitutionResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}