import * as React from "react";
import * as classNames from "classnames";
import ReactSelectClass from "react-select";
import { IBaseFormInputProps } from '../';
import { FontAwesomeIcons, FontAwesomeIcon } from "../../../../constants/fontAwesomeIcons";
import './SelectFormInput.scss';
import { IOptionConfig } from '../../../../interfaces/forms/IOptionConfig';


export interface ISelectFormInputProps {
    loading: boolean;
    multi: boolean;
    options: any[];
    clearable: boolean;
    backspaceRemoves: boolean;
    matchPos?: string;
    placeholder?: string;
    customArrowRender?: string;
    maxOptionValueDisplay?: number;
    feedbackIcon?: string;
    hideFeedback?: boolean;
}

class SelectFormInput extends React.Component<ISelectFormInputProps & IBaseFormInputProps, any> {
    constructor(props) {
        super(props)

        this.onMultiSelectChange = this.onMultiSelectChange.bind(this)
        this.onSelectChange = this.onSelectChange.bind(this);
    }

    render() {

        const {
            valid,
            invalid,
            className,
            name,
            onBlur,
            multi,
            onFocus,
            value,
            disabled,
            loading,
            options,
            clearable,
            backspaceRemoves,
            matchPos,
            placeholder,
            feedbackIcon,
            hideFeedback,
            hasSoftError,
            hasHardError

        } = this.props

        const derivedClassName = className ? className : undefined;


        const hardFeedback = hasHardError && !disabled ?
            <span className={classNames("form-control-feedback block pr-2")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.BAN} fixedWidth />
            </span> :
            null;

        const feedback = (invalid && !disabled) && !hideFeedback && !hasHardError ?
                    <span className={classNames("form-control-feedback pr-2")}>
                        <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE} fixedWidth />
                    </span> :
                    null

        const softFeedback = hasSoftError && !disabled && (!invalid) ?
            <span className={classNames("form-control-feedback warning pr-12")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.EXCLAMATION_TRIANGLE} fixedWidth />
            </span> :
            null;

        const selectInput = <div className={
            classNames(
                "form-select-group"
            )}>
            <ReactSelectClass
                className={classNames(
                    derivedClassName,
                    { "is-invalid": invalid && !disabled },
                    { "is-warning": hasSoftError && !disabled && (!invalid) }
                )}
                name={name}
                onBlur={onBlur}
                onChange={multi ? this.onMultiSelectChange : this.onSelectChange}
                onFocus={onFocus}
                value={value || value == 0 ? value : ''}
                disabled={disabled}
                isLoading={loading}
                multi={multi}
                options={options}
                clearable={clearable}
                backspaceRemoves={backspaceRemoves}
                matchPos={matchPos}
                placeholder={placeholder}
                arrowRenderer={this.renderArrow()}
            />
        </div>

        return <div style={{ position: "relative" }}>
            {selectInput}
            {hardFeedback}
            {feedback}
            {softFeedback}
        </div>

        //return (<div className={
        //    classNames(
        //        "form-select-group"
        //    )}>
        //    <ReactSelectClass
        //        className={classNames(
        //            derivedClassName,
        //            { "is-invalid": invalid && !disabled },
        //            { "is-warning": hasSoftError && !disabled && (!invalid) }
        //        )}
        //        name={name}
        //        onBlur={onBlur}
        //        onChange={multi ? this.onMultiSelectChange : this.onSelectChange}
        //        onFocus={onFocus}
        //        value={value || value == 0 ? value : ''}
        //        disabled={disabled}
        //        isLoading={loading}
        //        multi={multi}
        //        options={options}
        //        clearable={clearable}
        //        backspaceRemoves={backspaceRemoves}
        //        matchPos={matchPos}
        //        placeholder={placeholder}
        //        arrowRenderer={this.renderArrow()}
        //    />

        //    {
        //        (invalid && !disabled) && !hideFeedback ?
        //            <span className={classNames("form-control-feedback pr-2")}>
        //                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE} fixedWidth />
        //            </span> :
        //            null
        //    }
        //</div>);
    }


    onSelectChange(val: ReactSelectClass.Option) {
        if (!val) {
            this.props.onChange(null);
        }
        else {

            this.props.onChange(val.value);
        }
    }

    onMultiSelectChange(values: ReactSelectClass.Option[]) {
        this.props.onChange(values.map(val => val.value));
    }

    renderArrow() {
        const {
            customArrowRender,
            invalid,
            disabled,
            hideFeedback
        } = this.props;


        if (invalid) {
            if (hideFeedback) {
                return undefined;
            }
            return null;
        }

        if (customArrowRender) {
            return customArrowRender
        }

        return undefined;
    }
}

export default SelectFormInput;