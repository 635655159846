import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const formTemplatesApi = {
    getFormTemplates: (): Observable<Dtos.GetFormTemplatesResponse> => {
        //Create the request.
        let request: Dtos.GetFormTemplates = new Dtos.GetFormTemplates();

        //Send request.
        const response: Promise<Dtos.GetFormTemplatesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getFormTemplateById: (id: number): Observable<Dtos.GetFormTemplateByIdResponse> => {
        //Create the request.
        let request: Dtos.GetFormTemplateById = new Dtos.GetFormTemplateById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetFormTemplateByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setFormTemplate: (formTemplate: Dtos.FormTemplate, collection: Dtos.Collection): Observable<Dtos.SetFormTemplateResponse> => {

        let request: Dtos.SetFormTemplate = new Dtos.SetFormTemplate();

        request.formTemplate = formTemplate;
        request.collection = collection;

        // send request
        const response: Promise<Dtos.SetFormTemplateResponse> = client.post(request);

        return Observable.from(response);

    },
    createFormTemplate: (): Observable<Dtos.CreateFormTemplateResponse> => {
        //Create the request.
        let request: Dtos.CreateFormTemplate = new Dtos.CreateFormTemplate();

        //Send request.
        const response: Promise<Dtos.CreateFormTemplateResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}