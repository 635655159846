import * as React from 'react';
import './ItemSettingsLayout.scss';
import { connect } from "react-redux";
import { RestrictedLayout } from "../../../components/layouts/index";
import { ITmdState } from "../../../reducers/index";
import * as Dtos from '../../../dtos/Tmd.dtos';
import { SettingsSubMenu } from '../../../helpers/subMenuHelper';
import * as AuthenticationHelper from '../../../helpers/authentication';
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";

interface IItemSettingsLayoutProps {
    permissions?: Dtos.PersonnelTmdPermissions[];
    permission?: Dtos.Permission;
    loading?: boolean;
    children?: any;
}

interface IItemSettingsLayoutActions {

}

type ItemSettingsLayoutProps = IItemSettingsLayoutProps & IItemSettingsLayoutActions;

class ItemSettingsLayout extends React.PureComponent<ItemSettingsLayoutProps, any> {

    constructor(props: ItemSettingsLayoutProps) {
        super(props);

    }

    render() {
        const {
            loading,
            permissions,
            permission
        } = this.props;

        let menu: any = [];
        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TemplateTrialItemsView)) {
            menu.push({
                icon: undefined,
                title: "Trial Item Templates",
                url: "/settings/item/trialitemtemplates"
            });
        }
        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialInstitutionItemTemplatesView)) {
            menu.push({
                icon: undefined,
                title: "Trial Institution Item Templates",
                url: "/settings/item/itemtemplates"
            });
        }
        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialInstitutionItemTemplatesView)) {
            menu.push({
                icon: undefined,
                title: "Item Components",
                url: "/settings/item/itemcomponents"
            });
        }
        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.CategoriesView)) {
            menu.push({
                icon: undefined,
                title: "Item Categories",
                url: "/settings/item/itemcategories"
            });
        }

        return <RestrictedLayout
            subMenuItems={loading ? [] : SettingsSubMenu(permissions)}
            sub2MenuItems={loading ? [] : menu}
            loading={loading}
        >
            {permissions.length > 0 && permission && !AuthenticationHelper.hasPermission(permissions, permission) ?
                <div className="alert alert-warning d-flex">
                    <div className="mr-2">
                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                    </div>
                    <span>Permission Denied</span>
                </div>
                : this.props.children}
        </RestrictedLayout>;
    }

}


const mapStateToProps = (state: ITmdState, ownProps: ItemSettingsLayoutProps): IItemSettingsLayoutProps => {
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];
    return {
        permissions: permissions,
        permission: ownProps.permission,
        loading: ownProps.loading,
        children: ownProps.children
    };
};

const mapDispatchToProps = (dispatch): IItemSettingsLayoutActions => {
    return {
        
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(ItemSettingsLayout);
