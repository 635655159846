import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadState: IStateLoadActionCreator = () => {
        return {
            type: ACTION_STATES_LOAD
        }
    },
    LoadStateById: IStateLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_STATES_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IStateLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_STATES_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IStateLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_STATES_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveState: IStateSaveActionCreator = (formData) => {
        return {
            type: ACTION_STATES_SAVE,
            formData
        }
    },
    SaveSuccess: IStateSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_STATES_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IStateSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_STATES_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateState: IStateCreateActionCreator = () => {

        return {
            type: ACTION_STATES_CREATE
        }
    },
    CreateSuccess: IStateCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_STATES_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: IStateCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_STATES_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IStateClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_STATES_CLEAR,
            propertiesToClear
        }
    },
    Filter: IStateFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_STATES_FILTER,
            formFilter
        }
    };



export const
    ACTION_STATES_LOAD = "ACTION_STATES_LOAD",
    ACTION_STATES_LOAD_BY_ID = "ACTION_STATES_LOAD_BY_ID",
    ACTION_STATES_LOAD_SUCCESS = "ACTION_STATES_LOAD_SUCCESS",
    ACTION_STATES_LOAD_FAILURE = "ACTION_STATES_LOAD_FAILURE",

    ACTION_STATES_SAVE = "ACTION_STATES_SAVE",
    ACTION_STATES_SAVE_SUCCESS = "ACTION_STATES_SAVE_SUCCESS",
    ACTION_STATES_SAVE_FAILURE = "ACTION_STATES_SAVE_FAILURE",

    ACTION_STATES_CREATE = "ACTION_STATES_CREATE",
    ACTION_STATES_CREATE_SUCCESS = "ACTION_STATES_CREATE_SUCCESS",
    ACTION_STATES_CREATE_FAILURE = "ACTION_STATES_CREATE_FAILURE",

    ACTION_STATES_CLEAR = "ACTION_STATES_CLEAR",

    ACTION_STATES_FILTER = "ACTION_STATES_FILTER";

export interface IStateLoadAction {
    type: "ACTION_STATES_LOAD";
}

export interface IStateLoadActionCreator {
    (): IStateLoadAction;
}

export interface IStateLoadSuccessAction {
    type: "ACTION_STATES_LOAD_SUCCESS";
    formData: Dtos.State | Dtos.State[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IStateLoadSuccessActionCreator {
    (
        formData: Dtos.State | Dtos.State[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IStateLoadSuccessAction;
}

export interface IStateLoadFailureAction {
    type: "ACTION_STATES_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IStateLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IStateLoadFailureAction;
}

export interface IStateLoadByIdAction {
    type: "ACTION_STATES_LOAD_BY_ID";
    id: number;
}

export interface IStateLoadByIdActionCreator {
    (id: number): IStateLoadByIdAction;
}


export interface IStateSaveAction {
    type: "ACTION_STATES_SAVE";
    formData: Dtos.State;

}

export interface IStateSaveActionCreator {
    (
        formData: Dtos.State
    ): IStateSaveAction;
}

export interface IStateSaveSuccessAction {
    type: "ACTION_STATES_SAVE_SUCCESS";
    formData: Dtos.State;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IStateSaveSuccessActionCreator {
    (
        formData: Dtos.State,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IStateSaveSuccessAction;
}

export interface IStateSaveFailureAction {
    type: "ACTION_STATES_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IStateSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IStateSaveFailureAction;
}

export interface IStateCreateAction {
    type: "ACTION_STATES_CREATE"
}

export interface IStateCreateActionCreator {
    (): IStateCreateAction
}

export interface IStateCreateSuccessAction {
    type: "ACTION_STATES_CREATE_SUCCESS";
    formData: Dtos.State;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface IStateCreateSuccessActionCreator {
    (
        formData: Dtos.State,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): IStateCreateSuccessAction;
}

export interface IStateCreateFailureAction {
    type: "ACTION_STATES_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IStateCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IStateCreateFailureAction;
}

export interface IStateClearAction {
    type: "ACTION_STATES_CLEAR";
    propertiesToClear?: string[];
}

export interface IStateClearActionCreator {
    (propertiesToClear?: string[]): IStateClearAction;
}

export interface IStateFilterActionCreator {
    (formFilter: IFormFilter): IStateFilterAction;
}

export interface IStateFilterAction {
    type: "ACTION_STATES_FILTER";
    formFilter: IFormFilter;
}

export type StateAction =
    IStateLoadAction |
    IStateLoadByIdAction |
    IStateLoadFailureAction |
    IStateLoadSuccessAction |
    IStateSaveAction |
    IStateSaveSuccessAction |
    IStateSaveFailureAction |
    IStateCreateAction |
    IStateCreateSuccessAction |
    IStateCreateFailureAction |
    IStateClearAction |
    IStateFilterAction;
