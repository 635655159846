import * as React from 'react';
import './InstitutionDepartmentEditPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";
import { IDepartmentReduxFormState, initialDepartmentState } from "../../../reducers/reactReduxForms/department";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, DatePickerFormInputFabric, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as DepartmentActions from '../../../actions/department';
import * as InstitutionActions from '../../../actions/institution';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { Form, actions, Control } from 'react-redux-form';
import { InstitutionSubMenu } from '../../../helpers/subMenuHelper';

interface IInstitutionDepartmentEditPageParams {
    institutionId: number;
    departmentId: number;
}

interface IInstitutionDepartmentEditPageProps {

    form: Dtos.Department & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IDepartmentReduxFormState;

    location: Location;
    institutionId: number;
    departmentId: number;
    match: match<IInstitutionDepartmentEditPageParams>;
    history: History;

    // From state
    department: Dtos.Department;
    institution: Dtos.Institution;
    lookups: Dtos.Lookup[];

    loadingInstitution: boolean;

    loadingDepartment: boolean;
    loadDepartmentSuccess: boolean;
    loadDepartmentFailure: boolean;

    savingDepartment: boolean;
    saveDepartmentSuccess: boolean;
    saveDepartmentFailure: boolean;

    creatingDepartment: boolean;
    createDepartmentSuccess: boolean;
    createDepartmentFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface IInstitutionDepartmentEditPageActions {
    clearDepartment: DepartmentActions.IDepartmentClearActionCreator;
    loadDepartment: DepartmentActions.IDepartmentLoadByIdActionCreator;
    saveDepartment: DepartmentActions.IDepartmentSaveActionCreator;
    createDepartment: DepartmentActions.IDepartmentCreateActionCreator;

    clearInstitution: InstitutionActions.IInstitutionClearActionCreator;
    loadInstitution: InstitutionActions.IInstitutionLoadByIdActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type InstitutionDepartmentEditPageProps = IInstitutionDepartmentEditPageProps & IInstitutionDepartmentEditPageActions;

const formName: string = "department";
const reduxFormName: string = "reduxForms.department";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class InstitutionDepartmentEditPage extends React.PureComponent<InstitutionDepartmentEditPageProps, any> {

    constructor(props: InstitutionDepartmentEditPageProps) {
        super(props);

        this.clearDepartment = this.clearDepartment.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadDepartment,
            createDepartment,
            navigate,
            navigateReplace,
            departmentId,
            institutionId,
            loadInstitution
        } = this.props;

        if (departmentId) {
            loadDepartment(departmentId);
        } else if (!departmentId) {
            createDepartment(institutionId);
        }
        loadInstitution(institutionId);
    }

    componentWillReceiveProps(nextProps: InstitutionDepartmentEditPageProps) {
    }

    componentDidUpdate(prevProps: InstitutionDepartmentEditPageProps, prevState) {
        const {
            form,
            institutionId,
            departmentId,
            department,
            navigateReplace,
            loadDepartment,
            loadInstitution,
            loadingDepartment,
            loadDepartmentSuccess,
            savingDepartment,
            saveDepartmentSuccess,
            saveDepartmentFailure,
            createDepartment,
            validationFailures,
            creatingDepartment,
            createDepartmentSuccess,
            navigate
        } = this.props;

        if (institutionId && prevProps.institutionId != institutionId) {
            loadInstitution(institutionId);
        }

        if (departmentId && prevProps.departmentId != departmentId) {
            loadDepartment(departmentId);
        }
        else if (!departmentId && prevProps.departmentId) {
            createDepartment(institutionId);
        }

        if (!loadingDepartment && prevProps.loadingDepartment) {
            if (loadDepartmentSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingDepartment && prevProps.savingDepartment) {
            if (saveDepartmentSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/institutions/" + institutionId + "/departments")
                } else if (!form.saveAndReturn && departmentId && departmentId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !departmentId && department && department.id > 0) {
                    navigate("/institutions/" + institutionId + "/departments/" + department.id);
                    this.loadForm();
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingDepartment && prevProps.creatingDepartment) {
            if (createDepartmentSuccess) {
                this.loadForm();
            }
            else {

            }
        }
    }

    componentWillUpdate(nextProps: InstitutionDepartmentEditPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearDepartment();
        loadForm(reduxFormName, initialDepartmentState);
        resetForm(reduxFormName);
    }

    clearDepartment() {
        this.props.clearDepartment();
        this.props.clearInstitution();
    }

    showDepartment(): boolean {
        const {
            loadingInstitution,
            loadingDepartment,
            loadDepartmentSuccess
        } = this.props

        if (!loadingInstitution && !loadingDepartment && loadDepartmentSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingInstitution,
            loadingDepartment,
            institution,
            institutionId,
            permissions
        } = this.props;

        let id = institution ? institution.id : 0;
        let name = institution ? institution.name : "Institution";

        return <RestrictedLayout
            permission={Dtos.Permission.InstitutionDepartmentView}
            subMenuTitle={institution ? institution.display : ""}
            subMenuItems={InstitutionSubMenu(id, permissions)}
            loading={loadingInstitution || loadingDepartment}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingDepartment,
            institutionId,
            departmentId,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.InstitutionDepartmentUpdate, Dtos.Permission.InstitutionDepartmentCreate);
        const canCreatePersonnelRole: boolean = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.InstitutionPersonnelCreate, null, institutionId);
        const formDisabled: boolean = !hasPermission || savingDepartment || (formState && formState.formStatus == Dtos.FormStatus.Disabled);
        const personnelRoles = (form && form.personnelRoles) ? form.personnelRoles : [];

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            name: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
                labelClassName: "col-form-label col-sm-3",
                fieldClassName: "col-sm-9 generic-form-field",
            },
            addressGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    physicalAddressHeading: {
                        type: RegistrationComponent.RegistrationElement,
                        size: 1,
                        component: <div className="mt-3">
                            <h5>Physical Address</h5>
                        </div>
                    },
                    address1: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 1
                    },
                    address2: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 1
                    },
                    suburb: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    postcode: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    stateId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Department", "StateId", lookups), form.stateId)
                        },
                        size: 2,
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + '.state', null);
                            if (value == null || value != 10) {
                                changeForm(reduxFormName + parentModel + '.stateOther', null);
                            }
                        },
                    },
                    stateOther: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled || (form && form.stateId == null) || (form && form.stateId != 10),
                        size: 2
                    },
                    countryId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Department", "CountryId", lookups), form.countryId)
                        },
                        size: 2
                    }
                },
            },
            mailingAddressGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    physicalAddressHeading: {
                        type: RegistrationComponent.RegistrationElement,
                        size: 1,
                        component: <div className="mt-3">
                            <h5>Mailing Address</h5>
                        </div>
                    },
                    mailAddress1: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 1
                    },
                    mailAddress2: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 1
                    },
                    mailSuburb: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    mailPostcode: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    mailStateId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Department", "MailStateId", lookups), form.mailStateId)
                        },
                        size: 2,
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + '.mailState', null);
                            if (value == null || value != 10) {
                                changeForm(reduxFormName + parentModel + '.mailStateOther', null);
                            }
                        },
                    },
                    mailStateOther: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled || (form && form.mailStateId == null) || (form && form.mailStateId != 10),
                        size: 2
                    },
                    mailCountryId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Department", "MailCountryId", lookups), form.mailCountryId)
                        },
                        size: 2
                    }
                }
            },
            phone: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
                labelClassName: "col-form-label col-sm-3",
                fieldClassName: "col-sm-9 generic-form-field",
            },
            fax: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
                labelClassName: "col-form-label col-sm-3",
                fieldClassName: "col-sm-9 generic-form-field",
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
                labelClassName: "col-form-label col-sm-3",
                fieldClassName: "col-sm-9 generic-form-field",
            },
            personnelRolesHeading: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="border-0 d-flex pt-4 pl-0">
                    <h5>Department Roles</h5>
                </div>
            },
            personnelRoles: {
                type: RegistrationComponent.RegistrationElement,
                component: !personnelRoles || personnelRoles.length == 0 ? <><div className="alert alert-info d-flex">
                    <div className="mr-2">
                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                    </div>
                    <span>There are currently no department roles to view.</span>
                </div>
                    <div className="mt-3 mb-3">
                        {(canCreatePersonnelRole && departmentId) && <Link className={"btn btn-secondary btn-sm"} url={"/institutions/" + institutionId + "/departments/" + departmentId + "/personnel/create"}>Add Role</Link>}
                    </div>
                </> : <div className="table-wrapper mb-3">
                    <ReactTable
                        data={personnelRoles}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        defaultPageSize={personnelRoles.length}
                        pageSize={personnelRoles.length}
                        showPagination={false}
                        className="-striped -highlight -clickable mb-3"
                        noDataText="There are currently no roles to display"
                        resizable={false}
                        onPageSizeChange={val => { }}
                        getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                            return {
                                onClick: (e, handleOriginal) => {

                                    const url: string = "/personnel/" + rowInfo.original.personnelId + "/details";
                                    this.props.navigate(url);

                                    if (handleOriginal) {
                                        handleOriginal()
                                    }

                                },
                                style: {
                                    color: (rowInfo && rowInfo.original && rowInfo.original.active) ? '#212529' : '#9e9e9e'
                                }
                            }
                        }}
                        defaultSorted={[
                            {
                                id: "personnelDisplay",
                                desc: false
                            }
                        ]}
                        columns={[
                            {
                                id: "id",
                                Header: ' ',
                                accessor: "id",
                                className: "d-flex align-items-center justify-content-center",
                                maxWidth: 50,
                                Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.USER} fixedWidth />
                            },
                            {
                                id: "personnelDisplay",
                                Header: 'Name',
                                accessor: 'personnelDisplay',
                                className: "d-flex align-items-left justify-content-left",
                                headerClassName: "text-left"
                            },
                            {
                                id: "roleDisplay",
                                Header: 'Role',
                                accessor: 'roleDisplay',
                                className: "d-flex align-items-left justify-content-left",
                                headerClassName: "text-left"
                            },
                            {
                                id: "trialDisplay",
                                Header: "Trial",
                                accessor: "trialDisplay",
                                className: "d-flex align-items-left justify-content-left",
                                headerClassName: "text-left"
                            },
                            {
                                id: "email",
                                Header: 'Email',
                                accessor: 'emailDisplay',
                                className: "d-flex align-items-left justify-content-left",
                                headerClassName: "text-left"
                            },
                            {
                                id: "phone",
                                Header: 'Phone',
                                accessor: 'personnel.phone',
                                className: "d-flex align-items-left justify-content-left",
                                headerClassName: "text-left"
                            },
                        ]}
                    />
                    <div className="mt-3 mb-3">
                            {(canCreatePersonnelRole && departmentId) && <Link className={"btn btn-secondary btn-sm"} url={"/institutions/" + institutionId + "/departments/" + departmentId + "/personnel/create"}>Add Role</Link>}
                    </div>
                </div>
            }
        };

        return <div>
            <h2>Institution Department</h2>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "department", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
        </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.Department) {
        const {
            saveDepartment
        } = this.props;

        if (data) {
            let changes: Dtos.Department = {} as Dtos.Department;
            saveDepartment(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.Department) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            institutionId,
            navigate
        } = this.props;

        navigate("/institutions/" + institutionId + "/departments");
    }

    loadForm() {
        const {
            loadForm,
            department,
        } = this.props

        let extraData: Dtos.Department = {} as Dtos.Department;

        loadForm(reduxFormName, Object.assign({ ...department }, extraData));
    }
}


const mapStateToProps = (state: ITmdState, ownProps: InstitutionDepartmentEditPageProps): IInstitutionDepartmentEditPageProps => {

    let department: Dtos.Department | undefined = !(state.departments.formData instanceof Array) ? state.departments.formData : undefined;
    let institution: Dtos.Institution | undefined = !(state.institutions.formData instanceof Array) ? state.institutions.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        institutionId: ownProps.match ? ownProps.match.params.institutionId : undefined!,
        departmentId: ownProps.match && ownProps.match.params.departmentId.toString() != "create" ? ownProps.match.params.departmentId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        department: department!,

        institution: institution!,

        loadingDepartment: state.departments.loadState && state.departments.loadState.status == RequestState.Pending,
        loadDepartmentSuccess: state.departments.loadState && state.departments.loadState.status == RequestState.Success,
        loadDepartmentFailure: state.departments.loadState && state.departments.loadState.status == RequestState.Failure,

        savingDepartment: state.departments.saveState && state.departments.saveState.status == RequestState.Pending,
        saveDepartmentSuccess: state.departments.saveState && state.departments.saveState.status == RequestState.Success,
        saveDepartmentFailure: state.departments.saveState && state.departments.saveState.status == RequestState.Failure,

        creatingDepartment: state.departments.createState && state.departments.createState.status == RequestState.Pending,
        createDepartmentSuccess: state.departments.createState && state.departments.createState.status == RequestState.Success,
        createDepartmentFailure: state.departments.createState && state.departments.createState.status == RequestState.Failure,

        loadingInstitution: state.institutions.loadState && state.institutions.loadState.status == RequestState.Pending,

        user: state.user.data,
        permissions: permissions,
        lookups: state.departments.lookups,

        form: state.reduxForms.department,
        formState: state.departments.formState,
        formProperties: state.departments.formProperties,

        reduxFormState: state.reduxForms.formState.department,
        validationFailures: department ?
            state.departments.validationFailures :
            undefined,

    };
};

const mapDispatchToProps = (dispatch): IInstitutionDepartmentEditPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadDepartment: bindActionCreators(DepartmentActions.LoadDepartmentById, dispatch),
        clearDepartment: bindActionCreators(DepartmentActions.Clear, dispatch),
        createDepartment: bindActionCreators(DepartmentActions.CreateDepartment, dispatch),
        saveDepartment: bindActionCreators(DepartmentActions.SaveDepartment, dispatch),

        loadInstitution: bindActionCreators(InstitutionActions.LoadInstitutionById, dispatch),
        clearInstitution: bindActionCreators(InstitutionActions.Clear, dispatch)

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(InstitutionDepartmentEditPage);
