import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_TEMPLATETRIALITEMS_LOAD,
    ACTION_TEMPLATETRIALITEMS_LOAD_BY_CATEGORY,
    ACTION_TEMPLATETRIALITEMS_LOAD_BY_ID,

    ACTION_TEMPLATETRIALITEMS_SAVE,

    ACTION_TEMPLATETRIALITEMS_CREATE,

    ITemplateTrialItemLoadAction,
    ITemplateTrialItemLoadByCategoryAction,
    ITemplateTrialItemLoadByIdAction,
    ITemplateTrialItemLoadFailureAction,
    ITemplateTrialItemLoadSuccessAction,

    ITemplateTrialItemSaveAction,
    ITemplateTrialItemSaveSuccessAction,
    ITemplateTrialItemSaveFailureAction,

    ITemplateTrialItemCreateAction,
    ITemplateTrialItemCreateSuccessAction,
    ITemplateTrialItemCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/templateTrialItems";
import { templateTrialItemsApi } from "../services/templateTrialItem";


export const
    getTemplateTrialItemsEpic = (action$): Observable<ITemplateTrialItemLoadSuccessAction | ITemplateTrialItemLoadFailureAction> => {
        return action$
            .ofType(ACTION_TEMPLATETRIALITEMS_LOAD)
            .mergeMap((action: ITemplateTrialItemLoadAction) => {
                return templateTrialItemsApi
                    .getTemplateTrialItems()
                    .map(response =>
                        LoadSuccess([], undefined, undefined, [], response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTemplateTrialItemsByCategoryEpic = (action$): Observable<ITemplateTrialItemLoadSuccessAction | ITemplateTrialItemLoadFailureAction> => {
        return action$
            .ofType(ACTION_TEMPLATETRIALITEMS_LOAD_BY_CATEGORY)
            .mergeMap((action: ITemplateTrialItemLoadByCategoryAction) => {
                return templateTrialItemsApi
                    .getTemplateTrialItemsByCategory()
                    .map(response =>
                        LoadSuccess(response.templateTrialItemsByCategory, undefined, undefined, [], response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTemplateTrialItemByIdEpic = (action$): Observable<ITemplateTrialItemLoadSuccessAction | ITemplateTrialItemLoadFailureAction> => {
        return action$
            .ofType(ACTION_TEMPLATETRIALITEMS_LOAD_BY_ID)
            .mergeMap((action: ITemplateTrialItemLoadByIdAction) => {
                return templateTrialItemsApi
                    .getTemplateTrialItemById(action.id)
                    .map(response =>
                        LoadSuccess(response.templateTrialItem, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setTemplateTrialItemEpic = (action$): Observable<ITemplateTrialItemSaveSuccessAction | ITemplateTrialItemSaveFailureAction> => {
        return action$
            .ofType(ACTION_TEMPLATETRIALITEMS_SAVE)
            .mergeMap((action: ITemplateTrialItemSaveAction) => {
                return templateTrialItemsApi
                    .setTemplateTrialItem(action.formData, action.collectionFormData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.templateTrialItem, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createTemplateTrialItemEpic = (action$): Observable<ITemplateTrialItemCreateSuccessAction | ITemplateTrialItemCreateFailureAction> => {
        return action$
            .ofType(ACTION_TEMPLATETRIALITEMS_CREATE)
            .mergeMap((action: ITemplateTrialItemCreateAction) => {
                return templateTrialItemsApi
                    .createTemplateTrialItem()
                    .map(response =>
                        CreateSuccess(
                            response.templateTrialItem,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



