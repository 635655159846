import './InfoPanel.scss';

import * as React from 'react';
import * as ReactTooltip from 'react-tooltip';

import * as classNames from "classnames";
import { FontAwesomeIcon } from "../../../constants/fontAwesomeIcons";
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

interface IInfoPanelProps {
    topText?: string;
    bottomText?: string;
    backgroundClass?: string;
    icon?: IconDefinition;
    toolTipText?: string;
}

class InfoPanel extends React.PureComponent<IInfoPanelProps, any> {

    render() {

        const {
            topText,
            bottomText,
            backgroundClass,
            icon,
            toolTipText
        } = this.props

        

        return <div className="col">
            <div data-tip={toolTipText} className={classNames("text-white rounded d-flex", backgroundClass) }>
                <div className="p-4 bg-overlay text-center">
                    <FontAwesomeIcon icon={icon} fixedWidth size="3x" />
                </div>
                <div className="p-4 d-flex flex-column justify-content-between info-panel-text-container">
                    <div className="lead info-panel-text">
                        {topText}
                    </div>
                    <small className="text-uppercase info-panel-text">
                        {bottomText}
                    </small>
                </div>
            </div>
            {
                toolTipText ?
                    <ReactTooltip /> :
                    null
            }
        </div>
    }
}
export default InfoPanel