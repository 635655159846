import * as React from 'react';

import * as classNames from "classnames";
import './InstitutionFormEntryPage.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";



import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";
// add state for sub forms too below
import { IInstitutionFormReduxFormState, initialInstitutionFormState } from "../../../reducers/reactReduxForms/institutionForm";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, DatePickerFormInputFabric, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";

import * as InstitutionFormActions from '../../../actions/institutionForm';
import * as InstitutionActions from '../../../actions/institution';
import * as InstitutionFormsPageActions from '../../../actions/pages/institutionFormsPage';

import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../../components/form';
import { GridRemove } from "../../../enumerations/GridRemove";
import { SettingsSubMenu, InstitutionSubMenu } from '../../../helpers/subMenuHelper';

import {
    InstitutionFormCreateVersion,
    CollectionInstancePage
} from "../../../containers/index";

interface IInstitutionFormEntryParams {
    institutionId: number;
    institutionFormId: number;
}

interface IInstitutionFormEntryProps {

    form: Dtos.InstitutionForm & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IInstitutionFormReduxFormState;

    location: Location;
    institutionId: number;
    institutionFormId: number;
    match: match<IInstitutionFormEntryParams>;
    history: History;

    // modals
    modalTitle: string,
    modalDescription: string,
    editModalOpen: boolean,
    collectionInstanceId: number,
    createVersionModalOpen: boolean,

    // From state
    institutionForm: Dtos.InstitutionForm;
    institution: Dtos.Institution;
    lookups: Dtos.Lookup[];

    loadingInstitution: boolean;

    loadingInstitutionForm: boolean;
    loadInstitutionFormSuccess: boolean;
    loadInstitutionFormFailure: boolean;

    savingInstitutionForm: boolean;
    saveInstitutionFormSuccess: boolean;
    saveInstitutionFormFailure: boolean;

    creatingInstitutionForm: boolean;
    createInstitutionFormSuccess: boolean;
    createInstitutionFormFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User
}

interface IInstitutionFormEntryActions {
    clearInstitutionForm: InstitutionFormActions.IInstitutionFormClearActionCreator;
    loadInstitutionForm: InstitutionFormActions.IInstitutionFormLoadByIdActionCreator;
    saveInstitutionForm: InstitutionFormActions.IInstitutionFormSaveActionCreator;
    createInstitutionForm: InstitutionFormActions.IInstitutionFormCreateActionCreator;

    clearInstitution: InstitutionActions.IInstitutionClearActionCreator;
    loadInstitution: InstitutionActions.IInstitutionLoadByIdActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    setEditModelViewState: InstitutionFormsPageActions.IInstitutionFormsPageSetEditModalViewStateActionCreator;
    setCreateVersionModelViewState: InstitutionFormsPageActions.IInstitutionFormsPageSetCreateVersionModalViewStateActionCreator;

}

type InstitutionFormEntryProps = IInstitutionFormEntryProps & IInstitutionFormEntryActions;

const formName: string = "institutionForm";
const reduxFormName: string = "reduxForms.institutionForm";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class InstitutionFormEntryPage extends React.PureComponent<InstitutionFormEntryProps, any> {

    constructor(props: InstitutionFormEntryProps) {
        super(props);

        this.clearTrialForm = this.clearTrialForm.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadInstitutionForm,
            createInstitutionForm,
            institutionFormId,
            institutionId,
            loadInstitution
        } = this.props;

        if (institutionFormId) {
            loadInstitutionForm(institutionFormId);
        } else if (!institutionFormId) {
            createInstitutionForm(institutionId);
        }
        loadInstitution(institutionId);
    }

    componentWillReceiveProps(nextProps: InstitutionFormEntryProps) {
    }

    componentDidUpdate(prevProps: InstitutionFormEntryProps, prevState) {
        const {
            form,
            institutionId,
            institutionFormId,
            institutionForm,
            navigateReplace,
            loadInstitutionForm,
            loadInstitution,
            loadingInstitutionForm,
            loadInstitutionFormSuccess,
            savingInstitutionForm,
            saveInstitutionFormSuccess,
            saveInstitutionFormFailure,
            createInstitutionForm,
            validationFailures,
            creatingInstitutionForm,
            createInstitutionFormSuccess,
            navigate
        } = this.props;

        if (institutionId && prevProps.institutionId != institutionId) {
            loadInstitution(institutionId);
        }

        if (institutionFormId && prevProps.institutionFormId != institutionFormId) {
            loadInstitutionForm(institutionFormId);
        }
        else if (!institutionFormId && prevProps.institutionFormId) {
            createInstitutionForm(institutionId);
        }

        if (!loadingInstitutionForm && prevProps.loadingInstitutionForm) {
            if (loadInstitutionFormSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingInstitutionForm && prevProps.savingInstitutionForm) {
            if (saveInstitutionFormSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/institutions/" + institutionId + "/forms")
                } else if (!form.saveAndReturn && institutionFormId && institutionFormId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !institutionFormId && institutionForm && institutionForm.id > 0) {
                    navigate("/institutions/" + institutionId + "/forms/" + institutionForm.id);
                    this.loadForm();
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingInstitutionForm && prevProps.creatingInstitutionForm) {
            if (createInstitutionFormSuccess) {
                this.loadForm();
            }
            else {

            }
        }
    }

    componentWillUpdate(nextProps: InstitutionFormEntryProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearTrialForm();
        loadForm(reduxFormName, initialInstitutionFormState);
        resetForm(reduxFormName);
    }

    clearTrialForm() {
        this.props.clearInstitutionForm();
        this.props.clearInstitution();
    }

    showTrialForm(): boolean {
        const {
            loadingInstitution,
            loadingInstitutionForm,
            loadInstitutionFormSuccess
        } = this.props

        if (!loadingInstitution && !loadingInstitutionForm && loadInstitutionFormSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingInstitution,
            loadingInstitutionForm,
            institution,
            institutionId
        } = this.props;

        let id = institution ? institution.id : 0;
        let name = institution ? institution.name : "Institution";

        return <RestrictedLayout
            subMenuTitle={institution ? institution.display : ""}
            subMenuItems={[]}
            loading={loadingInstitution || loadingInstitutionForm}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            setEditModelViewState,
            savingInstitutionForm
        } = this.props;

        let noPermissionToEditMessage = false;
        if ((form.id && form.id > 0) && validationFailures && validationFailures.filter(f => f.errorCode == "PR-005").length > 0) {
            noPermissionToEditMessage = true;
        }

        const formDisabled: boolean = savingInstitutionForm || (formState && formState.formStatus == Dtos.FormStatus.Disabled) || noPermissionToEditMessage;
        const formEdit: boolean = (formState && formState.formStatus !== Dtos.FormStatus.Disabled && (form.id && form.id > 0));
        const formNew: boolean = (formState && formState.formStatus !== Dtos.FormStatus.Disabled && !formEdit);


        let formComponents: { [index: string]: RegistrationFormComponent } = {
            headerGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    formTemplateId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: true, //!formNew,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("InstitutionForm", "FormTemplateId", lookups), form.formTemplateId)
                        },
                        size: 1,
                    },
                    assignedToId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: true, //formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("InstitutionForm", "AssignedToId", lookups), form.assignedToId)
                        },
                        size: 2,
                    },
                    formStatusId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: true, //formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("InstitutionForm", "FormStatusId", lookups), form.formStatusId)
                        },
                        size: 2,
                    }
                }
            },
        };

        return <div>
            <div className="row border border-bottom-1 border-top-0">
                <div className={"col mb-2"}>
                    <h3>Institution Form Entry</h3>
                </div>
                <div className={"col-auto"}>
                    <Link className={"btn btn-secondary"} url={"/institutions/" + form.institutionId + '/forms/' + form.id}>Details</Link>
                </div>
            </div>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "institutionForm", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
            </Form>
            {(form.collectionInstanceId && form.collectionInstanceId > 0) && <div className="mt-2">
                <CollectionInstancePage
                    readonly={formDisabled}
                    collectionInstanceId={form.collectionInstanceId}
                    onClose={() => this.onCancel()} />
            </div>}
        </div>

    }


    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
        </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.TrialForm) {
        const {
           // saveTrialForm
        } = this.props;

        if (data) {
            //let changes: Dtos.TrialForm = {} as Dtos.TrialForm;
            //saveTrialForm(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.Trial) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            institutionId,
            navigate
        } = this.props;

        navigate("/institutions/" + institutionId + "/forms");
    }

    loadForm() {
        const {
            loadForm,
            institutionForm,
        } = this.props

        let extraData: Dtos.InstitutionForm = {} as Dtos.InstitutionForm;

        loadForm(reduxFormName, Object.assign({ ...institutionForm }, extraData));
    }
}


const mapStateToProps = (state: ITmdState, ownProps: InstitutionFormEntryProps): IInstitutionFormEntryProps => {

    let institutionForm: Dtos.InstitutionForm | undefined = !(state.institutionForm.formData instanceof Array) ? state.institutionForm.formData : undefined;
    let institution: Dtos.Institution | undefined = !(state.institutions.formData instanceof Array) ? state.institutions.formData : undefined;

    return {

        match: ownProps.match,
        institutionId: ownProps.match ? ownProps.match.params.institutionId : undefined!,
        institutionFormId: ownProps.match && ownProps.match.params.institutionFormId.toString() != "create" ? ownProps.match.params.institutionFormId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        institutionForm: institutionForm!,

        institution: institution!,

        modalTitle: state.trialFormsPage.name,
        modalDescription: state.trialFormsPage.description,

        editModalOpen: state.trialFormsPage.editModalOpen,
        collectionInstanceId: state.trialFormsPage.collectionInstanceId,

        createVersionModalOpen: state.trialFormsPage.createVersionModalOpen,


        loadingInstitutionForm: state.institutionForm.loadState && state.institutionForm.loadState.status == RequestState.Pending,
        loadInstitutionFormSuccess: state.institutionForm.loadState && state.institutionForm.loadState.status == RequestState.Success,
        loadInstitutionFormFailure: state.institutionForm.loadState && state.institutionForm.loadState.status == RequestState.Failure,

        savingInstitutionForm: state.institutionForm.saveState && state.institutionForm.saveState.status == RequestState.Pending,
        saveInstitutionFormSuccess: state.institutionForm.saveState && state.institutionForm.saveState.status == RequestState.Success,
        saveInstitutionFormFailure: state.institutionForm.saveState && state.institutionForm.saveState.status == RequestState.Failure,

        creatingInstitutionForm: state.institutionForm.createState && state.institutionForm.createState.status == RequestState.Pending,
        createInstitutionFormSuccess: state.institutionForm.createState && state.institutionForm.createState.status == RequestState.Success,
        createInstitutionFormFailure: state.institutionForm.createState && state.institutionForm.createState.status == RequestState.Failure,

        loadingInstitution: state.institutions.loadState && state.institutions.loadState.status == RequestState.Pending,

        user: state.user.data,
        lookups: state.institutionForm.lookups,

        form: state.reduxForms.institutionForm,
        formState: state.institutionForm.formState,
        formProperties: state.institutionForm.formProperties,

        reduxFormState: state.reduxForms.formState.institutionForm,
        validationFailures: institutionForm ?
            state.institutionForm.validationFailures :
            undefined,

    };
};

const mapDispatchToProps = (dispatch): IInstitutionFormEntryActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadInstitutionForm: bindActionCreators(InstitutionFormActions.LoadInstitutionFormById, dispatch),
        clearInstitutionForm: bindActionCreators(InstitutionFormActions.Clear, dispatch),
        createInstitutionForm: bindActionCreators(InstitutionFormActions.CreateInstitutionForm, dispatch),
        saveInstitutionForm: bindActionCreators(InstitutionFormActions.SaveInstitutionForm, dispatch),

        loadInstitution: bindActionCreators(InstitutionActions.LoadInstitutionById, dispatch),
        clearInstitution: bindActionCreators(InstitutionActions.Clear, dispatch),

        setEditModelViewState: bindActionCreators(InstitutionFormsPageActions.setEditModalViewState, dispatch),
        setCreateVersionModelViewState: bindActionCreators(InstitutionFormsPageActions.setCreateVersionModalViewState, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(InstitutionFormEntryPage);
