import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const statesApi = {
    getStates: (): Observable<Dtos.GetStatesResponse> => {
        //Create the request.
        let request: Dtos.GetStates = new Dtos.GetStates();

        //Send request.
        const response: Promise<Dtos.GetStatesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getStateById: (id: number): Observable<Dtos.GetStateByIdResponse> => {
        //Create the request.
        let request: Dtos.GetStateById = new Dtos.GetStateById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetStateByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setState: (state: Dtos.State): Observable<Dtos.SetStateResponse> => {

        let request: Dtos.SetState = new Dtos.SetState();

        request.state = state;

        // send request
        const response: Promise<Dtos.SetStateResponse> = client.post(request);

        return Observable.from(response);

    },
    createState: (): Observable<Dtos.CreateStateResponse> => {
        //Create the request.
        let request: Dtos.CreateState = new Dtos.CreateState();

        //Send request.
        const response: Promise<Dtos.CreateStateResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}