import * as React from 'react';
import './InstitutionPersonnelPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import * as ReactTooltip from 'react-tooltip';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";
import * as InstitutionActions from '../../../actions/institution';
import * as ToggleActions from '../../../actions/pages/togglePage';
import * as ToggleHelper from "../../../helpers/toggleHelper";
import { TogglePageItem } from '../../../reducers/pages/togglePage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { TextAreaFormInput, FormFilterInput } from '../../../components/form';
import { SettingsSubMenu, InstitutionSubMenu } from '../../../helpers/subMenuHelper';

interface IInstitutionPersonnelPageParams {
    institutionId: number;
}

interface IInstitutionPersonnelPageProps {

    location: Location;
    institutionId: number;
    match: match<IInstitutionPersonnelPageParams>;
    history: History;

    // From state
    institution: Dtos.Institution;
    formFilter: IFormFilter;
    personnelRoleSummaries: Dtos.PersonnelRoleSummary[];

    loadingInstitution: boolean;
    loadInstitutionSuccess: boolean;
    loadInstitutionFailure: boolean;

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];
    toggleItems: TogglePageItem[]
}

interface IInstitutionPersonnelPageActions {
    clearInstitution: InstitutionActions.IInstitutionClearActionCreator;
    loadInstitution: InstitutionActions.IInstitutionLoadByIdActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    toggleExpand: ToggleActions.IToggleExpandedStateActionCreator;

    filter: InstitutionActions.IInstitutionsPersonnelRolesFilterActionCreator;
}

type InstitutionPersonnelPageProps = IInstitutionPersonnelPageProps & IInstitutionPersonnelPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class InstitutionPersonnelPage extends React.PureComponent<InstitutionPersonnelPageProps, any> {

    constructor(props: InstitutionPersonnelPageProps) {
        super(props);

        this.clearInstitution = this.clearInstitution.bind(this);
    }

    componentDidMount() {
        const {
            loadInstitution,
            navigate,
            navigateReplace,
            institutionId
        } = this.props;

        loadInstitution(institutionId);
    }

    componentWillReceiveProps(nextProps: InstitutionPersonnelPageProps) {
    }

    componentDidUpdate(prevProps: InstitutionPersonnelPageProps, prevState) {
        const {
            navigateReplace
        } = this.props
    }

    componentWillUpdate(nextProps: InstitutionPersonnelPageProps) {
    }

    componentWillUnmount() {
        this.clearInstitution();
    }

    clearInstitution() {
        this.props.clearInstitution();
    }

    showInstitution(): boolean {
        const {
            loadingInstitution,
            loadInstitutionSuccess
        } = this.props

        if (!loadingInstitution && loadInstitutionSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingInstitution,
            permissions,
            institution
        } = this.props;

        let id = institution ? institution.id : 0;
        let name = institution ? institution.name : "Institution";

        return <RestrictedLayout
            permission={Dtos.Permission.InstitutionPersonnelView}
            subMenuTitle={institution ? institution.display : ""}
            subMenuItems={InstitutionSubMenu(id, permissions)}
            loading={loadingInstitution}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            institution,
            loadingInstitution,
            loadInstitutionSuccess,
            loadInstitutionFailure,
            clearInstitution,
            permissions,
            user
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-auto mb-2"}>
                    <h2>Institution Roles</h2>
                </div>
                <div className="col" style={{ textAlign: 'right' }}>
                    {(institution && institution.id > 0 && AuthenticationHelper.hasPermission(permissions, Dtos.Permission.InstitutionPersonnelCreate, null, institution.id)) && (
                        <>
                            <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} />
                            <a className="btn btn-info mr-2" target="_blank" href={encodeURI("/api/personnelrole/export/0/" + institution.id.toString())}>Export</a>
                            <Link className={"btn btn-primary"} url={"/institutions/" + institution.id + '/personnel/create'}>Create</Link>
                        </>
                    )}
                </div>
            </div>
            {
                loadingInstitution ?
                    "Loading Personnel" :
                    null
            }
            {
                !loadingInstitution ?
                    <div className="mt-2">
                        {this.renderTable()}
                    </div> :
                    null
            }
        </div>
    }


    toggle(personnelRoleSummary: Dtos.PersonnelRoleSummary) {

    }

    renderTable() {
        const {
            institution,
            personnelRoleSummaries,
            toggleItems,
            toggleExpand
        } = this.props;


        if (!personnelRoleSummaries || personnelRoleSummaries.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no roles to view.</span>
            </div>
        }

        return <div>
            {personnelRoleSummaries && personnelRoleSummaries.map((item, index) => {
                const key = "institution-roles-page-" + item.personnelRoleSummaryType + "-" + item.display;
                const expanded = ToggleHelper.isToggleExpanded(toggleItems, key);
                return (
                    <div key={"parent-category" + index}>
                        <div className={tableHeaderClass} style={tableStyleClass} onClick={() => toggleExpand(key)}>
                            <div>
                                <FontAwesomeIcon icon={!expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" />
                            </div>
                            <span>{item.display}</span>
                        </div>
                        {expanded && item.personnelRoleSummaryType == Dtos.PersonnelRoleSummaryType.Institution && (
                            this.renderInstitutionRoles(item.personnelRoleSummaries)
                        )}
                        {expanded && item.personnelRoleSummaryType == Dtos.PersonnelRoleSummaryType.TrialInstitution && (
                            this.renderInstitutionRoles(item.personnelRoleSummaries)
                        )}
                    </div>
                )
            }
            )}
        </div>
    }

    renderInstitutionRoles(personnelRoleSummaries: Dtos.PersonnelRoleSummary[]) {
        const showPagination = false; const {
            institution
        } = this.props;

        return (
            <ReactTable
                data={personnelRoleSummaries}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : personnelRoleSummaries.length}
                pageSize={showPagination ? undefined : personnelRoleSummaries.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no roles for this type"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log(rowInfo);
                            const url: string = "/institutions/" + institution.id + '/personnel/' + rowInfo.original.personnelRole.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.personnelRole.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={
                    [
                        {
                            id: "id",
                            Header: ' ',
                            accessor: "id",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 50,
                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.ROLE} fixedWidth />
                        },
                        {
                            id: "role",
                            Header: "Role",
                            accessor: "personnelRole.roleDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "person",
                            Header: "Person",
                            accessor: "personnelRole.personnelDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "department",
                            Header: "Department",
                            accessor: "personnelRole.department.name",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "dateFrom",
                            Header: "Date Started",
                            accessor: "personnelRole.dateFrom",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        },
                        {
                            id: "dateTo",
                            Header: "Date Ceased",
                            accessor: "personnelRole.dateTo",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        }
                    ]
                }
            />
        );
    }

    renderTrialInstitutionRoles(personnelRoleSummaries: Dtos.PersonnelRoleSummary[]) {
        const showPagination = false;
        const {
            institution
        } = this.props;

        return (
            <ReactTable
                data={personnelRoleSummaries}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : personnelRoleSummaries.length}
                pageSize={showPagination ? undefined : personnelRoleSummaries.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no roles for this type"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log(rowInfo);
                            const url: string = "/institutions/" + institution.id + '/personnel/' + rowInfo.original.personnelRole.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.personnelRole.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={
                    [
                        {
                            id: "id",
                            Header: ' ',
                            accessor: "id",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 50,
                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.ROLE} fixedWidth />
                        },
                        {
                            id: "role",
                            Header: "Role",
                            accessor: "personnelRole.roleDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "person",
                            Header: "Person",
                            accessor: "personnelRole.personnelDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "department",
                            Header: "Department",
                            accessor: "personnelRole.department.name",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "dateFrom",
                            Header: "Date Started",
                            accessor: "personnelRole.dateFrom",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        },
                        {
                            id: "dateTo",
                            Header: "Date Ceased",
                            accessor: "personnelRole.dateTo",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        }
                    ]
                }
            />
        );
    }

}


const mapStateToProps = (state: ITmdState, ownProps: InstitutionPersonnelPageProps): IInstitutionPersonnelPageProps => {

    let institution: Dtos.Institution | undefined = !(state.institutions.formData instanceof Array) ? state.institutions.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        institutionId: ownProps.match ? ownProps.match.params.institutionId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        institution: institution!,
        formFilter: state.institutions.formFilterPersonnelRoles,
        personnelRoleSummaries: state.institutions.formFilteredPersonnelRoles,

        loadingInstitution: state.institutions.loadState && state.institutions.loadState.status == RequestState.Pending,
        loadInstitutionSuccess: state.institutions.loadState && state.institutions.loadState.status == RequestState.Success,
        loadInstitutionFailure: state.institutions.loadState && state.institutions.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        toggleItems: state.togglePage.items
    };
};

const mapDispatchToProps = (dispatch): IInstitutionPersonnelPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadInstitution: bindActionCreators(InstitutionActions.LoadInstitutionById, dispatch),
        clearInstitution: bindActionCreators(InstitutionActions.Clear, dispatch),

        toggleExpand: bindActionCreators(ToggleActions.ToggleExpanded, dispatch),

        filter: bindActionCreators(InstitutionActions.PersonnelRolesFilter, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(InstitutionPersonnelPage);
