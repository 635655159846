import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const websiteSettingsApi = {
    getWebsiteSettings: (): Observable<Dtos.GetWebsiteSettingResponse> => {
        //Create the request.
        let request: Dtos.GetWebsiteSetting = new Dtos.GetWebsiteSetting();

        //Send request.
        const response: Promise<Dtos.GetWebsiteSettingResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setWebsiteSettings: (websiteSetting: Dtos.WebsiteSetting): Observable<Dtos.PostWebsiteSettingResponse> => {
        //Create the request.
        let request: Dtos.PostWebsiteSetting = new Dtos.PostWebsiteSetting();
        request.websiteSetting = websiteSetting;
        //Send request.
        const response: Promise<Dtos.PostWebsiteSettingResponse> = client.post(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}