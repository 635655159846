import * as Dtos from "../dtos/Tmd.dtos";

export const
    LoadTrialGroupById: ITrialGroupLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TRIALGROUP_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ITrialGroupLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALGROUP_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrialGroupLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALGROUP_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTrialGroup: ITrialGroupSaveActionCreator = (formData) => {
        return {
            type: ACTION_TRIALGROUP_SAVE,
            formData
        }
    },
    SaveSuccess: ITrialGroupSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRIALGROUP_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrialGroupSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALGROUP_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTrialGroup: ITrialGroupCreateActionCreator = (trialId) => {

        return {
            type: ACTION_TRIALGROUP_CREATE,
            trialId
        }
    },
    CreateSuccess: ITrialGroupCreateSuccessActionCreator = (formData, formState, formProperties, lookups) => {
        return {
            type: ACTION_TRIALGROUP_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups
        }
    },
    CreateFailure: ITrialGroupCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALGROUP_CREATE_FAILURE,
            responseStatus
        }
    },

    Clear: ITrialGroupClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRIALGROUP_CLEAR,
            propertiesToClear
        }
    };



export const
    ACTION_TRIALGROUP_LOAD_BY_ID = "ACTION_TRIALGROUP_LOAD_BY_ID",
    ACTION_TRIALGROUP_LOAD_SUCCESS = "ACTION_TRIALGROUP_LOAD_SUCCESS",
    ACTION_TRIALGROUP_LOAD_FAILURE = "ACTION_TRIALGROUP_LOAD_FAILURE",

    ACTION_TRIALGROUP_SAVE = "ACTION_TRIALGROUP_SAVE",
    ACTION_TRIALGROUP_SAVE_SUCCESS = "ACTION_TRIALGROUP_SAVE_SUCCESS",
    ACTION_TRIALGROUP_SAVE_FAILURE = "ACTION_TRIALGROUP_SAVE_FAILURE",

    ACTION_TRIALGROUP_CREATE = "ACTION_TRIALGROUP_CREATE",
    ACTION_TRIALGROUP_CREATE_SUCCESS = "ACTION_TRIALGROUP_CREATE_SUCCESS",
    ACTION_TRIALGROUP_CREATE_FAILURE = "ACTION_TRIALGROUP_CREATE_FAILURE",

    ACTION_TRIALGROUP_CLEAR = "ACTION_TRIALGROUP_CLEAR";


export interface ITrialGroupLoadSuccessAction {
    type: "ACTION_TRIALGROUP_LOAD_SUCCESS";
    formData: Dtos.TrialGroup;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialGroupLoadSuccessActionCreator {
    (
        formData: Dtos.TrialGroup,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialGroupLoadSuccessAction;
}

export interface ITrialGroupLoadFailureAction {
    type: "ACTION_TRIALGROUP_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialGroupLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialGroupLoadFailureAction;
}

export interface ITrialGroupLoadByIdAction {
    type: "ACTION_TRIALGROUP_LOAD_BY_ID";
    id: number;
}

export interface ITrialGroupLoadByIdActionCreator {
    (id: number): ITrialGroupLoadByIdAction;
}


export interface ITrialGroupSaveAction {
    type: "ACTION_TRIALGROUP_SAVE";
    formData: Dtos.TrialGroup;

}

export interface ITrialGroupSaveActionCreator {
    (
        formData: Dtos.TrialGroup,
    ): ITrialGroupSaveAction;
}

export interface ITrialGroupSaveSuccessAction {
    type: "ACTION_TRIALGROUP_SAVE_SUCCESS";
    formData: Dtos.TrialGroup;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialGroupSaveSuccessActionCreator {
    (
        formData: Dtos.TrialGroup,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrialGroupSaveSuccessAction;
}

export interface ITrialGroupSaveFailureAction {
    type: "ACTION_TRIALGROUP_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialGroupSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialGroupSaveFailureAction;
}

export interface ITrialGroupCreateAction {
    type: "ACTION_TRIALGROUP_CREATE",
    trialId: number
}

export interface ITrialGroupCreateActionCreator {
    (trialId: number): ITrialGroupCreateAction
}

export interface ITrialGroupCreateSuccessAction {
    type: "ACTION_TRIALGROUP_CREATE_SUCCESS";
    formData: Dtos.TrialGroup;
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[]
}

export interface ITrialGroupCreateSuccessActionCreator {
    (
        formData: Dtos.TrialGroup,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[]
    ): ITrialGroupCreateSuccessAction;
}

export interface ITrialGroupCreateFailureAction {
    type: "ACTION_TRIALGROUP_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialGroupCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialGroupCreateFailureAction;
}

export interface ITrialGroupClearAction {
    type: "ACTION_TRIALGROUP_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrialGroupClearActionCreator {
    (propertiesToClear?: string[]): ITrialGroupClearAction;
}

export type TrialGroupAction =
    ITrialGroupLoadByIdAction |
    ITrialGroupLoadFailureAction |
    ITrialGroupLoadSuccessAction |
    ITrialGroupSaveAction |
    ITrialGroupSaveSuccessAction |
    ITrialGroupSaveFailureAction |
    ITrialGroupCreateAction |
    ITrialGroupCreateSuccessAction |
    ITrialGroupCreateFailureAction |
    ITrialGroupClearAction;
