import './CustomFormInput.scss';
import * as Dtos from '../../../../dtos/Tmd.dtos';

import * as React from "react";
import * as classNames from "classnames";
import { IBaseFormInputProps } from '../';
import { actions, FieldAction, ChangeOptions, ModelAction } from 'react-redux-form';

import { FontAwesomeIcons, FontAwesomeIcon } from "../../../../constants/fontAwesomeIcons";

import { DatePickerFormInput, TextFormInput, TextAreaFormInput, CheckboxFormInput, SelectFormInput } from '../../inputs';

export interface ICustomFormInputProps {
    item?: Dtos.ItemComponent;
    feedbackIcon?: string;
    className?: string;
    label?: string;
    name: string;
    id: string;
    // onChange?: (value: any, options?: ChangeOptions) => ModelAction;
    onFocus?: () => FieldAction;
    validationFailures?: Dtos.ResponseError[];
    // value: any;
    disabled?: boolean;
    focused?: boolean;
    valid?: boolean;
    invalid?: boolean;
    hasSoftError?: boolean;
    hasHardError?: boolean;
}

class CustomFormInput extends React.PureComponent<ICustomFormInputProps, any> {

    renderComponentContainer() {

    }

    render() {
        const {
            className,
            item,
            disabled,
            focused,
            id,
            invalid,
            label,
            name,
            onFocus,
            valid,
            feedbackIcon,
            hasSoftError,
            hasHardError
        } = this.props;

        const derivedClass = className ? className : "form-control";

        let primitive;

        switch (item.customInputControlName) {
            case 'CustomDivider':
                primitive = <hr />;
                break;
        }

        const hardFeedback = hasHardError && !disabled ?
            <span className={classNames("form-control-feedback block pr-2")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.BAN} fixedWidth />
            </span> :
            null;


        const feedback = (invalid && !disabled) && !hasHardError ?
            <span className={classNames("form-control-feedback pr-2")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE} fixedWidth />
            </span> :
            null;

        const softFeedback = hasSoftError && !disabled && (!invalid) ?
            <span className={classNames("form-control-feedback warning pr-2")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.EXCLAMATION_TRIANGLE} fixedWidth />
            </span> :
            null;

        return <div className="container-fluid">
            <div className="row" style={{ minHeight: '40px' }}>
                <div className="col-sm">
                    {primitive}
                    {hardFeedback}
                    {feedback}
                    {softFeedback}
                </div>
            </div>
        </div>
    }
}

export default CustomFormInput;