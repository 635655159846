import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const institutionFormsApi = {
    getInstitutionForms: (institutionId: number): Observable<Dtos.GetInstitutionFormsResponse> => {
        //Create the request.
        let request: Dtos.GetInstitutionForms = new Dtos.GetInstitutionForms();
        request.institutionId = institutionId;

        //Send request.
        const response: Promise<Dtos.GetInstitutionFormsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getInstitutionFormsCurrentUser: (): Observable<Dtos.GetInstitutionFormsByCurrentUserResponse> => {
        //Create the request.
        let request: Dtos.GetInstitutionFormsByCurrentUser = new Dtos.GetInstitutionFormsByCurrentUser();

        //Send request.
        const response: Promise<Dtos.GetInstitutionFormsByCurrentUserResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getInstitutionFormById: (id: number): Observable<Dtos.GetInstitutionFormByIdResponse> => {
        //Create the request.
        let request: Dtos.GetInstitutionFormById = new Dtos.GetInstitutionFormById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetInstitutionFormByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setInstitutionForm: (institutionForm: Dtos.InstitutionForm): Observable<Dtos.SetInstitutionFormResponse> => {

        let request: Dtos.SetInstitutionForm = new Dtos.SetInstitutionForm();

        request.institutionForm = institutionForm;

        // send request
        const response: Promise<Dtos.SetInstitutionFormResponse> = client.post(request);

        return Observable.from(response);

    },
    createInstitutionForm: (institutionId: number): Observable<Dtos.CreateInstitutionFormResponse> => {
        //Create the request.
        let request: Dtos.CreateInstitutionForm = new Dtos.CreateInstitutionForm();

        request.institutionId = institutionId;

        //Send request.
        const response: Promise<Dtos.CreateInstitutionFormResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    createVersion: (institutionFormId: number, version: string): Observable<Dtos.CreateInstitutionFormVersionResponse> => {
        //Create the request.
        let request: Dtos.CreateInstitutionFormVersion = new Dtos.CreateInstitutionFormVersion();

        request.institutionFormId = institutionFormId;
        request.version = version;

        //Send request.
        const response: Promise<Dtos.CreateInstitutionFormVersionResponse> = client.post(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}