import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trialcategorysApi = {
    getTrialCategorys: (): Observable<Dtos.GetTrialCategorysResponse> => {
        //Create the request.
        let request: Dtos.GetTrialCategorys = new Dtos.GetTrialCategorys();

        //Send request.
        const response: Promise<Dtos.GetTrialCategorysResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrialCategoryById: (id: number): Observable<Dtos.GetTrialCategoryByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrialCategoryById = new Dtos.GetTrialCategoryById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTrialCategoryByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrialCategory: (trialCategory: Dtos.TrialCategory): Observable<Dtos.SetTrialCategoryResponse> => {

        let request: Dtos.SetTrialCategory = new Dtos.SetTrialCategory();

        request.trialCategory = trialCategory;

        // send request
        const response: Promise<Dtos.SetTrialCategoryResponse> = client.post(request);

        return Observable.from(response);

    },
    createTrialCategory: (): Observable<Dtos.CreateTrialCategoryResponse> => {
        //Create the request.
        let request: Dtos.CreateTrialCategory = new Dtos.CreateTrialCategory();

        //Send request.
        const response: Promise<Dtos.CreateTrialCategoryResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}