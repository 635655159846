import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadTumourGroup: ITumourGroupLoadActionCreator = () => {
        return {
            type: ACTION_TUMOURGROUPS_LOAD
        }
    },
    LoadTumourGroupById: ITumourGroupLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TUMOURGROUPS_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ITumourGroupLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_TUMOURGROUPS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITumourGroupLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TUMOURGROUPS_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTumourGroup: ITumourGroupSaveActionCreator = (formData) => {
        return {
            type: ACTION_TUMOURGROUPS_SAVE,
            formData
        }
    },
    SaveSuccess: ITumourGroupSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TUMOURGROUPS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITumourGroupSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TUMOURGROUPS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTumourGroup: ITumourGroupCreateActionCreator = () => {

        return {
            type: ACTION_TUMOURGROUPS_CREATE
        }
    },
    CreateSuccess: ITumourGroupCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_TUMOURGROUPS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: ITumourGroupCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TUMOURGROUPS_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: ITumourGroupClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TUMOURGROUPS_CLEAR,
            propertiesToClear
        }
    },
    Filter: ITumourGroupFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TUMOURGROUPS_FILTER,
            formFilter
        }
    };



export const
    ACTION_TUMOURGROUPS_LOAD = "ACTION_TUMOURGROUPS_LOAD",
    ACTION_TUMOURGROUPS_LOAD_BY_ID = "ACTION_TUMOURGROUPS_LOAD_BY_ID",
    ACTION_TUMOURGROUPS_LOAD_SUCCESS = "ACTION_TUMOURGROUPS_LOAD_SUCCESS",
    ACTION_TUMOURGROUPS_LOAD_FAILURE = "ACTION_TUMOURGROUPS_LOAD_FAILURE",

    ACTION_TUMOURGROUPS_SAVE = "ACTION_TUMOURGROUPS_SAVE",
    ACTION_TUMOURGROUPS_SAVE_SUCCESS = "ACTION_TUMOURGROUPS_SAVE_SUCCESS",
    ACTION_TUMOURGROUPS_SAVE_FAILURE = "ACTION_TUMOURGROUPS_SAVE_FAILURE",

    ACTION_TUMOURGROUPS_CREATE = "ACTION_TUMOURGROUPS_CREATE",
    ACTION_TUMOURGROUPS_CREATE_SUCCESS = "ACTION_TUMOURGROUPS_CREATE_SUCCESS",
    ACTION_TUMOURGROUPS_CREATE_FAILURE = "ACTION_TUMOURGROUPS_CREATE_FAILURE",

    ACTION_TUMOURGROUPS_CLEAR = "ACTION_TUMOURGROUPS_CLEAR",

    ACTION_TUMOURGROUPS_FILTER = "ACTION_TUMOURGROUPS_FILTER";

export interface ITumourGroupLoadAction {
    type: "ACTION_TUMOURGROUPS_LOAD";
}

export interface ITumourGroupLoadActionCreator {
    (): ITumourGroupLoadAction;
}

export interface ITumourGroupLoadSuccessAction {
    type: "ACTION_TUMOURGROUPS_LOAD_SUCCESS";
    formData: Dtos.TumourGroup | Dtos.TumourGroup[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITumourGroupLoadSuccessActionCreator {
    (
        formData: Dtos.TumourGroup | Dtos.TumourGroup[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITumourGroupLoadSuccessAction;
}

export interface ITumourGroupLoadFailureAction {
    type: "ACTION_TUMOURGROUPS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITumourGroupLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITumourGroupLoadFailureAction;
}

export interface ITumourGroupLoadByIdAction {
    type: "ACTION_TUMOURGROUPS_LOAD_BY_ID";
    id: number;
}

export interface ITumourGroupLoadByIdActionCreator {
    (id: number): ITumourGroupLoadByIdAction;
}


export interface ITumourGroupSaveAction {
    type: "ACTION_TUMOURGROUPS_SAVE";
    formData: Dtos.TumourGroup;

}

export interface ITumourGroupSaveActionCreator {
    (
        formData: Dtos.TumourGroup
    ): ITumourGroupSaveAction;
}

export interface ITumourGroupSaveSuccessAction {
    type: "ACTION_TUMOURGROUPS_SAVE_SUCCESS";
    formData: Dtos.TumourGroup;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITumourGroupSaveSuccessActionCreator {
    (
        formData: Dtos.TumourGroup,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITumourGroupSaveSuccessAction;
}

export interface ITumourGroupSaveFailureAction {
    type: "ACTION_TUMOURGROUPS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITumourGroupSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITumourGroupSaveFailureAction;
}

export interface ITumourGroupCreateAction {
    type: "ACTION_TUMOURGROUPS_CREATE"
}

export interface ITumourGroupCreateActionCreator {
    (): ITumourGroupCreateAction
}

export interface ITumourGroupCreateSuccessAction {
    type: "ACTION_TUMOURGROUPS_CREATE_SUCCESS";
    formData: Dtos.TumourGroup;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface ITumourGroupCreateSuccessActionCreator {
    (
        formData: Dtos.TumourGroup,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): ITumourGroupCreateSuccessAction;
}

export interface ITumourGroupCreateFailureAction {
    type: "ACTION_TUMOURGROUPS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITumourGroupCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITumourGroupCreateFailureAction;
}

export interface ITumourGroupClearAction {
    type: "ACTION_TUMOURGROUPS_CLEAR";
    propertiesToClear?: string[];
}

export interface ITumourGroupClearActionCreator {
    (propertiesToClear?: string[]): ITumourGroupClearAction;
}

export interface ITumourGroupFilterActionCreator {
    (formFilter: IFormFilter): ITumourGroupFilterAction;
}

export interface ITumourGroupFilterAction {
    type: "ACTION_TUMOURGROUPS_FILTER";
    formFilter: IFormFilter;
}

export type TumourGroupAction =
    ITumourGroupLoadAction |
    ITumourGroupLoadByIdAction |
    ITumourGroupLoadFailureAction |
    ITumourGroupLoadSuccessAction |
    ITumourGroupSaveAction |
    ITumourGroupSaveSuccessAction |
    ITumourGroupSaveFailureAction |
    ITumourGroupCreateAction |
    ITumourGroupCreateSuccessAction |
    ITumourGroupCreateFailureAction |
    ITumourGroupClearAction |
    ITumourGroupFilterAction;
