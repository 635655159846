import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trackerTypeApi = {
    getTrackerTypes: (): Observable<Dtos.GetTrackerTypesResponse> => {
        //Create the request.
        let request: Dtos.GetTrackerTypes = new Dtos.GetTrackerTypes();

        //Send request.
        const response: Promise<Dtos.GetTrackerTypesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrackerTypeById: (id: number): Observable<Dtos.GetTrackerTypeByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrackerTypeById = new Dtos.GetTrackerTypeById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTrackerTypeByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrackerType: (trackerType: Dtos.TrackerType): Observable<Dtos.SetTrackerTypeResponse> => {

        let request: Dtos.SetTrackerType = new Dtos.SetTrackerType();

        request.trackerType = trackerType;

        // send request
        const response: Promise<Dtos.SetTrackerTypeResponse> = client.post(request);

        return Observable.from(response);

    },
    createTrackerType: (): Observable<Dtos.CreateTrackerTypeResponse> => {
        //Create the request.
        let request: Dtos.CreateTrackerType = new Dtos.CreateTrackerType();

        //Send request.
        const response: Promise<Dtos.CreateTrackerTypeResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}