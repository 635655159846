import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadPatient: IPatientLoadActionCreator = (trialId, institutionId) => {
        return {
            type: ACTION_PATIENT_LOAD,
            trialId,
            institutionId
        }
    },
    LoadPatientById: IPatientLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_PATIENT_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IPatientLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_PATIENT_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IPatientLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PATIENT_LOAD_FAILURE,
            responseStatus
        }
    },

    SavePatient: IPatientSaveActionCreator = (formData) => {
        return {
            type: ACTION_PATIENT_SAVE,
            formData
        }
    },
    SaveSuccess: IPatientSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_PATIENT_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IPatientSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PATIENT_SAVE_FAILURE,
            responseStatus
        }
    },

    CreatePatient: IPatientCreateActionCreator = (trialId, trialInstitutionId) => {

        return {
            type: ACTION_PATIENT_CREATE,
            trialId,
            trialInstitutionId
        }
    },
    CreateSuccess: IPatientCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_PATIENT_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: IPatientCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PATIENT_CREATE_FAILURE,
            responseStatus
        }
    },

    Import: IPatientImportActionCreator = (trialId) => {
        return {
            type: ACTION_PATIENT_IMPORT,
            trialId
        }
    },
    ImportSuccess: IPatientImportSuccessActionCreator = (responseStatus) => {
        return {
            type: ACTION_PATIENT_IMPORT_SUCCESS,
            responseStatus
        }
    },
    ImportFailure: IPatientImportFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PATIENT_IMPORT_FAILURE,
            responseStatus
        }
    },

    Clear: IPatientClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_PATIENT_CLEAR,
            propertiesToClear
        }
    };



export const
    ACTION_PATIENT_LOAD = "ACTION_PATIENT_LOAD",
    ACTION_PATIENT_LOAD_BY_ID = "ACTION_PATIENT_LOAD_BY_ID",
    ACTION_PATIENT_LOAD_SUCCESS = "ACTION_PATIENT_LOAD_SUCCESS",
    ACTION_PATIENT_LOAD_FAILURE = "ACTION_PATIENT_LOAD_FAILURE",

    ACTION_PATIENT_SAVE = "ACTION_PATIENT_SAVE",
    ACTION_PATIENT_SAVE_SUCCESS = "ACTION_PATIENT_SAVE_SUCCESS",
    ACTION_PATIENT_SAVE_FAILURE = "ACTION_PATIENT_SAVE_FAILURE",

    ACTION_PATIENT_CREATE = "ACTION_PATIENT_CREATE",
    ACTION_PATIENT_CREATE_SUCCESS = "ACTION_PATIENT_CREATE_SUCCESS",
    ACTION_PATIENT_CREATE_FAILURE = "ACTION_PATIENT_CREATE_FAILURE",

    ACTION_PATIENT_IMPORT = "ACTION_PATIENT_IMPORT",
    ACTION_PATIENT_IMPORT_SUCCESS = "ACTION_PATIENT_IMPORT_SUCCESS",
    ACTION_PATIENT_IMPORT_FAILURE = "ACTION_PATIENT_IMPORT_FAILURE",

    ACTION_PATIENT_CLEAR = "ACTION_PATIENT_CLEAR";

export interface IPatientLoadAction {
    type: "ACTION_PATIENT_LOAD";
    trialId: number;
    institutionId: number;
}

export interface IPatientLoadActionCreator {
    (
        trialId: number,
        institutionId: number
    ): IPatientLoadAction;
}

export interface IPatientLoadSuccessAction {
    type: "ACTION_PATIENT_LOAD_SUCCESS";
    formData: Dtos.Patient | Dtos.Patient[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IPatientLoadSuccessActionCreator {
    (
        formData: Dtos.Patient | Dtos.Patient[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IPatientLoadSuccessAction;
}

export interface IPatientLoadFailureAction {
    type: "ACTION_PATIENT_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IPatientLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IPatientLoadFailureAction;
}

export interface IPatientLoadByIdAction {
    type: "ACTION_PATIENT_LOAD_BY_ID";
    id: number;
}

export interface IPatientLoadByIdActionCreator {
    (id: number): IPatientLoadByIdAction;
}

export interface IPatientSaveAction {
    type: "ACTION_PATIENT_SAVE";
    formData: Dtos.Patient;

}

export interface IPatientSaveActionCreator {
    (
        formData: Dtos.Patient
    ): IPatientSaveAction;
}

export interface IPatientSaveSuccessAction {
    type: "ACTION_PATIENT_SAVE_SUCCESS";
    formData: Dtos.Patient;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IPatientSaveSuccessActionCreator {
    (
        formData: Dtos.Patient,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IPatientSaveSuccessAction;
}

export interface IPatientSaveFailureAction {
    type: "ACTION_PATIENT_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IPatientSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IPatientSaveFailureAction;
}

export interface IPatientCreateAction {
    type: "ACTION_PATIENT_CREATE",
    trialId: number;
    trialInstitutionId: number;
}

export interface IPatientCreateActionCreator {
    (trialId: number, trialInstitutionId: number): IPatientCreateAction
}

export interface IPatientCreateSuccessAction {
    type: "ACTION_PATIENT_CREATE_SUCCESS";
    formData: Dtos.Patient;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface IPatientCreateSuccessActionCreator {
    (
        formData: Dtos.Patient,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): IPatientCreateSuccessAction;
}

export interface IPatientCreateFailureAction {
    type: "ACTION_PATIENT_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IPatientCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IPatientCreateFailureAction;
}

export interface IPatientImportAction {
    type: "ACTION_PATIENT_IMPORT";
    trialId: number;
}

export interface IPatientImportActionCreator {
    (
        trialId: number
    ): IPatientImportAction;
}
export interface IPatientImportSuccessAction {
    type: "ACTION_PATIENT_IMPORT_SUCCESS";
    responseStatus: Dtos.ResponseStatus;
}

export interface IPatientImportSuccessActionCreator {
    (
        responseStatus: Dtos.ResponseStatus
    ): IPatientImportSuccessAction;
}

export interface IPatientImportFailureAction {
    type: "ACTION_PATIENT_IMPORT_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IPatientImportFailureActionCreator {
    (
        responseStatus: Dtos.ResponseStatus
    ): IPatientImportFailureAction;
}

export interface IPatientClearAction {
    type: "ACTION_PATIENT_CLEAR";
    propertiesToClear?: string[];
}

export interface IPatientClearActionCreator {
    (propertiesToClear?: string[]): IPatientClearAction;
}

export type PatientAction =
    IPatientLoadAction |
    IPatientLoadByIdAction |
    IPatientLoadFailureAction |
    IPatientLoadSuccessAction |
    IPatientSaveAction |
    IPatientSaveSuccessAction |
    IPatientSaveFailureAction |
    IPatientCreateAction |
    IPatientCreateSuccessAction |
    IPatientCreateFailureAction |
    IPatientImportAction |
    IPatientImportFailureAction |
    IPatientImportSuccessAction |
    IPatientClearAction;
