import * as React from 'react';
import './TrialInstitutionPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { ITrialInstitutionReduxFormState, initialTrialInstitutionState } from "../../../reducers/reactReduxForms/trialInstitution";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as TrialActions from '../../../actions/trial';
import * as TrialInstitutionActions from '../../../actions/trialInstitution';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput, DatePickerFormInputFabric } from '../../../components/form';
import * as FormHelper from '../../../helpers/formHelper';
import { TrialInstitutionLayout } from '../../index';

interface ITrialInstitutionPageParams {
    trialId: number;
    trialInstitutionId: number;
}

interface ITrialInstitutionPageProps {

    form: Dtos.TrialInstitution & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITrialInstitutionReduxFormState;

    location: Location;
    trialId: number;
    trialInstitutionId: number;
    match: match<ITrialInstitutionPageParams>;
    history: History;

    // From state
    trialInstitution: Dtos.TrialInstitution;
    lookups: Dtos.Lookup[];

    loadingTrialInstitution: boolean;
    loadTrialInstitutionSuccess: boolean;
    loadTrialInstitutionFailure: boolean;

    savingTrialInstitution: boolean;
    saveTrialInstitutionSuccess: boolean;
    saveTrialInstitutionFailure: boolean;

    creatingTrialInstitution: boolean;
    createTrialInstitutionSuccess: boolean;
    createTrialInstitutionFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];
    formRoles: Dtos.RoleType[]
}

interface ITrialInstitutionPageActions {
    clearTrialInstitution: TrialInstitutionActions.ITrialInstitutionClearActionCreator;
    loadTrialInstitution: TrialInstitutionActions.ITrialInstitutionLoadByIdActionCreator;
    saveTrialInstitution: TrialInstitutionActions.ITrialInstitutionSaveActionCreator;
    createTrialInstitution: TrialInstitutionActions.ITrialInstitutionCreateActionCreator;

    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type TrialInstitutionPageProps = ITrialInstitutionPageProps & ITrialInstitutionPageActions;

const reduxFormName: string = "reduxForms.trialInstitution";

class TrialInstitutionPage extends React.PureComponent<TrialInstitutionPageProps, any> {

    constructor(props: TrialInstitutionPageProps) {
        super(props);

        this.clearTrialInstitution = this.clearTrialInstitution.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTrialInstitution,
            trialInstitutionId,
            trialId,
            loadTrial,
            createTrialInstitution
        } = this.props;

        if (trialInstitutionId) {
            // load
            loadTrialInstitution(trialInstitutionId);
        } else {
            // create
            createTrialInstitution(trialId);
        }

        if (trialId) {
            loadTrial(trialId);
        }
    }

    componentDidUpdate(prevProps: TrialInstitutionPageProps, prevState) {
        const {
            form,
            trialId,
            trialInstitutionId,
            trialInstitution,
            loadTrial,
            loadTrialInstitution,
            loadingTrialInstitution,
            loadTrialInstitutionSuccess,
            savingTrialInstitution,
            saveTrialInstitutionSuccess,
            validationFailures,
            navigate,
            createTrialInstitution,
            creatingTrialInstitution,
            createTrialInstitutionSuccess
        } = this.props;

        if (trialId && prevProps.trialId != trialId) {
            loadTrial(trialId);
        }

        if (trialInstitutionId && prevProps.trialInstitutionId != trialInstitutionId) {
            loadTrialInstitution(trialInstitutionId);
        }
        else if (!trialInstitutionId && prevProps.trialInstitutionId) {
            createTrialInstitution(trialId);
        }

        if (!loadingTrialInstitution && prevProps.loadingTrialInstitution) {
            if (loadTrialInstitutionSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingTrialInstitution && prevProps.savingTrialInstitution) {
            if (saveTrialInstitutionSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/trials/" + trialId + "/institutions")
                } else if (!form.saveAndReturn && trialInstitutionId && trialInstitutionId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !trialInstitutionId && trialInstitution && trialInstitution.id > 0) {
                    navigate("/trials/" + trialId + "/trialinstitutions/" + trialInstitution.id + "/details")
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingTrialInstitution && prevProps.creatingTrialInstitution) {
            if (createTrialInstitutionSuccess) {
                this.loadForm();
            }
            else {

            }
        }

    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearTrialInstitution();
        loadForm(reduxFormName, initialTrialInstitutionState);
        resetForm(reduxFormName);
    }

    clearTrialInstitution() {
        this.props.clearTrialInstitution();
        this.props.clearTrial();
    }

    showTrialInstitution(): boolean {
        const {
            loadingTrialInstitution,
            loadTrialInstitutionSuccess
        } = this.props

        if (!loadingTrialInstitution && loadTrialInstitutionSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrialInstitution,
            creatingTrialInstitution
        } = this.props

        return <TrialInstitutionLayout
            loading={loadingTrialInstitution || creatingTrialInstitution}
            permission={Dtos.Permission.TrialInstitutionView}>
            {this.renderContent()}
        </TrialInstitutionLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            permissions,
            savingTrialInstitution
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.TrialInstitutionUpdate, Dtos.Permission.TrialInstitutionCreate);
        const formNew: boolean = FormHelper.isNew(formState, form);
        const formEdit: boolean = FormHelper.isEdit(formState, form);
        const formDisabled: boolean = !hasPermission || savingTrialInstitution || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            institutionGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    institutionId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled || formEdit,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialInstitution", "InstitutionId", lookups), form.institutionId)
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + ".parentInstitutionId", null);
                            changeForm(reduxFormName + ".parentInstitutionDescription", null);
                        },
                        size: 1,
                    },
                    groupId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled || form.isGroupLocked,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialInstitution", "GroupId", lookups), form.groupId)
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            if (value === undefined || value === null) {
                                changeForm(reduxFormName + ".group", null);
                            }
                        },
                        size: 1,
                    },
                }
            },
            primarySiteGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    siteTypeId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialInstitution", "SiteTypeId", lookups), form.siteTypeId)
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + ".siteType", null);
                            if (value != 2) {
                                changeForm(reduxFormName + ".parentInstitutionDescriptionId", null);
                                changeForm(reduxFormName + ".parentInstitutionDescription", null);
                            }
                        },
                        size: 2
                    },
                    parentInstitutionId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialInstitution", "ParentInstitutionId", lookups), form.institutionId)
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            if (value === undefined || value === null) {
                                changeForm(reduxFormName + ".parentInstitutionDescription", null);
                            }
                        },
                        size: 1,
                        hide: (form.siteTypeId === null || form.siteTypeId === undefined || form.siteTypeId !== 2)
                    },
                    parentInstitutionDescription: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextAreaFormInput,
                        inputDisabled: formDisabled,
                        size: 1,
                        hide: true, // (form.parentInstitutionId === null || form.parentInstitutionId === undefined || form.parentInstitutionId === 0)
                    },
                    activitiesUndertakenId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialInstitution", "ActivitiesUndertakenId", lookups), form.activitiesUndertakenId)
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + ".activitiesUndertaken", null);
                            if (value != 4) {
                                changeForm(reduxFormName + parentModel + ".activitiesUndertakenOther", undefined);
                            }
                        },
                        size: 2,
                        hide: (form.siteTypeId === null || form.siteTypeId === undefined || form.siteTypeId !== 2)
                    },
                    activitiesUndertakenOther: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialInstitution", "ActivitiesUndertakenOther", formProperties),
                        disabledFn: (form: Dtos.TrialInstitution, arrayKey: string) => {
                            if (form) {
                                if (form.activitiesUndertakenId == 4) {
                                    return false;
                                }
                            }
                            return true;
                        },
                        size: 2,
                        hide: (form.siteTypeId === null || form.siteTypeId === undefined || form.siteTypeId !== 2)
                    },
                }
            },
            groupStatusGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                   
                    trialInstitutionStatusId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialInstitution", "trialInstitutionStatusId", lookups), form.trialInstitutionStatusId)
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            if (value === undefined || value === null) {
                                changeForm(reduxFormName + ".trialInstitutionStatus", null);
                            }
                        },
                        size: 2,
                    },
                }
            },
            rgoGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    rgoSubmissionDate: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        validationMessageAbove: true,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    rgoApprovalDate: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        validationMessageAbove: true,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    initationMeetingDate: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        validationMessageAbove: true,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    dateActivated: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        validationMessageAbove: true,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    firstPatient: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        validationMessageAbove: true,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    closureDate: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        validationMessageAbove: true,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    expectedAccrual: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: NumberFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    totalAccrual: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: NumberFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    }
                },                
            },
            registrationGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    ccOnRegistration: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            placeholder: "sample_one@email.com;sample_two@email.com...",
                        },
                        size: 1,
                    }
                }
            },
            commentsGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    comments: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextAreaFormInput,
                        inputDisabled: formDisabled,
                        size: 1
                    },
                    active: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: CheckboxFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            label: "Active"
                        },
                        size: 1,
                        inputLabel: " ",
                    }
                },
            },
        };
       
        return <div>
            <h3>Trial Institution</h3>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >

                {
                    renderFormControls(form, "trialInstitution", formComponents, lookups, validationFailures, formProperties, changeForm)
                }

                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>
                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
        </div>

    }

    renderErrorAlert(): any {
        const {
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {
        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.TrialInstitution) {
        const {
            saveTrialInstitution
        } = this.props;

        if (data) {
            let changes: Dtos.TrialInstitution = {} as Dtos.TrialInstitution;
            let collectionChanges: Dtos.Collection = {} as Dtos.Collection;
            saveTrialInstitution(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.TrialInstitution) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate,
            trialId
        } = this.props;

        navigate("/trials/" + trialId + "/institutions");
    }

    loadForm() {
        const {
            loadForm,
            trialInstitution,
        } = this.props

        let extraData: Dtos.TrialInstitution = {} as Dtos.TrialInstitution;

        loadForm(reduxFormName, Object.assign({ ...trialInstitution }, extraData));
    }
}


const mapStateToProps = (state: ITmdState, ownProps: TrialInstitutionPageProps): ITrialInstitutionPageProps => {

    let trialInstitution: Dtos.TrialInstitution | undefined = !(state.trialInstitution.formData instanceof Array) ? state.trialInstitution.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];
    
    return {

        match: ownProps.match,
        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,
        trialInstitutionId: ownProps.match && ownProps.match.params.trialInstitutionId.toString() != "create" ? ownProps.match.params.trialInstitutionId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trialInstitution: trialInstitution!,

        loadingTrialInstitution: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Pending,
        loadTrialInstitutionSuccess: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Success,
        loadTrialInstitutionFailure: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Failure,

        savingTrialInstitution: state.trialInstitution.saveState && state.trialInstitution.saveState.status == RequestState.Pending,
        saveTrialInstitutionSuccess: state.trialInstitution.saveState && state.trialInstitution.saveState.status == RequestState.Success,
        saveTrialInstitutionFailure: state.trialInstitution.saveState && state.trialInstitution.saveState.status == RequestState.Failure,

        creatingTrialInstitution: state.trialInstitution.createState && state.trialInstitution.createState.status == RequestState.Pending,
        createTrialInstitutionSuccess: state.trialInstitution.createState && state.trialInstitution.createState.status == RequestState.Success,
        createTrialInstitutionFailure: state.trialInstitution.createState && state.trialInstitution.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles,
        lookups: state.trialInstitution.lookups,

        form: state.reduxForms.trialInstitution,
        formState: state.trialInstitution.formState,
        formProperties: state.trialInstitution.formProperties,

        reduxFormState: state.reduxForms.formState.trialInstitution,
        validationFailures: trialInstitution ?
            state.trialInstitution.validationFailures :
            undefined,

    };
};

const mapDispatchToProps = (dispatch): ITrialInstitutionPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadTrialInstitution: bindActionCreators(TrialInstitutionActions.LoadTrialInstitutionById, dispatch),
        clearTrialInstitution: bindActionCreators(TrialInstitutionActions.Clear, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch),

        saveTrialInstitution: bindActionCreators(TrialInstitutionActions.SaveTrialInstitution, dispatch),
        createTrialInstitution: bindActionCreators(TrialInstitutionActions.CreateTrialInstitution, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialInstitutionPage);
