import * as Dtos from "../dtos/Tmd.dtos";

export const
    LoadTrialGroupItemById: ITrialGroupItemLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TRIALGROUPITEM_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ITrialGroupItemLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALGROUPITEM_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrialGroupItemLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALGROUPITEM_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTrialGroupItem: ITrialGroupItemSaveActionCreator = (formData) => {
        return {
            type: ACTION_TRIALGROUPITEM_SAVE,
            formData
        }
    },
    SaveSuccess: ITrialGroupItemSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRIALGROUPITEM_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrialGroupItemSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALGROUPITEM_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTrialGroupItem: ITrialGroupItemCreateActionCreator = (trialGroupId, groupItemId) => {

        return {
            type: ACTION_TRIALGROUPITEM_CREATE,
            trialGroupId,
            groupItemId
        }
    },
    CreateSuccess: ITrialGroupItemCreateSuccessActionCreator = (formData, formState, formProperties, lookups) => {
        return {
            type: ACTION_TRIALGROUPITEM_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups
        }
    },
    CreateFailure: ITrialGroupItemCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALGROUPITEM_CREATE_FAILURE,
            responseStatus
        }
    },

    Clear: ITrialGroupItemClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRIALGROUPITEM_CLEAR,
            propertiesToClear
        }
    };



export const
    ACTION_TRIALGROUPITEM_LOAD_BY_ID = "ACTION_TRIALGROUPITEM_LOAD_BY_ID",
    ACTION_TRIALGROUPITEM_LOAD_SUCCESS = "ACTION_TRIALGROUPITEM_LOAD_SUCCESS",
    ACTION_TRIALGROUPITEM_LOAD_FAILURE = "ACTION_TRIALGROUPITEM_LOAD_FAILURE",

    ACTION_TRIALGROUPITEM_SAVE = "ACTION_TRIALGROUPITEM_SAVE",
    ACTION_TRIALGROUPITEM_SAVE_SUCCESS = "ACTION_TRIALGROUPITEM_SAVE_SUCCESS",
    ACTION_TRIALGROUPITEM_SAVE_FAILURE = "ACTION_TRIALGROUPITEM_SAVE_FAILURE",

    ACTION_TRIALGROUPITEM_CREATE = "ACTION_TRIALGROUPITEM_CREATE",
    ACTION_TRIALGROUPITEM_CREATE_SUCCESS = "ACTION_TRIALGROUPITEM_CREATE_SUCCESS",
    ACTION_TRIALGROUPITEM_CREATE_FAILURE = "ACTION_TRIALGROUPITEM_CREATE_FAILURE",

    ACTION_TRIALGROUPITEM_CLEAR = "ACTION_TRIALGROUPITEM_CLEAR";


export interface ITrialGroupItemLoadSuccessAction {
    type: "ACTION_TRIALGROUPITEM_LOAD_SUCCESS";
    formData: Dtos.TrialGroupItem;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialGroupItemLoadSuccessActionCreator {
    (
        formData: Dtos.TrialGroupItem,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialGroupItemLoadSuccessAction;
}

export interface ITrialGroupItemLoadFailureAction {
    type: "ACTION_TRIALGROUPITEM_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialGroupItemLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialGroupItemLoadFailureAction;
}

export interface ITrialGroupItemLoadByIdAction {
    type: "ACTION_TRIALGROUPITEM_LOAD_BY_ID";
    id: number;
}

export interface ITrialGroupItemLoadByIdActionCreator {
    (id: number): ITrialGroupItemLoadByIdAction;
}


export interface ITrialGroupItemSaveAction {
    type: "ACTION_TRIALGROUPITEM_SAVE";
    formData: Dtos.TrialGroupItem;

}

export interface ITrialGroupItemSaveActionCreator {
    (
        formData: Dtos.TrialGroupItem,
    ): ITrialGroupItemSaveAction;
}

export interface ITrialGroupItemSaveSuccessAction {
    type: "ACTION_TRIALGROUPITEM_SAVE_SUCCESS";
    formData: Dtos.TrialGroupItem;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialGroupItemSaveSuccessActionCreator {
    (
        formData: Dtos.TrialGroupItem,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrialGroupItemSaveSuccessAction;
}

export interface ITrialGroupItemSaveFailureAction {
    type: "ACTION_TRIALGROUPITEM_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialGroupItemSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialGroupItemSaveFailureAction;
}

export interface ITrialGroupItemCreateAction {
    type: "ACTION_TRIALGROUPITEM_CREATE",
    trialGroupId: number,
    groupItemId: number
}

export interface ITrialGroupItemCreateActionCreator {
    (trialGroupId: number, groupItemId: number): ITrialGroupItemCreateAction
}

export interface ITrialGroupItemCreateSuccessAction {
    type: "ACTION_TRIALGROUPITEM_CREATE_SUCCESS";
    formData: Dtos.TrialGroupItem;
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[]
}

export interface ITrialGroupItemCreateSuccessActionCreator {
    (
        formData: Dtos.TrialGroupItem,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[]
    ): ITrialGroupItemCreateSuccessAction;
}

export interface ITrialGroupItemCreateFailureAction {
    type: "ACTION_TRIALGROUPITEM_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialGroupItemCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialGroupItemCreateFailureAction;
}

export interface ITrialGroupItemClearAction {
    type: "ACTION_TRIALGROUPITEM_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrialGroupItemClearActionCreator {
    (propertiesToClear?: string[]): ITrialGroupItemClearAction;
}

export type TrialGroupItemAction =
    ITrialGroupItemLoadByIdAction |
    ITrialGroupItemLoadFailureAction |
    ITrialGroupItemLoadSuccessAction |
    ITrialGroupItemSaveAction |
    ITrialGroupItemSaveSuccessAction |
    ITrialGroupItemSaveFailureAction |
    ITrialGroupItemCreateAction |
    ITrialGroupItemCreateSuccessAction |
    ITrialGroupItemCreateFailureAction |
    ITrialGroupItemClearAction;
