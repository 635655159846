import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadKeyContact: IKeyContactLoadActionCreator = () => {
        return {
            type: ACTION_KEYCONTACTS_LOAD
        }
    },
    LoadKeyContactById: IKeyContactLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_KEYCONTACTS_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IKeyContactLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_KEYCONTACTS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IKeyContactLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_KEYCONTACTS_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveKeyContact: IKeyContactSaveActionCreator = (formData) => {
        return {
            type: ACTION_KEYCONTACTS_SAVE,
            formData
        }
    },
    SaveSuccess: IKeyContactSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_KEYCONTACTS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IKeyContactSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_KEYCONTACTS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateKeyContact: IKeyContactCreateActionCreator = () => {

        return {
            type: ACTION_KEYCONTACTS_CREATE
        }
    },
    CreateSuccess: IKeyContactCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_KEYCONTACTS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: IKeyContactCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_KEYCONTACTS_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IKeyContactClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_KEYCONTACTS_CLEAR,
            propertiesToClear
        }
    },
    Filter: IKeyContactFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_KEYCONTACTS_FILTER,
            formFilter
        }
    };



export const
    ACTION_KEYCONTACTS_LOAD = "ACTION_KEYCONTACTS_LOAD",
    ACTION_KEYCONTACTS_LOAD_BY_ID = "ACTION_KEYCONTACTS_LOAD_BY_ID",
    ACTION_KEYCONTACTS_LOAD_SUCCESS = "ACTION_KEYCONTACTS_LOAD_SUCCESS",
    ACTION_KEYCONTACTS_LOAD_FAILURE = "ACTION_KEYCONTACTS_LOAD_FAILURE",

    ACTION_KEYCONTACTS_SAVE = "ACTION_KEYCONTACTS_SAVE",
    ACTION_KEYCONTACTS_SAVE_SUCCESS = "ACTION_KEYCONTACTS_SAVE_SUCCESS",
    ACTION_KEYCONTACTS_SAVE_FAILURE = "ACTION_KEYCONTACTS_SAVE_FAILURE",

    ACTION_KEYCONTACTS_CREATE = "ACTION_KEYCONTACTS_CREATE",
    ACTION_KEYCONTACTS_CREATE_SUCCESS = "ACTION_KEYCONTACTS_CREATE_SUCCESS",
    ACTION_KEYCONTACTS_CREATE_FAILURE = "ACTION_KEYCONTACTS_CREATE_FAILURE",

    ACTION_KEYCONTACTS_CLEAR = "ACTION_KEYCONTACTS_CLEAR",

    ACTION_KEYCONTACTS_FILTER = "ACTION_KEYCONTACTS_FILTER";

export interface IKeyContactLoadAction {
    type: "ACTION_KEYCONTACTS_LOAD";
}

export interface IKeyContactLoadActionCreator {
    (): IKeyContactLoadAction;
}

export interface IKeyContactLoadSuccessAction {
    type: "ACTION_KEYCONTACTS_LOAD_SUCCESS";
    formData: Dtos.KeyContact | Dtos.KeyContact[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IKeyContactLoadSuccessActionCreator {
    (
        formData: Dtos.KeyContact | Dtos.KeyContact[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IKeyContactLoadSuccessAction;
}

export interface IKeyContactLoadFailureAction {
    type: "ACTION_KEYCONTACTS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IKeyContactLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IKeyContactLoadFailureAction;
}

export interface IKeyContactLoadByIdAction {
    type: "ACTION_KEYCONTACTS_LOAD_BY_ID";
    id: number;
}

export interface IKeyContactLoadByIdActionCreator {
    (id: number): IKeyContactLoadByIdAction;
}


export interface IKeyContactSaveAction {
    type: "ACTION_KEYCONTACTS_SAVE";
    formData: Dtos.KeyContact;

}

export interface IKeyContactSaveActionCreator {
    (
        formData: Dtos.KeyContact
    ): IKeyContactSaveAction;
}

export interface IKeyContactSaveSuccessAction {
    type: "ACTION_KEYCONTACTS_SAVE_SUCCESS";
    formData: Dtos.KeyContact;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IKeyContactSaveSuccessActionCreator {
    (
        formData: Dtos.KeyContact,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IKeyContactSaveSuccessAction;
}

export interface IKeyContactSaveFailureAction {
    type: "ACTION_KEYCONTACTS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IKeyContactSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IKeyContactSaveFailureAction;
}

export interface IKeyContactCreateAction {
    type: "ACTION_KEYCONTACTS_CREATE"
}

export interface IKeyContactCreateActionCreator {
    (): IKeyContactCreateAction
}

export interface IKeyContactCreateSuccessAction {
    type: "ACTION_KEYCONTACTS_CREATE_SUCCESS";
    formData: Dtos.KeyContact;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface IKeyContactCreateSuccessActionCreator {
    (
        formData: Dtos.KeyContact,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): IKeyContactCreateSuccessAction;
}

export interface IKeyContactCreateFailureAction {
    type: "ACTION_KEYCONTACTS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IKeyContactCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IKeyContactCreateFailureAction;
}

export interface IKeyContactClearAction {
    type: "ACTION_KEYCONTACTS_CLEAR";
    propertiesToClear?: string[];
}

export interface IKeyContactClearActionCreator {
    (propertiesToClear?: string[]): IKeyContactClearAction;
}

export interface IKeyContactFilterActionCreator {
    (formFilter: IFormFilter): IKeyContactFilterAction;
}

export interface IKeyContactFilterAction {
    type: "ACTION_KEYCONTACTS_FILTER";
    formFilter: IFormFilter;
}

export type KeyContactAction =
    IKeyContactLoadAction |
    IKeyContactLoadByIdAction |
    IKeyContactLoadFailureAction |
    IKeyContactLoadSuccessAction |
    IKeyContactSaveAction |
    IKeyContactSaveSuccessAction |
    IKeyContactSaveFailureAction |
    IKeyContactCreateAction |
    IKeyContactCreateSuccessAction |
    IKeyContactCreateFailureAction |
    IKeyContactClearAction |
    IKeyContactFilterAction;
