import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_DEPARTMENT_LOAD,
    ACTION_DEPARTMENT_LOAD_BY_ID,

    ACTION_DEPARTMENT_SAVE,

    ACTION_DEPARTMENT_CREATE,

    IDepartmentLoadAction,
    IDepartmentLoadByIdAction,
    IDepartmentLoadFailureAction,
    IDepartmentLoadSuccessAction,

    IDepartmentSaveAction,
    IDepartmentSaveSuccessAction,
    IDepartmentSaveFailureAction,

    IDepartmentCreateAction,
    IDepartmentCreateSuccessAction,
    IDepartmentCreateFailureAction,

    LoadSuccess,
    LoadFailure,


    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,
} from "../actions/department";
import { departmentsApi } from "../services/departments";


export const
    getDepartmentsEpic = (action$): Observable<IDepartmentLoadSuccessAction | IDepartmentLoadFailureAction> => {
        return action$
            .ofType(ACTION_DEPARTMENT_LOAD)
            .mergeMap((action: IDepartmentLoadAction) => {
                return departmentsApi
                    .get()
                    .map(response =>
                        LoadSuccess(response.departments, undefined, [], [], response.lookups, undefined)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getDepartmentsByIdEpic = (action$): Observable<IDepartmentLoadSuccessAction | IDepartmentLoadFailureAction> => {
        return action$
            .ofType(ACTION_DEPARTMENT_LOAD_BY_ID)
            .mergeMap((action: IDepartmentLoadByIdAction) => {
                return departmentsApi
                    .getById(action.id)
                    .map(response =>
                        LoadSuccess(response.department, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setDepartmentEpic = (action$): Observable<IDepartmentSaveSuccessAction | IDepartmentSaveFailureAction> => {
        return action$
            .ofType(ACTION_DEPARTMENT_SAVE)
            .mergeMap((action: IDepartmentSaveAction) => {
                return departmentsApi
                    .set(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.department, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createDepartmentEpic = (action$): Observable<IDepartmentCreateSuccessAction | IDepartmentCreateFailureAction> => {
        return action$
            .ofType(ACTION_DEPARTMENT_CREATE)
            .mergeMap((action: IDepartmentCreateAction) => {
                return departmentsApi
                    .create(action.institutionId)
                    .map(response =>
                        CreateSuccess(response.department, response.formState, response.formProperties, response.formRoles, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



