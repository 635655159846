import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Tmd.dtos';

import {
    ACTION_COLLECTIONINSTANCES_LOAD_BY_ID,
    ACTION_COLLECTIONINSTANCES_LOAD_FAILURE,
    ACTION_COLLECTIONINSTANCES_LOAD_SUCCESS,

    ACTION_COLLECTIONINSTANCES_SAVE,
    ACTION_COLLECTIONINSTANCES_SAVE_SUCCESS,
    ACTION_COLLECTIONINSTANCES_SAVE_FAILURE,

    ACTION_COLLECTIONINSTANCES_EXPAND_SECTION,

    ACTION_COLLECTIONINSTANCES_CLEAR,
    CollectionInstanceAction
} from '../actions/collectionInstance';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState } from "./index";
import { updateFailureIndexToId } from "../helpers/formErrorHelper";

export interface ICollectionInstanceState {
    formData: Dtos.CollectionInstance | Dtos.CollectionInstance[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    validationFailures: Dtos.ResponseError[];
    lookups: Dtos.Lookup[],
    loadState: IRequestState;
    saveState: IRequestState;
    updateState: IRequestState;
    createState: IRequestState;
}

const initialState: ICollectionInstanceState = {
    formData: undefined,
    formState: undefined,
    formProperties: undefined,
    validationFailures: undefined,
    lookups: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    updateState: {
        status: RequestState.None
    } as IRequestState,
    createState: {
        status: RequestState.None
    } as IRequestState
};


function loading(state: ICollectionInstanceState, isLoading: boolean): ICollectionInstanceState {
    const newState: ICollectionInstanceState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(state: ICollectionInstanceState,
    formData: Dtos.CollectionInstance | Dtos.CollectionInstance[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[],
    responseStatus: Dtos.ResponseStatus
): ICollectionInstanceState {
    const newState: ICollectionInstanceState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        loadState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function loadFailure(state: ICollectionInstanceState, responseStatus: Dtos.ResponseStatus): ICollectionInstanceState {
    const newState: ICollectionInstanceState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: ICollectionInstanceState, propertiesToClear?: string[]): ICollectionInstanceState {

    if (!propertiesToClear) {
        const newState: ICollectionInstanceState = update(initialState, {});
        return newState;
    }

    let stateModifier: any = {};

    propertiesToClear.forEach(propertyToClear => {
        stateModifier[propertyToClear] = { $set: initialState[propertyToClear] }
    });

    const newState = update(state, stateModifier);

    return newState;
}

function saving(state: ICollectionInstanceState, isSaving: boolean): ICollectionInstanceState {
    const newState: ICollectionInstanceState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function saveSuccess(
    state: ICollectionInstanceState,
    formData: Dtos.CollectionInstance | Dtos.CollectionInstance[],
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus
): ICollectionInstanceState {
    const newState: ICollectionInstanceState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        saveState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function saveFailure(state: ICollectionInstanceState, responseStatus: Dtos.ResponseStatus): ICollectionInstanceState {
    const newState: ICollectionInstanceState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                meta: responseStatus.meta
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, state.formData) : undefined
        }
    });

    return newState;
}

function expandSection(state: ICollectionInstanceState, collectionSectionId: number): ICollectionInstanceState {
    let collectionInstance: Dtos.CollectionInstance | undefined = !(state.formData instanceof Array) ? state.formData : undefined;
    if (!collectionInstance) return state;
    //let formData = { ...collectionInstance };
    let newSections = [];
    collectionInstance.sections.forEach(section => {
        newSections.push({ ...section, expanded: section.collectionSection.id == collectionSectionId });
    });
    const newState: ICollectionInstanceState = update(state, {
        formData: {
            sections: {
                $set: newSections
            }
        }
    });

    return newState;
}

const CollectionInstanceReducer: Reducer<ICollectionInstanceState> = (state: ICollectionInstanceState = initialState, action: CollectionInstanceAction) => {
    switch (action.type) {
        case ACTION_COLLECTIONINSTANCES_LOAD_BY_ID:
            return loading(state, true);
        case ACTION_COLLECTIONINSTANCES_LOAD_SUCCESS:
            return loadSuccess(state, action.formData, action.formState, action.formProperties, action.lookups, action.responseStatus);
        case ACTION_COLLECTIONINSTANCES_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_COLLECTIONINSTANCES_CLEAR:
            return clear(state, action.propertiesToClear);
        case ACTION_COLLECTIONINSTANCES_SAVE:
            return saving(state, true);
        case ACTION_COLLECTIONINSTANCES_SAVE_SUCCESS:
            return saveSuccess(state, action.formData, action.formState, action.responseStatus);
        case ACTION_COLLECTIONINSTANCES_SAVE_FAILURE:
            return saveFailure(state, action.responseStatus);
        case ACTION_COLLECTIONINSTANCES_EXPAND_SECTION:
            return expandSection(state, action.collectionSectionId);
    }

    return state;
}

export default CollectionInstanceReducer;