import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trialItemsApi = {
    getTrailItemById: (id: number): Observable<Dtos.GetTrialItemByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrialItemById = new Dtos.GetTrialItemById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTrialItemByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrialItem: (trialItem: Dtos.TrialItem, collection: Dtos.Collection): Observable<Dtos.SetTrialItemResponse> => {

        let request: Dtos.SetTrialItem = new Dtos.SetTrialItem();

        request.trialItem = trialItem;
        request.collection = collection;

        // send request
        const response: Promise<Dtos.SetTrialItemResponse> = client.post(request);

        return Observable.from(response);

    },
    createTrialItem: (trialId: number): Observable<Dtos.CreateTrialItemResponse> => {
        //Create the request.
        let request: Dtos.CreateTrialItem = new Dtos.CreateTrialItem();

        request.trialId = trialId;

        //Send request.
        const response: Promise<Dtos.CreateTrialItemResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}