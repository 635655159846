import * as Dtos from "../../dtos/Tmd.dtos";

export const
    setEditModalViewState: ITrialGroupItemsPageSetEditModalViewStateActionCreator = (open, collectionInstanceId, name) => {
        return {
            type: ACTION_TRIALGROUPITEMS_PAGE_SET_EDIT_MODAL_VIEW_STATE,
            open,
            collectionInstanceId,
            name
        }
    },
    setCreateVersionModalViewState: ITrialGroupItemsPageSetCreateVersionModalViewStateActionCreator = (open, trialGroupId, groupItemId) => {
        return {
            type: ACTION_TRIALGROUPITEMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE,
            open,
            trialGroupId,
            groupItemId
        }
    }

export const
    ACTION_TRIALGROUPITEMS_PAGE_SET_EDIT_MODAL_VIEW_STATE = "ACTION_TRIALGROUPITEMS_PAGE_SET_EDIT_MODAL_VIEW_STATE",
    ACTION_TRIALGROUPITEMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE = "ACTION_TRIALGROUPITEMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE"

/* Actions */

export interface ITrialGroupItemsPageSetEditModalViewStateAction {
    type: "ACTION_TRIALGROUPITEMS_PAGE_SET_EDIT_MODAL_VIEW_STATE",
    open: boolean;
    collectionInstanceId: number;
    name: string;
}


export interface ITrialGroupItemsPageSetCreateVersionModalViewStateAction {
    type: "ACTION_TRIALGROUPITEMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE",
    open: boolean;
    trialGroupId: number;
    groupItemId: number;
}

/* Action Creators */

export interface ITrialGroupItemsPageSetEditModalViewStateActionCreator {
    (
        open: boolean,
        collectionIntanceId: number,
        name: string
    ): ITrialGroupItemsPageSetEditModalViewStateAction;
}

export interface ITrialGroupItemsPageSetCreateVersionModalViewStateActionCreator {
    (
        open: boolean,
        trialGroupId: number,
        groupItemId: number
    ): ITrialGroupItemsPageSetCreateVersionModalViewStateAction;
}


/* Final Export of actions */
export type TrialInstitutionItemsPageAction =
    ITrialGroupItemsPageSetEditModalViewStateAction |
    ITrialGroupItemsPageSetCreateVersionModalViewStateAction;
