import './CustomFormDesigner.scss';
import * as Dtos from '../../../../dtos/Tmd.dtos';

import * as React from "react";
import * as classNames from "classnames";
import { IBaseFormInputProps } from '../';
import { actions, FieldAction, ChangeOptions, ModelAction } from 'react-redux-form';

import { FontAwesomeIcons, FontAwesomeIcon } from "../../../../constants/fontAwesomeIcons";

export interface ICustomFormDesignerProps {
    item?: Dtos.ItemComponent;
    feedbackIcon?: string;
    className?: string;
    label?: string;
    name: string;
    description?: string;
    id: string;
    // onChange?: (value: any, options?: ChangeOptions) => ModelAction;
    onFocus?: () => FieldAction;
    // value: any;
    disabled?: boolean;
    focused?: boolean;
    valid?: boolean;
    invalid?: boolean;
    hasSoftError?: boolean;
    hasHardError?: boolean;
}

class CustomFormDesigner extends React.PureComponent<ICustomFormDesignerProps, any> {

    renderComponentContainer() {

    }

    render() {
        const {
            className,
            item,
            disabled,
            focused,
            id,
            invalid,
            label,
            name,
            onFocus,
            valid,
            feedbackIcon,
            hasSoftError,
            hasHardError
        } = this.props;

        const derivedClass = className ? className : "form-control";

        let componentDesign;

        switch (item.customInputControlName) {
            case "CustomDivider":
                componentDesign = <CustomFormDesignerContainer header={"Divider"} description={ "Display a horizontal line to visually separate virtical components" } item={item} ><>
                    </></CustomFormDesignerContainer>;
                break;

        }

        const primative = <div className="custom-form-designer">
            {componentDesign}
        </div>;

        const hardFeedback = hasHardError && !disabled ?
            <span className={classNames("form-control-feedback block pr-2")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.BAN} fixedWidth />
            </span> :
            null;


        const feedback = (invalid && !disabled) && !hasHardError ?
            <span className={classNames("form-control-feedback pr-2")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE} fixedWidth />
            </span> :
            null;

        const softFeedback = hasSoftError && !disabled && (!invalid) ?
            <span className={classNames("form-control-feedback warning pr-2")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.EXCLAMATION_TRIANGLE} fixedWidth />
            </span> :
            null;

        return <div style={{ position: "relative", width: "100%" }}>
            {primative}
            {hardFeedback}
            {feedback}
            {softFeedback}
        </div>
    }
}

export interface ICustomFormDesignerContainerProps {
    header: string;
    item?: Dtos.ItemComponent;
    description?: string;
}


class CustomFormDesignerContainer extends React.PureComponent<ICustomFormDesignerContainerProps, any> {
    constructor(props) {
        super(props);
    }

    render() {

        const {
            header,
            item,
            description
        } = this.props

        return (
            <div className="custom-form-designer-container container-fluid">
                <div className="row">
                    <div className="col">
                        <h6><b>{header}</b>
                            {description && (
                                <span>&nbsp;|&nbsp;{description}</span>
                            )}</h6>
                    </div>
                </div>
                <div className="row" style={{ marginTop: '5px' }}>
                    <div className="col">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomFormDesigner;