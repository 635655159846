import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialPersonnelState: Dtos.Personnel & IRegistrationFormState = {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    userName: null,
    active: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false,
    personnelRoleSummaries: [],
    openClinicaUserRoles: [],
    display: null,
    notes: [],
    activeDirectoryUser: null,
    hasActiveDirectoryEntry: null,
    password: null,
    confirmPassword: null,
    emailSent: null,
    apiKey: null,
    personnelRoles: [],
    sourceDocuments: [],
    salutationId: null,
    salutation: null,
    genderId: null,
    gender: null,
    secondaryEmail: null,
    jobTitle: null,
    phone: null,
    mobile: null,
    residentialPostcode: null,
    cvDate: null, 
    membershipTypeId: null,
    membershipType: null,
    membershipExpiryDate: null,
    comments: null,
    professionalDisciplineId: null,
    professionalDiscipline: null,
    personnelInterests: [],
    autogeneratePassword: true,
    displayWithSalutation: "",
    otherInterests: null,
    otherDisciplines: null,
    companyName: null,
    companyDepartment: null,
    workAddress: null,
    workAddress2: null,
    workSuburb: null,
    workPostcode: null,
    workStateId: 0,
    workState: null,
    workStateOther: null,
    workCountry: null,
    workCountryId: null,
    country: null,
    professionalDisciplineDisplay: null,
    activeDirectoryDisabled: null
}

export interface IPersonnelReduxFormState {
    id: IFieldState<number>,
    firstName: IFieldState<string>,
    lastName: IFieldState<string>,
    email: IFieldState<boolean>,
    userName: IFieldState<boolean>,
    active: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>,
    personnelRoleSummaries: IFieldState<Dtos.PersonnelRoleSummary[]>,
    OpenClinicaUserRoles: IFieldState<Dtos.OpenClinicaUserRole[]>,
    notes: IFieldState<Dtos.NoteTypeNotes[]>,
    activeDirectoryUser: IFieldState<string>,
    hasActiveDirectoryEntry: IFieldState<boolean>,
    emailSent: IFieldState<boolean>,
    password: IFieldState<string>,
    confirmPassword: IFieldState<string>,
    apiKey: IFieldState<string>,
    personnelRoles: IFieldState<Dtos.PersonnelRole[]>,
    sourceDocuments: IFieldState<Dtos.SourceDocument[]>,
    salutationId: IFieldState<number>,
    genderId: IFieldState<number>,
    secondaryEmail: IFieldState<string>,
    jobTitle: IFieldState<string>,
    phone: IFieldState<string>,
    mobile: IFieldState<string>,
    residentialPostcode: IFieldState<string>,
    cvDate: IFieldState<string>,
    membershipTypeId: IFieldState<number>,
    membershipExpiryDate: IFieldState<string>,
    comments: IFieldState<string>,
    professionalDisciplineId: IFieldState<number>,
    personnelInterests: IFieldState<Dtos.PersonnelInterest[]>,
    autogeneratePassword: IFieldState<boolean>,
    displayWithSalutation: IFieldState<string>,
    otherInterests: IFieldState<string>,
    otherDisciplines: IFieldState<string>,
    companyName: IFieldState<string>,
    companyDepartment: IFieldState<string>,
    workAddress: IFieldState<string>,
    workAddress2: IFieldState<string>,
    workSuburb: IFieldState<string>,
    workPostcode: IFieldState<string>,
    workStateId: IFieldState<number>,
    workStateOther: IFieldState<string>,
    workCountry: IFieldState<string>,
    workCountryId: IFieldState<number>,
}
