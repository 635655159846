import * as React from 'react';
import './PersonnelFilesPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { GridRemove } from "../../../enumerations/GridRemove";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import { FileUploadFormInput } from "../../../components/form/inputs/FileUploadFormInput/index";
import Alert from 'react-s-alert';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";
import { IPersonnelReduxFormState, initialPersonnelState } from "../../../reducers/reactReduxForms/personnel";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerFormInputFabric, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import { initialSourceDocumentState } from '../../../reducers/reactReduxForms/sourceDocument';
import * as PersonnelActions from '../../../actions/personnel';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { NotificationAlert } from "../../../components/common/index";
import { Form, actions } from 'react-redux-form';
import { PersonnelSubMenu } from '../../../helpers/subMenuHelper';
import { formatBytes, SOURCE_DOCUMENT_FILE_CONFIGURATION } from "../../../helpers/file";
import { DEFAULT_FILE_UPLOAD_VIEW, SOURCE_DOCUMENT_UPLOAD_VIEW } from "../../../components/form/inputs/FileUploadFormInput/FileUploadViews";
import { requiredText, requiredSourceDocument } from "../../../validators/index";
import * as AuthenticationHelper from '../../../helpers/authentication';


interface IPersonnelFilesPageParams {
    personnelId: number;
}

interface IPersonnelFilesPageProps {

    form: Dtos.Personnel & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IPersonnelReduxFormState;

    location: Location;
    personnelId: number;
    match: match<IPersonnelFilesPageParams>;
    history: History;

    // From state
    personnel: Dtos.Personnel;
    lookups: Dtos.Lookup[];

    loadingPersonnel: boolean;
    loadPersonneluccess: boolean;
    loadPersonnelFailure: boolean;

    savingPersonnel: boolean;
    savePersonneluccess: boolean;
    savePersonnelFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];
    formRoles: Dtos.RoleType[]
}

interface IPersonnelFilesPageActions {
    clearPersonnel: PersonnelActions.IPersonnelClearActionCreator;
    loadPersonnel: PersonnelActions.IPersonnelLoadByIdActionCreator;

    savePersonnel: PersonnelActions.IPersonnelaveActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type PersonnelFilesPageProps = IPersonnelFilesPageProps & IPersonnelFilesPageActions;


const formName: string = "personnel";
const reduxFormName: string = "reduxForms.personnel";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class PersonnelFilesPage extends React.PureComponent<PersonnelFilesPageProps, any> {

    constructor(props: PersonnelFilesPageProps) {
        super(props);

        this.state = {
            showUsernameWarning: false
        };

        this.clearPersonnel = this.clearPersonnel.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
        this.changeShowUsernameWarning = this.changeShowUsernameWarning.bind(this);
    }

    componentDidMount() {
        const {
            loadPersonnel,
            navigate,
            navigateReplace,
            personnelId
        } = this.props;

        if (personnelId) {
            loadPersonnel(personnelId);
        } else if (!personnelId) {
            navigate("/personnel");
        }
    }

    componentWillReceiveProps(nextProps: PersonnelFilesPageProps) {
    }

    componentDidUpdate(prevProps: PersonnelFilesPageProps, prevState) {
        const {
            navigateReplace,
            personnel,
            personnelId,
            form,
            navigate,
            loadPersonnel,
            loadingPersonnel,
            loadPersonneluccess,
            savingPersonnel,
            savePersonneluccess,
            savePersonnelFailure,
            validationFailures
        } = this.props;

        if (personnelId && prevProps.personnelId != personnelId) {
            loadPersonnel(personnelId);
            if (this.state.showUsernameWarning) {
                this.setState({ showUsernameWarning: false });
            }
        }
        else if (!personnelId && prevProps.personnelId) {
            navigate("/personnel");
            return;
        }

        if (!loadingPersonnel && prevProps.loadingPersonnel) {
            if (loadPersonneluccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingPersonnel && prevProps.savingPersonnel) {
            if (savePersonneluccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);
                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/personnel")
                } else if (!form.saveAndReturn && personnelId && personnelId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !personnelId && personnel && personnel.id > 0) {
                    navigate("/personnel/" + personnel.id + "/details");
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }
    }

    componentWillUpdate(nextProps: PersonnelFilesPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearPersonnel();
        loadForm(reduxFormName, initialPersonnelState);
        resetForm(reduxFormName);
    }

    changeShowUsernameWarning(value: boolean) {
        this.setState({ showUsernameWarning: value });
    }

    clearPersonnel() {
        this.props.clearPersonnel();
    }

    showPersonnel(): boolean {
        const {
            loadingPersonnel,
            loadPersonneluccess
        } = this.props

        if (!loadingPersonnel && loadPersonneluccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            form,
            loadingPersonnel,
            personnelId,
            personnel,
            formRoles,
            permissions
        } = this.props;

        return <RestrictedLayout
            permission={ Dtos.Permission.PersonnelItemsView }
            subMenuTitle={personnel ? personnel.display : ""}
            subMenuItems={personnelId == undefined ? undefined : PersonnelSubMenu(personnelId, permissions)}
            loading={loadingPersonnel}
        >
            
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingPersonnel,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.PersonnelItemsUpdate, Dtos.Permission.PersonnelItemsCreate);
        const formDisabled: boolean = !hasPermission || savingPersonnel || (formState && formState.formStatus == Dtos.FormStatus.Disabled);


        let formComponents: { [index: string]: RegistrationFormComponent } = {
            sourceDocuments: {
                type: RegistrationComponent.RegistrationSourceDocuments,
                label: " ",
                disabled: formDisabled,
                components: {
                    sourceDocuments: {
                        type: RegistrationComponent.RegistrationGrid,
                        formName: "SourceDocument",
                        disabled: formDisabled,
                        label: "Add Source Document",
                        className: "source-documents-source-documents",
                        minRows: 0,
                        columnStyles: [
                            {
                                width: undefined,
                                minWidth: "200px"
                            },
                            {
                                width: "23%",
                                minWidth: "150px"
                            },
                            {
                                width: "20%",
                                minWidth: "150px"
                            },
                            {
                                width: "125px"
                            },
                        ],
                        removeConfig: GridRemove.All,
                        addRow: (gridComponent, index, array) => {
                            const state: Dtos.SourceDocument = Object.assign(
                                { ...gridComponent.initialState }
                            );
                            return state;
                        },
                        components: {
                            name: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: TextFormInput,
                                inputDisabled: formDisabled,
                                inputLabel: getFormPropertyLabel("CollectionInstance", "FileName", formProperties),
                                inputProps: {
                                    feedbackIcon: FontAwesomeIcons.Solid.BAN
                                },
                                validators: {
                                    required: requiredText
                                },
                                errors: {
                                    required: "File name is required."
                                },
                                errorComponent: ValidationMessage,
                            },
                            fileType: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: SelectFormInput,
                                inputDisabled: formDisabled,
                                inputLabel: getFormPropertyLabel("CollectionInstance", "FileType", formProperties),
                                inputProps: {
                                    options: generateOptionsFromLookup(findLookup("SourceDocument", "FileType", lookups)),
                                    feedbackIcon: FontAwesomeIcons.Solid.BAN
                                },
                                validators: {
                                    required: value => value != undefined
                                },
                                errors: {
                                    required: "File type is required."
                                },
                                errorComponent: ValidationMessage
                            },
                            fileInformation: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: FileUploadFormInput,
                                inputDisabled: formDisabled,
                                inputLabel: getFormPropertyLabel("CollectionInstance", "Upload", formProperties),
                                changeAction: (model, value, form, parentModel) => {
                                    changeForm(model, value);
                                    const inputValue: Dtos.TemporaryFileInformation = value;
                                    const sourceDocument: Dtos.SourceDocument = form;

                                    if (sourceDocument.fileInformation &&
                                        inputValue &&
                                        sourceDocument.fileInformation.state != inputValue.state &&
                                        inputValue.state == Dtos.FileState.Success &&
                                        !sourceDocument.name) {
                                        changeForm(reduxFormName + parentModel + ".name", inputValue.temporaryFile.name.substr(0, inputValue.temporaryFile.name.lastIndexOf(inputValue.temporaryFile.extension)));
                                    }
                                },
                                inputProps: {
                                    render: SOURCE_DOCUMENT_UPLOAD_VIEW
                                },
                                validators: {
                                    required: requiredSourceDocument
                                },
                                errors: {
                                    required: "File must be uploaded."
                                },
                                errorComponent: ValidationMessage
                            },
                            fileConfiguration: {
                                type: RegistrationComponent.RegistrationGridElement,
                                label: " ",
                                render: SOURCE_DOCUMENT_FILE_CONFIGURATION
                            }
                        },
                        initialState: { ...initialSourceDocumentState },
                    },
                },
                keyReplace: "sourceDocuments",
                onRemove: (model: string) => {
                    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.PersonnelItemsUpdate)) {
                        changeForm(model + "active", false);
                    }
                    return true; // this was handled here
                },
                filterRow: (data: Dtos.SourceDocument) => {
                    console.info('filterRow', data);
                    if (data.active != true) {
                        return false
                    }
                    return true;
                }
            },
        };



        return <div>
            <h1>Personnel Items</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >   
                {
                    renderFormControls(form, "personnel", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
        </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.Personnel) {
        const {
            savePersonnel
        } = this.props;

        if (data) {
            let changes: Dtos.Personnel = {} as Dtos.Personnel;
            savePersonnel(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.Personnel) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }


    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/personnel");
    }

    loadForm() {
        const {
            loadForm,
            personnel,
        } = this.props

        let extraData: Dtos.Personnel = {} as Dtos.Personnel;

        loadForm(reduxFormName, Object.assign({ ...personnel }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: PersonnelFilesPageProps): IPersonnelFilesPageProps => {

    let personnel: Dtos.Personnel | undefined = !(state.personnel.formData instanceof Array) ? state.personnel.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        personnelId: ownProps.match && ownProps.match.params.personnelId && ownProps.match.params.personnelId.toString() != "create" ? ownProps.match.params.personnelId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        personnel: personnel!,

        loadingPersonnel: state.personnel.loadState && state.personnel.loadState.status == RequestState.Pending,
        loadPersonneluccess: state.personnel.loadState && state.personnel.loadState.status == RequestState.Success,
        loadPersonnelFailure: state.personnel.loadState && state.personnel.loadState.status == RequestState.Failure,

        savingPersonnel: state.personnel.saveState && state.personnel.saveState.status == RequestState.Pending,
        savePersonneluccess: state.personnel.saveState && state.personnel.saveState.status == RequestState.Success,
        savePersonnelFailure: state.personnel.saveState && state.personnel.saveState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles,
        lookups: state.personnel.lookups,

        form: state.reduxForms.personnel,
        formState: state.personnel.formState,
        formProperties: state.personnel.formProperties,

        reduxFormState: state.reduxForms.formState.personnel,
        validationFailures: personnel ?
            state.personnel.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): IPersonnelFilesPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadPersonnel: bindActionCreators(PersonnelActions.LoadPersonnelById, dispatch),
        clearPersonnel: bindActionCreators(PersonnelActions.Clear, dispatch),

        savePersonnel: bindActionCreators(PersonnelActions.SavePersonnel, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(PersonnelFilesPage);
