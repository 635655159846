import * as React from 'react';

import * as classNames from "classnames";
import './PersonnelRolesPage.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";



import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";
import { IPersonnelReduxFormState, initialPersonnelState } from "../../../reducers/reactReduxForms/personnel";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";

import * as PersonnelActions from '../../../actions/personnel';
import * as ToggleActions from '../../../actions/pages/togglePage';
import * as ToggleHelper from "../../../helpers/toggleHelper";
import { TogglePageItem } from '../../../reducers/pages/togglePage';


import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput, FormFilterInput } from '../../../components/form';
import { PrimitiveFormDesigner, PrimitiveFormInput } from '../../../components/form/index';
import { SettingsSubMenu, PersonnelSubMenu } from '../../../helpers/subMenuHelper';

interface IPersonnelRolesPageParams {
    personnelId: number;
}

interface IPersonnelRolesPageProps {

    form: Dtos.Personnel & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IPersonnelReduxFormState;

    location: Location;
    personnelId: number;
    match: match<IPersonnelRolesPageParams>;
    history: History;

    // From state
    formFilter: IFormFilter;
    personnel: Dtos.Personnel;
    personnelRoleSummaries: Dtos.PersonnelRoleSummary[];
    lookups: Dtos.Lookup[];

    loadingPersonnel: boolean;
    loadPersonneluccess: boolean;
    loadPersonnelFailure: boolean;

    savingPersonnel: boolean;
    savePersonneluccess: boolean;
    savePersonnelFailure: boolean;

    creatingPersonnel: boolean;
    createPersonneluccess: boolean;
    createPersonnelFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];
    formRoles: Dtos.RoleType[],

    toggleItems: TogglePageItem[]
}

interface IPersonnelRolesPageActions {
    clearPersonnel: PersonnelActions.IPersonnelClearActionCreator;
    loadPersonnel: PersonnelActions.IPersonnelLoadByIdActionCreator;

    savePersonnel: PersonnelActions.IPersonnelaveActionCreator;
    createPersonnel: PersonnelActions.IPersonnelCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    toggleExpand: ToggleActions.IToggleExpandedStateActionCreator;

    filter: PersonnelActions.IPersonnelRolesFilterActionCreator;
}

type PersonnelRolesPageProps = IPersonnelRolesPageProps & IPersonnelRolesPageActions;


const formName: string = "personnel";
const reduxFormName: string = "reduxForms.personnel";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class PersonnelRolesPage extends React.PureComponent<PersonnelRolesPageProps, any> {

    constructor(props: PersonnelRolesPageProps) {
        super(props);

        this.clearPersonnel = this.clearPersonnel.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadPersonnel,
            navigate,
            navigateReplace,
            personnelId,
            createPersonnel
        } = this.props;

        if (personnelId) {
            loadPersonnel(personnelId);
        } else if (!personnelId) {
            createPersonnel();
        }
    }

    componentWillReceiveProps(nextProps: PersonnelRolesPageProps) {
    }

    componentDidUpdate(prevProps: PersonnelRolesPageProps, prevState) {
        const {
            navigateReplace,
            personnel,
            personnelId,
            form,
            navigate,
            loadPersonnel,
            loadingPersonnel,
            loadPersonneluccess,
            createPersonnel,
            creatingPersonnel,
            createPersonneluccess,
            savingPersonnel,
            savePersonneluccess,
            savePersonnelFailure,
            validationFailures
        } = this.props;

        if (personnelId && prevProps.personnelId != personnelId) {
            loadPersonnel(personnelId);
        }
        else if (!personnelId && prevProps.personnelId) {
            createPersonnel();
        }

        if (!loadingPersonnel && prevProps.loadingPersonnel) {
            if (loadPersonneluccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingPersonnel && prevProps.savingPersonnel) {
            if (savePersonneluccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/personnel")
                } else if (!form.saveAndReturn && personnelId && personnelId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !personnelId && personnel && personnel.id > 0) {
                    navigate("/personnel/" + personnel.id);
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingPersonnel && prevProps.creatingPersonnel) {
            if (createPersonneluccess) {
                this.loadForm();
            }
            else {

            }
        }
    }

    componentWillUpdate(nextProps: PersonnelRolesPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearPersonnel();
        loadForm(reduxFormName, initialPersonnelState);
        resetForm(reduxFormName);
    }

    clearPersonnel() {
        this.props.clearPersonnel();
    }

    showPersonnel(): boolean {
        const {
            loadingPersonnel,
            loadPersonneluccess
        } = this.props

        if (!loadingPersonnel && loadPersonneluccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            form,
            loadingPersonnel,
            personnelId,
            personnel,
            formRoles,
            permissions
        } = this.props;

        return <RestrictedLayout
            permission={Dtos.Permission.PersonnelRoleView}
            subMenuTitle={personnel ? personnel.display : ""}
            subMenuItems={personnelId == undefined ? undefined : PersonnelSubMenu(personnelId, permissions)}
            loading={loadingPersonnel}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            form,
            personnel,
            loadingPersonnel,
            loadPersonneluccess,
            loadPersonnelFailure,
            clearPersonnel,
            navigate,
            user,
            lookups,
            validationFailures,
            formProperties,
            formState,
            permissions,
            savingPersonnel
        } = this.props;

        
        let formComponents: { [index: string]: RegistrationFormComponent } = {

        };



        return <div>
            <div className="row">
                <div className={"col-auto"}>
                    <h2>Personnel Roles</h2>
                </div>
                <div className="col" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} />
                    {(AuthenticationHelper.hasPermission(permissions, Dtos.Permission.PersonnelRoleCreate) &&  personnel && personnel.id > 0) && (
                        <Link className={"btn btn-primary"} url={"/personnel/" + personnel.id + '/roles/create'}>Create</Link>
                    )}
                </div>
            </div>
            {
                loadingPersonnel ?
                    "Loading Personnel" :
                    null
            }
            {
                !loadingPersonnel ?
                    <div className="mt-2">
                        {this.renderTable()}
                    </div> :
                    null
            }
        </div>

    }

    toggle(personnelRoleSummary: Dtos.PersonnelRoleSummary) {

    }


    renderTable() {
        const {
            personnel,
            personnelRoleSummaries,
            toggleItems,
            toggleExpand
        } = this.props;


        if (!personnelRoleSummaries || personnelRoleSummaries.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no institutions and roles to view.</span>
            </div>
        }

        const showPagination = false; //personnelRoleSummaries.length > DEFAULT_PAGE_SIZE;

        return <div>
            {personnelRoleSummaries && personnelRoleSummaries.map((item, index) => {
                const key = "personnel-roles-page-" + item.personnelRoleSummaryType + "-" + item.display;
                const expanded = ToggleHelper.isToggleExpanded(toggleItems, key);
                return (
                    <div key={"parent-category" + index}>
                        <div className={tableHeaderClass} style={tableStyleClass} onClick={() => toggleExpand(key)}>
                            <div>
                                <FontAwesomeIcon icon={!expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" />
                            </div>
                            <span>{item.display}</span>
                        </div>
                        {expanded && item.personnelRoleSummaryType == Dtos.PersonnelRoleSummaryType.General && (
                            this.renderGeneralRoles(item.personnelRoleSummaries)
                        )}
                        {expanded && item.personnelRoleSummaryType == Dtos.PersonnelRoleSummaryType.Group && (
                            this.renderGroupRoles(item.personnelRoleSummaries)
                        )}
                        {expanded && item.personnelRoleSummaryType == Dtos.PersonnelRoleSummaryType.Trial && (
                            this.renderTrialRoles(item.personnelRoleSummaries)
                        )}
                        {expanded && item.personnelRoleSummaryType == Dtos.PersonnelRoleSummaryType.Institution && (
                            this.renderInstitutionRoles(item.personnelRoleSummaries)
                        )}
                        {expanded && item.personnelRoleSummaryType == Dtos.PersonnelRoleSummaryType.TrialInstitution && (
                            this.renderTrialInstitutionRoles(item.personnelRoleSummaries)
                        )}
                    </div>
                )
            }
            )}
        </div>
    }

    renderGeneralRoles(personnelRoleSummaries: Dtos.PersonnelRoleSummary[]) {
        const showPagination = false; const {
            personnel
        } = this.props;

        return (
            <ReactTable
                data={personnelRoleSummaries}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : personnelRoleSummaries.length}
                pageSize={showPagination ? undefined : personnelRoleSummaries.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no roles for this type"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log(rowInfo);
                            const url: string = "/personnel/" + personnel.id + '/roles/' + rowInfo.original.personnelRole.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.personnelRole.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={
                    [
                        {
                            id: "id",
                            Header: ' ',
                            accessor: "id",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 50,
                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.ROLE} fixedWidth />
                        },
                        {
                            id: "role",
                            Header: "Role",
                            accessor: "personnelRole.roleDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "department",
                            Header: "Department",
                            accessor: "personnelRole.department.name",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "dateFrom",
                            Header: "Date Started",
                            accessor: "personnelRole.dateFrom",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        },
                        {
                            id: "dateTo",
                            Header: "Date Ceased",
                            accessor: "personnelRole.dateTo",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        }
                    ]
                }
            />
        );
    }

    renderGroupRoles(personnelRoleSummaries: Dtos.PersonnelRoleSummary[]) {
        const showPagination = false; const {
            personnel
        } = this.props;

        return (
            <ReactTable
                data={personnelRoleSummaries}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : personnelRoleSummaries.length}
                pageSize={showPagination ? undefined : personnelRoleSummaries.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no roles for this type"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log(rowInfo);
                            const url: string = "/personnel/" + personnel.id + '/roles/' + rowInfo.original.personnelRole.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.personnelRole.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={
                    [
                        {
                            id: "id",
                            Header: ' ',
                            accessor: "id",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 50,
                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.ROLE} fixedWidth />
                        },
                        {
                            id: "group",
                            Header: "Group",
                            accessor: "personnelRole.groupDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "role",
                            Header: "Role",
                            accessor: "personnelRole.roleDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "department",
                            Header: "Department",
                            accessor: "personnelRole.department.name",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "dateFrom",
                            Header: "Date Started",
                            accessor: "personnelRole.dateFrom",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        },
                        {
                            id: "dateTo",
                            Header: "Date Ceased",
                            accessor: "personnelRole.dateTo",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        }
                    ]
                }
            />
        );
    }

    renderInstitutionRoles(personnelRoleSummaries: Dtos.PersonnelRoleSummary[]) {
        const showPagination = false; const {
            personnel
        } = this.props;

        return (
            <ReactTable
                data={personnelRoleSummaries}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : personnelRoleSummaries.length}
                pageSize={showPagination ? undefined : personnelRoleSummaries.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no roles for this type"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log(rowInfo);
                            const url: string = "/personnel/" + personnel.id + '/roles/' + rowInfo.original.personnelRole.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.personnelRole.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={
                    [
                        {
                            id: "id",
                            Header: ' ',
                            accessor: "id",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 50,
                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.ROLE} fixedWidth />
                        },
                        {
                            id: "institution",
                            Header: "Institution",
                            accessor: "personnelRole.institutionDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "role",
                            Header: "Role",
                            accessor: "personnelRole.roleDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "department",
                            Header: "Department",
                            accessor: "personnelRole.department.name",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "dateFrom",
                            Header: "Date Started",
                            accessor: "personnelRole.dateFrom",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        },
                        {
                            id: "dateTo",
                            Header: "Date Ceased",
                            accessor: "personnelRole.dateTo",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        }
                    ]
                }
            />
        );
    }

    renderTrialRoles(personnelRoleSummaries: Dtos.PersonnelRoleSummary[]) {
        const showPagination = false; const {
            personnel
        } = this.props;

        return (
            <ReactTable
                data={personnelRoleSummaries}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : personnelRoleSummaries.length}
                pageSize={showPagination ? undefined : personnelRoleSummaries.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no roles for this type"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log(rowInfo);
                            const url: string = "/personnel/" + personnel.id + '/roles/' + rowInfo.original.personnelRole.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.personnelRole.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={
                    [
                        {
                            id: "id",
                            Header: ' ',
                            accessor: "id",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 50,
                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.ROLE} fixedWidth />
                        },
                        {
                            id: "trial",
                            Header: "Trial",
                            accessor: "personnelRole.trialDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "role",
                            Header: "Role",
                            accessor: "personnelRole.roleDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "department",
                            Header: "Department",
                            accessor: "personnelRole.department.name",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "dateFrom",
                            Header: "Date Started",
                            accessor: "personnelRole.dateFrom",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        },
                        {
                            id: "dateTo",
                            Header: "Date Ceased",
                            accessor: "personnelRole.dateTo",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        }
                    ]
                }
            />
        );
    }

    renderTrialInstitutionRoles(personnelRoleSummaries: Dtos.PersonnelRoleSummary[]) {
        const showPagination = false; const {
            personnel
        } = this.props;

        return (
            <ReactTable
                data={personnelRoleSummaries}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : personnelRoleSummaries.length}
                pageSize={showPagination ? undefined : personnelRoleSummaries.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no roles for this type"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log(rowInfo);
                            const url: string = "/personnel/" + personnel.id + '/roles/' + rowInfo.original.personnelRole.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.personnelRole.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={
                    [
                        {
                            id: "id",
                            Header: ' ',
                            accessor: "id",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 50,
                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.ROLE} fixedWidth />
                        },
                        {
                            id: "trial",
                            Header: "Trial",
                            accessor: "personnelRole.trialDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "institution",
                            Header: "Institution",
                            accessor: "personnelRole.institutionDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "role",
                            Header: "Role",
                            accessor: "personnelRole.roleDisplay",
                            className: "d-flex align-items-center justify-content-center",
                        },
                        {
                            id: "department",
                            Header: "Department",
                            accessor: "personnelRole.department.name",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "dateFrom",
                            Header: "Date Started",
                            accessor: "personnelRole.dateFrom",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        },
                        {
                            id: "dateTo",
                            Header: "Date Ceased",
                            accessor: "personnelRole.dateTo",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        }
                    ]
                }
            />
        );
    }



    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.Personnel) {
        const {
            savePersonnel
        } = this.props;

        if (data) {
            let changes: Dtos.Personnel = {} as Dtos.Personnel;
            savePersonnel(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.Personnel) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }


    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/personnel");
    }

    loadForm() {
        const {
            loadForm,
            personnel,
        } = this.props

        let extraData: Dtos.Personnel = {} as Dtos.Personnel;

        loadForm(reduxFormName, Object.assign({ ...personnel }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: PersonnelRolesPageProps): IPersonnelRolesPageProps => {

    let personnel: Dtos.Personnel | undefined = !(state.personnel.formData instanceof Array) ? state.personnel.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        personnelId: ownProps.match && ownProps.match.params.personnelId.toString() != "create" ? ownProps.match.params.personnelId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        formFilter: state.personnel.formFilterPersonnelRoles,
        personnel: personnel!,
        personnelRoleSummaries: state.personnel.formFilteredPersonnelRoles,

        loadingPersonnel: state.personnel.loadState && state.personnel.loadState.status == RequestState.Pending,
        loadPersonneluccess: state.personnel.loadState && state.personnel.loadState.status == RequestState.Success,
        loadPersonnelFailure: state.personnel.loadState && state.personnel.loadState.status == RequestState.Failure,

        savingPersonnel: state.personnel.saveState && state.personnel.saveState.status == RequestState.Pending,
        savePersonneluccess: state.personnel.saveState && state.personnel.saveState.status == RequestState.Success,
        savePersonnelFailure: state.personnel.saveState && state.personnel.saveState.status == RequestState.Failure,

        creatingPersonnel: state.personnel.createState && state.personnel.createState.status == RequestState.Pending,
        createPersonneluccess: state.personnel.createState && state.personnel.createState.status == RequestState.Success,
        createPersonnelFailure: state.personnel.createState && state.personnel.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles,
        lookups: [],

        form: state.reduxForms.personnel,
        formState: state.personnel.formState,
        formProperties: state.personnel.formProperties,

        reduxFormState: state.reduxForms.formState.personnel,
        validationFailures: personnel ?
            state.personnel.validationFailures :
            undefined,

        toggleItems: state.togglePage.items
    };
};

const mapDispatchToProps = (dispatch): IPersonnelRolesPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadPersonnel: bindActionCreators(PersonnelActions.LoadPersonnelById, dispatch),
        clearPersonnel: bindActionCreators(PersonnelActions.Clear, dispatch),

        savePersonnel: bindActionCreators(PersonnelActions.SavePersonnel, dispatch),
        createPersonnel: bindActionCreators(PersonnelActions.CreatePersonnel, dispatch),

        toggleExpand: bindActionCreators(ToggleActions.ToggleExpanded, dispatch),

        filter: bindActionCreators(PersonnelActions.PersonnelRolesFilter, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(PersonnelRolesPage);
