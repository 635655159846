import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadCollaboratingGroup: ICollaboratingGroupLoadActionCreator = () => {
        return {
            type: ACTION_COLLABORATINGGROUPS_LOAD
        }
    },
    LoadCollaboratingGroupById: ICollaboratingGroupLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_COLLABORATINGGROUPS_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ICollaboratingGroupLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_COLLABORATINGGROUPS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ICollaboratingGroupLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_COLLABORATINGGROUPS_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveCollaboratingGroup: ICollaboratingGroupSaveActionCreator = (formData) => {
        return {
            type: ACTION_COLLABORATINGGROUPS_SAVE,
            formData
        }
    },
    SaveSuccess: ICollaboratingGroupSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_COLLABORATINGGROUPS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ICollaboratingGroupSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_COLLABORATINGGROUPS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateCollaboratingGroup: ICollaboratingGroupCreateActionCreator = () => {

        return {
            type: ACTION_COLLABORATINGGROUPS_CREATE
        }
    },
    CreateSuccess: ICollaboratingGroupCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_COLLABORATINGGROUPS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: ICollaboratingGroupCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_COLLABORATINGGROUPS_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: ICollaboratingGroupClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_COLLABORATINGGROUPS_CLEAR,
            propertiesToClear
        }
    },
    Filter: ICollaboratingGroupFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_COLLABORATINGGROUPS_FILTER,
            formFilter
        }
    };



export const
    ACTION_COLLABORATINGGROUPS_LOAD = "ACTION_COLLABORATINGGROUPS_LOAD",
    ACTION_COLLABORATINGGROUPS_LOAD_BY_ID = "ACTION_COLLABORATINGGROUPS_LOAD_BY_ID",
    ACTION_COLLABORATINGGROUPS_LOAD_SUCCESS = "ACTION_COLLABORATINGGROUPS_LOAD_SUCCESS",
    ACTION_COLLABORATINGGROUPS_LOAD_FAILURE = "ACTION_COLLABORATINGGROUPS_LOAD_FAILURE",

    ACTION_COLLABORATINGGROUPS_SAVE = "ACTION_COLLABORATINGGROUPS_SAVE",
    ACTION_COLLABORATINGGROUPS_SAVE_SUCCESS = "ACTION_COLLABORATINGGROUPS_SAVE_SUCCESS",
    ACTION_COLLABORATINGGROUPS_SAVE_FAILURE = "ACTION_COLLABORATINGGROUPS_SAVE_FAILURE",

    ACTION_COLLABORATINGGROUPS_CREATE = "ACTION_COLLABORATINGGROUPS_CREATE",
    ACTION_COLLABORATINGGROUPS_CREATE_SUCCESS = "ACTION_COLLABORATINGGROUPS_CREATE_SUCCESS",
    ACTION_COLLABORATINGGROUPS_CREATE_FAILURE = "ACTION_COLLABORATINGGROUPS_CREATE_FAILURE",

    ACTION_COLLABORATINGGROUPS_CLEAR = "ACTION_COLLABORATINGGROUPS_CLEAR",

    ACTION_COLLABORATINGGROUPS_FILTER = "ACTION_COLLABORATINGGROUPS_FILTER";

export interface ICollaboratingGroupLoadAction {
    type: "ACTION_COLLABORATINGGROUPS_LOAD";
}

export interface ICollaboratingGroupLoadActionCreator {
    (): ICollaboratingGroupLoadAction;
}

export interface ICollaboratingGroupLoadSuccessAction {
    type: "ACTION_COLLABORATINGGROUPS_LOAD_SUCCESS";
    formData: Dtos.CollaboratingGroup | Dtos.CollaboratingGroup[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ICollaboratingGroupLoadSuccessActionCreator {
    (
        formData: Dtos.CollaboratingGroup | Dtos.CollaboratingGroup[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ICollaboratingGroupLoadSuccessAction;
}

export interface ICollaboratingGroupLoadFailureAction {
    type: "ACTION_COLLABORATINGGROUPS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ICollaboratingGroupLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ICollaboratingGroupLoadFailureAction;
}

export interface ICollaboratingGroupLoadByIdAction {
    type: "ACTION_COLLABORATINGGROUPS_LOAD_BY_ID";
    id: number;
}

export interface ICollaboratingGroupLoadByIdActionCreator {
    (id: number): ICollaboratingGroupLoadByIdAction;
}


export interface ICollaboratingGroupSaveAction {
    type: "ACTION_COLLABORATINGGROUPS_SAVE";
    formData: Dtos.CollaboratingGroup;

}

export interface ICollaboratingGroupSaveActionCreator {
    (
        formData: Dtos.CollaboratingGroup
    ): ICollaboratingGroupSaveAction;
}

export interface ICollaboratingGroupSaveSuccessAction {
    type: "ACTION_COLLABORATINGGROUPS_SAVE_SUCCESS";
    formData: Dtos.CollaboratingGroup;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ICollaboratingGroupSaveSuccessActionCreator {
    (
        formData: Dtos.CollaboratingGroup,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ICollaboratingGroupSaveSuccessAction;
}

export interface ICollaboratingGroupSaveFailureAction {
    type: "ACTION_COLLABORATINGGROUPS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ICollaboratingGroupSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ICollaboratingGroupSaveFailureAction;
}

export interface ICollaboratingGroupCreateAction {
    type: "ACTION_COLLABORATINGGROUPS_CREATE"
}

export interface ICollaboratingGroupCreateActionCreator {
    (): ICollaboratingGroupCreateAction
}

export interface ICollaboratingGroupCreateSuccessAction {
    type: "ACTION_COLLABORATINGGROUPS_CREATE_SUCCESS";
    formData: Dtos.CollaboratingGroup;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface ICollaboratingGroupCreateSuccessActionCreator {
    (
        formData: Dtos.CollaboratingGroup,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): ICollaboratingGroupCreateSuccessAction;
}

export interface ICollaboratingGroupCreateFailureAction {
    type: "ACTION_COLLABORATINGGROUPS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ICollaboratingGroupCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ICollaboratingGroupCreateFailureAction;
}

export interface ICollaboratingGroupClearAction {
    type: "ACTION_COLLABORATINGGROUPS_CLEAR";
    propertiesToClear?: string[];
}

export interface ICollaboratingGroupClearActionCreator {
    (propertiesToClear?: string[]): ICollaboratingGroupClearAction;
}

export interface ICollaboratingGroupFilterActionCreator {
    (formFilter: IFormFilter): ICollaboratingGroupFilterAction;
}

export interface ICollaboratingGroupFilterAction {
    type: "ACTION_COLLABORATINGGROUPS_FILTER";
    formFilter: IFormFilter;
}

export type CollaboratingGroupAction =
    ICollaboratingGroupLoadAction |
    ICollaboratingGroupLoadByIdAction |
    ICollaboratingGroupLoadFailureAction |
    ICollaboratingGroupLoadSuccessAction |
    ICollaboratingGroupSaveAction |
    ICollaboratingGroupSaveSuccessAction |
    ICollaboratingGroupSaveFailureAction |
    ICollaboratingGroupCreateAction |
    ICollaboratingGroupCreateSuccessAction |
    ICollaboratingGroupCreateFailureAction |
    ICollaboratingGroupClearAction |
    ICollaboratingGroupFilterAction;
