import * as React from 'react';
import './HubspotSettingsLayout.scss';
import { connect } from "react-redux";
import { RestrictedLayout } from "../../../components/layouts/index";
import { ITmdState } from "../../../reducers/index";
import * as Dtos from '../../../dtos/Tmd.dtos';
import { SettingsSubMenu } from '../../../helpers/subMenuHelper';
import * as AuthenticationHelper from '../../../helpers/authentication';
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";

interface IHubspotSettingsLayoutProps {
    permissions?: Dtos.PersonnelTmdPermissions[];
    permission?: Dtos.Permission;
    loading?: boolean;
    children?: any;
}

interface IHubspotSettingsLayoutActions {

}

type HubspotSettingsLayoutProps = IHubspotSettingsLayoutProps & IHubspotSettingsLayoutActions;

class HubspotSettingsLayout extends React.PureComponent<HubspotSettingsLayoutProps, any> {

    constructor(props: HubspotSettingsLayoutProps) {
        super(props);

    }

    render() {
        const {
            loading,
            permissions,
            permission
        } = this.props;

        let menu: any = [];
        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.HubspotContactExportView)) {
            menu.push({
                icon: undefined,
                title: "Active Members",
                url: "/settings/hubspot/activemembers"
            });
        }
        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.HubspotContactExportView)) {
            menu.push({
                icon: undefined,
                title: "Email Maintenance",
                url: "/settings/hubspot/emailupdate"
            });
        }
        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.HubspotContactExportView)) {
            menu.push({
                icon: undefined,
                title: "Process new members",
                url: "/settings/hubspot/import"
            });
        }

        return <RestrictedLayout
            subMenuItems={loading ? [] : SettingsSubMenu(permissions)}
            sub2MenuItems={loading ? [] : menu}
            loading={loading}
        >
            {permissions.length > 0 && permission && !AuthenticationHelper.hasPermission(permissions, permission) ?
                <div className="alert alert-warning d-flex">
                    <div className="mr-2">
                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                    </div>
                    <span>Permission Denied</span>
                </div>
                : this.props.children}
        </RestrictedLayout>;
    }

}


const mapStateToProps = (state: ITmdState, ownProps: HubspotSettingsLayoutProps): IHubspotSettingsLayoutProps => {
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];
    return {
        permissions: permissions,
        permission: ownProps.permission,
        loading: ownProps.loading,
        children: ownProps.children
    };
};

const mapDispatchToProps = (dispatch): IHubspotSettingsLayoutActions => {
    return {
        
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(HubspotSettingsLayout);
