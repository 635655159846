import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Tmd.dtos';

import {
    ACTION_HUBSPOT_LOAD_BY_ID,
    ACTION_HUBSPOT_LOAD_FAILURE,
    ACTION_HUBSPOT_LOAD_SUCCESS,
    ACTION_HUBSPOT_LOAD_CONTACTS_TO_EXPORT,
    ACTION_HUBSPOT_CLEAR,
    ACTION_HUBSPOT_EXPORT_CONTACTS,
    ACTION_HUBSPOT_EXPORT_CONTACTS_FAILURE,
    ACTION_HUBSPOT_EXPORT_CONTACTS_SUCCESS,
    ACTION_HUBSPOT_FILTER,
    ACTION_HUBSPOT_FIND_CONTACT,
    ACTION_HUBSPOT_FIND_CONTACT_FAILURE,
    ACTION_HUBSPOT_FIND_CONTACT_SUCCESS,
    ACTION_HUBSPOT_SET_EMAIL_UPDATE_OPTIONS,
    HubspotAction
} from '../actions/hubspot';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState, IFormFilter } from "./index";
import { updateFailureIndexToId } from "../helpers/formErrorHelper";

export interface IHubspotState {
    formData: Dtos.Hubspot | Dtos.Hubspot[],
    existing: Dtos.EmailUsage,
    replacement: Dtos.EmailUsage,
    emailUpdateOptions: Dtos.EmailUpdateOptions,
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    validationFailures: Dtos.ResponseError[];
    lookups: Dtos.Lookup[],
    loadState: IRequestState;
    saveState: IRequestState;
    updateState: IRequestState;
    createState: IRequestState;
    formFilter: IFormFilter;
    formFilteredData: Dtos.Hubspot[];
    formLength: number;
}

const initialState: IHubspotState = {
    formData: undefined,
    existing: undefined,
    replacement: undefined,
    emailUpdateOptions: undefined,
    formState: undefined,
    formProperties: undefined,
    validationFailures: undefined,
    lookups: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    updateState: {
        status: RequestState.None
    } as IRequestState,
    createState: {
        status: RequestState.None
    } as IRequestState,
    formFilter: {
        search: "",
        showInActive: false
    },
    formFilteredData: [],
    formLength: 0
};


function loading(state: IHubspotState, isLoading: boolean): IHubspotState {
    const newState: IHubspotState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(state: IHubspotState,
    formData: Dtos.Hubspot | Dtos.Hubspot[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[],
    responseStatus: Dtos.ResponseStatus
): IHubspotState {
    const newState: IHubspotState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        loadState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return filter(newState, state.formFilter);
}

function findSuccess(state: IHubspotState,
    existing: Dtos.EmailUsage,
    replacement: Dtos.EmailUsage,
    emailUpdateOptions: Dtos.EmailUpdateOptions,
    responseStatus: Dtos.ResponseStatus
): IHubspotState {
    const newState: IHubspotState = update(state, {
        existing: {
            $set: existing
        },
        replacement: {
            $set: replacement
        },
        emailUpdateOptions: {
            $set: emailUpdateOptions
        },
        loadState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus
        }
    });

    return filter(newState, state.formFilter);
}

function setEmailUpdateOptions(state: IHubspotState,
    emailUpdateOptions: Dtos.EmailUpdateOptions
): IHubspotState {
    const newState: IHubspotState = update(state, {
        emailUpdateOptions: {
            $set: emailUpdateOptions
        }
    });

    return filter(newState, state.formFilter);
}


function findClear(state: IHubspotState
): IHubspotState {
    const newState: IHubspotState = update(state, {
        existing: {
            $set: undefined
        },
        replacement: {
            $set: undefined
        },
    });

    return filter(newState, state.formFilter);
}


function loadFailure(state: IHubspotState, responseStatus: Dtos.ResponseStatus): IHubspotState {
    const newState: IHubspotState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: IHubspotState, propertiesToClear?: string[]): IHubspotState {

    if (!propertiesToClear) {
        const newState: IHubspotState = update(initialState, {});
        return newState;
    }

    let stateModifier: any = {};

    propertiesToClear.forEach(propertyToClear => {
        stateModifier[propertyToClear] = { $set: initialState[propertyToClear] }
    });

    const newState = update(state, stateModifier);

    return newState;
}

function saving(state: IHubspotState, isSaving: boolean): IHubspotState {
    const newState: IHubspotState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function saveSuccess(
    state: IHubspotState,
    formData: Dtos.Hubspot | Dtos.Hubspot[],
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus
): IHubspotState {
    const newState: IHubspotState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        saveState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return filter(newState, state.formFilter);
}

function saveFailure(state: IHubspotState, responseStatus: Dtos.ResponseStatus): IHubspotState {
    const newState: IHubspotState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                meta: responseStatus.meta
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, state.formData) : undefined
        }
    });

    return newState;
}

function creating(state: IHubspotState, isCreating: boolean): IHubspotState {
    const newState: IHubspotState = update(state, {
        createState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function createSuccess(
    state: IHubspotState,
    formData: Dtos.Hubspot,
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[]
): IHubspotState {
    const newState: IHubspotState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        createState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function createFailure(state: IHubspotState, responseStatus: Dtos.ResponseStatus): IHubspotState {
    const newState: IHubspotState = update(state, {
        createState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function filter(state: IHubspotState, formFilter: IFormFilter): IHubspotState {

    const items: Dtos.Hubspot[] | undefined =
        state.formData instanceof Array ?
            state.formData :
            undefined;

    const formLength = items ? items.length : 0;
    const filterSearch = formFilter.search.toLowerCase();
    const formFilteredData = items ? items.filter(p =>
        (p.active || formFilter.showInActive) &&
        (formFilter.search == "" || p.search.indexOf(filterSearch) > -1
        )) : undefined;

    const newState: IHubspotState = update(state, {
        formFilter: {
            $set: formFilter
        },
        formFilteredData: {
            $set: formFilteredData
        },
        formLength: {
            $set: formLength
        }
    });

    return newState;
}

function getHubpotDataValue(item: Dtos.Hubspot, name) {
    var value = item.data.find(f => f.name == name);
    return value ? value.value : "";
}

const HubspotReducer: Reducer<IHubspotState> = (state: IHubspotState = initialState, action: HubspotAction) => {
    switch (action.type) {
        case ACTION_HUBSPOT_LOAD_CONTACTS_TO_EXPORT:
            return loading(state, true);
        case ACTION_HUBSPOT_LOAD_BY_ID:
            return loading(state, true);
        case ACTION_HUBSPOT_LOAD_SUCCESS:
            return loadSuccess(state, action.formData, action.formState, action.formProperties, action.lookups, action.responseStatus);
        case ACTION_HUBSPOT_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_HUBSPOT_CLEAR:
            return clear(state, action.propertiesToClear);

        case ACTION_HUBSPOT_EXPORT_CONTACTS:
            return saving(state, true);
        case ACTION_HUBSPOT_EXPORT_CONTACTS_FAILURE:
            return saveFailure(state, action.responseStatus);
        case ACTION_HUBSPOT_EXPORT_CONTACTS_SUCCESS:
            return saveSuccess(state, action.formData, null, action.responseStatus)

        case ACTION_HUBSPOT_FILTER:
            return filter(state, action.formFilter);
        case ACTION_HUBSPOT_FIND_CONTACT:
            return loading(findClear(state), true);
        case ACTION_HUBSPOT_FIND_CONTACT_SUCCESS:
            return findSuccess(state, action.existing, action.replacement, action.emailUpdateOptions, action.responseStatus);
        case ACTION_HUBSPOT_FIND_CONTACT_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_HUBSPOT_SET_EMAIL_UPDATE_OPTIONS:
            return setEmailUpdateOptions(state, action.emailUpdateOptions)
    }

    return state;
}

export default HubspotReducer;