import * as React from 'react';
import './TemplateTrialItemsPage.scss';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { TruncatedCell } from "../../../components/layouts/index";
import ReactTable, { Column, RowInfo } from 'react-table';
import * as TemplateTrialItemActions from '../../../actions/templateTrialItems';
import * as PagingActions from '../../../actions/pages/pagingPage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { FormFilterInput } from '../../../components/form';
import { ItemSettingsLayout } from '../../index';
import { TogglePageItem } from '../../../reducers/pages/togglePage';
import * as ToggleActions from '../../../actions/pages/togglePage';
import * as ToggleHelper from "../../../helpers/toggleHelper";

interface ITemplateTrialItemsPageProps {

    location: Location;
    history: History;

    // From state
    templateTrialItems: Dtos.TemplateTrialItemCategory[];
    formFilter: IFormFilter;

    loadingTemplateTrialItems: boolean;
    loadTemplateTrialItemsSuccess: boolean;
    loadTemplateTrialItemsFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    formRoles?: Dtos.RoleType[];

    toggleItems: TogglePageItem[];
    pageSize: number;
}

interface ITemplateTrialItemsPageActions {
    clearTemplateTrialItems: TemplateTrialItemActions.ITemplateTrialItemClearActionCreator;
    loadTemplateTrialItems: TemplateTrialItemActions.ITemplateTrialItemLoadByCategoryActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    filter: TemplateTrialItemActions.ITemplateTrialItemFilterActionCreator;

    setPageSize: PagingActions.IPagingPageSizeChangedStateActionCreator;

    toggleExpand: ToggleActions.IToggleExpandedStateActionCreator;
}

type TemplateTrialItemsPageProps = ITemplateTrialItemsPageProps & ITemplateTrialItemsPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class TemplateTrialItemsPage extends React.PureComponent<TemplateTrialItemsPageProps, any> {

    constructor(props: TemplateTrialItemsPageProps) {
        super(props);

        this.clearTemplateTrialItems = this.clearTemplateTrialItems.bind(this);
    }

    componentDidMount() {
        const {
            loadTemplateTrialItems
        } = this.props;

        loadTemplateTrialItems();
    }

    componentWillReceiveProps(nextProps: TemplateTrialItemsPageProps) {
    }

    componentDidUpdate(prevProps: TemplateTrialItemsPageProps, prevState) {
        const {
            navigateReplace
        } = this.props
    }

    componentWillUpdate(nextProps: TemplateTrialItemsPageProps) {
    }

    componentWillUnmount() {
        this.clearTemplateTrialItems();
    }

    clearTemplateTrialItems() {
        this.props.clearTemplateTrialItems();
    }

    showTemplateTrialItems(): boolean {
        const {
            loadingTemplateTrialItems,
            loadTemplateTrialItemsSuccess
        } = this.props

        if (!loadingTemplateTrialItems && loadTemplateTrialItemsSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingTemplateTrialItems
        } = this.props;

        return <ItemSettingsLayout loading={loadingTemplateTrialItems} permission={Dtos.Permission.TemplateTrialItemsView}>
            {this.renderContent()}
        </ItemSettingsLayout>;
    }

    renderContent() {
        const {
            permissions
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-6 mb-2"}>
                    <h2>Trial Item Templates</h2>
                </div>
                <div className="col-6" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} />
                    {AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TemplateTrialItemsCreate) && (
                        <Link className={"btn btn-primary"} url={"/settings/item/trialitemtemplates/create"}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.showTemplateTrialItems() ?
                    "Loading Template Trial Items" :
                    null
            }
            {
                this.showTemplateTrialItems() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTableByCategory()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTableByCategory() {

        const {
            templateTrialItems,
            toggleItems,
            toggleExpand,
            pageSize,
            setPageSize
        } = this.props

        if (!templateTrialItems || templateTrialItems.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no template trial items to view.</span>
            </div>
        }

        const showPagination = false;

        return <div>
            {templateTrialItems.map((item, index) => {
                const key = "trial-item-instances-page-" + item.categoryId;
                const expanded = ToggleHelper.isToggleExpanded(toggleItems, key);
                return (
                    <div key={"trial-item-instances-category" + item.category.id}>
                        <div className={tableHeaderClass} style={tableStyleClass} onClick={() => toggleExpand(key)}>
                            <div>
                                <FontAwesomeIcon icon={!expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" />
                            </div>
                            <span>{item.category.value}</span>
                        </div>
                        {expanded && (
                            <ReactTable
                                data={item.templateTrialItems}
                                pageSizeOptions={PAGE_SIZE_OPTIONS}
                                defaultPageSize={item.templateTrialItems.length}
                                pageSize={showPagination ? undefined : item.templateTrialItems.length}
                                showPagination={showPagination}
                                className="-striped -highlight -clickable mb-3"
                                noDataText="There are currently no template trial items for this category"
                                resizable={false}
                                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                                    return {
                                        onClick: (e, handleOriginal) => {

                                            const url: string = "/settings/item/trialitemtemplates/" + rowInfo.original.id;
                                            this.props.navigate(url);

                                            if (handleOriginal) {
                                                handleOriginal()
                                            }

                                        },
                                        style: {
                                            color: (rowInfo && rowInfo.original && rowInfo.original.active) ? '#212529' : '#9e9e9e'
                                        }
                                    }
                                }}
                                columns={
                                    [
                                        {
                                            id: "id",
                                            Header: ' ',
                                            accessor: "id",
                                            className: "d-flex align-items-center justify-content-center",
                                            maxWidth: 50,
                                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.LOOKUP} fixedWidth />
                                        },
                                        {
                                            id: "name",
                                            Header: 'Name',
                                            accessor: 'name',
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            Cell: (props) => <TruncatedCell value={props.value} />
                                        },
                                        {
                                            id: "description",
                                            Header: "Description",
                                            accessor: "description",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            Cell: (props) => <TruncatedCell value={props.value} />
                                        }
                                    ]
                                }
                            />
                        )}
                    </div>
                )
            }
            )}
        </div>

    }

    createTable() {

        const {
            templateTrialItems,
            pageSize,
            setPageSize
        } = this.props

        if (!templateTrialItems || templateTrialItems.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no template trial items to view.</span>
            </div>
        }

        const showPagination = templateTrialItems.length > pageSize;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={templateTrialItems}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? pageSize : templateTrialItems.length}
                pageSize={showPagination ? pageSize : templateTrialItems.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no template trial items to display"
                resizable={false}
                onPageSizeChange={val => setPageSize(val)}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {

                            const url: string = "/settings/item/trialitemtemplates/" + rowInfo.original.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && !rowInfo.original.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.LOOKUP} fixedWidth />
                    },
                    {
                        id: "name",
                        Header: 'Name',
                        accessor: 'name',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value} />
                    },
                    {
                        id: "description",
                        Header: "Description",
                        accessor: "description",
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value} />
                    },
                    {
                        id: "category",
                        Header: 'Category',
                        accessor: 'category.value',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    }
                ]}
            />
        </div>

    }

}

const mapStateToProps = (state: ITmdState, ownProps: TemplateTrialItemsPageProps): ITemplateTrialItemsPageProps => {

    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        history: ownProps.history,
        location: state.routing.location,

        templateTrialItems: state.templateTrialItems.formFilteredData!,
        formFilter: state.templateTrialItems.formFilter,

        loadingTemplateTrialItems: state.templateTrialItems.loadState && state.templateTrialItems.loadState.status == RequestState.Pending,
        loadTemplateTrialItemsSuccess: state.templateTrialItems.loadState && state.templateTrialItems.loadState.status == RequestState.Success,
        loadTemplateTrialItemsFailure: state.templateTrialItems.loadState && state.templateTrialItems.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles,

        toggleItems: state.togglePage.items,
        pageSize: state.pagingPage.pageSize
    };
};

const mapDispatchToProps = (dispatch): ITemplateTrialItemsPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadTemplateTrialItems: bindActionCreators(TemplateTrialItemActions.LoadTemplateTrialItemByCategory, dispatch),
        clearTemplateTrialItems: bindActionCreators(TemplateTrialItemActions.Clear, dispatch),

        filter: bindActionCreators(TemplateTrialItemActions.Filter, dispatch),

        setPageSize: bindActionCreators(PagingActions.PageSizeChanged, dispatch),

        toggleExpand: bindActionCreators(ToggleActions.ToggleExpanded, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TemplateTrialItemsPage);
