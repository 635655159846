import * as React from 'react';
import './PersonnelOCRolesPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu, TruncatedCell } from "../../../components/layouts/index";
import { IPersonnelReduxFormState, initialPersonnelState } from "../../../reducers/reactReduxForms/personnel";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as PersonnelActions from '../../../actions/personnel';
import * as ToggleActions from '../../../actions/pages/togglePage';
import { TogglePageItem } from '../../../reducers/pages/togglePage';
import * as OpenClinicaUserRoleActions from '../../../actions/openClinicaUserRoles';
import * as PagingActions from '../../../actions/pages/pagingPage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { Form, actions, Control } from 'react-redux-form';
import { PersonnelSubMenu } from '../../../helpers/subMenuHelper';

interface IPersonnelOCRolesPageParams {
    personnelId: number;
}

interface IPersonnelOCRolesPageProps {

    form: Dtos.Personnel & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IPersonnelReduxFormState;

    location: Location;
    personnelId: number;
    match: match<IPersonnelOCRolesPageParams>;
    history: History;

    // From state
    personnel: Dtos.Personnel;
    openClinicaUserRoles: Dtos.OpenClinicaUserRole[];
    lookups: Dtos.Lookup[];

    loadingPersonnel: boolean;
    loadPersonneluccess: boolean;
    loadPersonnelFailure: boolean;

    savingPersonnel: boolean;
    savePersonneluccess: boolean;
    savePersonnelFailure: boolean;

    creatingPersonnel: boolean;
    createPersonneluccess: boolean;
    createPersonnelFailure: boolean;

    rebuilding: boolean;
    rebuildSuccess: boolean;
    rebuildFailure: boolean;

    resending: boolean;
    resendSuccess: boolean;
    resendFailure: boolean;

    saving: boolean;
    saveSuccess: boolean;
    saveFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];
    formRoles: Dtos.RoleType[],

    toggleItems: TogglePageItem[],

    pageSize: number
}

interface IPersonnelOCRolesPageActions {
    clearPersonnel: PersonnelActions.IPersonnelClearActionCreator;
    loadPersonnel: PersonnelActions.IPersonnelLoadByIdActionCreator;

    savePersonnel: PersonnelActions.IPersonnelaveActionCreator;
    createPersonnel: PersonnelActions.IPersonnelCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    toggleExpand: ToggleActions.IToggleExpandedStateActionCreator;

    changeStatus: OpenClinicaUserRoleActions.IOpenClinicaUserRolesChangeStatusActionCreator;
    rebuild: OpenClinicaUserRoleActions.IOpenClinicaUserRolesRebuildActionCreator;
    resend: OpenClinicaUserRoleActions.IOpenClinicaUserRolesResendEmailActionCreator;
    setPageSize: PagingActions.IPagingPageSizeChangedStateActionCreator;
}

type PersonnelOCRolesPageProps = IPersonnelOCRolesPageProps & IPersonnelOCRolesPageActions;


const formName: string = "personnel";
const reduxFormName: string = "reduxForms.personnel";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class PersonnelOCRolesPage extends React.PureComponent<PersonnelOCRolesPageProps, any> {

    constructor(props: PersonnelOCRolesPageProps) {
        super(props);

        this.clearPersonnel = this.clearPersonnel.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadPersonnel,
            navigate,
            navigateReplace,
            personnelId,
            createPersonnel
        } = this.props;

        if (personnelId) {
            loadPersonnel(personnelId);
        } else if (!personnelId) {
            createPersonnel();
        }
    }

    componentWillReceiveProps(nextProps: PersonnelOCRolesPageProps) {
    }

    componentDidUpdate(prevProps: PersonnelOCRolesPageProps, prevState) {
        const {
            navigateReplace,
            personnel,
            personnelId,
            form,
            navigate,
            loadPersonnel,
            loadingPersonnel,
            loadPersonneluccess,
            createPersonnel,
            creatingPersonnel,
            createPersonneluccess,
            savingPersonnel,
            savePersonneluccess,
            savePersonnelFailure,
            validationFailures,
            rebuilding,
            rebuildSuccess,
            rebuildFailure,
            saving,
            saveSuccess,
            saveFailure,
            resending,
            resendSuccess,
            resendFailure
        } = this.props;

        if (personnelId && prevProps.personnelId != personnelId) {
            loadPersonnel(personnelId);
        }
        else if (!personnelId && prevProps.personnelId) {
            createPersonnel();
        }

        if (!loadingPersonnel && prevProps.loadingPersonnel) {
            if (loadPersonneluccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingPersonnel && prevProps.savingPersonnel) {
            if (savePersonneluccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/personnel")
                } else if (!form.saveAndReturn && personnelId && personnelId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !personnelId && personnel && personnel.id > 0) {
                    navigate("/personnel/" + personnel.id);
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingPersonnel && prevProps.creatingPersonnel) {
            if (createPersonneluccess) {
                this.loadForm();
            }
            else {

            }
        }

        if (!rebuilding && prevProps.rebuilding) {
            if (rebuildSuccess) {
                Alert.success(<NotificationAlert
                    alertContent={<div>OpenClincia User Roles Refreshed</div>}
                    icon={FontAwesomeIcons.Solid.CHECK}
                />);
                loadPersonnel(personnel.id);
            }
            if (rebuildFailure) {
                Alert.error(
                    <NotificationAlert
                        alertContent={<div>The form was <strong> not </strong> saved.</div>}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

        if (!saving && prevProps.saving) {
            if (saveSuccess) {
                Alert.success(<NotificationAlert
                    alertContent={<div>OpenClincia User Role Saved</div>}
                    icon={FontAwesomeIcons.Solid.CHECK}
                />);
                loadPersonnel(personnel.id);
            }
            if (saveFailure) {
                Alert.error(
                    <NotificationAlert
                        alertContent={<div>The form was <strong> not </strong> saved.</div>}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

        if (!resending && prevProps.resending) {
            if (resendSuccess) {
                Alert.success(<NotificationAlert
                    alertContent={<div>OpenClincia role assigned email sent</div>}
                    icon={FontAwesomeIcons.Solid.CHECK}
                />);
            }
            if (resendFailure) {
                Alert.error(
                    <NotificationAlert
                        alertContent={<div>The OpenClinca role assigned was <strong> not </strong> sent</div>}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }
    }

    componentWillUpdate(nextProps: PersonnelOCRolesPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearPersonnel();
        loadForm(reduxFormName, initialPersonnelState);
        resetForm(reduxFormName);
    }

    clearPersonnel() {
        this.props.clearPersonnel();
    }

    showPersonnel(): boolean {
        const {
            loadingPersonnel,
            loadPersonneluccess
        } = this.props

        if (!loadingPersonnel && loadPersonneluccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            form,
            loadingPersonnel,
            personnelId,
            personnel,
            formRoles,
            permissions
        } = this.props;

        return <RestrictedLayout
            permission={Dtos.Permission.PersonnelOpenClinicaView}
            subMenuTitle={personnel ? personnel.display : ""}
            subMenuItems={personnelId == undefined ? undefined : PersonnelSubMenu(personnelId, permissions)}
            loading={loadingPersonnel}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            personnel,
            loadingPersonnel,
            formState,
            permissions,
            rebuilding,
            rebuild,
            savingPersonnel,
            saving
        } = this.props;

        const canEdit: boolean = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.PersonnelOpenClinicaUpdate);
        const formDisabled: boolean = !canEdit || savingPersonnel || saving || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        return <div>
            <div className="row">
                <div className={"col-9 mb-2"}>
                    <h2>Personnel Roles</h2>
                </div>
                <div className="col-3" style={{ textAlign: 'right' }}>
                    <button disabled={loadingPersonnel || rebuilding || formDisabled}
                        onClick={
                            () => {
                                rebuild([personnel.id]);
                            }
                        }
                        type="button"
                        className="btn btn-warning"
                    >
                        Run
                    </button>
                </div>
            </div>
            {
                loadingPersonnel ?
                    "Loading Personnel" :
                    null
            }
            {
                !loadingPersonnel ?
                    <div className="mt-2">
                        {this.renderTable()}
                    </div> :
                    null
            }
        </div>

    }

    toggle(personnelRoleSummary: Dtos.PersonnelRoleSummary) {

    }


    renderTable() {

        const {
            openClinicaUserRoles,
            permissions,
            pageSize,
            setPageSize,
            savingPersonnel,
            changeStatus,
            resend,
            resending
        } = this.props

        if (!openClinicaUserRoles || openClinicaUserRoles.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no OpenClinica user roles to view.</span>
            </div>
        }

        const canEdit: boolean = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.PersonnelOpenClinicaUpdate);
        const showPagination = openClinicaUserRoles.length > pageSize;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={openClinicaUserRoles}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? pageSize : openClinicaUserRoles.length}
                pageSize={showPagination ? pageSize : openClinicaUserRoles.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no item components to display"
                resizable={false}
                onPageSizeChange={val => setPageSize(val)}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log('rowInfo', rowInfo);
                            //const url: string = "/settings/itemcomponents/" + rowInfo.original.id;
                            //this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.errorMessage == "") ? '#212529' : 'red'
                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.ROLE} fixedWidth />
                    },
                    {
                        id: "trial",
                        Header: 'Trial',
                        accessor: 'trial',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value ? props.value.display : ""} />,
                        width: 120
                    },
                    {
                        id: "institution",
                        Header: 'Institution',
                        accessor: 'institution',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value ? props.value.display : ""} />
                    },
                    {
                        id: "role",
                        Header: 'OC Role',
                        accessor: 'role',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value ? props.value : ""} />
                    },
                    {
                        id: "studyIdentifier",
                        Header: 'Study',
                        accessor: 'studyIdentifier',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value ? props.value : ""} />,
                        width: 120
                    },
                    {
                        id: "status",
                        Header: 'Status',
                        accessor: 'status',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value ? props.value : ""} />,
                        width: 140
                    },
                    {
                        id: "errorMessage",
                        Header: 'Error',
                        accessor: 'errorMessage',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value ? props.value : ""} />
                    },
                    {
                        id: "id",
                        Header: '',
                        accessor: 'id',
                        className: "d-flex align-items-right justify-content-right",
                        headerClassName: "text-left",
                        Cell: (props) => {
                            return <>
                                {props.original.active && (
                                    <button disabled={savingPersonnel || !canEdit}
                                        onClick={
                                            () => {
                                                changeStatus(props.original.id, Dtos.OCSyncStatus.Pending);
                                            }
                                        }
                                        type="button"
                                        className="btn btn-outline-primary btn-sm mr-2"
                                    >
                                        Re-process
                                    </button>
                                )}
                                {props.original.active && (
                                    <button disabled={resending}
                                        onClick={
                                            () => {
                                                resend(props.original.id);
                                            }
                                        }
                                        type="button"
                                        className="btn btn-outline-primary btn-sm mr-2"
                                    >
                                        <FontAwesomeIcon data-tip={"Resend OpenClinca Role assigned email"} icon={FontAwesomeIcons.Light.EMAIL} fixedWidth /><ReactTooltip />
                                    </button>
                                )}
                                {props.original.active && (
                                    <button disabled={savingPersonnel || !canEdit}
                                        onClick={
                                            () => {
                                                changeStatus(props.original.id, Dtos.OCSyncStatus.Removed);
                                            }
                                        }
                                        type="button"
                                        className="btn btn-outline-danger btn-sm mr-2"
                                    >
                                        <FontAwesomeIcon icon={FontAwesomeIcons.Light.TRASH} fixedWidth />
                                    </button>
                                )}
                            </>
                        },
                        width: 140
                    }
                ]}
            />
        </div>

    }

    renderGeneralRoles(personnelRoleSummaries: Dtos.PersonnelRoleSummary[]) {
        const showPagination = false; const {
            personnel
        } = this.props;

        return (
            <ReactTable
                data={personnelRoleSummaries}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : personnelRoleSummaries.length}
                pageSize={showPagination ? undefined : personnelRoleSummaries.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no roles for this type"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log(rowInfo);
                            const url: string = "/personnel/" + personnel.id + '/roles/' + rowInfo.original.personnelRole.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.personnelRole.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={
                    [
                        {
                            id: "id",
                            Header: ' ',
                            accessor: "id",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 50,
                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.ROLE} fixedWidth />
                        },
                        {
                            id: "role",
                            Header: "Role",
                            accessor: "personnelRole.roleDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "dateStarted",
                            Header: "Date Started",
                            accessor: "personnelRole.dateStarted",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        },
                        {
                            id: "dateCeased",
                            Header: "Date Ceased",
                            accessor: "personnelRole.dateCeased",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        }
                    ]
                }
            />
        );
    }

    renderGroupRoles(personnelRoleSummaries: Dtos.PersonnelRoleSummary[]) {
        const showPagination = false; const {
            personnel
        } = this.props;

        return (
            <ReactTable
                data={personnelRoleSummaries}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : personnelRoleSummaries.length}
                pageSize={showPagination ? undefined : personnelRoleSummaries.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no roles for this type"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log(rowInfo);
                            const url: string = "/personnel/" + personnel.id + '/roles/' + rowInfo.original.personnelRole.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.personnelRole.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={
                    [
                        {
                            id: "id",
                            Header: ' ',
                            accessor: "id",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 50,
                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.ROLE} fixedWidth />
                        },
                        {
                            id: "group",
                            Header: "Group",
                            accessor: "personnelRole.groupDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "role",
                            Header: "Role",
                            accessor: "personnelRole.roleDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "dateStarted",
                            Header: "Date Started",
                            accessor: "personnelRole.dateStarted",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        },
                        {
                            id: "dateCeased",
                            Header: "Date Ceased",
                            accessor: "personnelRole.dateCeased",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        }
                    ]
                }
            />
        );
    }

    renderInstitutionRoles(personnelRoleSummaries: Dtos.PersonnelRoleSummary[]) {
        const showPagination = false; const {
            personnel
        } = this.props;

        return (
            <ReactTable
                data={personnelRoleSummaries}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : personnelRoleSummaries.length}
                pageSize={showPagination ? undefined : personnelRoleSummaries.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no roles for this type"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log(rowInfo);
                            const url: string = "/personnel/" + personnel.id + '/roles/' + rowInfo.original.personnelRole.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.personnelRole.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={
                    [
                        {
                            id: "id",
                            Header: ' ',
                            accessor: "id",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 50,
                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.ROLE} fixedWidth />
                        },
                        {
                            id: "institution",
                            Header: "Institution",
                            accessor: "personnelRole.institutionDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "role",
                            Header: "Role",
                            accessor: "personnelRole.roleDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "dateStarted",
                            Header: "Date Started",
                            accessor: "personnelRole.dateStarted",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        },
                        {
                            id: "dateCeased",
                            Header: "Date Ceased",
                            accessor: "personnelRole.dateCeased",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        }
                    ]
                }
            />
        );
    }

    renderTrialRoles(personnelRoleSummaries: Dtos.PersonnelRoleSummary[]) {
        const showPagination = false; const {
            personnel
        } = this.props;

        return (
            <ReactTable
                data={personnelRoleSummaries}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : personnelRoleSummaries.length}
                pageSize={showPagination ? undefined : personnelRoleSummaries.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no roles for this type"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log(rowInfo);
                            const url: string = "/personnel/" + personnel.id + '/roles/' + rowInfo.original.personnelRole.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.personnelRole.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={
                    [
                        {
                            id: "id",
                            Header: ' ',
                            accessor: "id",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 50,
                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.ROLE} fixedWidth />
                        },
                        {
                            id: "role",
                            Header: "Role",
                            accessor: "personnelRole.roleDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "dateStarted",
                            Header: "Date Started",
                            accessor: "personnelRole.dateStarted",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        },
                        {
                            id: "dateCeased",
                            Header: "Date Ceased",
                            accessor: "personnelRole.dateCeased",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        }
                    ]
                }
            />
        );
    }

    renderTrialInstitutionRoles(personnelRoleSummaries: Dtos.PersonnelRoleSummary[]) {
        const showPagination = false; const {
            personnel
        } = this.props;

        return (
            <ReactTable
                data={personnelRoleSummaries}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : personnelRoleSummaries.length}
                pageSize={showPagination ? undefined : personnelRoleSummaries.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no roles for this type"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log(rowInfo);
                            const url: string = "/personnel/" + personnel.id + '/roles/' + rowInfo.original.personnelRole.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.personnelRole.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={
                    [
                        {
                            id: "id",
                            Header: ' ',
                            accessor: "id",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 50,
                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.ROLE} fixedWidth />
                        },
                        {
                            id: "trial",
                            Header: "Trial",
                            accessor: "personnelRole.trialDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "institution",
                            Header: "Institution",
                            accessor: "personnelRole.institutionDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "role",
                            Header: "Role",
                            accessor: "personnelRole.roleDisplay",
                            className: "d-flex align-items-center justify-content-center",
                        },
                        {
                            id: "dateStarted",
                            Header: "Date Started",
                            accessor: "personnelRole.dateStarted",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        },
                        {
                            id: "dateCeased",
                            Header: "Date Ceased",
                            accessor: "personnelRole.dateCeased",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        }
                    ]
                }
            />
        );
    }



    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.Personnel) {
        const {
            savePersonnel
        } = this.props;

        if (data) {
            let changes: Dtos.Personnel = {} as Dtos.Personnel;
            savePersonnel(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.Personnel) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }


    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/personnel");
    }

    loadForm() {
        const {
            loadForm,
            personnel,
        } = this.props

        let extraData: Dtos.Personnel = {} as Dtos.Personnel;

        loadForm(reduxFormName, Object.assign({ ...personnel }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: PersonnelOCRolesPageProps): IPersonnelOCRolesPageProps => {

    let personnel: Dtos.Personnel | undefined = !(state.personnel.formData instanceof Array) ? state.personnel.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        personnelId: ownProps.match && ownProps.match.params.personnelId.toString() != "create" ? ownProps.match.params.personnelId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        personnel: personnel!,
        openClinicaUserRoles: personnel ? personnel.openClinicaUserRoles : undefined,

        loadingPersonnel: state.personnel.loadState && state.personnel.loadState.status == RequestState.Pending,
        loadPersonneluccess: state.personnel.loadState && state.personnel.loadState.status == RequestState.Success,
        loadPersonnelFailure: state.personnel.loadState && state.personnel.loadState.status == RequestState.Failure,

        savingPersonnel: state.personnel.saveState && state.personnel.saveState.status == RequestState.Pending,
        savePersonneluccess: state.personnel.saveState && state.personnel.saveState.status == RequestState.Success,
        savePersonnelFailure: state.personnel.saveState && state.personnel.saveState.status == RequestState.Failure,

        creatingPersonnel: state.personnel.createState && state.personnel.createState.status == RequestState.Pending,
        createPersonneluccess: state.personnel.createState && state.personnel.createState.status == RequestState.Success,
        createPersonnelFailure: state.personnel.createState && state.personnel.createState.status == RequestState.Failure,

        rebuilding: state.openClinicaUserRole.runningState && state.openClinicaUserRole.runningState.status == RequestState.Pending,
        rebuildSuccess: state.openClinicaUserRole.runningState && state.openClinicaUserRole.runningState.status == RequestState.Success,
        rebuildFailure: state.openClinicaUserRole.runningState && state.openClinicaUserRole.runningState.status == RequestState.Failure,

        resending: state.openClinicaUserRole.resendingState && state.openClinicaUserRole.resendingState.status == RequestState.Pending,
        resendSuccess: state.openClinicaUserRole.resendingState && state.openClinicaUserRole.resendingState.status == RequestState.Success,
        resendFailure: state.openClinicaUserRole.resendingState && state.openClinicaUserRole.resendingState.status == RequestState.Failure,

        saving: state.openClinicaUserRole.saveState && state.openClinicaUserRole.saveState.status == RequestState.Pending,
        saveSuccess: state.openClinicaUserRole.saveState && state.openClinicaUserRole.saveState.status == RequestState.Success,
        saveFailure: state.openClinicaUserRole.saveState && state.openClinicaUserRole.saveState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles,
        lookups: [],

        form: state.reduxForms.personnel,
        formState: state.personnel.formState,
        formProperties: state.personnel.formProperties,

        reduxFormState: state.reduxForms.formState.personnel,
        validationFailures: personnel ?
            state.personnel.validationFailures :
            undefined,

        toggleItems: state.togglePage.items,

        pageSize: state.pagingPage.pageSize
    };
};

const mapDispatchToProps = (dispatch): IPersonnelOCRolesPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadPersonnel: bindActionCreators(PersonnelActions.LoadPersonnelById, dispatch),
        clearPersonnel: bindActionCreators(PersonnelActions.Clear, dispatch),

        savePersonnel: bindActionCreators(PersonnelActions.SavePersonnel, dispatch),
        createPersonnel: bindActionCreators(PersonnelActions.CreatePersonnel, dispatch),

        toggleExpand: bindActionCreators(ToggleActions.ToggleExpanded, dispatch),

        changeStatus: bindActionCreators(OpenClinicaUserRoleActions.ChangeStatus, dispatch),
        rebuild: bindActionCreators(OpenClinicaUserRoleActions.Rebuild, dispatch),
        resend: bindActionCreators(OpenClinicaUserRoleActions.ResendEmail, dispatch),

        setPageSize: bindActionCreators(PagingActions.PageSizeChanged, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(PersonnelOCRolesPage);
