import { Reducer } from 'redux';
import { update } from '../../helpers/immutabilityHelper'
import * as Dtos from '../../dtos/Tmd.dtos';

import {
    ACTION_TRIALGROUPITEMS_PAGE_SET_EDIT_MODAL_VIEW_STATE,
    ACTION_TRIALGROUPITEMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE
} from '../../actions/pages/trialGroupItemsPage';


export interface ITrailGroupItemsPageState {
    editModalOpen: boolean;
    collectionInstanceId: number;
    name: string;
    createVersionModalOpen: boolean;
    trialGroupId: number;
    groupItemId: number;
}

const initialState: ITrailGroupItemsPageState = {
    editModalOpen: false,
    collectionInstanceId: null,
    name: "",
    createVersionModalOpen: false,
    trialGroupId: null,
    groupItemId: null
};


function setEditModalViewState(state: ITrailGroupItemsPageState, open: boolean, collectionInstanceId: number, name: string): ITrailGroupItemsPageState {
    const newState: ITrailGroupItemsPageState = update(state, {
        editModalOpen: {
            $set: open
        },
        collectionInstanceId: {
            $set: collectionInstanceId
        },
        name: {
            $set: name
        }
    });
    return newState;
}

function setCreateVersionModalViewState(state: ITrailGroupItemsPageState, open: boolean, trialGroupId: number, groupItemId: number): ITrailGroupItemsPageState {
    const newState: ITrailGroupItemsPageState = update(state, {
        createVersionModalOpen: {
            $set: open
        },
        trialGroupId: {
            $set: trialGroupId
        },
        groupItemId: {
            $set: groupItemId
        }
    });
    return newState;
}

const TrailGroupItemsPageReducer: Reducer<ITrailGroupItemsPageState> = (state: ITrailGroupItemsPageState = initialState, action) => { 
    switch (action.type) {
        case ACTION_TRIALGROUPITEMS_PAGE_SET_EDIT_MODAL_VIEW_STATE:
            return setEditModalViewState(state, action.open, action.collectionInstanceId, action.name);
        case ACTION_TRIALGROUPITEMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE:
            return setCreateVersionModalViewState(state, action.open, action.trialGroupId, action.groupItemId);
    }

    return state;
}

export default TrailGroupItemsPageReducer;