import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_FORMTEMPLATES_LOAD,
    ACTION_FORMTEMPLATES_LOAD_BY_ID,

    ACTION_FORMTEMPLATES_SAVE,

    ACTION_FORMTEMPLATES_CREATE,

    IFormTemplateLoadAction,
    IFormTemplateLoadByIdAction,
    IFormTemplateLoadFailureAction,
    IFormTemplateLoadSuccessAction,

    IFormTemplateSaveAction,
    IFormTemplateSaveSuccessAction,
    IFormTemplateSaveFailureAction,

    IFormTemplateCreateAction,
    IFormTemplateCreateSuccessAction,
    IFormTemplateCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/formTemplates";
import { formTemplatesApi } from "../services/formTemplate";


export const
    getFormTemplatesEpic = (action$): Observable<IFormTemplateLoadSuccessAction | IFormTemplateLoadFailureAction> => {
        return action$
            .ofType(ACTION_FORMTEMPLATES_LOAD)
            .mergeMap((action: IFormTemplateLoadAction) => {
                return formTemplatesApi
                    .getFormTemplates()
                    .map(response =>
                        LoadSuccess(response.formTemplates, undefined, undefined, [], response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getFormTemplateByIdEpic = (action$): Observable<IFormTemplateLoadSuccessAction | IFormTemplateLoadFailureAction> => {
        return action$
            .ofType(ACTION_FORMTEMPLATES_LOAD_BY_ID)
            .mergeMap((action: IFormTemplateLoadByIdAction) => {
                return formTemplatesApi
                    .getFormTemplateById(action.id)
                    .map(response =>
                        LoadSuccess(response.formTemplate, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setFormTemplateEpic = (action$): Observable<IFormTemplateSaveSuccessAction | IFormTemplateSaveFailureAction> => {
        return action$
            .ofType(ACTION_FORMTEMPLATES_SAVE)
            .mergeMap((action: IFormTemplateSaveAction) => {
                return formTemplatesApi
                    .setFormTemplate(action.formData, action.collectionFormData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.formTemplate, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createFormTemplateEpic = (action$): Observable<IFormTemplateCreateSuccessAction | IFormTemplateCreateFailureAction> => {
        return action$
            .ofType(ACTION_FORMTEMPLATES_CREATE)
            .mergeMap((action: IFormTemplateCreateAction) => {
                return formTemplatesApi
                    .createFormTemplate()
                    .map(response =>
                        CreateSuccess(
                            response.formTemplate,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



