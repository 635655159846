import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const tumourgroupsApi = {
    getTumourGroups: (): Observable<Dtos.GetTumourGroupsResponse> => {
        //Create the request.
        let request: Dtos.GetTumourGroups = new Dtos.GetTumourGroups();

        //Send request.
        const response: Promise<Dtos.GetTumourGroupsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTumourGroupById: (id: number): Observable<Dtos.GetTumourGroupByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTumourGroupById = new Dtos.GetTumourGroupById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTumourGroupByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTumourGroup: (tumourGroup: Dtos.TumourGroup): Observable<Dtos.SetTumourGroupResponse> => {

        let request: Dtos.SetTumourGroup = new Dtos.SetTumourGroup();

        request.tumourGroup = tumourGroup;

        // send request
        const response: Promise<Dtos.SetTumourGroupResponse> = client.post(request);

        return Observable.from(response);

    },
    createTumourGroup: (): Observable<Dtos.CreateTumourGroupResponse> => {
        //Create the request.
        let request: Dtos.CreateTumourGroup = new Dtos.CreateTumourGroup();

        //Send request.
        const response: Promise<Dtos.CreateTumourGroupResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}