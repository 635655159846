import * as React from 'react';
import './TrialItemsPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import { TruncatedCell } from "../../../components/layouts/index";
import * as TrialActions from '../../../actions/trial';
import * as ToggleActions from '../../../actions/pages/togglePage';
import * as ToggleHelper from "../../../helpers/toggleHelper";
import * as TrialItemActions from '../../../actions/trialItem';
import { TogglePageItem } from '../../../reducers/pages/togglePage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { TrialLayout } from '../../index';
import { FormFilterInput } from '../../../components/form';

interface ITrialItemsPageParams {
    trialId: number;
}

interface ITrialItemsPageProps {

    location: Location;
    trialId: number;
    match: match<ITrialItemsPageParams>;
    history: History;

    // From state
    trial: Dtos.Trial;
    trialRoles: Dtos.RoleType[];
    trialItemsByCategory: Dtos.TrialItemCategory[];
    formFilter: IFormFilter;
    loadingTrial: boolean;
    loadTrialSuccess: boolean;
    loadTrialFailure: boolean;

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];
    toggleItems: TogglePageItem[]
}

interface ITrialItemsPageActions {
    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    filter: TrialItemActions.ITrialItemFilterActionCreator;

    toggleExpand: ToggleActions.IToggleExpandedStateActionCreator;
}

type TrialItemsPageProps = ITrialItemsPageProps & ITrialItemsPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class TrialItemsPage extends React.PureComponent<TrialItemsPageProps, any> {

    constructor(props: TrialItemsPageProps) {
        super(props);

        this.clearTrial = this.clearTrial.bind(this);
    }

    componentDidMount() {
        const {
            loadTrial,
            navigate,
            navigateReplace,
            trialId
        } = this.props;

        loadTrial(trialId);
    }

    componentWillReceiveProps(nextProps: TrialItemsPageProps) {
    }

    componentDidUpdate(prevProps: TrialItemsPageProps, prevState) {
        const {
            navigateReplace,
            loadTrial
        } = this.props
    }

    componentWillUpdate(nextProps: TrialItemsPageProps) {
    }

    componentWillUnmount() {
        this.clearTrial();
    }

    clearTrial() {
        this.props.clearTrial();
    }

    showTrial(): boolean {
        const {
            loadingTrial,
            loadTrialSuccess
        } = this.props

        if (!loadingTrial && loadTrialSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrial,
            loadTrialSuccess
        } = this.props

        return (
            <TrialLayout loading={loadingTrial} permission={Dtos.Permission.TrialInstitutionItemInstanceView}>{this.renderContent()}</TrialLayout>
        );
    }

    renderContent() {
        const {
            trial,
            trialId,
            loadingTrial,
            loadTrialSuccess,
            loadTrialFailure,
            clearTrial,
            navigate,
            permissions,
            trialRoles
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col mb-2"}>
                    <h2>Institution Item Templates</h2>
                </div>
                <div className="col-auto" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} />
                    {(trialId && trialId > 0 && AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialInstitutionItemInstanceExport, trialId)) &&
                        <a className="btn btn-info mr-2"
                            href={encodeURI("/api/trialinstitutionitem/export/" + trialId + "/0")}
                        >
                            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.CLOUD_DOWNLOAD} fixedWidth />
                        </a>
                    }
                    {(AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialInstitutionItemTemplateImport, trialId, null) && trial && trial.id > 0) && (
                            <Link className={"btn btn-warning mr-2"} url={"/trials/" + trial.id + '/trialitemimport'}>Import</Link>
                    )}
                    {(AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialItemInstanceUpdate, trialId, null) && trial && trial.id > 0) && (
                            <Link className={"btn btn-warning mr-2"} url={"/trials/" + trial.id + '/trialitemupdate'}>Bulk Update</Link>
                    )}
                    {(AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialItemCreate, trialId, null) && trial && trial.id > 0) && (
                            <Link className={"btn btn-primary"} url={"/trials/" + trial.id + '/trialitems/create'}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.showTrial() ?
                    "Loading Item Templates" :
                    null
            }
            {
                this.showTrial() ?
                    <div className="mt-2">
                        {this.renderTable()}
                    </div> :
                    null
            }
        </div>
    }

    toggle(categoryId: number) {

    }


    renderTable() {
        const {
            trial,
            trialItemsByCategory,
            toggleItems,
            toggleExpand,
            permissions,
            trialId
        } = this.props;



        if (!trialItemsByCategory || trialItemsByCategory.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no items to view.</span>
            </div>
        }

        const showPagination = trialItemsByCategory.length > DEFAULT_PAGE_SIZE;

        return <div>
            {trialItemsByCategory.map((item, index) => {
                const key = "trial-items-page-" + item.categoryId + "-" + trial.id;
                const expanded = ToggleHelper.isToggleExpanded(toggleItems, key);
                return (
                    <div key={"trial-items-category" + item.category.id}>
                        <div className={tableHeaderClass} style={tableStyleClass} onClick={() => toggleExpand(key)}>
                            <div>
                                <FontAwesomeIcon icon={!expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" />
                            </div>
                            <span>{item.category.value}</span>
                        </div>
                        { expanded && (
                        <ReactTable
                            data={item.trialItemSummaries}
                            pageSizeOptions={PAGE_SIZE_OPTIONS}
                            defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : item.trialItemSummaries.length}
                            pageSize={showPagination ? undefined : item.trialItemSummaries.length}
                            showPagination={showPagination}
                            className="-striped -highlight -clickable mb-3"
                            noDataText="There are currently no item templates for this category"
                            resizable={false}
                            getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                                return {
                                    onClick: (e, handleOriginal) => {
                                        console.log(rowInfo);
                                        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialItemUpdate, trialId, null) ||
                                            AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialInstitutionItemTemplateRestrictedUpdate, trialId, null)) {
                                            const url: string = "/trials/" + trial.id + '/trialitems/' + rowInfo.original.id + "";
                                            this.props.navigate(url);
                                        }
                                        

                                        if (handleOriginal) {
                                            handleOriginal()
                                        }

                                    },
                                    style: {
                                        color: (rowInfo && rowInfo.original && rowInfo.original.active) ? '#212529' : '#9e9e9e'
                                    }
                                }
                            }}
                            columns={
                                [
                                    {
                                        id: "id",
                                        Header: ' ',
                                        accessor: "id",
                                        className: "d-flex align-items-center justify-content-center",
                                        maxWidth: 50,
                                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.TRIALITEM} fixedWidth />
                                    },
                                    {
                                        id: "name",
                                        Header: "Name",
                                        accessor: "name",
                                        className: "d-flex align-items-left justify-content-left",
                                        headerClassName: "text-left",
                                        Cell: (props) => <TruncatedCell value={props.value} />
                                    },
                                    {
                                        id: "description",
                                        Header: "Description",
                                        accessor: "description",
                                        className: "d-flex align-items-left justify-content-left",
                                        headerClassName: "text-left",
                                        Cell: (props) => <TruncatedCell value={props.value} />
                                    },
                                    {
                                        id: "groupDisplay",
                                        Header: "Groups or Institutions",
                                        accessor: "groupDisplay",
                                        className: "d-flex align-items-left justify-content-left",
                                        headerClassName: "text-left",
                                        maxWidth: 220,
                                        Cell: (props) => <TruncatedCell value={props.value + props.original.institutionDisplay} />
                                    },
                                    {
                                        id: "initialVersion",
                                        Header: "Initial Version",
                                        accessor: "initialVersion",
                                        className: "d-flex align-items-left justify-content-left",
                                        headerClassName: "text-left",
                                        maxWidth: 220,
                                        Cell: (props) => <TruncatedCell value={props.value} />
                                    }
                                ]
                            }
                            />
                        )}
                    </div>
                )
            }
            )}
        </div>
    }

}


const trialItemActiveFilter = (trialItemsByCategory: Dtos.TrialItemCategory[]): Dtos.TrialItemCategory[] => {

    try {
        if (trialItemsByCategory) {
            let trialItemsByCategoryFilters = [...trialItemsByCategory];
            trialItemsByCategoryFilters.forEach(c => {
                c.trialItemSummaries = [...c.trialItemSummaries].filter(f => f.active);
            });
            trialItemsByCategoryFilters = trialItemsByCategoryFilters.filter(f => f.trialItemSummaries.length > 0);
            return trialItemsByCategoryFilters;
        }
    } catch (e) {
        console.error(e);
    }

    return [];
}


const mapStateToProps = (state: ITmdState, ownProps: TrialItemsPageProps): ITrialItemsPageProps => {

    let trial: Dtos.Trial | undefined = !(state.trials.formData instanceof Array) ? state.trials.formData : undefined;
    //let trialItemsByCategory: Dtos.TrialItemCategory[] | undefined = trial == undefined ? undefined : trial.trialItemCategories;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trial: trial!,
        trialRoles: state.trials.formRoles,
        trialItemsByCategory: state.trialItem.formFilteredData,//trialItemActiveFilter(trialItemsByCategory),

        loadingTrial: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,
        loadTrialSuccess: state.trials.loadState && state.trials.loadState.status == RequestState.Success,
        loadTrialFailure: state.trials.loadState && state.trials.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        toggleItems: state.togglePage.items,

        formFilter: state.trialItem.formFilter,
    };
};

const mapDispatchToProps = (dispatch): ITrialItemsPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch),

        toggleExpand: bindActionCreators(ToggleActions.ToggleExpanded, dispatch),

        filter: bindActionCreators(TrialItemActions.Filter, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialItemsPage);
