import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const departmentContactsApi = {
    get: (): Observable<Dtos.GetDepartmentContactsResponse> => {
        //Create the request.
        let request: Dtos.GetDepartmentContacts = new Dtos.GetDepartmentContacts();

        //Send request.
        const response: Promise<Dtos.GetDepartmentContactsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getById: (id: number): Observable<Dtos.GetDepartmentContactByIdResponse> => {
        //Create the request.
        let request: Dtos.GetDepartmentContactById = new Dtos.GetDepartmentContactById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetDepartmentContactByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    set: (departmentContact: Dtos.DepartmentContact): Observable<Dtos.SetDepartmentContactResponse> => {

        let request: Dtos.SetDepartmentContact = new Dtos.SetDepartmentContact();

        request.departmentContact = departmentContact;

        // send request
        const response: Promise<Dtos.SetDepartmentContactResponse> = client.post(request);

        return Observable.from(response);

    },
    create: (departmentId: number): Observable<Dtos.CreateDepartmentContactResponse> => {
        //Create the request.
        let request: Dtos.CreateDepartmentContact = new Dtos.CreateDepartmentContact();

        request.departmentId = departmentId;

        //Send request.
        const response: Promise<Dtos.CreateDepartmentContactResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}