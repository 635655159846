import * as Dtos from "../dtos/Tmd.dtos";

export const
    LoadUser: IUserLoadActionCreator = () => {
        return {
            type: ACTION_USER_LOAD
        }
    },
    LoadSuccess: IUserLoadSuccessActionCreator = (data, userSummary, formRoles) => {
        return {
            type: ACTION_USER_LOAD_SUCCESS,
            data,
            userSummary,
            formRoles,
        }
    },
    LoadFailure: IUserLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_USER_LOAD_FAILURE,
            responseStatus
        }
    },
    Clear: IUserClearActionCreator = () => {
        return {
            type: ACTION_USER_CLEAR
        }
    };
    

export const
    ACTION_USER_LOAD = "ACTION_USER_LOAD",
    ACTION_USER_LOAD_BY_INSTITUTION = "ACTION_USER_LOAD_BY_INSTITUTION",
    ACTION_USER_LOAD_BY_STATUS = "ACTION_USER_LOAD_BY_STATUS",
    ACTION_USER_LOAD_BY_IDENTIFIER = "ACTION_USER_LOAD_BY_IDENTIFIER",
    ACTION_USER_LOAD_BY_ID = "ACTION_USER_LOAD_BY_ID",
    ACTION_USER_LOAD_SUCCESS = "ACTION_USER_LOAD_SUCCESS",
    ACTION_USER_LOAD_FAILURE = "ACTION_USER_LOAD_FAILURE",
    ACTION_USER_CLEAR = "ACTION_USER_CLEAR";

export interface IUserLoadAction {
    type: "ACTION_USER_LOAD";
}

export interface IUserLoadActionCreator {
    (): IUserLoadAction;
}

export interface IUserLoadSuccessAction {
    type: "ACTION_USER_LOAD_SUCCESS";
    data: Dtos.User;
    userSummary: Dtos.UserSummary;
    formRoles: Dtos.RoleType[];
}

export interface IUserLoadSuccessActionCreator {
    (data: Dtos.User, userSummary: Dtos.UserSummary, formRoles: Dtos.RoleType[]): IUserLoadSuccessAction;
}

export interface IUserLoadFailureAction {
    type: "ACTION_USER_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IUserLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IUserLoadFailureAction;
}

export interface IUserClearAction {
    type: "ACTION_USER_CLEAR";
}

export interface IUserClearActionCreator {
    (): IUserClearAction;
}

export type UserAction = IUserLoadAction |
    IUserLoadFailureAction |
    IUserLoadSuccessAction |
    IUserClearAction;
