import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_TRIALCATEGORYS_LOAD,
    ACTION_TRIALCATEGORYS_LOAD_BY_ID,

    ACTION_TRIALCATEGORYS_SAVE,

    ACTION_TRIALCATEGORYS_CREATE,

    ITrialCategoryLoadAction,
    ITrialCategoryLoadByIdAction,
    ITrialCategoryLoadFailureAction,
    ITrialCategoryLoadSuccessAction,

    ITrialCategorySaveAction,
    ITrialCategorySaveSuccessAction,
    ITrialCategorySaveFailureAction,

    ITrialCategoryCreateAction,
    ITrialCategoryCreateSuccessAction,
    ITrialCategoryCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/trialCategorys";
import { trialcategorysApi } from "../services/trialCategory";


export const
    getTrialCategorysEpic = (action$): Observable<ITrialCategoryLoadSuccessAction | ITrialCategoryLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALCATEGORYS_LOAD)
            .mergeMap((action: ITrialCategoryLoadAction) => {
                return trialcategorysApi
                    .getTrialCategorys()
                    .map(response =>
                        LoadSuccess(response.trialCategorys, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTrialCategoryByIdEpic = (action$): Observable<ITrialCategoryLoadSuccessAction | ITrialCategoryLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALCATEGORYS_LOAD_BY_ID)
            .mergeMap((action: ITrialCategoryLoadByIdAction) => {
                return trialcategorysApi
                    .getTrialCategoryById(action.id)
                    .map(response =>
                        LoadSuccess(response.trialCategory, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setTrialCategoryEpic = (action$): Observable<ITrialCategorySaveSuccessAction | ITrialCategorySaveFailureAction> => {
        return action$
            .ofType(ACTION_TRIALCATEGORYS_SAVE)
            .mergeMap((action: ITrialCategorySaveAction) => {
                return trialcategorysApi
                    .setTrialCategory(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.trialCategory, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createTrialCategoryEpic = (action$): Observable<ITrialCategoryCreateSuccessAction | ITrialCategoryCreateFailureAction> => {
        return action$
            .ofType(ACTION_TRIALCATEGORYS_CREATE)
            .mergeMap((action: ITrialCategoryCreateAction) => {
                return trialcategorysApi
                    .createTrialCategory()
                    .map(response =>
                        CreateSuccess(
                            response.trialCategory,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



