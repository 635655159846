import * as React from 'react';
import { connect } from "react-redux";
import { ITmdState } from '../../reducers';
import { RequestState } from "../../enumerations/RequestState";
import { bindActionCreators } from "redux";
import { match } from 'react-router';
import { DefaultLayout, RestrictedLayout, INavItem, UnrestrictedLayout } from "../../components/layouts/index";
import { FontAwesomeIcon, FontAwesomeIcons } from '../../constants/fontAwesomeIcons';
import { IRegistrationFormState } from "../../reducers/reactReduxForms/index";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../helpers/formHelper";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../components/form/index";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../helpers/alert";

import Alert from 'react-s-alert';
import Modal from 'react-responsive-modal';
import { NotificationAlert, MessageModal } from "../../components/common/index";
import { routerActions } from 'react-router-redux';
import { Form, Control, actions, Errors } from "react-redux-form";
import { IHomeReduxFormState, initialHomeFormState } from "../../reducers/reactReduxForms/homeForm";

import * as Dtos from '../../dtos/Tmd.dtos';
import * as HomeActions from '../../actions/home';

interface IHomePageProps {

    form: Dtos.Home & IRegistrationFormState;
    reduxFormState: IHomeReduxFormState;

    home: Dtos.Home;

    loadingHome: boolean;
    loadingHomeSuccess: boolean;
    loadingHomeFailure: boolean;
}

interface IHomePageActions {
    load: HomeActions.IHomeLoadActionCreator;
    clear: HomeActions.IHomeClearActionCreator;


    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type HomePageProps = IHomePageProps & IHomePageActions;

const formName: string = "Home";
const reduxFormName: string = "reduxForms.home";

class HomePage extends React.Component<HomePageProps, any> {

    constructor(props) {
        super(props);
        this.loadForm = this.loadForm.bind(this);
    }

    componentWillMount() {
    }

    componentDidMount() {
        const {
            load
        } = this.props;

        load();
    }

    componentWillReceiveProps(nextProps: IHomePageProps) {
    }

    componentDidUpdate(prevProps: IHomePageProps, prevState) {

        const {
            home,
            loadForm,
            loadingHome,
            loadingHomeSuccess,
            navigateReplace,
            form,
            reduxFormState,
            navigate
        } = this.props;

        if (!loadingHome && prevProps.loadingHome) {
            if (loadingHomeSuccess) {
                this.loadForm();
            }
            else {

            }
        }

    }

    componentWillUpdate(nextProps: IHomePageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        loadForm(reduxFormName, initialHomeFormState);
        resetForm(reduxFormName);
    }

    render() {
        return <RestrictedLayout
            subMenuItems={undefined}
            loading={false}
        >
            {this.renderContent()}
        </RestrictedLayout>
    }

    renderContent() {
        const {
            form,
            changeForm
        } = this.props;

        const formDisabled: boolean = false;


        const formComponents: { [index: string]: RegistrationFormComponent } = {
            performanceStatusHeading: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="row p-4 border border-top-0 border-left-0 border-right-0">
                    <h3 style={{width: '100%'}}>Home</h3>
                    {form.message && (
                        <h5>{form.message}</h5>
                    )}
                </div>
            },
        };

        return <div>
            <Form model={reduxFormName}
                onSubmit={(val, event) => console.log('submit not implemented')}
                onSubmitFailed={(val) => console.log('submit failed not implemented')}
            >
                {
                    renderFormControls(form, "home", formComponents, [], [], [], changeForm)
                }
            </Form>
        </div>;
    }

    loadForm() {
        const {
            loadForm,
            home
        } = this.props

        let extraData: Dtos.Home = {} as Dtos.Home;

        loadForm(reduxFormName, Object.assign({ ...home }, extraData));
    }

}

const mapStateToProps = (state: ITmdState, ownProps: HomePageProps): IHomePageProps => {

    return {
        form: state.reduxForms.home,
        reduxFormState: state.reduxForms.formState.home,

        home: !(state.home.data instanceof Array) ? state.home.data : undefined,

        loadingHome: state.home.loadState && state.home.loadState.status == RequestState.Pending,
        loadingHomeSuccess: state.home.loadState && state.home.loadState.status == RequestState.Success,
        loadingHomeFailure: state.home.loadState && state.home.loadState.status == RequestState.Failure,
    };
};

const mapDispatchToProps = (dispatch): IHomePageActions => {
    return {
        load: bindActionCreators(HomeActions.LoadHome, dispatch),
        clear: bindActionCreators(HomeActions.Clear, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),
    }
};

export default
    connect(mapStateToProps, mapDispatchToProps)(HomePage);