import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadPersonnel: IPersonnelLoadActionCreator = () => {
        return {
            type: ACTION_PERSONNEL_LOAD
        }
    },
    LoadPersonnelById: IPersonnelLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_PERSONNEL_LOAD_BY_ID,
            id
        }
    },
    LoadPersonnelSecurityById: IPersonnelSecurityLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_PERSONNEL_SECURITY_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IPersonnelLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_PERSONNEL_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IPersonnelLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PERSONNEL_LOAD_FAILURE,
            responseStatus
        }
    },

    SavePersonnel: IPersonnelaveActionCreator = (formData) => {
        return {
            type: ACTION_PERSONNEL_SAVE,
            formData
        }
    },
    SaveSuccess: IPersonnelaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_PERSONNEL_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IPersonnelaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PERSONNEL_SAVE_FAILURE,
            responseStatus
        }
    },

    SaveActiveDirectory: IPersonnelSaveActiveDirectoryActionCreator = (formData) => {
        return {
            type: ACTION_PERSONNEL_SAVE_ACTIVE_DIRECTORY,
            formData
        }
    },
    SaveActiveDirectorySuccess: IPersonnelSaveActiveDirectorySuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_PERSONNEL_SAVE_ACTIVE_DIRECTORY_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveActiveDirectoryFailure: IPersonnelSaveActiveDirectoryFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PERSONNEL_SAVE_ACTIVE_DIRECTORY_FAILURE,
            responseStatus
        }
    },

    CreatePersonnel: IPersonnelCreateActionCreator = () => {

        return {
            type: ACTION_PERSONNEL_CREATE
        }
    },
    CreateSuccess: IPersonnelCreateSuccessActionCreator = (formData, formState, formProperties, lookups) => {
        return {
            type: ACTION_PERSONNEL_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups
        }
    },
    CreateFailure: IPersonnelCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PERSONNEL_CREATE_FAILURE,
            responseStatus
        }
    },

    Clear: IPersonnelClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_PERSONNEL_CLEAR,
            propertiesToClear
        }
    },

    Filter: IPersonnelFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_PERSONNEL_FILTER,
            formFilter
        }
    },
    ResendEmail: IPersonnelResendEmailActionCreator = (id) => {
        return {
            type: ACTION_PERSONNEL_RESENDEMAIL,
            id
        }
    },
    ResendEmailSuccess: IPersonnelResendEmailSuccessActionCreator = (responseStatus) => {
        return {
            type: ACTION_PERSONNEL_RESENDEMAIL_SUCCESS,
            responseStatus
        }
    },
    ResendEmailFailure: IPersonnelResendEmailFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PERSONNEL_RESENDEMAIL_FAILURE,
            responseStatus
        }
    },
    LoadPersonnelPermissionsById: IPersonnelPermissionsLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_PERSONNEL_PERMISSIONS_LOAD_BY_ID,
            id
        }
    },
    LoadPermissionsSuccess: IPersonnelPermissionsLoadSuccessActionCreator = (data, responseStatus) => {
        return {
            type: ACTION_PERSONNEL_PERMISSIONS_LOAD_SUCCESS,
            data,
            responseStatus
        }
    },
    LoadPermissionsFailure: IPersonnelPermissionsLoadFailureActionCreator = (responseStatus) => {
            return {
                type: ACTION_PERSONNEL_PERMISSIONS_LOAD_FAILURE,
                responseStatus
            }
    },

    LoadPersonnelByRole: IPersonnelByRoleLoadActionCreator = () => {
        return {
            type: ACTION_PERSONNEL_BY_ROLE_LOAD
        }
    },
    LoadPersonnelByRoleSuccess: IPersonnelByRoleLoadSuccessActionCreator = (formData, responseStatus) => {
        return {
            type: ACTION_PERSONNEL_BY_ROLE_LOAD_SUCCESS,
            formData,
            responseStatus
        }
    },
    LoadPersonnelByRoleFailure: IPersonnelByRoleLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PERSONNEL_BY_ROLE_LOAD_FAILURE,
            responseStatus
        }
    },
    FilterByRole: IPersonnelFilterByRoleActionCreator = (formFilter) => {
        return {
            type: ACTION_PERSONNEL_FILTER_BY_ROLE,
            formFilter
        }
    },
    PersonnelRolesFilter: IPersonnelRolesFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_PERSONNEL_ROLES_FILTER,
            formFilter
        }
    }
    ;



export const
    ACTION_PERSONNEL_LOAD = "ACTION_PERSONNEL_LOAD",
    ACTION_PERSONNEL_LOAD_BY_ID = "ACTION_PERSONNEL_LOAD_BY_ID",
    ACTION_PERSONNEL_SECURITY_LOAD_BY_ID = "ACTION_PERSONNEL_SECURITY_LOAD_BY_ID",
    ACTION_PERSONNEL_LOAD_SUCCESS = "ACTION_PERSONNEL_LOAD_SUCCESS",
    ACTION_PERSONNEL_LOAD_FAILURE = "ACTION_PERSONNEL_LOAD_FAILURE",

    ACTION_PERSONNEL_SAVE = "ACTION_PERSONNEL_SAVE",
    ACTION_PERSONNEL_SAVE_SUCCESS = "ACTION_PERSONNEL_SAVE_SUCCESS",
    ACTION_PERSONNEL_SAVE_FAILURE = "ACTION_PERSONNEL_SAVE_FAILURE",

    ACTION_PERSONNEL_SAVE_ACTIVE_DIRECTORY = "ACTION_PERSONNEL_SAVE_ACTIVE_DIRECTORY",
    ACTION_PERSONNEL_SAVE_ACTIVE_DIRECTORY_SUCCESS = "ACTION_PERSONNEL_SAVE_ACTIVE_DIRECTORY_SUCCESS",
    ACTION_PERSONNEL_SAVE_ACTIVE_DIRECTORY_FAILURE = "ACTION_PERSONNEL_SAVE_ACTIVE_DIRECTORY_FAILURE",

    ACTION_PERSONNEL_CREATE = "ACTION_PERSONNEL_CREATE",
    ACTION_PERSONNEL_CREATE_SUCCESS = "ACTION_PERSONNEL_CREATE_SUCCESS",
    ACTION_PERSONNEL_CREATE_FAILURE = "ACTION_PERSONNEL_CREATE_FAILURE",

    ACTION_PERSONNEL_RESENDEMAIL = "ACTION_PERSONNEL_RESENDEMAIL",
    ACTION_PERSONNEL_RESENDEMAIL_SUCCESS = "ACTION_PERSONNEL_RESENDEMAIL_SUCCESS",
    ACTION_PERSONNEL_RESENDEMAIL_FAILURE = "ACTION_PERSONNEL_RESENDEMAIL_FAILURE",

    ACTION_PERSONNEL_CLEAR = "ACTION_PERSONNEL_CLEAR",

    ACTION_PERSONNEL_FILTER = "ACTION_PERSONNEL_FILTER",

    ACTION_PERSONNEL_PERMISSIONS_LOAD_BY_ID = "ACTION_PERSONNEL_PERMISSIONS_LOAD_BY_ID",
    ACTION_PERSONNEL_PERMISSIONS_LOAD_SUCCESS = "ACTION_PERSONNEL_PERMISSIONS_LOAD_SUCCESS",
    ACTION_PERSONNEL_PERMISSIONS_LOAD_FAILURE = "ACTION_PERSONNEL_PERMISSIONS_LOAD_FAILURE",

    ACTION_PERSONNEL_BY_ROLE_LOAD = "ACTION_PERSONNEL_BY_ROLE_LOAD",
    ACTION_PERSONNEL_BY_ROLE_LOAD_SUCCESS = "ACTION_PERSONNEL_BY_ROLE_LOAD_SUCCESS",
    ACTION_PERSONNEL_BY_ROLE_LOAD_FAILURE = "ACTION_PERSONNEL_BY_ROLE_LOAD_FAILURE",

    ACTION_PERSONNEL_FILTER_BY_ROLE = "ACTION_PERSONNEL_FILTER_BY_ROLE",
    ACTION_PERSONNEL_ROLES_FILTER = "ACTION_PERSONNEL_ROLES_FILTER";

export interface IPersonnelLoadAction {
    type: "ACTION_PERSONNEL_LOAD";
}

export interface IPersonnelLoadActionCreator {
    (): IPersonnelLoadAction;
}

export interface IPersonnelLoadSuccessAction {
    type: "ACTION_PERSONNEL_LOAD_SUCCESS";
    formData: Dtos.Personnel | Dtos.Personnel[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IPersonnelLoadSuccessActionCreator {
    (
        formData: Dtos.Personnel | Dtos.Personnel[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IPersonnelLoadSuccessAction;
}

export interface IPersonnelLoadFailureAction {
    type: "ACTION_PERSONNEL_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IPersonnelLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IPersonnelLoadFailureAction;
}

export interface IPersonnelLoadByIdAction {
    type: "ACTION_PERSONNEL_LOAD_BY_ID";
    id: number;
}

export interface IPersonnelLoadByIdActionCreator {
    (id: number): IPersonnelLoadByIdAction;
}

export interface IPersonnelSecurityLoadByIdAction {
    type: "ACTION_PERSONNEL_SECURITY_LOAD_BY_ID";
    id: number;
}

export interface IPersonnelSecurityLoadByIdActionCreator {
    (id: number): IPersonnelSecurityLoadByIdAction;
}

export interface IPersonnelaveAction {
    type: "ACTION_PERSONNEL_SAVE";
    formData: Dtos.Personnel;

}

export interface IPersonnelaveActionCreator {
    (
        formData: Dtos.Personnel
    ): IPersonnelaveAction;
}

export interface IPersonnelaveSuccessAction {
    type: "ACTION_PERSONNEL_SAVE_SUCCESS";
    formData: Dtos.Personnel;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IPersonnelaveSuccessActionCreator {
    (
        formData: Dtos.Personnel,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IPersonnelaveSuccessAction;
}

export interface IPersonnelaveFailureAction {
    type: "ACTION_PERSONNEL_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IPersonnelaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IPersonnelaveFailureAction;
}


export interface IPersonnelSaveActiveDirectoryAction {
    type: "ACTION_PERSONNEL_SAVE_ACTIVE_DIRECTORY";
    formData: Dtos.Personnel;

}

export interface IPersonnelSaveActiveDirectoryActionCreator {
    (
        formData: Dtos.Personnel
    ): IPersonnelSaveActiveDirectoryAction;
}

export interface IPersonnelSaveActiveDirectorySuccessAction {
    type: "ACTION_PERSONNEL_SAVE_ACTIVE_DIRECTORY_SUCCESS";
    formData: Dtos.Personnel;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IPersonnelSaveActiveDirectorySuccessActionCreator {
    (
        formData: Dtos.Personnel,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IPersonnelSaveActiveDirectorySuccessAction;
}

export interface IPersonnelSaveActiveDirectoryFailureAction {
    type: "ACTION_PERSONNEL_SAVE_ACTIVE_DIRECTORY_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IPersonnelSaveActiveDirectoryFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IPersonnelSaveActiveDirectoryFailureAction;
}


export interface IPersonnelResendEmailAction {
    type: "ACTION_PERSONNEL_RESENDEMAIL";
    id: number;
}

export interface IPersonnelResendEmailActionCreator {
    (id: number): IPersonnelResendEmailAction;
}

export interface IPersonnelResendEmailSuccessAction {
    type: "ACTION_PERSONNEL_RESENDEMAIL_SUCCESS";
    responseStatus: Dtos.ResponseStatus;
}

export interface IPersonnelResendEmailSuccessActionCreator {
    (
        responseStatus: Dtos.ResponseStatus
    ): IPersonnelResendEmailSuccessAction;
}

export interface IPersonnelResendEmailFailureAction {
    type: "ACTION_PERSONNEL_RESENDEMAIL_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IPersonnelResendEmailFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IPersonnelResendEmailFailureAction;
}

export interface IPersonnelCreateAction {
    type: "ACTION_PERSONNEL_CREATE"
}

export interface IPersonnelCreateActionCreator {
    (): IPersonnelCreateAction
}

export interface IPersonnelCreateSuccessAction {
    type: "ACTION_PERSONNEL_CREATE_SUCCESS";
    formData: Dtos.Personnel;
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[]
}

export interface IPersonnelCreateSuccessActionCreator {
    (
        formData: Dtos.Personnel,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[]
    ): IPersonnelCreateSuccessAction;
}

export interface IPersonnelCreateFailureAction {
    type: "ACTION_PERSONNEL_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IPersonnelCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IPersonnelCreateFailureAction;
}

export interface IPersonnelClearAction {
    type: "ACTION_PERSONNEL_CLEAR";
    propertiesToClear?: string[];
}

export interface IPersonnelClearActionCreator {
    (propertiesToClear?: string[]): IPersonnelClearAction;
}

export interface IPersonnelFilterActionCreator {
    (formFilter: IFormFilter): IPersonnelFilterAction;
}

export interface IPersonnelFilterAction {
    type: "ACTION_PERSONNEL_FILTER";
    formFilter: IFormFilter;
}

export interface IPersonnelPermissionsLoadByIdAction {
    type: "ACTION_PERSONNEL_PERMISSIONS_LOAD_BY_ID";
    id: number;
}

export interface IPersonnelPermissionsLoadByIdActionCreator {
    (id: number): IPersonnelPermissionsLoadByIdAction;
}

export interface IPersonnelPermissionsLoadSuccessAction {
    type: "ACTION_PERSONNEL_PERMISSIONS_LOAD_SUCCESS";
    data: Dtos.PersonnelPermissionGroup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IPersonnelPermissionsLoadSuccessActionCreator {
    (
        data: Dtos.PersonnelPermissionGroup[],
        responseStatus: Dtos.ResponseStatus
    ): IPersonnelPermissionsLoadSuccessAction;
}

export interface IPersonnelPermissionsLoadFailureAction {
    type: "ACTION_PERSONNEL_PERMISSIONS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IPersonnelPermissionsLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IPersonnelPermissionsLoadFailureAction;
}

export interface IPersonnelByRoleLoadAction {
    type: "ACTION_PERSONNEL_BY_ROLE_LOAD";
}

export interface IPersonnelByRoleLoadActionCreator {
    (): IPersonnelByRoleLoadAction;
}

export interface IPersonnelByRoleLoadSuccessAction {
    type: "ACTION_PERSONNEL_BY_ROLE_LOAD_SUCCESS";
    formData: Dtos.RolePersonnelGrouping[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IPersonnelByRoleLoadSuccessActionCreator {
    (
        formData: Dtos.RolePersonnelGrouping[],
        responseStatus: Dtos.ResponseStatus
    ): IPersonnelByRoleLoadSuccessAction;
}

export interface IPersonnelByRoleLoadFailureAction {
    type: "ACTION_PERSONNEL_BY_ROLE_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IPersonnelByRoleLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IPersonnelByRoleLoadFailureAction;
}

export interface IPersonnelFilterByRoleActionCreator {
    (formFilter: IFormFilter): IPersonnelFilterByRoleAction;
}

export interface IPersonnelFilterByRoleAction {
    type: "ACTION_PERSONNEL_FILTER_BY_ROLE";
    formFilter: IFormFilter;
}

export interface IPersonnelRolesFilterActionCreator {
    (formFilter: IFormFilter): IPersonnelRolesFilterAction;
}

export interface IPersonnelRolesFilterAction {
    type: "ACTION_PERSONNEL_ROLES_FILTER";
    formFilter: IFormFilter;
}


export type PersonnelAction =
    IPersonnelLoadAction |
    IPersonnelLoadByIdAction |
    IPersonnelSecurityLoadByIdAction |
    IPersonnelLoadFailureAction |
    IPersonnelLoadSuccessAction |
    IPersonnelaveAction |
    IPersonnelaveSuccessAction |
    IPersonnelaveFailureAction |
    IPersonnelSaveActiveDirectoryAction |
    IPersonnelSaveActiveDirectorySuccessAction |
    IPersonnelSaveActiveDirectoryFailureAction |
    IPersonnelCreateAction |
    IPersonnelCreateSuccessAction |
    IPersonnelCreateFailureAction |
    IPersonnelClearAction |
    IPersonnelFilterAction |
    IPersonnelResendEmailAction |
    IPersonnelResendEmailSuccessAction |
    IPersonnelResendEmailFailureAction |
    IPersonnelPermissionsLoadByIdAction |
    IPersonnelPermissionsLoadFailureAction |
    IPersonnelPermissionsLoadSuccessAction |
    IPersonnelByRoleLoadAction |
    IPersonnelByRoleLoadFailureAction |
    IPersonnelByRoleLoadSuccessAction |
    IPersonnelFilterByRoleAction |
    IPersonnelRolesFilterAction;
