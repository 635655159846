import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    Load: ITrialItemInstanceImportLoadByIdActionCreator = (id, trialId) => {
        return {
            type: ACTION_TRIALITEMINSTANCEIMPORT_LOAD_BY_ID,
            id,
            trialId
        }
    },
    LoadSuccess: ITrialItemInstanceImportLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALITEMINSTANCEIMPORT_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrialItemInstanceImportLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALITEMINSTANCEIMPORT_LOAD_FAILURE,
            responseStatus
        }
    },
    Save: ITrialItemInstanceImportSaveActionCreator = (formData) => {
        return {
            type: ACTION_TRIALITEMINSTANCEIMPORT_SAVE,
            formData
        }
    },
    SaveSuccess: ITrialItemInstanceImportSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRIALITEMINSTANCEIMPORT_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrialItemInstanceImportSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALITEMINSTANCEIMPORT_SAVE_FAILURE,
            responseStatus
        }
    },
    Clear: ITrialItemInstanceImportClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRIALITEMINSTANCEIMPORT_CLEAR,
            propertiesToClear
        }
    },
    Filter: ITrialItemInstanceImportFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRIALITEMINSTANCEIMPORT_FILTER,
            formFilter
        }
    };

export const
    ACTION_TRIALITEMINSTANCEIMPORT_LOAD_BY_ID = "ACTION_TRIALITEMINSTANCEIMPORT_LOAD_BY_ID",
    ACTION_TRIALITEMINSTANCEIMPORT_LOAD_SUCCESS = "ACTION_TRIALITEMINSTANCEIMPORT_LOAD_SUCCESS",
    ACTION_TRIALITEMINSTANCEIMPORT_LOAD_FAILURE = "ACTION_TRIALITEMINSTANCEIMPORT_LOAD_FAILURE",

    ACTION_TRIALITEMINSTANCEIMPORT_SAVE = "ACTION_TRIALITEMINSTANCEIMPORT_SAVE",
    ACTION_TRIALITEMINSTANCEIMPORT_SAVE_SUCCESS = "ACTION_TRIALITEMINSTANCEIMPORT_SAVE_SUCCESS",
    ACTION_TRIALITEMINSTANCEIMPORT_SAVE_FAILURE = "ACTION_TRIALITEMINSTANCEIMPORT_SAVE_FAILURE",

    ACTION_TRIALITEMINSTANCEIMPORT_CLEAR = "ACTION_TRIALITEMINSTANCEIMPORT_CLEAR",

    ACTION_TRIALITEMINSTANCEIMPORT_FILTER = "ACTION_TRIALITEMINSTANCEIMPORT_FILTER";

export interface ITrialItemInstanceImportLoadAction {
    type: "ACTION_TRIALITEMINSTANCEIMPORT_LOAD";
}

export interface ITrialItemInstanceImportLoadActionCreator {
    (): ITrialItemInstanceImportLoadAction;
}

export interface ITrialItemInstanceImportLoadSuccessAction {
    type: "ACTION_TRIALITEMINSTANCEIMPORT_LOAD_SUCCESS";
    formData: Dtos.TrialItemInstanceImport;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialItemInstanceImportLoadSuccessActionCreator {
    (
        formData: Dtos.TrialItemInstanceImport,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialItemInstanceImportLoadSuccessAction;
}

export interface ITrialItemInstanceImportLoadFailureAction {
    type: "ACTION_TRIALITEMINSTANCEIMPORT_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialItemInstanceImportLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialItemInstanceImportLoadFailureAction;
}

export interface ITrialItemInstanceImportLoadByIdAction {
    type: "ACTION_TRIALITEMINSTANCEIMPORT_LOAD_BY_ID";
    id: number;
    trialId: number;
}

export interface ITrialItemInstanceImportLoadByIdActionCreator {
    (id: number, trialId: number): ITrialItemInstanceImportLoadByIdAction;
}

export interface ITrialItemInstanceImportSaveAction {
    type: "ACTION_TRIALITEMINSTANCEIMPORT_SAVE";
    formData: Dtos.TrialItemInstanceImport;

}

export interface ITrialItemInstanceImportSaveActionCreator {
    (
        formData: Dtos.TrialItemInstanceImport
    ): ITrialItemInstanceImportSaveAction;
}

export interface ITrialItemInstanceImportSaveSuccessAction {
    type: "ACTION_TRIALITEMINSTANCEIMPORT_SAVE_SUCCESS";
    formData: Dtos.TrialItemInstanceImport;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialItemInstanceImportSaveSuccessActionCreator {
    (
        formData: Dtos.TrialItemInstanceImport,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrialItemInstanceImportSaveSuccessAction;
}

export interface ITrialItemInstanceImportSaveFailureAction {
    type: "ACTION_TRIALITEMINSTANCEIMPORT_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialItemInstanceImportSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialItemInstanceImportSaveFailureAction;
}

export interface ITrialItemInstanceImportClearAction {
    type: "ACTION_TRIALITEMINSTANCEIMPORT_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrialItemInstanceImportClearActionCreator {
    (propertiesToClear?: string[]): ITrialItemInstanceImportClearAction;
}

export interface ITrialItemInstanceImportFilterActionCreator {
    (formFilter: IFormFilter): ITrialItemInstanceImportFilterAction;
}

export interface ITrialItemInstanceImportFilterAction {
    type: "ACTION_TRIALITEMINSTANCEIMPORT_FILTER";
    formFilter: IFormFilter;
}

export type TrialItemInstanceImportAction =
    ITrialItemInstanceImportLoadByIdAction |
    ITrialItemInstanceImportLoadFailureAction |
    ITrialItemInstanceImportLoadSuccessAction |
    ITrialItemInstanceImportSaveAction |
    ITrialItemInstanceImportSaveSuccessAction |
    ITrialItemInstanceImportSaveFailureAction |
    ITrialItemInstanceImportClearAction |
    ITrialItemInstanceImportFilterAction;
