import * as React from 'react';

import * as classNames from "classnames";
import './ItemComponentsPage.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';



import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu, TruncatedCell } from "../../../components/layouts/index";

import * as ItemComponentActions from '../../../actions/itemComponents';
import * as PagingActions from '../../../actions/pages/pagingPage';

import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput, FormFilterInput } from '../../../components/form';
import { SettingsSubMenu } from '../../../helpers/subMenuHelper';
import { QALookupsLayout, ItemSettingsLayout } from '../../index';

interface IItemComponentsPageProps {

    location: Location;
    history: History;

    // From state
    itemComponents: Dtos.ItemComponent[];
    formLength: number;
    formFilter: IFormFilter;

    loadingItemComponents: boolean;
    loadItemComponentsSuccess: boolean;
    loadItemComponentsFailure: boolean;

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];

    pageSize: number
}

interface IItemComponentsPageActions {
    clearItemComponents: ItemComponentActions.IItemComponentClearActionCreator;
    loadItemComponents: ItemComponentActions.IItemComponentLoadActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    filter: ItemComponentActions.IItemComponentFilterActionCreator;

    setPageSize: PagingActions.IPagingPageSizeChangedStateActionCreator;
}

type ItemComponentsPageProps = IItemComponentsPageProps & IItemComponentsPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class ItemComponentsPage extends React.PureComponent<ItemComponentsPageProps, any> {

    constructor(props: ItemComponentsPageProps) {
        super(props);

        this.clearItemComponents = this.clearItemComponents.bind(this);
        this.state = {
            rootFolder: "/settings/item/itemcomponents",
            qaItemComponents: false,
        }
    }

    componentDidMount() {
        const {
            loadItemComponents,
            navigate,
            navigateReplace
        } = this.props;

        this.setState({
            rootFolder: this.props.location.pathname.indexOf('/qa/') == -1 ? "/settings/item/itemcomponents" : "/settings/qa/formcomponents",
            qaItemComponents: this.props.location.pathname.indexOf('/qa/') > -1,
        });

        loadItemComponents(this.props.location.pathname.indexOf('/qa/') > -1, 0);
    }

    componentWillReceiveProps(nextProps: ItemComponentsPageProps) {
    }

    componentDidUpdate(prevProps: ItemComponentsPageProps, prevState) {
        const {
            navigateReplace,
            loadItemComponents
        } = this.props
    }

    componentWillUpdate(nextProps: ItemComponentsPageProps) {
    }

    componentWillUnmount() {
        this.clearItemComponents();
    }

    clearItemComponents() {
        this.props.clearItemComponents();
    }

    showItemComponents(): boolean {
        const {
            loadingItemComponents,
            loadItemComponentsSuccess
        } = this.props

        if (!loadingItemComponents && loadItemComponentsSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingItemComponents,
            permissions
        } = this.props;

        if (this.state.qaItemComponents) {
            return <QALookupsLayout loading={loadingItemComponents} permission={ Dtos.Permission.FormComponentsView}>
                {this.renderContent()}
            </QALookupsLayout>;
        }

        return <ItemSettingsLayout
            permission={Dtos.Permission.ItemComponentsView}
            loading={loadingItemComponents}
        >
            {this.renderContent()}
        </ItemSettingsLayout>;
    }

    renderContent() {
        const {
            itemComponents,
            loadingItemComponents,
            loadItemComponentsSuccess,
            loadItemComponentsFailure,
            clearItemComponents,
            navigate,
            user,
            permissions
        } = this.props;

        let canCreate: boolean = false;
        if (this.state.qaItemComponents) {
            canCreate = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.FormComponentsCreate);
        } else {
            canCreate = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.ItemComponentsCreate);
        }

        return <div>
            <div className="row">
                <div className={"col-6 mb-2"}>
                    <h2>{this.state.qaItemComponents ? "Form Components" : "Item Components"}</h2>
                </div>
                <div className="col-6" style={{textAlign: 'right'}}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} />
                    {canCreate && (
                        <Link className={"btn btn-primary"} url={this.state.rootFolder + "/create"}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.showItemComponents() ?
                    "Loading Item Components" :
                    null
            }
            {
                this.showItemComponents() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {

        const {
            itemComponents,
            user,
            pageSize,
            setPageSize
        } = this.props

        if (!itemComponents || itemComponents.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no item components to view.</span>
            </div>
        }

        const showPagination = itemComponents.length > pageSize;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={itemComponents}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? pageSize : itemComponents.length}
                pageSize={showPagination ? pageSize : itemComponents.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no item components to display"
                resizable={false}
                onPageSizeChange={val => setPageSize(val)}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log('rowInfo', rowInfo);
                            const url: string = this.state.rootFolder + "/" + rowInfo.original.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.ITEMCOLLECTION} fixedWidth />
                    },
                    {
                        id: "name",
                        Header: 'Component Name',
                        accessor: 'name',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value} />
                    },
                    {
                        id: "description",
                        Header: 'Description',
                        accessor: 'description',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={ props.value } />
                    },
                    {
                        id: "itemComponentType.value",
                        Header: 'Component Type',
                        accessor: 'itemComponentType.value',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value} />
                    }
                ]}
            />
        </div>

    }

}

const mapStateToProps = (state: ITmdState, ownProps: ItemComponentsPageProps): IItemComponentsPageProps => {
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];
    return {

        history: ownProps.history,
        location: state.routing.location,

        itemComponents: state.itemComponent.formFilteredData!,
        formFilter: state.itemComponent.formFilter,
        formLength: state.itemComponent.formLength,

        loadingItemComponents: state.itemComponent.loadState && state.itemComponent.loadState.status == RequestState.Pending,
        loadItemComponentsSuccess: state.itemComponent.loadState && state.itemComponent.loadState.status == RequestState.Success,
        loadItemComponentsFailure: state.itemComponent.loadState && state.itemComponent.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,

        pageSize: state.pagingPage.pageSize
    };
};

const mapDispatchToProps = (dispatch): IItemComponentsPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadItemComponents: bindActionCreators(ItemComponentActions.LoadItemComponent, dispatch),
        clearItemComponents: bindActionCreators(ItemComponentActions.Clear, dispatch),

        filter: bindActionCreators(ItemComponentActions.Filter, dispatch),

        setPageSize: bindActionCreators(PagingActions.PageSizeChanged, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(ItemComponentsPage);
