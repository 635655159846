import * as React from 'react';
import './TrackerTypePage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { ITrackerTypeReduxFormState, initialTrackerTypeState } from "../../../reducers/reactReduxForms/trackerType";
import { RegistrationFormComponent } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, NumberFormInput, CheckboxFormInput } from "../../../components/form/index";
import * as TrackerTypeActions from '../../../actions/trackerTypes';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { NotificationAlert } from "../../../components/common/index";
import { Form, actions } from 'react-redux-form';
import { QALookupsLayout } from '../../index';
import * as AuthenticationHelper from '../../../helpers/authentication';

interface ITrackerTypePageParams {
    trackerTypeId: number;
}

interface ITrackerTypePageProps {

    form: Dtos.TrackerType & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITrackerTypeReduxFormState;

    location: Location;
    trackerTypeId: number;
    match: match<ITrackerTypePageParams>;
    history: History;

    // From state
    trackerType: Dtos.TrackerType;
    lookups: Dtos.Lookup[];

    loadingTrackerType: boolean;
    loadTrackerTypeSuccess: boolean;
    loadTrackerTypeFailure: boolean;

    savingTrackerType: boolean;
    saveTrackerTypeSuccess: boolean;
    saveTrackerTypeFailure: boolean;

    creatingTrackerType: boolean;
    createTrackerTypeSuccess: boolean;
    createTrackerTypeFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface ITrackerTypePageActions {
    clearTrackerType: TrackerTypeActions.ITrackerTypeClearActionCreator;
    loadTrackerType: TrackerTypeActions.ITrackerTypeLoadByIdActionCreator;

    saveTrackerType: TrackerTypeActions.ITrackerTypeSaveActionCreator;
    createTrackerType: TrackerTypeActions.ITrackerTypeCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type TrackerTypePageProps = ITrackerTypePageProps & ITrackerTypePageActions;

const reduxFormName: string = "reduxForms.trackerType";

class TrackerTypePage extends React.PureComponent<TrackerTypePageProps, any> {

    constructor(props: TrackerTypePageProps) {
        super(props);

        this.clearTrackerType = this.clearTrackerType.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTrackerType,
            trackerTypeId,
            createTrackerType
        } = this.props;

        if (trackerTypeId) {
            loadTrackerType(trackerTypeId);
        } else if (!trackerTypeId) {
            createTrackerType();
        }
    }

    componentWillReceiveProps(nextProps: TrackerTypePageProps) {
    }

    componentDidUpdate(prevProps: TrackerTypePageProps, prevState) {
        const {
            trackerTypeId,
            trackerType,
            form,
            navigate,
            loadTrackerType,
            loadingTrackerType,
            loadTrackerTypeSuccess,
            createTrackerType,
            creatingTrackerType,
            createTrackerTypeSuccess,
            savingTrackerType,
            saveTrackerTypeSuccess,
            validationFailures
        } = this.props;

        if (trackerTypeId && prevProps.trackerTypeId != trackerTypeId) {
            loadTrackerType(trackerTypeId);
        }
        else if (!trackerTypeId && prevProps.trackerTypeId) {
            createTrackerType();
        }

        if (!loadingTrackerType && prevProps.loadingTrackerType) {
            if (loadTrackerTypeSuccess) {
                this.loadForm();
            }
        }

        if (!savingTrackerType && prevProps.savingTrackerType) {
            if (saveTrackerTypeSuccess) {
                if (validationFailures && validationFailures.length > 0) {
                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {
                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);
                }
                if (form.saveAndReturn) {
                    navigate("/settings/qa/trackerTypes")
                } else if (!form.saveAndReturn && trackerTypeId && trackerTypeId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !trackerTypeId && trackerType && trackerType.id > 0) {
                    navigate("/settings/qa/trackerTypes/" + trackerType.id);
                }
            }
            else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

        if (!creatingTrackerType && prevProps.creatingTrackerType) {
            if (createTrackerTypeSuccess) {
                this.loadForm();
            }
        }
    }

    componentWillUpdate(nextProps: TrackerTypePageProps) {
    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearTrackerType();
        loadForm(reduxFormName, initialTrackerTypeState);
        resetForm(reduxFormName);
    }

    clearTrackerType() {
        this.props.clearTrackerType();
    }

    showTrackerType(): boolean {
        const {
            loadingTrackerType,
            loadTrackerTypeSuccess
        } = this.props

        if (!loadingTrackerType && loadTrackerTypeSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrackerType
        } = this.props;

        return <QALookupsLayout loading={loadingTrackerType} permission={Dtos.Permission.TaskTypesView}>
            {this.renderContent()}
        </QALookupsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingTrackerType,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.TaskTypesUpdate, Dtos.Permission.TaskTypesCreate);
        const formDisabled: boolean = !hasPermission || savingTrackerType || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            value: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            }
        };

        return <div>
            <h1>Tracker Type</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "trackerType", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.TrackerType) {
        const {
            saveTrackerType
        } = this.props;

        if (data) {
            let changes: Dtos.TrackerType = {} as Dtos.TrackerType;
            saveTrackerType(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.TrackerType) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/qa/trackerTypes");
    }

    loadForm() {
        const {
            loadForm,
            trackerType,
        } = this.props

        let extraData: Dtos.TrackerType = {} as Dtos.TrackerType;

        loadForm(reduxFormName, Object.assign({ ...trackerType }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: TrackerTypePageProps): ITrackerTypePageProps => {

    let trackerType: Dtos.TrackerType | undefined = !(state.trackerTypes.formData instanceof Array) ? state.trackerTypes.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trackerTypeId: ownProps.match && ownProps.match.params.trackerTypeId && ownProps.match.params.trackerTypeId.toString() != "create" ? ownProps.match.params.trackerTypeId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trackerType: trackerType!,

        loadingTrackerType: state.trackerTypes.loadState && state.trackerTypes.loadState.status == RequestState.Pending,
        loadTrackerTypeSuccess: state.trackerTypes.loadState && state.trackerTypes.loadState.status == RequestState.Success,
        loadTrackerTypeFailure: state.trackerTypes.loadState && state.trackerTypes.loadState.status == RequestState.Failure,

        savingTrackerType: state.trackerTypes.saveState && state.trackerTypes.saveState.status == RequestState.Pending,
        saveTrackerTypeSuccess: state.trackerTypes.saveState && state.trackerTypes.saveState.status == RequestState.Success,
        saveTrackerTypeFailure: state.trackerTypes.saveState && state.trackerTypes.saveState.status == RequestState.Failure,

        creatingTrackerType: state.trackerTypes.createState && state.trackerTypes.createState.status == RequestState.Pending,
        createTrackerTypeSuccess: state.trackerTypes.createState && state.trackerTypes.createState.status == RequestState.Success,
        createTrackerTypeFailure: state.trackerTypes.createState && state.trackerTypes.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: [],

        form: state.reduxForms.trackerType,
        formState: state.trackerTypes.formState,
        formProperties: state.trackerTypes.formProperties,

        reduxFormState: state.reduxForms.formState.trackerType,
        validationFailures: trackerType ?
            state.trackerTypes.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): ITrackerTypePageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadTrackerType: bindActionCreators(TrackerTypeActions.LoadTrackerTypeById, dispatch),
        clearTrackerType: bindActionCreators(TrackerTypeActions.Clear, dispatch),

        saveTrackerType: bindActionCreators(TrackerTypeActions.SaveTrackerType, dispatch),
        createTrackerType: bindActionCreators(TrackerTypeActions.CreateTrackerType, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrackerTypePage);
