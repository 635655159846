import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_TRIALANALYSISTYPES_LOAD,
    ACTION_TRIALANALYSISTYPES_LOAD_BY_ID,

    ACTION_TRIALANALYSISTYPES_SAVE,

    ACTION_TRIALANALYSISTYPES_CREATE,

    ITrialAnalysisTypeLoadAction,
    ITrialAnalysisTypeLoadByIdAction,
    ITrialAnalysisTypeLoadFailureAction,
    ITrialAnalysisTypeLoadSuccessAction,

    ITrialAnalysisTypeSaveAction,
    ITrialAnalysisTypeSaveSuccessAction,
    ITrialAnalysisTypeSaveFailureAction,

    ITrialAnalysisTypeCreateAction,
    ITrialAnalysisTypeCreateSuccessAction,
    ITrialAnalysisTypeCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/trialAnalysisTypes";
import { trialAnalysisTypesApi } from "../services/trialAnalysisType";


export const
    getTrialAnalysisTypesEpic = (action$): Observable<ITrialAnalysisTypeLoadSuccessAction | ITrialAnalysisTypeLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALANALYSISTYPES_LOAD)
            .mergeMap((action: ITrialAnalysisTypeLoadAction) => {
                return trialAnalysisTypesApi
                    .getTrialAnalysisTypes()
                    .map(response =>
                        LoadSuccess(response.trialAnalysisTypes, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTrialAnalysisTypeByIdEpic = (action$): Observable<ITrialAnalysisTypeLoadSuccessAction | ITrialAnalysisTypeLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALANALYSISTYPES_LOAD_BY_ID)
            .mergeMap((action: ITrialAnalysisTypeLoadByIdAction) => {
                return trialAnalysisTypesApi
                    .getTrialAnalysisTypeById(action.id)
                    .map(response =>
                        LoadSuccess(response.trialAnalysisType, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setTrialAnalysisTypeEpic = (action$): Observable<ITrialAnalysisTypeSaveSuccessAction | ITrialAnalysisTypeSaveFailureAction> => {
        return action$
            .ofType(ACTION_TRIALANALYSISTYPES_SAVE)
            .mergeMap((action: ITrialAnalysisTypeSaveAction) => {
                return trialAnalysisTypesApi
                    .setTrialAnalysisType(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.trialAnalysisType, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createTrialAnalysisTypeEpic = (action$): Observable<ITrialAnalysisTypeCreateSuccessAction | ITrialAnalysisTypeCreateFailureAction> => {
        return action$
            .ofType(ACTION_TRIALANALYSISTYPES_CREATE)
            .mergeMap((action: ITrialAnalysisTypeCreateAction) => {
                return trialAnalysisTypesApi
                    .createTrialAnalysisType()
                    .map(response =>
                        CreateSuccess(
                            response.trialAnalysisType,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



