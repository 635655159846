import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialNoteState: Dtos.Note & IRegistrationFormState = {
    id: null,
    title: null,
    comments: null,
    noteTypeId: null,
    noteType: null,
    active: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false,
    personnelNotes: [],
    trialInstitutionNotes: [],
    trialNotes: [],
    institutionNotes: [],
    groupNotes: [],
    trialId: null,
    institutionId: null,
    personnelId: null,
    trialInstitutionId: null,
    groupId: null,
    parentDisplay: null,
    trialDisplay: null,
    institutionDisplay: null,
    groupDisplay: null,
}

export interface INoteReduxFormState {
    id: IFieldState<number>,
    title: IFieldState<string>,
    comments: IFieldState<string>,
    noteTypeId: IFieldState<number>,
    noteType: IFieldState<Dtos.NoteType>,
    active: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>,
    personnelNotes: IFieldState<Dtos.PersonnelNote[]>,
    trialInstitutionNotes: IFieldState<Dtos.TrialInstitutionNote[]>,
    trialNotes: IFieldState<Dtos.TrialNote[]>,
    institutionNotes: IFieldState<Dtos.InstitutionNote[]>,
    groupNotes: IFieldState<Dtos.GroupNote[]>,
    trialId: IFieldState<number>,
    institutionId: IFieldState<number>,
    personnelId: IFieldState<number>,
    trialInstitutionId: IFieldState<number>,
    groupId: IFieldState<number>,
    parentDisplay: IFieldState<string>,
    trialDisplay: IFieldState<string>,
    institutionDisplay: IFieldState<string>,

}
