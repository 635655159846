import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const gendersApi = {
    getGenders: (): Observable<Dtos.GetGendersResponse> => {
        //Create the request.
        let request: Dtos.GetGenders = new Dtos.GetGenders();

        //Send request.
        const response: Promise<Dtos.GetGendersResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getGenderById: (id: number): Observable<Dtos.GetGenderByIdResponse> => {
        //Create the request.
        let request: Dtos.GetGenderById = new Dtos.GetGenderById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetGenderByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setGender: (gender: Dtos.Gender): Observable<Dtos.SetGenderResponse> => {

        let request: Dtos.SetGender = new Dtos.SetGender();

        request.gender = gender;

        // send request
        const response: Promise<Dtos.SetGenderResponse> = client.post(request);

        return Observable.from(response);

    },
    createGender: (): Observable<Dtos.CreateGenderResponse> => {
        //Create the request.
        let request: Dtos.CreateGender = new Dtos.CreateGender();

        //Send request.
        const response: Promise<Dtos.CreateGenderResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}