import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadItemComponent: IItemComponentLoadActionCreator = (qaItemComponents, itemComponentTypeId) => {
        return {
            type: ACTION_ITEM_COMPONENTS_LOAD,
            qaItemComponents,
            itemComponentTypeId
        }
    },
    LoadItemComponentById: IItemComponentLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_ITEM_COMPONENTS_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IItemComponentLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_ITEM_COMPONENTS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IItemComponentLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_ITEM_COMPONENTS_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveItemComponent: IItemComponentSaveActionCreator = (formData) => {
        return {
            type: ACTION_ITEM_COMPONENTS_SAVE,
            formData
        }
    },
    SaveSuccess: IItemComponentSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_ITEM_COMPONENTS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus

        }
    },
    SaveFailure: IItemComponentSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_ITEM_COMPONENTS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateItemComponent: IItemComponentCreateActionCreator = (cloneId) => {

        return {
            type: ACTION_ITEM_COMPONENTS_CREATE,
            cloneId
        }
    },
    CreateSuccess: IItemComponentCreateSuccessActionCreator = (formData, formState, formProperties, lookups) => {
        return {
            type: ACTION_ITEM_COMPONENTS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups
        }
    },
    CreateFailure: IItemComponentCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_ITEM_COMPONENTS_CREATE_FAILURE,
            responseStatus
        }
    },

    Clear: IItemComponentClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_ITEM_COMPONENTS_CLEAR,
            propertiesToClear
        }
    },

    Filter: IItemComponentFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_ITEM_COMPONENTS_FILTER,
            formFilter
        }
    };



export const
    ACTION_ITEM_COMPONENTS_LOAD = "ACTION_ITEM_COMPONENTS_LOAD",
    ACTION_ITEM_COMPONENTS_LOAD_BY_ID = "ACTION_ITEM_COMPONENTS_LOAD_BY_ID",
    ACTION_ITEM_COMPONENTS_LOAD_SUCCESS = "ACTION_ITEM_COMPONENTS_LOAD_SUCCESS",
    ACTION_ITEM_COMPONENTS_LOAD_FAILURE = "ACTION_ITEM_COMPONENTS_LOAD_FAILURE",

    ACTION_ITEM_COMPONENTS_SAVE = "ACTION_ITEM_COMPONENTS_SAVE",
    ACTION_ITEM_COMPONENTS_SAVE_SUCCESS = "ACTION_ITEM_COMPONENTS_SAVE_SUCCESS",
    ACTION_ITEM_COMPONENTS_SAVE_FAILURE = "ACTION_ITEM_COMPONENTS_SAVE_FAILURE",

    ACTION_ITEM_COMPONENTS_CREATE = "ACTION_ITEM_COMPONENTS_CREATE",
    ACTION_ITEM_COMPONENTS_CREATE_SUCCESS = "ACTION_ITEM_COMPONENTS_CREATE_SUCCESS",
    ACTION_ITEM_COMPONENTS_CREATE_FAILURE = "ACTION_ITEM_COMPONENTS_CREATE_FAILURE",

    ACTION_ITEM_COMPONENTS_CLEAR = "ACTION_ITEM_COMPONENTS_CLEAR",

    ACTION_ITEM_COMPONENTS_FILTER = "ACTION_ITEM_COMPONENTS_FILTER";

export interface IItemComponentLoadAction {
    type: "ACTION_ITEM_COMPONENTS_LOAD";
    qaItemComponents: boolean,
    itemComponentTypeId: number
}

export interface IItemComponentLoadActionCreator {
    (
        qaItemComponents: boolean,
        itemComponentTypeId: number
    ): IItemComponentLoadAction;
}

export interface IItemComponentLoadSuccessAction {
    type: "ACTION_ITEM_COMPONENTS_LOAD_SUCCESS";
    formData: Dtos.ItemComponent | Dtos.ItemComponent[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IItemComponentLoadSuccessActionCreator {
    (
        formData: Dtos.ItemComponent | Dtos.ItemComponent[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IItemComponentLoadSuccessAction;
}

export interface IItemComponentLoadFailureAction {
    type: "ACTION_ITEM_COMPONENTS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IItemComponentLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IItemComponentLoadFailureAction;
}

export interface IItemComponentLoadByIdAction {
    type: "ACTION_ITEM_COMPONENTS_LOAD_BY_ID";
    id: number;
}

export interface IItemComponentLoadByIdActionCreator {
    (id: number): IItemComponentLoadByIdAction;
}


export interface IItemComponentSaveAction {
    type: "ACTION_ITEM_COMPONENTS_SAVE";
    formData: Dtos.ItemComponent;

}

export interface IItemComponentSaveActionCreator {
    (
        formData: Dtos.ItemComponent
    ): IItemComponentSaveAction;
}

export interface IItemComponentSaveSuccessAction {
    type: "ACTION_ITEM_COMPONENTS_SAVE_SUCCESS";
    formData: Dtos.ItemComponent;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IItemComponentSaveSuccessActionCreator {
    (
        formData: Dtos.ItemComponent,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IItemComponentSaveSuccessAction;
}

export interface IItemComponentSaveFailureAction {
    type: "ACTION_ITEM_COMPONENTS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IItemComponentSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IItemComponentSaveFailureAction;
}

export interface IItemComponentCreateAction {
    type: "ACTION_ITEM_COMPONENTS_CREATE";
    cloneId: number;
}

export interface IItemComponentCreateActionCreator {
    (cloneId: number): IItemComponentCreateAction
}

export interface IItemComponentCreateSuccessAction {
    type: "ACTION_ITEM_COMPONENTS_CREATE_SUCCESS";
    formData: Dtos.ItemComponent;
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[]
}

export interface IItemComponentCreateSuccessActionCreator {
    (
        formData: Dtos.ItemComponent,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[]
    ): IItemComponentCreateSuccessAction;
}

export interface IItemComponentCreateFailureAction {
    type: "ACTION_ITEM_COMPONENTS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IItemComponentCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IItemComponentCreateFailureAction;
}

export interface IItemComponentClearAction {
    type: "ACTION_ITEM_COMPONENTS_CLEAR";
    propertiesToClear?: string[];
}

export interface IItemComponentClearActionCreator {
    (propertiesToClear?: string[]): IItemComponentClearAction;
}

export interface IItemComponentFilterActionCreator {
    (formFilter: IFormFilter): IItemComponentFilterAction;
}

export interface IItemComponentFilterAction {
    type: "ACTION_ITEM_COMPONENTS_FILTER";
    formFilter: IFormFilter;
}

export type ItemComponentAction =
    IItemComponentLoadAction |
    IItemComponentLoadByIdAction |
    IItemComponentLoadFailureAction |
    IItemComponentLoadSuccessAction |
    IItemComponentSaveAction |
    IItemComponentSaveSuccessAction |
    IItemComponentSaveFailureAction |
    IItemComponentCreateAction |
    IItemComponentCreateSuccessAction |
    IItemComponentCreateFailureAction |
    IItemComponentFilterAction |
    IItemComponentClearAction;
