import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const openClinicaUserRolesApi = {
    getOpenClinicaUserRoles: (): Observable<Dtos.GetOpenClinicaUserRolesResponse> => {
        //Create the request.
        let request: Dtos.GetOpenClinicaUserRoles = new Dtos.GetOpenClinicaUserRoles();

        //Send request.
        const response: Promise<Dtos.GetOpenClinicaUserRolesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    rebuildtOpenClinicaUserRole: (personnelIds: number[]): Observable<Dtos.SetOpenClinicaUserRoleRebuildResponse> => {

        let request: Dtos.SetOpenClinicaUserRoleRebuild = new Dtos.SetOpenClinicaUserRoleRebuild();

        request.personnelIds = personnelIds;

        // send request
        const response: Promise<Dtos.SetOpenClinicaUserRoleRebuildResponse> = client.post(request);

        return Observable.from(response);
    },
    changeStatusOpenClinicaUserRole: (id: number, ocSyncStatus: Dtos.OCSyncStatus): Observable<Dtos.PostOpenClinicaUserRoleChangeStatusResponse> => {

        let request: Dtos.PostOpenClinicaUserRoleChangeStatus = new Dtos.PostOpenClinicaUserRoleChangeStatus();

        request.id = id;
        request.ocSyncStatus = ocSyncStatus;

        // send request
        const response: Promise<Dtos.PostOpenClinicaUserRoleChangeStatusResponse> = client.post(request);

        return Observable.from(response);
    },
    resendEmailOpenClinicaUserRole: (id: number): Observable<Dtos.PostOpenClinicaUserRoleResendEmailResponse> => {

        let request: Dtos.PostOpenClinicaUserRoleResendEmail = new Dtos.PostOpenClinicaUserRoleResendEmail();

        request.id = id;

        // send request
        const response: Promise<Dtos.PostOpenClinicaUserRoleResendEmailResponse> = client.post(request);

        return Observable.from(response);
    },
    importOpenClinciaUsers: (): Observable<Dtos.PostOpenClinicaUserRoleImportResponse> => {
        let request: Dtos.PostOpenClinicaUserRoleImport = new Dtos.PostOpenClinicaUserRoleImport();

        // send request
        const response: Promise<Dtos.PostOpenClinicaUserRoleImportResponse> = client.post(request);

        return Observable.from(response);
    }
}