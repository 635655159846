import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_PATIENT_LOAD,
    ACTION_PATIENT_LOAD_BY_ID,
    ACTION_PATIENT_IMPORT,

    ACTION_PATIENT_SAVE,

    ACTION_PATIENT_CREATE,

    IPatientLoadAction,
    IPatientLoadByIdAction,
    IPatientLoadFailureAction,
    IPatientLoadSuccessAction,

    IPatientSaveAction,
    IPatientSaveSuccessAction,
    IPatientSaveFailureAction,

    IPatientCreateAction,
    IPatientCreateSuccessAction,
    IPatientCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

    IPatientImportAction,
    IPatientImportSuccessAction,
    IPatientImportFailureAction,

    ImportSuccess,
    ImportFailure,

} from "../actions/patient";
import { patientsApi } from "../services/patients";


export const
    getPatientsEpic = (action$): Observable<IPatientLoadSuccessAction | IPatientLoadFailureAction> => {
        return action$
            .ofType(ACTION_PATIENT_LOAD)
            .mergeMap((action: IPatientLoadAction) => {
                return patientsApi
                    .getPatients(action.trialId, action.institutionId)
                    .map(response =>
                        LoadSuccess(response.patients, undefined, [], [], response.lookups, undefined)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getPatientByIdEpic = (action$): Observable<IPatientLoadSuccessAction | IPatientLoadFailureAction> => {
        return action$
            .ofType(ACTION_PATIENT_LOAD_BY_ID)
            .mergeMap((action: IPatientLoadByIdAction) => {
                return patientsApi
                    .getPatientById(action.id)
                    .map(response =>
                        LoadSuccess(response.patient, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    postPatientsImportEpic = (action$): Observable<IPatientImportSuccessAction | IPatientImportFailureAction> => {
        return action$
            .ofType(ACTION_PATIENT_IMPORT)
            .mergeMap((action: IPatientImportAction) => {
                return patientsApi
                    .importPatients(action.trialId)
                    .map(response =>
                        ImportSuccess(undefined)
                    )
                    .catch(error =>
                        Observable.of(ImportFailure(error.responseStatus))
                    )
            });
    },
    setPatientEpic = (action$): Observable<IPatientSaveSuccessAction | IPatientSaveFailureAction> => {
        return action$
            .ofType(ACTION_PATIENT_SAVE)
            .mergeMap((action: IPatientSaveAction) => {
                return patientsApi
                    .setPatient(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.patient, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createPatientEpic = (action$): Observable<IPatientCreateSuccessAction | IPatientCreateFailureAction> => {
        return action$
            .ofType(ACTION_PATIENT_CREATE)
            .mergeMap((action: IPatientCreateAction) => {
                return patientsApi
                    .createPatient(action.trialId, action.trialInstitutionId, 0)
                    .map(response =>
                        CreateSuccess(response.patient, response.formState, response.formProperties, response.formRoles, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



