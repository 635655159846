import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const professionaldisciplinesApi = {
    getProfessionalDisciplines: (): Observable<Dtos.GetProfessionalDisciplinesResponse> => {
        //Create the request.
        let request: Dtos.GetProfessionalDisciplines = new Dtos.GetProfessionalDisciplines();

        //Send request.
        const response: Promise<Dtos.GetProfessionalDisciplinesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getProfessionalDisciplineById: (id: number): Observable<Dtos.GetProfessionalDisciplineByIdResponse> => {
        //Create the request.
        let request: Dtos.GetProfessionalDisciplineById = new Dtos.GetProfessionalDisciplineById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetProfessionalDisciplineByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setProfessionalDiscipline: (professionalDiscipline: Dtos.ProfessionalDiscipline): Observable<Dtos.SetProfessionalDisciplineResponse> => {

        let request: Dtos.SetProfessionalDiscipline = new Dtos.SetProfessionalDiscipline();

        request.professionalDiscipline = professionalDiscipline;

        // send request
        const response: Promise<Dtos.SetProfessionalDisciplineResponse> = client.post(request);

        return Observable.from(response);

    },
    createProfessionalDiscipline: (): Observable<Dtos.CreateProfessionalDisciplineResponse> => {
        //Create the request.
        let request: Dtos.CreateProfessionalDiscipline = new Dtos.CreateProfessionalDiscipline();

        //Send request.
        const response: Promise<Dtos.CreateProfessionalDisciplineResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}