import * as React from "react";
import * as classNames from "classnames";
import * as Dtos from '../../../dtos/Tmd.dtos';
import { actions, FieldAction, ChangeOptions, ModelAction } from 'react-redux-form';
import { IBaseFormInputProps, ValidationMessage } from "../index";

import { FontAwesomeIcons, FontAwesomeIcon } from "../../../constants/fontAwesomeIcons";

export interface IGenericMultiFormGroupProps {
    groupClassName?: string;
    labelClassName?: string;
    fieldClassName?: string;
    label?: string;
    
    removeContainer?: boolean;
}

class GenericMultiFormGroup extends React.PureComponent<IGenericMultiFormGroupProps, any> {
    constructor(props) {
        super(props);
        
    }

    render() {

        const {
            groupClassName,
            fieldClassName,
            labelClassName,
            label,
            children,
            removeContainer,
        } = this.props


        const classes = {
            group: groupClassName ? groupClassName : "row border border-bottom-1 border-top-0 pb-4 pt-4",
            label: labelClassName ? labelClassName : "col-form-label col-sm-3",
            field: fieldClassName ?
                fieldClassName :
                removeContainer ?
                    "" :
                    "col-sm-9"
        }
        
        const formInputs = <div className={classNames(
            classes.field,
            "generic-form-field")}
        >
            {
                children
            }
        </div>

        if (removeContainer) {
            return formInputs;
        }

        return (
            <div
                className={
                    classNames(
                        "form-group",
                        classes.group
                    )
                }
            >
                <label
                    className={classNames(
                        classes.label,
                    )}
                >
                    {label}

                </label>
                {formInputs}
            </div>
        );
    }

}

export default GenericMultiFormGroup;