import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadReportingTagCategory: IReportingTagCategoryLoadActionCreator = () => {
        return {
            type: ACTION_REPORTINGTAGCATEGORY_LOAD
        }
    },
    LoadReportingTagCategoryById: IReportingTagCategoryLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_REPORTINGTAGCATEGORY_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IReportingTagCategoryLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_REPORTINGTAGCATEGORY_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IReportingTagCategoryLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_REPORTINGTAGCATEGORY_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveReportingTagCategory: IReportingTagCategorySaveActionCreator = (formData) => {
        return {
            type: ACTION_REPORTINGTAGCATEGORY_SAVE,
            formData
        }
    },
    SaveSuccess: IReportingTagCategorySaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_REPORTINGTAGCATEGORY_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IReportingTagCategorySaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_REPORTINGTAGCATEGORY_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateReportingTagCategory: IReportingTagCategoryCreateActionCreator = () => {

        return {
            type: ACTION_REPORTINGTAGCATEGORY_CREATE
        }
    },
    CreateSuccess: IReportingTagCategoryCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_REPORTINGTAGCATEGORY_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: IReportingTagCategoryCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_REPORTINGTAGCATEGORY_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IReportingTagCategoryClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_REPORTINGTAGCATEGORY_CLEAR,
            propertiesToClear
        }
    },
    Filter: IReportingTagCategoryFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_REPORTINGTAGCATEGORY_FILTER,
            formFilter
        }
    };



export const
    ACTION_REPORTINGTAGCATEGORY_LOAD = "ACTION_REPORTINGTAGCATEGORY_LOAD",
    ACTION_REPORTINGTAGCATEGORY_LOAD_BY_ID = "ACTION_REPORTINGTAGCATEGORY_LOAD_BY_ID",
    ACTION_REPORTINGTAGCATEGORY_LOAD_SUCCESS = "ACTION_REPORTINGTAGCATEGORY_LOAD_SUCCESS",
    ACTION_REPORTINGTAGCATEGORY_LOAD_FAILURE = "ACTION_REPORTINGTAGCATEGORY_LOAD_FAILURE",

    ACTION_REPORTINGTAGCATEGORY_SAVE = "ACTION_REPORTINGTAGCATEGORY_SAVE",
    ACTION_REPORTINGTAGCATEGORY_SAVE_SUCCESS = "ACTION_REPORTINGTAGCATEGORY_SAVE_SUCCESS",
    ACTION_REPORTINGTAGCATEGORY_SAVE_FAILURE = "ACTION_REPORTINGTAGCATEGORY_SAVE_FAILURE",

    ACTION_REPORTINGTAGCATEGORY_CREATE = "ACTION_REPORTINGTAGCATEGORY_CREATE",
    ACTION_REPORTINGTAGCATEGORY_CREATE_SUCCESS = "ACTION_REPORTINGTAGCATEGORY_CREATE_SUCCESS",
    ACTION_REPORTINGTAGCATEGORY_CREATE_FAILURE = "ACTION_REPORTINGTAGCATEGORY_CREATE_FAILURE",

    ACTION_REPORTINGTAGCATEGORY_CLEAR = "ACTION_REPORTINGTAGCATEGORY_CLEAR",

    ACTION_REPORTINGTAGCATEGORY_FILTER = "ACTION_REPORTINGTAGCATEGORY_FILTER";

export interface IReportingTagCategoryLoadAction {
    type: "ACTION_REPORTINGTAGCATEGORY_LOAD";
}

export interface IReportingTagCategoryLoadActionCreator {
    (): IReportingTagCategoryLoadAction;
}

export interface IReportingTagCategoryLoadSuccessAction {
    type: "ACTION_REPORTINGTAGCATEGORY_LOAD_SUCCESS";
    formData: Dtos.ReportingTagCategory | Dtos.ReportingTagCategory[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IReportingTagCategoryLoadSuccessActionCreator {
    (
        formData: Dtos.ReportingTagCategory | Dtos.ReportingTagCategory[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IReportingTagCategoryLoadSuccessAction;
}

export interface IReportingTagCategoryLoadFailureAction {
    type: "ACTION_REPORTINGTAGCATEGORY_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IReportingTagCategoryLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IReportingTagCategoryLoadFailureAction;
}

export interface IReportingTagCategoryLoadByIdAction {
    type: "ACTION_REPORTINGTAGCATEGORY_LOAD_BY_ID";
    id: number;
}

export interface IReportingTagCategoryLoadByIdActionCreator {
    (id: number): IReportingTagCategoryLoadByIdAction;
}


export interface IReportingTagCategorySaveAction {
    type: "ACTION_REPORTINGTAGCATEGORY_SAVE";
    formData: Dtos.ReportingTagCategory;

}

export interface IReportingTagCategorySaveActionCreator {
    (
        formData: Dtos.ReportingTagCategory
    ): IReportingTagCategorySaveAction;
}

export interface IReportingTagCategorySaveSuccessAction {
    type: "ACTION_REPORTINGTAGCATEGORY_SAVE_SUCCESS";
    formData: Dtos.ReportingTagCategory;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IReportingTagCategorySaveSuccessActionCreator {
    (
        formData: Dtos.ReportingTagCategory,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IReportingTagCategorySaveSuccessAction;
}

export interface IReportingTagCategorySaveFailureAction {
    type: "ACTION_REPORTINGTAGCATEGORY_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IReportingTagCategorySaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IReportingTagCategorySaveFailureAction;
}

export interface IReportingTagCategoryCreateAction {
    type: "ACTION_REPORTINGTAGCATEGORY_CREATE"
}

export interface IReportingTagCategoryCreateActionCreator {
    (): IReportingTagCategoryCreateAction
}

export interface IReportingTagCategoryCreateSuccessAction {
    type: "ACTION_REPORTINGTAGCATEGORY_CREATE_SUCCESS";
    formData: Dtos.ReportingTagCategory;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface IReportingTagCategoryCreateSuccessActionCreator {
    (
        formData: Dtos.ReportingTagCategory,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): IReportingTagCategoryCreateSuccessAction;
}

export interface IReportingTagCategoryCreateFailureAction {
    type: "ACTION_REPORTINGTAGCATEGORY_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IReportingTagCategoryCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IReportingTagCategoryCreateFailureAction;
}

export interface IReportingTagCategoryClearAction {
    type: "ACTION_REPORTINGTAGCATEGORY_CLEAR";
    propertiesToClear?: string[];
}

export interface IReportingTagCategoryClearActionCreator {
    (propertiesToClear?: string[]): IReportingTagCategoryClearAction;
}

export interface IReportingTagCategoryFilterActionCreator {
    (formFilter: IFormFilter): IReportingTagCategoryFilterAction;
}

export interface IReportingTagCategoryFilterAction {
    type: "ACTION_REPORTINGTAGCATEGORY_FILTER";
    formFilter: IFormFilter;
}

export type ReportingTagCategoryAction =
    IReportingTagCategoryLoadAction |
    IReportingTagCategoryLoadByIdAction |
    IReportingTagCategoryLoadFailureAction |
    IReportingTagCategoryLoadSuccessAction |
    IReportingTagCategorySaveAction |
    IReportingTagCategorySaveSuccessAction |
    IReportingTagCategorySaveFailureAction |
    IReportingTagCategoryCreateAction |
    IReportingTagCategoryCreateSuccessAction |
    IReportingTagCategoryCreateFailureAction |
    IReportingTagCategoryClearAction |
    IReportingTagCategoryFilterAction;
