import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_INSTITUTIONFORM_LOAD,
    ACTION_INSTITUTIONFORM_LOAD_CURRENT_USER,
    ACTION_INSTITUTIONFORM_LOAD_BY_ID,

    ACTION_INSTITUTIONFORM_SAVE,

    ACTION_INSTITUTIONFORM_CREATE,
    ACTION_INSTITUTIONFORM_CREATEVERSION,

    IInstitutionFormLoadAction,
    IInstitutionFormLoadCurrentUserAction,
    IInstitutionFormLoadByIdAction,
    IInstitutionFormLoadFailureAction,
    IInstitutionFormLoadSuccessAction,

    IInstitutionFormSaveAction,
    IInstitutionFormSaveSuccessAction,
    IInstitutionFormSaveFailureAction,

    IInstitutionFormCreateAction,
    IInstitutionFormCreateSuccessAction,
    IInstitutionFormCreateFailureAction,

    IInstitutionFormCreateVersionAction,
    IInstitutionFormCreateVersionSuccessAction,
    IInstitutionFormCreateVersionFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

    CreateVersionSuccess,
    CreateVersionFailure,

} from "../actions/institutionForm";
import { institutionFormsApi } from "../services/institutionForms";

export const
    getInstitutionFormsEpic = (action$): Observable<IInstitutionFormLoadSuccessAction | IInstitutionFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_INSTITUTIONFORM_LOAD)
            .mergeMap((action: IInstitutionFormLoadAction) => {
                return institutionFormsApi
                    .getInstitutionForms(action.institutionId)
                    .map(response =>
                        LoadSuccess(response.institutionForms, undefined, undefined, [], response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getInstitutionFormCurrentUserEpic = (action$): Observable<IInstitutionFormLoadSuccessAction | IInstitutionFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_INSTITUTIONFORM_LOAD_CURRENT_USER)
            .mergeMap((action: IInstitutionFormLoadCurrentUserAction) => {
                return institutionFormsApi
                    .getInstitutionFormsCurrentUser()
                    .map(response =>
                        LoadSuccess(response.institutionForms, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },

    getInstitutionFormByIdEpic = (action$): Observable<IInstitutionFormLoadSuccessAction | IInstitutionFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_INSTITUTIONFORM_LOAD_BY_ID)
            .mergeMap((action: IInstitutionFormLoadByIdAction) => {
                return institutionFormsApi
                    .getInstitutionFormById(action.id)
                    .map(response =>
                        LoadSuccess(response.institutionForm, response.formState, response.formProperties, [], response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setInstitutionFormEpic = (action$): Observable<IInstitutionFormSaveSuccessAction | IInstitutionFormSaveFailureAction> => {
        return action$
            .ofType(ACTION_INSTITUTIONFORM_SAVE)
            .mergeMap((action: IInstitutionFormSaveAction) => {
                return institutionFormsApi
                    .setInstitutionForm(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.institutionForm, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createInstitutionFormEpic = (action$): Observable<IInstitutionFormCreateSuccessAction | IInstitutionFormCreateFailureAction> => {
        return action$
            .ofType(ACTION_INSTITUTIONFORM_CREATE)
            .mergeMap((action: IInstitutionFormCreateAction) => {
                return institutionFormsApi
                    .createInstitutionForm(action.institutionId)
                    .map(response =>
                        CreateSuccess(response.institutionForm, response.formState, response.formProperties, [], response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    },
    createVersionEpic = (action$): Observable<IInstitutionFormCreateVersionSuccessAction | IInstitutionFormCreateVersionFailureAction> => {
        return action$
            .ofType(ACTION_INSTITUTIONFORM_CREATEVERSION)
            .mergeMap((action: IInstitutionFormCreateVersionAction) => {
                return institutionFormsApi
                    .createVersion(action.institutionFormId, action.version)
                    .map(response =>
                        CreateVersionSuccess(response.institutionForm)
                    )
                    .catch(error =>
                        Observable.of(CreateVersionFailure(error.responseStatus))
                    )
            });
    }



