import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialTrialTreatmentState: Dtos.TrialTreatment & IRegistrationFormState = {
    id: null,
    active: null,
    trialId: null,
    name: null,
    shortName: null,
    duration: null,
    opmsId: -1,
    targetAccrual: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false,
}

export interface ITrialTreatmentReduxFormState {
    id: IFieldState<number>,
    trialId: IFieldState<number>,
    name: IFieldState<string>,
    shortName: IFieldState<string>,
    duration: IFieldState<number>,
    targetAccrual: IFieldState<number>,
    opmsId: IFieldState<number>,
    active: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    openedModals: IFieldState<string[]>,
}
