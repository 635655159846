import * as React from 'react';
import './NoteTypePage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { INoteTypeReduxFormState, initialNoteTypeState } from "../../../reducers/reactReduxForms/noteType";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as NoteTypeActions from '../../../actions/noteType';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { Form, actions, Control } from 'react-redux-form';
import { LookupsLayout } from '../../index';

interface INoteTypePageParams {
    noteTypeId: number;
}

interface INoteTypePageProps {

    form: Dtos.NoteType & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: INoteTypeReduxFormState;

    location: Location;
    noteTypeId: number;
    match: match<INoteTypePageParams>;
    history: History;

    // From state
    noteType: Dtos.NoteType;
    lookups: Dtos.Lookup[];

    loadingNoteType: boolean;
    loadNoteTypeSuccess: boolean;
    loadNoteTypeFailure: boolean;

    savingNoteType: boolean;
    saveNoteTypeSuccess: boolean;
    saveNoteTypeFailure: boolean;

    creatingNoteType: boolean;
    createNoteTypeSuccess: boolean;
    createNoteTypeFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface INoteTypePageActions {
    clearNoteType: NoteTypeActions.INoteTypeClearActionCreator;
    loadNoteType: NoteTypeActions.INoteTypeLoadByIdActionCreator;

    saveNoteType: NoteTypeActions.INoteTypeSaveActionCreator;
    createNoteType: NoteTypeActions.INoteTypeCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type NoteTypePageProps = INoteTypePageProps & INoteTypePageActions;


const formName: string = "notetype";
const reduxFormName: string = "reduxForms.noteType";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class NoteTypePage extends React.PureComponent<NoteTypePageProps, any> {

    constructor(props: NoteTypePageProps) {
        super(props);

        this.clearNoteType = this.clearNoteType.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadNoteType,
            navigate,
            navigateReplace,
            noteTypeId,
            createNoteType
        } = this.props;

        if (noteTypeId) {
            loadNoteType(noteTypeId);
        } else if (!noteTypeId) {
            createNoteType();
        }
    }

    componentWillReceiveProps(nextProps: NoteTypePageProps) {
    }

    componentDidUpdate(prevProps: NoteTypePageProps, prevState) {
        const {
            navigateReplace,
            noteTypeId,
            noteType,
            form,
            navigate,
            loadNoteType,
            loadingNoteType,
            loadNoteTypeSuccess,
            createNoteType,
            creatingNoteType,
            createNoteTypeSuccess,
            savingNoteType,
            saveNoteTypeSuccess,
            saveNoteTypeFailure,
            validationFailures
        } = this.props;

        if (noteTypeId && prevProps.noteTypeId != noteTypeId) {
            loadNoteType(noteTypeId);
        }
        else if (!noteTypeId && prevProps.noteTypeId) {
            createNoteType();
        }

        if (!loadingNoteType && prevProps.loadingNoteType) {
            if (loadNoteTypeSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingNoteType && prevProps.savingNoteType) {
            if (saveNoteTypeSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/settings/general/notetypes")
                } else if (!form.saveAndReturn && noteTypeId && noteTypeId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !noteTypeId && noteType && noteType.id > 0) {
                    navigate("/settings/general/notetypes/" + noteType.id);
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingNoteType && prevProps.creatingNoteType) {
            if (createNoteTypeSuccess) {
                this.loadForm();
            }
            else {

            }
        }

    }

    componentWillUpdate(nextProps: NoteTypePageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearNoteType();
        loadForm(reduxFormName, initialNoteTypeState);
        resetForm(reduxFormName);
    }

    clearNoteType() {
        this.props.clearNoteType();
    }

    showNoteType(): boolean {
        const {
            loadingNoteType,
            loadNoteTypeSuccess
        } = this.props

        if (!loadingNoteType && loadNoteTypeSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingNoteType
        } = this.props;

        return <LookupsLayout loading={loadingNoteType} permission={Dtos.Permission.NoteTypesView}>
            {this.renderContent()}
        </LookupsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingNoteType,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.NoteTypesUpdate, Dtos.Permission.NoteTypesCreate);
        const formDisabled: boolean = !hasPermission || savingNoteType || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            value: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            }
        };

        

        return <div>
            <h1>Note Type</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "notetype", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.NoteType) {
        const {
            saveNoteType
        } = this.props;

        if (data) {
            let changes: Dtos.Institution = {} as Dtos.Institution;
            saveNoteType(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.NoteType) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/general/notetypes");
    }

    loadForm() {
        const {
            loadForm,
            noteType,
        } = this.props

        let extraData: Dtos.NoteType = {} as Dtos.NoteType;

        loadForm(reduxFormName, Object.assign({ ...noteType }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: NoteTypePageProps): INoteTypePageProps => {

    let noteType: Dtos.NoteType | undefined = !(state.noteTypes.formData instanceof Array) ? state.noteTypes.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        noteTypeId: ownProps.match && ownProps.match.params.noteTypeId && ownProps.match.params.noteTypeId.toString() != "create" ? ownProps.match.params.noteTypeId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        noteType: noteType!,

        loadingNoteType: state.noteTypes.loadState && state.noteTypes.loadState.status == RequestState.Pending,
        loadNoteTypeSuccess: state.noteTypes.loadState && state.noteTypes.loadState.status == RequestState.Success,
        loadNoteTypeFailure: state.noteTypes.loadState && state.noteTypes.loadState.status == RequestState.Failure,

        savingNoteType: state.noteTypes.saveState && state.noteTypes.saveState.status == RequestState.Pending,
        saveNoteTypeSuccess: state.noteTypes.saveState && state.noteTypes.saveState.status == RequestState.Success,
        saveNoteTypeFailure: state.noteTypes.saveState && state.noteTypes.saveState.status == RequestState.Failure,

        creatingNoteType: state.noteTypes.createState && state.noteTypes.createState.status == RequestState.Pending,
        createNoteTypeSuccess: state.noteTypes.createState && state.noteTypes.createState.status == RequestState.Success,
        createNoteTypeFailure: state.noteTypes.createState && state.noteTypes.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: [],

        form: state.reduxForms.noteType,
        formState: state.noteTypes.formState,
        formProperties: state.noteTypes.formProperties,

        reduxFormState: state.reduxForms.formState.noteType,
        validationFailures: noteType ?
            state.noteTypes.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): INoteTypePageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadNoteType: bindActionCreators(NoteTypeActions.LoadNoteTypeById, dispatch),
        clearNoteType: bindActionCreators(NoteTypeActions.Clear, dispatch),

        saveNoteType: bindActionCreators(NoteTypeActions.SaveNoteType, dispatch),
        createNoteType: bindActionCreators(NoteTypeActions.CreateNoteType, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(NoteTypePage);
