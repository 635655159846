import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const groupsApi = {
    getGroups: (): Observable<Dtos.GetGroupsResponse> => {
        //Create the request.
        let request: Dtos.GetGroups = new Dtos.GetGroups();

        //Send request.
        const response: Promise<Dtos.GetGroupsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getGroupById: (id: number): Observable<Dtos.GetGroupByIdResponse> => {
        //Create the request.
        let request: Dtos.GetGroupById = new Dtos.GetGroupById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetGroupByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setGroup: (group: Dtos.Group): Observable<Dtos.SetGroupResponse> => {

        let request: Dtos.SetGroup = new Dtos.SetGroup();

        request.group = group;

        // send request
        const response: Promise<Dtos.SetGroupResponse> = client.post(request);

        return Observable.from(response);

    },
    createGroup: (): Observable<Dtos.CreateGroupResponse> => {
        //Create the request.
        let request: Dtos.CreateGroup = new Dtos.CreateGroup();

        //Send request.
        const response: Promise<Dtos.CreateGroupResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}