import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Tmd.dtos';

import {
    ACTION_INSTITUTIONFORM_LOAD,
    ACTION_INSTITUTIONFORM_LOAD_CURRENT_USER,
    ACTION_INSTITUTIONFORM_LOAD_BY_ID,
    ACTION_INSTITUTIONFORM_LOAD_FAILURE,
    ACTION_INSTITUTIONFORM_LOAD_SUCCESS,

    ACTION_INSTITUTIONFORM_SAVE,
    ACTION_INSTITUTIONFORM_SAVE_SUCCESS,
    ACTION_INSTITUTIONFORM_SAVE_FAILURE,

    ACTION_INSTITUTIONFORM_CREATE,
    ACTION_INSTITUTIONFORM_CREATE_SUCCESS,
    ACTION_INSTITUTIONFORM_CREATE_FAILURE,

    ACTION_INSTITUTIONFORM_CREATEVERSION,
    ACTION_INSTITUTIONFORM_CREATEVERSION_SUCCESS,
    ACTION_INSTITUTIONFORM_CREATEVERSION_FAILURE,

    ACTION_INSTITUTIONFORM_CLEAR,
    ACTION_INSTITUTIONFORM_FILTER,

    InstitutionFormAction
} from '../actions/institutionForm';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState, IFormFilter } from "./index";
import { updateFailureIndexToId } from "../helpers/formErrorHelper";

export interface IInstitutionFormState {
    formData: Dtos.InstitutionForm | Dtos.InstitutionForm[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    formRoles: Dtos.RoleType[],
    validationFailures: Dtos.ResponseError[];
    lookups: Dtos.Lookup[],
    loadState: IRequestState;
    saveState: IRequestState;
    updateState: IRequestState;
    createState: IRequestState;
    formFilter: IFormFilter;
    formFilteredData: Dtos.InstitutionForm[];
    formLength: number;
}

const initialState: IInstitutionFormState = {
    formData: undefined,
    formState: undefined,
    formProperties: undefined,
    formRoles: undefined,
    validationFailures: undefined,
    lookups: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    updateState: {
        status: RequestState.None
    } as IRequestState,
    createState: {
        status: RequestState.None
    } as IRequestState,
    formFilter: {
        search: "",
        showInActive: false
    },
    formFilteredData: [],
    formLength: 0
};

function loading(state: IInstitutionFormState, isLoading: boolean): IInstitutionFormState {
    const newState: IInstitutionFormState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(
    state: IInstitutionFormState,
    formData: Dtos.InstitutionForm | Dtos.InstitutionForm[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    formRoles: Dtos.RoleType[],
    lookups: Dtos.Lookup[],
    responseStatus: Dtos.ResponseStatus
): IInstitutionFormState {
    const newState: IInstitutionFormState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        formRoles: {
            $set: formRoles
        },
        lookups: {
            $set: lookups
        },
        loadState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return filter(newState, state.formFilter);
}

function loadFailure(state: IInstitutionFormState, responseStatus: Dtos.ResponseStatus): IInstitutionFormState {
    const newState: IInstitutionFormState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function filter(state: IInstitutionFormState, formFilter: IFormFilter): IInstitutionFormState {

    const items: Dtos.InstitutionForm[] | undefined =
        state.formData instanceof Array ?
            state.formData :
            undefined;

    const formLength = items ? items.length : 0;

    const formFilteredData = items ? items.filter(p => (p.active == true || formFilter.showInActive) &&
        (formFilter.search == "" ||
            p.formTemplate.name.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1)) : undefined;

    const newState: IInstitutionFormState = update(state, {
        formFilter: {
            $set: formFilter
        },
        formFilteredData: {
            $set: formFilteredData
        },
        formLength: {
            $set: formLength
        }
    });

    return newState;
}

function clear(state: IInstitutionFormState, propertiesToClear?: string[]): IInstitutionFormState {

    if (!propertiesToClear) {
        const newState: IInstitutionFormState = update(initialState, {});
        return newState;
    }

    let stateModifier: any = {};

    propertiesToClear.forEach(propertyToClear => {
        stateModifier[propertyToClear] = { $set: initialState[propertyToClear] }
    });

    const newState = update(state, stateModifier);

    return newState;
}

function saving(state: IInstitutionFormState, isSaving: boolean): IInstitutionFormState {
    const newState: IInstitutionFormState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function saveSuccess(
    state: IInstitutionFormState,
    formData: Dtos.InstitutionForm | Dtos.InstitutionForm[],
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus
): IInstitutionFormState {
    const newState: IInstitutionFormState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        saveState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function saveFailure(state: IInstitutionFormState, responseStatus: Dtos.ResponseStatus): IInstitutionFormState {
    const newState: IInstitutionFormState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                meta: responseStatus.meta
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, state.formData) : undefined
        }
    });

    return newState;
}

function creating(state: IInstitutionFormState, isCreating: boolean): IInstitutionFormState {
    const status = isCreating ? RequestState.Pending : RequestState.Success;
    const newState: IInstitutionFormState = update(state, {
        createState: {
            $set: {
                status: status
            } as IRequestState
        }
    });

    return newState;
}

function createSuccess(
    state: IInstitutionFormState,
    formData: Dtos.InstitutionForm,
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    formRoles: Dtos.RoleType[],
    lookups: Dtos.Lookup[]
): IInstitutionFormState {
    const newState: IInstitutionFormState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        formRoles: {
            $set: formRoles
        },
        lookups: {
            $set: lookups
        },
        createState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function createFailure(state: IInstitutionFormState, responseStatus: Dtos.ResponseStatus): IInstitutionFormState {
    const newState: IInstitutionFormState = update(state, {
        createState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

const InstitutionFormReducer: Reducer<IInstitutionFormState> = (state: IInstitutionFormState = initialState, action: InstitutionFormAction) => {
    switch (action.type) {
        case ACTION_INSTITUTIONFORM_LOAD:
        case ACTION_INSTITUTIONFORM_LOAD_CURRENT_USER:
        case ACTION_INSTITUTIONFORM_LOAD_BY_ID:
            return loading(state, true);
        case ACTION_INSTITUTIONFORM_LOAD_SUCCESS:
            return loadSuccess(state, action.formData, action.formState, action.formProperties, action.formRoles, action.lookups, action.responseStatus);
        case ACTION_INSTITUTIONFORM_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_INSTITUTIONFORM_CLEAR:
            return clear(state, action.propertiesToClear);
        case ACTION_INSTITUTIONFORM_SAVE:
            return saving(state, true);
        case ACTION_INSTITUTIONFORM_SAVE_SUCCESS:
            return saveSuccess(state, action.formData, action.formState, action.responseStatus);
        case ACTION_INSTITUTIONFORM_SAVE_FAILURE:
            return saveFailure(state, action.responseStatus);
        case ACTION_INSTITUTIONFORM_CREATEVERSION:
        case ACTION_INSTITUTIONFORM_CREATE:
            return creating(state, true);
        case ACTION_INSTITUTIONFORM_CREATEVERSION_SUCCESS:
            return creating(state, false);
        case ACTION_INSTITUTIONFORM_CREATE_SUCCESS:
            return createSuccess(state, action.formData, action.formState, action.formProperties, action.formRoles, action.lookups);
        case ACTION_INSTITUTIONFORM_CREATEVERSION_FAILURE:
        case ACTION_INSTITUTIONFORM_CREATE_FAILURE:
            return createFailure(state, action.responseStatus);
        case ACTION_INSTITUTIONFORM_FILTER:
            return filter(state, action.formFilter);

    }

    return state;
}

export default InstitutionFormReducer;