import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_CATEGORIES_LOAD,
    ACTION_CATEGORIES_LOAD_BY_ID,

    ACTION_CATEGORIES_SAVE,

    ACTION_CATEGORIES_CREATE,

    ICategoryLoadAction,
    ICategoryLoadByIdAction,
    ICategoryLoadFailureAction,
    ICategoryLoadSuccessAction,

    ICategorySaveAction,
    ICategorySaveSuccessAction,
    ICategorySaveFailureAction,

    ICategoryCreateAction,
    ICategoryCreateSuccessAction,
    ICategoryCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/categories";
import { categoriesApi } from "../services/categories";


export const
    getCategoriesEpic = (action$): Observable<ICategoryLoadSuccessAction | ICategoryLoadFailureAction> => {
        return action$
            .ofType(ACTION_CATEGORIES_LOAD)
            .mergeMap((action: ICategoryLoadAction) => {
                return categoriesApi
                    .getCategories()
                    .map(response =>
                        LoadSuccess(response.categories, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getCategoryByIdEpic = (action$): Observable<ICategoryLoadSuccessAction | ICategoryLoadFailureAction> => {
        return action$
            .ofType(ACTION_CATEGORIES_LOAD_BY_ID)
            .mergeMap((action: ICategoryLoadByIdAction) => {
                return categoriesApi
                    .getCategoryById(action.id)
                    .map(response =>
                        LoadSuccess(response.category, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setCategoryEpic = (action$): Observable<ICategorySaveSuccessAction | ICategorySaveFailureAction> => {
        return action$
            .ofType(ACTION_CATEGORIES_SAVE)
            .mergeMap((action: ICategorySaveAction) => {
                return categoriesApi
                    .setCategory(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.category, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createCategoryEpic = (action$): Observable<ICategoryCreateSuccessAction | ICategoryCreateFailureAction> => {
        return action$
            .ofType(ACTION_CATEGORIES_CREATE)
            .mergeMap((action: ICategoryCreateAction) => {
                return categoriesApi
                    .createCategory()
                    .map(response =>
                        CreateSuccess(
                            response.category,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



