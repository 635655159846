import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Tmd.dtos';
import { IFormFilter } from "../reducers/index";
import * as trialInstitutionActions from '../actions/trialInstitution';

import {
    ACTION_TRIALINSTITUTIONITEM_LOAD_BY_ID,
    ACTION_TRIALINSTITUTIONITEM_LOAD_FAILURE,
    ACTION_TRIALINSTITUTIONITEM_LOAD_SUCCESS,

    ACTION_TRIALINSTITUTIONITEM_SAVE,
    ACTION_TRIALINSTITUTIONITEM_SAVE_SUCCESS,
    ACTION_TRIALINSTITUTIONITEM_SAVE_FAILURE,

    ACTION_TRIALINSTITUTIONITEM_CREATE,
    ACTION_TRIALINSTITUTIONITEM_CREATE_SUCCESS,
    ACTION_TRIALINSTITUTIONITEM_CREATE_FAILURE,

    ACTION_TRIALINSTITUTIONITEM_CLEAR,
    ACTION_TRIALINSTITUTIONITEM_FILTER,

    TrialInstitutionItemAction
} from '../actions/trialInstitutionItem';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState } from "./index";
import { updateFailureIndexToId } from "../helpers/formErrorHelper";

export interface ITrialInstitutionItemState {
    formData: Dtos.TrialInstitutionItem | Dtos.TrialInstitutionItem[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    validationFailures: Dtos.ResponseError[];
    lookups: Dtos.Lookup[],
    loadState: IRequestState;
    saveState: IRequestState;
    updateState: IRequestState;
    createState: IRequestState;
    formFilter: IFormFilter;
    formDataByCategory: Dtos.TrialItemCategory[];
    formFilteredData: Dtos.TrialItemCategory[];
}

const initialState: ITrialInstitutionItemState = {
    formData: undefined,
    formState: undefined,
    formProperties: undefined,
    validationFailures: undefined,
    lookups: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    updateState: {
        status: RequestState.None
    } as IRequestState,
    createState: {
        status: RequestState.None
    } as IRequestState,
    formFilter: {
        search: "",
        showInActive: false
    },
    formDataByCategory: [],
    formFilteredData: []
};


function loading(state: ITrialInstitutionItemState, isLoading: boolean): ITrialInstitutionItemState {
    const newState: ITrialInstitutionItemState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(
    state: ITrialInstitutionItemState,
    formData: Dtos.TrialInstitutionItem | Dtos.TrialInstitutionItem[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[],
    responseStatus: Dtos.ResponseStatus
): ITrialInstitutionItemState {
    const newState: ITrialInstitutionItemState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        loadState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function loadFailure(state: ITrialInstitutionItemState, responseStatus: Dtos.ResponseStatus): ITrialInstitutionItemState {
    const newState: ITrialInstitutionItemState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: ITrialInstitutionItemState, propertiesToClear?: string[]): ITrialInstitutionItemState {

    if (!propertiesToClear) {
        const newState: ITrialInstitutionItemState = update(initialState, {});
        return newState;
    }

    let stateModifier: any = {};

    propertiesToClear.forEach(propertyToClear => {
        stateModifier[propertyToClear] = { $set: initialState[propertyToClear] }
    });

    const newState = update(state, stateModifier);

    return newState;
}

function saving(state: ITrialInstitutionItemState, isSaving: boolean): ITrialInstitutionItemState {
    const newState: ITrialInstitutionItemState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function saveSuccess(
    state: ITrialInstitutionItemState,
    formData: Dtos.TrialInstitutionItem | Dtos.TrialInstitutionItem[],
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus
): ITrialInstitutionItemState {
    const newState: ITrialInstitutionItemState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        saveState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function saveFailure(state: ITrialInstitutionItemState, responseStatus: Dtos.ResponseStatus): ITrialInstitutionItemState {
    const newState: ITrialInstitutionItemState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                meta: responseStatus.meta
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, state.formData) : undefined
        }
    });

    return newState;
}

function creating(state: ITrialInstitutionItemState, isCreating: boolean): ITrialInstitutionItemState {
    const newState: ITrialInstitutionItemState = update(state, {
        createState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function createSuccess(
    state: ITrialInstitutionItemState,
    formData: Dtos.TrialInstitutionItem,
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[]
): ITrialInstitutionItemState {
    const newState: ITrialInstitutionItemState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        createState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function createFailure(state: ITrialInstitutionItemState, responseStatus: Dtos.ResponseStatus): ITrialInstitutionItemState {
    const newState: ITrialInstitutionItemState = update(state, {
        createState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function loadTrialInstutitionSuccess(
    state: ITrialInstitutionItemState,
    formData: Dtos.TrialInstitution | Dtos.TrialInstitution[],
): ITrialInstitutionItemState {

    let formDataByCategory: Dtos.TrialItemCategory[] = [];

    const trialInstitution: Dtos.TrialInstitution = formData instanceof Array ? undefined : formData;
    if (trialInstitution && trialInstitution.trialItemCategories) {
        formDataByCategory = [...trialInstitution.trialItemCategories];
    }

    const newState: ITrialInstitutionItemState = update(state, {
        formDataByCategory: {
            $set: formDataByCategory
        }
    });

    return filter(newState, state.formFilter);
}

function filter(state: ITrialInstitutionItemState, formFilter: IFormFilter): ITrialInstitutionItemState {

    let formFilteredData: Dtos.TrialItemCategory[] = [];

    const formData: Dtos.TrialItemCategory[] = state.formDataByCategory;

    if (!formData) return state;

    // filter the sub items
    formData.forEach(item => {
        let newItem = { ...item };
        if (newItem.trialItemSummaries) {
            let trialItemInstanceSummaries = [...newItem.trialItemSummaries];
            trialItemInstanceSummaries = trialItemInstanceSummaries.filter(p =>
                (p.active == true || formFilter.showInActive) &&
                (formFilter.search == "" || p.name.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1 || p.description.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1)
            );
            newItem.trialItemSummaries = trialItemInstanceSummaries;
        }
        formFilteredData.push(newItem);
    });

    // filter the categories
    formFilteredData = formFilteredData.filter(p =>
        (p.trialItemSummaries && p.trialItemSummaries.length > 0)
    );

    const newState: ITrialInstitutionItemState = update(state, {
        formFilter: {
            $set: formFilter
        },
        formFilteredData: {
            $set: formFilteredData
        }
    });

    return newState;
}


const TrialInstitutionItemReducer: Reducer<ITrialInstitutionItemState> = (state: ITrialInstitutionItemState = initialState, action: TrialInstitutionItemAction | trialInstitutionActions.TrialInstitutionAction) => {
    switch (action.type) {
        case ACTION_TRIALINSTITUTIONITEM_LOAD_BY_ID:
            return loading(state, true);
        case ACTION_TRIALINSTITUTIONITEM_LOAD_SUCCESS:
            return loadSuccess(state, action.formData, action.formState, action.formProperties, action.lookups, action.responseStatus);
        case ACTION_TRIALINSTITUTIONITEM_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_TRIALINSTITUTIONITEM_CLEAR:
            return clear(state, action.propertiesToClear);
        case ACTION_TRIALINSTITUTIONITEM_SAVE:
            return saving(state, true);
        case ACTION_TRIALINSTITUTIONITEM_SAVE_SUCCESS:
            return saveSuccess(state, action.formData, action.formState, action.responseStatus);
        case ACTION_TRIALINSTITUTIONITEM_SAVE_FAILURE:
            return saveFailure(state, action.responseStatus);
        case ACTION_TRIALINSTITUTIONITEM_CREATE:
            return creating(state, true);
        case ACTION_TRIALINSTITUTIONITEM_CREATE_SUCCESS:
            return createSuccess(state, action.formData, action.formState, action.formProperties, action.lookups);
        case ACTION_TRIALINSTITUTIONITEM_CREATE_FAILURE:
            return createFailure(state, action.responseStatus);


        case ACTION_TRIALINSTITUTIONITEM_FILTER:
            return filter(state, action.formFilter);
        case trialInstitutionActions.ACTION_TRIALINSTITUTION_LOAD_SUCCESS:
            return loadTrialInstutitionSuccess(state, action.formData);
    }

    return state;
}

export default TrialInstitutionItemReducer;