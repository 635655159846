import * as React from 'react';

import * as classNames from "classnames";
import './TrialInstitutionItemCreateVersion.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";



import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { SubControlLayout } from "../../../components/layouts/index";
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu, SubPageLayout } from "../../../components/layouts/index";
// add state for sub forms too below
import { ITrialInstitutionItemReduxFormState, initialTrialInstitutionItemState } from "../../../reducers/reactReduxForms/trialInstitutionItem";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";

import * as TrialInstitutionItemActions from '../../../actions/trialInstitutionItem';
import * as TrialActions from '../../../actions/trial';

import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../../components/form';
import { TrialSubMenu } from '../../../helpers/subMenuHelper';


interface ITrialInstitutionItemCreateVersionProps {
    trialInstitutionItemId?: number;
    trialInstitutionId?: number;
    trialItemId?: number;

    form?: Dtos.TrialInstitutionItem & IRegistrationFormState;
    formState?: Dtos.FormState;
    formProperties?: Dtos.FormProperty[];
    reduxFormState?: ITrialInstitutionItemReduxFormState;

    location?: Location;
    history?: History;

    // From state
    trialInstitutionItem?: Dtos.TrialInstitutionItem;
    lookups?: Dtos.Lookup[];

    loadingTrialInstitutionItem?: boolean;
    loadTrialInstitutionItemSuccess?: boolean;
    loadTrialInstitutionItemFailure?: boolean;

    savingTrialInstitutionItem?: boolean;
    saveTrialInstitutionItemSuccess?: boolean;
    saveTrialInstitutionItemFailure?: boolean;

    creatingTrialInstitutionItem?: boolean;
    createTrialInstitutionItemSuccess?: boolean;
    createTrialInstitutionItemFailure?: boolean;

    validationFailures?: Dtos.ResponseError[];

    onClose?: Function;

    user?: Dtos.User
}

interface ITrialInstitutionItemCreateVersionActions {
    clearTrialInstitutionItem?: TrialInstitutionItemActions.ITrialInstitutionItemClearActionCreator;
    loadTrialInstitutionItem?: TrialInstitutionItemActions.ITrialInstitutionItemLoadByIdActionCreator;
    saveTrialInstitutionItem?: TrialInstitutionItemActions.ITrialInstitutionItemSaveActionCreator;
    createTrialInstitutionItem?: TrialInstitutionItemActions.ITrialInstitutionItemCreateActionCreator;

    loadForm?: typeof actions.load;
    changeForm?: typeof actions.change;
    resetForm?: typeof actions.reset;

    navigate?: typeof routerActions.push;
    navigateReplace?: typeof routerActions.replace;

}

type TrialInstitutionItemCreateVersionProps = ITrialInstitutionItemCreateVersionProps & ITrialInstitutionItemCreateVersionActions;

const formName: string = "TrialInstitutionItem";
const reduxFormName: string = "reduxForms.trialInstitutionItem";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class TrialInstitutionItemCreateVersion extends React.PureComponent<TrialInstitutionItemCreateVersionProps, any> {

    constructor(props: TrialInstitutionItemCreateVersionProps) {
        super(props);

        this.clearTrialInstitutionItem = this.clearTrialInstitutionItem.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTrialInstitutionItem,
            navigate,
            navigateReplace,
            trialInstitutionItemId,
            createTrialInstitutionItem,
            trialInstitutionId,
            trialItemId
        } = this.props;

        if (trialInstitutionId && trialItemId) {
            createTrialInstitutionItem(trialInstitutionId, trialItemId);
        }
    }

    componentWillReceiveProps(nextProps: TrialInstitutionItemCreateVersionProps) {
    }

    componentDidUpdate(prevProps: TrialInstitutionItemCreateVersionProps, prevState) {
        const {
            trialInstitutionId,
            trialItemId,
            createTrialInstitutionItem,
            creatingTrialInstitutionItem,
            createTrialInstitutionItemSuccess,
            savingTrialInstitutionItem,
            saveTrialInstitutionItemSuccess,
            saveTrialInstitutionItemFailure,
            validationFailures,
        } = this.props;

        if (trialInstitutionId && prevProps.trialInstitutionId != trialInstitutionId ||
            trialItemId && prevProps.trialItemId != trialItemId) {
            createTrialInstitutionItem(trialInstitutionId, trialItemId);
        }

        if (!creatingTrialInstitutionItem && prevProps.creatingTrialInstitutionItem) {
            if (createTrialInstitutionItemSuccess) {
                this.loadForm();
            }
            else {

            }
        }

        if (!savingTrialInstitutionItem && prevProps.savingTrialInstitutionItem) {
            if (saveTrialInstitutionItemSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                this.onCancel();
                /*
                if (form.saveAndReturn) {
                    navigate("/settings/groups/" + groupId + "/groupitems")
                } else if (!form.saveAndReturn && groupItemId && groupItemId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !groupItemId && groupItem && groupItem.id > 0) {
                    navigate("/settings/groups/" + groupId + "/groupitems/" + groupItem.id);
                }
                */
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

    }

    componentWillUpdate(nextProps: TrialInstitutionItemCreateVersionProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearTrialInstitutionItem();
        loadForm(reduxFormName, initialTrialInstitutionItemState);
        resetForm(reduxFormName);
    }

    clearTrialInstitutionItem() {
        this.props.clearTrialInstitutionItem();
    }

    showTrialInstitutionItem(): boolean {
        const {
            loadingTrialInstitutionItem,
            loadTrialInstitutionItemSuccess
        } = this.props

        if (!loadingTrialInstitutionItem && loadTrialInstitutionItemSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingTrialInstitutionItem,
            trialInstitutionItemId,
            trialInstitutionItem
        } = this.props;

        return <SubPageLayout loading={loadingTrialInstitutionItem}>
            {this.renderContent()}
        </SubPageLayout>;
    }

    renderContent() {
        const {
            form,
            trialInstitutionItem,
            trialInstitutionItemId,
            loadingTrialInstitutionItem,
            loadTrialInstitutionItemSuccess,
            loadTrialInstitutionItemFailure,
            clearTrialInstitutionItem,
            navigate,
            user,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingTrialInstitutionItem
        } = this.props;

        const formDisabled: boolean = savingTrialInstitutionItem || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            version: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
        };

        return <div>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "trialInstitutionItem", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
            
        </div>

    }


    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.TrialInstitutionItem) {
        const {
            saveTrialInstitutionItem,
        } = this.props;

        if (data) {
            let changes: Dtos.TrialInstitutionItem = {} as Dtos.TrialInstitutionItem;
            saveTrialInstitutionItem(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.TrialInstitutionItem) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        if (this.props.onClose) { this.props.onClose() }
    }

    loadForm() {
        const {
            loadForm,
            trialInstitutionItem,
        } = this.props

        let extraData: Dtos.TrialInstitutionItem = {} as Dtos.TrialInstitutionItem;

        loadForm(reduxFormName, Object.assign({ ...trialInstitutionItem }, extraData));
    }
}


const mapStateToProps = (state: ITmdState, ownProps: TrialInstitutionItemCreateVersionProps): ITrialInstitutionItemCreateVersionProps => {

    let trialInstitutionItem: Dtos.TrialInstitutionItem | undefined = !(state.trialInstitutionItem.formData instanceof Array) ? state.trialInstitutionItem.formData : undefined;
    
    return {
        trialInstitutionItemId: ownProps.trialInstitutionItemId ? ownProps.trialInstitutionItemId : undefined!,
        trialInstitutionId: ownProps.trialInstitutionId ? ownProps.trialInstitutionId : undefined!,
        trialItemId: ownProps.trialItemId ? ownProps.trialItemId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trialInstitutionItem: trialInstitutionItem!,

        loadingTrialInstitutionItem: state.trialInstitutionItem.loadState && state.trialInstitutionItem.loadState.status == RequestState.Pending,
        loadTrialInstitutionItemSuccess: state.trialInstitutionItem.loadState && state.trialInstitutionItem.loadState.status == RequestState.Success,
        loadTrialInstitutionItemFailure: state.trialInstitutionItem.loadState && state.trialInstitutionItem.loadState.status == RequestState.Failure,

        savingTrialInstitutionItem: state.trialInstitutionItem.saveState && state.trialInstitutionItem.saveState.status == RequestState.Pending,
        saveTrialInstitutionItemSuccess: state.trialInstitutionItem.saveState && state.trialInstitutionItem.saveState.status == RequestState.Success,
        saveTrialInstitutionItemFailure: state.trialInstitutionItem.saveState && state.trialInstitutionItem.saveState.status == RequestState.Failure,

        creatingTrialInstitutionItem: state.trialInstitutionItem.createState && state.trialInstitutionItem.createState.status == RequestState.Pending,
        createTrialInstitutionItemSuccess: state.trialInstitutionItem.createState && state.trialInstitutionItem.createState.status == RequestState.Success,
        createTrialInstitutionItemFailure: state.trialInstitutionItem.createState && state.trialInstitutionItem.createState.status == RequestState.Failure,

        user: state.user.data,
        lookups: state.trialInstitutionItem.lookups,

        form: state.reduxForms.trialInstitutionItem,
        formState: state.trialInstitutionItem.formState,
        formProperties: state.trialInstitutionItem.formProperties,

        reduxFormState: state.reduxForms.formState.trialInstitutionItem,
        validationFailures: state.trialInstitutionItem.validationFailures,

    };
};

const mapDispatchToProps = (dispatch): ITrialInstitutionItemCreateVersionActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadTrialInstitutionItem: bindActionCreators(TrialInstitutionItemActions.LoadTrialInstitutionItemById, dispatch),
        clearTrialInstitutionItem: bindActionCreators(TrialInstitutionItemActions.Clear, dispatch),

        saveTrialInstitutionItem: bindActionCreators(TrialInstitutionItemActions.SaveTrialInstitutionItem, dispatch),
        createTrialInstitutionItem: bindActionCreators(TrialInstitutionItemActions.CreateTrialInstitutionItem, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialInstitutionItemCreateVersion);
