import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_TRIALFORM_LOAD,
    ACTION_TRIALFORM_LOAD_CURRENT_USER,
    ACTION_TRIALFORM_LOAD_BY_ID,

    ACTION_TRIALFORM_SAVE,

    ACTION_TRIALFORM_CREATE,
    ACTION_TRIALFORM_CREATEVERSION,

    ACTION_TRIALFORM_REPORT_LOAD,

    ACTION_TRIALFORM_REPORT_JUSTIFIED,
    ACTION_TRIALFORM_SEND_REMINDER_EMAIL,

    ITrialFormLoadAction,
    ITrialFormLoadCurrentUserAction,
    ITrialFormLoadByIdAction,
    ITrialFormLoadFailureAction,
    ITrialFormLoadSuccessAction,

    ITrialFormSaveAction,
    ITrialFormSaveSuccessAction,
    ITrialFormSaveFailureAction,

    ITrialFormCreateAction,
    ITrialFormCreateSuccessAction,
    ITrialFormCreateFailureAction,

    ITrialFormCreateVersionAction,
    ITrialFormCreateVersionSuccessAction,
    ITrialFormCreateVersionFailureAction,

    ITrialFormReportLoadAction,
    ITrialFormReportLoadFailureAction,
    ITrialFormReportLoadSuccessAction,

    ITrialFormReportJustifiedAction,
    ITrialFormReportJustifiedFailureAction,
    ITrialFormReportJustifiedSuccessAction,

    ITrialFormSendReminderEmailAction,
    ITrialFormSendReminderEmailFailureAction,
    ITrialFormSendReminderEmailSuccessAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

    CreateVersionSuccess,
    CreateVersionFailure,

    LoadTrialFormReportSuccess,
    LoadTrialFormReportFailure,

    SetTrialFormReportJustifiedSuccess,
    SetTrialFormReportJustifiedFailure,

    SendReminderEmailFailure,
    SendReminderEmailSuccess
    

} from "../actions/trialForm";
import { trialFormsApi } from "../services/trialForms";

export const
    getTrialFormsEpic = (action$): Observable<ITrialFormLoadSuccessAction | ITrialFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALFORM_LOAD)
            .mergeMap((action: ITrialFormLoadAction) => {
                return trialFormsApi
                    .getTrialForms(action.trialId, action.institutionId)
                    .map(response =>
                        LoadSuccess(response.trialForms, undefined, undefined, [], response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTrialFormCurrentUserEpic = (action$): Observable<ITrialFormLoadSuccessAction | ITrialFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALFORM_LOAD_CURRENT_USER)
            .mergeMap((action: ITrialFormLoadCurrentUserAction) => {
                return trialFormsApi
                    .getTrialFormsCurrentUser()
                    .map(response =>
                        LoadSuccess(response.trialForms, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },

    getTrialFormByIdEpic = (action$): Observable<ITrialFormLoadSuccessAction | ITrialFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALFORM_LOAD_BY_ID)
            .mergeMap((action: ITrialFormLoadByIdAction) => {
                return trialFormsApi
                    .getTrialFormById(action.id)
                    .map(response =>
                        LoadSuccess(response.trialForm, response.formState, response.formProperties, [], response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setTrialFormEpic = (action$): Observable<ITrialFormSaveSuccessAction | ITrialFormSaveFailureAction> => {
        return action$
            .ofType(ACTION_TRIALFORM_SAVE)
            .mergeMap((action: ITrialFormSaveAction) => {
                return trialFormsApi
                    .setTrialForm(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.trialForm, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createTrialFormEpic = (action$): Observable<ITrialFormCreateSuccessAction | ITrialFormCreateFailureAction> => {
        return action$
            .ofType(ACTION_TRIALFORM_CREATE)
            .mergeMap((action: ITrialFormCreateAction) => {
                return trialFormsApi
                    .createTrialForm(action.trialId, action.institutionId, action.formTemplateId)
                    .map(response =>
                        CreateSuccess(response.trialForm, response.formState, response.formProperties, [], response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    },
    createVersionEpic = (action$): Observable<ITrialFormCreateVersionSuccessAction | ITrialFormCreateVersionFailureAction> => {
        return action$
            .ofType(ACTION_TRIALFORM_CREATEVERSION)
            .mergeMap((action: ITrialFormCreateVersionAction) => {
                return trialFormsApi
                    .createVersion(action.trialFormId, action.version, action.copyData)
                    .map(response =>
                        CreateVersionSuccess(response.trialForm)
                    )
                    .catch(error =>
                        Observable.of(CreateVersionFailure(error.responseStatus))
                    )
            });
    },
    getTrialFormReportEpic = (action$): Observable<ITrialFormReportLoadSuccessAction | ITrialFormReportLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALFORM_REPORT_LOAD)
            .mergeMap((action: ITrialFormReportLoadAction) => {
                return trialFormsApi
                    .getTrialFormReport(action.id, action.version)
                    .map(response =>
                        LoadTrialFormReportSuccess(response.measurementReports, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadTrialFormReportFailure(error.responseStatus))
                    )
            });
    },
    setTrialFormReportJustifiedEpic = (action$): Observable<ITrialFormReportJustifiedSuccessAction | ITrialFormReportJustifiedFailureAction> => {
        return action$
            .ofType(ACTION_TRIALFORM_REPORT_JUSTIFIED)
            .mergeMap((action: ITrialFormReportJustifiedAction) => {
                return trialFormsApi
                    .setTrialFormReportJustified(action.id, action.measurementReport)
                    .map(response =>
                        SetTrialFormReportJustifiedSuccess(response.measurementReports, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(SetTrialFormReportJustifiedFailure(error.responseStatus))
                    )
            });
    },
    setTrialFormSendReminderEmailEpic = (action$): Observable<ITrialFormSendReminderEmailSuccessAction | ITrialFormSendReminderEmailFailureAction> => {
        return action$
            .ofType(ACTION_TRIALFORM_SEND_REMINDER_EMAIL)
            .mergeMap((action: ITrialFormSendReminderEmailAction) => {
                return trialFormsApi
                    .sendReminderEmail(action.id)
                    .map(response =>
                        SendReminderEmailSuccess(response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(SendReminderEmailFailure(error.responseStatus))
                    )
            });
    };



