import * as React from 'react';
import './TrialsPage.scss';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import * as ReactTooltip from 'react-tooltip';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu, TruncatedCell } from "../../../components/layouts/index";
import * as TrialActions from '../../../actions/trial';
import * as PagingActions from '../../../actions/pages/pagingPage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup, findLookupValue } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { ModalSize } from '../../../enumerations/ModalSize';
import { TextAreaFormInput, FormFilterInput } from '../../../components/form';

interface ITrialsPageProps {

    location: Location;
    history: History;

    // From state
    formFilter: IFormFilter;
    formLength: number;
    trials: Dtos.Trial[];
    loadingTrials: boolean;
    loadTrialsSuccess: boolean;
    loadTrialsFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    lookups: Dtos.Lookup[];
    formRoles: Dtos.RoleType[]

    pageSize: number;
}

interface ITrialsPageActions {
    clearTrials: TrialActions.ITrialClearActionCreator;
    loadTrials: TrialActions.ITrialLoadActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    filter: TrialActions.ITrialFilterActionCreator;

    setPageSize: PagingActions.IPagingPageSizeChangedStateActionCreator;
}

type TrialsPageProps = ITrialsPageProps & ITrialsPageActions;

class TrialsPage extends React.PureComponent<TrialsPageProps, any> {

    constructor(props: TrialsPageProps) {
        super(props);

        this.clearTrials = this.clearTrials.bind(this);
        this.toggleExportMenu = this.toggleExportMenu.bind(this);

        this.state = {
            exportToggle: false
        };
    }

    componentDidMount() {
        const {
            loadTrials,
            navigate,
            navigateReplace
        } = this.props;

        loadTrials();
    }

    componentWillReceiveProps(nextProps: TrialsPageProps) {
    }

    componentDidUpdate(prevProps: TrialsPageProps, prevState) {
        const {
            navigateReplace,
            loadTrials
        } = this.props
    }

    componentWillUpdate(nextProps: TrialsPageProps) {
    }

    toggleExportMenu() {
        this.setState({
            exportToggle: !this.state.exportToggle
        });
    }

    componentWillUnmount() {
        this.clearTrials();
    }

    clearTrials() {
        this.props.clearTrials();
    }

    showTrials(): boolean {
        const {
            loadingTrials,
            loadTrialsSuccess
        } = this.props

        if (!loadingTrials && loadTrialsSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingTrials
        } = this.props;

        return <RestrictedLayout
            anyMatchpermission={Dtos.Permission.TrialView}
            loading={loadingTrials}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            permissions,
            formRoles
        } = this.props;

        const canCreate = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialCreate);
        const canViewPersonnelRoles = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialPersonnelRoleView);

        return <div>
            <div className="row">
                <div className={"col-auto mb-2"}>
                    <h2>Trials</h2>
                </div>
                <div className="col" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} />
                    {canViewPersonnelRoles && (
                        <div className="btn-group mr-2">
                            <button type="button" className="btn btn-info" onClick={() => this.toggleExportMenu()}>
                                <FontAwesomeIcon icon={FontAwesomeIcons.Solid.CLOUD_DOWNLOAD} fixedWidth />
                            </button>
                            <Modal open={this.state.exportToggle} disableCloseOnOverlayClick={true} onClose={() => { this.toggleExportMenu() }} size={ModalSize.Md}>
                                <div className="modal-header">
                                    <h5 className="modal-title">Download</h5>
                                    <button type="button" className="close" aria-label="Close" onClick={() => { this.toggleExportMenu() }}>
                                        <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                                    </button>
                                </div>
                                <div className="modal-body p-3">
                                    <a className="btn btn-outline-primary btn-block"
                                        onClick={() => this.toggleExportMenu()}
                                        href={encodeURI("/api/personnelrole/export/-1/0?filter=" + this.props.formFilter.search + "&showInactive=" + this.props.formFilter.showInActive)}
                                    ><b>Active Roles</b><br /><small>On the listed trials export a CSV containing active personnel roles</small></a>
                                </div>
                            </Modal>
                        </div>
                    )}

                    {canCreate && <>
                        <Link className={"btn btn-primary"} url={"/trials/create"}>Create</Link>
                    </>}
                </div>
            </div>
            {
                !this.showTrials() ?
                    "Loading Trials" :
                    null
            }
            {
                this.showTrials() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {

        const {
            trials,
            user,
            lookups,
            pageSize,
            setPageSize,
            formRoles,
            permissions
        } = this.props

        if (!trials || trials.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no trials to view.</span>
            </div>
        }

        const showPagination = trials.length > pageSize;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={trials}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? pageSize : trials.length}
                pageSize={showPagination ? pageSize : trials.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no trials to display"
                resizable={false}
                onPageSizeChange={val => setPageSize(val)}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            const trialId = rowInfo.original.id;
                            const canView = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialView, trialId, undefined);
                            const canViewPersonnelRoles = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialPersonnelRoleView, trialId, undefined);
                            if (!canViewPersonnelRoles && column.id == "personnelCount") {
                                return;
                            }
                            if (!canView && column.id != "personnelCount") {
                                return;
                            }
                            const url: string = (column.id == "personnelCount") ?
                                "/trials/" + rowInfo.original.id + "/personnel"
                                : "/trials/" + rowInfo.original.id + "/details";
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        }
                    }
                }}
                defaultSorted={[
                    {
                        id: "displayNumber",
                        desc: true
                    }
                ]}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.TRIAL} fixedWidth />
                    },
                    {
                        id: "allIdentifiers",
                        Header: 'Trial Identifiers',
                        accessor: 'allIdentifiers',
                        maxWidth: 200,
                        className: "d-flex align-items-center justify-content-center",
                        headerClassName: "text-center"
                    },
                    {
                        id: "displayNumber",
                        Header: 'TROG Number',
                        accessor: 'displayNumber',
                        maxWidth: 180,
                        className: "d-flex align-items-center justify-content-center",
                        headerClassName: "text-center"
                    },
                    {
                        id: "studyAcronym",
                        Header: 'Acronym',
                        accessor: 'studyAcronym',
                        maxWidth: 120,
                        className: "d-flex align-items-center justify-content-center",
                        headerClassName: "text-center"
                    },
                    /*{
                        id: "shortTitle",
                        Header: 'Short Title',
                        accessor: 'shortTitle',
                        maxWidth: 280,
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value} />
                    },
                    {
                        id: "title",
                        Header: 'Scientific Title',
                        accessor: "title",
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value} />
                    },*/
                    {
                        id: "registrationWebsite",
                        Header: 'Registration Website',
                        accessor: "registrationWebsite",
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <div><a target="_blank" onClick={e => { e.stopPropagation(); }} href={props.value}>{props.value}</a></div>
                    },
                    {
                        id: "trialStatusId",
                        Header: 'Status',
                        accessor: 'trialStatusId',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 180,
                        headerClassName: "text-center",
                        Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{findLookupValue("Trial", "TrialStatusId", props.value, lookups)}</span><ReactTooltip /></div>
                    },
                    /*{
                        id: "personnelCount",
                        Header: 'Roles',
                        accessor: 'personnelCount',
                        maxWidth: 80,
                        className: "d-flex align-items-center justify-content-center",
                        Cell: (props) => <div><span className="text-info"><u>{props.value || "0"}</u></span></div>
                    },
                    */
                ]}
            />
        </div>
    }

}

const mapStateToProps = (state: ITmdState, ownProps: TrialsPageProps): ITrialsPageProps => {
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];
    return {

        history: ownProps.history,
        location: state.routing.location,

        trials: state.trials.formFilteredData!,
        formFilter: state.trials.formFilter,
        formLength: state.trials.formLength,
        lookups: state.trials.lookups,

        loadingTrials: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,
        loadTrialsSuccess: state.trials.loadState && state.trials.loadState.status == RequestState.Success,
        loadTrialsFailure: state.trials.loadState && state.trials.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles,

        pageSize: state.pagingPage.pageSize
    };
};

const mapDispatchToProps = (dispatch): ITrialsPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadTrials: bindActionCreators(TrialActions.LoadTrial, dispatch),
        clearTrials: bindActionCreators(TrialActions.Clear, dispatch),

        filter: bindActionCreators(TrialActions.Filter, dispatch),

        setPageSize: bindActionCreators(PagingActions.PageSizeChanged, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialsPage);
