import * as React from 'react';

import * as classNames from "classnames";
import './InstitutionFormsPage.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';



import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";

import * as InstitutionActions from '../../../actions/institution';
import * as InstitutionFormActions from '../../../actions/institutionForm';
import * as InstitutionFormsPageActions from '../../../actions/pages/institutionFormsPage';


import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup, findLookupValue } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../../components/form';
import { InstitutionSubMenu } from '../../../helpers/subMenuHelper';

import {
    InstitutionFormCreateVersion,
    CollectionInstancePage
} from "../../../containers/index";

interface IInstitutionFormsPageParams {
    institutionId: number;
}

interface IInstitutionFormsPageProps {

    location: Location;
    institutionId: number;
    match: match<IInstitutionFormsPageParams>;
    history: History;

    // modals
    institutionFormId: number;
    modalTitle: string,
    modalDescription: string,
    editModalOpen: boolean,
    collectionInstanceId: number,
    createVersionModalOpen: boolean,

    // From state
    institution: Dtos.Institution;
    institutionForms: Dtos.InstitutionForm[],

    loadingInstitution: boolean;
    loadInstitutionSuccess: boolean;
    loadInstitutionFailure: boolean;

    loadingInstitutionForm: boolean;
    loadInstitutionFormSuccess: boolean;
    loadInstitutionFormFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    lookups: Dtos.Lookup[];
    formRoles: Dtos.RoleType[];
}

interface IInstitutionFormsPageActions {
    clearInstitution: InstitutionActions.IInstitutionClearActionCreator;
    loadInstitution: InstitutionActions.IInstitutionLoadByIdActionCreator;

    clearInstitutionForms: InstitutionFormActions.IInstitutionFormClearActionCreator;
    loadInstitutionForms: InstitutionFormActions.IInstitutionFormLoadActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    setEditModelViewState: InstitutionFormsPageActions.IInstitutionFormsPageSetEditModalViewStateActionCreator;
    setCreateVersionModelViewState: InstitutionFormsPageActions.IInstitutionFormsPageSetCreateVersionModalViewStateActionCreator;

}

type InstitutionFormsPageProps = IInstitutionFormsPageProps & IInstitutionFormsPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class InstitutionFormsPage extends React.PureComponent<InstitutionFormsPageProps, any> {

    constructor(props: InstitutionFormsPageProps) {
        super(props);

        this.clearInstitutionForm = this.clearInstitutionForm.bind(this);
    }

    componentDidMount() {
        const {
            loadInstitution,
            loadInstitutionForms,
            navigate,
            navigateReplace,
            institutionId
        } = this.props;

        loadInstitution(institutionId);
        loadInstitutionForms(institutionId);
    }

    componentWillReceiveProps(nextProps: InstitutionFormsPageProps) {
    }

    componentDidUpdate(prevProps: InstitutionFormsPageProps, prevState) {
        const {
            navigateReplace,
        } = this.props
    }

    componentWillUpdate(nextProps: InstitutionFormsPageProps) {
    }

    componentWillUnmount() {
        this.clearInstitutionForm();
    }

    clearInstitutionForm() {
        this.props.clearInstitution();
        this.props.clearInstitutionForms();
    }

    show(): boolean {
        const {
            loadingInstitution,
            loadInstitutionSuccess,
            loadingInstitutionForm,
            loadInstitutionFormSuccess
        } = this.props

        if (!loadingInstitution && loadInstitutionSuccess &&
            !loadingInstitutionForm && loadInstitutionFormSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingInstitution,
            permissions,
            institution,

        } = this.props;

        let id = institution ? institution.id : 0;

        return <RestrictedLayout
            permission={Dtos.Permission.InstitutionFormView}
            subMenuTitle={institution ? institution.display : ""}
            subMenuItems={InstitutionSubMenu(id, permissions)}
            loading={loadingInstitution}
        >
            {this.renderContent()}
            {this.renderItemModal()}
            {this.renderCreateVersionModal()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            institution,
            permissions,
            institutionId
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-9 mb-2"}>
                    <h2>Institution Forms</h2>
                </div>
                <div className="col-3" style={{ textAlign: 'right' }}>
                    {(AuthenticationHelper.hasPermission(permissions, Dtos.Permission.InstitutionFormCreate, null, institutionId) && institution && institution.id > 0) && (
                        <Link className={"btn btn-primary"} url={"/institutions/" + institution.id + '/forms/create'}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.show() ?
                    "Loading Institution Forms" :
                    null
            }
            {
                this.show() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {

        const {
            institutionForms,
            lookups,
            setCreateVersionModelViewState,
            setEditModelViewState,
            permissions,
            navigate,
            institutionId
        } = this.props

        if (!institutionForms || institutionForms.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no institution forms</span>
            </div>
        }

        const showPagination = institutionForms.length > DEFAULT_PAGE_SIZE;
        const canDataEdit: boolean = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.InstitutionFormDataEntryUpdate, null, institutionId);
        const canDataCreate: boolean = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.InstitutionFormDataEntryCreate, null, institutionId);

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={institutionForms}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : institutionForms.length}
                pageSize={showPagination ? undefined : institutionForms.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no trial groups to display"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            const url: string = "/institutions/" + rowInfo.original.institutionId + "/forms/" + rowInfo.original.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.TRIALITEM} fixedWidth />
                    },
                    {
                        id: "name",
                        Header: 'Form Template',
                        accessor: 'formTemplate.name',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    },
                    {
                        id: "assignedToId",
                        Header: 'Assigned To',
                        accessor: 'assignedTo.display',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 180,
                    },
                    {
                        id: "formStatusId",
                        Header: 'Status',
                        accessor: 'formStatus.name',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 180,
                    },
                    {
                        id: "version",
                        Header: 'Version',
                        accessor: 'version',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 180,
                    },
                    {
                        id: "create",
                        Header: "",
                        className: "d-flex align-items-right",
                        maxWidth: 200,
                        style: { textAlign: 'right' },
                        Cell: row => (
                            <div>
                                <button disabled={!canDataEdit}
                                    onClick={
                                        (e) => {
                                            e.stopPropagation();
                                            var url = "/institutions/" + row.original.institutionId + '/forms/' + row.original.id + '/entry';
                                            navigate(url);
                                        }
                                    }
                                    type="button"
                                    className="btn btn-outline-secondary mr-2 btn-sm btn-row-version"
                                >
                                    <span>Enter Data</span>
                                </button>
                                <button disabled={!canDataCreate}
                                    onClick={
                                        (e) => {
                                            e.stopPropagation();
                                            console.log(row)
                                            setCreateVersionModelViewState(true, row.original.id, null)
                                        }
                                    }
                                    type="button"
                                    className="btn btn-outline-secondary mr-2 btn-sm btn-row-version"
                                >
                                    <span>New version</span>
                                </button>
                            </div>
                        )
                    }
                ]}
            />
        </div>

    }

    renderItemModal() {
        const {
            editModalOpen,
            setEditModelViewState,
            collectionInstanceId,
            loadInstitutionForms,
            loadingInstitutionForm,
            institutionId,
            modalTitle,
            modalDescription,
            permissions
        } = this.props

        const canDataEdit: boolean = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.InstitutionFormDataEntryUpdate, null, institutionId);
        

        if (collectionInstanceId && collectionInstanceId > 0) {
            return <Modal
                open={editModalOpen}
                disableCloseOnOverlayClick={true}
                onClose={() => {
                    setEditModelViewState(false, null, "", "");
                    loadInstitutionForms(institutionId);
                }}
                size={ModalSize.Xl}>
                <div className="modal-header">
                    <h5 className="modal-title">{setModalTitle(undefined, modalTitle)}{modalDescription ? <><br /><small style={{ color: '#444' }}>{modalDescription}</small></> : undefined}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => {
                        setEditModelViewState(false, null, "", "");
                        loadInstitutionForms(institutionId);
                    }}>
                        <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                    </button>

                </div>
                <div className="modal-body">
                    <CollectionInstancePage
                        readonly={canDataEdit}
                        collectionInstanceId={collectionInstanceId}
                        onClose={() => {
                        setEditModelViewState(false, null, "", "");
                        loadInstitutionForms(institutionId);
                    }} />
                </div>
            </Modal>
        }
    }

    renderCreateVersionModal() {
        const {
            loadInstitutionForms,
            institutionId,
            createVersionModalOpen,
            setCreateVersionModelViewState,
        } = this.props

        return <Modal open={createVersionModalOpen}
            disableCloseOnOverlayClick={true}
            onClose={() => { setCreateVersionModelViewState(false, null, null); loadInstitutionForms(institutionId); }}
            size={ModalSize.Md}>
            <div className="modal-header">
                <h5 className="modal-title">{setModalTitle(undefined, "Create Version")}</h5>
                <button type="button" className="close" aria-label="Close" onClick={() => { setCreateVersionModelViewState(false, null, null); }}>
                    <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                </button>
            </div>
            <div className="modal-body" style={{ paddingTop: 0, paddingBottom: 0 }}>
                <InstitutionFormCreateVersion
                    onClose={() => {
                        setCreateVersionModelViewState(false, null, null);
                        loadInstitutionForms(institutionId);
                    }} />
            </div>
        </Modal>
    }

}

const mapStateToProps = (state: ITmdState, ownProps: InstitutionFormsPageProps): IInstitutionFormsPageProps => {

    let institution: Dtos.Institution | undefined = !(state.institutions.formData instanceof Array) ? state.institutions.formData : undefined;
    let institutionForms: Dtos.InstitutionForm[] | undefined = (state.institutionForm.formData instanceof Array) ? state.institutionForm.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        location: state.routing.location,
        institutionId: ownProps.match ? ownProps.match.params.institutionId : undefined!,
        match: ownProps.match,
        history: ownProps.history,

        institution: institution!,
        institutionForms: institutionForms!,
        lookups: state.trials.lookups,

        institutionFormId: state.institutionFormsPage.institutionFormId,
        modalTitle: state.institutionFormsPage.name,
        modalDescription: state.institutionFormsPage.description,

        editModalOpen: state.institutionFormsPage.editModalOpen,
        collectionInstanceId: state.institutionFormsPage.collectionInstanceId,

        createVersionModalOpen: state.institutionFormsPage.createVersionModalOpen,

        loadingInstitution: state.institutions.loadState && state.institutions.loadState.status == RequestState.Pending,
        loadInstitutionSuccess: state.institutions.loadState && state.institutions.loadState.status == RequestState.Success,
        loadInstitutionFailure: state.institutions.loadState && state.institutions.loadState.status == RequestState.Failure,

        loadingInstitutionForm: state.institutionForm.loadState && state.institutionForm.loadState.status == RequestState.Pending,
        loadInstitutionFormSuccess: state.institutionForm.loadState && state.institutionForm.loadState.status == RequestState.Success,
        loadInstitutionFormFailure: state.institutionForm.loadState && state.institutionForm.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles,
    };
};

const mapDispatchToProps = (dispatch): IInstitutionFormsPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadInstitution: bindActionCreators(InstitutionActions.LoadInstitutionById, dispatch),
        clearInstitution: bindActionCreators(InstitutionActions.Clear, dispatch),

        loadInstitutionForms: bindActionCreators(InstitutionFormActions.LoadInstitutionForm, dispatch),
        clearInstitutionForms: bindActionCreators(InstitutionFormActions.Clear, dispatch),

        setEditModelViewState: bindActionCreators(InstitutionFormsPageActions.setEditModalViewState, dispatch),
        setCreateVersionModelViewState: bindActionCreators(InstitutionFormsPageActions.setCreateVersionModalViewState, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(InstitutionFormsPage);
