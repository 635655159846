import * as Dtos from "../../dtos/Tmd.dtos";

export const
    setReminderEmailSettingsModalViewState: ITrialFormsPageSetReminderEmailSettingsModalViewStateActionCreator = (open, trialId) => {
        return {
            type: ACTION_TRIAL_PAGE_SET_EMAILREMINDERSETTINGS_MODAL_VIEW_STATE,
            open,
            trialId
        }
    }

export const
    ACTION_TRIAL_PAGE_SET_EMAILREMINDERSETTINGS_MODAL_VIEW_STATE = "ACTION_TRIAL_PAGE_SET_EMAILREMINDERSETTINGS_MODAL_VIEW_STATE"

/* Actions */

export interface ITrialFormsPageSetReminderEmailSettingsModalViewStateAction {
    type: "ACTION_TRIAL_PAGE_SET_EMAILREMINDERSETTINGS_MODAL_VIEW_STATE",
    open: boolean;
    trialId: number;
}

/* Action Creators */

export interface ITrialFormsPageSetReminderEmailSettingsModalViewStateActionCreator {
    (
        open: boolean,
        trialId: number
    ): ITrialFormsPageSetReminderEmailSettingsModalViewStateAction;
}


/* Final Export of actions */
export type TrialsPageAction =
    ITrialFormsPageSetReminderEmailSettingsModalViewStateAction;
