import * as React from 'react';
import './ReportingTagCategoriesPage.scss';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { RowInfo } from 'react-table';
import * as ReportingTagCategoryActions from '../../../actions/reportingTagCategories';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { FormFilterInput } from '../../../components/form';
import { QALookupsLayout } from '../../index';

interface IReportingTagCategoriesPageProps {

    location: Location;
    history: History;

    // From state
    formFilter: IFormFilter;
    reportingTagCategories: Dtos.ReportingTagCategory[];
    loadingReportingTagCategories: boolean;
    loadReportingTagCategoriesSuccess: boolean;
    loadReportingTagCategoriesFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    formRoles?: Dtos.RoleType[];
}

interface IReportingTagCategoriesPageActions {
    clearReportingTagCategories: ReportingTagCategoryActions.IReportingTagCategoryClearActionCreator;
    loadReportingTagCategories: ReportingTagCategoryActions.IReportingTagCategoryLoadActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    filter: ReportingTagCategoryActions.IReportingTagCategoryFilterActionCreator;
}

type ReportingTagCategoriesPageProps = IReportingTagCategoriesPageProps & IReportingTagCategoriesPageActions;

class ReportingTagCategoriesPage extends React.PureComponent<ReportingTagCategoriesPageProps, any> {

    constructor(props: ReportingTagCategoriesPageProps) {
        super(props);

        this.clearReportingTagCategories = this.clearReportingTagCategories.bind(this);
    }

    componentDidMount() {
        const {
            loadReportingTagCategories
        } = this.props;

        loadReportingTagCategories();
    }

    componentWillReceiveProps(nextProps: ReportingTagCategoriesPageProps) {
    }

    componentDidUpdate(prevProps: ReportingTagCategoriesPageProps, prevState) {
        const {
            navigateReplace
        } = this.props
    }

    componentWillUpdate(nextProps: ReportingTagCategoriesPageProps) {
    }

    componentWillUnmount() {
        this.clearReportingTagCategories();
    }

    clearReportingTagCategories() {
        this.props.clearReportingTagCategories();
    }

    showReportingTagCategories(): boolean {
        const {
            loadingReportingTagCategories,
            loadReportingTagCategoriesSuccess
        } = this.props

        if (!loadingReportingTagCategories && loadReportingTagCategoriesSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingReportingTagCategories
        } = this.props;

        return <QALookupsLayout loading={loadingReportingTagCategories} permission={Dtos.Permission.ReportingTagCategoriesView}>
            {this.renderContent()}
        </QALookupsLayout>;
    }

    renderContent() {
        const {
            permissions
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-6 mb-2"}>
                    <h2>Reporting Tag Categories</h2>
                </div>
                <div className="col-6" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e) } />
                    
                    {AuthenticationHelper.hasPermission(permissions, Dtos.Permission.ReportingTagCategoriesCreate) && (
                        <Link className={"btn btn-primary"} url={"/settings/qa/reportingTagCategories/create"}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.showReportingTagCategories() ?
                    "Loading ReportingTagCategories" :
                    null
            }
            {
                this.showReportingTagCategories() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {

        const {
            reportingTagCategories
        } = this.props

        if (!reportingTagCategories || reportingTagCategories.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no reportingTagCategorys to view.</span>
            </div>
        }

        const showPagination = reportingTagCategories.length > DEFAULT_PAGE_SIZE;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={reportingTagCategories}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : reportingTagCategories.length}
                pageSize={showPagination ? undefined : reportingTagCategories.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no reportingTagCategory to display"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {

                            const url: string = "/settings/qa/reportingTagCategories/" + rowInfo.original.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.LOOKUP} fixedWidth />
                    },
                    {
                        id: "value",
                        Header: 'Name',
                        accessor: 'value',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    }
                ]}
            />
        </div>

    }

}

const mapStateToProps = (state: ITmdState, ownProps: ReportingTagCategoriesPageProps): IReportingTagCategoriesPageProps => {

    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        history: ownProps.history,
        location: state.routing.location,

        formFilter: state.reportingTagCategories.formFilter,
        reportingTagCategories: state.reportingTagCategories.formFilteredData, 

        loadingReportingTagCategories: state.reportingTagCategories.loadState && state.reportingTagCategories.loadState.status == RequestState.Pending,
        loadReportingTagCategoriesSuccess: state.reportingTagCategories.loadState && state.reportingTagCategories.loadState.status == RequestState.Success,
        loadReportingTagCategoriesFailure: state.reportingTagCategories.loadState && state.reportingTagCategories.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles
    };
};

const mapDispatchToProps = (dispatch): IReportingTagCategoriesPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadReportingTagCategories: bindActionCreators(ReportingTagCategoryActions.LoadReportingTagCategory, dispatch),
        clearReportingTagCategories: bindActionCreators(ReportingTagCategoryActions.Clear, dispatch),

        filter: bindActionCreators(ReportingTagCategoryActions.Filter, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(ReportingTagCategoriesPage);
