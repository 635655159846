import { TogglePageItem } from '../reducers/pages/togglePage';

/** @description Returns the expanded state for the toggle, the default is expanded
 * @param {TogglePageItem[]} items the toggle states for all items
 * @param {string} key the name of the key for the item.
 * @return {boolean}
 */
export function isToggleExpanded(items: TogglePageItem[], key: string): boolean {

    let item = items.find(f => f.key == key);
    if (item) {
        return item.isExpanded;
    } 
    return true;
}
