import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_TUMOURGROUPS_LOAD,
    ACTION_TUMOURGROUPS_LOAD_BY_ID,

    ACTION_TUMOURGROUPS_SAVE,

    ACTION_TUMOURGROUPS_CREATE,

    ITumourGroupLoadAction,
    ITumourGroupLoadByIdAction,
    ITumourGroupLoadFailureAction,
    ITumourGroupLoadSuccessAction,

    ITumourGroupSaveAction,
    ITumourGroupSaveSuccessAction,
    ITumourGroupSaveFailureAction,

    ITumourGroupCreateAction,
    ITumourGroupCreateSuccessAction,
    ITumourGroupCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/tumourGroups";
import { tumourgroupsApi } from "../services/tumourGroup";

export const
    getTumourGroupsEpic = (action$): Observable<ITumourGroupLoadSuccessAction | ITumourGroupLoadFailureAction> => {
        return action$
            .ofType(ACTION_TUMOURGROUPS_LOAD)
            .mergeMap((action: ITumourGroupLoadAction) => {
                return tumourgroupsApi
                    .getTumourGroups()
                    .map(response =>
                        LoadSuccess(response.tumourGroups, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTumourGroupByIdEpic = (action$): Observable<ITumourGroupLoadSuccessAction | ITumourGroupLoadFailureAction> => {
        return action$
            .ofType(ACTION_TUMOURGROUPS_LOAD_BY_ID)
            .mergeMap((action: ITumourGroupLoadByIdAction) => {
                return tumourgroupsApi
                    .getTumourGroupById(action.id)
                    .map(response =>
                        LoadSuccess(response.tumourGroup, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setTumourGroupEpic = (action$): Observable<ITumourGroupSaveSuccessAction | ITumourGroupSaveFailureAction> => {
        return action$
            .ofType(ACTION_TUMOURGROUPS_SAVE)
            .mergeMap((action: ITumourGroupSaveAction) => {
                return tumourgroupsApi
                    .setTumourGroup(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.tumourGroup, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createTumourGroupEpic = (action$): Observable<ITumourGroupCreateSuccessAction | ITumourGroupCreateFailureAction> => {
        return action$
            .ofType(ACTION_TUMOURGROUPS_CREATE)
            .mergeMap((action: ITumourGroupCreateAction) => {
                return tumourgroupsApi
                    .createTumourGroup()
                    .map(response =>
                        CreateSuccess(
                            response.tumourGroup,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



