import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadTrialAnalysisType: ITrialAnalysisTypeLoadActionCreator = () => {
        return {
            type: ACTION_TRIALANALYSISTYPES_LOAD
        }
    },
    LoadTrialAnalysisTypeById: ITrialAnalysisTypeLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TRIALANALYSISTYPES_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ITrialAnalysisTypeLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALANALYSISTYPES_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrialAnalysisTypeLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALANALYSISTYPES_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTrialAnalysisType: ITrialAnalysisTypeSaveActionCreator = (formData) => {
        return {
            type: ACTION_TRIALANALYSISTYPES_SAVE,
            formData
        }
    },
    SaveSuccess: ITrialAnalysisTypeSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRIALANALYSISTYPES_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrialAnalysisTypeSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALANALYSISTYPES_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTrialAnalysisType: ITrialAnalysisTypeCreateActionCreator = () => {

        return {
            type: ACTION_TRIALANALYSISTYPES_CREATE
        }
    },
    CreateSuccess: ITrialAnalysisTypeCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_TRIALANALYSISTYPES_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: ITrialAnalysisTypeCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALANALYSISTYPES_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: ITrialAnalysisTypeClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRIALANALYSISTYPES_CLEAR,
            propertiesToClear
        }
    },
    Filter: ITrialAnalysisTypeFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRIALANALYSISTYPES_FILTER,
            formFilter
        }
    };



export const
    ACTION_TRIALANALYSISTYPES_LOAD = "ACTION_TRIALANALYSISTYPES_LOAD",
    ACTION_TRIALANALYSISTYPES_LOAD_BY_ID = "ACTION_TRIALANALYSISTYPES_LOAD_BY_ID",
    ACTION_TRIALANALYSISTYPES_LOAD_SUCCESS = "ACTION_TRIALANALYSISTYPES_LOAD_SUCCESS",
    ACTION_TRIALANALYSISTYPES_LOAD_FAILURE = "ACTION_TRIALANALYSISTYPES_LOAD_FAILURE",

    ACTION_TRIALANALYSISTYPES_SAVE = "ACTION_TRIALANALYSISTYPES_SAVE",
    ACTION_TRIALANALYSISTYPES_SAVE_SUCCESS = "ACTION_TRIALANALYSISTYPES_SAVE_SUCCESS",
    ACTION_TRIALANALYSISTYPES_SAVE_FAILURE = "ACTION_TRIALANALYSISTYPES_SAVE_FAILURE",

    ACTION_TRIALANALYSISTYPES_CREATE = "ACTION_TRIALANALYSISTYPES_CREATE",
    ACTION_TRIALANALYSISTYPES_CREATE_SUCCESS = "ACTION_TRIALANALYSISTYPES_CREATE_SUCCESS",
    ACTION_TRIALANALYSISTYPES_CREATE_FAILURE = "ACTION_TRIALANALYSISTYPES_CREATE_FAILURE",

    ACTION_TRIALANALYSISTYPES_CLEAR = "ACTION_TRIALANALYSISTYPES_CLEAR",

    ACTION_TRIALANALYSISTYPES_FILTER = "ACTION_TRIALANALYSISTYPES_FILTER";

export interface ITrialAnalysisTypeLoadAction {
    type: "ACTION_TRIALANALYSISTYPES_LOAD";
}

export interface ITrialAnalysisTypeLoadActionCreator {
    (): ITrialAnalysisTypeLoadAction;
}

export interface ITrialAnalysisTypeLoadSuccessAction {
    type: "ACTION_TRIALANALYSISTYPES_LOAD_SUCCESS";
    formData: Dtos.TrialAnalysisType | Dtos.TrialAnalysisType[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialAnalysisTypeLoadSuccessActionCreator {
    (
        formData: Dtos.TrialAnalysisType | Dtos.TrialAnalysisType[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialAnalysisTypeLoadSuccessAction;
}

export interface ITrialAnalysisTypeLoadFailureAction {
    type: "ACTION_TRIALANALYSISTYPES_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialAnalysisTypeLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialAnalysisTypeLoadFailureAction;
}

export interface ITrialAnalysisTypeLoadByIdAction {
    type: "ACTION_TRIALANALYSISTYPES_LOAD_BY_ID";
    id: number;
}

export interface ITrialAnalysisTypeLoadByIdActionCreator {
    (id: number): ITrialAnalysisTypeLoadByIdAction;
}


export interface ITrialAnalysisTypeSaveAction {
    type: "ACTION_TRIALANALYSISTYPES_SAVE";
    formData: Dtos.TrialAnalysisType;

}

export interface ITrialAnalysisTypeSaveActionCreator {
    (
        formData: Dtos.TrialAnalysisType
    ): ITrialAnalysisTypeSaveAction;
}

export interface ITrialAnalysisTypeSaveSuccessAction {
    type: "ACTION_TRIALANALYSISTYPES_SAVE_SUCCESS";
    formData: Dtos.TrialAnalysisType;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialAnalysisTypeSaveSuccessActionCreator {
    (
        formData: Dtos.TrialAnalysisType,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrialAnalysisTypeSaveSuccessAction;
}

export interface ITrialAnalysisTypeSaveFailureAction {
    type: "ACTION_TRIALANALYSISTYPES_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialAnalysisTypeSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialAnalysisTypeSaveFailureAction;
}

export interface ITrialAnalysisTypeCreateAction {
    type: "ACTION_TRIALANALYSISTYPES_CREATE"
}

export interface ITrialAnalysisTypeCreateActionCreator {
    (): ITrialAnalysisTypeCreateAction
}

export interface ITrialAnalysisTypeCreateSuccessAction {
    type: "ACTION_TRIALANALYSISTYPES_CREATE_SUCCESS";
    formData: Dtos.TrialAnalysisType;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface ITrialAnalysisTypeCreateSuccessActionCreator {
    (
        formData: Dtos.TrialAnalysisType,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): ITrialAnalysisTypeCreateSuccessAction;
}

export interface ITrialAnalysisTypeCreateFailureAction {
    type: "ACTION_TRIALANALYSISTYPES_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialAnalysisTypeCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialAnalysisTypeCreateFailureAction;
}

export interface ITrialAnalysisTypeClearAction {
    type: "ACTION_TRIALANALYSISTYPES_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrialAnalysisTypeClearActionCreator {
    (propertiesToClear?: string[]): ITrialAnalysisTypeClearAction;
}

export interface ITrialAnalysisTypeFilterActionCreator {
    (formFilter: IFormFilter): ITrialAnalysisTypeFilterAction;
}

export interface ITrialAnalysisTypeFilterAction {
    type: "ACTION_TRIALANALYSISTYPES_FILTER";
    formFilter: IFormFilter;
}

export type TrialAnalysisTypeAction =
    ITrialAnalysisTypeLoadAction |
    ITrialAnalysisTypeLoadByIdAction |
    ITrialAnalysisTypeLoadFailureAction |
    ITrialAnalysisTypeLoadSuccessAction |
    ITrialAnalysisTypeSaveAction |
    ITrialAnalysisTypeSaveSuccessAction |
    ITrialAnalysisTypeSaveFailureAction |
    ITrialAnalysisTypeCreateAction |
    ITrialAnalysisTypeCreateSuccessAction |
    ITrialAnalysisTypeCreateFailureAction |
    ITrialAnalysisTypeClearAction |
    ITrialAnalysisTypeFilterAction;
