import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadTrialPhase: ITrialPhaseLoadActionCreator = () => {
        return {
            type: ACTION_TRIALPHASES_LOAD
        }
    },
    LoadTrialPhaseById: ITrialPhaseLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TRIALPHASES_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ITrialPhaseLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALPHASES_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrialPhaseLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALPHASES_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTrialPhase: ITrialPhaseSaveActionCreator = (formData) => {
        return {
            type: ACTION_TRIALPHASES_SAVE,
            formData
        }
    },
    SaveSuccess: ITrialPhaseSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRIALPHASES_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrialPhaseSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALPHASES_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTrialPhase: ITrialPhaseCreateActionCreator = () => {

        return {
            type: ACTION_TRIALPHASES_CREATE
        }
    },
    CreateSuccess: ITrialPhaseCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_TRIALPHASES_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: ITrialPhaseCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALPHASES_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: ITrialPhaseClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRIALPHASES_CLEAR,
            propertiesToClear
        }
    },
    Filter: ITrialPhaseFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRIALPHASES_FILTER,
            formFilter
        }
    };



export const
    ACTION_TRIALPHASES_LOAD = "ACTION_TRIALPHASES_LOAD",
    ACTION_TRIALPHASES_LOAD_BY_ID = "ACTION_TRIALPHASES_LOAD_BY_ID",
    ACTION_TRIALPHASES_LOAD_SUCCESS = "ACTION_TRIALPHASES_LOAD_SUCCESS",
    ACTION_TRIALPHASES_LOAD_FAILURE = "ACTION_TRIALPHASES_LOAD_FAILURE",

    ACTION_TRIALPHASES_SAVE = "ACTION_TRIALPHASES_SAVE",
    ACTION_TRIALPHASES_SAVE_SUCCESS = "ACTION_TRIALPHASES_SAVE_SUCCESS",
    ACTION_TRIALPHASES_SAVE_FAILURE = "ACTION_TRIALPHASES_SAVE_FAILURE",

    ACTION_TRIALPHASES_CREATE = "ACTION_TRIALPHASES_CREATE",
    ACTION_TRIALPHASES_CREATE_SUCCESS = "ACTION_TRIALPHASES_CREATE_SUCCESS",
    ACTION_TRIALPHASES_CREATE_FAILURE = "ACTION_TRIALPHASES_CREATE_FAILURE",

    ACTION_TRIALPHASES_CLEAR = "ACTION_TRIALPHASES_CLEAR",

    ACTION_TRIALPHASES_FILTER = "ACTION_TRIALPHASES_FILTER";

export interface ITrialPhaseLoadAction {
    type: "ACTION_TRIALPHASES_LOAD";
}

export interface ITrialPhaseLoadActionCreator {
    (): ITrialPhaseLoadAction;
}

export interface ITrialPhaseLoadSuccessAction {
    type: "ACTION_TRIALPHASES_LOAD_SUCCESS";
    formData: Dtos.TrialPhase | Dtos.TrialPhase[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialPhaseLoadSuccessActionCreator {
    (
        formData: Dtos.TrialPhase | Dtos.TrialPhase[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialPhaseLoadSuccessAction;
}

export interface ITrialPhaseLoadFailureAction {
    type: "ACTION_TRIALPHASES_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialPhaseLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialPhaseLoadFailureAction;
}

export interface ITrialPhaseLoadByIdAction {
    type: "ACTION_TRIALPHASES_LOAD_BY_ID";
    id: number;
}

export interface ITrialPhaseLoadByIdActionCreator {
    (id: number): ITrialPhaseLoadByIdAction;
}


export interface ITrialPhaseSaveAction {
    type: "ACTION_TRIALPHASES_SAVE";
    formData: Dtos.TrialPhase;

}

export interface ITrialPhaseSaveActionCreator {
    (
        formData: Dtos.TrialPhase
    ): ITrialPhaseSaveAction;
}

export interface ITrialPhaseSaveSuccessAction {
    type: "ACTION_TRIALPHASES_SAVE_SUCCESS";
    formData: Dtos.TrialPhase;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialPhaseSaveSuccessActionCreator {
    (
        formData: Dtos.TrialPhase,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrialPhaseSaveSuccessAction;
}

export interface ITrialPhaseSaveFailureAction {
    type: "ACTION_TRIALPHASES_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialPhaseSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialPhaseSaveFailureAction;
}

export interface ITrialPhaseCreateAction {
    type: "ACTION_TRIALPHASES_CREATE"
}

export interface ITrialPhaseCreateActionCreator {
    (): ITrialPhaseCreateAction
}

export interface ITrialPhaseCreateSuccessAction {
    type: "ACTION_TRIALPHASES_CREATE_SUCCESS";
    formData: Dtos.TrialPhase;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface ITrialPhaseCreateSuccessActionCreator {
    (
        formData: Dtos.TrialPhase,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): ITrialPhaseCreateSuccessAction;
}

export interface ITrialPhaseCreateFailureAction {
    type: "ACTION_TRIALPHASES_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialPhaseCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialPhaseCreateFailureAction;
}

export interface ITrialPhaseClearAction {
    type: "ACTION_TRIALPHASES_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrialPhaseClearActionCreator {
    (propertiesToClear?: string[]): ITrialPhaseClearAction;
}

export interface ITrialPhaseFilterActionCreator {
    (formFilter: IFormFilter): ITrialPhaseFilterAction;
}

export interface ITrialPhaseFilterAction {
    type: "ACTION_TRIALPHASES_FILTER";
    formFilter: IFormFilter;
}

export type TrialPhaseAction =
    ITrialPhaseLoadAction |
    ITrialPhaseLoadByIdAction |
    ITrialPhaseLoadFailureAction |
    ITrialPhaseLoadSuccessAction |
    ITrialPhaseSaveAction |
    ITrialPhaseSaveSuccessAction |
    ITrialPhaseSaveFailureAction |
    ITrialPhaseCreateAction |
    ITrialPhaseCreateSuccessAction |
    ITrialPhaseCreateFailureAction |
    ITrialPhaseClearAction |
    ITrialPhaseFilterAction;
