import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadTrialItemInstanceById: ITrialItemInstanceLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TRIALITEMINSTANCE_LOAD_BY_ID,
            id
        }
    },
    LoadTrialItemInstanceCurrentUser: ITrialItemInstanceLoadCurrentUserActionCreator = () => {
        return {
            type: ACTION_TRIALITEMINSTANCE_LOAD_CURRENT_USER
        }
    },
    LoadSuccess: ITrialItemInstanceLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALITEMINSTANCE_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrialItemInstanceLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALITEMINSTANCE_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTrialItemInstance: ITrialItemInstanceSaveActionCreator = (formData) => {
        return {
            type: ACTION_TRIALITEMINSTANCE_SAVE,
            formData
        }
    },
    SaveSuccess: ITrialItemInstanceSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRIALITEMINSTANCE_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrialItemInstanceSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALITEMINSTANCE_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTrialItemInstance: ITrialItemInstanceCreateActionCreator = (trialId, templateTrialItemId, instanceNumber) => {

        return {
            type: ACTION_TRIALITEMINSTANCE_CREATE,
            trialId,
            templateTrialItemId,
            instanceNumber
        }
    },
    CreateSuccess: ITrialItemInstanceCreateSuccessActionCreator = (formData, formState, formProperties, lookups, templateTrialItems) => {
        return {
            type: ACTION_TRIALITEMINSTANCE_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            templateTrialItems
        }
    },
    CreateFailure: ITrialItemInstanceCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALITEMINSTANCE_CREATE_FAILURE,
            responseStatus
        }
    },

    Clear: ITrialItemInstanceClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRIALITEMINSTANCE_CLEAR,
            propertiesToClear
        }
    },
    Filter: ITrialItemInstanceFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRIALITEMINSTANCE_FILTER,
            formFilter
        }
    };



export const
    ACTION_TRIALITEMINSTANCE_LOAD_BY_ID = "ACTION_TRIALITEMINSTANCE_LOAD_BY_ID",
    ACTION_TRIALITEMINSTANCE_LOAD_CURRENT_USER = "ACTION_TRIALITEMINSTANCE_LOAD_CURRENT_USER",
    ACTION_TRIALITEMINSTANCE_LOAD_SUCCESS = "ACTION_TRIALITEMINSTANCE_LOAD_SUCCESS",
    ACTION_TRIALITEMINSTANCE_LOAD_FAILURE = "ACTION_TRIALITEMINSTANCE_LOAD_FAILURE",

    ACTION_TRIALITEMINSTANCE_SAVE = "ACTION_TRIALITEMINSTANCE_SAVE",
    ACTION_TRIALITEMINSTANCE_SAVE_SUCCESS = "ACTION_TRIALITEMINSTANCE_SAVE_SUCCESS",
    ACTION_TRIALITEMINSTANCE_SAVE_FAILURE = "ACTION_TRIALITEMINSTANCE_SAVE_FAILURE",

    ACTION_TRIALITEMINSTANCE_CREATE = "ACTION_TRIALITEMINSTANCE_CREATE",
    ACTION_TRIALITEMINSTANCE_CREATE_SUCCESS = "ACTION_TRIALITEMINSTANCE_CREATE_SUCCESS",
    ACTION_TRIALITEMINSTANCE_CREATE_FAILURE = "ACTION_TRIALITEMINSTANCE_CREATE_FAILURE",

    ACTION_TRIALITEMINSTANCE_FILTER = "ACTION_TRIALITEMINSTANCE_FILTER",

    ACTION_TRIALITEMINSTANCE_CLEAR = "ACTION_TRIALITEMINSTANCE_CLEAR";


export interface ITrialItemInstanceLoadSuccessAction {
    type: "ACTION_TRIALITEMINSTANCE_LOAD_SUCCESS";
    formData: Dtos.TrialItemInstance | Dtos.TrialItemInstance[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialItemInstanceLoadSuccessActionCreator {
    (
        formData: Dtos.TrialItemInstance | Dtos.TrialItemInstance[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialItemInstanceLoadSuccessAction;
}

export interface ITrialItemInstanceLoadFailureAction {
    type: "ACTION_TRIALITEMINSTANCE_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialItemInstanceLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialItemInstanceLoadFailureAction;
}

export interface ITrialItemInstanceLoadByIdAction {
    type: "ACTION_TRIALITEMINSTANCE_LOAD_BY_ID";
    id: number;
}

export interface ITrialItemInstanceLoadByIdActionCreator {
    (id: number): ITrialItemInstanceLoadByIdAction;
}

export interface ITrialItemInstanceLoadCurrentUserAction {
    type: "ACTION_TRIALITEMINSTANCE_LOAD_CURRENT_USER";
}

export interface ITrialItemInstanceLoadCurrentUserActionCreator {
    (): ITrialItemInstanceLoadCurrentUserAction;
}


export interface ITrialItemInstanceSaveAction {
    type: "ACTION_TRIALITEMINSTANCE_SAVE";
    formData: Dtos.TrialItemInstance;

}

export interface ITrialItemInstanceSaveActionCreator {
    (
        formData: Dtos.TrialItemInstance,
    ): ITrialItemInstanceSaveAction;
}

export interface ITrialItemInstanceSaveSuccessAction {
    type: "ACTION_TRIALITEMINSTANCE_SAVE_SUCCESS";
    formData: Dtos.TrialItemInstance;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialItemInstanceSaveSuccessActionCreator {
    (
        formData: Dtos.TrialItemInstance,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrialItemInstanceSaveSuccessAction;
}

export interface ITrialItemInstanceSaveFailureAction {
    type: "ACTION_TRIALITEMINSTANCE_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialItemInstanceSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialItemInstanceSaveFailureAction;
}

export interface ITrialItemInstanceCreateAction {
    type: "ACTION_TRIALITEMINSTANCE_CREATE",
    trialId: number,
    templateTrialItemId: number,
    instanceNumber: number
}

export interface ITrialItemInstanceCreateActionCreator {
    (trialId: number, templateTrialItemId: number, instanceNumber: number): ITrialItemInstanceCreateAction
}

export interface ITrialItemInstanceCreateSuccessAction {
    type: "ACTION_TRIALITEMINSTANCE_CREATE_SUCCESS";
    formData: Dtos.TrialItemInstance;
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[],
    templateTrialItems: Dtos.TemplateTrialItem[]
}

export interface ITrialItemInstanceCreateSuccessActionCreator {
    (
        formData: Dtos.TrialItemInstance,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[],
        templateTrialItems: Dtos.TemplateTrialItem[]
    ): ITrialItemInstanceCreateSuccessAction;
}

export interface ITrialItemInstanceCreateFailureAction {
    type: "ACTION_TRIALITEMINSTANCE_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialItemInstanceCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialItemInstanceCreateFailureAction;
}

export interface ITrialItemInstanceClearAction {
    type: "ACTION_TRIALITEMINSTANCE_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrialItemInstanceClearActionCreator {
    (propertiesToClear?: string[]): ITrialItemInstanceClearAction;
}

export interface ITrialItemInstanceFilterActionCreator {
    (formFilter: IFormFilter): ITrialItemInstanceFilterAction;
}

export interface ITrialItemInstanceFilterAction {
    type: "ACTION_TRIALITEMINSTANCE_FILTER";
    formFilter: IFormFilter;
}

export type TrialItemInstanceAction =
    ITrialItemInstanceLoadByIdAction |
    ITrialItemInstanceLoadCurrentUserAction | 
    ITrialItemInstanceLoadFailureAction |
    ITrialItemInstanceLoadSuccessAction |
    ITrialItemInstanceSaveAction |
    ITrialItemInstanceSaveSuccessAction |
    ITrialItemInstanceSaveFailureAction |
    ITrialItemInstanceCreateAction |
    ITrialItemInstanceCreateSuccessAction |
    ITrialItemInstanceCreateFailureAction |
    ITrialItemInstanceClearAction |
    ITrialItemInstanceFilterAction;
