import * as React from 'react';
import './TrialInstitutionItemTemplatePage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { ITrialInstitutionItemTemplateReduxFormState, initialTrialInstitutionItemTemplateState } from "../../../reducers/reactReduxForms/trialInstitutionItemTemplate";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as TrialInstitutionItemTemplateActions from '../../../actions/trialInstitutionItemTemplate';
//import * as TrialInstitutionSettingsPageActions from '../../../actions/pages/trialInstitutionSettingsPage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../../components/form';
import * as FormHelper from '../../../helpers/formHelper';
import { ItemSettingsLayout } from '../../index'

import {
    CollectionPage
} from "../../../containers/index";

interface ITrialInstitutionItemPageParams {
    trialInstitutionItemTemplateId: number;
}

interface ITrialInstitutionItemPageProps {

    form: Dtos.TrialInstitutionItemTemplate & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITrialInstitutionItemTemplateReduxFormState;

    collectionFormState: Dtos.Collection & IRegistrationFormState;

    location: Location;
    trialId: number;
    trialInstitutionItemTemplateId: number;
    match: match<ITrialInstitutionItemPageParams>;
    history: History;

    // From state
    trialInstitutionItemTemplate: Dtos.TrialInstitutionItemTemplate;
    collection: Dtos.Collection;
    lookups: Dtos.Lookup[];

    loadingTrialInstitutionItemTemplate: boolean;
    loadTrialInstitutionItemTemplateSuccess: boolean;
    loadTrialInstitutionItemTemplateFailure: boolean;

    savingTrialInstitutionItemTemplate: boolean;
    saveTrialInstitutionItemTemplateSuccess: boolean;
    saveTrialInstitutionItemTemplateFailure: boolean;

    creatingTrialInstitutionItemTemplate: boolean;
    createTrialInstitutionItemTemplateSuccess: boolean;
    createTrialInstitutionItemTemplateFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface ITrialInstitutionItemPageActions {
    clearTrialInstitutionItemTemplate: TrialInstitutionItemTemplateActions.ITrialInstitutionItemTemplateClearActionCreator;
    loadTrialInstitutionItemTemplate: TrialInstitutionItemTemplateActions.ITrialInstitutionItemTemplateLoadByIdActionCreator;
    saveTrialInstitutionItemTemplate: TrialInstitutionItemTemplateActions.ITrialInstitutionItemTemplateSaveActionCreator;
    createTrialInstitutionItemTemplate: TrialInstitutionItemTemplateActions.ITrialInstitutionItemTemplateCreateActionCreator;

    //setTrial: TrialInstitutionSettingsPageActions.ITrialInstitutionSettingsPageSetTrialActionCreator;
    
    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type TrialInstitutionItemPageProps = ITrialInstitutionItemPageProps & ITrialInstitutionItemPageActions;

const reduxFormName: string = "reduxForms.trialInstitutionItemTemplate";

class TrialInstitutionItemPage extends React.PureComponent<TrialInstitutionItemPageProps, any> {

    constructor(props: TrialInstitutionItemPageProps) {
        super(props);

        this.clearTrialInstitutionItem = this.clearTrialInstitutionItem.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTrialInstitutionItemTemplate,
            navigate,
            navigateReplace,
            trialId,
            trialInstitutionItemTemplateId,
            createTrialInstitutionItemTemplate
        } = this.props;

        if (trialInstitutionItemTemplateId) {
            loadTrialInstitutionItemTemplate(trialInstitutionItemTemplateId);
        } else {
            createTrialInstitutionItemTemplate(trialId)
        }
    }

    componentWillReceiveProps(nextProps: TrialInstitutionItemPageProps) {
    }

    componentDidUpdate(prevProps: TrialInstitutionItemPageProps, prevState) {
        const {
            form,
            trialId,
            trialInstitutionItemTemplateId,
            trialInstitutionItemTemplate,
            navigateReplace,
            loadTrialInstitutionItemTemplate,
            loadingTrialInstitutionItemTemplate,
            loadTrialInstitutionItemTemplateSuccess,
            createTrialInstitutionItemTemplate,
            creatingTrialInstitutionItemTemplate,
            createTrialInstitutionItemTemplateSuccess,
            savingTrialInstitutionItemTemplate,
            saveTrialInstitutionItemTemplateSuccess,
            saveTrialInstitutionItemTemplateFailure,
            validationFailures,
            navigate
        } = this.props;

        if (trialInstitutionItemTemplateId && prevProps.trialInstitutionItemTemplateId != trialInstitutionItemTemplateId) {
            loadTrialInstitutionItemTemplate(trialInstitutionItemTemplateId);
        }
        else if (!trialInstitutionItemTemplateId && prevProps.trialInstitutionItemTemplateId) {
            createTrialInstitutionItemTemplate(trialId);
        }

        if (!loadingTrialInstitutionItemTemplate && prevProps.loadingTrialInstitutionItemTemplate) {
            if (loadTrialInstitutionItemTemplateSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingTrialInstitutionItemTemplate && prevProps.savingTrialInstitutionItemTemplate) {
            if (saveTrialInstitutionItemTemplateSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/settings/item/itemTemplates")
                } else if (!form.saveAndReturn && trialInstitutionItemTemplateId && trialInstitutionItemTemplateId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !trialInstitutionItemTemplateId && trialInstitutionItemTemplate && trialInstitutionItemTemplate.id > 0) {
                    navigate("/settings/item/itemTemplates/" + trialInstitutionItemTemplate.id);
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingTrialInstitutionItemTemplate && prevProps.creatingTrialInstitutionItemTemplate) {
            if (createTrialInstitutionItemTemplateSuccess) {
                this.loadForm();
            }
            else {

            }
        }
    }

    componentWillUpdate(nextProps: TrialInstitutionItemPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearTrialInstitutionItem();
        loadForm(reduxFormName, initialTrialInstitutionItemTemplateState);
        resetForm(reduxFormName);
    }

    clearTrialInstitutionItem() {
        this.props.clearTrialInstitutionItemTemplate();
    }

    showTrialInstitutionItem(): boolean {
        const {
            loadingTrialInstitutionItemTemplate,
            loadTrialInstitutionItemTemplateSuccess
        } = this.props

        if (!loadingTrialInstitutionItemTemplate && loadTrialInstitutionItemTemplateSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingTrialInstitutionItemTemplate,
            trialInstitutionItemTemplateId,
            permissions,
        } = this.props;

        return <ItemSettingsLayout
            permissions={permissions}
            permission={Dtos.Permission.TrialInstitutionItemTemplatesView}
            loading={loadingTrialInstitutionItemTemplate}
        >
            {this.renderContent()}
        </ItemSettingsLayout>;
    }

    renderContent() {
        const {
            form,
            permissions,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingTrialInstitutionItemTemplate
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.TrialInstitutionItemTemplatesUpdate, Dtos.Permission.TrialInstitutionItemTemplatesCreate);
        const formDisabled: boolean = !hasPermission || savingTrialInstitutionItemTemplate || (formState && formState.formStatus == Dtos.FormStatus.Disabled);
        const formNew: boolean = FormHelper.isNew(formState, form);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            name: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            /*trialId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !formNew,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("TrialInstitutionItemTemplate", "TrialId", lookups), form.trialId)
                }
            },*/
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            categoryId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("TrialInstitutionItemTemplate", "CategoryId", lookups), form.categoryId)
                }
            },
            trialInstitutionItemsHeader: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="border-0 d-flex pt-4 ">
                    <h3>Item Template Components</h3>
                </div>
            },
            collectionTrialInstitution: {
                type: RegistrationComponent.RegistrationElement,
                component: this.renderCollection()
            },
            description: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextAreaFormInput,
                inputDisabled: formDisabled,
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            },
            allowVersions: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Allow Versions"
                },
                inputLabel: " ",
            },
            initialVersion: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled ,
            }
        };

        return <div>
            <h1>Trial Institution Item Template</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "trialInstitutionItemTemplate", formComponents, lookups, validationFailures, formProperties, changeForm)
                }

                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
        </div>

    }

    renderCollection() {
        const {
            form,
            loadingTrialInstitutionItemTemplate,
            savingTrialInstitutionItemTemplate,
            permissions,
            formState
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.TrialInstitutionItemTemplatesUpdate, Dtos.Permission.TrialInstitutionItemTemplatesCreate);
        const formDisabled: boolean = !hasPermission || savingTrialInstitutionItemTemplate || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        if (form && !loadingTrialInstitutionItemTemplate) {
            return <CollectionPage
                collectionId={form.collectionId || 0}
                readonly={formDisabled} />
        }
    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
        </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.TrialInstitutionItemTemplate) {
        const {
            saveTrialInstitutionItemTemplate,
            collection
        } = this.props;

        if (data) {
            let changes: Dtos.TrialInstitutionItemTemplate = {} as Dtos.TrialInstitutionItemTemplate;
            let collectionChanges: Dtos.Collection = {} as Dtos.Collection;
            saveTrialInstitutionItemTemplate(Object.assign({ ...data }, changes), Object.assign({ ...collection }, collectionChanges));
        }
    }

    handleOnSubmitFailed(data: Dtos.TrialInstitutionItemTemplate) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/item/itemTemplates");
    }

    loadForm() {
        const {
            loadForm,
            trialInstitutionItemTemplate,
        } = this.props

        let extraData: Dtos.TrialInstitutionItemTemplate = {} as Dtos.TrialInstitutionItemTemplate;

        loadForm(reduxFormName, Object.assign({ ...trialInstitutionItemTemplate }, extraData));
    }
}


const mapStateToProps = (state: ITmdState, ownProps: TrialInstitutionItemPageProps): ITrialInstitutionItemPageProps => {

    let trialInstitutionItemTemplate: Dtos.TrialInstitutionItemTemplate | undefined = !(state.trialInstitutionItemTemplate.formData instanceof Array) ? state.trialInstitutionItemTemplate.formData : undefined;
    let collection: Dtos.Collection | undefined = !(state.reduxForms.collection instanceof Array) ? state.reduxForms.collection : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialId: undefined, //state.trialInstitutionSettingsPage.trialId,
        trialInstitutionItemTemplateId: ownProps.match && ownProps.match.params.trialInstitutionItemTemplateId.toString() != "create" ? ownProps.match.params.trialInstitutionItemTemplateId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trialInstitutionItemTemplate: trialInstitutionItemTemplate!,
        collection: collection!,

        loadingTrialInstitutionItemTemplate: state.trialInstitutionItemTemplate.loadState && state.trialInstitutionItemTemplate.loadState.status == RequestState.Pending,
        loadTrialInstitutionItemTemplateSuccess: state.trialInstitutionItemTemplate.loadState && state.trialInstitutionItemTemplate.loadState.status == RequestState.Success,
        loadTrialInstitutionItemTemplateFailure: state.trialInstitutionItemTemplate.loadState && state.trialInstitutionItemTemplate.loadState.status == RequestState.Failure,

        savingTrialInstitutionItemTemplate: state.trialInstitutionItemTemplate.saveState && state.trialInstitutionItemTemplate.saveState.status == RequestState.Pending,
        saveTrialInstitutionItemTemplateSuccess: state.trialInstitutionItemTemplate.saveState && state.trialInstitutionItemTemplate.saveState.status == RequestState.Success,
        saveTrialInstitutionItemTemplateFailure: state.trialInstitutionItemTemplate.saveState && state.trialInstitutionItemTemplate.saveState.status == RequestState.Failure,

        creatingTrialInstitutionItemTemplate: state.trialInstitutionItemTemplate.createState && state.trialInstitutionItemTemplate.createState.status == RequestState.Pending,
        createTrialInstitutionItemTemplateSuccess: state.trialInstitutionItemTemplate.createState && state.trialInstitutionItemTemplate.createState.status == RequestState.Success,
        createTrialInstitutionItemTemplateFailure: state.trialInstitutionItemTemplate.createState && state.trialInstitutionItemTemplate.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: state.trialInstitutionItemTemplate.lookups,

        form: state.reduxForms.trialInstitutionItemTemplate,
        formState: state.trialInstitutionItemTemplate.formState,
        formProperties: state.trialInstitutionItemTemplate.formProperties,

        collectionFormState: state.reduxForms.collection,

        reduxFormState: state.reduxForms.formState.trialInstitutionItemTemplate,
        validationFailures: trialInstitutionItemTemplate ?
            state.trialInstitutionItemTemplate.validationFailures :
            undefined,

    };
};

const mapDispatchToProps = (dispatch): ITrialInstitutionItemPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadTrialInstitutionItemTemplate: bindActionCreators(TrialInstitutionItemTemplateActions.LoadTrialInstitutionItemTemplateById, dispatch),
        clearTrialInstitutionItemTemplate: bindActionCreators(TrialInstitutionItemTemplateActions.Clear, dispatch),

        saveTrialInstitutionItemTemplate: bindActionCreators(TrialInstitutionItemTemplateActions.SaveTrialInstitutionItemTemplate, dispatch),
        createTrialInstitutionItemTemplate: bindActionCreators(TrialInstitutionItemTemplateActions.CreateTrialInstitutionItemTemplate, dispatch),

        //setTrial: bindActionCreators(TrialInstitutionSettingsPageActions.SetTrial, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialInstitutionItemPage);
