import * as React from 'react';
import './CountryPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { ICountryReduxFormState, initialCountryState } from "../../../reducers/reactReduxForms/country";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as CountryActions from '../../../actions/countries';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { Form, actions, Control } from 'react-redux-form';
import { LookupsLayout } from '../../index';

import {
    CollectionPage
} from "../../../containers/index";

interface ICountryPageParams {
    countryId: number;
}

interface ICountryPageProps {

    form: Dtos.Country & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ICountryReduxFormState;

    location: Location;
    countryId: number;
    match: match<ICountryPageParams>;
    history: History;

    // From state
    country: Dtos.Country;
    lookups: Dtos.Lookup[];

    loadingCountry: boolean;
    loadCountrySuccess: boolean;
    loadCountryFailure: boolean;

    savingCountry: boolean;
    saveCountrySuccess: boolean;
    saveCountryFailure: boolean;

    creatingCountry: boolean;
    createCountrySuccess: boolean;
    createCountryFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface ICountryPageActions {
    clearCountry: CountryActions.ICountryClearActionCreator;
    loadCountry: CountryActions.ICountryLoadByIdActionCreator;

    saveCountry: CountryActions.ICountrySaveActionCreator;
    createCountry: CountryActions.ICountryCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type CountryPageProps = ICountryPageProps & ICountryPageActions;


const formName: string = "country";
const reduxFormName: string = "reduxForms.country";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class CountryPage extends React.PureComponent<CountryPageProps, any> {

    constructor(props: CountryPageProps) {
        super(props);

        this.clearCountry = this.clearCountry.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadCountry,
            navigate,
            navigateReplace,
            countryId,
            createCountry
        } = this.props;

        if (countryId) {
            loadCountry(countryId);
        } else if (!countryId) {
            createCountry();
        }
    }

    componentWillReceiveProps(nextProps: CountryPageProps) {
    }

    componentDidUpdate(prevProps: CountryPageProps, prevState) {
        const {
            navigateReplace,
            countryId,
            country,
            form,
            navigate,
            loadCountry,
            loadingCountry,
            loadCountrySuccess,
            createCountry,
            creatingCountry,
            createCountrySuccess,
            savingCountry,
            saveCountrySuccess,
            saveCountryFailure,
            validationFailures
        } = this.props;

        if (countryId && prevProps.countryId != countryId) {
            loadCountry(countryId);
        }
        else if (!countryId && prevProps.countryId) {
            createCountry();
        }

        if (!loadingCountry && prevProps.loadingCountry) {
            if (loadCountrySuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingCountry && prevProps.savingCountry) {
            if (saveCountrySuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/settings/general/countries")
                } else if (!form.saveAndReturn && countryId && countryId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !countryId && country && country.id > 0) {
                    navigate("/settings/general/countries/" + country.id);
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingCountry && prevProps.creatingCountry) {
            if (createCountrySuccess) {
                this.loadForm();
            }
            else {

            }
        }

    }

    componentWillUpdate(nextProps: CountryPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearCountry();
        loadForm(reduxFormName, initialCountryState);
        resetForm(reduxFormName);
    }

    clearCountry() {
        this.props.clearCountry();
    }

    showCountry(): boolean {
        const {
            loadingCountry,
            loadCountrySuccess
        } = this.props

        if (!loadingCountry && loadCountrySuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingCountry
        } = this.props;

        return <LookupsLayout loading={loadingCountry} permission={Dtos.Permission.CountriesView}>
            {this.renderContent()}
        </LookupsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingCountry,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.CountriesUpdate, Dtos.Permission.CountriesCreate);
        const formDisabled: boolean = !hasPermission || savingCountry || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            value: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            }
        };

        

        return <div>
            <h1>Country</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "country", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.Country) {
        const {
            saveCountry
        } = this.props;

        if (data) {
            let changes: Dtos.Institution = {} as Dtos.Institution;
            saveCountry(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.Country) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/general/countries");
    }

    loadForm() {
        const {
            loadForm,
            country,
        } = this.props

        let extraData: Dtos.Country = {} as Dtos.Country;

        loadForm(reduxFormName, Object.assign({ ...country }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: CountryPageProps): ICountryPageProps => {

    let country: Dtos.Country | undefined = !(state.countries.formData instanceof Array) ? state.countries.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        countryId: ownProps.match && ownProps.match.params.countryId && ownProps.match.params.countryId.toString() != "create" ? ownProps.match.params.countryId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        country: country!,

        loadingCountry: state.countries.loadState && state.countries.loadState.status == RequestState.Pending,
        loadCountrySuccess: state.countries.loadState && state.countries.loadState.status == RequestState.Success,
        loadCountryFailure: state.countries.loadState && state.countries.loadState.status == RequestState.Failure,

        savingCountry: state.countries.saveState && state.countries.saveState.status == RequestState.Pending,
        saveCountrySuccess: state.countries.saveState && state.countries.saveState.status == RequestState.Success,
        saveCountryFailure: state.countries.saveState && state.countries.saveState.status == RequestState.Failure,

        creatingCountry: state.countries.createState && state.countries.createState.status == RequestState.Pending,
        createCountrySuccess: state.countries.createState && state.countries.createState.status == RequestState.Success,
        createCountryFailure: state.countries.createState && state.countries.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: [],

        form: state.reduxForms.country,
        formState: state.countries.formState,
        formProperties: state.countries.formProperties,

        reduxFormState: state.reduxForms.formState.country,
        validationFailures: country ?
            state.countries.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): ICountryPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadCountry: bindActionCreators(CountryActions.LoadCountryById, dispatch),
        clearCountry: bindActionCreators(CountryActions.Clear, dispatch),

        saveCountry: bindActionCreators(CountryActions.SaveCountry, dispatch),
        createCountry: bindActionCreators(CountryActions.CreateCountry, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(CountryPage);
