import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_REPORTINGTAG_LOAD,
    ACTION_REPORTINGTAG_LOAD_BY_ID,

    ACTION_REPORTINGTAG_SAVE,

    ACTION_REPORTINGTAG_CREATE,

    IReportingTagLoadAction,
    IReportingTagLoadByIdAction,
    IReportingTagLoadFailureAction,
    IReportingTagLoadSuccessAction,

    IReportingTagSaveAction,
    IReportingTagSaveSuccessAction,
    IReportingTagSaveFailureAction,

    IReportingTagCreateAction,
    IReportingTagCreateSuccessAction,
    IReportingTagCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/reportingTags";
import { reportingApi } from "../services/reportingTag";


export const
    getReportingTagsEpic = (action$): Observable<IReportingTagLoadSuccessAction | IReportingTagLoadFailureAction> => {
        return action$
            .ofType(ACTION_REPORTINGTAG_LOAD)
            .mergeMap((action: IReportingTagLoadAction) => {
                return reportingApi
                    .getReportingTags()
                    .map(response =>
                        LoadSuccess(response.reportingTags, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getReportingTagByIdEpic = (action$): Observable<IReportingTagLoadSuccessAction | IReportingTagLoadFailureAction> => {
        return action$
            .ofType(ACTION_REPORTINGTAG_LOAD_BY_ID)
            .mergeMap((action: IReportingTagLoadByIdAction) => {
                return reportingApi
                    .getReportingTagById(action.id)
                    .map(response =>
                        LoadSuccess(response.reportingTag, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setReportingTagEpic = (action$): Observable<IReportingTagSaveSuccessAction | IReportingTagSaveFailureAction> => {
        return action$
            .ofType(ACTION_REPORTINGTAG_SAVE)
            .mergeMap((action: IReportingTagSaveAction) => {
                return reportingApi
                    .setReportingTag(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.reportingTag, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createReportingTagEpic = (action$): Observable<IReportingTagCreateSuccessAction | IReportingTagCreateFailureAction> => {
        return action$
            .ofType(ACTION_REPORTINGTAG_CREATE)
            .mergeMap((action: IReportingTagCreateAction) => {
                return reportingApi
                    .createReportingTag()
                    .map(response =>
                        CreateSuccess(
                            response.reportingTag,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



