import * as React from 'react';
import * as classNames from "classnames";
import './TrialTreatmentsPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactSelectClass from "react-select";
import ReactTable, { Column, RowInfo } from 'react-table';
import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";
import * as TrialActions from '../../../actions/trial';
import * as TrialTreatmentActions from '../../../actions/trialTreatment';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup, findLookupValue } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput, FormFilterInput } from '../../../components/form';
import { TrialSubMenu } from '../../../helpers/subMenuHelper';
import { TrialLayout } from '../../index';

interface ITrialTreatmentsPageParams {
    trialId: number;
}

interface ITrialTreatmentsPageProps {

    location: Location;
    trialId: number;
    match: match<ITrialTreatmentsPageParams>;
    history: History;

    // From state
    formFilter: IFormFilter;
    trial: Dtos.Trial;
    trialRoles: Dtos.RoleType[];
    trialTreatments: Dtos.TrialTreatment[],
    loadingTrial: boolean;
    loadingTrialTreatments: boolean;
    loadTrialSuccess: boolean;
    loadTrialFailure: boolean;

    validationFailures: Dtos.ResponseError[];
    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    lookups: Dtos.Lookup[];
    formRoles: Dtos.RoleType[];
}

interface ITrialTreatmentsPageActions {
    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;
    loadTrialTreatments: TrialTreatmentActions.ITrialTreatmentLoadActionCreator;

    filter: TrialTreatmentActions.ITrialTreatmentFilterActionCreator;
    syncTrialTreatments: TrialTreatmentActions.ITrialTreatmentSyncActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type TrialTreatmentsPageProps = ITrialTreatmentsPageProps & ITrialTreatmentsPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class TrialTreatmentsPage extends React.PureComponent<TrialTreatmentsPageProps, any> {

    constructor(props: TrialTreatmentsPageProps) {
        super(props);

        this.clearTrial = this.clearTrial.bind(this);
    }

    componentDidMount() {
        const {
            loadTrial,
            loadTrialTreatments,
            navigate,
            navigateReplace,
            trialId
        } = this.props;

        loadTrial(trialId);
        loadTrialTreatments(trialId);
    }

    componentWillReceiveProps(nextProps: TrialTreatmentsPageProps) {
    }

    componentDidUpdate(prevProps: TrialTreatmentsPageProps, prevState) {
        const {
            navigateReplace,
            loadTrial
        } = this.props
    }

    componentWillUpdate(nextProps: TrialTreatmentsPageProps) {
    }

    componentWillUnmount() {
        this.clearTrial();
    }

    clearTrial() {
        this.props.clearTrial();
    }

    showTrial(): boolean {
        const {
            loadingTrial,
            loadingTrialTreatments,
            loadTrialSuccess
        } = this.props

        if (!loadingTrial && loadTrialSuccess && !loadingTrialTreatments) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrial,
            loadingTrialTreatments
        } = this.props

        return (
            <TrialLayout loading={loadingTrial || loadingTrialTreatments } permission={Dtos.Permission.TrialTreatmentsView}>{this.renderContent()}</TrialLayout>
        );
    }

    renderContent() {
        const {
            trialId,
            trial,
            permissions,
            loadingTrialTreatments,
            syncTrialTreatments,
            validationFailures
        } = this.props;

        let syncError: string = "";
        if (validationFailures) {
            validationFailures.forEach((validationFailure) => {
                if (validationFailure.errorCode === "TT-000") {
                    syncError = validationFailure.message
                }
            });
        }

        return <div>
            <div className="row">
                <div className={"col mb-2"}>
                    <h2>Treatments</h2>
                </div>
                <div className="col-auto" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} />
                    {(AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialTreatmentsCreate, trialId, null) && trial && trial.id) > 0 && (
                        <button type="button" disabled={loadingTrialTreatments} className="btn btn-info mr-2" onClick={() => syncTrialTreatments(trialId)}>
                            <span>Sync</span>
                        </button>
                    )}
                    {(AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialTreatmentsCreate, trialId, null) && trial && trial.id) > 0 && (
                        <Link className={"btn btn-primary"} url={"/trials/" + trial.id + '/treatments/create'}>Add Treatment</Link>
                    )}
                </div>
            </div>
            {syncError && <div className="form-group row pt-2 pb-0 border border-bottom-1 border-top-0">
                <div className="col-md-12 mt-2">
                    <div className="alert alert-danger d-flex">
                        <div className="mr-2">
                            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE} fixedWidth />
                        </div>
                        <span>{syncError}</span>
                    </div>
                </div>
            </div>}
            {
                !this.showTrial() ?
                    "Loading Trial Treatments" :
                    null
            }
            {
                this.showTrial() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {

        const {
            trial,
            trialTreatments,
            user,
            lookups,
            formRoles
        } = this.props

        if (!trialTreatments || trialTreatments.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no trial groups</span>
            </div>
        }

        const showPagination = trialTreatments.length > DEFAULT_PAGE_SIZE;
        
        return <div className="table-wrapper mb-3">
            <ReactTable
                data={trialTreatments}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : trialTreatments.length}
                pageSize={showPagination ? undefined : trialTreatments.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no trial treatments to display"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            const url = "/trials/" + rowInfo.original.trialId + "/treatments/" + rowInfo.original.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                defaultSorted={[
                    {
                        id: "name",
                        desc: false
                    }
                ]}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <div className="large-small-icon"><FontAwesomeIcon className="large-icon" icon={FontAwesomeIcons.Light.TRIAL} fixedWidth /><FontAwesomeIcon className="small-icon" icon={FontAwesomeIcons.Light.INSTITUTION} fixedWidth /></div>
                    },
                    {
                        id: "name",
                        Header: 'Randomisation Arm',
                        accessor: 'name',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    },
                    {
                        id: "shortName",
                        Header: 'Short Name',
                        accessor: 'shortName',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    },
                    {
                        id: "duration",
                        Header: 'Duration',
                        accessor: 'duration',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 160
                    },
                    {
                        id: "targetAccrual",
                        Header: 'Target Accrual',
                        accessor: 'targetAccrual',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 160
                    }
                ]}
            />
        </div>

    }


}


const trialTreatmentActiveFilter = (items: Dtos.TrialTreatment[], filter: IFormFilter): Dtos.TrialTreatment[] => {

    if (items && filter) {
        if (!filter.showInActive) {
            items = items.filter(f => f.active && f.active);
        }
        if (filter.search.length > 0) {
            items = items.filter(f => f.name.toLowerCase().indexOf(filter.search.toLowerCase()) > -1);
        }
        return items;
    }

    return [];
}


const mapStateToProps = (state: ITmdState, ownProps: TrialTreatmentsPageProps): ITrialTreatmentsPageProps => {

    let trial: Dtos.Trial | undefined = !(state.trials.formData instanceof Array) ? state.trials.formData : undefined;
    let trialTreatments: Dtos.TrialTreatment[] | undefined = (state.trialTreatment.formData instanceof Array) ? state.trialTreatment.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        formFilter: state.trialTreatment.formFilter,
        trial: trial!,
        trialRoles: state.trials.formRoles,
        trialTreatments: trialTreatmentActiveFilter(trialTreatments!, state.trialTreatment.formFilter!),
        lookups: state.trials.lookups,

        loadingTrial: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,
        loadTrialSuccess: state.trials.loadState && state.trials.loadState.status == RequestState.Success,
        loadTrialFailure: state.trials.loadState && state.trials.loadState.status == RequestState.Failure,

        loadingTrialTreatments: state.trialTreatment.loadState && state.trialTreatment.loadState.status == RequestState.Pending,

        validationFailures: state.trialTreatment.validationFailures,
        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles,
    };
};

const mapDispatchToProps = (dispatch): ITrialTreatmentsPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        filter: bindActionCreators(TrialTreatmentActions.Filter, dispatch),
        syncTrialTreatments: bindActionCreators(TrialTreatmentActions.SyncTrialTreatment, dispatch),

        loadTrialTreatments: bindActionCreators(TrialTreatmentActions.LoadTrialTreatment, dispatch),
        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialTreatmentsPage);
