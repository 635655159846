import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialInstitutionFormState: Dtos.InstitutionForm & IRegistrationFormState = {
    id: null,
    institutionId: null,
    institution: null,
    formTemplateId: null,
    formTemplate: null,
    formStatusId: null,
    formStatus: null,
    version: null,
    assignedToId: null,
    assignedTo: null,
    formNextAutoVersionId: null,
    formNextAutoVersion: null,
    dateNextVersion: null,
    collectionInstanceId: null,
    collectionInstance: null,
    active: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false,
    versions: []
}

export interface IInstitutionFormReduxFormState {
    id: IFieldState<number>,
    institutionId: IFieldState<number>,
    institution: IFieldState<Dtos.Institution>,
    formTemplateId: IFieldState<number>,
    formTemplate: IFieldState<Dtos.FormTemplate>,
    formStatusId: IFieldState<number>,
    formStatus: IFieldState<Dtos.FormStatus>,
    version: IFieldState<string>,
    assignedToId: IFieldState<number>,
    assignedTo: IFieldState<Dtos.Personnel>,
    formNextAutoVersionId: IFieldState<number>,
    formNextAutoVersion: IFieldState<Dtos.FormNextAutoVersion>,
    dateNextVersion: IFieldState<string>,
    collectionInstanceId: IFieldState<number>,
    collectionInstance: IFieldState<Dtos.CollectionInstance>,
    active: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>,
    versions: IFieldState<Dtos.InstitutionForm[]>
}
