import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialHubspotState: Dtos.Hubspot & IRegistrationFormState = {
    email: null,
    personnel: null,
    hasHubspotRecord: null,
    id: null,
    firstname: null,
    lastname: null,
    company: null,
    phone: null,
    website: null,
    archived: null,
    link: null,
    saveAndReturn: false,
    data: [],
    adUsername: null,
    adGroups: null,
    active: null,
    search: null
}

export interface IHubspotReduxFormState {
    email: IFieldState<boolean>,
    hasHubspotRecord: IFieldState<boolean>,
    id: IFieldState<string>,
    firstname: IFieldState<string>,
    lastname: IFieldState<string>,
    company: IFieldState<string>,
    phone: IFieldState<string>,
    website: IFieldState<string>,
    link: IFieldState<string>,
    archived: IFieldState<boolean>,
    openedModals: IFieldState<string[]>,
    personnel: IFieldState<Dtos.Personnel>,
    data: IFieldState<Dtos.HubspotField[]>,
    adUsername: IFieldState<string>,
    adGroups: IFieldState<string>,
    active: IFieldState<string>,
    search: IFieldState<string>
}
