import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Tmd.dtos';

import {
    ACTION_SEARCH,
    ACTION_SEARCH_SUCCESS,
    ACTION_SEARCH_FAILURE,

    ACTION_SEARCH_CLEAR,
    SearchAction
} from '../actions/search';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState } from "./index";
import { updateFailureIndexToId } from "../helpers/formErrorHelper";

export interface ISearchState {
    formData: Dtos.SearchResult,
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    validationFailures: Dtos.ResponseError[];
    lookups: Dtos.Lookup[],
    loadState: IRequestState;
}

const initialState: ISearchState = {
    formData: undefined,
    formState: undefined,
    formProperties: undefined,
    validationFailures: undefined,
    lookups: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState
};


function loading(state: ISearchState, isLoading: boolean): ISearchState {
    const newState: ISearchState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function searchSuccess(state: ISearchState,
    formData: Dtos.SearchResult,
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[],
    responseStatus: Dtos.ResponseStatus
): ISearchState {
    const newState: ISearchState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        loadState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function searchFailure(state: ISearchState, responseStatus: Dtos.ResponseStatus): ISearchState {
    const newState: ISearchState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: ISearchState, propertiesToClear?: string[]): ISearchState {

    if (!propertiesToClear) {
        const newState: ISearchState = update(initialState, {});
        return newState;
    }

    let stateModifier: any = {};

    propertiesToClear.forEach(propertyToClear => {
        stateModifier[propertyToClear] = { $set: initialState[propertyToClear] }
    });

    const newState = update(state, stateModifier);

    return newState;
}

const SearchReducer: Reducer<ISearchState> = (state: ISearchState = initialState, action: SearchAction) => {
    switch (action.type) {
        case ACTION_SEARCH_CLEAR:
            return clear(state, action.propertiesToClear);
        case ACTION_SEARCH:
            return loading(state, true);
        case ACTION_SEARCH_SUCCESS:
            return searchSuccess(state, action.formData, action.formState, action.formProperties, action.lookups, action.responseStatus);
        case ACTION_SEARCH_FAILURE:
            return searchFailure(state, action.responseStatus);
    }

    return state;
}

export default SearchReducer;