import * as React from 'react';

import * as classNames from "classnames";
import './TrialGroupItemCreateVersion.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";



import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { SubControlLayout } from "../../../components/layouts/index";
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu, SubPageLayout } from "../../../components/layouts/index";
// add state for sub forms too below
import { ITrialGroupItemReduxFormState, initialTrialGroupItemState } from "../../../reducers/reactReduxForms/trialGroupItem";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";

import * as TrialGroupItemActions from '../../../actions/trialGroupItem';
import * as TrialActions from '../../../actions/trial';

import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../../components/form';
import { TrialSubMenu } from '../../../helpers/subMenuHelper';


interface ITrialGroupItemCreateVersionProps {
    trialGroupItemId?: number;
    trialGroupId?: number;
    groupItemId?: number;

    form?: Dtos.TrialGroupItem & IRegistrationFormState;
    formState?: Dtos.FormState;
    formProperties?: Dtos.FormProperty[];
    reduxFormState?: ITrialGroupItemReduxFormState;

    location?: Location;

    // From state
    trialGroupItem?: Dtos.TrialGroupItem;
    lookups?: Dtos.Lookup[];

    loadingTrialGroupItem?: boolean;
    loadTrialGroupItemSuccess?: boolean;
    loadTrialGroupItemFailure?: boolean;

    savingTrialGroupItem?: boolean;
    saveTrialGroupItemSuccess?: boolean;
    saveTrialGroupItemFailure?: boolean;

    creatingTrialGroupItem?: boolean;
    createTrialGroupItemSuccess?: boolean;
    createTrialGroupItemFailure?: boolean;

    validationFailures?: Dtos.ResponseError[];

    onClose?: Function;

    user?: Dtos.User
}

interface ITrialGroupItemCreateVersionActions {
    clearTrialGroupItem?: TrialGroupItemActions.ITrialGroupItemClearActionCreator;
    loadTrialGroupItem?: TrialGroupItemActions.ITrialGroupItemLoadByIdActionCreator;
    saveTrialGroupItem?: TrialGroupItemActions.ITrialGroupItemSaveActionCreator;
    createTrialGroupItem?: TrialGroupItemActions.ITrialGroupItemCreateActionCreator;

    loadForm?: typeof actions.load;
    changeForm?: typeof actions.change;
    resetForm?: typeof actions.reset;

    navigate?: typeof routerActions.push;
    navigateReplace?: typeof routerActions.replace;

}

type TrialGroupItemCreateVersionProps = ITrialGroupItemCreateVersionProps & ITrialGroupItemCreateVersionActions;

const formName: string = "TrialGroupItem";
const reduxFormName: string = "reduxForms.trialGroupItem";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class TrialGroupItemCreateVersion extends React.PureComponent<TrialGroupItemCreateVersionProps, any> {

    constructor(props: TrialGroupItemCreateVersionProps) {
        super(props);

        this.clearTrialGroupItem = this.clearTrialGroupItem.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTrialGroupItem,
            navigate,
            navigateReplace,
            trialGroupItemId,
            createTrialGroupItem,
            trialGroupId,
            groupItemId,
            validationFailures
        } = this.props;

        if (trialGroupId && groupItemId) {
            createTrialGroupItem(trialGroupId, groupItemId);
        }
    }

    componentWillReceiveProps(nextProps: TrialGroupItemCreateVersionProps) {
    }

    componentDidUpdate(prevProps: TrialGroupItemCreateVersionProps, prevState) {
        const {
            trialGroupId,
            groupItemId,
            createTrialGroupItem,
            creatingTrialGroupItem,
            createTrialGroupItemSuccess,
            savingTrialGroupItem,
            saveTrialGroupItemSuccess,
            saveTrialGroupItemFailure,
            validationFailures,
            form
        } = this.props;

        if (trialGroupId && prevProps.trialGroupId != trialGroupId ||
            groupItemId && prevProps.groupItemId != groupItemId) {
            createTrialGroupItem(trialGroupId, groupItemId);
        }

        if (!creatingTrialGroupItem && prevProps.creatingTrialGroupItem) {
            if (createTrialGroupItemSuccess) {
                this.loadForm();
            }
            else {

            }
        }

        if (!savingTrialGroupItem && prevProps.savingTrialGroupItem) {
            if (saveTrialGroupItemSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                this.onCancel();
                /*
                if (form.saveAndReturn) {
                    navigate("/settings/groups/" + groupId + "/groupitems")
                } else if (!form.saveAndReturn && groupItemId && groupItemId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !groupItemId && groupItem && groupItem.id > 0) {
                    navigate("/settings/groups/" + groupId + "/groupitems/" + groupItem.id);
                }
                */
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

    }

    componentWillUpdate(nextProps: TrialGroupItemCreateVersionProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearTrialGroupItem();
        loadForm(reduxFormName, initialTrialGroupItemState);
        resetForm(reduxFormName);
    }

    clearTrialGroupItem() {
        this.props.clearTrialGroupItem();
    }

    showTrialGroupItem(): boolean {
        const {
            loadingTrialGroupItem,
            loadTrialGroupItemSuccess
        } = this.props

        if (!loadingTrialGroupItem && loadTrialGroupItemSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingTrialGroupItem,
            trialGroupItemId,
            trialGroupItem
        } = this.props;

        return <SubPageLayout loading={loadingTrialGroupItem}>
            {this.renderContent()}
        </SubPageLayout>;
    }

    renderContent() {
        const {
            form,
            trialGroupItem,
            trialGroupItemId,
            loadingTrialGroupItem,
            loadTrialGroupItemSuccess,
            loadTrialGroupItemFailure,
            clearTrialGroupItem,
            navigate,
            user,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingTrialGroupItem
        } = this.props;

        const formDisabled: boolean = savingTrialGroupItem || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            version: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
        };

        return <div>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "trialGroupItem", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
            
        </div>

    }


    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.TrialGroupItem) {
        const {
            saveTrialGroupItem,
        } = this.props;

        if (data) {
            let changes: Dtos.TrialGroupItem = {} as Dtos.TrialGroupItem;
            saveTrialGroupItem(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.TrialGroupItem) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        if (this.props.onClose) { this.props.onClose() }
    }

    loadForm() {
        const {
            loadForm,
            trialGroupItem,
        } = this.props

        let extraData: Dtos.TrialGroupItem = {} as Dtos.TrialGroupItem;

        loadForm(reduxFormName, Object.assign({ ...trialGroupItem }, extraData));
    }
}


const mapStateToProps = (state: ITmdState, ownProps: TrialGroupItemCreateVersionProps): ITrialGroupItemCreateVersionProps => {

    let trialGroupItem: Dtos.TrialGroupItem | undefined = !(state.trialGroupItem.formData instanceof Array) ? state.trialGroupItem.formData : undefined;
    
    return {
        trialGroupItemId: ownProps.trialGroupItemId ? ownProps.trialGroupItemId : undefined!,
        trialGroupId: ownProps.trialGroupId ? ownProps.trialGroupId : undefined!,
        groupItemId: ownProps.groupItemId ? ownProps.groupItemId : undefined!,

        location: state.routing.location,

        trialGroupItem: trialGroupItem!,

        loadingTrialGroupItem: state.trialGroupItem.loadState && state.trialGroupItem.loadState.status == RequestState.Pending,
        loadTrialGroupItemSuccess: state.trialGroupItem.loadState && state.trialGroupItem.loadState.status == RequestState.Success,
        loadTrialGroupItemFailure: state.trialGroupItem.loadState && state.trialGroupItem.loadState.status == RequestState.Failure,

        savingTrialGroupItem: state.trialGroupItem.saveState && state.trialGroupItem.saveState.status == RequestState.Pending,
        saveTrialGroupItemSuccess: state.trialGroupItem.saveState && state.trialGroupItem.saveState.status == RequestState.Success,
        saveTrialGroupItemFailure: state.trialGroupItem.saveState && state.trialGroupItem.saveState.status == RequestState.Failure,

        creatingTrialGroupItem: state.trialGroupItem.createState && state.trialGroupItem.createState.status == RequestState.Pending,
        createTrialGroupItemSuccess: state.trialGroupItem.createState && state.trialGroupItem.createState.status == RequestState.Success,
        createTrialGroupItemFailure: state.trialGroupItem.createState && state.trialGroupItem.createState.status == RequestState.Failure,

        user: state.user.data,
        lookups: state.trialGroupItem.lookups,

        form: state.reduxForms.trialGroupItem,
        formState: state.trialGroupItem.formState,
        formProperties: state.trialGroupItem.formProperties,

        reduxFormState: state.reduxForms.formState.trialGroupItem,
        validationFailures: state.trialGroupItem.validationFailures,

    };
};

const mapDispatchToProps = (dispatch): ITrialGroupItemCreateVersionActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadTrialGroupItem: bindActionCreators(TrialGroupItemActions.LoadTrialGroupItemById, dispatch),
        clearTrialGroupItem: bindActionCreators(TrialGroupItemActions.Clear, dispatch),

        saveTrialGroupItem: bindActionCreators(TrialGroupItemActions.SaveTrialGroupItem, dispatch),
        createTrialGroupItem: bindActionCreators(TrialGroupItemActions.CreateTrialGroupItem, dispatch),

    };
};

//export default
//    connect(mapStateToProps, mapDispatchToProps)(TrialGroupItemCreateVersion);

const TrialGroupItemCreateVersionContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TrialGroupItemCreateVersion)

export default TrialGroupItemCreateVersionContainer;
