import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const involvementsApi = {
    getInvolvements: (): Observable<Dtos.GetInvolvementsResponse> => {
        //Create the request.
        let request: Dtos.GetInvolvements = new Dtos.GetInvolvements();

        //Send request.
        const response: Promise<Dtos.GetInvolvementsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getInvolvementById: (id: number): Observable<Dtos.GetInvolvementByIdResponse> => {
        //Create the request.
        let request: Dtos.GetInvolvementById = new Dtos.GetInvolvementById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetInvolvementByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setInvolvement: (involvement: Dtos.Involvement): Observable<Dtos.SetInvolvementResponse> => {

        let request: Dtos.SetInvolvement = new Dtos.SetInvolvement();

        request.involvement = involvement;

        // send request
        const response: Promise<Dtos.SetInvolvementResponse> = client.post(request);

        return Observable.from(response);

    },
    createInvolvement: (): Observable<Dtos.CreateInvolvementResponse> => {
        //Create the request.
        let request: Dtos.CreateInvolvement = new Dtos.CreateInvolvement();

        //Send request.
        const response: Promise<Dtos.CreateInvolvementResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}