import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadSiteLocation: ISiteLocationLoadActionCreator = () => {
        return {
            type: ACTION_SITELOCATIONS_LOAD
        }
    },
    LoadSiteLocationById: ISiteLocationLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_SITELOCATIONS_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ISiteLocationLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_SITELOCATIONS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ISiteLocationLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_SITELOCATIONS_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveSiteLocation: ISiteLocationSaveActionCreator = (formData) => {
        return {
            type: ACTION_SITELOCATIONS_SAVE,
            formData
        }
    },
    SaveSuccess: ISiteLocationSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_SITELOCATIONS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ISiteLocationSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_SITELOCATIONS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateSiteLocation: ISiteLocationCreateActionCreator = () => {

        return {
            type: ACTION_SITELOCATIONS_CREATE
        }
    },
    CreateSuccess: ISiteLocationCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_SITELOCATIONS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: ISiteLocationCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_SITELOCATIONS_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: ISiteLocationClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_SITELOCATIONS_CLEAR,
            propertiesToClear
        }
    },
    Filter: ISiteLocationFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_SITELOCATIONS_FILTER,
            formFilter
        }
    };



export const
    ACTION_SITELOCATIONS_LOAD = "ACTION_SITELOCATIONS_LOAD",
    ACTION_SITELOCATIONS_LOAD_BY_ID = "ACTION_SITELOCATIONS_LOAD_BY_ID",
    ACTION_SITELOCATIONS_LOAD_SUCCESS = "ACTION_SITELOCATIONS_LOAD_SUCCESS",
    ACTION_SITELOCATIONS_LOAD_FAILURE = "ACTION_SITELOCATIONS_LOAD_FAILURE",

    ACTION_SITELOCATIONS_SAVE = "ACTION_SITELOCATIONS_SAVE",
    ACTION_SITELOCATIONS_SAVE_SUCCESS = "ACTION_SITELOCATIONS_SAVE_SUCCESS",
    ACTION_SITELOCATIONS_SAVE_FAILURE = "ACTION_SITELOCATIONS_SAVE_FAILURE",

    ACTION_SITELOCATIONS_CREATE = "ACTION_SITELOCATIONS_CREATE",
    ACTION_SITELOCATIONS_CREATE_SUCCESS = "ACTION_SITELOCATIONS_CREATE_SUCCESS",
    ACTION_SITELOCATIONS_CREATE_FAILURE = "ACTION_SITELOCATIONS_CREATE_FAILURE",

    ACTION_SITELOCATIONS_CLEAR = "ACTION_SITELOCATIONS_CLEAR",

    ACTION_SITELOCATIONS_FILTER = "ACTION_SITELOCATIONS_FILTER";

export interface ISiteLocationLoadAction {
    type: "ACTION_SITELOCATIONS_LOAD";
}

export interface ISiteLocationLoadActionCreator {
    (): ISiteLocationLoadAction;
}

export interface ISiteLocationLoadSuccessAction {
    type: "ACTION_SITELOCATIONS_LOAD_SUCCESS";
    formData: Dtos.SiteLocation | Dtos.SiteLocation[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ISiteLocationLoadSuccessActionCreator {
    (
        formData: Dtos.SiteLocation | Dtos.SiteLocation[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ISiteLocationLoadSuccessAction;
}

export interface ISiteLocationLoadFailureAction {
    type: "ACTION_SITELOCATIONS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ISiteLocationLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ISiteLocationLoadFailureAction;
}

export interface ISiteLocationLoadByIdAction {
    type: "ACTION_SITELOCATIONS_LOAD_BY_ID";
    id: number;
}

export interface ISiteLocationLoadByIdActionCreator {
    (id: number): ISiteLocationLoadByIdAction;
}


export interface ISiteLocationSaveAction {
    type: "ACTION_SITELOCATIONS_SAVE";
    formData: Dtos.SiteLocation;

}

export interface ISiteLocationSaveActionCreator {
    (
        formData: Dtos.SiteLocation
    ): ISiteLocationSaveAction;
}

export interface ISiteLocationSaveSuccessAction {
    type: "ACTION_SITELOCATIONS_SAVE_SUCCESS";
    formData: Dtos.SiteLocation;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ISiteLocationSaveSuccessActionCreator {
    (
        formData: Dtos.SiteLocation,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ISiteLocationSaveSuccessAction;
}

export interface ISiteLocationSaveFailureAction {
    type: "ACTION_SITELOCATIONS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ISiteLocationSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ISiteLocationSaveFailureAction;
}

export interface ISiteLocationCreateAction {
    type: "ACTION_SITELOCATIONS_CREATE"
}

export interface ISiteLocationCreateActionCreator {
    (): ISiteLocationCreateAction
}

export interface ISiteLocationCreateSuccessAction {
    type: "ACTION_SITELOCATIONS_CREATE_SUCCESS";
    formData: Dtos.SiteLocation;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface ISiteLocationCreateSuccessActionCreator {
    (
        formData: Dtos.SiteLocation,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): ISiteLocationCreateSuccessAction;
}

export interface ISiteLocationCreateFailureAction {
    type: "ACTION_SITELOCATIONS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ISiteLocationCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ISiteLocationCreateFailureAction;
}

export interface ISiteLocationClearAction {
    type: "ACTION_SITELOCATIONS_CLEAR";
    propertiesToClear?: string[];
}

export interface ISiteLocationClearActionCreator {
    (propertiesToClear?: string[]): ISiteLocationClearAction;
}

export interface ISiteLocationFilterActionCreator {
    (formFilter: IFormFilter): ISiteLocationFilterAction;
}

export interface ISiteLocationFilterAction {
    type: "ACTION_SITELOCATIONS_FILTER";
    formFilter: IFormFilter;
}

export type SiteLocationAction =
    ISiteLocationLoadAction |
    ISiteLocationLoadByIdAction |
    ISiteLocationLoadFailureAction |
    ISiteLocationLoadSuccessAction |
    ISiteLocationSaveAction |
    ISiteLocationSaveSuccessAction |
    ISiteLocationSaveFailureAction |
    ISiteLocationCreateAction |
    ISiteLocationCreateSuccessAction |
    ISiteLocationCreateFailureAction |
    ISiteLocationClearAction |
    ISiteLocationFilterAction;
