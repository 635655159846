import * as React from 'react';

import { IRegistrationFormState } from "../reducers/reactReduxForms/index";
import { IRegistrationFormControl, ICreateMessage } from "../interfaces/forms/IRegistrationComponent";
import * as Dtos from '../dtos/Tmd.dtos';
import { Form, Control, actions, Errors } from "react-redux-form";
import { mapProps } from "./reactReduxFormSettings";
import { findFieldValidationFailures } from "./formErrorHelper";
import { GenericFormGroup, SelectFormInput, TextFormInput, RegistrationGenericFormGroup, ValidationMessage, RegistrationValidationMessage } from "../components/form/index";
import {
    RegistrationFormComponent,
    IRegistrationElement,
    IRegistrationGrid,
    IRegistrationMultiFormControls,
    IRegistrationSourceDocuments,
    IRegistrationGroupFormControls,
    IRegistrationGridElement
} from "../interfaces/forms/IRegistrationComponent";
import { FontAwesomeIcons, FontAwesomeIcon } from "../constants/fontAwesomeIcons";
import * as classNames from "classnames";
import { GridRemove } from "../enumerations/GridRemove";
import { AlertType } from '../enumerations/AlertType';

export const RegistrationComponent = {
    RegistrationControl: "IRegistrationFormControl",
    RegistrationElement: "IRegistrationElement",
    RegistrationGrid: "IRegistrationGrid",
    RegistrationMultiFormControls: "IRegistrationMultiFormControls",
    RegistrationSourceDocuments: "IRegistrationSourceDocuments",
    RegistrationGridElement: "IRegistrationGridElement",
    RegistrationGroupFormControls: "IRegistrationGroupFormControls"
}

export const validationRegex = {

}


export function getFormProperty(formName: string, propertyName: string, formProperties: Dtos.FormProperty[]): Dtos.FormProperty | undefined {

    if (propertyName && formProperties) {
        const formProperty: Dtos.FormProperty | undefined = formProperties.find(rfp => rfp.formName.toLocaleLowerCase() == formName.toLocaleLowerCase() && rfp.propertyName.toLocaleLowerCase() == propertyName.toLocaleLowerCase());

        return formProperty;
    }

    return undefined;
}

export function getFormPropertyLabel(formName: string, propertyName: string, formProperties: Dtos.FormProperty[]): string {
    const formProperty: Dtos.FormProperty | undefined = getFormProperty(formName, propertyName, formProperties)

    if (formProperty && formProperty.label) {
        return formProperty.label
    }

    return propertyName;
}

/**
 * /This function renders all the controls needed for a form
 * @param form
 * @param formName
 * @param formControls
 * @param lookups
 * @param validationFailures
 */
export function renderFormControls(
    form: any,
    formName: string,
    formComponents: { [index: string]: RegistrationFormComponent },
    lookups: Dtos.Lookup[],
    validationFailures: Dtos.ResponseError[],
    formProperties: Dtos.FormProperty[],
    formChange: typeof actions.change,
    keyPrefix?: string,
    baseFormName?: string,
    validationPrefix?: string
) {
    if (!keyPrefix) {
        keyPrefix = "";
    }

    if (!baseFormName) {
        baseFormName = formName;
    }
    if (!validationPrefix) {
        validationPrefix = "";
    }

    let renderedFormComponents: any[] = []
    let keys = Object.keys(formComponents);
    for (let key of keys) {
        let formKey = key;
        if (formComponents[key].keyReplace) {
            formKey = formComponents[key].keyReplace;
        }
        switch (formComponents[key].type) {
            case RegistrationComponent.RegistrationControl:
                if ((formComponents[key] as IRegistrationFormControl).hide) {

                } else {
                    renderedFormComponents.push(renderSingleControl(form, formName, formKey, keyPrefix, validationPrefix, formComponents[key] as IRegistrationFormControl, lookups, validationFailures, formProperties));
                }
                break;
            case RegistrationComponent.RegistrationGroupFormControls:
                if ((formComponents[key] as IRegistrationGroupFormControls).hide) {

                } else {
                    renderedFormComponents.push(renderGroupControl(form, formName, formKey, keyPrefix, validationPrefix, (formComponents[key] as IRegistrationGroupFormControls).components, lookups, validationFailures, formProperties, formChange));
                }
                break;
            case RegistrationComponent.RegistrationElement:
                if ((formComponents[key] as IRegistrationFormControl).hide) {

                } else {
                    let formComponent = (formComponents[key] as IRegistrationElement).component
                    if (formComponent) {
                        renderedFormComponents.push(React.cloneElement(formComponent, { key: keyPrefix + formKey }));
                    }
                }
                break;
            case RegistrationComponent.RegistrationGrid:

                if ((formComponents[key] as IRegistrationGrid).hide) {

                } else {
                    renderedFormComponents.push(renderGridControls(form, formName, formKey, keyPrefix, validationPrefix, formComponents[key] as IRegistrationGrid, lookups, validationFailures, formProperties, formChange, baseFormName, (formComponents[key] as IRegistrationGrid).onRemove, (formComponents[key] as IRegistrationGrid).filterRow));
                }
                break;
            case RegistrationComponent.RegistrationMultiFormControls:
                renderedFormComponents.push(renderMultiFormControls(form, formName, formKey, keyPrefix, validationPrefix, formComponents[key] as IRegistrationMultiFormControls, lookups, validationFailures, formProperties, formChange, baseFormName));
                break;
            case RegistrationComponent.RegistrationSourceDocuments:
                if (formKey != key) {

                    renderedFormComponents.push(
                        <div key={formKey + "_" + key}>
                            {
                                renderSourceDocumentControls(form, formName, formKey, keyPrefix, validationPrefix, formComponents[key] as IRegistrationSourceDocuments, lookups, validationFailures, formProperties, formChange, formComponents[key].onRemove, formComponents[key].filterRow)
                            }
                        </div>
                    )
                } else {
                    renderedFormComponents.push(renderSourceDocumentControls(form, formName, formKey, keyPrefix, validationPrefix, formComponents[key] as IRegistrationSourceDocuments, lookups, validationFailures, formProperties, formChange, formComponents[key].onRemove, formComponents[key].filterRow));
                }
                break;
        }
    }

    return renderedFormComponents;
}

/**
 * This function renders a single control to be put on a redux form
 * @param formName
 * @param key
 * @param controlOption
 * @param lookups
 * @param validationFailures
 */
export function renderSingleControl(
    form: any,
    formName: string,
    key: string,
    keyPrefix: string,
    validationPrefix: string,
    controlOption: IRegistrationFormControl,
    lookups: Dtos.Lookup[],
    validationFailures: Dtos.ResponseError[],
    formProperties: Dtos.FormProperty[]
) {

    const root = ("." + keyPrefix);
    const parentModel = keyPrefix ? root.substr(0, root.lastIndexOf(".")) : "";
    //console.log('renderSingleControl', formName, root, parentModel);

    const formProperty = getFormProperty(formName, key, formProperties);
    let validators: { [index: string]: (value: any) => boolean } = {};
    let errors: { [index: string]: string } = {};

    if (controlOption.validators) {
        Object.keys(controlOption.validators).forEach(validatorKey => {
            validators[validatorKey] = (value) => controlOption.validators![validatorKey](value, form);
        })
    }

    if (controlOption.errors) {
        Object.keys(controlOption.errors).forEach(errorKey => {
            if (controlOption.errors![errorKey] instanceof Function) {
                errors[errorKey] = (controlOption.errors![errorKey] as ICreateMessage)(`${formName}.${key}`, form[key], form, parentModel);
            } else {
                errors[errorKey] = controlOption.errors![errorKey] as string;
            }
        })
    }

    return <Control key={key}
        model={"." + keyPrefix + key}
        component={controlOption.component ? controlOption.component : RegistrationGenericFormGroup}
        controlProps={{
            inputClassName: controlOption.inputClassName,
            fieldClassName: controlOption.fieldClassName,
            labelClassName: controlOption.labelClassName,
            groupClassName: controlOption.groupClassName,
            validate: controlOption.validate ? controlOption.validate : true,
            touched: controlOption.inputTouched ? controlOption.inputTouched : false,
            label: controlOption.inputLabel ? controlOption.inputLabel : formProperty && formProperty.label ? formProperty.label : key,
            inputId: formName + "." + keyPrefix + key,
            inputName: keyPrefix + key,
            inputDisabled: controlOption.disabledFn ? controlOption.disabledFn(form, keyPrefix + key) : controlOption.inputDisabled ? controlOption.inputDisabled : false,
            inputType: controlOption.inputType ? controlOption.inputType : TextFormInput,
            inputProps: controlOption.inputProps ? controlOption.inputProps : undefined,
            disableEnter: controlOption.disableEnter ? controlOption.disableEnter : false,
            validationFailures: findFieldValidationFailures(validationPrefix + key, validationFailures),
            softErrors: controlOption.softErrors ? controlOption.softErrors : undefined,
            hardErrors: controlOption.hardErrors ? controlOption.hardErrors : undefined,
            disabled: controlOption.disabled ? controlOption.disabled : false,
            removeContainer: controlOption.removeContainer,
            validationMessageAbove: controlOption.validationMessageAbove,
        }}
        mapProps={controlOption.mapProps ? controlOption.mapProps : mapProps}
        parser={controlOption.parser ? controlOption.parser : undefined}
        validators={validators}
        //updateOn={controlOption.updateOn ? controlOption.updateOn: undefined}
        changeAction={controlOption.changeAction ?
            (model, value) => {
                controlOption.changeAction!(model, value, form, parentModel);
                
            } :
            undefined
        }
        onBlur={
            controlOption.blurAction ?
                () => {
                    controlOption.blurAction!(form, parentModel);
                } :
                () => { }
        }
    >
        {
            controlOption.errors ?
                <Errors
                    model={"." + keyPrefix + key}
                    component={controlOption.errorComponent ? controlOption.errorComponent : ValidationMessage}
                    messages={errors ? errors : undefined}
                    show={true}
                /> :
                null
        }
        {
            controlOption.hardErrors ?
                <Errors
                    model={"." + keyPrefix + key}
                    component={controlOption.errorComponent ? controlOption.errorComponent : ValidationMessage}
                    messages={controlOption.hardErrors ? controlOption.hardErrors : undefined}
                    show={true}
                /> :
                null
        }



    </Control>
}

export function renderGridControls(
    form: any,
    formName: string,
    arrayKey: string,
    keyPrefix: string,
    validationPrefix: string,
    gridComponent: IRegistrationGrid,
    lookups: Dtos.Lookup[],
    validationFailures: Dtos.ResponseError[],
    formProperties: Dtos.FormProperty[],
    formChange: typeof actions.change,
    baseFormName: string,
    onRemove?: (model: string) => boolean,
    filterRow?: (data: any) => boolean
) {

    const keys = Object.keys(gridComponent.components);

    const hasRecords: boolean = form[arrayKey] && form[arrayKey].length > 0;
    const gridLabel: string = gridComponent.label ? gridComponent.label : ""

    const removeConfig: GridRemove = hasRecords ? gridComponent.removeConfig ? gridComponent.removeConfig : GridRemove.All : GridRemove.None;

    if (gridComponent.minRows &&
        gridComponent.minRows > 0 &&
        (!form[arrayKey] || form[arrayKey].length < gridComponent.minRows)) {
        const currentRows = hasRecords ? 0 : form[arrayKey].length;

        let array: any[] = [];

        if (hasRecords) {
            array = [...form[arrayKey]];
        }

        while (array.length < gridComponent.minRows) {
            if (gridComponent.addRow) {
                array.push(gridComponent.addRow(gridComponent, array.length ? array.length : 0, array));
            }
            else {
                array.push({ ...gridComponent.initialState })
            }
        }

        formChange("reduxForms." + baseFormName + "." + keyPrefix + arrayKey, array);
    }


    return <div key={formName + "-" + arrayKey}>
        {
            <table className={classNames(
                "table",
                "table-bordered",
                "mt-2",
                "mb-2",
                gridComponent.className)}>
                <thead className="thead-light">
                    <tr>
                        {
                            keys.map((key, index) => {
                                const component: RegistrationFormComponent = gridComponent.components[key]
                                const columnStyle = gridComponent.columnStyles && gridComponent.columnStyles.length > index ?
                                    gridComponent.columnStyles[index] :
                                    undefined;

                                switch (component.type) {
                                    case RegistrationComponent.RegistrationControl:
                                        return <th key={formName + "-" + arrayKey + "-" + key} style={columnStyle}>
                                            {
                                                (component as IRegistrationFormControl).inputLabel ?
                                                    (component as IRegistrationFormControl).inputLabel :
                                                    getFormPropertyLabel(gridComponent.formName, key, formProperties)
                                            }
                                        </th>
                                    case RegistrationComponent.RegistrationGridElement:
                                        if (!hasRecords) {
                                            return null;
                                        }
                                        return <th key={formName + "-" + arrayKey + "-" + key} style={columnStyle}>
                                            {
                                                (component as IRegistrationGridElement).label ?
                                                    (component as IRegistrationGridElement).label :
                                                    getFormPropertyLabel(gridComponent.formName, key, formProperties)
                                            }
                                        </th>
                                    case RegistrationComponent.RegistrationMultiFormControls:
                                        let registrationMultiFormControls: IRegistrationMultiFormControls =
                                            component as IRegistrationMultiFormControls;

                                        return <th key={formName + "-" + arrayKey + "-" + key} style={columnStyle} className={registrationMultiFormControls.labelClassName}>
                                            {
                                                registrationMultiFormControls.label ?
                                                    registrationMultiFormControls.label :
                                                    getFormPropertyLabel(gridComponent.formName, key, formProperties)
                                            }
                                        </th>
                                    default:
                                        return <th key={formName + "-" + arrayKey + "-" + key} style={columnStyle}>
                                            {
                                                getFormPropertyLabel(gridComponent.formName, key, formProperties)
                                            }
                                        </th>

                                }
                            })
                        }
                        {
                            removeConfig != GridRemove.None ?
                                <th style={{ width: "80px" }}>
                                    Remove
                                </th> :
                                null
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        hasRecords ?
                            form[arrayKey].map((record, index, recordArray) => {
                                if (filterRow && !filterRow(form[arrayKey][index])) {
                                    return <></>;
                                }
                                return <tr key={formName + "-" + arrayKey + "-" + index}>
                                    {
                                        keys.map((key) => {
                                            let formComponent: any = { ...gridComponent.components[key] };

                                            formComponent.removeContainer = true;

                                            if (formComponent.type == RegistrationComponent.RegistrationGridElement) {
                                                return <td key={formName + "-" + arrayKey + "-" + index + "-" + key + "-td"}
                                                    data-info={formName + "-" + arrayKey + "-" + index + "-" + key + "-td"}
                                                    data-info-base={baseFormName}
                                                    data-info-formName={gridComponent.formName}
                                                >
                                                    {formComponent.render(form, formChange, "reduxForms." + formName, lookups, record, index, recordArray, gridComponent)}
                                                </td>
                                            }

                                            return <td key={formName + "-" + arrayKey + "-" + index + "-" + key + "-td"}
                                                data-info={formName + "-" + arrayKey + "-" + index + "-" + key + "-td"}
                                                data-info-base={baseFormName}
                                                data-info-formName={gridComponent.formName}
                                            >
                                                {
                                                    renderFormControls(form[arrayKey][index],
                                                        gridComponent.formName,
                                                        { [key]: formComponent },
                                                        lookups,
                                                        validationFailures,
                                                        formProperties,
                                                        formChange,
                                                        arrayKey + "[" + index + "].",
                                                        baseFormName,
                                                        arrayKey + "[" + form[arrayKey][index].id + "].")
                                                }
                                            </td>
                                        })
                                    }
                                    {
                                        removeConfig != GridRemove.None ?
                                            <td style={{ verticalAlign: "middle" }}>
                                                {
                                                    (!gridComponent.minRows || recordArray.length > gridComponent.minRows) &&
                                                        (removeConfig == GridRemove.All ||
                                                            (index == 0 && removeConfig == GridRemove.First) ||
                                                            (index == recordArray.length - 1 && removeConfig == GridRemove.Last)) ?
                                                        <div className={classNames("text-center text-danger", { "disabled": gridComponent.disabled })}
                                                            onClick={() => {
                                                                if (!gridComponent.disabled) {
                                                                    // Allow the remove to be overridder
                                                                    if (!onRemove || !onRemove("reduxForms." + baseFormName + "." + keyPrefix + arrayKey + "[" + index + "]")) {
                                                                        let array: any[] = [];

                                                                        if (hasRecords) {
                                                                            array = [...form[arrayKey]];
                                                                        }

                                                                        array.splice(index, 1);

                                                                        formChange("reduxForms." + baseFormName + "." + keyPrefix + arrayKey, array);
                                                                    }
                                                                }
                                                            }}>
                                                            <FontAwesomeIcon icon={FontAwesomeIcons.Regular.TIMES} fixedWidth />
                                                        </div> :
                                                        null
                                                }
                                            </td> :
                                            null
                                    }

                                </tr>

                            }) :
                            null
                    }
                    {
                        (!gridComponent.maxRows ||
                            (form[arrayKey] && form[arrayKey].length < gridComponent.maxRows)) && !gridComponent.hideAdd ?
                            <tr>
                                <td colSpan={keys.length + 1} className="text-right">
                                    <div
                                        className={classNames("btn btn-primary", { "disabled": gridComponent.disabled })}
                                        onClick={() => {
                                            if (!gridComponent.disabled) {
                                                let array: any[] = [];

                                                if (hasRecords) {
                                                    array = [...form[arrayKey]];
                                                }

                                                if (!gridComponent.maxRows || array.length < gridComponent.maxRows) {

                                                    if (gridComponent.addRow) {
                                                        array.push(gridComponent.addRow(gridComponent, array.length ? array.length : 0, array));
                                                    }
                                                    else {
                                                        array.push({ ...gridComponent.initialState })
                                                    }

                                                    formChange("reduxForms." + baseFormName + "." + keyPrefix + arrayKey, array);
                                                }

                                                formChange("reduxForms." + baseFormName + "." + keyPrefix + arrayKey, array);
                                            }
                                        }}
                                    >
                                        Add
                                    </div>
                                </td>
                            </tr> :
                            null
                    }
                </tbody>
            </table>
        }
    </div>

}

export function renderGroupControl(form, formName, formKey, keyPrefix, validationPrefix, formComponents: { [index: string]: RegistrationFormComponent }, lookups, validationFailures, formProperties, formChange) {
    const groupClass = "form-group row pt-0 pb-4 border border-bottom-1 border-top-0";
    const keys = Object.keys(formComponents);
    return (<div key="{}" className={groupClass}>
        {keys.map((key, index) => {
            if ((formComponents[key] as IRegistrationFormControl).hide) return <></>;
            const size: number = formComponents[key].size || 1;
            const textRight: boolean = formComponents[key].textRight || false;
            let colWidth: number = 12;
            let controlWidth: number = 9;
            let labelWidth: number = 3;
            switch (size) {
                case 2:
                    colWidth = 6;
                    labelWidth = 6;
                    controlWidth = 6;
                    break;
                default:
            }
            const columnClass: string = "col-md-" + colWidth;
            (formComponents[key] as IRegistrationFormControl).groupClassName = "row bottom-0 border-top-0 pb-0 pt-4";
            (formComponents[key] as IRegistrationFormControl).labelClassName = "col-form-label col-sm-" + labelWidth + (textRight ? " text-right" : "");
            (formComponents[key] as IRegistrationFormControl).fieldClassName = "col-sm-" + controlWidth + " generic-form-field";
            return <div className={columnClass} >
                {
                    renderFormControls(
                        form,
                        formName,
                        { [key]: formComponents[key] },
                        lookups,
                        validationFailures,
                        formProperties,
                        formChange)
                }
            </div>
        })}
    </div>)
}

export function renderMultiFormControls(
    form: any,
    formName: string,
    key: string,
    keyPrefix: string,
    validationPrefix: string,
    controlOption: IRegistrationMultiFormControls,
    lookups: Dtos.Lookup[],
    validationFailures: Dtos.ResponseError[],
    formProperties: Dtos.FormProperty[],
    formChange: typeof actions.change,
    baseFormName?: string
) {

    if (!baseFormName) {
        baseFormName = formName;
    }

    return <controlOption.component key={key}
        label={controlOption.label}
        fieldClassName={controlOption.fieldClassName}
        groupClassName={controlOption.groupClassName}
        labelClassName={controlOption.labelClassName}
        removeContainer={controlOption.removeContainer}
    >
        {
            renderFormControls(form, formName, controlOption.components, lookups, validationFailures, formProperties, formChange, keyPrefix, baseFormName, validationPrefix)
        }
    </controlOption.component>

}

export function renderSourceDocumentControls(
    form: any,
    formName: string,
    key: string,
    keyPrefix: string,
    validationPrefix: string,
    sourceDocuments: IRegistrationSourceDocuments,
    lookups: Dtos.Lookup[],
    validationFailures: Dtos.ResponseError[],
    formProperties: Dtos.FormProperty[],
    formChange: typeof actions.change,
    onRemove?: (model: string) => boolean,
    filterRow?: (data: any) => boolean
) {

    const keys = Object.keys(sourceDocuments.components);

    return <div key={key}>
        {
            sourceDocuments.label ? sourceDocuments.label : "Source Documents"
        }
        {
            renderGridControls(form, formName, key, keyPrefix, validationPrefix, sourceDocuments.components[key] as IRegistrationGrid, lookups, validationFailures, formProperties, formChange, formName, onRemove, filterRow)
        }
    </div>
}

//export const validateByRegex = function (pattern: RegExp, value: string) {
//    if (value) {
//        if (pattern.test(value) === true) {
//            return true;
//        }

//        return false;
//    }

//    return true;
//}

export function toFixedWithoutRounding(num: any, fixed: any) {

    if (!num) {
        return undefined;
    }

    if (!fixed) {
        return num;
    }


    var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
}

export function isNew(formState: Dtos.FormState, form: any) {
    return (formState && formState.formStatus != Dtos.FormStatus.Disabled) && (form !== null) && (form.id == null || form.id == undefined || form.id == 0);
}

export function isEdit(formState: Dtos.FormState, form: any) {
    return (formState && formState.formStatus != Dtos.FormStatus.Disabled) && (form !== null) && (form.id !== null && form.id !== undefined && form.id > 0);
}
