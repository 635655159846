import * as React from 'react';
import './GroupPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { RestrictedLayout } from "../../../components/layouts/index";
import { IGroupReduxFormState, initialGroupState } from "../../../reducers/reactReduxForms/group";
import { RegistrationFormComponent } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as GroupActions from '../../../actions/groups';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { Form, actions, Control } from 'react-redux-form';
import { GroupSubMenu } from '../../../helpers/subMenuHelper';
import * as FormHelper from '../../../helpers/formHelper';

import {
    CollectionPage
} from "../../../containers/index";

interface IGroupPageParams {
    groupId: number;
}

interface IGroupPageProps {

    form: Dtos.Group & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IGroupReduxFormState;

    location: Location;
    groupId: number;
    match: match<IGroupPageParams>;
    history: History;

    // From state
    group: Dtos.Group;
    groupItemsByCategory: Dtos.GroupItemCategory[];
    lookups: Dtos.Lookup[];

    loadingGroup: boolean;
    loadGroupSuccess: boolean;
    loadGroupFailure: boolean;

    savingGroup: boolean;
    saveGroupSuccess: boolean;
    saveGroupFailure: boolean;

    creatingGroup: boolean;
    createGroupSuccess: boolean;
    createGroupFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface IGroupPageActions {
    clearGroup: GroupActions.IGroupClearActionCreator;
    loadGroup: GroupActions.IGroupLoadByIdActionCreator;

    saveGroup: GroupActions.IGroupSaveActionCreator;
    createGroup: GroupActions.IGroupCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type GroupPageProps = IGroupPageProps & IGroupPageActions;


const formName: string = "Group";
const reduxFormName: string = "reduxForms.group";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class GroupPage extends React.PureComponent<GroupPageProps, any> {

    constructor(props: GroupPageProps) {
        super(props);

        this.clearGroup = this.clearGroup.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadGroup,
            navigate,
            navigateReplace,
            groupId,
            createGroup
        } = this.props;

        if (groupId) {
            loadGroup(groupId);
        } else if (!groupId) {
            createGroup();
        }
    }

    componentWillReceiveProps(nextProps: GroupPageProps) {
    }

    componentDidUpdate(prevProps: GroupPageProps, prevState) {
        const {
            navigateReplace,
            group,
            groupId,
            form,
            navigate,
            loadGroup,
            loadingGroup,
            loadGroupSuccess,
            createGroup,
            creatingGroup,
            createGroupSuccess,
            savingGroup,
            saveGroupSuccess,
            saveGroupFailure,
            validationFailures
        } = this.props;

        if (groupId && prevProps.groupId != groupId) {
            loadGroup(groupId);
        }
        else if (!groupId && prevProps.groupId) {
            createGroup();
        }

        if (!loadingGroup && prevProps.loadingGroup) {
            if (loadGroupSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingGroup && prevProps.savingGroup) {
            if (saveGroupSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/groups")
                } else if (!form.saveAndReturn && groupId && groupId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !groupId && group && group.id > 0) {
                    navigate("/groups/" + group.id + "/details");
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingGroup && prevProps.creatingGroup) {
            if (createGroupSuccess) {
                this.loadForm();
            }
            else {

            }
        }
    }

    componentWillUpdate(nextProps: GroupPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearGroup();
        loadForm(reduxFormName, initialGroupState);
        resetForm(reduxFormName);
    }

    clearGroup() {
        this.props.clearGroup();
    }

    showGroup(): boolean {
        const {
            loadingGroup,
            loadGroupSuccess
        } = this.props

        if (!loadingGroup && loadGroupSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            form,
            loadingGroup,
            permissions
        } = this.props;

        let id = form ? form.id : 0;
        let name = form ? form.name : "Group";

        return <RestrictedLayout
            loading={loadingGroup}
            permission={Dtos.Permission.GroupView}
            subMenuItems={GroupSubMenu(id, permissions)}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            form,
            group,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingGroup,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.GroupUpdate, Dtos.Permission.GroupCreate);
        const formDisabledOnEdit: boolean = !hasPermission || FormHelper.isEdit(formState, form);
        const formDisabled: boolean = !hasPermission || savingGroup || (formState && formState.formStatus == Dtos.FormStatus.Disabled); 
        const showContractingPartyAddress: boolean = (form && form.agreementAddressOptionId && form.agreementAddressOptionId == 3);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            name: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
                labelClassName: "col-form-label col-sm-3",
                fieldClassName: "col-sm-9 generic-form-field",
            },
            otherNames: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
                labelClassName: "col-form-label col-sm-3",
                fieldClassName: "col-sm-9 generic-form-field",
            },
            typeAndCodeGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    groupTypeId: {
                        type: RegistrationComponent.RegistrationControl,
                        size: 2,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Group", "GroupTypeId", lookups), form.groupTypeId)
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + '.groupType', null);
                            if (value == null || value != 3) {
                                changeForm(reduxFormName + parentModel + '.groupTypeOther', null);
                            }
                        },
                    },
                    code: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        size: 2,
                        inputDisabled: formDisabledOnEdit && (group != undefined && group.code !== undefined && group.code !== ""),
                    },
                    groupTypeOther: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled || (form && form.groupTypeId == null) || (form && form.groupTypeId != 3),
                        size: 2,
                    }
                }
            },
            addressGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    heading: {
                        type: RegistrationComponent.RegistrationElement,
                        size: 1,
                        component: <h5 className="mt-3">Physical Address</h5>
                    },
                    address: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        size: 1,
                        inputDisabled: formDisabled,
                    },
                    address2: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        size: 1,
                        inputDisabled: formDisabled,
                    },
                    suburb: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        size: 2,
                        inputDisabled: formDisabled,
                    },
                    postcode: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        size: 2,
                        inputDisabled: formDisabled,
                    },
                    stateId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        size: 2,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Group", "StateId", lookups), form.stateId)
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + '.state', null);
                            if (value == null || value != 10) {
                                changeForm(reduxFormName + parentModel + '.stateOther', null);
                            }
                        },
                    },
                    stateOther: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled || (form && form.stateId == null) || (form && form.stateId != 10),
                        size: 2,
                    },
                    countryId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        size: 2,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Group", "CountryId", lookups), form.countryId)
                        },
                    },
                }
            },
            mailAddressGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    heading: {
                        type: RegistrationComponent.RegistrationElement,
                        size: 1,
                        component: <h5 className="mt-3">Mailing Address</h5>
                    },
                    mailAddress: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        size: 1,
                        inputDisabled: formDisabled,
                    },
                    mailAddress2: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        size: 1,
                        inputDisabled: formDisabled,
                    },
                    mailSuburb: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        size: 2,
                        inputDisabled: formDisabled,
                    },
                    mailPostcode: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        size: 2,
                        inputDisabled: formDisabled,
                    },
                    mailStateId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        size: 2,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Group", "MailStateId", lookups), form.mailStateId)
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + '.mailState', null);
                            if (value == null || value != 10) {
                                changeForm(reduxFormName + parentModel + '.mailStateOther', null);
                            }
                        },
                    },
                    mailStateOther: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled || (form && form.mailStateId == null) || (form && form.mailStateId != 10),
                        size: 2,
                    },
                    mailCountryId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        size: 2,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Group", "MailCountryId", lookups), form.mailCountryId)
                        },
                    },
                }
            },
            phoneFaxWebsite: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    phone: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        size: 2,
                        inputDisabled: formDisabled,
                    },
                    fax: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        size: 2,
                        inputDisabled: formDisabled,
                    },
                    website: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        size: 1,
                        inputDisabled: formDisabled,
                    }
                }
            },
            contractingPartyAgreements: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    heading: {
                        type: RegistrationComponent.RegistrationElement,
                        size: 1,
                        component: <h3 className="mt-3">Contracting Party for Agreements</h3>
                    },
                    legalName: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 1,
                    },
                    agreementAddressOptionId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Group", "AgreementAddressOptionId", lookups), form.agreementAddressOptionId)
                        },
                        size: 1,
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + '.addressOption', null);
                        },
                    },
                    contractingPartyAddress: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        hide: !showContractingPartyAddress,
                        size: 1,
                    },
                    contractingPartyAddress2: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        hide: !showContractingPartyAddress,
                        size: 1,
                    },
                    contractingPartySuburb: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        hide: !showContractingPartyAddress,
                        size: 2,
                    },
                    contractingPartyPostcode: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        hide: !showContractingPartyAddress,
                        size: 2,
                    },
                    contractingPartyStateId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Group", "ContractingPartyStateId", lookups), form.contractingPartyStateId)
                        },
                        hide: !showContractingPartyAddress,
                        size: 2,
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + '.contractingPartyState', null);
                            if (value == null || value != 10) {
                                changeForm(reduxFormName + parentModel + '.contractingPartyStateOther', null);
                            }
                        },
                    },
                    contractingPartyStateOther: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        hide: !showContractingPartyAddress,
                        inputDisabled: formDisabled || (form && form.contractingPartyStateId == null) || (form && form.contractingPartyStateId != 10),
                        size: 2,
                    },
                    contractingPartyCountryId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Group", "ContractingPartyCountryId", lookups), form.contractingPartyCountryId)
                        },
                        hide: !showContractingPartyAddress,
                        size: 2,
                    }
                }
            },
            abcAndAcn: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    abn: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        size: 2,
                        inputDisabled: formDisabled,
                    },
                    acn: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        size: 2,
                        inputDisabled: formDisabled,
                    },
                }
            },
            contactNotice: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
                labelClassName: "col-form-label col-sm-3",
                fieldClassName: "col-sm-9 generic-form-field",
            },
            emailNotice: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
                labelClassName: "col-form-label col-sm-3",
                fieldClassName: "col-sm-9 generic-form-field",
            },
            phoneNotice: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
                labelClassName: "col-form-label col-sm-3",
                fieldClassName: "col-sm-9 generic-form-field",
            },
            faxNotice: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
                labelClassName: "col-form-label col-sm-3",
                fieldClassName: "col-sm-9 generic-form-field",
            },
            acceptElectronicSignature: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("Group", "AcceptElectronicSignature", lookups), form.acceptElectronicSignature)
                },
                labelClassName: "col-form-label col-sm-3",
                fieldClassName: "col-sm-9 generic-form-field",
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
                labelClassName: "col-form-label col-sm-3",
                fieldClassName: "col-sm-9 generic-form-field",
            },
            isDefault: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Is Default"
                },
                inputLabel: " ",
                labelClassName: "col-form-label col-sm-3",
                fieldClassName: "col-sm-9 generic-form-field",
            },
        };

        

        return <div>
            <h1>Group</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "group", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    toggle(categoryId: number) {

    }

    renderTable() {
        const {
            group,
            groupItemsByCategory
        } = this.props;

        let expanded = true;

        const showPagination = groupItemsByCategory.length > DEFAULT_PAGE_SIZE;

        return <div>
            {groupItemsByCategory.map((item, index) =>
                <div key={"group-items-category" + item.category.id}>
                    <div className={tableHeaderClass} style={tableStyleClass} onClick={() => this.toggle(item.category.id)}>
                        <div>
                            <FontAwesomeIcon icon={!expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" />
                        </div>
                        <span>{item.category.value}</span>
                    </div>
                    <ReactTable
                        data={item.groupItemSummaries}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : item.groupItemSummaries.length}
                        pageSize={showPagination ? undefined : item.groupItemSummaries.length}
                        showPagination={showPagination}
                        className="-striped -highlight -clickable mb-3"
                        noDataText="There are currently no group items for this category"
                        resizable={false}
                        getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                            return {
                                onClick: (e, handleOriginal) => {
                                    console.log(rowInfo);
                                    const url: string = "/groups/" + group.id + '/groupitems/' + rowInfo.original.id + "";
                                    this.props.navigate(url);

                                    if (handleOriginal) {
                                        handleOriginal()
                                    }

                                }
                            }
                        }}
                        columns={
                            [
                                {
                                    id: "name",
                                    Header: "Name",
                                    accessor: "name",
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    id: "description",
                                    Header: "Description",
                                    accessor: "description",
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    id: "version",
                                    Header: "Version",
                                    accessor: "version",
                                    className: "d-flex align-items-center justify-content-center",
                                }
                            ]
                        }
                    />
                </div>
            )}
        </div>
    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.Group) {
        const {
            saveGroup
        } = this.props;

        if (data) {
            let changes: Dtos.Group = {} as Dtos.Group;
            saveGroup(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.Group) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/groups");
    }

    loadForm() {
        const {
            loadForm,
            group,
        } = this.props

        let extraData: Dtos.Group = {} as Dtos.Group;

        loadForm(reduxFormName, Object.assign({ ...group }, extraData));
    }

}

const groupItemActiveFilter = (groupItemCategories: Dtos.GroupItemCategory[]): Dtos.GroupItemCategory[] => {

    if (groupItemCategories) {
        let result = [ ...groupItemCategories];
        result.forEach(c => {
            c = { ...c, groupItemSummaries: c.groupItemSummaries.filter(f => f.active) };
        });
        result = result.filter(f => f.groupItemSummaries.length > 0);
        return result;
    }

    return [];
}

const mapStateToProps = (state: ITmdState, ownProps: GroupPageProps): IGroupPageProps => {

    let group: Dtos.Group | undefined = !(state.groups.formData instanceof Array) ? state.groups.formData : undefined;
    let groupItemsByCategory: Dtos.GroupItemCategory[] | undefined = group == undefined ? undefined : group.groupItemCategories;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        groupId: ownProps.match && ownProps.match.params.groupId.toString() != "create" ? ownProps.match.params.groupId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        group: group!,
        groupItemsByCategory: groupItemActiveFilter(groupItemsByCategory),

        loadingGroup: state.groups.loadState && state.groups.loadState.status == RequestState.Pending,
        loadGroupSuccess: state.groups.loadState && state.groups.loadState.status == RequestState.Success,
        loadGroupFailure: state.groups.loadState && state.groups.loadState.status == RequestState.Failure,

        savingGroup: state.groups.saveState && state.groups.saveState.status == RequestState.Pending,
        saveGroupSuccess: state.groups.saveState && state.groups.saveState.status == RequestState.Success,
        saveGroupFailure: state.groups.saveState && state.groups.saveState.status == RequestState.Failure,

        creatingGroup: state.groups.createState && state.groups.createState.status == RequestState.Pending,
        createGroupSuccess: state.groups.createState && state.groups.createState.status == RequestState.Success,
        createGroupFailure: state.groups.createState && state.groups.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: state.groups.lookups,

        form: state.reduxForms.group,
        formState: state.groups.formState,
        formProperties: state.groups.formProperties,

        reduxFormState: state.reduxForms.formState.group,
        validationFailures: group ?
            state.groups.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): IGroupPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadGroup: bindActionCreators(GroupActions.LoadGroupById, dispatch),
        clearGroup: bindActionCreators(GroupActions.Clear, dispatch),

        saveGroup: bindActionCreators(GroupActions.SaveGroup, dispatch),
        createGroup: bindActionCreators(GroupActions.CreateGroup, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(GroupPage);
