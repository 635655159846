import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadInstitutionForm: IInstitutionFormLoadActionCreator = (institutionId) => {
        return {
            type: ACTION_INSTITUTIONFORM_LOAD,
            institutionId
        }
    },
    LoadInstitutionFormCurrentUser: IInstitutionFormLoadCurrentUserActionCreator = () => {
        return {
            type: ACTION_INSTITUTIONFORM_LOAD_CURRENT_USER
        }
    },
    LoadInstitutionFormById: IInstitutionFormLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_INSTITUTIONFORM_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IInstitutionFormLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_INSTITUTIONFORM_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IInstitutionFormLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_INSTITUTIONFORM_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveInstitutionForm: IInstitutionFormSaveActionCreator = (formData) => {
        return {
            type: ACTION_INSTITUTIONFORM_SAVE,
            formData
        }
    },
    SaveSuccess: IInstitutionFormSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_INSTITUTIONFORM_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IInstitutionFormSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_INSTITUTIONFORM_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateInstitutionForm: IInstitutionFormCreateActionCreator = (institutionId) => {

        return {
            type: ACTION_INSTITUTIONFORM_CREATE,
            institutionId
        }
    },
    CreateSuccess: IInstitutionFormCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_INSTITUTIONFORM_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: IInstitutionFormCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_INSTITUTIONFORM_CREATE_FAILURE,
            responseStatus
        }
    },

    CreateVersion: IInstitutionFormCreateVersionActionCreator = (institutionFormId, version) => {

        return {
            type: ACTION_INSTITUTIONFORM_CREATEVERSION,
            institutionFormId,
            version
        }
    },
    CreateVersionSuccess: IInstitutionFormCreateVersionSuccessActionCreator = (formData) => {
        return {
            type: ACTION_INSTITUTIONFORM_CREATEVERSION_SUCCESS,
            formData
        }
    },
    CreateVersionFailure: IInstitutionFormCreateVersionFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_INSTITUTIONFORM_CREATEVERSION_FAILURE,
            responseStatus
        }
    },

    Clear: IInstitutionFormClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_INSTITUTIONFORM_CLEAR,
            propertiesToClear
        }
    },

    Filter: IInstitutionFormFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_INSTITUTIONFORM_FILTER,
            formFilter
        }
    };


export const
    ACTION_INSTITUTIONFORM_LOAD = "ACTION_INSTITUTIONFORM_LOAD",
    ACTION_INSTITUTIONFORM_LOAD_CURRENT_USER = "ACTION_INSTITUTIONFORM_LOAD_CURRENT_USER",
    ACTION_INSTITUTIONFORM_LOAD_BY_ID = "ACTION_INSTITUTIONFORM_LOAD_BY_ID",
    ACTION_INSTITUTIONFORM_LOAD_SUCCESS = "ACTION_INSTITUTIONFORM_LOAD_SUCCESS",
    ACTION_INSTITUTIONFORM_LOAD_FAILURE = "ACTION_INSTITUTIONFORM_LOAD_FAILURE",

    ACTION_INSTITUTIONFORM_SAVE = "ACTION_INSTITUTIONFORM_SAVE",
    ACTION_INSTITUTIONFORM_SAVE_SUCCESS = "ACTION_INSTITUTIONFORM_SAVE_SUCCESS",
    ACTION_INSTITUTIONFORM_SAVE_FAILURE = "ACTION_INSTITUTIONFORM_SAVE_FAILURE",

    ACTION_INSTITUTIONFORM_CREATE = "ACTION_INSTITUTIONFORM_CREATE",
    ACTION_INSTITUTIONFORM_CREATE_SUCCESS = "ACTION_INSTITUTIONFORM_CREATE_SUCCESS",
    ACTION_INSTITUTIONFORM_CREATE_FAILURE = "ACTION_INSTITUTIONFORM_CREATE_FAILURE",

    ACTION_INSTITUTIONFORM_CREATEVERSION = "ACTION_INSTITUTIONFORM_CREATEVERSION",
    ACTION_INSTITUTIONFORM_CREATEVERSION_SUCCESS = "ACTION_INSTITUTIONFORM_CREATEVERSION_SUCCESS",
    ACTION_INSTITUTIONFORM_CREATEVERSION_FAILURE = "ACTION_INSTITUTIONFORM_CREATEVERSION_FAILURE",

    ACTION_INSTITUTIONFORM_CLEAR = "ACTION_INSTITUTIONFORM_CLEAR",

    ACTION_INSTITUTIONFORM_FILTER = "ACTION_INSTITUTIONFORM_FILTER";

export interface IInstitutionFormLoadAction {
    type: "ACTION_INSTITUTIONFORM_LOAD";
    institutionId: number;
}

export interface IInstitutionFormLoadActionCreator {
    (institutionId: number): IInstitutionFormLoadAction;
}

export interface IInstitutionFormLoadSuccessAction {
    type: "ACTION_INSTITUTIONFORM_LOAD_SUCCESS";
    formData: Dtos.InstitutionForm | Dtos.InstitutionForm[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IInstitutionFormLoadSuccessActionCreator {
    (
        formData: Dtos.InstitutionForm | Dtos.InstitutionForm[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IInstitutionFormLoadSuccessAction;
}

export interface IInstitutionFormLoadFailureAction {
    type: "ACTION_INSTITUTIONFORM_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IInstitutionFormLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IInstitutionFormLoadFailureAction;
}

export interface IInstitutionFormLoadByIdAction {
    type: "ACTION_INSTITUTIONFORM_LOAD_BY_ID";
    id: number;
}

export interface IInstitutionFormLoadByIdActionCreator {
    (id: number): IInstitutionFormLoadByIdAction;
}

export interface IInstitutionFormLoadCurrentUserAction {
    type: "ACTION_INSTITUTIONFORM_LOAD_CURRENT_USER";
}

export interface IInstitutionFormLoadCurrentUserActionCreator {
    (): IInstitutionFormLoadCurrentUserAction;
}

export interface IInstitutionFormSaveAction {
    type: "ACTION_INSTITUTIONFORM_SAVE";
    formData: Dtos.InstitutionForm;

}

export interface IInstitutionFormSaveActionCreator {
    (
        formData: Dtos.InstitutionForm
    ): IInstitutionFormSaveAction;
}

export interface IInstitutionFormSaveSuccessAction {
    type: "ACTION_INSTITUTIONFORM_SAVE_SUCCESS";
    formData: Dtos.InstitutionForm;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IInstitutionFormSaveSuccessActionCreator {
    (
        formData: Dtos.InstitutionForm,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IInstitutionFormSaveSuccessAction;
}

export interface IInstitutionFormSaveFailureAction {
    type: "ACTION_INSTITUTIONFORM_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IInstitutionFormSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IInstitutionFormSaveFailureAction;
}

export interface IInstitutionFormCreateAction {
    type: "ACTION_INSTITUTIONFORM_CREATE",
    institutionId: number
}

export interface IInstitutionFormCreateActionCreator {
    (institutionId: number): IInstitutionFormCreateAction
}

export interface IInstitutionFormCreateSuccessAction {
    type: "ACTION_INSTITUTIONFORM_CREATE_SUCCESS";
    formData: Dtos.InstitutionForm;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface IInstitutionFormCreateSuccessActionCreator {
    (
        formData: Dtos.InstitutionForm,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): IInstitutionFormCreateSuccessAction;
}

export interface IInstitutionFormCreateFailureAction {
    type: "ACTION_INSTITUTIONFORM_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IInstitutionFormCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IInstitutionFormCreateFailureAction;
}

export interface IInstitutionFormCreateVersionAction {
    type: "ACTION_INSTITUTIONFORM_CREATEVERSION",
    institutionFormId: number,
    version: string
}

export interface IInstitutionFormCreateVersionActionCreator {
    (institutionFormId: number, version: string): IInstitutionFormCreateVersionAction
}

export interface IInstitutionFormCreateVersionSuccessAction {
    type: "ACTION_INSTITUTIONFORM_CREATEVERSION_SUCCESS";
    formData: Dtos.InstitutionForm;
}

export interface IInstitutionFormCreateVersionSuccessActionCreator {
    (
        formData: Dtos.InstitutionForm,
    ): IInstitutionFormCreateVersionSuccessAction;
}

export interface IInstitutionFormCreateVersionFailureAction {
    type: "ACTION_INSTITUTIONFORM_CREATEVERSION_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IInstitutionFormCreateVersionFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IInstitutionFormCreateVersionFailureAction;
}

export interface IInstitutionFormClearAction {
    type: "ACTION_INSTITUTIONFORM_CLEAR";
    propertiesToClear?: string[];
}

export interface IInstitutionFormClearActionCreator {
    (propertiesToClear?: string[]): IInstitutionFormClearAction;
}

export interface IInstitutionFormFilterActionCreator {
    (formFilter: IFormFilter): IInstitutionFormFilterAction;
}

export interface IInstitutionFormFilterAction {
    type: "ACTION_INSTITUTIONFORM_FILTER";
    formFilter: IFormFilter;
}

export type InstitutionFormAction =
    IInstitutionFormLoadAction |
    IInstitutionFormLoadCurrentUserAction |
    IInstitutionFormLoadByIdAction |
    IInstitutionFormLoadFailureAction |
    IInstitutionFormLoadSuccessAction |
    IInstitutionFormSaveAction |
    IInstitutionFormSaveSuccessAction |
    IInstitutionFormSaveFailureAction |
    IInstitutionFormCreateAction |
    IInstitutionFormCreateSuccessAction |
    IInstitutionFormCreateFailureAction |
    IInstitutionFormCreateVersionAction |
    IInstitutionFormCreateVersionSuccessAction |
    IInstitutionFormCreateVersionFailureAction |
    IInstitutionFormClearAction | 
    IInstitutionFormFilterAction;
