import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trialItemInstanceImportApi = {
    getTrialItemInstanceImportById: (id: number, trialId: number): Observable<Dtos.GetTrialItemInstanceImportByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrialItemInstanceImportById = new Dtos.GetTrialItemInstanceImportById();

        request.id = id;
        request.trialId = trialId;

        //Send request.
        const response: Promise<Dtos.GetTrialItemInstanceImportByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrialItemInstanceImport: (trialItemInstanceImport: Dtos.TrialItemInstanceImport): Observable<Dtos.SetTrialItemInstanceImportResponse> => {
        //Create the request.
        let request: Dtos.SetTrialItemInstanceImport = new Dtos.SetTrialItemInstanceImport();

        request.id = trialItemInstanceImport.id;
        request.trialId = trialItemInstanceImport.trialId;
        request.trialItemInstanceImport = trialItemInstanceImport;

        // send request
        const response: Promise<Dtos.SetTrialItemInstanceImportResponse> = client.post(request);

        return Observable.from(response);
    }
}