import './FileUploadFormInput.scss'

import * as React from "react";
import * as classNames from "classnames";
import { IBaseFormInputProps } from '../';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';

import { TemporaryFileInformation, TemporaryFile, FileState, UploadTempFileResponse } from '../../../../dtos/Tmd.dtos';

import { fileApi } from '../../../../services/file';
import { ExternalView } from "../../../common/index";

export interface IFileUploadFormInputProps {
    render: IFileUploadFormInputRender;
}


export interface IFileUploadFormInputRender {
    (
        fileInformation: TemporaryFileInformation,
        inputId: string,
        createUpload: (element: JSX.Element, classes?: string, styles?: React.CSSProperties) => React.ReactNode,
        clear: () => void,
        cancel: () => void,
        invalid: boolean,
        disabled: boolean
    ): React.ReactNode
}

export interface IFileInfo {
    state: FileState;
    progress: string;
    guid: string;
    fileInfo: TemporaryFile;
}

class FileUploadFormInput extends React.Component<IFileUploadFormInputProps & IBaseFormInputProps, any> {
    public form: HTMLFormElement;
    public container: HTMLDivElement;
    public iframe: HTMLIFrameElement;
    public xhr: XMLHttpRequest;

    constructor(props) {
        super(props);

        this.onClear = this.onClear.bind(this);
        this.createUpload = this.createUpload.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onIframeLoad = this.onIframeLoad.bind(this);
        this.ajaxUpload = this.ajaxUpload.bind(this);
        this.processUploadResponse = this.processUploadResponse.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidUpdate() {
        if (this.form && this.container) {
            let offsetLeft: number = 0;
            let offsetTop: number = 0;
            let elem: HTMLElement = this.container;

            do {
                if (!isNaN(elem.offsetLeft)) {
                    offsetLeft += elem.offsetLeft;
                }

                if (!isNaN(elem.offsetTop)) {
                    offsetTop += elem.offsetTop;
                }

            } while (elem = elem.offsetParent as HTMLElement);

            this.form.style.top = offsetTop + "px";
            this.form.style.left = offsetLeft + "px";
        }
    }

    componentWillUnmount() {

        this.cancel();
    }

    render() {
        const {
            value,
            render,
            id,
            invalid,
            disabled
        } = this.props;

        return (
            <div ref={(container) => { (this.container = container!); }}>
                {
                    render ?
                        render(value, id, this.createUpload, this.onClear, this.cancel, invalid || false, disabled || false) :
                        null
                }
                <ExternalView>
                    <form
                        ref={(form) => { (this.form = form!); }}
                        method="post" action="/api/file/temp/upload?format=json"
                        target={this.props.id + ".iframe"}
                        style={{
                            position: "absolute",
                            zIndex: -1,
                        }}
                    >
                        <input
                            type="file"
                            className={classNames("custom-file-input", this.props.className, { "is-valid": this.props.valid }, { "is-invalid": this.props.invalid })}
                            name={this.props.name}
                            id={this.props.id}
                            onBlur={this.props.onBlur}
                            onChange={(event) => { this.onChange(event) }}
                            onFocus={this.props.onFocus}
                            onKeyPress={this.props.onKeyPress}
                            disabled={this.props.disabled}
                        />
                    </form>
                    <iframe id={this.props.id + ".iframe"} name={this.props.id + ".iframe"} className="file-input-iframe" ref={(iframe) => { (this.iframe = iframe!); }} />
                </ExternalView>
            </div>
        );
    }

    createUpload(element: JSX.Element, classes?: string, styles?: React.CSSProperties): React.ReactNode {

        return <label className={classNames(classes)} style={styles} htmlFor={this.props.id}>
            {element}
        </label>
    }

    onIframeLoad() {
        // @ts-ignore
        let body = this.iframe.contentDocument.body;
        // @ts-ignore
        let guidDiv = this.iframe.contentDocument.getElementById("guid");

        if (body) {
            this.processUploadResponse(body.innerHTML);
        }
        else {
            this.props.onChange({
                state: FileState.Failure,
                guid: undefined,
                progress: undefined,
                temporaryFile: undefined
            });
        }
    }

    onClear() {
        //TODO: clear temp file.

        this.props.onChange({
            state: FileState.Empty,
            guid: undefined,
            progress: undefined,
            temporaryFile: undefined
        });
    }

    cancel() {
        if (this.xhr) {
            this.xhr.abort();
        }
    }

    ajaxUpload(form: HTMLFormElement) {
        this.cancel();

        this.xhr = new XMLHttpRequest();
        
        this.xhr.addEventListener('progress', function (e) {
            var done = (e as any).position || e.loaded, total = (e as any).totalSize || e.total;
            console.log('xhr progress: ' + (Math.floor(done / total * 1000) / 10) + '%');
        }, false);
        if (this.xhr.upload) {
            this.xhr.upload.onprogress = (e) => {
                var done = (e as any).position || e.loaded, total = (e as any).totalSize || e.total;

                const percentage = (Math.floor(done / total * 1000) / 10) + '%';


                this.props.onChange({
                    state: FileState.Uploading,
                    guid: undefined,
                    progress: percentage,
                    temporaryFile: undefined
                });

                console.log('xhr.upload progress: ' + done + ' / ' + total + ' = ' + (Math.floor(done / total * 1000) / 10) + '%');
            };
        }
        this.xhr.onreadystatechange = (e) => {
            if (4 == this.xhr.readyState) {
                if (this.xhr.status == 200) {
                    console.log(['xhr upload complete', e]);
                    this.processUploadResponse(this.xhr.responseText);
                }
                else {
                    this.props.onChange({
                        state: FileState.Failure,
                        guid: undefined,
                        progress: undefined,
                        temporaryFile: undefined
                    });
                }
            }
        };
        this.xhr.open('post', form.action, true);
        var formData = new FormData(form);
        formData.append("json", "true");
        this.xhr.send(formData);
    }

    onChange(event) {
        if (event.target.value && event.target.value != '') {

            this.props.onChange({
                state: FileState.Uploading,
                guid: undefined,
                progress: undefined,
                temporaryFile: undefined
            });

            this.form.enctype = "multipart/form-data";
            this.form.encoding = "multipart/form-data";

            if (this.isFileAPIEnabled()) {
                this.ajaxUpload(this.form)
            }
            else {
                this.iframe.onload = this.onIframeLoad;
                this.form.submit();
            }
        }
        else {
            this.props.onChange({
                state: FileState.Empty,
                guid: undefined,
                progress: undefined,
                temporaryFile: undefined
            });
        }
    }

    isFileAPIEnabled() {
        return !!(window as any).FileReader;
    }

    processUploadResponse(jsonResponse: string) {
        try {
            let result: UploadTempFileResponse = JSON.parse(jsonResponse);
            if (!result.responseStatus) {
                //File uploaded ok.
                let guid = result.guid;

                fileApi.getTempFileInfo(guid).toPromise().then(response => {
                    this.props.onChange({
                        state: FileState.Success,
                        guid: guid,
                        progress: "100%",
                        temporaryFile: response.temporaryFile
                    });
                })
                    .catch(error => {
                        this.props.onChange({
                            state: FileState.Success,
                            guid: guid,
                            progress: "100%",
                            temporaryFile: undefined
                        });

                        return new Observable<void>();
                    })
            }
            else {
                this.props.onChange({
                    state: FileState.Failure,
                    guid: undefined,
                    progress: undefined,
                    temporaryFile: undefined
                });
            }
        } catch (error) {
            this.props.onChange({
                state: FileState.Failure,
                guid: undefined,
                progress: undefined,
                temporaryFile: undefined
            });
        }
    }
}

export default FileUploadFormInput;