import * as React from 'react';
import './InterestPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { IInterestReduxFormState, initialInterestState } from "../../../reducers/reactReduxForms/interest";
import { RegistrationFormComponent } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, NumberFormInput, CheckboxFormInput } from "../../../components/form/index";
import * as InterestActions from '../../../actions/interests';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { NotificationAlert } from "../../../components/common/index";
import { Form, actions } from 'react-redux-form';
import { LookupsLayout } from '../../index';
import * as AuthenticationHelper from '../../../helpers/authentication';

interface IInterestPageParams {
    interestId: number;
}

interface IInterestPageProps {

    form: Dtos.Interest & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IInterestReduxFormState;

    location: Location;
    interestId: number;
    match: match<IInterestPageParams>;
    history: History;

    // From state
    interest: Dtos.Interest;
    lookups: Dtos.Lookup[];

    loadingInterest: boolean;
    loadInterestSuccess: boolean;
    loadInterestFailure: boolean;

    savingInterest: boolean;
    saveInterestSuccess: boolean;
    saveInterestFailure: boolean;

    creatingInterest: boolean;
    createInterestSuccess: boolean;
    createInterestFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface IInterestPageActions {
    clearInterest: InterestActions.IInterestClearActionCreator;
    loadInterest: InterestActions.IInterestLoadByIdActionCreator;

    saveInterest: InterestActions.IInterestSaveActionCreator;
    createInterest: InterestActions.IInterestCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type InterestPageProps = IInterestPageProps & IInterestPageActions;

const reduxFormName: string = "reduxForms.interest";

class InterestPage extends React.PureComponent<InterestPageProps, any> {

    constructor(props: InterestPageProps) {
        super(props);

        this.clearInterest = this.clearInterest.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadInterest,
            interestId,
            createInterest
        } = this.props;

        if (interestId) {
            loadInterest(interestId);
        } else if (!interestId) {
            createInterest();
        }
    }

    componentWillReceiveProps(nextProps: InterestPageProps) {
    }

    componentDidUpdate(prevProps: InterestPageProps, prevState) {
        const {
            interestId,
            interest,
            form,
            navigate,
            loadInterest,
            loadingInterest,
            loadInterestSuccess,
            createInterest,
            creatingInterest,
            createInterestSuccess,
            savingInterest,
            saveInterestSuccess,
            validationFailures
        } = this.props;

        if (interestId && prevProps.interestId != interestId) {
            loadInterest(interestId);
        }
        else if (!interestId && prevProps.interestId) {
            createInterest();
        }

        if (!loadingInterest && prevProps.loadingInterest) {
            if (loadInterestSuccess) {
                this.loadForm();
            }
        }

        if (!savingInterest && prevProps.savingInterest) {
            if (saveInterestSuccess) {
                if (validationFailures && validationFailures.length > 0) {
                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {
                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);
                }
                if (form.saveAndReturn) {
                    navigate("/settings/general/interests")
                } else if (!form.saveAndReturn && interestId && interestId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !interestId && interest && interest.id > 0) {
                    navigate("/settings/general/interests/" + interest.id);
                }
            }
            else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

        if (!creatingInterest && prevProps.creatingInterest) {
            if (createInterestSuccess) {
                this.loadForm();
            }
        }
    }

    componentWillUpdate(nextProps: InterestPageProps) {
    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearInterest();
        loadForm(reduxFormName, initialInterestState);
        resetForm(reduxFormName);
    }

    clearInterest() {
        this.props.clearInterest();
    }

    showInterest(): boolean {
        const {
            loadingInterest,
            loadInterestSuccess
        } = this.props

        if (!loadingInterest && loadInterestSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingInterest
        } = this.props;

        return <LookupsLayout loading={loadingInterest} permission={Dtos.Permission.InterestsView}>
            {this.renderContent()}
        </LookupsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingInterest,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.InterestsUpdate, Dtos.Permission.InterestsCreate);
        const formDisabled: boolean = !hasPermission || savingInterest || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            value: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            }
        };

        return <div>
            <h1>Interest</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "interest", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.Interest) {
        const {
            saveInterest
        } = this.props;

        if (data) {
            let changes: Dtos.Interest = {} as Dtos.Interest;
            saveInterest(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.Interest) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/general/interests");
    }

    loadForm() {
        const {
            loadForm,
            interest,
        } = this.props

        let extraData: Dtos.Interest = {} as Dtos.Interest;

        loadForm(reduxFormName, Object.assign({ ...interest }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: InterestPageProps): IInterestPageProps => {

    let interest: Dtos.Interest | undefined = !(state.interests.formData instanceof Array) ? state.interests.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        interestId: ownProps.match && ownProps.match.params.interestId && ownProps.match.params.interestId.toString() != "create" ? ownProps.match.params.interestId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        interest: interest!,

        loadingInterest: state.interests.loadState && state.interests.loadState.status == RequestState.Pending,
        loadInterestSuccess: state.interests.loadState && state.interests.loadState.status == RequestState.Success,
        loadInterestFailure: state.interests.loadState && state.interests.loadState.status == RequestState.Failure,

        savingInterest: state.interests.saveState && state.interests.saveState.status == RequestState.Pending,
        saveInterestSuccess: state.interests.saveState && state.interests.saveState.status == RequestState.Success,
        saveInterestFailure: state.interests.saveState && state.interests.saveState.status == RequestState.Failure,

        creatingInterest: state.interests.createState && state.interests.createState.status == RequestState.Pending,
        createInterestSuccess: state.interests.createState && state.interests.createState.status == RequestState.Success,
        createInterestFailure: state.interests.createState && state.interests.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: [],

        form: state.reduxForms.interest,
        formState: state.interests.formState,
        formProperties: state.interests.formProperties,

        reduxFormState: state.reduxForms.formState.interest,
        validationFailures: interest ?
            state.interests.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): IInterestPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadInterest: bindActionCreators(InterestActions.LoadInterestById, dispatch),
        clearInterest: bindActionCreators(InterestActions.Clear, dispatch),

        saveInterest: bindActionCreators(InterestActions.SaveInterest, dispatch),
        createInterest: bindActionCreators(InterestActions.CreateInterest, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(InterestPage);
