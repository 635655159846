import * as Dtos from '../dtos/Tmd.dtos';
import { IAuthorisationDefinition, IAuthorisationRole } from '../interfaces/authentication/authorisationDefinition';
import * as AuthenticationConstants from '../constants/authentication';
import * as AuthorisationDefinitions from '../constants/authorisationDefinitions';
import { ICreateAuthorisationsFn } from '../components/common/Authentication/AuthenticatedRoute';

export function authenticate(user: Dtos.User, authDefinitions?: IAuthorisationDefinition[]) {

    //debugger;
    if (authDefinitions) {
        for (let i = 0; i < authDefinitions.length; i++) {
            if (authenticateDefinition(user, authDefinitions[i])) {
                return true;
            }
        }
    }
    else {
        return authenticateDefinition(user, undefined);
    }

    return false;
}

function authenticateDefinition(user: Dtos.User, authDefinition?: IAuthorisationDefinition) {

    // Check if user exists
    if (!user) {
        return false;
    }

    // Check Auth Definition
    if (!authDefinition) {
        if (getInstitutions(user).length > 0 ||
            hasGroup(user, "super-user")) {
            return true;
        }
        else {
            return false;
        }
    }

    // If super user, no need to check groups, roles and institutions
    if (hasGroup(user, "super-user")) {
        return true;
    }

    let isTROG: boolean = hasInstitution(user, AuthenticationConstants.INSTITUTION_TROG);

    if (isTROG) {
        return true;
    }

    // Check Groups
    if (authDefinition.groups && authDefinition.groups.length > 0) {
        for (let authGroup of authDefinition.groups) {
            if (!hasGroup(user, authGroup)) {
                // Group not found on user. invalid group permissions
                return false;
            }
        }
    }

    if (authDefinition.institutions && authDefinition.institutions.length > 0) {
        for (let authInstitution of authDefinition.institutions) {
            if (!hasInstitution(user, authInstitution)) {
                // Group not found on user. invalid group permissions
                return false;
            }
        }
    }

    if (authDefinition.roles && authDefinition.roles.length > 0) {
        for (let role of authDefinition.roles) {
            if (!hasRole(user, role.roleId, role.instCode)) {
                return false;
            }

        }
    }

    return true;
}

function hasGroup(user: Dtos.User, group: string): boolean {
    let groups: string[] = getGroups(user);

    if (groups) {
        if (groups.filter(g => g == group).length > 0) {
            return true;
        }
    }

    return false;
}

function hasInstitution(user: Dtos.User, institution: string): boolean {
    let institutions: string[] = getInstitutions(user);

    if (institutions) {
        if (institutions.filter(i => i == institution).length > 0) {
            return true;
        }
    }

    return false;
}

function hasRole(user: Dtos.User, role: string, instCode?: string): boolean {

    let roles: { [index: string]: string[] } = getRoles(user); // trialid_instid_role
    if (roles) {
        if (instCode) {
            if (!roles[instCode]) {
                return false;
            }

            return roles[instCode].some(r => r == role)
        }

        for (let key of Object.keys(roles)) {
            if (roles[key].some(r => r == role)) {
                return true;
            }
        }
    }

    return false;
}

function getGroups(user: Dtos.User): string[] {
    if (user && user.groups) {
        return user.groups;
    }
    return [];
}

function getInstitutions(user: Dtos.User): string[] {
    if (user && user.institutions) {
        return user.institutions;
    }
    return [];
}

function getRoles(user: Dtos.User): { [index: string]: string[] } {

    if (user && user.roles) {
        return user.roles;
    }
    return {};
}

export function hasPermission(personnelTmdPermissions: Dtos.PersonnelTmdPermissions[], permission: Dtos.Permission, trialId?: number, institutionId?: number): boolean {

    if (personnelTmdPermissions && personnelTmdPermissions.length > 0) {
        if (trialId !== undefined && institutionId !== undefined && trialId !== null && institutionId !== null) {
            if (personnelTmdPermissions.filter(g => g.trialMatch && g.trialId == trialId && g.institutionMatch && g.institutionId == institutionId && g.permission == permission).length > 0) {
                return true;
            }
            //check for a higher permission on the trial
            if (personnelTmdPermissions.filter(g => g.trialMatch && g.trialId == trialId && !g.institutionMatch && g.permission == permission).length > 0) {
                return true;
            }
        }
        if (trialId !== undefined && trialId !== null && (institutionId === undefined || institutionId === null)) {
            if (personnelTmdPermissions.filter(g => g.trialMatch && g.trialId == trialId && g.permission == permission).length > 0) {
                return true;
            }
        }
        if ((trialId === undefined || trialId === null) && institutionId !== undefined && institutionId !== null) {
            if (personnelTmdPermissions.filter(g => !g.trialMatch && g.institutionMatch && g.institutionId == institutionId && g.permission == permission).length > 0) {
                return true;
            }
        }
        if (personnelTmdPermissions.filter(g => !g.trialMatch && !g.institutionMatch && g.permission == permission).length > 0) {
            return true;
        }
    }
    return false;
}

export function hasPermissionAnyMatch(personnelTmdPermissions: Dtos.PersonnelTmdPermissions[], permission: Dtos.Permission): boolean {

    if (personnelTmdPermissions && personnelTmdPermissions.length > 0) {
        if (personnelTmdPermissions.filter(g => g.permission == permission).length > 0) {
            return true;
        }
    }
    return false;
}

export function hasAnyPermission(personnelTmdPermissions: Dtos.PersonnelTmdPermissions[], permissions: Dtos.Permission[]): boolean {
    if (personnelTmdPermissions.length == 0) return false;
    let result: boolean = false;
    for (var i = 0; i < permissions.length; i++) {
        if (personnelTmdPermissions.filter(f => f.permission == permissions[i]).length > 0) {
            result = true;
            break;
        }
    }
    return result;
}

export function hasCreateEditPermission(form: any, permissions: Dtos.PersonnelTmdPermissions[], editPermission: Dtos.Permission, createPermission: Dtos.Permission): boolean {
    
    let id: number = undefined;
    let trialId: number = undefined;
    let institutionId: number = undefined;
    if (form) {
        if (form.id != undefined && form.id != null && !isNaN(form.id) && form.id > 0) {
            id = form.id;
        }
        if (form.institutionId != undefined && form.institutionId != null && form.institutionId > 0) {
            institutionId = form.institutionId;
        }
        if (form.trialId != undefined && form.trialId != null && form.trialId > 0) {
            trialId = form.trialId;
        }
    }
    const isNew = (id !== undefined) ? false : true;
    const canCreate: boolean = hasPermission(permissions, createPermission, trialId, institutionId);
    const canEdit: boolean = hasPermission(permissions, editPermission, trialId, institutionId);
    const result: boolean = (isNew && canCreate) || (!isNew && canEdit);
    return result;
}

export function hasCreateEditPermissionFor(trialId: number, institutionId: number, permissions: Dtos.PersonnelTmdPermissions[], editPermission: Dtos.Permission, createPermission: Dtos.Permission): boolean {

    let _id: number = undefined;
    let _trialId: number = undefined;
    let _institutionId: number = undefined;
    if (trialId != undefined && trialId != null && !isNaN(trialId) && trialId > 0) {
        _id = trialId;
        _trialId = trialId;
    }
    if (institutionId != undefined && institutionId != null && !isNaN(institutionId)  && institutionId > 0) {
        institutionId = institutionId;
    }
    const isNew = (_id !== undefined) ? false : true;
    const canCreate: boolean = hasPermission(permissions, createPermission, _trialId, _institutionId);
    const canEdit: boolean = hasPermission(permissions, editPermission, _trialId, _institutionId);
    const result: boolean = (isNew && canCreate) || (!isNew && canEdit);
    return result;
}

export function hasFormPermission(form: any, permissions: Dtos.PersonnelTmdPermissions[], permission: Dtos.Permission): boolean {

    let id: number = undefined;
    let trialId: number = undefined;
    let institutionId: number = undefined;
    if (form) {
        if (form.id != undefined && form.id != null && !isNaN(form.id) && form.id > 0) {
            id = form.id;
        }
        if (form.institutionId != undefined && form.institutionId != null && form.institutionId > 0) {
            institutionId = form.institutionId;
        }
        if (form.trialId != undefined && form.trialId != null && form.trialId > 0) {
            trialId = form.trialId;
        }
    }
    const isNew = (id !== undefined) ? false : true;
    const result: boolean = hasPermission(permissions,permission, trialId, institutionId);
    return result;
}
