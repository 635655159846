import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadTrialInstitutionItemTemplate: ITrialInstitutionItemTemplateLoadActionCreator = (trialId) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD,
            trialId
        }
    },
    LoadTrialInstitutionItemTemplateById: ITrialInstitutionItemTemplateLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ITrialInstitutionItemTemplateLoadSuccessActionCreator = (formData, formDataGrouped, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD_SUCCESS,
            formData,
            formDataGrouped,
            formState,
            formProperties,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrialInstitutionItemTemplateLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTrialInstitutionItemTemplate: ITrialInstitutionItemTemplateSaveActionCreator = (formData, collectionFormData) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEMTEMPLATES_SAVE,
            formData,
            collectionFormData
        }
    },
    SaveSuccess: ITrialInstitutionItemTemplateSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEMTEMPLATES_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrialInstitutionItemTemplateSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEMTEMPLATES_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTrialInstitutionItemTemplate: ITrialInstitutionItemTemplateCreateActionCreator = (trialId) => {

        return {
            type: ACTION_TRIALINSTITUTIONITEMTEMPLATES_CREATE,
            trialId
        }
    },
    CreateSuccess: ITrialInstitutionItemTemplateCreateSuccessActionCreator = (formData, formState, formProperties, lookups) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEMTEMPLATES_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups
        }
    },
    CreateFailure: ITrialInstitutionItemTemplateCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEMTEMPLATES_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: ITrialInstitutionItemTemplateClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEMTEMPLATES_CLEAR,
            propertiesToClear
        }
    },
    Filter: ITrialInstitutionItemTemplateFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEMTEMPLATES_FILTER,
            formFilter
        }
    };



export const
    ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD = "ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD",
    ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD_BY_ID = "ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD_BY_ID",
    ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD_SUCCESS = "ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD_SUCCESS",
    ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD_FAILURE = "ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD_FAILURE",

    ACTION_TRIALINSTITUTIONITEMTEMPLATES_SAVE = "ACTION_TRIALINSTITUTIONITEMTEMPLATES_SAVE",
    ACTION_TRIALINSTITUTIONITEMTEMPLATES_SAVE_SUCCESS = "ACTION_TRIALINSTITUTIONITEMTEMPLATES_SAVE_SUCCESS",
    ACTION_TRIALINSTITUTIONITEMTEMPLATES_SAVE_FAILURE = "ACTION_TRIALINSTITUTIONITEMTEMPLATES_SAVE_FAILURE",

    ACTION_TRIALINSTITUTIONITEMTEMPLATES_CREATE = "ACTION_TRIALINSTITUTIONITEMTEMPLATES_CREATE",
    ACTION_TRIALINSTITUTIONITEMTEMPLATES_CREATE_SUCCESS = "ACTION_TRIALINSTITUTIONITEMTEMPLATES_CREATE_SUCCESS",
    ACTION_TRIALINSTITUTIONITEMTEMPLATES_CREATE_FAILURE = "ACTION_TRIALINSTITUTIONITEMTEMPLATES_CREATE_FAILURE",

    ACTION_TRIALINSTITUTIONITEMTEMPLATES_CLEAR = "ACTION_TRIALINSTITUTIONITEMTEMPLATES_CLEAR",

    ACTION_TRIALINSTITUTIONITEMTEMPLATES_FILTER = "ACTION_TRIALINSTITUTIONITEMTEMPLATES_FILTER";

export interface ITrialInstitutionItemTemplateLoadAction {
    type: "ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD";
    trialId: number;
}

export interface ITrialInstitutionItemTemplateLoadActionCreator {
    (trialId: number): ITrialInstitutionItemTemplateLoadAction;
}

export interface ITrialInstitutionItemTemplateLoadSuccessAction {
    type: "ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD_SUCCESS";
    formData: Dtos.TrialInstitutionItemTemplate | Dtos.TrialInstitutionItemTemplate[];
    formDataGrouped: Dtos.Category[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialInstitutionItemTemplateLoadSuccessActionCreator {
    (
        formData: Dtos.TrialInstitutionItemTemplate | Dtos.TrialInstitutionItemTemplate[],
        formDataGrouped: Dtos.Category[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialInstitutionItemTemplateLoadSuccessAction;
}

export interface ITrialInstitutionItemTemplateLoadFailureAction {
    type: "ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialInstitutionItemTemplateLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialInstitutionItemTemplateLoadFailureAction;
}

export interface ITrialInstitutionItemTemplateLoadByIdAction {
    type: "ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD_BY_ID";
    id: number;
}

export interface ITrialInstitutionItemTemplateLoadByIdActionCreator {
    (id: number): ITrialInstitutionItemTemplateLoadByIdAction;
}


export interface ITrialInstitutionItemTemplateSaveAction {
    type: "ACTION_TRIALINSTITUTIONITEMTEMPLATES_SAVE";
    formData: Dtos.TrialInstitutionItemTemplate;
    collectionFormData: Dtos.Collection;

}

export interface ITrialInstitutionItemTemplateSaveActionCreator {
    (
        formData: Dtos.TrialInstitutionItemTemplate,
        collectionFormData: Dtos.Collection
    ): ITrialInstitutionItemTemplateSaveAction;
}

export interface ITrialInstitutionItemTemplateSaveSuccessAction {
    type: "ACTION_TRIALINSTITUTIONITEMTEMPLATES_SAVE_SUCCESS";
    formData: Dtos.TrialInstitutionItemTemplate;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialInstitutionItemTemplateSaveSuccessActionCreator {
    (
        formData: Dtos.TrialInstitutionItemTemplate,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrialInstitutionItemTemplateSaveSuccessAction;
}

export interface ITrialInstitutionItemTemplateSaveFailureAction {
    type: "ACTION_TRIALINSTITUTIONITEMTEMPLATES_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialInstitutionItemTemplateSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialInstitutionItemTemplateSaveFailureAction;
}

export interface ITrialInstitutionItemTemplateCreateAction {
    type: "ACTION_TRIALINSTITUTIONITEMTEMPLATES_CREATE";
    trialId: number;
}

export interface ITrialInstitutionItemTemplateCreateActionCreator {
    (trialId: number): ITrialInstitutionItemTemplateCreateAction
}

export interface ITrialInstitutionItemTemplateCreateSuccessAction {
    type: "ACTION_TRIALINSTITUTIONITEMTEMPLATES_CREATE_SUCCESS";
    formData: Dtos.TrialInstitutionItemTemplate;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    lookups: Dtos.Lookup[];
}

export interface ITrialInstitutionItemTemplateCreateSuccessActionCreator {
    (
        formData: Dtos.TrialInstitutionItemTemplate,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[]
    ): ITrialInstitutionItemTemplateCreateSuccessAction;
}

export interface ITrialInstitutionItemTemplateCreateFailureAction {
    type: "ACTION_TRIALINSTITUTIONITEMTEMPLATES_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialInstitutionItemTemplateCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialInstitutionItemTemplateCreateFailureAction;
}

export interface ITrialInstitutionItemTemplateClearAction {
    type: "ACTION_TRIALINSTITUTIONITEMTEMPLATES_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrialInstitutionItemTemplateClearActionCreator {
    (propertiesToClear?: string[]): ITrialInstitutionItemTemplateClearAction;
}

export interface ITrialInstitutionItemTemplateFilterActionCreator {
    (formFilter: IFormFilter): ITrialInstitutionItemTemplateFilterAction;
}

export interface ITrialInstitutionItemTemplateFilterAction {
    type: "ACTION_TRIALINSTITUTIONITEMTEMPLATES_FILTER";
    formFilter: IFormFilter;
}

export type TrialInstitutionItemTemplateAction =
    ITrialInstitutionItemTemplateLoadAction |
    ITrialInstitutionItemTemplateLoadByIdAction |
    ITrialInstitutionItemTemplateLoadFailureAction |
    ITrialInstitutionItemTemplateLoadSuccessAction |
    ITrialInstitutionItemTemplateSaveAction |
    ITrialInstitutionItemTemplateSaveSuccessAction |
    ITrialInstitutionItemTemplateSaveFailureAction |
    ITrialInstitutionItemTemplateCreateAction |
    ITrialInstitutionItemTemplateCreateSuccessAction |
    ITrialInstitutionItemTemplateCreateFailureAction |
    ITrialInstitutionItemTemplateClearAction |
    ITrialInstitutionItemTemplateFilterAction;
