import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const groupItemsApi = {
    getGroupItemById: (id: number): Observable<Dtos.GetGroupItemByIdResponse> => {
        //Create the request.
        let request: Dtos.GetGroupItemById = new Dtos.GetGroupItemById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetGroupItemByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setGroupItem: (groupItem: Dtos.GroupItem, collection: Dtos.Collection): Observable<Dtos.SetGroupItemResponse> => {

        let request: Dtos.SetGroupItem = new Dtos.SetGroupItem();

        request.groupItem = groupItem;
        request.collection = collection;

        // send request
        const response: Promise<Dtos.SetGroupItemResponse> = client.post(request);

        return Observable.from(response);

    },
    createGroupItem: (groupId: number): Observable<Dtos.CreateGroupItemResponse> => {
        //Create the request.
        let request: Dtos.CreateGroupItem = new Dtos.CreateGroupItem();

        request.groupId = groupId;

        //Send request.
        const response: Promise<Dtos.CreateGroupItemResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}