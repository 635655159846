import * as React from 'react';
import './ItemComponentPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";
import { IItemComponentReduxFormState, initialItemComponentState, initialItemComponentPrimitiveState, initialItemComponentRuleState } from "../../../reducers/reactReduxForms/itemComponent";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as ItemComponentActions from '../../../actions/itemComponents';
import * as ItemComponentPageActions from '../../../actions/pages/itemComponentsPage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { initialSourceDocumentState } from '../../../reducers/reactReduxForms/sourceDocument';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../../components/form';
import { PrimitiveFormDesigner, PrimitiveFormInput, CustomFormInput, CustomFormDesigner } from '../../../components/form/index';
import { SettingsSubMenu } from '../../../helpers/subMenuHelper';
import { formatBytes, SOURCE_DOCUMENT_FILE_CONFIGURATION } from "../../../helpers/file";
import { DEFAULT_FILE_UPLOAD_VIEW, SOURCE_DOCUMENT_UPLOAD_VIEW } from "../../../components/form/inputs/FileUploadFormInput/FileUploadViews";
import { requiredText, requiredSourceDocument } from "../../../validators/index";
import { GridRemove } from "../../../enumerations/GridRemove";
import { QALookupsLayout, ItemSettingsLayout } from '../../index';
import { FileUploadFormInput } from "../../../components/form/inputs/FileUploadFormInput/index";

interface IItemComponentPageParams {
    itemComponentId: number;
    cloneId: number;
}

interface IItemComponentPageProps {

    form: Dtos.ItemComponent & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IItemComponentReduxFormState;

    location: Location;
    itemComponentId: number;
    cloneId: number;
    match: match<IItemComponentPageParams>;
    history: History;

    previewModalOpen: boolean,
    addPrimitiveModalOpen: boolean,
    rulesModalOpen: boolean,

    // From state
    itemComponent: Dtos.ItemComponent;
    lookups: Dtos.Lookup[];
    loadingItemComponent: boolean;
    loadItemComponentSuccess: boolean;
    loadItemComponentFailure: boolean;

    savingItemComponent: boolean;
    saveItemComponentSuccess: boolean;
    saveItemComponentFailure: boolean;

    creatingItemComponent: boolean;
    createItemComponentSuccess: boolean;
    createItemComponentFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface IItemComponentPageActions {
    clearItemComponent: ItemComponentActions.IItemComponentClearActionCreator;
    loadItemComponent: ItemComponentActions.IItemComponentLoadByIdActionCreator;
    saveItemComponent: ItemComponentActions.IItemComponentSaveActionCreator;
    createItemComponent: ItemComponentActions.IItemComponentCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;


    setPreviewModalViewState: ItemComponentPageActions.IItemComponentPageSetPreviewModalViewStateActionCreator;
    setAddPrimitiveModalViewState: ItemComponentPageActions.IItemComponentPageSetAddPrimitiveModalViewStateActionCreator;
    setRulesModalViewState: ItemComponentPageActions.IItemComponentPageSetRulesModalViewStateActionCreator;

    toggleMessageModal: ItemComponentPageActions.IItemComponentPageToggleMessageModalActionCreator;
}

type ItemComponentPageProps = IItemComponentPageProps & IItemComponentPageActions;


const reduxFormName: string = "reduxForms.itemComponent";
const reduxFormNameItemComponentPrimitives: string = "reduxForms.itemComponent.itemComponentPrimitives";

class ItemComponentPage extends React.PureComponent<ItemComponentPageProps, any> {

    constructor(props: ItemComponentPageProps) {
        super(props);

        this.clearItemComponent = this.clearItemComponent.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
        this.onClone = this.onClone.bind(this);
        this.toggleMessageModal = this.toggleMessageModal.bind(this);

        this.state = {
            rootFolder: "/settings/item/itemcomponents",
            qaItemComponents: false,
        }
    }

    componentDidMount() {
        const {
            loadItemComponent,
            itemComponentId,
            cloneId,
            navigate,
            navigateReplace,
            createItemComponent
        } = this.props;

        this.setState({
            rootFolder: this.props.location.pathname.indexOf('/qa/') == -1 ? "/settings/item/itemcomponents" : "/settings/qa/formcomponents",
            qaItemComponents: this.props.location.pathname.indexOf('/qa/') > -1,
        });

        if (itemComponentId) {
            loadItemComponent(itemComponentId);
        } else if (!itemComponentId) {
            createItemComponent(cloneId);
        }
    }

    componentWillReceiveProps(nextProps: ItemComponentPageProps) {
    }

    componentDidUpdate(prevProps: ItemComponentPageProps, prevState) {
        const {
            navigateReplace,
            itemComponent,
            itemComponentId,
            cloneId,
            form,
            navigate,
            loadItemComponent,
            loadingItemComponent,
            loadItemComponentSuccess,
            createItemComponent,
            creatingItemComponent,
            createItemComponentSuccess,
            savingItemComponent,
            saveItemComponentSuccess,
            saveItemComponentFailure,
            validationFailures,
            toggleMessageModal
        } = this.props;

        if (itemComponentId && prevProps.itemComponentId != itemComponentId) {
            loadItemComponent(itemComponentId);
        }
        else if (!itemComponentId && prevProps.itemComponentId) {
            createItemComponent(cloneId);
        }

        if (!loadingItemComponent && prevProps.loadingItemComponent) {
            if (loadItemComponentSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingItemComponent && prevProps.savingItemComponent) {
            if (saveItemComponentSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate(this.state.rootFolder)
                } else if (!form.saveAndReturn && itemComponentId && itemComponentId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !itemComponentId && itemComponent && itemComponent.id > 0) {
                    navigate(this.state.rootFolder + "/" + itemComponent.id);
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingItemComponent && prevProps.creatingItemComponent) {
            if (createItemComponentSuccess) {
                if (itemComponent && itemComponent.id && itemComponent.id > 0) {
                    navigate(this.state.rootFolder + "/" + itemComponent.id);
                }
                this.loadForm();
            }
            else {

            }
        }
    }

    componentWillUpdate(nextProps: ItemComponentPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearItemComponent();
        loadForm(reduxFormName, initialItemComponentState);
        resetForm(reduxFormName);
    }

    clearItemComponent() {
        this.props.clearItemComponent();
    }

    showItemComponent(): boolean {
        const {
            loadingItemComponent,
            loadItemComponentSuccess
        } = this.props

        if (!loadingItemComponent && loadItemComponentSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            form,
            loadingItemComponent,
            permissions
        } = this.props;


        if (this.state.qaItemComponents) {
            return <QALookupsLayout loading={loadingItemComponent} permission={Dtos.Permission.FormComponentsView}>
                {this.renderContent()}
                {
                    this.renderPreviewModal()
                }
                {
                    this.renderAddPrimitiveModal()
                }
                {
                    this.renderRulesModal()
                }
            </QALookupsLayout>;
        }

        return <ItemSettingsLayout
            permission={Dtos.Permission.ItemComponentsView}
            loading={loadingItemComponent}
        >
            {this.renderContent()}
            {
                this.renderPreviewModal()
            }
            {
                this.renderAddPrimitiveModal()
            }
            {
                this.renderRulesModal()
            }
        </ItemSettingsLayout>;
    }

    renderContent() {
        const {
            form,
            itemComponent,
            itemComponentId,
            loadingItemComponent,
            loadItemComponentSuccess,
            loadItemComponentFailure,
            clearItemComponent,
            navigate,
            user,
            setPreviewModalViewState,
            setAddPrimitiveModalViewState,
            setRulesModalViewState,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingItemComponent,
            permissions
        } = this.props;

        let canUpdate: boolean = false;
        if (this.state.qaItemComponents) {
            canUpdate = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.FormComponentsUpdate);
        } else {
            canUpdate = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.ItemComponentsUpdate);
        }
        const formDisabled: boolean = !canUpdate || savingItemComponent || (formState && formState.formStatus == Dtos.FormStatus.Disabled || form.isSystem); // || savingDemographicDetailForm;
        let itemComponentPrimitivesLookup: Dtos.Lookup = { type: "ItemComponentRule", propertyName: "ItemComponentPrimitive", values: [] };
        if (itemComponent && itemComponent.itemComponentPrimitives && itemComponent.itemComponentPrimitives.length > 0) {
            itemComponentPrimitivesLookup.values = itemComponent.itemComponentPrimitives.filter(f => [
                Dtos.ItemPrimitive.Comments,
                Dtos.ItemPrimitive.Text,
                Dtos.ItemPrimitive.Date,
                Dtos.ItemPrimitive.Select,
                Dtos.ItemPrimitive.Numeric
            ].indexOf(f.itemPrimitive) > -1 && f.active).map((item, index) => {
                return { id: item.id, value: this.itemPrimitiveLabel(item.itemPrimitive) + " [" + item.label + "]", rank: item.rank, active: true };
            });
        }
        let compareItemComponentPrimitivesLookup: Dtos.Lookup = { type: "ItemComponentRule", propertyName: "CompareItemComponentPrimitive", values: [] };
        if (itemComponent && itemComponent.itemComponentPrimitives && itemComponent.itemComponentPrimitives.length > 0) {
            compareItemComponentPrimitivesLookup.values = itemComponent.itemComponentPrimitives.filter(f => [
                Dtos.ItemPrimitive.Comments,
                Dtos.ItemPrimitive.Text,
                Dtos.ItemPrimitive.Select,
                Dtos.ItemPrimitive.Numeric,
                Dtos.ItemPrimitive.Checkbox
            ].indexOf(f.itemPrimitive) > -1 && f.active).map((item, index) => {
                return { id: item.id, value: this.itemPrimitiveLabel(item.itemPrimitive) + " [" + item.label + "]", rank: item.rank, active: true };
            });
        }

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            name: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            description: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextAreaFormInput,
                inputDisabled: formDisabled,
            },
            itemComponentTypeId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("ItemComponent", "ItemComponentTypeId", lookups), form.itemComponentTypeId).filter(f => (this.state.qaItemComponents && ["3", "4", "5"].indexOf(f.value.toString()) > -1) || (!this.state.qaItemComponents && ["3", "4", "5"].indexOf(f.value.toString()) == -1))
                }
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            },
            documentsHeading: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="border-0 d-flex pt-4 pl-0">
                    <h3>Documents and Downloads</h3>
                </div>
            },
            sourceDocuments: {
                type: RegistrationComponent.RegistrationSourceDocuments,
                label: " ",
                disabled: formDisabled,
                components: {
                    sourceDocuments: {
                        type: RegistrationComponent.RegistrationGrid,
                        formName: "SourceDocument",
                        disabled: formDisabled,
                        label: "Add Source Document",
                        className: "source-documents-source-documents",
                        minRows: 0,
                        columnStyles: [
                            {
                                width: undefined,
                                minWidth: "200px"
                            },
                            {
                                width: "23%",
                                minWidth: "150px"
                            },
                            {
                                width: "20%",
                                minWidth: "150px"
                            },
                            {
                                width: "125px"
                            },
                        ],
                        removeConfig: GridRemove.All,
                        addRow: (gridComponent, index, array) => {
                            const state: Dtos.SourceDocument = Object.assign(
                                { ...gridComponent.initialState }
                            );
                            return state;
                        },
                        components: {
                            name: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: TextFormInput,
                                inputDisabled: formDisabled,
                                inputLabel: getFormPropertyLabel("CollectionInstance", "FileName", formProperties),
                                inputProps: {
                                    feedbackIcon: FontAwesomeIcons.Solid.BAN
                                },
                                validators: {
                                    required: requiredText
                                },
                                errors: {
                                    required: "File name is required."
                                },
                                errorComponent: ValidationMessage

                            },
                            fileType: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: SelectFormInput,
                                inputLabel: getFormPropertyLabel("CollectionInstance", "FileType", formProperties),
                                inputProps: {
                                    options: generateOptionsFromLookup(findLookup("SourceDocument", "FileType", lookups)),
                                    feedbackIcon: FontAwesomeIcons.Solid.BAN
                                },
                                validators: {
                                    required: value => value != undefined
                                },
                                errors: {
                                    required: "File type is required."
                                },
                                errorComponent: ValidationMessage
                            },
                            fileInformation: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: FileUploadFormInput,
                                inputDisabled: formDisabled,
                                inputLabel: getFormPropertyLabel("CollectionInstance", "Upload", formProperties),
                                changeAction: (model, value, form, parentModel) => {
                                    changeForm(model, value);
                                    const inputValue: Dtos.TemporaryFileInformation = value;
                                    const sourceDocument: Dtos.SourceDocument = form;

                                    if (sourceDocument.fileInformation &&
                                        inputValue &&
                                        sourceDocument.fileInformation.state != inputValue.state &&
                                        inputValue.state == Dtos.FileState.Success &&
                                        !sourceDocument.name) {
                                        changeForm(reduxFormName + parentModel + ".name", inputValue.temporaryFile.name.substr(0, inputValue.temporaryFile.name.lastIndexOf(inputValue.temporaryFile.extension)));
                                    }
                                },
                                inputProps: {
                                    render: SOURCE_DOCUMENT_UPLOAD_VIEW
                                },
                                validators: {
                                    required: requiredSourceDocument
                                },
                                errors: {
                                    required: "File must be uploaded."
                                },
                                errorComponent: ValidationMessage
                            },
                            fileConfiguration: {
                                type: RegistrationComponent.RegistrationGridElement,
                                label: " ",
                                render: SOURCE_DOCUMENT_FILE_CONFIGURATION
                            }
                        },
                        initialState: { ...initialSourceDocumentState }
                    },
                },
                keyReplace: "sourceDocuments",
                onRemove: (model: string) => {
                    console.log('onRemove', model);
                    changeForm(model + "active", false);
                    return true; // this was handled here
                },
                filterRow: (data: Dtos.SourceDocument) => {
                    console.info('filterRow', data);
                    if (data.active != true) {
                        return false;
                    }
                    return true;
                }
            },
            itemComponentRuleHeader: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="border-0 d-flex pt-4 ">
                    <h3>Rules</h3>
                </div>
            },
            itemComponentRules: {
                type: RegistrationComponent.RegistrationGrid,
                label: "Rules",
                disabled: formDisabled,
                formName: "ItemComponentRule",
                className: "",
                minRows: 0,
                columnStyles: [
                    {
                        width: undefined,
                        minWidth: "250px"
                    },
                    {
                        width: undefined,
                        minWidth: "200px"
                    },
                    {
                        width: undefined,
                        minWidth: "250px"
                    },
                    {
                        width: undefined,
                        minWidth: "130px"
                    },
                    {
                        width: undefined,
                        minWidth: "100px"
                    },
                    {
                        width: "125px"
                    },
                ],
                removeConfig: GridRemove.All,
                addRow: (gridComponent, index, array) => {
                    const state: Dtos.ItemComponentRule = Object.assign(
                        { ...gridComponent.initialState }
                    );
                    return state;
                },
                components: {
                    itemComponentPrimitiveId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputLabel: getFormPropertyLabel("ItemComponentRule", "ItemComponentPrimitiveId", formProperties),
                        inputProps: {
                            options: generateOptionsFromLookup(itemComponentPrimitivesLookup),
                            feedbackIcon: FontAwesomeIcons.Solid.BAN
                        },
                        validators: {
                            required: value => value != undefined
                        },
                        errors: {
                            required: "Item Component is required."
                        },
                        errorComponent: ValidationMessage
                    },
                    itemPrimitiveRule: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputLabel: getFormPropertyLabel("ItemComponentRule", "ItemPrimitiveRule", formProperties),
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("ItemComponentRule", "ItemPrimitiveRule", lookups)),
                            feedbackIcon: FontAwesomeIcons.Solid.BAN
                        },
                        validators: {
                            required: value => value != undefined
                        },
                        errors: {
                            required: "Rule type is required."
                        },
                        errorComponent: ValidationMessage,
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            if (value == Dtos.ItemPrimitiveRule.Required) {
                                changeForm(reduxFormName + parentModel + '.compareItemComponentPrimitiveId', null);
                                changeForm(reduxFormName + parentModel + '.compareValue', null);
                            }
                        },
                    },
                    compareItemComponentPrimitiveId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputLabel: getFormPropertyLabel("ItemComponentRule", "CompareItemComponentPrimitiveId", formProperties),
                        inputProps: {
                            options: generateOptionsFromLookup(compareItemComponentPrimitivesLookup),
                            feedbackIcon: FontAwesomeIcons.Solid.BAN
                        },
                        disabledFn: (form: Dtos.ItemComponentRule, arrayKey: string) => {
                            if (form) {
                                if (form.itemPrimitiveRule == Dtos.ItemPrimitiveRule.Required) {
                                    return true;
                                }
                            }
                            return false;
                        },
                    },
                    compareValue: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("ItemComponentRule", "CompareValue", formProperties),
                        disabledFn: (form: Dtos.ItemComponentRule, arrayKey: string) => {
                            if (form) {
                                if (form.itemPrimitiveRule == Dtos.ItemPrimitiveRule.Required) {
                                    return true;
                                }
                            }
                            return false;
                        },
                    },
                    active: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: CheckboxFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("ItemComponentRule", "Active", formProperties),
                    },
                },
                initialState: initialItemComponentRuleState,
                onRemove: (model: string) => {
                    changeForm(model + "active", false);
                    return true; // this was handled here
                },
                filterRow: (data: any) => {
                    if (data.active != true) {
                        return false
                    }
                    return true;
                }
            },
            componentDesignerHeader: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="border-0 d-flex pt-4 ">
                    <h3>Component Primitives</h3>
                </div>
            },
        };

        if (form.itemComponentPrimitives && form.itemComponentPrimitives.filter(f => f.active).length == 0) {
            const name = "itemComponentPrimitives_empty";
            formComponents[name] = {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="border-0 d-flex pt-4">
                    <p style={{ textAlign: 'center', width: '100%', border: '1px dotted gray', padding: '10px' }}>Add one or more primitives to start building the item component</p>
                </div>
            };
        } else if (form.itemComponentPrimitives && form.itemComponentPrimitives.length > 0) {
            [...form.itemComponentPrimitives].sort((a, b) => a.rank - b.rank).forEach((item, index) => {
                const name = "itemComponentPrimitives_" + item.id + "_" + index + "_" + item.rank;
                formComponents[name] = {
                    type: RegistrationComponent.RegistrationElement,
                    component: !item.active ? <div></div> : <div className="border-0 d-flex pt-4 ">
                        <PrimitiveFormDesigner
                            index={index + 1}
                            item={item}
                            id={name}
                            name={name}
                            disabled={formDisabled}
                            // onShowRules={(item) => { setRulesModalViewState(true); }}
                            changeAction={(name, value) => {
                                changeForm(reduxFormNameItemComponentPrimitives + "[" + index.toString() + "]." + name, value);
                            }}
                            onUp={item => {
                                // find the previous rank, and then swap
                                let swapIndex = -1;
                                let swapRank = -1;
                                let newArray = [...form.itemComponentPrimitives].sort((a, b) => a.rank - b.rank);
                                newArray.forEach((swapItem, i) => {
                                    if (i < index && swapItem.active) {
                                        swapIndex = i;
                                        swapRank = swapItem.rank;
                                    }
                                });
                                if (swapIndex > -1) {
                                    newArray[swapIndex] = Object.assign({ ...newArray[swapIndex], rank: item.rank });
                                    newArray[index] = Object.assign({ ...newArray[index], rank: swapRank });;
                                    changeForm(reduxFormNameItemComponentPrimitives, newArray);
                                }
                            }}
                            onDown={() => {
                                // find the previous rank, and then swap
                                let swapIndex = -1;
                                let swapRank = -1;
                                let newArray = [...form.itemComponentPrimitives].sort((a, b) => a.rank - b.rank);
                                newArray.forEach((swapItem, i) => {
                                    if (i > index && swapItem.active && swapIndex == -1) {
                                        swapIndex = i;
                                        swapRank = swapItem.rank;
                                    }
                                });
                                if (swapIndex > -1) {
                                    newArray[swapIndex] = Object.assign({ ...newArray[swapIndex], rank: item.rank });
                                    newArray[index] = Object.assign({ ...newArray[index], rank: swapRank });;
                                    changeForm(reduxFormNameItemComponentPrimitives, newArray);
                                }
                            }}
                        />
                    </div>
                };
            });
        }
        if (form.customInputControlName && form.customInputControlName != '') {
            const name = "itemComponent_" + form.id + "_" + form.customInputControlName;
            formComponents[name] = {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="border-0 d-flex pt-4 ">
                    <CustomFormDesigner item={form} id={name} name={name} />
                </div>
            };

        }

        return <div>
            <h1>{this.state.qaItemComponents ? "Form Components" : "Item Component"}</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "itemComponent", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                setAddPrimitiveModalViewState(true);
                            }
                        }
                        type="button"
                        className="btn btn-outline-secondary mr-2"
                    >
                        Add primitive
                    </button>
                    <button disabled={false}
                        onClick={
                            () => {
                                setPreviewModalViewState(true);
                            }
                        }
                        type="button"
                        className="btn btn-secondary mr-2"
                    >
                        Preview
                    </button>
                    <button disabled={loadingItemComponent || savingItemComponent || !itemComponentId } onClick={this.onClone} className="btn btn-warning mr-2" > Clone </button>
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderPreviewModal() {
        const {
            previewModalOpen,
            setPreviewModalViewState,

            form,
            itemComponent,
            loadingItemComponent,
            loadItemComponentSuccess,
            loadItemComponentFailure,
            clearItemComponent,
            navigate,
            user,
            setAddPrimitiveModalViewState,
            setRulesModalViewState,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm
        } = this.props

        if (true) { // replace with checks for data

            const formDisabled: boolean = false;

            let formComponents: { [index: string]: RegistrationFormComponent } = {
            };

            if (form && form.itemComponentPrimitives) {
                [...form.itemComponentPrimitives].sort((a, b) => a.rank - b.rank).filter(f => f.active).sort((a, b) => a.rank - b.rank).forEach((item, index) => {
                    const name = "itemComponentInputPrimitives_" + item.id + "_" + index;
                    formComponents[name] = {
                        type: RegistrationComponent.RegistrationElement,
                        component: <div className="border-0 d-flex pt-4 ">
                            <PrimitiveFormInput item={item} id={name} name={name} />
                        </div>
                    };
                });
            }

            if (form && form.customInputControlName && form.customInputControlName != '') {
                const name = "itemComponent_" + form.id + "_" + form.customInputControlName;
                formComponents[name] = {
                    type: RegistrationComponent.RegistrationElement,
                    component: <div className="border-0 d-flex pt-4 ">
                        <CustomFormInput item={form} id={name} name={name} />
                    </div>
                };
            }

            return <Modal open={previewModalOpen} disableCloseOnOverlayClick={true} onClose={() => { setPreviewModalViewState(false); }} size={ModalSize.Md}>
                <div className="modal-header">
                    <h5 className="modal-title">{setModalTitle(undefined, "Preview Item Component")}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => { setPreviewModalViewState(false); }}>
                        <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                    </button>
                </div>
                <div className="modal-body">
                    {
                        renderFormControls(form, "itemComponent", formComponents, lookups, validationFailures, formProperties, changeForm)
                    }
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger" onClick={() => { setPreviewModalViewState(false); }}>Close</button>
                </div>
            </Modal>
        }
    }

    renderPrimitiveButton(label, description, itemPrimitive: Dtos.ItemPrimitive) {
        const {
            addPrimitiveModalOpen,
            setAddPrimitiveModalViewState,
            form,
            changeForm
        } = this.props

        return <button disabled={false}
            onClick={
                () => {
                    /* tslint:disable-next-line */
                    const newItem: Dtos.ItemComponentPrimitive = Object.assign({ ...initialItemComponentPrimitiveState });
                    newItem.itemPrimitive = itemPrimitive;
                    let array: any[] = [];
                    if (form.itemComponentPrimitives.length > 0) {
                        let newRank = 1;
                        form.itemComponentPrimitives.forEach(p => { if (p.rank >= newRank) { newRank = p.rank; } });
                        newItem.rank = newRank + 1;
                        array = [...form["itemComponentPrimitives"], newItem];
                    } else {
                        newItem.rank = 1;
                        array = [newItem];
                    }

                    changeForm(reduxFormNameItemComponentPrimitives, array);

                    setAddPrimitiveModalViewState(false);
                }
            }
            type="submit"
            className="btn btn-block btn-outline-dark mb-2"
        >
            {label}<br />
            <small>{description}</small>
        </button>;
    }

    renderAddPrimitiveModal() {
        const {
            addPrimitiveModalOpen,
            setAddPrimitiveModalViewState,
            form,
            itemComponent,
            loadingItemComponent,
            loadItemComponentSuccess,
            loadItemComponentFailure,
            clearItemComponent,
            navigate,
            user,
            setPreviewModalViewState,
            setRulesModalViewState,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm
        } = this.props

        if (true) { // replace with checks for data

            return <Modal open={addPrimitiveModalOpen} disableCloseOnOverlayClick={true} onClose={() => { setAddPrimitiveModalViewState(false); }} size={ModalSize.Md}>
                <div className="modal-header">
                    <h5 className="modal-title">{setModalTitle(undefined, "Select primitive to add")}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => { setAddPrimitiveModalViewState(false); }}>
                        <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                    </button>
                </div>
                <div className="modal-body">
                    {
                        this.renderPrimitiveButton("Label", "Display a caption that the user cannot overwrite", Dtos.ItemPrimitive.Label)
                    }
                    {
                        this.renderPrimitiveButton("Text", "Control for the user to enter single line of free text", Dtos.ItemPrimitive.Text)
                    }
                    {
                        this.renderPrimitiveButton("Comments", "Control for the user to enter multiple lines of free text", Dtos.ItemPrimitive.Comments)
                    }
                    {
                        this.renderPrimitiveButton("Check", "Tick box for yes/no answers", Dtos.ItemPrimitive.Checkbox)
                    }
                    {
                        this.renderPrimitiveButton("Documents", "Upload documents to this component", Dtos.ItemPrimitive.Documents)
                    }
                    {
                        this.renderPrimitiveButton("Numeric", "Control to enter numbers", Dtos.ItemPrimitive.Numeric)
                    }
                    {
                        this.renderPrimitiveButton("Select", "Choose a single item from a drop down list", Dtos.ItemPrimitive.Select)
                    }
                    {
                        this.renderPrimitiveButton("Date", "Control to select a date", Dtos.ItemPrimitive.Date)
                    }

                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger" onClick={() => { setAddPrimitiveModalViewState(false); }}>Close</button>
                </div>
            </Modal>
        }
    }

    renderRulesModal() {
        const {
            rulesModalOpen,
            setRulesModalViewState,
            form,
            itemComponent,
            loadingItemComponent,
            loadItemComponentSuccess,
            loadItemComponentFailure,
            clearItemComponent,
            navigate,
            user,
            setPreviewModalViewState,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm
        } = this.props

        if (true) { // replace with checks for data
            const formDisabled: boolean = false;

            let formComponents: { [index: string]: RegistrationFormComponent } = {
                itemComponentRules: {
                    type: RegistrationComponent.RegistrationGrid,
                    label: "Rules",
                    disabled: formDisabled,
                    formName: "ItemComponentRule",
                    className: "",
                    minRows: 0,
                    columnStyles: [
                        {
                            width: undefined,
                            minWidth: "200px"
                        },
                        {
                            width: "23%",
                            minWidth: "150px"
                        },
                        {
                            width: "20%",
                            minWidth: "150px"
                        },
                        {
                            width: "125px"
                        },
                    ],
                    removeConfig: GridRemove.All,
                    addRow: (gridComponent, index, array) => {
                        const state: Dtos.ItemComponentRule = Object.assign(
                            { ...gridComponent.initialState }
                        );
                        return state;
                    },
                    components: {
                        itemPrimitiveRule: {
                            type: RegistrationComponent.RegistrationControl,
                            inputType: SelectFormInput,
                            inputLabel: getFormPropertyLabel("ItemComponent", "ItemPrimitiveRule", formProperties),
                            inputProps: {
                                options: generateOptionsFromLookup(findLookup("ItemComponentRule", "ItemPrimitiveRule", lookups)),
                                feedbackIcon: FontAwesomeIcons.Solid.BAN
                            },
                            validators: {
                                required: value => value != undefined
                            },
                            errors: {
                                required: "Rule type is required."
                            },
                            errorComponent: ValidationMessage
                        },
                        compareValue: {
                            type: RegistrationComponent.RegistrationControl,
                            inputType: TextFormInput,
                            inputDisabled: formDisabled,
                            inputLabel: getFormPropertyLabel("ItemComponent", "CompareValue", formProperties)
                        },
                        active: {
                            type: RegistrationComponent.RegistrationControl,
                            inputType: CheckboxFormInput,
                            inputDisabled: formDisabled
                        },
                    },
                    initialState: initialItemComponentRuleState,
                    onRemove: (model: string) => {
                        changeForm(model + "active", false);
                        return true; // this was handled here
                    },
                    filterRow: (data: any) => {
                        if (data.active != true) {
                            return false
                        }
                        return true;
                    }
                }
            };

            return <Modal
                open={rulesModalOpen}
                disableCloseOnOverlayClick={true}
                onClose={() => { setRulesModalViewState(false); }}
                size={ModalSize.Xl}>
                <div className="modal-header">
                    <h5 className="modal-title">{setModalTitle(undefined, "Rules")}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => { setRulesModalViewState(false); }}>
                        <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                    </button>
                </div>
                <div className="modal-body">
                    {
                        renderFormControls(form, "itemComponent", formComponents, lookups, validationFailures, formProperties, changeForm)
                    }
                </div>
                <div className="modal-footer">
                    <button type="submit" className="btn btn-success">Confirm</button>
                    <button type="button" className="btn btn-danger" onClick={() => { setRulesModalViewState(false); }}>Close</button>
                </div>
            </Modal>
        }
    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    itemPrimitiveLabel(itemPrimitive: Dtos.ItemPrimitive) {
        switch (itemPrimitive) {
            case Dtos.ItemPrimitive.Checkbox:
                return "Checkbox";
            case Dtos.ItemPrimitive.Text:
                return "Text";
            case Dtos.ItemPrimitive.Label:
                return "Label";
            case Dtos.ItemPrimitive.Comments:
                return "Comments";
            case Dtos.ItemPrimitive.Date:
                return "Date";
            case Dtos.ItemPrimitive.Documents:
                return "Documents";
            case Dtos.ItemPrimitive.Numeric:
                return "Numeric";
            case Dtos.ItemPrimitive.Select:
                return "Select";
        }
    }

    handleOnSubmit(data: Dtos.ItemComponent) {
        const {
            saveItemComponent,
        } = this.props;

        if (data) {
            let changes: Dtos.ItemComponent = {} as Dtos.ItemComponent;
            saveItemComponent(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.ItemComponent) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate(this.state.rootFolder);
    }

    onClone() {
        const {
            navigate,
            itemComponentId
        } = this.props;

        navigate(this.state.rootFolder  + "/create/" + itemComponentId);
    }

    toggleMessageModal() {
        const {
            toggleMessageModal
        } = this.props
        // eslint-disable-next-line
        toggleMessageModal;
    }

    loadForm() {
        const {
            loadForm,
            itemComponent,
        } = this.props

        let extraData: Dtos.ItemComponent = {} as Dtos.ItemComponent;

        loadForm(reduxFormName, Object.assign({ ...itemComponent }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: ItemComponentPageProps): IItemComponentPageProps => {

    let itemComponent: Dtos.ItemComponent | undefined = !(state.itemComponent.formData instanceof Array) ? state.itemComponent.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        itemComponentId: ownProps.match && ownProps.match.params.itemComponentId.toString() != "create" ? ownProps.match.params.itemComponentId : undefined!,
        cloneId: ownProps.match && ownProps.match.params.cloneId ? ownProps.match.params.cloneId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        itemComponent: itemComponent!,

        loadingItemComponent: state.itemComponent.loadState && state.itemComponent.loadState.status == RequestState.Pending,
        loadItemComponentSuccess: state.itemComponent.loadState && state.itemComponent.loadState.status == RequestState.Success,
        loadItemComponentFailure: state.itemComponent.loadState && state.itemComponent.loadState.status == RequestState.Failure,

        savingItemComponent: state.itemComponent.saveState && state.itemComponent.saveState.status == RequestState.Pending,
        saveItemComponentSuccess: state.itemComponent.saveState && state.itemComponent.saveState.status == RequestState.Success,
        saveItemComponentFailure: state.itemComponent.saveState && state.itemComponent.saveState.status == RequestState.Failure,

        creatingItemComponent: state.itemComponent.createState && state.itemComponent.createState.status == RequestState.Pending,
        createItemComponentSuccess: state.itemComponent.createState && state.itemComponent.createState.status == RequestState.Success,
        createItemComponentFailure: state.itemComponent.createState && state.itemComponent.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: state.itemComponent.lookups,

        form: state.reduxForms.itemComponent,
        formState: state.itemComponent.formState,
        formProperties: state.itemComponent.formProperties,

        reduxFormState: state.reduxForms.formState.itemComponent,
        validationFailures: itemComponent ?
            state.itemComponent.validationFailures :
            undefined,

        previewModalOpen: state.itemComponentPage.previewModalOpen,
        addPrimitiveModalOpen: state.itemComponentPage.addPrimitiveModalOpen,
        rulesModalOpen: state.itemComponentPage.rulesModalOpen,
    };
};

const mapDispatchToProps = (dispatch): IItemComponentPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadItemComponent: bindActionCreators(ItemComponentActions.LoadItemComponentById, dispatch),
        clearItemComponent: bindActionCreators(ItemComponentActions.Clear, dispatch),

        saveItemComponent: bindActionCreators(ItemComponentActions.SaveItemComponent, dispatch),
        createItemComponent: bindActionCreators(ItemComponentActions.CreateItemComponent, dispatch),

        toggleMessageModal: bindActionCreators(ItemComponentPageActions.toggleMessageModal, dispatch),
        setPreviewModalViewState: bindActionCreators(ItemComponentPageActions.setPreviewModalViewState, dispatch),
        setAddPrimitiveModalViewState: bindActionCreators(ItemComponentPageActions.setAddPrimitiveModalViewState, dispatch),
        setRulesModalViewState: bindActionCreators(ItemComponentPageActions.setRulesModalViewState, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(ItemComponentPage);
