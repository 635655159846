import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadInstitution: IInstitutionLoadActionCreator = () => {
        return {
            type: ACTION_INSTITUTIONS_LOAD
        }
    },
    LoadInstitutionById: IInstitutionLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_INSTITUTIONS_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IInstitutionLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_INSTITUTIONS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IInstitutionLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_INSTITUTIONS_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveInstitution: IInstitutionSaveActionCreator = (formData) => {
        return {
            type: ACTION_INSTITUTIONS_SAVE,
            formData
        }
    },
    SaveSuccess: IInstitutionSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_INSTITUTIONS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IInstitutionSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_INSTITUTIONS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateInstitution: IInstitutionCreateActionCreator = () => {

        return {
            type: ACTION_INSTITUTIONS_CREATE
        }
    },
    CreateSuccess: IInstitutionCreateSuccessActionCreator = (formData, formState, formProperties, lookups) => {
        return {
            type: ACTION_INSTITUTIONS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups
        }
    },
    CreateFailure: IInstitutionCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_INSTITUTIONS_CREATE_FAILURE,
            responseStatus
        }
    },

    Clear: IInstitutionClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_INSTITUTIONS_CLEAR,
            propertiesToClear
        }
    },

    Filter: IInstitutionFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_INSTITUTIONS_FILTER,
            formFilter
        }
    },
    PersonnelRolesFilter: IInstitutionsPersonnelRolesFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_INSTITUTIONS_PERSONNEL_ROLES_FILTER,
            formFilter
        }
    };



export const
    ACTION_INSTITUTIONS_LOAD = "ACTION_INSTITUTIONS_LOAD",
    ACTION_INSTITUTIONS_LOAD_BY_ID = "ACTION_INSTITUTIONS_LOAD_BY_ID",
    ACTION_INSTITUTIONS_LOAD_SUCCESS = "ACTION_INSTITUTIONS_LOAD_SUCCESS",
    ACTION_INSTITUTIONS_LOAD_FAILURE = "ACTION_INSTITUTIONS_LOAD_FAILURE",

    ACTION_INSTITUTIONS_SAVE = "ACTION_INSTITUTIONS_SAVE",
    ACTION_INSTITUTIONS_SAVE_SUCCESS = "ACTION_INSTITUTIONS_SAVE_SUCCESS",
    ACTION_INSTITUTIONS_SAVE_FAILURE = "ACTION_INSTITUTIONS_SAVE_FAILURE",

    ACTION_INSTITUTIONS_CREATE = "ACTION_INSTITUTIONS_CREATE",
    ACTION_INSTITUTIONS_CREATE_SUCCESS = "ACTION_INSTITUTIONS_CREATE_SUCCESS",
    ACTION_INSTITUTIONS_CREATE_FAILURE = "ACTION_INSTITUTIONS_CREATE_FAILURE",

    ACTION_INSTITUTIONS_CLEAR = "ACTION_INSTITUTIONS_CLEAR",

    ACTION_INSTITUTIONS_FILTER = "ACTION_INSTITUTIONS_FILTER",
    ACTION_INSTITUTIONS_PERSONNEL_ROLES_FILTER = "ACTION_INSTITUTIONS_PERSONNEL_ROLES_FILTER";

export interface IInstitutionLoadAction {
    type: "ACTION_INSTITUTIONS_LOAD";
}

export interface IInstitutionLoadActionCreator {
    (): IInstitutionLoadAction;
}

export interface IInstitutionLoadSuccessAction {
    type: "ACTION_INSTITUTIONS_LOAD_SUCCESS";
    formData: Dtos.Institution | Dtos.Institution[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IInstitutionLoadSuccessActionCreator {
    (
        formData: Dtos.Institution | Dtos.Institution[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IInstitutionLoadSuccessAction;
}

export interface IInstitutionLoadFailureAction {
    type: "ACTION_INSTITUTIONS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IInstitutionLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IInstitutionLoadFailureAction;
}

export interface IInstitutionLoadByIdAction {
    type: "ACTION_INSTITUTIONS_LOAD_BY_ID";
    id: number;
}

export interface IInstitutionLoadByIdActionCreator {
    (id: number): IInstitutionLoadByIdAction;
}


export interface IInstitutionSaveAction {
    type: "ACTION_INSTITUTIONS_SAVE";
    formData: Dtos.Institution;

}

export interface IInstitutionSaveActionCreator {
    (
        formData: Dtos.Institution
    ): IInstitutionSaveAction;
}

export interface IInstitutionSaveSuccessAction {
    type: "ACTION_INSTITUTIONS_SAVE_SUCCESS";
    formData: Dtos.Institution;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IInstitutionSaveSuccessActionCreator {
    (
        formData: Dtos.Institution,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IInstitutionSaveSuccessAction;
}

export interface IInstitutionSaveFailureAction {
    type: "ACTION_INSTITUTIONS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IInstitutionSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IInstitutionSaveFailureAction;
}

export interface IInstitutionCreateAction {
    type: "ACTION_INSTITUTIONS_CREATE"
}

export interface IInstitutionCreateActionCreator {
    (): IInstitutionCreateAction
}

export interface IInstitutionCreateSuccessAction {
    type: "ACTION_INSTITUTIONS_CREATE_SUCCESS";
    formData: Dtos.Institution;
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[]
}

export interface IInstitutionCreateSuccessActionCreator {
    (
        formData: Dtos.Institution,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[]
    ): IInstitutionCreateSuccessAction;
}

export interface IInstitutionCreateFailureAction {
    type: "ACTION_INSTITUTIONS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IInstitutionCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IInstitutionCreateFailureAction;
}

export interface IInstitutionClearAction {
    type: "ACTION_INSTITUTIONS_CLEAR";
    propertiesToClear?: string[];
}

export interface IInstitutionClearActionCreator {
    (propertiesToClear?: string[]): IInstitutionClearAction;
}

export interface IInstitutionFilterActionCreator {
    (formFilter: IFormFilter): IInstitutionFilterAction;
}

export interface IInstitutionFilterAction {
    type: "ACTION_INSTITUTIONS_FILTER";
    formFilter: IFormFilter;
}

export interface IInstitutionsPersonnelRolesFilterActionCreator {
    (formFilter: IFormFilter): IInstitutionsPersonnelRolesFilterAction;
}

export interface IInstitutionsPersonnelRolesFilterAction {
    type: "ACTION_INSTITUTIONS_PERSONNEL_ROLES_FILTER";
    formFilter: IFormFilter;
}

export type InstitutionAction =
    IInstitutionLoadAction |
    IInstitutionLoadByIdAction |
    IInstitutionLoadFailureAction |
    IInstitutionLoadSuccessAction |
    IInstitutionSaveAction |
    IInstitutionSaveSuccessAction |
    IInstitutionSaveFailureAction |
    IInstitutionCreateAction |
    IInstitutionCreateSuccessAction |
    IInstitutionCreateFailureAction |
    IInstitutionClearAction |
    IInstitutionFilterAction |
    IInstitutionsPersonnelRolesFilterAction;
