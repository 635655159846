import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadNoteType: INoteTypeLoadActionCreator = () => {
        return {
            type: ACTION_NOTETYPES_LOAD
        }
    },
    LoadNoteTypeById: INoteTypeLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_NOTETYPES_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: INoteTypeLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_NOTETYPES_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: INoteTypeLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_NOTETYPES_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveNoteType: INoteTypeSaveActionCreator = (formData) => {
        return {
            type: ACTION_NOTETYPES_SAVE,
            formData
        }
    },
    SaveSuccess: INoteTypeSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_NOTETYPES_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: INoteTypeSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_NOTETYPES_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateNoteType: INoteTypeCreateActionCreator = () => {

        return {
            type: ACTION_NOTETYPES_CREATE
        }
    },
    CreateSuccess: INoteTypeCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_NOTETYPES_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: INoteTypeCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_NOTETYPES_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: INoteTypeClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_NOTETYPES_CLEAR,
            propertiesToClear
        }
    },

    Filter: INoteTypeFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_NOTETYPES_FILTER,
            formFilter
        }
    };



export const
    ACTION_NOTETYPES_LOAD = "ACTION_NOTETYPES_LOAD",
    ACTION_NOTETYPES_LOAD_BY_ID = "ACTION_NOTETYPES_LOAD_BY_ID",
    ACTION_NOTETYPES_LOAD_SUCCESS = "ACTION_NOTETYPES_LOAD_SUCCESS",
    ACTION_NOTETYPES_LOAD_FAILURE = "ACTION_NOTETYPES_LOAD_FAILURE",

    ACTION_NOTETYPES_SAVE = "ACTION_NOTETYPES_SAVE",
    ACTION_NOTETYPES_SAVE_SUCCESS = "ACTION_NOTETYPES_SAVE_SUCCESS",
    ACTION_NOTETYPES_SAVE_FAILURE = "ACTION_NOTETYPES_SAVE_FAILURE",

    ACTION_NOTETYPES_CREATE = "ACTION_NOTETYPES_CREATE",
    ACTION_NOTETYPES_CREATE_SUCCESS = "ACTION_NOTETYPES_CREATE_SUCCESS",
    ACTION_NOTETYPES_CREATE_FAILURE = "ACTION_NOTETYPES_CREATE_FAILURE",

    ACTION_NOTETYPES_CLEAR = "ACTION_NOTETYPES_CLEAR",

    ACTION_NOTETYPES_FILTER = "ACTION_NOTETYPES_FILTER";

export interface INoteTypeLoadAction {
    type: "ACTION_NOTETYPES_LOAD";
}

export interface INoteTypeLoadActionCreator {
    (): INoteTypeLoadAction;
}

export interface INoteTypeLoadSuccessAction {
    type: "ACTION_NOTETYPES_LOAD_SUCCESS";
    formData: Dtos.NoteType | Dtos.NoteType[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface INoteTypeLoadSuccessActionCreator {
    (
        formData: Dtos.NoteType | Dtos.NoteType[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): INoteTypeLoadSuccessAction;
}

export interface INoteTypeLoadFailureAction {
    type: "ACTION_NOTETYPES_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface INoteTypeLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): INoteTypeLoadFailureAction;
}

export interface INoteTypeLoadByIdAction {
    type: "ACTION_NOTETYPES_LOAD_BY_ID";
    id: number;
}

export interface INoteTypeLoadByIdActionCreator {
    (id: number): INoteTypeLoadByIdAction;
}


export interface INoteTypeSaveAction {
    type: "ACTION_NOTETYPES_SAVE";
    formData: Dtos.NoteType;

}

export interface INoteTypeSaveActionCreator {
    (
        formData: Dtos.NoteType
    ): INoteTypeSaveAction;
}

export interface INoteTypeSaveSuccessAction {
    type: "ACTION_NOTETYPES_SAVE_SUCCESS";
    formData: Dtos.NoteType;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface INoteTypeSaveSuccessActionCreator {
    (
        formData: Dtos.NoteType,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): INoteTypeSaveSuccessAction;
}

export interface INoteTypeSaveFailureAction {
    type: "ACTION_NOTETYPES_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface INoteTypeSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): INoteTypeSaveFailureAction;
}

export interface INoteTypeCreateAction {
    type: "ACTION_NOTETYPES_CREATE"
}

export interface INoteTypeCreateActionCreator {
    (): INoteTypeCreateAction
}

export interface INoteTypeCreateSuccessAction {
    type: "ACTION_NOTETYPES_CREATE_SUCCESS";
    formData: Dtos.NoteType;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface INoteTypeCreateSuccessActionCreator {
    (
        formData: Dtos.NoteType,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): INoteTypeCreateSuccessAction;
}

export interface INoteTypeCreateFailureAction {
    type: "ACTION_NOTETYPES_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface INoteTypeCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): INoteTypeCreateFailureAction;
}

export interface INoteTypeClearAction {
    type: "ACTION_NOTETYPES_CLEAR";
    propertiesToClear?: string[];
}

export interface INoteTypeClearActionCreator {
    (propertiesToClear?: string[]): INoteTypeClearAction;
}

export interface INoteTypeFilterActionCreator {
    (formFilter: IFormFilter): INoteTypeFilterAction;
}

export interface INoteTypeFilterAction {
    type: "ACTION_NOTETYPES_FILTER";
    formFilter: IFormFilter;
}

export type NoteTypeAction =
    INoteTypeLoadAction |
    INoteTypeLoadByIdAction |
    INoteTypeLoadFailureAction |
    INoteTypeLoadSuccessAction |
    INoteTypeSaveAction |
    INoteTypeSaveSuccessAction |
    INoteTypeSaveFailureAction |
    INoteTypeCreateAction |
    INoteTypeCreateSuccessAction |
    INoteTypeCreateFailureAction |
    INoteTypeFilterAction | 
    INoteTypeClearAction;
