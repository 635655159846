import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";
    
export const initialWebsiteSettingFormState: Dtos.WebsiteSetting & IRegistrationFormState = {
    showMessage : null,
    message: null,
    saveAndReturn: false,
}

export interface IWebsiteSettingReduxFormState {
    showMessage: IFieldState<boolean>,
    message: IFieldState<string>
}
