import * as React from 'react';
import './TrialLayout.scss';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { RestrictedLayout } from "../../../components/layouts/index";
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { TrialSubMenu } from '../../../helpers/subMenuHelper';

interface ITrialLayoutProps {
    // From state
    permissions?: Dtos.PersonnelTmdPermissions[];
    permission?: Dtos.Permission;
    trial?: Dtos.Trial;
    trialRoles?: Dtos.RoleType[];

    loadingTrial?: boolean;

    loading?: boolean;
    children?: any;
}

interface ITrialLayoutActions {

}

type TrialLayoutProps = ITrialLayoutProps & ITrialLayoutActions;

class TrialLayout extends React.PureComponent<TrialLayoutProps, any> {

    constructor(props: TrialLayoutProps) {
        super(props);

    }

    render() {

        const {
            loadingTrial,
            trial,
            loading,
            permissions,
            permission
        } = this.props;

        return <RestrictedLayout
            permission={permission}
            trialId={trial ? trial.id : null}
            subMenuTitle={trial ? trial.shortDisplay : ""}
            subMenuItems={loadingTrial == undefined || !trial ? [] : TrialSubMenu(trial.id, permissions)}
            loading={loadingTrial || loading}
        >
            {this.props.children}
        </RestrictedLayout>;
    }

}


const mapStateToProps = (state: ITmdState, ownProps: TrialLayoutProps): ITrialLayoutProps => {

    let trial: Dtos.Trial | undefined = !(state.trials.formData instanceof Array) ? state.trials.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        permissions: permissions,
        permission: ownProps.permission,

        trial: trial!,
        trialRoles: state.trials.formRoles,

        loadingTrial: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,

        loading: ownProps.loading,
        children: ownProps.children,
    };
};

const mapDispatchToProps = (dispatch): ITrialLayoutActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialLayout);
