import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const allocatedWPApi = {
    getAllocatedWPs: (): Observable<Dtos.GetAllocatedWPsResponse> => {
        //Create the request.
        let request: Dtos.GetAllocatedWPs = new Dtos.GetAllocatedWPs();

        //Send request.
        const response: Promise<Dtos.GetAllocatedWPsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getAllocatedWPById: (id: number): Observable<Dtos.GetAllocatedWPByIdResponse> => {
        //Create the request.
        let request: Dtos.GetAllocatedWPById = new Dtos.GetAllocatedWPById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetAllocatedWPByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setAllocatedWP: (allocatedWP: Dtos.AllocatedWP): Observable<Dtos.SetAllocatedWPResponse> => {

        let request: Dtos.SetAllocatedWP = new Dtos.SetAllocatedWP();

        request.allocatedWP = allocatedWP;

        // send request
        const response: Promise<Dtos.SetAllocatedWPResponse> = client.post(request);

        return Observable.from(response);

    },
    createAllocatedWP: (): Observable<Dtos.CreateAllocatedWPResponse> => {
        //Create the request.
        let request: Dtos.CreateAllocatedWP = new Dtos.CreateAllocatedWP();

        //Send request.
        const response: Promise<Dtos.CreateAllocatedWPResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}