import * as Dtos from '../dtos/Tmd.dtos';


/** @description Searches failures array for validation failures for a particular field and returns all failures found
 * @param {string} propertyName the string representation of the lookup to retrieve.
 * @param {Lookup[]} lookups the lookup array to search
 * @return {Lookup}
 */
export function findFieldValidationFailures(fieldName: string, failures: Dtos.ResponseError[]): Dtos.ResponseError[]  {

    if (fieldName && failures) {

        const validationFailures: Dtos.ResponseError[] = failures.filter(l => l.fieldName.toLocaleLowerCase() == fieldName.toLocaleLowerCase())
        //const validationFailure: Dtos.ResponseError[] = failures.find(l => l.fieldName.toLocaleLowerCase() == fieldName.toLocaleLowerCase());
        return validationFailures;
    }

    return [];
}

export function updateFailureIndexToId(failures: Dtos.ResponseError[], formData: any): Dtos.ResponseError[] {

    let errors: Dtos.ResponseError[] = [];

    for (let error of failures) {

        const startingIndex: number = error.fieldName.indexOf("[");

        if (startingIndex >= 0) {

            let propertyName: string = error.fieldName.substr(0, startingIndex);

            propertyName = propertyName[0].toLocaleLowerCase() + propertyName.substr(1);

            const closingIndex: number = error.fieldName.indexOf("]");

            let indexString: string = error.fieldName.substr(startingIndex + 1, closingIndex - (startingIndex + 1));

            let indexNumber: number = parseInt(indexString);

            let fieldName = error.fieldName.substr(0, startingIndex + 1) + formData[propertyName][indexNumber].id + error.fieldName.substr(closingIndex);

            let newError: Dtos.ResponseError = { ...error };

            newError.fieldName = fieldName;

            errors.push(newError);

        }
        else {
            errors.push(error);
        }
    }

    if (errors.length == 0) {
        errors = [];
    }

    return errors;
}

export function filterResponseErrorsByCode(failures: Dtos.ResponseError[],
    errorCode: string): Dtos.ResponseError[] {
    let errors: Dtos.ResponseError[] = [];

    if (failures != undefined) {
        errors = failures.filter(e => e.errorCode == errorCode);
    }

    return errors;
}
