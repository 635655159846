import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_TRIALCOORDCENTRES_LOAD,
    ACTION_TRIALCOORDCENTRES_LOAD_BY_ID,

    ACTION_TRIALCOORDCENTRES_SAVE,

    ACTION_TRIALCOORDCENTRES_CREATE,

    ITrialCoordCentreLoadAction,
    ITrialCoordCentreLoadByIdAction,
    ITrialCoordCentreLoadFailureAction,
    ITrialCoordCentreLoadSuccessAction,

    ITrialCoordCentreSaveAction,
    ITrialCoordCentreSaveSuccessAction,
    ITrialCoordCentreSaveFailureAction,

    ITrialCoordCentreCreateAction,
    ITrialCoordCentreCreateSuccessAction,
    ITrialCoordCentreCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/trialCoordCentres";
import { trialcoordcentresApi } from "../services/trialCoordCentre";


export const
    getTrialCoordCentresEpic = (action$): Observable<ITrialCoordCentreLoadSuccessAction | ITrialCoordCentreLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALCOORDCENTRES_LOAD)
            .mergeMap((action: ITrialCoordCentreLoadAction) => {
                return trialcoordcentresApi
                    .getTrialCoordCentres()
                    .map(response =>
                        LoadSuccess(response.trialCoordCentres, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTrialCoordCentreByIdEpic = (action$): Observable<ITrialCoordCentreLoadSuccessAction | ITrialCoordCentreLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALCOORDCENTRES_LOAD_BY_ID)
            .mergeMap((action: ITrialCoordCentreLoadByIdAction) => {
                return trialcoordcentresApi
                    .getTrialCoordCentreById(action.id)
                    .map(response =>
                        LoadSuccess(response.trialCoordCentre, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setTrialCoordCentreEpic = (action$): Observable<ITrialCoordCentreSaveSuccessAction | ITrialCoordCentreSaveFailureAction> => {
        return action$
            .ofType(ACTION_TRIALCOORDCENTRES_SAVE)
            .mergeMap((action: ITrialCoordCentreSaveAction) => {
                return trialcoordcentresApi
                    .setTrialCoordCentre(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.trialCoordCentre, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createTrialCoordCentreEpic = (action$): Observable<ITrialCoordCentreCreateSuccessAction | ITrialCoordCentreCreateFailureAction> => {
        return action$
            .ofType(ACTION_TRIALCOORDCENTRES_CREATE)
            .mergeMap((action: ITrialCoordCentreCreateAction) => {
                return trialcoordcentresApi
                    .createTrialCoordCentre()
                    .map(response =>
                        CreateSuccess(
                            response.trialCoordCentre,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



