/* Options:
Date: 2024-12-10 09:02:00
Version: 5.132
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://localhost:15231

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IGet
{
}

export interface IPost
{
}

export enum CalculationMethod
{
    EitherSideOfBaseline = 1,
    AbsoluteValues = 2,
}

export class ItemComponentType implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ItemComponentType>) { (Object as any).assign(this, init); }
}

export enum ItemPrimitive
{
    Label = 1,
    Text = 2,
    Comments = 3,
    Date = 4,
    Checkbox = 5,
    Documents = 6,
    Numeric = 7,
    Select = 8,
}

export class ItemComponentPrimitiveOption implements ISubForm
{
    public id: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.ItemComponentPrimitive)")
    public itemComponentPrimitiveId: number;

    public value: string;
    public active: boolean;
    public rank: number;
    public measurement: number;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ItemComponentPrimitiveOption>) { (Object as any).assign(this, init); }
}

export enum ItemPrimitiveRule
{
    IsEnabledIf = 1,
    IsDisabledIf = 2,
    RequiredIf = 3,
    Required = 4,
}

export class ItemComponentRule implements ISubForm
{
    public id: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.ItemComponent)")
    public itemComponentId: number;

    public itemComponentPrimitiveId: number;
    public itemPrimitiveRule: ItemPrimitiveRule;
    public compareItemComponentPrimitiveId: number;
    // @Ignore()
    public compareItemComponentPrimitiveColumn: number;

    // @Ignore()
    public compareItemComponentPrimitiveIndexInColumn: number;

    public compareValue: string;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ItemComponentRule>) { (Object as any).assign(this, init); }
}

export class ItemComponentPrimitive implements ISubForm
{
    public id: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.ItemComponent)")
    public itemComponentId: number;

    public itemComponent: ItemComponent;
    public itemPrimitive: ItemPrimitive;
    public label: string;
    public active: boolean;
    public rank: number;
    public measure: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    public itemComponentPrimitiveOptions: ItemComponentPrimitiveOption[];
    // @Ignore()
    public itemComponentRules: ItemComponentRule[];

    // @Ignore()
    public isQuestion: boolean;

    public constructor(init?: Partial<ItemComponentPrimitive>) { (Object as any).assign(this, init); }
}

export enum FileState
{
    Uploading = 1,
    Success = 2,
    Failure = 3,
    Cancelled = 4,
    Empty = 5,
}

export class TemporaryFile
{
    public id: string;
    public name: string;
    public contentType: string;
    public extension: string;
    public fileSize?: number;
    public image?: boolean;
    public dateUploaded?: string;

    public constructor(init?: Partial<TemporaryFile>) { (Object as any).assign(this, init); }
}

export class TemporaryFileInformation
{
    public state: FileState;
    public progress: string;
    public guid: string;
    public temporaryFile: TemporaryFile;

    public constructor(init?: Partial<TemporaryFileInformation>) { (Object as any).assign(this, init); }
}

export class SourceDocument implements IAudited
{
    public id?: number;
    public formName: string;
    public recordId?: number;
    public subRecordId?: number;
    public fileType?: number;
    public name: string;
    public extension: string;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    // @Ignore()
    public image: boolean;

    // @Ignore()
    public fileInformation: TemporaryFileInformation;

    // @Ignore()
    public guid: string;

    // @Ignore()
    public fileSize: number;

    // @Ignore()
    public contentType: string;

    // @Ignore()
    public exists: boolean;

    public constructor(init?: Partial<SourceDocument>) { (Object as any).assign(this, init); }
}

export class ItemComponent implements IForm, IHasSourceDocuments
{
    public id?: number;
    public name: string;
    public isSystem: boolean;
    public active: boolean;
    public rank: number;
    public customInputControlName: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.ItemComponentType)")
    public itemComponentTypeId?: number;

    public itemComponentType: ItemComponentType;
    public description: string;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    public itemComponentPrimitives: ItemComponentPrimitive[];
    public itemComponentRules: ItemComponentRule[];
    // @Ignore()
    public sourceDocuments: SourceDocument[];

    public constructor(init?: Partial<ItemComponent>) { (Object as any).assign(this, init); }
}

export class CollectionSection implements ISubForm
{
    public id: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Collection)")
    public collectionId?: number;

    public name: string;
    public rank: number;
    public canAssign: boolean;
    public isNaAllowed: boolean;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CollectionSection>) { (Object as any).assign(this, init); }
}

export class ReportingTagCategory implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ReportingTagCategory>) { (Object as any).assign(this, init); }
}

export class ReportingTag implements IForm
{
    public id?: number;
    public value: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.ReportingTagCategory)")
    public reportingTagCategoryId?: number;

    public reportingTagCategory: ReportingTagCategory;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ReportingTag>) { (Object as any).assign(this, init); }
}

export class CollectionItemComponent implements ISubForm
{
    public id: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Collection)")
    public collectionId?: number;

    // @References("typeof(TMD.ServiceModel.Types.Do.ItemComponent)")
    public itemComponentId: number;

    // @References("typeof(TMD.ServiceModel.Types.Do.CollectionSection)")
    public collectionSectionId: number;

    public active: boolean;
    public rank: number;
    public column: number;
    public isNaAllowed: boolean;
    public isRequired: boolean;
    public instructions: string;
    public overrideLabel: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.ReportingTag)")
    public reportingTagId: number;

    public baselineAmount: number;
    public minValue?: number;
    public maxValue?: number;
    public calculationMethod: CalculationMethod;
    public calculation1CollectionItemComponentId?: number;
    public calculation2CollectionItemComponentId?: number;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    public itemComponent: ItemComponent;
    public collectionSection: CollectionSection;
    public reportingTag: ReportingTag;
    // @Ignore()
    public isLocked: boolean;

    public constructor(init?: Partial<CollectionItemComponent>) { (Object as any).assign(this, init); }
}

export class Collection implements IForm
{
    public id?: number;
    public locked: boolean;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    public collectionItemComponents: CollectionItemComponent[];
    public collectionSections: CollectionSection[];

    public constructor(init?: Partial<Collection>) { (Object as any).assign(this, init); }
}

export class CollectionInstanceData implements ISubForm
{
    public id: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.CollectionInstance)")
    public collectionInstanceId: number;

    // @References("typeof(TMD.ServiceModel.Types.Do.CollectionItemComponent)")
    public collectionItemComponentId: number;

    public collectionItemComponent: CollectionItemComponent;
    // @References("typeof(TMD.ServiceModel.Types.Do.ItemComponent)")
    public itemComponentId: number;

    public itemComponent: ItemComponent;
    // @References("typeof(TMD.ServiceModel.Types.Do.ItemComponentPrimitive)")
    public itemComponentPrimitiveId: number;

    public itemComponentPrimitive: ItemComponentPrimitive;
    public textValue: string;
    public bitValue: boolean;
    public numberValue: number;
    public intValue: number;
    public dateValue?: string;
    public isNa: boolean;
    public version: string;
    public justified: boolean;
    public dateJustified?: string;
    public justifiedBy: string;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    // @Ignore()
    public isModified: boolean;

    public constructor(init?: Partial<CollectionInstanceData>) { (Object as any).assign(this, init); }
}

export class ItemComponentWithData
{
    public itemComponent: ItemComponent;
    public collectionSectionId: number;
    public isNaAllowed: boolean;
    public isRequired: boolean;
    public isFirstPrimitive: boolean;
    public isLastPrimitive: boolean;
    public instructions: string;
    public overrideLabel: string;
    public isQuestion: boolean;
    public isAnswered: boolean;
    public formLocked: boolean;
    public isNotApplicable: boolean;
    public itemComponentRules: ItemComponentRule[];
    public itemComponentPrimitive: ItemComponentPrimitive;
    public collectionInstanceData: CollectionInstanceData;

    public constructor(init?: Partial<ItemComponentWithData>) { (Object as any).assign(this, init); }
}

export class CollectionInstance implements IForm, IHasSourceDocuments
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Collection)")
    public collectionId?: number;

    public collection: Collection;
    public dataEntered: boolean;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    public collectionInstanceDatas: CollectionInstanceData[];
    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public itemComponentRules: ItemComponentRule[];

    // @Ignore()
    public columnFormat: string;

    // @Ignore()
    public column1: ItemComponentWithData[];

    // @Ignore()
    public column2: ItemComponentWithData[];

    // @Ignore()
    public column3: ItemComponentWithData[];

    // @Ignore()
    public documents: ItemComponentWithData[];

    public sections: CollectionInstanceSection[];
    // @Ignore()
    public isModified: boolean;

    // @Ignore()
    public hasSections: boolean;

    public constructor(init?: Partial<CollectionInstance>) { (Object as any).assign(this, init); }
}

export class CollectionInstanceSection implements ISubForm
{
    public id: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.CollectionInstance)")
    public collectionInstanceId?: number;

    public collectionInstance: CollectionInstance;
    // @References("typeof(TMD.ServiceModel.Types.Do.CollectionSection)")
    public collectionSectionId?: number;

    public collectionSection: CollectionSection;
    public isNa: boolean;
    public assignedToId?: number;
    public assignedRoleId?: number;
    public assignmentTypeId?: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    // @Ignore()
    public isDefault: boolean;

    // @Ignore()
    public columnFormat: string;

    // @Ignore()
    public expanded: boolean;

    // @Ignore()
    public locked: boolean;

    // @Ignore()
    public formLocked: boolean;

    // @Ignore()
    public column1: ItemComponentWithData[];

    // @Ignore()
    public column2: ItemComponentWithData[];

    // @Ignore()
    public column3: ItemComponentWithData[];

    // @Ignore()
    public documents: ItemComponentWithData[];

    // @Ignore()
    public isMissingRequiredData: boolean;

    // @Ignore()
    public summary: string;

    public constructor(init?: Partial<CollectionInstanceSection>) { (Object as any).assign(this, init); }
}

export class InstitutionType implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<InstitutionType>) { (Object as any).assign(this, init); }
}

export class State implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<State>) { (Object as any).assign(this, init); }
}

export class Country implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Country>) { (Object as any).assign(this, init); }
}

export class SiteLocation implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SiteLocation>) { (Object as any).assign(this, init); }
}

export class FacilityAllianceMember implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<FacilityAllianceMember>) { (Object as any).assign(this, init); }
}

export class KeyContact implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<KeyContact>) { (Object as any).assign(this, init); }
}

export class TrialCategory implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public validationFormat: string;
    public validationMessage: string;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialCategory>) { (Object as any).assign(this, init); }
}

export class Salutation implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Salutation>) { (Object as any).assign(this, init); }
}

export class Gender implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Gender>) { (Object as any).assign(this, init); }
}

export class MembershipType implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MembershipType>) { (Object as any).assign(this, init); }
}

export class ProfessionalDiscipline implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ProfessionalDiscipline>) { (Object as any).assign(this, init); }
}

export enum PersonnelRoleSummaryType
{
    General = 1,
    Group = 2,
    MasterGroup = 3,
    Institution = 4,
    Trial = 5,
    TrialCoordinatingGroup = 6,
    TrialInstitution = 7,
    Role = 8,
    Department = 9,
}

export class RolePermissionMatrix
{
    public roleGroupId: number;
    public institutionMatch: boolean;
    public configurationDescription: string;
    public moduleName: string;
    public moduleDescription: string;
    public roleId: number;
    public roleName: string;
    public permissionName: string;
    public permissionDescription: string;

    public constructor(init?: Partial<RolePermissionMatrix>) { (Object as any).assign(this, init); }
}

export class GroupedRolePermission
{
    public moduleName: string;
    public moduleDescription: string;
    public permissions: RolePermissionMatrix[];

    public constructor(init?: Partial<GroupedRolePermission>) { (Object as any).assign(this, init); }
}

export class Role implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public description: string;
    public openClinciaRoleId?: number;
    public openClinciaInstitutionRoleId?: number;
    public saeRoleId?: number;
    public drugManagementAccess: boolean;
    public opmsAccess: boolean;
    public isSystem: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    // @Ignore()
    public modules: { [index: string]: string; };

    // @Ignore()
    public groupedRolePermissions: GroupedRolePermission[];

    public constructor(init?: Partial<Role>) { (Object as any).assign(this, init); }
}

export class GroupType implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<GroupType>) { (Object as any).assign(this, init); }
}

export class AddressOption implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AddressOption>) { (Object as any).assign(this, init); }
}

export class TrialInstitutionItemTemplate implements IForm
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    public name: string;
    public description: string;
    public rank: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Category)")
    public categoryId: number;

    public category: Category;
    public auditOnSave: boolean;
    public allowVersions: boolean;
    public allowVersionChanges: boolean;
    public initialVersion: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.Collection)")
    public collectionId?: number;

    public collection: Collection;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialInstitutionItemTemplate>) { (Object as any).assign(this, init); }
}

export class Category implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    // @Ignore()
    public trialInstitutionItemTemplates: TrialInstitutionItemTemplate[];

    public constructor(init?: Partial<Category>) { (Object as any).assign(this, init); }
}

export class GroupItem implements IForm
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Group)")
    public groupId?: number;

    public name: string;
    public description: string;
    public rank: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Category)")
    public categoryId: number;

    public category: Category;
    public allowIntraVersions: boolean;
    public pendingDelete: boolean;
    public allowVersionChanges: boolean;
    public initialVersion: string;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.Collection)")
    public collectionId?: number;

    public collection: Collection;

    public constructor(init?: Partial<GroupItem>) { (Object as any).assign(this, init); }
}

export class GroupItemSummaryVersion
{
    public trialGroupItemId?: number;
    public version: string;
    public collectionInstanceId?: number;

    public constructor(init?: Partial<GroupItemSummaryVersion>) { (Object as any).assign(this, init); }
}

export class GroupItemSummary
{
    public id?: number;
    public name: string;
    public description: string;
    public allowVersions: boolean;
    public version: string;
    public initialVersion: string;
    public rank: number;
    public active: boolean;
    public trialGroupId?: number;
    public groupItemId?: number;
    public trialGroupItemId?: number;
    public collectionInstanceId?: number;
    public dataEntered: boolean;
    public groupItemSummaryVersions: GroupItemSummaryVersion[];

    public constructor(init?: Partial<GroupItemSummary>) { (Object as any).assign(this, init); }
}

export class GroupItemCategory
{
    public categoryId: number;
    public category: Category;
    public groupItemSummaries: GroupItemSummary[];

    public constructor(init?: Partial<GroupItemCategory>) { (Object as any).assign(this, init); }
}

export class NoteType implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<NoteType>) { (Object as any).assign(this, init); }
}

export class PersonnelNote implements IForm
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Personnel)")
    public personnelId?: number;

    public personnel: Personnel;
    // @References("typeof(TMD.ServiceModel.Types.Do.Note)")
    public noteId?: number;

    public note: Note;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PersonnelNote>) { (Object as any).assign(this, init); }
}

export class TrialNote implements IForm
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    public trial: Trial;
    // @References("typeof(TMD.ServiceModel.Types.Do.Note)")
    public noteId?: number;

    public note: Note;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialNote>) { (Object as any).assign(this, init); }
}

export class InstitutionNote implements IForm
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Institution)")
    public institutionId?: number;

    public institution: Institution;
    // @References("typeof(TMD.ServiceModel.Types.Do.Note)")
    public noteId?: number;

    public note: Note;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<InstitutionNote>) { (Object as any).assign(this, init); }
}

export class TrialInstitutionNote implements IForm
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.TrialInstitution)")
    public trialInstitutionId?: number;

    public trialInstitution: TrialInstitution;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    public trial: Trial;
    // @References("typeof(TMD.ServiceModel.Types.Do.Institution)")
    public institutionId?: number;

    public institution: Institution;
    // @References("typeof(TMD.ServiceModel.Types.Do.Note)")
    public noteId?: number;

    public note: Note;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialInstitutionNote>) { (Object as any).assign(this, init); }
}

export class GroupNote implements IForm
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Group)")
    public groupId?: number;

    public group: Group;
    // @References("typeof(TMD.ServiceModel.Types.Do.Note)")
    public noteId?: number;

    public note: Note;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<GroupNote>) { (Object as any).assign(this, init); }
}

export class Note implements IForm
{
    public id?: number;
    public title: string;
    public comments: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.NoteType)")
    public noteTypeId?: number;

    public noteType: NoteType;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    public personnelNotes: PersonnelNote[];
    public trialNotes: TrialNote[];
    public institutionNotes: InstitutionNote[];
    public trialInstitutionNotes: TrialInstitutionNote[];
    public groupNotes: GroupNote[];
    // @Ignore()
    public trialInstitutionId?: number;

    // @Ignore()
    public trialId?: number;

    // @Ignore()
    public institutionId?: number;

    // @Ignore()
    public personnelId?: number;

    // @Ignore()
    public groupId?: number;

    // @Ignore()
    public parentDisplay: string;

    // @Ignore()
    public trialDisplay: string;

    // @Ignore()
    public institutionDisplay: string;

    // @Ignore()
    public groupDisplay: string;

    public constructor(init?: Partial<Note>) { (Object as any).assign(this, init); }
}

export class NoteTypeNotes
{
    public noteTypeId: number;
    public noteType: NoteType;
    public notes: Note[];

    public constructor(init?: Partial<NoteTypeNotes>) { (Object as any).assign(this, init); }
}

export class GroupTrialSummary
{
    public trialId: number;
    public trialDisplay: string;
    public trialInstitutionId: number;
    public institutionId: number;
    public institutionDisplay: string;

    public constructor(init?: Partial<GroupTrialSummary>) { (Object as any).assign(this, init); }
}

export class Group implements IForm
{
    public id?: number;
    public name: string;
    public otherNames: string;
    public code: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.GroupType)")
    public groupTypeId?: number;

    public groupType: GroupType;
    public groupTypeOther: string;
    public rank: number;
    public address: string;
    public address2: string;
    public suburb: string;
    public postcode: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.State)")
    public stateId?: number;

    public state: State;
    public stateOther: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.Country)")
    public countryId?: number;

    public country: Country;
    public mailAddress: string;
    public mailAddress2: string;
    public mailSuburb: string;
    public mailPostcode: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.State)")
    public mailStateId?: number;

    public mailState: State;
    public mailStateOther: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.Country)")
    public mailCountryId?: number;

    public mailCountry: Country;
    public phone: string;
    public fax: string;
    public website: string;
    public legalName: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.AddressOption)")
    public agreementAddressOptionId?: number;

    public addressOption: AddressOption;
    public contractingPartyAddress: string;
    public contractingPartyAddress2: string;
    public contractingPartySuburb: string;
    public contractingPartyPostcode: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.State)")
    public contractingPartyStateId?: number;

    public contractingPartyState: State;
    public contractingPartyStateOther: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.Country)")
    public contractingPartyCountryId?: number;

    public contractingPartyCountry: Country;
    public abn: string;
    public acn: string;
    public contactNotice: string;
    public emailNotice: string;
    public phoneNotice: string;
    public faxNotice: string;
    public acceptElectronicSignature?: number;
    public isDefault: boolean;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    public groupItems: GroupItem[];
    // @Ignore()
    public groupItemCategories: GroupItemCategory[];

    // @Ignore()
    public personnelRoleSummaries: PersonnelRoleSummary[];

    // @Ignore()
    public notes: NoteTypeNotes[];

    // @Ignore()
    public groupTrialSummaries: GroupTrialSummary[];

    public constructor(init?: Partial<Group>) { (Object as any).assign(this, init); }
}

export class PersonnelRole implements IForm
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Personnel)")
    public personnelId?: number;

    public personnel: Personnel;
    // @References("typeof(TMD.ServiceModel.Types.Do.Role)")
    public roleId?: number;

    // @Ignore()
    public originalRoleId?: number;

    public role: Role;
    // @References("typeof(TMD.ServiceModel.Types.Do.Group)")
    public groupId?: number;

    public group: Group;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    public trial: Trial;
    // @References("typeof(TMD.ServiceModel.Types.Do.Institution)")
    public institutionId?: number;

    public institution: Institution;
    public dateStarted?: string;
    public dateCeased?: string;
    public noEmailCommunication: boolean;
    public emailSent: boolean;
    // @References("typeof(TMD.ServiceModel.Types.Do.Department)")
    public departmentId?: number;

    public department: Department;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    // @Ignore()
    public dateFrom: string;

    // @Ignore()
    public dateTo: string;

    // @Ignore()
    public personnelRoleSummaryType: PersonnelRoleSummaryType;

    // @Ignore()
    public groupDisplay: string;

    // @Ignore()
    public trialDisplay: string;

    // @Ignore()
    public institutionDisplay: string;

    // @Ignore()
    public personnelDisplay: string;

    // @Ignore()
    public emailDisplay: string;

    // @Ignore()
    public roleDisplay: string;

    // @Ignore()
    public emailNotSentReason: string;

    // @Ignore()
    public emailNotSentReasonExceptActiveDirectory: string;

    // @Ignore()
    public createNewPersonnel: boolean;

    // @Ignore()
    public salutationId?: number;

    // @Ignore()
    public firstName: string;

    // @Ignore()
    public lastName: string;

    // @Ignore()
    public email: string;

    // @Ignore()
    public userName: string;

    // @Ignore()
    public hasActiveDirectoryEntry: boolean;

    // @Ignore()
    public createNewDepartment: boolean;

    // @Ignore()
    public newDepartment: string;

    // @Ignore()
    public createNewInstitution: boolean;

    // @Ignore()
    public institutionName: string;

    // @Ignore()
    public institutionCode: string;

    public constructor(init?: Partial<PersonnelRole>) { (Object as any).assign(this, init); }
}

export class PersonnelRoleSummary
{
    public personnelRoleSummaryType: PersonnelRoleSummaryType;
    public display: string;
    public personnelRole: PersonnelRole;
    public personnelRoleSummaries: PersonnelRoleSummary[];

    public constructor(init?: Partial<PersonnelRoleSummary>) { (Object as any).assign(this, init); }
}

export enum OCSyncStatus
{
    Pending = 1,
    Complete = 2,
    Processing = 3,
    Failed = 4,
    UpdateRequired = 5,
    RemoveRequested = 6,
    Removed = 7,
}

export class OpenClinicaUserRole implements IForm
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Personnel)")
    public personnelId: number;

    public personnel: Personnel;
    // @References("typeof(TMD.ServiceModel.Types.Do.PersonnelRole)")
    public personnelRoleId: number;

    public personnelRole: PersonnelRole;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId: number;

    public trial: Trial;
    // @References("typeof(TMD.ServiceModel.Types.Do.Institution)")
    public institutionId?: number;

    public institution: Institution;
    public studyId: number;
    public parentStudyId?: number;
    public studyIdentifier: string;
    public role: string;
    public ocSyncStatus: OCSyncStatus;
    // @Ignore()
    public status: string;

    public errorMessage: string;
    public emailSent: boolean;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    // @Ignore()
    public search: string;

    public constructor(init?: Partial<OpenClinicaUserRole>) { (Object as any).assign(this, init); }
}

export class Interest implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Interest>) { (Object as any).assign(this, init); }
}

export class PersonnelInterest
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Personnel)")
    public personnelId?: number;

    public personnel: Personnel;
    // @References("typeof(TMD.ServiceModel.Types.Do.Interest)")
    public interestId: number;

    public interest: Interest;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PersonnelInterest>) { (Object as any).assign(this, init); }
}

export class Personnel implements IForm, IHasSourceDocuments
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Salutation)")
    public salutationId?: number;

    public salutation: Salutation;
    public firstName: string;
    public lastName: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.Gender)")
    public genderId?: number;

    public gender: Gender;
    public email: string;
    public secondaryEmail: string;
    public userName: string;
    public emailSent: boolean;
    public jobTitle: string;
    public phone: string;
    public mobile: string;
    public residentialPostcode: string;
    public activeDirectoryUser: string;
    // @Ignore()
    public activeDirectoryDisabled: boolean;

    public cvDate?: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.MembershipType)")
    public membershipTypeId?: number;

    public membershipType: MembershipType;
    public membershipExpiryDate?: string;
    public comments: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.ProfessionalDiscipline)")
    public professionalDisciplineId?: number;

    public professionalDiscipline: ProfessionalDiscipline;
    // @Ignore()
    public professionalDisciplineDisplay: string;

    public otherInterests: string;
    public otherDisciplines: string;
    public companyName: string;
    public companyDepartment: string;
    public workAddress: string;
    public workAddress2: string;
    public workSuburb: string;
    public workPostcode: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.State)")
    public workStateId?: number;

    public workState: State;
    public workStateOther: string;
    public workCountry: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.Country)")
    public workCountryId?: number;

    public country: Country;
    public active: boolean;
    public apiKey: string;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    // @Ignore()
    public autogeneratePassword: boolean;

    // @Ignore()
    public personnelRoleSummaries: PersonnelRoleSummary[];

    // @Ignore()
    public openClinicaUserRoles: OpenClinicaUserRole[];

    // @Ignore()
    public notes: NoteTypeNotes[];

    // @Ignore()
    public display: string;

    // @Ignore()
    public displayWithSalutation: string;

    // @Ignore()
    public hasActiveDirectoryEntry: boolean;

    // @Ignore()
    public password: string;

    // @Ignore()
    public confirmPassword: string;

    public personnelRoles: PersonnelRole[];
    public personnelInterests: PersonnelInterest[];
    // @Ignore()
    public sourceDocuments: SourceDocument[];

    public constructor(init?: Partial<Personnel>) { (Object as any).assign(this, init); }
}

export class TrialCoordCentre implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialCoordCentre>) { (Object as any).assign(this, init); }
}

export class InstitutionCoding implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<InstitutionCoding>) { (Object as any).assign(this, init); }
}

export class TrialType implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialType>) { (Object as any).assign(this, init); }
}

export class TrialStatus implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialStatus>) { (Object as any).assign(this, init); }
}

export class InternationalTrialStatus implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<InternationalTrialStatus>) { (Object as any).assign(this, init); }
}

export class StandardEmailType implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<StandardEmailType>) { (Object as any).assign(this, init); }
}

export class StandardEmailTemplate implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.StandardEmailType)")
    public standardEmailTypeId?: number;

    public standardEmailType: StandardEmailType;
    public subject: string;
    public body: string;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<StandardEmailTemplate>) { (Object as any).assign(this, init); }
}

export class TrialItemGroup
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.TrialItem)")
    public trialItemId?: number;

    // @References("typeof(TMD.ServiceModel.Types.Do.Group)")
    public groupId: number;

    public group: Group;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialItemGroup>) { (Object as any).assign(this, init); }
}

export class TrialItemInstitution
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.TrialItem)")
    public trialItemId?: number;

    // @References("typeof(TMD.ServiceModel.Types.Do.Institution)")
    public institutionId: number;

    public institution: Institution;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialItemInstitution>) { (Object as any).assign(this, init); }
}

export class TrialItem implements IForm
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    public name: string;
    public description: string;
    public rank: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Category)")
    public categoryId: number;

    public category: Category;
    public allowIntraVersions: boolean;
    public pendingDelete: boolean;
    public allowVersionChanges: boolean;
    public initialVersion: string;
    public sortOrderId?: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.Collection)")
    public collectionId?: number;

    public collection: Collection;
    public trialItemGroups: TrialItemGroup[];
    public trialItemInstitutions: TrialItemInstitution[];
    // @Ignore()
    public hasGroupsOrInstitutions: boolean;

    public constructor(init?: Partial<TrialItem>) { (Object as any).assign(this, init); }
}

export class TrialGroupItem implements IForm
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.TrialGroup)")
    public trialGroupId?: number;

    public trialGroup: TrialGroup;
    // @References("typeof(TMD.ServiceModel.Types.Do.GroupItem)")
    public groupItemId?: number;

    public groupItem: GroupItem;
    public version: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.CollectionInstance)")
    public collectionInstanceId?: number;

    public collectionInstance: CollectionInstance;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialGroupItem>) { (Object as any).assign(this, init); }
}

export class TrialGroup implements IForm
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    // @References("typeof(TMD.ServiceModel.Types.Do.Group)")
    public groupId: number;

    public group: Group;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    // @Ignore()
    public groupItemCategories: GroupItemCategory[];

    public trialGroupItems: TrialGroupItem[];

    public constructor(init?: Partial<TrialGroup>) { (Object as any).assign(this, init); }
}

export class TrialTreatment implements IForm
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    public name: string;
    public shortName: string;
    public duration: number;
    public targetAccrual: number;
    public opmsId: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialTreatment>) { (Object as any).assign(this, init); }
}

export class TrialRegistry implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialRegistry>) { (Object as any).assign(this, init); }
}

export class TrialProjectRegistration implements ISubForm
{
    public id: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    // @References("typeof(TMD.ServiceModel.Types.Do.TrialRegistry)")
    public trialRegistryId?: number;

    public trialRegistryOther: string;
    public registrationNumber: string;
    public url: string;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    public trial: Trial;
    public trialRegistry: TrialRegistry;

    public constructor(init?: Partial<TrialProjectRegistration>) { (Object as any).assign(this, init); }
}

export class TrialAnalysisType implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialAnalysisType>) { (Object as any).assign(this, init); }
}

export class TrialAnalysisResult implements ISubForm
{
    public id: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    // @References("typeof(TMD.ServiceModel.Types.Do.TrialAnalysisType)")
    public trialAnalysisTypeId?: number;

    public trialAnalysisTypeOther: string;
    public targetStartDate?: string;
    public actualStartDate?: string;
    public targetCompletionDate?: string;
    public actualCompletionDate?: string;
    public trialPublicationId?: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    public trial: Trial;
    public trialAnalysisType: TrialAnalysisType;

    public constructor(init?: Partial<TrialAnalysisResult>) { (Object as any).assign(this, init); }
}

export class FundingStatus implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<FundingStatus>) { (Object as any).assign(this, init); }
}

export class TrialFunding implements ISubForm
{
    public id: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    public name: string;
    public source: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.FundingStatus)")
    public fundingStatusId?: number;

    public submissionDate?: string;
    public startDate?: string;
    public endDate?: string;
    public amountRecieved?: number;
    public amountRequested?: number;
    public currency: string;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    public trial: Trial;
    public fundingStatus: FundingStatus;

    public constructor(init?: Partial<TrialFunding>) { (Object as any).assign(this, init); }
}

export class TrialChair implements ISubForm
{
    public id: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    // @References("typeof(TMD.ServiceModel.Types.Do.Personnel)")
    public personnelId?: number;

    public isPrimary: boolean;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    public trial: Trial;
    public personnel: Personnel;

    public constructor(init?: Partial<TrialChair>) { (Object as any).assign(this, init); }
}

export class TrialPresentationType implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialPresentationType>) { (Object as any).assign(this, init); }
}

export class TrialPresentation implements ISubForm
{
    public id: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    // @References("typeof(TMD.ServiceModel.Types.Do.TrialPresentationType)")
    public trialPresentationTypeId?: number;

    public title: string;
    public presenterName: string;
    public meeting: string;
    public meetingDate?: string;
    public publishedAsAnAbstractId?: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    public trial: Trial;
    public trialPresentationType: TrialPresentationType;

    public constructor(init?: Partial<TrialPresentation>) { (Object as any).assign(this, init); }
}

export class TrialPublicationType implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialPublicationType>) { (Object as any).assign(this, init); }
}

export class TrialPublicationStatus implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialPublicationStatus>) { (Object as any).assign(this, init); }
}

export class TrialPublication implements ISubForm
{
    public id: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    // @References("typeof(TMD.ServiceModel.Types.Do.TrialPublicationType)")
    public trialPublicationTypeId?: number;

    public trialPublicationTypeOther: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.TrialPublicationStatus)")
    public trialPublicationStatusId?: number;

    public details: string;
    public tpcReviewedId?: number;
    public yearPublished?: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    public trial: Trial;
    public trialPublicationType: TrialPublicationType;
    public trialPublicationStatus: TrialPublicationStatus;

    public constructor(init?: Partial<TrialPublication>) { (Object as any).assign(this, init); }
}

export class TrialCollaboratingGroup
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    public trial: Trial;
    // @References("typeof(TMD.ServiceModel.Types.Do.Group)")
    public collaboratingGroupId: number;

    public collaboratingGroup: Group;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialCollaboratingGroup>) { (Object as any).assign(this, init); }
}

export class TumourGroup implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TumourGroup>) { (Object as any).assign(this, init); }
}

export class TrialTumourGroup
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    public trial: Trial;
    // @References("typeof(TMD.ServiceModel.Types.Do.TumourGroup)")
    public tumourGroupId: number;

    public tumourGroup: TumourGroup;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialTumourGroup>) { (Object as any).assign(this, init); }
}

export class AllocatedWP implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AllocatedWP>) { (Object as any).assign(this, init); }
}

export class TrialAllocatedWP
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    public trial: Trial;
    // @References("typeof(TMD.ServiceModel.Types.Do.AllocatedWP)")
    public allocatedWPId: number;

    public allocatedWP: AllocatedWP;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialAllocatedWP>) { (Object as any).assign(this, init); }
}

export class TrialPhase implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialPhase>) { (Object as any).assign(this, init); }
}

export class TrialTrialPhase
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    public trial: Trial;
    // @References("typeof(TMD.ServiceModel.Types.Do.TrialPhase)")
    public trialPhaseId: number;

    public trialPhase: TrialPhase;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialTrialPhase>) { (Object as any).assign(this, init); }
}

export class Involvement implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Involvement>) { (Object as any).assign(this, init); }
}

export class TrialInvolvement
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    public trial: Trial;
    // @References("typeof(TMD.ServiceModel.Types.Do.Involvement)")
    public involvementId: number;

    public involvement: Involvement;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialInvolvement>) { (Object as any).assign(this, init); }
}

export class TrialItemSummaryVersion
{
    public trialGroupItemId?: number;
    public version: string;
    public collectionInstanceId?: number;
    public dateEntered?: string;

    public constructor(init?: Partial<TrialItemSummaryVersion>) { (Object as any).assign(this, init); }
}

export class TrialItemSummary
{
    public id?: number;
    public name: string;
    public description: string;
    public groups: Group[];
    public institutions: Institution[];
    public groupDisplay: string;
    public institutionDisplay: string;
    public allowVersions: boolean;
    public initialVersion: string;
    public version: string;
    public rank: number;
    public active: boolean;
    public trialInstitutionId?: number;
    public trialItemId?: number;
    public trialInstitutionItemId?: number;
    public collectionInstanceId?: number;
    public dataEntered: boolean;
    public trialItemSummaryVersions: TrialItemSummaryVersion[];

    public constructor(init?: Partial<TrialItemSummary>) { (Object as any).assign(this, init); }
}

export class TrialItemCategory
{
    public categoryId: number;
    public category: Category;
    public trialItemSummaries: TrialItemSummary[];

    public constructor(init?: Partial<TrialItemCategory>) { (Object as any).assign(this, init); }
}

export class Trial implements IForm
{
    public id?: number;
    public protocolID: string;
    public newProposalNumber: string;
    public trialDevelopmentNumber: string;
    public allIdentifiers: string;
    public studyAcronym: string;
    public title: string;
    public shortTitle: string;
    public laySummary: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.TrialCategory)")
    public trialCategoryId?: number;

    public trialCategory: TrialCategory;
    // @References("typeof(TMD.ServiceModel.Types.Do.Personnel)")
    public trialChair1PersonnelId?: number;

    public trialChair1Personnel: Personnel;
    // @References("typeof(TMD.ServiceModel.Types.Do.Personnel)")
    public trialChair2PersonnelId?: number;

    public trialChair2Personnel: Personnel;
    // @References("typeof(TMD.ServiceModel.Types.Do.TrialCoordCentre)")
    public trialCoordCentreId?: number;

    public trialCoordCentre: TrialCoordCentre;
    public active: boolean;
    public confidentiallyDeedRequired: boolean;
    // @References("typeof(TMD.ServiceModel.Types.Do.NoteType)")
    public institutionCodingId?: number;

    public institutionCoding: InstitutionCoding;
    // @References("typeof(TMD.ServiceModel.Types.Do.TrialType)")
    public trialTypeId?: number;

    public trialType: TrialType;
    public apiKey: string;
    public registrationWebsite: string;
    public openClinciaProtocolID: string;
    public patientDetailsCRF: string;
    public initialsCrfItemName: string;
    public treatmentCrfItemName: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.TrialStatus)")
    public trialStatusId?: number;

    public trialStatus: TrialStatus;
    // @References("typeof(TMD.ServiceModel.Types.Do.InternationalTrialStatus)")
    public internationalTrialStatusId?: number;

    public internationalTrialStatus: InternationalTrialStatus;
    public openClinciaInstitutionPrefix: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.Group)")
    public australianLeadGroupId?: number;

    public australianLeadGroup: Group;
    // @References("typeof(TMD.ServiceModel.Types.Do.Group)")
    public internationalLeadGroupId?: number;

    public internationalLeadGroup: Group;
    // @References("typeof(TMD.ServiceModel.Types.Do.Group)")
    public centralCoordinatingCentreGroupId?: number;

    public centralCoordinatingCentreGroup: Group;
    // @References("typeof(TMD.ServiceModel.Types.Do.Group)")
    public internationalCentralCoordinatingCentreGroupId?: number;

    public internationalCentralCoordinatingCentreGroup: Group;
    // @References("typeof(TMD.ServiceModel.Types.Do.Group)")
    public centralRTQACoordinatingCentreGroupId?: number;

    public centralRTQACoordinatingCentreGroup: Group;
    public isLegacyUserDatabase: boolean;
    public sendReminderEmail: boolean;
    public sendReminderAfterDays: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.StandardEmailTemplate)")
    public sendReminderStandardEmailTemplateId?: number;

    public sendReminderStandardEmailTemplate: StandardEmailTemplate;
    public dateTSCApprovedForDevelopment?: string;
    public dateTSCTROGNumberAllocated?: string;
    public dateTSCFinalProtocol?: string;
    public dateTSCHRECApprovalReveived?: string;
    public targetAccrual?: number;
    public totalAccrual?: number;
    public targetSitesTotal?: number;
    public actualSitesTotal?: number;
    public targetSitesAUS?: number;
    public actualSitesAUS?: number;
    public targetSitesNZ?: number;
    public actualSitesNZ?: number;
    public dateActivated?: string;
    public dateFirstPatient?: string;
    public targetDateAccrualCompleted?: string;
    public dateAccrualComplete?: string;
    public targetDateFollowupCompletion?: string;
    public dateStudyClosed?: string;
    public dateExpectedCompletion?: string;
    public dateCompletedWithdrawn?: string;
    public internationalTargetAccrual?: number;
    public internationalTotalAccrual?: number;
    public internationalTargetSites?: number;
    public internationalActualSites?: number;
    public internationalDateActivated?: string;
    public internationalDateCompletedWithdrawn?: string;
    public internationalDateAccrualCompleted?: string;
    public internationalTargetDateAccrualCompleted?: string;
    public internationalActualDateAccrualCompleted?: string;
    public internationalTargetDateFollowupCompletion?: string;
    public internationalActualDateFollowupCompletion?: string;
    public comments: string;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    public trialItems: TrialItem[];
    public trialGroups: TrialGroup[];
    public trialInstitutions: TrialInstitution[];
    public trialTreatments: TrialTreatment[];
    public trialProjectRegistrations: TrialProjectRegistration[];
    public trialAnalysisResults: TrialAnalysisResult[];
    public trialFundings: TrialFunding[];
    public trialChairs: TrialChair[];
    public trialPresentations: TrialPresentation[];
    public trialPublications: TrialPublication[];
    public trialCollaboratingGroups: TrialCollaboratingGroup[];
    public trialTumourGroups: TrialTumourGroup[];
    public tumourGroupOther: string;
    public trialAllocatedWPs: TrialAllocatedWP[];
    public trialTrialPhases: TrialTrialPhase[];
    public trialInvolvements: TrialInvolvement[];
    // @Ignore()
    public trialItemCategories: TrialItemCategory[];

    // @Ignore()
    public personnelRoleSummaries: PersonnelRoleSummary[];

    // @Ignore()
    public globalRoleSummaries: PersonnelRoleSummary[];

    // @Ignore()
    public notes: NoteTypeNotes[];

    // @Ignore()
    public display: string;

    // @Ignore()
    public displayName: string;

    // @Ignore()
    public displayNumber: string;

    // @Ignore()
    public shortDisplay: string;

    // @Ignore()
    public personnelCount: number;

    // @Ignore()
    public patientImportConfigured: boolean;

    public constructor(init?: Partial<Trial>) { (Object as any).assign(this, init); }
}

export class SiteType implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SiteType>) { (Object as any).assign(this, init); }
}

export class ActivitiesUndertaken implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ActivitiesUndertaken>) { (Object as any).assign(this, init); }
}

export class TrialInstitutionStatus implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialInstitutionStatus>) { (Object as any).assign(this, init); }
}

export class TrialInstitutionItem implements IForm
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.TrialInstitution)")
    public trialInstitutionId?: number;

    public trialInstitution: TrialInstitution;
    // @References("typeof(TMD.ServiceModel.Types.Do.TrialItem)")
    public trialItemId?: number;

    public trialItem: TrialItem;
    public version: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.CollectionInstance)")
    public collectionInstanceId?: number;

    public collectionInstance: CollectionInstance;
    public sortOrderId?: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialInstitutionItem>) { (Object as any).assign(this, init); }
}

export class TrialInstitution implements IForm
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    public trial: Trial;
    // @References("typeof(TMD.ServiceModel.Types.Do.Institution)")
    public institutionId?: number;

    public institution: Institution;
    // @References("typeof(TMD.ServiceModel.Types.Do.SiteType)")
    public siteTypeId?: number;

    public siteType: SiteType;
    // @References("typeof(TMD.ServiceModel.Types.Do.Institution)")
    public parentInstitutionId?: number;

    public parentInstitution: Institution;
    public parentInstitutionDescription: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.ActivitiesUndertaken)")
    public activitiesUndertakenId?: number;

    public activitiesUndertaken: ActivitiesUndertaken;
    public activitiesUndertakenOther: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.Group)")
    public groupId?: number;

    public group: Group;
    public active: boolean;
    // @References("typeof(TMD.ServiceModel.Types.Do.TrialInstitutionStatus)")
    public trialInstitutionStatusId?: number;

    public trialInstitutionStatus: TrialInstitutionStatus;
    public dateActivated?: string;
    public firstPatient?: string;
    public dateWithdrawn?: string;
    public randomisedPatients?: number;
    public disableRecruitment: boolean;
    public expectedAccrual?: number;
    public totalAccrual?: number;
    public rgoSubmissionDate?: string;
    public rgoApprovalDate?: string;
    public initationMeetingDate?: string;
    public closureDate?: string;
    public comments: string;
    public ccOnRegistration: string;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    // @Ignore()
    public trialItemCategories: TrialItemCategory[];

    public trialInstitutionItems: TrialInstitutionItem[];
    // @Ignore()
    public personnelRoleSummaries: PersonnelRoleSummary[];

    // @Ignore()
    public notes: NoteTypeNotes[];

    // @Ignore()
    public display: string;

    // @Ignore()
    public groupDisplay: string;

    // @Ignore()
    public isGroupLocked: boolean;

    // @Ignore()
    public trialDisplay: string;

    // @Ignore()
    public institutionDisplay: string;

    // @Ignore()
    public personnelCount: number;

    public constructor(init?: Partial<TrialInstitution>) { (Object as any).assign(this, init); }
}

export class Institution implements IForm, IHasSourceDocuments
{
    public id?: number;
    public name: string;
    public legalName: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.InstitutionType)")
    public institutionTypeId?: number;

    public institutionType: InstitutionType;
    public formerNames: string;
    public otherNames: string;
    public abn: string;
    public acn: string;
    public code: string;
    public active: boolean;
    public website: string;
    public address: string;
    public address2: string;
    public suburb: string;
    public postcode: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.State)")
    public stateId?: number;

    public state: State;
    public stateOther: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.Country)")
    public countryId?: number;

    public country: Country;
    public mailAddress: string;
    public mailAddress2: string;
    public mailSuburb: string;
    public mailPostcode: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.State)")
    public mailStateId?: number;

    public mailState: State;
    public mailStateOther: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.Country)")
    public mailCountryId?: number;

    public mailCountry: Country;
    public phone: string;
    public fax: string;
    public compactusNumber: string;
    public additionalInformation: string;
    public approvedForParticipation: boolean;
    // @References("typeof(TMD.ServiceModel.Types.Do.SiteLocation)")
    public siteLocationId?: number;

    public siteLocation: SiteLocation;
    public researchGovernanceOfficeName: string;
    public researchGovernanceOfficePhone: string;
    public researchGovernanceOfficeEmail: string;
    public researchGovernanceOfficeWebsite: string;
    public researchGovernanceOfficeIsNMAScheme?: number;
    public acceptElectronicSignature?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.FacilityAllianceMember)")
    public facilityAllianceMemberId?: number;

    public facilityAllianceMember: FacilityAllianceMember;
    // @References("typeof(TMD.ServiceModel.Types.Do.KeyContact)")
    public keyContactId?: number;

    public keyContact: KeyContact;
    public keyContactEmail: string;
    public agreementAddressOptionId?: number;
    public contractingPartyAddress: string;
    public contractingPartyAddress2: string;
    public contractingPartySuburb: string;
    public contractingPartyPostcode: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.State)")
    public contractingPartyStateId?: number;

    public contractingPartyState: State;
    public contractingPartyStateOther: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.Country)")
    public contractingPartyCountryId?: number;

    public contractingPartyCountry: Country;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    public trialInstitutions: TrialInstitution[];
    public departments: Department[];
    // @Ignore()
    public personnelRoleSummaries: PersonnelRoleSummary[];

    // @Ignore()
    public notes: NoteTypeNotes[];

    // @Ignore()
    public display: string;

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    public constructor(init?: Partial<Institution>) { (Object as any).assign(this, init); }
}

export class DepartmentContact implements IForm
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Department)")
    public departmentId?: number;

    public department: Department;
    public name: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.ProfessionalDiscipline)")
    public professionalDisciplineId?: number;

    public professionalDiscipline: ProfessionalDiscipline;
    // @Ignore()
    public professionalDisciplineDisplay: string;

    public otherDisciplines: string;
    public email: string;
    public phone: string;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<DepartmentContact>) { (Object as any).assign(this, init); }
}

export class Department implements IForm
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Institution)")
    public institutionId?: number;

    public institution: Institution;
    public name: string;
    public address1: string;
    public address2: string;
    public suburb: string;
    public postcode: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.State)")
    public stateId?: number;

    public state: State;
    public stateOther: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.Country)")
    public countryId?: number;

    public country: Country;
    public mailAddress1: string;
    public mailAddress2: string;
    public mailSuburb: string;
    public mailPostcode: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.State)")
    public mailStateId?: number;

    public mailState: State;
    public mailStateOther: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.Country)")
    public mailCountryId?: number;

    public mailCountry: Country;
    public phone: string;
    public fax: string;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    public departmentContacts: DepartmentContact[];
    public personnelRoles: PersonnelRole[];

    public constructor(init?: Partial<Department>) { (Object as any).assign(this, init); }
}

export class FormTemplate implements IForm
{
    public id?: number;
    public name: string;
    public description: string;
    public rank: number;
    public itemComponentTypeId: number;
    public formTemplateCategoryId: number;
    public auditOnSave: boolean;
    public allowVersions: boolean;
    // @References("typeof(TMD.ServiceModel.Types.Do.Collection)")
    public collectionId?: number;

    public collection: Collection;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<FormTemplate>) { (Object as any).assign(this, init); }
}

export class FormTemplateStatus implements IForm
{
    public id?: number;
    public value: string;
    public itemComponentTypeId: number;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<FormTemplateStatus>) { (Object as any).assign(this, init); }
}

export class FormNextAutoVersion implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<FormNextAutoVersion>) { (Object as any).assign(this, init); }
}

export class InstitutionForm implements IForm
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Institution)")
    public institutionId?: number;

    public institution: Institution;
    // @References("typeof(TMD.ServiceModel.Types.Do.FormTemplate)")
    public formTemplateId?: number;

    public formTemplate: FormTemplate;
    // @References("typeof(TMD.ServiceModel.Types.Do.FormTemplateStatus)")
    public formStatusId?: number;

    public formStatus: FormTemplateStatus;
    public version: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.Personnel)")
    public assignedToId?: number;

    public assignedTo: Personnel;
    // @References("typeof(TMD.ServiceModel.Types.Do.FormNextAutoVersion)")
    public formNextAutoVersionId?: number;

    public formNextAutoVersion: FormNextAutoVersion;
    public dateNextVersion?: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.CollectionInstance)")
    public collectionInstanceId?: number;

    public collectionInstance: CollectionInstance;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    // @Ignore()
    public versions: InstitutionForm[];

    public constructor(init?: Partial<InstitutionForm>) { (Object as any).assign(this, init); }
}

export class FormPropertyErrorMessage
{
    public propertyName: string;
    public label: string;

    public constructor(init?: Partial<FormPropertyErrorMessage>) { (Object as any).assign(this, init); }
}

export class FormProperty
{
    public formName: string;
    public propertyName: string;
    public label: string;
    public detailedLabel: string;
    public formPropertyErrorMessages: FormPropertyErrorMessage[];

    public constructor(init?: Partial<FormProperty>) { (Object as any).assign(this, init); }
}

export class RegistrationFormProperty
{
    public id: number;
    public registrationFormId: number;
    public formName: string;
    public propertyName: string;
    public label: string;

    public constructor(init?: Partial<RegistrationFormProperty>) { (Object as any).assign(this, init); }
}

export class CollaboratingGroup implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CollaboratingGroup>) { (Object as any).assign(this, init); }
}

export class TemplateTrialItemGroup
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.TemplateTrialItem)")
    public templateTrialItemId?: number;

    // @References("typeof(TMD.ServiceModel.Types.Do.Group)")
    public groupId: number;

    public group: Group;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TemplateTrialItemGroup>) { (Object as any).assign(this, init); }
}

export class TemplateTrialItem implements IForm
{
    public id?: number;
    public name: string;
    public description: string;
    public rank: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Category)")
    public categoryId: number;

    public category: Category;
    public allowIntraVersions: boolean;
    public pendingDelete: boolean;
    public allowVersionChanges: boolean;
    public initialVersion: string;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.Collection)")
    public collectionId?: number;

    public collection: Collection;
    public templateTrialItemGroups: TemplateTrialItemGroup[];

    public constructor(init?: Partial<TemplateTrialItem>) { (Object as any).assign(this, init); }
}

export class CaseType implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CaseType>) { (Object as any).assign(this, init); }
}

export class Patient implements IForm
{
    public id?: number;
    public initials: string;
    public registrationNumber: string;
    public accrualDate?: string;
    public trialId: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Institution)")
    public institutionId: number;

    public institution: Institution;
    public opmsId?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.CaseType)")
    public caseTypeId?: number;

    public caseType: CaseType;
    // @References("typeof(TMD.ServiceModel.Types.Do.TrialTreatment)")
    public trialTreatmentId?: number;

    public trialTreatment: TrialTreatment;
    // @References("typeof(TMD.ServiceModel.Types.Do.Personnel)")
    public treatingClinicianId?: number;

    public treatingClinician: Personnel;
    // @References("typeof(TMD.ServiceModel.Types.Do.Personnel)")
    public investigatorId?: number;

    public investigator: Personnel;
    // @References("typeof(TMD.ServiceModel.Types.Do.Personnel)")
    public dataManagerId?: number;

    public dataManager: Personnel;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Patient>) { (Object as any).assign(this, init); }
}

export class EmailUpdateOptions
{
    public canChangeEmail: boolean;
    public email: string;
    public deActivateUserRoles: boolean;
    public personnelId: number;
    public domain: string;

    public constructor(init?: Partial<EmailUpdateOptions>) { (Object as any).assign(this, init); }
}

export class FormTemplateFileType implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public formName: string;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<FormTemplateFileType>) { (Object as any).assign(this, init); }
}

export class TaskType implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TaskType>) { (Object as any).assign(this, init); }
}

export class TrackerMilestone implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrackerMilestone>) { (Object as any).assign(this, init); }
}

export class TrackerType implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrackerType>) { (Object as any).assign(this, init); }
}

export class TrialForm implements IForm
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Patient)")
    public patientId?: number;

    public patient: Patient;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    public trial: Trial;
    // @References("typeof(TMD.ServiceModel.Types.Do.Institution)")
    public institutionId?: number;

    public institution: Institution;
    // @References("typeof(TMD.ServiceModel.Types.Do.FormTemplate)")
    public formTemplateId?: number;

    public formTemplate: FormTemplate;
    // @References("typeof(TMD.ServiceModel.Types.Do.FormTemplateStatus)")
    public formStatusId?: number;

    public formStatus: FormTemplateStatus;
    public version: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.Personnel)")
    public assignedToId?: number;

    // @References("typeof(TMD.ServiceModel.Types.Do.Role)")
    public assignedRoleId?: number;

    public assignedRole: Role;
    public assignmentTypeId?: number;
    public assignedTo: Personnel;
    // @References("typeof(TMD.ServiceModel.Types.Do.FormNextAutoVersion)")
    public formNextAutoVersionId?: number;

    public formNextAutoVersion: FormNextAutoVersion;
    public dateNextVersion?: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.CollectionInstance)")
    public collectionInstanceId?: number;

    public collectionInstance: CollectionInstance;
    public reminderEmailDateSend?: string;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    // @Ignore()
    public versions: TrialForm[];

    // @Ignore()
    public assignedDisplay: string;

    public constructor(init?: Partial<TrialForm>) { (Object as any).assign(this, init); }
}

export class MeasurementReport
{
    public trialFormId: number;
    public version: string;
    public sectionName: string;
    public label: string;
    public reportingTagCategory: string;
    public collectionInstanceDataId: number;
    public acceptable: number;
    public minorVariations: number;
    public majorVariations: number;
    public missing: number;
    public na: number;
    public justified: number;

    public constructor(init?: Partial<MeasurementReport>) { (Object as any).assign(this, init); }
}

export class TrialItemImport implements IForm
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    public trial: Trial;
    public templateCategories: Category[];
    public addGroups: boolean;
    public display: string;
    public selectedItems: number[];
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialItemImport>) { (Object as any).assign(this, init); }
}

export class TrialItemInstanceSummaryVersion
{
    public id?: number;
    public trialGroupItemId?: number;
    public version: string;
    public versionDate?: string;
    public collectionInstanceId?: number;
    public dateEntered?: string;
    public instanceNumber: number;
    public display: string;

    public constructor(init?: Partial<TrialItemInstanceSummaryVersion>) { (Object as any).assign(this, init); }
}

export class TrialItemInstanceSummary
{
    public id?: number;
    public name: string;
    public description: string;
    public groups: Group[];
    public groupDisplay: string;
    public allowVersions: boolean;
    public assignedDisplay: string;
    public trialItemInstanceStatusDisplay: string;
    public initialVersion: string;
    public instanceNumber: number;
    public version: string;
    public versionDate?: string;
    public rank: number;
    public sortOrderId: number;
    public active: boolean;
    public trialId?: number;
    public templateTrialItemId?: number;
    public trialItemInstanceId?: number;
    public collectionInstanceId?: number;
    public dataEntered: boolean;
    public trialItemInstanceSummaryVersions: TrialItemInstanceSummaryVersion[];
    public display: string;

    public constructor(init?: Partial<TrialItemInstanceSummary>) { (Object as any).assign(this, init); }
}

export class TrialItemInstanceCategory
{
    public categoryId: number;
    public category: Category;
    public trialItemInstanceSummaries: TrialItemInstanceSummary[];

    public constructor(init?: Partial<TrialItemInstanceCategory>) { (Object as any).assign(this, init); }
}

export class TrialItemInstanceImport implements IForm
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    public trial: Trial;
    public trialItemInstanceCategories: TrialItemInstanceCategory[];
    public addGroups: boolean;
    public display: string;
    public selectedItems: number[];
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialItemInstanceImport>) { (Object as any).assign(this, init); }
}

export class TrialItemInstanceStatus implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialItemInstanceStatus>) { (Object as any).assign(this, init); }
}

export class TrialItemPreviousInstance
{
    public id: number;
    public version: string;
    public versionDate?: string;
    public description: string;
    // @References("typeof(TMD.ServiceModel.Types.Do.CollectionInstance)")
    public collectionInstanceId?: number;

    public dateEntered?: string;
    public instanceNumber: number;
    public active: boolean;

    public constructor(init?: Partial<TrialItemPreviousInstance>) { (Object as any).assign(this, init); }
}

export class TrialItemInstance implements IForm
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    public trial: Trial;
    // @References("typeof(TMD.ServiceModel.Types.Do.TemplateTrialItem)")
    public templateTrialItemId?: number;

    public instanceNumber: number;
    public templateTrialItem: TemplateTrialItem;
    public version: string;
    public versionDate?: string;
    public description: string;
    // @Ignore()
    public originalDescription: string;

    // @References("typeof(TMD.ServiceModel.Types.Do.CollectionInstance)")
    public collectionInstanceId?: number;

    public collectionInstance: CollectionInstance;
    // @References("typeof(TMD.ServiceModel.Types.Do.TrialItemInstanceStatus)")
    public trialItemInstanceStatusId?: number;

    public trialItemInstanceStatus: TrialItemInstanceStatus;
    // @References("typeof(TMD.ServiceModel.Types.Do.Personnel)")
    public assignedToId?: number;

    // @References("typeof(TMD.ServiceModel.Types.Do.Role)")
    public assignedRoleId?: number;

    public assignedRole: Role;
    public assignmentTypeId?: number;
    public assignedTo: Personnel;
    public sortOrderId?: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    // @Ignore()
    public assignedDisplay: string;

    // @Ignore()
    public trialItemPreviousInstances: TrialItemPreviousInstance[];

    public constructor(init?: Partial<TrialItemInstance>) { (Object as any).assign(this, init); }
}

export class TrialItemInstitutionSummary
{
    public institutionId: number;
    public name: string;
    public collectionInstanceId: number;
    public version: string;
    public itemComponentRules: ItemComponentRule[];
    public columnFormat: string;
    public column1: ItemComponentWithData[];
    public column2: ItemComponentWithData[];
    public column3: ItemComponentWithData[];
    public documents: ItemComponentWithData[];
    public isModified: boolean;

    public constructor(init?: Partial<TrialItemInstitutionSummary>) { (Object as any).assign(this, init); }
}

export class TrialItemUpdate implements IForm
{
    public id?: number;
    public display: string;
    public trialItems: TrialItem[];
    public trialItemId?: number;
    public trialItemInstitutions: TrialItemInstitutionSummary[];
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialItemUpdate>) { (Object as any).assign(this, init); }
}

export class TrackerStatus implements IForm
{
    public id?: number;
    public value: string;
    public rank: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrackerStatus>) { (Object as any).assign(this, init); }
}

export class TrialTracker implements IForm, IHasSourceDocuments
{
    public id?: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.Trial)")
    public trialId?: number;

    // @References("typeof(TMD.ServiceModel.Types.Do.TrackerMilestone)")
    public trackerMilestoneId?: number;

    // @References("typeof(TMD.ServiceModel.Types.Do.TrackerType)")
    public trackerTypeId?: number;

    // @References("typeof(TMD.ServiceModel.Types.Do.TrackerStatus)")
    public trackerStatusId?: number;

    // @References("typeof(TMD.ServiceModel.Types.Do.Personnel)")
    public assignedToId?: number;

    public subject: string;
    public dateStarted?: string;
    public dateStopped?: string;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    public trial: Trial;
    public trackerMilestone: TrackerMilestone;
    public trackerType: TrackerType;
    public trackerStatus: TrackerStatus;
    public assignedTo: Personnel;
    // @Ignore()
    public recentComment: TrialTrackerComment;

    // @Ignore()
    public addComment: string;

    // @Ignore()
    public enteredByDisplay: string;

    public trialTrackerComments: TrialTrackerComment[];
    // @Ignore()
    public sourceDocuments: SourceDocument[];

    public constructor(init?: Partial<TrialTracker>) { (Object as any).assign(this, init); }
}

export class TrialTrackerComment implements ISubForm
{
    public id: number;
    // @References("typeof(TMD.ServiceModel.Types.Do.TrialTracker)")
    public trialTrackerId?: number;

    public trialTracker: TrialTracker;
    public comments: string;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrialTrackerComment>) { (Object as any).assign(this, init); }
}

export enum Permission
{
    OpmsAccess = 1,
    OpmsInstitutionView = 2,
    OpmsAdminister = 3,
    OpmsPatientView = 4,
    SystemAdminister = 5,
    OpmsPatientUpdate = 6,
    OpmsMasterGroupAdminister = 7,
    OpmsCoordinatingGroupAdminister = 8,
    PathologyTestAdminister = 9,
    BlockShipmentDetailsView = 10,
    OpmsPatientRegister = 11,
    OpmsPatientRandomise = 12,
    SaeView = 13,
    SaeReview = 14,
    ScreeningLogView = 15,
    ScreeningLogUpdate = 16,
    TeleformView = 17,
    TeleformManage = 18,
    EcrfView = 19,
    SaeAdminister = 20,
    MedicalReviewAccess = 21,
    MedicalReviewPerform = 22,
    MedicalReviewAdminister = 23,
    HistopathologyAccess = 24,
    DrugManagementView = 25,
    DrugManagementAdminister = 26,
    DrugManagementDispenseDrug = 27,
    OpmsProView = 28,
    OpmsProUpdate = 29,
    OpmsProAdminister = 30,
    OpmsPatientImpersonate = 31,
    OpmsPatientAccountManagement = 32,
    OpmsCrfView = 33,
    OpmsCrfUpdate = 34,
    OpmsEventView = 35,
    OpmsEventUpdate = 36,
    OpmsConsentAdminister = 37,
    OpmsCoordinatingGroupContactView = 38,
    OpmsSaeInvestigatorNotify = 39,
    EcrfAdminister = 40,
    DrugManagementAdministerPharmacyStock = 41,
    TrialView = 100,
    TrialCreate = 101,
    TrialDetailsView = 102,
    TrialUpdate = 103,
    DashboardView = 104,
    TrialInstitutionView = 105,
    TrialInstitutionCreate = 106,
    TrialInstitutionUpdate = 107,
    TrialPersonnelRoleView = 108,
    TrialPersonnelRoleCreate = 109,
    TrialPersonnelRoleUpdate = 110,
    TrialPatientView = 111,
    TrialPatientCreate = 112,
    TrialPatientUpdate = 113,
    TrialItemView = 114,
    TrialItemCreate = 115,
    TrialItemUpdate = 116,
    TrialNoteView = 117,
    TrialNoteCreate = 118,
    TrialNoteUpdate = 119,
    TrialGroupView = 120,
    TrialGroupCreate = 121,
    TrialGroupUpdate = 122,
    TrialFormView = 123,
    TrialFormCreate = 124,
    TrialFormUpdate = 125,
    InstitutionView = 126,
    InstitutionCreate = 127,
    InstitutionDetailsView = 128,
    InstitutionUpdate = 129,
    InstitutionDepartmentView = 130,
    InstitutionDepartmentCreate = 131,
    InstitutionDepartmentUpdate = 132,
    InstitutionPersonnelView = 133,
    InstitutionPersonnelCreate = 134,
    InstitutionPersonnelUpdate = 135,
    InstitutionFormView = 136,
    InstitutionFormCreate = 137,
    InstitutionFormUpdate = 138,
    InstitutionFormDataEntryView = 139,
    InstitutionFormDataEntryCreate = 140,
    InstitutionFormDataEntryUpdate = 141,
    InstitutionNoteView = 142,
    InstitutionNoteCreate = 143,
    InstitutionNoteUpdate = 144,
    PersonnelView = 145,
    PersonnelCreate = 146,
    PersonnelUpdate = 147,
    PersonnelItemsView = 148,
    PersonnelItemsCreate = 149,
    PersonnelItemsUpdate = 150,
    PersonnelHubspotView = 151,
    PersonnelHubspotCreate = 152,
    PersonnelHubspotUpdate = 153,
    PersonnelRoleView = 154,
    PersonnelRoleCreate = 155,
    PersonnelRoleUpdate = 156,
    PersonnelNoteView = 157,
    PersonnelNoteCreate = 158,
    PersonnelNoteUpdate = 159,
    PersonnelSecurityView = 160,
    PersonnelSecurityCreate = 161,
    PersonnelSecurityUpdate = 162,
    PersonnelOpenClinicaView = 163,
    PersonnelOpenClinicaCreate = 164,
    PersonnelOpenClinicaUpdate = 165,
    GroupView = 166,
    GroupCreate = 167,
    GroupUpdate = 168,
    GroupItemsView = 169,
    GroupItemsCreate = 170,
    GroupItemsUpdate = 171,
    GroupPersonnelRoleView = 172,
    GroupPersonnelRoleCreate = 173,
    GroupPersonnelRoleUpdate = 174,
    GroupNotesView = 175,
    GroupNotesCreate = 176,
    GroupNotesUpdate = 177,
    CategoriesView = 178,
    CategoriesCreate = 179,
    CategoriesUpdate = 180,
    RolesView = 181,
    RolesCreate = 182,
    RolesUpdate = 183,
    CountriesView = 184,
    CountriesCreate = 185,
    CountriesUpdate = 186,
    StatesView = 187,
    StatesCreate = 188,
    StatesUpdate = 189,
    ItemComponentTypesView = 190,
    ItemComponentTypesCreate = 191,
    ItemComponentTypesUpdate = 192,
    NoteTypesView = 193,
    NoteTypesCreate = 194,
    NoteTypesUpdate = 195,
    GendersView = 196,
    GendersCreate = 197,
    GendersUpdate = 198,
    InterestsView = 199,
    InterestsCreate = 200,
    InterestsUpdate = 201,
    MembershipTypesView = 202,
    MembershipTypesCreate = 203,
    MembershipTypesUpdate = 204,
    ProfessionalDisciplinesView = 205,
    ProfessionalDisciplinesCreate = 206,
    ProfessionalDisciplinesUpdate = 207,
    SalutationsView = 208,
    SalutationsCreate = 209,
    SalutationsUpdate = 210,
    TrialCategoriesView = 211,
    TrialCategoriesCreate = 212,
    TrialCategoriesUpdate = 213,
    TumorGroupsView = 214,
    TumorGroupsCreate = 215,
    TumorGroupsUpdate = 216,
    AllocatedWPsView = 217,
    AllocatedWPsCreate = 218,
    AllocatedWPsUpdate = 219,
    TrialPhasesView = 220,
    TrialPhasesCreate = 221,
    TrialPhasesUpdate = 222,
    InvolvementsView = 223,
    InvolvementsCreate = 224,
    InvolvementsUpdate = 225,
    TrialCoordCentresView = 226,
    TrialCoordCentresCreate = 227,
    TrialCoordCentresUpdate = 228,
    CollaboratingGroupsView = 229,
    CollaboratingGroupsCreate = 230,
    CollaboratingGroupsUpdate = 231,
    TrialTypesView = 232,
    TrialTypesCreate = 233,
    TrialTypesUpdate = 234,
    SiteLocationsView = 235,
    SiteLocationsCreate = 236,
    SiteLocationsUpdate = 237,
    KeyContactsView = 238,
    KeyContactsCreate = 239,
    KeyContactsUpdate = 240,
    FacilityAllianceMembersView = 241,
    FacilityAllianceMembersCreate = 242,
    FacilityAllianceMembersUpdate = 243,
    InstitutionTypesView = 244,
    InstitutionTypesCreate = 245,
    InstitutionTypesUpdate = 246,
    InstitutionCodingView = 247,
    InstitutionCodingCreate = 248,
    InstitutionCodingUpdate = 249,
    FormTemplatesView = 250,
    FormTemplatesCreate = 251,
    FormTemplatesUpdate = 252,
    FormComponentsView = 253,
    FormComponentsCreate = 254,
    FormComponentsUpdate = 255,
    ReportingTagsView = 256,
    ReportingTagsCreate = 257,
    ReportingTagsUpdate = 258,
    ReportingTagCategoriesView = 259,
    ReportingTagCategoriesCreate = 260,
    ReportingTagCategoriesUpdate = 261,
    ItemComponentsView = 262,
    ItemComponentsCreate = 263,
    ItemComponentsUpdate = 264,
    OpenClinicaUserRolesView = 265,
    OpenClinicaUserRolesCreate = 266,
    OpenClinicaUserRolesUpdate = 267,
    HubspotContactExportView = 268,
    HubspotContactExportCreate = 269,
    HubspotContactExportUpdate = 270,
    TrialApisView = 271,
    TrialTreatmentsView = 272,
    TrialTreatmentsCreate = 273,
    TrialTreatmentsUpdate = 274,
    TaskTypesView = 275,
    TaskTypesCreate = 276,
    TaskTypesUpdate = 277,
    FormTemplateFileTypesView = 278,
    FormTemplateFileTypesCreate = 279,
    FormTemplateFileTypesUpdate = 280,
    TrialFormDataEntry = 281,
    TrialTrackerView = 282,
    TrialTrackerCreate = 283,
    TrialTrackerUpdate = 284,
    QAEmailTemplatesView = 285,
    QAEmailTemplatesCreate = 286,
    QAEmailTemplatesUpdate = 287,
    TemplateTrialItemsView = 288,
    TemplateTrialItemsCreate = 289,
    TemplateTrialItemsUpdate = 290,
    TrialItemInstanceView = 291,
    TrialItemInstanceCreate = 292,
    TrialItemInstanceUpdate = 293,
    TrialInstitutionItemTemplatesView = 294,
    TrialInstitutionItemTemplatesUpdate = 295,
    TrialInstitutionItemTemplatesCreate = 296,
    TrialInstitutionItemTemplateImport = 297,
    TrialInstitutionItemTemplateRestrictedUpdate = 298,
    TrialInstitutionItemInstanceView = 299,
    TrialInstitutionItemInstanceUpdate = 300,
    TrialPresentationTypesView = 301,
    TrialPresentationTypesCreate = 302,
    TrialPresentationTypesUpdate = 303,
    TrialPublicationTypesView = 304,
    TrialPublicationTypesCreate = 305,
    TrialPublicationTypesUpdate = 306,
    TrialAnalysisTypesView = 307,
    TrialAnalysisTypesCreate = 308,
    TrialAnalysisTypesUpdate = 309,
    TrialItemTemplateLock = 310,
    TrialItemTemplateUnLock = 311,
    TrialInstitutionItemTemplateLock = 312,
    TrialInstitutionItemTemplateUnLock = 313,
    CollectionLock = 314,
    CollectionUnLock = 315,
    TrialItemInstanceVersionUpdate = 316,
    TrialItemInstanceExport = 317,
    TrialInstitutionItemInstanceExport = 318,
}

export enum PermissionResolutionType
{
    DatabaseConfiguration = 1,
    ClassConfiguration = 2,
    FileConfiguration = 3,
}

export class PermissionContext
{
    public trialId?: number;
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public institutionCode: string;
    public patientStudyNumber: string;

    public constructor(init?: Partial<PermissionContext>) { (Object as any).assign(this, init); }
}

export class PermissionQuery
{
    public permission?: Permission;
    public permissionResolutionType?: PermissionResolutionType;
    public permissionContext: PermissionContext;

    public constructor(init?: Partial<PermissionQuery>) { (Object as any).assign(this, init); }
}

export enum UserSessionType
{
    Normal = 1,
    Patient = 2,
}

export class WebsiteSetting
{
    public showMessage: boolean;
    public message: string;

    public constructor(init?: Partial<WebsiteSetting>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class ClientInstitution
{
    public id: number;
    public institutionCode: string;
    public institutionName: string;
    public state: string;
    public country: string;
    public dateActivated?: string;

    public constructor(init?: Partial<ClientInstitution>) { (Object as any).assign(this, init); }
}

export class ClientTrialUser
{
    public username: string;
    public displayname: string;
    public email: string;
    public trialid: string;
    public trialcode: string;
    public institutionid?: number;
    public instcode: string;
    public roleid?: number;
    public rolename: string;
    public allInstitutions: boolean;

    public constructor(init?: Partial<ClientTrialUser>) { (Object as any).assign(this, init); }
}

export enum FormStatus
{
    Enabled = 1,
    Disabled = 2,
}

export class FormState
{
    public id: number;
    public formId: number;
    public formStatus?: FormStatus;
    public validate?: boolean;
    public blockSave?: boolean;

    public constructor(init?: Partial<FormState>) { (Object as any).assign(this, init); }
}

export class LookupValue
{
    public id?: number;
    public value: string;
    public rank?: number;
    public active: boolean;

    public constructor(init?: Partial<LookupValue>) { (Object as any).assign(this, init); }
}

export class Lookup
{
    public type: string;
    public propertyName: string;
    public values: LookupValue[];

    public constructor(init?: Partial<Lookup>) { (Object as any).assign(this, init); }
}

export interface IHasLookups
{
    lookups: Lookup[];
}

export enum RoleType
{
    SuperUser = 1,
    Staff = 2,
    PrincipalInvestigator = 3,
    Investigator = 4,
    Coordinator = 5,
    Pharmacist = 6,
    Finance = 7,
    QA = 8,
    StaffTrialManager = 9,
    ClinicalResearchAssociate = 16,
    ClinicalTrialAssistant = 17,
}

export interface IHasRoles
{
    formRoles: RoleType[];
}

export enum RegistrationFormStatus
{
    Enabled = 1,
    Disabled = 2,
}

export class RegistrationFormState
{
    public id: number;
    public registrationFormId: number;
    public formState?: RegistrationFormStatus;
    public validate?: boolean;
    public blockSave?: boolean;

    public constructor(init?: Partial<RegistrationFormState>) { (Object as any).assign(this, init); }
}

export class ModulePermission
{
    public name: string;
    public description: string;

    public constructor(init?: Partial<ModulePermission>) { (Object as any).assign(this, init); }
}

export class TemplateTrialItemCategory
{
    public categoryId: number;
    public category: Category;
    public templateTrialItems: TemplateTrialItem[];

    public constructor(init?: Partial<TemplateTrialItemCategory>) { (Object as any).assign(this, init); }
}

export class HubspotField
{
    public name: string;
    public value: string;
    public options: string[];

    public constructor(init?: Partial<HubspotField>) { (Object as any).assign(this, init); }
}

export class Hubspot
{
    public personnel: Personnel;
    public hasHubspotRecord: boolean;
    public id: string;
    public firstname: string;
    public lastname: string;
    public email: string;
    public company: string;
    public phone: string;
    public website: string;
    public archived: boolean;
    public link: string;
    public data: HubspotField[];
    public adUsername: string;
    public adGroups: string;
    public active: boolean;
    public search: string;

    public constructor(init?: Partial<Hubspot>) { (Object as any).assign(this, init); }
}

export class UserLogin
{
    public activeDirectory: string;
    public activeDirectoryUserName: string;
    public activeDirectoryEmail: string;
    public activeDirectoryActive: boolean;
    public activeDirectoryMemberships: string;

    public constructor(init?: Partial<UserLogin>) { (Object as any).assign(this, init); }
}

export class EmailUsage
{
    public email: string;
    public contact: Hubspot;
    public userLogin: UserLogin;
    public personnel: Personnel;
    public emailMatchedPersonnels: Personnel[];
    public welcomeEmailSentDate: string;

    public constructor(init?: Partial<EmailUsage>) { (Object as any).assign(this, init); }
}

export class PersonnelPermission
{
    public personnelId: number;
    public userName: string;
    public roleName: string;
    public trialId: number;
    public protocolID: string;
    public trialDevelopmentNumber: string;
    public shortTitle: string;
    public studyAcronym: string;
    public institutionId: number;
    public institution: string;
    public code: string;
    public moduleDescription: string;
    public permissionName: string;
    public permissionDescription: string;
    // @Ignore()
    public group: string;

    // @Ignore()
    public trialDisplay: string;

    // @Ignore()
    public institutionDisplay: string;

    public constructor(init?: Partial<PersonnelPermission>) { (Object as any).assign(this, init); }
}

export class PersonnelPermissionGroup
{
    public name: string;
    public personnelPermissions: PersonnelPermission[];

    public constructor(init?: Partial<PersonnelPermissionGroup>) { (Object as any).assign(this, init); }
}

export class RolePersonnel
{
    public roleId: number;
    public roleName: string;
    public roleRank: number;
    public userName: string;
    public personnelId: number;
    public firstName: string;
    public lastName: string;
    public trialId?: number;
    public protocolID: string;
    public trialDevelopmentNumber: string;
    public shortTitle: string;
    public studyAcronym: string;
    public institutionId?: number;
    public institutionName: string;
    public institutionCode: string;
    // @Ignore()
    public isGlobal: boolean;

    // @Ignore()
    public trialDisplay: string;

    // @Ignore()
    public personnelDisplay: string;

    // @Ignore()
    public institutionDisplay: string;

    public constructor(init?: Partial<RolePersonnel>) { (Object as any).assign(this, init); }
}

export class RolePersonnelGrouping
{
    public roleId: number;
    public roleName: string;
    public roleRank: number;
    public rolePersonnels: RolePersonnel[];

    public constructor(init?: Partial<RolePersonnelGrouping>) { (Object as any).assign(this, init); }
}

export class SearchResult implements IForm
{
    public searchFor: string;
    public institutionTrials: TrialInstitution[];
    public institutions: Institution[];
    public trials: Trial[];
    public personnel: Personnel[];
    public id?: number;
    public active: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SearchResult>) { (Object as any).assign(this, init); }
}

export class AppSettings
{
    public buildMode: string;
    public reportServerUrl: string;
    public reportFolder: string;
    public googleAnalyticsId: string;
    public group2Enabled: string;

    public constructor(init?: Partial<AppSettings>) { (Object as any).assign(this, init); }
}

export class ApiTrialInstitution
{
    public id: number;
    public name: string;
    public code: string;

    public constructor(init?: Partial<ApiTrialInstitution>) { (Object as any).assign(this, init); }
}

export class ApiUserTrialInstitution
{
    public id: number;
    public name: string;
    public code: string;
    public trialInstitutionStatusId?: number;

    public constructor(init?: Partial<ApiUserTrialInstitution>) { (Object as any).assign(this, init); }
}

export class ApiUserInstitution
{
    public id: number;
    public name: string;
    public code: string;
    public userTrialInstitutions: ApiUserTrialInstitution[];

    public constructor(init?: Partial<ApiUserInstitution>) { (Object as any).assign(this, init); }
}

export class ApiInstitution
{
    public id?: number;
    public name: string;
    public code: string;
    public trialInstitutions: ApiTrialInstitution[];
    public userInstitutions: ApiUserInstitution[];

    public constructor(init?: Partial<ApiInstitution>) { (Object as any).assign(this, init); }
}

export class ApiTrial
{
    public id: number;
    public name: string;
    public code: string;
    public userTrialInstitutions: ApiUserTrialInstitution[];
    public trialInstitutions: ApiTrialInstitution[];

    public constructor(init?: Partial<ApiTrial>) { (Object as any).assign(this, init); }
}

export class ApiGroup
{
    public id?: number;
    public name: string;
    public displayName: string;
    public description: string;
    public canImpersonate: boolean;
    public trialId?: number;
    public trialStatusId?: number;

    public constructor(init?: Partial<ApiGroup>) { (Object as any).assign(this, init); }
}

export class ApiUserGroup
{
    public id?: number;
    public name: string;
    public displayName: string;
    public description: string;

    public constructor(init?: Partial<ApiUserGroup>) { (Object as any).assign(this, init); }
}

export class ApiUser
{
    public id?: number;
    public firstName: string;
    public lastName: string;
    public subjectId: string;
    public username: string;
    public email: string;
    public apiKey: string;
    public memberships: ApiMembership[];
    public userInstitutions: ApiUserInstitution[];
    public userGroups: ApiUserGroup[];

    public constructor(init?: Partial<ApiUser>) { (Object as any).assign(this, init); }
}

export class ApiMembership
{
    public id: number;
    public userId: number;
    public groupId: number;
    public group: ApiGroup;
    public user: ApiUser;

    public constructor(init?: Partial<ApiMembership>) { (Object as any).assign(this, init); }
}

export class KeyValuePair<TKey, TValue>
{
    public key: TKey;
    public value: TValue;

    public constructor(init?: Partial<KeyValuePair<TKey, TValue>>) { (Object as any).assign(this, init); }
}

export class PermissionConfiguration
{
    public id?: number;
    public permission?: Permission;
    public description: string;

    public constructor(init?: Partial<PermissionConfiguration>) { (Object as any).assign(this, init); }
}

export class User
{
    public userName: string;
    public firstName: string;
    public lastName: string;
    public fullName: string;
    public email: string;
    public trialId: string;
    public groups: string[];
    public institutions: string[];
    public roles: { [index: string]: string[]; };

    public constructor(init?: Partial<User>) { (Object as any).assign(this, init); }
}

export class PersonnelTmdPermissions
{
    public id: number;
    public userName: string;
    public trialId?: number;
    public protocolID: string;
    public institutionId?: number;
    public code: string;
    public roleGroupId: number;
    public trialMatch: boolean;
    public institutionMatch: boolean;
    public requirementDescription: string;
    public configurationDescription: string;
    public moduleName: string;
    public moduleDescription: string;
    public roleId: number;
    public roleName: string;
    public permission: Permission;
    public permissionName: string;
    public permissionDescription: string;

    public constructor(init?: Partial<PersonnelTmdPermissions>) { (Object as any).assign(this, init); }
}

export class UserSummary
{
    public hasTrialRoles: boolean;
    public hasInstitutionRoles: boolean;
    public hasTrialInstitutionRoles: boolean;
    public institutions: Institution[];
    public permissions: PersonnelTmdPermissions[];

    public constructor(init?: Partial<UserSummary>) { (Object as any).assign(this, init); }
}

export class Home
{
    public message: string;

    public constructor(init?: Partial<Home>) { (Object as any).assign(this, init); }
}

export enum FileType
{
    EligibilityConfirmation = 1,
    ConsentFormMain = 2,
    ConsentFormServicesAustraliaData = 3,
    PathologyReportTumor = 4,
    PathologyReportBlood = 5,
    PatientReportOutcomeEORTCQLQC30 = 6,
    Worksheet = 7,
    ImagingReportMri = 8,
    ImagingReportFETPET = 9,
    ImagingReportFDGPET = 10,
    OtherSourceDocument = 11,
}

export class RegistrationFormPropertyErrorMessage
{
    public id: number;
    public registrationFormPropertyId: number;
    public propertyName: string;
    public label: string;

    public constructor(init?: Partial<RegistrationFormPropertyErrorMessage>) { (Object as any).assign(this, init); }
}

export class RegistrationFormSummary
{
    public formName: string;
    public formGroup: string;
    public formDisplayName: string;
    public totalQuestions?: number;
    public answeredQuestions?: number;
    public errors: ResponseError[];
    public ineligibleErrors: ResponseError[];
    public rank: number;

    public constructor(init?: Partial<RegistrationFormSummary>) { (Object as any).assign(this, init); }
}

export class EmailAddress
{
    public displayName: string;
    public address: string;

    public constructor(init?: Partial<EmailAddress>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserApiKey
{
    // @DataMember(Order=1)
    public key: string;

    // @DataMember(Order=2)
    public keyType: string;

    // @DataMember(Order=3)
    public expiryDate?: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UserApiKey>) { (Object as any).assign(this, init); }
}

export interface IForm extends IAudited
{
    id?: number;
}

export interface IAudited extends IDateTimeStamped, IUserStamped
{
    active: boolean;
}

export interface IDateTimeStamped
{
    dateEntered?: string;
    dateModified?: string;
}

export interface IUserStamped
{
    enteredBy: string;
    modifiedBy: string;
}

export interface IHasSourceDocuments
{
    sourceDocuments: SourceDocument[];
}

export interface ISubForm extends IAudited
{
    id: number;
}

export class GetClientInstitutionsResponse
{
    public responseStatus: ResponseStatus;
    public institutions: ClientInstitution[];

    public constructor(init?: Partial<GetClientInstitutionsResponse>) { (Object as any).assign(this, init); }
}

export class GetClientTrialUsersResponse
{
    public responseStatus: ResponseStatus;
    public trialUsers: ClientTrialUser[];

    public constructor(init?: Partial<GetClientTrialUsersResponse>) { (Object as any).assign(this, init); }
}

export class GetCollectionInstanceByIdResponse
{
    public responseStatus: ResponseStatus;
    public collectionInstance: CollectionInstance;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetCollectionInstanceByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetCollectionInstanceResponse
{
    public responseStatus: ResponseStatus;
    public collectionInstance: CollectionInstance;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetCollectionInstanceResponse>) { (Object as any).assign(this, init); }
}

export class GetCollectionByIdResponse
{
    public responseStatus: ResponseStatus;
    public collection: Collection;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetCollectionByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetCollectionResponse
{
    public responseStatus: ResponseStatus;
    public collection: Collection;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetCollectionResponse>) { (Object as any).assign(this, init); }
}

export class LockCollectionResponse
{
    public responseStatus: ResponseStatus;
    public collection: Collection;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<LockCollectionResponse>) { (Object as any).assign(this, init); }
}

export class CreateCollectionResponse
{
    public responseStatus: ResponseStatus;
    public collection: Collection;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<CreateCollectionResponse>) { (Object as any).assign(this, init); }
}

export class GetDepartmentContactsResponse
{
    public responseStatus: ResponseStatus;
    public departmentContacts: DepartmentContact[];
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetDepartmentContactsResponse>) { (Object as any).assign(this, init); }
}

export class GetDepartmentContactByIdResponse
{
    public responseStatus: ResponseStatus;
    public departmentContact: DepartmentContact;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetDepartmentContactByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetDepartmentContactResponse
{
    public responseStatus: ResponseStatus;
    public departmentContact: DepartmentContact;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetDepartmentContactResponse>) { (Object as any).assign(this, init); }
}

export class CreateDepartmentContactResponse
{
    public responseStatus: ResponseStatus;
    public departmentContact: DepartmentContact;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateDepartmentContactResponse>) { (Object as any).assign(this, init); }
}

export class GetDepartmentsResponse
{
    public responseStatus: ResponseStatus;
    public departments: Department[];
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetDepartmentsResponse>) { (Object as any).assign(this, init); }
}

export class GetDepartmentByIdResponse
{
    public responseStatus: ResponseStatus;
    public department: Department;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetDepartmentByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetDepartmentResponse
{
    public responseStatus: ResponseStatus;
    public department: Department;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetDepartmentResponse>) { (Object as any).assign(this, init); }
}

export class CreateDepartmentResponse
{
    public responseStatus: ResponseStatus;
    public department: Department;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateDepartmentResponse>) { (Object as any).assign(this, init); }
}

export class GetInstitutionFormsByCurrentUserResponse
{
    public responseStatus: ResponseStatus;
    public institutionForms: InstitutionForm[];

    public constructor(init?: Partial<GetInstitutionFormsByCurrentUserResponse>) { (Object as any).assign(this, init); }
}

export class GetInstitutionFormsResponse
{
    public responseStatus: ResponseStatus;
    public institutionForms: InstitutionForm[];
    public lookups: Lookup[];

    public constructor(init?: Partial<GetInstitutionFormsResponse>) { (Object as any).assign(this, init); }
}

export class GetInstitutionFormByIdResponse
{
    public responseStatus: ResponseStatus;
    public institutionForm: InstitutionForm;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetInstitutionFormByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetInstitutionFormResponse
{
    public responseStatus: ResponseStatus;
    public institutionForm: InstitutionForm;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetInstitutionFormResponse>) { (Object as any).assign(this, init); }
}

export class CreateInstitutionFormResponse
{
    public responseStatus: ResponseStatus;
    public institutionForm: InstitutionForm;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<CreateInstitutionFormResponse>) { (Object as any).assign(this, init); }
}

export class CreateInstitutionFormVersionResponse
{
    public responseStatus: ResponseStatus;
    public institutionForm: InstitutionForm;

    public constructor(init?: Partial<CreateInstitutionFormVersionResponse>) { (Object as any).assign(this, init); }
}

export class GetInstitutionsResponse
{
    public responseStatus: ResponseStatus;
    public institutions: Institution[];
    public lookups: Lookup[];

    public constructor(init?: Partial<GetInstitutionsResponse>) { (Object as any).assign(this, init); }
}

export class GetInstitutionByIdResponse
{
    public responseStatus: ResponseStatus;
    public institution: Institution;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetInstitutionByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetInstitutionResponse
{
    public responseStatus: ResponseStatus;
    public institution: Institution;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetInstitutionResponse>) { (Object as any).assign(this, init); }
}

export class CreateInstitutionResponse
{
    public responseStatus: ResponseStatus;
    public institution: Institution;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<CreateInstitutionResponse>) { (Object as any).assign(this, init); }
}

export class GetItemComponentsResponse
{
    public responseStatus: ResponseStatus;
    public itemComponents: ItemComponent[];
    public lookups: Lookup[];

    public constructor(init?: Partial<GetItemComponentsResponse>) { (Object as any).assign(this, init); }
}

export class GetItemComponentByIdResponse
{
    public responseStatus: ResponseStatus;
    public itemComponent: ItemComponent;
    public registrationFormState: RegistrationFormState;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetItemComponentByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetItemComponentResponse
{
    public responseStatus: ResponseStatus;
    public itemComponent: ItemComponent;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetItemComponentResponse>) { (Object as any).assign(this, init); }
}

export class CreateItemComponentResponse
{
    public responseStatus: ResponseStatus;
    public itemComponent: ItemComponent;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<CreateItemComponentResponse>) { (Object as any).assign(this, init); }
}

export class GetAllocatedWPsResponse
{
    public responseStatus: ResponseStatus;
    public allocatedWPs: AllocatedWP[];

    public constructor(init?: Partial<GetAllocatedWPsResponse>) { (Object as any).assign(this, init); }
}

export class GetAllocatedWPByIdResponse
{
    public responseStatus: ResponseStatus;
    public allocatedWP: AllocatedWP;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetAllocatedWPByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetAllocatedWPResponse
{
    public responseStatus: ResponseStatus;
    public allocatedWP: AllocatedWP;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetAllocatedWPResponse>) { (Object as any).assign(this, init); }
}

export class CreateAllocatedWPResponse
{
    public responseStatus: ResponseStatus;
    public allocatedWP: AllocatedWP;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateAllocatedWPResponse>) { (Object as any).assign(this, init); }
}

export class GetCategoriesResponse
{
    public responseStatus: ResponseStatus;
    public categories: Category[];

    public constructor(init?: Partial<GetCategoriesResponse>) { (Object as any).assign(this, init); }
}

export class GetCategoryByIdResponse
{
    public responseStatus: ResponseStatus;
    public category: Category;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetCategoryByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetCategoryResponse
{
    public responseStatus: ResponseStatus;
    public category: Category;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetCategoryResponse>) { (Object as any).assign(this, init); }
}

export class CreateCategoryResponse
{
    public responseStatus: ResponseStatus;
    public category: Category;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateCategoryResponse>) { (Object as any).assign(this, init); }
}

export class GetCollaboratingGroupsResponse
{
    public responseStatus: ResponseStatus;
    public collaboratingGroups: CollaboratingGroup[];

    public constructor(init?: Partial<GetCollaboratingGroupsResponse>) { (Object as any).assign(this, init); }
}

export class GetCollaboratingGroupByIdResponse
{
    public responseStatus: ResponseStatus;
    public collaboratingGroup: CollaboratingGroup;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetCollaboratingGroupByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetCollaboratingGroupResponse
{
    public responseStatus: ResponseStatus;
    public collaboratingGroup: CollaboratingGroup;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetCollaboratingGroupResponse>) { (Object as any).assign(this, init); }
}

export class CreateCollaboratingGroupResponse
{
    public responseStatus: ResponseStatus;
    public collaboratingGroup: CollaboratingGroup;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateCollaboratingGroupResponse>) { (Object as any).assign(this, init); }
}

export class GetCountriesResponse
{
    public responseStatus: ResponseStatus;
    public countries: Country[];

    public constructor(init?: Partial<GetCountriesResponse>) { (Object as any).assign(this, init); }
}

export class GetCountryByIdResponse
{
    public responseStatus: ResponseStatus;
    public country: Country;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetCountryByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetCountryResponse
{
    public responseStatus: ResponseStatus;
    public country: Country;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetCountryResponse>) { (Object as any).assign(this, init); }
}

export class CreateCountryResponse
{
    public responseStatus: ResponseStatus;
    public country: Country;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateCountryResponse>) { (Object as any).assign(this, init); }
}

export class GetFacilityAllianceMembersResponse
{
    public responseStatus: ResponseStatus;
    public facilityAllianceMembers: FacilityAllianceMember[];

    public constructor(init?: Partial<GetFacilityAllianceMembersResponse>) { (Object as any).assign(this, init); }
}

export class GetFacilityAllianceMemberByIdResponse
{
    public responseStatus: ResponseStatus;
    public facilityAllianceMember: FacilityAllianceMember;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetFacilityAllianceMemberByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetFacilityAllianceMemberResponse
{
    public responseStatus: ResponseStatus;
    public facilityAllianceMember: FacilityAllianceMember;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetFacilityAllianceMemberResponse>) { (Object as any).assign(this, init); }
}

export class CreateFacilityAllianceMemberResponse
{
    public responseStatus: ResponseStatus;
    public facilityAllianceMember: FacilityAllianceMember;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateFacilityAllianceMemberResponse>) { (Object as any).assign(this, init); }
}

export class GetGendersResponse
{
    public responseStatus: ResponseStatus;
    public genders: Gender[];

    public constructor(init?: Partial<GetGendersResponse>) { (Object as any).assign(this, init); }
}

export class GetGenderByIdResponse
{
    public responseStatus: ResponseStatus;
    public gender: Gender;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetGenderByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetGenderResponse
{
    public responseStatus: ResponseStatus;
    public gender: Gender;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetGenderResponse>) { (Object as any).assign(this, init); }
}

export class CreateGenderResponse
{
    public responseStatus: ResponseStatus;
    public gender: Gender;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateGenderResponse>) { (Object as any).assign(this, init); }
}

export class GetGroupItemByIdResponse
{
    public responseStatus: ResponseStatus;
    public groupItem: GroupItem;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetGroupItemByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetGroupItemResponse
{
    public responseStatus: ResponseStatus;
    public groupItem: GroupItem;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetGroupItemResponse>) { (Object as any).assign(this, init); }
}

export class CreateGroupItemResponse
{
    public responseStatus: ResponseStatus;
    public groupItem: GroupItem;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<CreateGroupItemResponse>) { (Object as any).assign(this, init); }
}

export class GetGroupsResponse
{
    public responseStatus: ResponseStatus;
    public groups: Group[];
    public lookups: Lookup[];

    public constructor(init?: Partial<GetGroupsResponse>) { (Object as any).assign(this, init); }
}

export class GetGroupByIdResponse
{
    public responseStatus: ResponseStatus;
    public group: Group;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetGroupByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetGroupResponse
{
    public responseStatus: ResponseStatus;
    public group: Group;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetGroupResponse>) { (Object as any).assign(this, init); }
}

export class CreateGroupResponse
{
    public responseStatus: ResponseStatus;
    public group: Group;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<CreateGroupResponse>) { (Object as any).assign(this, init); }
}

export class GetInstitutionCodingsResponse
{
    public responseStatus: ResponseStatus;
    public institutionCodings: InstitutionCoding[];

    public constructor(init?: Partial<GetInstitutionCodingsResponse>) { (Object as any).assign(this, init); }
}

export class GetInstitutionCodingByIdResponse
{
    public responseStatus: ResponseStatus;
    public institutionCoding: InstitutionCoding;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetInstitutionCodingByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetInstitutionCodingResponse
{
    public responseStatus: ResponseStatus;
    public institutionCoding: InstitutionCoding;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetInstitutionCodingResponse>) { (Object as any).assign(this, init); }
}

export class CreateInstitutionCodingResponse
{
    public responseStatus: ResponseStatus;
    public institutionCoding: InstitutionCoding;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateInstitutionCodingResponse>) { (Object as any).assign(this, init); }
}

export class GetInstitutionTypesResponse
{
    public responseStatus: ResponseStatus;
    public institutionTypes: InstitutionType[];

    public constructor(init?: Partial<GetInstitutionTypesResponse>) { (Object as any).assign(this, init); }
}

export class GetInstitutionTypeByIdResponse
{
    public responseStatus: ResponseStatus;
    public institutionType: InstitutionType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetInstitutionTypeByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetInstitutionTypeResponse
{
    public responseStatus: ResponseStatus;
    public institutionType: InstitutionType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetInstitutionTypeResponse>) { (Object as any).assign(this, init); }
}

export class CreateInstitutionTypeResponse
{
    public responseStatus: ResponseStatus;
    public institutionType: InstitutionType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateInstitutionTypeResponse>) { (Object as any).assign(this, init); }
}

export class GetInterestsResponse
{
    public responseStatus: ResponseStatus;
    public interests: Interest[];

    public constructor(init?: Partial<GetInterestsResponse>) { (Object as any).assign(this, init); }
}

export class GetInterestByIdResponse
{
    public responseStatus: ResponseStatus;
    public interest: Interest;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetInterestByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetInterestResponse
{
    public responseStatus: ResponseStatus;
    public interest: Interest;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetInterestResponse>) { (Object as any).assign(this, init); }
}

export class CreateInterestResponse
{
    public responseStatus: ResponseStatus;
    public interest: Interest;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateInterestResponse>) { (Object as any).assign(this, init); }
}

export class GetInvolvementsResponse
{
    public responseStatus: ResponseStatus;
    public involvements: Involvement[];

    public constructor(init?: Partial<GetInvolvementsResponse>) { (Object as any).assign(this, init); }
}

export class GetInvolvementByIdResponse
{
    public responseStatus: ResponseStatus;
    public involvement: Involvement;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetInvolvementByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetInvolvementResponse
{
    public responseStatus: ResponseStatus;
    public involvement: Involvement;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetInvolvementResponse>) { (Object as any).assign(this, init); }
}

export class CreateInvolvementResponse
{
    public responseStatus: ResponseStatus;
    public involvement: Involvement;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateInvolvementResponse>) { (Object as any).assign(this, init); }
}

export class GetItemComponentTypesResponse
{
    public responseStatus: ResponseStatus;
    public itemComponentTypes: ItemComponentType[];

    public constructor(init?: Partial<GetItemComponentTypesResponse>) { (Object as any).assign(this, init); }
}

export class GetItemComponentTypeByIdResponse
{
    public responseStatus: ResponseStatus;
    public itemComponentType: ItemComponentType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetItemComponentTypeByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetItemComponentTypeResponse
{
    public responseStatus: ResponseStatus;
    public itemComponentType: ItemComponentType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetItemComponentTypeResponse>) { (Object as any).assign(this, init); }
}

export class CreateItemComponentTypeResponse
{
    public responseStatus: ResponseStatus;
    public itemComponentType: ItemComponentType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateItemComponentTypeResponse>) { (Object as any).assign(this, init); }
}

export class GetKeyContactsResponse
{
    public responseStatus: ResponseStatus;
    public keyContacts: KeyContact[];

    public constructor(init?: Partial<GetKeyContactsResponse>) { (Object as any).assign(this, init); }
}

export class GetKeyContactByIdResponse
{
    public responseStatus: ResponseStatus;
    public keyContact: KeyContact;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetKeyContactByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetKeyContactResponse
{
    public responseStatus: ResponseStatus;
    public keyContact: KeyContact;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetKeyContactResponse>) { (Object as any).assign(this, init); }
}

export class CreateKeyContactResponse
{
    public responseStatus: ResponseStatus;
    public keyContact: KeyContact;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateKeyContactResponse>) { (Object as any).assign(this, init); }
}

export class GetMembershipTypesResponse
{
    public responseStatus: ResponseStatus;
    public membershipTypes: MembershipType[];

    public constructor(init?: Partial<GetMembershipTypesResponse>) { (Object as any).assign(this, init); }
}

export class GetMembershipTypeByIdResponse
{
    public responseStatus: ResponseStatus;
    public membershipType: MembershipType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetMembershipTypeByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetMembershipTypeResponse
{
    public responseStatus: ResponseStatus;
    public membershipType: MembershipType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetMembershipTypeResponse>) { (Object as any).assign(this, init); }
}

export class CreateMembershipTypeResponse
{
    public responseStatus: ResponseStatus;
    public membershipType: MembershipType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateMembershipTypeResponse>) { (Object as any).assign(this, init); }
}

export class GetNoteTypesResponse
{
    public responseStatus: ResponseStatus;
    public noteTypes: NoteType[];

    public constructor(init?: Partial<GetNoteTypesResponse>) { (Object as any).assign(this, init); }
}

export class GetNoteTypeByIdResponse
{
    public responseStatus: ResponseStatus;
    public noteType: NoteType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetNoteTypeByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetNoteTypeResponse
{
    public responseStatus: ResponseStatus;
    public noteType: NoteType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetNoteTypeResponse>) { (Object as any).assign(this, init); }
}

export class CreateNoteTypeResponse
{
    public responseStatus: ResponseStatus;
    public noteType: NoteType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateNoteTypeResponse>) { (Object as any).assign(this, init); }
}

export class GetProfessionalDisciplinesResponse
{
    public responseStatus: ResponseStatus;
    public professionalDisciplines: ProfessionalDiscipline[];

    public constructor(init?: Partial<GetProfessionalDisciplinesResponse>) { (Object as any).assign(this, init); }
}

export class GetProfessionalDisciplineByIdResponse
{
    public responseStatus: ResponseStatus;
    public professionalDiscipline: ProfessionalDiscipline;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetProfessionalDisciplineByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetProfessionalDisciplineResponse
{
    public responseStatus: ResponseStatus;
    public professionalDiscipline: ProfessionalDiscipline;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetProfessionalDisciplineResponse>) { (Object as any).assign(this, init); }
}

export class CreateProfessionalDisciplineResponse
{
    public responseStatus: ResponseStatus;
    public professionalDiscipline: ProfessionalDiscipline;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateProfessionalDisciplineResponse>) { (Object as any).assign(this, init); }
}

export class GetRolesResponse
{
    public responseStatus: ResponseStatus;
    public roles: Role[];
    public lookups: Lookup[];
    public modules: ModulePermission[];

    public constructor(init?: Partial<GetRolesResponse>) { (Object as any).assign(this, init); }
}

export class GetRoleByIdResponse
{
    public responseStatus: ResponseStatus;
    public role: Role;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetRoleByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetRoleResponse
{
    public responseStatus: ResponseStatus;
    public role: Role;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetRoleResponse>) { (Object as any).assign(this, init); }
}

export class CreateRoleResponse
{
    public responseStatus: ResponseStatus;
    public role: Role;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateRoleResponse>) { (Object as any).assign(this, init); }
}

export class GetSalutationsResponse
{
    public responseStatus: ResponseStatus;
    public salutations: Salutation[];

    public constructor(init?: Partial<GetSalutationsResponse>) { (Object as any).assign(this, init); }
}

export class GetSalutationByIdResponse
{
    public responseStatus: ResponseStatus;
    public salutation: Salutation;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetSalutationByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetSalutationResponse
{
    public responseStatus: ResponseStatus;
    public salutation: Salutation;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetSalutationResponse>) { (Object as any).assign(this, init); }
}

export class CreateSalutationResponse
{
    public responseStatus: ResponseStatus;
    public salutation: Salutation;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateSalutationResponse>) { (Object as any).assign(this, init); }
}

export class GetSiteLocationsResponse
{
    public responseStatus: ResponseStatus;
    public siteLocations: SiteLocation[];

    public constructor(init?: Partial<GetSiteLocationsResponse>) { (Object as any).assign(this, init); }
}

export class GetSiteLocationByIdResponse
{
    public responseStatus: ResponseStatus;
    public siteLocation: SiteLocation;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetSiteLocationByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetSiteLocationResponse
{
    public responseStatus: ResponseStatus;
    public siteLocation: SiteLocation;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetSiteLocationResponse>) { (Object as any).assign(this, init); }
}

export class CreateSiteLocationResponse
{
    public responseStatus: ResponseStatus;
    public siteLocation: SiteLocation;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateSiteLocationResponse>) { (Object as any).assign(this, init); }
}

export class GetStatesResponse
{
    public responseStatus: ResponseStatus;
    public states: State[];

    public constructor(init?: Partial<GetStatesResponse>) { (Object as any).assign(this, init); }
}

export class GetStateByIdResponse
{
    public responseStatus: ResponseStatus;
    public state: State;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetStateByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetStateResponse
{
    public responseStatus: ResponseStatus;
    public state: State;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetStateResponse>) { (Object as any).assign(this, init); }
}

export class CreateStateResponse
{
    public responseStatus: ResponseStatus;
    public state: State;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateStateResponse>) { (Object as any).assign(this, init); }
}

export class GetTemplateTrialItemsResponse
{
    public responseStatus: ResponseStatus;
    public templateTrialItems: TemplateTrialItem[];
    public lookups: Lookup[];

    public constructor(init?: Partial<GetTemplateTrialItemsResponse>) { (Object as any).assign(this, init); }
}

export class GetTemplateTrialItemsByCategoryResponse
{
    public responseStatus: ResponseStatus;
    public templateTrialItemsByCategory: TemplateTrialItemCategory[];
    public lookups: Lookup[];

    public constructor(init?: Partial<GetTemplateTrialItemsByCategoryResponse>) { (Object as any).assign(this, init); }
}

export class GetTemplateTrialItemByIdResponse
{
    public responseStatus: ResponseStatus;
    public templateTrialItem: TemplateTrialItem;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetTemplateTrialItemByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTemplateTrialItemResponse
{
    public responseStatus: ResponseStatus;
    public templateTrialItem: TemplateTrialItem;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetTemplateTrialItemResponse>) { (Object as any).assign(this, init); }
}

export class CreateTemplateTrialItemResponse
{
    public responseStatus: ResponseStatus;
    public templateTrialItem: TemplateTrialItem;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateTemplateTrialItemResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialAnalysisTypesResponse
{
    public responseStatus: ResponseStatus;
    public trialAnalysisTypes: TrialAnalysisType[];

    public constructor(init?: Partial<GetTrialAnalysisTypesResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialAnalysisTypeByIdResponse
{
    public responseStatus: ResponseStatus;
    public trialAnalysisType: TrialAnalysisType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetTrialAnalysisTypeByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrialAnalysisTypeResponse
{
    public responseStatus: ResponseStatus;
    public trialAnalysisType: TrialAnalysisType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetTrialAnalysisTypeResponse>) { (Object as any).assign(this, init); }
}

export class CreateTrialAnalysisTypeResponse
{
    public responseStatus: ResponseStatus;
    public trialAnalysisType: TrialAnalysisType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateTrialAnalysisTypeResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialCategorysResponse
{
    public responseStatus: ResponseStatus;
    public trialCategorys: TrialCategory[];

    public constructor(init?: Partial<GetTrialCategorysResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialCategoryByIdResponse
{
    public responseStatus: ResponseStatus;
    public trialCategory: TrialCategory;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetTrialCategoryByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrialCategoryResponse
{
    public responseStatus: ResponseStatus;
    public trialCategory: TrialCategory;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetTrialCategoryResponse>) { (Object as any).assign(this, init); }
}

export class CreateTrialCategoryResponse
{
    public responseStatus: ResponseStatus;
    public trialCategory: TrialCategory;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateTrialCategoryResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialCoordCentresResponse
{
    public responseStatus: ResponseStatus;
    public trialCoordCentres: TrialCoordCentre[];

    public constructor(init?: Partial<GetTrialCoordCentresResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialCoordCentreByIdResponse
{
    public responseStatus: ResponseStatus;
    public trialCoordCentre: TrialCoordCentre;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetTrialCoordCentreByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrialCoordCentreResponse
{
    public responseStatus: ResponseStatus;
    public trialCoordCentre: TrialCoordCentre;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetTrialCoordCentreResponse>) { (Object as any).assign(this, init); }
}

export class CreateTrialCoordCentreResponse
{
    public responseStatus: ResponseStatus;
    public trialCoordCentre: TrialCoordCentre;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateTrialCoordCentreResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialPhasesResponse
{
    public responseStatus: ResponseStatus;
    public trialPhases: TrialPhase[];

    public constructor(init?: Partial<GetTrialPhasesResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialPhaseByIdResponse
{
    public responseStatus: ResponseStatus;
    public trialPhase: TrialPhase;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetTrialPhaseByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrialPhaseResponse
{
    public responseStatus: ResponseStatus;
    public trialPhase: TrialPhase;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetTrialPhaseResponse>) { (Object as any).assign(this, init); }
}

export class CreateTrialPhaseResponse
{
    public responseStatus: ResponseStatus;
    public trialPhase: TrialPhase;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateTrialPhaseResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialPresentationTypesResponse
{
    public responseStatus: ResponseStatus;
    public trialPresentationTypes: TrialPresentationType[];

    public constructor(init?: Partial<GetTrialPresentationTypesResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialPresentationTypeByIdResponse
{
    public responseStatus: ResponseStatus;
    public trialPresentationType: TrialPresentationType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetTrialPresentationTypeByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrialPresentationTypeResponse
{
    public responseStatus: ResponseStatus;
    public trialPresentationType: TrialPresentationType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetTrialPresentationTypeResponse>) { (Object as any).assign(this, init); }
}

export class CreateTrialPresentationTypeResponse
{
    public responseStatus: ResponseStatus;
    public trialPresentationType: TrialPresentationType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateTrialPresentationTypeResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialPublicationTypesResponse
{
    public responseStatus: ResponseStatus;
    public trialPublicationTypes: TrialPublicationType[];

    public constructor(init?: Partial<GetTrialPublicationTypesResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialPublicationTypeByIdResponse
{
    public responseStatus: ResponseStatus;
    public trialPublicationType: TrialPublicationType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetTrialPublicationTypeByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrialPublicationTypeResponse
{
    public responseStatus: ResponseStatus;
    public trialPublicationType: TrialPublicationType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetTrialPublicationTypeResponse>) { (Object as any).assign(this, init); }
}

export class CreateTrialPublicationTypeResponse
{
    public responseStatus: ResponseStatus;
    public trialPublicationType: TrialPublicationType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateTrialPublicationTypeResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialTypesResponse
{
    public responseStatus: ResponseStatus;
    public trialTypes: TrialType[];

    public constructor(init?: Partial<GetTrialTypesResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialTypeByIdResponse
{
    public responseStatus: ResponseStatus;
    public trialType: TrialType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetTrialTypeByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrialTypeResponse
{
    public responseStatus: ResponseStatus;
    public trialType: TrialType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetTrialTypeResponse>) { (Object as any).assign(this, init); }
}

export class CreateTrialTypeResponse
{
    public responseStatus: ResponseStatus;
    public trialType: TrialType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateTrialTypeResponse>) { (Object as any).assign(this, init); }
}

export class GetTumourGroupsResponse
{
    public responseStatus: ResponseStatus;
    public tumourGroups: TumourGroup[];

    public constructor(init?: Partial<GetTumourGroupsResponse>) { (Object as any).assign(this, init); }
}

export class GetTumourGroupByIdResponse
{
    public responseStatus: ResponseStatus;
    public tumourGroup: TumourGroup;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetTumourGroupByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTumourGroupResponse
{
    public responseStatus: ResponseStatus;
    public tumourGroup: TumourGroup;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetTumourGroupResponse>) { (Object as any).assign(this, init); }
}

export class CreateTumourGroupResponse
{
    public responseStatus: ResponseStatus;
    public tumourGroup: TumourGroup;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateTumourGroupResponse>) { (Object as any).assign(this, init); }
}

export class GetNoteByIdResponse
{
    public responseStatus: ResponseStatus;
    public note: Note;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetNoteByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetNoteResponse
{
    public responseStatus: ResponseStatus;
    public note: Note;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetNoteResponse>) { (Object as any).assign(this, init); }
}

export class CreateNoteResponse
{
    public responseStatus: ResponseStatus;
    public note: Note;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateNoteResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientsResponse
{
    public responseStatus: ResponseStatus;
    public patients: Patient[];
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetPatientsResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientByIdResponse
{
    public responseStatus: ResponseStatus;
    public patient: Patient;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetPatientByIdResponse>) { (Object as any).assign(this, init); }
}

export class CreatePatientResponse
{
    public responseStatus: ResponseStatus;
    public patient: Patient;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreatePatientResponse>) { (Object as any).assign(this, init); }
}

export class SetPatientResponse
{
    public responseStatus: ResponseStatus;
    public patient: Patient;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetPatientResponse>) { (Object as any).assign(this, init); }
}

export class PostPatientsImportResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostPatientsImportResponse>) { (Object as any).assign(this, init); }
}

export class GetHubspotByIdResponse
{
    public responseStatus: ResponseStatus;
    public hubspot: Hubspot;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetHubspotByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetHubspotContactsToExportResponse
{
    public responseStatus: ResponseStatus;
    public contacts: Hubspot[];

    public constructor(init?: Partial<GetHubspotContactsToExportResponse>) { (Object as any).assign(this, init); }
}

export class PostHubspotContactExportResponse
{
    public responseStatus: ResponseStatus;
    public contacts: Hubspot[];

    public constructor(init?: Partial<PostHubspotContactExportResponse>) { (Object as any).assign(this, init); }
}

export class FindHubspotContactResponse
{
    public responseStatus: ResponseStatus;
    public existing: EmailUsage;
    public replacement: EmailUsage;
    public emailUpdateOptions: EmailUpdateOptions;

    public constructor(init?: Partial<FindHubspotContactResponse>) { (Object as any).assign(this, init); }
}

export class GetHubspotCurrentMembersResponse
{
    public responseStatus: ResponseStatus;
    public emails: string[];
    public scriptText: string;

    public constructor(init?: Partial<GetHubspotCurrentMembersResponse>) { (Object as any).assign(this, init); }
}

export class GetOpenClinicaUserRolesResponse
{
    public responseStatus: ResponseStatus;
    public openClinicaUserRoles: OpenClinicaUserRole[];

    public constructor(init?: Partial<GetOpenClinicaUserRolesResponse>) { (Object as any).assign(this, init); }
}

export class SetOpenClinicaUserRoleRebuildResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SetOpenClinicaUserRoleRebuildResponse>) { (Object as any).assign(this, init); }
}

export class PostOpenClinicaUserRoleChangeStatusResponse
{
    public openClinicaUserRole: OpenClinicaUserRole;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostOpenClinicaUserRoleChangeStatusResponse>) { (Object as any).assign(this, init); }
}

export class PostOpenClinicaUserRoleResendEmailResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostOpenClinicaUserRoleResendEmailResponse>) { (Object as any).assign(this, init); }
}

export class PostOpenClinicaUserRoleImportResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostOpenClinicaUserRoleImportResponse>) { (Object as any).assign(this, init); }
}

export class GetPersonnelRoleByIdResponse
{
    public responseStatus: ResponseStatus;
    public personnelRole: PersonnelRole;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetPersonnelRoleByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetPersonnelRoleResponse
{
    public responseStatus: ResponseStatus;
    public personnelRole: PersonnelRole;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetPersonnelRoleResponse>) { (Object as any).assign(this, init); }
}

export class CreatePersonnelRoleResponse
{
    public responseStatus: ResponseStatus;
    public personnelRole: PersonnelRole;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<CreatePersonnelRoleResponse>) { (Object as any).assign(this, init); }
}

export class PostPersonnelRoleResendEmailResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostPersonnelRoleResendEmailResponse>) { (Object as any).assign(this, init); }
}

export class UpdatePersonnelRoleResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UpdatePersonnelRoleResponse>) { (Object as any).assign(this, init); }
}

export class GetPersonnelResponse
{
    public responseStatus: ResponseStatus;
    public personnel: Personnel[];
    public lookups: Lookup[];

    public constructor(init?: Partial<GetPersonnelResponse>) { (Object as any).assign(this, init); }
}

export class GetPersonnelByIdResponse
{
    public responseStatus: ResponseStatus;
    public personnel: Personnel;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetPersonnelByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetPersonnelResponse
{
    public responseStatus: ResponseStatus;
    public personnel: Personnel;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetPersonnelResponse>) { (Object as any).assign(this, init); }
}

export class CreatePersonnelResponse
{
    public responseStatus: ResponseStatus;
    public personnel: Personnel;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<CreatePersonnelResponse>) { (Object as any).assign(this, init); }
}

export class SetPersonnelActiveDirectoryResponse
{
    public responseStatus: ResponseStatus;
    public personnel: Personnel;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetPersonnelActiveDirectoryResponse>) { (Object as any).assign(this, init); }
}

export class PostPersonnelResendEmailResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostPersonnelResendEmailResponse>) { (Object as any).assign(this, init); }
}

export class GetPersonnelPermissionsByIdResponse
{
    public responseStatus: ResponseStatus;
    public groupedPersonnelPermissions: PersonnelPermissionGroup[];
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetPersonnelPermissionsByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetPersonnelByRoleResponse
{
    public responseStatus: ResponseStatus;
    public rolePersonnelGroupings: RolePersonnelGrouping[];
    public lookups: Lookup[];

    public constructor(init?: Partial<GetPersonnelByRoleResponse>) { (Object as any).assign(this, init); }
}

export class GetFormTemplateFileTypesResponse
{
    public responseStatus: ResponseStatus;
    public formTemplateFileTypes: FormTemplateFileType[];

    public constructor(init?: Partial<GetFormTemplateFileTypesResponse>) { (Object as any).assign(this, init); }
}

export class GetFormTemplateFileTypeByIdResponse
{
    public responseStatus: ResponseStatus;
    public formTemplateFileType: FormTemplateFileType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetFormTemplateFileTypeByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetFormTemplateFileTypeResponse
{
    public responseStatus: ResponseStatus;
    public formTemplateFileType: FormTemplateFileType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetFormTemplateFileTypeResponse>) { (Object as any).assign(this, init); }
}

export class CreateFormTemplateFileTypeResponse
{
    public responseStatus: ResponseStatus;
    public formTemplateFileType: FormTemplateFileType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateFormTemplateFileTypeResponse>) { (Object as any).assign(this, init); }
}

export class GetFormTemplatesResponse
{
    public responseStatus: ResponseStatus;
    public formTemplates: FormTemplate[];
    public lookups: Lookup[];

    public constructor(init?: Partial<GetFormTemplatesResponse>) { (Object as any).assign(this, init); }
}

export class GetFormTemplateByIdResponse
{
    public responseStatus: ResponseStatus;
    public formTemplate: FormTemplate;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetFormTemplateByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetFormTemplateResponse
{
    public responseStatus: ResponseStatus;
    public formTemplate: FormTemplate;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetFormTemplateResponse>) { (Object as any).assign(this, init); }
}

export class CreateFormTemplateResponse
{
    public responseStatus: ResponseStatus;
    public formTemplate: FormTemplate;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateFormTemplateResponse>) { (Object as any).assign(this, init); }
}

export class GetReportingTagsResponse
{
    public responseStatus: ResponseStatus;
    public reportingTags: ReportingTag[];

    public constructor(init?: Partial<GetReportingTagsResponse>) { (Object as any).assign(this, init); }
}

export class GetReportingTagByIdResponse
{
    public responseStatus: ResponseStatus;
    public reportingTag: ReportingTag;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetReportingTagByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetReportingTagResponse
{
    public responseStatus: ResponseStatus;
    public reportingTag: ReportingTag;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetReportingTagResponse>) { (Object as any).assign(this, init); }
}

export class CreateReportingTagResponse
{
    public responseStatus: ResponseStatus;
    public reportingTag: ReportingTag;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateReportingTagResponse>) { (Object as any).assign(this, init); }
}

export class GetReportingTagCategoriesResponse
{
    public responseStatus: ResponseStatus;
    public reportingTagCategories: ReportingTagCategory[];

    public constructor(init?: Partial<GetReportingTagCategoriesResponse>) { (Object as any).assign(this, init); }
}

export class GetReportingTagCategoryByIdResponse
{
    public responseStatus: ResponseStatus;
    public reportingTagCategory: ReportingTagCategory;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetReportingTagCategoryByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetReportingTagCategoryResponse
{
    public responseStatus: ResponseStatus;
    public reportingTagCategory: ReportingTagCategory;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetReportingTagCategoryResponse>) { (Object as any).assign(this, init); }
}

export class CreateReportingTagCategoryResponse
{
    public responseStatus: ResponseStatus;
    public reportingTagCategory: ReportingTagCategory;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateReportingTagCategoryResponse>) { (Object as any).assign(this, init); }
}

export class GetTaskTypesResponse
{
    public responseStatus: ResponseStatus;
    public taskTypes: TaskType[];

    public constructor(init?: Partial<GetTaskTypesResponse>) { (Object as any).assign(this, init); }
}

export class GetTaskTypeByIdResponse
{
    public responseStatus: ResponseStatus;
    public taskType: TaskType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetTaskTypeByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTaskTypeResponse
{
    public responseStatus: ResponseStatus;
    public taskType: TaskType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetTaskTypeResponse>) { (Object as any).assign(this, init); }
}

export class CreateTaskTypeResponse
{
    public responseStatus: ResponseStatus;
    public taskType: TaskType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateTaskTypeResponse>) { (Object as any).assign(this, init); }
}

export class GetTrackerMilestonesResponse
{
    public responseStatus: ResponseStatus;
    public trackerMilestones: TrackerMilestone[];

    public constructor(init?: Partial<GetTrackerMilestonesResponse>) { (Object as any).assign(this, init); }
}

export class GetTrackerMilestoneByIdResponse
{
    public responseStatus: ResponseStatus;
    public trackerMilestone: TrackerMilestone;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetTrackerMilestoneByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrackerMilestoneResponse
{
    public responseStatus: ResponseStatus;
    public trackerMilestone: TrackerMilestone;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetTrackerMilestoneResponse>) { (Object as any).assign(this, init); }
}

export class CreateTrackerMilestoneResponse
{
    public responseStatus: ResponseStatus;
    public trackerMilestone: TrackerMilestone;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateTrackerMilestoneResponse>) { (Object as any).assign(this, init); }
}

export class GetTrackerTypesResponse
{
    public responseStatus: ResponseStatus;
    public trackerTypes: TrackerType[];

    public constructor(init?: Partial<GetTrackerTypesResponse>) { (Object as any).assign(this, init); }
}

export class GetTrackerTypeByIdResponse
{
    public responseStatus: ResponseStatus;
    public trackerType: TrackerType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetTrackerTypeByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrackerTypeResponse
{
    public responseStatus: ResponseStatus;
    public trackerType: TrackerType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetTrackerTypeResponse>) { (Object as any).assign(this, init); }
}

export class CreateTrackerTypeResponse
{
    public responseStatus: ResponseStatus;
    public trackerType: TrackerType;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateTrackerTypeResponse>) { (Object as any).assign(this, init); }
}

export class SearchResponse
{
    public responseStatus: ResponseStatus;
    public search: SearchResult;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SearchResponse>) { (Object as any).assign(this, init); }
}

export class GetStandardEmailTemplatesResponse
{
    public responseStatus: ResponseStatus;
    public standardEmailTemplates: StandardEmailTemplate[];

    public constructor(init?: Partial<GetStandardEmailTemplatesResponse>) { (Object as any).assign(this, init); }
}

export class GetStandardEmailTemplateByIdResponse
{
    public responseStatus: ResponseStatus;
    public standardEmailTemplate: StandardEmailTemplate;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetStandardEmailTemplateByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetStandardEmailTemplateResponse
{
    public responseStatus: ResponseStatus;
    public standardEmailTemplate: StandardEmailTemplate;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<SetStandardEmailTemplateResponse>) { (Object as any).assign(this, init); }
}

export class CreateStandardEmailTemplateResponse
{
    public responseStatus: ResponseStatus;
    public standardEmailTemplate: StandardEmailTemplate;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateStandardEmailTemplateResponse>) { (Object as any).assign(this, init); }
}

export class GetAppSettingsResponse
{
    public appSettings: AppSettings;

    public constructor(init?: Partial<GetAppSettingsResponse>) { (Object as any).assign(this, init); }
}

export class HelloResponse
{
    public result: string;

    public constructor(init?: Partial<HelloResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialFormsByCurrentUserResponse
{
    public responseStatus: ResponseStatus;
    public trialForms: TrialForm[];

    public constructor(init?: Partial<GetTrialFormsByCurrentUserResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialFormsResponse
{
    public responseStatus: ResponseStatus;
    public trialForms: TrialForm[];
    public lookups: Lookup[];

    public constructor(init?: Partial<GetTrialFormsResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialFormByIdResponse
{
    public responseStatus: ResponseStatus;
    public trialForm: TrialForm;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetTrialFormByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrialFormResponse
{
    public responseStatus: ResponseStatus;
    public trialForm: TrialForm;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetTrialFormResponse>) { (Object as any).assign(this, init); }
}

export class CreateTrialFormResponse
{
    public responseStatus: ResponseStatus;
    public trialForm: TrialForm;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<CreateTrialFormResponse>) { (Object as any).assign(this, init); }
}

export class CreateTrialFormVersionResponse
{
    public responseStatus: ResponseStatus;
    public trialForm: TrialForm;

    public constructor(init?: Partial<CreateTrialFormVersionResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialFormReportResponse
{
    public responseStatus: ResponseStatus;
    public measurementReports: MeasurementReport[];

    public constructor(init?: Partial<GetTrialFormReportResponse>) { (Object as any).assign(this, init); }
}

export class SetTrialFormDataJustifiedResponse
{
    public responseStatus: ResponseStatus;
    public measurementReports: MeasurementReport[];

    public constructor(init?: Partial<SetTrialFormDataJustifiedResponse>) { (Object as any).assign(this, init); }
}

export class SendReminderEmailResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SendReminderEmailResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialGroupItemByIdResponse
{
    public responseStatus: ResponseStatus;
    public trialGroupItem: TrialGroupItem;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetTrialGroupItemByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrialGroupItemResponse
{
    public responseStatus: ResponseStatus;
    public trialGroupItem: TrialGroupItem;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetTrialGroupItemResponse>) { (Object as any).assign(this, init); }
}

export class CreateTrialGroupItemResponse
{
    public responseStatus: ResponseStatus;
    public trialGroupItem: TrialGroupItem;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<CreateTrialGroupItemResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialGroupByIdResponse
{
    public responseStatus: ResponseStatus;
    public trialGroup: TrialGroup;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetTrialGroupByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrialGroupResponse
{
    public responseStatus: ResponseStatus;
    public trialGroup: TrialGroup;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetTrialGroupResponse>) { (Object as any).assign(this, init); }
}

export class CreateTrialGroupResponse
{
    public responseStatus: ResponseStatus;
    public trialGroup: TrialGroup;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<CreateTrialGroupResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialInstitutionItemByIdResponse
{
    public responseStatus: ResponseStatus;
    public trialInstitutionItem: TrialInstitutionItem;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetTrialInstitutionItemByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrialInstitutionItemResponse
{
    public responseStatus: ResponseStatus;
    public trialInstitutionItem: TrialInstitutionItem;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetTrialInstitutionItemResponse>) { (Object as any).assign(this, init); }
}

export class CreateTrialInstitutionItemResponse
{
    public responseStatus: ResponseStatus;
    public trialInstitutionItem: TrialInstitutionItem;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<CreateTrialInstitutionItemResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialInstitutionItemTemplatesResponse
{
    public responseStatus: ResponseStatus;
    public trialInstitutionItemTemplates: TrialInstitutionItemTemplate[];
    public trialInstitutionItemTemplatesByCategory: Category[];
    public lookups: Lookup[];

    public constructor(init?: Partial<GetTrialInstitutionItemTemplatesResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialInstitutionItemTemplateByIdResponse
{
    public responseStatus: ResponseStatus;
    public trialInstitutionItemTemplate: TrialInstitutionItemTemplate;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetTrialInstitutionItemTemplateByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrialInstitutionItemTemplateResponse
{
    public responseStatus: ResponseStatus;
    public trialInstitutionItemTemplate: TrialInstitutionItemTemplate;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetTrialInstitutionItemTemplateResponse>) { (Object as any).assign(this, init); }
}

export class CreateTrialInstitutionItemTemplateResponse
{
    public responseStatus: ResponseStatus;
    public trialInstitutionItemTemplate: TrialInstitutionItemTemplate;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<CreateTrialInstitutionItemTemplateResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialInstitutionsResponse
{
    public responseStatus: ResponseStatus;
    public trialInstitutions: TrialInstitution[];
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetTrialInstitutionsResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialInstitutionByIdResponse
{
    public responseStatus: ResponseStatus;
    public trialInstitution: TrialInstitution;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetTrialInstitutionByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrialInstitutionResponse
{
    public responseStatus: ResponseStatus;
    public trialInstitution: TrialInstitution;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetTrialInstitutionResponse>) { (Object as any).assign(this, init); }
}

export class CreateTrialInstitutionResponse
{
    public responseStatus: ResponseStatus;
    public trialInstitution: TrialInstitution;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateTrialInstitutionResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialItemImportByIdResponse
{
    public responseStatus: ResponseStatus;
    public trialItemImport: TrialItemImport;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetTrialItemImportByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrialItemImportResponse
{
    public responseStatus: ResponseStatus;
    public trialItemImport: TrialItemImport;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetTrialItemImportResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialItemInstanceImportByIdResponse
{
    public responseStatus: ResponseStatus;
    public trialItemInstanceImport: TrialItemInstanceImport;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetTrialItemInstanceImportByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrialItemInstanceImportResponse
{
    public responseStatus: ResponseStatus;
    public trialItemInstanceImport: TrialItemInstanceImport;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetTrialItemInstanceImportResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialItemInstancesByCurrentUserResponse
{
    public responseStatus: ResponseStatus;
    public trialItemInstances: TrialItemInstance[];

    public constructor(init?: Partial<GetTrialItemInstancesByCurrentUserResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialItemInstanceByIdResponse
{
    public responseStatus: ResponseStatus;
    public trialItemInstance: TrialItemInstance;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetTrialItemInstanceByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrialItemInstanceResponse
{
    public responseStatus: ResponseStatus;
    public trialItemInstance: TrialItemInstance;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetTrialItemInstanceResponse>) { (Object as any).assign(this, init); }
}

export class CreateTrialItemInstanceResponse
{
    public responseStatus: ResponseStatus;
    public trialItemInstance: TrialItemInstance;
    public templateTrialItems: TemplateTrialItem[];
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<CreateTrialItemInstanceResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialItemInstancesByTrialIdResponse
{
    public responseStatus: ResponseStatus;
    public trial: Trial;
    public trialItemInstanceCategories: TrialItemInstanceCategory[];
    public templateTrialItems: TemplateTrialItem[];
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetTrialItemInstancesByTrialIdResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialItemByIdResponse
{
    public responseStatus: ResponseStatus;
    public trialItem: TrialItem;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetTrialItemByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrialItemResponse
{
    public responseStatus: ResponseStatus;
    public trialItem: TrialItem;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetTrialItemResponse>) { (Object as any).assign(this, init); }
}

export class CreateTrialItemResponse
{
    public responseStatus: ResponseStatus;
    public trialItem: TrialItem;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<CreateTrialItemResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialItemUpdateByIdResponse
{
    public responseStatus: ResponseStatus;
    public trialItemUpdate: TrialItemUpdate;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetTrialItemUpdateByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrialItemUpdateResponse
{
    public responseStatus: ResponseStatus;
    public trialItemUpdate: TrialItemUpdate;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetTrialItemUpdateResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialsResponse implements IHasRoles
{
    public responseStatus: ResponseStatus;
    public trials: Trial[];
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetTrialsResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialByIdResponse
{
    public responseStatus: ResponseStatus;
    public trial: Trial;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetTrialByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrialResponse
{
    public responseStatus: ResponseStatus;
    public trial: Trial;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetTrialResponse>) { (Object as any).assign(this, init); }
}

export class CreateTrialResponse
{
    public responseStatus: ResponseStatus;
    public trial: Trial;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateTrialResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialTrackersResponse
{
    public responseStatus: ResponseStatus;
    public trialTrackers: TrialTracker[];
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetTrialTrackersResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialTrackerByIdResponse
{
    public responseStatus: ResponseStatus;
    public trialTracker: TrialTracker;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetTrialTrackerByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrialTrackerResponse
{
    public responseStatus: ResponseStatus;
    public trialTracker: TrialTracker;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetTrialTrackerResponse>) { (Object as any).assign(this, init); }
}

export class CreateTrialTrackerResponse
{
    public responseStatus: ResponseStatus;
    public trialTracker: TrialTracker;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateTrialTrackerResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialTreatmentsResponse
{
    public responseStatus: ResponseStatus;
    public trialTreatments: TrialTreatment[];
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<GetTrialTreatmentsResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialTreatmentByIdResponse
{
    public responseStatus: ResponseStatus;
    public trialTreatment: TrialTreatment;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetTrialTreatmentByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetTrialTreatmentResponse
{
    public responseStatus: ResponseStatus;
    public trialTreatment: TrialTreatment;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SetTrialTreatmentResponse>) { (Object as any).assign(this, init); }
}

export class CreateTrialTreatmentResponse
{
    public responseStatus: ResponseStatus;
    public trialTreatment: TrialTreatment;
    public formState: FormState;
    public lookups: Lookup[];
    public formProperties: FormProperty[];
    public formRoles: RoleType[];

    public constructor(init?: Partial<CreateTrialTreatmentResponse>) { (Object as any).assign(this, init); }
}

export class SyncTrialTreatmentResponse
{
    public responseStatus: ResponseStatus;
    public trialTreatments: TrialTreatment[];
    public lookups: Lookup[];
    public formProperties: FormProperty[];

    public constructor(init?: Partial<SyncTrialTreatmentResponse>) { (Object as any).assign(this, init); }
}

export class GetInstitutionResponse
{
    public responseStatus: ResponseStatus;
    public institution: ApiInstitution;

    public constructor(init?: Partial<GetInstitutionResponse>) { (Object as any).assign(this, init); }
}

export class GetTrogApiTrialInstitutionsResponse
{
    public responseStatus: ResponseStatus;
    public trial: ApiTrial;

    public constructor(init?: Partial<GetTrogApiTrialInstitutionsResponse>) { (Object as any).assign(this, init); }
}

export class GetUsersResponse
{
    public responseStatus: ResponseStatus;
    public users: ApiUser[];

    public constructor(init?: Partial<GetUsersResponse>) { (Object as any).assign(this, init); }
}

export class GetUserApiKeyResponse
{
    public responseStatus: ResponseStatus;
    public user: ApiUser;

    public constructor(init?: Partial<GetUserApiKeyResponse>) { (Object as any).assign(this, init); }
}

export class HasPermissionResponse
{
    public responseStatus: ResponseStatus;
    public hasPermission: boolean;

    public constructor(init?: Partial<HasPermissionResponse>) { (Object as any).assign(this, init); }
}

export class HasPermissionsResponse
{
    public responseStatus: ResponseStatus;
    public permissionResults: { [index: number]: boolean; };

    public constructor(init?: Partial<HasPermissionsResponse>) { (Object as any).assign(this, init); }
}

export class HasPermissionsAnyResponse
{
    public responseStatus: ResponseStatus;
    public hasPermission: boolean;

    public constructor(init?: Partial<HasPermissionsAnyResponse>) { (Object as any).assign(this, init); }
}

export class HasPermissionsAllResponse
{
    public responseStatus: ResponseStatus;
    public hasPermission: boolean;

    public constructor(init?: Partial<HasPermissionsAllResponse>) { (Object as any).assign(this, init); }
}

export class EvaluateAllPermissionsResponse
{
    public responseStatus: ResponseStatus;
    public permissionResults: KeyValuePair<PermissionQuery,boolean>[];

    public constructor(init?: Partial<EvaluateAllPermissionsResponse>) { (Object as any).assign(this, init); }
}

export class GetPermissionConfigurationsResponse
{
    public responseStatus: ResponseStatus;
    public permissionConfigurations: PermissionConfiguration[];

    public constructor(init?: Partial<GetPermissionConfigurationsResponse>) { (Object as any).assign(this, init); }
}

export class GetPermissionConfigurationByPermissionIdResponse
{
    public responseStatus: ResponseStatus;
    public permissionConfigurations: PermissionConfiguration[];

    public constructor(init?: Partial<GetPermissionConfigurationByPermissionIdResponse>) { (Object as any).assign(this, init); }
}

export class GetPermissionsForUserAndContextResponse
{
    public responseStatus: ResponseStatus;
    public permissions: Permission[];

    public constructor(init?: Partial<GetPermissionsForUserAndContextResponse>) { (Object as any).assign(this, init); }
}

export class GetUserByUserNameResponse
{
    public responseStatus: ResponseStatus;
    public apiUser: ApiUser;

    public constructor(init?: Partial<GetUserByUserNameResponse>) { (Object as any).assign(this, init); }
}

export class GetWebsiteSettingResponse
{
    public responseStatus: ResponseStatus;
    public websiteSetting: WebsiteSetting;

    public constructor(init?: Partial<GetWebsiteSettingResponse>) { (Object as any).assign(this, init); }
}

export class PostWebsiteSettingResponse
{
    public responseStatus: ResponseStatus;
    public websiteSetting: WebsiteSetting;

    public constructor(init?: Partial<PostWebsiteSettingResponse>) { (Object as any).assign(this, init); }
}

export class GetUserResponse
{
    public responseStatus: ResponseStatus;
    public user: User;
    public userSummary: UserSummary;
    public formRoles: RoleType[];

    public constructor(init?: Partial<GetUserResponse>) { (Object as any).assign(this, init); }
}

export class GetHomeResponse
{
    public responseStatus: ResponseStatus;
    public home: Home;
    public registrationFormProperty: RegistrationFormProperty;
    public lookup: Lookup;
    public fileType?: FileType;
    public registrationFormPropertyErrorMessage: RegistrationFormPropertyErrorMessage;
    public registrationFormState: RegistrationFormState;
    public registrationFormSummary: RegistrationFormSummary;
    public fileState: FileState;

    public constructor(init?: Partial<GetHomeResponse>) { (Object as any).assign(this, init); }
}

export class SendOpenClinicaRoleEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendOpenClinicaRoleEmailResponse>) { (Object as any).assign(this, init); }
}

export class UploadTempFileResponse
{
    public responseStatus: ResponseStatus;
    public guid: string;

    public constructor(init?: Partial<UploadTempFileResponse>) { (Object as any).assign(this, init); }
}

export class GetTempFileInfoResponse
{
    public responseStatus: ResponseStatus;
    public temporaryFile: TemporaryFile;

    public constructor(init?: Partial<GetTempFileInfoResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RegenerateApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @Route("/api/client/{apiKey}/institutions", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetClientInstitutions implements IReturn<GetClientInstitutionsResponse>
{
    public apiKey: string;

    public constructor(init?: Partial<GetClientInstitutions>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetClientInstitutionsResponse(); }
    public getTypeName() { return 'GetClientInstitutions'; }
}

// @Route("/api/client/{apiKey}/trialusers", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetClientTrialUsers implements IReturn<GetClientTrialUsersResponse>
{
    public apiKey: string;
    public legacyMode: boolean;

    public constructor(init?: Partial<GetClientTrialUsers>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetClientTrialUsersResponse(); }
    public getTypeName() { return 'GetClientTrialUsers'; }
}

// @Route("/api/collectioninstance/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="CollectionInstance not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetCollectionInstanceById implements IReturn<GetCollectionInstanceByIdResponse>, IGet
{
    /**
    * The primary key Id of the CollectionInstance
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the CollectionInstance", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetCollectionInstanceById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetCollectionInstanceByIdResponse(); }
    public getTypeName() { return 'GetCollectionInstanceById'; }
}

// @Route("/api/collectioninstance/save/{Id}", "POST")
// @Route("/api/collectioninstance/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetCollectionInstance implements IReturn<SetCollectionInstanceResponse>
{
    /**
    * The CollectionInstance Form data to save
    */
    // @ApiMember(DataType="CollectionInstance", Description="The CollectionInstance Form data to save", IsRequired=true, Name="collectionInstance", ParameterType="body")
    public collectionInstance: CollectionInstance;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetCollectionInstance>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetCollectionInstanceResponse(); }
    public getTypeName() { return 'SetCollectionInstance'; }
}

// @Route("/api/collection/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Collection not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetCollectionById implements IReturn<GetCollectionByIdResponse>, IGet
{
    /**
    * The primary key Id of the Collection
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Collection", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetCollectionById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetCollectionByIdResponse(); }
    public getTypeName() { return 'GetCollectionById'; }
}

// @Route("/api/collection/save/{Id}", "POST")
// @Route("/api/collection/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetCollection implements IReturn<SetCollectionResponse>
{
    /**
    * The Collection Form data to save
    */
    // @ApiMember(DataType="Collection", Description="The Collection Form data to save", IsRequired=true, Name="collection", ParameterType="body")
    public collection: Collection;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetCollectionResponse(); }
    public getTypeName() { return 'SetCollection'; }
}

// @Route("/api/collection/lock/{Id}", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class LockCollection implements IReturn<LockCollectionResponse>
{
    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    /**
    * Lock or Unlock
    */
    // @ApiMember(DataType="boolean", Description="Lock or Unlock", IsRequired=true, Name="Lock", ParameterType="body")
    public lock: boolean;

    public constructor(init?: Partial<LockCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new LockCollectionResponse(); }
    public getTypeName() { return 'LockCollection'; }
}

// @Route("/api/collection/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateCollection implements IReturn<CreateCollectionResponse>
{

    public constructor(init?: Partial<CreateCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateCollectionResponse(); }
    public getTypeName() { return 'CreateCollection'; }
}

// @Route("/api/departmentcontacts", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepartmentContacts implements IReturn<GetDepartmentContactsResponse>
{

    public constructor(init?: Partial<GetDepartmentContacts>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDepartmentContactsResponse(); }
    public getTypeName() { return 'GetDepartmentContacts'; }
}

// @Route("/api/departmentcontact/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Department Contact not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepartmentContactById implements IReturn<GetDepartmentContactByIdResponse>, IGet
{
    /**
    * The primary key Id of the department contact
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the department contact", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetDepartmentContactById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDepartmentContactByIdResponse(); }
    public getTypeName() { return 'GetDepartmentContactById'; }
}

// @Route("/api/departmentcontact/save/{Id}", "POST")
// @Route("/api/departmentcontact/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDepartmentContact implements IReturn<SetDepartmentContactResponse>
{
    /**
    * The Department Contact Form data to save
    */
    // @ApiMember(DataType="DepartmentContact", Description="The Department Contact Form data to save", IsRequired=true, Name="departmentcontact", ParameterType="body")
    public departmentContact: DepartmentContact;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetDepartmentContact>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetDepartmentContactResponse(); }
    public getTypeName() { return 'SetDepartmentContact'; }
}

// @Route("/api/departmentcontact/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateDepartmentContact implements IReturn<CreateDepartmentContactResponse>
{
    public departmentId: number;

    public constructor(init?: Partial<CreateDepartmentContact>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateDepartmentContactResponse(); }
    public getTypeName() { return 'CreateDepartmentContact'; }
}

// @Route("/api/departments", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepartments implements IReturn<GetDepartmentsResponse>
{

    public constructor(init?: Partial<GetDepartments>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDepartmentsResponse(); }
    public getTypeName() { return 'GetDepartments'; }
}

// @Route("/api/department/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Trial Item not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepartmentById implements IReturn<GetDepartmentByIdResponse>, IGet
{
    /**
    * The primary key Id of the department
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the department", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetDepartmentById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDepartmentByIdResponse(); }
    public getTypeName() { return 'GetDepartmentById'; }
}

// @Route("/api/department/save/{Id}", "POST")
// @Route("/api/department/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDepartment implements IReturn<SetDepartmentResponse>
{
    /**
    * The Department Form data to save
    */
    // @ApiMember(DataType="Department", Description="The Department Form data to save", IsRequired=true, Name="department", ParameterType="body")
    public department: Department;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetDepartment>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetDepartmentResponse(); }
    public getTypeName() { return 'SetDepartment'; }
}

// @Route("/api/department/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateDepartment implements IReturn<CreateDepartmentResponse>
{
    public institutionId: number;

    public constructor(init?: Partial<CreateDepartment>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateDepartmentResponse(); }
    public getTypeName() { return 'CreateDepartment'; }
}

// @Route("/api/dashboard/institutionforms", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetInstitutionFormsByCurrentUser implements IReturn<GetInstitutionFormsByCurrentUserResponse>
{

    public constructor(init?: Partial<GetInstitutionFormsByCurrentUser>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetInstitutionFormsByCurrentUserResponse(); }
    public getTypeName() { return 'GetInstitutionFormsByCurrentUser'; }
}

// @Route("/api/institutionforms", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetInstitutionForms implements IReturn<GetInstitutionFormsResponse>
{
    public institutionId: number;

    public constructor(init?: Partial<GetInstitutionForms>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetInstitutionFormsResponse(); }
    public getTypeName() { return 'GetInstitutionForms'; }
}

// @Route("/api/institutionform/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Institution Form not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetInstitutionFormById implements IReturn<GetInstitutionFormByIdResponse>, IGet
{
    /**
    * The primary key Id of the institution form
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the institution form", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetInstitutionFormById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetInstitutionFormByIdResponse(); }
    public getTypeName() { return 'GetInstitutionFormById'; }
}

// @Route("/api/institutionform/save/{Id}", "POST")
// @Route("/api/institutionform/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetInstitutionForm implements IReturn<SetInstitutionFormResponse>
{
    /**
    * The institution form data to save
    */
    // @ApiMember(DataType="InstitutionForm", Description="The institution form data to save", IsRequired=true, Name="InstitutionForm", ParameterType="body")
    public institutionForm: InstitutionForm;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetInstitutionForm>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetInstitutionFormResponse(); }
    public getTypeName() { return 'SetInstitutionForm'; }
}

// @Route("/api/institutionform/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateInstitutionForm implements IReturn<CreateInstitutionFormResponse>
{
    public institutionId: number;

    public constructor(init?: Partial<CreateInstitutionForm>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateInstitutionFormResponse(); }
    public getTypeName() { return 'CreateInstitutionForm'; }
}

// @Route("/api/institutionform/createversion", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateInstitutionFormVersion implements IReturn<CreateInstitutionFormVersionResponse>
{
    public institutionFormId: number;
    public version: string;

    public constructor(init?: Partial<CreateInstitutionFormVersion>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateInstitutionFormVersionResponse(); }
    public getTypeName() { return 'CreateInstitutionFormVersion'; }
}

// @Route("/api/institutions", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetInstitutions implements IReturn<GetInstitutionsResponse>
{

    public constructor(init?: Partial<GetInstitutions>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetInstitutionsResponse(); }
    public getTypeName() { return 'GetInstitutions'; }
}

// @Route("/api/institution/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Institution not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetInstitutionById implements IReturn<GetInstitutionByIdResponse>, IGet
{
    /**
    * The primary key Id of the Institution
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Institution", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetInstitutionById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetInstitutionByIdResponse(); }
    public getTypeName() { return 'GetInstitutionById'; }
}

// @Route("/api/institution/save/{Id}", "POST")
// @Route("/api/institution/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetInstitution implements IReturn<SetInstitutionResponse>
{
    /**
    * The Institution Form data to save
    */
    // @ApiMember(DataType="Institution", Description="The Institution Form data to save", IsRequired=true, Name="institution", ParameterType="body")
    public institution: Institution;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetInstitution>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetInstitutionResponse(); }
    public getTypeName() { return 'SetInstitution'; }
}

// @Route("/api/institution/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateInstitution implements IReturn<CreateInstitutionResponse>
{

    public constructor(init?: Partial<CreateInstitution>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateInstitutionResponse(); }
    public getTypeName() { return 'CreateInstitution'; }
}

// @Route("/api/itemcomponents", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetItemComponents implements IReturn<GetItemComponentsResponse>
{
    public itemComponentTypeId: number;
    public qaItemComponents: boolean;

    public constructor(init?: Partial<GetItemComponents>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetItemComponentsResponse(); }
    public getTypeName() { return 'GetItemComponents'; }
}

// @Route("/api/itemcomponent/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="ItemComponent not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetItemComponentById implements IReturn<GetItemComponentByIdResponse>, IGet
{
    /**
    * The primary key Id of the item component
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the item component", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public formProperties: FormProperty[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetItemComponentById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetItemComponentByIdResponse(); }
    public getTypeName() { return 'GetItemComponentById'; }
}

// @Route("/api/itemcomponent/save/{Id}", "POST")
// @Route("/api/itemcomponent/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetItemComponent implements IReturn<SetItemComponentResponse>
{
    /**
    * The Pre-registration Form data to save
    */
    // @ApiMember(DataType="PreRegistrationForm", Description="The Pre-registration Form data to save", IsRequired=true, Name="preRegistrationForm", ParameterType="body")
    public itemComponent: ItemComponent;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetItemComponent>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetItemComponentResponse(); }
    public getTypeName() { return 'SetItemComponent'; }
}

// @Route("/api/itemcomponent/create/{CloneId}", "POST")
// @Route("/api/itemcomponent/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateItemComponent implements IReturn<CreateItemComponentResponse>
{
    public cloneId?: number;

    public constructor(init?: Partial<CreateItemComponent>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateItemComponentResponse(); }
    public getTypeName() { return 'CreateItemComponent'; }
}

// @Route("/api/allocatedwps", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetAllocatedWPs implements IReturn<GetAllocatedWPsResponse>
{

    public constructor(init?: Partial<GetAllocatedWPs>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAllocatedWPsResponse(); }
    public getTypeName() { return 'GetAllocatedWPs'; }
}

// @Route("/api/allocatedwp/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="AllocatedWP not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetAllocatedWPById implements IReturn<GetAllocatedWPByIdResponse>, IGet
{
    /**
    * The primary key Id of the allocatedwps
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the allocatedwps", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetAllocatedWPById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAllocatedWPByIdResponse(); }
    public getTypeName() { return 'GetAllocatedWPById'; }
}

// @Route("/api/allocatedwp/save/{Id}", "POST")
// @Route("/api/allocatedwp/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetAllocatedWP implements IReturn<SetAllocatedWPResponse>
{
    /**
    * The AllocatedWP Form data to save
    */
    // @ApiMember(DataType="AllocatedWP", Description="The AllocatedWP Form data to save", IsRequired=true, Name="AllocatedWP", ParameterType="body")
    public allocatedWP: AllocatedWP;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetAllocatedWP>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetAllocatedWPResponse(); }
    public getTypeName() { return 'SetAllocatedWP'; }
}

// @Route("/api/allocatedwp/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateAllocatedWP implements IReturn<CreateAllocatedWPResponse>
{

    public constructor(init?: Partial<CreateAllocatedWP>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateAllocatedWPResponse(); }
    public getTypeName() { return 'CreateAllocatedWP'; }
}

// @Route("/api/categories", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetCategories implements IReturn<GetCategoriesResponse>
{

    public constructor(init?: Partial<GetCategories>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetCategoriesResponse(); }
    public getTypeName() { return 'GetCategories'; }
}

// @Route("/api/category/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Category not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetCategoryById implements IReturn<GetCategoryByIdResponse>, IGet
{
    /**
    * The primary key Id of the categories
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the categories", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetCategoryById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetCategoryByIdResponse(); }
    public getTypeName() { return 'GetCategoryById'; }
}

// @Route("/api/category/save/{Id}", "POST")
// @Route("/api/category/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetCategory implements IReturn<SetCategoryResponse>
{
    /**
    * The Category Form data to save
    */
    // @ApiMember(DataType="Category", Description="The Category Form data to save", IsRequired=true, Name="Category", ParameterType="body")
    public category: Category;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetCategory>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetCategoryResponse(); }
    public getTypeName() { return 'SetCategory'; }
}

// @Route("/api/category/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateCategory implements IReturn<CreateCategoryResponse>
{

    public constructor(init?: Partial<CreateCategory>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateCategoryResponse(); }
    public getTypeName() { return 'CreateCategory'; }
}

// @Route("/api/collaboratinggroups", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetCollaboratingGroups implements IReturn<GetCollaboratingGroupsResponse>
{

    public constructor(init?: Partial<GetCollaboratingGroups>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetCollaboratingGroupsResponse(); }
    public getTypeName() { return 'GetCollaboratingGroups'; }
}

// @Route("/api/collaboratinggroup/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="CollaboratingGroup not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetCollaboratingGroupById implements IReturn<GetCollaboratingGroupByIdResponse>, IGet
{
    /**
    * The primary key Id of the collaboratinggroups
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the collaboratinggroups", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetCollaboratingGroupById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetCollaboratingGroupByIdResponse(); }
    public getTypeName() { return 'GetCollaboratingGroupById'; }
}

// @Route("/api/collaboratinggroup/save/{Id}", "POST")
// @Route("/api/collaboratinggroup/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetCollaboratingGroup implements IReturn<SetCollaboratingGroupResponse>
{
    /**
    * The CollaboratingGroup Form data to save
    */
    // @ApiMember(DataType="CollaboratingGroup", Description="The CollaboratingGroup Form data to save", IsRequired=true, Name="CollaboratingGroup", ParameterType="body")
    public collaboratingGroup: CollaboratingGroup;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetCollaboratingGroup>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetCollaboratingGroupResponse(); }
    public getTypeName() { return 'SetCollaboratingGroup'; }
}

// @Route("/api/collaboratinggroup/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateCollaboratingGroup implements IReturn<CreateCollaboratingGroupResponse>
{

    public constructor(init?: Partial<CreateCollaboratingGroup>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateCollaboratingGroupResponse(); }
    public getTypeName() { return 'CreateCollaboratingGroup'; }
}

// @Route("/api/categories", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetCountries implements IReturn<GetCountriesResponse>
{

    public constructor(init?: Partial<GetCountries>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetCountriesResponse(); }
    public getTypeName() { return 'GetCountries'; }
}

// @Route("/api/country/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Country not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetCountryById implements IReturn<GetCountryByIdResponse>, IGet
{
    /**
    * The primary key Id of the categories
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the categories", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetCountryById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetCountryByIdResponse(); }
    public getTypeName() { return 'GetCountryById'; }
}

// @Route("/api/country/save/{Id}", "POST")
// @Route("/api/country/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetCountry implements IReturn<SetCountryResponse>
{
    /**
    * The Country Form data to save
    */
    // @ApiMember(DataType="Country", Description="The Country Form data to save", IsRequired=true, Name="Country", ParameterType="body")
    public country: Country;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetCountry>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetCountryResponse(); }
    public getTypeName() { return 'SetCountry'; }
}

// @Route("/api/country/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateCountry implements IReturn<CreateCountryResponse>
{

    public constructor(init?: Partial<CreateCountry>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateCountryResponse(); }
    public getTypeName() { return 'CreateCountry'; }
}

// @Route("/api/facilityalliancemembers", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetFacilityAllianceMembers implements IReturn<GetFacilityAllianceMembersResponse>
{

    public constructor(init?: Partial<GetFacilityAllianceMembers>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetFacilityAllianceMembersResponse(); }
    public getTypeName() { return 'GetFacilityAllianceMembers'; }
}

// @Route("/api/facilityalliancemember/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="FacilityAllianceMember not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetFacilityAllianceMemberById implements IReturn<GetFacilityAllianceMemberByIdResponse>, IGet
{
    /**
    * The primary key Id of the facilityalliancemembers
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the facilityalliancemembers", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetFacilityAllianceMemberById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetFacilityAllianceMemberByIdResponse(); }
    public getTypeName() { return 'GetFacilityAllianceMemberById'; }
}

// @Route("/api/facilityalliancemember/save/{Id}", "POST")
// @Route("/api/facilityalliancemember/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetFacilityAllianceMember implements IReturn<SetFacilityAllianceMemberResponse>
{
    /**
    * The FacilityAllianceMember Form data to save
    */
    // @ApiMember(DataType="FacilityAllianceMember", Description="The FacilityAllianceMember Form data to save", IsRequired=true, Name="FacilityAllianceMember", ParameterType="body")
    public facilityAllianceMember: FacilityAllianceMember;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetFacilityAllianceMember>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetFacilityAllianceMemberResponse(); }
    public getTypeName() { return 'SetFacilityAllianceMember'; }
}

// @Route("/api/facilityalliancemember/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateFacilityAllianceMember implements IReturn<CreateFacilityAllianceMemberResponse>
{

    public constructor(init?: Partial<CreateFacilityAllianceMember>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateFacilityAllianceMemberResponse(); }
    public getTypeName() { return 'CreateFacilityAllianceMember'; }
}

// @Route("/api/genders", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetGenders implements IReturn<GetGendersResponse>
{

    public constructor(init?: Partial<GetGenders>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetGendersResponse(); }
    public getTypeName() { return 'GetGenders'; }
}

// @Route("/api/gender/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Gender not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetGenderById implements IReturn<GetGenderByIdResponse>, IGet
{
    /**
    * The primary key Id of the genders
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the genders", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetGenderById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetGenderByIdResponse(); }
    public getTypeName() { return 'GetGenderById'; }
}

// @Route("/api/gender/save/{Id}", "POST")
// @Route("/api/gender/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetGender implements IReturn<SetGenderResponse>
{
    /**
    * The Gender Form data to save
    */
    // @ApiMember(DataType="Gender", Description="The Gender Form data to save", IsRequired=true, Name="Gender", ParameterType="body")
    public gender: Gender;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetGender>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetGenderResponse(); }
    public getTypeName() { return 'SetGender'; }
}

// @Route("/api/gender/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateGender implements IReturn<CreateGenderResponse>
{

    public constructor(init?: Partial<CreateGender>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateGenderResponse(); }
    public getTypeName() { return 'CreateGender'; }
}

// @Route("/api/groupitem/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Group Item not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetGroupItemById implements IReturn<GetGroupItemByIdResponse>, IGet
{
    /**
    * The primary key Id of the group item
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the group item", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetGroupItemById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetGroupItemByIdResponse(); }
    public getTypeName() { return 'GetGroupItemById'; }
}

// @Route("/api/groupItem/save/{Id}", "POST")
// @Route("/api/groupItem/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetGroupItem implements IReturn<SetGroupItemResponse>
{
    /**
    * The GroupItem Form data to save
    */
    // @ApiMember(DataType="GroupItem", Description="The GroupItem Form data to save", IsRequired=true, Name="groupItem", ParameterType="body")
    public groupItem: GroupItem;

    public collection: Collection;
    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetGroupItem>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetGroupItemResponse(); }
    public getTypeName() { return 'SetGroupItem'; }
}

// @Route("/api/groupItem/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateGroupItem implements IReturn<CreateGroupItemResponse>
{
    public groupId: number;

    public constructor(init?: Partial<CreateGroupItem>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateGroupItemResponse(); }
    public getTypeName() { return 'CreateGroupItem'; }
}

// @Route("/api/groups", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetGroups implements IReturn<GetGroupsResponse>
{
    public showInActive: boolean;

    public constructor(init?: Partial<GetGroups>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetGroupsResponse(); }
    public getTypeName() { return 'GetGroups'; }
}

// @Route("/api/group/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Group not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetGroupById implements IReturn<GetGroupByIdResponse>, IGet
{
    /**
    * The primary key Id of the Group
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Group", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetGroupById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetGroupByIdResponse(); }
    public getTypeName() { return 'GetGroupById'; }
}

// @Route("/api/group/save/{Id}", "POST")
// @Route("/api/group/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetGroup implements IReturn<SetGroupResponse>
{
    /**
    * The Group Form data to save
    */
    // @ApiMember(DataType="Group", Description="The Group Form data to save", IsRequired=true, Name="group", ParameterType="body")
    public group: Group;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetGroup>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetGroupResponse(); }
    public getTypeName() { return 'SetGroup'; }
}

// @Route("/api/group/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateGroup implements IReturn<CreateGroupResponse>
{

    public constructor(init?: Partial<CreateGroup>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateGroupResponse(); }
    public getTypeName() { return 'CreateGroup'; }
}

// @Route("/api/institutioncoding", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetInstitutionCodings implements IReturn<GetInstitutionCodingsResponse>
{

    public constructor(init?: Partial<GetInstitutionCodings>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetInstitutionCodingsResponse(); }
    public getTypeName() { return 'GetInstitutionCodings'; }
}

// @Route("/api/institutioncoding/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="InstitutionCoding not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetInstitutionCodingById implements IReturn<GetInstitutionCodingByIdResponse>, IGet
{
    /**
    * The primary key Id of the institutioncoding
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the institutioncoding", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetInstitutionCodingById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetInstitutionCodingByIdResponse(); }
    public getTypeName() { return 'GetInstitutionCodingById'; }
}

// @Route("/api/institutioncoding/save/{Id}", "POST")
// @Route("/api/institutioncoding/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetInstitutionCoding implements IReturn<SetInstitutionCodingResponse>
{
    /**
    * The InstitutionCoding Form data to save
    */
    // @ApiMember(DataType="InstitutionCoding", Description="The InstitutionCoding Form data to save", IsRequired=true, Name="InstitutionCoding", ParameterType="body")
    public institutionCoding: InstitutionCoding;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetInstitutionCoding>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetInstitutionCodingResponse(); }
    public getTypeName() { return 'SetInstitutionCoding'; }
}

// @Route("/api/institutioncoding/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateInstitutionCoding implements IReturn<CreateInstitutionCodingResponse>
{

    public constructor(init?: Partial<CreateInstitutionCoding>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateInstitutionCodingResponse(); }
    public getTypeName() { return 'CreateInstitutionCoding'; }
}

// @Route("/api/institutionTypes", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetInstitutionTypes implements IReturn<GetInstitutionTypesResponse>
{

    public constructor(init?: Partial<GetInstitutionTypes>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetInstitutionTypesResponse(); }
    public getTypeName() { return 'GetInstitutionTypes'; }
}

// @Route("/api/institutionType/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="InstitutionType not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetInstitutionTypeById implements IReturn<GetInstitutionTypeByIdResponse>, IGet
{
    /**
    * The primary key Id of the institutionTypes
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the institutionTypes", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetInstitutionTypeById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetInstitutionTypeByIdResponse(); }
    public getTypeName() { return 'GetInstitutionTypeById'; }
}

// @Route("/api/institutionType/save/{Id}", "POST")
// @Route("/api/institutionType/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetInstitutionType implements IReturn<SetInstitutionTypeResponse>
{
    /**
    * The InstitutionType Form data to save
    */
    // @ApiMember(DataType="InstitutionType", Description="The InstitutionType Form data to save", IsRequired=true, Name="InstitutionType", ParameterType="body")
    public institutionType: InstitutionType;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetInstitutionType>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetInstitutionTypeResponse(); }
    public getTypeName() { return 'SetInstitutionType'; }
}

// @Route("/api/institutionType/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateInstitutionType implements IReturn<CreateInstitutionTypeResponse>
{

    public constructor(init?: Partial<CreateInstitutionType>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateInstitutionTypeResponse(); }
    public getTypeName() { return 'CreateInstitutionType'; }
}

// @Route("/api/interests", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetInterests implements IReturn<GetInterestsResponse>
{

    public constructor(init?: Partial<GetInterests>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetInterestsResponse(); }
    public getTypeName() { return 'GetInterests'; }
}

// @Route("/api/interest/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Interest not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetInterestById implements IReturn<GetInterestByIdResponse>, IGet
{
    /**
    * The primary key Id of the interests
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the interests", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetInterestById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetInterestByIdResponse(); }
    public getTypeName() { return 'GetInterestById'; }
}

// @Route("/api/interest/save/{Id}", "POST")
// @Route("/api/interest/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetInterest implements IReturn<SetInterestResponse>
{
    /**
    * The Interest Form data to save
    */
    // @ApiMember(DataType="Interest", Description="The Interest Form data to save", IsRequired=true, Name="Interest", ParameterType="body")
    public interest: Interest;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetInterest>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetInterestResponse(); }
    public getTypeName() { return 'SetInterest'; }
}

// @Route("/api/interest/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateInterest implements IReturn<CreateInterestResponse>
{

    public constructor(init?: Partial<CreateInterest>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateInterestResponse(); }
    public getTypeName() { return 'CreateInterest'; }
}

// @Route("/api/involvements", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetInvolvements implements IReturn<GetInvolvementsResponse>
{

    public constructor(init?: Partial<GetInvolvements>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetInvolvementsResponse(); }
    public getTypeName() { return 'GetInvolvements'; }
}

// @Route("/api/involvement/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Involvement not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetInvolvementById implements IReturn<GetInvolvementByIdResponse>, IGet
{
    /**
    * The primary key Id of the involvements
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the involvements", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetInvolvementById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetInvolvementByIdResponse(); }
    public getTypeName() { return 'GetInvolvementById'; }
}

// @Route("/api/involvement/save/{Id}", "POST")
// @Route("/api/involvement/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetInvolvement implements IReturn<SetInvolvementResponse>
{
    /**
    * The Involvement Form data to save
    */
    // @ApiMember(DataType="Involvement", Description="The Involvement Form data to save", IsRequired=true, Name="Involvement", ParameterType="body")
    public involvement: Involvement;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetInvolvement>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetInvolvementResponse(); }
    public getTypeName() { return 'SetInvolvement'; }
}

// @Route("/api/involvement/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateInvolvement implements IReturn<CreateInvolvementResponse>
{

    public constructor(init?: Partial<CreateInvolvement>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateInvolvementResponse(); }
    public getTypeName() { return 'CreateInvolvement'; }
}

// @Route("/api/itemcomponenttype", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetItemComponentTypes implements IReturn<GetItemComponentTypesResponse>
{

    public constructor(init?: Partial<GetItemComponentTypes>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetItemComponentTypesResponse(); }
    public getTypeName() { return 'GetItemComponentTypes'; }
}

// @Route("/api/itemcomponenttype/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="ItemComponentType not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetItemComponentTypeById implements IReturn<GetItemComponentTypeByIdResponse>, IGet
{
    /**
    * The primary key Id of the itemcomponenttype
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the itemcomponenttype", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetItemComponentTypeById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetItemComponentTypeByIdResponse(); }
    public getTypeName() { return 'GetItemComponentTypeById'; }
}

// @Route("/api/itemcomponenttype/save/{Id}", "POST")
// @Route("/api/itemcomponenttype/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetItemComponentType implements IReturn<SetItemComponentTypeResponse>
{
    /**
    * The ItemComponentType Form data to save
    */
    // @ApiMember(DataType="ItemComponentType", Description="The ItemComponentType Form data to save", IsRequired=true, Name="ItemComponentType", ParameterType="body")
    public itemComponentType: ItemComponentType;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetItemComponentType>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetItemComponentTypeResponse(); }
    public getTypeName() { return 'SetItemComponentType'; }
}

// @Route("/api/itemcomponenttype/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateItemComponentType implements IReturn<CreateItemComponentTypeResponse>
{

    public constructor(init?: Partial<CreateItemComponentType>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateItemComponentTypeResponse(); }
    public getTypeName() { return 'CreateItemComponentType'; }
}

// @Route("/api/keycontacts", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetKeyContacts implements IReturn<GetKeyContactsResponse>
{

    public constructor(init?: Partial<GetKeyContacts>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetKeyContactsResponse(); }
    public getTypeName() { return 'GetKeyContacts'; }
}

// @Route("/api/keycontact/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="KeyContact not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetKeyContactById implements IReturn<GetKeyContactByIdResponse>, IGet
{
    /**
    * The primary key Id of the keycontacts
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the keycontacts", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetKeyContactById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetKeyContactByIdResponse(); }
    public getTypeName() { return 'GetKeyContactById'; }
}

// @Route("/api/keycontact/save/{Id}", "POST")
// @Route("/api/keycontact/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetKeyContact implements IReturn<SetKeyContactResponse>
{
    /**
    * The KeyContact Form data to save
    */
    // @ApiMember(DataType="KeyContact", Description="The KeyContact Form data to save", IsRequired=true, Name="KeyContact", ParameterType="body")
    public keyContact: KeyContact;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetKeyContact>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetKeyContactResponse(); }
    public getTypeName() { return 'SetKeyContact'; }
}

// @Route("/api/keycontact/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateKeyContact implements IReturn<CreateKeyContactResponse>
{

    public constructor(init?: Partial<CreateKeyContact>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateKeyContactResponse(); }
    public getTypeName() { return 'CreateKeyContact'; }
}

// @Route("/api/membershiptypes", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetMembershipTypes implements IReturn<GetMembershipTypesResponse>
{

    public constructor(init?: Partial<GetMembershipTypes>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetMembershipTypesResponse(); }
    public getTypeName() { return 'GetMembershipTypes'; }
}

// @Route("/api/membershiptype/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="MembershipType not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetMembershipTypeById implements IReturn<GetMembershipTypeByIdResponse>, IGet
{
    /**
    * The primary key Id of the membershiptypes
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the membershiptypes", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetMembershipTypeById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetMembershipTypeByIdResponse(); }
    public getTypeName() { return 'GetMembershipTypeById'; }
}

// @Route("/api/membershiptype/save/{Id}", "POST")
// @Route("/api/membershiptype/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetMembershipType implements IReturn<SetMembershipTypeResponse>
{
    /**
    * The MembershipType Form data to save
    */
    // @ApiMember(DataType="MembershipType", Description="The MembershipType Form data to save", IsRequired=true, Name="MembershipType", ParameterType="body")
    public membershipType: MembershipType;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetMembershipType>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetMembershipTypeResponse(); }
    public getTypeName() { return 'SetMembershipType'; }
}

// @Route("/api/membershiptype/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateMembershipType implements IReturn<CreateMembershipTypeResponse>
{

    public constructor(init?: Partial<CreateMembershipType>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateMembershipTypeResponse(); }
    public getTypeName() { return 'CreateMembershipType'; }
}

// @Route("/api/notetype", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetNoteTypes implements IReturn<GetNoteTypesResponse>
{

    public constructor(init?: Partial<GetNoteTypes>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetNoteTypesResponse(); }
    public getTypeName() { return 'GetNoteTypes'; }
}

// @Route("/api/notetype/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="NoteType not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetNoteTypeById implements IReturn<GetNoteTypeByIdResponse>, IGet
{
    /**
    * The primary key Id of the notetype
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the notetype", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetNoteTypeById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetNoteTypeByIdResponse(); }
    public getTypeName() { return 'GetNoteTypeById'; }
}

// @Route("/api/notetype/save/{Id}", "POST")
// @Route("/api/notetype/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetNoteType implements IReturn<SetNoteTypeResponse>
{
    /**
    * The NoteType Form data to save
    */
    // @ApiMember(DataType="NoteType", Description="The NoteType Form data to save", IsRequired=true, Name="NoteType", ParameterType="body")
    public noteType: NoteType;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetNoteType>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetNoteTypeResponse(); }
    public getTypeName() { return 'SetNoteType'; }
}

// @Route("/api/notetype/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateNoteType implements IReturn<CreateNoteTypeResponse>
{

    public constructor(init?: Partial<CreateNoteType>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateNoteTypeResponse(); }
    public getTypeName() { return 'CreateNoteType'; }
}

// @Route("/api/professionaldisciplines", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetProfessionalDisciplines implements IReturn<GetProfessionalDisciplinesResponse>
{

    public constructor(init?: Partial<GetProfessionalDisciplines>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetProfessionalDisciplinesResponse(); }
    public getTypeName() { return 'GetProfessionalDisciplines'; }
}

// @Route("/api/professionaldiscipline/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="ProfessionalDiscipline not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetProfessionalDisciplineById implements IReturn<GetProfessionalDisciplineByIdResponse>, IGet
{
    /**
    * The primary key Id of the professionaldisciplines
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the professionaldisciplines", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetProfessionalDisciplineById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetProfessionalDisciplineByIdResponse(); }
    public getTypeName() { return 'GetProfessionalDisciplineById'; }
}

// @Route("/api/professionaldiscipline/save/{Id}", "POST")
// @Route("/api/professionaldiscipline/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetProfessionalDiscipline implements IReturn<SetProfessionalDisciplineResponse>
{
    /**
    * The ProfessionalDiscipline Form data to save
    */
    // @ApiMember(DataType="ProfessionalDiscipline", Description="The ProfessionalDiscipline Form data to save", IsRequired=true, Name="ProfessionalDiscipline", ParameterType="body")
    public professionalDiscipline: ProfessionalDiscipline;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetProfessionalDiscipline>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetProfessionalDisciplineResponse(); }
    public getTypeName() { return 'SetProfessionalDiscipline'; }
}

// @Route("/api/professionaldiscipline/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateProfessionalDiscipline implements IReturn<CreateProfessionalDisciplineResponse>
{

    public constructor(init?: Partial<CreateProfessionalDiscipline>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateProfessionalDisciplineResponse(); }
    public getTypeName() { return 'CreateProfessionalDiscipline'; }
}

// @Route("/api/role", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetRoles implements IReturn<GetRolesResponse>
{

    public constructor(init?: Partial<GetRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetRolesResponse(); }
    public getTypeName() { return 'GetRoles'; }
}

// @Route("/api/role/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Role not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetRoleById implements IReturn<GetRoleByIdResponse>, IGet
{
    /**
    * The primary key Id of the role
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the role", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetRoleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetRoleByIdResponse(); }
    public getTypeName() { return 'GetRoleById'; }
}

// @Route("/api/role/save/{Id}", "POST")
// @Route("/api/role/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetRole implements IReturn<SetRoleResponse>
{
    /**
    * The Role Form data to save
    */
    // @ApiMember(DataType="Role", Description="The Role Form data to save", IsRequired=true, Name="Role", ParameterType="body")
    public role: Role;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetRole>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetRoleResponse(); }
    public getTypeName() { return 'SetRole'; }
}

// @Route("/api/role/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateRole implements IReturn<CreateRoleResponse>
{

    public constructor(init?: Partial<CreateRole>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateRoleResponse(); }
    public getTypeName() { return 'CreateRole'; }
}

// @Route("/api/salutations", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetSalutations implements IReturn<GetSalutationsResponse>
{

    public constructor(init?: Partial<GetSalutations>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSalutationsResponse(); }
    public getTypeName() { return 'GetSalutations'; }
}

// @Route("/api/salutation/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Salutation not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetSalutationById implements IReturn<GetSalutationByIdResponse>, IGet
{
    /**
    * The primary key Id of the salutations
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the salutations", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetSalutationById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSalutationByIdResponse(); }
    public getTypeName() { return 'GetSalutationById'; }
}

// @Route("/api/salutation/save/{Id}", "POST")
// @Route("/api/salutation/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetSalutation implements IReturn<SetSalutationResponse>
{
    /**
    * The Salutation Form data to save
    */
    // @ApiMember(DataType="Salutation", Description="The Salutation Form data to save", IsRequired=true, Name="Salutation", ParameterType="body")
    public salutation: Salutation;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetSalutation>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetSalutationResponse(); }
    public getTypeName() { return 'SetSalutation'; }
}

// @Route("/api/salutation/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateSalutation implements IReturn<CreateSalutationResponse>
{

    public constructor(init?: Partial<CreateSalutation>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateSalutationResponse(); }
    public getTypeName() { return 'CreateSalutation'; }
}

// @Route("/api/siteLocations", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetSiteLocations implements IReturn<GetSiteLocationsResponse>
{

    public constructor(init?: Partial<GetSiteLocations>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSiteLocationsResponse(); }
    public getTypeName() { return 'GetSiteLocations'; }
}

// @Route("/api/siteLocation/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="SiteLocation not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetSiteLocationById implements IReturn<GetSiteLocationByIdResponse>, IGet
{
    /**
    * The primary key Id of the siteLocations
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the siteLocations", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetSiteLocationById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSiteLocationByIdResponse(); }
    public getTypeName() { return 'GetSiteLocationById'; }
}

// @Route("/api/siteLocation/save/{Id}", "POST")
// @Route("/api/siteLocation/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetSiteLocation implements IReturn<SetSiteLocationResponse>
{
    /**
    * The SiteLocation Form data to save
    */
    // @ApiMember(DataType="SiteLocation", Description="The SiteLocation Form data to save", IsRequired=true, Name="SiteLocation", ParameterType="body")
    public siteLocation: SiteLocation;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetSiteLocation>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetSiteLocationResponse(); }
    public getTypeName() { return 'SetSiteLocation'; }
}

// @Route("/api/siteLocation/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateSiteLocation implements IReturn<CreateSiteLocationResponse>
{

    public constructor(init?: Partial<CreateSiteLocation>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateSiteLocationResponse(); }
    public getTypeName() { return 'CreateSiteLocation'; }
}

// @Route("/api/states", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetStates implements IReturn<GetStatesResponse>
{

    public constructor(init?: Partial<GetStates>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetStatesResponse(); }
    public getTypeName() { return 'GetStates'; }
}

// @Route("/api/state/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="State not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetStateById implements IReturn<GetStateByIdResponse>, IGet
{
    /**
    * The primary key Id of the states
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the states", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetStateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetStateByIdResponse(); }
    public getTypeName() { return 'GetStateById'; }
}

// @Route("/api/state/save/{Id}", "POST")
// @Route("/api/state/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetState implements IReturn<SetStateResponse>
{
    /**
    * The State Form data to save
    */
    // @ApiMember(DataType="State", Description="The State Form data to save", IsRequired=true, Name="State", ParameterType="body")
    public state: State;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetState>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetStateResponse(); }
    public getTypeName() { return 'SetState'; }
}

// @Route("/api/state/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateState implements IReturn<CreateStateResponse>
{

    public constructor(init?: Partial<CreateState>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateStateResponse(); }
    public getTypeName() { return 'CreateState'; }
}

// @Route("/api/TemplateTrialItems", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTemplateTrialItems implements IReturn<GetTemplateTrialItemsResponse>
{

    public constructor(init?: Partial<GetTemplateTrialItems>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTemplateTrialItemsResponse(); }
    public getTypeName() { return 'GetTemplateTrialItems'; }
}

// @Route("/api/TemplateTrialItems/bycategory", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTemplateTrialItemsByCategory implements IReturn<GetTemplateTrialItemsByCategoryResponse>
{

    public constructor(init?: Partial<GetTemplateTrialItemsByCategory>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTemplateTrialItemsByCategoryResponse(); }
    public getTypeName() { return 'GetTemplateTrialItemsByCategory'; }
}

// @Route("/api/TemplateTrialItems/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="TemplateTrialItem not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTemplateTrialItemById implements IReturn<GetTemplateTrialItemByIdResponse>, IGet
{
    /**
    * The primary key Id of the TemplateTrialItem
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the TemplateTrialItem", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTemplateTrialItemById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTemplateTrialItemByIdResponse(); }
    public getTypeName() { return 'GetTemplateTrialItemById'; }
}

// @Route("/api/TemplateTrialItems/save/{Id}", "POST")
// @Route("/api/TemplateTrialItems/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTemplateTrialItem implements IReturn<SetTemplateTrialItemResponse>
{
    /**
    * The TemplateTrialItem Form data to save
    */
    // @ApiMember(DataType="TemplateTrialItem", Description="The TemplateTrialItem Form data to save", IsRequired=true, Name="TemplateTrialItem", ParameterType="body")
    public templateTrialItem: TemplateTrialItem;

    public collection: Collection;
    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTemplateTrialItem>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTemplateTrialItemResponse(); }
    public getTypeName() { return 'SetTemplateTrialItem'; }
}

// @Route("/api/TemplateTrialItems/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTemplateTrialItem implements IReturn<CreateTemplateTrialItemResponse>
{

    public constructor(init?: Partial<CreateTemplateTrialItem>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTemplateTrialItemResponse(); }
    public getTypeName() { return 'CreateTemplateTrialItem'; }
}

// @Route("/api/trialAnalysisTypes", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialAnalysisTypes implements IReturn<GetTrialAnalysisTypesResponse>
{

    public constructor(init?: Partial<GetTrialAnalysisTypes>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialAnalysisTypesResponse(); }
    public getTypeName() { return 'GetTrialAnalysisTypes'; }
}

// @Route("/api/trialAnalysisType/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="TrialAnalysisType not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialAnalysisTypeById implements IReturn<GetTrialAnalysisTypeByIdResponse>, IGet
{
    /**
    * The primary key Id of the trialAnalysisTypes
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the trialAnalysisTypes", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTrialAnalysisTypeById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialAnalysisTypeByIdResponse(); }
    public getTypeName() { return 'GetTrialAnalysisTypeById'; }
}

// @Route("/api/trialAnalysisType/save/{Id}", "POST")
// @Route("/api/trialAnalysisType/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrialAnalysisType implements IReturn<SetTrialAnalysisTypeResponse>
{
    /**
    * The TrialAnalysisType Form data to save
    */
    // @ApiMember(DataType="TrialAnalysisType", Description="The TrialAnalysisType Form data to save", IsRequired=true, Name="TrialAnalysisType", ParameterType="body")
    public trialAnalysisType: TrialAnalysisType;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTrialAnalysisType>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrialAnalysisTypeResponse(); }
    public getTypeName() { return 'SetTrialAnalysisType'; }
}

// @Route("/api/trialAnalysisType/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTrialAnalysisType implements IReturn<CreateTrialAnalysisTypeResponse>
{

    public constructor(init?: Partial<CreateTrialAnalysisType>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTrialAnalysisTypeResponse(); }
    public getTypeName() { return 'CreateTrialAnalysisType'; }
}

// @Route("/api/trialcategorys", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialCategorys implements IReturn<GetTrialCategorysResponse>
{

    public constructor(init?: Partial<GetTrialCategorys>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialCategorysResponse(); }
    public getTypeName() { return 'GetTrialCategorys'; }
}

// @Route("/api/trialcategory/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="TrialCategory not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialCategoryById implements IReturn<GetTrialCategoryByIdResponse>, IGet
{
    /**
    * The primary key Id of the trialcategorys
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the trialcategorys", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTrialCategoryById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialCategoryByIdResponse(); }
    public getTypeName() { return 'GetTrialCategoryById'; }
}

// @Route("/api/trialcategory/save/{Id}", "POST")
// @Route("/api/trialcategory/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrialCategory implements IReturn<SetTrialCategoryResponse>
{
    /**
    * The TrialCategory Form data to save
    */
    // @ApiMember(DataType="TrialCategory", Description="The TrialCategory Form data to save", IsRequired=true, Name="TrialCategory", ParameterType="body")
    public trialCategory: TrialCategory;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTrialCategory>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrialCategoryResponse(); }
    public getTypeName() { return 'SetTrialCategory'; }
}

// @Route("/api/trialcategory/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTrialCategory implements IReturn<CreateTrialCategoryResponse>
{

    public constructor(init?: Partial<CreateTrialCategory>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTrialCategoryResponse(); }
    public getTypeName() { return 'CreateTrialCategory'; }
}

// @Route("/api/trialcoordcentres", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialCoordCentres implements IReturn<GetTrialCoordCentresResponse>
{

    public constructor(init?: Partial<GetTrialCoordCentres>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialCoordCentresResponse(); }
    public getTypeName() { return 'GetTrialCoordCentres'; }
}

// @Route("/api/trialcoordcentre/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="TrialCoordCentre not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialCoordCentreById implements IReturn<GetTrialCoordCentreByIdResponse>, IGet
{
    /**
    * The primary key Id of the trialcoordcentres
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the trialcoordcentres", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTrialCoordCentreById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialCoordCentreByIdResponse(); }
    public getTypeName() { return 'GetTrialCoordCentreById'; }
}

// @Route("/api/trialcoordcentre/save/{Id}", "POST")
// @Route("/api/trialcoordcentre/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrialCoordCentre implements IReturn<SetTrialCoordCentreResponse>
{
    /**
    * The TrialCoordCentre Form data to save
    */
    // @ApiMember(DataType="TrialCoordCentre", Description="The TrialCoordCentre Form data to save", IsRequired=true, Name="TrialCoordCentre", ParameterType="body")
    public trialCoordCentre: TrialCoordCentre;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTrialCoordCentre>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrialCoordCentreResponse(); }
    public getTypeName() { return 'SetTrialCoordCentre'; }
}

// @Route("/api/trialcoordcentre/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTrialCoordCentre implements IReturn<CreateTrialCoordCentreResponse>
{

    public constructor(init?: Partial<CreateTrialCoordCentre>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTrialCoordCentreResponse(); }
    public getTypeName() { return 'CreateTrialCoordCentre'; }
}

// @Route("/api/trialphases", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialPhases implements IReturn<GetTrialPhasesResponse>
{

    public constructor(init?: Partial<GetTrialPhases>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialPhasesResponse(); }
    public getTypeName() { return 'GetTrialPhases'; }
}

// @Route("/api/trialphase/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="TrialPhase not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialPhaseById implements IReturn<GetTrialPhaseByIdResponse>, IGet
{
    /**
    * The primary key Id of the trialphases
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the trialphases", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTrialPhaseById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialPhaseByIdResponse(); }
    public getTypeName() { return 'GetTrialPhaseById'; }
}

// @Route("/api/trialphase/save/{Id}", "POST")
// @Route("/api/trialphase/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrialPhase implements IReturn<SetTrialPhaseResponse>
{
    /**
    * The TrialPhase Form data to save
    */
    // @ApiMember(DataType="TrialPhase", Description="The TrialPhase Form data to save", IsRequired=true, Name="TrialPhase", ParameterType="body")
    public trialPhase: TrialPhase;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTrialPhase>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrialPhaseResponse(); }
    public getTypeName() { return 'SetTrialPhase'; }
}

// @Route("/api/trialphase/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTrialPhase implements IReturn<CreateTrialPhaseResponse>
{

    public constructor(init?: Partial<CreateTrialPhase>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTrialPhaseResponse(); }
    public getTypeName() { return 'CreateTrialPhase'; }
}

// @Route("/api/trialPresentationTypes", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialPresentationTypes implements IReturn<GetTrialPresentationTypesResponse>
{

    public constructor(init?: Partial<GetTrialPresentationTypes>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialPresentationTypesResponse(); }
    public getTypeName() { return 'GetTrialPresentationTypes'; }
}

// @Route("/api/trialPresentationType/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="TrialPresentationType not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialPresentationTypeById implements IReturn<GetTrialPresentationTypeByIdResponse>, IGet
{
    /**
    * The primary key Id of the trialPresentationTypes
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the trialPresentationTypes", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTrialPresentationTypeById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialPresentationTypeByIdResponse(); }
    public getTypeName() { return 'GetTrialPresentationTypeById'; }
}

// @Route("/api/trialPresentationType/save/{Id}", "POST")
// @Route("/api/trialPresentationType/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrialPresentationType implements IReturn<SetTrialPresentationTypeResponse>
{
    /**
    * The TrialPresentationType Form data to save
    */
    // @ApiMember(DataType="TrialPresentationType", Description="The TrialPresentationType Form data to save", IsRequired=true, Name="TrialPresentationType", ParameterType="body")
    public trialPresentationType: TrialPresentationType;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTrialPresentationType>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrialPresentationTypeResponse(); }
    public getTypeName() { return 'SetTrialPresentationType'; }
}

// @Route("/api/trialPresentationType/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTrialPresentationType implements IReturn<CreateTrialPresentationTypeResponse>
{

    public constructor(init?: Partial<CreateTrialPresentationType>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTrialPresentationTypeResponse(); }
    public getTypeName() { return 'CreateTrialPresentationType'; }
}

// @Route("/api/trialPublicationTypes", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialPublicationTypes implements IReturn<GetTrialPublicationTypesResponse>
{

    public constructor(init?: Partial<GetTrialPublicationTypes>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialPublicationTypesResponse(); }
    public getTypeName() { return 'GetTrialPublicationTypes'; }
}

// @Route("/api/trialPublicationType/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="TrialPublicationType not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialPublicationTypeById implements IReturn<GetTrialPublicationTypeByIdResponse>, IGet
{
    /**
    * The primary key Id of the trialPublicationTypes
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the trialPublicationTypes", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTrialPublicationTypeById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialPublicationTypeByIdResponse(); }
    public getTypeName() { return 'GetTrialPublicationTypeById'; }
}

// @Route("/api/trialPublicationType/save/{Id}", "POST")
// @Route("/api/trialPublicationType/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrialPublicationType implements IReturn<SetTrialPublicationTypeResponse>
{
    /**
    * The TrialPublicationType Form data to save
    */
    // @ApiMember(DataType="TrialPublicationType", Description="The TrialPublicationType Form data to save", IsRequired=true, Name="TrialPublicationType", ParameterType="body")
    public trialPublicationType: TrialPublicationType;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTrialPublicationType>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrialPublicationTypeResponse(); }
    public getTypeName() { return 'SetTrialPublicationType'; }
}

// @Route("/api/trialPublicationType/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTrialPublicationType implements IReturn<CreateTrialPublicationTypeResponse>
{

    public constructor(init?: Partial<CreateTrialPublicationType>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTrialPublicationTypeResponse(); }
    public getTypeName() { return 'CreateTrialPublicationType'; }
}

// @Route("/api/trialtype", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialTypes implements IReturn<GetTrialTypesResponse>
{

    public constructor(init?: Partial<GetTrialTypes>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialTypesResponse(); }
    public getTypeName() { return 'GetTrialTypes'; }
}

// @Route("/api/trialtype/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="TrialType not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialTypeById implements IReturn<GetTrialTypeByIdResponse>, IGet
{
    /**
    * The primary key Id of the trialtype
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the trialtype", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTrialTypeById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialTypeByIdResponse(); }
    public getTypeName() { return 'GetTrialTypeById'; }
}

// @Route("/api/trialtype/save/{Id}", "POST")
// @Route("/api/trialtype/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrialType implements IReturn<SetTrialTypeResponse>
{
    /**
    * The TrialType Form data to save
    */
    // @ApiMember(DataType="TrialType", Description="The TrialType Form data to save", IsRequired=true, Name="TrialType", ParameterType="body")
    public trialType: TrialType;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTrialType>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrialTypeResponse(); }
    public getTypeName() { return 'SetTrialType'; }
}

// @Route("/api/trialtype/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTrialType implements IReturn<CreateTrialTypeResponse>
{

    public constructor(init?: Partial<CreateTrialType>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTrialTypeResponse(); }
    public getTypeName() { return 'CreateTrialType'; }
}

// @Route("/api/tumourgroups", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTumourGroups implements IReturn<GetTumourGroupsResponse>
{

    public constructor(init?: Partial<GetTumourGroups>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTumourGroupsResponse(); }
    public getTypeName() { return 'GetTumourGroups'; }
}

// @Route("/api/tumourgroup/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="TumourGroup not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTumourGroupById implements IReturn<GetTumourGroupByIdResponse>, IGet
{
    /**
    * The primary key Id of the tumourgroups
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the tumourgroups", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTumourGroupById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTumourGroupByIdResponse(); }
    public getTypeName() { return 'GetTumourGroupById'; }
}

// @Route("/api/tumourgroup/save/{Id}", "POST")
// @Route("/api/tumourgroup/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTumourGroup implements IReturn<SetTumourGroupResponse>
{
    /**
    * The TumourGroup Form data to save
    */
    // @ApiMember(DataType="TumourGroup", Description="The TumourGroup Form data to save", IsRequired=true, Name="TumourGroup", ParameterType="body")
    public tumourGroup: TumourGroup;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTumourGroup>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTumourGroupResponse(); }
    public getTypeName() { return 'SetTumourGroup'; }
}

// @Route("/api/tumourgroup/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTumourGroup implements IReturn<CreateTumourGroupResponse>
{

    public constructor(init?: Partial<CreateTumourGroup>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTumourGroupResponse(); }
    public getTypeName() { return 'CreateTumourGroup'; }
}

// @Route("/api/note/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Note not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetNoteById implements IReturn<GetNoteByIdResponse>, IGet
{
    /**
    * The primary key Id of the Note
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Note", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetNoteById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetNoteByIdResponse(); }
    public getTypeName() { return 'GetNoteById'; }
}

// @Route("/api/note/save/{Id}", "POST")
// @Route("/api/note/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetNote implements IReturn<SetNoteResponse>
{
    /**
    * The Note Form data to save
    */
    // @ApiMember(DataType="Note", Description="The Note Form data to save", IsRequired=true, Name="note", ParameterType="body")
    public note: Note;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetNote>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetNoteResponse(); }
    public getTypeName() { return 'SetNote'; }
}

// @Route("/api/note/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateNote implements IReturn<CreateNoteResponse>
{
    public trialId?: number;
    public institutionId?: number;
    public trialInstitutionId?: number;
    public personnelId?: number;
    public groupId?: number;

    public constructor(init?: Partial<CreateNote>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateNoteResponse(); }
    public getTypeName() { return 'CreateNote'; }
}

// @Route("/api/patients", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPatients implements IReturn<GetPatientsResponse>
{
    public trialId: number;
    public institutionId: number;

    public constructor(init?: Partial<GetPatients>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPatientsResponse(); }
    public getTypeName() { return 'GetPatients'; }
}

// @Route("/api/patients/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Patient not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPatientById implements IReturn<GetPatientByIdResponse>, IGet
{
    /**
    * The primary key Id of the patient
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the patient", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetPatientById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPatientByIdResponse(); }
    public getTypeName() { return 'GetPatientById'; }
}

// @Route("/api/patients/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreatePatient implements IReturn<CreatePatientResponse>
{
    public trialId: number;
    public trialInstitutionId: number;
    public institutionId: number;

    public constructor(init?: Partial<CreatePatient>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreatePatientResponse(); }
    public getTypeName() { return 'CreatePatient'; }
}

// @Route("/api/patients/save/{Id}", "POST")
// @Route("/api/patients/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetPatient implements IReturn<SetPatientResponse>
{
    /**
    * The Patient Form data to save
    */
    // @ApiMember(DataType="Patient", Description="The Patient Form data to save", IsRequired=true, Name="patient", ParameterType="body")
    public patient: Patient;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetPatient>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetPatientResponse(); }
    public getTypeName() { return 'SetPatient'; }
}

// @Route("/api/patients/import", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class PostPatientsImport implements IReturn<PostPatientsImportResponse>
{
    public trialId: number;

    public constructor(init?: Partial<PostPatientsImport>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostPatientsImportResponse(); }
    public getTypeName() { return 'PostPatientsImport'; }
}

// @Route("/api/hubspot/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Personnel not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetHubspotById implements IReturn<GetHubspotByIdResponse>, IGet
{
    /**
    * The primary key Id of the Personnel
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Personnel", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetHubspotById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetHubspotByIdResponse(); }
    public getTypeName() { return 'GetHubspotById'; }
}

// @Route("/api/hubspot/contactexport", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetHubspotContactsToExport implements IReturn<GetHubspotContactsToExportResponse>, IGet
{

    public constructor(init?: Partial<GetHubspotContactsToExport>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetHubspotContactsToExportResponse(); }
    public getTypeName() { return 'GetHubspotContactsToExport'; }
}

// @Route("/api/hubspot/contactexport", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class PostHubspotContactExport implements IReturn<PostHubspotContactExportResponse>, IPost
{
    public username: string;
    public sendEmail: boolean;

    public constructor(init?: Partial<PostHubspotContactExport>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostHubspotContactExportResponse(); }
    public getTypeName() { return 'PostHubspotContactExport'; }
}

// @Route("/api/hubspot/contactfind", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class FindHubspotContact implements IReturn<FindHubspotContactResponse>, IPost
{
    public action: string;
    public email: string;
    public newEmail: string;
    public emailUpdateOptions: EmailUpdateOptions;

    public constructor(init?: Partial<FindHubspotContact>) { (Object as any).assign(this, init); }
    public createResponse() { return new FindHubspotContactResponse(); }
    public getTypeName() { return 'FindHubspotContact'; }
}

// @Route("/api/hubspot/currentmembers", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetHubspotCurrentMembers implements IReturn<GetHubspotCurrentMembersResponse>, IGet
{

    public constructor(init?: Partial<GetHubspotCurrentMembers>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetHubspotCurrentMembersResponse(); }
    public getTypeName() { return 'GetHubspotCurrentMembers'; }
}

// @Route("/api/openclinicauserrole", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetOpenClinicaUserRoles implements IReturn<GetOpenClinicaUserRolesResponse>
{

    public constructor(init?: Partial<GetOpenClinicaUserRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetOpenClinicaUserRolesResponse(); }
    public getTypeName() { return 'GetOpenClinicaUserRoles'; }
}

// @Route("/api/openclinicauserrole/rebuild", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetOpenClinicaUserRoleRebuild implements IReturn<SetOpenClinicaUserRoleRebuildResponse>
{
    public personnelIds: number[];

    public constructor(init?: Partial<SetOpenClinicaUserRoleRebuild>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetOpenClinicaUserRoleRebuildResponse(); }
    public getTypeName() { return 'SetOpenClinicaUserRoleRebuild'; }
}

// @Route("/api/openclinicauserrole/changestatus/{Id}", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class PostOpenClinicaUserRoleChangeStatus implements IReturn<PostOpenClinicaUserRoleChangeStatusResponse>
{
    public id: number;
    public ocSyncStatus: OCSyncStatus;

    public constructor(init?: Partial<PostOpenClinicaUserRoleChangeStatus>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostOpenClinicaUserRoleChangeStatusResponse(); }
    public getTypeName() { return 'PostOpenClinicaUserRoleChangeStatus'; }
}

// @Route("/api/openclinicauserrole/resendemail/{Id}", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class PostOpenClinicaUserRoleResendEmail implements IReturn<PostOpenClinicaUserRoleResendEmailResponse>
{
    public id: number;

    public constructor(init?: Partial<PostOpenClinicaUserRoleResendEmail>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostOpenClinicaUserRoleResendEmailResponse(); }
    public getTypeName() { return 'PostOpenClinicaUserRoleResendEmail'; }
}

// @Route("/api/openclinicauserrole/import", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class PostOpenClinicaUserRoleImport implements IReturn<PostOpenClinicaUserRoleImportResponse>
{

    public constructor(init?: Partial<PostOpenClinicaUserRoleImport>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostOpenClinicaUserRoleImportResponse(); }
    public getTypeName() { return 'PostOpenClinicaUserRoleImport'; }
}

// @Route("/api/personnelrole/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Personnel Role not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPersonnelRoleById implements IReturn<GetPersonnelRoleByIdResponse>, IGet
{
    /**
    * The primary key Id of the Personnel Role
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Personnel Role", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetPersonnelRoleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPersonnelRoleByIdResponse(); }
    public getTypeName() { return 'GetPersonnelRoleById'; }
}

// @Route("/api/personnelrole/save/{Id}", "POST")
// @Route("/api/personnelrole/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetPersonnelRole implements IReturn<SetPersonnelRoleResponse>
{
    /**
    * The Personnel Role Form data to save
    */
    // @ApiMember(DataType="PersonnelRole", Description="The Personnel Role Form data to save", IsRequired=true, Name="PersonnelRole", ParameterType="body")
    public personnelRole: PersonnelRole;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetPersonnelRole>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetPersonnelRoleResponse(); }
    public getTypeName() { return 'SetPersonnelRole'; }
}

// @Route("/api/personnelrole/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreatePersonnelRole implements IReturn<CreatePersonnelRoleResponse>
{
    public trialId?: number;
    public personnelId?: number;
    public institutionId?: number;
    public groupId?: number;
    public departmentId?: number;

    public constructor(init?: Partial<CreatePersonnelRole>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreatePersonnelRoleResponse(); }
    public getTypeName() { return 'CreatePersonnelRole'; }
}

// @Route("/api/personnelrole/resendemail/{Id}", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class PostPersonnelRoleResendEmail implements IReturn<PostPersonnelRoleResendEmailResponse>
{
    public id: number;

    public constructor(init?: Partial<PostPersonnelRoleResendEmail>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostPersonnelRoleResendEmailResponse(); }
    public getTypeName() { return 'PostPersonnelRoleResendEmail'; }
}

// @Route("/api/personnelrole/export/{TrialId}/{InstitutionId}", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class ExportPersonnelRoles
{
    /**
    * The primary key Id of the Trial
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Trial", IsRequired=true, Name="TrialId", ParameterType="path")
    public trialId: number;

    /**
    * The primary key Id of the Institution
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Institution", IsRequired=true, Name="InstitutionId", ParameterType="path")
    public institutionId: number;

    public filter: string;
    public showInActive: string;
    public groupByRole: boolean;

    public constructor(init?: Partial<ExportPersonnelRoles>) { (Object as any).assign(this, init); }
}

// @Route("/api/personnelrole/update/{Id}", "POST")
// @Route("/api/personnelrole/update", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class UpdatePersonnelRole implements IReturn<UpdatePersonnelRoleResponse>
{
    /**
    * The Id
    */
    // @ApiMember(DataType="int", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    /**
    * The Role Id
    */
    // @ApiMember(DataType="int", Description="The Role Id", IsRequired=true, Name="RoleId", ParameterType="body")
    public roleId: number;

    /**
    * start date
    */
    // @ApiMember(DataType="datetimeoffset", Description="start date", IsRequired=true, Name="DateStarted", ParameterType="body")
    public dateStarted: string;

    public constructor(init?: Partial<UpdatePersonnelRole>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdatePersonnelRoleResponse(); }
    public getTypeName() { return 'UpdatePersonnelRole'; }
}

// @Route("/api/personnel", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPersonnel implements IReturn<GetPersonnelResponse>
{

    public constructor(init?: Partial<GetPersonnel>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPersonnelResponse(); }
    public getTypeName() { return 'GetPersonnel'; }
}

// @Route("/api/personnelsecurity/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Personnel not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPersonnelSecurityById implements IReturn<GetPersonnelByIdResponse>, IGet
{
    /**
    * The primary key Id of the Personnel
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Personnel", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetPersonnelSecurityById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPersonnelByIdResponse(); }
    public getTypeName() { return 'GetPersonnelSecurityById'; }
}

// @Route("/api/personnel/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Personnel not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPersonnelById implements IReturn<GetPersonnelByIdResponse>, IGet
{
    /**
    * The primary key Id of the Personnel
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Personnel", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetPersonnelById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPersonnelByIdResponse(); }
    public getTypeName() { return 'GetPersonnelById'; }
}

// @Route("/api/personnel/save/{Id}", "POST")
// @Route("/api/personnel/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetPersonnel implements IReturn<SetPersonnelResponse>
{
    /**
    * The Personnel Form data to save
    */
    // @ApiMember(DataType="Personnel", Description="The Personnel Form data to save", IsRequired=true, Name="personnel", ParameterType="body")
    public personnel: Personnel;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetPersonnel>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetPersonnelResponse(); }
    public getTypeName() { return 'SetPersonnel'; }
}

// @Route("/api/personnel/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreatePersonnel implements IReturn<CreatePersonnelResponse>
{

    public constructor(init?: Partial<CreatePersonnel>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreatePersonnelResponse(); }
    public getTypeName() { return 'CreatePersonnel'; }
}

// @Route("/api/personnel/activedirectory/{Id}", "POST")
// @Route("/api/personnel/activedirectory", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetPersonnelActiveDirectory implements IReturn<SetPersonnelActiveDirectoryResponse>
{
    /**
    * The Personnel Form data to save
    */
    // @ApiMember(DataType="Personnel", Description="The Personnel Form data to save", IsRequired=true, Name="personnel", ParameterType="body")
    public personnel: Personnel;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetPersonnelActiveDirectory>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetPersonnelActiveDirectoryResponse(); }
    public getTypeName() { return 'SetPersonnelActiveDirectory'; }
}

// @Route("/api/personnel/resendemail/{Id}", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class PostPersonnelResendEmail implements IReturn<PostPersonnelResendEmailResponse>
{
    public id: number;

    public constructor(init?: Partial<PostPersonnelResendEmail>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostPersonnelResendEmailResponse(); }
    public getTypeName() { return 'PostPersonnelResendEmail'; }
}

// @Route("/api/personnel/permissions/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Personnel not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPersonnelPermissionsById implements IReturn<GetPersonnelPermissionsByIdResponse>, IGet
{
    /**
    * The primary key Id of the Personnel
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Personnel", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetPersonnelPermissionsById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPersonnelPermissionsByIdResponse(); }
    public getTypeName() { return 'GetPersonnelPermissionsById'; }
}

// @Route("/api/personnelbyrole", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPersonnelByRole implements IReturn<GetPersonnelByRoleResponse>
{

    public constructor(init?: Partial<GetPersonnelByRole>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPersonnelByRoleResponse(); }
    public getTypeName() { return 'GetPersonnelByRole'; }
}

// @Route("/api/FormTemplateFileTypes", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetFormTemplateFileTypes implements IReturn<GetFormTemplateFileTypesResponse>
{

    public constructor(init?: Partial<GetFormTemplateFileTypes>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetFormTemplateFileTypesResponse(); }
    public getTypeName() { return 'GetFormTemplateFileTypes'; }
}

// @Route("/api/FormTemplateFileTypes/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="FormTemplateFileType not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetFormTemplateFileTypeById implements IReturn<GetFormTemplateFileTypeByIdResponse>, IGet
{
    /**
    * The primary key Id of the FormTemplateFileType
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the FormTemplateFileType", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetFormTemplateFileTypeById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetFormTemplateFileTypeByIdResponse(); }
    public getTypeName() { return 'GetFormTemplateFileTypeById'; }
}

// @Route("/api/FormTemplateFileTypes/save/{Id}", "POST")
// @Route("/api/FormTemplateFileTypes/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetFormTemplateFileType implements IReturn<SetFormTemplateFileTypeResponse>
{
    /**
    * The FormTemplateFileType Form data to save
    */
    // @ApiMember(DataType="FormTemplateFileType", Description="The FormTemplateFileType Form data to save", IsRequired=true, Name="FormTemplateFileType", ParameterType="body")
    public formTemplateFileType: FormTemplateFileType;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetFormTemplateFileType>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetFormTemplateFileTypeResponse(); }
    public getTypeName() { return 'SetFormTemplateFileType'; }
}

// @Route("/api/FormTemplateFileTypes/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateFormTemplateFileType implements IReturn<CreateFormTemplateFileTypeResponse>
{

    public constructor(init?: Partial<CreateFormTemplateFileType>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateFormTemplateFileTypeResponse(); }
    public getTypeName() { return 'CreateFormTemplateFileType'; }
}

// @Route("/api/formtemplates", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetFormTemplates implements IReturn<GetFormTemplatesResponse>
{

    public constructor(init?: Partial<GetFormTemplates>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetFormTemplatesResponse(); }
    public getTypeName() { return 'GetFormTemplates'; }
}

// @Route("/api/formtemplates/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="FormTemplate not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetFormTemplateById implements IReturn<GetFormTemplateByIdResponse>, IGet
{
    /**
    * The primary key Id of the FormTemplate
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the FormTemplate", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetFormTemplateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetFormTemplateByIdResponse(); }
    public getTypeName() { return 'GetFormTemplateById'; }
}

// @Route("/api/formtemplates/save/{Id}", "POST")
// @Route("/api/formtemplates/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetFormTemplate implements IReturn<SetFormTemplateResponse>
{
    /**
    * The FormTemplate Form data to save
    */
    // @ApiMember(DataType="FormTemplate", Description="The FormTemplate Form data to save", IsRequired=true, Name="FormTemplate", ParameterType="body")
    public formTemplate: FormTemplate;

    public collection: Collection;
    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetFormTemplate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetFormTemplateResponse(); }
    public getTypeName() { return 'SetFormTemplate'; }
}

// @Route("/api/formtemplates/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateFormTemplate implements IReturn<CreateFormTemplateResponse>
{

    public constructor(init?: Partial<CreateFormTemplate>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateFormTemplateResponse(); }
    public getTypeName() { return 'CreateFormTemplate'; }
}

// @Route("/api/ReportingTags", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetReportingTags implements IReturn<GetReportingTagsResponse>
{

    public constructor(init?: Partial<GetReportingTags>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetReportingTagsResponse(); }
    public getTypeName() { return 'GetReportingTags'; }
}

// @Route("/api/ReportingTags/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="ReportingTag not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetReportingTagById implements IReturn<GetReportingTagByIdResponse>, IGet
{
    /**
    * The primary key Id of the ReportingTag
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the ReportingTag", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetReportingTagById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetReportingTagByIdResponse(); }
    public getTypeName() { return 'GetReportingTagById'; }
}

// @Route("/api/ReportingTags/save/{Id}", "POST")
// @Route("/api/ReportingTags/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetReportingTag implements IReturn<SetReportingTagResponse>
{
    /**
    * The ReportingTag Form data to save
    */
    // @ApiMember(DataType="ReportingTag", Description="The ReportingTag Form data to save", IsRequired=true, Name="ReportingTag", ParameterType="body")
    public reportingTag: ReportingTag;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetReportingTag>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetReportingTagResponse(); }
    public getTypeName() { return 'SetReportingTag'; }
}

// @Route("/api/ReportingTags/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateReportingTag implements IReturn<CreateReportingTagResponse>
{

    public constructor(init?: Partial<CreateReportingTag>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateReportingTagResponse(); }
    public getTypeName() { return 'CreateReportingTag'; }
}

// @Route("/api/ReportingTagCategories", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetReportingTagCategories implements IReturn<GetReportingTagCategoriesResponse>
{

    public constructor(init?: Partial<GetReportingTagCategories>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetReportingTagCategoriesResponse(); }
    public getTypeName() { return 'GetReportingTagCategories'; }
}

// @Route("/api/ReportingTagCategories/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="ReportingTagCategory not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetReportingTagCategoryById implements IReturn<GetReportingTagCategoryByIdResponse>, IGet
{
    /**
    * The primary key Id of the ReportingTagCategory
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the ReportingTagCategory", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetReportingTagCategoryById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetReportingTagCategoryByIdResponse(); }
    public getTypeName() { return 'GetReportingTagCategoryById'; }
}

// @Route("/api/ReportingTagCategories/save/{Id}", "POST")
// @Route("/api/ReportingTagCategories/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetReportingTagCategory implements IReturn<SetReportingTagCategoryResponse>
{
    /**
    * The ReportingTagCategory Form data to save
    */
    // @ApiMember(DataType="ReportingTagCategory", Description="The ReportingTagCategory Form data to save", IsRequired=true, Name="ReportingTagCategory", ParameterType="body")
    public reportingTagCategory: ReportingTagCategory;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetReportingTagCategory>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetReportingTagCategoryResponse(); }
    public getTypeName() { return 'SetReportingTagCategory'; }
}

// @Route("/api/ReportingTagCategories/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateReportingTagCategory implements IReturn<CreateReportingTagCategoryResponse>
{

    public constructor(init?: Partial<CreateReportingTagCategory>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateReportingTagCategoryResponse(); }
    public getTypeName() { return 'CreateReportingTagCategory'; }
}

// @Route("/api/TaskTypes", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTaskTypes implements IReturn<GetTaskTypesResponse>
{

    public constructor(init?: Partial<GetTaskTypes>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTaskTypesResponse(); }
    public getTypeName() { return 'GetTaskTypes'; }
}

// @Route("/api/TaskTypes/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="TaskType not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTaskTypeById implements IReturn<GetTaskTypeByIdResponse>, IGet
{
    /**
    * The primary key Id of the TaskType
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the TaskType", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTaskTypeById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTaskTypeByIdResponse(); }
    public getTypeName() { return 'GetTaskTypeById'; }
}

// @Route("/api/TaskTypes/save/{Id}", "POST")
// @Route("/api/TaskTypes/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTaskType implements IReturn<SetTaskTypeResponse>
{
    /**
    * The TaskType Form data to save
    */
    // @ApiMember(DataType="TaskType", Description="The TaskType Form data to save", IsRequired=true, Name="TaskType", ParameterType="body")
    public taskType: TaskType;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTaskType>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTaskTypeResponse(); }
    public getTypeName() { return 'SetTaskType'; }
}

// @Route("/api/TaskTypes/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTaskType implements IReturn<CreateTaskTypeResponse>
{

    public constructor(init?: Partial<CreateTaskType>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTaskTypeResponse(); }
    public getTypeName() { return 'CreateTaskType'; }
}

// @Route("/api/TrackerMilestones", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrackerMilestones implements IReturn<GetTrackerMilestonesResponse>
{

    public constructor(init?: Partial<GetTrackerMilestones>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrackerMilestonesResponse(); }
    public getTypeName() { return 'GetTrackerMilestones'; }
}

// @Route("/api/TrackerMilestones/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="TrackerMilestone not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrackerMilestoneById implements IReturn<GetTrackerMilestoneByIdResponse>, IGet
{
    /**
    * The primary key Id of the TrackerMilestone
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the TrackerMilestone", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTrackerMilestoneById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrackerMilestoneByIdResponse(); }
    public getTypeName() { return 'GetTrackerMilestoneById'; }
}

// @Route("/api/TrackerMilestones/save/{Id}", "POST")
// @Route("/api/TrackerMilestones/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrackerMilestone implements IReturn<SetTrackerMilestoneResponse>
{
    /**
    * The TrackerMilestone Form data to save
    */
    // @ApiMember(DataType="TrackerMilestone", Description="The TrackerMilestone Form data to save", IsRequired=true, Name="TrackerMilestone", ParameterType="body")
    public trackerMilestone: TrackerMilestone;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTrackerMilestone>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrackerMilestoneResponse(); }
    public getTypeName() { return 'SetTrackerMilestone'; }
}

// @Route("/api/TrackerMilestones/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTrackerMilestone implements IReturn<CreateTrackerMilestoneResponse>
{

    public constructor(init?: Partial<CreateTrackerMilestone>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTrackerMilestoneResponse(); }
    public getTypeName() { return 'CreateTrackerMilestone'; }
}

// @Route("/api/TrackerTypes", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrackerTypes implements IReturn<GetTrackerTypesResponse>
{

    public constructor(init?: Partial<GetTrackerTypes>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrackerTypesResponse(); }
    public getTypeName() { return 'GetTrackerTypes'; }
}

// @Route("/api/TrackerTypes/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="TrackerType not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrackerTypeById implements IReturn<GetTrackerTypeByIdResponse>, IGet
{
    /**
    * The primary key Id of the TrackerType
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the TrackerType", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTrackerTypeById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrackerTypeByIdResponse(); }
    public getTypeName() { return 'GetTrackerTypeById'; }
}

// @Route("/api/TrackerTypes/save/{Id}", "POST")
// @Route("/api/TrackerTypes/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrackerType implements IReturn<SetTrackerTypeResponse>
{
    /**
    * The TrackerType Form data to save
    */
    // @ApiMember(DataType="TrackerType", Description="The TrackerType Form data to save", IsRequired=true, Name="TrackerType", ParameterType="body")
    public trackerType: TrackerType;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTrackerType>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrackerTypeResponse(); }
    public getTypeName() { return 'SetTrackerType'; }
}

// @Route("/api/TrackerTypes/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTrackerType implements IReturn<CreateTrackerTypeResponse>
{

    public constructor(init?: Partial<CreateTrackerType>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTrackerTypeResponse(); }
    public getTypeName() { return 'CreateTrackerType'; }
}

// @Route("/api/search", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class Search implements IReturn<SearchResponse>
{
    public searchFor: string;

    public constructor(init?: Partial<Search>) { (Object as any).assign(this, init); }
    public createResponse() { return new SearchResponse(); }
    public getTypeName() { return 'Search'; }
}

// @Route("/api/StandardEmailTemplates", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetStandardEmailTemplates implements IReturn<GetStandardEmailTemplatesResponse>
{

    public constructor(init?: Partial<GetStandardEmailTemplates>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetStandardEmailTemplatesResponse(); }
    public getTypeName() { return 'GetStandardEmailTemplates'; }
}

// @Route("/api/StandardEmailTemplates/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="StandardEmailTemplate not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetStandardEmailTemplateById implements IReturn<GetStandardEmailTemplateByIdResponse>, IGet
{
    /**
    * The primary key Id of the StandardEmailTemplate
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the StandardEmailTemplate", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetStandardEmailTemplateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetStandardEmailTemplateByIdResponse(); }
    public getTypeName() { return 'GetStandardEmailTemplateById'; }
}

// @Route("/api/StandardEmailTemplates/save/{Id}", "POST")
// @Route("/api/StandardEmailTemplates/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetStandardEmailTemplate implements IReturn<SetStandardEmailTemplateResponse>
{
    /**
    * The StandardEmailTemplate Form data to save
    */
    // @ApiMember(DataType="StandardEmailTemplate", Description="The StandardEmailTemplate Form data to save", IsRequired=true, Name="StandardEmailTemplate", ParameterType="body")
    public standardEmailTemplate: StandardEmailTemplate;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetStandardEmailTemplate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetStandardEmailTemplateResponse(); }
    public getTypeName() { return 'SetStandardEmailTemplate'; }
}

// @Route("/api/StandardEmailTemplates/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateStandardEmailTemplate implements IReturn<CreateStandardEmailTemplateResponse>
{

    public constructor(init?: Partial<CreateStandardEmailTemplate>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateStandardEmailTemplateResponse(); }
    public getTypeName() { return 'CreateStandardEmailTemplate'; }
}

// @Route("/appsettings")
export class GetAppSettings implements IReturn<GetAppSettingsResponse>
{

    public constructor(init?: Partial<GetAppSettings>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAppSettingsResponse(); }
    public getTypeName() { return 'GetAppSettings'; }
}

// @Route("/appsettings.js")
export class GetAppSettingsJs
{

    public constructor(init?: Partial<GetAppSettingsJs>) { (Object as any).assign(this, init); }
}

// @Route("/hello")
// @Route("/hello/{Name}")
export class Hello implements IReturn<HelloResponse>
{
    public name: string;

    public constructor(init?: Partial<Hello>) { (Object as any).assign(this, init); }
    public createResponse() { return new HelloResponse(); }
    public getTypeName() { return 'Hello'; }
}

// @Route("/api/dashboard/trialforms", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialFormsByCurrentUser implements IReturn<GetTrialFormsByCurrentUserResponse>
{

    public constructor(init?: Partial<GetTrialFormsByCurrentUser>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialFormsByCurrentUserResponse(); }
    public getTypeName() { return 'GetTrialFormsByCurrentUser'; }
}

// @Route("/api/trialforms", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialForms implements IReturn<GetTrialFormsResponse>
{
    public trialId: number;
    public institutionId: number;

    public constructor(init?: Partial<GetTrialForms>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialFormsResponse(); }
    public getTypeName() { return 'GetTrialForms'; }
}

// @Route("/api/trialform/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Trial Form not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialFormById implements IReturn<GetTrialFormByIdResponse>, IGet
{
    /**
    * The primary key Id of the trial form
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the trial form", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTrialFormById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialFormByIdResponse(); }
    public getTypeName() { return 'GetTrialFormById'; }
}

// @Route("/api/trialform/save/{Id}", "POST")
// @Route("/api/trialform/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrialForm implements IReturn<SetTrialFormResponse>
{
    /**
    * The trial form data to save
    */
    // @ApiMember(DataType="TrialForm", Description="The trial form data to save", IsRequired=true, Name="TrialForm", ParameterType="body")
    public trialForm: TrialForm;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTrialForm>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrialFormResponse(); }
    public getTypeName() { return 'SetTrialForm'; }
}

// @Route("/api/trialform/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTrialForm implements IReturn<CreateTrialFormResponse>
{
    public trialId: number;
    public institutionId: number;
    public formTemplateId: number;

    public constructor(init?: Partial<CreateTrialForm>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTrialFormResponse(); }
    public getTypeName() { return 'CreateTrialForm'; }
}

// @Route("/api/trialform/createversion", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTrialFormVersion implements IReturn<CreateTrialFormVersionResponse>
{
    public trialFormId: number;
    public version: string;
    public copyData: boolean;

    public constructor(init?: Partial<CreateTrialFormVersion>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTrialFormVersionResponse(); }
    public getTypeName() { return 'CreateTrialFormVersion'; }
}

// @Route("/api/trialforreport/{Id}/{Version}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Trial Form not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialFormReport implements IReturn<GetTrialFormReportResponse>, IGet
{
    /**
    * The primary key Id of the trial form
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the trial form", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public version: string;

    public constructor(init?: Partial<GetTrialFormReport>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialFormReportResponse(); }
    public getTypeName() { return 'GetTrialFormReport'; }
}

// @Route("/api/trialform/justified/{Id}", "POST")
// @Route("/api/trialform/justified", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrialFormDataJustified implements IReturn<SetTrialFormDataJustifiedResponse>
{
    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public measurementReport: MeasurementReport;

    public constructor(init?: Partial<SetTrialFormDataJustified>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrialFormDataJustifiedResponse(); }
    public getTypeName() { return 'SetTrialFormDataJustified'; }
}

// @Route("/api/trialform/reminderemail/{Id}", "POST")
// @Route("/api/trialform/reminderemail", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SendReminderEmail implements IReturn<SendReminderEmailResponse>
{
    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SendReminderEmail>) { (Object as any).assign(this, init); }
    public createResponse() { return new SendReminderEmailResponse(); }
    public getTypeName() { return 'SendReminderEmail'; }
}

// @Route("/api/trialgroupitem/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Trial Group Item not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialGroupItemById implements IReturn<GetTrialGroupItemByIdResponse>, IGet
{
    /**
    * The primary key Id of the trial group item
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the trial group item", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTrialGroupItemById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialGroupItemByIdResponse(); }
    public getTypeName() { return 'GetTrialGroupItemById'; }
}

// @Route("/api/trialgroupitem/save/{Id}", "POST")
// @Route("/api/trialgroupitem/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrialGroupItem implements IReturn<SetTrialGroupItemResponse>
{
    /**
    * The TrialGroupItem Form data to save
    */
    // @ApiMember(DataType="TrialGroupItem", Description="The TrialGroupItem Form data to save", IsRequired=true, Name="TrialGroupItem", ParameterType="body")
    public trialGroupItem: TrialGroupItem;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTrialGroupItem>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrialGroupItemResponse(); }
    public getTypeName() { return 'SetTrialGroupItem'; }
}

// @Route("/api/trialgroupitem/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTrialGroupItem implements IReturn<CreateTrialGroupItemResponse>
{
    public trialGroupId: number;
    public groupItemId: number;

    public constructor(init?: Partial<CreateTrialGroupItem>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTrialGroupItemResponse(); }
    public getTypeName() { return 'CreateTrialGroupItem'; }
}

// @Route("/api/trialgroup/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Trial Group not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialGroupById implements IReturn<GetTrialGroupByIdResponse>, IGet
{
    /**
    * The primary key Id of the trial group
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the trial group", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTrialGroupById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialGroupByIdResponse(); }
    public getTypeName() { return 'GetTrialGroupById'; }
}

// @Route("/api/trialgroup/save/{Id}", "POST")
// @Route("/api/trialgroup/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrialGroup implements IReturn<SetTrialGroupResponse>
{
    /**
    * The TrialGroup Form data to save
    */
    // @ApiMember(DataType="TrialGroup", Description="The TrialGroup Form data to save", IsRequired=true, Name="TrialGroup", ParameterType="body")
    public trialGroup: TrialGroup;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTrialGroup>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrialGroupResponse(); }
    public getTypeName() { return 'SetTrialGroup'; }
}

// @Route("/api/trialgroup/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTrialGroup implements IReturn<CreateTrialGroupResponse>
{
    public trialId: number;

    public constructor(init?: Partial<CreateTrialGroup>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTrialGroupResponse(); }
    public getTypeName() { return 'CreateTrialGroup'; }
}

// @Route("/api/trialinstitutionitem/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Trial Institution Item not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialInstitutionItemById implements IReturn<GetTrialInstitutionItemByIdResponse>, IGet
{
    /**
    * The primary key Id of the trial institution item
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the trial institution item", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTrialInstitutionItemById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialInstitutionItemByIdResponse(); }
    public getTypeName() { return 'GetTrialInstitutionItemById'; }
}

// @Route("/api/trialinstitutionitem/save/{Id}", "POST")
// @Route("/api/trialinstitutionitem/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrialInstitutionItem implements IReturn<SetTrialInstitutionItemResponse>
{
    /**
    * The TrialInstitutionItem Form data to save
    */
    // @ApiMember(DataType="TrialInstitutionItem", Description="The TrialInstitutionItem Form data to save", IsRequired=true, Name="TrialInstitutionItem", ParameterType="body")
    public trialInstitutionItem: TrialInstitutionItem;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTrialInstitutionItem>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrialInstitutionItemResponse(); }
    public getTypeName() { return 'SetTrialInstitutionItem'; }
}

// @Route("/api/trialinstitutionitem/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTrialInstitutionItem implements IReturn<CreateTrialInstitutionItemResponse>
{
    public trialInstitutionId: number;
    public trialItemId: number;

    public constructor(init?: Partial<CreateTrialInstitutionItem>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTrialInstitutionItemResponse(); }
    public getTypeName() { return 'CreateTrialInstitutionItem'; }
}

// @Route("/api/trialinstitutionitem/export/{TrialId}/{InstitutionId}", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class ExportTrialInstitutionItemInstances
{
    /**
    * The primary key Id of the Trial
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Trial", IsRequired=true, Name="TrialId", ParameterType="path")
    public trialId: number;

    /**
    * The primary key Id of the Institution
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Institution", IsRequired=true, Name="InstitutionId", ParameterType="path")
    public institutionId: number;

    public constructor(init?: Partial<ExportTrialInstitutionItemInstances>) { (Object as any).assign(this, init); }
}

// @Route("/api/trialInstitutionitemtemplates", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialInstitutionItemTemplates implements IReturn<GetTrialInstitutionItemTemplatesResponse>
{
    public trialId?: number;

    public constructor(init?: Partial<GetTrialInstitutionItemTemplates>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialInstitutionItemTemplatesResponse(); }
    public getTypeName() { return 'GetTrialInstitutionItemTemplates'; }
}

// @Route("/api/trialInstitutionitemtemplates/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="TrialInstitutionItemTemplate not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialInstitutionItemTemplateById implements IReturn<GetTrialInstitutionItemTemplateByIdResponse>, IGet
{
    /**
    * The primary key Id of the TrialInstitutionItemTemplate
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the TrialInstitutionItemTemplate", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTrialInstitutionItemTemplateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialInstitutionItemTemplateByIdResponse(); }
    public getTypeName() { return 'GetTrialInstitutionItemTemplateById'; }
}

// @Route("/api/trialInstitutionitemtemplates/save/{Id}", "POST")
// @Route("/api/trialInstitutionitemtemplates/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrialInstitutionItemTemplate implements IReturn<SetTrialInstitutionItemTemplateResponse>
{
    /**
    * The TrialInstitutionItemTemplate Form data to save
    */
    // @ApiMember(DataType="TrialInstitutionItemTemplate", Description="The TrialInstitutionItemTemplate Form data to save", IsRequired=true, Name="TrialInstitutionItemTemplate", ParameterType="body")
    public trialInstitutionItemTemplate: TrialInstitutionItemTemplate;

    public collection: Collection;
    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTrialInstitutionItemTemplate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrialInstitutionItemTemplateResponse(); }
    public getTypeName() { return 'SetTrialInstitutionItemTemplate'; }
}

// @Route("/api/trialInstitutionitemtemplates/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTrialInstitutionItemTemplate implements IReturn<CreateTrialInstitutionItemTemplateResponse>
{
    public trialId?: number;

    public constructor(init?: Partial<CreateTrialInstitutionItemTemplate>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTrialInstitutionItemTemplateResponse(); }
    public getTypeName() { return 'CreateTrialInstitutionItemTemplate'; }
}

// @Route("/api/trialinstitutions", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialInstitutions implements IReturn<GetTrialInstitutionsResponse>
{

    public constructor(init?: Partial<GetTrialInstitutions>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialInstitutionsResponse(); }
    public getTypeName() { return 'GetTrialInstitutions'; }
}

// @Route("/api/trialinstitution/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Trial Item not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialInstitutionById implements IReturn<GetTrialInstitutionByIdResponse>, IGet
{
    /**
    * The primary key Id of the trial institution
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the trial institution", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTrialInstitutionById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialInstitutionByIdResponse(); }
    public getTypeName() { return 'GetTrialInstitutionById'; }
}

// @Route("/api/trialinstitution/save/{Id}", "POST")
// @Route("/api/trialinstitution/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrialInstitution implements IReturn<SetTrialInstitutionResponse>
{
    /**
    * The TrialInstitution Form data to save
    */
    // @ApiMember(DataType="TrialInstitution", Description="The TrialInstitution Form data to save", IsRequired=true, Name="trialInstitution", ParameterType="body")
    public trialInstitution: TrialInstitution;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTrialInstitution>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrialInstitutionResponse(); }
    public getTypeName() { return 'SetTrialInstitution'; }
}

// @Route("/api/trialinstitution/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTrialInstitution implements IReturn<CreateTrialInstitutionResponse>
{
    public trialId: number;

    public constructor(init?: Partial<CreateTrialInstitution>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTrialInstitutionResponse(); }
    public getTypeName() { return 'CreateTrialInstitution'; }
}

// @Route("/api/trialitemimport/id/{Id}/{TrialId}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Trial not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialItemImportById implements IReturn<GetTrialItemImportByIdResponse>, IGet
{
    /**
    * The primary key Id of the Destination Trial
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Destination Trial", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    /**
    * The primary key Id of the Soruce Trial
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Soruce Trial", IsRequired=true, Name="TrialId", ParameterType="path")
    public trialId: number;

    public constructor(init?: Partial<GetTrialItemImportById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialItemImportByIdResponse(); }
    public getTypeName() { return 'GetTrialItemImportById'; }
}

// @Route("/api/trialitemimport/save/{Id}/{TrialId}", "POST")
// @Route("/api/trialitemimport/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrialItemImport implements IReturn<SetTrialItemImportResponse>
{
    /**
    * The Trial Form data to save
    */
    // @ApiMember(DataType="Trial", Description="The Trial Form data to save", IsRequired=true, Name="trial", ParameterType="body")
    public trialItemImport: TrialItemImport;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    /**
    * The primary key Id of the Soruce Trial
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Soruce Trial", IsRequired=true, Name="TrialId", ParameterType="path")
    public trialId: number;

    public constructor(init?: Partial<SetTrialItemImport>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrialItemImportResponse(); }
    public getTypeName() { return 'SetTrialItemImport'; }
}

// @Route("/api/trialiteminstanceimport/id/{Id}/{TrialId}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Trial not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialItemInstanceImportById implements IReturn<GetTrialItemInstanceImportByIdResponse>, IGet
{
    /**
    * The primary key Id of the Destination Trial
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Destination Trial", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    /**
    * The primary key Id of the Soruce Trial
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Soruce Trial", IsRequired=true, Name="TrialId", ParameterType="path")
    public trialId: number;

    public constructor(init?: Partial<GetTrialItemInstanceImportById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialItemInstanceImportByIdResponse(); }
    public getTypeName() { return 'GetTrialItemInstanceImportById'; }
}

// @Route("/api/trialiteminstanceimport/save/{Id}/{TrialId}", "POST")
// @Route("/api/trialiteminstanceimport/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrialItemInstanceImport implements IReturn<SetTrialItemInstanceImportResponse>
{
    /**
    * The Trial Form data to save
    */
    // @ApiMember(DataType="Trial", Description="The Trial Form data to save", IsRequired=true, Name="trial", ParameterType="body")
    public trialItemInstanceImport: TrialItemInstanceImport;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    /**
    * The primary key Id of the Soruce Trial
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Soruce Trial", IsRequired=true, Name="TrialId", ParameterType="path")
    public trialId: number;

    public constructor(init?: Partial<SetTrialItemInstanceImport>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrialItemInstanceImportResponse(); }
    public getTypeName() { return 'SetTrialItemInstanceImport'; }
}

// @Route("/api/dashboard/trialiteminstances", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialItemInstancesByCurrentUser implements IReturn<GetTrialItemInstancesByCurrentUserResponse>
{

    public constructor(init?: Partial<GetTrialItemInstancesByCurrentUser>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialItemInstancesByCurrentUserResponse(); }
    public getTypeName() { return 'GetTrialItemInstancesByCurrentUser'; }
}

// @Route("/api/TrialItemInstance/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="trial item instance not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialItemInstanceById implements IReturn<GetTrialItemInstanceByIdResponse>, IGet
{
    /**
    * The primary key Id of the trial item instance
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the trial item instance", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTrialItemInstanceById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialItemInstanceByIdResponse(); }
    public getTypeName() { return 'GetTrialItemInstanceById'; }
}

// @Route("/api/TrialItemInstance/save/{Id}", "POST")
// @Route("/api/TrialItemInstance/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrialItemInstance implements IReturn<SetTrialItemInstanceResponse>
{
    /**
    * The TrialItemInstance Form data to save
    */
    // @ApiMember(DataType="TrialItemInstance", Description="The TrialItemInstance Form data to save", IsRequired=true, Name="TrialItemInstance", ParameterType="body")
    public trialItemInstance: TrialItemInstance;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTrialItemInstance>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrialItemInstanceResponse(); }
    public getTypeName() { return 'SetTrialItemInstance'; }
}

// @Route("/api/TrialItemInstance/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTrialItemInstance implements IReturn<CreateTrialItemInstanceResponse>
{
    public trialId: number;
    public templateTrialItemId: number;
    public instanceNumber: number;

    public constructor(init?: Partial<CreateTrialItemInstance>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTrialItemInstanceResponse(); }
    public getTypeName() { return 'CreateTrialItemInstance'; }
}

// @Route("/api/TrialItemInstances/{TrialId}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="trial item instance not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialItemInstancesByTrialId implements IReturn<GetTrialItemInstancesByTrialIdResponse>, IGet
{
    /**
    * The primary key Id of the trial
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the trial", IsRequired=true, Name="TrialId", ParameterType="path")
    public trialId: number;

    public constructor(init?: Partial<GetTrialItemInstancesByTrialId>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialItemInstancesByTrialIdResponse(); }
    public getTypeName() { return 'GetTrialItemInstancesByTrialId'; }
}

// @Route("/api/TrialItemInstance/export/{TrialId}", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class ExportTrialItemInstances
{
    /**
    * The primary key Id of the Trial
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Trial", IsRequired=true, Name="TrialId", ParameterType="path")
    public trialId: number;

    public constructor(init?: Partial<ExportTrialItemInstances>) { (Object as any).assign(this, init); }
}

// @Route("/api/trialitem/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Trial Item not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialItemById implements IReturn<GetTrialItemByIdResponse>, IGet
{
    /**
    * The primary key Id of the trial item
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the trial item", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTrialItemById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialItemByIdResponse(); }
    public getTypeName() { return 'GetTrialItemById'; }
}

// @Route("/api/trialItem/save/{Id}", "POST")
// @Route("/api/trialItem/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrialItem implements IReturn<SetTrialItemResponse>
{
    /**
    * The TrialItem Form data to save
    */
    // @ApiMember(DataType="TrialItem", Description="The TrialItem Form data to save", IsRequired=true, Name="trialItem", ParameterType="body")
    public trialItem: TrialItem;

    public collection: Collection;
    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTrialItem>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrialItemResponse(); }
    public getTypeName() { return 'SetTrialItem'; }
}

// @Route("/api/trialItem/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTrialItem implements IReturn<CreateTrialItemResponse>
{
    public trialId: number;

    public constructor(init?: Partial<CreateTrialItem>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTrialItemResponse(); }
    public getTypeName() { return 'CreateTrialItem'; }
}

// @Route("/api/trialitemupdate/id/{Id}/{TrialItemId}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Trial not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialItemUpdateById implements IReturn<GetTrialItemUpdateByIdResponse>, IGet
{
    /**
    * The primary key Id of the Trial
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Trial", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    /**
    * The primary key Id of the Trial Item
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Trial Item", IsRequired=true, Name="TrialItemId", ParameterType="path")
    public trialItemId: number;

    public constructor(init?: Partial<GetTrialItemUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialItemUpdateByIdResponse(); }
    public getTypeName() { return 'GetTrialItemUpdateById'; }
}

// @Route("/api/trialitemupdate/save/{Id}/{TrialItemId}", "POST")
// @Route("/api/trialitemupdate/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrialItemUpdate implements IReturn<SetTrialItemUpdateResponse>
{
    /**
    * The Trial Item Form data to save
    */
    // @ApiMember(DataType="TrialItemUpdate", Description="The Trial Item Form data to save", IsRequired=true, Name="TrialItemUpdate", ParameterType="body")
    public trialItemUpdate: TrialItemUpdate;

    /**
    * The trial Id
    */
    // @ApiMember(DataType="string", Description="The trial Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    /**
    * The primary key Id of Trial Item
    */
    // @ApiMember(DataType="string", Description="The primary key Id of Trial Item", IsRequired=true, Name="TrialItemId", ParameterType="path")
    public trialItemId: number;

    public constructor(init?: Partial<SetTrialItemUpdate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrialItemUpdateResponse(); }
    public getTypeName() { return 'SetTrialItemUpdate'; }
}

// @Route("/api/trials", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrials implements IReturn<GetTrialsResponse>
{
    public forCurrentUser: boolean;
    public showInActiveTrialItems: boolean;

    public constructor(init?: Partial<GetTrials>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialsResponse(); }
    public getTypeName() { return 'GetTrials'; }
}

// @Route("/api/trial/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Trial not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialById implements IReturn<GetTrialByIdResponse>, IGet
{
    /**
    * The primary key Id of the Trial
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the Trial", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTrialById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialByIdResponse(); }
    public getTypeName() { return 'GetTrialById'; }
}

// @Route("/api/trial/save/{Id}", "POST")
// @Route("/api/trial/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrial implements IReturn<SetTrialResponse>
{
    /**
    * The Trial Form data to save
    */
    // @ApiMember(DataType="Trial", Description="The Trial Form data to save", IsRequired=true, Name="trial", ParameterType="body")
    public trial: Trial;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTrial>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrialResponse(); }
    public getTypeName() { return 'SetTrial'; }
}

// @Route("/api/trial/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTrial implements IReturn<CreateTrialResponse>
{

    public constructor(init?: Partial<CreateTrial>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTrialResponse(); }
    public getTypeName() { return 'CreateTrial'; }
}

// @Route("/api/trialtrackers", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialTrackers implements IReturn<GetTrialTrackersResponse>
{
    public trialId: number;

    public constructor(init?: Partial<GetTrialTrackers>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialTrackersResponse(); }
    public getTypeName() { return 'GetTrialTrackers'; }
}

// @Route("/api/trialtracker/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Trial Item not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialTrackerById implements IReturn<GetTrialTrackerByIdResponse>, IGet
{
    /**
    * The primary key Id of the trial tracker
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the trial tracker", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTrialTrackerById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialTrackerByIdResponse(); }
    public getTypeName() { return 'GetTrialTrackerById'; }
}

// @Route("/api/trialtracker/save/{Id}", "POST")
// @Route("/api/trialtracker/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrialTracker implements IReturn<SetTrialTrackerResponse>
{
    /**
    * The TrialTracker Form data to save
    */
    // @ApiMember(DataType="TrialTracker", Description="The TrialTracker Form data to save", IsRequired=true, Name="trialTracker", ParameterType="body")
    public trialTracker: TrialTracker;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTrialTracker>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrialTrackerResponse(); }
    public getTypeName() { return 'SetTrialTracker'; }
}

// @Route("/api/trialtracker/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTrialTracker implements IReturn<CreateTrialTrackerResponse>
{
    public trialId: number;

    public constructor(init?: Partial<CreateTrialTracker>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTrialTrackerResponse(); }
    public getTypeName() { return 'CreateTrialTracker'; }
}

// @Route("/api/trialtreatments", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialTreatments implements IReturn<GetTrialTreatmentsResponse>
{
    public trialId: number;

    public constructor(init?: Partial<GetTrialTreatments>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialTreatmentsResponse(); }
    public getTypeName() { return 'GetTrialTreatments'; }
}

// @Route("/api/trialtreatment/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Trial Item not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialTreatmentById implements IReturn<GetTrialTreatmentByIdResponse>, IGet
{
    /**
    * The primary key Id of the trial treatment
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the trial treatment", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTrialTreatmentById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialTreatmentByIdResponse(); }
    public getTypeName() { return 'GetTrialTreatmentById'; }
}

// @Route("/api/trialtreatment/save/{Id}", "POST")
// @Route("/api/trialtreatment/save", "POST")
// @ApiResponse(Description="Form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetTrialTreatment implements IReturn<SetTrialTreatmentResponse>
{
    /**
    * The TrialTreatment Form data to save
    */
    // @ApiMember(DataType="TrialTreatment", Description="The TrialTreatment Form data to save", IsRequired=true, Name="trialTreatment", ParameterType="body")
    public trialTreatment: TrialTreatment;

    /**
    * The Id
    */
    // @ApiMember(DataType="string", Description="The Id", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<SetTrialTreatment>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetTrialTreatmentResponse(); }
    public getTypeName() { return 'SetTrialTreatment'; }
}

// @Route("/api/trialtreatment/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateTrialTreatment implements IReturn<CreateTrialTreatmentResponse>
{
    public trialId: number;

    public constructor(init?: Partial<CreateTrialTreatment>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTrialTreatmentResponse(); }
    public getTypeName() { return 'CreateTrialTreatment'; }
}

// @Route("/api/trialtreatment/sync", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SyncTrialTreatment implements IReturn<SyncTrialTreatmentResponse>
{
    public trialId: number;

    public constructor(init?: Partial<SyncTrialTreatment>) { (Object as any).assign(this, init); }
    public createResponse() { return new SyncTrialTreatmentResponse(); }
    public getTypeName() { return 'SyncTrialTreatment'; }
}

// @Route("/trogapi/institution", "GET")
// @Route("/trogapi/institution/{Id}", "GET")
export class GetInstitution implements IReturn<GetInstitutionResponse>
{
    public id?: number;
    public code: string;

    public constructor(init?: Partial<GetInstitution>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetInstitutionResponse(); }
    public getTypeName() { return 'GetInstitution'; }
}

// @Route("/trogapi/trial/institutions", "GET")
// @Route("/trogapi/trial/institutions/{code}", "GET")
export class GetTrogApiTrialInstitutions implements IReturn<GetTrogApiTrialInstitutionsResponse>
{
    public id?: number;
    public code: string;

    public constructor(init?: Partial<GetTrogApiTrialInstitutions>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrogApiTrialInstitutionsResponse(); }
    public getTypeName() { return 'GetTrogApiTrialInstitutions'; }
}

// @Route("/trogapi/users", "GET")
export class GetUsers implements IReturn<GetUsersResponse>
{
    public trialId?: number;
    public instId?: number;
    public groupIds: number[];

    public constructor(init?: Partial<GetUsers>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetUsersResponse(); }
    public getTypeName() { return 'GetUsers'; }
}

// @Route("/trogapi/usersapikey", "GET")
export class GetUserApiKey implements IReturn<GetUserApiKeyResponse>
{
    public username: string;
    public apiKey: string;

    public constructor(init?: Partial<GetUserApiKey>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetUserApiKeyResponse(); }
    public getTypeName() { return 'GetUserApiKey'; }
}

// @Route("/trogapi/permission/single", "POST")
export class HasPermission implements IReturn<HasPermissionResponse>, IPost
{
    public username: string;
    public permissionQuery: PermissionQuery;

    public constructor(init?: Partial<HasPermission>) { (Object as any).assign(this, init); }
    public createResponse() { return new HasPermissionResponse(); }
    public getTypeName() { return 'HasPermission'; }
}

// @Route("/trogapi/permission/multi", "POST")
export class HasPermissions implements IReturn<HasPermissionsResponse>, IPost
{
    public username: string;
    public permissionQueries: PermissionQuery[];

    public constructor(init?: Partial<HasPermissions>) { (Object as any).assign(this, init); }
    public createResponse() { return new HasPermissionsResponse(); }
    public getTypeName() { return 'HasPermissions'; }
}

// @Route("/trogapi/permission/any", "POST")
export class HasPermissionsAny implements IReturn<HasPermissionsAnyResponse>, IPost
{
    public username: string;
    public permissionQueries: PermissionQuery[];

    public constructor(init?: Partial<HasPermissionsAny>) { (Object as any).assign(this, init); }
    public createResponse() { return new HasPermissionsAnyResponse(); }
    public getTypeName() { return 'HasPermissionsAny'; }
}

// @Route("/trogapi/permission/all", "POST")
export class HasPermissionsAll implements IReturn<HasPermissionsAllResponse>, IPost
{
    public username: string;
    public permissionQueries: PermissionQuery[];

    public constructor(init?: Partial<HasPermissionsAll>) { (Object as any).assign(this, init); }
    public createResponse() { return new HasPermissionsAllResponse(); }
    public getTypeName() { return 'HasPermissionsAll'; }
}

// @Route("/trogapi/permission/evaluate-all", "POST")
export class EvaluateAllPermissions implements IReturn<EvaluateAllPermissionsResponse>, IPost
{
    public username: string;
    public permissionQueries: PermissionQuery[];

    public constructor(init?: Partial<EvaluateAllPermissions>) { (Object as any).assign(this, init); }
    public createResponse() { return new EvaluateAllPermissionsResponse(); }
    public getTypeName() { return 'EvaluateAllPermissions'; }
}

// @Route("/trogapi/permission-configurations", "GET")
export class GetPermissionConfigurations implements IReturn<GetPermissionConfigurationsResponse>, IGet
{

    public constructor(init?: Partial<GetPermissionConfigurations>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPermissionConfigurationsResponse(); }
    public getTypeName() { return 'GetPermissionConfigurations'; }
}

// @Route("/trogapi/permission-configuration", "GET")
export class GetPermissionConfigurationByPermissionId implements IReturn<GetPermissionConfigurationByPermissionIdResponse>, IGet
{
    public permission: Permission;

    public constructor(init?: Partial<GetPermissionConfigurationByPermissionId>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPermissionConfigurationByPermissionIdResponse(); }
    public getTypeName() { return 'GetPermissionConfigurationByPermissionId'; }
}

// @Route("/trogapi/permissions/by-user-and-context", "POST")
export class GetPermissionsForUserAndContext implements IReturn<GetPermissionsForUserAndContextResponse>, IPost
{
    public userName: string;
    public permissionResolutionType?: PermissionResolutionType;
    public permissionContext: PermissionContext;
    public userType?: UserSessionType;
    public trialId?: number;
    public institutionCode: string;
    public email: string;

    public constructor(init?: Partial<GetPermissionsForUserAndContext>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPermissionsForUserAndContextResponse(); }
    public getTypeName() { return 'GetPermissionsForUserAndContext'; }
}

// @Route("/trogapi/permissions/get-api-user", "GET")
export class GetUserByUserName implements IReturn<GetUserByUserNameResponse>, IPost
{
    public userName: string;

    public constructor(init?: Partial<GetUserByUserName>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetUserByUserNameResponse(); }
    public getTypeName() { return 'GetUserByUserName'; }
}

// @Route("/api/websitesetting", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetWebsiteSetting implements IReturn<GetWebsiteSettingResponse>
{

    public constructor(init?: Partial<GetWebsiteSetting>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetWebsiteSettingResponse(); }
    public getTypeName() { return 'GetWebsiteSetting'; }
}

// @Route("/api/websitesetting", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class PostWebsiteSetting implements IReturn<PostWebsiteSettingResponse>
{
    public websiteSetting: WebsiteSetting;

    public constructor(init?: Partial<PostWebsiteSetting>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostWebsiteSettingResponse(); }
    public getTypeName() { return 'PostWebsiteSetting'; }
}

// @Route("/api/user", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetUser implements IReturn<GetUserResponse>
{

    public constructor(init?: Partial<GetUser>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetUserResponse(); }
    public getTypeName() { return 'GetUser'; }
}

// @Route("/api/home", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetHome implements IReturn<GetHomeResponse>
{

    public constructor(init?: Partial<GetHome>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetHomeResponse(); }
    public getTypeName() { return 'GetHome'; }
}

export class SendOpenClinicaRoleEmail implements IReturn<SendOpenClinicaRoleEmailResponse>
{
    public openClinicaUserRoleId?: number;

    public constructor(init?: Partial<SendOpenClinicaRoleEmail>) { (Object as any).assign(this, init); }
    public createResponse() { return new SendOpenClinicaRoleEmailResponse(); }
    public getTypeName() { return 'SendOpenClinicaRoleEmail'; }
}

// @Route("/api/file/temp/upload", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class UploadTempFile implements IReturn<UploadTempFileResponse>
{
    public json: boolean;

    public constructor(init?: Partial<UploadTempFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new UploadTempFileResponse(); }
    public getTypeName() { return 'UploadTempFile'; }
}

// @Route("/api/file/temp/info/{Guid}", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTempFileInfo implements IReturn<GetTempFileInfoResponse>
{
    /**
    * The Guid (name) of the temporary file to retrieve
    */
    // @ApiMember(DataType="string", Description="The Guid (name) of the temporary file to retrieve", IsRequired=true, Name="Guid", ParameterType="path")
    public guid: string;

    public constructor(init?: Partial<GetTempFileInfo>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTempFileInfoResponse(); }
    public getTypeName() { return 'GetTempFileInfo'; }
}

// @Route("/api/file/temp/get/{Guid}", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTempFile
{
    /**
    * The Guid (name) of the temporary file to retrieve
    */
    // @ApiMember(DataType="string", Description="The Guid (name) of the temporary file to retrieve", IsRequired=true, Name="Guid", ParameterType="path")
    public guid: string;

    public constructor(init?: Partial<GetTempFile>) { (Object as any).assign(this, init); }
}

// @Route("/source-document/{Id}", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetSourceDocumentFile
{
    /**
    * The Id of the source document
    */
    // @ApiMember(DataType="integer", Description="The Id of the source document", Format="int", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetSourceDocumentFile>) { (Object as any).assign(this, init); }
}

// @Route("/source-document/download/{Id}", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class DownloadSourceDocumentFile
{
    /**
    * The Id of the source document
    */
    // @ApiMember(DataType="integer", Description="The Id of the source document", Format="int", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<DownloadSourceDocumentFile>) { (Object as any).assign(this, init); }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=16)
    public useTokenCookie?: boolean;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

// @Route("/assignroles")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new AssignRolesResponse(); }
    public getTypeName() { return 'AssignRoles'; }
}

// @Route("/unassignroles")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new UnAssignRolesResponse(); }
    public getTypeName() { return 'UnAssignRoles'; }
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export class GetApiKeys implements IReturn<GetApiKeysResponse>, IGet
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetApiKeys>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetApiKeysResponse(); }
    public getTypeName() { return 'GetApiKeys'; }
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export class RegenerateApiKeys implements IReturn<RegenerateApiKeysResponse>, IPost
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegenerateApiKeys>) { (Object as any).assign(this, init); }
    public createResponse() { return new RegenerateApiKeysResponse(); }
    public getTypeName() { return 'RegenerateApiKeys'; }
}

