import { Reducer } from 'redux';
import { update } from '../../helpers/immutabilityHelper'
import * as Dtos from '../../dtos/Tmd.dtos';

import {
    ACTION_TOGGLE_EXPANDED,
    ToggleExpandedAction
} from '../../actions/pages/togglePage';

export class TogglePageItem {
    public key: string;
    public isExpanded: boolean;
}

export interface ITogglePageState {
    items: TogglePageItem[];
}

const initialState: ITogglePageState = {
    items: []
};

function setToggleExpandedState(state: ITogglePageState, key: string): ITogglePageState {
    let items: TogglePageItem[] = [...state.items];
    let item = items.find(f => f.key == key);
    if (!item) {
        items.push({ key: key, isExpanded: false });
    } else {
        item.isExpanded = !item.isExpanded;
    }
    const newState: ITogglePageState = update(state, {
        items: {
            $set: items
        }
    });
    return newState;
}

const TogglePageReducer: Reducer<ITogglePageState> = (state: ITogglePageState = initialState, action) => {
    switch (action.type) {
        case ACTION_TOGGLE_EXPANDED:
            return setToggleExpandedState(state, action.key);
    }

    return state;
}

export default TogglePageReducer;