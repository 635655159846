import * as React from 'react';

import * as classNames from "classnames";
import './GroupItemsPage.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";



import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu, TruncatedCell } from "../../../components/layouts/index";
import { IGroupReduxFormState, initialGroupState } from "../../../reducers/reactReduxForms/group";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";

import * as GroupActions from '../../../actions/groups';
import * as ToggleActions from '../../../actions/pages/togglePage';
import * as ToggleHelper from "../../../helpers/toggleHelper";
import { TogglePageItem } from '../../../reducers/pages/togglePage';

import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../../components/form';
import { PrimitiveFormDesigner, PrimitiveFormInput } from '../../../components/form/index';
import { GroupSubMenu } from '../../../helpers/subMenuHelper';

import {
    CollectionPage
} from "../../../containers/index";

interface IGroupItemsPageParams {
    groupId: number;
}

interface IGroupItemsPageProps {

    form: Dtos.Group & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IGroupReduxFormState;

    location: Location;
    groupId: number;
    match: match<IGroupItemsPageParams>;
    history: History;

    // From state
    group: Dtos.Group;
    groupItemsByCategory: Dtos.GroupItemCategory[];
    lookups: Dtos.Lookup[];

    loadingGroup: boolean;
    loadGroupSuccess: boolean;
    loadGroupFailure: boolean;

    savingGroup: boolean;
    saveGroupSuccess: boolean;
    saveGroupFailure: boolean;

    creatingGroup: boolean;
    createGroupSuccess: boolean;
    createGroupFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];
    toggleItems: TogglePageItem[]
}

interface IGroupItemsPageActions {
    clearGroup: GroupActions.IGroupClearActionCreator;
    loadGroup: GroupActions.IGroupLoadByIdActionCreator;

    saveGroup: GroupActions.IGroupSaveActionCreator;
    createGroup: GroupActions.IGroupCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    toggleExpand: ToggleActions.IToggleExpandedStateActionCreator;
}

type GroupItemsPageProps = IGroupItemsPageProps & IGroupItemsPageActions;


const formName: string = "Group";
const reduxFormName: string = "reduxForms.group";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class GroupItemsPage extends React.PureComponent<GroupItemsPageProps, any> {

    constructor(props: GroupItemsPageProps) {
        super(props);

        this.clearGroup = this.clearGroup.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadGroup,
            navigate,
            navigateReplace,
            groupId,
            createGroup
        } = this.props;

        if (groupId) {
            loadGroup(groupId);
        } else if (!groupId) {
            createGroup();
        }
    }

    componentWillReceiveProps(nextProps: GroupItemsPageProps) {
    }

    componentDidUpdate(prevProps: GroupItemsPageProps, prevState) {
        const {
            navigateReplace,
            group,
            groupId,
            form,
            navigate,
            loadGroup,
            loadingGroup,
            loadGroupSuccess,
            createGroup,
            creatingGroup,
            createGroupSuccess,
            savingGroup,
            saveGroupSuccess,
            saveGroupFailure,
            validationFailures
        } = this.props;

        if (groupId && prevProps.groupId != groupId) {
            loadGroup(groupId);
        }
        else if (!groupId && prevProps.groupId) {
            createGroup();
        }

        if (!loadingGroup && prevProps.loadingGroup) {
            if (loadGroupSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingGroup && prevProps.savingGroup) {
            if (saveGroupSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/groups")
                } else if (!form.saveAndReturn && groupId && groupId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !groupId && group && group.id > 0) {
                    navigate("/groups/" + group.id);
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingGroup && prevProps.creatingGroup) {
            if (createGroupSuccess) {
                this.loadForm();
            }
            else {

            }
        }
    }

    componentWillUpdate(nextProps: GroupItemsPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearGroup();
        loadForm(reduxFormName, initialGroupState);
        resetForm(reduxFormName);
    }

    clearGroup() {
        this.props.clearGroup();
    }

    showGroup(): boolean {
        const {
            loadingGroup,
            loadGroupSuccess
        } = this.props

        if (!loadingGroup && loadGroupSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            form,
            loadingGroup,
            permissions
        } = this.props;

        let id = form ? form.id : 0;

        return <RestrictedLayout
            loading={loadingGroup}
            permission={Dtos.Permission.GroupItemsView }
            subMenuItems={GroupSubMenu(id, permissions)}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            permissions,
            group,
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-9 mb-2"}>
                    <h2>Group Items</h2>
                </div>
                <div className="col-3" style={{ textAlign: 'right' }}>
                    {(AuthenticationHelper.hasPermission(permissions, Dtos.Permission.GroupItemsCreate) && group && group.id > 0) && (
                        <Link className={"btn btn-primary"} url={"/groups/" + group.id + '/groupitems/create'}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.showGroup() ?
                    "Loading Group Items" :
                    null
            }
            {
                this.showGroup() ?
                    <div className="mt-2">
                        {this.renderTable()}
                    </div> :
                    null
            }
        </div>
    }


    toggle(categoryId: number) {

    }


    renderTable() {
        const {
            group,
            groupItemsByCategory,
            toggleItems,
            toggleExpand
        } = this.props;


        if (!groupItemsByCategory || groupItemsByCategory.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no items to view.</span>
            </div>
        }

        const showPagination = groupItemsByCategory.length > DEFAULT_PAGE_SIZE;

        return <div>
            {groupItemsByCategory.map((item, index) => {
                const key = "group-items-page-" + item.categoryId + "-" + group.id;
                const expanded = ToggleHelper.isToggleExpanded(toggleItems, key);
                return (
                    <div key={"group-items-category" + item.category.id}>
                        <div className={tableHeaderClass} style={tableStyleClass} onClick={() => toggleExpand(key)}>
                            <div>
                                <FontAwesomeIcon icon={!expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" />
                            </div>
                            <span>{item.category.value}</span>
                        </div>
                        {expanded && (
                            <ReactTable
                                data={item.groupItemSummaries}
                                pageSizeOptions={PAGE_SIZE_OPTIONS}
                                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : item.groupItemSummaries.length}
                                pageSize={showPagination ? undefined : item.groupItemSummaries.length}
                                showPagination={showPagination}
                                className="-striped -highlight -clickable mb-3"
                                noDataText="There are currently no group items for this category"
                                resizable={false}
                                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                                    return {
                                        onClick: (e, handleOriginal) => {
                                            console.log(rowInfo);
                                            const url: string = "/groups/" + group.id + '/groupitems/' + rowInfo.original.id + "";
                                            this.props.navigate(url);

                                            if (handleOriginal) {
                                                handleOriginal()
                                            }

                                        }
                                    }
                                }}
                                columns={
                                    [
                                        {
                                            id: "id",
                                            Header: ' ',
                                            accessor: "id",
                                            className: "d-flex align-items-center justify-content-center",
                                            maxWidth: 50,
                                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.TRIALITEM} fixedWidth />
                                        },
                                        {
                                            id: "name",
                                            Header: "Name",
                                            accessor: "name",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            Cell: (props) => <TruncatedCell value={props.value} />
                                        },
                                        {
                                            id: "description",
                                            Header: "Description",
                                            accessor: "description",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            Cell: (props) => <TruncatedCell value={props.value} />
                                        },
                                        {
                                            id: "initialVersion",
                                            Header: "Initial Version",
                                            accessor: "initialVersion",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            maxWidth: 220,
                                            Cell: (props) => <TruncatedCell value={props.value} />
                                        }
                                    ]
                                }
                            />
                            )}
                    </div>
                )
            }
            )}
        </div>
    }


    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.Group) {
        const {
            saveGroup
        } = this.props;

        if (data) {
            let changes: Dtos.Group = {} as Dtos.Group;
            //let collectionChanges: Dtos.Collection = {} as Dtos.Collection;
            //saveGroup(Object.assign({ ...data }, changes), Object.assign({ ...collection }, collectionChanges));
        }
    }


    handleOnSubmitFailed(data: Dtos.Group) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }


    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/groups");
    }

    loadForm() {
        const {
            loadForm,
            group,
        } = this.props

        let extraData: Dtos.Group = {} as Dtos.Group;

        loadForm(reduxFormName, Object.assign({ ...group }, extraData));
    }

}

const groupItemActiveFilter = (groupItemCategories: Dtos.GroupItemCategory[]): Dtos.GroupItemCategory[] => {

    if (groupItemCategories) {
        let result = [ ...groupItemCategories];
        result.forEach(c => {
            c = { ...c, groupItemSummaries: c.groupItemSummaries.filter(f => f.active) };
        });
        result = result.filter(f => f.groupItemSummaries.length > 0);
        return result;
    }

    return [];
}

const mapStateToProps = (state: ITmdState, ownProps: GroupItemsPageProps): IGroupItemsPageProps => {

    let group: Dtos.Group | undefined = !(state.groups.formData instanceof Array) ? state.groups.formData : undefined;
    let groupItemsByCategory: Dtos.GroupItemCategory[] | undefined = group == undefined ? undefined : group.groupItemCategories;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        groupId: ownProps.match && ownProps.match.params.groupId.toString() != "create" ? ownProps.match.params.groupId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        group: group!,
        groupItemsByCategory: groupItemActiveFilter(groupItemsByCategory),

        loadingGroup: state.groups.loadState && state.groups.loadState.status == RequestState.Pending,
        loadGroupSuccess: state.groups.loadState && state.groups.loadState.status == RequestState.Success,
        loadGroupFailure: state.groups.loadState && state.groups.loadState.status == RequestState.Failure,

        savingGroup: state.groups.saveState && state.groups.saveState.status == RequestState.Pending,
        saveGroupSuccess: state.groups.saveState && state.groups.saveState.status == RequestState.Success,
        saveGroupFailure: state.groups.saveState && state.groups.saveState.status == RequestState.Failure,

        creatingGroup: state.groups.createState && state.groups.createState.status == RequestState.Pending,
        createGroupSuccess: state.groups.createState && state.groups.createState.status == RequestState.Success,
        createGroupFailure: state.groups.createState && state.groups.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: [],

        form: state.reduxForms.group,
        formState: state.groups.formState,
        formProperties: state.groups.formProperties,

        reduxFormState: state.reduxForms.formState.group,
        validationFailures: group ?
            state.groups.validationFailures :
            undefined,

        toggleItems: state.togglePage.items
    };
};

const mapDispatchToProps = (dispatch): IGroupItemsPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadGroup: bindActionCreators(GroupActions.LoadGroupById, dispatch),
        clearGroup: bindActionCreators(GroupActions.Clear, dispatch),

        saveGroup: bindActionCreators(GroupActions.SaveGroup, dispatch),
        createGroup: bindActionCreators(GroupActions.CreateGroup, dispatch),

        toggleExpand: bindActionCreators(ToggleActions.ToggleExpanded, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(GroupItemsPage);
