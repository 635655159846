import * as React from 'react';
import './TrialItemInstancesPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { TruncatedCell, PopoverCell } from "../../../components/layouts/index";
import { ITrialReduxFormState, initialTrialState } from "../../../reducers/reactReduxForms/trial";
import { renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import * as TrialItemInstancesPageActions from '../../../actions/pages/trialItemInstancePage';
import * as TrialActions from '../../../actions/trial';
import * as TrialItemInstanceActions from '../../../actions/trialItemInstance';
import * as ToggleActions from '../../../actions/pages/togglePage';
import * as ToggleHelper from "../../../helpers/toggleHelper";
import { TogglePageItem } from '../../../reducers/pages/togglePage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { Link } from "../../../components/routing/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TrialLayout } from '../../index';

import {
    TrialItemInstanceCreateVersion,
    CollectionInstancePage
} from "../../../containers/index";
import { FormFilterInput } from '../../../components/form';

interface ITrialItemInstancesPageParams {
    trialId: number;
}

interface ITrialItemInstancesPageProps {

    form: Dtos.Trial & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formFilter: IFormFilter;
    reduxFormState: ITrialReduxFormState;

    location: Location;
    trialId: number;
    match: match<ITrialItemInstancesPageParams>;
    history: History;

    addModalOpen: boolean,
    editModalOpen: boolean,
    collectionInstanceId: number,
    modalTitle: string,
    modalDescription: string,

    loadingTrial: boolean;
    loadTrialSuccess: boolean;
    loadTrialFailure: boolean;

    createVersionModalOpen: boolean,
    createVersionTrialId: number,
    createVersionTemplateTrialItemId: number,
    createVersionInstanceNumber: number,

    // From state
    trial: Dtos.Trial;
    trialItemInstances: Dtos.TrialItemInstanceCategory[];
    templateTrialItems: Dtos.TemplateTrialItem[];

    lookups: Dtos.Lookup[];

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];
    toggleItems: TogglePageItem[]
}

interface ITrialItemInstancesPageActions {
    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrialWithTrialItemInstances: TrialActions.ITrialWithTrialItemInstancesLoadByIdActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    filter: TrialItemInstanceActions.ITrialItemInstanceFilterActionCreator;
    
    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    setAddModelViewState: TrialItemInstancesPageActions.ITrialItemInstancesPageSetAddModalViewStateActionCreator;
    setEditModelViewState: TrialItemInstancesPageActions.ITrialItemInstancesPageSetEditModalViewStateActionCreator;
    setCreateVersionModelViewState: TrialItemInstancesPageActions.ITrialItemInstancesPageSetCreateVersionModalViewStateActionCreator;

    toggleExpand: ToggleActions.IToggleExpandedStateActionCreator;
}

type TrialItemInstancesPageProps = ITrialItemInstancesPageProps & ITrialItemInstancesPageActions;

const formName: string = "trial";
const reduxFormName: string = "reduxForms.trial";
const reduxFormNameCollectionInstance: string = "reduxForms.collectionInstance";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class TrialItemInstancesPage extends React.PureComponent<TrialItemInstancesPageProps, any> {

    constructor(props: TrialItemInstancesPageProps) {
        super(props);

        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTrialWithTrialItemInstances,
            navigate,
            trialId,
        } = this.props;

        if (trialId) {
            loadTrialWithTrialItemInstances(trialId);
        } else {
            // redirect
            navigate("/trials/" + trialId);
        }
    }

    componentDidUpdate(prevProps: TrialItemInstancesPageProps, prevState) {
        const {
            trialId,
            loadTrialWithTrialItemInstances,
        } = this.props;

        if (trialId && prevProps.trialId != trialId) {
            loadTrialWithTrialItemInstances(trialId);
        }
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        loadForm(reduxFormName, initialTrialState);
        resetForm(reduxFormName);
    }

    clearTrial() {
        this.props.clearTrial();
        this.props.setEditModelViewState(false, null, null, null);
    }

    render() {
        const {
            loadingTrial
        } = this.props

        return <TrialLayout loading={loadingTrial} permission={Dtos.Permission.TrialItemInstanceView}>
            {this.renderContent()}
            {
                this.renderItemModal()
            }
            {
                this.renderCreateVersionModal()
            }
            {
                this.renderAddModal()
            }
        </TrialLayout>;
    }

    renderContent() {
        const {
            trialItemInstances,
            trialId,
            setAddModelViewState,
            permissions
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col mb-2"}>
                    <h2>Trial Items</h2>
                </div>
                <div className="col-auto" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} />
                    {(trialId && trialId > 0 && AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialItemInstanceExport, trialId)) &&
                        <a className="btn btn-info mr-2"
                            href={encodeURI("/api/trialiteminstance/export/" + trialId)}
                        >
                            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.CLOUD_DOWNLOAD} fixedWidth />
                        </a>
                    }
                    {(AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialItemInstanceCreate, trialId, null) && trialId > 0) && (
                        <>
                            <Link className={"btn btn-warning mr-2"} url={"/trials/" + trialId + '/itemsimport'}>Import</Link>
                            <Link className={"btn btn-primary"} url={"/trials/" + trialId + '/items/create'}>Create</Link>
                        </>
                    )}
                </div>
            </div>
            {
                trialItemInstances ?
                    <div className="mt-2">
                        {this.renderTable()}
                    </div> :
                    null
            }
        </div>
    }

    renderTable() {
        const {
            trialItemInstances,
            setEditModelViewState,
            setCreateVersionModelViewState,
            toggleItems,
            toggleExpand,
            permissions,
            navigate,
            trialId
        } = this.props;

        if (!trialItemInstances || trialItemInstances.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no items to view.</span>
            </div>
        }

        const showPagination = false;

        return <div>
            {trialItemInstances.map((item, index) => {
                const key = "trial-item-instances-page-" + item.categoryId;
                const expanded = ToggleHelper.isToggleExpanded(toggleItems, key);
                return (
                    <div key={"trial-item-instances-category" + item.category.id}>
                        <div className={tableHeaderClass} style={tableStyleClass} onClick={() => toggleExpand(key)}>
                            <div>
                                <FontAwesomeIcon icon={!expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" />
                            </div>
                            <span>{item.category.value}</span>
                        </div>
                        {expanded && (
                            <ReactTable
                                data={item.trialItemInstanceSummaries}
                                pageSizeOptions={PAGE_SIZE_OPTIONS}
                                defaultPageSize={false}
                                pageSize={item && item.trialItemInstanceSummaries ? item.trialItemInstanceSummaries.length : 0}
                                showPagination={showPagination}
                                className="-striped -highlight -clickable mb-3"
                                noDataText="There are currently no trial items for this category"
                                resizable={false}
                                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                                    return {
                                        onClick: (e, handleOriginal) => {
                                            if (rowInfo.original.collectionInstanceId && rowInfo.original.collectionInstanceId > 0) {
                                                setEditModelViewState(true, rowInfo.original.collectionInstanceId, rowInfo.original.name, rowInfo.original.description);
                                            }

                                            if (handleOriginal) {
                                                handleOriginal()
                                            }

                                        },
                                        style: {
                                            color: (rowInfo && rowInfo.original && (rowInfo.original.dataEntered || !rowInfo.original.active)) ? '#212529' : '#9e9e9e'
                                        }
                                    }
                                }}
                                columns={
                                    [
                                        {
                                            id: "id",
                                            Header: ' ',
                                            accessor: "id",
                                            className: "d-flex align-items-center justify-content-center",
                                            maxWidth: 50,
                                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.TRIALITEM} fixedWidth />
                                        },
                                        {
                                            id: "name",
                                            Header: "Name",
                                            accessor: "name",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            Cell: (props) => <TruncatedCell value={props.value} />
                                        },
                                        {
                                            id: "description",
                                            Header: "Description",
                                            accessor: "description",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            Cell: (props) => <TruncatedCell value={props.value} />
                                        },
                                        {
                                            id: "assignedDisplay",
                                            Header: 'Assigned To',
                                            accessor: 'assignedDisplay',
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            Cell: (props) => <TruncatedCell value={props.value} />,
                                        },
                                        {
                                            id: "version",
                                            Header: "Version",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            maxWidth: 320,
                                            style: { whiteSpace: 'break-spaces' },
                                            Cell: row => (
                                                <div>
                                                    {row.original.version && (
                                                        <button disabled={!AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialItemInstanceUpdate, row.original.trialId, undefined)}
                                                            type="button"
                                                            onClick={
                                                                (e) => {
                                                                    e.stopPropagation();
                                                                    console.log(row);
                                                                    if (row.original.collectionInstanceId && row.original.collectionInstanceId > 0) {
                                                                        setEditModelViewState(true, row.original.collectionInstanceId, row.original.name, row.original.description);
                                                                    }
                                                                }
                                                            }
                                                            className="btn btn-outline-secondary btn-sm btn-row-version"
                                                        >
                                                            <PopoverCell overflow={true} value={row.original.display} popover={row.original.display} />
                                                        </button>
                                                    )}
                                                    {row.original.trialItemInstanceSummaryVersions && row.original.trialItemInstanceSummaryVersions.map((item, index) =>
                                                        <button disabled={!AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialItemInstanceUpdate, row.original.trialId, undefined)}
                                                            onClick={
                                                                (e) => {
                                                                    e.stopPropagation();
                                                                    console.log(row);
                                                                    if (item.collectionInstanceId && item.collectionInstanceId > 0) {
                                                                        setEditModelViewState(true, item.collectionInstanceId, row.original.name + " [" + item.version + "]", row.original.name);
                                                                    }
                                                                }
                                                            }
                                                            type="button"
                                                            className="btn btn-secondary btn-sm btn-row-version"
                                                        >
                                                            <PopoverCell overflow={true} value={item.display} popover={item.display } />
                                                        </button>
                                                    )}
                                                </div>
                                            )
                                        },
                                        {
                                            id: "create",
                                            Header: "",
                                            className: "d-flex align-items-right",
                                            maxWidth: 120,
                                            style: { textAlign: 'right' },
                                            Cell: row => (
                                                <div>
                                                    {row.original.allowVersions && (
                                                        <button disabled={!AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialItemInstanceUpdate, row.original.trialId, undefined)}
                                                            onClick={
                                                                (e) => {
                                                                    console.log(row);
                                                                    e.stopPropagation();
                                                                    setCreateVersionModelViewState(true, row.original.trialId, row.original.templateTrialItemId, row.original.instanceNumber)
                                                                }
                                                            }
                                                            type="button"
                                                            className="btn btn-outline-secondary mr-2 btn-sm btn-row-version"
                                                        >
                                                            <span>New version</span>
                                                        </button>
                                                    )}
                                                </div>
                                            )
                                        },
                                        {
                                            id: "edit",
                                            Header: "",
                                            className: "d-flex align-items-right",
                                            maxWidth: 48,
                                            style: { textAlign: 'right' },
                                            Cell: row => (
                                                <div>
                                                    
                                                        <button disabled={!AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialItemInstanceUpdate, row.original.trialId, undefined)}
                                                            onClick={
                                                                (e) => {
                                                                    e.stopPropagation();
                                                                    let url = "/trials/" + trialId + '/items/' + row.original.id; 
                                                                    navigate(url);
                                                                }
                                                            }
                                                            type="button"
                                                            className="btn btn-secondary btn-sm"
                                                        >
                                                            <FontAwesomeIcon icon={FontAwesomeIcons.Light.EDIT} fixedWidth />
                                                        </button>
                                                </div>
                                            )
                                        }
                                    ]
                                }
                            />
                        )}
                    </div>
                )
            }
            )}
        </div>
    }

    renderItemModal() {
        const {
            trialId,
            trial,
            loadingTrial,
            editModalOpen,
            setEditModelViewState,
            collectionInstanceId,
            loadTrialWithTrialItemInstances,
            modalTitle,
            modalDescription,
            form,
            permissions,
            formState
        } = this.props

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermissionFor(trialId, undefined, permissions, Dtos.Permission.TrialItemInstanceUpdate, Dtos.Permission.TrialItemInstanceCreate);
        const formDisabled: boolean = !hasPermission || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        console.log('item permissions', hasPermission, formDisabled, formState);

        if (trial && !loadingTrial && collectionInstanceId && collectionInstanceId > 0) {
            return <Modal
                open={editModalOpen}
                disableCloseOnOverlayClick={true}
                onClose={() => {
                    setEditModelViewState(false, null, "", "");
                    loadTrialWithTrialItemInstances(trialId);
                }}
                size={ModalSize.Xl}>
                <div className="modal-header">
                    <h5 className="modal-title">{setModalTitle(undefined, modalTitle)}{modalDescription ? <><br /><small style={{ color: '#444' }}>{modalDescription}</small></> : undefined}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => { setEditModelViewState(false, null, "", ""); loadTrialWithTrialItemInstances(trialId); }}>
                        <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                    </button>

                </div>
                <div className="modal-body">
                    <CollectionInstancePage
                        collectionInstanceId={collectionInstanceId}
                        readonly={formDisabled}
                        onClose={() => { setEditModelViewState(false, null, "", ""); loadTrialWithTrialItemInstances(trialId); }} />
                </div>
            </Modal>
        }
    }

    renderCreateVersionModal() {
        const {
            trialId,
            trial,
            loadingTrial,
            createVersionModalOpen,
            setCreateVersionModelViewState,
            loadTrialWithTrialItemInstances,
            createVersionTrialId,
            createVersionTemplateTrialItemId,
            createVersionInstanceNumber,
        } = this.props

        if (trial && !loadingTrial) {
            return <Modal open={createVersionModalOpen}
                disableCloseOnOverlayClick={true}
                onClose={() => { setCreateVersionModelViewState(false, null, null, null); loadTrialWithTrialItemInstances(trialId); }}
                size={ModalSize.Md}>
                <div className="modal-header">
                    <h5 className="modal-title">{setModalTitle(undefined, "Create Version")}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => { setCreateVersionModelViewState(false, null, null, null); loadTrialWithTrialItemInstances(trialId); }}>
                        <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                    </button>
                </div>
                <div className="modal-body" style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <TrialItemInstanceCreateVersion
                        trialId={createVersionTrialId}
                        templateTrialItemId={createVersionTemplateTrialItemId}
                        instanceNumber={createVersionInstanceNumber}
                        onClose={() => { setCreateVersionModelViewState(false, null, null, null); loadTrialWithTrialItemInstances(trialId); }} />
                </div>
            </Modal>
        }
    }

    renderAddModal() {
        const {
            trialId,
            trial,
            loadingTrial,
            addModalOpen,
            setAddModelViewState,
            loadTrialWithTrialItemInstances,
            templateTrialItems
        } = this.props

        if (trial && !loadingTrial) {
            return <Modal open={addModalOpen}
                disableCloseOnOverlayClick={true}
                onClose={() => { setAddModelViewState(false); loadTrialWithTrialItemInstances(trialId); }}
                size={ModalSize.Md}>
                <div className="modal-header">
                    <h5 className="modal-title">{setModalTitle(undefined, "Add new trial item")}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => { setAddModelViewState(false); loadTrialWithTrialItemInstances(trialId); }}>
                        <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                    </button>
                </div>
                <div className="modal-body p-3">
                    {templateTrialItems.map((item, index) => (
                        <button className="btn btn-outline-primary btn-block">
                            <b>{ item.name }</b>
                            {item.description && (
                                <>
                                    <br /><small>{ item.description }</small>
                                </>
                            )}
                        </button>
                    ))}
                </div>
            </Modal>
        }
    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
        </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    handleOnSubmitFailed(data: Dtos.Trial) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            trialId,
            navigate
        } = this.props;

        navigate("/trials/" + trialId);
    }

    loadForm() {
        const {
            loadForm,
            trial,
        } = this.props

        let extraData: Dtos.Trial = {} as Dtos.Trial;

        loadForm(reduxFormName, Object.assign({ ...trial }, extraData));
    }
}


const mapStateToProps = (state: ITmdState, ownProps: TrialItemInstancesPageProps): ITrialItemInstancesPageProps => {

    let trial: Dtos.Trial | undefined = !(state.trials.formData instanceof Array) ? state.trials.formData : undefined;
    //let trialItemInstances: Dtos.TrialItemInstanceCategory[] | undefined = state.trials.trialItemInstances == undefined ? undefined : state.trials.trialItemInstances;
    let trialItemInstances: Dtos.TrialItemInstanceCategory[] | undefined = state.trialItemInstance.formFilteredData == undefined ? undefined : state.trialItemInstance.formFilteredData;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,
        
        history: ownProps.history,
        location: state.routing.location,

        trial: trial!,
        trialItemInstances: trialItemInstances!,
        templateTrialItems: state.trials.templateTrialItems,

        addModalOpen: state.trialItemInstancePage.addModalOpen,
        editModalOpen: state.trialItemInstancePage.editModalOpen,
        collectionInstanceId: state.trialItemInstancePage.collectionInstanceId,
        modalTitle: state.trialItemInstancePage.name,
        modalDescription: state.trialItemInstancePage.description,

        createVersionModalOpen: state.trialItemInstancePage.createVersionModalOpen,
        createVersionTrialId: state.trialItemInstancePage.trialId,
        createVersionTemplateTrialItemId: state.trialItemInstancePage.templateTrialItemId,
        createVersionInstanceNumber: state.trialItemInstancePage.instanceNumber,

        loadingTrial: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,
        loadTrialSuccess: state.trials.loadState && state.trials.loadState.status == RequestState.Success,
        loadTrialFailure: state.trials.loadState && state.trials.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: state.trials.lookups,

        form: state.reduxForms.trial,
        formState: state.trials.formState,
        formProperties: state.trials.formProperties,
        formFilter: state.trialItemInstance.formFilter,

        reduxFormState: state.reduxForms.formState.trial,
        validationFailures: trial ?
            state.trials.validationFailures :
            undefined,

        toggleItems: state.togglePage.items

    };
};

const mapDispatchToProps = (dispatch): ITrialItemInstancesPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        filter: bindActionCreators(TrialItemInstanceActions.Filter, dispatch),
       
        loadTrialWithTrialItemInstances: bindActionCreators(TrialActions.LoadTrialWithTrialItemInstancesById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch),

        setAddModelViewState: bindActionCreators(TrialItemInstancesPageActions.setAddModalViewState, dispatch),
        setEditModelViewState: bindActionCreators(TrialItemInstancesPageActions.setEditModalViewState, dispatch),
        setCreateVersionModelViewState: bindActionCreators(TrialItemInstancesPageActions.setCreateVersionModalViewState, dispatch),

        toggleExpand: bindActionCreators(ToggleActions.ToggleExpanded, dispatch)

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialItemInstancesPage);
