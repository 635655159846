import * as Dtos from "../dtos/Tmd.dtos";

export const
    LoadHome: IHomeLoadActionCreator = () => {
        return {
            type: ACTION_HOME_LOAD
        }
    },
    LoadSuccess: IHomeLoadSuccessActionCreator = (home) => {
        return {
            type: ACTION_HOME_LOAD_SUCCESS,
            home
        }
    },
    LoadFailure: IHomeLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_HOME_LOAD_FAILURE,
            responseStatus
        }
    },
    Clear: IHomeClearActionCreator = () => {
        return {
            type: ACTION_HOME_CLEAR
        }
    };
    
    

export const
    ACTION_HOME_LOAD = "ACTION_HOME_LOAD",
    ACTION_HOME_LOAD_SUCCESS = "ACTION_HOME_LOAD_SUCCESS",
    ACTION_HOME_LOAD_FAILURE = "ACTION_HOME_LOAD_FAILURE",
    ACTION_HOME_CLEAR = "ACTION_HOME_CLEAR";

export interface IHomeLoadAction {
    type: "ACTION_HOME_LOAD";
}

export interface IHomeLoadActionCreator {
    (): IHomeLoadAction;
}

export interface IHomeLoadSuccessAction {
    type: "ACTION_HOME_LOAD_SUCCESS";
    home: Dtos.Home;
}

export interface IHomeLoadSuccessActionCreator {
    (home: Dtos.Home): IHomeLoadSuccessAction;
}

export interface IHomeLoadFailureAction {
    type: "ACTION_HOME_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IHomeLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IHomeLoadFailureAction;
}

export interface IHomeClearAction {
    type: "ACTION_HOME_CLEAR";
}

export interface IHomeClearActionCreator {
    (): IHomeClearAction;
}

export type HomeAction = 
    IHomeLoadAction |
    IHomeLoadFailureAction |
    IHomeLoadSuccessAction |
    IHomeClearAction;
