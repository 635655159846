import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_TRIALINSTITUTION_LOAD,
    ACTION_TRIALINSTITUTION_LOAD_BY_ID,

    ACTION_TRIALINSTITUTION_SAVE,

    ACTION_TRIALINSTITUTION_CREATE,

    ITrialInstitutionLoadAction,
    ITrialInstitutionLoadByIdAction,
    ITrialInstitutionLoadFailureAction,
    ITrialInstitutionLoadSuccessAction,

    ITrialInstitutionSaveAction,
    ITrialInstitutionSaveSuccessAction,
    ITrialInstitutionSaveFailureAction,

    ITrialInstitutionCreateAction,
    ITrialInstitutionCreateSuccessAction,
    ITrialInstitutionCreateFailureAction,

    LoadSuccess,
    LoadFailure,


    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,
} from "../actions/trialInstitution";
import { trialInstitutionsApi } from "../services/trialInstitutions";


export const
    getTrialInstitutionsEpic = (action$): Observable<ITrialInstitutionLoadSuccessAction | ITrialInstitutionLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALINSTITUTION_LOAD)
            .mergeMap((action: ITrialInstitutionLoadAction) => {
                return trialInstitutionsApi
                    .getTrialInstitutions()
                    .map(response =>
                        LoadSuccess(response.trialInstitutions, undefined, [], [], response.lookups, undefined)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTrialInstitutionsByIdEpic = (action$): Observable<ITrialInstitutionLoadSuccessAction | ITrialInstitutionLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALINSTITUTION_LOAD_BY_ID)
            .mergeMap((action: ITrialInstitutionLoadByIdAction) => {
                return trialInstitutionsApi
                    .getTrailItemById(action.id)
                    .map(response =>
                        LoadSuccess(response.trialInstitution, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setTrialInstitutionEpic = (action$): Observable<ITrialInstitutionSaveSuccessAction | ITrialInstitutionSaveFailureAction> => {
        return action$
            .ofType(ACTION_TRIALINSTITUTION_SAVE)
            .mergeMap((action: ITrialInstitutionSaveAction) => {
                return trialInstitutionsApi
                    .setTrialInstitution(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.trialInstitution, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createTrialInstitutionEpic = (action$): Observable<ITrialInstitutionCreateSuccessAction | ITrialInstitutionCreateFailureAction> => {
        return action$
            .ofType(ACTION_TRIALINSTITUTION_CREATE)
            .mergeMap((action: ITrialInstitutionCreateAction) => {
                return trialInstitutionsApi
                    .createTrialInstitution(action.trialId)
                    .map(response =>
                        CreateSuccess(response.trialInstitution, response.formState, response.formProperties, response.formRoles, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



