import * as React from 'react';
import './GendersPage.scss';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { RowInfo } from 'react-table';
import * as GenderActions from '../../../actions/genders';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { FormFilterInput } from '../../../components/form';
import { LookupsLayout } from '../../index';

interface IGendersPageProps {

    location: Location;
    history: History;

    // From state
    formFilter: IFormFilter;
    gender: Dtos.Gender[];
    loadingGenders: boolean;
    loadGendersSuccess: boolean;
    loadGendersFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    formRoles?: Dtos.RoleType[];
}

interface IGendersPageActions {
    clearGenders: GenderActions.IGenderClearActionCreator;
    loadGenders: GenderActions.IGenderLoadActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    filter: GenderActions.IGenderFilterActionCreator;
}

type GendersPageProps = IGendersPageProps & IGendersPageActions;

class GendersPage extends React.PureComponent<GendersPageProps, any> {

    constructor(props: GendersPageProps) {
        super(props);

        this.clearGenders = this.clearGenders.bind(this);
    }

    componentDidMount() {
        const {
            loadGenders
        } = this.props;

        loadGenders();
    }

    componentWillReceiveProps(nextProps: GendersPageProps) {
    }

    componentDidUpdate(prevProps: GendersPageProps, prevState) {
        const {
            navigateReplace
        } = this.props
    }

    componentWillUpdate(nextProps: GendersPageProps) {
    }

    componentWillUnmount() {
        this.clearGenders();
    }

    clearGenders() {
        this.props.clearGenders();
    }

    showGenders(): boolean {
        const {
            loadingGenders,
            loadGendersSuccess
        } = this.props

        if (!loadingGenders && loadGendersSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingGenders
        } = this.props;

        return <LookupsLayout loading={loadingGenders} permission={Dtos.Permission.GendersView}>
            {this.renderContent()}
        </LookupsLayout>;
    }

    renderContent() {
        const {
            permissions
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-6 mb-2"}>
                    <h2>Genders</h2>
                </div>
                <div className="col-6" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e) } />
                    
                    {AuthenticationHelper.hasPermission(permissions, Dtos.Permission.GendersCreate) && (
                        <Link className={"btn btn-primary"} url={"/settings/general/genders/create"}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.showGenders() ?
                    "Loading Genders" :
                    null
            }
            {
                this.showGenders() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {

        const {
            gender
        } = this.props

        if (!gender || gender.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no genders to view.</span>
            </div>
        }

        const showPagination = gender.length > DEFAULT_PAGE_SIZE;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={gender}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : gender.length}
                pageSize={showPagination ? undefined : gender.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no gender to display"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {

                            const url: string = "/settings/general/genders/" + rowInfo.original.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.LOOKUP} fixedWidth />
                    },
                    {
                        id: "value",
                        Header: 'Name',
                        accessor: 'value',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    }
                ]}
            />
        </div>

    }

}

const mapStateToProps = (state: ITmdState, ownProps: GendersPageProps): IGendersPageProps => {
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];
    return {

        history: ownProps.history,
        location: state.routing.location,

        formFilter: state.genders.formFilter,
        gender: state.genders.formFilteredData, 

        loadingGenders: state.genders.loadState && state.genders.loadState.status == RequestState.Pending,
        loadGendersSuccess: state.genders.loadState && state.genders.loadState.status == RequestState.Success,
        loadGendersFailure: state.genders.loadState && state.genders.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles
    };
};

const mapDispatchToProps = (dispatch): IGendersPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadGenders: bindActionCreators(GenderActions.LoadGender, dispatch),
        clearGenders: bindActionCreators(GenderActions.Clear, dispatch),

        filter: bindActionCreators(GenderActions.Filter, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(GendersPage);
