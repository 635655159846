import * as React from 'react';
import './TrialTypePage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { ITrialTypeReduxFormState, initialTrialTypeState } from "../../../reducers/reactReduxForms/trialType";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as TrialTypeActions from '../../../actions/trialType';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { Form, actions, Control } from 'react-redux-form';
import { TrialLookupsLayout } from '../../index';

interface ITrialTypePageParams {
    trialTypeId: number;
}

interface ITrialTypePageProps {

    form: Dtos.TrialType & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITrialTypeReduxFormState;

    location: Location;
    trialTypeId: number;
    match: match<ITrialTypePageParams>;
    history: History;

    // From state
    trialType: Dtos.TrialType;
    lookups: Dtos.Lookup[];

    loadingTrialType: boolean;
    loadTrialTypeSuccess: boolean;
    loadTrialTypeFailure: boolean;

    savingTrialType: boolean;
    saveTrialTypeSuccess: boolean;
    saveTrialTypeFailure: boolean;

    creatingTrialType: boolean;
    createTrialTypeSuccess: boolean;
    createTrialTypeFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface ITrialTypePageActions {
    clearTrialType: TrialTypeActions.ITrialTypeClearActionCreator;
    loadTrialType: TrialTypeActions.ITrialTypeLoadByIdActionCreator;

    saveTrialType: TrialTypeActions.ITrialTypeSaveActionCreator;
    createTrialType: TrialTypeActions.ITrialTypeCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type TrialTypePageProps = ITrialTypePageProps & ITrialTypePageActions;


const formName: string = "notetype";
const reduxFormName: string = "reduxForms.trialType";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class TrialTypePage extends React.PureComponent<TrialTypePageProps, any> {

    constructor(props: TrialTypePageProps) {
        super(props);

        this.clearTrialType = this.clearTrialType.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTrialType,
            navigate,
            navigateReplace,
            trialTypeId,
            createTrialType
        } = this.props;

        if (trialTypeId) {
            loadTrialType(trialTypeId);
        } else if (!trialTypeId) {
            createTrialType();
        }
    }

    componentWillReceiveProps(nextProps: TrialTypePageProps) {
    }

    componentDidUpdate(prevProps: TrialTypePageProps, prevState) {
        const {
            navigateReplace,
            trialTypeId,
            trialType,
            form,
            navigate,
            loadTrialType,
            loadingTrialType,
            loadTrialTypeSuccess,
            createTrialType,
            creatingTrialType,
            createTrialTypeSuccess,
            savingTrialType,
            saveTrialTypeSuccess,
            saveTrialTypeFailure,
            validationFailures
        } = this.props;

        if (trialTypeId && prevProps.trialTypeId != trialTypeId) {
            loadTrialType(trialTypeId);
        }
        else if (!trialTypeId && prevProps.trialTypeId) {
            createTrialType();
        }

        if (!loadingTrialType && prevProps.loadingTrialType) {
            if (loadTrialTypeSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingTrialType && prevProps.savingTrialType) {
            if (saveTrialTypeSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/settings/trial/trialtypes")
                } else if (!form.saveAndReturn && trialTypeId && trialTypeId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !trialTypeId && trialType && trialType.id > 0) {
                    navigate("/settings/trial/trialtypes/" + trialType.id);
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingTrialType && prevProps.creatingTrialType) {
            if (createTrialTypeSuccess) {
                this.loadForm();
            }
            else {

            }
        }

    }

    componentWillUpdate(nextProps: TrialTypePageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearTrialType();
        loadForm(reduxFormName, initialTrialTypeState);
        resetForm(reduxFormName);
    }

    clearTrialType() {
        this.props.clearTrialType();
    }

    showTrialType(): boolean {
        const {
            loadingTrialType,
            loadTrialTypeSuccess
        } = this.props

        if (!loadingTrialType && loadTrialTypeSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrialType
        } = this.props;

        return <TrialLookupsLayout loading={loadingTrialType} permission={Dtos.Permission.TrialTypesView}>
            {this.renderContent()}
        </TrialLookupsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingTrialType,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.TrialTypesUpdate, Dtos.Permission.TrialTypesCreate);
        const formDisabled: boolean = !hasPermission || savingTrialType || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            value: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            }
        };



        return <div>
            <h1>Trial Type</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "trialtype", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.TrialType) {
        const {
            saveTrialType
        } = this.props;

        if (data) {
            let changes: Dtos.Institution = {} as Dtos.Institution;
            saveTrialType(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.TrialType) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/trial/trialtypes");
    }

    loadForm() {
        const {
            loadForm,
            trialType,
        } = this.props

        let extraData: Dtos.TrialType = {} as Dtos.TrialType;

        loadForm(reduxFormName, Object.assign({ ...trialType }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: TrialTypePageProps): ITrialTypePageProps => {

    let trialType: Dtos.TrialType | undefined = !(state.trialTypes.formData instanceof Array) ? state.trialTypes.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialTypeId: ownProps.match && ownProps.match.params.trialTypeId && ownProps.match.params.trialTypeId.toString() != "create" ? ownProps.match.params.trialTypeId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trialType: trialType!,

        loadingTrialType: state.trialTypes.loadState && state.trialTypes.loadState.status == RequestState.Pending,
        loadTrialTypeSuccess: state.trialTypes.loadState && state.trialTypes.loadState.status == RequestState.Success,
        loadTrialTypeFailure: state.trialTypes.loadState && state.trialTypes.loadState.status == RequestState.Failure,

        savingTrialType: state.trialTypes.saveState && state.trialTypes.saveState.status == RequestState.Pending,
        saveTrialTypeSuccess: state.trialTypes.saveState && state.trialTypes.saveState.status == RequestState.Success,
        saveTrialTypeFailure: state.trialTypes.saveState && state.trialTypes.saveState.status == RequestState.Failure,

        creatingTrialType: state.trialTypes.createState && state.trialTypes.createState.status == RequestState.Pending,
        createTrialTypeSuccess: state.trialTypes.createState && state.trialTypes.createState.status == RequestState.Success,
        createTrialTypeFailure: state.trialTypes.createState && state.trialTypes.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: [],

        form: state.reduxForms.trialType,
        formState: state.trialTypes.formState,
        formProperties: state.trialTypes.formProperties,

        reduxFormState: state.reduxForms.formState.trialType,
        validationFailures: trialType ?
            state.trialTypes.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): ITrialTypePageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadTrialType: bindActionCreators(TrialTypeActions.LoadTrialTypeById, dispatch),
        clearTrialType: bindActionCreators(TrialTypeActions.Clear, dispatch),

        saveTrialType: bindActionCreators(TrialTypeActions.SaveTrialType, dispatch),
        createTrialType: bindActionCreators(TrialTypeActions.CreateTrialType, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialTypePage);
