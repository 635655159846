import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";
    
export const initialHomeFormState: Dtos.Home & IRegistrationFormState = {
    message: null,
    saveAndReturn: false,
}

export interface IHomeReduxFormState {
    message: IFieldState<string>
}
