import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadDepartment: IDepartmentLoadActionCreator = () => {
        return {
            type: ACTION_DEPARTMENT_LOAD
        }
    },
    LoadDepartmentById: IDepartmentLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_DEPARTMENT_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IDepartmentLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_DEPARTMENT_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IDepartmentLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_DEPARTMENT_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveDepartment: IDepartmentSaveActionCreator = (formData) => {
        return {
            type: ACTION_DEPARTMENT_SAVE,
            formData
        }
    },
    SaveSuccess: IDepartmentSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_DEPARTMENT_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IDepartmentSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_DEPARTMENT_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateDepartment: IDepartmentCreateActionCreator = (institutionId) => {

        return {
            type: ACTION_DEPARTMENT_CREATE,
            institutionId
        }
    },
    CreateSuccess: IDepartmentCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_DEPARTMENT_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: IDepartmentCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_DEPARTMENT_CREATE_FAILURE,
            responseStatus
        }
    },

    Clear: IDepartmentClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_DEPARTMENT_CLEAR,
            propertiesToClear
        }
    },

    Filter: IDepartmentFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_DEPARTMENT_FILTER,
            formFilter
        }
    };



export const
    ACTION_DEPARTMENT_LOAD = "ACTION_DEPARTMENT_LOAD",
    ACTION_DEPARTMENT_LOAD_BY_ID = "ACTION_DEPARTMENT_LOAD_BY_ID",
    ACTION_DEPARTMENT_LOAD_SUCCESS = "ACTION_DEPARTMENT_LOAD_SUCCESS",
    ACTION_DEPARTMENT_LOAD_FAILURE = "ACTION_DEPARTMENT_LOAD_FAILURE",

    ACTION_DEPARTMENT_SAVE = "ACTION_DEPARTMENT_SAVE",
    ACTION_DEPARTMENT_SAVE_SUCCESS = "ACTION_DEPARTMENT_SAVE_SUCCESS",
    ACTION_DEPARTMENT_SAVE_FAILURE = "ACTION_DEPARTMENT_SAVE_FAILURE",

    ACTION_DEPARTMENT_CREATE = "ACTION_DEPARTMENT_CREATE",
    ACTION_DEPARTMENT_CREATE_SUCCESS = "ACTION_DEPARTMENT_CREATE_SUCCESS",
    ACTION_DEPARTMENT_CREATE_FAILURE = "ACTION_DEPARTMENT_CREATE_FAILURE",

    ACTION_DEPARTMENT_CLEAR = "ACTION_DEPARTMENT_CLEAR",

    ACTION_DEPARTMENT_FILTER = "ACTION_DEPARTMENT_FILTER";

export interface IDepartmentLoadAction {
    type: "ACTION_DEPARTMENT_LOAD";
}

export interface IDepartmentLoadActionCreator {
    (): IDepartmentLoadAction;
}

export interface IDepartmentLoadSuccessAction {
    type: "ACTION_DEPARTMENT_LOAD_SUCCESS";
    formData: Dtos.Department | Dtos.Department[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IDepartmentLoadSuccessActionCreator {
    (
        formData: Dtos.Department | Dtos.Department[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IDepartmentLoadSuccessAction;
}

export interface IDepartmentLoadFailureAction {
    type: "ACTION_DEPARTMENT_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IDepartmentLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IDepartmentLoadFailureAction;
}

export interface IDepartmentLoadByIdAction {
    type: "ACTION_DEPARTMENT_LOAD_BY_ID";
    id: number;
}

export interface IDepartmentLoadByIdActionCreator {
    (id: number): IDepartmentLoadByIdAction;
}


export interface IDepartmentSaveAction {
    type: "ACTION_DEPARTMENT_SAVE";
    formData: Dtos.Department;

}

export interface IDepartmentSaveActionCreator {
    (
        formData: Dtos.Department
    ): IDepartmentSaveAction;
}

export interface IDepartmentSaveSuccessAction {
    type: "ACTION_DEPARTMENT_SAVE_SUCCESS";
    formData: Dtos.Department;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IDepartmentSaveSuccessActionCreator {
    (
        formData: Dtos.Department,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IDepartmentSaveSuccessAction;
}

export interface IDepartmentSaveFailureAction {
    type: "ACTION_DEPARTMENT_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IDepartmentSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IDepartmentSaveFailureAction;
}

export interface IDepartmentCreateAction {
    type: "ACTION_DEPARTMENT_CREATE",
    institutionId: number
}

export interface IDepartmentCreateActionCreator {
    (institutionId: number): IDepartmentCreateAction
}

export interface IDepartmentCreateSuccessAction {
    type: "ACTION_DEPARTMENT_CREATE_SUCCESS";
    formData: Dtos.Department;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface IDepartmentCreateSuccessActionCreator {
    (
        formData: Dtos.Department,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): IDepartmentCreateSuccessAction;
}

export interface IDepartmentCreateFailureAction {
    type: "ACTION_DEPARTMENT_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IDepartmentCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IDepartmentCreateFailureAction;
}

export interface IDepartmentClearAction {
    type: "ACTION_DEPARTMENT_CLEAR";
    propertiesToClear?: string[];
}

export interface IDepartmentClearActionCreator {
    (propertiesToClear?: string[]): IDepartmentClearAction;
}

export interface IDepartmentFilterActionCreator {
    (formFilter: IFormFilter): IDepartmentFilterAction;
}

export interface IDepartmentFilterAction {
    type: "ACTION_DEPARTMENT_FILTER";
    formFilter: IFormFilter;
}

export type DepartmentAction =
    IDepartmentLoadAction |
    IDepartmentLoadByIdAction |
    IDepartmentLoadFailureAction |
    IDepartmentLoadSuccessAction |
    IDepartmentSaveAction |
    IDepartmentSaveSuccessAction |
    IDepartmentSaveFailureAction |
    IDepartmentCreateAction |
    IDepartmentCreateSuccessAction |
    IDepartmentCreateFailureAction |
    IDepartmentClearAction | 
    IDepartmentFilterAction;
