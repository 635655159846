import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadTrialInstitutionItemById: ITrialInstitutionItemLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEM_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ITrialInstitutionItemLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEM_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrialInstitutionItemLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEM_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTrialInstitutionItem: ITrialInstitutionItemSaveActionCreator = (formData) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEM_SAVE,
            formData
        }
    },
    SaveSuccess: ITrialInstitutionItemSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEM_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrialInstitutionItemSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEM_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTrialInstitutionItem: ITrialInstitutionItemCreateActionCreator = (trialInstitutionId, trialItemId) => {

        return {
            type: ACTION_TRIALINSTITUTIONITEM_CREATE,
            trialInstitutionId,
            trialItemId
        }
    },
    CreateSuccess: ITrialInstitutionItemCreateSuccessActionCreator = (formData, formState, formProperties, lookups) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEM_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups
        }
    },
    CreateFailure: ITrialInstitutionItemCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEM_CREATE_FAILURE,
            responseStatus
        }
    },

    Clear: ITrialInstitutionItemClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEM_CLEAR,
            propertiesToClear
        }
    },
    Filter: ITrialInstitutionItemFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRIALINSTITUTIONITEM_FILTER,
            formFilter
        }
    };



export const
    ACTION_TRIALINSTITUTIONITEM_LOAD_BY_ID = "ACTION_TRIALINSTITUTIONITEM_LOAD_BY_ID",
    ACTION_TRIALINSTITUTIONITEM_LOAD_SUCCESS = "ACTION_TRIALINSTITUTIONITEM_LOAD_SUCCESS",
    ACTION_TRIALINSTITUTIONITEM_LOAD_FAILURE = "ACTION_TRIALINSTITUTIONITEM_LOAD_FAILURE",

    ACTION_TRIALINSTITUTIONITEM_SAVE = "ACTION_TRIALINSTITUTIONITEM_SAVE",
    ACTION_TRIALINSTITUTIONITEM_SAVE_SUCCESS = "ACTION_TRIALINSTITUTIONITEM_SAVE_SUCCESS",
    ACTION_TRIALINSTITUTIONITEM_SAVE_FAILURE = "ACTION_TRIALINSTITUTIONITEM_SAVE_FAILURE",

    ACTION_TRIALINSTITUTIONITEM_CREATE = "ACTION_TRIALINSTITUTIONITEM_CREATE",
    ACTION_TRIALINSTITUTIONITEM_CREATE_SUCCESS = "ACTION_TRIALINSTITUTIONITEM_CREATE_SUCCESS",
    ACTION_TRIALINSTITUTIONITEM_CREATE_FAILURE = "ACTION_TRIALINSTITUTIONITEM_CREATE_FAILURE",

    ACTION_TRIALINSTITUTIONITEM_FILTER = "ACTION_TRIALINSTITUTIONITEM_FILTER",

    ACTION_TRIALINSTITUTIONITEM_CLEAR = "ACTION_TRIALINSTITUTIONITEM_CLEAR";


export interface ITrialInstitutionItemLoadSuccessAction {
    type: "ACTION_TRIALINSTITUTIONITEM_LOAD_SUCCESS";
    formData: Dtos.TrialInstitutionItem;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialInstitutionItemLoadSuccessActionCreator {
    (
        formData: Dtos.TrialInstitutionItem,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialInstitutionItemLoadSuccessAction;
}

export interface ITrialInstitutionItemLoadFailureAction {
    type: "ACTION_TRIALINSTITUTIONITEM_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialInstitutionItemLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialInstitutionItemLoadFailureAction;
}

export interface ITrialInstitutionItemLoadByIdAction {
    type: "ACTION_TRIALINSTITUTIONITEM_LOAD_BY_ID";
    id: number;
}

export interface ITrialInstitutionItemLoadByIdActionCreator {
    (id: number): ITrialInstitutionItemLoadByIdAction;
}


export interface ITrialInstitutionItemSaveAction {
    type: "ACTION_TRIALINSTITUTIONITEM_SAVE";
    formData: Dtos.TrialInstitutionItem;

}

export interface ITrialInstitutionItemSaveActionCreator {
    (
        formData: Dtos.TrialInstitutionItem,
    ): ITrialInstitutionItemSaveAction;
}

export interface ITrialInstitutionItemSaveSuccessAction {
    type: "ACTION_TRIALINSTITUTIONITEM_SAVE_SUCCESS";
    formData: Dtos.TrialInstitutionItem;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialInstitutionItemSaveSuccessActionCreator {
    (
        formData: Dtos.TrialInstitutionItem,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrialInstitutionItemSaveSuccessAction;
}

export interface ITrialInstitutionItemSaveFailureAction {
    type: "ACTION_TRIALINSTITUTIONITEM_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialInstitutionItemSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialInstitutionItemSaveFailureAction;
}

export interface ITrialInstitutionItemCreateAction {
    type: "ACTION_TRIALINSTITUTIONITEM_CREATE",
    trialInstitutionId: number,
    trialItemId: number
}

export interface ITrialInstitutionItemCreateActionCreator {
    (trialInstitutionId: number, trialItemId: number): ITrialInstitutionItemCreateAction
}

export interface ITrialInstitutionItemCreateSuccessAction {
    type: "ACTION_TRIALINSTITUTIONITEM_CREATE_SUCCESS";
    formData: Dtos.TrialInstitutionItem;
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[]
}

export interface ITrialInstitutionItemCreateSuccessActionCreator {
    (
        formData: Dtos.TrialInstitutionItem,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[]
    ): ITrialInstitutionItemCreateSuccessAction;
}

export interface ITrialInstitutionItemCreateFailureAction {
    type: "ACTION_TRIALINSTITUTIONITEM_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialInstitutionItemCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialInstitutionItemCreateFailureAction;
}

export interface ITrialInstitutionItemClearAction {
    type: "ACTION_TRIALINSTITUTIONITEM_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrialInstitutionItemClearActionCreator {
    (propertiesToClear?: string[]): ITrialInstitutionItemClearAction;
}

export interface ITrialInstitutionItemFilterActionCreator {
    (formFilter: IFormFilter): ITrialInstitutionItemFilterAction;
}

export interface ITrialInstitutionItemFilterAction {
    type: "ACTION_TRIALINSTITUTIONITEM_FILTER";
    formFilter: IFormFilter;
}

export type TrialInstitutionItemAction =
    ITrialInstitutionItemLoadByIdAction |
    ITrialInstitutionItemLoadFailureAction |
    ITrialInstitutionItemLoadSuccessAction |
    ITrialInstitutionItemSaveAction |
    ITrialInstitutionItemSaveSuccessAction |
    ITrialInstitutionItemSaveFailureAction |
    ITrialInstitutionItemCreateAction |
    ITrialInstitutionItemCreateSuccessAction |
    ITrialInstitutionItemCreateFailureAction |
    ITrialInstitutionItemClearAction |
    ITrialInstitutionItemFilterAction;
