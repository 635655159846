import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_TRIALITEMINSTANCE_LOAD_BY_ID,
    ACTION_TRIALITEMINSTANCE_LOAD_CURRENT_USER,
    ACTION_TRIALITEMINSTANCE_SAVE,

    ACTION_TRIALITEMINSTANCE_CREATE,

    ITrialItemInstanceLoadByIdAction,
    ITrialItemInstanceLoadCurrentUserAction,
    ITrialItemInstanceLoadFailureAction,
    ITrialItemInstanceLoadSuccessAction,

    ITrialItemInstanceSaveAction,
    ITrialItemInstanceSaveSuccessAction,
    ITrialItemInstanceSaveFailureAction,

    ITrialItemInstanceCreateAction,
    ITrialItemInstanceCreateSuccessAction,
    ITrialItemInstanceCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,
} from "../actions/trialItemInstance";
import { trialItemInstancesApi } from "../services/trialItemInstances";


export const
    getTrialItemInstancesByIdEpic = (action$): Observable<ITrialItemInstanceLoadSuccessAction | ITrialItemInstanceLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALITEMINSTANCE_LOAD_BY_ID)
            .mergeMap((action: ITrialItemInstanceLoadByIdAction) => {
                return trialItemInstancesApi
                    .getTrialItemInstanceById(action.id)
                    .map(response =>
                        LoadSuccess(response.trialItemInstance, response.formState, response.formProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTrialItemInstanceCurrentUserEpic = (action$): Observable<ITrialItemInstanceLoadSuccessAction | ITrialItemInstanceLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALITEMINSTANCE_LOAD_CURRENT_USER)
            .mergeMap((action: ITrialItemInstanceLoadCurrentUserAction) => {
                return trialItemInstancesApi
                    .getTrialItemInstancesCurrentUser()
                    .map(response =>
                        LoadSuccess(response.trialItemInstances, undefined, undefined, [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setTrialItemInstanceEpic = (action$): Observable<ITrialItemInstanceSaveSuccessAction | ITrialItemInstanceSaveFailureAction> => {
        return action$
            .ofType(ACTION_TRIALITEMINSTANCE_SAVE)
            .mergeMap((action: ITrialItemInstanceSaveAction) => {
                return trialItemInstancesApi
                    .setTrialItemInstance(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.trialItemInstance, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createTrialItemInstanceEpic = (action$): Observable<ITrialItemInstanceCreateSuccessAction | ITrialItemInstanceCreateFailureAction> => {
        return action$
            .ofType(ACTION_TRIALITEMINSTANCE_CREATE)
            .mergeMap((action: ITrialItemInstanceCreateAction) => {
                return trialItemInstancesApi
                    .createTrialItemInstance(action.trialId, action.templateTrialItemId, action.instanceNumber)
                    .map(response =>
                        CreateSuccess(response.trialItemInstance, response.formState, response.formProperties, response.lookups, response.templateTrialItems)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



