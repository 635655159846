import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trialItemInstancesApi = {
    getTrialItemInstanceById: (id: number): Observable<Dtos.GetTrialItemInstanceByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrialItemInstanceById = new Dtos.GetTrialItemInstanceById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTrialItemInstanceByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrialItemInstance: (trialItemInstance: Dtos.TrialItemInstance): Observable<Dtos.SetTrialItemInstanceResponse> => {

        let request: Dtos.SetTrialItemInstance = new Dtos.SetTrialItemInstance();

        request.trialItemInstance = trialItemInstance;

        // send request
        const response: Promise<Dtos.SetTrialItemInstanceResponse> = client.post(request);

        return Observable.from(response);

    },
    createTrialItemInstance: (trialId: number, templateTrialItemId: number, instanceNumber: number): Observable<Dtos.CreateTrialItemInstanceResponse> => {
        //Create the request.
        let request: Dtos.CreateTrialItemInstance = new Dtos.CreateTrialItemInstance();

        request.trialId = trialId;
        request.templateTrialItemId = templateTrialItemId;
        request.instanceNumber = instanceNumber;

        //Send request.
        const response: Promise<Dtos.CreateTrialItemInstanceResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrialItemInstancesByCategory: (trialId: number): Observable<Dtos.GetTrialItemInstancesByTrialIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrialItemInstancesByTrialId = new Dtos.GetTrialItemInstancesByTrialId();

        request.trialId = trialId;

        //Send request.
        const response: Promise<Dtos.GetTrialItemInstancesByTrialIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrialItemInstancesCurrentUser: (): Observable<Dtos.GetTrialItemInstancesByCurrentUserResponse> => {
        //Create the request.
        let request: Dtos.GetTrialItemInstancesByCurrentUser = new Dtos.GetTrialItemInstancesByCurrentUser();

        //Send request.
        const response: Promise<Dtos.GetTrialItemInstancesByCurrentUserResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
}