import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trialcoordcentresApi = {
    getTrialCoordCentres: (): Observable<Dtos.GetTrialCoordCentresResponse> => {
        //Create the request.
        let request: Dtos.GetTrialCoordCentres = new Dtos.GetTrialCoordCentres();

        //Send request.
        const response: Promise<Dtos.GetTrialCoordCentresResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrialCoordCentreById: (id: number): Observable<Dtos.GetTrialCoordCentreByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrialCoordCentreById = new Dtos.GetTrialCoordCentreById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTrialCoordCentreByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrialCoordCentre: (trialCoordCentre: Dtos.TrialCoordCentre): Observable<Dtos.SetTrialCoordCentreResponse> => {

        let request: Dtos.SetTrialCoordCentre = new Dtos.SetTrialCoordCentre();

        request.trialCoordCentre = trialCoordCentre;

        // send request
        const response: Promise<Dtos.SetTrialCoordCentreResponse> = client.post(request);

        return Observable.from(response);

    },
    createTrialCoordCentre: (): Observable<Dtos.CreateTrialCoordCentreResponse> => {
        //Create the request.
        let request: Dtos.CreateTrialCoordCentre = new Dtos.CreateTrialCoordCentre();

        //Send request.
        const response: Promise<Dtos.CreateTrialCoordCentreResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}