import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_PROFESSIONALDISCIPLINES_LOAD,
    ACTION_PROFESSIONALDISCIPLINES_LOAD_BY_ID,

    ACTION_PROFESSIONALDISCIPLINES_SAVE,

    ACTION_PROFESSIONALDISCIPLINES_CREATE,

    IProfessionalDisciplineLoadAction,
    IProfessionalDisciplineLoadByIdAction,
    IProfessionalDisciplineLoadFailureAction,
    IProfessionalDisciplineLoadSuccessAction,

    IProfessionalDisciplineSaveAction,
    IProfessionalDisciplineSaveSuccessAction,
    IProfessionalDisciplineSaveFailureAction,

    IProfessionalDisciplineCreateAction,
    IProfessionalDisciplineCreateSuccessAction,
    IProfessionalDisciplineCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/professionalDisciplines";
import { professionaldisciplinesApi } from "../services/professionalDiscipline";


export const
    getProfessionalDisciplinesEpic = (action$): Observable<IProfessionalDisciplineLoadSuccessAction | IProfessionalDisciplineLoadFailureAction> => {
        return action$
            .ofType(ACTION_PROFESSIONALDISCIPLINES_LOAD)
            .mergeMap((action: IProfessionalDisciplineLoadAction) => {
                return professionaldisciplinesApi
                    .getProfessionalDisciplines()
                    .map(response =>
                        LoadSuccess(response.professionalDisciplines, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getProfessionalDisciplineByIdEpic = (action$): Observable<IProfessionalDisciplineLoadSuccessAction | IProfessionalDisciplineLoadFailureAction> => {
        return action$
            .ofType(ACTION_PROFESSIONALDISCIPLINES_LOAD_BY_ID)
            .mergeMap((action: IProfessionalDisciplineLoadByIdAction) => {
                return professionaldisciplinesApi
                    .getProfessionalDisciplineById(action.id)
                    .map(response =>
                        LoadSuccess(response.professionalDiscipline, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setProfessionalDisciplineEpic = (action$): Observable<IProfessionalDisciplineSaveSuccessAction | IProfessionalDisciplineSaveFailureAction> => {
        return action$
            .ofType(ACTION_PROFESSIONALDISCIPLINES_SAVE)
            .mergeMap((action: IProfessionalDisciplineSaveAction) => {
                return professionaldisciplinesApi
                    .setProfessionalDiscipline(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.professionalDiscipline, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createProfessionalDisciplineEpic = (action$): Observable<IProfessionalDisciplineCreateSuccessAction | IProfessionalDisciplineCreateFailureAction> => {
        return action$
            .ofType(ACTION_PROFESSIONALDISCIPLINES_CREATE)
            .mergeMap((action: IProfessionalDisciplineCreateAction) => {
                return professionaldisciplinesApi
                    .createProfessionalDiscipline()
                    .map(response =>
                        CreateSuccess(
                            response.professionalDiscipline,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



