import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_PERSONNELROLE_LOAD_BY_ID,
    ACTION_PERSONNELROLE_CREATE,
    ACTION_PERSONNELROLE_SAVE,
    ACTION_PERSONNELROLE_RESENDEMAIL,

    IPersonnelRoleLoadByIdAction,
    IPersonnelRoleLoadFailureAction,
    IPersonnelRoleLoadSuccessAction,

    IPersonnelRoleCreateAction,
    IPersonnelRoleCreateFailureAction,
    IPersonnelRoleCreateSuccessAction,

    IPersonnelRoleSaveAction,
    IPersonnelRoleSaveSuccessAction,
    IPersonnelRoleSaveFailureAction,

    IPersonnelRoleResendEmailAction,
    IPersonnelRoleResendEmailSuccessAction,
    IPersonnelRoleResendEmailFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

    ResendEmailSuccess,
    ResendEmailFailure,

} from "../actions/personnelRole";
import { personnelRolesApi } from "../services/personnelRoles";


export const
    getPersonnelRolesByIdEpic = (action$): Observable<IPersonnelRoleLoadSuccessAction | IPersonnelRoleLoadFailureAction> => {
        return action$
            .ofType(ACTION_PERSONNELROLE_LOAD_BY_ID)
            .mergeMap((action: IPersonnelRoleLoadByIdAction) => {
                return personnelRolesApi
                    .getPersonnelRoleById(action.id)
                    .map(response =>
                        LoadSuccess(response.personnelRole, response.formState, response.formProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    createPersonnelRoleEpic = (action$): Observable<IPersonnelRoleCreateSuccessAction | IPersonnelRoleCreateFailureAction> => {
        return action$
            .ofType(ACTION_PERSONNELROLE_CREATE)
            .mergeMap((action: IPersonnelRoleCreateAction) => {
                return personnelRolesApi
                    .createPersonnelRole(action.personnelId, action.trialId, action.institutionId, action.groupId, action.departmentId)
                    .map(response =>
                        CreateSuccess(response.personnelRole, response.formState, response.formProperties, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    },
    setPersonnelRoleEpic = (action$): Observable<IPersonnelRoleSaveSuccessAction | IPersonnelRoleSaveFailureAction> => {
        return action$
            .ofType(ACTION_PERSONNELROLE_SAVE)
            .mergeMap((action: IPersonnelRoleSaveAction) => {
                return personnelRolesApi
                    .setPersonnelRole(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.personnelRole, response.formState, response.lookups, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    postPersonnelRoleResendEmailEpic = (action$): Observable<IPersonnelRoleResendEmailSuccessAction | IPersonnelRoleResendEmailFailureAction> => {
        return action$
            .ofType(ACTION_PERSONNELROLE_RESENDEMAIL)
            .mergeMap((action: IPersonnelRoleResendEmailAction) => {
                return personnelRolesApi
                    .resendEmail(action.id)
                    .map(response => {
                        return ResendEmailSuccess(response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(ResendEmailFailure(error.responseStatus))
                    )
            });
    }



