import './FormFilterInput.scss';
import * as React from "react";
import { IFormFilter } from '../../../../reducers/index';

export interface IFormFilterInputProps {
    formFilter: IFormFilter;
    hideActiveToggle?: boolean;
    activeLabel?: string;
    onFilter?: (formFilter: IFormFilter) => void;
}

class FormFilterInput extends React.PureComponent<IFormFilterInputProps, any> {

    renderComponentContainer() {

    }

    render() {
        const {
            formFilter,
            onFilter,
            hideActiveToggle,
            activeLabel
        } = this.props;

        const showLabel = activeLabel && activeLabel != "" ? "Show " + activeLabel : "Show Inactive"
        const hideLabel = activeLabel && activeLabel != "" ? "Hide " + activeLabel : "Hide Inactive"

        return <>
            <input type="text" placeholder="Filter..." className="form-control mr-2" style={{ display: "inline-block", width: "150px", paddingBottom: "0.7rem" }} name="filter" id="filter.search"
                value={formFilter.search}
                onChange={e => {
                    let filter = { ...formFilter };
                    filter.search = e.target.value;
                    onFilter(filter);
                }} />
            {!hideActiveToggle && (
                <button
                    onClick={
                        () => {
                            let filter = { ...formFilter };
                            filter.showInActive = !filter.showInActive;
                            onFilter(filter);
                        }
                    }
                    type="button"
                    className="btn btn-outline-dark mr-2"
                >
                    {formFilter.showInActive ? hideLabel : showLabel}
                </button>
            )}

        </>;
    }
}

export default FormFilterInput;