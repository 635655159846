import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const countriesApi = {
    getCountries: (): Observable<Dtos.GetCountriesResponse> => {
        //Create the request.
        let request: Dtos.GetCountries = new Dtos.GetCountries();

        //Send request.
        const response: Promise<Dtos.GetCountriesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getCountryById: (id: number): Observable<Dtos.GetCountryByIdResponse> => {
        //Create the request.
        let request: Dtos.GetCountryById = new Dtos.GetCountryById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetCountryByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setCountry: (country: Dtos.Country): Observable<Dtos.SetCountryResponse> => {

        let request: Dtos.SetCountry = new Dtos.SetCountry();

        request.country = country;

        // send request
        const response: Promise<Dtos.SetCountryResponse> = client.post(request);

        return Observable.from(response);

    },
    createCountry: (): Observable<Dtos.CreateCountryResponse> => {
        //Create the request.
        let request: Dtos.CreateCountry = new Dtos.CreateCountry();

        //Send request.
        const response: Promise<Dtos.CreateCountryResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}