import * as Dtos from "../dtos/Tmd.dtos";

export const
    LoadWebsiteSettings: IWebsiteSettingsLoadActionCreator = () => {
        return {
            type: ACTION_WEBSITE_SETTINGS_LOAD
        }
    },
    LoadSuccess: IWebsiteSettingsLoadSuccessActionCreator = (websiteSetting) => {
        return {
            type: ACTION_WEBSITE_SETTINGS_LOAD_SUCCESS,
            websiteSetting
        }
    },
    LoadFailure: IWebsiteSettingsLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_WEBSITE_SETTINGS_LOAD_FAILURE,
            responseStatus
        }
    },
    Clear: IWebsiteSettingsClearActionCreator = () => {
        return {
            type: ACTION_WEBSITE_SETTINGS_CLEAR
        }
    },
    SetWebsiteSettings: IWebsiteSettingsSetActionCreator = (websiteSetting) => {
        return {
            type: ACTION_WEBSITE_SETTINGS_SET,
            websiteSetting
        }
    },
    SetWebsiteSettingsSuccess: IWebsiteSettingsSetSuccessActionCreator = (websiteSetting) => {
        return {
            type: ACTION_WEBSITE_SETTINGS_SET_SUCCESS,
            websiteSetting
        }
    },
    SetWebsiteSettingsFailure: IWebsiteSettingsSetFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_WEBSITE_SETTINGS_SET_FAILURE,
            responseStatus
        }
    };
    
    

export const
    ACTION_WEBSITE_SETTINGS_LOAD = "ACTION_WEBSITE_SETTINGS_LOAD",
    ACTION_WEBSITE_SETTINGS_LOAD_SUCCESS = "ACTION_WEBSITE_SETTINGS_LOAD_SUCCESS",
    ACTION_WEBSITE_SETTINGS_LOAD_FAILURE = "ACTION_WEBSITE_SETTINGS_LOAD_FAILURE",
    ACTION_WEBSITE_SETTINGS_CLEAR = "ACTION_WEBSITE_SETTINGS_CLEAR",

    ACTION_WEBSITE_SETTINGS_SET = "ACTION_WEBSITE_SETTINGS_SET",
    ACTION_WEBSITE_SETTINGS_SET_SUCCESS = "ACTION_WEBSITE_SETTINGS_SET_SUCCESS",
    ACTION_WEBSITE_SETTINGS_SET_FAILURE = "ACTION_WEBSITE_SETTINGS_SET_FAILURE";

export interface IWebsiteSettingsLoadAction {
    type: "ACTION_WEBSITE_SETTINGS_LOAD";
}

export interface IWebsiteSettingsLoadActionCreator {
    (): IWebsiteSettingsLoadAction;
}

export interface IWebsiteSettingsLoadSuccessAction {
    type: "ACTION_WEBSITE_SETTINGS_LOAD_SUCCESS";
    websiteSetting: Dtos.WebsiteSetting;
}

export interface IWebsiteSettingsLoadSuccessActionCreator {
    (websiteSetting: Dtos.WebsiteSetting): IWebsiteSettingsLoadSuccessAction;
}

export interface IWebsiteSettingsLoadFailureAction {
    type: "ACTION_WEBSITE_SETTINGS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IWebsiteSettingsLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IWebsiteSettingsLoadFailureAction;
}

export interface IWebsiteSettingsClearAction {
    type: "ACTION_WEBSITE_SETTINGS_CLEAR";
}

export interface IWebsiteSettingsClearActionCreator {
    (): IWebsiteSettingsClearAction;
}

export interface IWebsiteSettingsSetAction {
    type: "ACTION_WEBSITE_SETTINGS_SET",
    websiteSetting: Dtos.WebsiteSetting
}

export interface IWebsiteSettingsSetActionCreator {
    (websiteSetting: Dtos.WebsiteSetting): IWebsiteSettingsSetAction;
}

export interface IWebsiteSettingsSetSuccessAction {
    type: "ACTION_WEBSITE_SETTINGS_SET_SUCCESS";
    websiteSetting: Dtos.WebsiteSetting;
}

export interface IWebsiteSettingsSetSuccessActionCreator {
    (websiteSetting: Dtos.WebsiteSetting): IWebsiteSettingsSetSuccessAction;
}

export interface IWebsiteSettingsSetFailureAction {
    type: "ACTION_WEBSITE_SETTINGS_SET_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IWebsiteSettingsSetFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IWebsiteSettingsSetFailureAction;
}

export type WebsiteSettingsAction = 
    IWebsiteSettingsLoadAction |
    IWebsiteSettingsLoadFailureAction |
    IWebsiteSettingsLoadSuccessAction |
    IWebsiteSettingsClearAction | 
    IWebsiteSettingsSetAction | 
    IWebsiteSettingsSetSuccessAction | 
    IWebsiteSettingsSetFailureAction;
