import { Reducer } from 'redux';
import { update } from '../../helpers/immutabilityHelper'
import * as Dtos from '../../dtos/Tmd.dtos';

import {
    ACTION_TRIALINSTITUTIONITEMS_PAGE_SET_EDIT_MODAL_VIEW_STATE,
    ACTION_TRIALINSTITUTIONITEMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE,
    TrialInstitutionItemsPageAction
} from '../../actions/pages/trialInstitutionItemsPage';


export interface ITrailInstitutionItemsPageState {
    editModalOpen: boolean;
    collectionInstanceId: number;
    name: string;
    description: string;
    createVersionModalOpen: boolean;
    trialInstitutionId: number;
    trialItemId: number;
}

const initialState: ITrailInstitutionItemsPageState = {
    editModalOpen: false,
    collectionInstanceId: null,
    name: "",
    description: "",
    createVersionModalOpen: false,
    trialInstitutionId: null,
    trialItemId: null
};


function setEditModalViewState(state: ITrailInstitutionItemsPageState, open: boolean, collectionInstanceId: number, name: string, description: string): ITrailInstitutionItemsPageState {
    const newState: ITrailInstitutionItemsPageState = update(state, {
        editModalOpen: {
            $set: open
        },
        collectionInstanceId: {
            $set: collectionInstanceId
        },
        name: {
            $set: name
        },
        description: {
            $set: description
        }
    });
    return newState;
}

function setCreateVersionModalViewState(state: ITrailInstitutionItemsPageState, open: boolean, trialInstitutionId: number, trialItemId: number): ITrailInstitutionItemsPageState {
    const newState: ITrailInstitutionItemsPageState = update(state, {
        createVersionModalOpen: {
            $set: open
        },
        trialInstitutionId: {
            $set: trialInstitutionId
        },
        trialItemId: {
            $set: trialItemId
        }
    });
    return newState;
}

const TrailInstitutionItemsPageReducer: Reducer<ITrailInstitutionItemsPageState> = (state: ITrailInstitutionItemsPageState = initialState, action) => { 
    switch (action.type) {
        case ACTION_TRIALINSTITUTIONITEMS_PAGE_SET_EDIT_MODAL_VIEW_STATE:
            return setEditModalViewState(state, action.open, action.collectionInstanceId, action.name, action.description);
        case ACTION_TRIALINSTITUTIONITEMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE:
            return setCreateVersionModalViewState(state, action.open, action.trialInstitutionId, action.trialItemId);
    }

    return state;
}

export default TrailInstitutionItemsPageReducer;