import './InlineLoading.scss';

import * as React from 'react';
import * as classNames from "classnames";

interface IInlineLoadingProps {
    size?: number;
    text?: string;
    textAnchor?: "Left" | "Right" | "Top" | "Bottom";
    className?: string;
    absoluteCenter?: boolean;
}

class InlineLoading extends React.Component<IInlineLoadingProps, any> {

    render() {

        const {
            size,
            className,
            text,
            textAnchor,
            absoluteCenter
        } = this.props;

        const actualSize = size ? size : 30;
        const baseHeight = actualSize / 2.2;
        const baseWidth = actualSize / 7.0;

        return <div className={classNames("color-bar-loading", className, { "absolute-center": absoluteCenter })} style={{ height: actualSize, width: actualSize }}>
            <div className="loading" style={{ height: baseHeight, width: actualSize }}>
                <div className="loading-bar" style={{ height: baseHeight, width: baseWidth }}></div>
                <div className="loading-bar" style={{ height: baseHeight, width: baseWidth }}></div>
                <div className="loading-bar" style={{ height: baseHeight, width: baseWidth }}></div>
                <div className="loading-bar" style={{ height: baseHeight, width: baseWidth }}> </div>
            </div>
        </div>;
    }
}
export default InlineLoading;