import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialItemComponentRuleState: Dtos.ItemComponentRule = {
    id: null,
    itemComponentId: null,
    itemPrimitiveRule: null,
    itemComponentPrimitiveId: null,
    compareItemComponentPrimitiveId: null,
    compareValue: null,
    active: true,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    compareItemComponentPrimitiveColumn: null,
    compareItemComponentPrimitiveIndexInColumn: null
}

export const initialItemComponentPrimitiveOptionState: Dtos.ItemComponentPrimitiveOption = {
    id: null,
    itemComponentPrimitiveId: null,
    value: null,
    rank: null,
    active: true,
    measurement: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null
}

export const initialItemComponentPrimitiveState: Dtos.ItemComponentPrimitive = {
    id: null,
    itemComponentId: null,
    itemComponent: null,
    itemPrimitive: null,
    label: null,
    active: true,
    rank: null,
    isQuestion: null,
    
    measure: false,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    itemComponentPrimitiveOptions: [],
    itemComponentRules: []
}

export const initialItemComponentState: Dtos.ItemComponent & IRegistrationFormState = {
    id: null,
    name: null,
    active: true,
    rank: 99,
    isSystem: false,
    customInputControlName: '',
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    itemComponentPrimitives: [],
    itemComponentRules: [],
    saveAndReturn: false,
    description: null,
    itemComponentType: null,
    itemComponentTypeId: null,
    sourceDocuments: [],
}

export interface IItemComponentReduxFormState {
    id: IFieldState<number>,
    name: IFieldState<string>,
    customInputControlName: IFieldState<string>,
    isSystem: IFieldState<boolean>,
    active: IFieldState<boolean>,
    rank: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    itemComponentPrimitives: IFieldState<Dtos.ItemComponentPrimitive[]>,
    itemComponentRules: IFieldState<Dtos.ItemComponentRule[]>,
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>,
    description: IFieldState<string>,
    itemComponentTypeId: IFieldState<number>,
    itemComponentType: IFieldState<Dtos.ItemComponentType>,
    sourceDocuments: IFieldState<Dtos.SourceDocument[]>,
}
