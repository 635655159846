import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadInstitutionType: IInstitutionTypeLoadActionCreator = () => {
        return {
            type: ACTION_INSTITUTIONTYPES_LOAD
        }
    },
    LoadInstitutionTypeById: IInstitutionTypeLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_INSTITUTIONTYPES_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IInstitutionTypeLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_INSTITUTIONTYPES_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IInstitutionTypeLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_INSTITUTIONTYPES_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveInstitutionType: IInstitutionTypeSaveActionCreator = (formData) => {
        return {
            type: ACTION_INSTITUTIONTYPES_SAVE,
            formData
        }
    },
    SaveSuccess: IInstitutionTypeSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_INSTITUTIONTYPES_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IInstitutionTypeSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_INSTITUTIONTYPES_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateInstitutionType: IInstitutionTypeCreateActionCreator = () => {

        return {
            type: ACTION_INSTITUTIONTYPES_CREATE
        }
    },
    CreateSuccess: IInstitutionTypeCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_INSTITUTIONTYPES_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: IInstitutionTypeCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_INSTITUTIONTYPES_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IInstitutionTypeClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_INSTITUTIONTYPES_CLEAR,
            propertiesToClear
        }
    },
    Filter: IInstitutionTypeFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_INSTITUTIONTYPES_FILTER,
            formFilter
        }
    };



export const
    ACTION_INSTITUTIONTYPES_LOAD = "ACTION_INSTITUTIONTYPES_LOAD",
    ACTION_INSTITUTIONTYPES_LOAD_BY_ID = "ACTION_INSTITUTIONTYPES_LOAD_BY_ID",
    ACTION_INSTITUTIONTYPES_LOAD_SUCCESS = "ACTION_INSTITUTIONTYPES_LOAD_SUCCESS",
    ACTION_INSTITUTIONTYPES_LOAD_FAILURE = "ACTION_INSTITUTIONTYPES_LOAD_FAILURE",

    ACTION_INSTITUTIONTYPES_SAVE = "ACTION_INSTITUTIONTYPES_SAVE",
    ACTION_INSTITUTIONTYPES_SAVE_SUCCESS = "ACTION_INSTITUTIONTYPES_SAVE_SUCCESS",
    ACTION_INSTITUTIONTYPES_SAVE_FAILURE = "ACTION_INSTITUTIONTYPES_SAVE_FAILURE",

    ACTION_INSTITUTIONTYPES_CREATE = "ACTION_INSTITUTIONTYPES_CREATE",
    ACTION_INSTITUTIONTYPES_CREATE_SUCCESS = "ACTION_INSTITUTIONTYPES_CREATE_SUCCESS",
    ACTION_INSTITUTIONTYPES_CREATE_FAILURE = "ACTION_INSTITUTIONTYPES_CREATE_FAILURE",

    ACTION_INSTITUTIONTYPES_CLEAR = "ACTION_INSTITUTIONTYPES_CLEAR",

    ACTION_INSTITUTIONTYPES_FILTER = "ACTION_INSTITUTIONTYPES_FILTER";

export interface IInstitutionTypeLoadAction {
    type: "ACTION_INSTITUTIONTYPES_LOAD";
}

export interface IInstitutionTypeLoadActionCreator {
    (): IInstitutionTypeLoadAction;
}

export interface IInstitutionTypeLoadSuccessAction {
    type: "ACTION_INSTITUTIONTYPES_LOAD_SUCCESS";
    formData: Dtos.InstitutionType | Dtos.InstitutionType[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IInstitutionTypeLoadSuccessActionCreator {
    (
        formData: Dtos.InstitutionType | Dtos.InstitutionType[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IInstitutionTypeLoadSuccessAction;
}

export interface IInstitutionTypeLoadFailureAction {
    type: "ACTION_INSTITUTIONTYPES_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IInstitutionTypeLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IInstitutionTypeLoadFailureAction;
}

export interface IInstitutionTypeLoadByIdAction {
    type: "ACTION_INSTITUTIONTYPES_LOAD_BY_ID";
    id: number;
}

export interface IInstitutionTypeLoadByIdActionCreator {
    (id: number): IInstitutionTypeLoadByIdAction;
}


export interface IInstitutionTypeSaveAction {
    type: "ACTION_INSTITUTIONTYPES_SAVE";
    formData: Dtos.InstitutionType;

}

export interface IInstitutionTypeSaveActionCreator {
    (
        formData: Dtos.InstitutionType
    ): IInstitutionTypeSaveAction;
}

export interface IInstitutionTypeSaveSuccessAction {
    type: "ACTION_INSTITUTIONTYPES_SAVE_SUCCESS";
    formData: Dtos.InstitutionType;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IInstitutionTypeSaveSuccessActionCreator {
    (
        formData: Dtos.InstitutionType,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IInstitutionTypeSaveSuccessAction;
}

export interface IInstitutionTypeSaveFailureAction {
    type: "ACTION_INSTITUTIONTYPES_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IInstitutionTypeSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IInstitutionTypeSaveFailureAction;
}

export interface IInstitutionTypeCreateAction {
    type: "ACTION_INSTITUTIONTYPES_CREATE"
}

export interface IInstitutionTypeCreateActionCreator {
    (): IInstitutionTypeCreateAction
}

export interface IInstitutionTypeCreateSuccessAction {
    type: "ACTION_INSTITUTIONTYPES_CREATE_SUCCESS";
    formData: Dtos.InstitutionType;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface IInstitutionTypeCreateSuccessActionCreator {
    (
        formData: Dtos.InstitutionType,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): IInstitutionTypeCreateSuccessAction;
}

export interface IInstitutionTypeCreateFailureAction {
    type: "ACTION_INSTITUTIONTYPES_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IInstitutionTypeCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IInstitutionTypeCreateFailureAction;
}

export interface IInstitutionTypeClearAction {
    type: "ACTION_INSTITUTIONTYPES_CLEAR";
    propertiesToClear?: string[];
}

export interface IInstitutionTypeClearActionCreator {
    (propertiesToClear?: string[]): IInstitutionTypeClearAction;
}

export interface IInstitutionTypeFilterActionCreator {
    (formFilter: IFormFilter): IInstitutionTypeFilterAction;
}

export interface IInstitutionTypeFilterAction {
    type: "ACTION_INSTITUTIONTYPES_FILTER";
    formFilter: IFormFilter;
}

export type InstitutionTypeAction =
    IInstitutionTypeLoadAction |
    IInstitutionTypeLoadByIdAction |
    IInstitutionTypeLoadFailureAction |
    IInstitutionTypeLoadSuccessAction |
    IInstitutionTypeSaveAction |
    IInstitutionTypeSaveSuccessAction |
    IInstitutionTypeSaveFailureAction |
    IInstitutionTypeCreateAction |
    IInstitutionTypeCreateSuccessAction |
    IInstitutionTypeCreateFailureAction |
    IInstitutionTypeClearAction |
    IInstitutionTypeFilterAction;
