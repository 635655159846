import * as React from 'react';
import './TrialAnalysisTypePage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { ITrialAnalysisTypeReduxFormState, initialTrialAnalysisTypeState } from "../../../reducers/reactReduxForms/trialAnalysisType";
import { RegistrationFormComponent } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, NumberFormInput, CheckboxFormInput } from "../../../components/form/index";
import * as TrialAnalysisTypeActions from '../../../actions/trialAnalysisTypes';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { NotificationAlert } from "../../../components/common/index";
import { Form, actions } from 'react-redux-form';
import { TrialLookupsLayout } from '../../index';
import * as AuthenticationHelper from '../../../helpers/authentication';

interface ITrialAnalysisTypePageParams {
    trialAnalysisTypeId: number;
}

interface ITrialAnalysisTypePageProps {

    form: Dtos.TrialAnalysisType & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITrialAnalysisTypeReduxFormState;

    location: Location;
    trialAnalysisTypeId: number;
    match: match<ITrialAnalysisTypePageParams>;
    history: History;

    // From state
    trialAnalysisType: Dtos.TrialAnalysisType;
    lookups: Dtos.Lookup[];

    loadingTrialAnalysisType: boolean;
    loadTrialAnalysisTypeSuccess: boolean;
    loadTrialAnalysisTypeFailure: boolean;

    savingTrialAnalysisType: boolean;
    saveTrialAnalysisTypeSuccess: boolean;
    saveTrialAnalysisTypeFailure: boolean;

    creatingTrialAnalysisType: boolean;
    createTrialAnalysisTypeSuccess: boolean;
    createTrialAnalysisTypeFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface ITrialAnalysisTypePageActions {
    clearTrialAnalysisType: TrialAnalysisTypeActions.ITrialAnalysisTypeClearActionCreator;
    loadTrialAnalysisType: TrialAnalysisTypeActions.ITrialAnalysisTypeLoadByIdActionCreator;

    saveTrialAnalysisType: TrialAnalysisTypeActions.ITrialAnalysisTypeSaveActionCreator;
    createTrialAnalysisType: TrialAnalysisTypeActions.ITrialAnalysisTypeCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type TrialAnalysisTypePageProps = ITrialAnalysisTypePageProps & ITrialAnalysisTypePageActions;

const reduxFormName: string = "reduxForms.trialAnalysisType";

class TrialAnalysisTypePage extends React.PureComponent<TrialAnalysisTypePageProps, any> {

    constructor(props: TrialAnalysisTypePageProps) {
        super(props);

        this.clearTrialAnalysisType = this.clearTrialAnalysisType.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTrialAnalysisType,
            trialAnalysisTypeId,
            createTrialAnalysisType
        } = this.props;

        if (trialAnalysisTypeId) {
            loadTrialAnalysisType(trialAnalysisTypeId);
        } else if (!trialAnalysisTypeId) {
            createTrialAnalysisType();
        }
    }

    componentWillReceiveProps(nextProps: TrialAnalysisTypePageProps) {
    }

    componentDidUpdate(prevProps: TrialAnalysisTypePageProps, prevState) {
        const {
            trialAnalysisTypeId,
            trialAnalysisType,
            form,
            navigate,
            loadTrialAnalysisType,
            loadingTrialAnalysisType,
            loadTrialAnalysisTypeSuccess,
            createTrialAnalysisType,
            creatingTrialAnalysisType,
            createTrialAnalysisTypeSuccess,
            savingTrialAnalysisType,
            saveTrialAnalysisTypeSuccess,
            validationFailures
        } = this.props;

        if (trialAnalysisTypeId && prevProps.trialAnalysisTypeId != trialAnalysisTypeId) {
            loadTrialAnalysisType(trialAnalysisTypeId);
        }
        else if (!trialAnalysisTypeId && prevProps.trialAnalysisTypeId) {
            createTrialAnalysisType();
        }

        if (!loadingTrialAnalysisType && prevProps.loadingTrialAnalysisType) {
            if (loadTrialAnalysisTypeSuccess) {
                this.loadForm();
            }
        }

        if (!savingTrialAnalysisType && prevProps.savingTrialAnalysisType) {
            if (saveTrialAnalysisTypeSuccess) {
                if (validationFailures && validationFailures.length > 0) {
                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {
                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);
                }
                if (form.saveAndReturn) {
                    navigate("/settings/trial/trialAnalysisTypes")
                } else if (!form.saveAndReturn && trialAnalysisTypeId && trialAnalysisTypeId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !trialAnalysisTypeId && trialAnalysisType && trialAnalysisType.id > 0) {
                    navigate("/settings/trial/trialAnalysisTypes/" + trialAnalysisType.id);
                }
            }
            else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

        if (!creatingTrialAnalysisType && prevProps.creatingTrialAnalysisType) {
            if (createTrialAnalysisTypeSuccess) {
                this.loadForm();
            }
        }
    }

    componentWillUpdate(nextProps: TrialAnalysisTypePageProps) {
    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearTrialAnalysisType();
        loadForm(reduxFormName, initialTrialAnalysisTypeState);
        resetForm(reduxFormName);
    }

    clearTrialAnalysisType() {
        this.props.clearTrialAnalysisType();
    }

    showTrialAnalysisType(): boolean {
        const {
            loadingTrialAnalysisType,
            loadTrialAnalysisTypeSuccess
        } = this.props

        if (!loadingTrialAnalysisType && loadTrialAnalysisTypeSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrialAnalysisType
        } = this.props;

        return <TrialLookupsLayout loading={loadingTrialAnalysisType} permission={Dtos.Permission.TrialAnalysisTypesView}>
            {this.renderContent()}
        </TrialLookupsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingTrialAnalysisType,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.TrialAnalysisTypesUpdate, Dtos.Permission.TrialAnalysisTypesCreate);
        const formDisabled: boolean = !hasPermission || savingTrialAnalysisType || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            value: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            }
        };

        return <div>
            <h1>Trial Analysis Type</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "trialAnalysisType", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.TrialAnalysisType) {
        const {
            saveTrialAnalysisType
        } = this.props;

        if (data) {
            let changes: Dtos.TrialAnalysisType = {} as Dtos.TrialAnalysisType;
            saveTrialAnalysisType(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.TrialAnalysisType) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/trial/trialAnalysisTypes");
    }

    loadForm() {
        const {
            loadForm,
            trialAnalysisType,
        } = this.props

        let extraData: Dtos.TrialAnalysisType = {} as Dtos.TrialAnalysisType;

        loadForm(reduxFormName, Object.assign({ ...trialAnalysisType }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: TrialAnalysisTypePageProps): ITrialAnalysisTypePageProps => {

    let trialAnalysisType: Dtos.TrialAnalysisType | undefined = !(state.trialAnalysisTypes.formData instanceof Array) ? state.trialAnalysisTypes.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialAnalysisTypeId: ownProps.match && ownProps.match.params.trialAnalysisTypeId && ownProps.match.params.trialAnalysisTypeId.toString() != "create" ? ownProps.match.params.trialAnalysisTypeId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trialAnalysisType: trialAnalysisType!,

        loadingTrialAnalysisType: state.trialAnalysisTypes.loadState && state.trialAnalysisTypes.loadState.status == RequestState.Pending,
        loadTrialAnalysisTypeSuccess: state.trialAnalysisTypes.loadState && state.trialAnalysisTypes.loadState.status == RequestState.Success,
        loadTrialAnalysisTypeFailure: state.trialAnalysisTypes.loadState && state.trialAnalysisTypes.loadState.status == RequestState.Failure,

        savingTrialAnalysisType: state.trialAnalysisTypes.saveState && state.trialAnalysisTypes.saveState.status == RequestState.Pending,
        saveTrialAnalysisTypeSuccess: state.trialAnalysisTypes.saveState && state.trialAnalysisTypes.saveState.status == RequestState.Success,
        saveTrialAnalysisTypeFailure: state.trialAnalysisTypes.saveState && state.trialAnalysisTypes.saveState.status == RequestState.Failure,

        creatingTrialAnalysisType: state.trialAnalysisTypes.createState && state.trialAnalysisTypes.createState.status == RequestState.Pending,
        createTrialAnalysisTypeSuccess: state.trialAnalysisTypes.createState && state.trialAnalysisTypes.createState.status == RequestState.Success,
        createTrialAnalysisTypeFailure: state.trialAnalysisTypes.createState && state.trialAnalysisTypes.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: [],

        form: state.reduxForms.trialAnalysisType,
        formState: state.trialAnalysisTypes.formState,
        formProperties: state.trialAnalysisTypes.formProperties,

        reduxFormState: state.reduxForms.formState.trialAnalysisType,
        validationFailures: trialAnalysisType ?
            state.trialAnalysisTypes.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): ITrialAnalysisTypePageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadTrialAnalysisType: bindActionCreators(TrialAnalysisTypeActions.LoadTrialAnalysisTypeById, dispatch),
        clearTrialAnalysisType: bindActionCreators(TrialAnalysisTypeActions.Clear, dispatch),

        saveTrialAnalysisType: bindActionCreators(TrialAnalysisTypeActions.SaveTrialAnalysisType, dispatch),
        createTrialAnalysisType: bindActionCreators(TrialAnalysisTypeActions.CreateTrialAnalysisType, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialAnalysisTypePage);
