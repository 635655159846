import * as Dtos from "../dtos/Tmd.dtos";

export const
    LoadGroupItemById: IGroupItemLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_GROUPITEM_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IGroupItemLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_GROUPITEM_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IGroupItemLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_GROUPITEM_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveGroupItem: IGroupItemSaveActionCreator = (formData, collectionFormData) => {
        return {
            type: ACTION_GROUPITEM_SAVE,
            formData,
            collectionFormData
        }
    },
    SaveSuccess: IGroupItemSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_GROUPITEM_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IGroupItemSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_GROUPITEM_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateGroupItem: IGroupItemCreateActionCreator = (groupId) => {

        return {
            type: ACTION_GROUPITEM_CREATE,
            groupId
        }
    },
    CreateSuccess: IGroupItemCreateSuccessActionCreator = (formData, formState, formProperties, lookups) => {
        return {
            type: ACTION_GROUPITEM_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups
        }
    },
    CreateFailure: IGroupItemCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_GROUPITEM_CREATE_FAILURE,
            responseStatus
        }
    },

    Clear: IGroupItemClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_GROUPITEM_CLEAR,
            propertiesToClear
        }
    };



export const
    ACTION_GROUPITEM_LOAD_BY_ID = "ACTION_GROUPITEM_LOAD_BY_ID",
    ACTION_GROUPITEM_LOAD_SUCCESS = "ACTION_GROUPITEM_LOAD_SUCCESS",
    ACTION_GROUPITEM_LOAD_FAILURE = "ACTION_GROUPITEM_LOAD_FAILURE",

    ACTION_GROUPITEM_SAVE = "ACTION_GROUPITEM_SAVE",
    ACTION_GROUPITEM_SAVE_SUCCESS = "ACTION_GROUPITEM_SAVE_SUCCESS",
    ACTION_GROUPITEM_SAVE_FAILURE = "ACTION_GROUPITEM_SAVE_FAILURE",

    ACTION_GROUPITEM_CREATE = "ACTION_GROUPITEM_CREATE",
    ACTION_GROUPITEM_CREATE_SUCCESS = "ACTION_GROUPITEM_CREATE_SUCCESS",
    ACTION_GROUPITEM_CREATE_FAILURE = "ACTION_GROUPITEM_CREATE_FAILURE",

    ACTION_GROUPITEM_CLEAR = "ACTION_GROUPITEM_CLEAR";


export interface IGroupItemLoadSuccessAction {
    type: "ACTION_GROUPITEM_LOAD_SUCCESS";
    formData: Dtos.GroupItem;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IGroupItemLoadSuccessActionCreator {
    (
        formData: Dtos.GroupItem,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IGroupItemLoadSuccessAction;
}

export interface IGroupItemLoadFailureAction {
    type: "ACTION_GROUPITEM_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IGroupItemLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IGroupItemLoadFailureAction;
}

export interface IGroupItemLoadByIdAction {
    type: "ACTION_GROUPITEM_LOAD_BY_ID";
    id: number;
}

export interface IGroupItemLoadByIdActionCreator {
    (id: number): IGroupItemLoadByIdAction;
}


export interface IGroupItemSaveAction {
    type: "ACTION_GROUPITEM_SAVE";
    formData: Dtos.GroupItem;
    collectionFormData: Dtos.Collection;

}

export interface IGroupItemSaveActionCreator {
    (
        formData: Dtos.GroupItem,
        collectionFormData: Dtos.Collection
    ): IGroupItemSaveAction;
}

export interface IGroupItemSaveSuccessAction {
    type: "ACTION_GROUPITEM_SAVE_SUCCESS";
    formData: Dtos.GroupItem;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IGroupItemSaveSuccessActionCreator {
    (
        formData: Dtos.GroupItem,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IGroupItemSaveSuccessAction;
}

export interface IGroupItemSaveFailureAction {
    type: "ACTION_GROUPITEM_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IGroupItemSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IGroupItemSaveFailureAction;
}

export interface IGroupItemCreateAction {
    type: "ACTION_GROUPITEM_CREATE",
    groupId: number
}

export interface IGroupItemCreateActionCreator {
    (groupId: number): IGroupItemCreateAction
}

export interface IGroupItemCreateSuccessAction {
    type: "ACTION_GROUPITEM_CREATE_SUCCESS";
    formData: Dtos.GroupItem;
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[]
}

export interface IGroupItemCreateSuccessActionCreator {
    (
        formData: Dtos.GroupItem,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[]
    ): IGroupItemCreateSuccessAction;
}

export interface IGroupItemCreateFailureAction {
    type: "ACTION_GROUPITEM_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IGroupItemCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IGroupItemCreateFailureAction;
}

export interface IGroupItemClearAction {
    type: "ACTION_GROUPITEM_CLEAR";
    propertiesToClear?: string[];
}

export interface IGroupItemClearActionCreator {
    (propertiesToClear?: string[]): IGroupItemClearAction;
}

export type GroupItemAction =
    IGroupItemLoadByIdAction |
    IGroupItemLoadFailureAction |
    IGroupItemLoadSuccessAction |
    IGroupItemSaveAction |
    IGroupItemSaveSuccessAction |
    IGroupItemSaveFailureAction |
    IGroupItemCreateAction |
    IGroupItemCreateSuccessAction |
    IGroupItemCreateFailureAction |
    IGroupItemClearAction;
