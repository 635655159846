import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const collectionsApi = {
    getCollectionById: (id: number): Observable<Dtos.GetCollectionByIdResponse> => {
        //Create the request.
        let request: Dtos.GetCollectionById = new Dtos.GetCollectionById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetCollectionByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    createCollection: (): Observable<Dtos.CreateCollectionResponse> => {
        //Create the request.
        let request: Dtos.CreateCollection = new Dtos.CreateCollection();

        //Send request.
        const response: Promise<Dtos.CreateCollectionResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    lockCollection: (id: number, lock: boolean): Observable<Dtos.LockCollectionResponse> => {
        //Create the request.
        let request: Dtos.LockCollection = new Dtos.LockCollection();

        request.id = id;
        request.lock = lock;

        // send request
        const response: Promise<Dtos.LockCollectionResponse> = client.post(request);

        return Observable.from(response);

    },
}