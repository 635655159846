import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialTrialInstitutionItemState: Dtos.TrialInstitutionItem & IRegistrationFormState = {
    id: null,
    trialInstitutionId: null,
    trialInstitution: null,
    trialItemId: null,
    trialItem: null,
    version: null,
    sortOrderId: 1,
    collectionInstanceId: null,
    collectionInstance: null,
    active: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false
}

export interface ITrialInstitutionItemReduxFormState {
    id: IFieldState<number>,
    trialInstitutionId: IFieldState<number>,
    trialInstitution: IFieldState<Dtos.TrialInstitution>,
    trialItemId: IFieldState<number>,
    trialItem: IFieldState<Dtos.TrialItem>,
    version: IFieldState<string>,
    collectionInstanceId: IFieldState<number>,
    collectionInstance: IFieldState<Dtos.CollectionInstance>,
    active: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>,
}
