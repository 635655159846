import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialTrialState: Dtos.Trial & IRegistrationFormState = {
    id: null,
    protocolID: null,
    newProposalNumber: null,
    trialDevelopmentNumber: null,
    allIdentifiers: null,
    studyAcronym: null,
    title: null,
    shortTitle: null,
    trialCategoryId: null,
    trialCategory: null,
    trialChair1PersonnelId: null,
    trialChair1Personnel: null,
    trialChair2PersonnelId: null,
    trialChair2Personnel: null,
    trialCoordCentreId: null,
    trialCoordCentre: null,
    active: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false,
    trialItems: [],
    trialGroups: [],
    trialInstitutions: [],
    trialItemCategories: [],
    personnelRoleSummaries: [],
    globalRoleSummaries: [],
    display: null,
    notes: [],
    comments: null,
    confidentiallyDeedRequired: null,
    institutionCodingId: null,
    institutionCoding: null,
    trialTypeId: null,
    trialType: null,
    trialTreatments: [],
    trialStatusId: null,
    trialStatus: null,
    dateActivated: null,
    dateFirstPatient: null,
    dateAccrualComplete: null,
    dateStudyClosed: null,
    dateCompletedWithdrawn: null,
    internationalTrialStatusId: null,
    internationalTrialStatus: null,
    internationalDateActivated: null,
    internationalDateAccrualCompleted: null,
    initialsCrfItemName: null,
    targetAccrual: null,
    totalAccrual: null,
    targetDateAccrualCompleted: null,
    targetDateFollowupCompletion: null,
    internationalTargetAccrual: null,
    internationalTotalAccrual: null,
    internationalTargetDateAccrualCompleted: null,
    internationalTargetDateFollowupCompletion: null,
    apiKey: null,
    registrationWebsite: null,
    openClinciaProtocolID: null,
    openClinciaInstitutionPrefix: null,
    patientDetailsCRF: null,
    treatmentCrfItemName: null,
    trialCollaboratingGroups: [],
    trialTrialPhases: [],
    trialTumourGroups: [],
    trialAllocatedWPs: [],
    trialInvolvements: [],
    personnelCount: null,
    isLegacyUserDatabase: null,
    shortDisplay: null,
    displayName: null,
    displayNumber: null,
    sendReminderEmail: null,
    sendReminderAfterDays: null,
    sendReminderStandardEmailTemplateId: null,
    sendReminderStandardEmailTemplate: null,
    laySummary: null,
    australianLeadGroup: null,
    australianLeadGroupId: null,
    internationalLeadGroup: null,
    internationalLeadGroupId: null,
    centralCoordinatingCentreGroupId: null,
    centralCoordinatingCentreGroup: null,
    internationalCentralCoordinatingCentreGroupId: null,
    internationalCentralCoordinatingCentreGroup: null,
    centralRTQACoordinatingCentreGroupId: null,
    centralRTQACoordinatingCentreGroup: null,
    trialProjectRegistrations: [],
    trialFundings: [],
    trialChairs: [],
    tumourGroupOther: null,
    dateTSCApprovedForDevelopment: null,
    dateTSCTROGNumberAllocated: null,
    dateTSCFinalProtocol: null,
    dateTSCHRECApprovalReveived: null,
    targetSitesTotal: null,
    actualSitesTotal: null,
    targetSitesAUS: null,
    actualSitesAUS: null,
    targetSitesNZ: null,
    actualSitesNZ: null,
    dateExpectedCompletion: null,
    internationalTargetSites: null,
    internationalActualSites: null,
    internationalDateCompletedWithdrawn: null,
    internationalActualDateAccrualCompleted: null,
    internationalActualDateFollowupCompletion: null,
    trialAnalysisResults: [],
    trialPresentations: [],
    trialPublications: [],
    patientImportConfigured: false
}

export const initialTrialProjectRegistrationState: Dtos.TrialProjectRegistration & IRegistrationFormState = {
    id: null,
    trialId: null,
    trialRegistryId: null,
    trialRegistryOther: null,
    registrationNumber: null,
    url: null,
    active: true,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    trial: null,
    trialRegistry: null,
    saveAndReturn: false,
}

export const initialTrialChairState: Dtos.TrialChair & IRegistrationFormState = {
    id: null,
    trialId: null,
    personnelId: null,
    personnel: null,
    isPrimary: false,
    trial: null,
    active: true,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false,
}

export const initialTrialFundingState: Dtos.TrialFunding & IRegistrationFormState = {
    id: null,
    trialId: null,
    trial: null,
    name: null,
    source: null,
    fundingStatus: null,
    fundingStatusId: null,
    startDate: null,
    endDate: null,
    submissionDate: null,
    amountRequested: null,
    amountRecieved: 0,
    currency: null,
    active: true,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false,
}

export const initialTrialAnalysisResultState: Dtos.TrialAnalysisResult & IRegistrationFormState = {
    id: null,
    trialId: null,
    trialAnalysisTypeId: null,
    trialAnalysisTypeOther: null,
    targetStartDate: null,
    actualStartDate: null,
    targetCompletionDate: null,
    actualCompletionDate: null,
    trialPublicationId: null,
    active: true,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    trial: null,
    trialAnalysisType: null,
    saveAndReturn: false,
}

export const initialTrialPresentationState: Dtos.TrialPresentation & IRegistrationFormState = {
    id: null,
    trialId: null,
    trialPresentationTypeId: null,
    title: null,
    presenterName: null,
    meeting: null,
    meetingDate: null,
    publishedAsAnAbstractId: null,
    active: true,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    trial: null,
    trialPresentationType: null,
    saveAndReturn: false,
}

export const initialTrialPublicationState: Dtos.TrialPublication & IRegistrationFormState = {
    id: null,
    trialId: null,
    trialPublicationTypeId: null,
    trialPublicationTypeOther: null,
    trialPublicationStatusId: null,
    details: null,
    tpcReviewedId: null,
    yearPublished: null,
    active: true,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    trial: null,
    trialPublicationType: null,
    trialPublicationStatus: null,
    saveAndReturn: false,
}

export interface ITrialReduxFormState {
    id: IFieldState<number>,
    protocolID: IFieldState<string>,
    newProposalNumber: IFieldState<string>,
    trialDevelopmentNumber: IFieldState<string>,
    allIdentifiers: IFieldState<string>,
    studyAcronym: IFieldState<string>,
    title: IFieldState<number>,
    shortTitle: IFieldState<string>,
    trialCategoryId: IFieldState<number>,
    trialCategory: IFieldState<Dtos.TrialCategory>,
    trialChair1PersonnelId: IFieldState<number>,
    trialChair1Personnel: IFieldState<Dtos.Personnel>,
    trialChair2PersonnelId: IFieldState<number>,
    trialChair2Personnel: IFieldState<Dtos.Personnel>,
    trialCoordCentreId: IFieldState<number>,
    trialCoordCentre: IFieldState<Dtos.TrialCoordCentre>,
    active: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>,
    trialItems: IFieldState<Dtos.TrialItem[]>,
    trialGroups: IFieldState<Dtos.TrialGroup[]>,
    trialInstitutions: IFieldState<Dtos.TrialInstitution[]>,
    trialItemCategories: IFieldState<Dtos.TrialItemCategory[]>,
    personnelRoleSummaries: IFieldState<Dtos.PersonnelRoleSummary[]>,
    globalRoleSummaries: IFieldState<Dtos.PersonnelRoleSummary[]>,
    notes: IFieldState<Dtos.NoteTypeNotes[]>,
    comments: IFieldState<string>,
    confidentiallyDeedRequired: IFieldState<boolean>,
    institutionCodingId: IFieldState<number>,
    institutionCoding: IFieldState<Dtos.InstitutionCoding>,
    trialTypeId: IFieldState<number>,
    trialType: IFieldState<Dtos.TrialType>,

    trialStatusId: IFieldState<number>,
    trialStatus: IFieldState<Dtos.TrialStatus>,
    dateActivated: IFieldState<string>,
    dateFirstPatient: IFieldState<string>,
    dateAccrualComplete: IFieldState<string>,
    dateStudyClosed: IFieldState<string>,
    dateCompletedWithdrawn: IFieldState<string>,

    internationalTrialStatusId: IFieldState<number>,
    internationalTrialStatus: IFieldState<Dtos.InternationalTrialStatus>,
    internationalDateActivated: IFieldState<string>,
    internationalDateAccrualCompleted: IFieldState<string>,


    targetAccrual: IFieldState<number>,
    totalAccrual: IFieldState<number>,
    targetDateAccrualCompleted: IFieldState<string>,
    targetDateFollowupCompletion: IFieldState<string>,
    internationalTargetAccrual: IFieldState<number>,
    internationalTotalAccrual: IFieldState<number>,
    internationalTargetDateAccrualCompleted: IFieldState<string>,
    internationalTargetDateFollowupCompletion: IFieldState<string>,
    apiKey: IFieldState<string>,
    registrationWebsite: IFieldState<string>,
    trialCollaboratingGroups: IFieldState<Dtos.TrialCollaboratingGroup[]>,
    trialTrialPhases: IFieldState<Dtos.TrialTrialPhase[]>,
    trialTumourGroups: IFieldState<Dtos.TrialTumourGroup[]>,
    trialAllocatedWPs: IFieldState<Dtos.TrialAllocatedWP[]>,
    trialInvolvements: IFieldState<Dtos.TrialInvolvement[]>,
    personnelCount: IFieldState<number>,
    patientDetailsCRF: IFieldState<string>,
    treatmentCrfItemName: IFieldState<string>,
    isLegacyUserDatabase: IFieldState<boolean>,
    sendReminderEmail: IFieldState<boolean>,
    sendReminderAfterDays: IFieldState<number>,
    sendReminderStandardEmailTemplateId: IFieldState<number>,

    laySummary: IFieldState<string>,
    australianLeadGroupId: IFieldState<number>,
    internationalLeadGroupId: IFieldState<number>,
    centralCoordinatingCentreGroupId: IFieldState<number>,
    internationalCentralCoordinatingCentreGroupId: IFieldState<number>,
    centralRTQACoordinatingCentreGroupId: IFieldState<number>,
    trialProjectRegistrations: IFieldState<Dtos.TrialProjectRegistration[]>,
    trialFundings: IFieldState<Dtos.TrialFunding[]>,
    trialChairs: IFieldState<Dtos.TrialChair[]>,
    tumourGroupOther: IFieldState<string>,

    dateTSCApprovedForDevelopment: IFieldState<string>,
    dateTSCTROGNumberAllocated: IFieldState<string>,
    dateTSCFinalProtocol: IFieldState<string>,
    dateTSCHRECApprovalReveived: IFieldState<string>,
    targetSitesAUS: IFieldState<number>,
    actualSitesAUS: IFieldState<number>,
    targetSitesNZ: IFieldState<number>,
    actualSitesNZ: IFieldState<number>,
    dateExpectedCompletion: IFieldState<string>,
    internationalTargetSites: IFieldState<number>,
    internationalActualSites: IFieldState<number>,
    internationalDateCompletedWithdrawn: IFieldState<number>,
    internationalActualDateAccrualCompleted: IFieldState<number>,
    internationalActualDateFollowupCompletion: IFieldState<number>,
    mainAnalysisTargetStartDate: IFieldState<string>,
    mainAnalysisActualStartDate: IFieldState<string>,
    mainAnalysisTargetCompletionDate: IFieldState<string>,
    mainAnalysisActualCompletionDate: IFieldState<string>,
    mainAnalysisTrialPublicationId: IFieldState<number>,
    finalAnalysisTargetStartDate: IFieldState<string>,
    finalAnalysisActualStartDate: IFieldState<string>,
    finalAnalysisTargetCompletionDate: IFieldState<string>,
    finalAnalysisActualCompletionDate: IFieldState<string>,
    finalAnalysisTrialPublicationId: IFieldState<number>,
}
