import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadInstitutionCoding: IInstitutionCodingLoadActionCreator = () => {
        return {
            type: ACTION_INSTITUTIONCODINGS_LOAD
        }
    },
    LoadInstitutionCodingById: IInstitutionCodingLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_INSTITUTIONCODINGS_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IInstitutionCodingLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_INSTITUTIONCODINGS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IInstitutionCodingLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_INSTITUTIONCODINGS_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveInstitutionCoding: IInstitutionCodingSaveActionCreator = (formData) => {
        return {
            type: ACTION_INSTITUTIONCODINGS_SAVE,
            formData
        }
    },
    SaveSuccess: IInstitutionCodingSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_INSTITUTIONCODINGS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IInstitutionCodingSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_INSTITUTIONCODINGS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateInstitutionCoding: IInstitutionCodingCreateActionCreator = () => {

        return {
            type: ACTION_INSTITUTIONCODINGS_CREATE
        }
    },
    CreateSuccess: IInstitutionCodingCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_INSTITUTIONCODINGS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: IInstitutionCodingCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_INSTITUTIONCODINGS_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IInstitutionCodingClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_INSTITUTIONCODINGS_CLEAR,
            propertiesToClear
        }
    },

    Filter: IInstitutionCodingFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_INSTITUTIONCODINGS_FILTER,
            formFilter
        }
    };



export const
    ACTION_INSTITUTIONCODINGS_LOAD = "ACTION_INSTITUTIONCODINGS_LOAD",
    ACTION_INSTITUTIONCODINGS_LOAD_BY_ID = "ACTION_INSTITUTIONCODINGS_LOAD_BY_ID",
    ACTION_INSTITUTIONCODINGS_LOAD_SUCCESS = "ACTION_INSTITUTIONCODINGS_LOAD_SUCCESS",
    ACTION_INSTITUTIONCODINGS_LOAD_FAILURE = "ACTION_INSTITUTIONCODINGS_LOAD_FAILURE",

    ACTION_INSTITUTIONCODINGS_SAVE = "ACTION_INSTITUTIONCODINGS_SAVE",
    ACTION_INSTITUTIONCODINGS_SAVE_SUCCESS = "ACTION_INSTITUTIONCODINGS_SAVE_SUCCESS",
    ACTION_INSTITUTIONCODINGS_SAVE_FAILURE = "ACTION_INSTITUTIONCODINGS_SAVE_FAILURE",

    ACTION_INSTITUTIONCODINGS_CREATE = "ACTION_INSTITUTIONCODINGS_CREATE",
    ACTION_INSTITUTIONCODINGS_CREATE_SUCCESS = "ACTION_INSTITUTIONCODINGS_CREATE_SUCCESS",
    ACTION_INSTITUTIONCODINGS_CREATE_FAILURE = "ACTION_INSTITUTIONCODINGS_CREATE_FAILURE",

    ACTION_INSTITUTIONCODINGS_CLEAR = "ACTION_INSTITUTIONCODINGS_CLEAR",

    ACTION_INSTITUTIONCODINGS_FILTER = "ACTION_INSTITUTIONCODINGS_FILTER";

export interface IInstitutionCodingLoadAction {
    type: "ACTION_INSTITUTIONCODINGS_LOAD";
}

export interface IInstitutionCodingLoadActionCreator {
    (): IInstitutionCodingLoadAction;
}

export interface IInstitutionCodingLoadSuccessAction {
    type: "ACTION_INSTITUTIONCODINGS_LOAD_SUCCESS";
    formData: Dtos.InstitutionCoding | Dtos.InstitutionCoding[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IInstitutionCodingLoadSuccessActionCreator {
    (
        formData: Dtos.InstitutionCoding | Dtos.InstitutionCoding[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IInstitutionCodingLoadSuccessAction;
}

export interface IInstitutionCodingLoadFailureAction {
    type: "ACTION_INSTITUTIONCODINGS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IInstitutionCodingLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IInstitutionCodingLoadFailureAction;
}

export interface IInstitutionCodingLoadByIdAction {
    type: "ACTION_INSTITUTIONCODINGS_LOAD_BY_ID";
    id: number;
}

export interface IInstitutionCodingLoadByIdActionCreator {
    (id: number): IInstitutionCodingLoadByIdAction;
}


export interface IInstitutionCodingSaveAction {
    type: "ACTION_INSTITUTIONCODINGS_SAVE";
    formData: Dtos.InstitutionCoding;

}

export interface IInstitutionCodingSaveActionCreator {
    (
        formData: Dtos.InstitutionCoding
    ): IInstitutionCodingSaveAction;
}

export interface IInstitutionCodingSaveSuccessAction {
    type: "ACTION_INSTITUTIONCODINGS_SAVE_SUCCESS";
    formData: Dtos.InstitutionCoding;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IInstitutionCodingSaveSuccessActionCreator {
    (
        formData: Dtos.InstitutionCoding,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IInstitutionCodingSaveSuccessAction;
}

export interface IInstitutionCodingSaveFailureAction {
    type: "ACTION_INSTITUTIONCODINGS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IInstitutionCodingSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IInstitutionCodingSaveFailureAction;
}

export interface IInstitutionCodingCreateAction {
    type: "ACTION_INSTITUTIONCODINGS_CREATE"
}

export interface IInstitutionCodingCreateActionCreator {
    (): IInstitutionCodingCreateAction
}

export interface IInstitutionCodingCreateSuccessAction {
    type: "ACTION_INSTITUTIONCODINGS_CREATE_SUCCESS";
    formData: Dtos.InstitutionCoding;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface IInstitutionCodingCreateSuccessActionCreator {
    (
        formData: Dtos.InstitutionCoding,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): IInstitutionCodingCreateSuccessAction;
}

export interface IInstitutionCodingCreateFailureAction {
    type: "ACTION_INSTITUTIONCODINGS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IInstitutionCodingCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IInstitutionCodingCreateFailureAction;
}

export interface IInstitutionCodingClearAction {
    type: "ACTION_INSTITUTIONCODINGS_CLEAR";
    propertiesToClear?: string[];
}

export interface IInstitutionCodingClearActionCreator {
    (propertiesToClear?: string[]): IInstitutionCodingClearAction;
}

export interface IInstitutionCodingFilterActionCreator {
    (formFilter: IFormFilter): IInstitutionCodingFilterAction;
}

export interface IInstitutionCodingFilterAction {
    type: "ACTION_INSTITUTIONCODINGS_FILTER";
    formFilter: IFormFilter;
}

export type InstitutionCodingAction =
    IInstitutionCodingLoadAction |
    IInstitutionCodingLoadByIdAction |
    IInstitutionCodingLoadFailureAction |
    IInstitutionCodingLoadSuccessAction |
    IInstitutionCodingSaveAction |
    IInstitutionCodingSaveSuccessAction |
    IInstitutionCodingSaveFailureAction |
    IInstitutionCodingCreateAction |
    IInstitutionCodingCreateSuccessAction |
    IInstitutionCodingCreateFailureAction |
    IInstitutionCodingFilterAction | 
    IInstitutionCodingClearAction;
