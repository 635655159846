import * as React from 'react';
import './HubspotMemberPage.scss';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import Alert from 'react-s-alert';
import { Link } from "../../../components/routing/index";
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu, TruncatedCell } from "../../../components/layouts/index";
import * as HubspotActions from '../../../actions/hubspot';
import * as PagingActions from '../../../actions/pages/pagingPage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { InfoPanel, Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { TextAreaFormInput, FormFilterInput, SearchInput, CheckboxFormInput } from '../../../components/form';
import { SettingsSubMenu } from '../../../helpers/subMenuHelper';
import EmailSearchInput from './EmailSearchInput';
import { HubspotSettingsLayout } from '../../index';

interface IHubspotMemberPageProps {
    location: Location;
    history: History;

    existing: Dtos.EmailUsage;
    replacement: Dtos.EmailUsage;
    emailUpdateOptions: Dtos.EmailUpdateOptions;

    loading: boolean;
    loadSuccess: boolean;
    loadFailure: boolean;
    loadErrorMessage: string;

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface IHubspotMemberPageActions {
    clear: HubspotActions.IHubspotClearActionCreator;
    load: HubspotActions.IHubspotFindContactActionCreator;
    update: HubspotActions.IHubspotSetEmailUpdateOptionsActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

}

type HubspotMemberPageProps = IHubspotMemberPageProps & IHubspotMemberPageActions;

class HubspotMemberPage extends React.PureComponent<HubspotMemberPageProps, any> {

    constructor(props: HubspotMemberPageProps) {
        super(props);
        this.clear = this.clear.bind(this);
        this.find = this.find.bind(this);
        this.state = {
            email: "",
            email2: ""
        }
    }

    componentDidMount() {
        const {
            clear
        } = this.props;
        clear();
    }

    componentWillReceiveProps(nextProps: HubspotMemberPageProps) {
    }

    componentDidUpdate(prevProps: HubspotMemberPageProps, prevState) {
        const {
            loading,
            loadFailure,
            loadErrorMessage
        } = this.props;

        if (!loading && prevProps.loading) {
            if (loadFailure) {
                if (loadErrorMessage && loadErrorMessage.length > 0) {
                    Alert.error(
                        <NotificationAlert
                            alertContent={loadErrorMessage}
                            icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                        />
                    );
                }
            }
        }
    }

    componentWillUpdate(nextProps: HubspotMemberPageProps) {
    }

    componentWillUnmount() {
        this.clear();
    }

    clear() {
        this.props.clear();
    }

    find() {
        this.props.load(this.state.email, "", this.state.email2, this.props.emailUpdateOptions);
    }

    render() {

        const {
            loading,
            permissions
        } = this.props;

        return <HubspotSettingsLayout
            permission={Dtos.Permission.HubspotContactExportView}
            loading={loading}
        >
            {this.renderContent()}
        </HubspotSettingsLayout>;
    }

    renderContent() {
        const {
            navigate,
            permissions,
            loading,
            existing,
            replacement,
            emailUpdateOptions,
        } = this.props;
        let self = this;

        let contact;
        let userLogin;
        let personnel;
        let personnels;

        if (existing) {
            contact = existing.contact;
            userLogin = existing.userLogin;
            personnel = existing.personnel;
            personnels = existing.emailMatchedPersonnels;
        }

        let contact2;
        let userLogin2;
        let personnel2;
        let personnels2;

        if (replacement) {
            contact2 = replacement.contact;
            userLogin2 = replacement.userLogin;
            personnel2 = replacement.personnel;
            personnels2 = replacement.emailMatchedPersonnels;
        }

        return <div>
            <div className="row">
                <div className={"col mb-2"}>
                    <h2>Hubspot Email Maintenance</h2>
                </div>
                <div className="col-auto" style={{ textAlign: 'right' }}>
                    <div className="row">
                        <div className="col-auto">
                            <EmailSearchInput email1={this.state.email} email2={this.state.email2} onSearch={(email1, email2) => self.setState({ email: email1, email2 }, () => this.find())} />
                        </div>
                    </div>
                </div>
            </div>
            {
                loading ?
                    "Loading Contact" : <div className="row">
                        <div className="col">
                            <h3 className="text-center">Existing</h3>
                            <div className="row mt-3">
                                <div className="col">
                                    <h4>Hubspot Contact</h4>
                                    {contact ? <>
                                        <div className="row">
                                            <div className="col-4">
                                                <p><b>First name</b></p>
                                            </div>
                                            <div className="col-8">
                                                <p>{this.getHubpotDataValue(contact.data, "First Name")}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <p><b>Last name</b></p>
                                            </div>
                                            <div className="col-8">
                                                <p>{this.getHubpotDataValue(contact.data, "Last Name")}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <p><b>Email</b></p>
                                            </div>
                                            <div className="col-8">
                                                <p>{this.getHubpotDataValue(contact.data, "Email")}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <p><b>Username</b></p>
                                            </div>
                                            <div className="col-8">
                                                <p>{this.getHubpotDataValue(contact.data, "Username")}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <p><b>Membership Status</b></p>
                                            </div>
                                            <div className="col-8">
                                                <p>{this.getHubpotDataValue(contact.data, "Membership status")}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <p><b>Contact type</b></p>
                                            </div>
                                            <div className="col-8">
                                                <p>{this.getHubpotDataValue(contact.data, "Contact type")}</p>
                                            </div>
                                        </div>
                                    </> : <p>Not found</p>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <h4>Active Directory Account</h4>
                                    {userLogin ? <>
                                        <div className="row">
                                            <div className="col-4">
                                                <p><b>Directory</b></p>
                                            </div>
                                            <div className="col-8">
                                                <p style={{ overflowWrap: 'anywhere' }}>{userLogin.activeDirectory}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <p><b>Username</b></p>
                                            </div>
                                            <div className="col-8">
                                                <p>{userLogin.activeDirectoryUserName}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <p><b>Email</b></p>
                                            </div>
                                            <div className="col-8">
                                                <p>{userLogin.activeDirectoryEmail}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <p><b>Memberships</b></p>
                                            </div>
                                            <div className="col-8">
                                                <p>{userLogin.activeDirectoryMemberships}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <p><b>Active</b></p>
                                            </div>
                                            <div className="col-8">
                                                <p>{userLogin.activeDirectoryActive ? "True" : "False"}</p>
                                            </div>
                                        </div>

                                        {contact && (<div className="row">
                                            <div className="col">
                                            </div>
                                            <div className="col-auto">
                                                <button className="btn btn-warning mr-2 btn-sm" type="button" onClick={() => this.props.load(existing.email, "Update", this.state.email2, this.props.emailUpdateOptions)}>Update</button>
                                                <button className="btn btn-warning btn-sm" type="button" onClick={() => this.props.load(existing.email, "Resend|" + userLogin.activeDirectoryUserName, this.state.email2, this.props.emailUpdateOptions)}>Resend Email</button>
                                            </div>
                                        </div>)}
                                    </> : <>
                                        <p>Not found</p>
                                        {contact && (<div className="row">
                                            <div className="col">
                                                <button className="btn btn-warning" type="button" onClick={() => this.props.load(existing.email, "Create", this.state.email2, this.props.emailUpdateOptions)}>Create</button>
                                            </div>
                                        </div>)}
                                    </>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <h4>Personnel</h4>
                                    {personnel ? <>
                                        <div className="row">
                                            <div className="col-4">
                                                <p><b>Name</b></p>
                                            </div>
                                            <div className="col-8">
                                                <p style={{ overflowWrap: 'anywhere' }}>{personnel.display}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <p><b>Username</b></p>
                                            </div>
                                            <div className="col-8">
                                                <p>{personnel.userName}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <p><b>Email</b></p>
                                            </div>
                                            <div className="col-8">
                                                <p>{personnel.email}</p>
                                            </div>
                                        </div>

                                    </> : <>
                                        <p>Username not found in personnel</p>
                                    </>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <h4>Personnel matching email</h4>
                                    {(personnels && personnels.length > 0) ? <>
                                        <div className="row">
                                            <div className="col-4">
                                                <p><b>Username</b></p>
                                            </div>
                                            <div className="col-8">
                                                <p><b>Name</b></p>
                                            </div>
                                        </div>
                                        {personnels.map((item, index) => <>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p style={{ overflowWrap: 'anywhere' }}>{item.userName}</p>
                                                </div>
                                                <div className="col-8">
                                                    <p style={{ overflowWrap: 'anywhere' }}>{item.display}</p>
                                                </div>
                                            </div>
                                        </>)}
                                    </> : <>
                                        <p>Email not found in personnel</p>
                                    </>}
                                </div>
                            </div>
                        </div>
                        <div className="col" style={{ borderLeft: '1px dotted black' }} >
                            <h3 className="text-center">Replacement</h3>
                            {emailUpdateOptions && emailUpdateOptions.canChangeEmail ? <>
                                <div className="row my-3">
                                    <div className="col">
                                    </div>
                                    <div className="col-auto">
                                        <CheckboxFormInput
                                            name={"emailUpdateOptions.deActivateUserRoles"}
                                            id={"emailUpdateOptions.deActivateUserRoles"} value={emailUpdateOptions.deActivateUserRoles}
                                            onChange={(v, o) => {
                                                if (this.props.update) {
                                                    let value = { ...emailUpdateOptions };
                                                    value.deActivateUserRoles = v;
                                                    this.props.update(value);
                                                }
                                                return null;
                                            }}
                                            additionalContent={""}
                                            disabled={false}
                                            hideLabel={false}
                                            label={"De-activate TMD roles"} />
                                    </div>
                                    <div className="col">
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col">
                                    </div>
                                    <div className="col-auto">
                                        <button className="btn btn-danger mr-2 btn-sm" type="button" onClick={() => this.props.load(existing.email, "Replace", this.state.email2, this.props.emailUpdateOptions)}>Update Email to {emailUpdateOptions.email}</button>
                                    </div>
                                    <div className="col">
                                    </div>
                                </div>
                            </> : <>
                                <div className="row mt-3">
                                    <div className="col">
                                        <h4>Hubspot Contact</h4>
                                        {contact2 ? <>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p><b>First name</b></p>
                                                </div>
                                                <div className="col-8">
                                                    <p>{this.getHubpotDataValue(contact2.data, "First Name")}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p><b>Last name</b></p>
                                                </div>
                                                <div className="col-8">
                                                    <p>{this.getHubpotDataValue(contact2.data, "Last Name")}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p><b>Email</b></p>
                                                </div>
                                                <div className="col-8">
                                                    <p>{this.getHubpotDataValue(contact2.data, "Email")}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p><b>Username</b></p>
                                                </div>
                                                <div className="col-8">
                                                    <p>{this.getHubpotDataValue(contact2.data, "Username")}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p><b>Membership Status</b></p>
                                                </div>
                                                <div className="col-8">
                                                    <p>{this.getHubpotDataValue(contact2.data, "Membership status")}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p><b>Contact type</b></p>
                                                </div>
                                                <div className="col-8">
                                                    <p>{this.getHubpotDataValue(contact2.data, "Contact type")}</p>
                                                </div>
                                            </div>
                                        </> : <p>Not found</p>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <h4>Active Directory Account</h4>
                                        {userLogin2 ? <>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p><b>Directory</b></p>
                                                </div>
                                                <div className="col-8">
                                                    <p style={{ overflowWrap: 'anywhere' }}>{userLogin2.activeDirectory}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p><b>Username</b></p>
                                                </div>
                                                <div className="col-8">
                                                    <p>{userLogin2.activeDirectoryUserName}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p><b>Email</b></p>
                                                </div>
                                                <div className="col-8">
                                                    <p>{userLogin2.activeDirectoryEmail}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p><b>Memberships</b></p>
                                                </div>
                                                <div className="col-8">
                                                    <p>{userLogin2.activeDirectoryMemberships}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p><b>Active</b></p>
                                                </div>
                                                <div className="col-8">
                                                    <p>{userLogin2.activeDirectoryActive ? "True" : "False"}</p>
                                                </div>
                                            </div>
                                        </> : <>
                                            <p>Not found</p>
                                        </>}
                                    </div>
                                </div>
                            </>}
                            <div className="row">
                                <div className="col">
                                    <h4>Personnel</h4>
                                    {personnel2 ? <>
                                        <div className="row">
                                            <div className="col-4">
                                                <p><b>Name</b></p>
                                            </div>
                                            <div className="col-8">
                                                <p style={{ overflowWrap: 'anywhere' }}>{personnel2.display}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <p><b>Username</b></p>
                                            </div>
                                            <div className="col-8">
                                                <p>{personnel2.userName}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <p><b>Email</b></p>
                                            </div>
                                            <div className="col-8">
                                                <p>{personnel2.email}</p>
                                            </div>
                                        </div>

                                    </> : <>
                                        <p>Username not found in personnel</p>
                                    </>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <h4>Personnel matching email</h4>
                                    {(personnels2 && personnels2.length > 0) ? <>
                                        <div className="row">
                                            <div className="col-4">
                                                <p><b>Username</b></p>
                                            </div>
                                            <div className="col-8">
                                                <p><b>Name</b></p>
                                            </div>
                                        </div>
                                        {personnels2.map((item, index) => <>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p style={{ overflowWrap: 'anywhere' }}>{item.userName}</p>
                                                </div>
                                                <div className="col-8">
                                                    <p style={{ overflowWrap: 'anywhere' }}>{item.display}</p>
                                                </div>
                                            </div>
                                        </>)}
                                    </> : <>
                                        <p>Email not found in personnel</p>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    }

    getHubpotDataValue(values, name) {
        var value = values.find(f => f.name == name);
        console.log(values, name, value);
        return value ? value.value : "";
    }

}

const mapStateToProps = (state: ITmdState, ownProps: HubspotMemberPageProps): IHubspotMemberPageProps => {

    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {
        history: ownProps.history,
        location: state.routing.location,

        existing: state.hubspot.existing,
        replacement: state.hubspot.replacement,
        emailUpdateOptions: state.hubspot.emailUpdateOptions,

        loading: state.hubspot.loadState && state.hubspot.loadState.status == RequestState.Pending,
        loadSuccess: state.hubspot.loadState && state.hubspot.loadState.status == RequestState.Success,
        loadFailure: state.hubspot.loadState && state.hubspot.loadState.status == RequestState.Failure,
        loadErrorMessage: state.hubspot.loadState.errorMessage,

        user: state.user.data,
        permissions: permissions
    };
};

const mapDispatchToProps = (dispatch): IHubspotMemberPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        load: bindActionCreators(HubspotActions.HubspotFindContact, dispatch),
        clear: bindActionCreators(HubspotActions.Clear, dispatch),
        update: bindActionCreators(HubspotActions.HubspotSetEmailUpdateOptions, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(HubspotMemberPage);
