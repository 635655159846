import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    Load: ITrialItemUpdateLoadByIdActionCreator = (id, trialItemId) => {
        return {
            type: ACTION_TRIALITEMUPDATE_LOAD_BY_ID,
            id,
            trialItemId
        }
    },
    LoadSuccess: ITrialItemUpdateLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALITEMUPDATE_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrialItemUpdateLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALITEMUPDATE_LOAD_FAILURE,
            responseStatus
        }
    },
    Save: ITrialItemUpdateSaveActionCreator = (formData) => {
        return {
            type: ACTION_TRIALITEMUPDATE_SAVE,
            formData
        }
    },
    SaveSuccess: ITrialItemUpdateSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRIALITEMUPDATE_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrialItemUpdateSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALITEMUPDATE_SAVE_FAILURE,
            responseStatus
        }
    },
    Clear: ITrialItemUpdateClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRIALITEMUPDATE_CLEAR,
            propertiesToClear
        }
    },
    Filter: ITrialItemUpdateFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRIALITEMUPDATE_FILTER,
            formFilter
        }
    };

export const
    ACTION_TRIALITEMUPDATE_LOAD_BY_ID = "ACTION_TRIALITEMUPDATE_LOAD_BY_ID",
    ACTION_TRIALITEMUPDATE_LOAD_SUCCESS = "ACTION_TRIALITEMUPDATE_LOAD_SUCCESS",
    ACTION_TRIALITEMUPDATE_LOAD_FAILURE = "ACTION_TRIALITEMUPDATE_LOAD_FAILURE",

    ACTION_TRIALITEMUPDATE_SAVE = "ACTION_TRIALITEMUPDATE_SAVE",
    ACTION_TRIALITEMUPDATE_SAVE_SUCCESS = "ACTION_TRIALITEMUPDATE_SAVE_SUCCESS",
    ACTION_TRIALITEMUPDATE_SAVE_FAILURE = "ACTION_TRIALITEMUPDATE_SAVE_FAILURE",

    ACTION_TRIALITEMUPDATE_CLEAR = "ACTION_TRIALITEMUPDATE_CLEAR",

    ACTION_TRIALITEMUPDATE_FILTER = "ACTION_TRIALITEMUPDATE_FILTER";

export interface ITrialItemUpdateLoadAction {
    type: "ACTION_TRIALITEMUPDATE_LOAD";
}

export interface ITrialItemUpdateLoadActionCreator {
    (): ITrialItemUpdateLoadAction;
}

export interface ITrialItemUpdateLoadSuccessAction {
    type: "ACTION_TRIALITEMUPDATE_LOAD_SUCCESS";
    formData: Dtos.TrialItemUpdate;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialItemUpdateLoadSuccessActionCreator {
    (
        formData: Dtos.TrialItemUpdate,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialItemUpdateLoadSuccessAction;
}

export interface ITrialItemUpdateLoadFailureAction {
    type: "ACTION_TRIALITEMUPDATE_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialItemUpdateLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialItemUpdateLoadFailureAction;
}

export interface ITrialItemUpdateLoadByIdAction {
    type: "ACTION_TRIALITEMUPDATE_LOAD_BY_ID";
    id: number;
    trialItemId: number;
}

export interface ITrialItemUpdateLoadByIdActionCreator {
    (id: number, trialItemId: number): ITrialItemUpdateLoadByIdAction;
}

export interface ITrialItemUpdateSaveAction {
    type: "ACTION_TRIALITEMUPDATE_SAVE";
    formData: Dtos.TrialItemUpdate;

}

export interface ITrialItemUpdateSaveActionCreator {
    (
        formData: Dtos.TrialItemUpdate
    ): ITrialItemUpdateSaveAction;
}

export interface ITrialItemUpdateSaveSuccessAction {
    type: "ACTION_TRIALITEMUPDATE_SAVE_SUCCESS";
    formData: Dtos.TrialItemUpdate;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialItemUpdateSaveSuccessActionCreator {
    (
        formData: Dtos.TrialItemUpdate,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrialItemUpdateSaveSuccessAction;
}

export interface ITrialItemUpdateSaveFailureAction {
    type: "ACTION_TRIALITEMUPDATE_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialItemUpdateSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialItemUpdateSaveFailureAction;
}

export interface ITrialItemUpdateClearAction {
    type: "ACTION_TRIALITEMUPDATE_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrialItemUpdateClearActionCreator {
    (propertiesToClear?: string[]): ITrialItemUpdateClearAction;
}

export interface ITrialItemUpdateFilterActionCreator {
    (formFilter: IFormFilter): ITrialItemUpdateFilterAction;
}

export interface ITrialItemUpdateFilterAction {
    type: "ACTION_TRIALITEMUPDATE_FILTER";
    formFilter: IFormFilter;
}

export type TrialItemUpdateAction =
    ITrialItemUpdateLoadByIdAction |
    ITrialItemUpdateLoadFailureAction |
    ITrialItemUpdateLoadSuccessAction |
    ITrialItemUpdateSaveAction |
    ITrialItemUpdateSaveSuccessAction |
    ITrialItemUpdateSaveFailureAction |
    ITrialItemUpdateClearAction |
    ITrialItemUpdateFilterAction;
