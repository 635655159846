import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialPatientState: Dtos.Patient & IRegistrationFormState = {
    id: null,
    active: null,
    trialId: null,
    institutionId: null,
    institution: null,
    initials: null,
    opmsId: 0,
    caseTypeId: null,
    caseType: null,
    trialTreatmentId: null,
    trialTreatment: null,
    treatingClinicianId: null,
    treatingClinician: null,
    investigatorId: null,
    investigator: null,
    dataManagerId: null,
    dataManager: null,
    registrationNumber: null,
    accrualDate: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false
}

export interface IPatientReduxFormState {
    id: IFieldState<number>,
    trialId: IFieldState<number>,
    institutionId: IFieldState<number>,
    institution: IFieldState<Dtos.Institution>,
    initials: IFieldState<string>,
    registrationNumber: IFieldState<string>,
    accrualDate: IFieldState<string>,
    opmsId: IFieldState<number>,
    caseTypeId: IFieldState<number>,
    trialTreatmentId: IFieldState<number>,
    treatingClinicianId: IFieldState<number>,
    investigatorId: IFieldState<number>,
    dataManagerId: IFieldState<number>,
    active: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>
}
