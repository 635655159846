import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trialItemImportApi = {
    getTrialItemImportById: (id: number, trialId: number): Observable<Dtos.GetTrialItemImportByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrialItemImportById = new Dtos.GetTrialItemImportById();

        request.id = id;
        request.trialId = trialId;

        //Send request.
        const response: Promise<Dtos.GetTrialItemImportByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrialItemImport: (trialItemImport: Dtos.TrialItemImport): Observable<Dtos.SetTrialItemImportResponse> => {
        //Create the request.
        let request: Dtos.SetTrialItemImport = new Dtos.SetTrialItemImport();

        request.id = trialItemImport.id;
        request.trialId = trialItemImport.trialId;
        request.trialItemImport = trialItemImport;

        // send request
        const response: Promise<Dtos.SetTrialItemImportResponse> = client.post(request);

        return Observable.from(response);
    }
}