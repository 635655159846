import * as Dtos from '../../dtos/Tmd.dtos';
import { FileState } from '../../dtos/Tmd.dtos';

export interface ISourceDocumentModalState {
    openedModals: string[];
}

export const initialSourceDocumentState: Dtos.SourceDocument = {
    id: null,
    formName: null,
    recordId: null,
    subRecordId: null,
    fileType: null,
    fileSize: null,
    name: null,
    extension: null,
    contentType: null,
    exists: null,
    image: null,
    active: true,
    guid: null,
    fileInformation: {
        guid: null,
        progress: null,
        state: FileState.Empty,
        temporaryFile: null
    },
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null
}