import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const taskTypeApi = {
    getTaskTypes: (): Observable<Dtos.GetTaskTypesResponse> => {
        //Create the request.
        let request: Dtos.GetTaskTypes = new Dtos.GetTaskTypes();

        //Send request.
        const response: Promise<Dtos.GetTaskTypesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTaskTypeById: (id: number): Observable<Dtos.GetTaskTypeByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTaskTypeById = new Dtos.GetTaskTypeById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTaskTypeByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTaskType: (taskType: Dtos.TaskType): Observable<Dtos.SetTaskTypeResponse> => {

        let request: Dtos.SetTaskType = new Dtos.SetTaskType();

        request.taskType = taskType;

        // send request
        const response: Promise<Dtos.SetTaskTypeResponse> = client.post(request);

        return Observable.from(response);

    },
    createTaskType: (): Observable<Dtos.CreateTaskTypeResponse> => {
        //Create the request.
        let request: Dtos.CreateTaskType = new Dtos.CreateTaskType();

        //Send request.
        const response: Promise<Dtos.CreateTaskTypeResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}