import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Tmd.dtos';

import {
    ACTION_PERSONNEL_LOAD,
    ACTION_PERSONNEL_LOAD_BY_ID,
    ACTION_PERSONNEL_SECURITY_LOAD_BY_ID,
    ACTION_PERSONNEL_LOAD_FAILURE,
    ACTION_PERSONNEL_LOAD_SUCCESS,

    ACTION_PERSONNEL_SAVE,
    ACTION_PERSONNEL_SAVE_SUCCESS,
    ACTION_PERSONNEL_SAVE_FAILURE,

    ACTION_PERSONNEL_SAVE_ACTIVE_DIRECTORY,
    ACTION_PERSONNEL_SAVE_ACTIVE_DIRECTORY_SUCCESS,
    ACTION_PERSONNEL_SAVE_ACTIVE_DIRECTORY_FAILURE,

    ACTION_PERSONNEL_CREATE,
    ACTION_PERSONNEL_CREATE_SUCCESS,
    ACTION_PERSONNEL_CREATE_FAILURE,

    ACTION_PERSONNEL_RESENDEMAIL,
    ACTION_PERSONNEL_RESENDEMAIL_SUCCESS,
    ACTION_PERSONNEL_RESENDEMAIL_FAILURE,

    ACTION_PERSONNEL_CLEAR,
    ACTION_PERSONNEL_FILTER,

    ACTION_PERSONNEL_PERMISSIONS_LOAD_BY_ID,
    ACTION_PERSONNEL_PERMISSIONS_LOAD_FAILURE,
    ACTION_PERSONNEL_PERMISSIONS_LOAD_SUCCESS,

    ACTION_PERSONNEL_BY_ROLE_LOAD,
    ACTION_PERSONNEL_BY_ROLE_LOAD_FAILURE,
    ACTION_PERSONNEL_BY_ROLE_LOAD_SUCCESS,
    ACTION_PERSONNEL_FILTER_BY_ROLE,
    ACTION_PERSONNEL_ROLES_FILTER,

    PersonnelAction
} from '../actions/personnel';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState, IFormFilter } from "./index";
import { updateFailureIndexToId } from "../helpers/formErrorHelper";

export interface IPersonnelState {
    formData: Dtos.Personnel | Dtos.Personnel[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    validationFailures: Dtos.ResponseError[];
    lookups: Dtos.Lookup[],
    loadState: IRequestState;
    saveState: IRequestState;
    updateState: IRequestState;
    createState: IRequestState;
    resendingState: IRequestState;
    formFilter: IFormFilter;
    formFilteredData: Dtos.Personnel[];
    formLength: number;
    loadPermissionsState: IRequestState;
    groupedPersonnelPermissions: Dtos.PersonnelPermissionGroup[];
    rolePersonnelGroupings: Dtos.RolePersonnelGrouping[];
    rolePersonnelGroupingsFiltered: Dtos.RolePersonnelGrouping[];
    formFilterByRole: IFormFilter;
    formFilterPersonnelRoles: IFormFilter;
    formFilteredPersonnelRoles: Dtos.PersonnelRoleSummary[];
    formLengthPersonnelRoles: number;
}

const initialState: IPersonnelState = {
    formData: undefined,
    formState: undefined,
    formProperties: undefined,
    validationFailures: undefined,
    lookups: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    updateState: {
        status: RequestState.None
    } as IRequestState,
    createState: {
        status: RequestState.None
    } as IRequestState,
    resendingState: {
        status: RequestState.None
    } as IRequestState,
    loadPermissionsState: {
        status: RequestState.None
    } as IRequestState,
    formFilter: {
        search: "",
        showInActive: false
    },
    formFilteredData: [],
    formLength: 0,
    groupedPersonnelPermissions: [],
    rolePersonnelGroupings: [],
    rolePersonnelGroupingsFiltered: [],
    formFilterByRole: {
        search: "",
        showInActive: false
    },
    formFilterPersonnelRoles: {
        search: "",
        showInActive: false
    },
    formFilteredPersonnelRoles: [],
    formLengthPersonnelRoles: 0
};


function loading(state: IPersonnelState, isLoading: boolean): IPersonnelState {
    const newState: IPersonnelState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });
    return newState;
}

function loadSuccess(state: IPersonnelState,
    formData: Dtos.Personnel | Dtos.Personnel[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[],
    responseStatus: Dtos.ResponseStatus
): IPersonnelState {

    // if switching between list and details, reset the list
    var isList: boolean = formData instanceof Array ? true : false;
    const formFilterPersonnelRoles = isList ? {
        search: "",
        showInActive: false
    } : state.formFilterPersonnelRoles;

    const newState: IPersonnelState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        loadState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        },
        formFilterPersonnelRoles: {
            $set: formFilterPersonnelRoles
        }
    });

    return filterPersonnelRoles(filter(newState, state.formFilter), newState.formFilterPersonnelRoles);
}

function loadFailure(state: IPersonnelState, responseStatus: Dtos.ResponseStatus): IPersonnelState {
    const newState: IPersonnelState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function filter(state: IPersonnelState, formFilter: IFormFilter): IPersonnelState {

    const items: Dtos.Personnel[] | undefined =
        state.formData instanceof Array ?
            state.formData :
            undefined;

    const formLength = items ? items.length : 0;

    const formFilteredData = items ? items.filter(p => (p.active == true || formFilter.showInActive) &&
        (
            formFilter.search == ""
            || p.display.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1
            || (p.email != undefined && p.email != null && p.email != '' && p.email.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1)
        )
    ) : undefined;

    const newState: IPersonnelState = update(state, {
        formFilter: {
            $set: formFilter
        },
        formFilteredData: {
            $set: formFilteredData
        },
        formLength: {
            $set: formLength
        }
    });

    return newState;
}

function filterPersonnelRoles(state: IPersonnelState, formFilter: IFormFilter): IPersonnelState {

    let personnel: Dtos.Personnel | undefined =
        state.formData instanceof Array ?
            undefined : JSON.parse(JSON.stringify(state.formData));

    let personnelRoles: Dtos.PersonnelRoleSummary[] =
        personnel ? [...personnel.personnelRoleSummaries] : [];

    const formLengthPersonnelRoles = personnelRoles ? personnelRoles.length : 0;

    personnelRoles.forEach(item => {
        var personnelRoleSummaries = item.personnelRoleSummaries.filter(p => (p.personnelRole.active == true || formFilter.showInActive) &&
            (
                formFilter.search == ""
                || p.display.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1
            )
        );
        item.personnelRoleSummaries = [...personnelRoleSummaries];
    });

    let formFilteredPersonnelRoles = personnelRoles.filter(p => p.personnelRoleSummaries.length > 0);

    const newState: IPersonnelState = update(state, {
        formFilterPersonnelRoles: {
            $set: formFilter
        },
        formFilteredPersonnelRoles: {
            $set: formFilteredPersonnelRoles
        },
        formLengthPersonnelRoles: {
            $set: formLengthPersonnelRoles
        }
    });

    return newState;
}

function clear(state: IPersonnelState, propertiesToClear?: string[]): IPersonnelState {

    if (!propertiesToClear) {
        let formFilter = state.formFilter;
        const newState: IPersonnelState = update(initialState, {
            formFilter: {
                $set: formFilter
            }
        });
        return newState;
    }

    let stateModifier: any = {};

    propertiesToClear.forEach(propertyToClear => {
        stateModifier[propertyToClear] = { $set: initialState[propertyToClear] }
    });

    const newState = update(state, stateModifier);

    return newState;
}

function saving(state: IPersonnelState, isSaving: boolean): IPersonnelState {
    const newState: IPersonnelState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function saveSuccess(
    state: IPersonnelState,
    formData: Dtos.Personnel | Dtos.Personnel[],
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus
): IPersonnelState {
    const newState: IPersonnelState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        saveState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function saveFailure(state: IPersonnelState, responseStatus: Dtos.ResponseStatus): IPersonnelState {
    const newState: IPersonnelState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                meta: responseStatus.meta
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, state.formData) : undefined
        }
    });

    return newState;
}

function creating(state: IPersonnelState, isCreating: boolean): IPersonnelState {
    const newState: IPersonnelState = update(state, {
        createState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function createSuccess(
    state: IPersonnelState,
    formData: Dtos.Personnel,
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[]
): IPersonnelState {
    const newState: IPersonnelState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        createState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function createFailure(state: IPersonnelState, responseStatus: Dtos.ResponseStatus): IPersonnelState {
    const newState: IPersonnelState = update(state, {
        createState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function resending(state: IPersonnelState, isRunning: boolean): IPersonnelState {
    const newState: IPersonnelState = update(state, {
        resendingState: {
            $set: {
                status: isRunning ? RequestState.Pending : RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function resendingFailure(state: IPersonnelState, responseStatus: Dtos.ResponseStatus): IPersonnelState {
    const newState: IPersonnelState = update(state, {
        resendingState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                meta: responseStatus.meta
            } as IRequestState
        }
    });

    return newState;
}

function loadingPermissions(state: IPersonnelState, isLoading: boolean): IPersonnelState {
    const newState: IPersonnelState = update(state, {
        loadPermissionsState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });
    return newState;
}

function loadPermissionsSuccess(state: IPersonnelState,
    formData: Dtos.PersonnelPermissionGroup[],
    responseStatus: Dtos.ResponseStatus
): IPersonnelState {
    const newState: IPersonnelState = update(state, {
        groupedPersonnelPermissions: {
            $set: formData
        },
        loadPermissionsState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        }
    });

    return filter(newState, state.formFilter);
}

function loadPermissionsFailure(state: IPersonnelState, responseStatus: Dtos.ResponseStatus): IPersonnelState {
    const newState: IPersonnelState = update(state, {
        loadPermissionsState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function loadByRoleSuccess(state: IPersonnelState,
    formData: Dtos.RolePersonnelGrouping[],
    responseStatus: Dtos.ResponseStatus
): IPersonnelState {
    const newState: IPersonnelState = update(state, {
        rolePersonnelGroupings: {
            $set: formData
        },
        loadState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return filterByRole(newState, state.formFilter);
}

function loadByRoleFailure(state: IPersonnelState, responseStatus: Dtos.ResponseStatus): IPersonnelState {
    const newState: IPersonnelState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function filterByRole(state: IPersonnelState, formFilter: IFormFilter): IPersonnelState {

    const items: Dtos.RolePersonnelGrouping[] = state.rolePersonnelGroupings;

    const filteredItems = items ? items.filter(p =>
    (
        formFilter.search == ""
        || p.roleName.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1
    )
    ) : undefined;

    const newState: IPersonnelState = update(state, {
        formFilterByRole: {
            $set: formFilter
        },
        rolePersonnelGroupingsFiltered: {
            $set: filteredItems
        }
    });

    return newState;
}


const PersonnelReducer: Reducer<IPersonnelState> = (state: IPersonnelState = initialState, action: PersonnelAction) => {
    switch (action.type) {
        case ACTION_PERSONNEL_LOAD:
        case ACTION_PERSONNEL_LOAD_BY_ID:
        case ACTION_PERSONNEL_SECURITY_LOAD_BY_ID:
            return loading(state, true);
        case ACTION_PERSONNEL_LOAD_SUCCESS:
            return loadSuccess(state, action.formData, action.formState, action.formProperties, action.lookups, action.responseStatus);
        case ACTION_PERSONNEL_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_PERSONNEL_CLEAR:
            return clear(state, action.propertiesToClear);
        case ACTION_PERSONNEL_SAVE:
        case ACTION_PERSONNEL_SAVE_ACTIVE_DIRECTORY:
            return saving(state, true);
        case ACTION_PERSONNEL_SAVE_SUCCESS:
        case ACTION_PERSONNEL_SAVE_ACTIVE_DIRECTORY_SUCCESS:
            return saveSuccess(state, action.formData, action.formState, action.responseStatus);
        case ACTION_PERSONNEL_SAVE_FAILURE:
        case ACTION_PERSONNEL_SAVE_ACTIVE_DIRECTORY_FAILURE:
            return saveFailure(state, action.responseStatus);
        case ACTION_PERSONNEL_CREATE:
            return creating(state, true);
        case ACTION_PERSONNEL_CREATE_SUCCESS:
            return createSuccess(state, action.formData, action.formState, action.formProperties, action.lookups);
        case ACTION_PERSONNEL_CREATE_FAILURE:
            return createFailure(state, action.responseStatus);
        case ACTION_PERSONNEL_FILTER:
            return filter(state, action.formFilter);
        case ACTION_PERSONNEL_RESENDEMAIL:
            return resending(state, true);
        case ACTION_PERSONNEL_RESENDEMAIL_SUCCESS:
            return resending(state, false);
        case ACTION_PERSONNEL_RESENDEMAIL_FAILURE:
            return resendingFailure(state, action.responseStatus);
        case ACTION_PERSONNEL_PERMISSIONS_LOAD_BY_ID:
            return loadingPermissions(state, true);
        case ACTION_PERSONNEL_PERMISSIONS_LOAD_SUCCESS:
            return loadPermissionsSuccess(state, action.data, action.responseStatus);
        case ACTION_PERSONNEL_PERMISSIONS_LOAD_FAILURE:
            return loadPermissionsFailure(state, action.responseStatus);
        case ACTION_PERSONNEL_BY_ROLE_LOAD_SUCCESS:
            return loadByRoleSuccess(state, action.formData, action.responseStatus);
        case ACTION_PERSONNEL_BY_ROLE_LOAD_FAILURE:
            return loadByRoleFailure(state, action.responseStatus);
        case ACTION_PERSONNEL_FILTER_BY_ROLE:
            return filterByRole(state, action.formFilter);
        case ACTION_PERSONNEL_ROLES_FILTER:
            return filterPersonnelRoles(state, action.formFilter);
    }

    return state;
}

export default PersonnelReducer;