import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const rolesApi = {
    getRoles: (): Observable<Dtos.GetRolesResponse> => {
        //Create the request.
        let request: Dtos.GetRoles = new Dtos.GetRoles();

        //Send request.
        const response: Promise<Dtos.GetRolesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getRoleById: (id: number): Observable<Dtos.GetRoleByIdResponse> => {
        //Create the request.
        let request: Dtos.GetRoleById = new Dtos.GetRoleById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetRoleByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setRole: (role: Dtos.Role): Observable<Dtos.SetRoleResponse> => {

        let request: Dtos.SetRole = new Dtos.SetRole();

        request.role = role;

        // send request
        const response: Promise<Dtos.SetRoleResponse> = client.post(request);

        return Observable.from(response);

    },
    createRole: (): Observable<Dtos.CreateRoleResponse> => {
        //Create the request.
        let request: Dtos.CreateRole = new Dtos.CreateRole();

        //Send request.
        const response: Promise<Dtos.CreateRoleResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}