import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialCollectionInstanceState: Dtos.CollectionInstance & IRegistrationFormState = {
    id: null,
    collectionId: null,
    collection: null,
    active: null,
    dataEntered: false,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false,
    collectionInstanceDatas: [],
    sourceDocuments: [],
    itemComponentRules: [],
    columnFormat: null,
    column1: [],
    column2: [],
    column3: [],
    documents: [],
    isModified: false,
    sections: [],
    hasSections: null,
}

export interface ICollectionInstanceReduxFormState {
    id: IFieldState<number>,
    collectionId: IFieldState<number>,
    collection: IFieldState<Dtos.Collection>,
    assignedToId: IFieldState<number>,
    assignedRoleId: IFieldState<number>,
    assignmentTypeId: IFieldState<number>,
    active: IFieldState<boolean>,
    dataEntered: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>,
    collectionInstanceDatas: IFieldState<Dtos.CollectionInstanceData[]>,
    sourceDocuments: IFieldState<Dtos.SourceDocument[]>,
    itemComponentRules: IFieldState<Dtos.ItemComponentRule[]>,
    columnFormat: IFieldState<string[]>,
    column1: IFieldState<Dtos.ItemComponentWithData[]>,
    column2: IFieldState<Dtos.ItemComponentWithData[]>,
    column3: IFieldState<Dtos.ItemComponentWithData[]>,
    documents: IFieldState<Dtos.ItemComponentWithData[]>,
    isModified: IFieldState<boolean>,
    sections: IFieldState<Dtos.CollectionInstanceSection[]>,
    hasSections: IFieldState<boolean>,
}
