import * as Dtos from "../dtos/Tmd.dtos";

export const
    LoadCollectionById: ICollectionLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_COLLECTION_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ICollectionLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_COLLECTION_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ICollectionLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_COLLECTION_LOAD_FAILURE,
            responseStatus
        }
    },

    CreateCollection: ICollectionCreateActionCreator = () => {

        return {
            type: ACTION_COLLECTION_CREATE
        }
    },
    CreateSuccess: ICollectionCreateSuccessActionCreator = (formData, formState, formProperties, lookups) => {
        return {
            type: ACTION_COLLECTION_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups
        }
    },
    CreateFailure: ICollectionCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_COLLECTION_CREATE_FAILURE,
            responseStatus
        }
    },


    LockCollection: ICollectionLockActionCreator = (id, lock) => {
        return {
            type: ACTION_COLLECTION_LOCK,
            id,
            lock
        }
    },
    LockSuccess: ICollectionLockSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_COLLECTION_LOCK_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus
        }
    },
    LockFailure: ICollectionLockFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_COLLECTION_LOCK_FAILURE,
            responseStatus
        }
    },

    Clear: ICollectionClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_COLLECTION_CLEAR,
            propertiesToClear
        }
    };



export const
    ACTION_COLLECTION_LOAD_BY_ID = "ACTION_COLLECTION_LOAD_BY_ID",
    ACTION_COLLECTION_LOAD_SUCCESS = "ACTION_COLLECTION_LOAD_SUCCESS",
    ACTION_COLLECTION_LOAD_FAILURE = "ACTION_COLLECTION_LOAD_FAILURE",

    ACTION_COLLECTION_CREATE = "ACTION_COLLECTION_CREATE",
    ACTION_COLLECTION_CREATE_SUCCESS = "ACTION_COLLECTION_CREATE_SUCCESS",
    ACTION_COLLECTION_CREATE_FAILURE = "ACTION_COLLECTION_CREATE_FAILURE",

    ACTION_COLLECTION_LOCK = "ACTION_COLLECTION_LOCK",
    ACTION_COLLECTION_LOCK_SUCCESS = "ACTION_COLLECTION_LOCK_SUCCESS",
    ACTION_COLLECTION_LOCK_FAILURE = "ACTION_COLLECTION_LOCK_FAILURE",

    ACTION_COLLECTION_CLEAR = "ACTION_COLLECTION_CLEAR";


export interface ICollectionLoadSuccessAction {
    type: "ACTION_COLLECTION_LOAD_SUCCESS";
    formData: Dtos.Collection;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ICollectionLoadSuccessActionCreator {
    (
        formData: Dtos.Collection,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ICollectionLoadSuccessAction;
}

export interface ICollectionLoadFailureAction {
    type: "ACTION_COLLECTION_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ICollectionLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ICollectionLoadFailureAction;
}

export interface ICollectionLoadByIdAction {
    type: "ACTION_COLLECTION_LOAD_BY_ID";
    id: number;
}

export interface ICollectionLoadByIdActionCreator {
    (id: number): ICollectionLoadByIdAction;
}

export interface ICollectionCreateAction {
    type: "ACTION_COLLECTION_CREATE"
}

export interface ICollectionCreateActionCreator {
    (): ICollectionCreateAction
}

export interface ICollectionCreateSuccessAction {
    type: "ACTION_COLLECTION_CREATE_SUCCESS";
    formData: Dtos.Collection;
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[]
}

export interface ICollectionCreateSuccessActionCreator {
    (
        formData: Dtos.Collection,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[]
    ): ICollectionCreateSuccessAction;
}

export interface ICollectionCreateFailureAction {
    type: "ACTION_COLLECTION_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ICollectionCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ICollectionCreateFailureAction;
}

export interface ICollectionClearAction {
    type: "ACTION_COLLECTION_CLEAR";
    propertiesToClear?: string[];
}

export interface ICollectionClearActionCreator {
    (propertiesToClear?: string[]): ICollectionClearAction;
}


export interface ICollectionLockAction {
    type: "ACTION_COLLECTION_LOCK",
    id: number,
    lock: boolean
}

export interface ICollectionLockActionCreator {
    (id: number, lock: boolean): ICollectionLockAction
}

export interface ICollectionLockSuccessAction {
    type: "ACTION_COLLECTION_LOCK_SUCCESS";
    formData: Dtos.Collection,
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[],
    responseStatus: Dtos.ResponseStatus
}

export interface ICollectionLockSuccessActionCreator {
    (
        formData: Dtos.Collection,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ICollectionLockSuccessAction;
}

export interface ICollectionLockFailureAction {
    type: "ACTION_COLLECTION_LOCK_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ICollectionLockFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ICollectionLockFailureAction;
}

export type CollectionAction =
    ICollectionLoadByIdAction |
    ICollectionLoadFailureAction |
    ICollectionLoadSuccessAction |
    ICollectionCreateAction |
    ICollectionCreateSuccessAction |
    ICollectionCreateFailureAction |
    ICollectionClearAction |
    ICollectionLockAction |
    ICollectionLockSuccessAction |
    ICollectionLockFailureAction;
