import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_GROUPITEM_LOAD_BY_ID,

    ACTION_GROUPITEM_SAVE,

    ACTION_GROUPITEM_CREATE,

    IGroupItemLoadByIdAction,
    IGroupItemLoadFailureAction,
    IGroupItemLoadSuccessAction,

    IGroupItemSaveAction,
    IGroupItemSaveSuccessAction,
    IGroupItemSaveFailureAction,

    IGroupItemCreateAction,
    IGroupItemCreateSuccessAction,
    IGroupItemCreateFailureAction,

    LoadSuccess,
    LoadFailure,


    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,
} from "../actions/groupItem";
import { groupItemsApi } from "../services/groupItems";


export const
    getGroupItemsByIdEpic = (action$): Observable<IGroupItemLoadSuccessAction | IGroupItemLoadFailureAction> => {
        return action$
            .ofType(ACTION_GROUPITEM_LOAD_BY_ID)
            .mergeMap((action: IGroupItemLoadByIdAction) => {
                return groupItemsApi
                    .getGroupItemById(action.id)
                    .map(response =>
                        LoadSuccess(response.groupItem, response.formState, response.formProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setGroupItemEpic = (action$): Observable<IGroupItemSaveSuccessAction | IGroupItemSaveFailureAction> => {
        return action$
            .ofType(ACTION_GROUPITEM_SAVE)
            .mergeMap((action: IGroupItemSaveAction) => {
                return groupItemsApi
                    .setGroupItem(action.formData, action.collectionFormData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.groupItem, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createGroupItemEpic = (action$): Observable<IGroupItemCreateSuccessAction | IGroupItemCreateFailureAction> => {
        return action$
            .ofType(ACTION_GROUPITEM_CREATE)
            .mergeMap((action: IGroupItemCreateAction) => {
                return groupItemsApi
                    .createGroupItem(action.groupId)
                    .map(response =>
                        CreateSuccess(response.groupItem, response.formState, response.formProperties, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



