import * as React from 'react';
import './InstitutionFormPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";
import { IInstitutionFormReduxFormState, initialInstitutionFormState } from "../../../reducers/reactReduxForms/institutionForm";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, DatePickerFormInputFabric, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as InstitutionFormActions from '../../../actions/institutionForm';
import * as InstitutionActions from '../../../actions/institution';
import * as InstitutionFormsPageActions from '../../../actions/pages/institutionFormsPage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { InstitutionSubMenu } from '../../../helpers/subMenuHelper';
import * as FormHelper from '../../../helpers/formHelper';

import {
    CollectionInstancePage
} from "../../../containers/index";

interface IInstitutionFormPageParams {
    institutionId: number;
    institutionFormId: number;
}

interface IInstitutionFormPageProps {

    form: Dtos.InstitutionForm & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IInstitutionFormReduxFormState;

    location: Location;
    institutionId: number;
    institutionFormId: number;
    match: match<IInstitutionFormPageParams>;
    history: History;

    // modals
    modalTitle: string,
    modalDescription: string,
    editModalOpen: boolean,
    collectionInstanceId: number,
    createVersionModalOpen: boolean,

    // From state
    institutionForm: Dtos.InstitutionForm;
    institution: Dtos.Institution;
    lookups: Dtos.Lookup[];

    loadingInstitution: boolean;

    loadingInstitutionForm: boolean;
    loadInstitutionFormSuccess: boolean;
    loadInstitutionFormFailure: boolean;

    savingInstitutionForm: boolean;
    saveInstitutionFormSuccess: boolean;
    saveInstitutionFormFailure: boolean;

    creatingInstitutionForm: boolean;
    createInstitutionFormSuccess: boolean;
    createInstitutionFormFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface IInstitutionFormPageActions {
    clearInstitutionForm: InstitutionFormActions.IInstitutionFormClearActionCreator;
    loadInstitutionForm: InstitutionFormActions.IInstitutionFormLoadByIdActionCreator;
    saveInstitutionForm: InstitutionFormActions.IInstitutionFormSaveActionCreator;
    createInstitutionForm: InstitutionFormActions.IInstitutionFormCreateActionCreator;

    clearInstitution: InstitutionActions.IInstitutionClearActionCreator;
    loadInstitution: InstitutionActions.IInstitutionLoadByIdActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    setEditModelViewState: InstitutionFormsPageActions.IInstitutionFormsPageSetEditModalViewStateActionCreator;
    setCreateVersionModelViewState: InstitutionFormsPageActions.IInstitutionFormsPageSetCreateVersionModalViewStateActionCreator;

}

type InstitutionFormPageProps = IInstitutionFormPageProps & IInstitutionFormPageActions;

const formName: string = "institutionForm";
const reduxFormName: string = "reduxForms.institutionForm";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class InstitutionFormPage extends React.PureComponent<InstitutionFormPageProps, any> {

    constructor(props: InstitutionFormPageProps) {
        super(props);

        this.clearInstitutionForm = this.clearInstitutionForm.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadInstitutionForm,
            createInstitutionForm,
            navigate,
            navigateReplace,
            institutionFormId,
            institutionId,
            loadInstitution
        } = this.props;

        if (institutionFormId) {
            loadInstitutionForm(institutionFormId);
        } else if (!institutionFormId) {
            createInstitutionForm(institutionId);
        }
        loadInstitution(institutionId);
    }

    componentWillReceiveProps(nextProps: InstitutionFormPageProps) {
    }

    componentDidUpdate(prevProps: InstitutionFormPageProps, prevState) {
        const {
            form,
            institutionId,
            institutionFormId,
            institutionForm,
            navigateReplace,
            loadInstitutionForm,
            loadInstitution,
            loadingInstitutionForm,
            loadInstitutionFormSuccess,
            savingInstitutionForm,
            saveInstitutionFormSuccess,
            saveInstitutionFormFailure,
            createInstitutionForm,
            validationFailures,
            creatingInstitutionForm,
            createInstitutionFormSuccess,
            navigate
        } = this.props;

        if (institutionId && prevProps.institutionId != institutionId) {
            loadInstitution(institutionId);
        }

        if (institutionFormId && prevProps.institutionFormId != institutionFormId) {
            loadInstitutionForm(institutionFormId);
        }
        else if (!institutionFormId && prevProps.institutionFormId) {
            createInstitutionForm(institutionId);
        }

        if (!loadingInstitutionForm && prevProps.loadingInstitutionForm) {
            if (loadInstitutionFormSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingInstitutionForm && prevProps.savingInstitutionForm) {
            if (saveInstitutionFormSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/institutions/" + institutionId + "/forms")
                } else if (!form.saveAndReturn && institutionFormId && institutionFormId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !institutionFormId && institutionForm && institutionForm.id > 0) {
                    navigate("/institutions/" + institutionId + "/forms/" + institutionForm.id);
                    this.loadForm();
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingInstitutionForm && prevProps.creatingInstitutionForm) {
            if (createInstitutionFormSuccess) {
                this.loadForm();
            }
            else {

            }
        }
    }

    componentWillUpdate(nextProps: InstitutionFormPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearInstitutionForm();
        loadForm(reduxFormName, initialInstitutionFormState);
        resetForm(reduxFormName);
    }

    clearInstitutionForm() {
        this.props.clearInstitutionForm();
        this.props.clearInstitution();
    }

    showInstitutionForm(): boolean {
        const {
            loadingInstitution,
            loadingInstitutionForm,
            loadInstitutionFormSuccess
        } = this.props

        if (!loadingInstitution && !loadingInstitutionForm && loadInstitutionFormSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingInstitution,
            loadingInstitutionForm,
            institution,
            permissions
        } = this.props;

        let id = institution ? institution.id : 0;
        let name = institution ? institution.name : "Institution";

        return <RestrictedLayout
            permission={Dtos.Permission.InstitutionFormView}
            subMenuTitle={institution ? institution.display : ""}
            subMenuItems={InstitutionSubMenu(id, permissions)}
            loading={loadingInstitution || loadingInstitutionForm}
        >
            {this.renderContent()}
            {this.renderItemModal()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            permissions,
            savingInstitutionForm,
            institutionId
        } = this.props;

        let noPermissionToEditMessage = false;
        if ((form.id && form.id > 0) && validationFailures && validationFailures.filter(f => f.errorCode == "PR-005").length > 0) {
            noPermissionToEditMessage = true;
        }

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.InstitutionFormUpdate, Dtos.Permission.InstitutionFormCreate);
        const formDisabledOnEdit: boolean = !hasPermission || FormHelper.isEdit(formState, form);
        const formDisabledOnNew: boolean = !hasPermission || FormHelper.isNew(formState, form);
        const formDisabled: boolean = !hasPermission || savingInstitutionForm || (formState && formState.formStatus == Dtos.FormStatus.Disabled) || noPermissionToEditMessage;
        const canDataEdit: boolean = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.InstitutionFormDataEntryUpdate, null, institutionId);
        

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            formTemplateId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabledOnEdit,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("InstitutionForm", "FormTemplateId", lookups), form.formTemplateId)
                }
            },
            formStatusId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("InstitutionForm", "FormStatusId", lookups), form.formStatusId)
                }
            },
            assignedToId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("InstitutionForm", "AssignedToId", lookups), form.assignedToId)
                }
            },
            version: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled || formDisabledOnEdit,
            },
            formNextAutoVersionId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("InstitutionForm", "FormNextAutoVersionId", lookups), form.formNextAutoVersionId)
                }
            },
            dateNextVersion: {
                type: RegistrationComponent.RegistrationControl,
                inputType: DatePickerFormInputFabric,
                validationMessageAbove: true,
                inputDisabled: formDisabled
            },
        };


        return <div>
            <div className="row">
                <div className={"col"}>
                    <h3>Institution Form</h3>
                </div>
                <div className={"col-auto"}>
                    {canDataEdit && (
                        <Link className={"btn btn-secondary"} url={"/institutions/" + form.institutionId + '/forms/' + form.id + '/entry'}>Enter Data</Link>
                    )}
                </div>
            </div>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "institutionForm", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                {(formDisabledOnNew) && this.renderVersionsTable()}

                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
        </div>

    }

    renderVersionsTable() {
        const {
            form,
            setEditModelViewState,
            navigate,
            permissions,
            institutionId
        } = this.props

        if (!form || !form.versions || form.versions.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no old versions</span>
            </div>
        }
        const canDataEdit: boolean = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.InstitutionFormDataEntryUpdate, null, institutionId);

        const showPagination = false;

        return <>
            <div className="border-0 d-flex pt-4 ">
                <h3>Versions</h3>
            </div>
            <div className="table-wrapper mb-3">
                <ReactTable
                    data={form.versions}
                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                    defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : form.versions.length}
                    pageSize={showPagination ? undefined : form.versions.length}
                    showPagination={showPagination}
                    className="-striped -highlight -clickable mb-3"
                    noDataText="There are currently no versions to display"
                    resizable={false}
                    getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                        return {
                            onClick: (e, handleOriginal) => {

                            }
                        }
                    }}
                    columns={[
                        {
                            id: "id",
                            Header: ' ',
                            accessor: "id",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 50,
                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Regular.CLOCK} fixedWidth />
                        },
                        {
                            id: "assignedToId",
                            Header: 'Assigned To',
                            accessor: 'assignedTo.display',
                            className: "d-flex align-items-center justify-content-center"
                        },
                        {
                            id: "formStatusId",
                            Header: 'Status',
                            accessor: 'formStatus.name',
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                        },
                        {
                            id: "dateModified",
                            Header: 'Last Modified',
                            accessor: 'dateModified',
                            className: "d-flex align-items-center justify-content-center",
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToDateTimeString(props.value)}</span><ReactTooltip /></div>,
                            maxWidth: 180,
                        },
                        {
                            id: "version",
                            Header: 'Version',
                            accessor: 'version',
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 190,
                        },
                        {
                            id: "create",
                            Header: "",
                            className: "d-flex align-items-right",
                            maxWidth: 150,
                            style: { textAlign: 'right' },
                            Cell: row => (
                                <div>
                                    <button disabled={!canDataEdit}
                                        onClick={
                                            (e) => {
                                                e.stopPropagation();
                                                var url = "/institutions/" + row.original.institutionId + '/forms/' + row.original.id + '/entry';
                                                navigate(url);
                                                //console.log(row)
                                                //setEditModelViewState(true, row.original.collectionInstanceId, row.original.formTemplate.name + " [" + row.original.version + "]", row.original.formTemplate.description)
                                            }
                                        }
                                        type="button"
                                        className="btn btn-outline-secondary mr-2 btn-sm btn-row-version"
                                    >
                                        <span>Open Form</span>
                                    </button>
                                    <button disabled={false}
                                        onClick={
                                            (e) => {
                                                e.stopPropagation();
                                                console.log(row)
                                                const url: string = "/institutions/" + row.original.institutionId + "/forms/" + row.original.id;
                                                this.props.navigate(url);
                                            }
                                        }
                                        type="button"
                                        className="btn btn-outline-secondary mr-2 btn-sm btn-row-version"
                                    >
                                        <span>Edit</span>
                                    </button>
                                </div>
                            )
                        }
                    ]}
                />
            </div>
        </>
    }

    renderItemModal() {
        const {
            editModalOpen,
            setEditModelViewState,
            collectionInstanceId,
            loadInstitutionForm,
            institutionFormId,
            institutionId,
            modalTitle,
            modalDescription,
            permissions
        } = this.props

        const canDataEdit: boolean = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.InstitutionFormDataEntryUpdate, null, institutionId);

        if (collectionInstanceId && collectionInstanceId > 0) {
            return <Modal
                open={editModalOpen}
                disableCloseOnOverlayClick={true}
                onClose={() => {
                    setEditModelViewState(false, null, "", "");
                    loadInstitutionForm(institutionFormId);
                }}
                size={ModalSize.Xl}>
                <div className="modal-header">
                    <h5 className="modal-title">{setModalTitle(undefined, modalTitle)}{modalDescription ? <><br /><small style={{ color: '#444' }}>{modalDescription}</small></> : undefined}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => {
                        setEditModelViewState(false, null, "", "");
                        loadInstitutionForm(institutionFormId);
                    }}>
                        <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                    </button>

                </div>
                <div className="modal-body">
                    <CollectionInstancePage
                        readonly={!canDataEdit}
                        collectionInstanceId={collectionInstanceId}
                        onClose={() => {
                            setEditModelViewState(false, null, "", "");
                            loadInstitutionForm(institutionFormId);
                        }} />
                </div>
            </Modal>
        }
    }


    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
        </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.InstitutionForm) {
        const {
            saveInstitutionForm
        } = this.props;

        if (data) {
            let changes: Dtos.InstitutionForm = {} as Dtos.InstitutionForm;
            saveInstitutionForm(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.Institution) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            institutionId,
            navigate
        } = this.props;

        navigate("/institutions/" + institutionId + "/forms");
    }

    loadForm() {
        const {
            loadForm,
            institutionForm,
        } = this.props

        let extraData: Dtos.InstitutionForm = {} as Dtos.InstitutionForm;

        loadForm(reduxFormName, Object.assign({ ...institutionForm }, extraData));
    }
}


const mapStateToProps = (state: ITmdState, ownProps: InstitutionFormPageProps): IInstitutionFormPageProps => {

    let institutionForm: Dtos.InstitutionForm | undefined = !(state.institutionForm.formData instanceof Array) ? state.institutionForm.formData : undefined;
    let institution: Dtos.Institution | undefined = !(state.institutions.formData instanceof Array) ? state.institutions.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        institutionId: ownProps.match ? ownProps.match.params.institutionId : undefined!,
        institutionFormId: ownProps.match && ownProps.match.params.institutionFormId.toString() != "create" ? ownProps.match.params.institutionFormId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        institutionForm: institutionForm!,

        institution: institution!,

        modalTitle: state.institutionFormsPage.name,
        modalDescription: state.institutionFormsPage.description,

        editModalOpen: state.institutionFormsPage.editModalOpen,
        collectionInstanceId: state.institutionFormsPage.collectionInstanceId,

        createVersionModalOpen: state.institutionFormsPage.createVersionModalOpen,


        loadingInstitutionForm: state.institutionForm.loadState && state.institutionForm.loadState.status == RequestState.Pending,
        loadInstitutionFormSuccess: state.institutionForm.loadState && state.institutionForm.loadState.status == RequestState.Success,
        loadInstitutionFormFailure: state.institutionForm.loadState && state.institutionForm.loadState.status == RequestState.Failure,

        savingInstitutionForm: state.institutionForm.saveState && state.institutionForm.saveState.status == RequestState.Pending,
        saveInstitutionFormSuccess: state.institutionForm.saveState && state.institutionForm.saveState.status == RequestState.Success,
        saveInstitutionFormFailure: state.institutionForm.saveState && state.institutionForm.saveState.status == RequestState.Failure,

        creatingInstitutionForm: state.institutionForm.createState && state.institutionForm.createState.status == RequestState.Pending,
        createInstitutionFormSuccess: state.institutionForm.createState && state.institutionForm.createState.status == RequestState.Success,
        createInstitutionFormFailure: state.institutionForm.createState && state.institutionForm.createState.status == RequestState.Failure,

        loadingInstitution: state.institutions.loadState && state.institutions.loadState.status == RequestState.Pending,

        user: state.user.data,
        permissions: permissions,
        lookups: state.institutionForm.lookups,

        form: state.reduxForms.institutionForm,
        formState: state.institutionForm.formState,
        formProperties: state.institutionForm.formProperties,

        reduxFormState: state.reduxForms.formState.institutionForm,
        validationFailures: institutionForm ?
            state.institutionForm.validationFailures :
            undefined,

    };
};

const mapDispatchToProps = (dispatch): IInstitutionFormPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadInstitutionForm: bindActionCreators(InstitutionFormActions.LoadInstitutionFormById, dispatch),
        clearInstitutionForm: bindActionCreators(InstitutionFormActions.Clear, dispatch),
        createInstitutionForm: bindActionCreators(InstitutionFormActions.CreateInstitutionForm, dispatch),
        saveInstitutionForm: bindActionCreators(InstitutionFormActions.SaveInstitutionForm, dispatch),

        loadInstitution: bindActionCreators(InstitutionActions.LoadInstitutionById, dispatch),
        clearInstitution: bindActionCreators(InstitutionActions.Clear, dispatch),

        setEditModelViewState: bindActionCreators(InstitutionFormsPageActions.setEditModalViewState, dispatch),
        setCreateVersionModelViewState: bindActionCreators(InstitutionFormsPageActions.setCreateVersionModalViewState, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(InstitutionFormPage);
