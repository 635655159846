import * as React from 'react';

import './TrialCategoryPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { ITrialCategoryReduxFormState, initialTrialCategoryState } from "../../../reducers/reactReduxForms/trialCategory";
import { RegistrationFormComponent } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, NumberFormInput, CheckboxFormInput } from "../../../components/form/index";
import * as TrialCategoryActions from '../../../actions/trialCategorys';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { NotificationAlert } from "../../../components/common/index";
import { Form, actions } from 'react-redux-form';
import { TrialLookupsLayout } from '../../index';
import * as AuthenticationHelper from '../../../helpers/authentication';

interface ITrialCategoryPageParams {
    trialCategoryId: number;
}

interface ITrialCategoryPageProps {

    form: Dtos.TrialCategory & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITrialCategoryReduxFormState;

    location: Location;
    trialCategoryId: number;
    match: match<ITrialCategoryPageParams>;
    history: History;

    // From state
    trialCategory: Dtos.TrialCategory;
    lookups: Dtos.Lookup[];

    loadingTrialCategory: boolean;
    loadTrialCategorySuccess: boolean;
    loadTrialCategoryFailure: boolean;

    savingTrialCategory: boolean;
    saveTrialCategorySuccess: boolean;
    saveTrialCategoryFailure: boolean;

    creatingTrialCategory: boolean;
    createTrialCategorySuccess: boolean;
    createTrialCategoryFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface ITrialCategoryPageActions {
    clearTrialCategory: TrialCategoryActions.ITrialCategoryClearActionCreator;
    loadTrialCategory: TrialCategoryActions.ITrialCategoryLoadByIdActionCreator;

    saveTrialCategory: TrialCategoryActions.ITrialCategorySaveActionCreator;
    createTrialCategory: TrialCategoryActions.ITrialCategoryCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type TrialCategoryPageProps = ITrialCategoryPageProps & ITrialCategoryPageActions;

const reduxFormName: string = "reduxForms.trialCategory";

class TrialCategoryPage extends React.PureComponent<TrialCategoryPageProps, any> {

    constructor(props: TrialCategoryPageProps) {
        super(props);

        this.clearTrialCategory = this.clearTrialCategory.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTrialCategory,
            trialCategoryId,
            createTrialCategory
        } = this.props;

        if (trialCategoryId) {
            loadTrialCategory(trialCategoryId);
        } else if (!trialCategoryId) {
            createTrialCategory();
        }
    }

    componentWillReceiveProps(nextProps: TrialCategoryPageProps) {
    }

    componentDidUpdate(prevProps: TrialCategoryPageProps, prevState) {
        const {
            trialCategoryId,
            trialCategory,
            form,
            navigate,
            loadTrialCategory,
            loadingTrialCategory,
            loadTrialCategorySuccess,
            createTrialCategory,
            creatingTrialCategory,
            createTrialCategorySuccess,
            savingTrialCategory,
            saveTrialCategorySuccess,
            validationFailures
        } = this.props;

        if (trialCategoryId && prevProps.trialCategoryId != trialCategoryId) {
            loadTrialCategory(trialCategoryId);
        }
        else if (!trialCategoryId && prevProps.trialCategoryId) {
            createTrialCategory();
        }

        if (!loadingTrialCategory && prevProps.loadingTrialCategory) {
            if (loadTrialCategorySuccess) {
                this.loadForm();
            }
        }

        if (!savingTrialCategory && prevProps.savingTrialCategory) {
            if (saveTrialCategorySuccess) {
                if (validationFailures && validationFailures.length > 0) {
                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {
                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);
                }
                if (form.saveAndReturn) {
                    navigate("/settings/trial/trialcategorys")
                } else if (!form.saveAndReturn && trialCategoryId && trialCategoryId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !trialCategoryId && trialCategory && trialCategory.id > 0) {
                    navigate("/settings/trial/trialcategorys/" + trialCategory.id);
                }
            }
            else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

        if (!creatingTrialCategory && prevProps.creatingTrialCategory) {
            if (createTrialCategorySuccess) {
                this.loadForm();
            }
        }
    }

    componentWillUpdate(nextProps: TrialCategoryPageProps) {
    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearTrialCategory();
        loadForm(reduxFormName, initialTrialCategoryState);
        resetForm(reduxFormName);
    }

    clearTrialCategory() {
        this.props.clearTrialCategory();
    }

    showTrialCategory(): boolean {
        const {
            loadingTrialCategory,
            loadTrialCategorySuccess
        } = this.props

        if (!loadingTrialCategory && loadTrialCategorySuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrialCategory
        } = this.props;

        return <TrialLookupsLayout loading={loadingTrialCategory} permission={Dtos.Permission.TrialCategoriesView}>
            {this.renderContent()}
        </TrialLookupsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingTrialCategory,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.TrialCategoriesUpdate, Dtos.Permission.TrialCategoriesCreate);
        const formDisabled: boolean = !hasPermission || savingTrialCategory || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            value: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            validationFormat: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            validationMessage: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            }
        };

        return <div>
            <h1>Trial Category</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "trialCategory", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.TrialCategory) {
        const {
            saveTrialCategory
        } = this.props;

        if (data) {
            let changes: Dtos.TrialCategory = {} as Dtos.TrialCategory;
            saveTrialCategory(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.TrialCategory) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/trial/trialcategorys");
    }

    loadForm() {
        const {
            loadForm,
            trialCategory,
        } = this.props

        let extraData: Dtos.TrialCategory = {} as Dtos.TrialCategory;

        loadForm(reduxFormName, Object.assign({ ...trialCategory }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: TrialCategoryPageProps): ITrialCategoryPageProps => {

    let trialCategory: Dtos.TrialCategory | undefined = !(state.trialCategorys.formData instanceof Array) ? state.trialCategorys.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialCategoryId: ownProps.match && ownProps.match.params.trialCategoryId && ownProps.match.params.trialCategoryId.toString() != "create" ? ownProps.match.params.trialCategoryId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trialCategory: trialCategory!,

        loadingTrialCategory: state.trialCategorys.loadState && state.trialCategorys.loadState.status == RequestState.Pending,
        loadTrialCategorySuccess: state.trialCategorys.loadState && state.trialCategorys.loadState.status == RequestState.Success,
        loadTrialCategoryFailure: state.trialCategorys.loadState && state.trialCategorys.loadState.status == RequestState.Failure,

        savingTrialCategory: state.trialCategorys.saveState && state.trialCategorys.saveState.status == RequestState.Pending,
        saveTrialCategorySuccess: state.trialCategorys.saveState && state.trialCategorys.saveState.status == RequestState.Success,
        saveTrialCategoryFailure: state.trialCategorys.saveState && state.trialCategorys.saveState.status == RequestState.Failure,

        creatingTrialCategory: state.trialCategorys.createState && state.trialCategorys.createState.status == RequestState.Pending,
        createTrialCategorySuccess: state.trialCategorys.createState && state.trialCategorys.createState.status == RequestState.Success,
        createTrialCategoryFailure: state.trialCategorys.createState && state.trialCategorys.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: [],

        form: state.reduxForms.trialCategory,
        formState: state.trialCategorys.formState,
        formProperties: state.trialCategorys.formProperties,

        reduxFormState: state.reduxForms.formState.trialCategory,
        validationFailures: trialCategory ?
            state.trialCategorys.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): ITrialCategoryPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadTrialCategory: bindActionCreators(TrialCategoryActions.LoadTrialCategoryById, dispatch),
        clearTrialCategory: bindActionCreators(TrialCategoryActions.Clear, dispatch),

        saveTrialCategory: bindActionCreators(TrialCategoryActions.SaveTrialCategory, dispatch),
        createTrialCategory: bindActionCreators(TrialCategoryActions.CreateTrialCategory, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialCategoryPage);
