import * as React from 'react';
import './TrialFormsPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu, TruncatedCell } from "../../../components/layouts/index";
import * as TrialActions from '../../../actions/trial';
import * as TrialFormActions from '../../../actions/trialForm';
import * as TrialFormsPageActions from '../../../actions/pages/trialFormsPage';
import * as TrialsPageActions from '../../../actions/pages/trialsPage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { ModalSize } from '../../../enumerations/ModalSize';
import { TrialLayout } from '../../index';
import { Form, actions, Control } from 'react-redux-form';

import {
    TrialFormCreateVersion,
    TrialEmailReminderSettings
} from "../../../containers/index";

interface ITrialFormsPageParams {
    trialId: number;
}

interface ITrialFormsPageProps {

    location: Location;
    trialId: number;
    match: match<ITrialFormsPageParams>;
    history: History;

    // modals
    trialFormId: number;
    modalTitle: string,
    modalDescription: string,
    editModalOpen: boolean,
    collectionInstanceId: number,
    createVersionModalOpen: boolean,
    emailReminderSettingsModalOpen: boolean,

    // From state
    trial: Dtos.Trial;
    trialForms: Dtos.TrialForm[],

    loadingTrial: boolean;
    loadTrialSuccess: boolean;
    loadTrialFailure: boolean;

    loadingTrialForm: boolean;
    loadTrialFormSuccess: boolean;
    loadTrialFormFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    lookups: Dtos.Lookup[];
    formRoles: Dtos.RoleType[];
}

interface ITrialFormsPageActions {
    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    clearTrialForms: TrialFormActions.ITrialFormClearActionCreator;
    loadTrialForms: TrialFormActions.ITrialFormLoadActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    setEditModelViewState: TrialFormsPageActions.ITrialFormsPageSetEditModalViewStateActionCreator;
    setCreateVersionModelViewState: TrialFormsPageActions.ITrialFormsPageSetCreateVersionModalViewStateActionCreator;
    setReminderEmailSettingsModelViewState: TrialsPageActions.ITrialFormsPageSetReminderEmailSettingsModalViewStateActionCreator;

    loadForm?: typeof actions.load;
    changeForm?: typeof actions.change;

}

type TrialFormsPageProps = ITrialFormsPageProps & ITrialFormsPageActions;
const reduxFormName: string = "reduxForms.trial";

class TrialFormsPage extends React.PureComponent<TrialFormsPageProps, any> {

    constructor(props: TrialFormsPageProps) {
        super(props);

        this.clearTrialForm = this.clearTrialForm.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTrial,
            loadTrialForms,
            trialId
        } = this.props;

        loadTrial(trialId);
        loadTrialForms(trialId, 0);
    }

    componentDidUpdate(prevProps: TrialFormsPageProps, prevState) {
        const {
            trialId,
            loadTrial,
            loadingTrial,
            loadTrialSuccess,
        } = this.props;

        if (trialId && prevProps.trialId != trialId) {
            loadTrial(trialId);
        }

        if (!loadingTrial && prevProps.loadingTrial) {
            if (loadTrialSuccess) {
                this.loadForm();
            }
            else {

            }
        }

    }


    componentWillUnmount() {
        this.clearTrialForm();
    }

    clearTrialForm() {
        this.props.clearTrial();
        this.props.clearTrialForms();
    }

    show(): boolean {
        const {
            loadingTrial,
            loadTrialSuccess,
            loadingTrialForm,
            loadTrialFormSuccess
        } = this.props

        if (!loadingTrial && loadTrialSuccess &&
            !loadingTrialForm && loadTrialFormSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrial,
            loadingTrialForm
        } = this.props;

        return <TrialLayout loading={loadingTrial || loadingTrialForm} permission={Dtos.Permission.TrialFormView}> 
            {this.renderContent()}
            {this.renderCreateVersionModal()}
            {this.renderEmailReminderSettingsModal()}
            </TrialLayout>;
    }

    renderContent() {
        const {
            trial,
            trialId,
            permissions,
            setReminderEmailSettingsModelViewState,
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col mb-2"}>
                    <h2>Trial Forms</h2>
                </div>
                <div className="col-auto mr-0 pr-0" >
                    {(AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialUpdate, trialId) && trial && trial.id > 0) && (
                        <button className={"btn btn-secondary"} type="button" onClick={ () => setReminderEmailSettingsModelViewState(true, trialId)} >Settings</button>
                    )}
                </div>
                <div className="col-auto">
                    {(AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialFormCreate, trialId) &&  trial && trial.id > 0) && (
                        <Link className={"btn btn-primary"} url={"/trials/" + trial.id + '/forms/create'}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.show() ?
                    "Loading Trial Forms" :
                    null
            }
            {
                this.show() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {
        const {
            trialForms,
            setCreateVersionModelViewState,
            permissions,
            trialId,
            navigate
        } = this.props

        if (!trialForms || trialForms.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no trial forms</span>
            </div>
        }

        const showPagination = trialForms.length > DEFAULT_PAGE_SIZE;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={trialForms}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : trialForms.length}
                pageSize={showPagination ? undefined : trialForms.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no trial groups to display"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            const url: string = "/trials/" + rowInfo.original.trialId + "/forms/" + rowInfo.original.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.TRIALITEM} fixedWidth />
                    },
                    {
                        id: "patientId",
                        Header: 'Patient',
                        accessor: 'patient.registrationNumber',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 120,
                    },
                    {
                        id: "institutionId",
                        Header: 'Institution',
                        accessor: 'institution.name',
                        className: "d-flex align-items-center justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value} />
                    },
                    {
                        id: "name",
                        Header: 'Form Template',
                        accessor: 'formTemplate.name',
                        className: "d-flex align-items-center justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value} />,
                        maxWidth: 180,
                    },
                    {
                        id: "assignedDisplay",
                        Header: 'Assigned To',
                        accessor: 'assignedDisplay',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 180,
                        Cell: (props) => <TruncatedCell value={props.value} />,
                    },
                    {
                        id: "formStatusId",
                        Header: 'Status',
                        accessor: 'formStatus.value',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 120,
                    },
                    {
                        id: "version",
                        Header: 'Version',
                        accessor: 'version',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 120,
                    },
                    {
                        id: "create",
                        Header: "",
                        className: "d-flex align-items-right",
                        maxWidth: 210,
                        style: { textAlign: 'right' },
                        Cell: row => (
                            <div>
                                {AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialFormCreate, trialId, row.original.institutionId) && (
                                    <button disabled={false}
                                        onClick={
                                            (e) => {
                                                e.stopPropagation();
                                                var url = "/trials/" + trialId + '/forms/' + row.original.id + '/entry';
                                                navigate(url);
                                            }
                                        }
                                        type="button"
                                        className="btn btn-outline-secondary mr-2 btn-sm btn-row-version"
                                    >
                                        <span>Enter data</span>
                                    </button>
                                )}
                                {AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialFormCreate, trialId, row.original.institutionId) && (
                                    <button disabled={false}
                                        onClick={
                                            (e) => {
                                                e.stopPropagation();
                                                console.log(row)
                                                setCreateVersionModelViewState(true, row.original.id, null, false)
                                            }
                                        }
                                        type="button"
                                        className="btn btn-outline-secondary mr-2 btn-sm btn-row-version"
                                    >
                                        <span>New version</span>
                                    </button>
                                )}
                            </div>
                        )
                    }
                ]}
            />
        </div>

    }

    renderCreateVersionModal() {
        const {
            loadTrialForms,
            trialId,
            createVersionModalOpen,
            setCreateVersionModelViewState,
        } = this.props

        return <Modal open={createVersionModalOpen}
            disableCloseOnOverlayClick={true}
            onClose={() => { setCreateVersionModelViewState(false, null, null, false); loadTrialForms(trialId, 0); }}
            size={ModalSize.Md}>
            <div className="modal-header">
                <h5 className="modal-title">{setModalTitle(undefined, "Create Version")}</h5>
                <button type="button" className="close" aria-label="Close" onClick={() => { setCreateVersionModelViewState(false, null, null, false); }}>
                    <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                </button>
            </div>
            <div className="modal-body" style={{ paddingTop: 0, paddingBottom: 0 }}>
                <TrialFormCreateVersion
                    onClose={() => {
                        setCreateVersionModelViewState(false, null, null, false);
                        loadTrialForms(trialId, 0);
                    }} />
            </div>
        </Modal>
    }

    renderEmailReminderSettingsModal() {
        const {
            loadTrial,
            trialId,
            emailReminderSettingsModalOpen,
            setReminderEmailSettingsModelViewState,
        } = this.props

        return <Modal open={emailReminderSettingsModalOpen}
            disableCloseOnOverlayClick={true}
            onClose={() => { setReminderEmailSettingsModelViewState(false, null); loadTrial(trialId); }}
            size={ModalSize.Md}>
            <div className="modal-header">
                <h5 className="modal-title">{setModalTitle(undefined, "Reminding Email Settings")}</h5>
                <button type="button" className="close" aria-label="Close" onClick={() => { setReminderEmailSettingsModelViewState(false, null); }}>
                    <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                </button>
            </div>
            <div className="modal-body" style={{ paddingTop: 0, paddingBottom: 0 }}>
                <TrialEmailReminderSettings
                    onClose={() => {
                        setReminderEmailSettingsModelViewState(false, null);
                        loadTrial(trialId);
                    }} />
            </div>
        </Modal>
    }

    loadForm() {
        const {
            loadForm,
            trial,
        } = this.props

        let extraData: Dtos.Trial = {} as Dtos.Trial;

        loadForm(reduxFormName, Object.assign({ ...trial }, extraData));
    }
}

const mapStateToProps = (state: ITmdState, ownProps: TrialFormsPageProps): ITrialFormsPageProps => {

    let trial: Dtos.Trial | undefined = !(state.trials.formData instanceof Array) ? state.trials.formData : undefined;
    let trialForms: Dtos.TrialForm[] | undefined = (state.trialForm.formData instanceof Array) ? state.trialForm.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        location: state.routing.location,
        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,
        match: ownProps.match,
        history: ownProps.history,

        trial: trial!,
        trialForms: trialForms!,
        lookups: state.trials.lookups,

        trialFormId: state.trialFormsPage.trialFormId,
        modalTitle: state.trialFormsPage.name,
        modalDescription: state.trialFormsPage.description,

        editModalOpen: state.trialFormsPage.editModalOpen,
        collectionInstanceId: state.trialFormsPage.collectionInstanceId,

        createVersionModalOpen: state.trialFormsPage.createVersionModalOpen,

        emailReminderSettingsModalOpen: state.trialsPage.emailReminderSettingsModalOpen,

        loadingTrial: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,
        loadTrialSuccess: state.trials.loadState && state.trials.loadState.status == RequestState.Success,
        loadTrialFailure: state.trials.loadState && state.trials.loadState.status == RequestState.Failure,

        loadingTrialForm: state.trialForm.loadState && state.trialForm.loadState.status == RequestState.Pending,
        loadTrialFormSuccess: state.trialForm.loadState && state.trialForm.loadState.status == RequestState.Success,
        loadTrialFormFailure: state.trialForm.loadState && state.trialForm.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles,
    };
};

const mapDispatchToProps = (dispatch): ITrialFormsPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch),

        loadTrialForms: bindActionCreators(TrialFormActions.LoadTrialForm, dispatch),
        clearTrialForms: bindActionCreators(TrialFormActions.Clear, dispatch),

        setEditModelViewState: bindActionCreators(TrialFormsPageActions.setEditModalViewState, dispatch),
        setCreateVersionModelViewState: bindActionCreators(TrialFormsPageActions.setCreateVersionModalViewState, dispatch),
        setReminderEmailSettingsModelViewState: bindActionCreators(TrialsPageActions.setReminderEmailSettingsModalViewState, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialFormsPage);
