import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadCountry: ICountryLoadActionCreator = () => {
        return {
            type: ACTION_COUNTRIES_LOAD
        }
    },
    LoadCountryById: ICountryLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_COUNTRIES_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ICountryLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_COUNTRIES_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ICountryLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_COUNTRIES_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveCountry: ICountrySaveActionCreator = (formData) => {
        return {
            type: ACTION_COUNTRIES_SAVE,
            formData
        }
    },
    SaveSuccess: ICountrySaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_COUNTRIES_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ICountrySaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_COUNTRIES_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateCountry: ICountryCreateActionCreator = () => {

        return {
            type: ACTION_COUNTRIES_CREATE
        }
    },
    CreateSuccess: ICountryCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_COUNTRIES_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: ICountryCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_COUNTRIES_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: ICountryClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_COUNTRIES_CLEAR,
            propertiesToClear
        }
    },

    Filter: ICountryFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_COUNTRIES_FILTER,
            formFilter
        }
    };



export const
    ACTION_COUNTRIES_LOAD = "ACTION_COUNTRIES_LOAD",
    ACTION_COUNTRIES_LOAD_BY_ID = "ACTION_COUNTRIES_LOAD_BY_ID",
    ACTION_COUNTRIES_LOAD_SUCCESS = "ACTION_COUNTRIES_LOAD_SUCCESS",
    ACTION_COUNTRIES_LOAD_FAILURE = "ACTION_COUNTRIES_LOAD_FAILURE",

    ACTION_COUNTRIES_SAVE = "ACTION_COUNTRIES_SAVE",
    ACTION_COUNTRIES_SAVE_SUCCESS = "ACTION_COUNTRIES_SAVE_SUCCESS",
    ACTION_COUNTRIES_SAVE_FAILURE = "ACTION_COUNTRIES_SAVE_FAILURE",

    ACTION_COUNTRIES_CREATE = "ACTION_COUNTRIES_CREATE",
    ACTION_COUNTRIES_CREATE_SUCCESS = "ACTION_COUNTRIES_CREATE_SUCCESS",
    ACTION_COUNTRIES_CREATE_FAILURE = "ACTION_COUNTRIES_CREATE_FAILURE",

    ACTION_COUNTRIES_CLEAR = "ACTION_COUNTRIES_CLEAR",

    ACTION_COUNTRIES_FILTER = "ACTION_COUNTRIES_FILTER";

export interface ICountryLoadAction {
    type: "ACTION_COUNTRIES_LOAD";
}

export interface ICountryLoadActionCreator {
    (): ICountryLoadAction;
}

export interface ICountryLoadSuccessAction {
    type: "ACTION_COUNTRIES_LOAD_SUCCESS";
    formData: Dtos.Country | Dtos.Country[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ICountryLoadSuccessActionCreator {
    (
        formData: Dtos.Country | Dtos.Country[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ICountryLoadSuccessAction;
}

export interface ICountryLoadFailureAction {
    type: "ACTION_COUNTRIES_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ICountryLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ICountryLoadFailureAction;
}

export interface ICountryLoadByIdAction {
    type: "ACTION_COUNTRIES_LOAD_BY_ID";
    id: number;
}

export interface ICountryLoadByIdActionCreator {
    (id: number): ICountryLoadByIdAction;
}


export interface ICountrySaveAction {
    type: "ACTION_COUNTRIES_SAVE";
    formData: Dtos.Country;

}

export interface ICountrySaveActionCreator {
    (
        formData: Dtos.Country
    ): ICountrySaveAction;
}

export interface ICountrySaveSuccessAction {
    type: "ACTION_COUNTRIES_SAVE_SUCCESS";
    formData: Dtos.Country;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ICountrySaveSuccessActionCreator {
    (
        formData: Dtos.Country,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ICountrySaveSuccessAction;
}

export interface ICountrySaveFailureAction {
    type: "ACTION_COUNTRIES_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ICountrySaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ICountrySaveFailureAction;
}

export interface ICountryCreateAction {
    type: "ACTION_COUNTRIES_CREATE"
}

export interface ICountryCreateActionCreator {
    (): ICountryCreateAction
}

export interface ICountryCreateSuccessAction {
    type: "ACTION_COUNTRIES_CREATE_SUCCESS";
    formData: Dtos.Country;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface ICountryCreateSuccessActionCreator {
    (
        formData: Dtos.Country,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): ICountryCreateSuccessAction;
}

export interface ICountryCreateFailureAction {
    type: "ACTION_COUNTRIES_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ICountryCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ICountryCreateFailureAction;
}

export interface ICountryClearAction {
    type: "ACTION_COUNTRIES_CLEAR";
    propertiesToClear?: string[];
}

export interface ICountryClearActionCreator {
    (propertiesToClear?: string[]): ICountryClearAction;
}

export interface ICountryFilterActionCreator {
    (formFilter: IFormFilter): ICountryFilterAction;
}

export interface ICountryFilterAction {
    type: "ACTION_COUNTRIES_FILTER";
    formFilter: IFormFilter;
}

export type CountryAction =
    ICountryLoadAction |
    ICountryLoadByIdAction |
    ICountryLoadFailureAction |
    ICountryLoadSuccessAction |
    ICountrySaveAction |
    ICountrySaveSuccessAction |
    ICountrySaveFailureAction |
    ICountryCreateAction |
    ICountryCreateSuccessAction |
    ICountryCreateFailureAction |
    ICountryFilterAction | 
    ICountryClearAction;
