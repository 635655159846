import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_HUBSPOT_LOAD_BY_ID,
    ACTION_HUBSPOT_LOAD_CONTACTS_TO_EXPORT,
    ACTION_HUBSPOT_EXPORT_CONTACTS,
    ACTION_HUBSPOT_FIND_CONTACT,

    IHubspotLoadByIdAction,
    IHubspotLoadFailureAction,
    IHubspotLoadSuccessAction,
    IHubspotLoadContactsToExportAction,
    IHubspotExportContactsAction,
    IHubspotExportContactsSuccessAction,
    IHubspotExportContactsFailureAction,
    IHubspotFindContactAction,
    IHubspotFindContactSuccessAction,
    IHubspotFindContactFailureAction,

    LoadSuccess,
    LoadFailure,
    HubspotExportContactsSuccess,
    HubspotExportContactsFailure,
    HubspotFindContactSuccess,
    HubspotFindContactFailure

} from "../actions/hubspot";
import { hubspotApi } from "../services/hubspot";


export const
    getHubspotByIdEpic = (action$): Observable<IHubspotLoadSuccessAction | IHubspotLoadFailureAction> => {
        return action$
            .ofType(ACTION_HUBSPOT_LOAD_BY_ID)
            .mergeMap((action: IHubspotLoadByIdAction) => {
                return hubspotApi
                    .getHubspotById(action.id)
                    .map(response =>
                        LoadSuccess(response.hubspot, response.formState, response.formProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getHubspotContactsToExportEpic = (action$): Observable<IHubspotLoadSuccessAction | IHubspotLoadFailureAction> => {
        return action$
            .ofType(ACTION_HUBSPOT_LOAD_CONTACTS_TO_EXPORT)
            .mergeMap((action: IHubspotLoadContactsToExportAction) => {
                return hubspotApi
                    .getHubspotContactsToExport()
                    .map(response =>
                        LoadSuccess(response.contacts, null, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    postHubspotContactsToExportEpic = (action$): Observable<IHubspotExportContactsSuccessAction | IHubspotExportContactsFailureAction> => {
        return action$
            .ofType(ACTION_HUBSPOT_EXPORT_CONTACTS)
            .mergeMap((action: IHubspotExportContactsAction) => {
                return hubspotApi
                    .postHubspotContactExport(action.username)
                    .map(response =>
                        HubspotExportContactsSuccess(response.contacts, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(HubspotExportContactsFailure(error.responseStatus))
                    )
            });
    },
    findHubspotContactEpic = (action$): Observable<IHubspotFindContactSuccessAction | IHubspotFindContactFailureAction> => {
        return action$
            .ofType(ACTION_HUBSPOT_FIND_CONTACT)
            .mergeMap((action: IHubspotFindContactAction) => {
                return hubspotApi
                    .findHubspotContact(action.email, action.action, action.newEmail, action.emailUpdateOptions)
                    .map(response =>
                        HubspotFindContactSuccess(response.existing, response.replacement, response.emailUpdateOptions, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(HubspotFindContactFailure(error.responseStatus))
                    )
            });
    };



