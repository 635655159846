import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const reportingCategoryApi = {
    getReportingTagCategorys: (): Observable<Dtos.GetReportingTagCategoriesResponse> => {
        //Create the request.
        let request: Dtos.GetReportingTagCategories = new Dtos.GetReportingTagCategories();

        //Send request.
        const response: Promise<Dtos.GetReportingTagCategoriesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getReportingTagCategoryById: (id: number): Observable<Dtos.GetReportingTagCategoryByIdResponse> => {
        //Create the request.
        let request: Dtos.GetReportingTagCategoryById = new Dtos.GetReportingTagCategoryById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetReportingTagCategoryByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setReportingTagCategory: (reportingTagCategory: Dtos.ReportingTagCategory): Observable<Dtos.SetReportingTagCategoryResponse> => {

        let request: Dtos.SetReportingTagCategory = new Dtos.SetReportingTagCategory();

        request.reportingTagCategory = reportingTagCategory;

        // send request
        const response: Promise<Dtos.SetReportingTagCategoryResponse> = client.post(request);

        return Observable.from(response);

    },
    createReportingTagCategory: (): Observable<Dtos.CreateReportingTagCategoryResponse> => {
        //Create the request.
        let request: Dtos.CreateReportingTagCategory = new Dtos.CreateReportingTagCategory();

        //Send request.
        const response: Promise<Dtos.CreateReportingTagCategoryResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}