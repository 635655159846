import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trialAnalysisTypesApi = {
    getTrialAnalysisTypes: (): Observable<Dtos.GetTrialAnalysisTypesResponse> => {
        //Create the request.
        let request: Dtos.GetTrialAnalysisTypes = new Dtos.GetTrialAnalysisTypes();

        //Send request.
        const response: Promise<Dtos.GetTrialAnalysisTypesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrialAnalysisTypeById: (id: number): Observable<Dtos.GetTrialAnalysisTypeByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrialAnalysisTypeById = new Dtos.GetTrialAnalysisTypeById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTrialAnalysisTypeByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrialAnalysisType: (trialAnalysisType: Dtos.TrialAnalysisType): Observable<Dtos.SetTrialAnalysisTypeResponse> => {

        let request: Dtos.SetTrialAnalysisType = new Dtos.SetTrialAnalysisType();

        request.trialAnalysisType = trialAnalysisType;

        // send request
        const response: Promise<Dtos.SetTrialAnalysisTypeResponse> = client.post(request);

        return Observable.from(response);

    },
    createTrialAnalysisType: (): Observable<Dtos.CreateTrialAnalysisTypeResponse> => {
        //Create the request.
        let request: Dtos.CreateTrialAnalysisType = new Dtos.CreateTrialAnalysisType();

        //Send request.
        const response: Promise<Dtos.CreateTrialAnalysisTypeResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}