import * as Dtos from '../dtos/Tmd.dtos';
import { replace } from 'react-router-redux';

export function RouterReplace(store: any, url: string): void {
    if (!store || !store.dispatch) {
        return;
    }

    store.dispatch(replace(url));
};
