import * as React from 'react';
import * as Dtos from '../dtos/Tmd.dtos';
import { FontAwesomeIcons, FontAwesomeIcon } from '../constants/fontAwesomeIcons';

export function renderRegistrationFormSaveSuccess() {
    return <div>The form has been saved successfully</div>
}

export function renderRegistrationFormSaveWarning(validationFailures: Dtos.ResponseError[]) {
    if (validationFailures) {
        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>
    }

    return <div>
        The form was saved but was invalid.
        </div>
}

export function renderRegistrationFormSaveError() {
    return <div>
        The form was <strong>not</strong> saved.
            </div>
}

export function renderRegistrationFormSaveHard() {
    return <div>
        The form cannot be saved because some fields have invalid data. These fields have been marked with a <FontAwesomeIcon icon={FontAwesomeIcons.Solid.BAN} fixedWidth /> icon.
        </div>
}

export function renderRegistrationFormSaveBlock() {
    return <div>
        The form cannot be saved. Please ensure all field data is valid and try again.
        </div>
}

export function renderSaeNotifyFormSendEmail() {
    return <div>
        The notification cannot be sent because some fields have invalid data. These fields have been marked with a <FontAwesomeIcon icon={FontAwesomeIcons.Solid.BAN} fixedWidth /> icon.
        </div>
}