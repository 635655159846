import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_FACILITYALLIANCEMEMBERS_LOAD,
    ACTION_FACILITYALLIANCEMEMBERS_LOAD_BY_ID,

    ACTION_FACILITYALLIANCEMEMBERS_SAVE,

    ACTION_FACILITYALLIANCEMEMBERS_CREATE,

    IFacilityAllianceMemberLoadAction,
    IFacilityAllianceMemberLoadByIdAction,
    IFacilityAllianceMemberLoadFailureAction,
    IFacilityAllianceMemberLoadSuccessAction,

    IFacilityAllianceMemberSaveAction,
    IFacilityAllianceMemberSaveSuccessAction,
    IFacilityAllianceMemberSaveFailureAction,

    IFacilityAllianceMemberCreateAction,
    IFacilityAllianceMemberCreateSuccessAction,
    IFacilityAllianceMemberCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/facilityAllianceMembers";
import { facilityalliancemembersApi } from "../services/facilityAllianceMember";

export const
    getFacilityAllianceMembersEpic = (action$): Observable<IFacilityAllianceMemberLoadSuccessAction | IFacilityAllianceMemberLoadFailureAction> => {
        return action$
            .ofType(ACTION_FACILITYALLIANCEMEMBERS_LOAD)
            .mergeMap((action: IFacilityAllianceMemberLoadAction) => {
                return facilityalliancemembersApi
                    .getFacilityAllianceMembers()
                    .map(response =>
                        LoadSuccess(response.facilityAllianceMembers, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getFacilityAllianceMemberByIdEpic = (action$): Observable<IFacilityAllianceMemberLoadSuccessAction | IFacilityAllianceMemberLoadFailureAction> => {
        return action$
            .ofType(ACTION_FACILITYALLIANCEMEMBERS_LOAD_BY_ID)
            .mergeMap((action: IFacilityAllianceMemberLoadByIdAction) => {
                return facilityalliancemembersApi
                    .getFacilityAllianceMemberById(action.id)
                    .map(response =>
                        LoadSuccess(response.facilityAllianceMember, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setFacilityAllianceMemberEpic = (action$): Observable<IFacilityAllianceMemberSaveSuccessAction | IFacilityAllianceMemberSaveFailureAction> => {
        return action$
            .ofType(ACTION_FACILITYALLIANCEMEMBERS_SAVE)
            .mergeMap((action: IFacilityAllianceMemberSaveAction) => {
                return facilityalliancemembersApi
                    .setFacilityAllianceMember(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.facilityAllianceMember, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createFacilityAllianceMemberEpic = (action$): Observable<IFacilityAllianceMemberCreateSuccessAction | IFacilityAllianceMemberCreateFailureAction> => {
        return action$
            .ofType(ACTION_FACILITYALLIANCEMEMBERS_CREATE)
            .mergeMap((action: IFacilityAllianceMemberCreateAction) => {
                return facilityalliancemembersApi
                    .createFacilityAllianceMember()
                    .map(response =>
                        CreateSuccess(
                            response.facilityAllianceMember,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



