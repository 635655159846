import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trialTrackersApi = {
    getTrialTrackers: (trialId: number): Observable<Dtos.GetTrialTrackersResponse> => {
        //Create the request.
        let request: Dtos.GetTrialTrackers = new Dtos.GetTrialTrackers();
        request.trialId = trialId;

        //Send request.
        const response: Promise<Dtos.GetTrialTrackersResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrailTrackerById: (id: number): Observable<Dtos.GetTrialTrackerByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrialTrackerById = new Dtos.GetTrialTrackerById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTrialTrackerByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrialTracker: (trialTracker: Dtos.TrialTracker): Observable<Dtos.SetTrialTrackerResponse> => {

        let request: Dtos.SetTrialTracker = new Dtos.SetTrialTracker();

        request.trialTracker = trialTracker;

        // send request
        const response: Promise<Dtos.SetTrialTrackerResponse> = client.post(request);

        return Observable.from(response);
    },
    createTrialTracker: (trialId: number): Observable<Dtos.CreateTrialTrackerResponse> => {
        //Create the request.
        let request: Dtos.CreateTrialTracker = new Dtos.CreateTrialTracker();

        request.trialId = trialId;

        //Send request.
        const response: Promise<Dtos.CreateTrialTrackerResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}