import './NavMenu.scss';

import * as React from 'react';
import * as classNames from "classnames";
import * as FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import * as Dtos from '../../../dtos/Tmd.dtos';
import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { Link } from "../../routing/index";
import { ITmdState } from '../../../reducers';
import { connect } from 'react-redux';
import { AppSettings } from '../../../helpers/AppSettings';

const isProduction = AppSettings.buildMode === 'Production';
const isDeveloper = AppSettings.buildMode === 'Development';
const isTest = AppSettings.buildMode === 'Test';

interface INavMenuProps {
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface INavMenuActions {

}

export interface INavItem {
    url?: string;
    target?: string;
    icon: React.ReactNode;
    title: string;
    current?: boolean;
}

type NavMenuProps = INavMenuProps & INavMenuActions;

class NavMenu extends React.PureComponent<INavMenuProps, any> {

    constructor(props) {
        super(props);
    }

    render() {

        const {
            permissions,
        } = this.props

        let trialAccess: boolean = false;
        let dashboardAccess: boolean = false;
        let personnelAccess: boolean = false;
        let groupAccess: boolean = false;
        let settingsAccess: boolean = false;
        let institutionAccess: boolean = false;

        trialAccess = AuthenticationHelper.hasPermissionAnyMatch(permissions, Dtos.Permission.TrialView) || AuthenticationHelper.hasPermissionAnyMatch(permissions, Dtos.Permission.TrialUpdate);
        institutionAccess = AuthenticationHelper.hasPermissionAnyMatch(permissions, Dtos.Permission.InstitutionView);
        dashboardAccess = AuthenticationHelper.hasPermissionAnyMatch(permissions, Dtos.Permission.DashboardView);
        personnelAccess = AuthenticationHelper.hasPermissionAnyMatch(permissions, Dtos.Permission.PersonnelView);
        groupAccess = AuthenticationHelper.hasPermissionAnyMatch(permissions, Dtos.Permission.GroupView);
        settingsAccess = AuthenticationHelper.hasAnyPermission(permissions, [
            Dtos.Permission.CategoriesView,
            Dtos.Permission.RolesView,
            Dtos.Permission.CountriesView,
            Dtos.Permission.StatesView,
            Dtos.Permission.ItemComponentTypesView,
            Dtos.Permission.NoteTypesView,
            Dtos.Permission.GendersView,
            Dtos.Permission.InterestsView,
            Dtos.Permission.MembershipTypesView,
            Dtos.Permission.ProfessionalDisciplinesView,
            Dtos.Permission.SalutationsView,
            Dtos.Permission.TrialCategoriesView,
            Dtos.Permission.TumorGroupsView,
            Dtos.Permission.AllocatedWPsView,
            Dtos.Permission.TrialPhasesView,
            Dtos.Permission.InvolvementsView,
            Dtos.Permission.TrialCoordCentresView,
            Dtos.Permission.CollaboratingGroupsView,
            Dtos.Permission.TrialTypesView,
            Dtos.Permission.SiteLocationsView,
            Dtos.Permission.KeyContactsView,
            Dtos.Permission.FacilityAllianceMembersView,
            Dtos.Permission.InstitutionTypesView,
            Dtos.Permission.InstitutionCodingView,
            Dtos.Permission.FormTemplatesView,
            Dtos.Permission.FormComponentsView,
            Dtos.Permission.ReportingTagsView,
            Dtos.Permission.ReportingTagCategoriesView,
            Dtos.Permission.ItemComponentsView,
            Dtos.Permission.OpenClinicaUserRolesView,
            Dtos.Permission.HubspotContactExportView
        ]);

        return <div className="container-fluid bg-primary">
            <nav className="container Capture-nav p-0 d-flex" style={{ minHeight: 40 }}>
                {
                    dashboardAccess ?
                        <Link className="nav-link" url="/dashboard">
                            <span>
                                &emsp;<FontAwesomeIcon icon={FontAwesomeIcons.Light.HOME} fixedWidth className="mr-3" />
                                Dashboard&emsp;
                            </span>
                        </Link>
                        :
                        null
                }
                {
                    trialAccess ?
                        <Link className="nav-link" url="/trials">
                            <span>
                                &emsp;<FontAwesomeIcon icon={FontAwesomeIcons.Light.TRIAL} fixedWidth className="mr-3" />Trials&emsp;
                            </span>
                        </Link>
                        :
                        null
                }
                {
                    institutionAccess ?
                        <Link className="nav-link" url="/institutions">
                            <span>
                                &emsp;<FontAwesomeIcon icon={FontAwesomeIcons.Light.INSTITUTION} fixedWidth className="mr-3" />Institutions&emsp;
                            </span>
                        </Link>
                        :
                        null
                }
                {
                    personnelAccess ?
                        <Link className="nav-link" url="/personnel">
                            <span>
                                &emsp;<FontAwesomeIcon icon={FontAwesomeIcons.Light.USER} fixedWidth className="mr-3" />Personnel&emsp;
                            </span>
                        </Link> :
                        null
                }
                {
                    groupAccess ?
                        <Link className="nav-link" url="/groups">
                            <span>
                                &emsp;<FontAwesomeIcon icon={FontAwesomeIcons.Light.GROUP} fixedWidth className="mr-3" />Groups&emsp;
                            </span>
                        </Link> :
                        null
                }
                {
                    settingsAccess ?
                        <Link className="nav-link" url="/settings/general/roles">
                            <span>
                                &emsp;<FontAwesomeIcon icon={FontAwesomeIcons.Light.SETTINGS} fixedWidth className="mr-3" />Settings&emsp;
                            </span>
                        </Link> :
                        null
                }
                {(trialAccess || personnelAccess || institutionAccess) && (
                    <Link className="nav-link" url="/search" style={{ marginLeft: 'auto' }}>
                        <span>
                            &emsp;<FontAwesomeIcon icon={FontAwesomeIcons.Solid.SEARCH} fixedWidth />&emsp;
                        </span>
                    </Link>
                )}
            </nav>
            {isDeveloper && (
                <a className="ribbon ribbon-sm ribbon-primary ribbon-top-right" href="/">Development</a>
            )}
            {isTest && (
                <a className="ribbon ribbon-sm ribbon-secondary ribbon-top-right" href="/">Test</a>
            )}
        </div>
    }
}

const mapStateToProps = (state: ITmdState, ownProps: NavMenuProps): INavMenuProps => {
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];
    return {
        permissions: permissions
    };
};

const mapDispatchToProps = (dispatch): INavMenuActions => {
    return {

    }
};

export default
    connect(mapStateToProps, mapDispatchToProps)(NavMenu);