import './ListGroupItem.scss';

import * as React from 'react';

import * as classNames from "classnames";

interface IListGroupItemProps {
    onClick?: (event: React.SyntheticEvent<any>) => void;  
    className?: string;
}

class ListGroupItem extends React.PureComponent<IListGroupItemProps, any> {

    render() {
        const {
            children,
            className,
            onClick
        } = this.props

        return <div onClick={onClick} className={classNames("list-group-item list-group-item-action", className)}>
            {children}
        </div>
    }
}
export default ListGroupItem