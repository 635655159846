import { Reducer } from 'redux';
import { update } from '../../helpers/immutabilityHelper'
import * as Dtos from '../../dtos/Tmd.dtos';

import {
    ACTION_TRIALITEMINSTANCES_PAGE_SET_ADD_MODAL_VIEW_STATE,
    ACTION_TRIALITEMINSTANCES_PAGE_SET_EDIT_MODAL_VIEW_STATE,
    ACTION_TRIALITEMINSTANCES_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE,
    TrialItemInstancesPageAction
} from '../../actions/pages/trialItemInstancePage';


export interface ITrailItemInstancePageState {
    addModalOpen: boolean;
    editModalOpen: boolean;
    collectionInstanceId: number;
    name: string;
    description: string;
    createVersionModalOpen: boolean;
    trialId: number;
    templateTrialItemId: number;
    instanceNumber: number;
}

const initialState: ITrailItemInstancePageState = {
    addModalOpen: false,
    editModalOpen: false,
    collectionInstanceId: null,
    name: "",
    description: "",
    createVersionModalOpen: false,
    trialId: null,
    templateTrialItemId: null,
    instanceNumber: null
};

function setAddModalViewState(state: ITrailItemInstancePageState, open: boolean): ITrailItemInstancePageState {
    const newState: ITrailItemInstancePageState = update(state, {
        addModalOpen: {
            $set: open
        }
    });
    return newState;
}


function setEditModalViewState(state: ITrailItemInstancePageState, open: boolean, collectionInstanceId: number, name: string, description: string): ITrailItemInstancePageState {
    const newState: ITrailItemInstancePageState = update(state, {
        editModalOpen: {
            $set: open
        },
        collectionInstanceId: {
            $set: collectionInstanceId
        },
        name: {
            $set: name
        },
        description: {
            $set: description
        }
    });
    return newState;
}

function setCreateVersionModalViewState(state: ITrailItemInstancePageState, open: boolean, trialId: number, templateTrialItemId: number, instanceNumber: number): ITrailItemInstancePageState {
    const newState: ITrailItemInstancePageState = update(state, {
        createVersionModalOpen: {
            $set: open
        },
        trialId: {
            $set: trialId
        },
        templateTrialItemId: {
            $set: templateTrialItemId
        },
        instanceNumber: {
            $set: instanceNumber
        }
    });
    return newState;
}

const TrailInstitutionItemsPageReducer: Reducer<ITrailItemInstancePageState> = (state: ITrailItemInstancePageState = initialState, action) => { 
    switch (action.type) {
        case ACTION_TRIALITEMINSTANCES_PAGE_SET_ADD_MODAL_VIEW_STATE:
            return setAddModalViewState(state, action.open);
        case ACTION_TRIALITEMINSTANCES_PAGE_SET_EDIT_MODAL_VIEW_STATE:
            return setEditModalViewState(state, action.open, action.collectionInstanceId, action.name, action.description);
        case ACTION_TRIALITEMINSTANCES_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE:
            return setCreateVersionModalViewState(state, action.open, action.trialId, action.templateTrialItemId, action.instanceNumber);
    }

    return state;
}

export default TrailInstitutionItemsPageReducer;