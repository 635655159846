import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadFormTemplate: IFormTemplateLoadActionCreator = () => {
        return {
            type: ACTION_FORMTEMPLATES_LOAD
        }
    },
    LoadFormTemplateById: IFormTemplateLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_FORMTEMPLATES_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IFormTemplateLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_FORMTEMPLATES_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IFormTemplateLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_FORMTEMPLATES_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveFormTemplate: IFormTemplateSaveActionCreator = (formData, collectionFormData) => {
        return {
            type: ACTION_FORMTEMPLATES_SAVE,
            formData,
            collectionFormData
        }
    },
    SaveSuccess: IFormTemplateSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_FORMTEMPLATES_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IFormTemplateSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_FORMTEMPLATES_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateFormTemplate: IFormTemplateCreateActionCreator = () => {

        return {
            type: ACTION_FORMTEMPLATES_CREATE
        }
    },
    CreateSuccess: IFormTemplateCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_FORMTEMPLATES_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: IFormTemplateCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_FORMTEMPLATES_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IFormTemplateClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_FORMTEMPLATES_CLEAR,
            propertiesToClear
        }
    },
    Filter: IFormTemplateFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_FORMTEMPLATES_FILTER,
            formFilter
        }
    };



export const
    ACTION_FORMTEMPLATES_LOAD = "ACTION_FORMTEMPLATES_LOAD",
    ACTION_FORMTEMPLATES_LOAD_BY_ID = "ACTION_FORMTEMPLATES_LOAD_BY_ID",
    ACTION_FORMTEMPLATES_LOAD_SUCCESS = "ACTION_FORMTEMPLATES_LOAD_SUCCESS",
    ACTION_FORMTEMPLATES_LOAD_FAILURE = "ACTION_FORMTEMPLATES_LOAD_FAILURE",

    ACTION_FORMTEMPLATES_SAVE = "ACTION_FORMTEMPLATES_SAVE",
    ACTION_FORMTEMPLATES_SAVE_SUCCESS = "ACTION_FORMTEMPLATES_SAVE_SUCCESS",
    ACTION_FORMTEMPLATES_SAVE_FAILURE = "ACTION_FORMTEMPLATES_SAVE_FAILURE",

    ACTION_FORMTEMPLATES_CREATE = "ACTION_FORMTEMPLATES_CREATE",
    ACTION_FORMTEMPLATES_CREATE_SUCCESS = "ACTION_FORMTEMPLATES_CREATE_SUCCESS",
    ACTION_FORMTEMPLATES_CREATE_FAILURE = "ACTION_FORMTEMPLATES_CREATE_FAILURE",

    ACTION_FORMTEMPLATES_CLEAR = "ACTION_FORMTEMPLATES_CLEAR",

    ACTION_FORMTEMPLATES_FILTER = "ACTION_FORMTEMPLATES_FILTER";

export interface IFormTemplateLoadAction {
    type: "ACTION_FORMTEMPLATES_LOAD";
}

export interface IFormTemplateLoadActionCreator {
    (): IFormTemplateLoadAction;
}

export interface IFormTemplateLoadSuccessAction {
    type: "ACTION_FORMTEMPLATES_LOAD_SUCCESS";
    formData: Dtos.FormTemplate | Dtos.FormTemplate[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IFormTemplateLoadSuccessActionCreator {
    (
        formData: Dtos.FormTemplate | Dtos.FormTemplate[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IFormTemplateLoadSuccessAction;
}

export interface IFormTemplateLoadFailureAction {
    type: "ACTION_FORMTEMPLATES_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IFormTemplateLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IFormTemplateLoadFailureAction;
}

export interface IFormTemplateLoadByIdAction {
    type: "ACTION_FORMTEMPLATES_LOAD_BY_ID";
    id: number;
}

export interface IFormTemplateLoadByIdActionCreator {
    (id: number): IFormTemplateLoadByIdAction;
}


export interface IFormTemplateSaveAction {
    type: "ACTION_FORMTEMPLATES_SAVE";
    formData: Dtos.FormTemplate;
    collectionFormData: Dtos.Collection;

}

export interface IFormTemplateSaveActionCreator {
    (
        formData: Dtos.FormTemplate,
        collectionFormData: Dtos.Collection
    ): IFormTemplateSaveAction;
}

export interface IFormTemplateSaveSuccessAction {
    type: "ACTION_FORMTEMPLATES_SAVE_SUCCESS";
    formData: Dtos.FormTemplate;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IFormTemplateSaveSuccessActionCreator {
    (
        formData: Dtos.FormTemplate,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IFormTemplateSaveSuccessAction;
}

export interface IFormTemplateSaveFailureAction {
    type: "ACTION_FORMTEMPLATES_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IFormTemplateSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IFormTemplateSaveFailureAction;
}

export interface IFormTemplateCreateAction {
    type: "ACTION_FORMTEMPLATES_CREATE"
}

export interface IFormTemplateCreateActionCreator {
    (): IFormTemplateCreateAction
}

export interface IFormTemplateCreateSuccessAction {
    type: "ACTION_FORMTEMPLATES_CREATE_SUCCESS";
    formData: Dtos.FormTemplate;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface IFormTemplateCreateSuccessActionCreator {
    (
        formData: Dtos.FormTemplate,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): IFormTemplateCreateSuccessAction;
}

export interface IFormTemplateCreateFailureAction {
    type: "ACTION_FORMTEMPLATES_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IFormTemplateCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IFormTemplateCreateFailureAction;
}

export interface IFormTemplateClearAction {
    type: "ACTION_FORMTEMPLATES_CLEAR";
    propertiesToClear?: string[];
}

export interface IFormTemplateClearActionCreator {
    (propertiesToClear?: string[]): IFormTemplateClearAction;
}

export interface IFormTemplateFilterActionCreator {
    (formFilter: IFormFilter): IFormTemplateFilterAction;
}

export interface IFormTemplateFilterAction {
    type: "ACTION_FORMTEMPLATES_FILTER";
    formFilter: IFormFilter;
}

export type FormTemplateAction =
    IFormTemplateLoadAction |
    IFormTemplateLoadByIdAction |
    IFormTemplateLoadFailureAction |
    IFormTemplateLoadSuccessAction |
    IFormTemplateSaveAction |
    IFormTemplateSaveSuccessAction |
    IFormTemplateSaveFailureAction |
    IFormTemplateCreateAction |
    IFormTemplateCreateSuccessAction |
    IFormTemplateCreateFailureAction |
    IFormTemplateClearAction |
    IFormTemplateFilterAction;
