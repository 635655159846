import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_DEPARTMENTCONTACT_LOAD,
    ACTION_DEPARTMENTCONTACT_LOAD_BY_ID,

    ACTION_DEPARTMENTCONTACT_SAVE,

    ACTION_DEPARTMENTCONTACT_CREATE,

    IDepartmentContactLoadAction,
    IDepartmentContactLoadByIdAction,
    IDepartmentContactLoadFailureAction,
    IDepartmentContactLoadSuccessAction,

    IDepartmentContactSaveAction,
    IDepartmentContactSaveSuccessAction,
    IDepartmentContactSaveFailureAction,

    IDepartmentContactCreateAction,
    IDepartmentContactCreateSuccessAction,
    IDepartmentContactCreateFailureAction,

    LoadSuccess,
    LoadFailure,


    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,
} from "../actions/departmentContact";
import { departmentContactsApi } from "../services/departmentContacts";


export const
    getDepartmentContactsEpic = (action$): Observable<IDepartmentContactLoadSuccessAction | IDepartmentContactLoadFailureAction> => {
        return action$
            .ofType(ACTION_DEPARTMENTCONTACT_LOAD)
            .mergeMap((action: IDepartmentContactLoadAction) => {
                return departmentContactsApi
                    .get()
                    .map(response =>
                        LoadSuccess(response.departmentContacts, undefined, [], [], response.lookups, undefined)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getDepartmentContactsByIdEpic = (action$): Observable<IDepartmentContactLoadSuccessAction | IDepartmentContactLoadFailureAction> => {
        return action$
            .ofType(ACTION_DEPARTMENTCONTACT_LOAD_BY_ID)
            .mergeMap((action: IDepartmentContactLoadByIdAction) => {
                return departmentContactsApi
                    .getById(action.id)
                    .map(response =>
                        LoadSuccess(response.departmentContact, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setDepartmentContactEpic = (action$): Observable<IDepartmentContactSaveSuccessAction | IDepartmentContactSaveFailureAction> => {
        return action$
            .ofType(ACTION_DEPARTMENTCONTACT_SAVE)
            .mergeMap((action: IDepartmentContactSaveAction) => {
                return departmentContactsApi
                    .set(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.departmentContact, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createDepartmentContactEpic = (action$): Observable<IDepartmentContactCreateSuccessAction | IDepartmentContactCreateFailureAction> => {
        return action$
            .ofType(ACTION_DEPARTMENTCONTACT_CREATE)
            .mergeMap((action: IDepartmentContactCreateAction) => {
                return departmentContactsApi
                    .create(action.departmentId)
                    .map(response =>
                        CreateSuccess(response.departmentContact, response.formState, response.formProperties, response.formRoles, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



