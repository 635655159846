import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const reportingApi = {
    getReportingTags: (): Observable<Dtos.GetReportingTagsResponse> => {
        //Create the request.
        let request: Dtos.GetReportingTags = new Dtos.GetReportingTags();

        //Send request.
        const response: Promise<Dtos.GetReportingTagsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getReportingTagById: (id: number): Observable<Dtos.GetReportingTagByIdResponse> => {
        //Create the request.
        let request: Dtos.GetReportingTagById = new Dtos.GetReportingTagById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetReportingTagByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setReportingTag: (reportingTag: Dtos.ReportingTag): Observable<Dtos.SetReportingTagResponse> => {

        let request: Dtos.SetReportingTag = new Dtos.SetReportingTag();

        request.reportingTag = reportingTag;

        // send request
        const response: Promise<Dtos.SetReportingTagResponse> = client.post(request);

        return Observable.from(response);

    },
    createReportingTag: (): Observable<Dtos.CreateReportingTagResponse> => {
        //Create the request.
        let request: Dtos.CreateReportingTag = new Dtos.CreateReportingTag();

        //Send request.
        const response: Promise<Dtos.CreateReportingTagResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}