import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialOpenClinicaUserRoleState: Dtos.OpenClinicaUserRole & IRegistrationFormState = {
    id: null,
    personnelId: null,
    personnel: null,
    personnelRoleId: null,
    personnelRole: null,
    trialId: null,
    trial: null,
    institutionId: null,
    institution: null,
    studyId: null,
    parentStudyId: null,
    studyIdentifier: null,
    role: null,
    ocSyncStatus: null,
    status: null,
    errorMessage: null,
    emailSent: null,
    search: null,
    active: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false
}

export interface IOpenClinicaUserRoleReduxFormState {
    id: IFieldState<number>,
    personnelId: IFieldState<number>,
    personnel: IFieldState<Dtos.Personnel>,
    personnelRoleId: IFieldState<number>,
    personnelRole: IFieldState<Dtos.PersonnelRole>,
    trialId: IFieldState<number>,
    trial: IFieldState<Dtos.Trial>,
    institutionId: IFieldState<number>,
    institution: IFieldState<Dtos.Institution>,
    studyId: IFieldState<number>,
    parentStudyId: IFieldState<number>,
    studyIdentifier: IFieldState<string>,
    role: IFieldState<string>,
    ocSyncStatus: IFieldState<Dtos.OCSyncStatus>,
    errorMessage: IFieldState<string>,
    emailSent: IFieldState<boolean>,
    search: IFieldState<string>,
    active: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>
}
