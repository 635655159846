import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_TRACKERMILESTONE_LOAD,
    ACTION_TRACKERMILESTONE_LOAD_BY_ID,

    ACTION_TRACKERMILESTONE_SAVE,

    ACTION_TRACKERMILESTONE_CREATE,

    ITrackerMilestoneLoadAction,
    ITrackerMilestoneLoadByIdAction,
    ITrackerMilestoneLoadFailureAction,
    ITrackerMilestoneLoadSuccessAction,

    ITrackerMilestoneSaveAction,
    ITrackerMilestoneSaveSuccessAction,
    ITrackerMilestoneSaveFailureAction,

    ITrackerMilestoneCreateAction,
    ITrackerMilestoneCreateSuccessAction,
    ITrackerMilestoneCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/trackerMilestones";
import { trackerMilestoneApi } from "../services/trackerMilestone";

export const
    getTrackerMilestonesEpic = (action$): Observable<ITrackerMilestoneLoadSuccessAction | ITrackerMilestoneLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRACKERMILESTONE_LOAD)
            .mergeMap((action: ITrackerMilestoneLoadAction) => {
                return trackerMilestoneApi
                    .getTrackerMilestones()
                    .map(response =>
                        LoadSuccess(response.trackerMilestones, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTrackerMilestoneByIdEpic = (action$): Observable<ITrackerMilestoneLoadSuccessAction | ITrackerMilestoneLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRACKERMILESTONE_LOAD_BY_ID)
            .mergeMap((action: ITrackerMilestoneLoadByIdAction) => {
                return trackerMilestoneApi
                    .getTrackerMilestoneById(action.id)
                    .map(response =>
                        LoadSuccess(response.trackerMilestone, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setTrackerMilestoneEpic = (action$): Observable<ITrackerMilestoneSaveSuccessAction | ITrackerMilestoneSaveFailureAction> => {
        return action$
            .ofType(ACTION_TRACKERMILESTONE_SAVE)
            .mergeMap((action: ITrackerMilestoneSaveAction) => {
                return trackerMilestoneApi
                    .setTrackerMilestone(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.trackerMilestone, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createTrackerMilestoneEpic = (action$): Observable<ITrackerMilestoneCreateSuccessAction | ITrackerMilestoneCreateFailureAction> => {
        return action$
            .ofType(ACTION_TRACKERMILESTONE_CREATE)
            .mergeMap((action: ITrackerMilestoneCreateAction) => {
                return trackerMilestoneApi
                    .createTrackerMilestone()
                    .map(response =>
                        CreateSuccess(
                            response.trackerMilestone,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



