import * as React from 'react';
import './TrialItemUpdatePage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { ITrialItemUpdateReduxFormState, initialTrialItemUpdateState } from "../../../reducers/reactReduxForms/trialItemUpdate";
import * as TrialActions from '../../../actions/trial';
import * as TrialItemUpdateActions from '../../../actions/trialItemUpdate';
import * as ToggleActions from '../../../actions/pages/togglePage';
import * as ToggleHelper from "../../../helpers/toggleHelper";
import { TogglePageItem } from '../../../reducers/pages/togglePage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInputFabric, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput, PrimitiveFormInput } from '../../../components/form';
import { TrialLayout } from '../../index';

interface ITrialItemUpdatePageParams {
    trialId: number;
}

interface ITrialItemUpdatePageProps {

    form: Dtos.TrialItemUpdate & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITrialItemUpdateReduxFormState;
    lookups: Dtos.Lookup[];
    validationFailures: Dtos.ResponseError[];

    location: Location;
    trialId: number;
    trialItemId: number;
    match: match<ITrialItemUpdatePageParams>;
    history: History;

    // From state
    trialItemUpdate: Dtos.TrialItemUpdate;
    loading: boolean;
    loadSuccess: boolean;
    loadFailure: boolean;


    saving: boolean;
    saveSuccess: boolean;
    saveFailure: boolean;

    user: Dtos.User,

    toggleItems: TogglePageItem[]
}

interface ITrialItemUpdatePageActions {
    clear: TrialItemUpdateActions.ITrialItemUpdateClearActionCreator;
    load: TrialItemUpdateActions.ITrialItemUpdateLoadByIdActionCreator;
    save: TrialItemUpdateActions.ITrialItemUpdateSaveActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    toggleExpand: ToggleActions.IToggleExpandedStateActionCreator;
}

type TrialItemUpdatePageProps = ITrialItemUpdatePageProps & ITrialItemUpdatePageActions;

const reduxFormName: string = "reduxForms.trialItemUpdate";

class TrialItemUpdatePage extends React.PureComponent<TrialItemUpdatePageProps, any> {

    constructor(props: TrialItemUpdatePageProps) {
        super(props);

        this.clear = this.clear.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount() {
        const {
            load,
            loadTrial,
            trialId,
            trialItemId,
        } = this.props;

        loadTrial(trialId);

        if (trialItemId) {
            load(trialId, trialItemId);
        } else {
            load(trialId, 0);
        }
    }

    componentDidUpdate(prevProps: TrialItemUpdatePageProps, prevState) {
        const {
            form,
            trialId,
            trialItemId,
            load,
            loading,
            loadSuccess,
            saving,
            saveSuccess,
            validationFailures,
            navigate,
            loadTrial
        } = this.props


        if ((trialId && prevProps.trialId != trialId)) {
            loadTrial(trialId);
        }

        if ((trialItemId && prevProps.trialItemId != trialItemId)) {
            load(trialId, trialItemId);
        }

        if (!loading && prevProps.loading) {
            if (loadSuccess) {
                this.loadForm();
            }
        }

        if (!saving && prevProps.saving) {
            if (saveSuccess) {
                if (validationFailures && validationFailures.length > 0) {
                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);
                } else {
                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);
                }
                if (form.saveAndReturn) {
                    navigate("/trials/" + trialId + "/trialitems")
                } else {
                    this.loadForm();
                }
            } else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }
    }

    componentWillUnmount() {
        this.clear();
    }

    clear() {
        this.props.clear();
        this.props.clearTrial();
    }

    show(): boolean {
        const {
            loading,
            loadSuccess
        } = this.props

        if (!loading && loadSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loading
        } = this.props

        return (
            <TrialLayout loading={loading} permission={Dtos.Permission.TrialItemInstanceUpdate}>{this.renderContent()}</TrialLayout>
        );
    }

    renderContent() {
        const {
            form,
            trialItemUpdate,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            saving,
        } = this.props;

        const formDisabled: boolean = saving || (formState && formState.formStatus == Dtos.FormStatus.Disabled);
        const formTrialItemSelected: boolean = !formDisabled && form.trialItemId && form.trialItemId > 0;
        const formCanSave: boolean = !formDisabled && formTrialItemSelected; // selectedItems && selectedItems.length > 0;
        let trialItemsLookup: Dtos.Lookup = { type: "TrialItemUpdate", propertyName: "TrialItemId", values: [] };
        if (trialItemUpdate && trialItemUpdate.trialItems && trialItemUpdate.trialItems.length > 0) {
            trialItemsLookup.values = trialItemUpdate.trialItems.filter(f => f.active).map((item, index) => {
                return { id: item.id, value: item.name, rank: item.rank, active: true };
            });
        }

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            trialItemId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(trialItemsLookup)
                },
                inputLabel: "Select the trial item to update",
                changeAction: (model, value, form, parentModel) => {
                    changeForm(model, value);
                },
            }
        };


        return <div className="trail-item-update">
            <h3>Institution Item Update</h3>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "trialItemUpdate", formComponents, lookups, validationFailures, formProperties, changeForm)
                }

                {
                    this.renderTable()
                }

                <div className="mt-4 mb-4 text-right">
                    <button disabled={!formCanSave}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>
                    <button disabled={!formCanSave}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
        </div>
    }

    renderTable() {
        const {
            trialId,
            toggleItems,
            toggleExpand,
            form,
            formState
        } = this.props;

        if (!form.trialItemInstitutions) {
            return;
        }

        return <div className="institution-groups">
            {form.trialItemInstitutions.map((item, trialItemInstitutionsIndex) => {
                const key = "trial-items-update-page-" + item.institutionId + "-" + trialId;
                const expanded = ToggleHelper.isToggleExpanded(toggleItems, key);
                return <div className="row institution-group" key={"institution-" + item.institutionId}>
                    <div className="col-12 mt-3 mb-3" onClick={() => toggleExpand(key)}>
                        <FontAwesomeIcon icon={!expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" /><label className="mb-0"><b>{item.name}</b><span className="ml-3">{ item.version != "" ? "version [" + item.version + "]": ""}</span></label>
                    </div>
                    {expanded && (
                        <>
                            <div className="col-12 mb-3">
                                {item.columnFormat == "SMALL-LARGE-DOCUMENTS-RIGHT" && (
                                    <>
                                        <div className="row">
                                            <div className="col-3">
                                                {item.column1.map((itemComponentWithData, itemComponentWithDataIndex) => {
                                                    return this.renderItemComponent(trialItemInstitutionsIndex, itemComponentWithData, reduxFormName + ".trialItemInstitutions[" + trialItemInstitutionsIndex + "].column1[" + itemComponentWithDataIndex + "].collectionInstanceData")
                                                })}
                                            </div>
                                            <div className="col-9">
                                                {item.column2.map((itemComponentWithData, itemComponentWithDataIndex) => {
                                                    return this.renderItemComponent(trialItemInstitutionsIndex, itemComponentWithData, reduxFormName + ".trialItemInstitutions[" + trialItemInstitutionsIndex + "].column2[" + itemComponentWithDataIndex + "].collectionInstanceData")
                                                })}
                                                {item.column3.map((itemComponentWithData, itemComponentWithDataIndex) => {
                                                    return this.renderItemComponent(trialItemInstitutionsIndex, itemComponentWithData, reduxFormName + ".trialItemInstitutions[" + trialItemInstitutionsIndex + "].column3[" + itemComponentWithDataIndex + "].collectionInstanceData")
                                                })}
                                                {false && item.documents.map((itemComponentWithData, itemComponentWithDataIndex) => {
                                                    return this.renderItemComponent(trialItemInstitutionsIndex, itemComponentWithData, reduxFormName + ".trialItemInstitutions[" + trialItemInstitutionsIndex + "].documents[" + itemComponentWithDataIndex + "].collectionInstanceData")
                                                })}
                                            </div>
                                        </div>
                                    </>
                                )}
                                {item.columnFormat == "EVEN" && (
                                    <>
                                        <div className="row">
                                            {item.column1.length > 0 && (
                                                <div className="col">
                                                    {item.column1.map((itemComponentWithData, itemComponentWithDataIndex) => {
                                                        return this.renderItemComponent(trialItemInstitutionsIndex, itemComponentWithData, reduxFormName + ".trialItemInstitutions[" + trialItemInstitutionsIndex + "].column1[" + itemComponentWithDataIndex + "].collectionInstanceData")
                                                    })}
                                                </div>
                                            )}
                                            {item.column2.length > 0 && (
                                                <div className="col">
                                                    {item.column2.map((itemComponentWithData, itemComponentWithDataIndex) => {
                                                        return this.renderItemComponent(trialItemInstitutionsIndex, itemComponentWithData, reduxFormName + ".trialItemInstitutions[" + trialItemInstitutionsIndex + "].column2[" + itemComponentWithDataIndex + "].collectionInstanceData")
                                                    })}
                                                </div>
                                            )}
                                            {item.column3.length > 0 && (
                                                <div className="col">
                                                    {item.column3.map((itemComponentWithData, itemComponentWithDataIndex) => {
                                                        return this.renderItemComponent(trialItemInstitutionsIndex, itemComponentWithData, reduxFormName + ".trialItemInstitutions[" + trialItemInstitutionsIndex + "].column3[" + itemComponentWithDataIndex + "].collectionInstanceData")
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                {false && item.documents.map((itemComponentWithData, itemComponentWithDataIndex) => {
                                                    return this.renderItemComponent(trialItemInstitutionsIndex, itemComponentWithData, reduxFormName + ".trialItemInstitutions[" + trialItemInstitutionsIndex + "].documents[" + itemComponentWithDataIndex + "].collectionInstanceData")
                                                })}
                                            </div>
                                        </div>
                                    </>
                                )}

                            </div>
                        </>
                    )}
                </div>
            })}
        </div>;
    }

    renderItemComponent(trialItemInstitutionsIndex: number, itemComponentWithData: Dtos.ItemComponentWithData, reduxFormNameParent: string) {
        const {
            reduxFormState,
            changeForm,
            form,
            saving,
            validationFailures
        } = this.props;

        if (itemComponentWithData.collectionInstanceData && itemComponentWithData.itemComponent && itemComponentWithData.itemComponentPrimitive) {
            const validations = validationFailures == undefined ? undefined : validationFailures.filter(f => f.fieldName == 'CollectionInstanceDatas[' + itemComponentWithData.collectionInstanceData.id + '].TextValue');
            const name = "itemComponentInputPrimitives_" + itemComponentWithData.collectionInstanceData.id;
            let disabled: boolean = saving;

            if (itemComponentWithData.itemComponentRules && itemComponentWithData.itemComponentRules.length > 0 && !saving) {
                itemComponentWithData.itemComponentRules.forEach(rule => {
                    if (rule.itemPrimitiveRule == Dtos.ItemPrimitiveRule.IsDisabledIf) {
                        const compareItemComponentWithData: Dtos.ItemComponentWithData = this.findItemComponentWithData(trialItemInstitutionsIndex, rule.compareItemComponentPrimitiveId);
                        if (compareItemComponentWithData != undefined) {
                            if (compareItemComponentWithData.itemComponentPrimitive != undefined) {
                                switch (compareItemComponentWithData.itemComponentPrimitive.itemPrimitive) {
                                    case Dtos.ItemPrimitive.Checkbox:
                                        if (compareItemComponentWithData.collectionInstanceData.bitValue == true && (rule.compareValue == "1" || rule.compareValue.toLowerCase() == "true")) {
                                            disabled = true;
                                        }
                                        if (compareItemComponentWithData.collectionInstanceData.bitValue == false && (rule.compareValue == "" || rule.compareValue == "0" || rule.compareValue.toLowerCase() == "false")) {
                                            disabled = true;
                                        }
                                        break;
                                    case Dtos.ItemPrimitive.Text:
                                        if (compareItemComponentWithData.collectionInstanceData.textValue.toLowerCase() == rule.compareValue.toLowerCase()) {
                                            disabled = true;
                                        }
                                        break;
                                    case Dtos.ItemPrimitive.Comments:
                                        if (compareItemComponentWithData.collectionInstanceData.textValue.toLowerCase() == rule.compareValue.toLowerCase()) {
                                            disabled = true;
                                        }
                                        break;
                                    case Dtos.ItemPrimitive.Select:
                                        let option = compareItemComponentWithData.itemComponentPrimitive.itemComponentPrimitiveOptions.find(f => f.value.toLowerCase() == rule.compareValue.toLowerCase());
                                        if (option.id == compareItemComponentWithData.collectionInstanceData.intValue) {
                                            disabled = true;
                                        }
                                        break;
                                    case Dtos.ItemPrimitive.Numeric:
                                        if (compareItemComponentWithData.collectionInstanceData.numberValue.toString() == rule.compareValue) {
                                            disabled = true;
                                        }
                                        break;
                                }
                            }
                        }
                    }
                    if (rule.itemPrimitiveRule == Dtos.ItemPrimitiveRule.IsEnabledIf) {
                        const compareItemComponentWithData: Dtos.ItemComponentWithData = this.findItemComponentWithData(trialItemInstitutionsIndex, rule.compareItemComponentPrimitiveId);
                        if (compareItemComponentWithData != undefined) {
                            if (compareItemComponentWithData.itemComponentPrimitive != undefined) {
                                disabled = true;
                                switch (compareItemComponentWithData.itemComponentPrimitive.itemPrimitive) {
                                    case Dtos.ItemPrimitive.Checkbox:
                                        if (compareItemComponentWithData.collectionInstanceData.bitValue == true && (rule.compareValue == "1" || rule.compareValue.toLowerCase() == "true")) {
                                            disabled = false;
                                        }
                                        if (compareItemComponentWithData.collectionInstanceData.bitValue == false && (rule.compareValue == "" || rule.compareValue == "0" || rule.compareValue.toLowerCase() == "false")) {
                                            disabled = false;
                                        }
                                        break;
                                    case Dtos.ItemPrimitive.Text:
                                        if (compareItemComponentWithData.collectionInstanceData.textValue.toLowerCase() == rule.compareValue.toLowerCase()) {
                                            disabled = false;
                                        }
                                        break;
                                    case Dtos.ItemPrimitive.Comments:
                                        if (compareItemComponentWithData.collectionInstanceData.textValue.toLowerCase() == rule.compareValue.toLowerCase()) {
                                            disabled = false;
                                        }
                                        break;
                                    case Dtos.ItemPrimitive.Select:
                                        let option = compareItemComponentWithData.itemComponentPrimitive.itemComponentPrimitiveOptions.find(f => f.value.toLowerCase() == rule.compareValue.toLowerCase());
                                        if (option.id == compareItemComponentWithData.collectionInstanceData.intValue) {
                                            disabled = false;
                                        }
                                        break;
                                    case Dtos.ItemPrimitive.Numeric:
                                        if (compareItemComponentWithData.collectionInstanceData.numberValue.toString() == rule.compareValue) {
                                            disabled = false;
                                        }
                                        break;
                                }
                            }
                        }
                    }
                });
            }

            return <div key={"itemComponent-" + itemComponentWithData.collectionInstanceData.id + "-" + trialItemInstitutionsIndex} style={{ display: 'block' }}>
                <PrimitiveFormInput
                    key={name}
                    item={itemComponentWithData.itemComponentPrimitive}
                    id={name}
                    name={name}
                    disabled={disabled}
                    textValue={itemComponentWithData.collectionInstanceData.textValue ? itemComponentWithData.collectionInstanceData.textValue : ""}
                    bitValue={itemComponentWithData.collectionInstanceData.bitValue != null ? itemComponentWithData.collectionInstanceData.bitValue : false}
                    intValue={itemComponentWithData.collectionInstanceData.intValue != null ? itemComponentWithData.collectionInstanceData.intValue : null}
                    numberValue={itemComponentWithData.collectionInstanceData.numberValue != null ? itemComponentWithData.collectionInstanceData.numberValue : null}
                    dateValue={itemComponentWithData.collectionInstanceData.dateValue != null ? itemComponentWithData.collectionInstanceData.dateValue : null}
                    onChange={(field, value) => {
                        changeForm(reduxFormNameParent + "." + field, value);
                        changeForm(reduxFormNameParent + ".isModified", true);
                    }}
                    validationFailures={validations}
                />
            </div>;
        }

        
    }

    findItemComponentWithData(trialItemInstitutionsIndex: number, id: number) {
        const {
            form
        } = this.props;

        let result: Dtos.ItemComponentWithData = null;

        form.trialItemInstitutions[trialItemInstitutionsIndex].column1.forEach(item => {
            if (item.itemComponentPrimitive && item.itemComponentPrimitive.id == id) {
                result = item;
            }
        });

        form.trialItemInstitutions[trialItemInstitutionsIndex].column2.forEach(item => {
            if (item.itemComponentPrimitive && item.itemComponentPrimitive.id == id) {
                result = item;
            }
        });

        form.trialItemInstitutions[trialItemInstitutionsIndex].column3.forEach(item => {
            if (item.itemComponentPrimitive && item.itemComponentPrimitive.id == id) {
                result = item;
            }
        });

        return result;
    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The items hves been successfully saved to the trial</div>
    }

    handleOnSubmit(data: Dtos.TrialItemUpdate) {
        const {
            save
        } = this.props;

        if (data) {
            let changes: Dtos.TrialItemUpdate = {} as Dtos.TrialItemUpdate;
            save(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.TrialInstitution) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            trialId,
            navigate
        } = this.props;

        navigate("/trials/" + trialId + "/trialitems");
    }

    loadForm() {
        const {
            loadForm,
            trialItemUpdate,
        } = this.props

        let extraData: Dtos.TrialItemUpdate = {} as Dtos.TrialItemUpdate;

        loadForm(reduxFormName, Object.assign({ ...trialItemUpdate }, extraData));
    }
}

const mapStateToProps = (state: ITmdState, ownProps: TrialItemUpdatePageProps): ITrialItemUpdatePageProps => {

    return {

        form: state.reduxForms.trialItemUpdate,
        lookups: state.trialItemUpdate.lookups,
        formState: state.trialItemUpdate.formState,
        formProperties: state.trialItemUpdate.formProperties,
        reduxFormState: state.reduxForms.formState.trialItemUpdate,
        validationFailures: state.trialItemUpdate.validationFailures,

        match: ownProps.match,
        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,
        trialItemId: state.reduxForms.trialItemUpdate ? state.reduxForms.trialItemUpdate.trialItemId : undefined,

        history: ownProps.history,
        location: state.routing.location,

        trialItemUpdate: state.trialItemUpdate.formData,

        loading: state.trialItemUpdate.loadState && state.trialItemUpdate.loadState.status == RequestState.Pending,
        loadSuccess: state.trialItemUpdate.loadState && state.trialItemUpdate.loadState.status == RequestState.Success,
        loadFailure: state.trialItemUpdate.loadState && state.trialItemUpdate.loadState.status == RequestState.Failure,

        saving: state.trialItemUpdate.saveState && state.trialItemUpdate.saveState.status == RequestState.Pending,
        saveSuccess: state.trialItemUpdate.saveState && state.trialItemUpdate.saveState.status == RequestState.Success,
        saveFailure: state.trialItemUpdate.saveState && state.trialItemUpdate.saveState.status == RequestState.Failure,

        user: state.user.data,

        toggleItems: state.togglePage.items
    };
};

const mapDispatchToProps = (dispatch): ITrialItemUpdatePageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        load: bindActionCreators(TrialItemUpdateActions.Load, dispatch),
        clear: bindActionCreators(TrialItemUpdateActions.Clear, dispatch),
        save: bindActionCreators(TrialItemUpdateActions.Save, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch),

        toggleExpand: bindActionCreators(ToggleActions.ToggleExpanded, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialItemUpdatePage);
