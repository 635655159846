import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const templateTrialItemsApi = {
    getTemplateTrialItems: (): Observable<Dtos.GetTemplateTrialItemsResponse> => {
        //Create the request.
        let request: Dtos.GetTemplateTrialItems = new Dtos.GetTemplateTrialItems();

        //Send request.
        const response: Promise<Dtos.GetTemplateTrialItemsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTemplateTrialItemsByCategory: (): Observable<Dtos.GetTemplateTrialItemsByCategoryResponse> => {
        //Create the request.
        let request: Dtos.GetTemplateTrialItemsByCategory = new Dtos.GetTemplateTrialItemsByCategory();

        //Send request.
        const response: Promise<Dtos.GetTemplateTrialItemsByCategoryResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTemplateTrialItemById: (id: number): Observable<Dtos.GetTemplateTrialItemByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTemplateTrialItemById = new Dtos.GetTemplateTrialItemById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTemplateTrialItemByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTemplateTrialItem: (templateTrialItem: Dtos.TemplateTrialItem, collection: Dtos.Collection): Observable<Dtos.SetTemplateTrialItemResponse> => {

        let request: Dtos.SetTemplateTrialItem = new Dtos.SetTemplateTrialItem();

        request.templateTrialItem = templateTrialItem;
        request.collection = collection;

        // send request
        const response: Promise<Dtos.SetTemplateTrialItemResponse> = client.post(request);

        return Observable.from(response);

    },
    createTemplateTrialItem: (): Observable<Dtos.CreateTemplateTrialItemResponse> => {
        //Create the request.
        let request: Dtos.CreateTemplateTrialItem = new Dtos.CreateTemplateTrialItem();

        //Send request.
        const response: Promise<Dtos.CreateTemplateTrialItemResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}