import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_SALUTATIONS_LOAD,
    ACTION_SALUTATIONS_LOAD_BY_ID,

    ACTION_SALUTATIONS_SAVE,

    ACTION_SALUTATIONS_CREATE,

    ISalutationLoadAction,
    ISalutationLoadByIdAction,
    ISalutationLoadFailureAction,
    ISalutationLoadSuccessAction,

    ISalutationSaveAction,
    ISalutationSaveSuccessAction,
    ISalutationSaveFailureAction,

    ISalutationCreateAction,
    ISalutationCreateSuccessAction,
    ISalutationCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,
} from "../actions/salutations";
import { salutationsApi } from "../services/salutation";


export const
    getSalutationsEpic = (action$): Observable<ISalutationLoadSuccessAction | ISalutationLoadFailureAction> => {
        return action$
            .ofType(ACTION_SALUTATIONS_LOAD)
            .mergeMap((action: ISalutationLoadAction) => {
                return salutationsApi
                    .getSalutations()
                    .map(response =>
                        LoadSuccess(response.salutations, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getSalutationByIdEpic = (action$): Observable<ISalutationLoadSuccessAction | ISalutationLoadFailureAction> => {
        return action$
            .ofType(ACTION_SALUTATIONS_LOAD_BY_ID)
            .mergeMap((action: ISalutationLoadByIdAction) => {
                return salutationsApi
                    .getSalutationById(action.id)
                    .map(response =>
                        LoadSuccess(response.salutation, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setSalutationEpic = (action$): Observable<ISalutationSaveSuccessAction | ISalutationSaveFailureAction> => {
        return action$
            .ofType(ACTION_SALUTATIONS_SAVE)
            .mergeMap((action: ISalutationSaveAction) => {
                return salutationsApi
                    .setSalutation(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.salutation, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createSalutationEpic = (action$): Observable<ISalutationCreateSuccessAction | ISalutationCreateFailureAction> => {
        return action$
            .ofType(ACTION_SALUTATIONS_CREATE)
            .mergeMap((action: ISalutationCreateAction) => {
                return salutationsApi
                    .createSalutation()
                    .map(response =>
                        CreateSuccess(
                            response.salutation,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



