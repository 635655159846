import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_ITEMCOMPONENTTYPES_LOAD,
    ACTION_ITEMCOMPONENTTYPES_LOAD_BY_ID,

    ACTION_ITEMCOMPONENTTYPES_SAVE,

    ACTION_ITEMCOMPONENTTYPES_CREATE,

    IItemComponentTypeLoadAction,
    IItemComponentTypeLoadByIdAction,
    IItemComponentTypeLoadFailureAction,
    IItemComponentTypeLoadSuccessAction,

    IItemComponentTypeSaveAction,
    IItemComponentTypeSaveSuccessAction,
    IItemComponentTypeSaveFailureAction,

    IItemComponentTypeCreateAction,
    IItemComponentTypeCreateSuccessAction,
    IItemComponentTypeCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/itemComponentType";
import { itemComponentTypesApi } from "../services/itemComponentTypes";


export const
    getItemComponentTypesEpic = (action$): Observable<IItemComponentTypeLoadSuccessAction | IItemComponentTypeLoadFailureAction> => {
        return action$
            .ofType(ACTION_ITEMCOMPONENTTYPES_LOAD)
            .mergeMap((action: IItemComponentTypeLoadAction) => {
                return itemComponentTypesApi
                    .getItemComponentTypes()
                    .map(response =>
                        LoadSuccess(response.itemComponentTypes, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getItemComponentTypeByIdEpic = (action$): Observable<IItemComponentTypeLoadSuccessAction | IItemComponentTypeLoadFailureAction> => {
        return action$
            .ofType(ACTION_ITEMCOMPONENTTYPES_LOAD_BY_ID)
            .mergeMap((action: IItemComponentTypeLoadByIdAction) => {
                return itemComponentTypesApi
                    .getItemComponentTypeById(action.id)
                    .map(response =>
                        LoadSuccess(response.itemComponentType, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setItemComponentTypeEpic = (action$): Observable<IItemComponentTypeSaveSuccessAction | IItemComponentTypeSaveFailureAction> => {
        return action$
            .ofType(ACTION_ITEMCOMPONENTTYPES_SAVE)
            .mergeMap((action: IItemComponentTypeSaveAction) => {
                return itemComponentTypesApi
                    .setItemComponentType(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.itemComponentType, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createItemComponentTypeEpic = (action$): Observable<IItemComponentTypeCreateSuccessAction | IItemComponentTypeCreateFailureAction> => {
        return action$
            .ofType(ACTION_ITEMCOMPONENTTYPES_CREATE)
            .mergeMap((action: IItemComponentTypeCreateAction) => {
                return itemComponentTypesApi
                    .createItemComponentType()
                    .map(response =>
                        CreateSuccess(
                            response.itemComponentType,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



