import { Reducer } from 'redux';
import { update } from '../../helpers/immutabilityHelper'
import * as Dtos from '../../dtos/Tmd.dtos';

import {
    ACTION_PAGING_PAGESIZE_CHANGED,
    PagingPageAction
} from '../../actions/pages/pagingPage';

export interface IPagingPageState {
    pageSize: number;
}

const initialState: IPagingPageState = {
    pageSize: 50
};

function setPageSize(state: IPagingPageState, pageSize: number): IPagingPageState {
    const newState: IPagingPageState = update(state, {
        pageSize: {
            $set: pageSize
        }
    });
    return newState;
}

const PagingPageReducer: Reducer<IPagingPageState> = (state: IPagingPageState = initialState, action) => {
    switch (action.type) {
        case ACTION_PAGING_PAGESIZE_CHANGED:
            return setPageSize(state, action.pageSize);
    }

    return state;
}

export default PagingPageReducer;