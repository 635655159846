import './CheckboxFormInput.scss';

import * as React from "react";
import * as classNames from "classnames";
import { IBaseFormInputProps } from '../';

export interface ICheckboxFormInputProps {
    additionalContent: any;
    hideLabel: boolean;
    reverse?: boolean;
    
}

class CheckboxFormInput extends React.Component<ICheckboxFormInputProps & IBaseFormInputProps, any> {

    render() {

        const {
            additionalContent,
            hideLabel,
            className,
            disabled,
            focused,
            id,
            label,
            name,
            invalid,
            onBlur,
            onChange,
            onFocus,
            onKeyPress,
            valid,
            value,
            reverse

    } = this.props
        
        return (<div className={classNames("custom-control", "custom-checkbox", className,
            { "hide-label": hideLabel })}>
            {reverse && (
                <input
                    type="checkbox"
                    key={id}
                    name={name}
                    id={id} checked={value == false}
                    className={classNames(
                        "custom-control-input",
                        { "is-invalid": invalid }
                    )}
                    onChange={(event) => { onChange(value ? false : true) }}
                    onKeyPress={(event) => { onKeyPress && onKeyPress(true) }}
                    // eslint-disable-next-line
                    onFocus={(event) => { onFocus }}
                    // eslint-disable-next-line
                    onBlur={(event) => { onBlur }}
                    disabled={disabled}
                />
                )}
            {!reverse && (
                <input
                    type="checkbox"
                    key={id}
                    name={name}
                    id={id} checked={value == true}
                    className={classNames(
                        "custom-control-input",
                        { "is-invalid": invalid }
                    )}
                    onChange={(event) => { onChange(value ? false : true) }}
                    onKeyPress={(event) => { onKeyPress && onKeyPress(true) }}
                    // eslint-disable-next-line
                    onFocus={(event) => { onFocus }}
                    // eslint-disable-next-line
                    onBlur={(event) => { onBlur }}
                    disabled={disabled}
                />
                )}
            
            <label

                key={id + "_button"}
                htmlFor={id}
                className={classNames(
                    "custom-control-label",
                    { "disabled": disabled }
                )}
            >
                {
                    !hideLabel ?
                        label :
                        null
                }
            </label>
         </div>);
    }
}

export default CheckboxFormInput;