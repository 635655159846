import * as React from 'react';
import './FacilityAllianceMembersPage.scss';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { RowInfo } from 'react-table';
import * as FacilityAllianceMemberActions from '../../../actions/facilityAllianceMembers';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { FormFilterInput } from '../../../components/form';
import { InstitutionLookupsLayout } from '../../index';

interface IFacilityAllianceMembersPageProps {

    location: Location;
    history: History;

    // From state
    formFilter: IFormFilter;
    facilityAllianceMember: Dtos.FacilityAllianceMember[];
    loadingFacilityAllianceMembers: boolean;
    loadFacilityAllianceMembersSuccess: boolean;
    loadFacilityAllianceMembersFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    formRoles?: Dtos.RoleType[];
}

interface IFacilityAllianceMembersPageActions {
    clearFacilityAllianceMembers: FacilityAllianceMemberActions.IFacilityAllianceMemberClearActionCreator;
    loadFacilityAllianceMembers: FacilityAllianceMemberActions.IFacilityAllianceMemberLoadActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    filter: FacilityAllianceMemberActions.IFacilityAllianceMemberFilterActionCreator;
}

type FacilityAllianceMembersPageProps = IFacilityAllianceMembersPageProps & IFacilityAllianceMembersPageActions;

class FacilityAllianceMembersPage extends React.PureComponent<FacilityAllianceMembersPageProps, any> {

    constructor(props: FacilityAllianceMembersPageProps) {
        super(props);

        this.clearFacilityAllianceMembers = this.clearFacilityAllianceMembers.bind(this);
    }

    componentDidMount() {
        const {
            loadFacilityAllianceMembers
        } = this.props;

        loadFacilityAllianceMembers();
    }

    componentWillReceiveProps(nextProps: FacilityAllianceMembersPageProps) {
    }

    componentDidUpdate(prevProps: FacilityAllianceMembersPageProps, prevState) {
        const {
            navigateReplace
        } = this.props
    }

    componentWillUpdate(nextProps: FacilityAllianceMembersPageProps) {
    }

    componentWillUnmount() {
        this.clearFacilityAllianceMembers();
    }

    clearFacilityAllianceMembers() {
        this.props.clearFacilityAllianceMembers();
    }

    showFacilityAllianceMembers(): boolean {
        const {
            loadingFacilityAllianceMembers,
            loadFacilityAllianceMembersSuccess
        } = this.props

        if (!loadingFacilityAllianceMembers && loadFacilityAllianceMembersSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingFacilityAllianceMembers
        } = this.props;

        return <InstitutionLookupsLayout loading={loadingFacilityAllianceMembers} permission={Dtos.Permission.FacilityAllianceMembersView}>
            {this.renderContent()}
        </InstitutionLookupsLayout>;
    }

    renderContent() {
        const {
            permissions
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-6 mb-2"}>
                    <h2>Facility Alliance Members</h2>
                </div>
                <div className="col-6" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e) } />
                    
                    {AuthenticationHelper.hasPermission(permissions, Dtos.Permission.FacilityAllianceMembersCreate) && (
                        <Link className={"btn btn-primary"} url={"/settings/institution/facilityalliancemembers/create"}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.showFacilityAllianceMembers() ?
                    "Loading Facility Alliance Members" :
                    null
            }
            {
                this.showFacilityAllianceMembers() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {

        const {
            facilityAllianceMember
        } = this.props

        if (!facilityAllianceMember || facilityAllianceMember.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no facility alliance members to view.</span>
            </div>
        }

        const showPagination = facilityAllianceMember.length > DEFAULT_PAGE_SIZE;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={facilityAllianceMember}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : facilityAllianceMember.length}
                pageSize={showPagination ? undefined : facilityAllianceMember.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no facilityAllianceMember to display"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {

                            const url: string = "/settings/institution/facilityalliancemembers/" + rowInfo.original.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.LOOKUP} fixedWidth />
                    },
                    {
                        id: "value",
                        Header: 'Name',
                        accessor: 'value',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    }
                ]}
            />
        </div>

    }

}

const mapStateToProps = (state: ITmdState, ownProps: FacilityAllianceMembersPageProps): IFacilityAllianceMembersPageProps => {

    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        history: ownProps.history,
        location: state.routing.location,

        formFilter: state.facilityAllianceMembers.formFilter,
        facilityAllianceMember: state.facilityAllianceMembers.formFilteredData, 

        loadingFacilityAllianceMembers: state.facilityAllianceMembers.loadState && state.facilityAllianceMembers.loadState.status == RequestState.Pending,
        loadFacilityAllianceMembersSuccess: state.facilityAllianceMembers.loadState && state.facilityAllianceMembers.loadState.status == RequestState.Success,
        loadFacilityAllianceMembersFailure: state.facilityAllianceMembers.loadState && state.facilityAllianceMembers.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles
    };
};

const mapDispatchToProps = (dispatch): IFacilityAllianceMembersPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadFacilityAllianceMembers: bindActionCreators(FacilityAllianceMemberActions.LoadFacilityAllianceMember, dispatch),
        clearFacilityAllianceMembers: bindActionCreators(FacilityAllianceMemberActions.Clear, dispatch),

        filter: bindActionCreators(FacilityAllianceMemberActions.Filter, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(FacilityAllianceMembersPage);
