import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Tmd.dtos';

import {
    ACTION_TRIALS_LOAD,
    ACTION_TRIALS_LOAD_CURRENT_USER,
    ACTION_TRIALS_LOAD_BY_ID,
    ACTION_TRIALS_LOAD_FAILURE,
    ACTION_TRIALS_LOAD_SUCCESS,
    ACTION_TRIALS_WITH_TRIALITEMINSTANCES_LOAD_BY_ID,
    ACTION_TRIALS_WITH_TRIALITEMINSTANCES_LOAD_SUCCESS,

    ACTION_TRIALS_SAVE,
    ACTION_TRIALS_SAVE_SUCCESS,
    ACTION_TRIALS_SAVE_FAILURE,

    ACTION_TRIALS_CREATE,
    ACTION_TRIALS_CREATE_SUCCESS,
    ACTION_TRIALS_CREATE_FAILURE,

    ACTION_TRIALS_CLEAR,
    ACTION_TRIALS_FILTER,
    ACTION_TRIALS_PERSONNEL_ROLES_FILTER,

    TrialAction
} from '../actions/trial';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState, IFormFilter } from "./index";
import { updateFailureIndexToId } from "../helpers/formErrorHelper";

export interface ITrialState {
    formData: Dtos.Trial | Dtos.Trial[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    formRoles: Dtos.RoleType[],
    validationFailures: Dtos.ResponseError[];
    lookups: Dtos.Lookup[],
    loadState: IRequestState;
    saveState: IRequestState;
    updateState: IRequestState;
    createState: IRequestState;
    formFilter: IFormFilter;
    formFilteredData: Dtos.Trial[];
    formLength: number;
    trialItemInstances: Dtos.TrialItemInstanceCategory[];
    templateTrialItems: Dtos.TemplateTrialItem[];
    formFilterPersonnelRoles: IFormFilter;
    formFilteredPersonnelRoles: Dtos.PersonnelRoleSummary[];
    formLengthPersonnelRoles: number;
}

const initialState: ITrialState = {
    formData: undefined,
    formState: undefined,
    formProperties: undefined,
    formRoles: undefined,
    validationFailures: undefined,
    lookups: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    updateState: {
        status: RequestState.None
    } as IRequestState,
    createState: {
        status: RequestState.None
    } as IRequestState,
    formFilter: {
        search: "",
        showInActive: false
    },
    formFilteredData: [],
    formLength: 0,
    trialItemInstances: [],
    templateTrialItems: [],
    formFilterPersonnelRoles: {
        search: "",
        showInActive: false
    },
    formFilteredPersonnelRoles: [],
    formLengthPersonnelRoles: 0
};


function loading(state: ITrialState, isLoading: boolean): ITrialState {
    const newState: ITrialState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(state: ITrialState,
    formData: Dtos.Trial | Dtos.Trial[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    formRoles: Dtos.RoleType[],
    lookups: Dtos.Lookup[],
    responseStatus: Dtos.ResponseStatus
): ITrialState {

    // if switching between list and details, reset the list
    var isList: boolean = formData instanceof Array ? true : false;
    const formFilterPersonnelRoles = isList ? {
        search: "",
        showInActive: false
    } : state.formFilterPersonnelRoles;

    const newState: ITrialState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        formRoles: {
            $set: formRoles
        },
        lookups: {
            $set: lookups
        },
        loadState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        },
        formFilterPersonnelRoles: {
            $set: formFilterPersonnelRoles
        }
    });

    return filterPersonnelRoles(filter(newState, state.formFilter), newState.formFilterPersonnelRoles);
}

function loadTrialItemInstancesSuccess(state: ITrialState,
    trialItemInstances: Dtos.TrialItemInstanceCategory[],
    templateTrialItems: Dtos.TemplateTrialItem[]
): ITrialState {
    const newState: ITrialState = update(state, {
        trialItemInstances: {
            $set: trialItemInstances
        },
        templateTrialItems: {
            $set: templateTrialItems
        }
    });

    return filter(newState, state.formFilter);
}

function filter(state: ITrialState, formFilter: IFormFilter): ITrialState {

    const trials: Dtos.Trial[] | undefined =
        state.formData instanceof Array ?
            state.formData :
            undefined;

    const formLength = trials ? trials.length : 0;

    const formFilteredData = trials ? trials.filter(p => (p.active == true || formFilter.showInActive) &&
        (formFilter.search == "" || p.display.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1)) : undefined;

    const newState: ITrialState = update(state, {
        formFilter: {
            $set: formFilter
        },
        formFilteredData: {
            $set: formFilteredData
        },
        formLength: {
            $set: formLength
        }
    });

    return newState;
}

function filterPersonnelRoles(state: ITrialState, formFilter: IFormFilter): ITrialState {

    let trial: Dtos.Trial | undefined =
        state.formData instanceof Array ?
            undefined : JSON.parse(JSON.stringify(state.formData));

    let personnelRoles: Dtos.PersonnelRoleSummary[] =
        trial ? [...trial.personnelRoleSummaries] : [];

    const formLengthPersonnelRoles = personnelRoles ? personnelRoles.length : 0;

    personnelRoles.forEach(item => {
        var personnelRoleSummaries = item.personnelRoleSummaries.filter(p => (p.personnelRole.active == true || formFilter.showInActive) &&
            (
                formFilter.search == ""
                || p.personnelRole.roleDisplay.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1
                || p.personnelRole.personnelDisplay.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1
            )
        );
        item.personnelRoleSummaries = [...personnelRoleSummaries];
    });

    let formFilteredPersonnelRoles = personnelRoles.filter(p => p.personnelRoleSummaries.length > 0);

    const newState: ITrialState = update(state, {
        formFilterPersonnelRoles: {
            $set: formFilter
        },
        formFilteredPersonnelRoles: {
            $set: formFilteredPersonnelRoles
        },
        formLengthPersonnelRoles: {
            $set: formLengthPersonnelRoles
        }
    });

    return newState;
}

function loadFailure(state: ITrialState, responseStatus: Dtos.ResponseStatus): ITrialState {
    const newState: ITrialState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: ITrialState, propertiesToClear?: string[]): ITrialState {

    if (!propertiesToClear) {
        const newState: ITrialState = update(initialState, {});
        return newState;
    }

    let stateModifier: any = {};

    propertiesToClear.forEach(propertyToClear => {
        stateModifier[propertyToClear] = { $set: initialState[propertyToClear] }
    });

    const newState = update(state, stateModifier);

    return newState;
}

function saving(state: ITrialState, isSaving: boolean): ITrialState {
    const newState: ITrialState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function saveSuccess(
    state: ITrialState,
    formData: Dtos.Trial | Dtos.Trial[],
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus
): ITrialState {
    const newState: ITrialState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        saveState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function saveFailure(state: ITrialState, responseStatus: Dtos.ResponseStatus): ITrialState {
    const newState: ITrialState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                meta: responseStatus.meta
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, state.formData) : undefined
        }
    });

    return newState;
}

function creating(state: ITrialState, isCreating: boolean): ITrialState {
    const newState: ITrialState = update(state, {
        createState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function createSuccess(
    state: ITrialState,
    formData: Dtos.Trial,
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    formRoles: Dtos.RoleType[],
    lookups: Dtos.Lookup[]
): ITrialState {
    const newState: ITrialState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        formRoles: {
            $set: formRoles
        },
        lookups: {
            $set: lookups
        },
        createState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function createFailure(state: ITrialState, responseStatus: Dtos.ResponseStatus): ITrialState {
    const newState: ITrialState = update(state, {
        createState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

const TrialReducer: Reducer<ITrialState> = (state: ITrialState = initialState, action: TrialAction) => {
    switch (action.type) {
        case ACTION_TRIALS_LOAD:
        case ACTION_TRIALS_LOAD_CURRENT_USER:
        case ACTION_TRIALS_WITH_TRIALITEMINSTANCES_LOAD_BY_ID:
        case ACTION_TRIALS_LOAD_BY_ID:
            return loading(state, true);
        case ACTION_TRIALS_LOAD_SUCCESS:
            return loadSuccess(state, action.formData, action.formState, action.formProperties, action.formRoles, action.lookups, action.responseStatus);
        case ACTION_TRIALS_WITH_TRIALITEMINSTANCES_LOAD_SUCCESS:
            return loadTrialItemInstancesSuccess(
                loadSuccess(state, action.formData, action.formState, action.formProperties, action.formRoles, action.lookups, action.responseStatus)
                , action.trialItemInstances, action.templateTrialItems);
        case ACTION_TRIALS_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_TRIALS_CLEAR:
            return clear(state, action.propertiesToClear);
        case ACTION_TRIALS_SAVE:
            return saving(state, true);
        case ACTION_TRIALS_SAVE_SUCCESS:
            return saveSuccess(state, action.formData, action.formState, action.responseStatus);
        case ACTION_TRIALS_SAVE_FAILURE:
            return saveFailure(state, action.responseStatus);
        case ACTION_TRIALS_CREATE:
            return creating(state, true);
        case ACTION_TRIALS_CREATE_SUCCESS:
            return createSuccess(state, action.formData, action.formState, action.formProperties, action.formRoles, action.lookups);
        case ACTION_TRIALS_CREATE_FAILURE:
            return createFailure(state, action.responseStatus);
        case ACTION_TRIALS_FILTER:
            return filter(state, action.formFilter);
        case ACTION_TRIALS_PERSONNEL_ROLES_FILTER:
            return filterPersonnelRoles(state, action.formFilter);
    }

    return state;
}

export default TrialReducer;