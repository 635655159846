import * as Dtos from "../../dtos/Tmd.dtos";

export const
    setEditModalViewState: ITrialFormsPageSetEditModalViewStateActionCreator = (open, collectionInstanceId, name, description) => {
        return {
            type: ACTION_TRIALFORMS_PAGE_SET_EDIT_MODAL_VIEW_STATE,
            open,
            collectionInstanceId,
            name,
            description
        }
    },
    setCreateVersionModalViewState: ITrialFormsPageSetCreateVersionModalViewStateActionCreator = (open, trialFormId, version, copyData) => {
        return {
            type: ACTION_TRIALFORMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE,
            open,
            trialFormId,
            version,
            copyData
        }
    }

export const
    ACTION_TRIALFORMS_PAGE_SET_EDIT_MODAL_VIEW_STATE = "ACTION_TRIALFORMS_PAGE_SET_EDIT_MODAL_VIEW_STATE",
    ACTION_TRIALFORMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE = "ACTION_TRIALFORMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE"

/* Actions */

export interface ITrialFormsPageSetEditModalViewStateAction {
    type: "ACTION_TRIALFORMS_PAGE_SET_EDIT_MODAL_VIEW_STATE",
    open: boolean;
    collectionInstanceId: number;
    name: string;
    description: string;
}


export interface ITrialFormsPageSetCreateVersionModalViewStateAction {
    type: "ACTION_TRIALFORMS_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE",
    open: boolean;
    trialFormId: number;
    version: string;
    copyData: boolean;
}

/* Action Creators */

export interface ITrialFormsPageSetEditModalViewStateActionCreator {
    (
        open: boolean,
        collectionIntanceId: number,
        name: string,
        description: string
    ): ITrialFormsPageSetEditModalViewStateAction;
}

export interface ITrialFormsPageSetCreateVersionModalViewStateActionCreator {
    (
        open: boolean,
        trialFormId: number,
        version: string,
        copyData: boolean,
    ): ITrialFormsPageSetCreateVersionModalViewStateAction;
}


/* Final Export of actions */
export type TrialFormsPageAction =
    ITrialFormsPageSetEditModalViewStateAction |
    ITrialFormsPageSetCreateVersionModalViewStateAction;
