import * as React from 'react';

import * as classNames from "classnames";
import './ItemComponentTypesPage.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';



import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";

import * as ItemComponentTypeActions from '../../../actions/itemComponentType';
import * as PagingActions from '../../../actions/pages/pagingPage';

import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput, FormFilterInput } from '../../../components/form';

import { LookupsLayout } from '../../index';

interface IItemComponentTypesPageProps {

    location: Location;
    history: History;

    // From state
    itemComponentTypes: Dtos.ItemComponentType[];
    formFilter: IFormFilter;
    formLength: number;

    loadingItemComponentTypes: boolean;
    loadItemComponentTypesSuccess: boolean;
    loadItemComponentTypesFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    formRoles?: Dtos.RoleType[];

    pageSize: number;
}

interface IItemComponentTypesPageActions {
    clearItemComponentTypes: ItemComponentTypeActions.IItemComponentTypeClearActionCreator;
    loadItemComponentTypes: ItemComponentTypeActions.IItemComponentTypeLoadActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    filter: ItemComponentTypeActions.IItemComponentTypeFilterActionCreator;

    setPageSize: PagingActions.IPagingPageSizeChangedStateActionCreator;
}

type ItemComponentTypesPageProps = IItemComponentTypesPageProps & IItemComponentTypesPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}

class ItemComponentTypesPage extends React.PureComponent<ItemComponentTypesPageProps, any> {

    constructor(props: ItemComponentTypesPageProps) {
        super(props);

        this.clearItemComponentTypes = this.clearItemComponentTypes.bind(this);
    }

    componentDidMount() {
        const {
            loadItemComponentTypes
        } = this.props;

        loadItemComponentTypes();
    }

    componentWillReceiveProps(nextProps: ItemComponentTypesPageProps) {
    }

    componentDidUpdate(prevProps: ItemComponentTypesPageProps, prevState) {
        const {
            navigateReplace
        } = this.props
    }

    componentWillUpdate(nextProps: ItemComponentTypesPageProps) {
    }

    componentWillUnmount() {
        this.clearItemComponentTypes();
    }

    clearItemComponentTypes() {
        this.props.clearItemComponentTypes();
    }

    showItemComponentTypes(): boolean {
        const {
            loadingItemComponentTypes,
            loadItemComponentTypesSuccess
        } = this.props

        if (!loadingItemComponentTypes && loadItemComponentTypesSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingItemComponentTypes
        } = this.props;


        return <LookupsLayout loading={loadingItemComponentTypes} permission={Dtos.Permission.ItemComponentTypesView}>
            {this.renderContent()}
        </LookupsLayout>;
    }

    renderContent() {
        const {
            permissions
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-6 mb-2"}>
                    <h2>Item Component Types</h2>
                </div>
                <div className="col-6" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} />
                    {AuthenticationHelper.hasPermission(permissions, Dtos.Permission.ItemComponentTypesCreate) && (
                        <Link className={"btn btn-primary"} url={"/settings/general/itemComponenttypes/create"}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.showItemComponentTypes() ?
                    "Loading Item Component Types" :
                    null
            }
            {
                this.showItemComponentTypes() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {

        const {
            itemComponentTypes,
            pageSize,
            setPageSize
        } = this.props

        if (!itemComponentTypes || itemComponentTypes.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no ItemComponent Types to view.</span>
            </div>
        }

        const showPagination = itemComponentTypes.length > pageSize;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={itemComponentTypes}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? pageSize : itemComponentTypes.length}
                pageSize={showPagination ? pageSize : itemComponentTypes.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no Item Component Types to display"
                resizable={false}
                onPageSizeChange={val => setPageSize(val)}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {

                            const url: string = "/settings/general/itemComponenttypes/" + rowInfo.original.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.LOOKUP} fixedWidth />
                    },
                    {
                        id: "value",
                        Header: 'Name',
                        accessor: 'value',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    }
                ]}
            />
        </div>

    }

}

const mapStateToProps = (state: ITmdState, ownProps: ItemComponentTypesPageProps): IItemComponentTypesPageProps => {

    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];
    return {

        history: ownProps.history,
        location: state.routing.location,

        itemComponentTypes: state.itemComponentTypes.formFilteredData!,
        formFilter: state.itemComponentTypes.formFilter,
        formLength: state.itemComponentTypes.formLength,

        loadingItemComponentTypes: state.itemComponentTypes.loadState && state.itemComponentTypes.loadState.status == RequestState.Pending,
        loadItemComponentTypesSuccess: state.itemComponentTypes.loadState && state.itemComponentTypes.loadState.status == RequestState.Success,
        loadItemComponentTypesFailure: state.itemComponentTypes.loadState && state.itemComponentTypes.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles,

        pageSize: state.pagingPage.pageSize
    };
};

const mapDispatchToProps = (dispatch): IItemComponentTypesPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadItemComponentTypes: bindActionCreators(ItemComponentTypeActions.LoadItemComponentType, dispatch),
        clearItemComponentTypes: bindActionCreators(ItemComponentTypeActions.Clear, dispatch),

        filter: bindActionCreators(ItemComponentTypeActions.Filter, dispatch),

        setPageSize: bindActionCreators(PagingActions.PageSizeChanged, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(ItemComponentTypesPage);
