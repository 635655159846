import * as React from 'react';
import './TrialItemInstanceCreateVersion.scss';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { SubPageLayout } from "../../../components/layouts/index";
import { ITrialItemInstanceReduxFormState, initialTrialItemInstanceState } from "../../../reducers/reactReduxForms/trialItemInstance";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, DatePickerFormInputFabric, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as TrialItemInstanceActions from '../../../actions/trialItemInstance';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import { Form, actions, Control } from 'react-redux-form';


interface ITrialItemInstanceCreateVersionProps {
    trialItemInstanceId?: number;
    trialId?: number;
    templateTrialItemId?: number;
    instanceNumber?: number;

    form?: Dtos.TrialItemInstance & IRegistrationFormState;
    formState?: Dtos.FormState;
    formProperties?: Dtos.FormProperty[];
    reduxFormState?: ITrialItemInstanceReduxFormState;

    location?: Location;
    history?: History;

    // From state
    trialItemInstance?: Dtos.TrialItemInstance;
    lookups?: Dtos.Lookup[];

    loadingTrialItemInstance?: boolean;
    loadTrialItemInstanceSuccess?: boolean;
    loadTrialItemInstanceFailure?: boolean;

    savingTrialItemInstance?: boolean;
    saveTrialItemInstanceSuccess?: boolean;
    saveTrialItemInstanceFailure?: boolean;

    creatingTrialItemInstance?: boolean;
    createTrialItemInstanceSuccess?: boolean;
    createTrialItemInstanceFailure?: boolean;

    validationFailures?: Dtos.ResponseError[];

    onClose?: Function;

    user?: Dtos.User
}

interface ITrialItemInstanceCreateVersionActions {
    clearTrialItemInstance?: TrialItemInstanceActions.ITrialItemInstanceClearActionCreator;
    loadTrialItemInstance?: TrialItemInstanceActions.ITrialItemInstanceLoadByIdActionCreator;
    saveTrialItemInstance?: TrialItemInstanceActions.ITrialItemInstanceSaveActionCreator;
    createTrialItemInstance?: TrialItemInstanceActions.ITrialItemInstanceCreateActionCreator;

    loadForm?: typeof actions.load;
    changeForm?: typeof actions.change;
    resetForm?: typeof actions.reset;

    navigate?: typeof routerActions.push;
    navigateReplace?: typeof routerActions.replace;

}

type TrialItemInstanceCreateVersionProps = ITrialItemInstanceCreateVersionProps & ITrialItemInstanceCreateVersionActions;

const reduxFormName: string = "reduxForms.trialItemInstance";

class TrialItemInstanceCreateVersion extends React.PureComponent<TrialItemInstanceCreateVersionProps, any> {

    constructor(props: TrialItemInstanceCreateVersionProps) {
        super(props);

        this.clearTrialItemInstance = this.clearTrialItemInstance.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            createTrialItemInstance,
            trialId,
            templateTrialItemId,
            instanceNumber
        } = this.props;

        if (trialId && templateTrialItemId) {
            createTrialItemInstance(trialId, templateTrialItemId, instanceNumber);
        }
    }

    componentDidUpdate(prevProps: TrialItemInstanceCreateVersionProps, prevState) {
        const {
            trialId,
            templateTrialItemId,
            instanceNumber,
            createTrialItemInstance,
            creatingTrialItemInstance,
            createTrialItemInstanceSuccess,
            savingTrialItemInstance,
            saveTrialItemInstanceSuccess,
            saveTrialItemInstanceFailure,
            validationFailures,
        } = this.props;

        if ((trialId && prevProps.trialId != trialId) ||
            (templateTrialItemId && prevProps.templateTrialItemId != templateTrialItemId) ||
            (instanceNumber && prevProps.instanceNumber != instanceNumber)
        ) {
            createTrialItemInstance(trialId, templateTrialItemId, instanceNumber);
        }

        if (!creatingTrialItemInstance && prevProps.creatingTrialItemInstance) {
            if (createTrialItemInstanceSuccess) {
                this.loadForm();
            }
        }

        if (!savingTrialItemInstance && prevProps.savingTrialItemInstance) {
            if (saveTrialItemInstanceSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                this.onCancel();
            }
            else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearTrialItemInstance();
        loadForm(reduxFormName, initialTrialItemInstanceState);
        resetForm(reduxFormName);
    }

    clearTrialItemInstance() {
        this.props.clearTrialItemInstance();
    }

    showTrialItemInstance(): boolean {
        const {
            loadingTrialItemInstance,
            loadTrialItemInstanceSuccess
        } = this.props

        if (!loadingTrialItemInstance && loadTrialItemInstanceSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingTrialItemInstance,
        } = this.props;

        return <SubPageLayout loading={loadingTrialItemInstance}>
            {this.renderContent()}
        </SubPageLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingTrialItemInstance
        } = this.props;

        const formDisabled: boolean = savingTrialItemInstance || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            version: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputLabel: "Version",
                inputDisabled: formDisabled,
            },
            versionDate: {
                type: RegistrationComponent.RegistrationControl,
                inputType: DatePickerFormInputFabric,
                inputDisabled: formDisabled,
                inputLabel: "Version Date",
                size: 2
            },
        };

        return <div>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "trialItemInstance", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
            
        </div>

    }


    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.TrialItemInstance) {
        const {
            saveTrialItemInstance,
        } = this.props;

        if (data) {
            let changes: Dtos.TrialItemInstance = {} as Dtos.TrialItemInstance;
            saveTrialItemInstance(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.TrialItemInstance) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        if (this.props.onClose) { this.props.onClose() }
    }

    loadForm() {
        const {
            loadForm,
            trialItemInstance,
        } = this.props

        let extraData: Dtos.TrialItemInstance = {} as Dtos.TrialItemInstance;

        loadForm(reduxFormName, Object.assign({ ...trialItemInstance }, extraData));
    }
}


const mapStateToProps = (state: ITmdState, ownProps: TrialItemInstanceCreateVersionProps): ITrialItemInstanceCreateVersionProps => {

    let trialItemInstance: Dtos.TrialItemInstance | undefined = !(state.trialItemInstance.formData instanceof Array) ? state.trialItemInstance.formData : undefined;
    
    return {
        trialItemInstanceId: ownProps.trialItemInstanceId ? ownProps.trialItemInstanceId : undefined!,
        trialId: ownProps.trialId ? ownProps.trialId : undefined!,
        templateTrialItemId: ownProps.templateTrialItemId ? ownProps.templateTrialItemId : undefined!,
        instanceNumber: ownProps.instanceNumber ? ownProps.instanceNumber : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trialItemInstance: trialItemInstance!,

        loadingTrialItemInstance: state.trialItemInstance.loadState && state.trialItemInstance.loadState.status == RequestState.Pending,
        loadTrialItemInstanceSuccess: state.trialItemInstance.loadState && state.trialItemInstance.loadState.status == RequestState.Success,
        loadTrialItemInstanceFailure: state.trialItemInstance.loadState && state.trialItemInstance.loadState.status == RequestState.Failure,

        savingTrialItemInstance: state.trialItemInstance.saveState && state.trialItemInstance.saveState.status == RequestState.Pending,
        saveTrialItemInstanceSuccess: state.trialItemInstance.saveState && state.trialItemInstance.saveState.status == RequestState.Success,
        saveTrialItemInstanceFailure: state.trialItemInstance.saveState && state.trialItemInstance.saveState.status == RequestState.Failure,

        creatingTrialItemInstance: state.trialItemInstance.createState && state.trialItemInstance.createState.status == RequestState.Pending,
        createTrialItemInstanceSuccess: state.trialItemInstance.createState && state.trialItemInstance.createState.status == RequestState.Success,
        createTrialItemInstanceFailure: state.trialItemInstance.createState && state.trialItemInstance.createState.status == RequestState.Failure,

        user: state.user.data,
        lookups: state.trialItemInstance.lookups,

        form: state.reduxForms.trialItemInstance,
        formState: state.trialItemInstance.formState,
        formProperties: state.trialItemInstance.formProperties,

        reduxFormState: state.reduxForms.formState.trialItemInstance,
        validationFailures: state.trialItemInstance.validationFailures,

    };
};

const mapDispatchToProps = (dispatch): ITrialItemInstanceCreateVersionActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadTrialItemInstance: bindActionCreators(TrialItemInstanceActions.LoadTrialItemInstanceById, dispatch),
        clearTrialItemInstance: bindActionCreators(TrialItemInstanceActions.Clear, dispatch),

        saveTrialItemInstance: bindActionCreators(TrialItemInstanceActions.SaveTrialItemInstance, dispatch),
        createTrialItemInstance: bindActionCreators(TrialItemInstanceActions.CreateTrialItemInstance, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialItemInstanceCreateVersion);
