import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trialGroupsApi = {
    getTrialGroupById: (id: number): Observable<Dtos.GetTrialGroupByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrialGroupById = new Dtos.GetTrialGroupById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTrialGroupByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrialGroup: (trialGroup: Dtos.TrialGroup): Observable<Dtos.SetTrialGroupResponse> => {

        let request: Dtos.SetTrialGroup = new Dtos.SetTrialGroup();

        request.trialGroup = trialGroup;

        // send request
        const response: Promise<Dtos.SetTrialGroupResponse> = client.post(request);

        return Observable.from(response);

    },
    createTrialGroup: (trialId: number): Observable<Dtos.CreateTrialGroupResponse> => {
        //Create the request.
        let request: Dtos.CreateTrialGroup = new Dtos.CreateTrialGroup();

        request.trialId = trialId;

        //Send request.
        const response: Promise<Dtos.CreateTrialGroupResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}