import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialTrialItemImportState: Dtos.TrialItemImport & IRegistrationFormState = {
    id: null,
    trialId: null,
    trial: null,
    active: null,
    addGroups: null,
    display: null,
    selectedItems: [],
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false
}

export interface ITrialItemImportReduxFormState {
    id: IFieldState<number>,
    trialId: IFieldState<number>,
    trial: IFieldState<Dtos.Trial>,
    addGroups: IFieldState<boolean>,
    display: IFieldState<string>,
    active: IFieldState<boolean>,
    selectedItems: IFieldState<number[]>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>
}
