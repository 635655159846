import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_TRIALITEMIMPORT_LOAD_BY_ID,

    ACTION_TRIALITEMIMPORT_SAVE,

    ITrialItemImportLoadByIdAction,
    ITrialItemImportLoadFailureAction,
    ITrialItemImportLoadSuccessAction,

    ITrialItemImportSaveAction,
    ITrialItemImportSaveSuccessAction,
    ITrialItemImportSaveFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

} from "../actions/trialItemImport";
import { trialItemImportApi } from "../services/trialItemImport";


export const
    getTrialItemImportByIdEpic = (action$): Observable<ITrialItemImportLoadSuccessAction | ITrialItemImportLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALITEMIMPORT_LOAD_BY_ID)
            .mergeMap((action: ITrialItemImportLoadByIdAction) => {
                return trialItemImportApi
                    .getTrialItemImportById(action.id, action.trialId)
                    .map(response =>
                        LoadSuccess(response.trialItemImport, response.formState, response.formProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setTrialItemImportEpic = (action$): Observable<ITrialItemImportSaveSuccessAction | ITrialItemImportSaveFailureAction> => {
        return action$
            .ofType(ACTION_TRIALITEMIMPORT_SAVE)
            .mergeMap((action: ITrialItemImportSaveAction) => {
                return trialItemImportApi
                    .setTrialItemImport(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.trialItemImport, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    };

