import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadFacilityAllianceMember: IFacilityAllianceMemberLoadActionCreator = () => {
        return {
            type: ACTION_FACILITYALLIANCEMEMBERS_LOAD
        }
    },
    LoadFacilityAllianceMemberById: IFacilityAllianceMemberLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_FACILITYALLIANCEMEMBERS_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IFacilityAllianceMemberLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_FACILITYALLIANCEMEMBERS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IFacilityAllianceMemberLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_FACILITYALLIANCEMEMBERS_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveFacilityAllianceMember: IFacilityAllianceMemberSaveActionCreator = (formData) => {
        return {
            type: ACTION_FACILITYALLIANCEMEMBERS_SAVE,
            formData
        }
    },
    SaveSuccess: IFacilityAllianceMemberSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_FACILITYALLIANCEMEMBERS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IFacilityAllianceMemberSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_FACILITYALLIANCEMEMBERS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateFacilityAllianceMember: IFacilityAllianceMemberCreateActionCreator = () => {

        return {
            type: ACTION_FACILITYALLIANCEMEMBERS_CREATE
        }
    },
    CreateSuccess: IFacilityAllianceMemberCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_FACILITYALLIANCEMEMBERS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: IFacilityAllianceMemberCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_FACILITYALLIANCEMEMBERS_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IFacilityAllianceMemberClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_FACILITYALLIANCEMEMBERS_CLEAR,
            propertiesToClear
        }
    },
    Filter: IFacilityAllianceMemberFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_FACILITYALLIANCEMEMBERS_FILTER,
            formFilter
        }
    };



export const
    ACTION_FACILITYALLIANCEMEMBERS_LOAD = "ACTION_FACILITYALLIANCEMEMBERS_LOAD",
    ACTION_FACILITYALLIANCEMEMBERS_LOAD_BY_ID = "ACTION_FACILITYALLIANCEMEMBERS_LOAD_BY_ID",
    ACTION_FACILITYALLIANCEMEMBERS_LOAD_SUCCESS = "ACTION_FACILITYALLIANCEMEMBERS_LOAD_SUCCESS",
    ACTION_FACILITYALLIANCEMEMBERS_LOAD_FAILURE = "ACTION_FACILITYALLIANCEMEMBERS_LOAD_FAILURE",

    ACTION_FACILITYALLIANCEMEMBERS_SAVE = "ACTION_FACILITYALLIANCEMEMBERS_SAVE",
    ACTION_FACILITYALLIANCEMEMBERS_SAVE_SUCCESS = "ACTION_FACILITYALLIANCEMEMBERS_SAVE_SUCCESS",
    ACTION_FACILITYALLIANCEMEMBERS_SAVE_FAILURE = "ACTION_FACILITYALLIANCEMEMBERS_SAVE_FAILURE",

    ACTION_FACILITYALLIANCEMEMBERS_CREATE = "ACTION_FACILITYALLIANCEMEMBERS_CREATE",
    ACTION_FACILITYALLIANCEMEMBERS_CREATE_SUCCESS = "ACTION_FACILITYALLIANCEMEMBERS_CREATE_SUCCESS",
    ACTION_FACILITYALLIANCEMEMBERS_CREATE_FAILURE = "ACTION_FACILITYALLIANCEMEMBERS_CREATE_FAILURE",

    ACTION_FACILITYALLIANCEMEMBERS_CLEAR = "ACTION_FACILITYALLIANCEMEMBERS_CLEAR",

    ACTION_FACILITYALLIANCEMEMBERS_FILTER = "ACTION_FACILITYALLIANCEMEMBERS_FILTER";

export interface IFacilityAllianceMemberLoadAction {
    type: "ACTION_FACILITYALLIANCEMEMBERS_LOAD";
}

export interface IFacilityAllianceMemberLoadActionCreator {
    (): IFacilityAllianceMemberLoadAction;
}

export interface IFacilityAllianceMemberLoadSuccessAction {
    type: "ACTION_FACILITYALLIANCEMEMBERS_LOAD_SUCCESS";
    formData: Dtos.FacilityAllianceMember | Dtos.FacilityAllianceMember[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IFacilityAllianceMemberLoadSuccessActionCreator {
    (
        formData: Dtos.FacilityAllianceMember | Dtos.FacilityAllianceMember[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IFacilityAllianceMemberLoadSuccessAction;
}

export interface IFacilityAllianceMemberLoadFailureAction {
    type: "ACTION_FACILITYALLIANCEMEMBERS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IFacilityAllianceMemberLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IFacilityAllianceMemberLoadFailureAction;
}

export interface IFacilityAllianceMemberLoadByIdAction {
    type: "ACTION_FACILITYALLIANCEMEMBERS_LOAD_BY_ID";
    id: number;
}

export interface IFacilityAllianceMemberLoadByIdActionCreator {
    (id: number): IFacilityAllianceMemberLoadByIdAction;
}


export interface IFacilityAllianceMemberSaveAction {
    type: "ACTION_FACILITYALLIANCEMEMBERS_SAVE";
    formData: Dtos.FacilityAllianceMember;

}

export interface IFacilityAllianceMemberSaveActionCreator {
    (
        formData: Dtos.FacilityAllianceMember
    ): IFacilityAllianceMemberSaveAction;
}

export interface IFacilityAllianceMemberSaveSuccessAction {
    type: "ACTION_FACILITYALLIANCEMEMBERS_SAVE_SUCCESS";
    formData: Dtos.FacilityAllianceMember;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IFacilityAllianceMemberSaveSuccessActionCreator {
    (
        formData: Dtos.FacilityAllianceMember,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IFacilityAllianceMemberSaveSuccessAction;
}

export interface IFacilityAllianceMemberSaveFailureAction {
    type: "ACTION_FACILITYALLIANCEMEMBERS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IFacilityAllianceMemberSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IFacilityAllianceMemberSaveFailureAction;
}

export interface IFacilityAllianceMemberCreateAction {
    type: "ACTION_FACILITYALLIANCEMEMBERS_CREATE"
}

export interface IFacilityAllianceMemberCreateActionCreator {
    (): IFacilityAllianceMemberCreateAction
}

export interface IFacilityAllianceMemberCreateSuccessAction {
    type: "ACTION_FACILITYALLIANCEMEMBERS_CREATE_SUCCESS";
    formData: Dtos.FacilityAllianceMember;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface IFacilityAllianceMemberCreateSuccessActionCreator {
    (
        formData: Dtos.FacilityAllianceMember,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): IFacilityAllianceMemberCreateSuccessAction;
}

export interface IFacilityAllianceMemberCreateFailureAction {
    type: "ACTION_FACILITYALLIANCEMEMBERS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IFacilityAllianceMemberCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IFacilityAllianceMemberCreateFailureAction;
}

export interface IFacilityAllianceMemberClearAction {
    type: "ACTION_FACILITYALLIANCEMEMBERS_CLEAR";
    propertiesToClear?: string[];
}

export interface IFacilityAllianceMemberClearActionCreator {
    (propertiesToClear?: string[]): IFacilityAllianceMemberClearAction;
}

export interface IFacilityAllianceMemberFilterActionCreator {
    (formFilter: IFormFilter): IFacilityAllianceMemberFilterAction;
}

export interface IFacilityAllianceMemberFilterAction {
    type: "ACTION_FACILITYALLIANCEMEMBERS_FILTER";
    formFilter: IFormFilter;
}

export type FacilityAllianceMemberAction =
    IFacilityAllianceMemberLoadAction |
    IFacilityAllianceMemberLoadByIdAction |
    IFacilityAllianceMemberLoadFailureAction |
    IFacilityAllianceMemberLoadSuccessAction |
    IFacilityAllianceMemberSaveAction |
    IFacilityAllianceMemberSaveSuccessAction |
    IFacilityAllianceMemberSaveFailureAction |
    IFacilityAllianceMemberCreateAction |
    IFacilityAllianceMemberCreateSuccessAction |
    IFacilityAllianceMemberCreateFailureAction |
    IFacilityAllianceMemberClearAction |
    IFacilityAllianceMemberFilterAction;
