import * as React from 'react';

import * as classNames from "classnames";
import './InstitutionFormCreateVersion.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";



import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { SubControlLayout } from "../../../components/layouts/index";
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu, SubPageLayout } from "../../../components/layouts/index";
// add state for sub forms too below
import { IInstitutionFormReduxFormState, initialInstitutionFormState } from "../../../reducers/reactReduxForms/institutionForm";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";

import * as InstitutionFormActions from '../../../actions/institutionForm';
import * as InstitutionFormsPageActions from '../../../actions/pages/institutionFormsPage';

import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../../components/form';
import { TrialSubMenu } from '../../../helpers/subMenuHelper';


interface IInstitutionFormCreateVersionProps {
    institutionFormId?: number;
    version?: string;

    creatingVersion?: boolean;
    creatingVersionSuccess?: boolean;
    creatingVersionFailure?: boolean;

    validationFailures?: [];

    onClose?: Function;
    user?: Dtos.User
}

interface IInstitutionFormCreateVersionActions {
    setVersionState?: InstitutionFormsPageActions.IInstitutionFormsPageSetCreateVersionModalViewStateActionCreator;
    createVersion?: InstitutionFormActions.IInstitutionFormCreateVersionActionCreator;
}

type InstitutionFormCreateVersionProps = IInstitutionFormCreateVersionProps & IInstitutionFormCreateVersionActions;

const formName: string = "InstitutionFormVersion";
const reduxFormName: string = "reduxForms.institutionForm";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class InstitutionFormCreateVersion extends React.PureComponent<InstitutionFormCreateVersionProps, any> {

    constructor(props: InstitutionFormCreateVersionProps) {
        super(props);

        this.clearInstitutionForm = this.clearInstitutionForm.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount() {
        const {
        } = this.props;

    }

    componentWillReceiveProps(nextProps: InstitutionFormCreateVersionProps) {
    }

    componentDidUpdate(prevProps: InstitutionFormCreateVersionProps, prevState) {
        const {
            institutionFormId,
            creatingVersion,
            creatingVersionSuccess,
            validationFailures,
        } = this.props;

        if (!creatingVersion && prevProps.creatingVersion) {
            if (creatingVersionSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                this.onCancel();
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

    }

    componentWillUpdate(nextProps: InstitutionFormCreateVersionProps) {
    }

    componentWillUnmount() {

        this.clearInstitutionForm();
    }

    clearInstitutionForm() {
    }

    showInstitutionForm(): boolean {
        return true;
    }

    render() {
        return <SubPageLayout loading={false}>
            {this.renderContent()}
        </SubPageLayout>;
    }

    renderContent() {
        const {
            version,
            institutionFormId,
            setVersionState
        } = this.props;

        const formDisabled: boolean = false;

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            version: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
        };

        let form = {
            version: version
        }

        return <div>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                <div className="alert alert-warning mt-4" role="alert">
                    The new version will be the default, older versions will be available from the details page.
                </div>
                <div className="form-group row border border-bottom-1 border-top-0 pb-4 pt-4">
                    <label className="col-form-label col-sm-6" htmlFor="version">Version</label>
                    <div className="col-sm-6 generic-form-field">
                        <div style={{ position: 'relative' }}>
                            <input type="text"
                                className="form-control"
                                name="version"
                                id="version"
                                value={version}
                                onChange={e => setVersionState(true, institutionFormId, e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }


    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
        </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.InstitutionForm) {
        const {
            createVersion,
            version,
            institutionFormId,
        } = this.props;

        createVersion(institutionFormId, version);
    }

    handleOnSubmitFailed(data: Dtos.InstitutionForm) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        if (this.props.onClose) { this.props.onClose() }
    }
}


const mapStateToProps = (state: ITmdState, ownProps: InstitutionFormCreateVersionProps): IInstitutionFormCreateVersionProps => {

    let institutionForm: Dtos.InstitutionForm | undefined = !(state.institutionForm.formData instanceof Array) ? state.institutionForm.formData : undefined;

    return {
        institutionFormId: state.institutionFormsPage.institutionFormId,
        version: state.institutionFormsPage.version,

        creatingVersion: state.institutionForm.createState && state.institutionForm.createState.status == RequestState.Pending,
        creatingVersionSuccess: state.institutionForm.createState && state.institutionForm.createState.status == RequestState.Success,
        creatingVersionFailure: state.institutionForm.createState && state.institutionForm.createState.status == RequestState.Failure,

        user: state.user.data,

        validationFailures: [],

    };
};

const mapDispatchToProps = (dispatch): IInstitutionFormCreateVersionActions => {
    return {
        setVersionState: bindActionCreators(InstitutionFormsPageActions.setCreateVersionModalViewState, dispatch),
        createVersion: bindActionCreators(InstitutionFormActions.CreateVersion, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(InstitutionFormCreateVersion);
