import './SubMenu.scss';

import * as React from 'react';
import * as classNames from "classnames";
import { Link } from "../../routing/index";
import { INavItem } from "../NavMenu/NavMenu";
import { InlineLoading } from "../../common/index";

interface ISubMenuProps {
    subMenuTitle?: string;
    items: INavItem[];
    loading?: boolean;
}

class SubMenu extends React.PureComponent<ISubMenuProps, any> {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps: any) {
    }

    componentDidUpdate(prevProps: any) {

    }

    componentWillUpdate(nextProps: any) {
    }

    componentWillUnmount() {
    }

    render() {
        const {
            subMenuTitle,
            items,
            loading
        } = this.props;

        return <div className="container p-0 box-shadow-x">
            {subMenuTitle && (
                <div className={classNames(
                    "container",
                    "p-0",
                    "d-flex",
                    "Capture-menu",
                    "x-box-shadow-x",
                )}><label className={"sub-menu-title"}>{subMenuTitle}</label></div>
            )}
            <nav className={
                classNames(
                    "container",
                    "p-0",
                    "d-flex",
                    "Capture-menu",
                    "x-box-shadow-x",
                    { "justify-content-around": loading }
                )
            }>

                {
                    loading ?
                        <InlineLoading className="my-1" size={32}></InlineLoading> :
                        items && items.length > 0 ?
                            items.map(this.createItem) :
                            null
                }
            </nav>
        </div>
    }

    createItem(item: INavItem, index: number, items: INavItem[]): React.ReactNode {
        return item.url ?
            <Link key={"sub-menu-" + index} className={classNames("nav-link", { "nav-link-current": item.current })} activeClassName={"nav-link-current"} url={item.url} target={item.target}>
                {item.icon}
                {item.icon && <span>&emsp;</span> }
                <span>
                    {item.title}
                </span>
            </Link> :
            <a key={"sub-menu-" + index} className="nav-link">
                {item.icon}
                {item.icon && <span>&emsp;</span>}
                <span>
                    {item.title}
                </span>
            </a>

    }
}


export default SubMenu;