import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const membershiptypesApi = {
    getMembershipTypes: (): Observable<Dtos.GetMembershipTypesResponse> => {
        //Create the request.
        let request: Dtos.GetMembershipTypes = new Dtos.GetMembershipTypes();

        //Send request.
        const response: Promise<Dtos.GetMembershipTypesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getMembershipTypeById: (id: number): Observable<Dtos.GetMembershipTypeByIdResponse> => {
        //Create the request.
        let request: Dtos.GetMembershipTypeById = new Dtos.GetMembershipTypeById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetMembershipTypeByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setMembershipType: (membershipType: Dtos.MembershipType): Observable<Dtos.SetMembershipTypeResponse> => {

        let request: Dtos.SetMembershipType = new Dtos.SetMembershipType();

        request.membershipType = membershipType;

        // send request
        const response: Promise<Dtos.SetMembershipTypeResponse> = client.post(request);

        return Observable.from(response);

    },
    createMembershipType: (): Observable<Dtos.CreateMembershipTypeResponse> => {
        //Create the request.
        let request: Dtos.CreateMembershipType = new Dtos.CreateMembershipType();

        //Send request.
        const response: Promise<Dtos.CreateMembershipTypeResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}