import { Reducer } from 'redux';
import { update } from '../../helpers/immutabilityHelper'
import * as Dtos from '../../dtos/Tmd.dtos';

import {
    ACTION_ITEMCOMPONENT_PAGE_SET_PREVIEW_MODAL_VIEW_STATE,
    ACTION_ITEMCOMPONENT_PAGE_SET_ADDPRIMITIVE_MODAL_VIEW_STATE,
    ACTION_ITEMCOMPONENT_PAGE_SET_RULES_MODAL_VIEW_STATE,
    ItemComponentPageAction
} from '../../actions/pages/itemComponentsPage';


export interface IItemComponentPageState {
    previewModalOpen: boolean;
    addPrimitiveModalOpen: boolean;
    rulesModalOpen: boolean;
}

const initialState: IItemComponentPageState = {
    previewModalOpen: false,
    addPrimitiveModalOpen: false,
    rulesModalOpen: false
};

function setPreviewModalViewState(state: IItemComponentPageState, open: boolean): IItemComponentPageState {
    const newState: IItemComponentPageState = update(state, {
        previewModalOpen: {
            $set: open
        }
    });
    return newState;
}

function setAddPrimitiveModalViewState(state: IItemComponentPageState, open: boolean): IItemComponentPageState {
    const newState: IItemComponentPageState = update(state, {
        addPrimitiveModalOpen: {
            $set: open
        }
    });
    return newState;
}

function setRulesModalViewState(state: IItemComponentPageState, open: boolean): IItemComponentPageState {
    const newState: IItemComponentPageState = update(state, {
        rulesModalOpen: {
            $set: open
        }
    });
    return newState;
}

const ItemComponentPageReducer: Reducer<IItemComponentPageState> = (state: IItemComponentPageState = initialState, action: ItemComponentPageAction) => {
    switch (action.type) {
        case ACTION_ITEMCOMPONENT_PAGE_SET_PREVIEW_MODAL_VIEW_STATE:
            return setPreviewModalViewState(state, action.open);
        case ACTION_ITEMCOMPONENT_PAGE_SET_ADDPRIMITIVE_MODAL_VIEW_STATE:
            return setAddPrimitiveModalViewState(state, action.open);
        case ACTION_ITEMCOMPONENT_PAGE_SET_RULES_MODAL_VIEW_STATE:
            return setRulesModalViewState(state, action.open);
    }

    return state;
}

export default ItemComponentPageReducer;