import * as React from 'react';
import * as classNames from "classnames";
import './NotePage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";
import { INoteReduxFormState, initialNoteState } from "../../../reducers/reactReduxForms/note";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as TrialInstitutionActions from '../../../actions/trialInstitution';
import * as NoteActions from '../../../actions/note';
import * as TrialActions from '../../../actions/trial';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../../components/form';
import { PersonnelSubMenu, TrialSubMenu, InstitutionSubMenu, InstitutionTrialSubMenu, GroupSubMenu } from '../../../helpers/subMenuHelper';
import { TrialLayout, TrialInstitutionLayout } from '../../index';

interface INotePageParams {
    noteId: number;
    trialId: number;
    institutionId: number;
    trialInstitutionId: number;
    personnelId: number;
    groupId: number;
}

interface INotePageProps {

    form: Dtos.Note & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: INoteReduxFormState;

    location: Location;
    noteId: number;
    trialId: number;
    institutionId: number;
    trialInstitutionId: number;
    personnelId: number;
    groupId: number;
    match: match<INotePageParams>;
    history: History;

    // From state
    note: Dtos.Note;
    trialInstitution: Dtos.TrialInstitution;
    lookups: Dtos.Lookup[];

    loadingNote: boolean;
    loadNoteSuccess: boolean;
    loadNoteFailure: boolean;

    savingNote: boolean;
    saveNoteuccess: boolean;
    saveNoteFailure: boolean;

    creatingNote: boolean;
    createNoteuccess: boolean;
    createNoteFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface INotePageActions {
    clearNote: NoteActions.INoteClearActionCreator;
    loadNote: NoteActions.INoteLoadByIdActionCreator;

    saveNote: NoteActions.INoteSaveActionCreator;
    createNote: NoteActions.INoteCreateActionCreator;

    clearTrialInstitution: TrialInstitutionActions.ITrialInstitutionClearActionCreator;
    loadTrialInstitution: TrialInstitutionActions.ITrialInstitutionLoadByIdActionCreator;

    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type NotePageProps = INotePageProps & INotePageActions;


const formName: string = "note";
const reduxFormName: string = "reduxForms.note";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class NotePage extends React.PureComponent<NotePageProps, any> {

    constructor(props: NotePageProps) {
        super(props);

        this.clearNote = this.clearNote.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadNote,
            navigate,
            navigateReplace,
            noteId,
            createNote,
            trialId,
            trialInstitutionId,
            institutionId,
            groupId,
            personnelId,
            loadTrial,
            loadTrialInstitution
        } = this.props;

        if (noteId) {
            loadNote(noteId);
        } else if (!noteId) {
            createNote(trialId, institutionId, trialInstitutionId, personnelId, groupId);
        }

        if (trialId) {
            loadTrial(trialId);
        }

        if (trialInstitutionId) {
            loadTrialInstitution(trialInstitutionId);
        }
    }

    componentWillReceiveProps(nextProps: NotePageProps) {
    }

    componentDidUpdate(prevProps: NotePageProps, prevState) {
        const {
            navigateReplace,
            note,
            noteId,
            form,
            navigate,
            loadNote,
            loadingNote,
            loadNoteSuccess,
            createNote,
            creatingNote,
            createNoteuccess,
            savingNote,
            saveNoteuccess,
            saveNoteFailure,
            validationFailures,
            trialId,
            trialInstitutionId,
            institutionId,
            personnelId,
            groupId,
            loadTrial,
            loadTrialInstitution
        } = this.props;

        if (noteId && prevProps.noteId != noteId) {
            loadNote(noteId);
        }
        else if (!noteId && prevProps.noteId) {
            createNote(trialId, institutionId, trialInstitutionId, personnelId, groupId);
        }

        if (trialId && prevProps.trialId != trialId) {
            loadTrial(trialId);
        }

        if (trialInstitutionId && prevProps.trialInstitutionId != trialInstitutionId) {
            loadTrialInstitution(trialInstitutionId);
        }

        if (!loadingNote && prevProps.loadingNote) {
            if (loadNoteSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingNote && prevProps.savingNote) {
            if (saveNoteuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate(this.baseUrl())
                } else if (!form.saveAndReturn && noteId && noteId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !noteId && note && note.id > 0) {
                    navigate(this.baseUrl() + "/" + note.id);
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingNote && prevProps.creatingNote) {
            if (createNoteuccess) {
                this.loadForm();
            }
            else {

            }
        }
    }

    componentWillUpdate(nextProps: NotePageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm,
            clearTrial
        } = this.props

        this.clearNote();
        clearTrial();
        loadForm(reduxFormName, initialNoteState);
        resetForm(reduxFormName);
    }

    baseUrl(): string {
        const {
            trialId,
            trialInstitutionId,
            institutionId,
            personnelId,
            groupId
        } = this.props;

        if (personnelId && personnelId > 0) {
            return "/personnel/" + personnelId + "/notes";
        } else if (trialInstitutionId && trialInstitutionId > 0) {
            return "/trials/" + trialId + "/trialinstitution/" + trialInstitutionId + "/notes";
        } else if (trialId && trialId > 0) {
            return "/trials/" + trialId + "/notes";
        } else if (institutionId && institutionId > 0) {
            return "/institutions/" + institutionId + "/notes";
        } else if (groupId && groupId > 0) {
            return "/groups/" + groupId + "/notes";
        }

        return "/notes";
    }

    clearNote() {
        this.props.clearTrialInstitution();
        this.props.clearTrial();
        this.props.clearNote();
    }

    showNote(): boolean {
        const {
            loadingNote,
            loadNoteSuccess
        } = this.props

        if (!loadingNote && loadNoteSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            form,
            loadingNote,
            creatingNote,
            noteId,
            note,
            trialId,
            trialInstitutionId,
            institutionId,
            personnelId,
            groupId,
            permissions
        } = this.props;

        let menu;
        let subMenu;
        let display = note ? note.parentDisplay : "";
        let subDisplay;
        if (personnelId && personnelId > 0) {
            menu = PersonnelSubMenu(personnelId, permissions);
        } else if (trialInstitutionId && trialInstitutionId > 0) {
            return (
                <TrialInstitutionLayout
                    permission={Dtos.Permission.TrialNoteView}
                    loading={loadingNote || creatingNote}
                >{this.renderContent()}</TrialInstitutionLayout>
            );
        } else if (trialId && trialId > 0) {
            return (
                <TrialLayout loadingTrial={loadingNote || creatingNote}
                    loading={loadingNote || creatingNote}
                    permission={Dtos.Permission.TrialNoteView}>{this.renderContent()}</TrialLayout>
            );
        } else if (institutionId && institutionId > 0) {
            menu = InstitutionSubMenu(institutionId, permissions);
        } else if (groupId && groupId > 0) {
            return <RestrictedLayout
                permission={ Dtos.Permission.GroupNotesView}
                subMenuItems={GroupSubMenu(groupId, permissions)}
                loading={loadingNote || creatingNote}
            >
                {this.renderContent()}
            </RestrictedLayout>;
        }



        return <RestrictedLayout
            subMenuTitle={display}
            subMenuItems={menu}
            sub2MenuTitle={subDisplay}
            sub2MenuItems={subMenu}
            loading={loadingNote}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingNote,
            trialId,
            trialInstitutionId,
            institutionId,
            personnelId,
            groupId,
            permissions,
            trialInstitution
        } = this.props;

        let canEdit: boolean = true;
        if (personnelId && personnelId > 0) {
            canEdit = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.PersonnelNoteUpdate);
        } else if (trialInstitutionId && trialInstitutionId > 0) {
            canEdit = (trialInstitution && trialInstitution.trialId > 0 &&
                AuthenticationHelper.hasPermission(permissions, Dtos.Permission.PersonnelNoteUpdate, trialInstitution.trialId, trialInstitution.institutionId));
        } else if (trialId && trialId > 0) {
            canEdit = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialNoteUpdate, trialId, null);
        } else if (institutionId && institutionId > 0) {
            canEdit = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialNoteUpdate, null, institutionId);
        } else if (groupId && groupId > 0) {
            canEdit = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.GroupNotesUpdate);
        }

        const formDisabled: boolean = !canEdit || savingNote || (formState && formState.formStatus == Dtos.FormStatus.Disabled); 

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            title: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            noteTypeId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("Note", "NoteTypeId", lookups), form.noteTypeId)
                }
            },
            comments: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextAreaFormInput,
                inputDisabled: formDisabled,
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            },
        };

        

        return <div>
            <h1>Note</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "note", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.Note) {
        const {
            saveNote
        } = this.props;

        if (data) {
            let changes: Dtos.Note = {} as Dtos.Note;
            saveNote(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.Note) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }


    onCancel() {
        const {
            navigate
        } = this.props;

        navigate(this.baseUrl());
    }

    loadForm() {
        const {
            loadForm,
            note,
        } = this.props

        let extraData: Dtos.Note = {} as Dtos.Note;

        loadForm(reduxFormName, Object.assign({ ...note }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: NotePageProps): INotePageProps => {

    let note: Dtos.Note | undefined = !(state.notes.formData instanceof Array) ? state.notes.formData : undefined;
    let trialInstitution: Dtos.TrialInstitution | undefined = !(state.trialInstitution.formData instanceof Array) ? state.trialInstitution.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];
    
    return {

        match: ownProps.match,
        noteId: ownProps.match && ownProps.match.params.noteId && ownProps.match.params.noteId.toString() != "create" ? ownProps.match.params.noteId : undefined!,
        trialId: ownProps.match && ownProps.match.params.trialId && ownProps.match.params.trialId > 0 ? ownProps.match.params.trialId : undefined!,
        institutionId: ownProps.match && ownProps.match.params.institutionId && ownProps.match.params.institutionId > 0 ? ownProps.match.params.institutionId : undefined!,
        trialInstitutionId: ownProps.match && ownProps.match.params.trialInstitutionId && ownProps.match.params.trialInstitutionId > 0 ? ownProps.match.params.trialInstitutionId : undefined!,
        personnelId: ownProps.match && ownProps.match.params.personnelId && ownProps.match.params.personnelId > 0 ? ownProps.match.params.personnelId : undefined!,
        groupId: ownProps.match && ownProps.match.params.groupId && ownProps.match.params.groupId > 0 ? ownProps.match.params.groupId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        note: note!,
        trialInstitution: trialInstitution,

        loadingNote: state.notes.loadState && state.notes.loadState.status == RequestState.Pending,
        loadNoteSuccess: state.notes.loadState && state.notes.loadState.status == RequestState.Success,
        loadNoteFailure: state.notes.loadState && state.notes.loadState.status == RequestState.Failure,

        savingNote: state.notes.saveState && state.notes.saveState.status == RequestState.Pending,
        saveNoteuccess: state.notes.saveState && state.notes.saveState.status == RequestState.Success,
        saveNoteFailure: state.notes.saveState && state.notes.saveState.status == RequestState.Failure,

        creatingNote: state.notes.createState && state.notes.createState.status == RequestState.Pending,
        createNoteuccess: state.notes.createState && state.notes.createState.status == RequestState.Success,
        createNoteFailure: state.notes.createState && state.notes.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: state.notes.lookups,

        form: state.reduxForms.note,
        formState: state.notes.formState,
        formProperties: state.notes.formProperties,

        reduxFormState: state.reduxForms.formState.note,
        validationFailures: note ?
            state.notes.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): INotePageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadTrialInstitution: bindActionCreators(TrialInstitutionActions.LoadTrialInstitutionById, dispatch),
        clearTrialInstitution: bindActionCreators(TrialInstitutionActions.Clear, dispatch),

        loadNote: bindActionCreators(NoteActions.LoadNoteById, dispatch),
        clearNote: bindActionCreators(NoteActions.Clear, dispatch),

        saveNote: bindActionCreators(NoteActions.SaveNote, dispatch),
        createNote: bindActionCreators(NoteActions.CreateNote, dispatch),


        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(NotePage);
