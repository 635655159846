import * as React from 'react';
import './CollaboratingGroupPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { ICollaboratingGroupReduxFormState, initialCollaboratingGroupState } from "../../../reducers/reactReduxForms/collaboratingGroup";
import { RegistrationFormComponent } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, NumberFormInput, CheckboxFormInput } from "../../../components/form/index";
import * as CollaboratingGroupActions from '../../../actions/collaboratingGroups';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { NotificationAlert } from "../../../components/common/index";
import { Form, actions } from 'react-redux-form';
import { TrialLookupsLayout } from '../../index';
import * as AuthenticationHelper from '../../../helpers/authentication';

interface ICollaboratingGroupPageParams {
    collaboratingGroupId: number;
}

interface ICollaboratingGroupPageProps {

    form: Dtos.CollaboratingGroup & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ICollaboratingGroupReduxFormState;

    location: Location;
    collaboratingGroupId: number;
    match: match<ICollaboratingGroupPageParams>;
    history: History;

    // From state
    collaboratingGroup: Dtos.CollaboratingGroup;
    lookups: Dtos.Lookup[];

    loadingCollaboratingGroup: boolean;
    loadCollaboratingGroupSuccess: boolean;
    loadCollaboratingGroupFailure: boolean;

    savingCollaboratingGroup: boolean;
    saveCollaboratingGroupSuccess: boolean;
    saveCollaboratingGroupFailure: boolean;

    creatingCollaboratingGroup: boolean;
    createCollaboratingGroupSuccess: boolean;
    createCollaboratingGroupFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface ICollaboratingGroupPageActions {
    clearCollaboratingGroup: CollaboratingGroupActions.ICollaboratingGroupClearActionCreator;
    loadCollaboratingGroup: CollaboratingGroupActions.ICollaboratingGroupLoadByIdActionCreator;

    saveCollaboratingGroup: CollaboratingGroupActions.ICollaboratingGroupSaveActionCreator;
    createCollaboratingGroup: CollaboratingGroupActions.ICollaboratingGroupCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type CollaboratingGroupPageProps = ICollaboratingGroupPageProps & ICollaboratingGroupPageActions;

const reduxFormName: string = "reduxForms.collaboratingGroup";

class CollaboratingGroupPage extends React.PureComponent<CollaboratingGroupPageProps, any> {

    constructor(props: CollaboratingGroupPageProps) {
        super(props);

        this.clearCollaboratingGroup = this.clearCollaboratingGroup.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadCollaboratingGroup,
            collaboratingGroupId,
            createCollaboratingGroup
        } = this.props;

        if (collaboratingGroupId) {
            loadCollaboratingGroup(collaboratingGroupId);
        } else if (!collaboratingGroupId) {
            createCollaboratingGroup();
        }
    }

    componentWillReceiveProps(nextProps: CollaboratingGroupPageProps) {
    }

    componentDidUpdate(prevProps: CollaboratingGroupPageProps, prevState) {
        const {
            collaboratingGroupId,
            collaboratingGroup,
            form,
            navigate,
            loadCollaboratingGroup,
            loadingCollaboratingGroup,
            loadCollaboratingGroupSuccess,
            createCollaboratingGroup,
            creatingCollaboratingGroup,
            createCollaboratingGroupSuccess,
            savingCollaboratingGroup,
            saveCollaboratingGroupSuccess,
            validationFailures
        } = this.props;

        if (collaboratingGroupId && prevProps.collaboratingGroupId != collaboratingGroupId) {
            loadCollaboratingGroup(collaboratingGroupId);
        }
        else if (!collaboratingGroupId && prevProps.collaboratingGroupId) {
            createCollaboratingGroup();
        }

        if (!loadingCollaboratingGroup && prevProps.loadingCollaboratingGroup) {
            if (loadCollaboratingGroupSuccess) {
                this.loadForm();
            }
        }

        if (!savingCollaboratingGroup && prevProps.savingCollaboratingGroup) {
            if (saveCollaboratingGroupSuccess) {
                if (validationFailures && validationFailures.length > 0) {
                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {
                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);
                }
                if (form.saveAndReturn) {
                    navigate("/settings/trial/collaboratinggroups")
                } else if (!form.saveAndReturn && collaboratingGroupId && collaboratingGroupId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !collaboratingGroupId && collaboratingGroup && collaboratingGroup.id > 0) {
                    navigate("/settings/trial/collaboratinggroups/" + collaboratingGroup.id);
                }
            }
            else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

        if (!creatingCollaboratingGroup && prevProps.creatingCollaboratingGroup) {
            if (createCollaboratingGroupSuccess) {
                this.loadForm();
            }
        }
    }

    componentWillUpdate(nextProps: CollaboratingGroupPageProps) {
    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearCollaboratingGroup();
        loadForm(reduxFormName, initialCollaboratingGroupState);
        resetForm(reduxFormName);
    }

    clearCollaboratingGroup() {
        this.props.clearCollaboratingGroup();
    }

    showCollaboratingGroup(): boolean {
        const {
            loadingCollaboratingGroup,
            loadCollaboratingGroupSuccess
        } = this.props

        if (!loadingCollaboratingGroup && loadCollaboratingGroupSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingCollaboratingGroup
        } = this.props;

        return <TrialLookupsLayout loading={loadingCollaboratingGroup} permission={Dtos.Permission.CollaboratingGroupsView}>
            {this.renderContent()}
        </TrialLookupsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingCollaboratingGroup,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.CollaboratingGroupsUpdate, Dtos.Permission.CollaboratingGroupsCreate);
        const formDisabled: boolean = !hasPermission || savingCollaboratingGroup || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            value: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            }
        };

        return <div>
            <h1>Collaborating Group</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "collaboratingGroup", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.CollaboratingGroup) {
        const {
            saveCollaboratingGroup
        } = this.props;

        if (data) {
            let changes: Dtos.CollaboratingGroup = {} as Dtos.CollaboratingGroup;
            saveCollaboratingGroup(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.CollaboratingGroup) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/trial/collaboratinggroups");
    }

    loadForm() {
        const {
            loadForm,
            collaboratingGroup,
        } = this.props

        let extraData: Dtos.CollaboratingGroup = {} as Dtos.CollaboratingGroup;

        loadForm(reduxFormName, Object.assign({ ...collaboratingGroup }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: CollaboratingGroupPageProps): ICollaboratingGroupPageProps => {

    let collaboratingGroup: Dtos.CollaboratingGroup | undefined = !(state.collaboratingGroups.formData instanceof Array) ? state.collaboratingGroups.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        collaboratingGroupId: ownProps.match && ownProps.match.params.collaboratingGroupId && ownProps.match.params.collaboratingGroupId.toString() != "create" ? ownProps.match.params.collaboratingGroupId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        collaboratingGroup: collaboratingGroup!,

        loadingCollaboratingGroup: state.collaboratingGroups.loadState && state.collaboratingGroups.loadState.status == RequestState.Pending,
        loadCollaboratingGroupSuccess: state.collaboratingGroups.loadState && state.collaboratingGroups.loadState.status == RequestState.Success,
        loadCollaboratingGroupFailure: state.collaboratingGroups.loadState && state.collaboratingGroups.loadState.status == RequestState.Failure,

        savingCollaboratingGroup: state.collaboratingGroups.saveState && state.collaboratingGroups.saveState.status == RequestState.Pending,
        saveCollaboratingGroupSuccess: state.collaboratingGroups.saveState && state.collaboratingGroups.saveState.status == RequestState.Success,
        saveCollaboratingGroupFailure: state.collaboratingGroups.saveState && state.collaboratingGroups.saveState.status == RequestState.Failure,

        creatingCollaboratingGroup: state.collaboratingGroups.createState && state.collaboratingGroups.createState.status == RequestState.Pending,
        createCollaboratingGroupSuccess: state.collaboratingGroups.createState && state.collaboratingGroups.createState.status == RequestState.Success,
        createCollaboratingGroupFailure: state.collaboratingGroups.createState && state.collaboratingGroups.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: [],

        form: state.reduxForms.collaboratingGroup,
        formState: state.collaboratingGroups.formState,
        formProperties: state.collaboratingGroups.formProperties,

        reduxFormState: state.reduxForms.formState.collaboratingGroup,
        validationFailures: collaboratingGroup ?
            state.collaboratingGroups.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): ICollaboratingGroupPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadCollaboratingGroup: bindActionCreators(CollaboratingGroupActions.LoadCollaboratingGroupById, dispatch),
        clearCollaboratingGroup: bindActionCreators(CollaboratingGroupActions.Clear, dispatch),

        saveCollaboratingGroup: bindActionCreators(CollaboratingGroupActions.SaveCollaboratingGroup, dispatch),
        createCollaboratingGroup: bindActionCreators(CollaboratingGroupActions.CreateCollaboratingGroup, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(CollaboratingGroupPage);
