import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_TRIALPRESENTATIONTYPES_LOAD,
    ACTION_TRIALPRESENTATIONTYPES_LOAD_BY_ID,

    ACTION_TRIALPRESENTATIONTYPES_SAVE,

    ACTION_TRIALPRESENTATIONTYPES_CREATE,

    ITrialPresentationTypeLoadAction,
    ITrialPresentationTypeLoadByIdAction,
    ITrialPresentationTypeLoadFailureAction,
    ITrialPresentationTypeLoadSuccessAction,

    ITrialPresentationTypeSaveAction,
    ITrialPresentationTypeSaveSuccessAction,
    ITrialPresentationTypeSaveFailureAction,

    ITrialPresentationTypeCreateAction,
    ITrialPresentationTypeCreateSuccessAction,
    ITrialPresentationTypeCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/trialPresentationTypes";
import { trialPresentationTypesApi } from "../services/trialPresentationType";


export const
    getTrialPresentationTypesEpic = (action$): Observable<ITrialPresentationTypeLoadSuccessAction | ITrialPresentationTypeLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALPRESENTATIONTYPES_LOAD)
            .mergeMap((action: ITrialPresentationTypeLoadAction) => {
                return trialPresentationTypesApi
                    .getTrialPresentationTypes()
                    .map(response =>
                        LoadSuccess(response.trialPresentationTypes, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTrialPresentationTypeByIdEpic = (action$): Observable<ITrialPresentationTypeLoadSuccessAction | ITrialPresentationTypeLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALPRESENTATIONTYPES_LOAD_BY_ID)
            .mergeMap((action: ITrialPresentationTypeLoadByIdAction) => {
                return trialPresentationTypesApi
                    .getTrialPresentationTypeById(action.id)
                    .map(response =>
                        LoadSuccess(response.trialPresentationType, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setTrialPresentationTypeEpic = (action$): Observable<ITrialPresentationTypeSaveSuccessAction | ITrialPresentationTypeSaveFailureAction> => {
        return action$
            .ofType(ACTION_TRIALPRESENTATIONTYPES_SAVE)
            .mergeMap((action: ITrialPresentationTypeSaveAction) => {
                return trialPresentationTypesApi
                    .setTrialPresentationType(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.trialPresentationType, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createTrialPresentationTypeEpic = (action$): Observable<ITrialPresentationTypeCreateSuccessAction | ITrialPresentationTypeCreateFailureAction> => {
        return action$
            .ofType(ACTION_TRIALPRESENTATIONTYPES_CREATE)
            .mergeMap((action: ITrialPresentationTypeCreateAction) => {
                return trialPresentationTypesApi
                    .createTrialPresentationType()
                    .map(response =>
                        CreateSuccess(
                            response.trialPresentationType,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



