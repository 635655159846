import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Tmd.dtos';

import {
    ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD,
    ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD_BY_ID,
    ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD_FAILURE,
    ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD_SUCCESS,

    ACTION_TRIALINSTITUTIONITEMTEMPLATES_SAVE,
    ACTION_TRIALINSTITUTIONITEMTEMPLATES_SAVE_SUCCESS,
    ACTION_TRIALINSTITUTIONITEMTEMPLATES_SAVE_FAILURE,

    ACTION_TRIALINSTITUTIONITEMTEMPLATES_CREATE,
    ACTION_TRIALINSTITUTIONITEMTEMPLATES_CREATE_SUCCESS,
    ACTION_TRIALINSTITUTIONITEMTEMPLATES_CREATE_FAILURE,

    ACTION_TRIALINSTITUTIONITEMTEMPLATES_CLEAR,
    ACTION_TRIALINSTITUTIONITEMTEMPLATES_FILTER,

    TrialInstitutionItemTemplateAction
} from '../actions/trialInstitutionItemTemplate';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState, IFormFilter } from "./index";
import { updateFailureIndexToId } from "../helpers/formErrorHelper";


export interface ITrialInstitutionItemTemplateState {
    formData: Dtos.TrialInstitutionItemTemplate | Dtos.TrialInstitutionItemTemplate[];
    formDataGrouped: Dtos.Category[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    validationFailures: Dtos.ResponseError[];
    lookups: Dtos.Lookup[];
    loadState: IRequestState;
    saveState: IRequestState;
    updateState: IRequestState;
    createState: IRequestState;
    formFilter: IFormFilter;
    formFilteredData: Dtos.TrialInstitutionItemTemplate[];
    formFilteredDataGrouped: Dtos.Category[];
}

const initialState: ITrialInstitutionItemTemplateState = {
    formData: undefined,
    formDataGrouped: undefined,
    formState: undefined,
    formProperties: undefined,
    validationFailures: undefined,
    lookups: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    updateState: {
        status: RequestState.None
    } as IRequestState,
    createState: {
        status: RequestState.None
    } as IRequestState,
    formFilter: {
        search: "",
        showInActive: false
    },
    formFilteredData: [],
    formFilteredDataGrouped: undefined,
};


function loading(state: ITrialInstitutionItemTemplateState, isLoading: boolean): ITrialInstitutionItemTemplateState {
    const newState: ITrialInstitutionItemTemplateState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(state: ITrialInstitutionItemTemplateState,
    formData: Dtos.TrialInstitutionItemTemplate | Dtos.TrialInstitutionItemTemplate[],
    formDataGrouped: Dtos.Category[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[],
    responseStatus: Dtos.ResponseStatus
): ITrialInstitutionItemTemplateState {
    const newState: ITrialInstitutionItemTemplateState = update(state, {
        formData: {
            $set: formData
        },
        formDataGrouped: {
            $set: formDataGrouped
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        loadState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return filter(newState, state.formFilter);
}

function filter(state: ITrialInstitutionItemTemplateState, formFilter: IFormFilter): ITrialInstitutionItemTemplateState {

    const formData: Dtos.TrialInstitutionItemTemplate[] | undefined =
        state.formData instanceof Array ?
            state.formData :
            undefined;

    const formFilteredData = formData ? formData.filter(p => (p.active == true || formFilter.showInActive) &&
        (formFilter.search == "" || p.name.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1)) : undefined;

    let formFilteredDataGrouped = [];
    if (state.formDataGrouped) {
        for (var i = 0; i < state.formDataGrouped.length; i++) {
            const filteredCategoryItems =
                state.formDataGrouped[i].trialInstitutionItemTemplates.filter(p => (p.active == true || formFilter.showInActive) &&
                    (formFilter.search == "" || p.name.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1 || p.description.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1));
            if (filteredCategoryItems.length > 0) {
                formFilteredDataGrouped.push({
                    ...state.formDataGrouped[i],
                    trialInstitutionItemTemplates: filteredCategoryItems
                });
            }
        }
    }

    const newState: ITrialInstitutionItemTemplateState = update(state, {
        formFilter: {
            $set: formFilter
        },
        formFilteredData: {
            $set: formFilteredData
        },
        formFilteredDataGrouped: {
            $set: formFilteredDataGrouped
        }
    });

    return newState;
}

function loadFailure(state: ITrialInstitutionItemTemplateState, responseStatus: Dtos.ResponseStatus): ITrialInstitutionItemTemplateState {
    const newState: ITrialInstitutionItemTemplateState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: ITrialInstitutionItemTemplateState, propertiesToClear?: string[]): ITrialInstitutionItemTemplateState {

    if (!propertiesToClear) {
        let formFilter = state.formFilter;
        const newState: ITrialInstitutionItemTemplateState = update(initialState, {
            formFilter: {
                $set: formFilter
            }
        });
        return newState;
    }

    let stateModifier: any = {};

    propertiesToClear.forEach(propertyToClear => {
        stateModifier[propertyToClear] = { $set: initialState[propertyToClear] }
    });

    const newState = update(state, stateModifier);

    return newState;
}

function saving(state: ITrialInstitutionItemTemplateState, isSaving: boolean): ITrialInstitutionItemTemplateState {
    const newState: ITrialInstitutionItemTemplateState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function saveSuccess(
    state: ITrialInstitutionItemTemplateState,
    formData: Dtos.TrialInstitutionItemTemplate | Dtos.TrialInstitutionItemTemplate[],
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus
): ITrialInstitutionItemTemplateState {
    const newState: ITrialInstitutionItemTemplateState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        saveState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function saveFailure(state: ITrialInstitutionItemTemplateState, responseStatus: Dtos.ResponseStatus): ITrialInstitutionItemTemplateState {
    const newState: ITrialInstitutionItemTemplateState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                meta: responseStatus.meta
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, state.formData) : undefined
        }
    });

    return newState;
}

function creating(state: ITrialInstitutionItemTemplateState, isCreating: boolean): ITrialInstitutionItemTemplateState {
    const newState: ITrialInstitutionItemTemplateState = update(state, {
        createState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function createSuccess(
    state: ITrialInstitutionItemTemplateState,
    formData: Dtos.TrialInstitutionItemTemplate,
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[]
): ITrialInstitutionItemTemplateState {
    const newState: ITrialInstitutionItemTemplateState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        createState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function createFailure(state: ITrialInstitutionItemTemplateState, responseStatus: Dtos.ResponseStatus): ITrialInstitutionItemTemplateState {
    const newState: ITrialInstitutionItemTemplateState = update(state, {
        createState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

const TrialInstitutionItemTemplateReducer: Reducer<ITrialInstitutionItemTemplateState> = (state: ITrialInstitutionItemTemplateState = initialState, action: TrialInstitutionItemTemplateAction) => {
    switch (action.type) {
        case ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD:
        case ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD_BY_ID:
            return loading(state, true);
        case ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD_SUCCESS:
            return loadSuccess(state, action.formData, action.formDataGrouped, action.formState, action.formProperties, action.lookups, action.responseStatus);
        case ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_TRIALINSTITUTIONITEMTEMPLATES_CLEAR:
            return clear(state, action.propertiesToClear);
        case ACTION_TRIALINSTITUTIONITEMTEMPLATES_SAVE:
            return saving(state, true);
        case ACTION_TRIALINSTITUTIONITEMTEMPLATES_SAVE_SUCCESS:
            return saveSuccess(state, action.formData, action.formState, action.responseStatus);
        case ACTION_TRIALINSTITUTIONITEMTEMPLATES_SAVE_FAILURE:
            return saveFailure(state, action.responseStatus);
        case ACTION_TRIALINSTITUTIONITEMTEMPLATES_CREATE:
            return creating(state, true);
        case ACTION_TRIALINSTITUTIONITEMTEMPLATES_CREATE_SUCCESS:
            return createSuccess(state, action.formData, action.formState, action.formProperties, action.lookups);
        case ACTION_TRIALINSTITUTIONITEMTEMPLATES_CREATE_FAILURE:
            return createFailure(state, action.responseStatus);
        case ACTION_TRIALINSTITUTIONITEMTEMPLATES_FILTER:
            return filter(state, action.formFilter);

    }

    return state;
}

export default TrialInstitutionItemTemplateReducer;