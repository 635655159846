import * as Dtos from '../dtos/Tmd.dtos';
import * as UserActions from '../actions/user';

export function LoadUser(store: any): void {
    if (!store || !store.dispatch) {
        return;
    }
    
    store.dispatch(UserActions.LoadUser());
};
