import * as React from 'react';

import './TrialInstitutionLayout.scss';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import { RestrictedLayout } from "../../../components/layouts/index";
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { TrialSubMenu, TrialInstitutionSubMenu } from '../../../helpers/subMenuHelper';

interface ITrialInstitutionLayoutProps {
    permissions?: Dtos.PersonnelTmdPermissions[];
    permission?: Dtos.Permission;
    trial?: Dtos.Trial;
    trialRoles?: Dtos.RoleType[];
    loadingTrial?: boolean;

    trialInstitution?: Dtos.TrialInstitution;
    trialInstitutionRoles?: Dtos.RoleType[];
    loadingTrialInstitution?: boolean;

    loading?: boolean;
    children?: any;
}

interface ITrialInstitutionLayoutActions {

}

type TrialInstitutionLayoutProps = ITrialInstitutionLayoutProps & ITrialInstitutionLayoutActions;

class TrialInstitutionLayout extends React.PureComponent<TrialInstitutionLayoutProps, any> {

    constructor(props: TrialInstitutionLayoutProps) {
        super(props);

    }

    render() {

        const {
            loadingTrial,
            trial,
            permissions,
            loadingTrialInstitution,
            trialInstitution,
            trialInstitutionRoles,
            loading
        } = this.props;

        console.log('TrialInstitutionLayout: render', trialInstitution);

        return <RestrictedLayout
            subMenuTitle={trial ? trial.shortDisplay : ""}
            trialId={trial ? trial.id : null}
            institutionId={trialInstitution? trialInstitution.institutionId : null }
            subMenuItems={loadingTrial == undefined || !trial ? [] : TrialSubMenu(trial.id, permissions)}
            sub2MenuTitle={trialInstitution ? trialInstitution.institutionDisplay : ""}
            sub2MenuItems={trialInstitution ? TrialInstitutionSubMenu(trialInstitution, permissions) : []}
            loading={loadingTrial || loadingTrialInstitution || loading}
        >
            {this.props.children}
        </RestrictedLayout>;
    }

}


const mapStateToProps = (state: ITmdState, ownProps: TrialInstitutionLayoutProps): ITrialInstitutionLayoutProps => {

    let trial: Dtos.Trial | undefined = !(state.trials.formData instanceof Array) ? state.trials.formData : undefined;
    let trialInstitution: Dtos.TrialInstitution | undefined = !(state.trialInstitution.formData instanceof Array) ? state.trialInstitution.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        permissions: permissions,
        permission: ownProps.permission,

        trial: trial!,
        trialRoles: state.trials.formRoles,
        loadingTrial: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,

        trialInstitution: trialInstitution!,
        trialInstitutionRoles: state.trialInstitution.formRoles,
        loadingTrialInstitution: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Pending,

        loading: ownProps.loading,
        children: ownProps.children,

    };
};

const mapDispatchToProps = (dispatch): ITrialInstitutionLayoutActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialInstitutionLayout);
