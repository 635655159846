import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadMembershipType: IMembershipTypeLoadActionCreator = () => {
        return {
            type: ACTION_MEMBERSHIPTYPES_LOAD
        }
    },
    LoadMembershipTypeById: IMembershipTypeLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_MEMBERSHIPTYPES_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IMembershipTypeLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_MEMBERSHIPTYPES_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IMembershipTypeLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_MEMBERSHIPTYPES_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveMembershipType: IMembershipTypeSaveActionCreator = (formData) => {
        return {
            type: ACTION_MEMBERSHIPTYPES_SAVE,
            formData
        }
    },
    SaveSuccess: IMembershipTypeSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_MEMBERSHIPTYPES_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IMembershipTypeSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_MEMBERSHIPTYPES_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateMembershipType: IMembershipTypeCreateActionCreator = () => {

        return {
            type: ACTION_MEMBERSHIPTYPES_CREATE
        }
    },
    CreateSuccess: IMembershipTypeCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_MEMBERSHIPTYPES_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: IMembershipTypeCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_MEMBERSHIPTYPES_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IMembershipTypeClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_MEMBERSHIPTYPES_CLEAR,
            propertiesToClear
        }
    },
    Filter: IMembershipTypeFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_MEMBERSHIPTYPES_FILTER,
            formFilter
        }
    };



export const
    ACTION_MEMBERSHIPTYPES_LOAD = "ACTION_MEMBERSHIPTYPES_LOAD",
    ACTION_MEMBERSHIPTYPES_LOAD_BY_ID = "ACTION_MEMBERSHIPTYPES_LOAD_BY_ID",
    ACTION_MEMBERSHIPTYPES_LOAD_SUCCESS = "ACTION_MEMBERSHIPTYPES_LOAD_SUCCESS",
    ACTION_MEMBERSHIPTYPES_LOAD_FAILURE = "ACTION_MEMBERSHIPTYPES_LOAD_FAILURE",

    ACTION_MEMBERSHIPTYPES_SAVE = "ACTION_MEMBERSHIPTYPES_SAVE",
    ACTION_MEMBERSHIPTYPES_SAVE_SUCCESS = "ACTION_MEMBERSHIPTYPES_SAVE_SUCCESS",
    ACTION_MEMBERSHIPTYPES_SAVE_FAILURE = "ACTION_MEMBERSHIPTYPES_SAVE_FAILURE",

    ACTION_MEMBERSHIPTYPES_CREATE = "ACTION_MEMBERSHIPTYPES_CREATE",
    ACTION_MEMBERSHIPTYPES_CREATE_SUCCESS = "ACTION_MEMBERSHIPTYPES_CREATE_SUCCESS",
    ACTION_MEMBERSHIPTYPES_CREATE_FAILURE = "ACTION_MEMBERSHIPTYPES_CREATE_FAILURE",

    ACTION_MEMBERSHIPTYPES_CLEAR = "ACTION_MEMBERSHIPTYPES_CLEAR",

    ACTION_MEMBERSHIPTYPES_FILTER = "ACTION_MEMBERSHIPTYPES_FILTER";

export interface IMembershipTypeLoadAction {
    type: "ACTION_MEMBERSHIPTYPES_LOAD";
}

export interface IMembershipTypeLoadActionCreator {
    (): IMembershipTypeLoadAction;
}

export interface IMembershipTypeLoadSuccessAction {
    type: "ACTION_MEMBERSHIPTYPES_LOAD_SUCCESS";
    formData: Dtos.MembershipType | Dtos.MembershipType[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IMembershipTypeLoadSuccessActionCreator {
    (
        formData: Dtos.MembershipType | Dtos.MembershipType[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IMembershipTypeLoadSuccessAction;
}

export interface IMembershipTypeLoadFailureAction {
    type: "ACTION_MEMBERSHIPTYPES_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IMembershipTypeLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IMembershipTypeLoadFailureAction;
}

export interface IMembershipTypeLoadByIdAction {
    type: "ACTION_MEMBERSHIPTYPES_LOAD_BY_ID";
    id: number;
}

export interface IMembershipTypeLoadByIdActionCreator {
    (id: number): IMembershipTypeLoadByIdAction;
}


export interface IMembershipTypeSaveAction {
    type: "ACTION_MEMBERSHIPTYPES_SAVE";
    formData: Dtos.MembershipType;

}

export interface IMembershipTypeSaveActionCreator {
    (
        formData: Dtos.MembershipType
    ): IMembershipTypeSaveAction;
}

export interface IMembershipTypeSaveSuccessAction {
    type: "ACTION_MEMBERSHIPTYPES_SAVE_SUCCESS";
    formData: Dtos.MembershipType;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IMembershipTypeSaveSuccessActionCreator {
    (
        formData: Dtos.MembershipType,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IMembershipTypeSaveSuccessAction;
}

export interface IMembershipTypeSaveFailureAction {
    type: "ACTION_MEMBERSHIPTYPES_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IMembershipTypeSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IMembershipTypeSaveFailureAction;
}

export interface IMembershipTypeCreateAction {
    type: "ACTION_MEMBERSHIPTYPES_CREATE"
}

export interface IMembershipTypeCreateActionCreator {
    (): IMembershipTypeCreateAction
}

export interface IMembershipTypeCreateSuccessAction {
    type: "ACTION_MEMBERSHIPTYPES_CREATE_SUCCESS";
    formData: Dtos.MembershipType;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface IMembershipTypeCreateSuccessActionCreator {
    (
        formData: Dtos.MembershipType,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): IMembershipTypeCreateSuccessAction;
}

export interface IMembershipTypeCreateFailureAction {
    type: "ACTION_MEMBERSHIPTYPES_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IMembershipTypeCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IMembershipTypeCreateFailureAction;
}

export interface IMembershipTypeClearAction {
    type: "ACTION_MEMBERSHIPTYPES_CLEAR";
    propertiesToClear?: string[];
}

export interface IMembershipTypeClearActionCreator {
    (propertiesToClear?: string[]): IMembershipTypeClearAction;
}

export interface IMembershipTypeFilterActionCreator {
    (formFilter: IFormFilter): IMembershipTypeFilterAction;
}

export interface IMembershipTypeFilterAction {
    type: "ACTION_MEMBERSHIPTYPES_FILTER";
    formFilter: IFormFilter;
}

export type MembershipTypeAction =
    IMembershipTypeLoadAction |
    IMembershipTypeLoadByIdAction |
    IMembershipTypeLoadFailureAction |
    IMembershipTypeLoadSuccessAction |
    IMembershipTypeSaveAction |
    IMembershipTypeSaveSuccessAction |
    IMembershipTypeSaveFailureAction |
    IMembershipTypeCreateAction |
    IMembershipTypeCreateSuccessAction |
    IMembershipTypeCreateFailureAction |
    IMembershipTypeClearAction |
    IMembershipTypeFilterAction;
