import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const personnelRolesApi = {
    getPersonnelRoleById: (id: number): Observable<Dtos.GetPersonnelRoleByIdResponse> => {
        //Create the request.
        let request: Dtos.GetPersonnelRoleById = new Dtos.GetPersonnelRoleById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetPersonnelRoleByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    createPersonnelRole: (personnelId: number, trialId: number, institutionId: number, groupId: number, departmentId: number): Observable<Dtos.CreatePersonnelRoleResponse> => {
        //Create the request.
        let request: Dtos.CreatePersonnelRole = new Dtos.CreatePersonnelRole();

        request.personnelId = personnelId;
        request.trialId= trialId;
        request.institutionId = institutionId;
        request.groupId = groupId;
        request.departmentId = departmentId;

        //Send request.
        const response: Promise<Dtos.CreatePersonnelRoleResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setPersonnelRole: (personnelRole: Dtos.PersonnelRole): Observable<Dtos.SetPersonnelRoleResponse> => {

        let request: Dtos.SetPersonnelRole = new Dtos.SetPersonnelRole();

        request.personnelRole = personnelRole;

        // send request
        const response: Promise<Dtos.SetPersonnelRoleResponse> = client.post(request);

        return Observable.from(response);

    },
    resendEmail: (id: number): Observable<Dtos.PostPersonnelRoleResendEmailResponse> => {

        let request: Dtos.PostPersonnelRoleResendEmail = new Dtos.PostPersonnelRoleResendEmail();

        request.id = id;

        // send request
        const response: Promise<Dtos.PostPersonnelRoleResendEmailResponse> = client.post(request);

        return Observable.from(response);
    }
}