import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Tmd.dtos';

import {
    ACTION_PERSONNELROLE_LOAD_BY_ID,
    ACTION_PERSONNELROLE_LOAD_FAILURE,
    ACTION_PERSONNELROLE_LOAD_SUCCESS,

    ACTION_PERSONNELROLE_CREATE,
    ACTION_PERSONNELROLE_CREATE_SUCCESS,
    ACTION_PERSONNELROLE_CREATE_FAILURE,

    ACTION_PERSONNELROLE_SAVE,
    ACTION_PERSONNELROLE_SAVE_SUCCESS,
    ACTION_PERSONNELROLE_SAVE_FAILURE,

    ACTION_PERSONNELROLE_RESENDEMAIL,
    ACTION_PERSONNELROLE_RESENDEMAIL_SUCCESS,
    ACTION_PERSONNELROLE_RESENDEMAIL_FAILURE,

    ACTION_PERSONNELROLE_CLEAR,
    PersonnelRoleAction
} from '../actions/personnelRole';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState } from "./index";
import { updateFailureIndexToId } from "../helpers/formErrorHelper";

export interface IPersonnelRoleState {
    formData: Dtos.PersonnelRole | Dtos.PersonnelRole[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    validationFailures: Dtos.ResponseError[];
    lookups: Dtos.Lookup[],
    loadState: IRequestState;
    saveState: IRequestState;
    updateState: IRequestState;
    createState: IRequestState;
    resendingState: IRequestState;
}

const initialState: IPersonnelRoleState = {
    formData: undefined,
    formState: undefined,
    formProperties: undefined,
    validationFailures: undefined,
    lookups: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    updateState: {
        status: RequestState.None
    } as IRequestState,
    createState: {
        status: RequestState.None
    } as IRequestState,
    resendingState: {
        status: RequestState.None
    } as IRequestState,
};


function loading(state: IPersonnelRoleState, isLoading: boolean): IPersonnelRoleState {
    const newState: IPersonnelRoleState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(
    state: IPersonnelRoleState,
    formData: Dtos.PersonnelRole | Dtos.PersonnelRole[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[],
    responseStatus: Dtos.ResponseStatus
): IPersonnelRoleState {
    const newState: IPersonnelRoleState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        loadState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function loadFailure(state: IPersonnelRoleState, responseStatus: Dtos.ResponseStatus): IPersonnelRoleState {
    const newState: IPersonnelRoleState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: IPersonnelRoleState, propertiesToClear?: string[]): IPersonnelRoleState {

    if (!propertiesToClear) {
        const newState: IPersonnelRoleState = update(initialState, {});
        return newState;
    }

    let stateModifier: any = {};

    propertiesToClear.forEach(propertyToClear => {
        stateModifier[propertyToClear] = { $set: initialState[propertyToClear] }
    });

    const newState = update(state, stateModifier);

    return newState;
}

function creating(state: IPersonnelRoleState, isCreating: boolean): IPersonnelRoleState {
    const newState: IPersonnelRoleState = update(state, {
        createState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function createSuccess(
    state: IPersonnelRoleState,
    formData: Dtos.PersonnelRole,
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[]
): IPersonnelRoleState {
    const newState: IPersonnelRoleState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        createState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function createFailure(state: IPersonnelRoleState, responseStatus: Dtos.ResponseStatus): IPersonnelRoleState {
    const newState: IPersonnelRoleState = update(state, {
        createState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function saving(state: IPersonnelRoleState, isSaving: boolean): IPersonnelRoleState {
    const newState: IPersonnelRoleState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function saveSuccess(
    state: IPersonnelRoleState,
    formData: Dtos.PersonnelRole | Dtos.PersonnelRole[],
    formState: Dtos.FormState,
    lookups: Dtos.Lookup[],
    responseStatus: Dtos.ResponseStatus
): IPersonnelRoleState {
    const newState: IPersonnelRoleState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        saveState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        lookups: {
            $set: lookups
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function saveFailure(state: IPersonnelRoleState, responseStatus: Dtos.ResponseStatus): IPersonnelRoleState {
    const newState: IPersonnelRoleState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                meta: responseStatus.meta
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, state.formData) : undefined
        }
    });

    return newState;
}


function resending(state: IPersonnelRoleState, isRunning: boolean): IPersonnelRoleState {
    const newState: IPersonnelRoleState = update(state, {
        resendingState: {
            $set: {
                status: isRunning ? RequestState.Pending : RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function resendingFailure(state: IPersonnelRoleState, responseStatus: Dtos.ResponseStatus): IPersonnelRoleState {
    const newState: IPersonnelRoleState = update(state, {
        resendingState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                meta: responseStatus.meta
            } as IRequestState
        }
    });

    return newState;
}

const PersonnelRoleReducer: Reducer<IPersonnelRoleState> = (state: IPersonnelRoleState = initialState, action: PersonnelRoleAction) => {
    switch (action.type) {
        case ACTION_PERSONNELROLE_LOAD_BY_ID:
            return loading(state, true);
        case ACTION_PERSONNELROLE_LOAD_SUCCESS:
            return loadSuccess(state, action.formData, action.formState, action.formProperties, action.lookups, action.responseStatus);
        case ACTION_PERSONNELROLE_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_PERSONNELROLE_CLEAR:
            return clear(state, action.propertiesToClear);
        case ACTION_PERSONNELROLE_CREATE:
            return creating(state, true);
        case ACTION_PERSONNELROLE_CREATE_SUCCESS:
            return createSuccess(state, action.formData, action.formState, action.formProperties, action.lookups);
        case ACTION_PERSONNELROLE_CREATE_FAILURE:
            return createFailure(state, action.responseStatus);
        case ACTION_PERSONNELROLE_SAVE:
            return saving(state, true);
        case ACTION_PERSONNELROLE_SAVE_SUCCESS:
            return saveSuccess(state, action.formData, action.formState, action.lookups, action.responseStatus);
        case ACTION_PERSONNELROLE_SAVE_FAILURE:
            return saveFailure(state, action.responseStatus);
        case ACTION_PERSONNELROLE_RESENDEMAIL:
            return resending(state, true);
        case ACTION_PERSONNELROLE_RESENDEMAIL_SUCCESS:
            return resending(state, false);
        case ACTION_PERSONNELROLE_RESENDEMAIL_FAILURE:
            return resendingFailure(state, action.responseStatus);
    }

    return state;
}

export default PersonnelRoleReducer;