import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    Load: IOpenClinicaUserRolesLoadActionCreator = () => {
        return {
            type: ACTION_OPENCLINICAUSERROLES_LOAD
        }
    },
    LoadSuccess: IOpenClinicaUserRolesLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_OPENCLINICAUSERROLES_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IOpenClinicaUserRolesLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_OPENCLINICAUSERROLES_LOAD_FAILURE,
            responseStatus
        }
    },
    Rebuild: IOpenClinicaUserRolesRebuildActionCreator = (personnelIds) => {
        return {
            type: ACTION_OPENCLINICAUSERROLES_REBUILD,
            personnelIds
        }
    },
    RebuildSuccess: IOpenClinicaUserRolesRebuildSuccessActionCreator = (responseStatus) => {
        return {
            type: ACTION_OPENCLINICAUSERROLES_REBUILD_SUCCESS,
            responseStatus
        }
    },
    RebuildFailure: IOpenClinicaUserRolesRebuildFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_OPENCLINICAUSERROLES_REBUILD_FAILURE,
            responseStatus
        }
    },
    ChangeStatus: IOpenClinicaUserRolesChangeStatusActionCreator = (id, ocSyncStatus) => {
        return {
            type: ACTION_OPENCLINICAUSERROLES_CHANGESTATUS,
            id,
            ocSyncStatus
        }
    },
    ChangeStatusSuccess: IOpenClinicaUserRolesChangeStatusSuccessActionCreator = (openClinicaUserRole, responseStatus) => {
        return {
            type: ACTION_OPENCLINICAUSERROLES_CHANGESTATUS_SUCCESS,
            openClinicaUserRole,
            responseStatus
        }
    },
    ChangeStatusFailure: IOpenClinicaUserRolesChangeStatusFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_OPENCLINICAUSERROLES_CHANGESTATUS_FAILURE,
            responseStatus
        }
    },
    ResendEmail: IOpenClinicaUserRolesResendEmailActionCreator = (id) => {
        return {
            type: ACTION_OPENCLINICAUSERROLES_RESENDEMAIL,
            id
        }
    },
    ResendEmailSuccess: IOpenClinicaUserRolesResendEmailSuccessActionCreator = (responseStatus) => {
        return {
            type: ACTION_OPENCLINICAUSERROLES_RESENDEMAIL_SUCCESS,
            responseStatus
        }
    },
    ResendEmailFailure: IOpenClinicaUserRolesResendEmailFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_OPENCLINICAUSERROLES_RESENDEMAIL_FAILURE,
            responseStatus
        }
    },
    Clear: IOpenClinicaUserRolesClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_OPENCLINICAUSERROLES_CLEAR,
            propertiesToClear
        }
    },
    Filter: IOpenClinicaUserRolesFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_OPENCLINICAUSERROLES_FILTER,
            formFilter
        }
    },
    ImportUsers: IOpenClinicaUserRolesImportActionCreator = () => {
        return {
            type: ACTION_OPENCLINICAUSERROLES_IMPORT
        }
    },
    ImportUsersSuccess: IOpenClinicaUserRolesImportSuccessActionCreator = (responseStatus) => {
        return {
            type: ACTION_OPENCLINICAUSERROLES_IMPORT_SUCCESS,
            responseStatus
        }
    },
    ImportUsersFailure: IOpenClinicaUserRolesImportFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_OPENCLINICAUSERROLES_IMPORT_FAILURE,
            responseStatus
        }
    };




export const
    ACTION_OPENCLINICAUSERROLES_LOAD = "ACTION_OPENCLINICAUSERROLES_LOAD",
    ACTION_OPENCLINICAUSERROLES_LOAD_SUCCESS = "ACTION_OPENCLINICAUSERROLES_LOAD_SUCCESS",
    ACTION_OPENCLINICAUSERROLES_LOAD_FAILURE = "ACTION_OPENCLINICAUSERROLES_LOAD_FAILURE",

    ACTION_OPENCLINICAUSERROLES_REBUILD = "ACTION_OPENCLINICAUSERROLES_REBUILD",
    ACTION_OPENCLINICAUSERROLES_REBUILD_SUCCESS = "ACTION_OPENCLINICAUSERROLES_REBUILD_SUCCESS",
    ACTION_OPENCLINICAUSERROLES_REBUILD_FAILURE = "ACTION_OPENCLINICAUSERROLES_REBUILD_FAILURE",

    ACTION_OPENCLINICAUSERROLES_CHANGESTATUS = "ACTION_OPENCLINICAUSERROLES_CHANGESTATUS",
    ACTION_OPENCLINICAUSERROLES_CHANGESTATUS_SUCCESS = "ACTION_OPENCLINICAUSERROLES_CHANGESTATUS_SUCCESS",
    ACTION_OPENCLINICAUSERROLES_CHANGESTATUS_FAILURE = "ACTION_OPENCLINICAUSERROLES_CHANGESTATUS_FAILURE",

    ACTION_OPENCLINICAUSERROLES_RESENDEMAIL = "ACTION_OPENCLINICAUSERROLES_RESENDEMAIL",
    ACTION_OPENCLINICAUSERROLES_RESENDEMAIL_SUCCESS = "ACTION_OPENCLINICAUSERROLES_RESENDEMAIL_SUCCESS",
    ACTION_OPENCLINICAUSERROLES_RESENDEMAIL_FAILURE = "ACTION_OPENCLINICAUSERROLES_RESENDEMAIL_FAILURE",

    ACTION_OPENCLINICAUSERROLES_CLEAR = "ACTION_OPENCLINICAUSERROLES_CLEAR",

    ACTION_OPENCLINICAUSERROLES_FILTER = "ACTION_OPENCLINICAUSERROLES_FILTER",

    ACTION_OPENCLINICAUSERROLES_IMPORT = "ACTION_OPENCLINICAUSERROLES_IMPORT",
    ACTION_OPENCLINICAUSERROLES_IMPORT_SUCCESS = "ACTION_OPENCLINICAUSERROLES_IMPORT_SUCCESS",
    ACTION_OPENCLINICAUSERROLES_IMPORT_FAILURE = "ACTION_OPENCLINICAUSERROLES_IMPORT_FAILURE"
    ;

export interface IOpenClinicaUserRolesLoadAction {
    type: "ACTION_OPENCLINICAUSERROLES_LOAD";
}

export interface IOpenClinicaUserRolesLoadActionCreator {
    (): IOpenClinicaUserRolesLoadAction;
}

export interface IOpenClinicaUserRolesLoadSuccessAction {
    type: "ACTION_OPENCLINICAUSERROLES_LOAD_SUCCESS";
    formData: Dtos.OpenClinicaUserRole[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IOpenClinicaUserRolesLoadSuccessActionCreator {
    (
        formData: Dtos.OpenClinicaUserRole[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IOpenClinicaUserRolesLoadSuccessAction;
}

export interface IOpenClinicaUserRolesLoadFailureAction {
    type: "ACTION_OPENCLINICAUSERROLES_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IOpenClinicaUserRolesLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IOpenClinicaUserRolesLoadFailureAction;
}

export interface IOpenClinicaUserRolesRebuildAction {
    type: "ACTION_OPENCLINICAUSERROLES_REBUILD";
    personnelIds: number[];
}

export interface IOpenClinicaUserRolesRebuildActionCreator {
    (personnelIds: number[]): IOpenClinicaUserRolesRebuildAction;
}

export interface IOpenClinicaUserRolesRebuildSuccessAction {
    type: "ACTION_OPENCLINICAUSERROLES_REBUILD_SUCCESS";
    responseStatus: Dtos.ResponseStatus;
}

export interface IOpenClinicaUserRolesRebuildSuccessActionCreator {
    (
        responseStatus: Dtos.ResponseStatus
    ): IOpenClinicaUserRolesRebuildSuccessAction;
}

export interface IOpenClinicaUserRolesRebuildFailureAction {
    type: "ACTION_OPENCLINICAUSERROLES_REBUILD_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IOpenClinicaUserRolesRebuildFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IOpenClinicaUserRolesRebuildFailureAction;
}

export interface IOpenClinicaUserRolesChangeStatusAction {
    type: "ACTION_OPENCLINICAUSERROLES_CHANGESTATUS";
    id: number;
    ocSyncStatus: Dtos.OCSyncStatus;
}

export interface IOpenClinicaUserRolesChangeStatusActionCreator {
    (id: number, ocSyncStatus: Dtos.OCSyncStatus): IOpenClinicaUserRolesChangeStatusAction;
}

export interface IOpenClinicaUserRolesChangeStatusSuccessAction {
    type: "ACTION_OPENCLINICAUSERROLES_CHANGESTATUS_SUCCESS";
    openClinicaUserRole: Dtos.OpenClinicaUserRole;
    responseStatus: Dtos.ResponseStatus;
}

export interface IOpenClinicaUserRolesChangeStatusSuccessActionCreator {
    (
        openClinicaUserRole: Dtos.OpenClinicaUserRole,
        responseStatus: Dtos.ResponseStatus
    ): IOpenClinicaUserRolesChangeStatusSuccessAction;
}

export interface IOpenClinicaUserRolesChangeStatusFailureAction {
    type: "ACTION_OPENCLINICAUSERROLES_CHANGESTATUS_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IOpenClinicaUserRolesChangeStatusFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IOpenClinicaUserRolesChangeStatusFailureAction;
}

export interface IOpenClinicaUserRolesResendEmailAction {
    type: "ACTION_OPENCLINICAUSERROLES_RESENDEMAIL";
    id: number;
}

export interface IOpenClinicaUserRolesResendEmailActionCreator {
    (id: number): IOpenClinicaUserRolesResendEmailAction;
}

export interface IOpenClinicaUserRolesResendEmailSuccessAction {
    type: "ACTION_OPENCLINICAUSERROLES_RESENDEMAIL_SUCCESS";
    responseStatus: Dtos.ResponseStatus;
}

export interface IOpenClinicaUserRolesResendEmailSuccessActionCreator {
    (
        responseStatus: Dtos.ResponseStatus
    ): IOpenClinicaUserRolesResendEmailSuccessAction;
}

export interface IOpenClinicaUserRolesResendEmailFailureAction {
    type: "ACTION_OPENCLINICAUSERROLES_RESENDEMAIL_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IOpenClinicaUserRolesResendEmailFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IOpenClinicaUserRolesResendEmailFailureAction;
}

export interface IOpenClinicaUserRolesClearAction {
    type: "ACTION_OPENCLINICAUSERROLES_CLEAR";
    propertiesToClear?: string[];
}

export interface IOpenClinicaUserRolesClearActionCreator {
    (propertiesToClear?: string[]): IOpenClinicaUserRolesClearAction;
}

export interface IOpenClinicaUserRolesFilterActionCreator {
    (formFilter: IFormFilter): IOpenClinicaUserRolesFilterAction;
}

export interface IOpenClinicaUserRolesFilterAction {
    type: "ACTION_OPENCLINICAUSERROLES_FILTER";
    formFilter: IFormFilter;
}

export interface IOpenClinicaUserRolesImportAction {
    type: "ACTION_OPENCLINICAUSERROLES_IMPORT";
}

export interface IOpenClinicaUserRolesImportActionCreator {
    (): IOpenClinicaUserRolesImportAction;
}

export interface IOpenClinicaUserRolesImportSuccessAction {
    type: "ACTION_OPENCLINICAUSERROLES_IMPORT_SUCCESS";
    responseStatus: Dtos.ResponseStatus;
}

export interface IOpenClinicaUserRolesImportSuccessActionCreator {
    (
        responseStatus: Dtos.ResponseStatus
    ): IOpenClinicaUserRolesImportSuccessAction;
}

export interface IOpenClinicaUserRolesImportFailureAction {
    type: "ACTION_OPENCLINICAUSERROLES_IMPORT_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IOpenClinicaUserRolesImportFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IOpenClinicaUserRolesImportFailureAction;
}


export type OpenClinicaUserRolesAction =
    IOpenClinicaUserRolesLoadAction |
    IOpenClinicaUserRolesLoadFailureAction |
    IOpenClinicaUserRolesLoadSuccessAction |
    IOpenClinicaUserRolesRebuildAction |
    IOpenClinicaUserRolesRebuildSuccessAction |
    IOpenClinicaUserRolesRebuildFailureAction |
    IOpenClinicaUserRolesChangeStatusAction |
    IOpenClinicaUserRolesChangeStatusSuccessAction |
    IOpenClinicaUserRolesChangeStatusFailureAction |
    IOpenClinicaUserRolesResendEmailAction |
    IOpenClinicaUserRolesResendEmailSuccessAction |
    IOpenClinicaUserRolesResendEmailFailureAction |
    IOpenClinicaUserRolesFilterAction | 
    IOpenClinicaUserRolesClearAction |
    IOpenClinicaUserRolesImportAction |
    IOpenClinicaUserRolesImportSuccessAction |
    IOpenClinicaUserRolesImportFailureAction;
