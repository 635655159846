import * as React from 'react';
import './PersonnelsPage.scss';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { RowInfo } from 'react-table';
import { RestrictedLayout } from "../../../components/layouts/index";
import * as PersonnelActions from '../../../actions/personnel';
import * as PagingActions from '../../../actions/pages/pagingPage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { Modal } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { ModalSize } from '../../../enumerations/ModalSize';
import { FormFilterInput } from '../../../components/form';

interface IPersonnelsPageProps {
    location: Location;
    history: History;

    // From state
    personnel: Dtos.Personnel[];
    formFilter: IFormFilter;
    formLength: number;

    loadingPersonnel: boolean;
    loadPersonnelSuccess: boolean;
    loadPersonnelFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    pageSize: number;
}

interface IPersonnelsPageActions {
    clearPersonnel: PersonnelActions.IPersonnelClearActionCreator;
    loadPersonnel: PersonnelActions.IPersonnelLoadActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    filter: PersonnelActions.IPersonnelFilterActionCreator;

    setPageSize: PagingActions.IPagingPageSizeChangedStateActionCreator;
}

type PersonnelsPageProps = IPersonnelsPageProps & IPersonnelsPageActions;

class PersonnelsPage extends React.PureComponent<PersonnelsPageProps, any> {

    constructor(props: PersonnelsPageProps) {
        super(props);

        this.clearPersonnel = this.clearPersonnel.bind(this);
        this.toggleExportMenu = this.toggleExportMenu.bind(this);

        this.state = {
            exportToggle: false
        };
    }

    componentDidMount() {
        const {
            loadPersonnel,
        } = this.props;

        loadPersonnel();
    }

    componentWillUnmount() {
        this.clearPersonnel();
    }

    toggleExportMenu() {
        this.setState({
            exportToggle: !this.state.exportToggle
        });
    }

    clearPersonnel() {
        this.props.clearPersonnel();
    }

    showPersonnel(): boolean {
        const {
            loadingPersonnel,
            loadPersonnelSuccess
        } = this.props

        if (!loadingPersonnel && loadPersonnelSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingPersonnel
        } = this.props;

        return <RestrictedLayout
            permission={Dtos.Permission.PersonnelView}
            loading={loadingPersonnel }
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            permissions
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col col-auto mb-2"}>
                    <h2>Personnel</h2>
                </div>
                <div className="col" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} />
                    {AuthenticationHelper.hasPermission(permissions, Dtos.Permission.PersonnelUpdate) && (
                        <div className="btn-group mr-2">
                            <button type="button" className="btn btn-info" onClick={() => this.toggleExportMenu()}>
                                <FontAwesomeIcon icon={FontAwesomeIcons.Solid.CLOUD_DOWNLOAD} fixedWidth />
                            </button>
                            <Modal open={this.state.exportToggle} disableCloseOnOverlayClick={true} onClose={() => { this.toggleExportMenu() }} size={ModalSize.Md}>
                                <div className="modal-header">
                                    <h5 className="modal-title">Download</h5>
                                    <button type="button" className="close" aria-label="Close" onClick={() => { this.toggleExportMenu() }}>
                                        <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                                    </button>
                                </div>
                                <div className="modal-body p-3">
                                    <a className="btn btn-outline-primary btn-block"
                                        onClick={() => this.toggleExportMenu()}
                                        href={encodeURI("/api/personnelrole/export/0/0?filter=" + this.props.formFilter.search + "&showInactive=" + this.props.formFilter.showInActive)}
                                    ><b>Personnel List</b><br /><small>The listed personnel as a CSV file</small></a>
                                 </div>
                            </Modal>
                        </div>
                    )}
                    <Link className={"btn btn-secondary mr-2"} url={"/personnel/byroles"}>Group By Roles</Link>
                    {AuthenticationHelper.hasPermission(permissions, Dtos.Permission.PersonnelCreate) && (
                        <Link className={"btn btn-primary"} url={"/personnel/create"}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.showPersonnel() ?
                    "Loading Personnel" :
                    null
            }
            {
                this.showPersonnel() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {
        const {
            personnel,
            pageSize,
            setPageSize
        } = this.props

        if (!personnel || personnel.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no personnel to view.</span>
            </div>
        }

        const showPagination = personnel.length > pageSize;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={personnel}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? pageSize : personnel.length}
                pageSize={showPagination ? pageSize : personnel.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no personnel to display"
                resizable={false}
                onPageSizeChange={val => setPageSize(val)}
                defaultSorted={[
                    {
                        id: "firstName",
                        desc: false
                    },
                    {
                        id: "lastName",
                        desc: false
                    }
                ]}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {

                            const url: string = "/personnel/" + rowInfo.original.id + "/details";
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "activeDirectoryUser",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={(props.value === undefined || props.value === null || props.value === "")? FontAwesomeIcons.Light.USER : FontAwesomeIcons.Light.IDBADGE} fixedWidth />
                    },
                    {
                        id: "salutationId",
                        Header: 'Salutation',
                        accessor: 'salutation.value',
                        className: "d-flex align-items-left justify-content-left",
                        maxWidth: 100,
                        headerClassName: "text-left"
                    },
                    {
                        id: "firstName",
                        Header: 'First Name',
                        accessor: 'firstName',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    },
                    {
                        id: "lastName",
                        Header: 'Last Name',
                        accessor: 'lastName',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    },
                    {
                        id: "email",
                        Header: 'Email',
                        accessor: 'email',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    },                    
                    {
                        id: "userName",
                        Header: 'User Name',
                        accessor: 'userName',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        maxWidth: 220
                    }
                ]}
            />
        </div>
    }

}

const mapStateToProps = (state: ITmdState, ownProps: PersonnelsPageProps): IPersonnelsPageProps => {

    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        history: ownProps.history,
        location: state.routing.location,

        personnel: state.personnel.formFilteredData!,
        formFilter: state.personnel.formFilter,
        formLength: state.personnel.formLength,

        loadingPersonnel: state.personnel.loadState && state.personnel.loadState.status == RequestState.Pending,
        loadPersonnelSuccess: state.personnel.loadState && state.personnel.loadState.status == RequestState.Success,
        loadPersonnelFailure: state.personnel.loadState && state.personnel.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        pageSize: state.pagingPage.pageSize
    };
};

const mapDispatchToProps = (dispatch): IPersonnelsPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadPersonnel: bindActionCreators(PersonnelActions.LoadPersonnel, dispatch),
        clearPersonnel: bindActionCreators(PersonnelActions.Clear, dispatch),

        filter: bindActionCreators(PersonnelActions.Filter, dispatch),

        setPageSize: bindActionCreators(PagingActions.PageSizeChanged, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(PersonnelsPage);
