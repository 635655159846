import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadTrialCategory: ITrialCategoryLoadActionCreator = () => {
        return {
            type: ACTION_TRIALCATEGORYS_LOAD
        }
    },
    LoadTrialCategoryById: ITrialCategoryLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TRIALCATEGORYS_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ITrialCategoryLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALCATEGORYS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrialCategoryLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALCATEGORYS_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTrialCategory: ITrialCategorySaveActionCreator = (formData) => {
        return {
            type: ACTION_TRIALCATEGORYS_SAVE,
            formData
        }
    },
    SaveSuccess: ITrialCategorySaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRIALCATEGORYS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrialCategorySaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALCATEGORYS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTrialCategory: ITrialCategoryCreateActionCreator = () => {

        return {
            type: ACTION_TRIALCATEGORYS_CREATE
        }
    },
    CreateSuccess: ITrialCategoryCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_TRIALCATEGORYS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: ITrialCategoryCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALCATEGORYS_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: ITrialCategoryClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRIALCATEGORYS_CLEAR,
            propertiesToClear
        }
    },
    Filter: ITrialCategoryFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRIALCATEGORYS_FILTER,
            formFilter
        }
    };



export const
    ACTION_TRIALCATEGORYS_LOAD = "ACTION_TRIALCATEGORYS_LOAD",
    ACTION_TRIALCATEGORYS_LOAD_BY_ID = "ACTION_TRIALCATEGORYS_LOAD_BY_ID",
    ACTION_TRIALCATEGORYS_LOAD_SUCCESS = "ACTION_TRIALCATEGORYS_LOAD_SUCCESS",
    ACTION_TRIALCATEGORYS_LOAD_FAILURE = "ACTION_TRIALCATEGORYS_LOAD_FAILURE",

    ACTION_TRIALCATEGORYS_SAVE = "ACTION_TRIALCATEGORYS_SAVE",
    ACTION_TRIALCATEGORYS_SAVE_SUCCESS = "ACTION_TRIALCATEGORYS_SAVE_SUCCESS",
    ACTION_TRIALCATEGORYS_SAVE_FAILURE = "ACTION_TRIALCATEGORYS_SAVE_FAILURE",

    ACTION_TRIALCATEGORYS_CREATE = "ACTION_TRIALCATEGORYS_CREATE",
    ACTION_TRIALCATEGORYS_CREATE_SUCCESS = "ACTION_TRIALCATEGORYS_CREATE_SUCCESS",
    ACTION_TRIALCATEGORYS_CREATE_FAILURE = "ACTION_TRIALCATEGORYS_CREATE_FAILURE",

    ACTION_TRIALCATEGORYS_CLEAR = "ACTION_TRIALCATEGORYS_CLEAR",

    ACTION_TRIALCATEGORYS_FILTER = "ACTION_TRIALCATEGORYS_FILTER";

export interface ITrialCategoryLoadAction {
    type: "ACTION_TRIALCATEGORYS_LOAD";
}

export interface ITrialCategoryLoadActionCreator {
    (): ITrialCategoryLoadAction;
}

export interface ITrialCategoryLoadSuccessAction {
    type: "ACTION_TRIALCATEGORYS_LOAD_SUCCESS";
    formData: Dtos.TrialCategory | Dtos.TrialCategory[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialCategoryLoadSuccessActionCreator {
    (
        formData: Dtos.TrialCategory | Dtos.TrialCategory[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialCategoryLoadSuccessAction;
}

export interface ITrialCategoryLoadFailureAction {
    type: "ACTION_TRIALCATEGORYS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialCategoryLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialCategoryLoadFailureAction;
}

export interface ITrialCategoryLoadByIdAction {
    type: "ACTION_TRIALCATEGORYS_LOAD_BY_ID";
    id: number;
}

export interface ITrialCategoryLoadByIdActionCreator {
    (id: number): ITrialCategoryLoadByIdAction;
}


export interface ITrialCategorySaveAction {
    type: "ACTION_TRIALCATEGORYS_SAVE";
    formData: Dtos.TrialCategory;

}

export interface ITrialCategorySaveActionCreator {
    (
        formData: Dtos.TrialCategory
    ): ITrialCategorySaveAction;
}

export interface ITrialCategorySaveSuccessAction {
    type: "ACTION_TRIALCATEGORYS_SAVE_SUCCESS";
    formData: Dtos.TrialCategory;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialCategorySaveSuccessActionCreator {
    (
        formData: Dtos.TrialCategory,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrialCategorySaveSuccessAction;
}

export interface ITrialCategorySaveFailureAction {
    type: "ACTION_TRIALCATEGORYS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialCategorySaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialCategorySaveFailureAction;
}

export interface ITrialCategoryCreateAction {
    type: "ACTION_TRIALCATEGORYS_CREATE"
}

export interface ITrialCategoryCreateActionCreator {
    (): ITrialCategoryCreateAction
}

export interface ITrialCategoryCreateSuccessAction {
    type: "ACTION_TRIALCATEGORYS_CREATE_SUCCESS";
    formData: Dtos.TrialCategory;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface ITrialCategoryCreateSuccessActionCreator {
    (
        formData: Dtos.TrialCategory,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): ITrialCategoryCreateSuccessAction;
}

export interface ITrialCategoryCreateFailureAction {
    type: "ACTION_TRIALCATEGORYS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialCategoryCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialCategoryCreateFailureAction;
}

export interface ITrialCategoryClearAction {
    type: "ACTION_TRIALCATEGORYS_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrialCategoryClearActionCreator {
    (propertiesToClear?: string[]): ITrialCategoryClearAction;
}

export interface ITrialCategoryFilterActionCreator {
    (formFilter: IFormFilter): ITrialCategoryFilterAction;
}

export interface ITrialCategoryFilterAction {
    type: "ACTION_TRIALCATEGORYS_FILTER";
    formFilter: IFormFilter;
}

export type TrialCategoryAction =
    ITrialCategoryLoadAction |
    ITrialCategoryLoadByIdAction |
    ITrialCategoryLoadFailureAction |
    ITrialCategoryLoadSuccessAction |
    ITrialCategorySaveAction |
    ITrialCategorySaveSuccessAction |
    ITrialCategorySaveFailureAction |
    ITrialCategoryCreateAction |
    ITrialCategoryCreateSuccessAction |
    ITrialCategoryCreateFailureAction |
    ITrialCategoryClearAction |
    ITrialCategoryFilterAction;
