import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const keycontactsApi = {
    getKeyContacts: (): Observable<Dtos.GetKeyContactsResponse> => {
        //Create the request.
        let request: Dtos.GetKeyContacts = new Dtos.GetKeyContacts();

        //Send request.
        const response: Promise<Dtos.GetKeyContactsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getKeyContactById: (id: number): Observable<Dtos.GetKeyContactByIdResponse> => {
        //Create the request.
        let request: Dtos.GetKeyContactById = new Dtos.GetKeyContactById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetKeyContactByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setKeyContact: (keyContact: Dtos.KeyContact): Observable<Dtos.SetKeyContactResponse> => {

        let request: Dtos.SetKeyContact = new Dtos.SetKeyContact();

        request.keyContact = keyContact;

        // send request
        const response: Promise<Dtos.SetKeyContactResponse> = client.post(request);

        return Observable.from(response);

    },
    createKeyContact: (): Observable<Dtos.CreateKeyContactResponse> => {
        //Create the request.
        let request: Dtos.CreateKeyContact = new Dtos.CreateKeyContact();

        //Send request.
        const response: Promise<Dtos.CreateKeyContactResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}