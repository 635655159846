import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const notesApi = {
    getNoteById: (id: number): Observable<Dtos.GetNoteByIdResponse> => {
        //Create the request.
        let request: Dtos.GetNoteById = new Dtos.GetNoteById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetNoteByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    createNote: (
        trialId: number,
        institutionId: number,
        trialInstitutionId: number,
        personnelId: number,
        groupId: number,
    ): Observable<Dtos.CreateNoteResponse> => {
        //Create the request.
        let request: Dtos.CreateNote = new Dtos.CreateNote();

        request.trialId = trialId;
        request.institutionId = institutionId;
        request.trialInstitutionId = trialInstitutionId;
        request.personnelId = personnelId;
        request.groupId = groupId;

        //Send request.
        const response: Promise<Dtos.CreateNoteResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setNote: (note: Dtos.Note): Observable<Dtos.SetNoteResponse> => {

        let request: Dtos.SetNote = new Dtos.SetNote();

        request.note = note;

        // send request
        const response: Promise<Dtos.SetNoteResponse> = client.post(request);

        return Observable.from(response);

    },
}