import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialDepartmentContactState: Dtos.DepartmentContact & IRegistrationFormState = {
    id: null,
    departmentId: null,
    department: null,
    name: null,
    professionalDisciplineId: null,
    professionalDiscipline: null,
    professionalDisciplineDisplay: null,
    otherDisciplines: null,
    email: null,
    phone: null,
    active: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false
}

export interface IDepartmentContactReduxFormState {
    id: IFieldState<number>,
    departmentId: IFieldState<number>,
    name: IFieldState<string>,
    professionalDisciplineId: IFieldState<number>,
    otherDisciplines: IFieldState<string>,
    email: IFieldState<string>,
    phone: IFieldState<string>,
    active: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
}
