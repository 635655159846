import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialTrialItemInstanceState: Dtos.TrialItemInstance & IRegistrationFormState = {
    id: null,
    trialId: null,
    trial: null,
    templateTrialItemId: null,
    templateTrialItem: null,
    version: null,
    versionDate: null,
    description: null,
    originalDescription: null,
    collectionInstanceId: null,
    collectionInstance: null,
    trialItemInstanceStatusId: 1,
    trialItemInstanceStatus: null,
    assignedToId: null,
    assignedTo: null,
    assignedDisplay: null,
    assignedRole: null,
    assignedRoleId: null,
    assignmentTypeId: 1,
    sortOrderId: 1,
    active: null,
    trialItemPreviousInstances: [],
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false
}

export interface ITrialItemInstanceReduxFormState {
    id: IFieldState<number>,
    trialId: IFieldState<number>,
    templateTrialItemId: IFieldState<number>,
    version: IFieldState<string>,
    description: IFieldState<string>,
    collectionInstanceId: IFieldState<number>,
    collectionInstance: IFieldState<Dtos.CollectionInstance>,
    trialItemInstanceStatusId: IFieldState<number>,
    trialItemInstanceStatus: null,
    assignedToId: IFieldState<number>,
    assignedRoleId: IFieldState<number>,
    assignmentTypeId: IFieldState<number>,
    active: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>,
}
