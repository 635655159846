import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const facilityalliancemembersApi = {
    getFacilityAllianceMembers: (): Observable<Dtos.GetFacilityAllianceMembersResponse> => {
        //Create the request.
        let request: Dtos.GetFacilityAllianceMembers = new Dtos.GetFacilityAllianceMembers();

        //Send request.
        const response: Promise<Dtos.GetFacilityAllianceMembersResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getFacilityAllianceMemberById: (id: number): Observable<Dtos.GetFacilityAllianceMemberByIdResponse> => {
        //Create the request.
        let request: Dtos.GetFacilityAllianceMemberById = new Dtos.GetFacilityAllianceMemberById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetFacilityAllianceMemberByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setFacilityAllianceMember: (facilityAllianceMember: Dtos.FacilityAllianceMember): Observable<Dtos.SetFacilityAllianceMemberResponse> => {

        let request: Dtos.SetFacilityAllianceMember = new Dtos.SetFacilityAllianceMember();

        request.facilityAllianceMember = facilityAllianceMember;

        // send request
        const response: Promise<Dtos.SetFacilityAllianceMemberResponse> = client.post(request);

        return Observable.from(response);

    },
    createFacilityAllianceMember: (): Observable<Dtos.CreateFacilityAllianceMemberResponse> => {
        //Create the request.
        let request: Dtos.CreateFacilityAllianceMember = new Dtos.CreateFacilityAllianceMember();

        //Send request.
        const response: Promise<Dtos.CreateFacilityAllianceMemberResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}