
import { INavItem } from "../components/layouts/NavMenu/NavMenu";
import * as Dtos from '../dtos/Tmd.dtos';
import * as AuthenticationHelper from '../helpers/authentication';

export function SettingsSubMenu(permissions: Dtos.PersonnelTmdPermissions[]): INavItem[] {
    let menu: INavItem[] = [];
    if (AuthenticationHelper.hasAnyPermission(permissions, [
        Dtos.Permission.RolesView,
        Dtos.Permission.CountriesView,
        Dtos.Permission.StatesView,
        Dtos.Permission.ItemComponentTypesView,
        Dtos.Permission.NoteTypesView,
        Dtos.Permission.GendersView,
        Dtos.Permission.InterestsView,
        Dtos.Permission.MembershipTypesView,
        Dtos.Permission.ProfessionalDisciplinesView,
        Dtos.Permission.SalutationsView
    ])) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.CLIPBOARD} />,
            title: "General",
            url: "/settings/general/roles"
        });
    }
    if (AuthenticationHelper.hasAnyPermission(permissions, [
        Dtos.Permission.TumorGroupsView,
        Dtos.Permission.AllocatedWPsView,
        Dtos.Permission.TrialPhasesView,
        Dtos.Permission.InvolvementsView,
        Dtos.Permission.TrialCoordCentresView,
        Dtos.Permission.CollaboratingGroupsView,
        Dtos.Permission.TrialTypesView
    ])) {
        menu.push(
            {
                icon: undefined, 
                title: "Trial",
                url: "/settings/trial/trialcategorys"
            }
        );
    }
    if (AuthenticationHelper.hasAnyPermission(permissions, [
        Dtos.Permission.TemplateTrialItemsView,
        Dtos.Permission.TrialInstitutionItemTemplatesView,
        Dtos.Permission.ItemComponentsView,
        Dtos.Permission.CategoriesView
    ])) {
        menu.push(
            {
                icon: undefined,
                title: "Items",
                url: "/settings/item/trialitemtemplates"
            }
        );
    }

    if (AuthenticationHelper.hasAnyPermission(permissions, [
        Dtos.Permission.SiteLocationsView,
        Dtos.Permission.KeyContactsView,
        Dtos.Permission.FacilityAllianceMembersView,
        Dtos.Permission.InstitutionTypesView,
        Dtos.Permission.InstitutionCodingView,

    ])) {
        menu.push(
            {
                icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.CLIPBOARD} />,
                title: "Institution",
                url: "/settings/institution/sitelocations"
            }
        );
    }

    if (AuthenticationHelper.hasAnyPermission(permissions, [
        Dtos.Permission.FormTemplatesView,
        Dtos.Permission.FormComponentsView,
        Dtos.Permission.ReportingTagsView,
        Dtos.Permission.ReportingTagCategoriesView,
    ])) {
        menu.push(
            {
                icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.QUESTION} />,
                title: "QA",
                url: "/settings/qa/formtemplates"
            }
        );
    }

    //if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.ItemComponentsView)) {
    //    menu.push(
    //        {
    //            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.QUESTION} />,
    //            title: "Item Components",
    //            url: "/settings/itemcomponents"
    //        }
    //    );
    //}

    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.OpenClinicaUserRolesView)) {
        menu.push(
            {
                icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.QUESTION} />,
                title: "OpenClinica User Roles",
                url: "/settings/openclinicauserroles"
            }
        );
    }

    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.HubspotContactExportView)) {
        /*menu.push(
            {
                icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.QUESTION} />,
                title: "Hubspot Contact Export",
                url: "/settings/hubspotexport"
            }
        );
        */
        menu.push(
            {
                icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.QUESTION} />,
                title: "Hubspot",
                url: "/settings/hubspot/activemembers"
            }
        );
    }

    return menu;
};

export function GroupSubMenu(groupId: number, permissions: Dtos.PersonnelTmdPermissions[]): INavItem[] {
    let menu: INavItem[] = [];

    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.GroupView)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.QUESTION} />,
            title: "Details",
            url: "/groups/" + groupId + "/details",
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.GroupItemsView)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.COGS} />,
            title: "Items",
            url: "/groups/" + groupId + "/groupitems",
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.GroupPersonnelRoleView)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.COGS} />,
            title: "Personnel",
            url: "/groups/" + groupId + "/personnel",
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialView)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.COGS} />,
            title: "Trials",
            url: "/groups/" + groupId + "/trials",
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.GroupNotesView)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.COGS} />,
            title: "Notes",
            url: "/groups/" + groupId + "/notes",
        });
    }
    return menu;
};

export function InstitutionSubMenu(institutionId: number, permissions: Dtos.PersonnelTmdPermissions[]): INavItem[] {
    let menu: INavItem[] = [];

    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.InstitutionView, undefined, institutionId)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.QUESTION} />,
            title: "Details",
            url: "/institutions/" + institutionId + "/details"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.InstitutionDepartmentView, undefined, institutionId)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.COGS} />,
            title: "Departments",
            url: "/institutions/" + institutionId + "/departments"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.InstitutionPersonnelView, undefined, institutionId)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.COGS} />,
            title: "Personnel",
            url: "/institutions/" + institutionId + "/personnel"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.InstitutionFormView, undefined, institutionId)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.QUESTION} />,
            title: "Forms",
            url: "/institutions/" + institutionId + "/forms"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.InstitutionNoteView, undefined, institutionId)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.COGS} />,
            title: "Notes",
            url: "/institutions/" + institutionId + "/notes"
        });
    }
    return menu;
};

export function PersonnelSubMenu(personnelId: number, permissions: Dtos.PersonnelTmdPermissions[]): INavItem[] {
    let menu: INavItem[] = [];
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.PersonnelView)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.QUESTION} />,
            title: "Details",
            url: "/personnel/" + personnelId + "/details"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.PersonnelItemsView)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.QUESTION} />,
            title: "Items",
            url: "/personnel/" + personnelId + "/items"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.PersonnelHubspotView)) {
        menu.push({
            icon: undefined,
            title: "Hubspot",
            url: "/personnel/" + personnelId + "/hubspot"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.PersonnelRoleView)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.COGS} />,
            title: "Institutions and Roles",
            url: "/personnel/" + personnelId + "/roles"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.PersonnelRoleView)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.COGS} />,
            title: "Permissions",
            url: "/personnel/" + personnelId + "/permissions"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.PersonnelNoteView)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.COGS} />,
            title: "Notes",
            url: "/personnel/" + personnelId + "/notes"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.PersonnelSecurityView)) {
        menu.push({
            icon: undefined,
            title: "Login & Security",
            url: "/personnel/" + personnelId + "/activedirectory"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.PersonnelOpenClinicaView)) {
        menu.push({
            icon: undefined,
            title: "Open Clinica",
            url: "/personnel/" + personnelId + "/openclinica"
        });
    }
    return menu;
};

export function TrialSubMenu(trialId: number, permissions: Dtos.PersonnelTmdPermissions[]): INavItem[] {
    let menu: INavItem[] = [];
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialView, trialId, undefined)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.QUESTION} />,
            title: "Details",
            url: "/trials/" + trialId + "/details"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialView, trialId, undefined)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.QUESTION} />,
            title: "Milestones",
            url: "/trials/" + trialId + "/milestones"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialInstitutionView, trialId, undefined)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.COGS} />,
            title: "Institutions",
            url: "/trials/" + trialId + "/institutions"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialPersonnelRoleView, trialId, undefined)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.COGS} />,
            title: "Personnel",
            url: "/trials/" + trialId + "/personnel"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialPatientView, trialId, undefined)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.COGS} />,
            title: "Participants",
            url: "/trials/" + trialId + "/participants"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialTreatmentsView, trialId, undefined)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.COGS} />,
            title: "Treatments",
            url: "/trials/" + trialId + "/treatments"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialItemInstanceView, trialId, undefined)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.QUESTION} />,
            title: "Trial Items",
            url: "/trials/" + trialId + "/items"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialItemView, trialId, undefined)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.COGS} />,
            title: "Institution Items", // Templates",
            url: "/trials/" + trialId + "/trialitems"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialFormView, trialId, undefined)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.QUESTION} />,
            title: "Forms",
            url: "/trials/" + trialId + "/forms"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialGroupView, trialId, undefined)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.COGS} />,
            title: "Groups",
            url: "/trials/" + trialId + "/groups"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialApisView, trialId, undefined)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.COGS} />,
            title: "Api",
            url: "/trials/" + trialId + "/api"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialNoteView, trialId, undefined)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.COGS} />,
            title: "Notes",
            url: "/trials/" + trialId + "/notes"
        });
    }
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialTrackerView, trialId, undefined)) {
        menu.push({
            icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.COGS} />,
            title: "QA Tracker",
            url: "/trials/" + trialId + "/trackers"
        });
    }

    return menu;
};

export function TrialInstitutionSubMenu(trialInstitution: Dtos.TrialInstitution, permissions: Dtos.PersonnelTmdPermissions[]): INavItem[] {
    let menu: INavItem[] = [];
    const isExisting: boolean = trialInstitution && trialInstitution.id !== undefined && trialInstitution.id.toString() !== 'create';
    if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialDetailsView, trialInstitution.trialId, trialInstitution.institutionId)) {
        menu.push({
            icon: undefined,
            title: "Details",
            url: "/trials/" + trialInstitution.trialId + "/trialinstitution/" + trialInstitution.id + "/details",
        });
    }
    if (isExisting) {
        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialPatientView, trialInstitution.trialId, trialInstitution.institutionId)) {
            menu.push({
                icon: undefined, // <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.COGS} />,
                title: "Participants",
                url: "/trials/" + trialInstitution.trialId + "/trialinstitution/" + trialInstitution.id + "/participants"
            });
        }
        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialInstitutionItemInstanceView, trialInstitution.trialId, trialInstitution.institutionId)) {
            menu.push({
                icon: undefined,
                title: "Items",
                url: "/trials/" + trialInstitution.trialId + "/trialinstitution/" + trialInstitution.id + "/items",
            });
        }
        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialPersonnelRoleView, trialInstitution.trialId, trialInstitution.institutionId)) {
            menu.push({
                icon: undefined,
                title: "Personnel",
                url: "/trials/" + trialInstitution.trialId + "/trialinstitution/" + trialInstitution.id + "/personnel",
            });
        }
        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialNoteView, trialInstitution.trialId, trialInstitution.institutionId)) {
            menu.push({
                icon: undefined,
                title: "Notes",
                url: "/trials/" + trialInstitution.trialId + "/trialinstitution/" + trialInstitution.id + "/notes",
            });
        }
    }

    return menu;
};

export function InstitutionTrialSubMenu(trialInstitution: Dtos.TrialInstitution): INavItem[] {

    if (!trialInstitution) {
        return [];
    }
    return [
        {
            icon: undefined,
            title: "Details",
            url: "/institutiontrials/" + trialInstitution.id + "/details",
            // current: current == "institution"
        },
        {
            icon: undefined,
            title: "Items",
            url: "/institutiontrials/" + trialInstitution.id + "/items",
            // current: current == "items"
        },
        {
            icon: undefined,
            title: "Personnel",
            url: "/institutiontrials/" + trialInstitution.id + "/personnel",
            // current: current == "personnel"
        },
        {
            icon: undefined,
            title: "Notes",
            url: "/institutiontrials/" + trialInstitution.id + "/notes",
            // current: current == "notes"
        }
    ];
};