import * as React from 'react';
import './TrialTrackersPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import * as TrialActions from '../../../actions/trial';
import * as TrialTrackerActions from '../../../actions/trialTracker';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import * as ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { TruncatedCell } from "../../../components/layouts/index";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { TextAreaFormInput, FormFilterInput } from '../../../components/form';
import { TrialLayout } from '../../index';

interface ITrialTrackersPageParams {
    trialId: number;
}

interface ITrialTrackersPageProps {
    location: Location;
    trialId: number;
    match: match<ITrialTrackersPageParams>;
    history: History;

    // From state
    formFilter: IFormFilter;
    trial: Dtos.Trial;
    trialRoles: Dtos.RoleType[];
    trialTrackers: Dtos.TrialTracker[],
    loadingTrial: boolean;
    loadingTrialTrackers: boolean;
    loadTrialSuccess: boolean;
    loadTrialFailure: boolean;

    validationFailures: Dtos.ResponseError[];
    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    lookups: Dtos.Lookup[];
    formRoles: Dtos.RoleType[];
}

interface ITrialTrackersPageActions {
    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;
    loadTrialTrackers: TrialTrackerActions.ITrialTrackerLoadActionCreator;

    filter: TrialTrackerActions.ITrialTrackerFilterActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type TrialTrackersPageProps = ITrialTrackersPageProps & ITrialTrackersPageActions;

class TrialTrackersPage extends React.PureComponent<TrialTrackersPageProps, any> {

    constructor(props: TrialTrackersPageProps) {
        super(props);

        this.clearTrial = this.clearTrial.bind(this);
    }

    componentDidMount() {
        const {
            loadTrial,
            loadTrialTrackers,
            trialId
        } = this.props;

        loadTrial(trialId);
        loadTrialTrackers(trialId);
    }

    componentWillUnmount() {
        this.clearTrial();
    }

    clearTrial() {
        this.props.clearTrial();
    }

    showTrial(): boolean {
        const {
            loadingTrial,
            loadingTrialTrackers,
            loadTrialSuccess
        } = this.props

        if (!loadingTrial && loadTrialSuccess && !loadingTrialTrackers) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrial,
            loadingTrialTrackers
        } = this.props

        return (
            <TrialLayout
                loading={loadingTrial || loadingTrialTrackers}
                permission={Dtos.Permission.TrialTrackerView}>{this.renderContent()}</TrialLayout>
        );
    }

    renderContent() {
        const {
            trialId,
            trial,
            permissions,
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col mb-2"}>
                    <h2>Tracker</h2>
                </div>
                <div className="col-auto" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} />
                    {(AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialTrackerCreate, trialId, null) && trial && trial.id) > 0 && (
                        <Link className={"btn btn-primary"} url={"/trials/" + trial.id + '/trackers/create'}>Add Tracker</Link>
                    )}
                </div>
            </div>
            {
                !this.showTrial() ?
                    "Loading Trial Tracking" :
                    null
            }
            {
                this.showTrial() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {
        const {
            trialTrackers,
        } = this.props

        if (!trialTrackers || trialTrackers.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no trial tracking</span>
            </div>
        }

        const showPagination = trialTrackers.length > DEFAULT_PAGE_SIZE;
        
        return <div className="table-wrapper mb-3">
            <ReactTable
                data={trialTrackers}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : trialTrackers.length}
                pageSize={showPagination ? undefined : trialTrackers.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no trial trackers to display"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            const url = "/trials/" + rowInfo.original.trialId + "/trackers/" + rowInfo.original.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                defaultSorted={[
                    {
                        id: "name",
                        desc: false
                    }
                ]}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.NOTE} fixedWidth />
                    },
                    {
                        id: "trackerMilestone",
                        Header: 'Milestone',
                        accessor: 'trackerMilestone.value',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    },
                    {
                        id: "subject",
                        Header: 'Subject',
                        accessor: 'subject',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    },
                    {
                        id: "dateStarted",
                        Header: 'Started',
                        accessor: 'dateStarted',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 150,
                        Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                    },
                    {
                        id: "trackerType",
                        Header: 'Type',
                        accessor: 'trackerType.value',
                        className: "d-flex align-items-center justify-content-center"
                    },
                    {
                        id: "enteredBy",
                        Header: 'Entered By',
                        accessor: 'enteredBy',
                        className: "d-flex align-items-center justify-content-center"
                    },
                    {
                        id: "trackerStatus",
                        Header: 'Status',
                        accessor: 'trackerStatus.value',
                        className: "d-flex align-items-center justify-content-center"
                    },
                    {
                        id: "dateStopped",
                        Header: 'Completed/Due',
                        accessor: 'dateStopped',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 150,
                        Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                    },
                    {
                        id: "recentComment",
                        Header: 'Recent Comment',
                        accessor: 'recentComment.comments',
                        className: "d-flex align-items-center justify-content-center",
                        Cell: (props) => <TruncatedCell value={props.value} />
                    },
                ]}
            />
        </div>

    }


}


const trialTrackerActiveFilter = (items: Dtos.TrialTracker[], filter: IFormFilter): Dtos.TrialTracker[] => {

    if (items && filter) {
        if (!filter.showInActive) {
            items = items.filter(f => f.active && f.active);
        }
        if (filter.search.length > 0) {
            items = items.filter(f => f.subject.toLowerCase().indexOf(filter.search.toLowerCase()) > -1);
        }
        return items;
    }

    return [];
}


const mapStateToProps = (state: ITmdState, ownProps: TrialTrackersPageProps): ITrialTrackersPageProps => {

    let trial: Dtos.Trial | undefined = !(state.trials.formData instanceof Array) ? state.trials.formData : undefined;
    let trialTrackers: Dtos.TrialTracker[] | undefined = (state.trialTracker.formData instanceof Array) ? state.trialTracker.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        formFilter: state.trialTracker.formFilter,
        trial: trial!,
        trialRoles: state.trials.formRoles,
        trialTrackers: trialTrackerActiveFilter(trialTrackers!, state.trialTracker.formFilter!),
        lookups: state.trials.lookups,

        loadingTrial: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,
        loadTrialSuccess: state.trials.loadState && state.trials.loadState.status == RequestState.Success,
        loadTrialFailure: state.trials.loadState && state.trials.loadState.status == RequestState.Failure,

        loadingTrialTrackers: state.trialTracker.loadState && state.trialTracker.loadState.status == RequestState.Pending,

        validationFailures: state.trialTracker.validationFailures,
        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles,
    };
};

const mapDispatchToProps = (dispatch): ITrialTrackersPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        filter: bindActionCreators(TrialTrackerActions.Filter, dispatch),

        loadTrialTrackers: bindActionCreators(TrialTrackerActions.LoadTrialTracker, dispatch),
        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialTrackersPage);
