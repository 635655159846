import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_SITELOCATIONS_LOAD,
    ACTION_SITELOCATIONS_LOAD_BY_ID,

    ACTION_SITELOCATIONS_SAVE,

    ACTION_SITELOCATIONS_CREATE,

    ISiteLocationLoadAction,
    ISiteLocationLoadByIdAction,
    ISiteLocationLoadFailureAction,
    ISiteLocationLoadSuccessAction,

    ISiteLocationSaveAction,
    ISiteLocationSaveSuccessAction,
    ISiteLocationSaveFailureAction,

    ISiteLocationCreateAction,
    ISiteLocationCreateSuccessAction,
    ISiteLocationCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/siteLocations";
import { sitelocationsApi } from "../services/siteLocation";


export const
    getSiteLocationsEpic = (action$): Observable<ISiteLocationLoadSuccessAction | ISiteLocationLoadFailureAction> => {
        return action$
            .ofType(ACTION_SITELOCATIONS_LOAD)
            .mergeMap((action: ISiteLocationLoadAction) => {
                return sitelocationsApi
                    .getSiteLocations()
                    .map(response =>
                        LoadSuccess(response.siteLocations, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getSiteLocationByIdEpic = (action$): Observable<ISiteLocationLoadSuccessAction | ISiteLocationLoadFailureAction> => {
        return action$
            .ofType(ACTION_SITELOCATIONS_LOAD_BY_ID)
            .mergeMap((action: ISiteLocationLoadByIdAction) => {
                return sitelocationsApi
                    .getSiteLocationById(action.id)
                    .map(response =>
                        LoadSuccess(response.siteLocation, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setSiteLocationEpic = (action$): Observable<ISiteLocationSaveSuccessAction | ISiteLocationSaveFailureAction> => {
        return action$
            .ofType(ACTION_SITELOCATIONS_SAVE)
            .mergeMap((action: ISiteLocationSaveAction) => {
                return sitelocationsApi
                    .setSiteLocation(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.siteLocation, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createSiteLocationEpic = (action$): Observable<ISiteLocationCreateSuccessAction | ISiteLocationCreateFailureAction> => {
        return action$
            .ofType(ACTION_SITELOCATIONS_CREATE)
            .mergeMap((action: ISiteLocationCreateAction) => {
                return sitelocationsApi
                    .createSiteLocation()
                    .map(response =>
                        CreateSuccess(
                            response.siteLocation,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



