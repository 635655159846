import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadGender: IGenderLoadActionCreator = () => {
        return {
            type: ACTION_GENDERS_LOAD
        }
    },
    LoadGenderById: IGenderLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_GENDERS_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IGenderLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_GENDERS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IGenderLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_GENDERS_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveGender: IGenderSaveActionCreator = (formData) => {
        return {
            type: ACTION_GENDERS_SAVE,
            formData
        }
    },
    SaveSuccess: IGenderSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_GENDERS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IGenderSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_GENDERS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateGender: IGenderCreateActionCreator = () => {

        return {
            type: ACTION_GENDERS_CREATE
        }
    },
    CreateSuccess: IGenderCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_GENDERS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: IGenderCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_GENDERS_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IGenderClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_GENDERS_CLEAR,
            propertiesToClear
        }
    },
    Filter: IGenderFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_GENDERS_FILTER,
            formFilter
        }
    };



export const
    ACTION_GENDERS_LOAD = "ACTION_GENDERS_LOAD",
    ACTION_GENDERS_LOAD_BY_ID = "ACTION_GENDERS_LOAD_BY_ID",
    ACTION_GENDERS_LOAD_SUCCESS = "ACTION_GENDERS_LOAD_SUCCESS",
    ACTION_GENDERS_LOAD_FAILURE = "ACTION_GENDERS_LOAD_FAILURE",

    ACTION_GENDERS_SAVE = "ACTION_GENDERS_SAVE",
    ACTION_GENDERS_SAVE_SUCCESS = "ACTION_GENDERS_SAVE_SUCCESS",
    ACTION_GENDERS_SAVE_FAILURE = "ACTION_GENDERS_SAVE_FAILURE",

    ACTION_GENDERS_CREATE = "ACTION_GENDERS_CREATE",
    ACTION_GENDERS_CREATE_SUCCESS = "ACTION_GENDERS_CREATE_SUCCESS",
    ACTION_GENDERS_CREATE_FAILURE = "ACTION_GENDERS_CREATE_FAILURE",

    ACTION_GENDERS_CLEAR = "ACTION_GENDERS_CLEAR",

    ACTION_GENDERS_FILTER = "ACTION_GENDERS_FILTER";

export interface IGenderLoadAction {
    type: "ACTION_GENDERS_LOAD";
}

export interface IGenderLoadActionCreator {
    (): IGenderLoadAction;
}

export interface IGenderLoadSuccessAction {
    type: "ACTION_GENDERS_LOAD_SUCCESS";
    formData: Dtos.Gender | Dtos.Gender[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IGenderLoadSuccessActionCreator {
    (
        formData: Dtos.Gender | Dtos.Gender[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IGenderLoadSuccessAction;
}

export interface IGenderLoadFailureAction {
    type: "ACTION_GENDERS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IGenderLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IGenderLoadFailureAction;
}

export interface IGenderLoadByIdAction {
    type: "ACTION_GENDERS_LOAD_BY_ID";
    id: number;
}

export interface IGenderLoadByIdActionCreator {
    (id: number): IGenderLoadByIdAction;
}


export interface IGenderSaveAction {
    type: "ACTION_GENDERS_SAVE";
    formData: Dtos.Gender;

}

export interface IGenderSaveActionCreator {
    (
        formData: Dtos.Gender
    ): IGenderSaveAction;
}

export interface IGenderSaveSuccessAction {
    type: "ACTION_GENDERS_SAVE_SUCCESS";
    formData: Dtos.Gender;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IGenderSaveSuccessActionCreator {
    (
        formData: Dtos.Gender,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IGenderSaveSuccessAction;
}

export interface IGenderSaveFailureAction {
    type: "ACTION_GENDERS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IGenderSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IGenderSaveFailureAction;
}

export interface IGenderCreateAction {
    type: "ACTION_GENDERS_CREATE"
}

export interface IGenderCreateActionCreator {
    (): IGenderCreateAction
}

export interface IGenderCreateSuccessAction {
    type: "ACTION_GENDERS_CREATE_SUCCESS";
    formData: Dtos.Gender;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface IGenderCreateSuccessActionCreator {
    (
        formData: Dtos.Gender,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): IGenderCreateSuccessAction;
}

export interface IGenderCreateFailureAction {
    type: "ACTION_GENDERS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IGenderCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IGenderCreateFailureAction;
}

export interface IGenderClearAction {
    type: "ACTION_GENDERS_CLEAR";
    propertiesToClear?: string[];
}

export interface IGenderClearActionCreator {
    (propertiesToClear?: string[]): IGenderClearAction;
}

export interface IGenderFilterActionCreator {
    (formFilter: IFormFilter): IGenderFilterAction;
}

export interface IGenderFilterAction {
    type: "ACTION_GENDERS_FILTER";
    formFilter: IFormFilter;
}

export type GenderAction =
    IGenderLoadAction |
    IGenderLoadByIdAction |
    IGenderLoadFailureAction |
    IGenderLoadSuccessAction |
    IGenderSaveAction |
    IGenderSaveSuccessAction |
    IGenderSaveFailureAction |
    IGenderCreateAction |
    IGenderCreateSuccessAction |
    IGenderCreateFailureAction |
    IGenderClearAction |
    IGenderFilterAction;
