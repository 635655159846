import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialRoleState: Dtos.Role & IRegistrationFormState = {
    id: null,
    value: null,
    rank: null,
    active: null,
    description: null,
    openClinciaRoleId: null,
    openClinciaInstitutionRoleId: null,
    saeRoleId: null,
    drugManagementAccess: null,
    opmsAccess: null,
    isSystem: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false,
    modules: null,
    groupedRolePermissions: null
}

export interface IRoleReduxFormState {
    id: IFieldState<number>,
    name: IFieldState<string>,
    rank: IFieldState<number>,
    active: IFieldState<boolean>,
    description: IFieldState<string>,
    openClinciaRoleId: IFieldState<number>,
    openClinciaInstitutionRoleId: IFieldState<number>,
    saeRoleId: IFieldState<number>,
    drugManagementAccess: IFieldState<boolean>,
    opmsAccess: IFieldState<boolean>,
    isSystem: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>
}
