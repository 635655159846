import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const departmentsApi = {
    get: (): Observable<Dtos.GetDepartmentsResponse> => {
        //Create the request.
        let request: Dtos.GetDepartments = new Dtos.GetDepartments();

        //Send request.
        const response: Promise<Dtos.GetDepartmentsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getById: (id: number): Observable<Dtos.GetDepartmentByIdResponse> => {
        //Create the request.
        let request: Dtos.GetDepartmentById = new Dtos.GetDepartmentById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetDepartmentByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    set: (department: Dtos.Department): Observable<Dtos.SetDepartmentResponse> => {

        let request: Dtos.SetDepartment = new Dtos.SetDepartment();

        request.department = department;

        // send request
        const response: Promise<Dtos.SetDepartmentResponse> = client.post(request);

        return Observable.from(response);

    },
    create: (institutionId: number): Observable<Dtos.CreateDepartmentResponse> => {
        //Create the request.
        let request: Dtos.CreateDepartment = new Dtos.CreateDepartment();

        request.institutionId = institutionId;

        //Send request.
        const response: Promise<Dtos.CreateDepartmentResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}