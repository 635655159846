import * as React from 'react';
import './TrialPersonnelPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import * as ReactTooltip from 'react-tooltip';
import * as TrialActions from '../../../actions/trial';
import * as ToggleActions from '../../../actions/pages/togglePage';
import * as ToggleHelper from "../../../helpers/toggleHelper";
import { TogglePageItem } from '../../../reducers/pages/togglePage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { ModalSize } from '../../../enumerations/ModalSize';
import { TextAreaFormInput, FormFilterInput } from '../../../components/form';
import { TrialLayout } from '../../index';

interface ITrialPersonnelPageParams {
    trialId: number;
}

interface ITrialPersonnelPageProps {

    location: Location;
    trialId: number;
    match: match<ITrialPersonnelPageParams>;
    history: History;

    // From state
    trial: Dtos.Trial;
    trialRoles: Dtos.RoleType[];
    formFilter: IFormFilter;
    personnelRoleSummaries: Dtos.PersonnelRoleSummary[];
    globalRoleSummaries: Dtos.PersonnelRoleSummary[];

    loadingTrial: boolean;
    loadTrialSuccess: boolean;
    loadTrialFailure: boolean;

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];
    toggleItems: TogglePageItem[]
}

interface ITrialPersonnelPageActions {
    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    toggleExpand: ToggleActions.IToggleExpandedStateActionCreator;

    filter: TrialActions.ITrialPersonnelRolesFilterActionCreator;
}

type TrialPersonnelPageProps = ITrialPersonnelPageProps & ITrialPersonnelPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex";
const tableGeneralHeaderClass = "btn btn-secondary btn-block rounded-0 p-3 d-flex";
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class TrialPersonnelPage extends React.PureComponent<TrialPersonnelPageProps, any> {

    constructor(props: TrialPersonnelPageProps) {
        super(props);

        this.clearTrial = this.clearTrial.bind(this);
        this.toggleExportMenu = this.toggleExportMenu.bind(this);

        this.state = {
            exportToggle: false
        };
    }

    componentDidMount() {
        const {
            loadTrial,
            navigate,
            navigateReplace,
            trialId
        } = this.props;

        loadTrial(trialId);
    }

    componentWillReceiveProps(nextProps: TrialPersonnelPageProps) {
    }

    componentDidUpdate(prevProps: TrialPersonnelPageProps, prevState) {
        const {
            navigateReplace,
            loadTrial
        } = this.props
    }

    componentWillUpdate(nextProps: TrialPersonnelPageProps) {
    }

    componentWillUnmount() {
        this.clearTrial();
    }

    toggleExportMenu() {
        this.setState({
            exportToggle: !this.state.exportToggle
        });
    }

    clearTrial() {
        this.props.clearTrial();
    }

    showTrial(): boolean {
        const {
            loadingTrial,
            loadTrialSuccess
        } = this.props

        if (!loadingTrial && loadTrialSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrial
        } = this.props

        return (
            <TrialLayout loading={loadingTrial} permission={Dtos.Permission.TrialPersonnelRoleView}>{this.renderContent()}</TrialLayout>
        );
    }

    renderContent() {
        const {
            trial,
            loadingTrial,
            loadTrialSuccess,
            loadTrialFailure,
            clearTrial,
            navigate,
            user,
            trialId,
            permissions
        } = this.props;


        return <div>
            <div className="row">
                <div className={"col-auto mb-2"}>
                    <h2>Trial Roles</h2>
                </div>
                <div className="col" style={{ textAlign: 'right' }}>
                    {trial && trial.id > 0 && (
                        <>
                            <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} />
                            <div className="btn-group mr-2">
                                <button type="button" className="btn btn-info" onClick={() => this.toggleExportMenu()}>
                                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.CLOUD_DOWNLOAD} fixedWidth />
                                </button>
                                <Modal open={this.state.exportToggle} disableCloseOnOverlayClick={true} onClose={() => { this.toggleExportMenu() }} size={ModalSize.Md}>
                                    <div className="modal-header">
                                        <h5 className="modal-title">Download</h5>
                                        <button type="button" className="close" aria-label="Close" onClick={() => { this.toggleExportMenu() }}>
                                            <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                                        </button>
                                    </div>
                                    <div className="modal-body p-3">
                                        <a className="btn btn-outline-primary btn-block"
                                            onClick={() => this.toggleExportMenu()}
                                            href={encodeURI("/api/personnelrole/export/" + trial.id.toString() + "/0")}
                                        ><b>Trial Personnel Role List</b><br /><small>The trial personnel with institution, role and active dates as a CSV file</small></a>
                                    </div>
                                </Modal>
                            </div>
                            {(AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialPersonnelRoleCreate, trialId, null) && trial && trial.id > 0) && (
                                <Link className={"btn btn-primary"} url={"/trials/" + trial.id + '/personnel/create'}>Create</Link>
                            )}
                        </>
                    )}
                </div>
            </div>
            {
                loadingTrial ?
                    "Loading Personnel" :
                    null
            }
            {
                !loadingTrial ?
                    <div className="mt-2">
                        {trial && trial.isLegacyUserDatabase && (
                            <div className="alert alert-warning" role="alert">
                                <strong>Warning!</strong>&nbsp;&nbsp; The registration website for this trial is not connected to TMD, please contact <a href="mailto:support@newtongreen.com">support@newtongreen.com</a> if any access needs to be changed.
                            </div>
                        )}
                        {this.renderTable()}
                    </div> :
                    null
            }
        </div>
    }


    toggle(personnelRoleSummary: Dtos.PersonnelRoleSummary) {

    }

    renderTable() {
        const {
            trial,
            personnelRoleSummaries,
            globalRoleSummaries,
            toggleItems,
            toggleExpand
        } = this.props;

        if ((!personnelRoleSummaries || personnelRoleSummaries.length == 0) && (!globalRoleSummaries || globalRoleSummaries.length == 0)) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no roles to view.</span>
            </div>
        }

        return <div>
            {this.renderGeneralRoles()}
            {personnelRoleSummaries && personnelRoleSummaries.map((item, index) => {
                const key = "trial-roles-page-" + item.personnelRoleSummaryType + "-" + item.display;
                let expanded = ToggleHelper.isToggleExpanded(toggleItems, key);
                if (item.personnelRoleSummaryType == Dtos.PersonnelRoleSummaryType.Trial) {
                    expanded = !expanded;
                }
                return (
                    <div key={"parent-category" + index}>
                        <div className={tableHeaderClass} style={tableStyleClass} onClick={() => toggleExpand(key)}>
                            <div>
                                <FontAwesomeIcon icon={!expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" />
                            </div>
                            <span>{item.display}</span>
                        </div>
                        {expanded && item.personnelRoleSummaryType == Dtos.PersonnelRoleSummaryType.Trial && (
                            this.renderTrialRoles(item.personnelRoleSummaries)
                        )}

                        {expanded && item.personnelRoleSummaryType == Dtos.PersonnelRoleSummaryType.TrialInstitution && (
                            this.renderTrialInstitutionRoles(item.personnelRoleSummaries)
                        )}
                    </div>
                )
            }
            )}
        </div>
    }

    renderGeneralRoles() {
        const showPagination = false; const {
            globalRoleSummaries,
            trialId,
            toggleItems,
            toggleExpand
        } = this.props;

        if (globalRoleSummaries == undefined || globalRoleSummaries.length == 0) return <></>;

        const personnelRoleSummaries = globalRoleSummaries[0].personnelRoleSummaries;
        const key = "trial-roles-page-general-" + trialId;
        const expanded = !ToggleHelper.isToggleExpanded(toggleItems, key);
        return (
            <div key={"parent-category-general"}>
                <div className={tableGeneralHeaderClass} style={tableStyleClass} onClick={() => toggleExpand(key)}>
                    <div>
                        <FontAwesomeIcon icon={!expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" />
                    </div>
                    <span>(Global Roles: {personnelRoleSummaries.length})</span>
                </div>
                {expanded && (
                    <ReactTable
                        data={personnelRoleSummaries}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        defaultPageSize={personnelRoleSummaries.length}
                        pageSize={personnelRoleSummaries.length}
                        showPagination={showPagination}
                        className="-striped -highlight -clickable mb-3"
                        noDataText="There are currently no roles for this type"
                        resizable={false}
                        defaultSorted={[
                            {
                                id: "person",
                                desc: false
                            }
                        ]}
                        getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                            return {
                                onClick: (e, handleOriginal) => {
                                    console.log(rowInfo);
                                    const url: string = "/personnel/" + rowInfo.original.personnelRole.personnelId + "/roles";
                                    this.props.navigate(url);

                                    if (handleOriginal) {
                                        handleOriginal()
                                    }

                                },
                            }
                        }}
                        columns={
                            [
                                {
                                    id: "id",
                                    Header: ' ',
                                    accessor: "id",
                                    className: "d-flex align-items-center justify-content-center",
                                    maxWidth: 50,
                                    Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.ROLE} fixedWidth />
                                },
                                {
                                    id: "role",
                                    Header: "Role",
                                    accessor: "personnelRole.roleDisplay",
                                    className: "d-flex align-items-left justify-content-left",
                                    headerClassName: "text-left"
                                },
                                {
                                    id: "person",
                                    Header: "Person",
                                    accessor: "personnelRole.personnelDisplay",
                                    className: "d-flex align-items-left justify-content-left",
                                    headerClassName: "text-left"
                                },
                                {
                                    id: "department",
                                    Header: "Department",
                                    accessor: "personnelRole.department.name",
                                    className: "d-flex align-items-left justify-content-left",
                                    headerClassName: "text-left"
                                },
                                {
                                    id: "dateFrom",
                                    Header: "Date Started",
                                    accessor: "personnelRole.dateFrom",
                                    className: "d-flex align-items-center justify-content-center",
                                    maxWidth: 180,
                                    Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                                },
                                {
                                    id: "dateTo",
                                    Header: "Date Ceased",
                                    accessor: "personnelRole.dateTo",
                                    className: "d-flex align-items-center justify-content-center",
                                    maxWidth: 180,
                                    Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                                }
                            ]
                        }
                    />
                )}
            </div>
        );
    }

    renderTrialRoles(personnelRoleSummaries: Dtos.PersonnelRoleSummary[]) {
        const showPagination = false; const {
            trial
        } = this.props;

        return (
            <ReactTable
                data={personnelRoleSummaries}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : personnelRoleSummaries.length}
                pageSize={showPagination ? undefined : personnelRoleSummaries.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no roles for this type"
                resizable={false}
                defaultSorted={[
                    {
                        id: "person",
                        desc: false
                    }
                ]}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log(rowInfo);
                            const url: string = "/trials/" + trial.id + '/personnel/' + rowInfo.original.personnelRole.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.personnelRole.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={
                    [
                        {
                            id: "id",
                            Header: ' ',
                            accessor: "id",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 50,
                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.ROLE} fixedWidth />
                        },
                        {
                            id: "role",
                            Header: "Role",
                            accessor: "personnelRole.roleDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "person",
                            Header: "Person",
                            accessor: "personnelRole.personnelDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "department",
                            Header: "Department",
                            accessor: "personnelRole.department.name",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "dateFrom",
                            Header: "Date Started",
                            accessor: "personnelRole.dateFrom",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        },
                        {
                            id: "dateTo",
                            Header: "Date Ceased",
                            accessor: "personnelRole.dateTo",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        }
                    ]
                }
            />
        );
    }

    renderTrialInstitutionRoles(personnelRoleSummaries: Dtos.PersonnelRoleSummary[]) {
        const showPagination = false; const {
            trial
        } = this.props;

        return (
            <ReactTable
                data={personnelRoleSummaries}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : personnelRoleSummaries.length}
                pageSize={showPagination ? undefined : personnelRoleSummaries.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no roles for this type"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log(rowInfo);
                            const url: string = "/trials/" + trial.id + '/personnel/' + rowInfo.original.personnelRole.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.personnelRole.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={
                    [
                        {
                            id: "id",
                            Header: ' ',
                            accessor: "id",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 50,
                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.ROLE} fixedWidth />
                        },
                        {
                            id: "role",
                            Header: "Role",
                            accessor: "personnelRole.roleDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "person",
                            Header: "Person",
                            accessor: "personnelRole.personnelDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "department",
                            Header: "Department",
                            accessor: "personnelRole.department.name",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "dateFrom",
                            Header: "Date Started",
                            accessor: "personnelRole.dateFrom",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        },
                        {
                            id: "dateTo",
                            Header: "Date Ceased",
                            accessor: "personnelRole.dateTo",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        }
                    ]
                }
            />
        );
    }

}


const mapStateToProps = (state: ITmdState, ownProps: TrialPersonnelPageProps): ITrialPersonnelPageProps => {

    let trial: Dtos.Trial | undefined = !(state.trials.formData instanceof Array) ? state.trials.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trial: trial!,
        trialRoles: state.trials.formRoles,
        formFilter: state.trials.formFilterPersonnelRoles,
        personnelRoleSummaries: state.trials.formFilteredPersonnelRoles,
        globalRoleSummaries: trial ? trial.globalRoleSummaries : undefined,

        loadingTrial: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,
        loadTrialSuccess: state.trials.loadState && state.trials.loadState.status == RequestState.Success,
        loadTrialFailure: state.trials.loadState && state.trials.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        toggleItems: state.togglePage.items
    };
};

const mapDispatchToProps = (dispatch): ITrialPersonnelPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch),

        toggleExpand: bindActionCreators(ToggleActions.ToggleExpanded, dispatch),

        filter: bindActionCreators(TrialActions.PersonnelRolesFilter, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialPersonnelPage);
