import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadTrialTreatment: ITrialTreatmentLoadActionCreator = (trialId) => {
        return {
            type: ACTION_TRIALTREATMENT_LOAD,
            trialId
        }
    },
    LoadTrialTreatmentById: ITrialTreatmentLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TRIALTREATMENT_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ITrialTreatmentLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALTREATMENT_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrialTreatmentLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALTREATMENT_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTrialTreatment: ITrialTreatmentSaveActionCreator = (formData) => {
        return {
            type: ACTION_TRIALTREATMENT_SAVE,
            formData
        }
    },
    SaveSuccess: ITrialTreatmentSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRIALTREATMENT_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrialTreatmentSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALTREATMENT_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTrialTreatment: ITrialTreatmentCreateActionCreator = (trialId) => {

        return {
            type: ACTION_TRIALTREATMENT_CREATE,
            trialId
        }
    },
    CreateSuccess: ITrialTreatmentCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_TRIALTREATMENT_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: ITrialTreatmentCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALTREATMENT_CREATE_FAILURE,
            responseStatus
        }
    },

    SyncTrialTreatment: ITrialTreatmentSyncActionCreator = (trialId) => {

        return {
            type: ACTION_TRIALTREATMENT_SYNC,
            trialId
        }
    },
    SyncSuccess: ITrialTreatmentSyncSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALTREATMENT_SYNC_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    SyncFailure: ITrialTreatmentSyncFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALTREATMENT_SYNC_FAILURE,
            responseStatus
        }
    },

    Clear: ITrialTreatmentClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRIALTREATMENT_CLEAR,
            propertiesToClear
        }
    },

    Filter: ITrialTreatmentFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRIALTREATMENT_FILTER,
            formFilter
        }
    };



export const
    ACTION_TRIALTREATMENT_LOAD = "ACTION_TRIALTREATMENT_LOAD",
    ACTION_TRIALTREATMENT_LOAD_BY_ID = "ACTION_TRIALTREATMENT_LOAD_BY_ID",
    ACTION_TRIALTREATMENT_LOAD_SUCCESS = "ACTION_TRIALTREATMENT_LOAD_SUCCESS",
    ACTION_TRIALTREATMENT_LOAD_FAILURE = "ACTION_TRIALTREATMENT_LOAD_FAILURE",

    ACTION_TRIALTREATMENT_SAVE = "ACTION_TRIALTREATMENT_SAVE",
    ACTION_TRIALTREATMENT_SAVE_SUCCESS = "ACTION_TRIALTREATMENT_SAVE_SUCCESS",
    ACTION_TRIALTREATMENT_SAVE_FAILURE = "ACTION_TRIALTREATMENT_SAVE_FAILURE",

    ACTION_TRIALTREATMENT_CREATE = "ACTION_TRIALTREATMENT_CREATE",
    ACTION_TRIALTREATMENT_CREATE_SUCCESS = "ACTION_TRIALTREATMENT_CREATE_SUCCESS",
    ACTION_TRIALTREATMENT_CREATE_FAILURE = "ACTION_TRIALTREATMENT_CREATE_FAILURE",

    ACTION_TRIALTREATMENT_SYNC = "ACTION_TRIALTREATMENT_SYNC",
    ACTION_TRIALTREATMENT_SYNC_SUCCESS = "ACTION_TRIALTREATMENT_SYNC_SUCCESS",
    ACTION_TRIALTREATMENT_SYNC_FAILURE = "ACTION_TRIALTREATMENT_SYNC_FAILURE",

    ACTION_TRIALTREATMENT_CLEAR = "ACTION_TRIALTREATMENT_CLEAR",

    ACTION_TRIALTREATMENT_FILTER = "ACTION_TRIALTREATMENT_FILTER";

export interface ITrialTreatmentLoadAction {
    type: "ACTION_TRIALTREATMENT_LOAD";
    trialId: number;
}

export interface ITrialTreatmentLoadActionCreator {
    (trialId: number): ITrialTreatmentLoadAction;
}

export interface ITrialTreatmentLoadSuccessAction {
    type: "ACTION_TRIALTREATMENT_LOAD_SUCCESS";
    formData: Dtos.TrialTreatment | Dtos.TrialTreatment[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialTreatmentLoadSuccessActionCreator {
    (
        formData: Dtos.TrialTreatment | Dtos.TrialTreatment[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialTreatmentLoadSuccessAction;
}

export interface ITrialTreatmentLoadFailureAction {
    type: "ACTION_TRIALTREATMENT_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialTreatmentLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialTreatmentLoadFailureAction;
}

export interface ITrialTreatmentLoadByIdAction {
    type: "ACTION_TRIALTREATMENT_LOAD_BY_ID";
    id: number;
}

export interface ITrialTreatmentLoadByIdActionCreator {
    (id: number): ITrialTreatmentLoadByIdAction;
}

export interface ITrialTreatmentSaveAction {
    type: "ACTION_TRIALTREATMENT_SAVE";
    formData: Dtos.TrialTreatment;

}

export interface ITrialTreatmentSaveActionCreator {
    (
        formData: Dtos.TrialTreatment
    ): ITrialTreatmentSaveAction;
}

export interface ITrialTreatmentSaveSuccessAction {
    type: "ACTION_TRIALTREATMENT_SAVE_SUCCESS";
    formData: Dtos.TrialTreatment;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialTreatmentSaveSuccessActionCreator {
    (
        formData: Dtos.TrialTreatment,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrialTreatmentSaveSuccessAction;
}

export interface ITrialTreatmentSaveFailureAction {
    type: "ACTION_TRIALTREATMENT_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialTreatmentSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialTreatmentSaveFailureAction;
}

export interface ITrialTreatmentCreateAction {
    type: "ACTION_TRIALTREATMENT_CREATE",
    trialId: number
}

export interface ITrialTreatmentCreateActionCreator {
    (trialId: number): ITrialTreatmentCreateAction
}

export interface ITrialTreatmentCreateSuccessAction {
    type: "ACTION_TRIALTREATMENT_CREATE_SUCCESS";
    formData: Dtos.TrialTreatment;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface ITrialTreatmentCreateSuccessActionCreator {
    (
        formData: Dtos.TrialTreatment,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): ITrialTreatmentCreateSuccessAction;
}

export interface ITrialTreatmentCreateFailureAction {
    type: "ACTION_TRIALTREATMENT_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialTreatmentCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialTreatmentCreateFailureAction;
}

export interface ITrialTreatmentClearAction {
    type: "ACTION_TRIALTREATMENT_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrialTreatmentClearActionCreator {
    (propertiesToClear?: string[]): ITrialTreatmentClearAction;
}

export interface ITrialTreatmentFilterActionCreator {
    (formFilter: IFormFilter): ITrialTreatmentFilterAction;
}

export interface ITrialTreatmentFilterAction {
    type: "ACTION_TRIALTREATMENT_FILTER";
    formFilter: IFormFilter;
}

export interface ITrialTreatmentSyncAction {
    type: "ACTION_TRIALTREATMENT_SYNC",
    trialId: number
}

export interface ITrialTreatmentSyncActionCreator {
    (trialId: number): ITrialTreatmentSyncAction
}

export interface ITrialTreatmentSyncSuccessAction {
    type: "ACTION_TRIALTREATMENT_SYNC_SUCCESS";
    formData: Dtos.TrialTreatment[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialTreatmentSyncSuccessActionCreator {
    (
        formData: Dtos.TrialTreatment[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialTreatmentSyncSuccessAction;
}

export interface ITrialTreatmentSyncFailureAction {
    type: "ACTION_TRIALTREATMENT_SYNC_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialTreatmentSyncFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialTreatmentSyncFailureAction;
}

export type TrialTreatmentAction =
    ITrialTreatmentLoadAction |
    ITrialTreatmentLoadByIdAction |
    ITrialTreatmentLoadFailureAction |
    ITrialTreatmentLoadSuccessAction |
    ITrialTreatmentSaveAction |
    ITrialTreatmentSaveSuccessAction |
    ITrialTreatmentSaveFailureAction |
    ITrialTreatmentCreateAction |
    ITrialTreatmentCreateSuccessAction |
    ITrialTreatmentCreateFailureAction |
    ITrialTreatmentSyncAction |
    ITrialTreatmentSyncSuccessAction |
    ITrialTreatmentSyncFailureAction |
    ITrialTreatmentClearAction | 
    ITrialTreatmentFilterAction;
