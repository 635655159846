import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadProfessionalDiscipline: IProfessionalDisciplineLoadActionCreator = () => {
        return {
            type: ACTION_PROFESSIONALDISCIPLINES_LOAD
        }
    },
    LoadProfessionalDisciplineById: IProfessionalDisciplineLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_PROFESSIONALDISCIPLINES_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IProfessionalDisciplineLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_PROFESSIONALDISCIPLINES_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IProfessionalDisciplineLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PROFESSIONALDISCIPLINES_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveProfessionalDiscipline: IProfessionalDisciplineSaveActionCreator = (formData) => {
        return {
            type: ACTION_PROFESSIONALDISCIPLINES_SAVE,
            formData
        }
    },
    SaveSuccess: IProfessionalDisciplineSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_PROFESSIONALDISCIPLINES_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IProfessionalDisciplineSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PROFESSIONALDISCIPLINES_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateProfessionalDiscipline: IProfessionalDisciplineCreateActionCreator = () => {

        return {
            type: ACTION_PROFESSIONALDISCIPLINES_CREATE
        }
    },
    CreateSuccess: IProfessionalDisciplineCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_PROFESSIONALDISCIPLINES_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: IProfessionalDisciplineCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PROFESSIONALDISCIPLINES_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IProfessionalDisciplineClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_PROFESSIONALDISCIPLINES_CLEAR,
            propertiesToClear
        }
    },
    Filter: IProfessionalDisciplineFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_PROFESSIONALDISCIPLINES_FILTER,
            formFilter
        }
    };



export const
    ACTION_PROFESSIONALDISCIPLINES_LOAD = "ACTION_PROFESSIONALDISCIPLINES_LOAD",
    ACTION_PROFESSIONALDISCIPLINES_LOAD_BY_ID = "ACTION_PROFESSIONALDISCIPLINES_LOAD_BY_ID",
    ACTION_PROFESSIONALDISCIPLINES_LOAD_SUCCESS = "ACTION_PROFESSIONALDISCIPLINES_LOAD_SUCCESS",
    ACTION_PROFESSIONALDISCIPLINES_LOAD_FAILURE = "ACTION_PROFESSIONALDISCIPLINES_LOAD_FAILURE",

    ACTION_PROFESSIONALDISCIPLINES_SAVE = "ACTION_PROFESSIONALDISCIPLINES_SAVE",
    ACTION_PROFESSIONALDISCIPLINES_SAVE_SUCCESS = "ACTION_PROFESSIONALDISCIPLINES_SAVE_SUCCESS",
    ACTION_PROFESSIONALDISCIPLINES_SAVE_FAILURE = "ACTION_PROFESSIONALDISCIPLINES_SAVE_FAILURE",

    ACTION_PROFESSIONALDISCIPLINES_CREATE = "ACTION_PROFESSIONALDISCIPLINES_CREATE",
    ACTION_PROFESSIONALDISCIPLINES_CREATE_SUCCESS = "ACTION_PROFESSIONALDISCIPLINES_CREATE_SUCCESS",
    ACTION_PROFESSIONALDISCIPLINES_CREATE_FAILURE = "ACTION_PROFESSIONALDISCIPLINES_CREATE_FAILURE",

    ACTION_PROFESSIONALDISCIPLINES_CLEAR = "ACTION_PROFESSIONALDISCIPLINES_CLEAR",

    ACTION_PROFESSIONALDISCIPLINES_FILTER = "ACTION_PROFESSIONALDISCIPLINES_FILTER";

export interface IProfessionalDisciplineLoadAction {
    type: "ACTION_PROFESSIONALDISCIPLINES_LOAD";
}

export interface IProfessionalDisciplineLoadActionCreator {
    (): IProfessionalDisciplineLoadAction;
}

export interface IProfessionalDisciplineLoadSuccessAction {
    type: "ACTION_PROFESSIONALDISCIPLINES_LOAD_SUCCESS";
    formData: Dtos.ProfessionalDiscipline | Dtos.ProfessionalDiscipline[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IProfessionalDisciplineLoadSuccessActionCreator {
    (
        formData: Dtos.ProfessionalDiscipline | Dtos.ProfessionalDiscipline[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IProfessionalDisciplineLoadSuccessAction;
}

export interface IProfessionalDisciplineLoadFailureAction {
    type: "ACTION_PROFESSIONALDISCIPLINES_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IProfessionalDisciplineLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IProfessionalDisciplineLoadFailureAction;
}

export interface IProfessionalDisciplineLoadByIdAction {
    type: "ACTION_PROFESSIONALDISCIPLINES_LOAD_BY_ID";
    id: number;
}

export interface IProfessionalDisciplineLoadByIdActionCreator {
    (id: number): IProfessionalDisciplineLoadByIdAction;
}


export interface IProfessionalDisciplineSaveAction {
    type: "ACTION_PROFESSIONALDISCIPLINES_SAVE";
    formData: Dtos.ProfessionalDiscipline;

}

export interface IProfessionalDisciplineSaveActionCreator {
    (
        formData: Dtos.ProfessionalDiscipline
    ): IProfessionalDisciplineSaveAction;
}

export interface IProfessionalDisciplineSaveSuccessAction {
    type: "ACTION_PROFESSIONALDISCIPLINES_SAVE_SUCCESS";
    formData: Dtos.ProfessionalDiscipline;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IProfessionalDisciplineSaveSuccessActionCreator {
    (
        formData: Dtos.ProfessionalDiscipline,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IProfessionalDisciplineSaveSuccessAction;
}

export interface IProfessionalDisciplineSaveFailureAction {
    type: "ACTION_PROFESSIONALDISCIPLINES_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IProfessionalDisciplineSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IProfessionalDisciplineSaveFailureAction;
}

export interface IProfessionalDisciplineCreateAction {
    type: "ACTION_PROFESSIONALDISCIPLINES_CREATE"
}

export interface IProfessionalDisciplineCreateActionCreator {
    (): IProfessionalDisciplineCreateAction
}

export interface IProfessionalDisciplineCreateSuccessAction {
    type: "ACTION_PROFESSIONALDISCIPLINES_CREATE_SUCCESS";
    formData: Dtos.ProfessionalDiscipline;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface IProfessionalDisciplineCreateSuccessActionCreator {
    (
        formData: Dtos.ProfessionalDiscipline,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): IProfessionalDisciplineCreateSuccessAction;
}

export interface IProfessionalDisciplineCreateFailureAction {
    type: "ACTION_PROFESSIONALDISCIPLINES_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IProfessionalDisciplineCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IProfessionalDisciplineCreateFailureAction;
}

export interface IProfessionalDisciplineClearAction {
    type: "ACTION_PROFESSIONALDISCIPLINES_CLEAR";
    propertiesToClear?: string[];
}

export interface IProfessionalDisciplineClearActionCreator {
    (propertiesToClear?: string[]): IProfessionalDisciplineClearAction;
}

export interface IProfessionalDisciplineFilterActionCreator {
    (formFilter: IFormFilter): IProfessionalDisciplineFilterAction;
}

export interface IProfessionalDisciplineFilterAction {
    type: "ACTION_PROFESSIONALDISCIPLINES_FILTER";
    formFilter: IFormFilter;
}

export type ProfessionalDisciplineAction =
    IProfessionalDisciplineLoadAction |
    IProfessionalDisciplineLoadByIdAction |
    IProfessionalDisciplineLoadFailureAction |
    IProfessionalDisciplineLoadSuccessAction |
    IProfessionalDisciplineSaveAction |
    IProfessionalDisciplineSaveSuccessAction |
    IProfessionalDisciplineSaveFailureAction |
    IProfessionalDisciplineCreateAction |
    IProfessionalDisciplineCreateSuccessAction |
    IProfessionalDisciplineCreateFailureAction |
    IProfessionalDisciplineClearAction |
    IProfessionalDisciplineFilterAction;
