import * as React from 'react';
import './TrialPatientPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { IPatientReduxFormState, initialPatientState } from "../../../reducers/reactReduxForms/patient";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInputFabric, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as PatientActions from '../../../actions/patient';
import * as TrialActions from '../../../actions/trial';
import * as TrialInstitutionActions from '../../../actions/trialInstitution';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { Form, actions, Control } from 'react-redux-form';
import { TrialLayout } from '../../index';
import { TrialInstitutionLayout } from '../../index';
import * as FormHelper from '../../../helpers/formHelper';

interface IPatientPageParams {
    trialId: number;
    trialInstitutionId: number;
    patientId: number;
}

interface IPatientPageProps {

    form: Dtos.Patient & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IPatientReduxFormState;

    location: Location;
    trialId: number;
    trialInstitutionId: number;
    trialInstitution: Dtos.TrialInstitution;
    patientId: number;
    match: match<IPatientPageParams>;
    history: History;

    // From state
    patient: Dtos.Patient;
    trial: Dtos.Trial;
    lookups: Dtos.Lookup[];

    loadingTrial: boolean;

    loadingTrialInstitution: boolean;
    loadTrialInstitutionSuccess: boolean;
    loadTrialInstitutionFailure: boolean;

    loadingPatient: boolean;
    loadPatientSuccess: boolean;
    loadPatientFailure: boolean;

    savingPatient: boolean;
    savePatientSuccess: boolean;
    savePatientFailure: boolean;

    creatingPatient: boolean;
    createPatientSuccess: boolean;
    createPatientFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface IPatientPageActions {
    clearPatient: PatientActions.IPatientClearActionCreator;
    loadPatient: PatientActions.IPatientLoadByIdActionCreator;
    savePatient: PatientActions.IPatientSaveActionCreator;
    createPatient: PatientActions.IPatientCreateActionCreator;

    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    clearTrialInstitution: TrialInstitutionActions.ITrialInstitutionClearActionCreator;
    loadTrialInstitution: TrialInstitutionActions.ITrialInstitutionLoadByIdActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type PatientPageProps = IPatientPageProps & IPatientPageActions;

const formName: string = "patient";
const reduxFormName: string = "reduxForms.patient";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}

class PatientPage extends React.PureComponent<PatientPageProps, any> {

    constructor(props: PatientPageProps) {
        super(props);

        this.clearPatient = this.clearPatient.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadPatient,
            navigate,
            navigateReplace,
            patientId,
            trialId,
            trialInstitutionId,
            loadTrialInstitution,
            loadTrial,
            createPatient
        } = this.props;

        if (patientId) {
            loadPatient(patientId);
        } else {
            // change to create
            createPatient(trialId, trialInstitutionId);
        }
        loadTrial(trialId);
        if (trialInstitutionId !== undefined) {
            loadTrialInstitution(trialInstitutionId);
        }
    }

    componentWillReceiveProps(nextProps: PatientPageProps) {
    }

    componentDidUpdate(prevProps: PatientPageProps, prevState) {
        const {
            form,
            trialId,
            trialInstitutionId,
            patientId,
            patient,
            navigateReplace,
            loadPatient,
            loadTrial,
            loadingPatient,
            loadPatientSuccess,
            savingPatient,
            savePatientSuccess,
            savePatientFailure,
            validationFailures,
            navigate,
            createPatient,
            creatingPatient,
            createPatientSuccess,
            loadTrialInstitution
        } = this.props;

        if (trialId && prevProps.trialId != trialId) {
            loadTrial(trialId);
        }

        if (trialInstitutionId && prevProps.trialInstitutionId != trialInstitutionId) {
            loadTrialInstitution(trialInstitutionId);
        }

        if (patientId && prevProps.patientId != patientId) {
            loadPatient(patientId);
        }
        else if (!patientId && prevProps.patientId) {
            createPatient(trialId, trialInstitutionId);
        }

        if (!loadingPatient && prevProps.loadingPatient) {
            if (loadPatientSuccess) {
                this.loadForm();
            }
            else {

            }
        }

        if (!savingPatient && prevProps.savingPatient) {
            if (savePatientSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (trialInstitutionId !== undefined) {
                    if (form.saveAndReturn) {
                        navigate("/trials/" + trialId + "/trialinstitution/" + trialInstitutionId + "/participants")
                    } else if (!form.saveAndReturn && patientId && patientId > 0) {
                        this.loadForm();
                    } else if (!form.saveAndReturn && !patientId && patient && patient.id > 0) {
                        navigate("/trials/" + trialId + "/trialinstitution/" + trialInstitutionId + "/participants/" + patient.id)
                    }
                } else {
                    if (form.saveAndReturn) {
                        navigate("/trials/" + trialId + "/participants")
                    } else if (!form.saveAndReturn && patientId && patientId > 0) {
                        this.loadForm();
                    } else if (!form.saveAndReturn && !patientId && patient && patient.id > 0) {
                        navigate("/trials/" + trialId + "/participants/" + patient.id)
                    }
                }

            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingPatient && prevProps.creatingPatient) {
            if (createPatientSuccess) {
                this.loadForm();
            }
            else {

            }
        }
    }

    componentWillUpdate(nextProps: PatientPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearPatient();
        loadForm(reduxFormName, initialPatientState);
        resetForm(reduxFormName);
    }

    clearPatient() {
        this.props.clearPatient();
        this.props.clearTrial();
    }

    showPatient(): boolean {
        const {
            loadingTrial,
            loadingPatient,
            loadPatientSuccess,
            loadingTrialInstitution
        } = this.props

        if (!loadingTrial && !loadingTrialInstitution && !loadingPatient && loadPatientSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrial,
            loadingPatient,
            loadingTrialInstitution
        } = this.props

        if (this.props.trialInstitutionId !== undefined) {
            return <TrialInstitutionLayout loading={loadingTrial || loadingPatient || loadingTrialInstitution} permission={Dtos.Permission.TrialPatientView}>{this.renderContent()}</TrialInstitutionLayout>;
        }
        return <TrialLayout loading={loadingTrial || loadingTrialInstitution} permission={Dtos.Permission.TrialPatientView}>{this.renderContent()}</TrialLayout>;
    }

    renderContent() {
        const {
            form,
            trialInstitutionId,
            permissions,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingPatient
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.TrialPatientUpdate, Dtos.Permission.TrialPatientCreate);
        const formEdit: boolean = FormHelper.isEdit(formState, form);

        const canEdit: boolean = hasPermission && formEdit;
        const formDisabled: boolean = !hasPermission || savingPatient || (formState && formState.formStatus == Dtos.FormStatus.Disabled);
        const formOpmsDisabled: boolean = !hasPermission || savingPatient || (formState && formState.formStatus == Dtos.FormStatus.Disabled) || (form.opmsId != undefined && form.opmsId != null && form.opmsId > 0);
        const institutionLocked = trialInstitutionId !== undefined || formEdit;

        let treatingClinicianLookup = this.loadPersonnelLookups("TreatingClinicianId", ["PrincipalInvestigator", "SubInvestigator"]);
        let investigatorLookup = this.loadPersonnelLookups("InvestigatorId", ["PrincipalInvestigator", "SubInvestigator"]);
        let dataManagerLookup = this.loadPersonnelLookups("DataManagerId", ["DataManager"]);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            institutionId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: institutionLocked,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("Patient", "InstitutionId", lookups), form.institutionId),
                },
                changeAction: (model, value, form, parentModel) => {
                    changeForm(model, value);
                    changeForm(reduxFormName + parentModel + '.institution', null);
                    changeForm(reduxFormName + parentModel + '.treatingClinicianId', null);
                    changeForm(reduxFormName + parentModel + '.investigatorId', null);
                    changeForm(reduxFormName + parentModel + '.dataManagerId', null);
                },
            },
            initials: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formOpmsDisabled,
            },
            registrationNumber: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formOpmsDisabled,
            },
            caseTypeId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("Patient", "CaseTypeId", lookups), form.caseTypeId),
                },
                changeAction: (model, value, form, parentModel) => {
                    changeForm(model, value);
                    changeForm(reduxFormName + parentModel + '.caseType', null);
                },
            },
            trialTreatmentId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formOpmsDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("Patient", "TrialTreatmentId", lookups), form.trialTreatmentId),
                },
                changeAction: (model, value, form, parentModel) => {
                    changeForm(model, value);
                    changeForm(reduxFormName + parentModel + '.trialTreatment', null);
                },
            },
            accrualDate: {
                type: RegistrationComponent.RegistrationControl,
                inputType: DatePickerFormInput,
                inputDisabled: formOpmsDisabled,
            },
            treatingClinicianId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(treatingClinicianLookup, form.treatingClinicianId),
                },
                changeAction: (model, value, form, parentModel) => {
                    changeForm(model, value);
                    changeForm(reduxFormName + parentModel + '.treatingClinician', null);
                },
            },
            investigatorId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(investigatorLookup, form.investigatorId),
                },
                changeAction: (model, value, form, parentModel) => {
                    changeForm(model, value);
                    changeForm(reduxFormName + parentModel + '.investigator', null);
                },
            },
            dataManagerId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(dataManagerLookup, form.dataManagerId),
                },
                changeAction: (model, value, form, parentModel) => {
                    changeForm(model, value);
                    changeForm(reduxFormName + parentModel + '.dataManager', null);
                },
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formOpmsDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            },
        };

        return <div>
            <h3>Participant</h3>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >

                {
                    renderFormControls(form, "patient", formComponents, lookups, validationFailures, formProperties, changeForm)
                }

                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
        </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.Patient) {
        const {
            savePatient
        } = this.props;

        if (data) {
            let changes: Dtos.Patient = {} as Dtos.Patient;
            savePatient(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.Patient) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            trialId,
            trialInstitutionId,
            navigate
        } = this.props;

        if (trialInstitutionId !== undefined) {
            navigate("/trials/" + trialId + "/trialinstitution/" + trialInstitutionId + "/participants");
        } else {
            navigate("/trials/" + trialId + "/participants");
        }
    }

    loadForm() {
        const {
            loadForm,
            patient,
        } = this.props

        let extraData: Dtos.Patient = {} as Dtos.Patient;

        loadForm(reduxFormName, Object.assign({ ...patient }, extraData));
    }

    loadPersonnelLookups(propertyName: string, roleNames: string[]) {

        const {
            form,
            lookups,
        } = this.props;

        let resultLookup = new Dtos.Lookup();
        resultLookup.propertyName = propertyName;
        resultLookup.type = "Patient";
        resultLookup.values = [];

        if (form.institutionId && form.institutionId > 0) {
            roleNames.forEach(roleName => {
                const key: string = "Personnel-" + form.institutionId.toString() + "-" + roleName;
                let lookup = findLookup("Patient", key, lookups);
                if (lookup) {
                    lookup.values.forEach(val => {
                        resultLookup.values.push(val);
                    });
                }
            });
        }
        return resultLookup;
    }
}


const mapStateToProps = (state: ITmdState, ownProps: PatientPageProps): IPatientPageProps => {

    let patient: Dtos.Patient | undefined = !(state.patient.formData instanceof Array) ? state.patient.formData : undefined;
    let trial: Dtos.Trial | undefined = !(state.trials.formData instanceof Array) ? state.trials.formData : undefined;
    let trialInstitution: Dtos.TrialInstitution | undefined = !(state.trialInstitution.formData instanceof Array) ? state.trialInstitution.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,
        trialInstitutionId: ownProps.match ? ownProps.match.params.trialInstitutionId : undefined!,
        trialInstitution: trialInstitution!,
        patientId: ownProps.match && ownProps.match.params.patientId && ownProps.match.params.patientId.toString() != "create" ? ownProps.match.params.patientId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        patient: patient!,

        trial: trial!,

        loadingPatient: state.patient.loadState && state.patient.loadState.status == RequestState.Pending,
        loadPatientSuccess: state.patient.loadState && state.patient.loadState.status == RequestState.Success,
        loadPatientFailure: state.patient.loadState && state.patient.loadState.status == RequestState.Failure,

        loadingTrialInstitution: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Pending,
        loadTrialInstitutionSuccess: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Success,
        loadTrialInstitutionFailure: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Failure,

        loadingTrial: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,

        savingPatient: state.patient.saveState && state.patient.saveState.status == RequestState.Pending,
        savePatientSuccess: state.patient.saveState && state.patient.saveState.status == RequestState.Success,
        savePatientFailure: state.patient.saveState && state.patient.saveState.status == RequestState.Failure,

        creatingPatient: state.patient.createState && state.patient.createState.status == RequestState.Pending,
        createPatientSuccess: state.patient.createState && state.patient.createState.status == RequestState.Success,
        createPatientFailure: state.patient.createState && state.patient.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: state.patient.lookups,

        form: state.reduxForms.patient,
        formState: state.patient.formState,
        formProperties: state.patient.formProperties,

        reduxFormState: state.reduxForms.formState.patient,
        validationFailures: patient ?
            state.patient.validationFailures :
            undefined,

    };
};

const mapDispatchToProps = (dispatch): IPatientPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadPatient: bindActionCreators(PatientActions.LoadPatientById, dispatch),
        clearPatient: bindActionCreators(PatientActions.Clear, dispatch),

        savePatient: bindActionCreators(PatientActions.SavePatient, dispatch),
        createPatient: bindActionCreators(PatientActions.CreatePatient, dispatch),

        loadTrialInstitution: bindActionCreators(TrialInstitutionActions.LoadTrialInstitutionById, dispatch),
        clearTrialInstitution: bindActionCreators(TrialInstitutionActions.Clear, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch)

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(PatientPage);
