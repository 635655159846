import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const institutiontypesApi = {
    getInstitutionTypes: (): Observable<Dtos.GetInstitutionTypesResponse> => {
        //Create the request.
        let request: Dtos.GetInstitutionTypes = new Dtos.GetInstitutionTypes();

        //Send request.
        const response: Promise<Dtos.GetInstitutionTypesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getInstitutionTypeById: (id: number): Observable<Dtos.GetInstitutionTypeByIdResponse> => {
        //Create the request.
        let request: Dtos.GetInstitutionTypeById = new Dtos.GetInstitutionTypeById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetInstitutionTypeByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setInstitutionType: (institutionType: Dtos.InstitutionType): Observable<Dtos.SetInstitutionTypeResponse> => {

        let request: Dtos.SetInstitutionType = new Dtos.SetInstitutionType();

        request.institutionType = institutionType;

        // send request
        const response: Promise<Dtos.SetInstitutionTypeResponse> = client.post(request);

        return Observable.from(response);

    },
    createInstitutionType: (): Observable<Dtos.CreateInstitutionTypeResponse> => {
        //Create the request.
        let request: Dtos.CreateInstitutionType = new Dtos.CreateInstitutionType();

        //Send request.
        const response: Promise<Dtos.CreateInstitutionTypeResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}