import * as React from 'react';
import * as ReactDom from 'react-dom';
import * as classNames from "classnames";
import './Modal.scss';
import { ModalSize } from '../../../../enumerations/ModalSize';

interface IModalProps {
    modalContainerId?: string;
    open?: boolean;
    disableCloseOnOverlayClick?: boolean;
    onClose?: () => void;
    size?: ModalSize;
}

const modalContainerIdDefault: string = 'modal';
const modalBodyClass: string = 'modal-open'
const modalBodyClassRegex: RegExp = new RegExp("(^|\s)" + modalBodyClass + "($|(?=(\s)))", "g");

class Modal extends React.PureComponent<IModalProps, any> {
    private el: HTMLDivElement;
    // @ts-ignore
    private modalContainer: HTMLElement;

    constructor(props) {
        super(props);

        this.el = document.createElement('div');
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        // The portal element is inserted in the DOM tree after
        // the Modal's children are mounted, meaning that children
        // will be mounted on a detached DOM node. If a child
        // component requires to be attached to the DOM tree
        // immediately when mounted, for example to measure a
        // DOM node, or uses 'autoFocus' in a descendant, add
        // state to Modal and only render the children when Modal
        // is inserted in the DOM tree.

        if (this.props.open) {
            this.openModal();
        }
    }

    componentWillUpdate(nextProps: IModalProps) {
        if (nextProps.open && !this.props.open) {
            this.openModal();
        }
    }

    componentDidUpdate(prevProps: IModalProps) {

        if (prevProps.open && !this.props.open) {
            this.closeModal();
        }
    }

    openModal() {
        if (!this.modalContainer) {
            // @ts-ignore
            this.modalContainer = document.getElementById(this.props.modalContainerId ? this.props.modalContainerId : modalContainerIdDefault);
        }

        if (this.modalContainer && this.el) {

            this.modalContainer.appendChild(this.el);

            let body: HTMLElement = document.body;
            if (!modalBodyClassRegex.exec(body.className)) {
                if (body.className && body.className != '' && body.className.indexOf(modalBodyClass) > -1) {
                    // do nothing
                } else if (body.className && body.className != '') {
                    body.className += " ";
                    body.className += modalBodyClass;
                }
                else {
                    body.className = "";
                    body.className += modalBodyClass;
                }

            }
        }
        else if (!this.modalContainer) {
            throw 'Modal container not found.';
        }
    }

    closeModal() {
        const {
            modalContainerId,
            onClose
        } = this.props;

        if (!this.modalContainer) {
            // @ts-ignore
            this.modalContainer = document.getElementById(modalContainerId ? modalContainerId : modalContainerIdDefault);
        }

        if (this.modalContainer && this.el) {
            this.modalContainer.removeChild(this.el);

            let body: HTMLElement = document.body;

            if (!this.modalContainer.childNodes ||
                this.modalContainer.childNodes.length == 0) {

                body.className = body.className.replace(modalBodyClassRegex, "");
            }
        }
        else if (!this.modalContainer) {
            throw 'Modal container not found.';
        }
    }

    componentWillUnmount() {
        if (this.props.open) {
            this.closeModal();
        }
    }

    render() {

        if (!this.props.open) {
            return null;
        }

        return ReactDom.createPortal(
            this.renderContent(),
            this.el
        );
    }

    renderContent() {
        const {
            children,
            disableCloseOnOverlayClick,
            onClose,
            size
        } = this.props;

        let overlayFunction: (() => void) | undefined;
        // let close: (() => void) | undefined;

        if (!disableCloseOnOverlayClick && onClose) {
            overlayFunction = onClose;
        }

        return <div>
            <div className="modal-backdrop fade show" onClick={overlayFunction}/>
            <div className="modal fade d-block show" tabIndex={-1} role="dialog" aria-hidden="true" style={{ pointerEvents: "none" }}>
                <div className={classNames("modal-dialog","modal-dialog-scrollable" , "modal-dialog-centered", { "modal-sm": size == ModalSize.Sm }, { "modal-lg": size == ModalSize.Lg }, { "modal-xl": size == ModalSize.Xl })} role="document">
                    <div className="modal-content" style={{ pointerEvents: "all" }}>
                        {
                            children
                        }
                    </div>
                </div>
            </div>
        </div>;
    }
}
export default Modal;