import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const homeApi = {
    getHome: (): Observable<Dtos.GetHomeResponse> => {
        //Create the request.
        let request: Dtos.GetHome = new Dtos.GetHome();

        //Send request.
        const response: Promise<Dtos.GetHomeResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}