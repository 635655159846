import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_TRIALINSTITUTIONITEM_LOAD_BY_ID,

    ACTION_TRIALINSTITUTIONITEM_SAVE,

    ACTION_TRIALINSTITUTIONITEM_CREATE,

    ITrialInstitutionItemLoadByIdAction,
    ITrialInstitutionItemLoadFailureAction,
    ITrialInstitutionItemLoadSuccessAction,

    ITrialInstitutionItemSaveAction,
    ITrialInstitutionItemSaveSuccessAction,
    ITrialInstitutionItemSaveFailureAction,

    ITrialInstitutionItemCreateAction,
    ITrialInstitutionItemCreateSuccessAction,
    ITrialInstitutionItemCreateFailureAction,

    LoadSuccess,
    LoadFailure,


    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,
} from "../actions/trialInstitutionItem";
import { trialInstitutionItemsApi } from "../services/trialInstitutionItems";


export const
    getTrialInstitutionItemsByIdEpic = (action$): Observable<ITrialInstitutionItemLoadSuccessAction | ITrialInstitutionItemLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALINSTITUTIONITEM_LOAD_BY_ID)
            .mergeMap((action: ITrialInstitutionItemLoadByIdAction) => {
                return trialInstitutionItemsApi
                    .getTrialInstitutionItemById(action.id)
                    .map(response =>
                        LoadSuccess(response.trialInstitutionItem, response.formState, response.formProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setTrialInstitutionItemEpic = (action$): Observable<ITrialInstitutionItemSaveSuccessAction | ITrialInstitutionItemSaveFailureAction> => {
        return action$
            .ofType(ACTION_TRIALINSTITUTIONITEM_SAVE)
            .mergeMap((action: ITrialInstitutionItemSaveAction) => {
                return trialInstitutionItemsApi
                    .setTrialInstitutionItem(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.trialInstitutionItem, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createTrialInstitutionItemEpic = (action$): Observable<ITrialInstitutionItemCreateSuccessAction | ITrialInstitutionItemCreateFailureAction> => {
        return action$
            .ofType(ACTION_TRIALINSTITUTIONITEM_CREATE)
            .mergeMap((action: ITrialInstitutionItemCreateAction) => {
                return trialInstitutionItemsApi
                    .createTrialInstitutionItem(action.trialInstitutionId, action.trialItemId)
                    .map(response =>
                        CreateSuccess(response.trialInstitutionItem, response.formState, response.formProperties, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



