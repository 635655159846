import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trackerMilestoneApi = {
    getTrackerMilestones: (): Observable<Dtos.GetTrackerMilestonesResponse> => {
        //Create the request.
        let request: Dtos.GetTrackerMilestones = new Dtos.GetTrackerMilestones();

        //Send request.
        const response: Promise<Dtos.GetTrackerMilestonesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrackerMilestoneById: (id: number): Observable<Dtos.GetTrackerMilestoneByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrackerMilestoneById = new Dtos.GetTrackerMilestoneById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTrackerMilestoneByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrackerMilestone: (trackerMilestone: Dtos.TrackerMilestone): Observable<Dtos.SetTrackerMilestoneResponse> => {

        let request: Dtos.SetTrackerMilestone = new Dtos.SetTrackerMilestone();

        request.trackerMilestone = trackerMilestone;

        // send request
        const response: Promise<Dtos.SetTrackerMilestoneResponse> = client.post(request);

        return Observable.from(response);

    },
    createTrackerMilestone: (): Observable<Dtos.CreateTrackerMilestoneResponse> => {
        //Create the request.
        let request: Dtos.CreateTrackerMilestone = new Dtos.CreateTrackerMilestone();

        //Send request.
        const response: Promise<Dtos.CreateTrackerMilestoneResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}