import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trialInstitutionItemsApi = {
    getTrialInstitutionItemById: (id: number): Observable<Dtos.GetTrialInstitutionItemByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrialInstitutionItemById = new Dtos.GetTrialInstitutionItemById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTrialInstitutionItemByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrialInstitutionItem: (trialInstitutionItem: Dtos.TrialInstitutionItem): Observable<Dtos.SetTrialInstitutionItemResponse> => {

        let request: Dtos.SetTrialInstitutionItem = new Dtos.SetTrialInstitutionItem();

        request.trialInstitutionItem = trialInstitutionItem;

        // send request
        const response: Promise<Dtos.SetTrialInstitutionItemResponse> = client.post(request);

        return Observable.from(response);

    },
    createTrialInstitutionItem: (trialInstitutionId: number, trialItemId: number): Observable<Dtos.CreateTrialInstitutionItemResponse> => {
        //Create the request.
        let request: Dtos.CreateTrialInstitutionItem = new Dtos.CreateTrialInstitutionItem();

        request.trialInstitutionId = trialInstitutionId;
        request.trialItemId = trialItemId;

        //Send request.
        const response: Promise<Dtos.CreateTrialInstitutionItemResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}