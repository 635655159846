import * as React from 'react';
import './TrialPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { ITrialReduxFormState, initialTrialState, initialTrialProjectRegistrationState, initialTrialChairState, initialTrialFundingState } from "../../../reducers/reactReduxForms/trial";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as TrialActions from '../../../actions/trial';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput, DatePickerFormInputFabric } from '../../../components/form';
import { TrialLayout } from '../../index';
import { GridRemove } from "../../../enumerations/GridRemove";

interface ITrialPageParams {
    trialId: number;
}

interface ITrialPageProps {

    form: Dtos.Trial & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITrialReduxFormState;

    location: Location;
    trialId: number;
    match: match<ITrialPageParams>;
    history: History;

    // From state
    trial: Dtos.Trial;
    trialRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];


    loadingTrial: boolean;
    loadTrialSuccess: boolean;
    loadTrialFailure: boolean;

    savingTrial: boolean;
    saveTrialSuccess: boolean;
    saveTrialFailure: boolean;

    creatingTrial: boolean;
    createTrialSuccess: boolean;
    createTrialFailure: boolean;

    validationFailures: Dtos.ResponseError[];
    permissions?: Dtos.PersonnelTmdPermissions[];
    user: Dtos.User;
}

interface ITrialPageActions {
    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    saveTrial: TrialActions.ITrialSaveActionCreator;
    createTrial: TrialActions.ITrialCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type TrialPageProps = ITrialPageProps & ITrialPageActions;

const reduxFormName: string = "reduxForms.trial";

const TRIAL_PROJECT_REGISTRATION_URL_LINK: any = (form, changeForm, reduxFormName, lookups, item, index, itemArray, gridComponent) => {
    if (!item || !item.url) {
        return <></>
    }
    return <div>
        <a href={item.url} target="_blank" className="btn btn-link">Open</a>
    </div>
}

class TrialPage extends React.PureComponent<TrialPageProps, any> {

    constructor(props: TrialPageProps) {
        super(props);

        this.clearTrial = this.clearTrial.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTrial,
            trialId,
            createTrial
        } = this.props;

        if (trialId) {
            loadTrial(trialId);
        } else if (!trialId) {
            createTrial();
        }
    }

    componentDidUpdate(prevProps: TrialPageProps, prevState) {
        const {
            trial,
            trialId,
            form,
            navigate,
            loadTrial,
            loadingTrial,
            loadTrialSuccess,
            createTrial,
            creatingTrial,
            createTrialSuccess,
            savingTrial,
            saveTrialSuccess,
            validationFailures
        } = this.props;

        if (trialId && prevProps.trialId != trialId) {
            loadTrial(trialId);
        }
        else if (!trialId && prevProps.trialId) {
            createTrial();
        }

        if (!loadingTrial && prevProps.loadingTrial) {
            if (loadTrialSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingTrial && prevProps.savingTrial) {
            if (saveTrialSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/trials")
                } else if (!form.saveAndReturn && trialId && trialId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !trialId && trial && trial.id > 0) {
                    navigate("/trials/" + trial.id + "/details");
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingTrial && prevProps.creatingTrial) {
            if (createTrialSuccess) {
                this.loadForm();
            }
            else {

            }
        }
    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearTrial();
        loadForm(reduxFormName, initialTrialState);
        resetForm(reduxFormName);
    }

    clearTrial() {
        this.props.clearTrial();
    }

    showTrial(): boolean {
        const {
            loadingTrial,
            loadTrialSuccess
        } = this.props

        if (!loadingTrial && loadTrialSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrial,
            creatingTrial
        } = this.props

        return (
            <TrialLayout loading={loadingTrial || creatingTrial} permission={ Dtos.Permission.TrialView }>{this.renderContent()}</TrialLayout>
        );
    }

    renderContent() {
        const {
            form,
            trialId,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            permissions,
            savingTrial
        } = this.props;

        const canViewDetails = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialDetailsView, trialId, undefined);
        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermissionFor(trialId, undefined, permissions, Dtos.Permission.TrialUpdate, Dtos.Permission.TrialCreate);
        const formDisabled: boolean = !hasPermission || savingTrial || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            detailsGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    studyAcronym: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    title: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 1
                    },
                }
            },
            laySummaryGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    laySummary: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextAreaFormInput,
                        inputDisabled: formDisabled,
                        size: 1
                    },
                }
            },
            numberingGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    newProposalNumber: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    trialDevelopmentNumber: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    allIdentifiers: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    protocolID: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    }
                }
            },
            trialProjectRegistrationHeader: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="border-0 d-flex pt-4 ">
                    <h3>Project Registrations</h3>
                </div>
            },
            trialProjectRegistrations: {
                type: RegistrationComponent.RegistrationGrid,
                label: "Project Registrations",
                disabled: formDisabled,
                formName: "TrialProjectRegistration",
                className: "",
                minRows: 0,
                columnStyles: [
                    {
                        width: undefined,
                        minWidth: "150px"
                    },
                    {
                        width: undefined,
                        minWidth: "150px"
                    },
                    {
                        width: undefined,
                        minWidth: "150px"
                    },
                    {
                        width: undefined,
                        minWidth: "150px"
                    },
                    {
                        width: "80px"
                    },
                    {
                        width: "125px"
                    },
                ],
                removeConfig: GridRemove.All,
                addRow: (gridComponent, index, array) => {
                    const state: Dtos.TrialProjectRegistration = Object.assign(
                        { ...gridComponent.initialState }
                    );
                    return state;
                },
                components: {
                    trialRegistryId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputLabel: getFormPropertyLabel("TrialProjectRegistration", "Registry", formProperties),
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialProjectRegistration", "TrialRegistryId", lookups)),
                            feedbackIcon: FontAwesomeIcons.Solid.BAN
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            const trialProjectRegistration: Dtos.TrialProjectRegistration = form;

                            if (value != 3) {
                                changeForm(reduxFormName + parentModel + ".trialRegistryOther", undefined);
                            }
                        },
                        validators: {
                            required: value => value != undefined
                        },
                        errors: {
                            required: "Registry is required."
                        },
                        errorComponent: ValidationMessage
                    },
                    trialRegistryOther: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialProjectRegistration", "Other", formProperties),
                        disabledFn: (form: Dtos.TrialProjectRegistration, arrayKey: string) => {
                            if (form) {
                                if (form.trialRegistryId == 3) {
                                    return false;
                                }
                            }
                            return true;
                        },
                    },
                    registrationNumber: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialProjectRegistration", "Registration Number", formProperties),
                    },
                    url: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialProjectRegistration", "Url", formProperties),
                    },
                    link: {
                        type: RegistrationComponent.RegistrationGridElement,
                        label: " ",
                        render: TRIAL_PROJECT_REGISTRATION_URL_LINK /*(form, changeForm, reduxFormName, lookups, sourceDocument, sourceDocumentIndex) => {
                            <div>
                                <a href="https://www.trog.com.au" target="_blank" className="btn btn-link">Open</a>
                            </div>
                        }*/
                    }
                },
                initialState: initialTrialProjectRegistrationState,
                onRemove: (model: string) => {
                    changeForm(model + "active", false);
                    return true; // this was handled here
                },
                filterRow: (data: any) => {
                    if (data.active != true) {
                        return false
                    }
                    return true;
                }
            },
            blankGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {

                }
            },
            configGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    trialCategoryId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Trial", "TrialCategoryId", lookups), form.trialCategoryId)
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + ".trialCategory", null);
                        },
                        hide: !canViewDetails,
                        size: 2
                    },
                    trialTrialPhases: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            multi: true,
                            value: form.trialTrialPhases ? form.trialTrialPhases.filter(f => f.active).map(val => val.trialPhaseId) : [],
                            options: generateOptionsFromLookup(findLookup("Trial", "TrialTrialPhases", lookups)),
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value.map(v => { return { active: 1, trialPhaseId: v }; }));
                            console.log('changeAction', model, value, form, parentModel);
                        },
                        hide: !canViewDetails,
                        size: 1,
                    },
                    trialInvolvements: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            multi: true,
                            value: form.trialInvolvements ? form.trialInvolvements.filter(f => f.active).map(val => val.involvementId) : [],
                            options: generateOptionsFromLookup(findLookup("Trial", "TrialInvolvements", lookups)),
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value.map(v => { return { active: 1, involvementId: v }; }));
                            console.log('changeAction', model, value, form, parentModel);
                        },
                        size: 1,
                        hide: !canViewDetails
                    },
                    trialTumourGroups: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            multi: true,
                            value: form.trialTumourGroups ? form.trialTumourGroups.filter(f => f.active).map(val => val.tumourGroupId) : [],
                            options: generateOptionsFromLookup(findLookup("Trial", "TrialTumourGroups", lookups)),
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value.map(v => { return { active: 1, tumourGroupId: v }; }));
                            if (value.indexOf(10) == -1) {
                                changeForm(reduxFormName + ".tumourGroupOther", undefined);
                            }
                        },
                        size: 1,
                        hide: !canViewDetails
                    },
                    tumourGroupOther: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 1,
                        disabledFn: (form, key) => {
                            if (form && form.trialTumourGroups) {
                                if (form.trialTumourGroups.find(f => f.tumourGroupId == 10) != undefined) {
                                    return false;
                                }
                            }
                            return true;
                        }
                    },
                    trialAllocatedWPs: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            multi: true,
                            value: form.trialAllocatedWPs ? form.trialAllocatedWPs.filter(f => f.active).map(val => val.allocatedWPId) : [],
                            options: generateOptionsFromLookup(findLookup("Trial", "TrialAllocatedWPs", lookups)),
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value.map(v => { return { active: 1, allocatedWPId: v }; }));
                        },
                        size: 1,
                        hide: !canViewDetails
                    },
                }
            },
            statusGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    trialStatusId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Trial", "TrialStatusId", lookups), form.trialStatusId)
                        },
                        size: 2
                    },
                    internationalTrialStatusId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Trial", "InternationalTrialStatusId", lookups), form.internationalTrialStatusId)
                        },
                        size: 2
                    }
                }
            },
            sponsorCollaboratorsGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    sponsorCollaboratorsHeader: {
                        type: RegistrationComponent.RegistrationElement,
                        component: <div className="border-0 d-flex pt-4 ">
                            <h3>Sponsor &amp; Collaborators</h3>
                        </div>,
                        size: 1
                    },
                    australianLeadGroupId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Trial", "AustralianLeadGroupId", lookups), form.australianLeadGroupId)
                        },
                        size: 2
                    },
                    internationalLeadGroupId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Trial", "InternationalLeadGroupId", lookups), form.internationalLeadGroupId)
                        },
                        size: 2
                    },
                    trialCollaboratingGroups: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            multi: true,
                            value: form.trialCollaboratingGroups ? form.trialCollaboratingGroups.filter(f => f.active).map(val => val.collaboratingGroupId) : [],
                            options: generateOptionsFromLookup(findLookup("Trial", "TrialCollaboratingGroups", lookups)),
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value.map(v => { return { active: 1, collaboratingGroupId: v }; }));
                        },
                        size: 1
                    },
                    centralCoordinatingCentreGroupId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Trial", "CentralCoordinatingCentreGroupId", lookups), form.centralCoordinatingCentreGroupId)
                        },
                        size: 2
                    },
                    internationalCentralCoordinatingCentreGroupId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Trial", "InternationalCentralCoordinatingCentreGroupId", lookups), form.internationalCentralCoordinatingCentreGroupId)
                        },
                        size: 2
                    },
                    centralRTQACoordinatingCentreGroupId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Trial", "CentralRTQACoordinatingCentreGroupId", lookups), form.centralRTQACoordinatingCentreGroupId)
                        },
                        size: 2
                    },
                }
            },            
            trialChairHeader: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="border-0 d-flex pt-4 ">
                    <h3>Trial Chairs</h3>
                </div>
            },
            trialChairs: {
                type: RegistrationComponent.RegistrationGrid,
                label: "Trial Chairs",
                disabled: formDisabled,
                formName: "TrialChair",
                className: "",
                minRows: 0,
                columnStyles: [
                    {
                        width: undefined,
                        minWidth: "150px"
                    },
                    {
                        width: undefined,
                        minWidth: "150px"
                    },
                    {
                        width: "125px"
                    },
                ],
                removeConfig: GridRemove.All,
                addRow: (gridComponent, index, array) => {
                    const state: Dtos.TrialChair = Object.assign(
                        { ...gridComponent.initialState }
                    );
                    return state;
                },
                components: {
                    personnelId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputLabel: getFormPropertyLabel("TrialChair", "PersonnelId", formProperties),
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialChair", "PersonnelId", lookups)),
                            feedbackIcon: FontAwesomeIcons.Solid.BAN
                        },
                        validators: {
                            required: value => value != undefined
                        },
                        errors: {
                            required: "Required."
                        },
                        errorComponent: ValidationMessage
                    },
                    isPrimary: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: CheckboxFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: "Primary Contact",
                    },
                    /*active: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: CheckboxFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialChair", "Active", formProperties),
                    },*/
                },
                initialState: initialTrialProjectRegistrationState,
                onRemove: (model: string) => {
                    changeForm(model + "active", false);
                    return true; // this was handled here
                },
                filterRow: (data: any) => {
                    if (data.active != true) {
                        return false
                    }
                    return true;
                }
            },
            fundingHeader: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="border-0 d-flex pt-4 ">
                    <h3>Funding</h3>
                </div>
            },
            trialFundings: {
                type: RegistrationComponent.RegistrationGrid,
                label: "Trial Fundings",
                disabled: formDisabled,
                formName: "TrialFunding",
                className: "",
                minRows: 0,
                columnStyles: [
                    {
                        width: undefined,
                        minWidth: "150px"
                    },
                    {
                        width: undefined,
                        minWidth: "150px"
                    },
                    {
                        width: undefined,
                        minWidth: "150px"
                    },
                    {
                        width: undefined,
                        minWidth: "100px"
                    },
                    {
                        width: undefined,
                        minWidth: "150px"
                    },
                    {
                        width: undefined,
                        minWidth: "100px"
                    },
                    {
                        width: undefined,
                        minWidth: "100px"
                    },
                    {
                        width: undefined,
                        minWidth: "150px"
                    },
                    {
                        width: undefined,
                        minWidth: "150px"
                    },
                    {
                        width: "125px"
                    },
                ],
                removeConfig: GridRemove.All,
                addRow: (gridComponent, index, array) => {
                    const state: Dtos.TrialChair = Object.assign(
                        { ...gridComponent.initialState }
                    );
                    return state;
                },
                components: {
                    name: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialFunding", "Name", formProperties),
                    },
                    source: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialFunding", "Source", formProperties),
                    },
                    fundingStatusId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialFunding", "FundingStatusId", formProperties),
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialFunding", "FundingStatusId", lookups)),
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + ".fundingStatus", undefined);
                        },
                    },
                    amountRequested: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: NumberFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialFunding", "AmountRequested", formProperties),
                        inputProps: {
                            precision: 2,
                            strict: true
                        },
                        inputClassName: "form-control text-right",
                    },
                    submissionDate: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialFunding", "SubmissionDate", formProperties),
                    },
                    amountRecieved: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: NumberFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialFunding", "AmountRecieved", formProperties),
                        inputProps: {
                            precision: 2,
                            strict: true
                        },
                        inputClassName: "form-control text-right",
                    },
                    currency: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialFunding", "Currency", formProperties),
                    },
                    startDate: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialFunding", "StartDate", formProperties),
                    },
                    endDate: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInputFabric,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialFunding", "EndDate", formProperties),
                    },
                    /*active: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: CheckboxFormInput,
                        inputDisabled: formDisabled,
                        inputLabel: getFormPropertyLabel("TrialChair", "Active", formProperties),
                    },*/
                },
                initialState: initialTrialProjectRegistrationState,
                onRemove: (model: string) => {
                    changeForm(model + "active", false);
                    return true; // this was handled here
                },
                filterRow: (data: any) => {
                    if (data.active != true) {
                        return false
                    }
                    return true;
                }
            },
            blankGroup2: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {

                }
            },
            activeGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    active: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: CheckboxFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            label: "Active"
                        },
                        inputLabel: " ",
                        size: 1,
                        hide: !canViewDetails
                    },
                }
            },
        };

        return <div>
            <h1>Trial</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "trial", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
        </div>
    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.Trial) {
        const {
            saveTrial
        } = this.props;

        if (data) {
            let changes: Dtos.Trial = {} as Dtos.Trial;
            saveTrial(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.Trial) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/trials");
    }

    loadForm() {
        const {
            loadForm,
            trial,
        } = this.props

        let extraData: Dtos.Trial = {} as Dtos.Trial;

        loadForm(reduxFormName, Object.assign({ ...trial }, extraData));
    }
}

const mapStateToProps = (state: ITmdState, ownProps: TrialPageProps): ITrialPageProps => {

    let trial: Dtos.Trial | undefined = !(state.trials.formData instanceof Array) ? state.trials.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialId: ownProps.match && ownProps.match.params.trialId && ownProps.match.params.trialId.toString() != "create" ? ownProps.match.params.trialId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trial: trial!,
        trialRoles: state.trials.formRoles,

        loadingTrial: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,
        loadTrialSuccess: state.trials.loadState && state.trials.loadState.status == RequestState.Success,
        loadTrialFailure: state.trials.loadState && state.trials.loadState.status == RequestState.Failure,

        savingTrial: state.trials.saveState && state.trials.saveState.status == RequestState.Pending,
        saveTrialSuccess: state.trials.saveState && state.trials.saveState.status == RequestState.Success,
        saveTrialFailure: state.trials.saveState && state.trials.saveState.status == RequestState.Failure,

        creatingTrial: state.trials.createState && state.trials.createState.status == RequestState.Pending,
        createTrialSuccess: state.trials.createState && state.trials.createState.status == RequestState.Success,
        createTrialFailure: state.trials.createState && state.trials.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: state.trials.lookups,

        form: state.reduxForms.trial,
        formState: state.trials.formState,
        formProperties: state.trials.formProperties,

        reduxFormState: state.reduxForms.formState.trial,
        validationFailures: trial ?
            state.trials.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): ITrialPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch),

        saveTrial: bindActionCreators(TrialActions.SaveTrial, dispatch),
        createTrial: bindActionCreators(TrialActions.CreateTrial, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialPage);
