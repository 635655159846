import * as React from 'react';

import './InstitutionLookupsLayout.scss';
import { connect } from "react-redux";
import { RestrictedLayout } from "../../../components/layouts/index";
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { SettingsSubMenu } from '../../../helpers/subMenuHelper';
import * as AuthenticationHelper from '../../../helpers/authentication';
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";

interface IInstitutionLookupsLayoutProps {
    loading?: boolean;
    children?: any;
    permissions?: Dtos.PersonnelTmdPermissions[];
    permission?: Dtos.Permission;
}

interface IInstitutionLookupsLayoutActions {

}

type InstitutionLookupsLayoutProps = IInstitutionLookupsLayoutProps & IInstitutionLookupsLayoutActions;

class InstitutionLookupsLayout extends React.PureComponent<InstitutionLookupsLayoutProps, any> {

    constructor(props: InstitutionLookupsLayoutProps) {
        super(props);

    }

    render() {

        const {
            loading,
            permissions,
            permission
        } = this.props;

        let menu: any[] = [];

        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.SiteLocationsView)) {
            menu.push({
                icon: undefined,
                title: "Site Locations",
                url: "/settings/institution/sitelocations"
            });
        }
        //if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.KeyContactsView)) {
        //    menu.push({
        //        icon: undefined,
        //        title: "Key Contacts",
        //        url: "/settings/institution/keycontacts"
        //    });
        //}
        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.FacilityAllianceMembersView )) {
            menu.push({
                icon: undefined,
                title: "Facility Alliance Members",
                url: "/settings/institution/facilityalliancemembers"
            });
        }
        if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.InstitutionTypesView)) {
            menu.push({
                icon: undefined,
                title: "Institution Types",
                url: "/settings/institution/institutiontypes"
            });
        }
        //if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.InstitutionCodingView)) {
        //    menu.push({
        //        icon: undefined,
        //        title: "Institution Coding",
        //        url: "/settings/institution/institutioncodings"
        //    });
        //}

        return <RestrictedLayout
            subMenuTitle={"" }
            subMenuItems={SettingsSubMenu(permissions)}
            sub2MenuTitle={""}
            sub2MenuItems={menu}
            loading={loading}
        >
            {permissions.length > 0 && permission && !AuthenticationHelper.hasPermission(permissions, permission) ?
                <div className="alert alert-warning d-flex">
                    <div className="mr-2">
                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                    </div>
                    <span>Permission Denied</span>
                </div>
                : this.props.children}
        </RestrictedLayout>;
    }

}


const mapStateToProps = (state: ITmdState, ownProps: InstitutionLookupsLayoutProps): IInstitutionLookupsLayoutProps => {
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];
    return {
        permissions: permissions,
        permission: ownProps.permission,
        loading: ownProps.loading,
        children: ownProps.children
    };
};

const mapDispatchToProps = (dispatch): IInstitutionLookupsLayoutActions => {
    return {
        
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(InstitutionLookupsLayout);
