import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_KEYCONTACTS_LOAD,
    ACTION_KEYCONTACTS_LOAD_BY_ID,

    ACTION_KEYCONTACTS_SAVE,

    ACTION_KEYCONTACTS_CREATE,

    IKeyContactLoadAction,
    IKeyContactLoadByIdAction,
    IKeyContactLoadFailureAction,
    IKeyContactLoadSuccessAction,

    IKeyContactSaveAction,
    IKeyContactSaveSuccessAction,
    IKeyContactSaveFailureAction,

    IKeyContactCreateAction,
    IKeyContactCreateSuccessAction,
    IKeyContactCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/keyContacts";
import { keycontactsApi } from "../services/keyContact";


export const
    getKeyContactsEpic = (action$): Observable<IKeyContactLoadSuccessAction | IKeyContactLoadFailureAction> => {
        return action$
            .ofType(ACTION_KEYCONTACTS_LOAD)
            .mergeMap((action: IKeyContactLoadAction) => {
                return keycontactsApi
                    .getKeyContacts()
                    .map(response =>
                        LoadSuccess(response.keyContacts, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getKeyContactByIdEpic = (action$): Observable<IKeyContactLoadSuccessAction | IKeyContactLoadFailureAction> => {
        return action$
            .ofType(ACTION_KEYCONTACTS_LOAD_BY_ID)
            .mergeMap((action: IKeyContactLoadByIdAction) => {
                return keycontactsApi
                    .getKeyContactById(action.id)
                    .map(response =>
                        LoadSuccess(response.keyContact, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setKeyContactEpic = (action$): Observable<IKeyContactSaveSuccessAction | IKeyContactSaveFailureAction> => {
        return action$
            .ofType(ACTION_KEYCONTACTS_SAVE)
            .mergeMap((action: IKeyContactSaveAction) => {
                return keycontactsApi
                    .setKeyContact(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.keyContact, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createKeyContactEpic = (action$): Observable<IKeyContactCreateSuccessAction | IKeyContactCreateFailureAction> => {
        return action$
            .ofType(ACTION_KEYCONTACTS_CREATE)
            .mergeMap((action: IKeyContactCreateAction) => {
                return keycontactsApi
                    .createKeyContact()
                    .map(response =>
                        CreateSuccess(
                            response.keyContact,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



