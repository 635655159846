import * as React from 'react';

import * as classNames from "classnames";
import './InstitutionDepartmentPersonnelEditPage.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";



import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";
// add state for sub forms too below
import { IPersonnelRoleReduxFormState, initialPersonnelRoleState } from "../../../reducers/reactReduxForms/personnelRole";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, DatePickerFormInputFabric, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";

import * as PersonnelRoleActions from '../../../actions/personnelRole';
import * as InstitutionActions from '../../../actions/institution';

import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../../components/form';
import { SettingsSubMenu, InstitutionSubMenu } from '../../../helpers/subMenuHelper';

interface IInstitutionDepartmentPersonnelEditPageParams {
    institutionId: number;
    departmentId: number;
    personnelRoleId: number;
}

interface IInstitutionDepartmentPersonnelEditPageProps {

    form: Dtos.PersonnelRole & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IPersonnelRoleReduxFormState;

    location: Location;
    institutionId: number;
    departmentId: number;
    personnelRoleId: number;
    match: match<IInstitutionDepartmentPersonnelEditPageParams>;
    history: History;

    // From state
    personnelRole: Dtos.PersonnelRole;
    institution: Dtos.Institution;
    lookups: Dtos.Lookup[];

    loadingInstitution: boolean;

    loadingPersonnelRole: boolean;
    loadPersonnelRoleSuccess: boolean;
    loadPersonnelRoleFailure: boolean;

    savingPersonnelRole: boolean;
    savePersonnelRoleSuccess: boolean;
    savePersonnelRoleFailure: boolean;

    creatingPersonnelRole: boolean;
    createPersonnelRoleSuccess: boolean;
    createPersonnelRoleFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User
}

interface IInstitutionDepartmentPersonnelEditPageActions {
    clearPersonnelRole: PersonnelRoleActions.IPersonnelRoleClearActionCreator;
    loadPersonnelRole: PersonnelRoleActions.IPersonnelRoleLoadByIdActionCreator;
    savePersonnelRole: PersonnelRoleActions.IPersonnelRoleSaveActionCreator;
    createPersonnelRole: PersonnelRoleActions.IPersonnelRoleCreateActionCreator;

    clearInstitution: InstitutionActions.IInstitutionClearActionCreator;
    loadInstitution: InstitutionActions.IInstitutionLoadByIdActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type InstitutionDepartmentPersonnelEditPageProps = IInstitutionDepartmentPersonnelEditPageProps & IInstitutionDepartmentPersonnelEditPageActions;

const formName: string = "personnelRole";
const reduxFormName: string = "reduxForms.personnelRole";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class InstitutionDepartmentPersonnelEditPage extends React.PureComponent<InstitutionDepartmentPersonnelEditPageProps, any> {

    constructor(props: InstitutionDepartmentPersonnelEditPageProps) {
        super(props);

        this.clearPersonnelRole = this.clearPersonnelRole.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadPersonnelRole,
            createPersonnelRole,
            navigate,
            navigateReplace,
            personnelRoleId,
            departmentId,
            institutionId,
            loadInstitution
        } = this.props;

        if (personnelRoleId) {
            loadPersonnelRole(personnelRoleId);
        } else if (!personnelRoleId) {
            createPersonnelRole(null, null, institutionId, null, departmentId);
        }
        loadInstitution(institutionId);
    }

    componentWillReceiveProps(nextProps: InstitutionDepartmentPersonnelEditPageProps) {
    }

    componentDidUpdate(prevProps: InstitutionDepartmentPersonnelEditPageProps, prevState) {
        const {
            form,
            institutionId,
            departmentId,
            personnelRoleId,
            personnelRole,
            navigateReplace,
            loadPersonnelRole,
            loadInstitution,
            loadingPersonnelRole,
            loadPersonnelRoleSuccess,
            savingPersonnelRole,
            savePersonnelRoleSuccess,
            savePersonnelRoleFailure,
            createPersonnelRole,
            validationFailures,
            creatingPersonnelRole,
            createPersonnelRoleSuccess,
            navigate
        } = this.props;

        if (institutionId && prevProps.institutionId != institutionId) {
            loadInstitution(institutionId);
        }

        if (personnelRoleId && prevProps.personnelRoleId != personnelRoleId) {
            loadPersonnelRole(personnelRoleId);
        }
        else if (!personnelRoleId && prevProps.personnelRoleId) {
            createPersonnelRole(null, null, institutionId, null, departmentId);
        }

        if (!loadingPersonnelRole && prevProps.loadingPersonnelRole) {
            if (loadPersonnelRoleSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingPersonnelRole && prevProps.savingPersonnelRole) {
            if (savePersonnelRoleSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/institutions/" + institutionId + "/departments/" + departmentId)
                } else if (!form.saveAndReturn && personnelRoleId && personnelRoleId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !personnelRoleId && personnelRole && personnelRole.id > 0) {
                    navigate("/institutions/" + institutionId + "/departments/" + departmentId + "/personnel/" + personnelRole.id);
                    this.loadForm();
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingPersonnelRole && prevProps.creatingPersonnelRole) {
            if (createPersonnelRoleSuccess) {
                this.loadForm();
            }
            else {

            }
        }
    }

    componentWillUpdate(nextProps: InstitutionDepartmentPersonnelEditPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearPersonnelRole();
        loadForm(reduxFormName, initialPersonnelRoleState);
        resetForm(reduxFormName);
    }

    clearPersonnelRole() {
        this.props.clearPersonnelRole();
        this.props.clearInstitution();
    }

    showPersonnelRole(): boolean {
        const {
            loadingInstitution,
            loadingPersonnelRole,
            loadPersonnelRoleSuccess
        } = this.props

        if (!loadingInstitution && !loadingPersonnelRole && loadPersonnelRoleSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingInstitution,
            loadingPersonnelRole,
            institution,
            institutionId
        } = this.props;

        let id = institution ? institution.id : 0;
        let name = institution ? institution.name : "Institution";
        
        return <RestrictedLayout
            subMenuTitle={institution ? institution.display : ""}
            subMenuItems={[]}
            loading={loadingInstitution || loadingPersonnelRole}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingPersonnelRole
        } = this.props;

        let noPermissionToEditMessage = false;
        if ((form.id && form.id > 0) && validationFailures && validationFailures.filter(f => f.errorCode == "PR-005").length > 0) {
            noPermissionToEditMessage = true;
        }

        const formDisabled: boolean = savingPersonnelRole || (formState && formState.formStatus == Dtos.FormStatus.Disabled) || noPermissionToEditMessage;
        const formEdit: boolean = (formState && formState.formStatus == Dtos.FormStatus.Disabled || (form.id && form.id > 0));
        const formNew: boolean = (formState && formState.formStatus == Dtos.FormStatus.Disabled || (!form.id || form.id == 0));
        const departmentLookup: string = form.institutionId && form.institutionId > 0 ? form.institutionId.toString() + "DepartmentId" : "DepartmentId";


        let formComponents: { [index: string]: RegistrationFormComponent } = {

            trialId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formEdit,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PersonnelRole", "TrialId", lookups), form.trialId),
                    placeholder: "Institution Role"
                }
            },
            parent: {
                type: RegistrationComponent.RegistrationElement,
                component: <div>
                    {
                        renderFormControls(
                            form,
                            "PersonnelRole",
                            {
                               institutionDisplay: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: TextFormInput,
                                    inputDisabled: true,
                                    groupClassName: form.institutionDisplay == '' ? "form-group-hide" : "form-group row border border-bottom-1 border-top-0 pb-4 pt-4",
                                },
                                groupDisplay: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: TextFormInput,
                                    inputDisabled: true,
                                    groupClassName: form.groupDisplay == '' ? "form-group-hide" : "form-group row border border-bottom-1 border-top-0 pb-4 pt-4",
                                },
                            },
                            lookups,
                            validationFailures,
                            formProperties,
                            changeForm
                        )
                    }
                </div>
            },
            personnelId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formEdit,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PersonnelRole", "PersonnelId", lookups), form.personnelId)
                }
            },
            roleId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formEdit,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PersonnelRole", "RoleId", lookups), form.roleId)
                }
            },
            departmentId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: true,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PersonnelRole", departmentLookup, lookups), form.departmentId),
                    placeholder: "No Department"
                },
            },
            dateStarted: {
                type: RegistrationComponent.RegistrationControl,
                inputType: DatePickerFormInputFabric,
                validationMessageAbove: true,
                inputDisabled: formDisabled
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
                changeAction: (model, value, form, parentModel) => {
                    changeForm(model, value);
                    if (value) {
                        changeForm(reduxFormName + parentModel + '.dateCeased', null);
                    }
                },
            },
            dateCeased: {
                type: RegistrationComponent.RegistrationControl,
                inputType: DatePickerFormInputFabric,
                validationMessageAbove: true,
                inputDisabled: formDisabled,
                disabledFn: (form: Dtos.PersonnelRole, arrayKey: string) => {
                    if (form) {
                        if (form.active) {
                            return true;
                        }
                    }
                    return false;
                },
            }
        };

        return <div>
            <h3>Personnel Role</h3>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {noPermissionToEditMessage && (
                    <div className="alert alert-warning d-flex mt-2 mb-2">
                        <div className="mr-2">
                            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                        </div>
                        <span>You cannot edit a role that is higher than your own.</span>
                    </div>
                )}
                {
                    renderFormControls(form, "personnelRole", formComponents, lookups, validationFailures, formProperties, changeForm)
                }

                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.PersonnelRole) {
        const {
            savePersonnelRole
        } = this.props;

        if (data) {
            let changes: Dtos.PersonnelRole = {} as Dtos.PersonnelRole;
            savePersonnelRole(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.Institution) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            institutionId,
            departmentId,
            navigate
        } = this.props;

        navigate("/institutions/" + institutionId + "/departments/" + departmentId);
    }

    loadForm() {
        const {
            loadForm,
            personnelRole,
        } = this.props

        let extraData: Dtos.PersonnelRole = {} as Dtos.PersonnelRole;

        loadForm(reduxFormName, Object.assign({ ...personnelRole }, extraData));
    }
}


const mapStateToProps = (state: ITmdState, ownProps: InstitutionDepartmentPersonnelEditPageProps): IInstitutionDepartmentPersonnelEditPageProps => {

    let personnelRole: Dtos.PersonnelRole | undefined = !(state.personnelRole.formData instanceof Array) ? state.personnelRole.formData : undefined;
    let institution: Dtos.Institution | undefined = !(state.institutions.formData instanceof Array) ? state.institutions.formData : undefined;

    return {

        match: ownProps.match,
        institutionId: ownProps.match ? ownProps.match.params.institutionId : undefined!,
        personnelRoleId: ownProps.match && ownProps.match.params.personnelRoleId.toString() != "create" ? ownProps.match.params.personnelRoleId : undefined!,
        departmentId: ownProps.match ? ownProps.match.params.departmentId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        personnelRole: personnelRole!,

        institution: institution!,

        loadingPersonnelRole: state.personnelRole.loadState && state.personnelRole.loadState.status == RequestState.Pending,
        loadPersonnelRoleSuccess: state.personnelRole.loadState && state.personnelRole.loadState.status == RequestState.Success,
        loadPersonnelRoleFailure: state.personnelRole.loadState && state.personnelRole.loadState.status == RequestState.Failure,

        savingPersonnelRole: state.personnelRole.saveState && state.personnelRole.saveState.status == RequestState.Pending,
        savePersonnelRoleSuccess: state.personnelRole.saveState && state.personnelRole.saveState.status == RequestState.Success,
        savePersonnelRoleFailure: state.personnelRole.saveState && state.personnelRole.saveState.status == RequestState.Failure,

        creatingPersonnelRole: state.personnelRole.createState && state.personnelRole.createState.status == RequestState.Pending,
        createPersonnelRoleSuccess: state.personnelRole.createState && state.personnelRole.createState.status == RequestState.Success,
        createPersonnelRoleFailure: state.personnelRole.createState && state.personnelRole.createState.status == RequestState.Failure,

        loadingInstitution: state.institutions.loadState && state.institutions.loadState.status == RequestState.Pending,

        user: state.user.data,
        lookups: state.personnelRole.lookups,

        form: state.reduxForms.personnelRole,
        formState: state.personnelRole.formState,
        formProperties: state.personnelRole.formProperties,

        reduxFormState: state.reduxForms.formState.personnelRole,
        validationFailures: personnelRole ?
            state.personnelRole.validationFailures :
            undefined,

    };
};

const mapDispatchToProps = (dispatch): IInstitutionDepartmentPersonnelEditPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadPersonnelRole: bindActionCreators(PersonnelRoleActions.LoadPersonnelRoleById, dispatch),
        clearPersonnelRole: bindActionCreators(PersonnelRoleActions.Clear, dispatch),
        createPersonnelRole: bindActionCreators(PersonnelRoleActions.CreatePersonnelRole, dispatch),
        savePersonnelRole: bindActionCreators(PersonnelRoleActions.SavePersonnelRole, dispatch),

        loadInstitution: bindActionCreators(InstitutionActions.LoadInstitutionById, dispatch),
        clearInstitution: bindActionCreators(InstitutionActions.Clear, dispatch)

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(InstitutionDepartmentPersonnelEditPage);
