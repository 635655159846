import * as React from 'react';
import './InstitutionPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import { FileUploadFormInput } from "../../../components/form/inputs/FileUploadFormInput/index";
import { GridRemove } from "../../../enumerations/GridRemove";
import Alert from 'react-s-alert';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";
import { IInstitutionReduxFormState, initialInstitutionState } from "../../../reducers/reactReduxForms/institution";
import { initialSourceDocumentState } from '../../../reducers/reactReduxForms/sourceDocument';
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as InstitutionActions from '../../../actions/institution';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { Form, actions, Control } from 'react-redux-form';
import { formatBytes, SOURCE_DOCUMENT_FILE_CONFIGURATION } from "../../../helpers/file";
import { DEFAULT_FILE_UPLOAD_VIEW, SOURCE_DOCUMENT_UPLOAD_VIEW } from "../../../components/form/inputs/FileUploadFormInput/FileUploadViews";
import { requiredText, requiredSourceDocument } from "../../../validators/index";
import { SettingsSubMenu, InstitutionSubMenu } from '../../../helpers/subMenuHelper';
import * as FormHelper from '../../../helpers/formHelper';

interface IInstitutionPageParams {
    institutionId: number;
}

interface IInstitutionPageProps {

    form: Dtos.Institution & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IInstitutionReduxFormState;

    location: Location;
    institutionId: number;
    match: match<IInstitutionPageParams>;
    history: History;

    // From state
    institution: Dtos.Institution;
    lookups: Dtos.Lookup[];

    loadingInstitution: boolean;
    loadInstitutionSuccess: boolean;
    loadInstitutionFailure: boolean;

    savingInstitution: boolean;
    saveInstitutionSuccess: boolean;
    saveInstitutionFailure: boolean;

    creatingInstitution: boolean;
    createInstitutionSuccess: boolean;
    createInstitutionFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface IInstitutionPageActions {
    clearInstitution: InstitutionActions.IInstitutionClearActionCreator;
    loadInstitution: InstitutionActions.IInstitutionLoadByIdActionCreator;

    saveInstitution: InstitutionActions.IInstitutionSaveActionCreator;
    createInstitution: InstitutionActions.IInstitutionCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type InstitutionPageProps = IInstitutionPageProps & IInstitutionPageActions;


const reduxFormName: string = "reduxForms.institution";

class InstitutionPage extends React.PureComponent<InstitutionPageProps, any> {

    constructor(props: InstitutionPageProps) {
        super(props);

        this.clearInstitution = this.clearInstitution.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadInstitution,
            institutionId,
            createInstitution
        } = this.props;

        if (institutionId) {
            loadInstitution(institutionId);
        } else if (!institutionId) {
            createInstitution();
        }
    }

    componentDidUpdate(prevProps: InstitutionPageProps, prevState) {
        const {
            institution,
            institutionId,
            form,
            navigate,
            loadInstitution,
            loadingInstitution,
            loadInstitutionSuccess,
            createInstitution,
            creatingInstitution,
            createInstitutionSuccess,
            savingInstitution,
            saveInstitutionSuccess,
            validationFailures
        } = this.props;

        if (institutionId && prevProps.institutionId != institutionId) {
            loadInstitution(institutionId);
        }
        else if (!institutionId && prevProps.institutionId) {
            createInstitution();
        }

        if (!loadingInstitution && prevProps.loadingInstitution) {
            if (loadInstitutionSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingInstitution && prevProps.savingInstitution) {
            if (saveInstitutionSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/institutions")
                } else if (!form.saveAndReturn && institutionId && institutionId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !institutionId && institution && institution.id > 0) {
                    navigate("/institutions/" + institution.id + "/details");
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingInstitution && prevProps.creatingInstitution) {
            if (createInstitutionSuccess) {
                this.loadForm();
            }
            else {

            }
        }

    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearInstitution();
        loadForm(reduxFormName, initialInstitutionState);
        resetForm(reduxFormName);
    }

    clearInstitution() {
        this.props.clearInstitution();
    }

    showInstitution(): boolean {
        const {
            loadingInstitution,
            loadInstitutionSuccess
        } = this.props

        if (!loadingInstitution && loadInstitutionSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            institutionId,
            institution,
            loadingInstitution,
            permissions
        } = this.props;

        return <RestrictedLayout
            permission={Dtos.Permission.InstitutionView}
            subMenuTitle={institution ? institution.display : ""}
            subMenuItems={InstitutionSubMenu(institutionId, permissions)}
            loading={loadingInstitution}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingInstitution,
            permissions,
            institutionId,
            institution
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermissionFor(undefined, institutionId, permissions, Dtos.Permission.InstitutionUpdate, Dtos.Permission.InstitutionCreate);
        const formCodeDisabled: boolean = !(hasPermission && (FormHelper.isNew(formState, form) || institution == null || institution.code == null || institution.code == undefined || institution.code === ""));
        const formDisabled: boolean = !hasPermission || savingInstitution || (formState && formState.formStatus == Dtos.FormStatus.Disabled);
        const showContractingPartyAddress: boolean = (form && form.agreementAddressOptionId && form.agreementAddressOptionId == 3);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            detailsGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    name: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 1,
                    },
                    formerNames: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 1,
                    },
                    institutionTypeId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Institution", "InstitutionTypeId", lookups), form.institutionTypeId)
                        },
                        size: 2,
                    },
                    code: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formCodeDisabled,
                        size: 2,
                    },
                    siteLocationId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Institution", "SiteLocationId", lookups), form.siteLocationId)
                        },
                        size: 2,
                    },
                    facilityAllianceMemberId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Institution", "FacilityAllianceMemberId", lookups), form.facilityAllianceMemberId)
                        },
                        size: 2,
                    }
                },
            },
            addressGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    header: {
                        type: RegistrationComponent.RegistrationElement,
                        size: 1,
                        component: <div className="mt-3">
                            <h3>Address</h3>
                        </div>
                    },
                    physicalAddressHeading: {
                        type: RegistrationComponent.RegistrationElement,
                        size: 1,
                        component: <div className="mt-3">
                            <h5>Institution Physical Address</h5>
                        </div>
                    },
                    address: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 1,
                    },
                    address2: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 1,
                    },
                    suburb: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 2,
                    },
                    postcode: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 2,
                    },
                    stateId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Institution", "StateId", lookups), form.stateId)
                        },
                        size: 2,
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + '.state', null);
                            if (value == null || value != 10) {
                                changeForm(reduxFormName + parentModel + '.stateOther', null);
                            }
                        },
                    },
                    stateOther: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled || (form && form.stateId == null) || (form && form.stateId != 10),
                        size: 2,
                    },
                    countryId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Institution", "CountryId", lookups), form.countryId)
                        },
                        size: 2,
                    },
                    mailAddressHeading: {
                        type: RegistrationComponent.RegistrationElement,
                        size: 1,
                        component: <div className="mt-3">
                            <h5>Institution Mailing Address</h5>
                        </div>
                    },
                    mailAddress: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 1
                    },
                    mailAddress2: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 1
                    },
                    mailSuburb: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    mailPostcode: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    mailStateId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Institution", "MailStateId", lookups), form.mailStateId)
                        },
                        size: 2,
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + '.mailState', null);
                            if (value == null || value != 10) {
                                changeForm(reduxFormName + parentModel + '.mailStateOther', null);
                            }
                        },
                    },
                    mailStateOther: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled || (form && form.mailStateId == null) || (form && form.mailStateId != 10),
                        size: 2,
                    },
                    mailCountryId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Institution", "MailCountryId", lookups), form.mailCountryId)
                        },
                        size: 2
                    },
                    otherHeading: {
                        type: RegistrationComponent.RegistrationElement,
                        size: 1,
                        component: <div className="mt-3">
                            <h5>Other</h5>
                        </div>
                    },
                    phone: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    fax: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    website: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    }
                }
            },
            siteDetailsGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    header: {
                        type: RegistrationComponent.RegistrationElement,
                        size: 1,
                        component: <div className="mt-3">
                            <h3>Research Office Details</h3>
                        </div>
                    },
                    researchGovernanceOfficeName: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 1,
                    },
                    researchGovernanceOfficePhone: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 2,
                    },
                    researchGovernanceOfficeEmail: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 2,
                    },
                    researchGovernanceOfficeWebsite: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 2,
                    },
                    researchGovernanceOfficeIsNMAScheme: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Institution", "ResearchGovernanceOfficeIsNMAScheme", lookups), form.researchGovernanceOfficeIsNMAScheme)
                        },
                        size: 2
                    }
                }
            },
            contractingPartyAgreements: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    header: {
                        type: RegistrationComponent.RegistrationElement,
                        size: 1,
                        component: <div className="mt-3">
                            <h3>Contracting Party for Agreements</h3>
                        </div>
                    },
                    legalName: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 1
                    },
                    agreementAddressOptionId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Institution", "AgreementAddressOptionId", lookups), form.agreementAddressOptionId)
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + '.addressOption', null);
                        },
                        size: 1
                    },
                    contractingPartyAddress: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        hide: !showContractingPartyAddress,
                        size: 1
                    },
                    contractingPartyAddress2: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        hide: !showContractingPartyAddress,
                        size: 1
                    },
                    contractingPartySuburb: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        hide: !showContractingPartyAddress,
                        size: 2
                    },
                    contractingPartyPostcode: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        hide: !showContractingPartyAddress,
                        size: 2
                    },
                    contractingPartyStateId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        hide: !showContractingPartyAddress,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Institution", "ContractingPartyStateId", lookups), form.contractingPartyStateId)
                        },
                        size: 2,
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + '.contractingPartyState', null);
                            if (value == null || value != 10) {
                                changeForm(reduxFormName + parentModel + '.contractingPartyStateOther', null);
                            }
                        },
                    },
                    contractingPartyStateOther: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        hide: !showContractingPartyAddress,
                        inputDisabled: formDisabled || (form && form.contractingPartyStateId == null) || (form && form.contractingPartyStateId != 10),
                        size: 2,
                    },
                    contractingPartyCountryId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        hide: !showContractingPartyAddress,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Institution", "ContractingPartyCountryId", lookups), form.contractingPartyCountryId)
                        },
                        size: 2
                    },
                    countrySpace: {
                        type: RegistrationComponent.RegistrationElement,
                        hide: !showContractingPartyAddress,
                        size: 2,
                        component: <></>
                    },
                    abn: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    acn: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    acceptElectronicSignature: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Institution", "AcceptElectronicSignature", lookups), form.acceptElectronicSignature)
                        },
                        size: 2
                    },
                }
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
                labelClassName: "col-form-label col-sm-3",
                fieldClassName: "col-sm-9 generic-form-field",
            },
            documentsHeading: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="border-0 d-flex pt-4 pl-0">
                    <h3>Documents</h3>
                </div>
            },
            sourceDocuments: {
                type: RegistrationComponent.RegistrationSourceDocuments,
                label: " ",
                disabled: formDisabled,
                components: {
                    sourceDocuments: {
                        type: RegistrationComponent.RegistrationGrid,
                        formName: "SourceDocument",
                        disabled: formDisabled,
                        label: "Add Source Document",
                        className: "source-documents-source-documents",
                        minRows: 0,
                        columnStyles: [
                            {
                                width: undefined,
                                minWidth: "200px"
                            },
                            {
                                width: "23%",
                                minWidth: "150px"
                            },
                            {
                                width: "20%",
                                minWidth: "150px"
                            },
                            {
                                width: "125px"
                            },
                        ],
                        removeConfig: GridRemove.All,
                        addRow: (gridComponent, index, array) => {
                            const state: Dtos.SourceDocument = Object.assign(
                                { ...gridComponent.initialState }
                            );
                            return state;
                        },
                        components: {
                            name: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: TextFormInput,
                                inputDisabled: formDisabled,
                                inputLabel: getFormPropertyLabel("CollectionInstance", "FileName", formProperties),
                                inputProps: {
                                    feedbackIcon: FontAwesomeIcons.Solid.BAN
                                },
                                validators: {
                                    required: requiredText
                                },
                                errors: {
                                    required: "File name is required."
                                },
                                errorComponent: ValidationMessage

                            },
                            fileType: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: SelectFormInput,
                                inputLabel: getFormPropertyLabel("CollectionInstance", "FileType", formProperties),
                                inputProps: {
                                    options: generateOptionsFromLookup(findLookup("SourceDocument", "FileType", lookups)),
                                    feedbackIcon: FontAwesomeIcons.Solid.BAN
                                },
                                validators: {
                                    required: value => value != undefined
                                },
                                errors: {
                                    required: "File type is required."
                                },
                                errorComponent: ValidationMessage
                            },
                            fileInformation: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: FileUploadFormInput,
                                inputDisabled: formDisabled,
                                inputLabel: getFormPropertyLabel("CollectionInstance", "Upload", formProperties),
                                changeAction: (model, value, form, parentModel) => {
                                    changeForm(model, value);
                                    const inputValue: Dtos.TemporaryFileInformation = value;
                                    const sourceDocument: Dtos.SourceDocument = form;

                                    if (sourceDocument.fileInformation &&
                                        inputValue &&
                                        sourceDocument.fileInformation.state != inputValue.state &&
                                        inputValue.state == Dtos.FileState.Success &&
                                        !sourceDocument.name) {
                                        changeForm(reduxFormName + parentModel + ".name", inputValue.temporaryFile.name.substr(0, inputValue.temporaryFile.name.lastIndexOf(inputValue.temporaryFile.extension)));
                                    }
                                },
                                inputProps: {
                                    render: SOURCE_DOCUMENT_UPLOAD_VIEW
                                },
                                validators: {
                                    required: requiredSourceDocument
                                },
                                errors: {
                                    required: "File must be uploaded."
                                },
                                errorComponent: ValidationMessage
                            },
                            fileConfiguration: {
                                type: RegistrationComponent.RegistrationGridElement,
                                label: " ",
                                render: SOURCE_DOCUMENT_FILE_CONFIGURATION
                            }
                        },
                        initialState: { ...initialSourceDocumentState }
                    },
                },
                keyReplace: "sourceDocuments",
                onRemove: (model: string) => {
                    console.log('onRemove', model);
                    changeForm(model + "active", false);
                    return true; // this was handled here
                },
                filterRow: (data: Dtos.SourceDocument) => {
                    console.info('filterRow', data);
                    if (data.active != true) {
                        return false
                    }
                    return true;
                }
            },
        };



        return <div>
            <h1>Institution</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "institution", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderControl(formControl) {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            changeForm
        } = this.props;

        return renderFormControls(
            form,
            "institution",
            formControl,
            lookups,
            validationFailures,
            formProperties,
            changeForm)
    }

    renderErrorAlert(): any {

        return <div>
            The form was <strong> not </strong> saved.
        </div>
    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.Institution) {
        const {
            saveInstitution
        } = this.props;

        if (data) {
            let changes: Dtos.Institution = {} as Dtos.Institution;
            saveInstitution(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.Institution) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }


    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/institutions");
    }

    loadForm() {
        const {
            loadForm,
            institution,
        } = this.props

        let extraData: Dtos.Institution = {} as Dtos.Institution;

        loadForm(reduxFormName, Object.assign({ ...institution }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: InstitutionPageProps): IInstitutionPageProps => {

    let institution: Dtos.Institution | undefined = !(state.institutions.formData instanceof Array) ? state.institutions.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        institutionId: ownProps.match && ownProps.match.params.institutionId && ownProps.match.params.institutionId.toString() != "create" ? ownProps.match.params.institutionId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        institution: institution!,

        loadingInstitution: state.institutions.loadState && state.institutions.loadState.status == RequestState.Pending,
        loadInstitutionSuccess: state.institutions.loadState && state.institutions.loadState.status == RequestState.Success,
        loadInstitutionFailure: state.institutions.loadState && state.institutions.loadState.status == RequestState.Failure,

        savingInstitution: state.institutions.saveState && state.institutions.saveState.status == RequestState.Pending,
        saveInstitutionSuccess: state.institutions.saveState && state.institutions.saveState.status == RequestState.Success,
        saveInstitutionFailure: state.institutions.saveState && state.institutions.saveState.status == RequestState.Failure,

        creatingInstitution: state.institutions.createState && state.institutions.createState.status == RequestState.Pending,
        createInstitutionSuccess: state.institutions.createState && state.institutions.createState.status == RequestState.Success,
        createInstitutionFailure: state.institutions.createState && state.institutions.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: state.institutions.lookups,

        form: state.reduxForms.institution,
        formState: state.institutions.formState,
        formProperties: state.institutions.formProperties,

        reduxFormState: state.reduxForms.formState.institution,
        validationFailures: institution ?
            state.institutions.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): IInstitutionPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadInstitution: bindActionCreators(InstitutionActions.LoadInstitutionById, dispatch),
        clearInstitution: bindActionCreators(InstitutionActions.Clear, dispatch),

        saveInstitution: bindActionCreators(InstitutionActions.SaveInstitution, dispatch),
        createInstitution: bindActionCreators(InstitutionActions.CreateInstitution, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(InstitutionPage);
