import * as React from 'react';
import './PersonnelActiveDirectoryPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";
import { IPersonnelReduxFormState, initialPersonnelState } from "../../../reducers/reactReduxForms/personnel";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as PersonnelActions from '../../../actions/personnel';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { Form, actions, Control } from 'react-redux-form';
import { SettingsSubMenu, PersonnelSubMenu } from '../../../helpers/subMenuHelper';

interface IPersonnelActiveDirectoryPageParams {
    personnelId: number;
}

interface IPersonnelActiveDirectoryPageProps {

    form: Dtos.Personnel & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IPersonnelReduxFormState;

    location: Location;
    personnelId: number;
    match: match<IPersonnelActiveDirectoryPageParams>;
    history: History;

    // From state
    personnel: Dtos.Personnel;
    lookups: Dtos.Lookup[];

    loadingPersonnel: boolean;
    loadPersonneluccess: boolean;
    loadPersonnelFailure: boolean;

    savingPersonnel: boolean;
    savePersonneluccess: boolean;
    savePersonnelFailure: boolean;

    resending: boolean;
    resendSuccess: boolean;
    resendFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[]
    formRoles: Dtos.RoleType[]
}

interface IPersonnelActiveDirectoryPageActions {
    clearPersonnel: PersonnelActions.IPersonnelClearActionCreator;
    loadPersonnel: PersonnelActions.IPersonnelSecurityLoadByIdActionCreator;

    savePersonnel: PersonnelActions.IPersonnelSaveActiveDirectoryActionCreator;
    resend: PersonnelActions.IPersonnelResendEmailActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type PersonnelActiveDirectoryPageProps = IPersonnelActiveDirectoryPageProps & IPersonnelActiveDirectoryPageActions;


const formName: string = "personnel";
const reduxFormName: string = "reduxForms.personnel";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class PersonnelActiveDirectoryPage extends React.PureComponent<PersonnelActiveDirectoryPageProps, any> {

    constructor(props: PersonnelActiveDirectoryPageProps) {
        super(props);
        this.state = {
            changeEmail: false
        };

        this.clearPersonnel = this.clearPersonnel.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
        this.resetLogin = this.resetLogin.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
    }

    componentDidMount() {
        const {
            loadPersonnel,
            navigate,
            navigateReplace,
            personnelId
        } = this.props;

        if (personnelId) {
            loadPersonnel(personnelId);
        }
    }

    componentWillReceiveProps(nextProps: PersonnelActiveDirectoryPageProps) {
    }

    componentDidUpdate(prevProps: PersonnelActiveDirectoryPageProps, prevState) {
        const {
            navigateReplace,
            personnel,
            personnelId,
            form,
            navigate,
            loadPersonnel,
            loadingPersonnel,
            loadPersonneluccess,
            savingPersonnel,
            savePersonneluccess,
            savePersonnelFailure,
            validationFailures,
            resending,
            resendSuccess,
            resendFailure
        } = this.props;

        if (personnelId && prevProps.personnelId != personnelId) {
            loadPersonnel(personnelId);
            this.setState({ changeEmail: false });
        }
        else if (!personnelId && prevProps.personnelId) {
            navigate("/personnel");
        }

        if (!loadingPersonnel && prevProps.loadingPersonnel) {
            if (loadPersonneluccess) {
                this.loadForm();
                this.setState({ changeEmail: false });
            }
            else {

            }
        }


        if (!savingPersonnel && prevProps.savingPersonnel) {
            if (savePersonneluccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/personnel")
                } else if (!form.saveAndReturn && personnelId && personnelId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !personnelId && personnel && personnel.id > 0) {
                    navigate("/personnel/" + personnel.id + "/details");
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!resending && prevProps.resending) {
            if (resendSuccess) {
                Alert.success(<NotificationAlert
                    alertContent={<div>Email sent</div>}
                    icon={FontAwesomeIcons.Solid.CHECK}
                />);
            }
            if (resendFailure) {
                Alert.error(
                    <NotificationAlert
                        alertContent={<div>The personnel trial role assigned was <strong> not </strong> sent</div>}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }
    }

    componentWillUpdate(nextProps: PersonnelActiveDirectoryPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearPersonnel();
        loadForm(reduxFormName, initialPersonnelState);
        resetForm(reduxFormName);
    }

    clearPersonnel() {
        this.props.clearPersonnel();
    }

    showPersonnel(): boolean {
        const {
            loadingPersonnel,
            loadPersonneluccess
        } = this.props

        if (!loadingPersonnel && loadPersonneluccess) {
            return true;
        }

        return false;
    }

    resetLogin() {
        const {
            changeForm
        } = this.props;

        changeForm(reduxFormName + ".userName", null);
        changeForm(reduxFormName + ".email", null);
        changeForm(reduxFormName + ".activeDirectoryUser", null);
    }

    changeEmail() {
        const {
            changeForm
        } = this.props;
        this.setState({ changeEmail: true });
        changeForm(reduxFormName + ".email", null);
    }

    render() {

        const {
            form,
            loadingPersonnel,
            personnelId,
            personnel,
            formRoles,
            permissions
        } = this.props;

        return <RestrictedLayout
            permission={Dtos.Permission.PersonnelSecurityView}
            subMenuTitle={personnel ? personnel.display : ""}
            subMenuItems={personnelId == undefined ? undefined : PersonnelSubMenu(personnelId, permissions)}
            loading={loadingPersonnel}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            resending,
            resend,
            savingPersonnel,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.PersonnelSecurityUpdate, Dtos.Permission.PersonnelSecurityCreate);
        const canEdit: boolean = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.PersonnelSecurityUpdate);
        const canCreate: boolean = AuthenticationHelper.hasPermission(permissions, Dtos.Permission.PersonnelSecurityCreate);
        const formDisabled: boolean = !hasPermission || savingPersonnel || (formState && formState.formStatus == Dtos.FormStatus.Disabled);
        let activeDirectoryError: string = "";
        if (validationFailures) {
            validationFailures.forEach((validationFailure) => {
                if (validationFailure.errorCode === "AD-099") {
                    activeDirectoryError = validationFailure.message
                }
            });
        }
        const activeDirectoryLock: boolean = !canEdit || (form !== undefined && form.activeDirectoryUser !== undefined && form.activeDirectoryUser !== null && form.activeDirectoryUser != "");

        let emailDisabled = activeDirectoryLock;
        if (this.state.changeEmail) {
            emailDisabled = false;
        }
        let canSave = false;
        if (this.state.changeEmail) {
            canSave = true;
        } else if ((form.activeDirectoryUser || "").length == 0) {
            canSave = true;
        }

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            userName: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled || activeDirectoryLock,
            },
            email: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled || emailDisabled,
            },
            activeDirectoryError: {
                type: RegistrationComponent.RegistrationElement,
                component: activeDirectoryError ? <div className="form-group row pt-2 pb-0 border border-bottom-1 border-top-0">
                    <div className="col-md-12 mt-2">
                        <div className="alert alert-danger d-flex">
                            <div className="mr-2">
                                <FontAwesomeIcon icon={FontAwesomeIcons.Solid.EXCLAMATION_TRIANGLE} fixedWidth />
                            </div>
                            <span>{activeDirectoryError}</span>
                        </div>
                    </div>
                </div> : <>
                </>
            },
            activeDirectoryUser: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: true,
            },
        };

        return <div>
            <h1>Personnel Login & Security</h1>
            {(false && !form.hasActiveDirectoryEntry) && (
                <div className="alert alert-info d-flex">
                    <div className="mr-2">
                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                    </div>
                    <span>This personnel does not have an Active Directory record that is required to access this site and registration websites. If the user has a valid username and email then press submit to create one.</span>
                </div>
            )}
            {form.activeDirectoryDisabled && (
                <div className="alert alert-info d-flex">
                    <div className="mr-2">
                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                    </div>
                    <span>This Active Directory record that is required to access this site and registration websites is disabled. Click the Re-submit button to enable this record.</span>
                </div>
            )}
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "personnel", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    {canEdit && (<button
                        disabled={!activeDirectoryLock}
                        onClick={
                            () => {
                                this.resetLogin();
                            }
                        }
                        type="button"
                        className="btn btn-warning mr-2"
                    >
                        Change Username and Email
                    </button>)}
                    {canEdit && (<button
                        disabled={!activeDirectoryLock}
                        onClick={
                            () => {
                                this.changeEmail();
                            }
                        }
                        type="button"
                        className="btn btn-warning mr-2"
                    >
                        Change Email
                    </button>)}
                    <button disabled={!form.active || !form.hasActiveDirectoryEntry || resending || formDisabled}
                        onClick={
                            () => {
                                resend(form.id);
                            }
                        }
                        type="button"
                        className="btn btn-warning mr-2"
                    >
                        Re-send Email
                    </button>
                    {(canEdit && form.hasActiveDirectoryEntry) && (
                        <button disabled={formDisabled || canSave}
                            onClick={
                                () => {
                                    this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                                }
                            }
                            type="submit"
                            className="btn btn-primary mr-2"
                        >
                            Re-Submit
                        </button>
                    )}
                    {(canEdit && form.hasActiveDirectoryEntry) && (
                        <button disabled={formDisabled || !canSave}
                            onClick={
                                () => {
                                    this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                                }
                            }
                            type="submit"
                            className="btn btn-primary mr-2"
                        >
                            Submit
                        </button>
                    )}
                    {(canCreate && !form.hasActiveDirectoryEntry) && (
                        <button disabled={formDisabled || !canSave}
                            onClick={
                                () => {
                                    this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                                }
                            }
                            type="submit"
                            className="btn btn-primary mr-2"
                        >
                            Create Login
                        </button>
                    )}

                    {(canEdit && form.hasActiveDirectoryEntry) && (
                        <button disabled={formDisabled || !canSave}
                            onClick={() => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                            }}
                            type="submit"
                            className="btn btn-primary mr-2"
                        >
                            Submit And Return
                        </button>
                    )}
                    {(canCreate && !form.hasActiveDirectoryEntry) && (
                        <button disabled={formDisabled || !canSave}
                            onClick={() => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                            }}
                            type="submit"
                            className="btn btn-primary mr-2"
                        >
                            Create Login And Return
                        </button>
                    )}

                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
        </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.Personnel) {
        const {
            savePersonnel
        } = this.props;

        if (data) {
            let changes: Dtos.Personnel = {} as Dtos.Personnel;
            savePersonnel(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.Personnel) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/personnel");
    }

    loadForm() {
        const {
            loadForm,
            personnel,
        } = this.props

        let extraData: Dtos.Personnel = {} as Dtos.Personnel;

        loadForm(reduxFormName, Object.assign({ ...personnel }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: PersonnelActiveDirectoryPageProps): IPersonnelActiveDirectoryPageProps => {

    let personnel: Dtos.Personnel | undefined = !(state.personnel.formData instanceof Array) ? state.personnel.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        personnelId: ownProps.match && ownProps.match.params.personnelId && ownProps.match.params.personnelId.toString() != "create" ? ownProps.match.params.personnelId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        personnel: personnel!,

        loadingPersonnel: state.personnel.loadState && state.personnel.loadState.status == RequestState.Pending,
        loadPersonneluccess: state.personnel.loadState && state.personnel.loadState.status == RequestState.Success,
        loadPersonnelFailure: state.personnel.loadState && state.personnel.loadState.status == RequestState.Failure,

        savingPersonnel: state.personnel.saveState && state.personnel.saveState.status == RequestState.Pending,
        savePersonneluccess: state.personnel.saveState && state.personnel.saveState.status == RequestState.Success,
        savePersonnelFailure: state.personnel.saveState && state.personnel.saveState.status == RequestState.Failure,

        resending: state.personnel.resendingState && state.personnel.resendingState.status == RequestState.Pending,
        resendSuccess: state.personnel.resendingState && state.personnel.resendingState.status == RequestState.Success,
        resendFailure: state.personnel.resendingState && state.personnel.resendingState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles,
        lookups: [],

        form: state.reduxForms.personnel,
        formState: state.personnel.formState,
        formProperties: state.personnel.formProperties,

        reduxFormState: state.reduxForms.formState.personnel,
        validationFailures: personnel ?
            state.personnel.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): IPersonnelActiveDirectoryPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),
        resend: bindActionCreators(PersonnelActions.ResendEmail, dispatch),

        loadPersonnel: bindActionCreators(PersonnelActions.LoadPersonnelSecurityById, dispatch),
        clearPersonnel: bindActionCreators(PersonnelActions.Clear, dispatch),

        savePersonnel: bindActionCreators(PersonnelActions.SaveActiveDirectory, dispatch)

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(PersonnelActiveDirectoryPage);
