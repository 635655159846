import * as React from "react";
import * as classNames from "classnames";
import { IBaseFormInputProps } from '../';

import './DatePickerFormInput.scss';

import { FontAwesomeIcons, FontAwesomeIcon } from "../../../../constants/fontAwesomeIcons";

import { DateRangePicker, SingleDatePicker, DayPickerRangeController, } from 'react-dates';
import * as Moment from 'moment';
import { DEFAULT_DATE_FORMAT } from "../../../../constants/dateFormats";

export interface IDatePickerFormInputProps {
    displayFormat?: string;
    numberOfMonths?: number;
    isOutsideRange?: boolean;
    hideShortcuts?: boolean;
    customInputIcon?: any // font awesome icon
    feedbackIcon?: string
}

export interface IDatePickerFormInputState {
    resetting: boolean;
}

//"yyyy'-'MM'-'dd'T'HH':'mm':'ss'.'fffffff'Z'"

const dateFormat: string = "YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[.]SSSSSSS[Z]";

class DatePickerFormInput extends React.Component<IDatePickerFormInputProps & IBaseFormInputProps, IDatePickerFormInputState> {
    // @ts-ignore
    private container: HTMLDivElement;

    constructor(props) {
        super(props)

        this.onDateChange = this.onDateChange.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);

        this.state = { resetting: false };


    }

    componentDidUpdate(prevProps: IDatePickerFormInputProps & IBaseFormInputProps) {

        const {
            value,
            onChange,
            focused,
            name
        } = this.props

        const {
            resetting
        } = this.state;

        if (this.container && !focused) {
            let inputNodes = this.container.getElementsByTagName("input");
            if (inputNodes && inputNodes.length > 0) {
                Array.from(inputNodes).forEach(node => {
                    if (node.className.indexOf("DateInput_input") >= 0) {

                        let inputValue: string = node.value
                        if (inputValue && !value) {
                            onChange(Moment.default().format(dateFormat));
                            this.setState({ resetting: true });
                        }
                    }

                });
            }
        }

        if (resetting) {
            onChange(null);
            this.setState({ resetting: false });
        }
    }

    render() {

        const {
            valid,
            invalid,
            className,
            name,
            onBlur,
            onFocus,
            value,
            disabled,
            focused,
            displayFormat,
            numberOfMonths,
            isOutsideRange,
            hideShortcuts,
            customInputIcon,
            feedbackIcon
        } = this.props



        const derivedDisplayFormat = displayFormat ? displayFormat : DEFAULT_DATE_FORMAT;
        const derivedClassName = className ? className : undefined;
        const derivedHideShortcuts = hideShortcuts != undefined ? hideShortcuts : true;
        const derivedNumberOfMonths = numberOfMonths ? numberOfMonths : 1
        
        const date = value ? Moment.default(value) : undefined;

        /* ref={thisInput => (this.textInput = thisInput!)} */

        return (
            <div ref={ref => (this.container = ref!)} className={classNames(
                "form-date-group",
                { "focused": focused },
                { "is-invalid": invalid && !disabled }
            )}>
                <SingleDatePicker

                    date={date}
                    onDateChange={this.onDateChange} // PropTypes.func.isRequired
                    focused={focused} // PropTypes.bool
                    onFocusChange={this.onFocusChange}
                    displayFormat={derivedDisplayFormat}
                    numberOfMonths={derivedNumberOfMonths}
                    isOutsideRange={isOutsideRange}
                    hideKeyboardShortcutsPanel={derivedHideShortcuts}
                    customInputIcon={customInputIcon}
                    disabled={disabled}
                    readOnly={false}
                />
                {
                    invalid && !disabled ?
                        <span className={classNames("form-control-feedback pr-2")}>
                            <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE} fixedWidth />
                        </span> :
                        null
                }
            </div>
        );
    }


    onDateChange(val: any) {
        if (!val) {
            this.props.onChange(null);
        }
        else {

            this.props.onChange(val.format(dateFormat));
        }
    }

    onFocusChange(event: { focused: boolean }) {

        const {
            onFocus,
            onBlur,
            onChange,
            value

        } = this.props

        if (event.focused && onFocus) {
            
            onFocus();
        }
        else if(onBlur) {
            onBlur();
        }
    }
}

export default DatePickerFormInput;