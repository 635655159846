import * as React from 'react';
import './HubspotActiveMembersPage.scss';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import Alert from 'react-s-alert';
import { Link } from "../../../components/routing/index";
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu, TruncatedCell } from "../../../components/layouts/index";
import * as HubspotActions from '../../../actions/hubspot';
import * as PagingActions from '../../../actions/pages/pagingPage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { InfoPanel, Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { TextAreaFormInput, FormFilterInput, SearchInput, CheckboxFormInput } from '../../../components/form';
import { SettingsSubMenu } from '../../../helpers/subMenuHelper';
import EmailSearchInput from './EmailSearchInput';
import { HubspotSettingsLayout } from '../../index';

interface IHubspotActiveMembersPageProps {
    location: Location;
    history: History;

    existing: Dtos.EmailUsage;
    emailUpdateOptions: Dtos.EmailUpdateOptions;

    loading: boolean;
    loadSuccess: boolean;
    loadFailure: boolean;
    loadErrorMessage: string;

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface IHubspotActiveMembersPageActions {
    clear: HubspotActions.IHubspotClearActionCreator;
    load: HubspotActions.IHubspotFindContactActionCreator;
    update: HubspotActions.IHubspotSetEmailUpdateOptionsActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

}

type HubspotActiveMembersPageProps = IHubspotActiveMembersPageProps & IHubspotActiveMembersPageActions;

class HubspotActiveMembersPage extends React.PureComponent<HubspotActiveMembersPageProps, any> {

    constructor(props: HubspotActiveMembersPageProps) {
        super(props);
        this.clear = this.clear.bind(this);
        this.find = this.find.bind(this);
        this.state = {
            email: ""
        }
    }

    componentDidMount() {
        const {
            clear
        } = this.props;
        clear();
    }

    componentDidUpdate(prevProps: HubspotActiveMembersPageProps, prevState) {
        const {
            loading,
            loadFailure,
            loadErrorMessage
        } = this.props;

        if (!loading && prevProps.loading) {
            if (loadFailure) {
                if (loadErrorMessage && loadErrorMessage.length > 0) {
                    Alert.error(
                        <NotificationAlert
                            alertContent={loadErrorMessage}
                            icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                        />
                    );
                }
            }
        }
    }

    componentWillUpdate(nextProps: HubspotActiveMembersPageProps) {
    }

    componentWillUnmount() {
        this.clear();
    }

    clear() {
        this.props.clear();
    }

    find() {
        this.props.load(this.state.email, "", "", this.props.emailUpdateOptions);
    }

    render() {

        const {
            loading,
            permissions
        } = this.props;

        return <HubspotSettingsLayout
            permission={Dtos.Permission.HubspotContactExportView}
            loading={loading}
        >
            {this.renderContent()}
        </HubspotSettingsLayout>;
    }

    renderContent() {
        const {
            navigate,
            permissions,
            loading,
            existing,
            emailUpdateOptions,
        } = this.props;
        let self = this;

        let contact;
        let userLogin;
        let personnel;
        let personnels;

        if (existing) {
            contact = existing.contact;
            userLogin = existing.userLogin;
            personnel = existing.personnel;
            personnels = existing.emailMatchedPersonnels;
        }


        return <div>
            <div className="row">
                <div className={"col mb-2"}>
                    <h2>Hubspot Active Member</h2>
                </div>
                <div className="col-auto" style={{ textAlign: 'right' }}>
                    <div className="row">
                        <div className="col-auto">
                            <EmailSearchInput email1={this.state.email} onSearch={(email1) => self.setState({ email: email1 }, () => this.find())} />
                        </div>
                    </div>
                </div>
            </div>
            {
                loading ?
                    "Loading Contact" : <div className="row">
                        {(existing && existing.email != '') ?
                            <div className="col">
                                <div className="row mt-3">
                                    <div className="col">
                                        {contact ? <>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="text-center"><b>{this.getHubpotDataValue(contact.data, "First Name")} {this.getHubpotDataValue(contact.data, "Last Name")}</b> was found in
                                                        &nbsp;<b>Hubspot</b> with the contact type of <b>{this.getHubpotDataValue(contact.data, "Contact type")}</b> and
                                                        the membership status of <b>{this.getHubpotDataValue(contact.data, "Membership status")}</b>
                                                    </p>
                                                </div>
                                            </div>
                                        </> : <div className="row">
                                            <div className="col">
                                                <p className="text-center">Email not found in Hubspot</p>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {userLogin ? <>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="text-center">
                                                        Username <b>{userLogin.activeDirectoryUserName}</b> was found linked to an&nbsp;
                                                        <b>{userLogin.activeDirectoryActive ? "active" : "disabled"}</b> login account with the membership <b>{userLogin.activeDirectoryMemberships}</b>
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col">
                                                    <p className="text-center">
                                                        The welcome email {existing.welcomeEmailSentDate ? <>was send on <b>{existing.welcomeEmailSentDate}</b></> : "has not been sent" }
                                                    </p>
                                                </div>
                                            </div>

                                            {contact && (<div className="row">
                                                <div className="col">
                                                </div>
                                                <div className="col-auto">
                                                    <button className="btn btn-warning mr-2 btn-sm" type="button" onClick={() => this.props.load(existing.email, "Update", "", this.props.emailUpdateOptions)}>Update account from Hubspot contact</button>
                                                    <button className="btn btn-warning btn-sm" type="button" onClick={() => this.props.load(existing.email, "Email", "", this.props.emailUpdateOptions)}>Resend Welcome Email</button>
                                                </div>
                                                <div className="col">
                                                </div>
                                            </div>)}
                                        </> : <> <div className="row">
                                            <div className="col">
                                                <p className="text-center">Login account not found</p>
                                            </div>
                                        </div>
                                            {contact && (<div className="row">
                                                <div className="col">
                                                </div>
                                                <div className="col-auto">
                                                        <button className="btn btn-warning btn-sm" type="button" onClick={() => this.props.load(existing.email, "Create", this.state.email2, this.props.emailUpdateOptions)}>Create account from Hubspot contact</button>
                                                </div>
                                                <div className="col">
                                                </div>
                                            </div>)}
                                        </>}
                                    </div>
                                </div>
                            </div> : <div className="col">
                                <p className="text-center p-3">Search for a user by email</p>
                            </div>}
                    </div>
            }
        </div>
    }

    getHubpotDataValue(values, name) {
        var value = values.find(f => f.name == name);
        console.log(values, name, value);
        return value ? value.value : "";
    }

}

const mapStateToProps = (state: ITmdState, ownProps: HubspotActiveMembersPageProps): IHubspotActiveMembersPageProps => {

    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {
        history: ownProps.history,
        location: state.routing.location,

        existing: state.hubspot.existing,
        emailUpdateOptions: state.hubspot.emailUpdateOptions,

        loading: state.hubspot.loadState && state.hubspot.loadState.status == RequestState.Pending,
        loadSuccess: state.hubspot.loadState && state.hubspot.loadState.status == RequestState.Success,
        loadFailure: state.hubspot.loadState && state.hubspot.loadState.status == RequestState.Failure,
        loadErrorMessage: state.hubspot.loadState.errorMessage,

        user: state.user.data,
        permissions: permissions
    };
};

const mapDispatchToProps = (dispatch): IHubspotActiveMembersPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        load: bindActionCreators(HubspotActions.HubspotFindContact, dispatch),
        clear: bindActionCreators(HubspotActions.Clear, dispatch),
        update: bindActionCreators(HubspotActions.HubspotSetEmailUpdateOptions, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(HubspotActiveMembersPage);
