import * as React from 'react';
import * as classNames from "classnames";
import './TrialInstitutionPatientsPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactSelectClass from "react-select";
import ReactTable, { Column, RowInfo } from 'react-table';
import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";
import * as TrialInstitutionActions from '../../../actions/trialInstitution';
import * as TrialActions from '../../../actions/trial';
import * as PatientActions from '../../../actions/patient';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert, Placeholder } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../../components/form';
import { TrialSubMenu, InstitutionTrialSubMenu } from '../../../helpers/subMenuHelper';
import { TrialInstitutionLayout } from '../../index';

interface ITrialInstitutionPatientsPageParams {
    trialId: number;
    trialInstitutionId: number;
}

interface ITrialInstitutionPatientsPageProps {

    trialId: number;
    trialInstitutionId: number;
    trialInstitution: Dtos.TrialInstitution;
    patients: Dtos.Patient[],

    location: Location;
    match: match<ITrialInstitutionPatientsPageParams>;
    history: History;

    loadingTrialInstitution: boolean;
    loadTrialInstitutionSuccess: boolean;
    loadTrialInstitutionFailure: boolean;

    loadingPatients: boolean;
    loadPatientsSuccess: boolean;
    loadPatientsFailure: boolean;

    importingPatients: boolean;
    importPatientsSuccess: boolean;
    importPatientsFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface ITrialInstitutionPatientsPageActions {
    clearPatients: PatientActions.IPatientClearActionCreator;
    loadPatients: PatientActions.IPatientLoadActionCreator;

    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    clearTrialInstitution: TrialInstitutionActions.ITrialInstitutionClearActionCreator;
    loadTrialInstitution: TrialInstitutionActions.ITrialInstitutionLoadByIdActionCreator;

    importPatients: PatientActions.IPatientImportActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type TrialInstitutionPatientsPageProps = ITrialInstitutionPatientsPageProps & ITrialInstitutionPatientsPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class TrialInstitutionPatientsPage extends React.PureComponent<TrialInstitutionPatientsPageProps, any> {

    constructor(props: TrialInstitutionPatientsPageProps) {
        super(props);

        this.clearPatients = this.clearPatients.bind(this);
    }

    componentDidMount() {
        const {
            loadTrial,
            loadTrialInstitution,
            clearPatients,
            navigate,
            navigateReplace,
            trialId,
            trialInstitutionId
        } = this.props;

        clearPatients();
        loadTrialInstitution(trialInstitutionId);
        loadTrial(trialId);
    }

    componentWillReceiveProps(nextProps: TrialInstitutionPatientsPageProps) {
    }

    componentDidUpdate(prevProps: TrialInstitutionPatientsPageProps, prevState) {
        const {
            loadingTrialInstitution,
            trialInstitution,
            importingPatients,
            importPatientsSuccess,
            loadPatients
        } = this.props

        if (prevProps.loadingTrialInstitution && !loadingTrialInstitution && trialInstitution) {
            loadPatients(trialInstitution.trialId, trialInstitution.institutionId);
        }

        if (prevProps.importingPatients && !importingPatients && importPatientsSuccess && trialInstitution) {
            loadPatients(trialInstitution.trialId, trialInstitution.institutionId);
        }
    }

    componentWillUpdate(nextProps: TrialInstitutionPatientsPageProps) {
    }

    componentWillUnmount() {
        this.clearPatients();
    }

    clearPatients() {
        this.props.clearTrial();
        this.props.clearTrialInstitution();
        this.props.clearPatients();
    }

    showPatients(): boolean {
        const {
            loadingTrialInstitution,
            loadTrialInstitutionSuccess,
            loadingPatients,
            loadPatientsSuccess
        } = this.props

        if (!loadingTrialInstitution && loadTrialInstitutionSuccess && !loadingPatients && loadPatientsSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrialInstitution,
            loadingPatients,
        } = this.props;

        return <TrialInstitutionLayout
            loading={loadingTrialInstitution || loadingPatients}
            permission={Dtos.Permission.TrialPatientView}>
            {this.renderContent()}
        </TrialInstitutionLayout>;
    }

    renderContent() {
        const {
            trialInstitution,
            importingPatients,
            importPatients,
            permissions
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-9 mb-2"}>
                    <h2>Trial Institution Participants</h2>
                </div>
                <div className="col-3" style={{ textAlign: 'right' }}>
                    {false && trialInstitution && trialInstitution.trialId > 0 && (
                        <button
                            className={"btn btn-warning"}
                            onClick={() => importPatients(trialInstitution.trialId)}
                            disabled={importingPatients}>
                            {importingPatients ? "Importing..." : "Import"}
                        </button>
                    )}
                    {(trialInstitution && trialInstitution.trialId > 0 &&
                        AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialPatientCreate, trialInstitution.trialId, trialInstitution.institutionId)) && (
                        <Link className={"btn btn-primary ml-2"} url={"/trials/" + trialInstitution.trialId + "/trialinstitution/" + trialInstitution.id + "/participants/create"}>Add Participant</Link>
                    )}
                </div>
            </div>
            {
                !this.showPatients() ?
                    "Loading Participants" :
                    null
            }
            {
                this.showPatients() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {

        const {
            trialInstitution,
            patients
        } = this.props

        if (!patients || patients.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no participants</span>
            </div>
        }

        const showPagination = patients.length > DEFAULT_PAGE_SIZE;


        return <div className="table-wrapper mb-3">
            <ReactTable
                data={patients}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : patients.length}
                pageSize={showPagination ? undefined : patients.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no patients to display"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            const url: string = "/trials/" + trialInstitution.trialId + "/trialinstitution/" + trialInstitution.id + "/participants/" + rowInfo.original.id;
                            console.log(url);
                            this.props.navigate(url);
                            if (handleOriginal) {
                                handleOriginal()
                            }
                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.USER} fixedWidth />
                    },
                    {
                        id: "registrationNumber",
                        Header: 'Registration No.',
                        accessor: 'registrationNumber',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        maxWidth: 160
                    },
                    {
                        id: "initials",
                        Header: 'Initials',
                        accessor: 'initials',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        maxWidth: 90
                    },
                    {
                        id: "caseType",
                        Header: 'Case Type',
                        accessor: 'caseType.value',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    },
                    {
                        id: "treatment",
                        Header: 'Treatment',
                        accessor: 'trialTreatment.name',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    }
                ]}
            />
        </div>

    }

}


const mapStateToProps = (state: ITmdState, ownProps: TrialInstitutionPatientsPageProps): ITrialInstitutionPatientsPageProps => {

    let trialInstitution: Dtos.TrialInstitution | undefined = !(state.trialInstitution.formData instanceof Array) ? state.trialInstitution.formData : undefined;
    let patients: Dtos.Patient[] | undefined = (state.patient.formData instanceof Array) ? state.patient.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,
        trialInstitutionId: ownProps.match ? ownProps.match.params.trialInstitutionId : undefined!,
        trialInstitution: trialInstitution!,

        match: ownProps.match,
        history: ownProps.history,
        location: state.routing.location,

        patients: patients!,

        loadingPatients: state.patient.loadState && state.patient.loadState.status == RequestState.Pending,
        loadPatientsSuccess: state.patient.loadState && state.patient.loadState.status == RequestState.Success,
        loadPatientsFailure: state.patient.loadState && state.patient.loadState.status == RequestState.Failure,

        loadingTrialInstitution: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Pending,
        loadTrialInstitutionSuccess: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Success,
        loadTrialInstitutionFailure: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Failure,

        importingPatients: state.patient.importState && state.patient.importState.status == RequestState.Pending,
        importPatientsSuccess: state.patient.importState && state.patient.importState.status == RequestState.Success,
        importPatientsFailure: state.patient.importState && state.patient.importState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
    };
};

const mapDispatchToProps = (dispatch): ITrialInstitutionPatientsPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch),

        loadPatients: bindActionCreators(PatientActions.LoadPatient, dispatch),
        clearPatients: bindActionCreators(PatientActions.Clear, dispatch),

        importPatients: bindActionCreators(PatientActions.Import, dispatch),

        loadTrialInstitution: bindActionCreators(TrialInstitutionActions.LoadTrialInstitutionById, dispatch),
        clearTrialInstitution: bindActionCreators(TrialInstitutionActions.Clear, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialInstitutionPatientsPage);
