import * as Dtos from "../dtos/Tmd.dtos";

export const
    Search: ISearchActionCreator = (searchFor) => {
        return {
            type: ACTION_SEARCH,
            searchFor
        }
    },
    SearchSuccess: ISearchSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_SEARCH_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus
        }
    },
    SearchFailure: ISearchFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_SEARCH_FAILURE,
            responseStatus
        }
    },

    Clear: ISearchClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_SEARCH_CLEAR,
            propertiesToClear
        }
    };



export const
    ACTION_SEARCH = "ACTION_SEARCH",
    ACTION_SEARCH_SUCCESS = "ACTION_SEARCH_SUCCESS",
    ACTION_SEARCH_FAILURE = "ACTION_SEARCH_FAILURE",

    ACTION_SEARCH_CLEAR = "ACTION_SEARCH_CLEAR";

export interface ISearchAction {
    type: "ACTION_SEARCH";
    searchFor: string;

}

export interface ISearchActionCreator {
    (
        searchFor: string
    ): ISearchAction;
}

export interface ISearchSuccessAction {
    type: "ACTION_SEARCH_SUCCESS";
    formData: Dtos.SearchResult;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ISearchSuccessActionCreator {
    (
        formData: Dtos.SearchResult,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ISearchSuccessAction;
}

export interface ISearchFailureAction {
    type: "ACTION_SEARCH_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ISearchFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ISearchFailureAction;
}

export interface ISearchClearAction {
    type: "ACTION_SEARCH_CLEAR";
    propertiesToClear?: string[];
}

export interface ISearchClearActionCreator {
    (propertiesToClear?: string[]): ISearchClearAction;
}

export type SearchAction =
    ISearchAction |
    ISearchSuccessAction |
    ISearchFailureAction |
    ISearchClearAction;
