import * as React from 'react';
import './SalutationPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { ISalutationReduxFormState, initialSalutationState } from "../../../reducers/reactReduxForms/salutations";
import { RegistrationFormComponent } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, NumberFormInput, CheckboxFormInput } from "../../../components/form/index";
import * as SalutationActions from '../../../actions/salutations';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { NotificationAlert } from "../../../components/common/index";
import { Form, actions } from 'react-redux-form';
import { LookupsLayout } from '../../index';
import * as AuthenticationHelper from '../../../helpers/authentication';

interface ISalutationPageParams {
    salutationId: number;
}

interface ISalutationPageProps {

    form: Dtos.Salutation & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ISalutationReduxFormState;

    location: Location;
    salutationId: number;
    match: match<ISalutationPageParams>;
    history: History;

    // From state
    salutation: Dtos.Salutation;
    lookups: Dtos.Lookup[];

    loadingSalutation: boolean;
    loadSalutationSuccess: boolean;
    loadSalutationFailure: boolean;

    savingSalutation: boolean;
    saveSalutationSuccess: boolean;
    saveSalutationFailure: boolean;

    creatingSalutation: boolean;
    createSalutationSuccess: boolean;
    createSalutationFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface ISalutationPageActions {
    clearSalutation: SalutationActions.ISalutationClearActionCreator;
    loadSalutation: SalutationActions.ISalutationLoadByIdActionCreator;

    saveSalutation: SalutationActions.ISalutationSaveActionCreator;
    createSalutation: SalutationActions.ISalutationCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type SalutationPageProps = ISalutationPageProps & ISalutationPageActions;

const reduxFormName: string = "reduxForms.salutation";

class SalutationPage extends React.PureComponent<SalutationPageProps, any> {

    constructor(props: SalutationPageProps) {
        super(props);

        this.clearSalutation = this.clearSalutation.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadSalutation,
            salutationId,
            createSalutation
        } = this.props;

        if (salutationId) {
            loadSalutation(salutationId);
        } else if (!salutationId) {
            createSalutation();
        }
    }

    componentWillReceiveProps(nextProps: SalutationPageProps) {
    }

    componentDidUpdate(prevProps: SalutationPageProps, prevState) {
        const {
            salutationId,
            salutation,
            form,
            navigate,
            loadSalutation,
            loadingSalutation,
            loadSalutationSuccess,
            createSalutation,
            creatingSalutation,
            createSalutationSuccess,
            savingSalutation,
            saveSalutationSuccess,
            validationFailures
        } = this.props;

        if (salutationId && prevProps.salutationId != salutationId) {
            loadSalutation(salutationId);
        }
        else if (!salutationId && prevProps.salutationId) {
            createSalutation();
        }

        if (!loadingSalutation && prevProps.loadingSalutation) {
            if (loadSalutationSuccess) {
                this.loadForm();
            }
        }

        if (!savingSalutation && prevProps.savingSalutation) {
            if (saveSalutationSuccess) {
                if (validationFailures && validationFailures.length > 0) {
                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {
                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);
                }
                if (form.saveAndReturn) {
                    navigate("/settings/general/salutations")
                } else if (!form.saveAndReturn && salutationId && salutationId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !salutationId && salutation && salutation.id > 0) {
                    navigate("/settings/general/salutations/" + salutation.id);
                }
            }
            else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

        if (!creatingSalutation && prevProps.creatingSalutation) {
            if (createSalutationSuccess) {
                this.loadForm();
            }
        }
    }

    componentWillUpdate(nextProps: SalutationPageProps) {
    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearSalutation();
        loadForm(reduxFormName, initialSalutationState);
        resetForm(reduxFormName);
    }

    clearSalutation() {
        this.props.clearSalutation();
    }

    showSalutation(): boolean {
        const {
            loadingSalutation,
            loadSalutationSuccess
        } = this.props

        if (!loadingSalutation && loadSalutationSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingSalutation
        } = this.props;

        return <LookupsLayout loading={loadingSalutation} permission={Dtos.Permission.SalutationsView}>
            {this.renderContent()}
        </LookupsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingSalutation,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.SalutationsUpdate, Dtos.Permission.SalutationsCreate);
        const formDisabled: boolean = !hasPermission || savingSalutation || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            value: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            }
        };

        return <div>
            <h1>Salutation</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "salutation", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.Salutation) {
        const {
            saveSalutation
        } = this.props;

        if (data) {
            let changes: Dtos.Salutation = {} as Dtos.Salutation;
            saveSalutation(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.Salutation) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/general/salutations");
    }

    loadForm() {
        const {
            loadForm,
            salutation,
        } = this.props

        let extraData: Dtos.Salutation = {} as Dtos.Salutation;

        loadForm(reduxFormName, Object.assign({ ...salutation }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: SalutationPageProps): ISalutationPageProps => {

    let salutation: Dtos.Salutation | undefined = !(state.salutations.formData instanceof Array) ? state.salutations.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        salutationId: ownProps.match && ownProps.match.params.salutationId && ownProps.match.params.salutationId.toString() != "create" ? ownProps.match.params.salutationId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        salutation: salutation!,

        loadingSalutation: state.salutations.loadState && state.salutations.loadState.status == RequestState.Pending,
        loadSalutationSuccess: state.salutations.loadState && state.salutations.loadState.status == RequestState.Success,
        loadSalutationFailure: state.salutations.loadState && state.salutations.loadState.status == RequestState.Failure,

        savingSalutation: state.salutations.saveState && state.salutations.saveState.status == RequestState.Pending,
        saveSalutationSuccess: state.salutations.saveState && state.salutations.saveState.status == RequestState.Success,
        saveSalutationFailure: state.salutations.saveState && state.salutations.saveState.status == RequestState.Failure,

        creatingSalutation: state.salutations.createState && state.salutations.createState.status == RequestState.Pending,
        createSalutationSuccess: state.salutations.createState && state.salutations.createState.status == RequestState.Success,
        createSalutationFailure: state.salutations.createState && state.salutations.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: [],

        form: state.reduxForms.salutation,
        formState: state.salutations.formState,
        formProperties: state.salutations.formProperties,

        reduxFormState: state.reduxForms.formState.salutation,
        validationFailures: salutation ?
            state.salutations.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): ISalutationPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadSalutation: bindActionCreators(SalutationActions.LoadSalutationById, dispatch),
        clearSalutation: bindActionCreators(SalutationActions.Clear, dispatch),

        saveSalutation: bindActionCreators(SalutationActions.SaveSalutation, dispatch),
        createSalutation: bindActionCreators(SalutationActions.CreateSalutation, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(SalutationPage);
