import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialPersonnelRoleState: Dtos.PersonnelRole & IRegistrationFormState = {
    id: null,
    personnelId: null,
    personnel: null,
    originalRoleId: null,
    roleId: null,
    role: null,
    groupId: null,
    group: null,
    trialId: null,
    trial: null,
    institutionId: null,
    institution: null,
    dateStarted: null,
    dateCeased: null,
    dateFrom: null,
    dateTo: null,
    personnelRoleSummaryType: null,
    groupDisplay: null,
    trialDisplay: null,
    institutionDisplay: null,
    personnelDisplay: null,
    emailDisplay: null,
    roleDisplay: null,
    emailSent: null,
    active: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false,
    emailNotSentReason: null,
    departmentId: null,
    department: null,
    createNewPersonnel: false,
    salutationId: null,
    firstName: null,
    lastName: null,
    email: null,
    userName: null,
    hasActiveDirectoryEntry: null,
    createNewDepartment: null,
    newDepartment: null,
    createNewInstitution: null,
    institutionName: null,
    institutionCode: null,
    noEmailCommunication: false
}

export interface IPersonnelRoleReduxFormState {
    id: IFieldState<number>,
    personnelId: IFieldState<number>,
    personnel: IFieldState<Dtos.Personnel>,
    originalRoleId: IFieldState<number>,
    roleId: IFieldState<number>,
    role: IFieldState<Dtos.Role>,
    groupId: IFieldState<number>,
    group: IFieldState<Dtos.Group>,
    trialId: IFieldState<number>,
    trial: IFieldState<Dtos.Trial>,
    institutionId: IFieldState<number>,
    institution: IFieldState<Dtos.Group>,
    dateStarted: IFieldState<string>,
    dateCeased: IFieldState<string>,
    personnelRoleSummaryType: IFieldState<number>,
    groupDisplay: IFieldState<string>,
    trialDisplay: IFieldState<string>,
    institutionDisplay: IFieldState<string>,
    personnelDisplay: IFieldState<string>,
    roleDisplay: IFieldState<string>,
    active: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>,
    emailNotSentReason: IFieldState<string>,
    departmentId: IFieldState<number>,
    department: IFieldState<Dtos.Department>,
    createNewPersonnel: IFieldState<boolean>,
    salutationId: IFieldState<number>,
    firstName: IFieldState<string>,
    lastName: IFieldState<string>,
    email: IFieldState<string>,
    userName: IFieldState<string>,
    hasActiveDirectoryEntry: IFieldState<boolean>,
    createNewDepartment: IFieldState<boolean>,
    newDepartment: IFieldState<string>,
    createNewInstitution: IFieldState<boolean>,
    institutionName: IFieldState<string>,
    institutionCode: IFieldState<string>,
    noEmailCommunication: IFieldState<boolean>
}
