import * as React from 'react';
import './DashboardPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import * as ReactTooltip from 'react-tooltip';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu, TruncatedCell } from "../../components/layouts/index";
import * as TrialActions from '../../actions/trial';
import * as TrialInstitutionsActions from '../../actions/trialInstitution';
import * as TrialItemInstanceActions from '../../actions/trialItemInstance';
import * as InstitutionFormActions from '../../actions/institutionForm';
import * as InstitutionFormsPageActions from '../../actions/pages/institutionFormsPage';
import * as TrialItemInstancePageActions from '../../actions/pages/trialItemInstancePage';
import * as TrialFormActions from '../../actions/trialForm';
import * as PagingActions from '../../actions/pages/pagingPage';
import * as Dtos from '../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../reducers/index";
import { RequestState } from "../../enumerations/RequestState";
import { findLookup, generateOptionsFromLookup, findLookupValue } from "../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../constants/reactTableConstants";
import { setModalTitle } from "../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../components/common/index";
import * as AuthenticationHelper from '../../helpers/authentication';
import { ModalSize } from '../../enumerations/ModalSize';
import { TextAreaFormInput, FormFilterInput } from '../../components/form';

import {
    CollectionInstancePage
} from "../../containers/index";

interface IDashboardPageParams {
}

interface IDashboardPageProps {

    location: Location;
    match: match<IDashboardPageParams>;
    history: History;

    // modals
    modalTitle: string,
    modalDescription: string,
    editModalOpen: boolean,
    collectionInstanceId: number,

    // From state
    formFilter: IFormFilter;
    formLength: number;
    trials: Dtos.Trial[],
    trialInstitutions: Dtos.TrialInstitution[],
    institutionForms: Dtos.InstitutionForm[],
    trialItemInstances: Dtos.TrialItemInstance[],
    audits: Dtos.TrialForm[],
    checklists: Dtos.TrialForm[],

    loadingTrials: boolean;
    loadTrialsSuccess: boolean;
    loadTrialsFailure: boolean;

    loadingTrialInstitutions: boolean;
    loadTrialInstitutionsSuccess: boolean;
    loadTrialInstitutionsFailure: boolean;

    loadingInstitutionForms: boolean;
    loadInstitutionFormsSuccess: boolean;
    loadInstitutionFormsFailure: boolean;

    loadingTrialItemInstances: boolean;
    loadTrialItemInstancesSuccess: boolean;
    loadTrialItemInstancesFailure: boolean;

    loadingTrialForms: boolean;
    loadTrialFormsSuccess: boolean;
    loadTrialFormsFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    lookups: Dtos.Lookup[];
    trialLookups: Dtos.Lookup[];
    formRoles: Dtos.RoleType[];

    pageSize: number;
}

interface IDashboardPageActions {
    clearTrials: TrialActions.ITrialClearActionCreator;
    loadTrials: TrialActions.ITrialLoadCurrentUserActionCreator;

    clearTrialInstitutions: TrialInstitutionsActions.ITrialInstitutionClearActionCreator;
    loadTrialInstitutions: TrialInstitutionsActions.ITrialInstitutionLoadActionCreator;

    clearInstitutionForms: InstitutionFormActions.IInstitutionFormClearActionCreator;
    loadInstitutionFormCurrentUser: InstitutionFormActions.IInstitutionFormLoadCurrentUserActionCreator;

    clearTrialForms: TrialFormActions.ITrialFormClearActionCreator;
    loadTrialFormCurrentUser: TrialFormActions.ITrialFormLoadCurrentUserActionCreator;

    clearTrialItemInstances: TrialItemInstanceActions.ITrialItemInstanceClearActionCreator;
    loadTrialItemInstanceCurrentUser: TrialItemInstanceActions.ITrialItemInstanceLoadCurrentUserActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    filter: TrialInstitutionsActions.ITrialInstitutionFilterActionCreator;

    setPageSize: PagingActions.IPagingPageSizeChangedStateActionCreator;

    setEditModelViewState: InstitutionFormsPageActions.IInstitutionFormsPageSetEditModalViewStateActionCreator;
    setTrailItemInstanceModelViewState: TrialItemInstancePageActions.ITrialItemInstancesPageSetEditModalViewStateActionCreator;

}

type DashboardPageProps = IDashboardPageProps & IDashboardPageActions;

class DashboardPage extends React.PureComponent<DashboardPageProps, any> {

    constructor(props: DashboardPageProps) {
        super(props);

        this.clearTrial = this.clearTrial.bind(this);
    }

    componentDidMount() {
        const {
            loadTrialInstitutions,
            loadInstitutionFormCurrentUser,
            loadTrialFormCurrentUser,
            loadTrialItemInstanceCurrentUser,
            loadTrials,
        } = this.props;

        loadTrials();
        loadTrialInstitutions();
        loadInstitutionFormCurrentUser();
        loadTrialFormCurrentUser();
        loadTrialItemInstanceCurrentUser();
    }

    componentWillUnmount() {
        this.clearTrial();
    }

    clearTrial() {
        this.props.clearTrialInstitutions();
    }

    showTrial(): boolean {
        const {
            loadingTrialInstitutions,
            loadTrialInstitutionsSuccess,
            loadingTrials,
            loadTrialsSuccess
        } = this.props

        if (!loadingTrialInstitutions && loadTrialInstitutionsSuccess && !loadingTrials && loadTrialsSuccess) {
            return true;
        }

        return false;
    }

    showTrialItemInstances(): boolean {
        const {
            loadingTrialItemInstances,
            trialItemInstances
        } = this.props

        if (!loadingTrialItemInstances && trialItemInstances && trialItemInstances.length > 0) {
            return true;
        }

        return false;
    }

    showInstitutionForms(): boolean {
        const {
            loadingInstitutionForms,
            institutionForms
        } = this.props

        if (!loadingInstitutionForms && institutionForms && institutionForms.length > 0) {
            return true;
        }

        return false;
    }

    showAudits(): boolean {
        const {
            loadingTrialForms,
            audits
        } = this.props

        if (!loadingTrialForms && audits && audits.length > 0) {
            return true;
        }

        return false;
    }

    showChecklists(): boolean {
        const {
            loadingTrialForms,
            checklists
        } = this.props

        if (!loadingTrialForms && checklists && checklists.length > 0) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingTrialInstitutions,
            loadingInstitutionForms,
            loadingTrialForms,
            loadingTrialItemInstances,
            loadingTrials
        } = this.props;

        return <RestrictedLayout
            anyMatchpermission={ Dtos.Permission.DashboardView }
            loading={ loadingTrials || loadingTrialInstitutions || loadingInstitutionForms || loadingTrialForms || loadingTrialItemInstances}
        >
            {this.renderContent()}
            {this.renderItemModal()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            audits,
            checklists
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-6 mb-2"}>
                    <h3>My Trials</h3>
                </div>
                <div className="col-6" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} hideActiveToggle={true} />
                </div>
            </div>
            {
                !this.showTrial() ?
                    "Loading Trials" :
                    null
            }
            {
                this.showTrial() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
            {
                this.showTrialItemInstances() ?
                    <>
                        <div className="row">
                            <div className={"col mb-2"}>
                                <h3>Trial Items</h3>
                            </div>
                        </div>
                        {
                            this.createTrialItemInstances()
                        }
                    </> :
                    null
            }
            {
                this.showInstitutionForms() ?
                    <>
                        <div className="row">
                            <div className={"col mb-2"}>
                                <h3>Facility Questionnaires</h3>
                            </div>
                        </div>
                        {
                            this.createInstitutionForms()
                        }
                    </> :
                    null
            }
            {
                this.showAudits() ?
                    <>
                        <div className="row">
                            <div className={"col mb-2"}>
                                <h3>Audits</h3>
                            </div>
                        </div>
                        {
                            this.createTrialForms(audits)
                        }
                    </> :
                    null
            }
            {
                this.showChecklists() ?
                    <>
                        <div className="row">
                            <div className={"col mb-2"}>
                                <h3>Checklists</h3>
                            </div>
                        </div>
                        {
                            this.createTrialForms(checklists)
                        }
                    </> :
                    null
            }
        </div>
    }

    createTable() {
        const {
            trialInstitutions,
            lookups,
            trialLookups,
            pageSize,
            setPageSize,
            permissions,
            trials
        } = this.props

        if ((!trialInstitutions || trialInstitutions.length == 0) && (!trials || trials.length == 0)) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no trials assigned</span>
            </div>
        }

        const showPagination = trialInstitutions.length > pageSize;

        const trialInstitutionsTable = trialInstitutions && trialInstitutions.length > 0 ? <div className="table-wrapper mb-3">
            <ReactTable
                data={trialInstitutions}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? pageSize : trialInstitutions.length}
                pageSize={showPagination ? pageSize : trialInstitutions.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no trial groups to display"
                resizable={false}
                onPageSizeChange={val => setPageSize(val)}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {

                            if (column.id == "personnelCount" && AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialPersonnelRoleView, rowInfo.original.trialId, rowInfo.original.institutionId)) {
                                const url: string = "/trials/" + rowInfo.original.trialId + "/trialinstitution/" + rowInfo.original.id + "/personnel";
                                this.props.navigate(url);
                            } else if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialInstitutionView, rowInfo.original.trialId, rowInfo.original.institutionId)) {
                                const url: string = "/trials/" + rowInfo.original.trialId + "/trialinstitution/" + rowInfo.original.id + "/details";
                                this.props.navigate(url);
                            }

                            if (handleOriginal) {
                                handleOriginal()
                            }
                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <div className="large-small-icon"><FontAwesomeIcon className="large-icon" icon={FontAwesomeIcons.Light.TRIAL} fixedWidth /><FontAwesomeIcon className="small-icon" icon={FontAwesomeIcons.Light.INSTITUTION} fixedWidth /></div>
                    },
                    {
                        id: "protocolID",
                        Header: 'TROG Number',
                        accessor: 'trial.protocolID',
                        maxWidth: 180,
                        className: "d-flex align-items-center justify-content-center",
                    },
                    /*{
                        id: "title",
                        Header: 'Scientific Title',
                        accessor: 'trial.title',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value} />
                    }, */
                    {
                        id: "shortTitle",
                        Header: 'Short Title',
                        accessor: 'trial.shortTitle',
                        maxWidth: 280,
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value} />
                    },
                    {
                        id: "name",
                        Header: 'Institution',
                        accessor: 'institution.name',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value} />
                    },
                    {
                        id: "trialInstitutionStatusId",
                        Header: 'Status',
                        accessor: 'trialInstitutionStatusId',
                        className: "d-flex align-items-center justify-content-center",
                        Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{findLookupValue("TrialInstitution", "TrialInstitutionStatusId", props.value, lookups)}</span><ReactTooltip /></div>
                    },
                    {
                        id: "personnelCount",
                        Header: 'Roles',
                        accessor: 'personnelCount',
                        maxWidth: 80,
                        className: "d-flex align-items-center justify-content-center",
                        Cell: (props) => <div><span className="text-info"><u>{props.value || "0"}</u></span></div>
                    },
                    {
                        id: "dateActivated",
                        Header: "Date Activated",
                        accessor: "dateActivated",
                        className: "d-flex align-items-center justify-content-center",
                        Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                    }
                ]}
            />
        </div> : undefined;

        const trialsTable = trials && trials.length > 0 ? <div className="table-wrapper mb-3">
            <ReactTable
                data={trials}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? pageSize : trials.length}
                pageSize={showPagination ? pageSize : trials.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no trial groups to display"
                resizable={false}
                onPageSizeChange={val => setPageSize(val)}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {

                            if (column.id == "personnelCount" && AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialPersonnelRoleView, rowInfo.original.id, null)) {
                                const url: string = "/trials/" + rowInfo.original.id + "/personnel";
                                this.props.navigate(url);
                            } else if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialInstitutionView, rowInfo.original.id, null)) {
                                const url: string = "/trials/" + rowInfo.original.id + "/details";
                                this.props.navigate(url);
                            }

                            if (handleOriginal) {
                                handleOriginal()
                            }
                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.TRIAL} fixedWidth />
                    },
                    {
                        id: "displayNumber",
                        Header: 'TROG Number',
                        accessor: 'displayNumber',
                        maxWidth: 180,
                        className: "d-flex align-items-center justify-content-center",
                    },
                    {
                        id: "shortTitle",
                        Header: 'Short Title',
                        accessor: 'shortTitle',
                        maxWidth: 280,
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value} />
                    },
                    {
                        id: "trialStatusId",
                        Header: 'Status',
                        accessor: 'trialStatusId',
                        className: "d-flex align-items-center justify-content-center",
                        Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{findLookupValue("Trial", "TrialStatusId", props.value, trialLookups)}</span><ReactTooltip /></div>
                    },
                    {
                        id: "personnelCount",
                        Header: 'Roles',
                        accessor: 'personnelCount',
                        maxWidth: 80,
                        className: "d-flex align-items-center justify-content-center",
                        Cell: (props) => <div><span className="text-info"><u>{props.value || "0"}</u></span></div>
                    }
                ]}
            />
        </div> : undefined;

        return <>
            {trialInstitutionsTable}
            {trialsTable }
        </>
    }

    createInstitutionForms() {

        const {
            institutionForms,
            permissions,
        } = this.props

        if (!institutionForms || institutionForms.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no institution forms</span>
            </div>
        }

        const showPagination = institutionForms.length > DEFAULT_PAGE_SIZE;
        
        return <div className="table-wrapper mb-3">
            <ReactTable
                data={institutionForms}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : institutionForms.length}
                pageSize={showPagination ? undefined : institutionForms.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no forms to display"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.InstitutionFormDataEntryView, null, rowInfo.original.institutionId)) {
                                const url: string = "/institutions/" + rowInfo.original.institutionId + "/forms/" + rowInfo.original.id + "/entry";
                                this.props.navigate(url);
                            }
                            if (handleOriginal) {
                                handleOriginal()
                            }

                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.TRIALITEM} fixedWidth />
                    },
                    {
                        id: "institution",
                        Header: 'Institution',
                        accessor: 'institution.name',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    },
                    {
                        id: "name",
                        Header: 'Form Template',
                        accessor: 'formTemplate.name',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    },
                    {
                        id: "assignedToId",
                        Header: 'Assigned To',
                        accessor: 'assignedTo.display',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 180,
                    },
                    {
                        id: "formStatusId",
                        Header: 'Status',
                        accessor: 'formStatus.value',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 180,
                    },
                    {
                        id: "version",
                        Header: 'Version',
                        accessor: 'version',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 180,
                    }
                ]}
            />
        </div>

    }

    createTrialItemInstances() {

        const {
            trialItemInstances,
            permissions,
            setTrailItemInstanceModelViewState,
        } = this.props

        if (!trialItemInstances || trialItemInstances.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no assigned trial items</span>
            </div>
        }


        const showPagination = trialItemInstances.length > DEFAULT_PAGE_SIZE;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={trialItemInstances}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : trialItemInstances.length}
                pageSize={showPagination ? undefined : trialItemInstances.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no trial items to display"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {


                            if (
                                AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialFormUpdate, rowInfo.original.trialId, null)
                                || AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialFormDataEntry, rowInfo.original.trialId, null)
                            ) {
                                setTrailItemInstanceModelViewState(true, rowInfo.original.collectionInstanceId, rowInfo.original.templateTrialItem.name, rowInfo.original.templateTrialItem.description);
                                const url: string = "/trials/" + rowInfo.original.trialId + "/items" ;
                                this.props.navigate(url);
                            }

                            if (handleOriginal) {
                                handleOriginal()
                            }
                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.TRIALITEM} fixedWidth />
                    },
                    {
                        id: "trial",
                        Header: 'Trial',
                        accessor: 'trial.display',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    },
                    {
                        id: "name",
                        Header: 'Item',
                        accessor: 'templateTrialItem.name',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    },
                    {
                        id: "assignedDisplay",
                        Header: 'Assigned To',
                        accessor: 'assignedDisplay',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 180,
                        Cell: (props) => <TruncatedCell value={props.value} />,
                    },
                    {
                        id: "trialItemInstanceStatusDisplay",
                        Header: 'Status',
                        accessor: 'trialItemInstanceStatus.value',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 180,
                    },
                    {
                        id: "version",
                        Header: 'Version',
                        accessor: 'version',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 180,
                    }
                ]}
            />
        </div>

    }

    createTrialForms(items: Dtos.TrialForm[]) {

        const {
            permissions,
        } = this.props

        if (!items || items.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no assigned forms</span>
            </div>
        }


        const showPagination = items.length > DEFAULT_PAGE_SIZE;
        
        return <div className="table-wrapper mb-3">
            <ReactTable
                data={items}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : items.length}
                pageSize={showPagination ? undefined : items.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no forms to display"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            

                            if (
                                AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialFormUpdate, rowInfo.original.trialId, null)
                                || AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialFormDataEntry, rowInfo.original.trialId, null)
                            ) {
                                const url: string = "/trials/" + rowInfo.original.trialId + "/forms/" + rowInfo.original.id + "/entry";
                                this.props.navigate(url);
                            }

                            if (handleOriginal) {
                                handleOriginal()
                            }
                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.TRIALITEM} fixedWidth />
                    },
                    {
                        id: "institution",
                        Header: 'Institution',
                        accessor: 'institution.name',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    },
                    {
                        id: "name",
                        Header: 'Form Template',
                        accessor: 'formTemplate.name',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    },
                    {
                        id: "assignedDisplay",
                        Header: 'Assigned To',
                        accessor: 'assignedDisplay',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 180,
                        Cell: (props) => <TruncatedCell value={props.value} />,
                    },
                    {
                        id: "formStatusId",
                        Header: 'Status',
                        accessor: 'formStatus.value',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 180,
                    },
                    {
                        id: "version",
                        Header: 'Version',
                        accessor: 'version',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 180,
                    }
                ]}
            />
        </div>

    }

    renderItemModal() {
        const {
            editModalOpen,
            setEditModelViewState,
            collectionInstanceId,
            modalTitle,
            modalDescription,
            loadInstitutionFormCurrentUser
        } = this.props

        if (collectionInstanceId && collectionInstanceId > 0) {
            return <Modal
                open={editModalOpen}
                disableCloseOnOverlayClick={true}
                onClose={() => {
                    setEditModelViewState(false, null, "", "");
                    loadInstitutionFormCurrentUser();
                }}
                size={ModalSize.Xl}>
                <div className="modal-header">
                    <h5 className="modal-title">{setModalTitle(undefined, modalTitle)}{modalDescription ? <><br /><small style={{ color: '#444' }}>{modalDescription}</small></> : undefined}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => {
                        setEditModelViewState(false, null, "", "");
                        loadInstitutionFormCurrentUser();
                    }}>
                        <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                    </button>

                </div>
                <div className="modal-body">
                    <CollectionInstancePage
                        readonly={false}
                        collectionInstanceId={collectionInstanceId} onClose={() => {
                        setEditModelViewState(false, null, "", "");
                        loadInstitutionFormCurrentUser();
                    }} />
                </div>
            </Modal>
        }
    }

}

const mapStateToProps = (state: ITmdState, ownProps: DashboardPageProps): IDashboardPageProps => {

    let trialItemInstances: Dtos.TrialItemInstance[] | undefined = (state.trialItemInstance.formData instanceof Array) ? state.trialItemInstance.formData : undefined;
    let institutionForms: Dtos.InstitutionForm[] | undefined = (state.institutionForm.formData instanceof Array) ? state.institutionForm.formData : undefined;
    let audits: Dtos.TrialForm[] | undefined = (state.trialForm.formData instanceof Array) ? state.trialForm.formData.filter(f => f.formTemplate.itemComponentTypeId == 3) : undefined;
    let checklists: Dtos.TrialForm[] | undefined = (state.trialForm.formData instanceof Array) ? state.trialForm.formData.filter(f => f.formTemplate.itemComponentTypeId == 4) : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];
    let trials: Dtos.Trial[] | undefined = (state.trials.formData instanceof Array) ? state.trials.formData : undefined;

    return {

        match: ownProps.match,

        history: ownProps.history,
        location: state.routing.location,

        modalTitle: state.institutionFormsPage.name,
        modalDescription: state.institutionFormsPage.description,
        editModalOpen: state.institutionFormsPage.editModalOpen,
        collectionInstanceId: state.institutionFormsPage.collectionInstanceId,

        institutionForms: institutionForms!,
        trialInstitutions: state.trialInstitution.formFilteredData!,
        trials: trials!,
        audits: audits!,
        trialItemInstances: trialItemInstances!,
        checklists: checklists!,
        formFilter: state.trialInstitution.formFilter,
        formLength: state.trialInstitution.formLength,
        lookups: state.trialInstitution.lookups,
        trialLookups: state.trials.lookups,

        loadingTrials: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,
        loadTrialsSuccess: state.trials.loadState && state.trials.loadState.status == RequestState.Success,
        loadTrialsFailure: state.trials.loadState && state.trials.loadState.status == RequestState.Failure,

        loadingTrialInstitutions: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Pending,
        loadTrialInstitutionsSuccess: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Success,
        loadTrialInstitutionsFailure: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Failure,

        loadingInstitutionForms: state.institutionForm.loadState && state.institutionForm.loadState.status == RequestState.Pending,
        loadInstitutionFormsSuccess: state.institutionForm.loadState && state.institutionForm.loadState.status == RequestState.Success,
        loadInstitutionFormsFailure: state.institutionForm.loadState && state.institutionForm.loadState.status == RequestState.Failure,

        loadingTrialItemInstances: state.trialItemInstance.loadState && state.trialItemInstance.loadState.status == RequestState.Pending,
        loadTrialItemInstancesSuccess: state.trialItemInstance.loadState && state.trialItemInstance.loadState.status == RequestState.Success,
        loadTrialItemInstancesFailure: state.trialItemInstance.loadState && state.trialItemInstance.loadState.status == RequestState.Failure,

        loadingTrialForms: state.trialForm.loadState && state.trialForm.loadState.status == RequestState.Pending,
        loadTrialFormsSuccess: state.trialForm.loadState && state.trialForm.loadState.status == RequestState.Success,
        loadTrialFormsFailure: state.trialForm.loadState && state.trialForm.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles,
        pageSize: state.pagingPage.pageSize
    };
};

const mapDispatchToProps = (dispatch): IDashboardPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadTrials: bindActionCreators(TrialActions.LoadTrialCurrentUser, dispatch),
        clearTrials: bindActionCreators(TrialActions.Clear, dispatch),

        loadTrialInstitutions: bindActionCreators(TrialInstitutionsActions.LoadTrialInstitution, dispatch),
        clearTrialInstitutions: bindActionCreators(TrialInstitutionsActions.Clear, dispatch),

        loadInstitutionFormCurrentUser: bindActionCreators(InstitutionFormActions.LoadInstitutionFormCurrentUser, dispatch),
        clearInstitutionForms: bindActionCreators(InstitutionFormActions.Clear, dispatch),

        loadTrialFormCurrentUser: bindActionCreators(TrialFormActions.LoadTrialFormCurrentUser, dispatch),
        clearTrialForms: bindActionCreators(TrialFormActions.Clear, dispatch),

        loadTrialItemInstanceCurrentUser: bindActionCreators(TrialItemInstanceActions.LoadTrialItemInstanceCurrentUser, dispatch),
        clearTrialItemInstances: bindActionCreators(TrialItemInstanceActions.Clear, dispatch),

        setEditModelViewState: bindActionCreators(InstitutionFormsPageActions.setEditModalViewState, dispatch),
        setTrailItemInstanceModelViewState: bindActionCreators(TrialItemInstancePageActions.setEditModalViewState, dispatch),

        filter: bindActionCreators(TrialInstitutionsActions.Filter, dispatch),

        setPageSize: bindActionCreators(PagingActions.PageSizeChanged, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
