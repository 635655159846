import * as React from 'react';
import { connect } from "react-redux";
import { ITmdState } from '../../reducers';
import { match } from 'react-router';
import { DefaultLayout, RestrictedLayout, INavItem, UnrestrictedLayout } from "../../components/layouts/index";
import { FontAwesomeIcon, FontAwesomeIcons } from '../../constants/fontAwesomeIcons';

interface IErrorPageParams {
    errorCode: string;
}

interface IErrorPageProps {
    match: match<IErrorPageParams>;
    errorCode: string | undefined;
}

interface IErrorPageActions {

}

type ErrorPageProps = IErrorPageProps & IErrorPageActions;

interface IErrorType {
    code: string;
    title: string;
    message: string;

}

const ErrorTypes = {
    403: { code: "403", title: "Forbidden", message: "The current user has insufficient permissions to access the requested resource." } as IErrorType,
    404: { code: "404", title: "Page Not Found", message: "The requested resource could not be found. Please check the requested URL is correct." } as IErrorType,
    generic: { code: "500", title: "Internal Server Error", message: "An error occurred on the server while attempting to process the request, please try again." } as IErrorType
}

class ErrorPage extends React.Component<ErrorPageProps, any> {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps: IErrorPageProps) {
    }

    componentDidUpdate(prevProps: IErrorPageProps, prevState) {

    }

    componentWillUpdate(nextProps: IErrorPageProps) {
    }

    componentWillUnmount() {
    }

    render() {

        const {
            errorCode
        } = this.props

        let errorType = ErrorTypes.generic;

        if (errorCode && ErrorTypes[errorCode]) {
            errorType = ErrorTypes[errorCode];
        }

        return <RestrictedLayout
            subMenuItems={undefined}
            loading={!errorType}
        >
            {this.renderContent(errorType)}
        </RestrictedLayout>

    }

    renderContent(errorType: IErrorType) {

        return <div className="container-fluid">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="jumbotron my-5 text-center">
                            <div className="text-danger mb-7">
                                <FontAwesomeIcon icon={FontAwesomeIcons.Solid.EXCLAMATION_TRIANGLE} fixedWidth transform="grow-64" />
                            </div>
                            <div className="display-3 text-danger">
                                {errorType.code}
                            </div>
                            <p className="lead">
                                {errorType.title}
                            </p>
                            <hr className="my-4" />
                            <p className="">
                                {errorType.message}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    createSubMenuItems(): INavItem[] {

        return [
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.BAN} />,
                title: "Error",
                url: undefined
            }
        ]
    }

}

const mapStateToProps = (state: ITmdState, ownProps: ErrorPageProps): IErrorPageProps => {

    return {
        match: ownProps.match,
        errorCode: ownProps.match ? ownProps.match.params.errorCode : undefined
    };
};

const mapDispatchToProps = (dispatch): IErrorPageActions => {
    return {

    }
};

export default
    connect(mapStateToProps, mapDispatchToProps)(ErrorPage);