import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const institutionsApi = {
    getInstitutions: (): Observable<Dtos.GetInstitutionsResponse> => {
        //Create the request.
        let request: Dtos.GetInstitutions = new Dtos.GetInstitutions();

        //Send request.
        const response: Promise<Dtos.GetInstitutionsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getInstitutionById: (id: number): Observable<Dtos.GetInstitutionByIdResponse> => {
        //Create the request.
        let request: Dtos.GetInstitutionById = new Dtos.GetInstitutionById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetInstitutionByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setInstitution: (institution: Dtos.Institution): Observable<Dtos.SetInstitutionResponse> => {

        let request: Dtos.SetInstitution = new Dtos.SetInstitution();

        request.institution = institution;

        // send request
        const response: Promise<Dtos.SetInstitutionResponse> = client.post(request);

        return Observable.from(response);

    },
    createInstitution: (): Observable<Dtos.CreateInstitutionResponse> => {
        //Create the request.
        let request: Dtos.CreateInstitution = new Dtos.CreateInstitution();

        //Send request.
        const response: Promise<Dtos.CreateInstitutionResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}