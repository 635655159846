import * as React from 'react';
import * as classNames from "classnames";

interface ITreeNodeProps {
    name: string,
    id: string,
    onToggle: (id: string) => void;
    toggled?: boolean;
}

class TreeNode extends React.PureComponent<ITreeNodeProps, any> {

    render() {

        const {
            id,
            name,
            onToggle
        } = this.props;

        return <div className={classNames(
            "tree-node-container", "list-group-item", "list-group-item-action", "-clickable", "rounded-0")}
            onClick={() => {
                onToggle(id)
            }}
        >
            {
                name ? 
                    name : 
                    null
            }
        </div>
    }
}
export default TreeNode;