import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const formTemplateFileTypeApi = {
    getFormTemplateFileTypes: (): Observable<Dtos.GetFormTemplateFileTypesResponse> => {
        //Create the request.
        let request: Dtos.GetFormTemplateFileTypes = new Dtos.GetFormTemplateFileTypes();

        //Send request.
        const response: Promise<Dtos.GetFormTemplateFileTypesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getFormTemplateFileTypeById: (id: number): Observable<Dtos.GetFormTemplateFileTypeByIdResponse> => {
        //Create the request.
        let request: Dtos.GetFormTemplateFileTypeById = new Dtos.GetFormTemplateFileTypeById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetFormTemplateFileTypeByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setFormTemplateFileType: (formTemplateFileType: Dtos.FormTemplateFileType): Observable<Dtos.SetFormTemplateFileTypeResponse> => {

        let request: Dtos.SetFormTemplateFileType = new Dtos.SetFormTemplateFileType();

        request.formTemplateFileType = formTemplateFileType;

        // send request
        const response: Promise<Dtos.SetFormTemplateFileTypeResponse> = client.post(request);

        return Observable.from(response);

    },
    createFormTemplateFileType: (): Observable<Dtos.CreateFormTemplateFileTypeResponse> => {
        //Create the request.
        let request: Dtos.CreateFormTemplateFileType = new Dtos.CreateFormTemplateFileType();

        //Send request.
        const response: Promise<Dtos.CreateFormTemplateFileTypeResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}