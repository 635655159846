import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const itemComponentsApi = {
    getItemComponents: (qaItemComponents: boolean, itemComponentTypeId: number): Observable<Dtos.GetItemComponentsResponse> => {
        //Create the request.
        let request: Dtos.GetItemComponents = new Dtos.GetItemComponents();

        request.qaItemComponents = qaItemComponents;
        request.itemComponentTypeId = itemComponentTypeId;

        //Send request.
        const response: Promise<Dtos.GetItemComponentsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getItemComponentById: (id: number): Observable<Dtos.GetItemComponentByIdResponse> => {
        //Create the request.
        let request: Dtos.GetItemComponentById = new Dtos.GetItemComponentById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetItemComponentByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setItemComponent: (itemComponent: Dtos.ItemComponent): Observable<Dtos.SetItemComponentResponse> => {

        let request: Dtos.SetItemComponent = new Dtos.SetItemComponent();

        request.itemComponent = itemComponent;

        // send request
        const response: Promise<Dtos.SetItemComponentResponse> = client.post(request);

        return Observable.from(response);

    },
    createItemComponent: (cloneId: number): Observable<Dtos.CreateItemComponentResponse> => {
        //Create the request.
        let request: Dtos.CreateItemComponent = new Dtos.CreateItemComponent();

        request.cloneId = cloneId;

        //Send request.
        const response: Promise<Dtos.CreateItemComponentResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}