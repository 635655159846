import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trialInstitutionItemTemplatesApi = {
    getTrialInstitutionItemTemplates: (trialId: number): Observable<Dtos.GetTrialInstitutionItemTemplatesResponse> => {
        //Create the request.
        let request: Dtos.GetTrialInstitutionItemTemplates = new Dtos.GetTrialInstitutionItemTemplates();

        request.trialId = trialId;

        //Send request.
        const response: Promise<Dtos.GetTrialInstitutionItemTemplatesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrialInstitutionItemTemplateById: (id: number): Observable<Dtos.GetTrialInstitutionItemTemplateByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrialInstitutionItemTemplateById = new Dtos.GetTrialInstitutionItemTemplateById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTrialInstitutionItemTemplateByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrialInstitutionItemTemplate: (trialInstitutionItemTemplate: Dtos.TrialInstitutionItemTemplate, collection: Dtos.Collection): Observable<Dtos.SetTrialInstitutionItemTemplateResponse> => {

        let request: Dtos.SetTrialInstitutionItemTemplate = new Dtos.SetTrialInstitutionItemTemplate();

        request.trialInstitutionItemTemplate = trialInstitutionItemTemplate;
        request.collection = collection;

        // send request
        const response: Promise<Dtos.SetTrialInstitutionItemTemplateResponse> = client.post(request);

        return Observable.from(response);

    },
    createTrialInstitutionItemTemplate: (trialId: number): Observable<Dtos.CreateTrialInstitutionItemTemplateResponse> => {
        //Create the request.
        let request: Dtos.CreateTrialInstitutionItemTemplate = new Dtos.CreateTrialInstitutionItemTemplate();

        request.trialId = trialId;

        //Send request.
        const response: Promise<Dtos.CreateTrialInstitutionItemTemplateResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}