import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_COLLECTIONINSTANCES_LOAD_BY_ID,

    ACTION_COLLECTIONINSTANCES_SAVE,

    ICollectionInstanceLoadByIdAction,
    ICollectionInstanceLoadFailureAction,
    ICollectionInstanceLoadSuccessAction,

    ICollectionInstanceSaveAction,
    ICollectionInstanceSaveSuccessAction,
    ICollectionInstanceSaveFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

} from "../actions/collectionInstance";
import { collectionInstancesApi } from "../services/collectionInstances";


export const
    getCollectionInstanceByIdEpic = (action$): Observable<ICollectionInstanceLoadSuccessAction | ICollectionInstanceLoadFailureAction> => {
        return action$
            .ofType(ACTION_COLLECTIONINSTANCES_LOAD_BY_ID)
            .mergeMap((action: ICollectionInstanceLoadByIdAction) => {
                return collectionInstancesApi
                    .getCollectionInstanceById(action.id)
                    .map(response =>
                        LoadSuccess(response.collectionInstance, response.formState, response.formProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setCollectionInstanceEpic = (action$): Observable<ICollectionInstanceSaveSuccessAction | ICollectionInstanceSaveFailureAction> => {
        return action$
            .ofType(ACTION_COLLECTIONINSTANCES_SAVE)
            .mergeMap((action: ICollectionInstanceSaveAction) => {
                return collectionInstancesApi
                    .setCollectionInstance(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.collectionInstance, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    }



