import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_MEMBERSHIPTYPES_LOAD,
    ACTION_MEMBERSHIPTYPES_LOAD_BY_ID,

    ACTION_MEMBERSHIPTYPES_SAVE,

    ACTION_MEMBERSHIPTYPES_CREATE,

    IMembershipTypeLoadAction,
    IMembershipTypeLoadByIdAction,
    IMembershipTypeLoadFailureAction,
    IMembershipTypeLoadSuccessAction,

    IMembershipTypeSaveAction,
    IMembershipTypeSaveSuccessAction,
    IMembershipTypeSaveFailureAction,

    IMembershipTypeCreateAction,
    IMembershipTypeCreateSuccessAction,
    IMembershipTypeCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/membershipTypes";
import { membershiptypesApi } from "../services/membershipType";


export const
    getMembershipTypesEpic = (action$): Observable<IMembershipTypeLoadSuccessAction | IMembershipTypeLoadFailureAction> => {
        return action$
            .ofType(ACTION_MEMBERSHIPTYPES_LOAD)
            .mergeMap((action: IMembershipTypeLoadAction) => {
                return membershiptypesApi
                    .getMembershipTypes()
                    .map(response =>
                        LoadSuccess(response.membershipTypes, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getMembershipTypeByIdEpic = (action$): Observable<IMembershipTypeLoadSuccessAction | IMembershipTypeLoadFailureAction> => {
        return action$
            .ofType(ACTION_MEMBERSHIPTYPES_LOAD_BY_ID)
            .mergeMap((action: IMembershipTypeLoadByIdAction) => {
                return membershiptypesApi
                    .getMembershipTypeById(action.id)
                    .map(response =>
                        LoadSuccess(response.membershipType, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setMembershipTypeEpic = (action$): Observable<IMembershipTypeSaveSuccessAction | IMembershipTypeSaveFailureAction> => {
        return action$
            .ofType(ACTION_MEMBERSHIPTYPES_SAVE)
            .mergeMap((action: IMembershipTypeSaveAction) => {
                return membershiptypesApi
                    .setMembershipType(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.membershipType, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createMembershipTypeEpic = (action$): Observable<IMembershipTypeCreateSuccessAction | IMembershipTypeCreateFailureAction> => {
        return action$
            .ofType(ACTION_MEMBERSHIPTYPES_CREATE)
            .mergeMap((action: IMembershipTypeCreateAction) => {
                return membershiptypesApi
                    .createMembershipType()
                    .map(response =>
                        CreateSuccess(
                            response.membershipType,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



