import { Reducer } from 'redux';
import { update } from '../../helpers/immutabilityHelper'
import * as Dtos from '../../dtos/Tmd.dtos';

import {
    ACTION_COLLECTION_PAGE_SET_ADD_MODAL_VIEW_STATE,
    ACTION_COLLECTION_PAGE_SET_FILTER,
    ACTION_COLLECTION_PAGE_SET_SECTION,
    ACTION_COLLECTION_PAGE_SET_ADD_SELECTED,
    ACTION_COLLECTION_PAGE_SET_EDIT_MODAL_VIEW_STATE,
    ACTION_COLLECTION_PAGE_SET_PREVIEW_MODAL_VIEW_STATE,
    ACTION_COLLECTION_PAGE_SET_PREVIEW_COMPONENT_MODAL_VIEW_STATE,
    ACTION_COLLECTION_PAGE_TOGGLE_SECTIONS,
    ACTION_COLLECTION_PAGE_SET_SECTION_EXPANDED,
    ACTION_COLLECTION_PAGE_SET_SECTIONS,

    CollectionPageAction
} from '../../actions/pages/collectionPage';


export interface ICollectionPageState {
    addModalOpen: boolean;
    addFilter: string;
    sectionId: number;
    selected: Map<number, number>;
    sectionsExpanded: boolean;
    sectionExpanded: number;
    editModalOpen: boolean;
    previewModalOpen: boolean;
    previewComponentModalOpen: boolean;
    previewItemComponent: Dtos.ItemComponent;
}

const initialState: ICollectionPageState = {
    addModalOpen: false,
    addFilter: "",
    sectionId: 0,
    selected: new Map<number, number>(),
    sectionsExpanded: true,
    sectionExpanded: 0,
    editModalOpen: false,
    previewModalOpen: false,
    previewComponentModalOpen: false,
    previewItemComponent: null
};

function setAddModalViewState(state: ICollectionPageState, open: boolean): ICollectionPageState {
    const newState: ICollectionPageState = update(state, {
        addModalOpen: {
            $set: open
        },
        addFilter: {
            $set: ""
        },
        selected: {
            $set: new Map<number,number>()
        },
        sectionId: {
            $set: state.sectionExpanded
        }
    });
    return newState;
}

function setAddFilter(state: ICollectionPageState, filter: string): ICollectionPageState {
    const newState: ICollectionPageState = update(state, {
        addFilter: {
            $set: filter
        }
    });
    return newState;
}

function setSectionId(state: ICollectionPageState, sectionId: number): ICollectionPageState {
    const newState: ICollectionPageState = update(state, {
        sectionId: {
            $set: sectionId
        }
    });
    return newState;
}

function setAddSelected(state: ICollectionPageState, id: number, isSelected: boolean, count: number): ICollectionPageState {
    let selected = new Map(state.selected);
    
    if (isSelected) {
        //if (!selected.has(id)) { // selected.indexOf(id) == -1) {
        selected.set(id, count);
        //}
    } else {
        if (selected.has(id)) {
            selected.delete(id);
        }
    }
    console.log(selected);
    const newState: ICollectionPageState = update(state, {
        selected: {
            $set: selected
        }
    });
    return newState;
}

function setEditModalViewState(state: ICollectionPageState, open: boolean): ICollectionPageState {
    const newState: ICollectionPageState = update(state, {
        editModalOpen: {
            $set: open
        }
    });
    return newState;
}

function setPreviewModalViewState(state: ICollectionPageState, open: boolean): ICollectionPageState {
    const newState: ICollectionPageState = update(state, {
        previewModalOpen: {
            $set: open
        }
    });
    return newState;
}

function setPreviewComponentModalViewState(state: ICollectionPageState, open: boolean, previewItemComponent: Dtos.ItemComponent): ICollectionPageState {
    const newState: ICollectionPageState = update(state, {
        previewComponentModalOpen: {
            $set: open,
        },
        previewItemComponent: {
            $set: previewItemComponent
        }
    });
    return newState;
}

function toggleSectionsExpanded(state: ICollectionPageState): ICollectionPageState {
    const newState: ICollectionPageState = update(state, {
        sectionsExpanded: {
            $set: !state.sectionsExpanded
        }
    });
    return newState;
}

function setSectionsExpanded(state: ICollectionPageState, expanded: boolean): ICollectionPageState {
    const newState: ICollectionPageState = update(state, {
        sectionsExpanded: {
            $set: expanded
        }
    });
    return newState;
}

function setSectionExpanded(state: ICollectionPageState, index: number): ICollectionPageState {
    const newState: ICollectionPageState = update(state, {
        sectionExpanded: {
            $set: index
        }
    });
    return newState;
}

const CollectionPageReducer: Reducer<ICollectionPageState> = (state: ICollectionPageState = initialState, action) => { // : CollectionPageAction) => {
    //debugger;
    switch (action.type) {
        case ACTION_COLLECTION_PAGE_SET_ADD_MODAL_VIEW_STATE:
            return setAddModalViewState(state, action.open);
        case ACTION_COLLECTION_PAGE_SET_FILTER:
            return setAddFilter(state, action.filter);
        case ACTION_COLLECTION_PAGE_SET_SECTION:
            return setSectionId(state, action.sectionId);
        case ACTION_COLLECTION_PAGE_SET_ADD_SELECTED:
            return setAddSelected(state, action.id, action.isSelected, action.count)
        case ACTION_COLLECTION_PAGE_SET_EDIT_MODAL_VIEW_STATE:
            return setEditModalViewState(state, action.open);
        case ACTION_COLLECTION_PAGE_SET_PREVIEW_MODAL_VIEW_STATE:
            return setPreviewModalViewState(state, action.open);
        case ACTION_COLLECTION_PAGE_SET_PREVIEW_COMPONENT_MODAL_VIEW_STATE:
            return setPreviewComponentModalViewState(state, action.open, action.previewItemComponent);
        case ACTION_COLLECTION_PAGE_TOGGLE_SECTIONS:
            return toggleSectionsExpanded(state);
        case ACTION_COLLECTION_PAGE_SET_SECTIONS:
            return setSectionsExpanded(state, action.expanded);
        case ACTION_COLLECTION_PAGE_SET_SECTION_EXPANDED:
            return setSectionExpanded(state, action.index);
    }

    return state;
}

export default CollectionPageReducer;