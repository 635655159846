import * as React from 'react';
import './StatePage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { IStateReduxFormState, initialStateState } from "../../../reducers/reactReduxForms/state";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as StateActions from '../../../actions/states';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { Form, actions, Control } from 'react-redux-form';
import { LookupsLayout } from '../../index';

interface IStatePageParams {
    stateId: number;
}

interface IStatePageProps {

    form: Dtos.State & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IStateReduxFormState;

    location: Location;
    stateId: number;
    match: match<IStatePageParams>;
    history: History;

    // From state
    state: Dtos.State;
    lookups: Dtos.Lookup[];

    loadingState: boolean;
    loadStateSuccess: boolean;
    loadStateFailure: boolean;

    savingState: boolean;
    saveStateSuccess: boolean;
    saveStateFailure: boolean;

    creatingState: boolean;
    createStateSuccess: boolean;
    createStateFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface IStatePageActions {
    clearState: StateActions.IStateClearActionCreator;
    loadState: StateActions.IStateLoadByIdActionCreator;

    saveState: StateActions.IStateSaveActionCreator;
    createState: StateActions.IStateCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type StatePageProps = IStatePageProps & IStatePageActions;


const formName: string = "state";
const reduxFormName: string = "reduxForms.state";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class StatePage extends React.PureComponent<StatePageProps, any> {

    constructor(props: StatePageProps) {
        super(props);

        this.clearState = this.clearState.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadState,
            navigate,
            navigateReplace,
            stateId,
            createState
        } = this.props;

        if (stateId) {
            loadState(stateId);
        } else if (!stateId) {
            createState();
        }
    }

    componentWillReceiveProps(nextProps: StatePageProps) {
    }

    componentDidUpdate(prevProps: StatePageProps, prevState) {
        const {
            navigateReplace,
            stateId,
            state,
            form,
            navigate,
            loadState,
            loadingState,
            loadStateSuccess,
            createState,
            creatingState,
            createStateSuccess,
            savingState,
            saveStateSuccess,
            saveStateFailure,
            validationFailures
        } = this.props;

        if (stateId && prevProps.stateId != stateId) {
            loadState(stateId);
        }
        else if (!stateId && prevProps.stateId) {
            createState();
        }

        if (!loadingState && prevProps.loadingState) {
            if (loadStateSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingState && prevProps.savingState) {
            if (saveStateSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/settings/general/states")
                } else if (!form.saveAndReturn && stateId && stateId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !stateId && state && state.id > 0) {
                    navigate("/settings/general/states/" + state.id);
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingState && prevProps.creatingState) {
            if (createStateSuccess) {
                this.loadForm();
            }
            else {

            }
        }

    }

    componentWillUpdate(nextProps: StatePageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearState();
        loadForm(reduxFormName, initialStateState);
        resetForm(reduxFormName);
    }

    clearState() {
        this.props.clearState();
    }

    showState(): boolean {
        const {
            loadingState,
            loadStateSuccess
        } = this.props

        if (!loadingState && loadStateSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingState
        } = this.props;

        return <LookupsLayout loading={loadingState} permission={Dtos.Permission.StatesView}>
            {this.renderContent()}
        </LookupsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingState,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.StatesUpdate, Dtos.Permission.StatesCreate);
        const formDisabled: boolean = !hasPermission || savingState || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            value: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            }
        };

        

        return <div>
            <h1>State</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "state", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.State) {
        const {
            saveState
        } = this.props;

        if (data) {
            let changes: Dtos.Institution = {} as Dtos.Institution;
            saveState(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.State) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/general/states");
    }

    loadForm() {
        const {
            loadForm,
            state,
        } = this.props

        let extraData: Dtos.State = {} as Dtos.State;

        loadForm(reduxFormName, Object.assign({ ...state }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: StatePageProps): IStatePageProps => {

    let data: Dtos.State | undefined = !(state.states.formData instanceof Array) ? state.states.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        stateId: ownProps.match && ownProps.match.params.stateId && ownProps.match.params.stateId.toString() != "create" ? ownProps.match.params.stateId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        state: data!,

        loadingState: state.states.loadState && state.states.loadState.status == RequestState.Pending,
        loadStateSuccess: state.states.loadState && state.states.loadState.status == RequestState.Success,
        loadStateFailure: state.states.loadState && state.states.loadState.status == RequestState.Failure,

        savingState: state.states.saveState && state.states.saveState.status == RequestState.Pending,
        saveStateSuccess: state.states.saveState && state.states.saveState.status == RequestState.Success,
        saveStateFailure: state.states.saveState && state.states.saveState.status == RequestState.Failure,

        creatingState: state.states.createState && state.states.createState.status == RequestState.Pending,
        createStateSuccess: state.states.createState && state.states.createState.status == RequestState.Success,
        createStateFailure: state.states.createState && state.states.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: [],

        form: state.reduxForms.state,
        formState: state.states.formState,
        formProperties: state.states.formProperties,

        reduxFormState: state.reduxForms.formState.state,
        validationFailures: state ?
            state.states.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): IStatePageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadState: bindActionCreators(StateActions.LoadStateById, dispatch),
        clearState: bindActionCreators(StateActions.Clear, dispatch),

        saveState: bindActionCreators(StateActions.SaveState, dispatch),
        createState: bindActionCreators(StateActions.CreateState, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(StatePage);
