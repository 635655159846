import * as React from 'react';
import * as ReactTooltip from 'react-tooltip';

interface IPopoverCellProps {
    value?: any;
    popover?: any;
    overflow?: boolean;
}

type PopoverCellProps = IPopoverCellProps;

export default class PopoverCell extends React.Component<IPopoverCellProps, any> {
    render() {
        const {
            value,
            popover,
            overflow
        } = this.props

        let classNames = overflow ? "" : "truncate";
        
        return <><span data-tip={popover} className={classNames}>{value}</span><ReactTooltip multiline={true} /></>
    }
}
