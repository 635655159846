import { Reducer } from 'redux';
import { update } from '../../helpers/immutabilityHelper'
import * as Dtos from '../../dtos/Tmd.dtos';

import {
    ACTION_TRIAL_PAGE_SET_EMAILREMINDERSETTINGS_MODAL_VIEW_STATE,
    TrialsPageAction
} from '../../actions/pages/trialsPage';


export interface ITrialsPageState {
    emailReminderSettingsModalOpen: boolean;
    trialId: number;
}

const initialState: ITrialsPageState = {
    emailReminderSettingsModalOpen: false,
    trialId: null
};


function setEmailReminderSettingsModalViewState(state: ITrialsPageState, open: boolean, trialId: number): ITrialsPageState {
    const newState: ITrialsPageState = update(state, {
        emailReminderSettingsModalOpen: {
            $set: open
        },
        trialId: {
            $set: trialId
        }
    });
    return newState;
}

const TrialsPageReducer: Reducer<ITrialsPageState> = (state: ITrialsPageState = initialState, action) => { 
    switch (action.type) {
        case ACTION_TRIAL_PAGE_SET_EMAILREMINDERSETTINGS_MODAL_VIEW_STATE:
            return setEmailReminderSettingsModalViewState(state, action.open, action.trialId);
    }

    return state;
}

export default TrialsPageReducer;