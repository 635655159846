import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialTrialInstitutionState: Dtos.TrialInstitution & IRegistrationFormState = {
    id: null,
    active: null,
    trialId: null,
    trial: null,
    institutionId: null,
    institution: null,
    parentInstitution: null,
    parentInstitutionId: null,
    parentInstitutionDescription: null,
    groupId: null,
    isGroupLocked: null,
    group: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false,
    trialInstitutionItems: [],
    trialItemCategories: [],
    personnelRoleSummaries: [],
    display: null,
    groupDisplay: null,
    trialDisplay: null,
    institutionDisplay: null,
    notes: [],
    trialInstitutionStatusId: null,
    trialInstitutionStatus: null,
    dateActivated: null,
    firstPatient: null,
    dateWithdrawn: null,
    randomisedPatients: null,
    disableRecruitment: null,
    comments: null,
    expectedAccrual: null,
    totalAccrual: null,
    rgoSubmissionDate: null,
    rgoApprovalDate: null,
    initationMeetingDate: null,
    closureDate: null,
    personnelCount: null,
    ccOnRegistration: null,
    siteTypeId: null,
    siteType: null,
    activitiesUndertakenId: null,
    activitiesUndertaken: null,
    activitiesUndertakenOther: null,
}

export interface ITrialInstitutionReduxFormState {
    id: IFieldState<number>,
    trialId: IFieldState<number>,
    trial: IFieldState<Dtos.Trial>,
    institutionId: IFieldState<number>,
    institution: IFieldState<Dtos.Institution>,
    parentInstitutionId: IFieldState<number>,
    parentInstitution: IFieldState<Dtos.Institution>,
    parentInstitutionDescription: IFieldState<string>,
    groupId: IFieldState<number>,
    group: IFieldState<Dtos.Group>,
    active: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>,
    trialInstitutionItems: IFieldState<Dtos.TrialInstitutionItem[]>,
    trialItemCategories: IFieldState<Dtos.TrialItemCategory[]>,
    personnelRoleSummaries: IFieldState<Dtos.PersonnelRoleSummary[]>,
    notes: IFieldState<Dtos.NoteTypeNotes[]>,
    trialInstitutionStatusId: IFieldState<number>,
    trialInstitutionStatus: IFieldState<Dtos.TrialInstitutionStatus>,
    dateActivated: IFieldState<string>,
    firstPatient: IFieldState<string>,
    dateWithdrawn: IFieldState<string>,
    randomisedPatients: IFieldState<number>,
    disableRecruitment: IFieldState<boolean>,
    comments: IFieldState<string>
    expectedAccrual: IFieldState<number>,
    totalAccrual: IFieldState<number>,
    rgoSubmissionDate: IFieldState<string>,
    rgoApprovalDate: IFieldState<string>,
    initationMeetingDate: IFieldState<string>,
    closureDate: IFieldState<string>,
    personnelCount: IFieldState<number>,
    ccOnRegistration: IFieldState<string>,
    siteTypeId: IFieldState<number>,
    activitiesUndertakenId: IFieldState<number>,
    activitiesUndertakenOther: IFieldState<string>,
}
