import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trialTypesApi = {
    getTrialTypes: (): Observable<Dtos.GetTrialTypesResponse> => {
        //Create the request.
        let request: Dtos.GetTrialTypes = new Dtos.GetTrialTypes();

        //Send request.
        const response: Promise<Dtos.GetTrialTypesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrialTypeById: (id: number): Observable<Dtos.GetTrialTypeByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrialTypeById = new Dtos.GetTrialTypeById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTrialTypeByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrialType: (trialType: Dtos.TrialType): Observable<Dtos.SetTrialTypeResponse> => {

        let request: Dtos.SetTrialType = new Dtos.SetTrialType();

        request.trialType = trialType;

        // send request
        const response: Promise<Dtos.SetTrialTypeResponse> = client.post(request);

        return Observable.from(response);

    },
    createTrialType: (): Observable<Dtos.CreateTrialTypeResponse> => {
        //Create the request.
        let request: Dtos.CreateTrialType = new Dtos.CreateTrialType();

        //Send request.
        const response: Promise<Dtos.CreateTrialTypeResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}