import './PrimitiveFormInput.scss';
import * as Dtos from '../../../../dtos/Tmd.dtos';

import * as React from "react";
import * as classNames from "classnames";
import { IBaseFormInputProps } from '../';
import { actions, FieldAction, ChangeOptions, ModelAction } from 'react-redux-form';

import { FontAwesomeIcons, FontAwesomeIcon } from "../../../../constants/fontAwesomeIcons";
import RegistrationValidationMessage from "../../validation/RegistrationValidationMessage";
import ValidationMessage from '../../validation/ValidationMessage';

import * as ReactTooltip from 'react-tooltip';

import { DatePickerFormInput, TextFormInput, TextAreaFormInput, CheckboxFormInput, SelectFormInput, DatePickerFormInputFabric, NumberFormInput } from '../../inputs';

export interface IPrimitiveFormInputProps {
    item?: Dtos.ItemComponentPrimitive;
    textValue?: string;
    intValue?: number;
    bitValue?: boolean;
    numberValue?: number;
    dateValue?: any;
    feedbackIcon?: string;
    className?: string;
    label?: string;
    name: string;
    id: string;
    onChange?: (field: string, value: any) => void;
    validationFailures?: Dtos.ResponseError[];
    onFocus?: () => FieldAction;
    // value: any;
    disabled?: boolean;
    focused?: boolean;
    valid?: boolean;
    invalid?: boolean;
    hasSoftError?: boolean;
    hasHardError?: boolean;
    isRequired?: boolean;
    instructions?: string;
    isNaAllowed?: boolean;
    isNa?: boolean;
}

class PrimitiveFormInput extends React.PureComponent<IPrimitiveFormInputProps, any> {

    renderComponentContainer() {

    }

    renderPrimitive(labelLeft: boolean, item: Dtos.ItemComponentPrimitive, content: any) {
        let result;
        const {
            name,
            validationFailures
        } = this.props;

        const hasValidationFailures = validationFailures && validationFailures.length > 0

        if (labelLeft) {
            result = <div className="row">
                <div className="col-5">
                    {item.label != "" && (
                        <label className={classNames("mt-2 mb-1", { "is-invalid": hasValidationFailures })}>{this.props.isRequired && (<><FontAwesomeIcon icon={FontAwesomeIcons.Solid.ASTERISK} fixedWidth /><span>&nbsp;</span></>)}{item.label}</label>
                    )}
                </div>
                <div className="col-7">
                    <div className={"d-flex"}>
                        <div className={"flex-grow-1"}>
                            { content }
                        </div>
                        {this.props.isNaAllowed && <div className="p-2">
                            <CheckboxFormInput name={name + "_isNa"} id={name + "isNa"} value={this.props.isNa}
                                onChange={(v, o) => {
                                    if (this.props.onChange) {
                                        this.props.onChange("isNa", v);
                                    }
                                    return null;
                                }}
                                additionalContent={""}
                                disabled={false}
                                hideLabel={false} label={"N/A"} />
                        </div>}
                        {(this.props.instructions != undefined && this.props.instructions != "") && (<div data-tip={this.props.instructions}>
                            <p className="p-2 text-center"><FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_CIRCLE} fixedWidth /></p>
                            <ReactTooltip />
                        </div>)}
                    </div>
                </div>
            </div>;
        } else {
            result = <div className="row">
                <div className="col-12">
                    {item.label != "" && (
                        <label className={classNames("mt-2 mb-1", { "is-invalid": hasValidationFailures })}>{this.props.isRequired && (<><FontAwesomeIcon icon={FontAwesomeIcons.Solid.ASTERISK} fixedWidth /><span>&nbsp;</span></>)}{item.label}</label>
                    )}
                </div>
                <div className="col-12">
                    <div className={"d-flex"}>
                        <div className={"flex-grow-1"}>
                            {content}
                        </div>
                        {this.props.isNaAllowed && <div className="p-2">
                            <CheckboxFormInput name={name + "_isNa"} id={name + "isNa"} value={this.props.isNa}
                                onChange={(v, o) => {
                                    if (this.props.onChange) {
                                        this.props.onChange("isNa", v);
                                    }
                                    return null;
                                }}
                                additionalContent={""}
                                disabled={false}
                                hideLabel={false} label={"N/A"} />
                        </div>}
                        {(this.props.instructions != undefined && this.props.instructions != "") && (<div data-tip={this.props.instructions}>
                            <p className="p-2 text-center"><FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_CIRCLE} fixedWidth /></p>
                            <ReactTooltip />
                        </div>)}
                    </div>
                </div>
            </div>;
        }

        return result;
    }

    render() {
        const {
            className,
            item,
            textValue,
            intValue,
            bitValue,
            numberValue,
            dateValue,
            disabled,
            focused,
            id,
            invalid,
            label,
            name,
            onFocus,
            valid,
            feedbackIcon,
            hasSoftError,
            hasHardError,
            validationFailures
        } = this.props;

        const derivedClass = className ? className : "form-control";
        const hasValidationFailures = validationFailures && validationFailures.length > 0

        let labelLeft = true;
        let primitive;

        switch (item.itemPrimitive) {
            case Dtos.ItemPrimitive.Label:
                primitive = <label className={classNames("mt-2 mb-1")}>{item.label}</label>;
                break;
            case Dtos.ItemPrimitive.Text:
                primitive = this.renderPrimitive(labelLeft, item, (<TextFormInput
                    name={name ? name : "TextFormInput-" + item.id}
                    id={id ? id : "TextFormInput-" + item.id}
                    value={textValue}
                    disabled={disabled}
                    className={classNames("form-control", { "is-invalid": hasValidationFailures })}
                    onChange={(v, o) => {
                        if (this.props.onChange) {
                            this.props.onChange("textValue", v);
                        }
                        return null;
                    }}
                    placeholder={"Enter text..."}
                    mapProps={{}} />));
                break;
            case Dtos.ItemPrimitive.Comments:
                primitive = this.renderPrimitive(false, item, (<TextAreaFormInput
                    name={name ? name : "TextAreaFormInput-" + item.id}
                    id={id ? id : "TextAreaFormInput-" + item.id}
                    value={textValue}
                    disabled={disabled}
                    onChange={(v, o) => {
                        if (this.props.onChange) {
                            this.props.onChange("textValue", v);
                        }
                        return null;
                    }}
                    placeholder={"Enter text..."} mapProps={{}} />));
                break;
            case Dtos.ItemPrimitive.Date:
                primitive = this.renderPrimitive(labelLeft, item, (<DatePickerFormInputFabric
                    name={name ? name : "DatePickerFormInputFabric-" + item.id}
                    id={id ? id : "DatePickerFormInputFabric-" + item.id}
                    value={dateValue}
                    disabled={disabled}
                    onChange={(v, o) => {
                        if (this.props.onChange) {
                            this.props.onChange("dateValue", v);
                        }
                        return null;
                    }}
                />));
                break;
            case Dtos.ItemPrimitive.Checkbox:
                if (labelLeft) {
                    primitive = <>
                        <CheckboxFormInput
                            name={name ? name : "CheckboxFormInput-" + item.id}
                            disabled={disabled}
                            id={id ? id : "CheckboxFormInput-" + item.id}
                            value={bitValue}
                            onChange={(v, o) => {
                                if (this.props.onChange) {
                                    this.props.onChange("bitValue", v);
                                }
                                return null;
                            }}
                            additionalContent={"test"}
                            hideLabel={false}
                            label={item.label} />
                    </>;
                } else {
                    primitive = <>
                        <CheckboxFormInput
                            name={name ? name : "CheckboxFormInput-" + item.id}
                            disabled={disabled}
                            id={id ? id : "CheckboxFormInput-" + item.id}
                            value={bitValue}
                            onChange={(v, o) => {
                                if (this.props.onChange) {
                                    this.props.onChange("bitValue", v);
                                }
                                return null;
                            }}
                            additionalContent={"test"}
                            hideLabel={false}
                            label={item.label} />
                    </>;
                }
                break;
            case Dtos.ItemPrimitive.Documents:
                primitive = <>
                    {item.label != "" && (
                        <label>{item.label}</label>
                    )}
                    <p style={{ border: '1px dotted #888', textAlign: 'center', padding: '10px' }}>
                        Multiple file upload grid
                    </p>
                </>;
                break;
            case Dtos.ItemPrimitive.Numeric:
                primitive = this.renderPrimitive(labelLeft, item, (<NumberFormInput
                    name={name ? name : "NumberFormInput-" + item.id}
                    id={id ? id : "NumberFormInput-" + item.id}
                    value={numberValue}
                    disabled={disabled}
                    onChange={(v, o) => {
                        if (this.props.onChange) {
                            this.props.onChange("numberValue", v);
                        }
                        return null;
                    }}
                />));
                break;
            case Dtos.ItemPrimitive.Select:
                let options: any[] = [];
                if (item.itemComponentPrimitiveOptions) {
                    item.itemComponentPrimitiveOptions.filter(f => f.active).sort((a, b) => {
                        if (a.rank == b.rank) {
                            if (a.value < b.value) { return -1; }
                            if (a.value > b.value) { return 1; }
                            return 0;
                        } else { return a.rank - b.rank; }
                    }).forEach(option => {
                        options.push({ value: option.id, label: option.value });
                    });
                }
                primitive = this.renderPrimitive(labelLeft, item, (<SelectFormInput
                    name={name ? name : "SelectFormInput-" + item.id}
                    id={id ? id : "SelectFormInput-" + item.id}
                    disabled={disabled}
                    loading={false} multi={false}
                    options={options}
                    clearable={true}
                    backspaceRemoves={true}
                    value={intValue}
                    onChange={(v, o) => {
                        if (this.props.onChange) {
                            this.props.onChange("intValue", v);
                        }
                        return null;
                    }}
                />));
                break;
        }

        const hardFeedback = (hasHardError) && !disabled ?
            <span className={classNames("form-control-feedback block pr-2 is-invalid")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.BAN} fixedWidth />
            </span> :
            null;


        const feedback = (invalid && !disabled) && !hasHardError ?
            <span className={classNames("form-control-feedback pr-2")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE} fixedWidth />
            </span> :
            null;

        const softFeedback = hasSoftError && !disabled && (!invalid) ?
            <span className={classNames("form-control-feedback warning pr-2")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.EXCLAMATION_TRIANGLE} fixedWidth />
            </span> :
            null;

        return <div className="x-container-fluid">
            <div className="row primitive-form-input no-gutters" style={{ minHeight: '40px' }}>
                <div className={classNames("col-sm")}>

                    {primitive}
                    {hardFeedback}
                    {feedback}
                    {softFeedback}
                    {
                        hasValidationFailures ?
                            <ValidationMessage className="validation-messages min-registration-validation-message-width">
                                {
                                    validationFailures && validationFailures.length > 0 ?
                                        validationFailures.map((failure, index) => {
                                            return <span className="validation-message mb-2 mt-2" key={failure.fieldName + "-failure-" + index}>
                                                {failure.message}
                                            </span>
                                        }) :
                                        null
                                }
                            </ValidationMessage> :
                            null
                    }
                </div>
            </div>
        </div>
    }
}

export default PrimitiveFormInput;