import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Tmd.dtos';

import {
    ACTION_NOTE_LOAD_BY_ID,
    ACTION_NOTE_LOAD_FAILURE,
    ACTION_NOTE_LOAD_SUCCESS,

    ACTION_NOTE_SAVE,
    ACTION_NOTE_SAVE_SUCCESS,
    ACTION_NOTE_SAVE_FAILURE,

    ACTION_NOTE_CREATE,
    ACTION_NOTE_CREATE_SUCCESS,
    ACTION_NOTE_CREATE_FAILURE,

    ACTION_NOTE_CLEAR,
    NoteAction
} from '../actions/note';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState } from "./index";
import { updateFailureIndexToId } from "../helpers/formErrorHelper";

export interface INoteState {
    formData: Dtos.Note | Dtos.Note[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[],
    validationFailures: Dtos.ResponseError[];
    lookups: Dtos.Lookup[];
    loadState: IRequestState;
    saveState: IRequestState;
    updateState: IRequestState;
    createState: IRequestState;
}

const initialState: INoteState = {
    formData: undefined,
    formState: undefined,
    formProperties: undefined,
    formRoles: undefined,
    validationFailures: undefined,
    lookups: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    updateState: {
        status: RequestState.None
    } as IRequestState,
    createState: {
        status: RequestState.None
    } as IRequestState
};


function loading(state: INoteState, isLoading: boolean): INoteState {
    const newState: INoteState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(state: INoteState,
    formData: Dtos.Note | Dtos.Note[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    formRoles: Dtos.RoleType[],
    lookups: Dtos.Lookup[],
    responseStatus: Dtos.ResponseStatus
): INoteState {
    const newState: INoteState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        formRoles: {
            $set: formRoles
        },
        lookups: {
            $set: lookups
        },
        loadState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function loadFailure(state: INoteState, responseStatus: Dtos.ResponseStatus): INoteState {
    const newState: INoteState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: INoteState, propertiesToClear?: string[]): INoteState {

    if (!propertiesToClear) {
        const newState: INoteState = update(initialState, {});
        return newState;
    }

    let stateModifier: any = {};

    propertiesToClear.forEach(propertyToClear => {
        stateModifier[propertyToClear] = { $set: initialState[propertyToClear] }
    });

    const newState = update(state, stateModifier);

    return newState;
}

function saving(state: INoteState, isSaving: boolean): INoteState {
    const newState: INoteState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function saveSuccess(
    state: INoteState,
    formData: Dtos.Note | Dtos.Note[],
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus
): INoteState {
    const newState: INoteState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        saveState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function saveFailure(state: INoteState, responseStatus: Dtos.ResponseStatus): INoteState {
    const newState: INoteState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                meta: responseStatus.meta
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, state.formData) : undefined
        }
    });

    return newState;
}

function creating(state: INoteState, isCreating: boolean): INoteState {
    const newState: INoteState = update(state, {
        createState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function createSuccess(
    state: INoteState,
    formData: Dtos.Note,
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    formRoles: Dtos.RoleType[],
    lookups: Dtos.Lookup[]
): INoteState {
    const newState: INoteState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        formRoles: {
            $set: formRoles
        },
        lookups: {
            $set: lookups
        },
        createState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function createFailure(state: INoteState, responseStatus: Dtos.ResponseStatus): INoteState {
    const newState: INoteState = update(state, {
        createState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

const NoteReducer: Reducer<INoteState> = (state: INoteState = initialState, action: NoteAction) => {
    switch (action.type) {
        case ACTION_NOTE_LOAD_BY_ID:
            return loading(state, true);
        case ACTION_NOTE_LOAD_SUCCESS:
            return loadSuccess(state, action.formData, action.formState, action.formProperties, action.formRoles, action.lookups, action.responseStatus);
       case ACTION_NOTE_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_NOTE_CLEAR:
            return clear(state, action.propertiesToClear);
        case ACTION_NOTE_SAVE:
            return saving(state, true);
        case ACTION_NOTE_SAVE_SUCCESS:
            return saveSuccess(state, action.formData, action.formState, action.responseStatus);
        case ACTION_NOTE_SAVE_FAILURE:
            return saveFailure(state, action.responseStatus);
        case ACTION_NOTE_CREATE:
            return creating(state, true);
        case ACTION_NOTE_CREATE_SUCCESS:
            return createSuccess(state, action.formData, action.formState, action.formProperties, action.formRoles, action.lookups);
        case ACTION_NOTE_CREATE_FAILURE:
            return createFailure(state, action.responseStatus);

    }

    return state;
}

export default NoteReducer;