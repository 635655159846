import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_TRIALTRACKER_LOAD,
    ACTION_TRIALTRACKER_LOAD_BY_ID,

    ACTION_TRIALTRACKER_SAVE,

    ACTION_TRIALTRACKER_CREATE,

    ACTION_TRIALTRACKER_SYNC,

    ITrialTrackerLoadAction,
    ITrialTrackerLoadByIdAction,
    ITrialTrackerLoadFailureAction,
    ITrialTrackerLoadSuccessAction,

    ITrialTrackerSaveAction,
    ITrialTrackerSaveSuccessAction,
    ITrialTrackerSaveFailureAction,

    ITrialTrackerCreateAction,
    ITrialTrackerCreateSuccessAction,
    ITrialTrackerCreateFailureAction,

    ITrialTrackerSyncAction,
    ITrialTrackerSyncSuccessAction,
    ITrialTrackerSyncFailureAction,

    LoadSuccess,
    LoadFailure,


    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

    SyncSuccess,
    SyncFailure,
} from "../actions/trialTracker";
import { trialTrackersApi } from "../services/trialTrackers";


export const
    getTrialTrackersEpic = (action$): Observable<ITrialTrackerLoadSuccessAction | ITrialTrackerLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALTRACKER_LOAD)
            .mergeMap((action: ITrialTrackerLoadAction) => {
                return trialTrackersApi
                    .getTrialTrackers(action.trialId)
                    .map(response =>
                        LoadSuccess(response.trialTrackers, undefined, [], [], response.lookups, undefined)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTrialTrackersByIdEpic = (action$): Observable<ITrialTrackerLoadSuccessAction | ITrialTrackerLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALTRACKER_LOAD_BY_ID)
            .mergeMap((action: ITrialTrackerLoadByIdAction) => {
                return trialTrackersApi
                    .getTrailTrackerById(action.id)
                    .map(response =>
                        LoadSuccess(response.trialTracker, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setTrialTrackerEpic = (action$): Observable<ITrialTrackerSaveSuccessAction | ITrialTrackerSaveFailureAction> => {
        return action$
            .ofType(ACTION_TRIALTRACKER_SAVE)
            .mergeMap((action: ITrialTrackerSaveAction) => {
                return trialTrackersApi
                    .setTrialTracker(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.trialTracker, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createTrialTrackerEpic = (action$): Observable<ITrialTrackerCreateSuccessAction | ITrialTrackerCreateFailureAction> => {
        return action$
            .ofType(ACTION_TRIALTRACKER_CREATE)
            .mergeMap((action: ITrialTrackerCreateAction) => {
                return trialTrackersApi
                    .createTrialTracker(action.trialId)
                    .map(response =>
                        CreateSuccess(response.trialTracker, response.formState, response.formProperties, response.formRoles, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



