import * as React from 'react';
import './SiteLocationsPage.scss';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { RowInfo } from 'react-table';
import * as SiteLocationActions from '../../../actions/siteLocations';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { FormFilterInput } from '../../../components/form';
import { InstitutionLookupsLayout } from '../../index';

interface ISiteLocationsPageProps {

    location: Location;
    history: History;

    // From state
    formFilter: IFormFilter;
    siteLocation: Dtos.SiteLocation[];
    loadingSiteLocations: boolean;
    loadSiteLocationsSuccess: boolean;
    loadSiteLocationsFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    formRoles?: Dtos.RoleType[];
}

interface ISiteLocationsPageActions {
    clearSiteLocations: SiteLocationActions.ISiteLocationClearActionCreator;
    loadSiteLocations: SiteLocationActions.ISiteLocationLoadActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    filter: SiteLocationActions.ISiteLocationFilterActionCreator;
}

type SiteLocationsPageProps = ISiteLocationsPageProps & ISiteLocationsPageActions;

class SiteLocationsPage extends React.PureComponent<SiteLocationsPageProps, any> {

    constructor(props: SiteLocationsPageProps) {
        super(props);

        this.clearSiteLocations = this.clearSiteLocations.bind(this);
    }

    componentDidMount() {
        const {
            loadSiteLocations
        } = this.props;

        loadSiteLocations();
    }

    componentWillReceiveProps(nextProps: SiteLocationsPageProps) {
    }

    componentDidUpdate(prevProps: SiteLocationsPageProps, prevState) {
        const {
            navigateReplace
        } = this.props
    }

    componentWillUpdate(nextProps: SiteLocationsPageProps) {
    }

    componentWillUnmount() {
        this.clearSiteLocations();
    }

    clearSiteLocations() {
        this.props.clearSiteLocations();
    }

    showSiteLocations(): boolean {
        const {
            loadingSiteLocations,
            loadSiteLocationsSuccess
        } = this.props

        if (!loadingSiteLocations && loadSiteLocationsSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingSiteLocations
        } = this.props;

        return <InstitutionLookupsLayout loading={loadingSiteLocations} permission={Dtos.Permission.SiteLocationsView}>
            {this.renderContent()}
        </InstitutionLookupsLayout>;
    }

    renderContent() {
        const {
            permissions
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-6 mb-2"}>
                    <h2>Site Locations</h2>
                </div>
                <div className="col-6" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e) } />
                    
                    {AuthenticationHelper.hasPermission(permissions, Dtos.Permission.SiteLocationsCreate) && (
                        <Link className={"btn btn-primary"} url={"/settings/institution/sitelocations/create"}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.showSiteLocations() ?
                    "Loading Site Locations" :
                    null
            }
            {
                this.showSiteLocations() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {

        const {
            siteLocation
        } = this.props

        if (!siteLocation || siteLocation.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no site locations to view.</span>
            </div>
        }

        const showPagination = siteLocation.length > DEFAULT_PAGE_SIZE;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={siteLocation}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : siteLocation.length}
                pageSize={showPagination ? undefined : siteLocation.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no siteLocation to display"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {

                            const url: string = "/settings/institution/sitelocations/" + rowInfo.original.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.LOOKUP} fixedWidth />
                    },
                    {
                        id: "value",
                        Header: 'Name',
                        accessor: 'value',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    }
                ]}
            />
        </div>

    }

}

const mapStateToProps = (state: ITmdState, ownProps: SiteLocationsPageProps): ISiteLocationsPageProps => {

    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        history: ownProps.history,
        location: state.routing.location,

        formFilter: state.siteLocations.formFilter,
        siteLocation: state.siteLocations.formFilteredData, 

        loadingSiteLocations: state.siteLocations.loadState && state.siteLocations.loadState.status == RequestState.Pending,
        loadSiteLocationsSuccess: state.siteLocations.loadState && state.siteLocations.loadState.status == RequestState.Success,
        loadSiteLocationsFailure: state.siteLocations.loadState && state.siteLocations.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles
    };
};

const mapDispatchToProps = (dispatch): ISiteLocationsPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadSiteLocations: bindActionCreators(SiteLocationActions.LoadSiteLocation, dispatch),
        clearSiteLocations: bindActionCreators(SiteLocationActions.Clear, dispatch),

        filter: bindActionCreators(SiteLocationActions.Filter, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(SiteLocationsPage);
