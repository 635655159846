import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadTaskType: ITaskTypeLoadActionCreator = () => {
        return {
            type: ACTION_TASKTYPE_LOAD
        }
    },
    LoadTaskTypeById: ITaskTypeLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TASKTYPE_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ITaskTypeLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_TASKTYPE_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITaskTypeLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TASKTYPE_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTaskType: ITaskTypeSaveActionCreator = (formData) => {
        return {
            type: ACTION_TASKTYPE_SAVE,
            formData
        }
    },
    SaveSuccess: ITaskTypeSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TASKTYPE_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITaskTypeSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TASKTYPE_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTaskType: ITaskTypeCreateActionCreator = () => {

        return {
            type: ACTION_TASKTYPE_CREATE
        }
    },
    CreateSuccess: ITaskTypeCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_TASKTYPE_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: ITaskTypeCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TASKTYPE_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: ITaskTypeClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TASKTYPE_CLEAR,
            propertiesToClear
        }
    },
    Filter: ITaskTypeFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TASKTYPE_FILTER,
            formFilter
        }
    };



export const
    ACTION_TASKTYPE_LOAD = "ACTION_TASKTYPE_LOAD",
    ACTION_TASKTYPE_LOAD_BY_ID = "ACTION_TASKTYPE_LOAD_BY_ID",
    ACTION_TASKTYPE_LOAD_SUCCESS = "ACTION_TASKTYPE_LOAD_SUCCESS",
    ACTION_TASKTYPE_LOAD_FAILURE = "ACTION_TASKTYPE_LOAD_FAILURE",

    ACTION_TASKTYPE_SAVE = "ACTION_TASKTYPE_SAVE",
    ACTION_TASKTYPE_SAVE_SUCCESS = "ACTION_TASKTYPE_SAVE_SUCCESS",
    ACTION_TASKTYPE_SAVE_FAILURE = "ACTION_TASKTYPE_SAVE_FAILURE",

    ACTION_TASKTYPE_CREATE = "ACTION_TASKTYPE_CREATE",
    ACTION_TASKTYPE_CREATE_SUCCESS = "ACTION_TASKTYPE_CREATE_SUCCESS",
    ACTION_TASKTYPE_CREATE_FAILURE = "ACTION_TASKTYPE_CREATE_FAILURE",

    ACTION_TASKTYPE_CLEAR = "ACTION_TASKTYPE_CLEAR",

    ACTION_TASKTYPE_FILTER = "ACTION_TASKTYPE_FILTER";

export interface ITaskTypeLoadAction {
    type: "ACTION_TASKTYPE_LOAD";
}

export interface ITaskTypeLoadActionCreator {
    (): ITaskTypeLoadAction;
}

export interface ITaskTypeLoadSuccessAction {
    type: "ACTION_TASKTYPE_LOAD_SUCCESS";
    formData: Dtos.TaskType | Dtos.TaskType[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITaskTypeLoadSuccessActionCreator {
    (
        formData: Dtos.TaskType | Dtos.TaskType[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITaskTypeLoadSuccessAction;
}

export interface ITaskTypeLoadFailureAction {
    type: "ACTION_TASKTYPE_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITaskTypeLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITaskTypeLoadFailureAction;
}

export interface ITaskTypeLoadByIdAction {
    type: "ACTION_TASKTYPE_LOAD_BY_ID";
    id: number;
}

export interface ITaskTypeLoadByIdActionCreator {
    (id: number): ITaskTypeLoadByIdAction;
}


export interface ITaskTypeSaveAction {
    type: "ACTION_TASKTYPE_SAVE";
    formData: Dtos.TaskType;

}

export interface ITaskTypeSaveActionCreator {
    (
        formData: Dtos.TaskType
    ): ITaskTypeSaveAction;
}

export interface ITaskTypeSaveSuccessAction {
    type: "ACTION_TASKTYPE_SAVE_SUCCESS";
    formData: Dtos.TaskType;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITaskTypeSaveSuccessActionCreator {
    (
        formData: Dtos.TaskType,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITaskTypeSaveSuccessAction;
}

export interface ITaskTypeSaveFailureAction {
    type: "ACTION_TASKTYPE_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITaskTypeSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITaskTypeSaveFailureAction;
}

export interface ITaskTypeCreateAction {
    type: "ACTION_TASKTYPE_CREATE"
}

export interface ITaskTypeCreateActionCreator {
    (): ITaskTypeCreateAction
}

export interface ITaskTypeCreateSuccessAction {
    type: "ACTION_TASKTYPE_CREATE_SUCCESS";
    formData: Dtos.TaskType;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface ITaskTypeCreateSuccessActionCreator {
    (
        formData: Dtos.TaskType,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): ITaskTypeCreateSuccessAction;
}

export interface ITaskTypeCreateFailureAction {
    type: "ACTION_TASKTYPE_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITaskTypeCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITaskTypeCreateFailureAction;
}

export interface ITaskTypeClearAction {
    type: "ACTION_TASKTYPE_CLEAR";
    propertiesToClear?: string[];
}

export interface ITaskTypeClearActionCreator {
    (propertiesToClear?: string[]): ITaskTypeClearAction;
}

export interface ITaskTypeFilterActionCreator {
    (formFilter: IFormFilter): ITaskTypeFilterAction;
}

export interface ITaskTypeFilterAction {
    type: "ACTION_TASKTYPE_FILTER";
    formFilter: IFormFilter;
}

export type TaskTypeAction =
    ITaskTypeLoadAction |
    ITaskTypeLoadByIdAction |
    ITaskTypeLoadFailureAction |
    ITaskTypeLoadSuccessAction |
    ITaskTypeSaveAction |
    ITaskTypeSaveSuccessAction |
    ITaskTypeSaveFailureAction |
    ITaskTypeCreateAction |
    ITaskTypeCreateSuccessAction |
    ITaskTypeCreateFailureAction |
    ITaskTypeClearAction |
    ITaskTypeFilterAction;
