import * as React from 'react';
import './RolePage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { IRoleReduxFormState, initialRoleState } from "../../../reducers/reactReduxForms/role";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as RoleActions from '../../../actions/role';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { Form, actions, Control } from 'react-redux-form';
import { LookupsLayout } from '../../index';

interface IRolePageParams {
    roleId: number;
}

interface IRolePageProps {

    form: Dtos.Role & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IRoleReduxFormState;

    location: Location;
    roleId: number;
    match: match<IRolePageParams>;
    history: History;

    // From state
    role: Dtos.Role;
    lookups: Dtos.Lookup[];

    loadingRole: boolean;
    loadRoleSuccess: boolean;
    loadRoleFailure: boolean;

    savingRole: boolean;
    saveRoleSuccess: boolean;
    saveRoleFailure: boolean;

    creatingRole: boolean;
    createRoleSuccess: boolean;
    createRoleFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface IRolePageActions {
    clearRole: RoleActions.IRoleClearActionCreator;
    loadRole: RoleActions.IRoleLoadByIdActionCreator;

    saveRole: RoleActions.IRoleSaveActionCreator;
    createRole: RoleActions.IRoleCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type RolePageProps = IRolePageProps & IRolePageActions;


const formName: string = "role";
const reduxFormName: string = "reduxForms.role";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class RolePage extends React.PureComponent<RolePageProps, any> {

    constructor(props: RolePageProps) {
        super(props);

        this.clearRole = this.clearRole.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadRole,
            navigate,
            navigateReplace,
            roleId,
            createRole
        } = this.props;

        if (roleId) {
            loadRole(roleId);
        } else if (!roleId) {
            createRole();
        }
    }

    componentWillReceiveProps(nextProps: RolePageProps) {
    }

    componentDidUpdate(prevProps: RolePageProps, prevState) {
        const {
            navigateReplace,
            roleId,
            role,
            form,
            navigate,
            loadRole,
            loadingRole,
            loadRoleSuccess,
            createRole,
            creatingRole,
            createRoleSuccess,
            savingRole,
            saveRoleSuccess,
            saveRoleFailure,
            validationFailures
        } = this.props;

        if (roleId && prevProps.roleId != roleId) {
            loadRole(roleId);
        }
        else if (!roleId && prevProps.roleId) {
            createRole();
        }

        if (!loadingRole && prevProps.loadingRole) {
            if (loadRoleSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingRole && prevProps.savingRole) {
            if (saveRoleSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/settings/general/roles")
                } else if (!form.saveAndReturn && roleId && roleId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !roleId && role && role.id > 0) {
                    navigate("/settings/general/roles/" + role.id);
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingRole && prevProps.creatingRole) {
            if (createRoleSuccess) {
                this.loadForm();
            }
            else {

            }
        }

    }

    componentWillUpdate(nextProps: RolePageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearRole();
        loadForm(reduxFormName, initialRoleState);
        resetForm(reduxFormName);
    }

    clearRole() {
        this.props.clearRole();
    }

    showRole(): boolean {
        const {
            loadingRole,
            loadRoleSuccess
        } = this.props

        if (!loadingRole && loadRoleSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingRole
        } = this.props;

        return <LookupsLayout
            loading={loadingRole}
            permission={Dtos.Permission.RolesView}>
            {this.renderContent()}
        </LookupsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingRole,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.RolesUpdate, Dtos.Permission.RolesCreate);
        const formDisabled: boolean = !hasPermission || savingRole || (formState && formState.formStatus == Dtos.FormStatus.Disabled);
        const formDisabledSystem: boolean = formDisabled || (form && form.isSystem);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            value: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabledSystem,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabledSystem,
            },
            permissions: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="form-group row pt-4 pb-2 border border-bottom-1 border-top-0">
                    <div className="col-md-6">
                        <h5>Permissions</h5>
                        <p><small>Contact NewtonGreen if changes are required</small></p>
                    </div>
                    <div className="col-md-6">
                        {form.groupedRolePermissions && form.groupedRolePermissions.map((value, index) => <>
                            <p className=""><strong>{value.moduleDescription}</strong></p>
                            {value.permissions && value.permissions.map((permission, index) => <p className="pl-4">{permission.permissionDescription} {permission.institutionMatch && <span className="float-right badge badge-info">Assigned Institution</span>}</p>)}
                        </>)}
                    </div>
                </div>
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabledSystem,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            }
        };



        return <div>
            <h1>Role</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "role", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
        </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.Role) {
        const {
            saveRole
        } = this.props;

        if (data) {
            let changes: Dtos.Institution = {} as Dtos.Institution;
            saveRole(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.Role) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/general/roles");
    }

    loadForm() {
        const {
            loadForm,
            role,
        } = this.props

        let extraData: Dtos.Role = {} as Dtos.Role;

        loadForm(reduxFormName, Object.assign({ ...role }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: RolePageProps): IRolePageProps => {

    let role: Dtos.Role | undefined = !(state.roles.formData instanceof Array) ? state.roles.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        roleId: ownProps.match && ownProps.match.params.roleId && ownProps.match.params.roleId.toString() != "create" ? ownProps.match.params.roleId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        role: role!,

        loadingRole: state.roles.loadState && state.roles.loadState.status == RequestState.Pending,
        loadRoleSuccess: state.roles.loadState && state.roles.loadState.status == RequestState.Success,
        loadRoleFailure: state.roles.loadState && state.roles.loadState.status == RequestState.Failure,

        savingRole: state.roles.saveState && state.roles.saveState.status == RequestState.Pending,
        saveRoleSuccess: state.roles.saveState && state.roles.saveState.status == RequestState.Success,
        saveRoleFailure: state.roles.saveState && state.roles.saveState.status == RequestState.Failure,

        creatingRole: state.roles.createState && state.roles.createState.status == RequestState.Pending,
        createRoleSuccess: state.roles.createState && state.roles.createState.status == RequestState.Success,
        createRoleFailure: state.roles.createState && state.roles.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: state.roles.lookups,

        form: state.reduxForms.role,
        formState: state.roles.formState,
        formProperties: state.roles.formProperties,

        reduxFormState: state.reduxForms.formState.role,
        validationFailures: role ?
            state.roles.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): IRolePageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadRole: bindActionCreators(RoleActions.LoadRoleById, dispatch),
        clearRole: bindActionCreators(RoleActions.Clear, dispatch),

        saveRole: bindActionCreators(RoleActions.SaveRole, dispatch),
        createRole: bindActionCreators(RoleActions.CreateRole, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(RolePage);
