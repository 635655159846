import * as React from 'react';
import './GenderPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { IGenderReduxFormState, initialGenderState } from "../../../reducers/reactReduxForms/gender";
import { RegistrationFormComponent } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, NumberFormInput, CheckboxFormInput } from "../../../components/form/index";
import * as GenderActions from '../../../actions/genders';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { NotificationAlert } from "../../../components/common/index";
import { Form, actions } from 'react-redux-form';
import { LookupsLayout } from '../../index';
import * as AuthenticationHelper from '../../../helpers/authentication';

interface IGenderPageParams {
    genderId: number;
}

interface IGenderPageProps {

    form: Dtos.Gender & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IGenderReduxFormState;

    location: Location;
    genderId: number;
    match: match<IGenderPageParams>;
    history: History;

    // From state
    gender: Dtos.Gender;
    lookups: Dtos.Lookup[];

    loadingGender: boolean;
    loadGenderSuccess: boolean;
    loadGenderFailure: boolean;

    savingGender: boolean;
    saveGenderSuccess: boolean;
    saveGenderFailure: boolean;

    creatingGender: boolean;
    createGenderSuccess: boolean;
    createGenderFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface IGenderPageActions {
    clearGender: GenderActions.IGenderClearActionCreator;
    loadGender: GenderActions.IGenderLoadByIdActionCreator;

    saveGender: GenderActions.IGenderSaveActionCreator;
    createGender: GenderActions.IGenderCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type GenderPageProps = IGenderPageProps & IGenderPageActions;

const reduxFormName: string = "reduxForms.gender";

class GenderPage extends React.PureComponent<GenderPageProps, any> {

    constructor(props: GenderPageProps) {
        super(props);

        this.clearGender = this.clearGender.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadGender,
            genderId,
            createGender
        } = this.props;

        if (genderId) {
            loadGender(genderId);
        } else if (!genderId) {
            createGender();
        }
    }

    componentWillReceiveProps(nextProps: GenderPageProps) {
    }

    componentDidUpdate(prevProps: GenderPageProps, prevState) {
        const {
            genderId,
            gender,
            form,
            navigate,
            loadGender,
            loadingGender,
            loadGenderSuccess,
            createGender,
            creatingGender,
            createGenderSuccess,
            savingGender,
            saveGenderSuccess,
            validationFailures
        } = this.props;

        if (genderId && prevProps.genderId != genderId) {
            loadGender(genderId);
        }
        else if (!genderId && prevProps.genderId) {
            createGender();
        }

        if (!loadingGender && prevProps.loadingGender) {
            if (loadGenderSuccess) {
                this.loadForm();
            }
        }

        if (!savingGender && prevProps.savingGender) {
            if (saveGenderSuccess) {
                if (validationFailures && validationFailures.length > 0) {
                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {
                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);
                }
                if (form.saveAndReturn) {
                    navigate("/settings/general/genders")
                } else if (!form.saveAndReturn && genderId && genderId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !genderId && gender && gender.id > 0) {
                    navigate("/settings/general/genders/" + gender.id);
                }
            }
            else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

        if (!creatingGender && prevProps.creatingGender) {
            if (createGenderSuccess) {
                this.loadForm();
            }
        }
    }

    componentWillUpdate(nextProps: GenderPageProps) {
    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearGender();
        loadForm(reduxFormName, initialGenderState);
        resetForm(reduxFormName);
    }

    clearGender() {
        this.props.clearGender();
    }

    showGender(): boolean {
        const {
            loadingGender,
            loadGenderSuccess
        } = this.props

        if (!loadingGender && loadGenderSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingGender
        } = this.props;

        return <LookupsLayout loading={loadingGender} permission={Dtos.Permission.GendersView}>
            {this.renderContent()}
        </LookupsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingGender,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.GendersUpdate, Dtos.Permission.GendersCreate);
        const formDisabled: boolean = !hasPermission || savingGender || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            value: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            }
        };

        return <div>
            <h1>Gender</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "gender", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.Gender) {
        const {
            saveGender
        } = this.props;

        if (data) {
            let changes: Dtos.Gender = {} as Dtos.Gender;
            saveGender(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.Gender) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/general/genders");
    }

    loadForm() {
        const {
            loadForm,
            gender,
        } = this.props

        let extraData: Dtos.Gender = {} as Dtos.Gender;

        loadForm(reduxFormName, Object.assign({ ...gender }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: GenderPageProps): IGenderPageProps => {

    let gender: Dtos.Gender | undefined = !(state.genders.formData instanceof Array) ? state.genders.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        genderId: ownProps.match && ownProps.match.params.genderId && ownProps.match.params.genderId.toString() != "create" ? ownProps.match.params.genderId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        gender: gender!,

        loadingGender: state.genders.loadState && state.genders.loadState.status == RequestState.Pending,
        loadGenderSuccess: state.genders.loadState && state.genders.loadState.status == RequestState.Success,
        loadGenderFailure: state.genders.loadState && state.genders.loadState.status == RequestState.Failure,

        savingGender: state.genders.saveState && state.genders.saveState.status == RequestState.Pending,
        saveGenderSuccess: state.genders.saveState && state.genders.saveState.status == RequestState.Success,
        saveGenderFailure: state.genders.saveState && state.genders.saveState.status == RequestState.Failure,

        creatingGender: state.genders.createState && state.genders.createState.status == RequestState.Pending,
        createGenderSuccess: state.genders.createState && state.genders.createState.status == RequestState.Success,
        createGenderFailure: state.genders.createState && state.genders.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: [],

        form: state.reduxForms.gender,
        formState: state.genders.formState,
        formProperties: state.genders.formProperties,

        reduxFormState: state.reduxForms.formState.gender,
        validationFailures: gender ?
            state.genders.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): IGenderPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadGender: bindActionCreators(GenderActions.LoadGenderById, dispatch),
        clearGender: bindActionCreators(GenderActions.Clear, dispatch),

        saveGender: bindActionCreators(GenderActions.SaveGender, dispatch),
        createGender: bindActionCreators(GenderActions.CreateGender, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(GenderPage);
