import * as React from 'react';

import * as classNames from "classnames";
import './MyTrialsPage.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';



import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu, TruncatedCell } from "../../../components/layouts/index";

import * as TrialInstitutionsActions from '../../../actions/trialInstitution';
import * as PagingActions from '../../../actions/pages/pagingPage';

import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup, findLookupValue } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput, FormFilterInput } from '../../../components/form';
import { TrialSubMenu } from '../../../helpers/subMenuHelper';

interface IMyTrialsPageParams {
}

interface IMyTrialsPageProps {

    location: Location;
    match: match<IMyTrialsPageParams>;
    history: History;

    // From state
    formFilter: IFormFilter;
    formLength: number;
    trialInstitutions: Dtos.TrialInstitution[],

    loadingTrialInstitutions: boolean;
    loadTrialInstitutionsSuccess: boolean;
    loadTrialInstitutionsFailure: boolean;

    user: Dtos.User;
    lookups: Dtos.Lookup[];
    formRoles: Dtos.RoleType[];
    permissions?: Dtos.PersonnelTmdPermissions[];

    pageSize: number;
}

interface IMyTrialsPageActions {
    clearTrialInstitutions: TrialInstitutionsActions.ITrialInstitutionClearActionCreator;
    loadTrialInstitutions: TrialInstitutionsActions.ITrialInstitutionLoadActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    filter: TrialInstitutionsActions.ITrialInstitutionFilterActionCreator;

    setPageSize: PagingActions.IPagingPageSizeChangedStateActionCreator;
}

type MyTrialsPageProps = IMyTrialsPageProps & IMyTrialsPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class MyTrialsPage extends React.PureComponent<MyTrialsPageProps, any> {

    constructor(props: MyTrialsPageProps) {
        super(props);

        this.clearTrial = this.clearTrial.bind(this);
    }

    componentDidMount() {
        const {
            loadTrialInstitutions
        } = this.props;

        loadTrialInstitutions();
    }

    componentWillReceiveProps(nextProps: MyTrialsPageProps) {
    }

    componentDidUpdate(prevProps: MyTrialsPageProps, prevState) {
        const {
        } = this.props
    }

    componentWillUpdate(nextProps: MyTrialsPageProps) {
    }

    componentWillUnmount() {
        this.clearTrial();
    }

    clearTrial() {
        this.props.clearTrialInstitutions();
    }

    showTrial(): boolean {
        const {
            loadingTrialInstitutions,
            loadTrialInstitutionsSuccess
        } = this.props

        if (!loadingTrialInstitutions && loadTrialInstitutionsSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingTrialInstitutions,
        } = this.props;

        return <RestrictedLayout
            loading={loadingTrialInstitutions}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {

        return <div>
            <div className="row">
                <div className={"col-6 mb-2"}>
                    <h2>Trial Institutions</h2>
                </div>
                <div className="col-6" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} hideActiveToggle={true} />
                </div>
            </div>
            {
                !this.showTrial() ?
                    "Loading Trials" :
                    null
            }
            {
                this.showTrial() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {

        const {
            trialInstitutions,
            user,
            lookups,
            pageSize,
            setPageSize,
            permissions
        } = this.props

        if (!trialInstitutions || trialInstitutions.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no trial institutions</span>
            </div>
        }

        const showPagination = trialInstitutions.length > pageSize;
        
        return <div className="table-wrapper mb-3">
            <ReactTable
                data={trialInstitutions}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? pageSize : trialInstitutions.length}
                pageSize={showPagination ? pageSize : trialInstitutions.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no trial groups to display"
                resizable={false}
                onPageSizeChange={val => setPageSize(val)}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            if (column.id == "personnelCount" && AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialPersonnelRoleView, rowInfo.original.trialId, rowInfo.original.institutionId)) {
                                const url: string = "/trials/" + rowInfo.original.trialId + "/trialinstitution/" + rowInfo.original.id + "/personnel";
                                this.props.navigate(url);
                            } else if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialInstitutionView, rowInfo.original.trialId, rowInfo.original.institutionId)) {
                                const url: string = "/trials/" + rowInfo.original.trialId + "/trialinstitution/" + rowInfo.original.id + "/details";
                                this.props.navigate(url);
                            }

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <div className="large-small-icon"><FontAwesomeIcon className="large-icon" icon={FontAwesomeIcons.Light.TRIAL} fixedWidth /><FontAwesomeIcon className="small-icon" icon={FontAwesomeIcons.Light.INSTITUTION} fixedWidth /></div>
                    },
                    {
                        id: "protocolID",
                        Header: 'TROG Number',
                        accessor: 'trial.protocolID',
                        maxWidth: 180,
                        className: "d-flex align-items-center justify-content-center",
                    },
                    /*{
                        id: "title",
                        Header: 'Scientific Title',
                        accessor: 'trial.title',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value} />
                    }, */
                    {
                        id: "shortTitle",
                        Header: 'Short Title',
                        accessor: 'trial.shortTitle',
                        maxWidth: 280,
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value} />
                    },
                    {
                        id: "name",
                        Header: 'Institution',
                        accessor: 'institution.name',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => <TruncatedCell value={props.value} />
                    },
                    {
                        id: "trialInstitutionStatusId",
                        Header: 'Status',
                        accessor: 'trialInstitutionStatusId',
                        className: "d-flex align-items-center justify-content-center",
                        Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{findLookupValue("TrialInstitution", "TrialInstitutionStatusId", props.value, lookups)}</span><ReactTooltip /></div>
                    },
                    {
                        id: "personnelCount",
                        Header: 'Roles',
                        accessor: 'personnelCount',
                        maxWidth: 80,
                        className: "d-flex align-items-center justify-content-center",
                        Cell: (props) =>  <div><span className="text-info"><u>{props.value || "0"}</u></span></div>
                    },
                    {
                        id: "dateActivated",
                        Header: "Date Activated",
                        accessor: "dateActivated",
                        className: "d-flex align-items-center justify-content-center",
                        Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                    }
                ]}
            />
        </div>
    }
}

const mapStateToProps = (state: ITmdState, ownProps: MyTrialsPageProps): IMyTrialsPageProps => {
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,

        history: ownProps.history,
        location: state.routing.location,

        trialInstitutions: state.trialInstitution.formFilteredData!,
        formFilter: state.trialInstitution.formFilter,
        formLength: state.trialInstitution.formLength,
        lookups: state.trialInstitution.lookups,

        loadingTrialInstitutions: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Pending,
        loadTrialInstitutionsSuccess: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Success,
        loadTrialInstitutionsFailure: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles,
        pageSize: state.pagingPage.pageSize
    };
};

const mapDispatchToProps = (dispatch): IMyTrialsPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadTrialInstitutions: bindActionCreators(TrialInstitutionsActions.LoadTrialInstitution, dispatch),
        clearTrialInstitutions: bindActionCreators(TrialInstitutionsActions.Clear, dispatch),

        filter: bindActionCreators(TrialInstitutionsActions.Filter, dispatch),

        setPageSize: bindActionCreators(PagingActions.PageSizeChanged, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(MyTrialsPage);
