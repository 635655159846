import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadFormTemplateFileType: IFormTemplateFileTypeLoadActionCreator = () => {
        return {
            type: ACTION_FORMTEMPLATEFILETYPE_LOAD
        }
    },
    LoadFormTemplateFileTypeById: IFormTemplateFileTypeLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_FORMTEMPLATEFILETYPE_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IFormTemplateFileTypeLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_FORMTEMPLATEFILETYPE_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IFormTemplateFileTypeLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_FORMTEMPLATEFILETYPE_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveFormTemplateFileType: IFormTemplateFileTypeSaveActionCreator = (formData) => {
        return {
            type: ACTION_FORMTEMPLATEFILETYPE_SAVE,
            formData
        }
    },
    SaveSuccess: IFormTemplateFileTypeSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_FORMTEMPLATEFILETYPE_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: IFormTemplateFileTypeSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_FORMTEMPLATEFILETYPE_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateFormTemplateFileType: IFormTemplateFileTypeCreateActionCreator = () => {

        return {
            type: ACTION_FORMTEMPLATEFILETYPE_CREATE
        }
    },
    CreateSuccess: IFormTemplateFileTypeCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_FORMTEMPLATEFILETYPE_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: IFormTemplateFileTypeCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_FORMTEMPLATEFILETYPE_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IFormTemplateFileTypeClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_FORMTEMPLATEFILETYPE_CLEAR,
            propertiesToClear
        }
    },
    Filter: IFormTemplateFileTypeFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_FORMTEMPLATEFILETYPE_FILTER,
            formFilter
        }
    };



export const
    ACTION_FORMTEMPLATEFILETYPE_LOAD = "ACTION_FORMTEMPLATEFILETYPE_LOAD",
    ACTION_FORMTEMPLATEFILETYPE_LOAD_BY_ID = "ACTION_FORMTEMPLATEFILETYPE_LOAD_BY_ID",
    ACTION_FORMTEMPLATEFILETYPE_LOAD_SUCCESS = "ACTION_FORMTEMPLATEFILETYPE_LOAD_SUCCESS",
    ACTION_FORMTEMPLATEFILETYPE_LOAD_FAILURE = "ACTION_FORMTEMPLATEFILETYPE_LOAD_FAILURE",

    ACTION_FORMTEMPLATEFILETYPE_SAVE = "ACTION_FORMTEMPLATEFILETYPE_SAVE",
    ACTION_FORMTEMPLATEFILETYPE_SAVE_SUCCESS = "ACTION_FORMTEMPLATEFILETYPE_SAVE_SUCCESS",
    ACTION_FORMTEMPLATEFILETYPE_SAVE_FAILURE = "ACTION_FORMTEMPLATEFILETYPE_SAVE_FAILURE",

    ACTION_FORMTEMPLATEFILETYPE_CREATE = "ACTION_FORMTEMPLATEFILETYPE_CREATE",
    ACTION_FORMTEMPLATEFILETYPE_CREATE_SUCCESS = "ACTION_FORMTEMPLATEFILETYPE_CREATE_SUCCESS",
    ACTION_FORMTEMPLATEFILETYPE_CREATE_FAILURE = "ACTION_FORMTEMPLATEFILETYPE_CREATE_FAILURE",

    ACTION_FORMTEMPLATEFILETYPE_CLEAR = "ACTION_FORMTEMPLATEFILETYPE_CLEAR",

    ACTION_FORMTEMPLATEFILETYPE_FILTER = "ACTION_FORMTEMPLATEFILETYPE_FILTER";

export interface IFormTemplateFileTypeLoadAction {
    type: "ACTION_FORMTEMPLATEFILETYPE_LOAD";
}

export interface IFormTemplateFileTypeLoadActionCreator {
    (): IFormTemplateFileTypeLoadAction;
}

export interface IFormTemplateFileTypeLoadSuccessAction {
    type: "ACTION_FORMTEMPLATEFILETYPE_LOAD_SUCCESS";
    formData: Dtos.FormTemplateFileType | Dtos.FormTemplateFileType[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IFormTemplateFileTypeLoadSuccessActionCreator {
    (
        formData: Dtos.FormTemplateFileType | Dtos.FormTemplateFileType[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IFormTemplateFileTypeLoadSuccessAction;
}

export interface IFormTemplateFileTypeLoadFailureAction {
    type: "ACTION_FORMTEMPLATEFILETYPE_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IFormTemplateFileTypeLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IFormTemplateFileTypeLoadFailureAction;
}

export interface IFormTemplateFileTypeLoadByIdAction {
    type: "ACTION_FORMTEMPLATEFILETYPE_LOAD_BY_ID";
    id: number;
}

export interface IFormTemplateFileTypeLoadByIdActionCreator {
    (id: number): IFormTemplateFileTypeLoadByIdAction;
}


export interface IFormTemplateFileTypeSaveAction {
    type: "ACTION_FORMTEMPLATEFILETYPE_SAVE";
    formData: Dtos.FormTemplateFileType;

}

export interface IFormTemplateFileTypeSaveActionCreator {
    (
        formData: Dtos.FormTemplateFileType
    ): IFormTemplateFileTypeSaveAction;
}

export interface IFormTemplateFileTypeSaveSuccessAction {
    type: "ACTION_FORMTEMPLATEFILETYPE_SAVE_SUCCESS";
    formData: Dtos.FormTemplateFileType;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IFormTemplateFileTypeSaveSuccessActionCreator {
    (
        formData: Dtos.FormTemplateFileType,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): IFormTemplateFileTypeSaveSuccessAction;
}

export interface IFormTemplateFileTypeSaveFailureAction {
    type: "ACTION_FORMTEMPLATEFILETYPE_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IFormTemplateFileTypeSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IFormTemplateFileTypeSaveFailureAction;
}

export interface IFormTemplateFileTypeCreateAction {
    type: "ACTION_FORMTEMPLATEFILETYPE_CREATE"
}

export interface IFormTemplateFileTypeCreateActionCreator {
    (): IFormTemplateFileTypeCreateAction
}

export interface IFormTemplateFileTypeCreateSuccessAction {
    type: "ACTION_FORMTEMPLATEFILETYPE_CREATE_SUCCESS";
    formData: Dtos.FormTemplateFileType;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface IFormTemplateFileTypeCreateSuccessActionCreator {
    (
        formData: Dtos.FormTemplateFileType,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): IFormTemplateFileTypeCreateSuccessAction;
}

export interface IFormTemplateFileTypeCreateFailureAction {
    type: "ACTION_FORMTEMPLATEFILETYPE_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IFormTemplateFileTypeCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IFormTemplateFileTypeCreateFailureAction;
}

export interface IFormTemplateFileTypeClearAction {
    type: "ACTION_FORMTEMPLATEFILETYPE_CLEAR";
    propertiesToClear?: string[];
}

export interface IFormTemplateFileTypeClearActionCreator {
    (propertiesToClear?: string[]): IFormTemplateFileTypeClearAction;
}

export interface IFormTemplateFileTypeFilterActionCreator {
    (formFilter: IFormFilter): IFormTemplateFileTypeFilterAction;
}

export interface IFormTemplateFileTypeFilterAction {
    type: "ACTION_FORMTEMPLATEFILETYPE_FILTER";
    formFilter: IFormFilter;
}

export type FormTemplateFileTypeAction =
    IFormTemplateFileTypeLoadAction |
    IFormTemplateFileTypeLoadByIdAction |
    IFormTemplateFileTypeLoadFailureAction |
    IFormTemplateFileTypeLoadSuccessAction |
    IFormTemplateFileTypeSaveAction |
    IFormTemplateFileTypeSaveSuccessAction |
    IFormTemplateFileTypeSaveFailureAction |
    IFormTemplateFileTypeCreateAction |
    IFormTemplateFileTypeCreateSuccessAction |
    IFormTemplateFileTypeCreateFailureAction |
    IFormTemplateFileTypeClearAction |
    IFormTemplateFileTypeFilterAction;
