import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trialPublicationTypesApi = {
    getTrialPublicationTypes: (): Observable<Dtos.GetTrialPublicationTypesResponse> => {
        //Create the request.
        let request: Dtos.GetTrialPublicationTypes = new Dtos.GetTrialPublicationTypes();

        //Send request.
        const response: Promise<Dtos.GetTrialPublicationTypesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrialPublicationTypeById: (id: number): Observable<Dtos.GetTrialPublicationTypeByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrialPublicationTypeById = new Dtos.GetTrialPublicationTypeById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTrialPublicationTypeByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrialPublicationType: (trialPublicationType: Dtos.TrialPublicationType): Observable<Dtos.SetTrialPublicationTypeResponse> => {

        let request: Dtos.SetTrialPublicationType = new Dtos.SetTrialPublicationType();

        request.trialPublicationType = trialPublicationType;

        // send request
        const response: Promise<Dtos.SetTrialPublicationTypeResponse> = client.post(request);

        return Observable.from(response);

    },
    createTrialPublicationType: (): Observable<Dtos.CreateTrialPublicationTypeResponse> => {
        //Create the request.
        let request: Dtos.CreateTrialPublicationType = new Dtos.CreateTrialPublicationType();

        //Send request.
        const response: Promise<Dtos.CreateTrialPublicationTypeResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}