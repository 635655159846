import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const patientsApi = {
    getPatients: (trialId: number, institutionId: number): Observable<Dtos.GetPatientsResponse> => {
        //Create the request.
        let request: Dtos.GetPatients = new Dtos.GetPatients();
        request.trialId = trialId;
        request.institutionId = institutionId;

        //Send request.
        const response: Promise<Dtos.GetPatientsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getPatientById: (id: number): Observable<Dtos.GetPatientByIdResponse> => {
        //Create the request.
        let request: Dtos.GetPatientById = new Dtos.GetPatientById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetPatientByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    createPatient: (trialId: number, trialInstitutionId: number, institutionId: number): Observable<Dtos.CreatePatientResponse> => {
        //Create the request.
        let request: Dtos.CreatePatient = new Dtos.CreatePatient();

        request.trialId = trialId;
        request.trialInstitutionId = trialInstitutionId;
        request.institutionId = institutionId;

        //Send request.
        const response: Promise<Dtos.CreatePatientResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setPatient: (patient: Dtos.Patient): Observable<Dtos.SetPatientResponse> => {

        let request: Dtos.SetPatient = new Dtos.SetPatient();

        request.patient = patient;

        // send request
        const response: Promise<Dtos.SetPatientResponse> = client.post(request);

        return Observable.from(response);
    },
    importPatients: (trialId: number): Observable<Dtos.PostPatientsImportResponse> => {
        let request: Dtos.PostPatientsImport = new Dtos.PostPatientsImport();
        request.trialId = trialId;

        //Send request.
        const response: Promise<Dtos.PostPatientsImportResponse> = client.post(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}