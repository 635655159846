import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trialPresentationTypesApi = {
    getTrialPresentationTypes: (): Observable<Dtos.GetTrialPresentationTypesResponse> => {
        //Create the request.
        let request: Dtos.GetTrialPresentationTypes = new Dtos.GetTrialPresentationTypes();

        //Send request.
        const response: Promise<Dtos.GetTrialPresentationTypesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrialPresentationTypeById: (id: number): Observable<Dtos.GetTrialPresentationTypeByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrialPresentationTypeById = new Dtos.GetTrialPresentationTypeById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTrialPresentationTypeByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrialPresentationType: (trialPresentationType: Dtos.TrialPresentationType): Observable<Dtos.SetTrialPresentationTypeResponse> => {

        let request: Dtos.SetTrialPresentationType = new Dtos.SetTrialPresentationType();

        request.trialPresentationType = trialPresentationType;

        // send request
        const response: Promise<Dtos.SetTrialPresentationTypeResponse> = client.post(request);

        return Observable.from(response);

    },
    createTrialPresentationType: (): Observable<Dtos.CreateTrialPresentationTypeResponse> => {
        //Create the request.
        let request: Dtos.CreateTrialPresentationType = new Dtos.CreateTrialPresentationType();

        //Send request.
        const response: Promise<Dtos.CreateTrialPresentationTypeResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}