import * as Dtos from "../../dtos/Tmd.dtos";

export const
    toggleMessageModal: IItemComponentPageToggleMessageModalActionCreator = () => {
        return {
            type: ACTION_ITEMCOMPONENT_PAGE_TOGGLE_MESSAGE_MODAL
        }
    },

    setPreviewModalViewState: IItemComponentPageSetPreviewModalViewStateActionCreator = (open: boolean) => {
        return {
            type: ACTION_ITEMCOMPONENT_PAGE_SET_PREVIEW_MODAL_VIEW_STATE,
            open
        }
    },

    setAddPrimitiveModalViewState: IItemComponentPageSetAddPrimitiveModalViewStateActionCreator = (open: boolean) => {
        return {
            type: ACTION_ITEMCOMPONENT_PAGE_SET_ADDPRIMITIVE_MODAL_VIEW_STATE,
            open
        }
    },

    setRulesModalViewState: IItemComponentPageSetRulesModalViewStateActionCreator = (open: boolean) => {
        return {
            type: ACTION_ITEMCOMPONENT_PAGE_SET_RULES_MODAL_VIEW_STATE,
            open
        }
    }

export const
    ACTION_ITEMCOMPONENT_PAGE_TOGGLE_MESSAGE_MODAL = "ACTION_ITEMCOMPONENT_PAGE_TOGGLE_MESSAGE_MODAL",
    ACTION_ITEMCOMPONENT_PAGE_SET_PREVIEW_MODAL_VIEW_STATE = "ACTION_ITEMCOMPONENT_PAGE_SET_PREVIEW_MODAL_VIEW_STATE",
    ACTION_ITEMCOMPONENT_PAGE_SET_ADDPRIMITIVE_MODAL_VIEW_STATE = "ACTION_ITEMCOMPONENT_PAGE_SET_ADDPRIMITIVE_MODAL_VIEW_STATE",
    ACTION_ITEMCOMPONENT_PAGE_SET_RULES_MODAL_VIEW_STATE = "ACTION_ITEMCOMPONENT_PAGE_SET_RULES_MODAL_VIEW_STATE"

/* Actions */

export interface IItemComponentPageToggleMessageModalAction {
    type: "ACTION_ITEMCOMPONENT_PAGE_TOGGLE_MESSAGE_MODAL"
}

export interface IItemComponentPageSetPreviewModalViewStateAction {
    type: "ACTION_ITEMCOMPONENT_PAGE_SET_PREVIEW_MODAL_VIEW_STATE",
    open: boolean;
}

export interface IItemComponentPageSetAddPrimitiveModalViewStateAction {
    type: "ACTION_ITEMCOMPONENT_PAGE_SET_ADDPRIMITIVE_MODAL_VIEW_STATE",
    open: boolean;
}

export interface IItemComponentPageSetRulesModalViewStateAction {
    type: "ACTION_ITEMCOMPONENT_PAGE_SET_RULES_MODAL_VIEW_STATE",
    open: boolean;
}

/* Action Creators */
export interface IItemComponentPageToggleMessageModalActionCreator {
    (): IItemComponentPageToggleMessageModalAction;
}

export interface IItemComponentPageSetPreviewModalViewStateActionCreator {
    (open: boolean): IItemComponentPageSetPreviewModalViewStateAction;
}

export interface IItemComponentPageSetAddPrimitiveModalViewStateActionCreator {
    (open: boolean): IItemComponentPageSetAddPrimitiveModalViewStateAction;
}

export interface IItemComponentPageSetRulesModalViewStateActionCreator {
    (open: boolean): IItemComponentPageSetRulesModalViewStateAction;
}


/* Final Export of actions */
export type ItemComponentPageAction = IItemComponentPageToggleMessageModalAction |
    IItemComponentPageSetPreviewModalViewStateAction |
    IItemComponentPageSetAddPrimitiveModalViewStateAction |
    IItemComponentPageSetRulesModalViewStateAction;
