import * as React from 'react';

import * as classNames from "classnames";
import './InstitutionNotesPage.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';



import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";

import * as InstitutionActions from '../../../actions/institution';
import * as ToggleActions from '../../../actions/pages/togglePage';
import * as ToggleHelper from "../../../helpers/toggleHelper";
import { TogglePageItem } from '../../../reducers/pages/togglePage';

import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../../components/form';
import { InstitutionSubMenu } from '../../../helpers/subMenuHelper';

interface IInstitutionNotePageParams {
    institutionId: number;
}

interface IInstitutionNotePageProps {

    location: Location;
    institutionId: number;
    match: match<IInstitutionNotePageParams>;
    history: History;

    // From state
    institution: Dtos.Institution;

    loadingInstitution: boolean;
    loadInstitutionSuccess: boolean;
    loadInstitutionFailure: boolean;

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];
    toggleItems: TogglePageItem[]
}

interface IInstitutionNotePageActions {
    clearInstitution: InstitutionActions.IInstitutionClearActionCreator;
    loadInstitution: InstitutionActions.IInstitutionLoadByIdActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    toggleExpand: ToggleActions.IToggleExpandedStateActionCreator;
}

type InstitutionNotePageProps = IInstitutionNotePageProps & IInstitutionNotePageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class InstitutionNotePage extends React.PureComponent<InstitutionNotePageProps, any> {

    constructor(props: InstitutionNotePageProps) {
        super(props);

        this.clearInstitution = this.clearInstitution.bind(this);
    }

    componentDidMount() {
        const {
            loadInstitution,
            navigate,
            navigateReplace,
            institutionId
        } = this.props;

        loadInstitution(institutionId);
    }

    componentWillReceiveProps(nextProps: InstitutionNotePageProps) {
    }

    componentDidUpdate(prevProps: InstitutionNotePageProps, prevState) {
        const {
            navigateReplace
        } = this.props
    }

    componentWillUpdate(nextProps: InstitutionNotePageProps) {
    }

    componentWillUnmount() {
        this.clearInstitution();
    }

    clearInstitution() {
        this.props.clearInstitution();
    }

    showInstitution(): boolean {
        const {
            loadingInstitution,
            loadInstitutionSuccess
        } = this.props

        if (!loadingInstitution && loadInstitutionSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingInstitution,
            permissions,
            institution
        } = this.props;

        let id = institution ? institution.id : 0;
        let name = institution ? institution.name : "Institution";

        return <RestrictedLayout
            permission={Dtos.Permission.InstitutionNoteView}
            subMenuTitle={institution ? institution.display : ""}
            subMenuItems={InstitutionSubMenu(id, permissions)}
            loading={loadingInstitution}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            institution,
            loadingInstitution,
            loadInstitutionSuccess,
            loadInstitutionFailure,
            clearInstitution,
            permissions,
            institutionId
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-9 mb-2"}>
                    <h2>Institution Notes</h2>
                </div>
                <div className="col-3" style={{ textAlign: 'right' }}>
                    {(AuthenticationHelper.hasPermission(permissions, Dtos.Permission.InstitutionNoteCreate, null, institutionId) && institution && institution.id > 0) && (
                        <Link className={"btn btn-primary"} url={"/institutions/" + institution.id + '/notes/create'}>Create</Link>
                    )}
                </div>
            </div>
            {
                loadingInstitution ?
                    "Loading Notes" :
                    null
            }
            {
                !loadingInstitution ?
                    <div className="mt-2">
                        {this.renderTable()}
                    </div> :
                    null
            }
        </div>
    }

    toggle(noteTypeNotes: Dtos.NoteTypeNotes) {

    }

    renderTable() {
        const {
            institution,
            toggleItems,
            toggleExpand
        } = this.props;


        if (!institution || !institution.notes || institution.notes.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no notes to view.</span>
            </div>
        }

        const showPagination = false; //personnelRoleSummaries.length > DEFAULT_PAGE_SIZE;

        return <div>
            {institution && institution.notes && institution.notes.map((item, index) => {
                const key = "institution-notes-page-" + item.noteTypeId;
                const expanded = ToggleHelper.isToggleExpanded(toggleItems, key);
                return (
                    <div key={"note-type" + index}>
                        <div className={tableHeaderClass} style={tableStyleClass} onClick={() => toggleExpand(key)}>
                            <div>
                                <FontAwesomeIcon icon={!expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" />
                            </div>
                            <span>{item.noteType.value}</span>
                        </div>
                        {expanded && (
                            <ReactTable
                                data={item.notes}
                                pageSizeOptions={PAGE_SIZE_OPTIONS}
                                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : item.notes.length}
                                pageSize={showPagination ? undefined : item.notes.length}
                                showPagination={showPagination}
                                className="-striped -highlight -clickable mb-3"
                                noDataText="There are currently no notes for this type"
                                resizable={false}
                                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                                    return {
                                        onClick: (e, handleOriginal) => {
                                            console.log(rowInfo);
                                            const url: string = "/institutions/" + institution.id + '/notes/' + rowInfo.original.id;
                                            this.props.navigate(url);

                                            if (handleOriginal) {
                                                handleOriginal()
                                            }

                                        },
                                        style: {
                                            color: (rowInfo && rowInfo.original && rowInfo.original.active) ? '#212529' : '#9e9e9e'
                                        }
                                    }
                                }}
                                columns={
                                    [
                                        {
                                            id: "id",
                                            Header: ' ',
                                            accessor: "id",
                                            className: "d-flex align-items-center justify-content-center",
                                            maxWidth: 50,
                                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.NOTE} fixedWidth />
                                        },
                                        {
                                            id: "title",
                                            Header: "Title",
                                            accessor: "title",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left"
                                        },
                                        {
                                            id: "comments",
                                            Header: "Comments",
                                            accessor: "comments",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left"
                                        },
                                        {
                                            id: "dateEntered",
                                            Header: "Date Entered",
                                            accessor: "dateEntered",
                                            className: "d-flex align-items-center justify-content-center",
                                            maxWidth: 180,
                                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                                        }
                                    ]
                                }
                            />
                        )}
                    </div>
                )
            }
            )}
        </div>
    }

}


const mapStateToProps = (state: ITmdState, ownProps: InstitutionNotePageProps): IInstitutionNotePageProps => {

    let institution: Dtos.Institution | undefined = !(state.institutions.formData instanceof Array) ? state.institutions.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        institutionId: ownProps.match ? ownProps.match.params.institutionId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        institution: institution!,

        loadingInstitution: state.institutions.loadState && state.institutions.loadState.status == RequestState.Pending,
        loadInstitutionSuccess: state.institutions.loadState && state.institutions.loadState.status == RequestState.Success,
        loadInstitutionFailure: state.institutions.loadState && state.institutions.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        toggleItems: state.togglePage.items
    };
};

const mapDispatchToProps = (dispatch): IInstitutionNotePageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadInstitution: bindActionCreators(InstitutionActions.LoadInstitutionById, dispatch),
        clearInstitution: bindActionCreators(InstitutionActions.Clear, dispatch),

        toggleExpand: bindActionCreators(ToggleActions.ToggleExpanded, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(InstitutionNotePage);
