import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Tmd.dtos';

import {
    ACTION_COLLECTION_LOAD_BY_ID,
    ACTION_COLLECTION_LOAD_FAILURE,
    ACTION_COLLECTION_LOAD_SUCCESS,

    ACTION_COLLECTION_CREATE,
    ACTION_COLLECTION_CREATE_SUCCESS,
    ACTION_COLLECTION_CREATE_FAILURE,

    ACTION_COLLECTION_LOCK,
    ACTION_COLLECTION_LOCK_SUCCESS,
    ACTION_COLLECTION_LOCK_FAILURE,

    ACTION_COLLECTION_CLEAR,
    CollectionAction
} from '../actions/collection';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState } from "./index";
import { updateFailureIndexToId } from "../helpers/formErrorHelper";

export interface ICollectionState {
    formData: Dtos.Collection | Dtos.Collection[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    validationFailures: Dtos.ResponseError[];
    lookups: Dtos.Lookup[],
    loadState: IRequestState;
    saveState: IRequestState;
    updateState: IRequestState;
    createState: IRequestState;
    lockState: IRequestState;
}

const initialState: ICollectionState = {
    formData: undefined,
    formState: undefined,
    formProperties: undefined,
    validationFailures: undefined,
    lookups: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    updateState: {
        status: RequestState.None
    } as IRequestState,
    createState: {
        status: RequestState.None
    } as IRequestState,
    lockState: {
        status: RequestState.None
    } as IRequestState
};


function loading(state: ICollectionState, isLoading: boolean): ICollectionState {
    const newState: ICollectionState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(
    state: ICollectionState,
    formData: Dtos.Collection | Dtos.Collection[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[],
    responseStatus: Dtos.ResponseStatus
): ICollectionState {
    const newState: ICollectionState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        loadState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function loadFailure(state: ICollectionState, responseStatus: Dtos.ResponseStatus): ICollectionState {
    const newState: ICollectionState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: ICollectionState, propertiesToClear?: string[]): ICollectionState {

    if (!propertiesToClear) {
        const newState: ICollectionState = update(initialState, {});
        return newState;
    }

    let stateModifier: any = {};

    propertiesToClear.forEach(propertyToClear => {
        stateModifier[propertyToClear] = { $set: initialState[propertyToClear] }
    });

    const newState = update(state, stateModifier);

    return newState;
}

function creating(state: ICollectionState, isCreating: boolean): ICollectionState {
    const newState: ICollectionState = update(state, {
        createState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function createSuccess(
    state: ICollectionState,
    formData: Dtos.Collection,
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[]
): ICollectionState {
    const newState: ICollectionState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        createState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function createFailure(state: ICollectionState, responseStatus: Dtos.ResponseStatus): ICollectionState {
    const newState: ICollectionState = update(state, {
        createState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function locking(state: ICollectionState, isLocking: boolean): ICollectionState {
    const newState: ICollectionState = update(state, {
        lockState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function lockSuccess(
    state: ICollectionState,
    formData: Dtos.Collection | Dtos.Collection[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[],
    responseStatus: Dtos.ResponseStatus
): ICollectionState {
    const newState: ICollectionState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        lockState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function lockFailure(state: ICollectionState, responseStatus: Dtos.ResponseStatus): ICollectionState {
    const newState: ICollectionState = update(state, {
        lockState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

const CollectionReducer: Reducer<ICollectionState> = (state: ICollectionState = initialState, action: CollectionAction) => {
    switch (action.type) {
        case ACTION_COLLECTION_LOAD_BY_ID:
            return loading(state, true);
        case ACTION_COLLECTION_LOAD_SUCCESS:
            return loadSuccess(state, action.formData, action.formState, action.formProperties, action.lookups, action.responseStatus);
        case ACTION_COLLECTION_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_COLLECTION_CLEAR:
            return clear(state, action.propertiesToClear);
        case ACTION_COLLECTION_CREATE:
            return creating(state, true);
        case ACTION_COLLECTION_CREATE_SUCCESS:
            return createSuccess(state, action.formData, action.formState, action.formProperties, action.lookups);
        case ACTION_COLLECTION_CREATE_FAILURE:
            return createFailure(state, action.responseStatus);
        case ACTION_COLLECTION_LOCK:
            return locking(state, true);
        case ACTION_COLLECTION_LOCK_SUCCESS:
            return lockSuccess(state, action.formData, action.formState, action.formProperties, action.lookups, action.responseStatus);
        case ACTION_COLLECTION_LOCK_FAILURE:
            return lockFailure(state, action.responseStatus);
    }

    return state;
}

export default CollectionReducer;