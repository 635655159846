import * as Dtos from "../../dtos/Tmd.dtos";

export const
    PageSizeChanged: IPagingPageSizeChangedStateActionCreator = (pageSize: number) => {
        return {
            type: ACTION_PAGING_PAGESIZE_CHANGED,
            pageSize
        }
    }

export const
    ACTION_PAGING_PAGESIZE_CHANGED = "ACTION_PAGING_PAGESIZE_CHANGED"

/* Actions */

export interface IPagingPageSizeChangedStateAction {
    type: "ACTION_PAGING_PAGESIZE_CHANGED";
    pageSize: number;
}

/* Action Creators */

export interface IPagingPageSizeChangedStateActionCreator {
    (pageSize: number): IPagingPageSizeChangedStateAction;
}

/* Final Export of actions */

export type PagingPageAction = 
    IPagingPageSizeChangedStateAction;
