import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    Load: ITrialItemImportLoadByIdActionCreator = (id, trialId) => {
        return {
            type: ACTION_TRIALITEMIMPORT_LOAD_BY_ID,
            id,
            trialId
        }
    },
    LoadSuccess: ITrialItemImportLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALITEMIMPORT_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrialItemImportLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALITEMIMPORT_LOAD_FAILURE,
            responseStatus
        }
    },
    Save: ITrialItemImportSaveActionCreator = (formData) => {
        return {
            type: ACTION_TRIALITEMIMPORT_SAVE,
            formData
        }
    },
    SaveSuccess: ITrialItemImportSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRIALITEMIMPORT_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrialItemImportSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALITEMIMPORT_SAVE_FAILURE,
            responseStatus
        }
    },
    Clear: ITrialItemImportClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRIALITEMIMPORT_CLEAR,
            propertiesToClear
        }
    },
    Filter: ITrialItemImportFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRIALITEMIMPORT_FILTER,
            formFilter
        }
    };

export const
    ACTION_TRIALITEMIMPORT_LOAD_BY_ID = "ACTION_TRIALITEMIMPORT_LOAD_BY_ID",
    ACTION_TRIALITEMIMPORT_LOAD_SUCCESS = "ACTION_TRIALITEMIMPORT_LOAD_SUCCESS",
    ACTION_TRIALITEMIMPORT_LOAD_FAILURE = "ACTION_TRIALITEMIMPORT_LOAD_FAILURE",

    ACTION_TRIALITEMIMPORT_SAVE = "ACTION_TRIALITEMIMPORT_SAVE",
    ACTION_TRIALITEMIMPORT_SAVE_SUCCESS = "ACTION_TRIALITEMIMPORT_SAVE_SUCCESS",
    ACTION_TRIALITEMIMPORT_SAVE_FAILURE = "ACTION_TRIALITEMIMPORT_SAVE_FAILURE",

    ACTION_TRIALITEMIMPORT_CLEAR = "ACTION_TRIALITEMIMPORT_CLEAR",

    ACTION_TRIALITEMIMPORT_FILTER = "ACTION_TRIALITEMIMPORT_FILTER";

export interface ITrialItemImportLoadAction {
    type: "ACTION_TRIALITEMIMPORT_LOAD";
}

export interface ITrialItemImportLoadActionCreator {
    (): ITrialItemImportLoadAction;
}

export interface ITrialItemImportLoadSuccessAction {
    type: "ACTION_TRIALITEMIMPORT_LOAD_SUCCESS";
    formData: Dtos.TrialItemImport;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialItemImportLoadSuccessActionCreator {
    (
        formData: Dtos.TrialItemImport,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialItemImportLoadSuccessAction;
}

export interface ITrialItemImportLoadFailureAction {
    type: "ACTION_TRIALITEMIMPORT_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialItemImportLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialItemImportLoadFailureAction;
}

export interface ITrialItemImportLoadByIdAction {
    type: "ACTION_TRIALITEMIMPORT_LOAD_BY_ID";
    id: number;
    trialId: number;
}

export interface ITrialItemImportLoadByIdActionCreator {
    (id: number, trialId: number): ITrialItemImportLoadByIdAction;
}

export interface ITrialItemImportSaveAction {
    type: "ACTION_TRIALITEMIMPORT_SAVE";
    formData: Dtos.TrialItemImport;

}

export interface ITrialItemImportSaveActionCreator {
    (
        formData: Dtos.TrialItemImport
    ): ITrialItemImportSaveAction;
}

export interface ITrialItemImportSaveSuccessAction {
    type: "ACTION_TRIALITEMIMPORT_SAVE_SUCCESS";
    formData: Dtos.TrialItemImport;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialItemImportSaveSuccessActionCreator {
    (
        formData: Dtos.TrialItemImport,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrialItemImportSaveSuccessAction;
}

export interface ITrialItemImportSaveFailureAction {
    type: "ACTION_TRIALITEMIMPORT_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialItemImportSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialItemImportSaveFailureAction;
}

export interface ITrialItemImportClearAction {
    type: "ACTION_TRIALITEMIMPORT_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrialItemImportClearActionCreator {
    (propertiesToClear?: string[]): ITrialItemImportClearAction;
}

export interface ITrialItemImportFilterActionCreator {
    (formFilter: IFormFilter): ITrialItemImportFilterAction;
}

export interface ITrialItemImportFilterAction {
    type: "ACTION_TRIALITEMIMPORT_FILTER";
    formFilter: IFormFilter;
}

export type TrialItemImportAction =
    ITrialItemImportLoadByIdAction |
    ITrialItemImportLoadFailureAction |
    ITrialItemImportLoadSuccessAction |
    ITrialItemImportSaveAction |
    ITrialItemImportSaveSuccessAction |
    ITrialItemImportSaveFailureAction |
    ITrialItemImportClearAction |
    ITrialItemImportFilterAction;
