import * as React from 'react';
import './TrialTrackerPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { FileUploadFormInput } from "../../../components/form/inputs/FileUploadFormInput/index";
import { GridRemove } from "../../../enumerations/GridRemove";
import { ITrialTrackerReduxFormState, initialTrialTrackerState } from "../../../reducers/reactReduxForms/trialTracker";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInputFabric, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as TrialTrackerActions from '../../../actions/trialTracker';
import { TextAreaFormInput } from '../../../components/form';
import * as TrialActions from '../../../actions/trial';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { formatBytes, SOURCE_DOCUMENT_FILE_CONFIGURATION } from "../../../helpers/file";
import { DEFAULT_FILE_UPLOAD_VIEW, SOURCE_DOCUMENT_UPLOAD_VIEW } from "../../../components/form/inputs/FileUploadFormInput/FileUploadViews";
import { requiredText, requiredSourceDocument } from "../../../validators/index";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { initialSourceDocumentState } from '../../../reducers/reactReduxForms/sourceDocument';
import * as ReactTooltip from 'react-tooltip';
import { Form, actions, Control } from 'react-redux-form';
import ReactTable, { Column, RowInfo } from 'react-table';
import { TrialLayout } from '../../index';
import * as FormHelper from '../../../helpers/formHelper';

interface ITrialTrackerPageParams {
    trialId: number;
    trialTrackerId: number;
}

interface ITrialTrackerPageProps {

    form: Dtos.TrialTracker & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITrialTrackerReduxFormState;

    location: Location;
    trialId: number;
    trialTrackerId: number;
    match: match<ITrialTrackerPageParams>;
    history: History;

    // From state
    trialTracker: Dtos.TrialTracker;
    trial: Dtos.Trial;
    lookups: Dtos.Lookup[];

    loadingTrial: boolean;

    loadingTrialTracker: boolean;
    loadTrialTrackerSuccess: boolean;
    loadTrialTrackerFailure: boolean;

    savingTrialTracker: boolean;
    saveTrialTrackerSuccess: boolean;
    saveTrialTrackerFailure: boolean;

    creatingTrialTracker: boolean;
    createTrialTrackerSuccess: boolean;
    createTrialTrackerFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface ITrialTrackerPageActions {
    clearTrialTracker: TrialTrackerActions.ITrialTrackerClearActionCreator;
    loadTrialTracker: TrialTrackerActions.ITrialTrackerLoadByIdActionCreator;
    saveTrialTracker: TrialTrackerActions.ITrialTrackerSaveActionCreator;
    createTrialTracker: TrialTrackerActions.ITrialTrackerCreateActionCreator;

    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type TrialTrackerPageProps = ITrialTrackerPageProps & ITrialTrackerPageActions;

const reduxFormName: string = "reduxForms.trialTracker";

class TrialTrackerPage extends React.PureComponent<TrialTrackerPageProps, any> {

    constructor(props: TrialTrackerPageProps) {
        super(props);

        this.clearTrialTracker = this.clearTrialTracker.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTrialTracker,
            trialTrackerId,
            trialId,
            loadTrial,
            createTrialTracker
        } = this.props;

        if (trialTrackerId) {
            loadTrialTracker(trialTrackerId);
        } else {
            // change to create
            createTrialTracker(trialId);
        }
        loadTrial(trialId);
    }

    componentDidUpdate(prevProps: TrialTrackerPageProps, prevState) {
        const {
            form,
            trialId,
            trialTrackerId,
            trialTracker,
            loadTrialTracker,
            loadTrial,
            loadingTrialTracker,
            loadTrialTrackerSuccess,
            savingTrialTracker,
            saveTrialTrackerSuccess,
            validationFailures,
            navigate,
            createTrialTracker,
            creatingTrialTracker,
            createTrialTrackerSuccess
        } = this.props;

        if (trialId && prevProps.trialId != trialId) {
            loadTrial(trialId);
        }

        if (trialTrackerId && prevProps.trialTrackerId != trialTrackerId) {
            loadTrialTracker(trialTrackerId);
        }
        else if (!trialTrackerId && prevProps.trialTrackerId) {
            createTrialTracker(trialId);
        }

        if (!loadingTrialTracker && prevProps.loadingTrialTracker) {
            if (loadTrialTrackerSuccess) {
                this.loadForm();
            }
            else {

            }
        }

        if (!savingTrialTracker && prevProps.savingTrialTracker) {
            if (saveTrialTrackerSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);
                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/trials/" + trialId + "/trackers")
                } else if (!form.saveAndReturn && trialTrackerId && trialTrackerId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !trialTrackerId && trialTracker && trialTracker.id > 0) {
                   navigate("/trials/" + trialId + "/trackers/" + trialTracker.id)
                }
            }
            else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

        if (!creatingTrialTracker && prevProps.creatingTrialTracker) {
            if (createTrialTrackerSuccess) {
                this.loadForm();
            }
        }
    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearTrialTracker();
        loadForm(reduxFormName, initialTrialTrackerState);
        resetForm(reduxFormName);
    }

    clearTrialTracker() {
        this.props.clearTrialTracker();
        this.props.clearTrial();
    }

    showTrialTracker(): boolean {
        const {
            loadingTrial,
            loadingTrialTracker,
            loadTrialTrackerSuccess
        } = this.props

        if (!loadingTrial && !loadingTrialTracker && loadTrialTrackerSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrial,
            loadingTrialTracker
        } = this.props

        return <TrialLayout loading={loadingTrial || loadingTrialTracker} permission={ Dtos.Permission.TrialFormView }>{this.renderContent()}</TrialLayout>;
    }

    renderContent() {
        const {
            form,
            permissions,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingTrialTracker
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.TrialTrackerUpdate, Dtos.Permission.TrialTrackerCreate);
        const formDisabled: boolean = !hasPermission || savingTrialTracker || (formState && formState.formStatus == Dtos.FormStatus.Disabled);
        const formNew: boolean = FormHelper.isNew(formState, form);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            trackerMilestonAndType: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    trackerMilestoneId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialTracker", "trackerMilestoneId", lookups), form.trackerMilestoneId),
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + '.trackerMilestone', null);
                        },
                        size: 2
                    },
                    trackerTypeId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialTracker", "trackerTypeId", lookups), form.trackerTypeId),
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + '.trackerType', null);
                        },
                        size: 2
                    }
                }
            },
            subjectGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    subject: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextFormInput,
                        inputDisabled: formDisabled,
                    },
                }
            },
            statusAndAssigned: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    trackerStatusId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialTracker", "trackerStatusId", lookups), form.trackerStatusId),
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + '.trackerStatus', null);
                        },
                        size: 2
                    },
                    assignedToId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialTracker", "AssignedToId", lookups), form.assignedToId),
                        },
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + parentModel + '.AssignedTo', null);
                        },
                        size: 2
                    },
                }
            },
            dates: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    dateStarted: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                    dateStopped: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: DatePickerFormInput,
                        inputDisabled: formDisabled,
                        size: 2
                    },
                }
            },
            activeGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    active: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: CheckboxFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            label: "Active"
                        },
                        inputLabel: " ",
                        size: 2,
                    }
                }
            },
            addCommentGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                hide: formNew,
                components: {
                    addComment: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: TextAreaFormInput,
                        inputDisabled: formDisabled,
                        size: 1,
                    },
                    personnelRolesHeading: {
                        type: RegistrationComponent.RegistrationElement,
                        component: <div className="border-0 d-flex pt-4 pl-0">
                            <h5>Comments</h5>
                        </div>
                    },
                    comments: {
                        type: RegistrationComponent.RegistrationElement,
                        component: !form || !form.trialTrackerComments || form.trialTrackerComments.length == 0 ? <>
                            <div className="alert alert-info d-flex mt-3">
                                <div className="mr-2">
                                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                                </div>
                                <span>There are currently no comments to view.</span>
                            </div>
                        </> : <div className="table-wrapper mb-3 mt-3">
                            <ReactTable
                                data={form.trialTrackerComments}
                                pageSizeOptions={PAGE_SIZE_OPTIONS}
                                defaultPageSize={form.trialTrackerComments.length}
                                pageSize={form.trialTrackerComments.length}
                                showPagination={false}
                                className="-striped -highlight -clickable mb-3"
                                noDataText="There are currently no comments to display"
                                resizable={false}
                                onPageSizeChange={val => { }}
                                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                                    return {
                                        onClick: (e, handleOriginal) => {
                                            if (handleOriginal) {
                                                handleOriginal()
                                            }

                                        },
                                        style: {
                                            color: (rowInfo && rowInfo.original && rowInfo.original.active) ? '#212529' : '#9e9e9e'
                                        }
                                    }
                                }}
                                defaultSorted={[
                                    {
                                        id: "dateEntered",
                                        desc: true
                                    }
                                ]}
                                columns={[
                                    {
                                        id: "id",
                                        Header: ' ',
                                        accessor: "id",
                                        className: "d-flex align-items-center justify-content-center",
                                        maxWidth: 50,
                                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.NOTE} fixedWidth />
                                    },
                                    {
                                        id: "comments",
                                        Header: 'Comment',
                                        accessor: 'comments',
                                        className: "d-flex align-items-left justify-content-left",
                                        headerClassName: "text-left"
                                    },
                                    {
                                        id: "enteredBy",
                                        Header: 'Entered By',
                                        accessor: 'enteredBy',
                                        className: "d-flex align-items-left justify-content-left",
                                        headerClassName: "text-left",
                                        maxWidth: 180,
                                    },
                                    {
                                        id: "dateEntered",
                                        Header: 'Date Entered',
                                        accessor: 'dateEntered',
                                        className: "d-flex align-items-left justify-content-left",
                                        headerClassName: "text-left",
                                        maxWidth: 180,
                                        Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                                    },
                                ]}
                            />
                        </div>
                    }
                }
            },
            documentsHeading: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="border-0 d-flex pt-4 pl-0">
                    <h3>Documents</h3>
                </div>
            },
            sourceDocuments: {
                type: RegistrationComponent.RegistrationSourceDocuments,
                label: " ",
                disabled: formDisabled,
                components: {
                    sourceDocuments: {
                        type: RegistrationComponent.RegistrationGrid,
                        formName: "SourceDocument",
                        disabled: formDisabled,
                        label: "Add Source Document",
                        className: "source-documents-source-documents",
                        minRows: 0,
                        columnStyles: [
                            {
                                width: undefined,
                                minWidth: "200px"
                            },
                            {
                                width: "23%",
                                minWidth: "150px"
                            },
                            {
                                width: "20%",
                                minWidth: "150px"
                            },
                            {
                                width: "125px"
                            },
                        ],
                        removeConfig: GridRemove.All,
                        addRow: (gridComponent, index, array) => {
                            const state: Dtos.SourceDocument = Object.assign(
                                { ...gridComponent.initialState }
                            );
                            return state;
                        },
                        components: {
                            name: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: TextFormInput,
                                inputDisabled: formDisabled,
                                inputLabel: getFormPropertyLabel("CollectionInstance", "FileName", formProperties),
                                inputProps: {
                                    feedbackIcon: FontAwesomeIcons.Solid.BAN
                                },
                                validators: {
                                    required: requiredText
                                },
                                errors: {
                                    required: "File name is required."
                                },
                                errorComponent: ValidationMessage

                            },
                            fileType: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: SelectFormInput,
                                inputLabel: getFormPropertyLabel("CollectionInstance", "FileType", formProperties),
                                inputProps: {
                                    options: generateOptionsFromLookup(findLookup("SourceDocument", "FileType", lookups)),
                                    feedbackIcon: FontAwesomeIcons.Solid.BAN
                                },
                                validators: {
                                    required: value => value != undefined
                                },
                                errors: {
                                    required: "File type is required."
                                },
                                errorComponent: ValidationMessage
                            },
                            fileInformation: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: FileUploadFormInput,
                                inputDisabled: formDisabled,
                                inputLabel: getFormPropertyLabel("CollectionInstance", "Upload", formProperties),
                                changeAction: (model, value, form, parentModel) => {
                                    changeForm(model, value);
                                    const inputValue: Dtos.TemporaryFileInformation = value;
                                    const sourceDocument: Dtos.SourceDocument = form;

                                    if (sourceDocument.fileInformation &&
                                        inputValue &&
                                        sourceDocument.fileInformation.state != inputValue.state &&
                                        inputValue.state == Dtos.FileState.Success &&
                                        !sourceDocument.name) {
                                        changeForm(reduxFormName + parentModel + ".name", inputValue.temporaryFile.name.substr(0, inputValue.temporaryFile.name.lastIndexOf(inputValue.temporaryFile.extension)));
                                    }
                                },
                                inputProps: {
                                    render: SOURCE_DOCUMENT_UPLOAD_VIEW
                                },
                                validators: {
                                    required: requiredSourceDocument
                                },
                                errors: {
                                    required: "File must be uploaded."
                                },
                                errorComponent: ValidationMessage
                            },
                            fileConfiguration: {
                                type: RegistrationComponent.RegistrationGridElement,
                                label: " ",
                                render: SOURCE_DOCUMENT_FILE_CONFIGURATION
                            }
                        },
                        initialState: { ...initialSourceDocumentState }
                    },
                },
                keyReplace: "sourceDocuments",
                onRemove: (model: string) => {
                    console.log('onRemove', model);
                    changeForm(model + "active", false);
                    return true; // this was handled here
                },
                filterRow: (data: Dtos.SourceDocument) => {
                    console.info('filterRow', data);
                    if (data.active != true) {
                        return false
                    }
                    return true;
                }
            },
        };
        
        return <div>
            <h3>Tracker</h3>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >

                {
                    renderFormControls(form, "trialTracker", formComponents, lookups, validationFailures, formProperties, changeForm)
                }

                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
        </div>

    }

    renderErrorAlert(): any {
        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>
    }

    renderWarningAlert(): any {
        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.TrialTracker) {
        const {
            saveTrialTracker
        } = this.props;

        if (data) {
            let changes: Dtos.TrialTracker = {} as Dtos.TrialTracker;
            saveTrialTracker(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.TrialTracker) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            trialId,
            navigate
        } = this.props;

        navigate("/trials/" + trialId + "/trackers");
    }

    loadForm() {
        const {
            loadForm,
            trialTracker,
        } = this.props

        let extraData: Dtos.TrialTracker = {} as Dtos.TrialTracker;

        loadForm(reduxFormName, Object.assign({ ...trialTracker }, extraData));
    }
}


const mapStateToProps = (state: ITmdState, ownProps: TrialTrackerPageProps): ITrialTrackerPageProps => {

    let trialTracker: Dtos.TrialTracker | undefined = !(state.trialTracker.formData instanceof Array) ? state.trialTracker.formData : undefined;
    let trial: Dtos.Trial | undefined = !(state.trials.formData instanceof Array) ? state.trials.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,
        trialTrackerId: ownProps.match && ownProps.match.params.trialTrackerId && ownProps.match.params.trialTrackerId.toString() != "create" ? ownProps.match.params.trialTrackerId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trialTracker: trialTracker!,

        trial: trial!,

        loadingTrialTracker: state.trialTracker.loadState && state.trialTracker.loadState.status == RequestState.Pending,
        loadTrialTrackerSuccess: state.trialTracker.loadState && state.trialTracker.loadState.status == RequestState.Success,
        loadTrialTrackerFailure: state.trialTracker.loadState && state.trialTracker.loadState.status == RequestState.Failure,

        loadingTrial: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,

        savingTrialTracker: state.trialTracker.saveState && state.trialTracker.saveState.status == RequestState.Pending,
        saveTrialTrackerSuccess: state.trialTracker.saveState && state.trialTracker.saveState.status == RequestState.Success,
        saveTrialTrackerFailure: state.trialTracker.saveState && state.trialTracker.saveState.status == RequestState.Failure,

        creatingTrialTracker: state.trialTracker.createState && state.trialTracker.createState.status == RequestState.Pending,
        createTrialTrackerSuccess: state.trialTracker.createState && state.trialTracker.createState.status == RequestState.Success,
        createTrialTrackerFailure: state.trialTracker.createState && state.trialTracker.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: state.trialTracker.lookups,

        form: state.reduxForms.trialTracker,
        formState: state.trialTracker.formState,
        formProperties: state.trialTracker.formProperties,

        reduxFormState: state.reduxForms.formState.trialTracker,
        validationFailures: trialTracker ?
            state.trialTracker.validationFailures :
            undefined,

    };
};

const mapDispatchToProps = (dispatch): ITrialTrackerPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadTrialTracker: bindActionCreators(TrialTrackerActions.LoadTrialTrackerById, dispatch),
        clearTrialTracker: bindActionCreators(TrialTrackerActions.Clear, dispatch),

        saveTrialTracker: bindActionCreators(TrialTrackerActions.SaveTrialTracker, dispatch),
        createTrialTracker: bindActionCreators(TrialTrackerActions.CreateTrialTracker, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch)

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialTrackerPage);
