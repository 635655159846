import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialCategoryState: Dtos.Category & IRegistrationFormState = {
    id: null,
    value: null,
    rank: null,
    active: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false,
    trialInstitutionItemTemplates: null
}

export interface ICategoryReduxFormState {
    id: IFieldState<number>,
    name: IFieldState<string>,
    rank: IFieldState<number>,
    active: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>
}
