import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Tmd.dtos';

import {
    ACTION_PATIENT_LOAD,
    ACTION_PATIENT_LOAD_BY_ID,
    ACTION_PATIENT_LOAD_FAILURE,
    ACTION_PATIENT_LOAD_SUCCESS,

    ACTION_PATIENT_SAVE,
    ACTION_PATIENT_SAVE_SUCCESS,
    ACTION_PATIENT_SAVE_FAILURE,

    ACTION_PATIENT_CREATE,
    ACTION_PATIENT_CREATE_SUCCESS,
    ACTION_PATIENT_CREATE_FAILURE,

    ACTION_PATIENT_IMPORT,
    ACTION_PATIENT_IMPORT_FAILURE,
    ACTION_PATIENT_IMPORT_SUCCESS,

    ACTION_PATIENT_CLEAR,

    PatientAction
} from '../actions/patient';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState, IFormFilter } from "./index";
import { updateFailureIndexToId } from "../helpers/formErrorHelper";

export interface IPatientState {
    formData: Dtos.Patient | Dtos.Patient[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    formRoles: Dtos.RoleType[],
    validationFailures: Dtos.ResponseError[];
    lookups: Dtos.Lookup[],
    loadState: IRequestState;
    saveState: IRequestState;
    updateState: IRequestState;
    createState: IRequestState;
    importState: IRequestState;
    formFilter: IFormFilter;
    formFilteredData: Dtos.Patient[];
    formLength: number;
}

const initialState: IPatientState = {
    formData: undefined,
    formState: undefined,
    formProperties: undefined,
    formRoles: undefined,
    validationFailures: undefined,
    lookups: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    updateState: {
        status: RequestState.None
    } as IRequestState,
    createState: {
        status: RequestState.None
    } as IRequestState,
    importState: {
        status: RequestState.None
    } as IRequestState,
    formFilter: {
        search: "",
        showInActive: false
    },
    formFilteredData: [],
    formLength: 0
};


function loading(state: IPatientState, isLoading: boolean): IPatientState {
    const newState: IPatientState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(
    state: IPatientState,
    formData: Dtos.Patient | Dtos.Patient[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    formRoles: Dtos.RoleType[],
    lookups: Dtos.Lookup[],
    responseStatus: Dtos.ResponseStatus
): IPatientState {
    const newState: IPatientState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        formRoles: {
            $set: formRoles
        },
        lookups: {
            $set: lookups
        },
        loadState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function loadFailure(state: IPatientState, responseStatus: Dtos.ResponseStatus): IPatientState {
    const newState: IPatientState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: IPatientState, propertiesToClear?: string[]): IPatientState {

    if (!propertiesToClear) {
        const newState: IPatientState = update(initialState, {});
        return newState;
    }

    let stateModifier: any = {};

    propertiesToClear.forEach(propertyToClear => {
        stateModifier[propertyToClear] = { $set: initialState[propertyToClear] }
    });

    const newState = update(state, stateModifier);

    return newState;
}

function importing(state: IPatientState): IPatientState {
    const newState: IPatientState = update(state, {
        importState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function importSuccess(
    state: IPatientState,
    responseStatus: Dtos.ResponseStatus
): IPatientState {
    const newState: IPatientState = update(state, {
        importState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
    });
    return newState;
}

function importFailure(state: IPatientState, responseStatus: Dtos.ResponseStatus): IPatientState {
    const newState: IPatientState = update(state, {
        importState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });
    return newState;
}

function saving(state: IPatientState, isSaving: boolean): IPatientState {
    const newState: IPatientState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function saveSuccess(
    state: IPatientState,
    formData: Dtos.Patient | Dtos.Patient[],
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus
): IPatientState {
    const newState: IPatientState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        saveState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function saveFailure(state: IPatientState, responseStatus: Dtos.ResponseStatus): IPatientState {
    const newState: IPatientState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                meta: responseStatus.meta
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, state.formData) : undefined
        }
    });

    return newState;
}

function creating(state: IPatientState, isCreating: boolean): IPatientState {
    const newState: IPatientState = update(state, {
        createState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function createSuccess(
    state: IPatientState,
    formData: Dtos.Patient,
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    formRoles: Dtos.RoleType[],
    lookups: Dtos.Lookup[]
): IPatientState {
    const newState: IPatientState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        formRoles: {
            $set: formRoles
        },
        lookups: {
            $set: lookups
        },
        createState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function createFailure(state: IPatientState, responseStatus: Dtos.ResponseStatus): IPatientState {
    const newState: IPatientState = update(state, {
        createState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

const PatientReducer: Reducer<IPatientState> = (state: IPatientState = initialState, action: PatientAction) => {
    switch (action.type) {
        case ACTION_PATIENT_LOAD:
        case ACTION_PATIENT_LOAD_BY_ID:
            return loading(state, true);
        case ACTION_PATIENT_LOAD_SUCCESS:
            return loadSuccess(state, action.formData, action.formState, action.formProperties, action.formRoles, action.lookups, action.responseStatus);
        case ACTION_PATIENT_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_PATIENT_CLEAR:
            return clear(state, action.propertiesToClear);
        case ACTION_PATIENT_IMPORT:
            return importing(state);
        case ACTION_PATIENT_IMPORT_SUCCESS:
            return importSuccess(state, action.responseStatus);
        case ACTION_PATIENT_IMPORT_FAILURE:
            return importFailure(state, action.responseStatus);
        case ACTION_PATIENT_SAVE:
            return saving(state, true);
        case ACTION_PATIENT_SAVE_SUCCESS:
            return saveSuccess(state, action.formData, action.formState, action.responseStatus);
        case ACTION_PATIENT_SAVE_FAILURE:
            return saveFailure(state, action.responseStatus);
        case ACTION_PATIENT_CREATE:
            return creating(state, true);
        case ACTION_PATIENT_CREATE_SUCCESS:
            return createSuccess(state, action.formData, action.formState, action.formProperties, action.formRoles, action.lookups);
        case ACTION_PATIENT_CREATE_FAILURE:
            return createFailure(state, action.responseStatus);
    }

    return state;
}

export default PatientReducer;