import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_TRIALTREATMENT_LOAD,
    ACTION_TRIALTREATMENT_LOAD_BY_ID,

    ACTION_TRIALTREATMENT_SAVE,

    ACTION_TRIALTREATMENT_CREATE,

    ACTION_TRIALTREATMENT_SYNC,

    ITrialTreatmentLoadAction,
    ITrialTreatmentLoadByIdAction,
    ITrialTreatmentLoadFailureAction,
    ITrialTreatmentLoadSuccessAction,

    ITrialTreatmentSaveAction,
    ITrialTreatmentSaveSuccessAction,
    ITrialTreatmentSaveFailureAction,

    ITrialTreatmentCreateAction,
    ITrialTreatmentCreateSuccessAction,
    ITrialTreatmentCreateFailureAction,

    ITrialTreatmentSyncAction,
    ITrialTreatmentSyncSuccessAction,
    ITrialTreatmentSyncFailureAction,

    LoadSuccess,
    LoadFailure,


    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

    SyncSuccess,
    SyncFailure,
} from "../actions/trialTreatment";
import { trialTreatmentsApi } from "../services/trialTreatments";


export const
    getTrialTreatmentsEpic = (action$): Observable<ITrialTreatmentLoadSuccessAction | ITrialTreatmentLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALTREATMENT_LOAD)
            .mergeMap((action: ITrialTreatmentLoadAction) => {
                return trialTreatmentsApi
                    .getTrialTreatments(action.trialId)
                    .map(response =>
                        LoadSuccess(response.trialTreatments, undefined, [], [], response.lookups, undefined)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTrialTreatmentsByIdEpic = (action$): Observable<ITrialTreatmentLoadSuccessAction | ITrialTreatmentLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALTREATMENT_LOAD_BY_ID)
            .mergeMap((action: ITrialTreatmentLoadByIdAction) => {
                return trialTreatmentsApi
                    .getTrailTreatmentById(action.id)
                    .map(response =>
                        LoadSuccess(response.trialTreatment, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setTrialTreatmentEpic = (action$): Observable<ITrialTreatmentSaveSuccessAction | ITrialTreatmentSaveFailureAction> => {
        return action$
            .ofType(ACTION_TRIALTREATMENT_SAVE)
            .mergeMap((action: ITrialTreatmentSaveAction) => {
                return trialTreatmentsApi
                    .setTrialTreatment(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.trialTreatment, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createTrialTreatmentEpic = (action$): Observable<ITrialTreatmentCreateSuccessAction | ITrialTreatmentCreateFailureAction> => {
        return action$
            .ofType(ACTION_TRIALTREATMENT_CREATE)
            .mergeMap((action: ITrialTreatmentCreateAction) => {
                return trialTreatmentsApi
                    .createTrialTreatment(action.trialId)
                    .map(response =>
                        CreateSuccess(response.trialTreatment, response.formState, response.formProperties, response.formRoles, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    },
    syncTrialTreatmentEpic = (action$): Observable<ITrialTreatmentSyncSuccessAction | ITrialTreatmentSyncFailureAction> => {
        return action$
            .ofType(ACTION_TRIALTREATMENT_SYNC)
            .mergeMap((action: ITrialTreatmentSyncAction) => {
                return trialTreatmentsApi
                    .syncTrialTreatment(action.trialId)
                    .map(response =>
                        SyncSuccess(response.trialTreatments, undefined, [], [], response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(SyncFailure(error.responseStatus))
                    )
            });
    }



