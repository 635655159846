import './StaticFormInput.scss';

import * as React from "react";
import * as classNames from "classnames";
import { IBaseFormInputProps } from '../';

import { FontAwesomeIcons, FontAwesomeIcon } from "../../../../constants/fontAwesomeIcons";

export interface IStaticFormInputProps {
    maxLength?: number; mapProps
    type?: string;
    placeholder?: string;
}

class StaticFormInput extends React.PureComponent<IStaticFormInputProps & IBaseFormInputProps, any> {
    render() {
        const {
            className,
            disabled,
            focused,
            id,
            invalid,
            label,
            name,
            type,
            valid,
            value,
            maxLength,
            placeholder
        } = this.props;

        const derivedClass = className ? className : "form-control-plaintext";

        const textInput = <input
            readOnly={true}
            type={type ? type : "text"}
            className={classNames(
                derivedClass,
                { "is-invalid": invalid && !disabled }
            )}
            name={name}
            id={id}
            placeholder={placeholder}
            value={value}
            maxLength={maxLength}
            disabled={disabled}
        />;

        const feedback = invalid && !disabled ?
            <span className={classNames("form-control-feedback pr-2")}>
                <FontAwesomeIcon icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE} fixedWidth />
            </span> :
            null;

        return <div style={{ position: "relative" }}>
            {textInput}
            {feedback}
        </div>
    }
}

export default StaticFormInput;