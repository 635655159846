import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_TRIALGROUPITEM_LOAD_BY_ID,

    ACTION_TRIALGROUPITEM_SAVE,

    ACTION_TRIALGROUPITEM_CREATE,

    ITrialGroupItemLoadByIdAction,
    ITrialGroupItemLoadFailureAction,
    ITrialGroupItemLoadSuccessAction,

    ITrialGroupItemSaveAction,
    ITrialGroupItemSaveSuccessAction,
    ITrialGroupItemSaveFailureAction,

    ITrialGroupItemCreateAction,
    ITrialGroupItemCreateSuccessAction,
    ITrialGroupItemCreateFailureAction,

    LoadSuccess,
    LoadFailure,


    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,
} from "../actions/trialGroupItem";
import { trialGroupItemsApi } from "../services/trialGroupItems";


export const
    getTrialGroupItemsByIdEpic = (action$): Observable<ITrialGroupItemLoadSuccessAction | ITrialGroupItemLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALGROUPITEM_LOAD_BY_ID)
            .mergeMap((action: ITrialGroupItemLoadByIdAction) => {
                return trialGroupItemsApi
                    .getTrialGroupItemById(action.id)
                    .map(response =>
                        LoadSuccess(response.trialGroupItem, response.formState, response.formProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setTrialGroupItemEpic = (action$): Observable<ITrialGroupItemSaveSuccessAction | ITrialGroupItemSaveFailureAction> => {
        return action$
            .ofType(ACTION_TRIALGROUPITEM_SAVE)
            .mergeMap((action: ITrialGroupItemSaveAction) => {
                return trialGroupItemsApi
                    .setTrialGroupItem(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.trialGroupItem, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createTrialGroupItemEpic = (action$): Observable<ITrialGroupItemCreateSuccessAction | ITrialGroupItemCreateFailureAction> => {
        return action$
            .ofType(ACTION_TRIALGROUPITEM_CREATE)
            .mergeMap((action: ITrialGroupItemCreateAction) => {
                return trialGroupItemsApi
                    .createTrialGroupItem(action.trialGroupId, action.groupItemId)
                    .map(response =>
                        CreateSuccess(response.trialGroupItem, response.formState, response.formProperties, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



