import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD,
    ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD_BY_ID,

    ACTION_TRIALINSTITUTIONITEMTEMPLATES_SAVE,

    ACTION_TRIALINSTITUTIONITEMTEMPLATES_CREATE,

    ITrialInstitutionItemTemplateLoadAction,
    ITrialInstitutionItemTemplateLoadByIdAction,
    ITrialInstitutionItemTemplateLoadFailureAction,
    ITrialInstitutionItemTemplateLoadSuccessAction,

    ITrialInstitutionItemTemplateSaveAction,
    ITrialInstitutionItemTemplateSaveSuccessAction,
    ITrialInstitutionItemTemplateSaveFailureAction,

    ITrialInstitutionItemTemplateCreateAction,
    ITrialInstitutionItemTemplateCreateSuccessAction,
    ITrialInstitutionItemTemplateCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/trialInstitutionItemTemplate";
import { trialInstitutionItemTemplatesApi } from "../services/trialInstitutionItemTemplates";


export const
    getTrialInstitutionItemTemplatesEpic = (action$): Observable<ITrialInstitutionItemTemplateLoadSuccessAction | ITrialInstitutionItemTemplateLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD)
            .mergeMap((action: ITrialInstitutionItemTemplateLoadAction) => {
                return trialInstitutionItemTemplatesApi
                    .getTrialInstitutionItemTemplates(action.trialId)
                    .map(response =>
                        LoadSuccess(response.trialInstitutionItemTemplates, response.trialInstitutionItemTemplatesByCategory, undefined, undefined, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTrialInstitutionItemTemplateByIdEpic = (action$): Observable<ITrialInstitutionItemTemplateLoadSuccessAction | ITrialInstitutionItemTemplateLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIALINSTITUTIONITEMTEMPLATES_LOAD_BY_ID)
            .mergeMap((action: ITrialInstitutionItemTemplateLoadByIdAction) => {
                return trialInstitutionItemTemplatesApi
                    .getTrialInstitutionItemTemplateById(action.id)
                    .map(response =>
                        LoadSuccess(response.trialInstitutionItemTemplate, [], response.formState, response.formProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setTrialInstitutionItemTemplateEpic = (action$): Observable<ITrialInstitutionItemTemplateSaveSuccessAction | ITrialInstitutionItemTemplateSaveFailureAction> => {
        return action$
            .ofType(ACTION_TRIALINSTITUTIONITEMTEMPLATES_SAVE)
            .mergeMap((action: ITrialInstitutionItemTemplateSaveAction) => {
                return trialInstitutionItemTemplatesApi
                    .setTrialInstitutionItemTemplate(action.formData, action.collectionFormData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.trialInstitutionItemTemplate, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createTrialInstitutionItemTemplateEpic = (action$): Observable<ITrialInstitutionItemTemplateCreateSuccessAction | ITrialInstitutionItemTemplateCreateFailureAction> => {
        return action$
            .ofType(ACTION_TRIALINSTITUTIONITEMTEMPLATES_CREATE)
            .mergeMap((action: ITrialInstitutionItemTemplateCreateAction) => {
                return trialInstitutionItemTemplatesApi
                    .createTrialInstitutionItemTemplate(action.trialId)
                    .map(response =>
                        CreateSuccess(
                            response.trialInstitutionItemTemplate,
                            response.formState,
                            response.formProperties,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



