import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const interestsApi = {
    getInterests: (): Observable<Dtos.GetInterestsResponse> => {
        //Create the request.
        let request: Dtos.GetInterests = new Dtos.GetInterests();

        //Send request.
        const response: Promise<Dtos.GetInterestsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getInterestById: (id: number): Observable<Dtos.GetInterestByIdResponse> => {
        //Create the request.
        let request: Dtos.GetInterestById = new Dtos.GetInterestById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetInterestByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setInterest: (interest: Dtos.Interest): Observable<Dtos.SetInterestResponse> => {

        let request: Dtos.SetInterest = new Dtos.SetInterest();

        request.interest = interest;

        // send request
        const response: Promise<Dtos.SetInterestResponse> = client.post(request);

        return Observable.from(response);

    },
    createInterest: (): Observable<Dtos.CreateInterestResponse> => {
        //Create the request.
        let request: Dtos.CreateInterest = new Dtos.CreateInterest();

        //Send request.
        const response: Promise<Dtos.CreateInterestResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}