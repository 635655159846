import * as React from 'react';
import './TrialInstitutionPersonnelPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import * as ReactTooltip from 'react-tooltip';
import { ITrialInstitutionReduxFormState } from "../../../reducers/reactReduxForms/trialInstitution";
import * as TrialInstitutionActions from '../../../actions/trialInstitution';
import * as TrialActions from '../../../actions/trial';
import * as ToggleActions from '../../../actions/pages/togglePage';
import * as ToggleHelper from "../../../helpers/toggleHelper";
import { TogglePageItem } from '../../../reducers/pages/togglePage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { ModalSize } from '../../../enumerations/ModalSize';
import { TextAreaFormInput, FormFilterInput } from '../../../components/form';
import { TrialInstitutionLayout } from '../../index';

interface ITrialInstitutionPersonnelPageParams {
    trialId: number;
    trialInstitutionId: number;
}

interface ITrialInstitutionPersonnelPageProps {

    form: Dtos.TrialInstitution & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITrialInstitutionReduxFormState;


    location: Location;
    trialId: number;
    trialInstitutionId: number;
    match: match<ITrialInstitutionPersonnelPageParams>;
    history: History;

    // From state
    trialInstitution: Dtos.TrialInstitution;
    formFilter: IFormFilter;
    personnelRoleSummaries: Dtos.PersonnelRoleSummary[];

    loadingTrialInstitution: boolean;
    loadTrialInstitutionSuccess: boolean;
    loadTrialInstitutionFailure: boolean;

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];
    toggleItems: TogglePageItem[]
}

interface ITrialInstitutionPersonnelPageActions {
    clearTrialInstitution: TrialInstitutionActions.ITrialInstitutionClearActionCreator;
    loadTrialInstitution: TrialInstitutionActions.ITrialInstitutionLoadByIdActionCreator;

    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    toggleExpand: ToggleActions.IToggleExpandedStateActionCreator;

    filter: TrialInstitutionActions.ITrialInstitutionPersonnelRolesFilterActionCreator;
}

type TrialInstitutionPersonnelPageProps = ITrialInstitutionPersonnelPageProps & ITrialInstitutionPersonnelPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class TrialInstitutionPersonnelPage extends React.PureComponent<TrialInstitutionPersonnelPageProps, any> {

    constructor(props: TrialInstitutionPersonnelPageProps) {
        super(props);

        this.clear = this.clear.bind(this);
        this.toggleExportMenu = this.toggleExportMenu.bind(this);

        this.state = {
            exportToggle: false
        };
    }

    componentDidMount() {
        const {
            loadTrial,
            loadTrialInstitution,
            navigate,
            navigateReplace,
            trialId,
            trialInstitutionId
        } = this.props;

        if (trialInstitutionId) {
            loadTrialInstitution(trialInstitutionId);

            if (trialId) {
                loadTrial(trialId);
            }
        } else {
            // redirect
            navigate("/trials/" + trialId + "/institutions");
        }
    }

    componentWillReceiveProps(nextProps: TrialInstitutionPersonnelPageProps) {
    }

    componentDidUpdate(prevProps: TrialInstitutionPersonnelPageProps, prevState) {
        const {
            trialId,
            trialInstitutionId,
            navigateReplace,
            loadTrial,
            loadTrialInstitution,
        } = this.props;

        if (trialInstitutionId && prevProps.trialInstitutionId != trialInstitutionId) {
            loadTrialInstitution(trialInstitutionId);
        }

        if (trialId && prevProps.trialId != trialId) {
            loadTrial(trialId);
        }
    }

    componentWillUpdate(nextProps: TrialInstitutionPersonnelPageProps) {
    }

    componentWillUnmount() {
        this.clear();
    }

    toggleExportMenu() {
        this.setState({
            exportToggle: !this.state.exportToggle
        });
    }

    clear() {
        this.props.clearTrialInstitution();
        this.props.clearTrial();
    }

    show(): boolean {
        const {
            loadingTrialInstitution,
            loadTrialInstitutionSuccess
        } = this.props

        if (!loadingTrialInstitution && loadTrialInstitutionSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrialInstitution,
            loadTrialInstitutionSuccess
        } = this.props

        return <TrialInstitutionLayout
            loading={loadingTrialInstitution}
            permission={Dtos.Permission.TrialPersonnelRoleView}
        >
            {this.renderContent()}
        </TrialInstitutionLayout>;
    }

    renderContent() {
        const {
            trialId,
            trialInstitutionId,
            navigate,
            user,
            trialInstitution,
            permissions
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-auto mb-2"}>
                    <h2>Trial Institution Roles</h2>
                </div>
                <div className="col" style={{ textAlign: 'right' }}>
                    {(trialInstitution && trialInstitution.trialId > 0 &&
                        AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialPersonnelRoleCreate, trialInstitution.trialId, trialInstitution.institutionId)) && (
                            <>
                                <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} />
                                <div className="btn-group mr-2">
                                    <button type="button" className="btn btn-info" onClick={() => this.toggleExportMenu()}>
                                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.CLOUD_DOWNLOAD} fixedWidth />
                                    </button>
                                    <Modal open={this.state.exportToggle} disableCloseOnOverlayClick={true} onClose={() => { this.toggleExportMenu() }} size={ModalSize.Md}>
                                        <div className="modal-header">
                                            <h5 className="modal-title">Download</h5>
                                            <button type="button" className="close" aria-label="Close" onClick={() => { this.toggleExportMenu() }}>
                                                <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                                            </button>
                                        </div>
                                        <div className="modal-body p-3">
                                            <a className="btn btn-outline-primary btn-block"
                                                onClick={() => this.toggleExportMenu()}
                                                href={encodeURI("/api/personnelrole/export/" + trialId.toString() + "/" + trialInstitution.institutionId.toString())}
                                            ><b>Trial Institution Personnel Role List</b><br /><small>The trial institution personnel with role and active dates as a CSV file</small></a>
                                        </div>
                                    </Modal>
                                </div>
                                <Link className={"btn btn-primary"} url={"/trials/" + trialId + "/trialinstitution/" + trialInstitutionId + '/personnel/create'}>Create</Link>
                            </>
                        )}
                </div>
            </div>
            {
                !this.show() ?
                    "Loading Personnel" :
                    null
            }
            {
                this.show() ?
                    <div className="mt-2">
                        {trialInstitution && trialInstitution.trial && trialInstitution.trial.isLegacyUserDatabase && (
                            <div className="alert alert-warning" role="alert">
                                <strong>Warning!</strong>&nbsp;&nbsp; The registration website for this trial is not connected to TMD, please contact <a href="mailto:support@newtongreen.com">support@newtongreen.com</a> if any access needs to be changed.
                            </div>
                        )}
                        {this.renderTable()}
                    </div> :
                    null
            }
        </div>
    }


    toggle(personnelRoleSummary: Dtos.PersonnelRoleSummary) {

    }

    renderTable() {
        const {
            personnelRoleSummaries,
            toggleItems,
            toggleExpand
        } = this.props;

        if (!personnelRoleSummaries || personnelRoleSummaries.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no roles to view.</span>
            </div>
        }

        return <div>
            {personnelRoleSummaries && personnelRoleSummaries.map((item, index) => {
                const key = "trial-institution-roles-page-" + item.personnelRoleSummaryType + "-" + item.display;
                const expanded = ToggleHelper.isToggleExpanded(toggleItems, key);
                return (
                    <div key={"parent-category" + index}>
                        <div className={tableHeaderClass} style={tableStyleClass} onClick={() => toggleExpand(key)}>
                            <div>
                                <FontAwesomeIcon icon={!expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" />
                            </div>
                            <span>{item.display}</span>
                        </div>
                        {expanded && item.personnelRoleSummaryType == Dtos.PersonnelRoleSummaryType.TrialInstitution && (
                            this.renderTrialInstitutionRoles(item.personnelRoleSummaries)
                        )}
                    </div>
                )
            }
            )}
        </div>
    }

    renderTrialInstitutionRoles(personnelRoleSummaries: Dtos.PersonnelRoleSummary[]) {
        const showPagination = false;
        const {
            trialId,
            trialInstitutionId
        } = this.props;

        return (
            <ReactTable
                data={personnelRoleSummaries}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : personnelRoleSummaries.length}
                pageSize={showPagination ? undefined : personnelRoleSummaries.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no roles for this type"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            console.log(rowInfo);
                            const url: string = "/trials/" + trialId + "/trialinstitution/" + trialInstitutionId + '/personnel/' + rowInfo.original.personnelRole.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        },
                        style: {
                            color: (rowInfo && rowInfo.original && rowInfo.original.personnelRole.active) ? '#212529' : '#9e9e9e'
                        }
                    }
                }}
                defaultSorted={[
                    {
                        id: "person",
                        desc: false
                    }
                ]}
                columns={
                    [
                        {
                            id: "id",
                            Header: ' ',
                            accessor: "id",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 50,
                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.ROLE} fixedWidth />
                        },
                        {
                            id: "person",
                            Header: "Person",
                            accessor: "personnelRole.personnelDisplay",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "department",
                            Header: "Department",
                            accessor: "personnelRole.department.name",
                            className: "d-flex align-items-left justify-content-left",
                            headerClassName: "text-left"
                        },
                        {
                            id: "dateStarted",
                            Header: "Date Started",
                            accessor: "personnelRole.dateStarted",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        },
                        {
                            id: "dateCeased",
                            Header: "Date Ceased",
                            accessor: "personnelRole.dateCeased",
                            className: "d-flex align-items-center justify-content-center",
                            maxWidth: 180,
                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                        }
                    ]
                }
            />
        );
    }

}


const mapStateToProps = (state: ITmdState, ownProps: TrialInstitutionPersonnelPageProps): ITrialInstitutionPersonnelPageProps => {

    let trialInstitution: Dtos.TrialInstitution | undefined = !(state.trialInstitution.formData instanceof Array) ? state.trialInstitution.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {
        form: state.reduxForms.trialInstitution,
        formState: state.trialInstitution.formState,
        formProperties: state.trialInstitution.formProperties,
        reduxFormState: state.reduxForms.formState.trialInstitution,

        match: ownProps.match,
        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,
        trialInstitutionId: ownProps.match && ownProps.match.params.trialInstitutionId.toString() != "create" ? ownProps.match.params.trialInstitutionId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trialInstitution: trialInstitution!,
        formFilter: state.trialInstitution.formFilterPersonnelRoles,
        personnelRoleSummaries: state.trialInstitution.formFilteredPersonnelRoles,

        loadingTrialInstitution: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Pending,
        loadTrialInstitutionSuccess: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Success,
        loadTrialInstitutionFailure: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        toggleItems: state.togglePage.items
    };
};

const mapDispatchToProps = (dispatch): ITrialInstitutionPersonnelPageActions => {
    return {

        loadTrialInstitution: bindActionCreators(TrialInstitutionActions.LoadTrialInstitutionById, dispatch),
        clearTrialInstitution: bindActionCreators(TrialInstitutionActions.Clear, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch),

        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        toggleExpand: bindActionCreators(ToggleActions.ToggleExpanded, dispatch),

        filter: bindActionCreators(TrialInstitutionActions.PersonnelRolesFilter, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialInstitutionPersonnelPage);
