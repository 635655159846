import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Tmd.dtos';

import {
    ACTION_TRIALINSTITUTION_LOAD_BY_ID,
    ACTION_TRIALINSTITUTION_LOAD_FAILURE,
    ACTION_TRIALINSTITUTION_LOAD_SUCCESS,

    ACTION_TRIALINSTITUTION_SAVE,
    ACTION_TRIALINSTITUTION_SAVE_SUCCESS,
    ACTION_TRIALINSTITUTION_SAVE_FAILURE,

    ACTION_TRIALINSTITUTION_CREATE,
    ACTION_TRIALINSTITUTION_CREATE_SUCCESS,
    ACTION_TRIALINSTITUTION_CREATE_FAILURE,

    ACTION_TRIALINSTITUTION_CLEAR,
    ACTION_TRIALINSTITUTION_FILTER,
    ACTION_TRIALINSTITUTION_PERSONNEL_ROLES_FILTER,

    TrialInstitutionAction
} from '../actions/trialInstitution';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState, IFormFilter } from "./index";
import { updateFailureIndexToId } from "../helpers/formErrorHelper";

export interface ITrialInstitutionState {
    formData: Dtos.TrialInstitution | Dtos.TrialInstitution[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    formRoles: Dtos.RoleType[],
    validationFailures: Dtos.ResponseError[];
    lookups: Dtos.Lookup[],
    loadState: IRequestState;
    saveState: IRequestState;
    updateState: IRequestState;
    createState: IRequestState;
    formFilter: IFormFilter;
    formFilteredData: Dtos.TrialInstitution[];
    formLength: number;
    formFilterPersonnelRoles: IFormFilter;
    formFilteredPersonnelRoles: Dtos.PersonnelRoleSummary[];
    formLengthPersonnelRoles: number;
}

const initialState: ITrialInstitutionState = {
    formData: undefined,
    formState: undefined,
    formProperties: undefined,
    formRoles: undefined,
    validationFailures: undefined,
    lookups: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    updateState: {
        status: RequestState.None
    } as IRequestState,
    createState: {
        status: RequestState.None
    } as IRequestState,
    formFilter: {
        search: "",
        showInActive: false
    },
    formFilteredData: [],
    formLength: 0,
    formFilterPersonnelRoles: {
        search: "",
        showInActive: false
    },
    formFilteredPersonnelRoles: [],
    formLengthPersonnelRoles: 0
};


function loading(state: ITrialInstitutionState, isLoading: boolean): ITrialInstitutionState {
    const newState: ITrialInstitutionState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(
    state: ITrialInstitutionState,
    formData: Dtos.TrialInstitution | Dtos.TrialInstitution[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    formRoles: Dtos.RoleType[],
    lookups: Dtos.Lookup[],
    responseStatus: Dtos.ResponseStatus
): ITrialInstitutionState {

    // if switching between list and details, reset the list
    var isList: boolean = formData instanceof Array ? true : false;
    const formFilterPersonnelRoles = isList ? {
        search: "",
        showInActive: false
    } : state.formFilterPersonnelRoles;

    const newState: ITrialInstitutionState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        formRoles: {
            $set: formRoles
        },
        lookups: {
            $set: lookups
        },
        loadState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        },
        formFilterPersonnelRoles: {
            $set: formFilterPersonnelRoles
        }
    });

    return filterPersonnelRoles(filter(newState, state.formFilter), newState.formFilterPersonnelRoles);
}

function loadFailure(state: ITrialInstitutionState, responseStatus: Dtos.ResponseStatus): ITrialInstitutionState {
    const newState: ITrialInstitutionState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function filter(state: ITrialInstitutionState, formFilter: IFormFilter): ITrialInstitutionState {

    const items: Dtos.TrialInstitution[] | undefined =
        state.formData instanceof Array ?
            state.formData :
            undefined;

    const formLength = items ? items.length : 0;

    const formFilteredData = items ? items.filter(p => (p.active == true || formFilter.showInActive) &&
        (formFilter.search == "" ||
            p.display.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1) ||
            p.institutionDisplay.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1) : undefined;

    const newState: ITrialInstitutionState = update(state, {
        formFilter: {
            $set: formFilter
        },
        formFilteredData: {
            $set: formFilteredData
        },
        formLength: {
            $set: formLength
        }
    });

    return newState;
}

function filterPersonnelRoles(state: ITrialInstitutionState, formFilter: IFormFilter): ITrialInstitutionState {

    let trialInstitution: Dtos.TrialInstitution | undefined =
        state.formData instanceof Array ?
            undefined : JSON.parse(JSON.stringify(state.formData));

    let personnelRoles: Dtos.PersonnelRoleSummary[] =
        trialInstitution ? [...trialInstitution.personnelRoleSummaries] : [];

    const formLengthPersonnelRoles = personnelRoles ? personnelRoles.length : 0;

    personnelRoles.forEach(item => {
        var personnelRoleSummaries = item.personnelRoleSummaries.filter(p => (p.personnelRole.active == true || formFilter.showInActive) &&
            (
                formFilter.search == ""
                || p.personnelRole.roleDisplay.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1
                || p.personnelRole.personnelDisplay.toLowerCase().indexOf(formFilter.search.toLowerCase()) > -1
            )
        );
        item.personnelRoleSummaries = [...personnelRoleSummaries];
    });

    let formFilteredPersonnelRoles = personnelRoles.filter(p => p.personnelRoleSummaries.length > 0);

    const newState: ITrialInstitutionState = update(state, {
        formFilterPersonnelRoles: {
            $set: formFilter
        },
        formFilteredPersonnelRoles: {
            $set: formFilteredPersonnelRoles
        },
        formLengthPersonnelRoles: {
            $set: formLengthPersonnelRoles
        }
    });

    return newState;
}

function clear(state: ITrialInstitutionState, propertiesToClear?: string[]): ITrialInstitutionState {

    if (!propertiesToClear) {
        const newState: ITrialInstitutionState = update(initialState, {});
        return newState;
    }

    let stateModifier: any = {};

    propertiesToClear.forEach(propertyToClear => {
        stateModifier[propertyToClear] = { $set: initialState[propertyToClear] }
    });

    const newState = update(state, stateModifier);

    return newState;
}

function saving(state: ITrialInstitutionState, isSaving: boolean): ITrialInstitutionState {
    const newState: ITrialInstitutionState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function saveSuccess(
    state: ITrialInstitutionState,
    formData: Dtos.TrialInstitution | Dtos.TrialInstitution[],
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus
): ITrialInstitutionState {
    const newState: ITrialInstitutionState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        saveState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function saveFailure(state: ITrialInstitutionState, responseStatus: Dtos.ResponseStatus): ITrialInstitutionState {
    const newState: ITrialInstitutionState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                meta: responseStatus.meta
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, state.formData) : undefined
        }
    });

    return newState;
}

function creating(state: ITrialInstitutionState, isCreating: boolean): ITrialInstitutionState {
    const newState: ITrialInstitutionState = update(state, {
        createState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function createSuccess(
    state: ITrialInstitutionState,
    formData: Dtos.TrialInstitution,
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    formRoles: Dtos.RoleType[],
    lookups: Dtos.Lookup[]
): ITrialInstitutionState {
    const newState: ITrialInstitutionState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        formRoles: {
            $set: formRoles
        },
        lookups: {
            $set: lookups
        },
        createState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function createFailure(state: ITrialInstitutionState, responseStatus: Dtos.ResponseStatus): ITrialInstitutionState {
    const newState: ITrialInstitutionState = update(state, {
        createState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

const TrialInstitutionReducer: Reducer<ITrialInstitutionState> = (state: ITrialInstitutionState = initialState, action: TrialInstitutionAction) => {
    switch (action.type) {
        case ACTION_TRIALINSTITUTION_LOAD_BY_ID:
            return loading(state, true);
        case ACTION_TRIALINSTITUTION_LOAD_SUCCESS:
            return loadSuccess(state, action.formData, action.formState, action.formProperties, action.formRoles, action.lookups, action.responseStatus);
        case ACTION_TRIALINSTITUTION_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_TRIALINSTITUTION_CLEAR:
            return clear(state, action.propertiesToClear);
        case ACTION_TRIALINSTITUTION_SAVE:
            return saving(state, true);
        case ACTION_TRIALINSTITUTION_SAVE_SUCCESS:
            return saveSuccess(state, action.formData, action.formState, action.responseStatus);
        case ACTION_TRIALINSTITUTION_SAVE_FAILURE:
            return saveFailure(state, action.responseStatus);
        case ACTION_TRIALINSTITUTION_CREATE:
            return creating(state, true);
        case ACTION_TRIALINSTITUTION_CREATE_SUCCESS:
            return createSuccess(state, action.formData, action.formState, action.formProperties, action.formRoles, action.lookups);
        case ACTION_TRIALINSTITUTION_CREATE_FAILURE:
            return createFailure(state, action.responseStatus);
        case ACTION_TRIALINSTITUTION_FILTER:
            return filter(state, action.formFilter);
        case ACTION_TRIALINSTITUTION_PERSONNEL_ROLES_FILTER:
            return filterPersonnelRoles(state, action.formFilter);

    }

    return state;
}

export default TrialInstitutionReducer;