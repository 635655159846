import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadTrial: ITrialLoadActionCreator = () => {
        return {
            type: ACTION_TRIALS_LOAD
        }
    },
    LoadTrialCurrentUser: ITrialLoadCurrentUserActionCreator = () => {
        return {
            type: ACTION_TRIALS_LOAD_CURRENT_USER
        }
    },
    LoadTrialById: ITrialLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TRIALS_LOAD_BY_ID,
            id
        }
    },
    LoadTrialWithTrialItemInstancesById: ITrialWithTrialItemInstancesLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TRIALS_WITH_TRIALITEMINSTANCES_LOAD_BY_ID,
            id
        }
    },
    LoadWithTrialItemInstancesSuccess: ITrialWithTrialItemInstancesLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, trialItemInstances, templateTrialItems, responseStatus) => {
        return {
            type: ACTION_TRIALS_WITH_TRIALITEMINSTANCES_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            trialItemInstances,
            templateTrialItems,
            responseStatus
        }
    },
    LoadSuccess: ITrialLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrialLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALS_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTrial: ITrialSaveActionCreator = (formData) => {
        return {
            type: ACTION_TRIALS_SAVE,
            formData
        }
    },
    SaveSuccess: ITrialSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRIALS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrialSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTrial: ITrialCreateActionCreator = () => {

        return {
            type: ACTION_TRIALS_CREATE
        }
    },
    CreateSuccess: ITrialCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_TRIALS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: ITrialCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALS_CREATE_FAILURE,
            responseStatus
        }
    },

    Clear: ITrialClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRIALS_CLEAR,
            propertiesToClear
        }
    },

    Filter: ITrialFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRIALS_FILTER,
            formFilter
        }
    },
    PersonnelRolesFilter: ITrialPersonnelRolesFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRIALS_PERSONNEL_ROLES_FILTER,
            formFilter
        }
    };



export const
    ACTION_TRIALS_LOAD = "ACTION_TRIALS_LOAD",
    ACTION_TRIALS_LOAD_CURRENT_USER = "ACTION_TRIALS_LOAD_CURRENT_USER",
    ACTION_TRIALS_LOAD_BY_ID = "ACTION_TRIALS_LOAD_BY_ID",
    ACTION_TRIALS_WITH_TRIALITEMINSTANCES_LOAD_BY_ID = "ACTION_TRIALS_WITH_TRIALITEMINSTANCES_LOAD_BY_ID",
    ACTION_TRIALS_WITH_TRIALITEMINSTANCES_LOAD_SUCCESS = "ACTION_TRIALS_WITH_TRIALITEMINSTANCES_LOAD_SUCCESS",
    ACTION_TRIALS_LOAD_SUCCESS = "ACTION_TRIALS_LOAD_SUCCESS",
    ACTION_TRIALS_LOAD_FAILURE = "ACTION_TRIALS_LOAD_FAILURE",

    ACTION_TRIALS_SAVE = "ACTION_TRIALS_SAVE",
    ACTION_TRIALS_SAVE_SUCCESS = "ACTION_TRIALS_SAVE_SUCCESS",
    ACTION_TRIALS_SAVE_FAILURE = "ACTION_TRIALS_SAVE_FAILURE",

    ACTION_TRIALS_CREATE = "ACTION_TRIALS_CREATE",
    ACTION_TRIALS_CREATE_SUCCESS = "ACTION_TRIALS_CREATE_SUCCESS",
    ACTION_TRIALS_CREATE_FAILURE = "ACTION_TRIALS_CREATE_FAILURE",

    ACTION_TRIALS_CLEAR = "ACTION_TRIALS_CLEAR",

    ACTION_TRIALS_FILTER = "ACTION_TRIALS_FILTER",
    ACTION_TRIALS_PERSONNEL_ROLES_FILTER = "ACTION_TRIALS_PERSONNEL_ROLES_FILTER";

export interface ITrialLoadAction {
    type: "ACTION_TRIALS_LOAD";
}

export interface ITrialLoadActionCreator {
    (): ITrialLoadAction;
}

export interface ITrialLoadCurrentUserAction {
    type: "ACTION_TRIALS_LOAD_CURRENT_USER";
}

export interface ITrialLoadCurrentUserActionCreator {
    (): ITrialLoadCurrentUserAction;
}

export interface ITrialLoadSuccessAction {
    type: "ACTION_TRIALS_LOAD_SUCCESS";
    formData: Dtos.Trial | Dtos.Trial[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialLoadSuccessActionCreator {
    (
        formData: Dtos.Trial | Dtos.Trial[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialLoadSuccessAction;
}

export interface ITrialLoadFailureAction {
    type: "ACTION_TRIALS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialLoadFailureAction;
}

export interface ITrialLoadByIdAction {
    type: "ACTION_TRIALS_LOAD_BY_ID";
    id: number;
}

export interface ITrialLoadByIdActionCreator {
    (id: number): ITrialLoadByIdAction;
}

export interface ITrialWithTrialItemInstancesLoadByIdAction {
    type: "ACTION_TRIALS_WITH_TRIALITEMINSTANCES_LOAD_BY_ID";
    id: number;
}

export interface ITrialWithTrialItemInstancesLoadByIdActionCreator {
    (id: number): ITrialWithTrialItemInstancesLoadByIdAction;
}

export interface ITrialWithTrialItemInstancesLoadSuccessAction {
    type: "ACTION_TRIALS_WITH_TRIALITEMINSTANCES_LOAD_SUCCESS";
    formData: Dtos.Trial | Dtos.Trial[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    trialItemInstances: Dtos.TrialItemInstanceCategory[];
    templateTrialItems: Dtos.TemplateTrialItem[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialWithTrialItemInstancesLoadSuccessActionCreator {
    (
        formData: Dtos.Trial | Dtos.Trial[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        trialItemInstances: Dtos.TrialItemInstanceCategory[],
        templateTrialItems: Dtos.TemplateTrialItem[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialWithTrialItemInstancesLoadSuccessAction;
}


export interface ITrialSaveAction {
    type: "ACTION_TRIALS_SAVE";
    formData: Dtos.Trial;

}

export interface ITrialSaveActionCreator {
    (
        formData: Dtos.Trial
    ): ITrialSaveAction;
}

export interface ITrialSaveSuccessAction {
    type: "ACTION_TRIALS_SAVE_SUCCESS";
    formData: Dtos.Trial;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialSaveSuccessActionCreator {
    (
        formData: Dtos.Trial,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrialSaveSuccessAction;
}

export interface ITrialSaveFailureAction {
    type: "ACTION_TRIALS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialSaveFailureAction;
}

export interface ITrialCreateAction {
    type: "ACTION_TRIALS_CREATE"
}

export interface ITrialCreateActionCreator {
    (): ITrialCreateAction
}

export interface ITrialCreateSuccessAction {
    type: "ACTION_TRIALS_CREATE_SUCCESS";
    formData: Dtos.Trial;
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[]
}

export interface ITrialCreateSuccessActionCreator {
    (
        formData: Dtos.Trial,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): ITrialCreateSuccessAction;
}

export interface ITrialCreateFailureAction {
    type: "ACTION_TRIALS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialCreateFailureAction;
}

export interface ITrialClearAction {
    type: "ACTION_TRIALS_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrialClearActionCreator {
    (propertiesToClear?: string[]): ITrialClearAction;
}

export interface ITrialFilterActionCreator {
    (formFilter: IFormFilter): ITrialFilterAction;
}

export interface ITrialFilterAction {
    type: "ACTION_TRIALS_FILTER";
    formFilter: IFormFilter;
}

export interface ITrialPersonnelRolesFilterActionCreator {
    (formFilter: IFormFilter): ITrialPersonnelRolesFilterAction;
}

export interface ITrialPersonnelRolesFilterAction {
    type: "ACTION_TRIALS_PERSONNEL_ROLES_FILTER";
    formFilter: IFormFilter;
}

export type TrialAction =
    ITrialLoadAction |
    ITrialLoadCurrentUserAction |
    ITrialLoadByIdAction |
    ITrialWithTrialItemInstancesLoadByIdAction |
    ITrialWithTrialItemInstancesLoadSuccessAction |
    ITrialLoadFailureAction |
    ITrialLoadSuccessAction |
    ITrialSaveAction |
    ITrialSaveSuccessAction |
    ITrialSaveFailureAction |
    ITrialCreateAction |
    ITrialCreateSuccessAction |
    ITrialCreateFailureAction |
    ITrialClearAction |
    ITrialFilterAction |
    ITrialPersonnelRolesFilterAction;
