import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadHubspotById: IHubspotLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_HUBSPOT_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IHubspotLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_HUBSPOT_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus
        }
    },
    LoadFailure: IHubspotLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_HUBSPOT_LOAD_FAILURE,
            responseStatus
        }
    },
    Clear: IHubspotClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_HUBSPOT_CLEAR,
            propertiesToClear
        }
    },
    LoadHubspotContactsToExport: IHubspotLoadContactsToExportActionCreator = () => {
        return {
            type: ACTION_HUBSPOT_LOAD_CONTACTS_TO_EXPORT
        }
    },
    HubspotExportContacts: IHubspotExportContactsActionCreator = (username) => {
        return {
            type: ACTION_HUBSPOT_EXPORT_CONTACTS,
            username
        }
    },
    HubspotExportContactsSuccess: IHubspotExportContactsSuccessActionCreator = (formData, responseStatus) => {
        return {
            type: ACTION_HUBSPOT_EXPORT_CONTACTS_SUCCESS,
            formData,
            responseStatus
        }
    },
    HubspotExportContactsFailure: IHubspotExportContactsFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_HUBSPOT_EXPORT_CONTACTS_FAILURE,
            responseStatus
        }
    },
    HubspotFindContact: IHubspotFindContactActionCreator = (email, action, newEmail, emailUpdateOptions) => {
        return {
            type: ACTION_HUBSPOT_FIND_CONTACT,
            email,
            action,
            newEmail,
            emailUpdateOptions
        }
    },
    HubspotFindContactSuccess: IHubspotFindContactSuccessActionCreator = (existing, replacement, emailUpdateOptions, responseStatus) => {
        return {
            type: ACTION_HUBSPOT_FIND_CONTACT_SUCCESS,
            existing,
            replacement,
            emailUpdateOptions,
            responseStatus
        }
    },
    HubspotFindContactFailure: IHubspotFindContactFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_HUBSPOT_FIND_CONTACT_FAILURE,
            responseStatus
        }
    },
    HubspotSetEmailUpdateOptions: IHubspotSetEmailUpdateOptionsActionCreator = (emailUpdateOptions) => {
        return {
            type: ACTION_HUBSPOT_SET_EMAIL_UPDATE_OPTIONS,
            emailUpdateOptions
        }
    },
    Filter: IHubspotFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_HUBSPOT_FILTER,
            formFilter
        }
    };


export const
    ACTION_HUBSPOT_LOAD_BY_ID = "ACTION_HUBSPOT_LOAD_BY_ID",
    ACTION_HUBSPOT_LOAD_SUCCESS = "ACTION_HUBSPOT_LOAD_SUCCESS",
    ACTION_HUBSPOT_LOAD_FAILURE = "ACTION_HUBSPOT_LOAD_FAILURE",
    ACTION_HUBSPOT_LOAD_CONTACTS_TO_EXPORT = "ACTION_HUBSPOT_LOAD_CONTACTS_TO_EXPORT",
    ACTION_HUBSPOT_CLEAR = "ACTION_HUBSPOT_CLEAR",
    ACTION_HUBSPOT_EXPORT_CONTACTS = "ACTION_HUBSPOT_EXPORT_CONTACTS",
    ACTION_HUBSPOT_EXPORT_CONTACTS_SUCCESS = "ACTION_HUBSPOT_EXPORT_CONTACTS_SUCCESS",
    ACTION_HUBSPOT_EXPORT_CONTACTS_FAILURE = "ACTION_HUBSPOT_EXPORT_CONTACTS_FAILURE",
    ACTION_HUBSPOT_FIND_CONTACT = "ACTION_HUBSPOT_FIND_CONTACT",
    ACTION_HUBSPOT_FIND_CONTACT_SUCCESS = "ACTION_HUBSPOT_FIND_CONTACT_SUCCESS",
    ACTION_HUBSPOT_FIND_CONTACT_FAILURE = "ACTION_HUBSPOT_FIND_CONTACT_FAILURE",
    ACTION_HUBSPOT_FILTER = "ACTION_HUBSPOT_FILTER",
    ACTION_HUBSPOT_SET_EMAIL_UPDATE_OPTIONS = "ACTION_HUBSPOT_SET_EMAIL_UPDATE_OPTIONS";

export interface IHubspotLoadSuccessAction {
    type: "ACTION_HUBSPOT_LOAD_SUCCESS";
    formData: Dtos.Hubspot | Dtos.Hubspot[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IHubspotLoadSuccessActionCreator {
    (
        formData: Dtos.Hubspot | Dtos.Hubspot[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IHubspotLoadSuccessAction;
}

export interface IHubspotLoadFailureAction {
    type: "ACTION_HUBSPOT_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IHubspotLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IHubspotLoadFailureAction;
}

export interface IHubspotLoadByIdAction {
    type: "ACTION_HUBSPOT_LOAD_BY_ID";
    id: number;
}

export interface IHubspotLoadByIdActionCreator {
    (id: number): IHubspotLoadByIdAction;
}

export interface IHubspotClearAction {
    type: "ACTION_HUBSPOT_CLEAR";
    propertiesToClear?: string[];
}

export interface IHubspotClearActionCreator {
    (propertiesToClear?: string[]): IHubspotClearAction;
}

export interface IHubspotLoadContactsToExportAction {
    type: "ACTION_HUBSPOT_LOAD_CONTACTS_TO_EXPORT";
}

export interface IHubspotLoadContactsToExportActionCreator {
    (): IHubspotLoadContactsToExportAction;
}

export interface IHubspotExportContactsSuccessAction {
    type: "ACTION_HUBSPOT_EXPORT_CONTACTS_SUCCESS";
    formData: Dtos.Hubspot | Dtos.Hubspot[];
    responseStatus: Dtos.ResponseStatus;
}

export interface IHubspotExportContactsSuccessActionCreator {
    (
        formData: Dtos.Hubspot | Dtos.Hubspot[],
        responseStatus: Dtos.ResponseStatus
    ): IHubspotExportContactsSuccessAction;
}

export interface IHubspotExportContactsFailureAction {
    type: "ACTION_HUBSPOT_EXPORT_CONTACTS_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IHubspotExportContactsFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IHubspotExportContactsFailureAction;
}

export interface IHubspotExportContactsAction {
    type: "ACTION_HUBSPOT_EXPORT_CONTACTS";
    username: string;
}

export interface IHubspotExportContactsActionCreator {
    (username: string): IHubspotExportContactsAction;
}

export interface IHubspotFilterActionCreator {
    (formFilter: IFormFilter): IHubspotFilterAction;
}

export interface IHubspotFilterAction {
    type: "ACTION_HUBSPOT_FILTER";
    formFilter: IFormFilter;
}

export interface IHubspotFindContactAction {
    type: "ACTION_HUBSPOT_FIND_CONTACT";
    email: string;
    action: string;
    newEmail: string;
    emailUpdateOptions: Dtos.EmailUpdateOptions;
}

export interface IHubspotFindContactActionCreator {
    (email: string, action: string, newEmail: string, emailUpdateOptions: Dtos.EmailUpdateOptions): IHubspotFindContactAction;
}

export interface IHubspotFindContactSuccessAction {
    type: "ACTION_HUBSPOT_FIND_CONTACT_SUCCESS";
    existing: Dtos.EmailUsage;
    replacement: Dtos.EmailUsage;
    emailUpdateOptions: Dtos.EmailUpdateOptions;
    responseStatus: Dtos.ResponseStatus;
}

export interface IHubspotFindContactSuccessActionCreator {
    (
        existing: Dtos.EmailUsage,
        replacement: Dtos.EmailUsage,
        emailUpdateOptions: Dtos.EmailUpdateOptions,
        responseStatus: Dtos.ResponseStatus
    ): IHubspotFindContactSuccessAction;
}

export interface IHubspotFindContactFailureAction {
    type: "ACTION_HUBSPOT_FIND_CONTACT_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IHubspotFindContactFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IHubspotFindContactFailureAction;
}

export interface IHubspotSetEmailUpdateOptionsAction {
    type: "ACTION_HUBSPOT_SET_EMAIL_UPDATE_OPTIONS";
    emailUpdateOptions: Dtos.EmailUpdateOptions;
}

export interface IHubspotSetEmailUpdateOptionsActionCreator {
    (
        emailUpdateOptions: Dtos.EmailUpdateOptions
    ): IHubspotSetEmailUpdateOptionsAction;
}

export type HubspotAction =
    IHubspotLoadByIdAction |
    IHubspotLoadFailureAction |
    IHubspotLoadSuccessAction |
    IHubspotClearAction |
    IHubspotLoadContactsToExportAction |
    IHubspotExportContactsAction |
    IHubspotExportContactsSuccessAction |
    IHubspotExportContactsFailureAction |
    IHubspotFilterAction |
    IHubspotFindContactAction |
    IHubspotFindContactSuccessAction |
    IHubspotFindContactFailureAction |
    IHubspotSetEmailUpdateOptionsAction;
