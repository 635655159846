import * as Dtos from "../dtos/Tmd.dtos";

export const
    LoadCollectionInstanceById: ICollectionInstanceLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_COLLECTIONINSTANCES_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ICollectionInstanceLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_COLLECTIONINSTANCES_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ICollectionInstanceLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_COLLECTIONINSTANCES_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveCollectionInstance: ICollectionInstanceSaveActionCreator = (formData) => {
        return {
            type: ACTION_COLLECTIONINSTANCES_SAVE,
            formData
        }
    },
    SaveSuccess: ICollectionInstanceSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_COLLECTIONINSTANCES_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ICollectionInstanceSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_COLLECTIONINSTANCES_SAVE_FAILURE,
            responseStatus
        }
    },

    ExpandSection: ICollectionInstanceExpandSectionActionCreator = (collectionSectionId) => {
        return {
            type: ACTION_COLLECTIONINSTANCES_EXPAND_SECTION,
            collectionSectionId
        }
    },

    Clear: ICollectionInstanceClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_COLLECTIONINSTANCES_CLEAR,
            propertiesToClear
        }
    };



export const
    ACTION_COLLECTIONINSTANCES_LOAD_BY_ID = "ACTION_COLLECTIONINSTANCES_LOAD_BY_ID",
    ACTION_COLLECTIONINSTANCES_LOAD_SUCCESS = "ACTION_COLLECTIONINSTANCES_LOAD_SUCCESS",
    ACTION_COLLECTIONINSTANCES_LOAD_FAILURE = "ACTION_COLLECTIONINSTANCES_LOAD_FAILURE",

    ACTION_COLLECTIONINSTANCES_SAVE = "ACTION_COLLECTIONINSTANCES_SAVE",
    ACTION_COLLECTIONINSTANCES_SAVE_SUCCESS = "ACTION_COLLECTIONINSTANCES_SAVE_SUCCESS",
    ACTION_COLLECTIONINSTANCES_SAVE_FAILURE = "ACTION_COLLECTIONINSTANCES_SAVE_FAILURE",

    ACTION_COLLECTIONINSTANCES_EXPAND_SECTION = "ACTION_COLLECTIONINSTANCES_EXPAND_SECTION",

    ACTION_COLLECTIONINSTANCES_CLEAR = "ACTION_COLLECTIONINSTANCES_CLEAR";


export interface ICollectionInstanceLoadSuccessAction {
    type: "ACTION_COLLECTIONINSTANCES_LOAD_SUCCESS";
    formData: Dtos.CollectionInstance | Dtos.CollectionInstance[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ICollectionInstanceLoadSuccessActionCreator {
    (
        formData: Dtos.CollectionInstance | Dtos.CollectionInstance[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ICollectionInstanceLoadSuccessAction;
}

export interface ICollectionInstanceLoadFailureAction {
    type: "ACTION_COLLECTIONINSTANCES_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ICollectionInstanceLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ICollectionInstanceLoadFailureAction;
}

export interface ICollectionInstanceLoadByIdAction {
    type: "ACTION_COLLECTIONINSTANCES_LOAD_BY_ID";
    id: number;
}

export interface ICollectionInstanceLoadByIdActionCreator {
    (id: number): ICollectionInstanceLoadByIdAction;
}


export interface ICollectionInstanceSaveAction {
    type: "ACTION_COLLECTIONINSTANCES_SAVE";
    formData: Dtos.CollectionInstance;

}

export interface ICollectionInstanceSaveActionCreator {
    (
        formData: Dtos.CollectionInstance
    ): ICollectionInstanceSaveAction;
}

export interface ICollectionInstanceSaveSuccessAction {
    type: "ACTION_COLLECTIONINSTANCES_SAVE_SUCCESS";
    formData: Dtos.CollectionInstance;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ICollectionInstanceSaveSuccessActionCreator {
    (
        formData: Dtos.CollectionInstance,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ICollectionInstanceSaveSuccessAction;
}

export interface ICollectionInstanceSaveFailureAction {
    type: "ACTION_COLLECTIONINSTANCES_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ICollectionInstanceSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ICollectionInstanceSaveFailureAction;
}

export interface ICollectionInstanceExpandSectionAction {
    type: "ACTION_COLLECTIONINSTANCES_EXPAND_SECTION";
    collectionSectionId: number;
}

export interface ICollectionInstanceExpandSectionActionCreator {
    (collectionSectionId: number): ICollectionInstanceExpandSectionAction;
}

export interface ICollectionInstanceClearAction {
    type: "ACTION_COLLECTIONINSTANCES_CLEAR";
    propertiesToClear?: string[];
}

export interface ICollectionInstanceClearActionCreator {
    (propertiesToClear?: string[]): ICollectionInstanceClearAction;
}

export type CollectionInstanceAction =
    ICollectionInstanceLoadByIdAction |
    ICollectionInstanceLoadFailureAction |
    ICollectionInstanceLoadSuccessAction |
    ICollectionInstanceSaveAction |
    ICollectionInstanceSaveSuccessAction |
    ICollectionInstanceSaveFailureAction |
    ICollectionInstanceExpandSectionAction |
    ICollectionInstanceClearAction;
