import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trialsApi = {
    getTrials: (): Observable<Dtos.GetTrialsResponse> => {
        //Create the request.
        let request: Dtos.GetTrials = new Dtos.GetTrials();

        //Send request.
        const response: Promise<Dtos.GetTrialsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrialsByCurrentUser: (): Observable<Dtos.GetTrialsResponse> => {
        //Create the request.
        let request: Dtos.GetTrials = new Dtos.GetTrials();
        request.forCurrentUser = true;

        //Send request.
        const response: Promise<Dtos.GetTrialsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrialById: (id: number): Observable<Dtos.GetTrialByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrialById = new Dtos.GetTrialById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTrialByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrial: (trial: Dtos.Trial): Observable<Dtos.SetTrialResponse> => {

        let request: Dtos.SetTrial = new Dtos.SetTrial();

        request.trial = trial;

        // send request
        const response: Promise<Dtos.SetTrialResponse> = client.post(request);

        return Observable.from(response);

    },
    createTrial: (): Observable<Dtos.CreateTrialResponse> => {
        //Create the request.
        let request: Dtos.CreateTrial = new Dtos.CreateTrial();

        //Send request.
        const response: Promise<Dtos.CreateTrialResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}