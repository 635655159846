import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_ITEM_COMPONENTS_LOAD,
    ACTION_ITEM_COMPONENTS_LOAD_BY_ID,

    ACTION_ITEM_COMPONENTS_SAVE,

    ACTION_ITEM_COMPONENTS_CREATE,

    IItemComponentLoadAction,
    IItemComponentLoadByIdAction,
    IItemComponentLoadFailureAction,
    IItemComponentLoadSuccessAction,

    IItemComponentSaveAction,
    IItemComponentSaveSuccessAction,
    IItemComponentSaveFailureAction,

    IItemComponentCreateAction,
    IItemComponentCreateSuccessAction,
    IItemComponentCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/itemComponents";
import { itemComponentsApi } from "../services/itemComponents";


export const
    getTrialsEpic = (action$): Observable<IItemComponentLoadSuccessAction | IItemComponentLoadFailureAction> => {
        return action$
            .ofType(ACTION_ITEM_COMPONENTS_LOAD)
            .mergeMap((action: IItemComponentLoadAction) => {
                return itemComponentsApi
                    .getItemComponents(action.qaItemComponents, action.itemComponentTypeId)
                    .map(response =>
                        LoadSuccess(response.itemComponents, undefined, undefined, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTrialsByIdEpic = (action$): Observable<IItemComponentLoadSuccessAction | IItemComponentLoadFailureAction> => {
        return action$
            .ofType(ACTION_ITEM_COMPONENTS_LOAD_BY_ID)
            .mergeMap((action: IItemComponentLoadByIdAction) => {
                return itemComponentsApi
                    .getItemComponentById(action.id)
                    .map(response =>
                        LoadSuccess(response.itemComponent, response.formState, response.formProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setItemComponentEpic = (action$): Observable<IItemComponentSaveSuccessAction | IItemComponentSaveFailureAction> => {
        return action$
            .ofType(ACTION_ITEM_COMPONENTS_SAVE)
            .mergeMap((action: IItemComponentSaveAction) => {
                return itemComponentsApi
                    .setItemComponent(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.itemComponent, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createItemComponentEpic = (action$): Observable<IItemComponentCreateSuccessAction | IItemComponentCreateFailureAction> => {
        return action$
            .ofType(ACTION_ITEM_COMPONENTS_CREATE)
            .mergeMap((action: IItemComponentCreateAction) => {
                return itemComponentsApi
                    .createItemComponent(action.cloneId)
                    .map(response =>
                        CreateSuccess(response.itemComponent, response.formState, response.formProperties, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    }



