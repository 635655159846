import * as React from 'react';

import * as classNames from "classnames";
import './InstitutionDepartmentContactEditPage.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";



import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";
// add state for sub forms too below
import { IDepartmentContactReduxFormState, initialDepartmentContactState } from "../../../reducers/reactReduxForms/departmentContact";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, DatePickerFormInputFabric, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";

import * as DepartmentContactActions from '../../../actions/departmentContact';
import * as InstitutionActions from '../../../actions/institution';

import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../../components/form';
import { SettingsSubMenu, InstitutionSubMenu } from '../../../helpers/subMenuHelper';

interface IInstitutionDepartmentContactEditPageParams {
    institutionId: number;
    departmentId: number;
    departmentContactId: number;
}

interface IInstitutionDepartmentContactEditPageProps {

    form: Dtos.DepartmentContact & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IDepartmentContactReduxFormState;

    location: Location;
    institutionId: number;
    departmentId: number;
    departmentContactId: number;
    match: match<IInstitutionDepartmentContactEditPageParams>;
    history: History;

    // From state
    departmentContact: Dtos.DepartmentContact;
    institution: Dtos.Institution;
    lookups: Dtos.Lookup[];

    loadingInstitution: boolean;

    loadingDepartmentContact: boolean;
    loadDepartmentContactSuccess: boolean;
    loadDepartmentContactFailure: boolean;

    savingDepartmentContact: boolean;
    saveDepartmentContactSuccess: boolean;
    saveDepartmentContactFailure: boolean;

    creatingDepartmentContact: boolean;
    createDepartmentContactSuccess: boolean;
    createDepartmentContactFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User
}

interface IInstitutionDepartmentContactEditPageActions {
    clearDepartmentContact: DepartmentContactActions.IDepartmentContactClearActionCreator;
    loadDepartmentContact: DepartmentContactActions.IDepartmentContactLoadByIdActionCreator;
    saveDepartmentContact: DepartmentContactActions.IDepartmentContactSaveActionCreator;
    createDepartmentContact: DepartmentContactActions.IDepartmentContactCreateActionCreator;

    clearInstitution: InstitutionActions.IInstitutionClearActionCreator;
    loadInstitution: InstitutionActions.IInstitutionLoadByIdActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type InstitutionDepartmentContactEditPageProps = IInstitutionDepartmentContactEditPageProps & IInstitutionDepartmentContactEditPageActions;

const formName: string = "departmentContact";
const reduxFormName: string = "reduxForms.departmentContact";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class InstitutionDepartmentContactEditPage extends React.PureComponent<InstitutionDepartmentContactEditPageProps, any> {

    constructor(props: InstitutionDepartmentContactEditPageProps) {
        super(props);

        this.clearDepartmentContact = this.clearDepartmentContact.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadDepartmentContact,
            createDepartmentContact,
            navigate,
            navigateReplace,
            departmentId,
            departmentContactId,
            institutionId,
            loadInstitution
        } = this.props;

        if (departmentContactId) {
            loadDepartmentContact(departmentContactId);
        } else if (!departmentContactId) {
            createDepartmentContact(departmentId);
        }
        loadInstitution(institutionId);
    }

    componentWillReceiveProps(nextProps: InstitutionDepartmentContactEditPageProps) {
    }

    componentDidUpdate(prevProps: InstitutionDepartmentContactEditPageProps, prevState) {
        const {
            form,
            institutionId,
            departmentId,
            departmentContactId,
            departmentContact,
            navigateReplace,
            loadDepartmentContact,
            loadInstitution,
            loadingDepartmentContact,
            loadDepartmentContactSuccess,
            savingDepartmentContact,
            saveDepartmentContactSuccess,
            saveDepartmentContactFailure,
            createDepartmentContact,
            validationFailures,
            creatingDepartmentContact,
            createDepartmentContactSuccess,
            navigate
        } = this.props;

        if (institutionId && prevProps.institutionId != institutionId) {
            loadInstitution(institutionId);
        }

        if (departmentContactId && prevProps.departmentContactId != departmentContactId) {
            loadDepartmentContact(departmentContactId);
        }
        else if (!departmentContactId && prevProps.departmentContactId) {
            createDepartmentContact(departmentId);
        }

        if (!loadingDepartmentContact && prevProps.loadingDepartmentContact) {
            if (loadDepartmentContactSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingDepartmentContact && prevProps.savingDepartmentContact) {
            if (saveDepartmentContactSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/institutions/" + institutionId + "/departments/" + departmentId)
                } else if (!form.saveAndReturn && departmentContactId && departmentContactId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !departmentContactId && departmentContact && departmentContact.id > 0) {
                    navigate("/institutions/" + institutionId + "/departments/" + departmentId + "/contacts/" + departmentContact.id);
                    this.loadForm();
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingDepartmentContact && prevProps.creatingDepartmentContact) {
            if (createDepartmentContactSuccess) {
                this.loadForm();
            }
            else {

            }
        }
    }

    componentWillUpdate(nextProps: InstitutionDepartmentContactEditPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearDepartmentContact();
        loadForm(reduxFormName, initialDepartmentContactState);
        resetForm(reduxFormName);
    }

    clearDepartmentContact() {
        this.props.clearDepartmentContact();
        this.props.clearInstitution();
    }

    showDepartmentContact(): boolean {
        const {
            loadingInstitution,
            loadingDepartmentContact,
            loadDepartmentContactSuccess
        } = this.props

        if (!loadingInstitution && !loadingDepartmentContact && loadDepartmentContactSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingInstitution,
            loadingDepartmentContact,
            institution
        } = this.props;

        let id = institution ? institution.id : 0;
        let name = institution ? institution.name : "Institution";
        
        return <RestrictedLayout
            subMenuTitle={institution ? institution.display : ""}
            subMenuItems={[]}
            loading={loadingInstitution || loadingDepartmentContact}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingDepartmentContact
        } = this.props;

        const formDisabled: boolean = savingDepartmentContact || (formState && formState.formStatus == Dtos.FormStatus.Disabled);
        const formEdit: boolean = (formState && formState.formStatus == Dtos.FormStatus.Disabled || (form.id && form.id  > 0));


        let formComponents: { [index: string]: RegistrationFormComponent } = {
            name: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
                labelClassName: "col-form-label col-sm-3",
                fieldClassName: "col-sm-9 generic-form-field",
            },
            professionalDisciplineId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("DepartmentContact", "ProfessionalDisciplineId", lookups), form.professionalDisciplineId)
                },
                changeAction: (model, value, form, parentModel) => {
                    changeForm(model, value);
                    if (value && value !== 15) {
                        changeForm(reduxFormName + parentModel + '.otherDisciplines', null);
                    }
                },
                labelClassName: "col-form-label col-sm-3",
                fieldClassName: "col-sm-9 generic-form-field",
            },
            otherDisciplines: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled || form.professionalDisciplineId != 15,
                labelClassName: "col-form-label col-sm-3",
                fieldClassName: "col-sm-9 generic-form-field",
            },
            email: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
                labelClassName: "col-form-label col-sm-3",
                fieldClassName: "col-sm-9 generic-form-field",
            },
            phone: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
                labelClassName: "col-form-label col-sm-3",
                fieldClassName: "col-sm-9 generic-form-field",
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
                labelClassName: "col-form-label col-sm-3",
                fieldClassName: "col-sm-9 generic-form-field",
            },
        };

        return <div>
            <h3>Department Contact</h3>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >   
                {
                    renderFormControls(form, "departmentContact", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.DepartmentContact) {
        const {
            saveDepartmentContact
        } = this.props;

        if (data) {
            let changes: Dtos.DepartmentContact = {} as Dtos.DepartmentContact;
            saveDepartmentContact(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.DepartmentContact) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            institutionId,
            departmentId,
            navigate
        } = this.props;

        navigate("/institutions/" + institutionId + "/departments/" + departmentId);
    }

    loadForm() {
        const {
            loadForm,
            departmentContact,
        } = this.props

        let extraData: Dtos.DepartmentContact = {} as Dtos.DepartmentContact;

        loadForm(reduxFormName, Object.assign({ ...departmentContact }, extraData));
    }
}


const mapStateToProps = (state: ITmdState, ownProps: InstitutionDepartmentContactEditPageProps): IInstitutionDepartmentContactEditPageProps => {

    let departmentContact: Dtos.DepartmentContact | undefined = !(state.departmentContacts.formData instanceof Array) ? state.departmentContacts.formData : undefined;
    let institution: Dtos.Institution | undefined = !(state.institutions.formData instanceof Array) ? state.institutions.formData : undefined;

    return {

        match: ownProps.match,
        institutionId: ownProps.match ? ownProps.match.params.institutionId : undefined!,
        departmentContactId: ownProps.match && ownProps.match.params.departmentContactId.toString() != "create" ? ownProps.match.params.departmentContactId : undefined!,
        departmentId: ownProps.match ? ownProps.match.params.departmentId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        departmentContact: departmentContact!,

        institution: institution!,

        loadingDepartmentContact: state.departmentContacts.loadState && state.departmentContacts.loadState.status == RequestState.Pending,
        loadDepartmentContactSuccess: state.departmentContacts.loadState && state.departmentContacts.loadState.status == RequestState.Success,
        loadDepartmentContactFailure: state.departmentContacts.loadState && state.departmentContacts.loadState.status == RequestState.Failure,

        savingDepartmentContact: state.departmentContacts.saveState && state.departmentContacts.saveState.status == RequestState.Pending,
        saveDepartmentContactSuccess: state.departmentContacts.saveState && state.departmentContacts.saveState.status == RequestState.Success,
        saveDepartmentContactFailure: state.departmentContacts.saveState && state.departmentContacts.saveState.status == RequestState.Failure,

        creatingDepartmentContact: state.departmentContacts.createState && state.departmentContacts.createState.status == RequestState.Pending,
        createDepartmentContactSuccess: state.departmentContacts.createState && state.departmentContacts.createState.status == RequestState.Success,
        createDepartmentContactFailure: state.departmentContacts.createState && state.departmentContacts.createState.status == RequestState.Failure,

        loadingInstitution: state.institutions.loadState && state.institutions.loadState.status == RequestState.Pending,

        user: state.user.data,
        lookups: state.departmentContacts.lookups,

        form: state.reduxForms.departmentContact,
        formState: state.departmentContacts.formState,
        formProperties: state.departmentContacts.formProperties,

        reduxFormState: state.reduxForms.formState.departmentContact,
        validationFailures: departmentContact ?
            state.departments.validationFailures :
            undefined,

    };
};

const mapDispatchToProps = (dispatch): IInstitutionDepartmentContactEditPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadDepartmentContact: bindActionCreators(DepartmentContactActions.LoadDepartmentContactById, dispatch),
        clearDepartmentContact: bindActionCreators(DepartmentContactActions.Clear, dispatch),
        createDepartmentContact: bindActionCreators(DepartmentContactActions.CreateDepartmentContact, dispatch),
        saveDepartmentContact: bindActionCreators(DepartmentContactActions.SaveDepartmentContact, dispatch),

        loadInstitution: bindActionCreators(InstitutionActions.LoadInstitutionById, dispatch),
        clearInstitution: bindActionCreators(InstitutionActions.Clear, dispatch)

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(InstitutionDepartmentContactEditPage);
