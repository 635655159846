import * as React from 'react';
import './FacilityAllianceMemberPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { IFacilityAllianceMemberReduxFormState, initialFacilityAllianceMemberState } from "../../../reducers/reactReduxForms/facilityAllianceMember";
import { RegistrationFormComponent } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, NumberFormInput, CheckboxFormInput } from "../../../components/form/index";
import * as FacilityAllianceMembersActions from '../../../actions/facilityAllianceMembers';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { NotificationAlert } from "../../../components/common/index";
import { Form, actions } from 'react-redux-form';
import { InstitutionLookupsLayout } from '../../index';
import * as AuthenticationHelper from '../../../helpers/authentication';

interface IFacilityAllianceMembersPageParams {
    facilityAllianceMemberId: number;
}

interface IFacilityAllianceMembersPageProps {

    form: Dtos.FacilityAllianceMember & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IFacilityAllianceMemberReduxFormState;

    location: Location;
    facilityAllianceMemberId: number;
    match: match<IFacilityAllianceMembersPageParams>;
    history: History;

    // From state
    facilityAllianceMember: Dtos.FacilityAllianceMember;
    lookups: Dtos.Lookup[];

    loadingFacilityAllianceMembers: boolean;
    loadFacilityAllianceMembersSuccess: boolean;
    loadFacilityAllianceMembersFailure: boolean;

    savingFacilityAllianceMembers: boolean;
    saveFacilityAllianceMembersSuccess: boolean;
    saveFacilityAllianceMembersFailure: boolean;

    creatingFacilityAllianceMembers: boolean;
    createFacilityAllianceMembersSuccess: boolean;
    createFacilityAllianceMembersFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface IFacilityAllianceMembersPageActions {
    clearFacilityAllianceMembers: FacilityAllianceMembersActions.IFacilityAllianceMemberClearActionCreator;
    loadFacilityAllianceMembers: FacilityAllianceMembersActions.IFacilityAllianceMemberLoadByIdActionCreator;

    saveFacilityAllianceMembers: FacilityAllianceMembersActions.IFacilityAllianceMemberSaveActionCreator;
    createFacilityAllianceMembers: FacilityAllianceMembersActions.IFacilityAllianceMemberCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type FacilityAllianceMembersPageProps = IFacilityAllianceMembersPageProps & IFacilityAllianceMembersPageActions;

const reduxFormName: string = "reduxForms.facilityAllianceMember";

class FacilityAllianceMembersPage extends React.PureComponent<FacilityAllianceMembersPageProps, any> {

    constructor(props: FacilityAllianceMembersPageProps) {
        super(props);

        this.clearFacilityAllianceMembers = this.clearFacilityAllianceMembers.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadFacilityAllianceMembers,
            facilityAllianceMemberId,
            createFacilityAllianceMembers
        } = this.props;

        if (facilityAllianceMemberId) {
            loadFacilityAllianceMembers(facilityAllianceMemberId);
        } else if (!facilityAllianceMemberId) {
            createFacilityAllianceMembers();
        }
    }

    componentWillReceiveProps(nextProps: FacilityAllianceMembersPageProps) {
    }

    componentDidUpdate(prevProps: FacilityAllianceMembersPageProps, prevState) {
        const {
            facilityAllianceMemberId,
            facilityAllianceMember,
            form,
            navigate,
            loadFacilityAllianceMembers,
            loadingFacilityAllianceMembers,
            loadFacilityAllianceMembersSuccess,
            createFacilityAllianceMembers,
            creatingFacilityAllianceMembers,
            createFacilityAllianceMembersSuccess,
            savingFacilityAllianceMembers,
            saveFacilityAllianceMembersSuccess,
            validationFailures
        } = this.props;

        if (facilityAllianceMemberId && prevProps.facilityAllianceMemberId != facilityAllianceMemberId) {
            loadFacilityAllianceMembers(facilityAllianceMemberId);
        }
        else if (!facilityAllianceMemberId && prevProps.facilityAllianceMemberId) {
            createFacilityAllianceMembers();
        }

        if (!loadingFacilityAllianceMembers && prevProps.loadingFacilityAllianceMembers) {
            if (loadFacilityAllianceMembersSuccess) {
                this.loadForm();
            }
        }

        if (!savingFacilityAllianceMembers && prevProps.savingFacilityAllianceMembers) {
            if (saveFacilityAllianceMembersSuccess) {
                if (validationFailures && validationFailures.length > 0) {
                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {
                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);
                }
                if (form.saveAndReturn) {
                    navigate("/settings/institution/facilityalliancemembers")
                } else if (!form.saveAndReturn && facilityAllianceMemberId && facilityAllianceMemberId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !facilityAllianceMemberId && facilityAllianceMember && facilityAllianceMember.id > 0) {
                    navigate("/settings/institution/facilityalliancemembers/" + facilityAllianceMember.id);
                }
            }
            else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

        if (!creatingFacilityAllianceMembers && prevProps.creatingFacilityAllianceMembers) {
            if (createFacilityAllianceMembersSuccess) {
                this.loadForm();
            }
        }
    }

    componentWillUpdate(nextProps: FacilityAllianceMembersPageProps) {
    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearFacilityAllianceMembers();
        loadForm(reduxFormName, initialFacilityAllianceMemberState);
        resetForm(reduxFormName);
    }

    clearFacilityAllianceMembers() {
        this.props.clearFacilityAllianceMembers();
    }

    showFacilityAllianceMembers(): boolean {
        const {
            loadingFacilityAllianceMembers,
            loadFacilityAllianceMembersSuccess
        } = this.props

        if (!loadingFacilityAllianceMembers && loadFacilityAllianceMembersSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingFacilityAllianceMembers
        } = this.props;

        return <InstitutionLookupsLayout loading={loadingFacilityAllianceMembers} permission={Dtos.Permission.FacilityAllianceMembersView}>
            {this.renderContent()}
        </InstitutionLookupsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingFacilityAllianceMembers,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.FacilityAllianceMembersUpdate, Dtos.Permission.FacilityAllianceMembersCreate);
        const formDisabled: boolean = !hasPermission || savingFacilityAllianceMembers || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            value: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            }
        };

        return <div>
            <h1>Facility Alliance Member</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "facilityAllianceMember", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.FacilityAllianceMember) {
        const {
            saveFacilityAllianceMembers
        } = this.props;

        if (data) {
            let changes: Dtos.Institution = {} as Dtos.Institution;
            saveFacilityAllianceMembers(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.FacilityAllianceMember) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/institution/facilityalliancemembers");
    }

    loadForm() {
        const {
            loadForm,
            facilityAllianceMember,
        } = this.props

        let extraData: Dtos.FacilityAllianceMember = {} as Dtos.FacilityAllianceMember;

        loadForm(reduxFormName, Object.assign({ ...facilityAllianceMember }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: FacilityAllianceMembersPageProps): IFacilityAllianceMembersPageProps => {

    let facilityAllianceMember: Dtos.FacilityAllianceMember | undefined = !(state.facilityAllianceMembers.formData instanceof Array) ? state.facilityAllianceMembers.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        facilityAllianceMemberId: ownProps.match && ownProps.match.params.facilityAllianceMemberId && ownProps.match.params.facilityAllianceMemberId.toString() != "create" ? ownProps.match.params.facilityAllianceMemberId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        facilityAllianceMember: facilityAllianceMember!,

        loadingFacilityAllianceMembers: state.facilityAllianceMembers.loadState && state.facilityAllianceMembers.loadState.status == RequestState.Pending,
        loadFacilityAllianceMembersSuccess: state.facilityAllianceMembers.loadState && state.facilityAllianceMembers.loadState.status == RequestState.Success,
        loadFacilityAllianceMembersFailure: state.facilityAllianceMembers.loadState && state.facilityAllianceMembers.loadState.status == RequestState.Failure,

        savingFacilityAllianceMembers: state.facilityAllianceMembers.saveState && state.facilityAllianceMembers.saveState.status == RequestState.Pending,
        saveFacilityAllianceMembersSuccess: state.facilityAllianceMembers.saveState && state.facilityAllianceMembers.saveState.status == RequestState.Success,
        saveFacilityAllianceMembersFailure: state.facilityAllianceMembers.saveState && state.facilityAllianceMembers.saveState.status == RequestState.Failure,

        creatingFacilityAllianceMembers: state.facilityAllianceMembers.createState && state.facilityAllianceMembers.createState.status == RequestState.Pending,
        createFacilityAllianceMembersSuccess: state.facilityAllianceMembers.createState && state.facilityAllianceMembers.createState.status == RequestState.Success,
        createFacilityAllianceMembersFailure: state.facilityAllianceMembers.createState && state.facilityAllianceMembers.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: [],

        form: state.reduxForms.facilityAllianceMember,
        formState: state.facilityAllianceMembers.formState,
        formProperties: state.facilityAllianceMembers.formProperties,

        reduxFormState: state.reduxForms.formState.facilityAllianceMember,
        validationFailures: facilityAllianceMember ?
            state.facilityAllianceMembers.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): IFacilityAllianceMembersPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadFacilityAllianceMembers: bindActionCreators(FacilityAllianceMembersActions.LoadFacilityAllianceMemberById, dispatch),
        clearFacilityAllianceMembers: bindActionCreators(FacilityAllianceMembersActions.Clear, dispatch),

        saveFacilityAllianceMembers: bindActionCreators(FacilityAllianceMembersActions.SaveFacilityAllianceMember, dispatch),
        createFacilityAllianceMembers: bindActionCreators(FacilityAllianceMembersActions.CreateFacilityAllianceMember, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(FacilityAllianceMembersPage);
