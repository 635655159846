import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialTrialGroupState: Dtos.TrialGroup & IRegistrationFormState = {
    id: null,
    groupId: null,
    group: null,
    active: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false,
    groupItemCategories: [],
    trialGroupItems: []
}

export interface ITrialGroupReduxFormState {
    id: IFieldState<number>,
    groupId: IFieldState<number>,
    category: IFieldState<Dtos.Group>,
    active: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>,
    groupItemCategories: IFieldState<Dtos.GroupItemCategory[]>,
    trialGroupItems: IFieldState<Dtos.TrialGroupItem[]>
}
