import * as React from 'react';
import './TrialFormEntryPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { ITrialFormReduxFormState, initialTrialFormState } from "../../../reducers/reactReduxForms/trialForm";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, DatePickerFormInputFabric, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as TrialFormActions from '../../../actions/trialForm';
import * as TrialActions from '../../../actions/trial';
import * as TrialFormsPageActions from '../../../actions/pages/trialFormsPage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { Form, actions, Control } from 'react-redux-form';
import { TrialLayout } from '../../index';

import {
    CollectionInstancePage
} from "../../../containers/index";

interface ITrialFormEntryPageParams {
    trialId: number;
    trialFormId: number;
}

interface ITrialFormEntryPageProps {

    form: Dtos.TrialForm & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITrialFormReduxFormState;

    location: Location;
    trialId: number;
    trialFormId: number;
    match: match<ITrialFormEntryPageParams>;
    history: History;

    // modals
    modalTitle: string,
    modalDescription: string,
    editModalOpen: boolean,
    collectionInstanceId: number,
    createVersionModalOpen: boolean,

    // From state
    trialForm: Dtos.TrialForm;
    trial: Dtos.Trial;
    lookups: Dtos.Lookup[];

    loadingTrial: boolean;

    loadingTrialForm: boolean;
    loadTrialFormSuccess: boolean;
    loadTrialFormFailure: boolean;

    savingTrialForm: boolean;
    saveTrialFormSuccess: boolean;
    saveTrialFormFailure: boolean;

    creatingTrialForm: boolean;
    createTrialFormSuccess: boolean;
    createTrialFormFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface ITrialFormEntryPageActions {
    clearTrialForm: TrialFormActions.ITrialFormClearActionCreator;
    loadTrialForm: TrialFormActions.ITrialFormLoadByIdActionCreator;
    saveTrialForm: TrialFormActions.ITrialFormSaveActionCreator;
    createTrialForm: TrialFormActions.ITrialFormCreateActionCreator;

    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    setEditModelViewState: TrialFormsPageActions.ITrialFormsPageSetEditModalViewStateActionCreator;
    setCreateVersionModelViewState: TrialFormsPageActions.ITrialFormsPageSetCreateVersionModalViewStateActionCreator;

}

type TrialFormEntryPageProps = ITrialFormEntryPageProps & ITrialFormEntryPageActions;

const reduxFormName: string = "reduxForms.trialForm";

class TrialFormEntryPage extends React.PureComponent<TrialFormEntryPageProps, any> {

    constructor(props: TrialFormEntryPageProps) {
        super(props);

        this.clearTrialForm = this.clearTrialForm.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTrialForm,
            createTrialForm,
            trialFormId,
            trialId,
            loadTrial
        } = this.props;

        if (trialFormId) {
            loadTrialForm(trialFormId);
        } else if (!trialFormId) {
            createTrialForm(trialId, 0, 0);
        }
        loadTrial(trialId);
    }

    componentDidUpdate(prevProps: TrialFormEntryPageProps, prevState) {
        const {
            form,
            trialId,
            trialFormId,
            trialForm,
            loadTrialForm,
            loadTrial,
            loadingTrialForm,
            loadTrialFormSuccess,
            savingTrialForm,
            saveTrialFormSuccess,
            createTrialForm,
            validationFailures,
            creatingTrialForm,
            createTrialFormSuccess,
            navigate
        } = this.props;

        if (trialId && prevProps.trialId != trialId) {
            loadTrial(trialId);
        }

        if (trialFormId && prevProps.trialFormId != trialFormId) {
            loadTrialForm(trialFormId);
        }
        else if (!trialFormId && prevProps.trialFormId) {
            createTrialForm(trialId, 0, 0);
        }

        if (!loadingTrialForm && prevProps.loadingTrialForm) {
            if (loadTrialFormSuccess) {
                this.loadForm();
            }
        }

        if (!savingTrialForm && prevProps.savingTrialForm) {
            if (saveTrialFormSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/trials/" + trialId + "/forms")
                } else if (!form.saveAndReturn && trialFormId && trialFormId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !trialFormId && trialForm && trialForm.id > 0) {
                    navigate("/trials/" + trialId + "/forms/" + trialForm.id);
                    this.loadForm();
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingTrialForm && prevProps.creatingTrialForm) {
            if (createTrialFormSuccess) {
                this.loadForm();
            }
        }
    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearTrialForm();
        loadForm(reduxFormName, initialTrialFormState);
        resetForm(reduxFormName);
    }

    clearTrialForm() {
        this.props.clearTrialForm();
        this.props.clearTrial();
    }

    showTrialForm(): boolean {
        const {
            loadingTrial,
            loadingTrialForm,
            loadTrialFormSuccess
        } = this.props

        if (!loadingTrial && !loadingTrialForm && loadTrialFormSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrial,
            loadingTrialForm
        } = this.props;

        return <TrialLayout loading={loadingTrial || loadingTrialForm} permission={Dtos.Permission.TrialFormDataEntry}>
            {this.renderContent()}
        </TrialLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingTrialForm,
            permissions,
        } = this.props;

        let noPermissionToEditMessage = false;
        if ((form.id && form.id > 0) && validationFailures && validationFailures.filter(f => f.errorCode == "PR-005").length > 0) {
            noPermissionToEditMessage = true;
        }

        const hasPermission: boolean = AuthenticationHelper.hasFormPermission(form, permissions, Dtos.Permission.TrialFormDataEntry);
        const formDisabled: boolean = !hasPermission || savingTrialForm || (formState && formState.formStatus == Dtos.FormStatus.Disabled) || noPermissionToEditMessage;

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            headerGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    formTemplateId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: true, //!formNew,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialForm", "FormTemplateId", lookups), form.formTemplateId)
                        },
                        size: 2,
                    },
                    patientId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: true, //formDisabled || !formNew,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialForm", "PatientId", lookups), form.patientId)
                        },
                        size: 2,
                    },
                    assignedToId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: true, //formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialForm", "AssignedToId", lookups), form.assignedToId)
                        },
                        size: 2,
                        hide: form.assignmentTypeId != 2
                    },
                    assignedRoleId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: true, //formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialForm", "AssignedRoleId", lookups), form.assignedToId)
                        },
                        size: 2,
                        hide: form.assignmentTypeId != 1
                    },
                    formStatusId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: true, //formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("TrialForm", "FormStatusId", lookups), form.formStatusId)
                        },
                        size: 2,
                    }
                }
            },
        };

        return <div>
            <div className="row border border-bottom-1 border-top-0">
                <div className={"col mb-2"}>
                    <h3>Trial Form Entry</h3>
                </div>
                <div className={"col-auto pr-0"}>
                    <Link className={"btn btn-secondary"} url={"/trials/" + form.trialId + '/forms/' + form.id}>Details</Link>
                </div>
                <div className={"col-auto"}>
                    <Link className={"btn btn-secondary"} url={"/trials/" + form.trialId + '/forms/' + form.id + '/report'}>Report</Link>
                </div>
            </div>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "trialForm", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
            </Form>
            {(form.collectionInstanceId && form.collectionInstanceId > 0) && <div className="mt-2">
                <CollectionInstancePage readonly={formDisabled} collectionInstanceId={form.collectionInstanceId} onClose={() => this.onCancel()} />
            </div>}
        </div>

    }


    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
        </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.TrialForm) {
        const {
           // saveTrialForm
        } = this.props;

        if (data) {
            //let changes: Dtos.TrialForm = {} as Dtos.TrialForm;
            //saveTrialForm(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.Trial) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            trialId,
            navigate
        } = this.props;

        navigate("/trials/" + trialId + "/forms");
    }

    loadForm() {
        const {
            loadForm,
            trialForm,
        } = this.props

        let extraData: Dtos.TrialForm = {} as Dtos.TrialForm;

        loadForm(reduxFormName, Object.assign({ ...trialForm }, extraData));
    }
}


const mapStateToProps = (state: ITmdState, ownProps: TrialFormEntryPageProps): ITrialFormEntryPageProps => {

    let trialForm: Dtos.TrialForm | undefined = !(state.trialForm.formData instanceof Array) ? state.trialForm.formData : undefined;
    let trial: Dtos.Trial | undefined = !(state.trials.formData instanceof Array) ? state.trials.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,
        trialFormId: ownProps.match && ownProps.match.params.trialFormId.toString() != "create" ? ownProps.match.params.trialFormId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trialForm: trialForm!,

        trial: trial!,

        modalTitle: state.trialFormsPage.name,
        modalDescription: state.trialFormsPage.description,

        editModalOpen: state.trialFormsPage.editModalOpen,
        collectionInstanceId: state.trialFormsPage.collectionInstanceId,

        createVersionModalOpen: state.trialFormsPage.createVersionModalOpen,


        loadingTrialForm: state.trialForm.loadState && state.trialForm.loadState.status == RequestState.Pending,
        loadTrialFormSuccess: state.trialForm.loadState && state.trialForm.loadState.status == RequestState.Success,
        loadTrialFormFailure: state.trialForm.loadState && state.trialForm.loadState.status == RequestState.Failure,

        savingTrialForm: state.trialForm.saveState && state.trialForm.saveState.status == RequestState.Pending,
        saveTrialFormSuccess: state.trialForm.saveState && state.trialForm.saveState.status == RequestState.Success,
        saveTrialFormFailure: state.trialForm.saveState && state.trialForm.saveState.status == RequestState.Failure,

        creatingTrialForm: state.trialForm.createState && state.trialForm.createState.status == RequestState.Pending,
        createTrialFormSuccess: state.trialForm.createState && state.trialForm.createState.status == RequestState.Success,
        createTrialFormFailure: state.trialForm.createState && state.trialForm.createState.status == RequestState.Failure,

        loadingTrial: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,

        user: state.user.data,
        permissions: permissions,
        lookups: state.trialForm.lookups,

        form: state.reduxForms.trialForm,
        formState: state.trialForm.formState,
        formProperties: state.trialForm.formProperties,

        reduxFormState: state.reduxForms.formState.trialForm,
        validationFailures: trialForm ?
            state.trialForm.validationFailures :
            undefined,

    };
};

const mapDispatchToProps = (dispatch): ITrialFormEntryPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadTrialForm: bindActionCreators(TrialFormActions.LoadTrialFormById, dispatch),
        clearTrialForm: bindActionCreators(TrialFormActions.Clear, dispatch),
        createTrialForm: bindActionCreators(TrialFormActions.CreateTrialForm, dispatch),
        saveTrialForm: bindActionCreators(TrialFormActions.SaveTrialForm, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch),

        setEditModelViewState: bindActionCreators(TrialFormsPageActions.setEditModalViewState, dispatch),
        setCreateVersionModelViewState: bindActionCreators(TrialFormsPageActions.setCreateVersionModalViewState, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialFormEntryPage);
