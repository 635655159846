import * as React from 'react';
import './CollectionInstancePage.scss';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { SubPageLayout } from "../../../components/layouts/index";
import { ICollectionInstanceReduxFormState, initialCollectionInstanceState } from "../../../reducers/reactReduxForms/collectionInstance";
import { initialSourceDocumentState } from '../../../reducers/reactReduxForms/sourceDocument';
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, TextAreaFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as CollectionInstanceActions from '../../../actions/collectionInstance';
import * as ItemComponentActions from '../../../actions/itemComponents';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { findLookup, generateOptionsFromLookup, findLookupValue } from "../../../helpers/lookupHelper";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import { Form, actions, Control } from 'react-redux-form';
import { PrimitiveFormDesigner, PrimitiveFormInput, CustomFormInput } from '../../../components/form/index';
import { GridRemove } from "../../../enumerations/GridRemove";
import { FileUploadFormInput } from "../../../components/form/inputs/FileUploadFormInput/index";
import { formatBytes, SOURCE_DOCUMENT_FILE_CONFIGURATION } from "../../../helpers/file";
import { DEFAULT_FILE_UPLOAD_VIEW, SOURCE_DOCUMENT_UPLOAD_VIEW } from "../../../components/form/inputs/FileUploadFormInput/FileUploadViews";
import { requiredText, requiredSourceDocument } from "../../../validators/index";

interface ICollectionInstancePageProps {
    readonly?: boolean;
    form: Dtos.CollectionInstance & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ICollectionInstanceReduxFormState;

    location: Location;
    collectionInstanceId: number;
    history: History;

    // From state
    collection: Dtos.Collection;
    collectionInstance: Dtos.CollectionInstance;
    lookups: Dtos.Lookup[];

    loadingCollectionInstance: boolean;
    loadCollectionInstanceSuccess: boolean;
    loadCollectionInstanceFailure: boolean;

    savingCollectionInstance: boolean;
    saveCollectionInstanceSuccess: boolean;
    saveCollectionInstanceFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    // Item components from state
    itemComponents: Dtos.ItemComponent[];
    activeItemComponents: Dtos.ItemComponent[];
    loadingItemComponents: boolean;

    // column items
    column1: Dtos.CollectionItemComponent[];
    column2: Dtos.CollectionItemComponent[];
    column3: Dtos.CollectionItemComponent[];
    documents: Dtos.CollectionItemComponent[];

    onClose?: Function;

    user: Dtos.User;
}

interface ICollectionInstancePageActions {
    clearCollectionInstance: CollectionInstanceActions.ICollectionInstanceClearActionCreator;
    loadCollectionInstance: CollectionInstanceActions.ICollectionInstanceLoadByIdActionCreator;
    saveCollectionInstance: CollectionInstanceActions.ICollectionInstanceSaveActionCreator;
    expandSection: CollectionInstanceActions.ICollectionInstanceExpandSectionActionCreator;

    clearItemComponents: ItemComponentActions.IItemComponentClearActionCreator;
    loadItemComponents: ItemComponentActions.IItemComponentLoadActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type CollectionInstancePageProps = ICollectionInstancePageProps & ICollectionInstancePageActions;

interface ICollectionInstancePageState {
    collectionInstanceDataStates: CollectionInstanceDataState[];
    isValid: boolean;
}

const reduxFormName: string = "reduxForms.collectionInstance";
const reduxFormNameCollectionInstanceDatas: string = "reduxForms.collectionInstance.collectionInstanceDatas";

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableHeaderDangerClass = "btn btn-danger btn-block rounded-0 p-3 d-flex"
const tableHeaderNaClass = "btn btn-secondary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}

class CollectionInstancePage extends React.PureComponent<CollectionInstancePageProps, ICollectionInstancePageState> {

    constructor(props: CollectionInstancePageProps) {
        super(props);

        this.state = {
            collectionInstanceDataStates: [],
            isValid: true,
        }

        this.clearCollectionInstance = this.clearCollectionInstance.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadCollectionInstance,
            loadItemComponents,
            collectionInstanceId
        } = this.props;

        loadItemComponents(false, -1);

        if (collectionInstanceId) {
            loadCollectionInstance(collectionInstanceId);
        }
    }

    componentDidUpdate(prevProps: CollectionInstancePageProps, prevState) {
        const {
            collectionInstanceId,
            form,
            loadCollectionInstance,
            loadingCollectionInstance,
            loadCollectionInstanceSuccess,
            savingCollectionInstance,
            validationFailures,
            saveCollectionInstanceSuccess,
            readonly
        } = this.props;

        if (collectionInstanceId && prevProps.collectionInstanceId != collectionInstanceId) {
            if (collectionInstanceId > 0) {
                loadCollectionInstance(collectionInstanceId);
            }
        }

        if (JSON.stringify(form) != JSON.stringify(prevProps.form)) {
            let collectionInstanceDataStates = validateCollectionInstance(flattenItemComponentWithDatas(form), (readonly || savingCollectionInstance));
            let isValid = collectionInstanceIsValid(collectionInstanceDataStates);
            this.setState({ collectionInstanceDataStates, isValid });
        }

        if (!loadingCollectionInstance && prevProps.loadingCollectionInstance) {
            if (loadCollectionInstanceSuccess) {
                this.loadForm();
            }
        }

        if (!savingCollectionInstance && prevProps.savingCollectionInstance) {
            if (saveCollectionInstanceSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }

                if (form.saveAndReturn) {
                    this.onCancel();
                } else {
                    this.loadForm();
                }
            } else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearCollectionInstance();
        loadForm(reduxFormName, initialCollectionInstanceState);
        resetForm(reduxFormName);
    }

    clearCollectionInstance() {
        this.props.clearCollectionInstance();
        this.props.clearItemComponents();
    }

    showCollectionInstance(): boolean {
        const {
            loadingCollectionInstance,
            loadingItemComponents
        } = this.props

        if (!loadingCollectionInstance && !loadingItemComponents) {
            return true;
        }
        return false;
    }

    render() {
        return <SubPageLayout loading={!this.showCollectionInstance()}>
            {this.renderContent()}
        </SubPageLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            documents,
            expandSection,
            collectionInstance,
            savingCollectionInstance,
            readonly
        } = this.props;

        const formDisabled: boolean = readonly || savingCollectionInstance || (formState && formState.formStatus == Dtos.FormStatus.Disabled);
        let formComponents: { [index: string]: RegistrationFormComponent } = {};

        // what is the current sections
        let sectionId: number = 0;
        let columnFormat: string = form.columnFormat;
        if (collectionInstance && collectionInstance.hasSections) {
            collectionInstance.sections.forEach(s => {
                if (s.expanded) {
                    sectionId = s.collectionSection.id;
                    columnFormat = s.columnFormat;
                }
            });
        }
        let section = form.sections.find(s => s.collectionSectionId == sectionId);

        if (documents) {
            documents.filter(f => f.collectionSectionId == sectionId).forEach(itemComponentWithDocuments => {
                itemComponentWithDocuments.itemComponent.itemComponentPrimitives.filter(f => f.active && f.itemPrimitive == Dtos.ItemPrimitive.Documents).forEach(item => {
                    // TO DO ~ find the data record - move this to the property mapping for performance reasons
                    const collectionInstanceData: Dtos.CollectionInstanceData = form.collectionInstanceDatas.find(i =>
                        i.itemComponentPrimitiveId == item.id &&
                        i.itemComponentId == item.itemComponentId);

                    if (collectionInstanceData != null) {
                        formComponents["sourceDocuments" + collectionInstanceData.id] = {
                            type: RegistrationComponent.RegistrationSourceDocuments,
                            label: item.label,
                            disabled: formDisabled || (section!== undefined && section.locked),
                            components: {
                                sourceDocuments: {
                                    type: RegistrationComponent.RegistrationGrid,
                                    formName: "SourceDocument",
                                    disabled: formDisabled,
                                    label: "Add Source Document",
                                    className: "source-documents-source-documents",
                                    minRows: 0,
                                    columnStyles: [
                                        {
                                            width: undefined,
                                            minWidth: "200px"
                                        },
                                        {
                                            width: "23%",
                                            minWidth: "150px"
                                        },
                                        {
                                            width: "20%",
                                            minWidth: "150px"
                                        },
                                        {
                                            width: "125px"
                                        },
                                    ],
                                    removeConfig: GridRemove.All,
                                    addRow: (gridComponent, index, array) => {
                                        const state: Dtos.SourceDocument = Object.assign(
                                            { ...gridComponent.initialState }
                                        );
                                        return state;
                                    },
                                    components: {
                                        name: {
                                            type: RegistrationComponent.RegistrationControl,
                                            inputType: TextFormInput,
                                            inputDisabled: formDisabled,
                                            inputLabel: getFormPropertyLabel("CollectionInstance", "FileName", formProperties),
                                            inputProps: {
                                                feedbackIcon: FontAwesomeIcons.Solid.BAN
                                            },
                                            validators: {
                                                required: requiredText
                                            },
                                            errors: {
                                                required: "File name is required."
                                            },
                                            errorComponent: ValidationMessage

                                        },
                                        fileType: {
                                            type: RegistrationComponent.RegistrationControl,
                                            inputType: SelectFormInput,
                                            inputLabel: getFormPropertyLabel("CollectionInstance", "FileType", formProperties),
                                            inputProps: {
                                                options: generateOptionsFromLookup(findLookup("CollectionSourceDocument", "FileType", lookups)),
                                                feedbackIcon: FontAwesomeIcons.Solid.BAN
                                            },
                                            validators: {
                                                required: value => value != undefined
                                            },
                                            errors: {
                                                required: "File type is required."
                                            },
                                            errorComponent: ValidationMessage
                                        },
                                        fileInformation: {
                                            type: RegistrationComponent.RegistrationControl,
                                            inputType: FileUploadFormInput,
                                            inputDisabled: formDisabled,
                                            inputLabel: getFormPropertyLabel("CollectionInstance", "Upload", formProperties),
                                            changeAction: (model, value, form, parentModel) => {
                                                changeForm(model, value);
                                                const inputValue: Dtos.TemporaryFileInformation = value;
                                                const sourceDocument: Dtos.SourceDocument = form;

                                                if (sourceDocument.fileInformation &&
                                                    inputValue &&
                                                    sourceDocument.fileInformation.state != inputValue.state &&
                                                    inputValue.state == Dtos.FileState.Success &&
                                                    !sourceDocument.name) {
                                                    changeForm(reduxFormName + parentModel + ".name", inputValue.temporaryFile.name.substr(0, inputValue.temporaryFile.name.lastIndexOf(inputValue.temporaryFile.extension)));
                                                }
                                            },
                                            inputProps: {
                                                render: SOURCE_DOCUMENT_UPLOAD_VIEW
                                            },
                                            validators: {
                                                required: requiredSourceDocument
                                            },
                                            errors: {
                                                required: "File must be uploaded."
                                            },
                                            errorComponent: ValidationMessage
                                        },
                                        fileConfiguration: {
                                            type: RegistrationComponent.RegistrationGridElement,
                                            label: " ",
                                            render: SOURCE_DOCUMENT_FILE_CONFIGURATION
                                        }
                                    },
                                    initialState: { ...initialSourceDocumentState, subRecordId: collectionInstanceData.id }
                                },
                            },
                            keyReplace: "sourceDocuments",
                            onRemove: (model: string) => {
                                console.log('onRemove', model);
                                changeForm(model + "active", false);
                                return true; // this was handled here
                            },
                            filterRow: (data: Dtos.SourceDocument) => {
                                console.info('filterRow', data);
                                if (data.active != true || data.subRecordId != collectionInstanceData.id) {
                                    return false
                                }
                                return true;
                            }
                        };
                    }

                });
            });
        }

        return <div>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                <div className="x-container-fluid">
                    {form && form.sections.map((section, sectionIndex) => (
                        <div key={"map-section-" + section.collectionSection.id}>
                            {form.hasSections && (
                                <div key={"section-" + section.collectionSection.id}>
                                    <div className={section.isNa ? tableHeaderNaClass : section.isMissingRequiredData ? tableHeaderDangerClass : tableHeaderClass} style={tableStyleClass} onClick={() => {
                                        expandSection(section.collectionSection.id);
                                    }}>
                                        <div className="d-flex" style={{ width: '100%' }}>
                                            <div>
                                                {!section.isNa && (<>
                                                    <FontAwesomeIcon icon={!section.expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" />
                                                </>)}
                                            </div>
                                            <div className="flex-grow-1">
                                                <span>{section.collectionSection.name}</span>
                                            </div>
                                            <div className="px-2" style={{ minWidth: "150px", margin: "-7px 0", fontWeight: 'normal' }}>
                                                <SelectFormInput
                                                    name={section.collectionSectionId + "section_assigmentType"}
                                                    id={section.collectionSectionId + "section_assigmentType"}
                                                    value={section.assignmentTypeId}
                                                    loading={false}
                                                    multi={false}
                                                    disabled={section.locked}
                                                    placeholder={"Assign..."}
                                                    options={generateOptionsFromLookup(findLookup("TrialForm", "AssignmentTypeId", lookups), section.assignmentTypeId)}
                                                    clearable={true}
                                                    backspaceRemoves={true}
                                                    onChange={(v, o) => {
                                                        let changeFormName = reduxFormName + ".sections[" + sectionIndex + "].assignmentTypeId";
                                                        changeForm(changeFormName, v, o);
                                                        return null;
                                                    }}
                                                />
                                            </div>
                                            <div className="px-2" style={{ minWidth: "180px", margin: "-7px 0", fontWeight: 'normal' }}>
                                                {section.assignmentTypeId == 1 && <>
                                                    <SelectFormInput
                                                        name={section.collectionSectionId + "section_assignedRoleId"}
                                                        id={section.collectionSectionId + "section_assignedRoleId"}
                                                        value={section.assignedRoleId}
                                                        loading={false}
                                                        multi={false}
                                                        disabled={section.locked}
                                                        placeholder={"To..."}
                                                        options={generateOptionsFromLookup(findLookup("TrialForm", "AssignedRoleId", lookups), section.assignedRoleId)}
                                                        clearable={true}
                                                        backspaceRemoves={true}
                                                        onChange={(v, o) => {
                                                            let changeFormName = reduxFormName + ".sections[" + sectionIndex + "].assignedRoleId";
                                                            changeForm(changeFormName, v, o);
                                                            changeForm(reduxFormName + ".sections[" + sectionIndex + "].assignedTo", null);
                                                            changeForm(reduxFormName + ".sections[" + sectionIndex + "].assignedRole", null);
                                                            return null;
                                                        }}
                                                    />
                                                </>}
                                                {section.assignmentTypeId == 2 && <>
                                                    <SelectFormInput
                                                        name={section.collectionSectionId + "section_assignedToId"}
                                                        id={section.collectionSectionId + "section_assignedToId"}
                                                        value={section.assignedToId}
                                                        loading={false}
                                                        multi={false}
                                                        placeholder={"To..."}
                                                        options={generateOptionsFromLookup(findLookup("TrialForm", "AssignedToId", lookups), section.assignedToId)}
                                                        clearable={true}
                                                        backspaceRemoves={true}
                                                        onChange={(v, o) => {
                                                            let changeFormName = reduxFormName + ".sections[" + sectionIndex + "].assignedToId";
                                                            changeForm(changeFormName, v, o);
                                                            changeForm(reduxFormName + ".sections[" + sectionIndex + "].assignedTo", null);
                                                            changeForm(reduxFormName + ".sections[" + sectionIndex + "].assignedRole", null);
                                                            return null;
                                                        }}
                                                    />
                                                </>}
                                            </div>
                                            <div style={{ minWidth: "220px", textAlign: 'right' }}>
                                                {!section.isNa && (<>
                                                    <span>{section.summary}</span>
                                                </>)}
                                            </div>
                                            <div style={{ minWidth: "70px" }}>
                                                {section.collectionSection.isNaAllowed && (<div className="pl-4">
                                                    <CheckboxFormInput name={section.collectionSectionId + "section_isNa"} id={section.collectionSectionId + "section_isNa"} value={section.isNa}
                                                        onChange={(v, o) => {
                                                            let changeFormName = reduxFormName + ".sections[" + sectionIndex + "].isNa";
                                                            changeForm(changeFormName, v, o);
                                                            return null;
                                                        }}
                                                        additionalContent={""}
                                                        disabled={section.locked}
                                                        hideLabel={false} label={"N/A"} />
                                                </div>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {(!form.hasSections || (section.collectionSection.id == sectionId && !section.isNa)) &&
                                <div className="row">
                                    <div className="col-12 mb-3 mt-3">
                                        {columnFormat == "SMALL-LARGE-DOCUMENTS-RIGHT" && (
                                            <div className="row">
                                                <div className="col-4">
                                                    {form.column1.map((itemComponentWithData, itemComponentWithDataIndex) => {
                                                        if (itemComponentWithData.collectionSectionId != sectionId) return null;
                                                        return this.renderItemComponentWithData(0, itemComponentWithData, reduxFormName + ".column1[" + itemComponentWithDataIndex + "].collectionInstanceData", section.formLocked)
                                                    })}
                                                </div>
                                                <div className="col-8">
                                                    {form.column2.map((itemComponentWithData, itemComponentWithDataIndex) => {
                                                        if (itemComponentWithData.collectionSectionId != sectionId) return null;
                                                        return this.renderItemComponentWithData(0, itemComponentWithData, reduxFormName + ".column2[" + itemComponentWithDataIndex + "].collectionInstanceData", section.formLocked)
                                                    })}
                                                    {form.column3.map((itemComponentWithData, itemComponentWithDataIndex) => {
                                                        if (itemComponentWithData.collectionSectionId != sectionId) return null;
                                                        return this.renderItemComponentWithData(0, itemComponentWithData, reduxFormName + ".column3[" + itemComponentWithDataIndex + "].collectionInstanceData", section.formLocked)
                                                    })}
                                                    {
                                                        renderFormControls(form, "collectionInstance", formComponents, lookups, validationFailures, formProperties, changeForm)
                                                    }
                                                </div>
                                            </div>
                                        )}
                                        {columnFormat == "EVEN" && (
                                            <div className="row">
                                                <div className="col">
                                                    <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                        <div className="row">
                                                            {form.column1.length > 0 && (
                                                                <div className="col">
                                                                    {form.column1.map((itemComponentWithData, itemComponentWithDataIndex) => {
                                                                        if (itemComponentWithData.collectionSectionId != sectionId) return null;
                                                                        return this.renderItemComponentWithData(0, itemComponentWithData, reduxFormName + ".column1[" + itemComponentWithDataIndex + "].collectionInstanceData", section.formLocked)
                                                                    })}
                                                                </div>
                                                            )}
                                                            {form.column2.length > 0 && (
                                                                <div className="col">
                                                                    {form.column2.map((itemComponentWithData, itemComponentWithDataIndex) => {
                                                                        if (itemComponentWithData.collectionSectionId != sectionId) return null;
                                                                        return this.renderItemComponentWithData(0, itemComponentWithData, reduxFormName + ".column2[" + itemComponentWithDataIndex + "].collectionInstanceData", section.formLocked)
                                                                    })}
                                                                </div>
                                                            )}
                                                            {form.column3.length > 0 && (
                                                                <div className="col">
                                                                    {form.column3.map((itemComponentWithData, itemComponentWithDataIndex) => {
                                                                        if (itemComponentWithData.collectionSectionId != sectionId) return null;
                                                                        return this.renderItemComponentWithData(0, itemComponentWithData, reduxFormName + ".column3[" + itemComponentWithDataIndex + "].collectionInstanceData", section.formLocked)
                                                                    })}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                {
                                                                    renderFormControls(form, "collectionInstance", formComponents, lookups, validationFailures, formProperties, changeForm)
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                        </div>
                    ))}
                    <div className="row" style={{ marginTop: '10px' }}>
                        <div className="col" style={{ textAlign: 'right' }}>
                            <button disabled={formDisabled || !this.state.isValid}
                                onClick={
                                    () => {
                                        this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                                    }
                                }
                                type="submit"
                                className="btn btn-primary mr-2"
                            >
                                Submit
                            </button>

                            <button disabled={formDisabled || !this.state.isValid}
                                onClick={() => {
                                    this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                                }}
                                type="submit"
                                className="btn btn-primary mr-2"
                            >
                                Submit And Return
                            </button>
                            <button type="button" className="btn btn-danger" onClick={() => this.onCancel()}>Cancel</button>
                        </div>
                    </div>
                </div>
            </Form>
        </div >
    }

    renderItemComponentWithData(trialItemInstitutionsIndex: number, itemComponentWithData: Dtos.ItemComponentWithData, reduxFormNameParent: string, locked: boolean) {
        const {
            lookups,
            changeForm,
            form,
            savingCollectionInstance,
            readonly
        } = this.props;

        if (itemComponentWithData.collectionInstanceData && itemComponentWithData.itemComponent && itemComponentWithData.itemComponentPrimitive) {
            const name = "itemComponentInputPrimitives_" + itemComponentWithData.collectionInstanceData.id;

            let collectionInstanceDataState = this.state.collectionInstanceDataStates.find(f => f.id == itemComponentWithData.collectionInstanceData.id);
            if (collectionInstanceDataState == undefined) {
                collectionInstanceDataState = {
                    id: itemComponentWithData.collectionInstanceData.id,
                    isAnswered: false,
                    isDisabled: readonly || savingCollectionInstance || locked || itemComponentWithData.formLocked,
                    isRequired: false,
                    isValid: true,
                    validations: []
                };
            }

            let primitiveItem = { ...itemComponentWithData.itemComponentPrimitive };
            if (itemComponentWithData.overrideLabel) {
                primitiveItem.label = itemComponentWithData.overrideLabel;
            }

            return <div key={"itemComponent-" + itemComponentWithData.collectionInstanceData.id} style={{ display: 'block' }}>
                <div className={((itemComponentWithData.isQuestion && !itemComponentWithData.isAnswered && itemComponentWithData.isRequired) ? "text-danger" : "")} style={{ width: '100%' }}>
                    <PrimitiveFormInput
                        key={name}
                        item={primitiveItem}
                        id={name}
                        name={name}
                        disabled={collectionInstanceDataState.isDisabled || itemComponentWithData.collectionInstanceData.isNa}
                        textValue={itemComponentWithData.collectionInstanceData.textValue ? itemComponentWithData.collectionInstanceData.textValue : ""}
                        bitValue={itemComponentWithData.collectionInstanceData.bitValue != null ? itemComponentWithData.collectionInstanceData.bitValue : false}
                        intValue={itemComponentWithData.collectionInstanceData.intValue != null ? itemComponentWithData.collectionInstanceData.intValue : null}
                        numberValue={itemComponentWithData.collectionInstanceData.numberValue != null ? itemComponentWithData.collectionInstanceData.numberValue : null}
                        dateValue={itemComponentWithData.collectionInstanceData.dateValue != null ? itemComponentWithData.collectionInstanceData.dateValue : null}
                        onChange={(field, value) => {
                            console.log("itemComponentWithData.collectionInstanceData", field, reduxFormNameParent, value);
                            changeForm(reduxFormNameParent + "." + field, value);
                            changeForm(reduxFormNameParent + ".isModified", true);
                        }}
                        validationFailures={collectionInstanceDataState.validations}
                        isRequired={collectionInstanceDataState.isRequired || itemComponentWithData.isRequired}
                        instructions={itemComponentWithData.instructions}
                        isNaAllowed={itemComponentWithData.isNaAllowed}
                        isNa={itemComponentWithData.collectionInstanceData.isNa}
                    />
                    {(itemComponentWithData.isLastPrimitive && itemComponentWithData.itemComponent.sourceDocuments && itemComponentWithData.itemComponent.sourceDocuments.length) > 0 && (
                        <div className="card">
                            <div className="card-body p-3">
                                <h6 className="card-subtitle text-muted">Downloads and Documents</h6>
                                {itemComponentWithData.itemComponent.sourceDocuments.map((sourceDocument, sourceDocumentIndex) => <div className="d-flex">
                                    <div className="flex-grow-1 py-2">{sourceDocument.name}</div>
                                    <div className="p-2 text-muted"><em>{findLookupValue("ItemComponentSourceDocument", "FileType", sourceDocument.fileType, lookups)}</em></div>
                                    <div className="">{SOURCE_DOCUMENT_FILE_CONFIGURATION(form, changeForm, reduxFormName, lookups, sourceDocument, sourceDocumentIndex)}</div>
                                </div>)}
                            </div>
                        </div>
                    )}
                </div>
            </div>;
        }


    }

    findItemComponentWithData(trialItemInstitutionsIndex: number, id: number) {
        const {
            form
        } = this.props;

        let result: Dtos.ItemComponentWithData = null;

        form.column1.forEach(item => {
            if (item.itemComponentPrimitive && item.itemComponentPrimitive.id == id) {
                result = item;
            }
        });

        form.column2.forEach(item => {
            if (item.itemComponentPrimitive && item.itemComponentPrimitive.id == id) {
                result = item;
            }
        });

        form.column3.forEach(item => {
            if (item.itemComponentPrimitive && item.itemComponentPrimitive.id == id) {
                result = item;
            }
        });

        return result;
    }

    // remove after testing
    renderItemComponent(collectionItemComponent: Dtos.CollectionItemComponent, top: boolean, bottom: boolean, first: boolean, last: boolean) {
        const {
            itemComponents,
            collectionInstance,
            reduxFormState,
            changeForm,
            form,
            savingCollectionInstance,
            validationFailures
        } = this.props;

        if (itemComponents) {
            // find the item component
            //const itemComponent: Dtos.ItemComponent = itemComponents.find(i => i.id == collectionItemComponent.itemComponentId);
            const itemComponent: Dtos.ItemComponent = collectionItemComponent.itemComponent;
            return <div key={"itemComponent-" + collectionItemComponent.id} className="x-item-components-border-padded">
                <div className="x-container">
                    <div className="row no-gutters" style={{ paddingBottom: '10px' }}>
                        <div className="col">
                            {itemComponent.itemComponentPrimitives && itemComponent.itemComponentPrimitives.filter(f => f.active && f.itemPrimitive != Dtos.ItemPrimitive.Documents).map((item, index) => {
                                const name = "itemComponentInputPrimitives_" + item.id + "_" + index;

                                let disabled: boolean = savingCollectionInstance;

                                if (form.collectionInstanceDatas) {
                                    // TO DO ~ find the data record - move this to the property mapping for performance reasons
                                    const collectionInstanceData: Dtos.CollectionInstanceData = form.collectionInstanceDatas.find(i =>
                                        i.itemComponentPrimitiveId == item.id &&
                                        i.itemComponentId == item.itemComponentId);
                                    const indexCollectionInstanceData = form.collectionInstanceDatas.indexOf(collectionInstanceData);
                                    const collectionInstanceDataId = collectionInstanceData ? collectionInstanceData.id : "0";
                                    const validations = validationFailures == undefined ? undefined : validationFailures.filter(f => f.fieldName == 'CollectionInstanceDatas[' + collectionInstanceDataId + '].TextValue');
                                    if (item.itemComponentRules && item.itemComponentRules.length > 0 && !savingCollectionInstance) {
                                        item.itemComponentRules.forEach(rule => {
                                            if (rule.itemPrimitiveRule == Dtos.ItemPrimitiveRule.IsDisabledIf) {
                                                const compareCollectionInstanceData: Dtos.CollectionInstanceData = form.collectionInstanceDatas.find(i =>
                                                    i.itemComponentPrimitiveId == rule.compareItemComponentPrimitiveId &&
                                                    i.itemComponentId == rule.itemComponentId);
                                                if (compareCollectionInstanceData != undefined) {

                                                    const compareItemComponentPrimitive: Dtos.ItemComponentPrimitive = itemComponent.itemComponentPrimitives.find(f => f.id == rule.compareItemComponentPrimitiveId);
                                                    if (compareItemComponentPrimitive != undefined) {
                                                        switch (compareItemComponentPrimitive.itemPrimitive) {
                                                            case Dtos.ItemPrimitive.Checkbox:
                                                                if (compareCollectionInstanceData.bitValue == true && (rule.compareValue == "1" || rule.compareValue.toLowerCase() == "true")) {
                                                                    disabled = true;
                                                                }
                                                                if (compareCollectionInstanceData.bitValue == false && (rule.compareValue == "" || rule.compareValue == "0" || rule.compareValue.toLowerCase() == "false")) {
                                                                    disabled = true;
                                                                }
                                                                break;
                                                            case Dtos.ItemPrimitive.Text:
                                                                if (compareCollectionInstanceData.textValue.toLowerCase() == rule.compareValue.toLowerCase()) {
                                                                    disabled = true;
                                                                }
                                                                break;
                                                            case Dtos.ItemPrimitive.Comments:
                                                                if (compareCollectionInstanceData.textValue.toLowerCase() == rule.compareValue.toLowerCase()) {
                                                                    disabled = true;
                                                                }
                                                                break;
                                                            case Dtos.ItemPrimitive.Select:
                                                                let option = compareItemComponentPrimitive.itemComponentPrimitiveOptions.find(f => f.value.toLowerCase() == rule.compareValue.toLowerCase());
                                                                if (option.id == compareCollectionInstanceData.intValue) {
                                                                    disabled = true;
                                                                }
                                                                break;
                                                            case Dtos.ItemPrimitive.Numeric:
                                                                if (compareCollectionInstanceData.numberValue.toString() == rule.compareValue) {
                                                                    disabled = true;
                                                                }
                                                                break;
                                                        }
                                                    }
                                                }
                                            }
                                            if (rule.itemPrimitiveRule == Dtos.ItemPrimitiveRule.IsEnabledIf) {
                                                const compareCollectionInstanceData: Dtos.CollectionInstanceData = form.collectionInstanceDatas.find(i =>
                                                    i.itemComponentPrimitiveId == rule.compareItemComponentPrimitiveId &&
                                                    i.itemComponentId == rule.itemComponentId);
                                                if (compareCollectionInstanceData != undefined) {

                                                    const compareItemComponentPrimitive: Dtos.ItemComponentPrimitive = itemComponent.itemComponentPrimitives.find(f => f.id == rule.compareItemComponentPrimitiveId);
                                                    if (compareItemComponentPrimitive != undefined) {
                                                        disabled = true;
                                                        switch (compareItemComponentPrimitive.itemPrimitive) {
                                                            case Dtos.ItemPrimitive.Checkbox:
                                                                if (compareCollectionInstanceData.bitValue == true && (rule.compareValue == "1" || rule.compareValue.toLowerCase() == "true")) {
                                                                    disabled = false;
                                                                }
                                                                if (compareCollectionInstanceData.bitValue == false && (rule.compareValue == "" || rule.compareValue == "0" || rule.compareValue.toLowerCase() == "false")) {
                                                                    disabled = false;
                                                                }
                                                                break;
                                                            case Dtos.ItemPrimitive.Text:
                                                                if (compareCollectionInstanceData.textValue.toLowerCase() == rule.compareValue.toLowerCase()) {
                                                                    disabled = false;
                                                                }
                                                                break;
                                                            case Dtos.ItemPrimitive.Comments:
                                                                if (compareCollectionInstanceData.textValue.toLowerCase() == rule.compareValue.toLowerCase()) {
                                                                    disabled = false;
                                                                }
                                                                break;
                                                            case Dtos.ItemPrimitive.Select:
                                                                let option = compareItemComponentPrimitive.itemComponentPrimitiveOptions.find(f => f.value.toLowerCase() == rule.compareValue.toLowerCase());
                                                                if (option.id == compareCollectionInstanceData.intValue) {
                                                                    disabled = false;
                                                                }
                                                                break;
                                                            case Dtos.ItemPrimitive.Numeric:
                                                                if (compareCollectionInstanceData.numberValue.toString() == rule.compareValue) {
                                                                    disabled = false;
                                                                }
                                                                break;
                                                        }
                                                    }
                                                }
                                            }
                                        });
                                    }

                                    // ~ TO DO
                                    return <PrimitiveFormInput
                                        key={name}
                                        item={item}
                                        id={name}
                                        name={name}
                                        disabled={disabled}
                                        textValue={collectionInstanceData && collectionInstanceData.textValue ? collectionInstanceData.textValue : ""}
                                        bitValue={collectionInstanceData && collectionInstanceData.bitValue != null ? collectionInstanceData.bitValue : false}
                                        intValue={collectionInstanceData && collectionInstanceData.intValue != null ? collectionInstanceData.intValue : null}
                                        numberValue={collectionInstanceData && collectionInstanceData.numberValue != null ? collectionInstanceData.numberValue : null}
                                        dateValue={collectionInstanceData && collectionInstanceData.dateValue != null ? collectionInstanceData.dateValue : null}
                                        onChange={(field, value) => {
                                            //con sole.log('PrimitiveFormInput->onChange', field, value);
                                            changeForm(reduxFormNameCollectionInstanceDatas + "[" + indexCollectionInstanceData.toString() + "]." + field, value);
                                        }}
                                        validationFailures={validations}
                                    />
                                }


                            }
                            )}
                            {itemComponent.customInputControlName != '' && (
                                <CustomFormInput
                                    item={itemComponent}
                                    id={"itemComponent_" + itemComponent.id + "_" + itemComponent.customInputControlName}
                                    name={"itemComponent_" + itemComponent.id + "_" + itemComponent.customInputControlName}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>;
        }

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
        </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    toggleMessageModal() {
        //const {
        //    toggleMessageModal
        //} = this.props
        // eslint-disable-next-line
        //toggleMessageModal;
    }

    handleOnSubmit(data: Dtos.CollectionInstance) {
        const {
            saveCollectionInstance
        } = this.props;

        if (data) {
            let changes: Dtos.CollectionInstance = {} as Dtos.CollectionInstance;
            saveCollectionInstance(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.CollectionInstance) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        if (this.props.onClose) { this.props.onClose() }
    }

    loadForm() {
        const {
            loadForm,
            collectionInstance,
        } = this.props

        let extraData: Dtos.CollectionInstance = {} as Dtos.CollectionInstance;

        loadForm(reduxFormName, Object.assign({ ...collectionInstance }, extraData));
    }

}

const filterItemComponentsByActive = (itemComponents: Dtos.ItemComponent[], active: boolean): Dtos.ItemComponent[] => {

    if (itemComponents) {
        return itemComponents.filter(p => p.active == active);
    }

    return [];
}

const allItemComponents = (itemComponents: Dtos.ItemComponent[]): Dtos.ItemComponent[] => {

    if (itemComponents) {
        return itemComponents;
    }

    return [];
}

const filterCollectionItemComponentsByColumn = (collection: Dtos.Collection, column: number): Dtos.CollectionItemComponent[] => {

    if (collection && collection.collectionItemComponents) {
        return collection.collectionItemComponents.filter(p => p.active == true && p.column == column && p.itemComponent.active && (p.itemComponent.customInputControlName != '' || p.itemComponent.itemComponentPrimitives.filter(f => f.active && f.itemPrimitive != Dtos.ItemPrimitive.Documents).length > 0)).sort((a, b) => a.rank - b.rank);
    }

    return [];
}

const filterCollectionItemComponentsByDocuments = (collection: Dtos.Collection): Dtos.CollectionItemComponent[] => {

    if (collection && collection.collectionItemComponents) {
        return collection.collectionItemComponents.filter(p => p.active == true && p.itemComponent.active && (p.itemComponent.itemComponentPrimitives.filter(f => f.active && f.itemPrimitive == Dtos.ItemPrimitive.Documents).length > 0)).sort((a, b) => a.rank - b.rank);
    }

    return [];
}

export class CollectionInstanceDataState {
    public id: number;
    public isDisabled: boolean;
    public isAnswered: boolean;
    public isRequired: boolean;
    public isValid: boolean;
    public validations: Dtos.ResponseError[];
}

const flattenItemComponentWithDatas = (form: Dtos.CollectionInstance & IRegistrationFormState): Dtos.ItemComponentWithData[] => {
    /* combine the datas into an array */
    let itemComponentWithDatas: Dtos.ItemComponentWithData[] = [];

    form.column1.forEach(itemComponentWithData => {
        itemComponentWithDatas.push(itemComponentWithData);
    });
    form.column2.forEach(itemComponentWithData => {
        itemComponentWithDatas.push(itemComponentWithData);
    });
    form.column3.forEach(itemComponentWithData => {
        itemComponentWithDatas.push(itemComponentWithData);
    });

    return itemComponentWithDatas;
}

export const findCompareItemComponentWithData = (itemComponentWithData: Dtos.ItemComponentWithData, itemComponentWithDatas: Dtos.ItemComponentWithData[], compareItemComponentPrimitiveId: number): Dtos.ItemComponentWithData | undefined => {
    // find the primitive on the same collection instance and item component
    const collectionInstanceId = itemComponentWithData.collectionInstanceData.collectionInstanceId;
    const itemComponentId = itemComponentWithData.collectionInstanceData.itemComponentId;

    let foundItemComponentWithData = itemComponentWithDatas.find(f => f.collectionInstanceData.collectionInstanceId == collectionInstanceId &&
        f.collectionInstanceData.itemComponentId == itemComponentId &&
        f.collectionInstanceData.itemComponentPrimitiveId == compareItemComponentPrimitiveId);

    return foundItemComponentWithData;
}

export const validateCollectionInstance = (itemComponentWithDatas: Dtos.ItemComponentWithData[], disabled: boolean): CollectionInstanceDataState[] => {
    let collectionInstanceDataStates: CollectionInstanceDataState[] = [];

    itemComponentWithDatas.forEach(itemComponentWithData => {
        let collectionInstanceDataState: CollectionInstanceDataState = {
            id: itemComponentWithData.collectionInstanceData.id,
            isAnswered: false,
            isDisabled: disabled || itemComponentWithData.formLocked, // readonly || savingCollectionInstance || locked;
            isRequired: false,
            isValid: true,
            validations: []
        };

        if (itemComponentWithData.itemComponentRules && itemComponentWithData.itemComponentRules.length > 0) {
            itemComponentWithData.itemComponentRules.forEach(rule => {
                if (rule.itemPrimitiveRule == Dtos.ItemPrimitiveRule.IsDisabledIf) {
                    const compareItemComponentWithData: Dtos.ItemComponentWithData = findCompareItemComponentWithData(itemComponentWithData, itemComponentWithDatas, rule.compareItemComponentPrimitiveId);
                    if (compareItemComponentWithData != undefined) {
                        if (compareItemComponentWithData.itemComponentPrimitive != undefined) {
                            switch (compareItemComponentWithData.itemComponentPrimitive.itemPrimitive) {
                                case Dtos.ItemPrimitive.Checkbox:
                                    if (compareItemComponentWithData.collectionInstanceData.bitValue == true && (rule.compareValue == "1" || rule.compareValue.toLowerCase() == "true")) {
                                        collectionInstanceDataState.isDisabled = true;
                                    }
                                    if (compareItemComponentWithData.collectionInstanceData.bitValue == false && (rule.compareValue == "" || rule.compareValue == "0" || rule.compareValue.toLowerCase() == "false")) {
                                        collectionInstanceDataState.isDisabled = true;
                                    }
                                    break;
                                case Dtos.ItemPrimitive.Text:
                                    if (compareItemComponentWithData.collectionInstanceData.textValue.toLowerCase() == rule.compareValue.toLowerCase()) {
                                        collectionInstanceDataState.isDisabled = true;
                                    }
                                    break;
                                case Dtos.ItemPrimitive.Comments:
                                    if (compareItemComponentWithData.collectionInstanceData.textValue.toLowerCase() == rule.compareValue.toLowerCase()) {
                                        collectionInstanceDataState.isDisabled = true;
                                    }
                                    break;
                                case Dtos.ItemPrimitive.Select:
                                    let option = compareItemComponentWithData.itemComponentPrimitive.itemComponentPrimitiveOptions.find(f => f.active && f.value != undefined && f.value != null && f.value.toLowerCase() == rule.compareValue.toLowerCase());
                                    if (option && option.id == compareItemComponentWithData.collectionInstanceData.intValue) {
                                        collectionInstanceDataState.isDisabled = true;
                                    }
                                    break;
                                case Dtos.ItemPrimitive.Numeric:
                                    if (compareItemComponentWithData.collectionInstanceData.numberValue.toString() == rule.compareValue) {
                                        collectionInstanceDataState.isDisabled = true;
                                    }
                                    break;
                            }
                        }
                    }
                }
                if (rule.itemPrimitiveRule == Dtos.ItemPrimitiveRule.IsEnabledIf) {
                    const compareItemComponentWithData: Dtos.ItemComponentWithData = findCompareItemComponentWithData(itemComponentWithData, itemComponentWithDatas, rule.compareItemComponentPrimitiveId);
                    if (compareItemComponentWithData != undefined) {
                        if (compareItemComponentWithData.itemComponentPrimitive != undefined) {
                            collectionInstanceDataState.isDisabled = true;
                            switch (compareItemComponentWithData.itemComponentPrimitive.itemPrimitive) {
                                case Dtos.ItemPrimitive.Checkbox:
                                    if (compareItemComponentWithData.collectionInstanceData.bitValue == true && (rule.compareValue == "1" || rule.compareValue.toLowerCase() == "true")) {
                                        collectionInstanceDataState.isDisabled = false;
                                    }
                                    if (compareItemComponentWithData.collectionInstanceData.bitValue == false && (rule.compareValue == "" || rule.compareValue == "0" || rule.compareValue.toLowerCase() == "false")) {
                                        collectionInstanceDataState.isDisabled = false;
                                    }
                                    break;
                                case Dtos.ItemPrimitive.Text:
                                    if (compareItemComponentWithData.collectionInstanceData.textValue.toLowerCase() == rule.compareValue.toLowerCase()) {
                                        collectionInstanceDataState.isDisabled = false;
                                    }
                                    break;
                                case Dtos.ItemPrimitive.Comments:
                                    if (compareItemComponentWithData.collectionInstanceData.textValue.toLowerCase() == rule.compareValue.toLowerCase()) {
                                        collectionInstanceDataState.isDisabled = false;
                                    }
                                    break;
                                case Dtos.ItemPrimitive.Select:
                                    let option = compareItemComponentWithData.itemComponentPrimitive.itemComponentPrimitiveOptions.find(f => f.value.toLowerCase() == rule.compareValue.toLowerCase());
                                    if (option && option.id == compareItemComponentWithData.collectionInstanceData.intValue) {
                                        collectionInstanceDataState.isDisabled = false;
                                    }
                                    break;
                                case Dtos.ItemPrimitive.Numeric:
                                    if (compareItemComponentWithData.collectionInstanceData.numberValue.toString() == rule.compareValue) {
                                        collectionInstanceDataState.isDisabled = false;
                                    }
                                    break;
                            }
                        }
                    }
                }
                if (rule.itemPrimitiveRule == Dtos.ItemPrimitiveRule.Required) {
                    collectionInstanceDataState.isRequired = true;
                }
                if (rule.itemPrimitiveRule == Dtos.ItemPrimitiveRule.RequiredIf) {
                    const compareItemComponentWithData: Dtos.ItemComponentWithData = findCompareItemComponentWithData(itemComponentWithData, itemComponentWithDatas, rule.compareItemComponentPrimitiveId);
                    if (compareItemComponentWithData != undefined) {
                        if (compareItemComponentWithData.itemComponentPrimitive != undefined) {
                            switch (compareItemComponentWithData.itemComponentPrimitive.itemPrimitive) {
                                case Dtos.ItemPrimitive.Checkbox:
                                    if (compareItemComponentWithData.collectionInstanceData.bitValue == true && (rule.compareValue == "1" || rule.compareValue.toLowerCase() == "true")) {
                                        collectionInstanceDataState.isRequired = true;
                                    }
                                    if (compareItemComponentWithData.collectionInstanceData.bitValue == false && (rule.compareValue == "" || rule.compareValue == "0" || rule.compareValue.toLowerCase() == "false")) {
                                        collectionInstanceDataState.isRequired = true;
                                    }
                                    break;
                                case Dtos.ItemPrimitive.Text:
                                    if (compareItemComponentWithData.collectionInstanceData.textValue.toLowerCase() == rule.compareValue.toLowerCase()) {
                                        collectionInstanceDataState.isRequired = true;
                                    }
                                    break;
                                case Dtos.ItemPrimitive.Comments:
                                    if (compareItemComponentWithData.collectionInstanceData.textValue.toLowerCase() == rule.compareValue.toLowerCase()) {
                                        collectionInstanceDataState.isRequired = true;
                                    }
                                    break;
                                case Dtos.ItemPrimitive.Select:
                                    let option = compareItemComponentWithData.itemComponentPrimitive.itemComponentPrimitiveOptions.find(f => f.value.toLowerCase() == rule.compareValue.toLowerCase());
                                    if (option && option.id == compareItemComponentWithData.collectionInstanceData.intValue) {
                                        collectionInstanceDataState.isRequired = true;
                                    }
                                    break;
                                case Dtos.ItemPrimitive.Numeric:
                                    if (compareItemComponentWithData.collectionInstanceData.numberValue.toString() == rule.compareValue) {
                                        collectionInstanceDataState.isRequired = true;
                                    }
                                    break;
                            }
                        }
                    }
                }
            });
            if (collectionInstanceDataState.isRequired && collectionInstanceDataState.isDisabled) {
                collectionInstanceDataState.isRequired = false;
            }
            if (collectionInstanceDataState.isRequired) {
                // check for a value being entered
                if (itemComponentWithData.isQuestion && itemComponentWithData.itemComponentPrimitive != undefined && itemComponentWithData.collectionInstanceData != undefined) {
                    switch (itemComponentWithData.itemComponentPrimitive.itemPrimitive) {
                        case Dtos.ItemPrimitive.Select:
                            if (itemComponentWithData.collectionInstanceData.intValue != undefined && itemComponentWithData.collectionInstanceData.intValue != null && itemComponentWithData.collectionInstanceData.intValue > 0) {
                                collectionInstanceDataState.isAnswered = true;
                            }
                            break;
                        case Dtos.ItemPrimitive.Comments:
                            if (itemComponentWithData.collectionInstanceData.textValue != undefined && itemComponentWithData.collectionInstanceData.textValue != null && itemComponentWithData.collectionInstanceData.textValue.length > 0) {
                                collectionInstanceDataState.isAnswered = true;
                            }
                            break;
                        case Dtos.ItemPrimitive.Date:
                            if (itemComponentWithData.collectionInstanceData.dateValue != undefined && itemComponentWithData.collectionInstanceData.dateValue != null) {
                                collectionInstanceDataState.isAnswered = true;
                            }
                            break;
                        case Dtos.ItemPrimitive.Text:
                            if (itemComponentWithData.collectionInstanceData.textValue != undefined && itemComponentWithData.collectionInstanceData.textValue != null && itemComponentWithData.collectionInstanceData.textValue.length > 0) {
                                collectionInstanceDataState.isAnswered = true;
                            }
                            break;
                        case Dtos.ItemPrimitive.Numeric:
                            if ((itemComponentWithData.collectionInstanceData.intValue != undefined && itemComponentWithData.collectionInstanceData.intValue != null && itemComponentWithData.collectionInstanceData.intValue !== 0) ||
                                (itemComponentWithData.collectionInstanceData.numberValue != undefined && itemComponentWithData.collectionInstanceData.numberValue != null && itemComponentWithData.collectionInstanceData.numberValue !== 0)) {
                                collectionInstanceDataState.isAnswered = true;
                            }
                            break;
                    }
                }

                if (itemComponentWithData.isQuestion && !collectionInstanceDataState.isAnswered) {
                    const fieldName = "CollectionInstanceDatas[" + itemComponentWithData.collectionInstanceData.id.toString() + "].TextValue";
                    if (collectionInstanceDataState.validations.filter(f => f.fieldName == fieldName).length === 0) {
                        let validationFailure: Dtos.ResponseError = new Dtos.ResponseError();
                        validationFailure.errorCode = "CID-001";
                        validationFailure.fieldName = "CollectionInstanceDatas[" + itemComponentWithData.collectionInstanceData.id.toString() + "].TextValue";
                        validationFailure.message = "This field is required";
                        collectionInstanceDataState.validations.push(validationFailure);
                    }
                }
            }
        }

        collectionInstanceDataStates.push(collectionInstanceDataState);
    });

    return collectionInstanceDataStates;
}

export const collectionInstanceIsValid = (collectionInstanceDataState: CollectionInstanceDataState[]): boolean => {
    let isValid: boolean = true;
    if (collectionInstanceDataState) {
        isValid = collectionInstanceDataState.filter(f => f.validations.length > 0).length == 0;
    }
    return isValid;
}


const mapStateToProps = (state: ITmdState, ownProps): ICollectionInstancePageProps => {

    let collectionInstance: Dtos.CollectionInstance | undefined = !(state.collectionInstance.formData instanceof Array) ? state.collectionInstance.formData : undefined;
    let collection: Dtos.Collection | undefined = collectionInstance && collectionInstance.collection ? collectionInstance.collection : undefined;

    const itemComponents: Dtos.ItemComponent[] | undefined = state.itemComponent.formData instanceof Array ? allItemComponents(state.itemComponent.formData) : undefined;

    return {

        // match: ownProps.match,
        readonly: ownProps.readonly,
        collectionInstanceId: ownProps.collectionInstanceId ? ownProps.collectionInstanceId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        collectionInstance: collectionInstance!,
        collection: collection!,

        loadingCollectionInstance: state.collectionInstance.loadState && state.collectionInstance.loadState.status == RequestState.Pending,
        loadCollectionInstanceSuccess: state.collectionInstance.loadState && state.collectionInstance.loadState.status == RequestState.Success,
        loadCollectionInstanceFailure: state.collectionInstance.loadState && state.collectionInstance.loadState.status == RequestState.Failure,

        savingCollectionInstance: state.collectionInstance.saveState && state.collectionInstance.saveState.status == RequestState.Pending,
        saveCollectionInstanceSuccess: state.collectionInstance.saveState && state.collectionInstance.saveState.status == RequestState.Success,
        saveCollectionInstanceFailure: state.collectionInstance.saveState && state.collectionInstance.saveState.status == RequestState.Failure,

        user: state.user.data,
        lookups: state.collectionInstance.lookups,

        form: state.reduxForms.collectionInstance,
        formState: state.collectionInstance.formState,
        formProperties: state.collectionInstance.formProperties,

        reduxFormState: state.reduxForms.formState.collectionInstance,
        validationFailures: state.collectionInstance.validationFailures,

        itemComponents: itemComponents,
        activeItemComponents: filterItemComponentsByActive(itemComponents!, true),
        loadingItemComponents: state.itemComponent.loadState && state.itemComponent.loadState.status == RequestState.Pending,

        column1: filterCollectionItemComponentsByColumn(collection, 1),
        column2: filterCollectionItemComponentsByColumn(collection, 2),
        column3: filterCollectionItemComponentsByColumn(collection, 3),
        documents: filterCollectionItemComponentsByDocuments(collection)
    };
};

const mapDispatchToProps = (dispatch): ICollectionInstancePageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadCollectionInstance: bindActionCreators(CollectionInstanceActions.LoadCollectionInstanceById, dispatch),
        clearCollectionInstance: bindActionCreators(CollectionInstanceActions.Clear, dispatch),
        saveCollectionInstance: bindActionCreators(CollectionInstanceActions.SaveCollectionInstance, dispatch),
        expandSection: bindActionCreators(CollectionInstanceActions.ExpandSection, dispatch),

        loadItemComponents: bindActionCreators(ItemComponentActions.LoadItemComponent, dispatch),
        clearItemComponents: bindActionCreators(ItemComponentActions.Clear, dispatch),
    };
};

const CollectionInstancePageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CollectionInstancePage)

export default CollectionInstancePageContainer;

