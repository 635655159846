import * as React from 'react';
import './TrialEmailReminderSettings.scss';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Alert from 'react-s-alert';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import { ITrialReduxFormState, initialTrialState } from "../../../reducers/reactReduxForms/trial";
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu, SubPageLayout } from "../../../components/layouts/index";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as TrialActions from '../../../actions/trial';
import * as TrialsPageActions from '../../../actions/pages/trialsPage';
import * as AuthenticationHelper from '../../../helpers/authentication';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { Modal, NotificationAlert } from "../../../components/common/index";
import { Form, actions, Control } from 'react-redux-form';
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";

interface ITrialEmailReminderSettingsProps {
    trialId?: number;

    form?: Dtos.Trial & IRegistrationFormState;
    formState?: Dtos.FormState;
    formProperties?: Dtos.FormProperty[];
    reduxFormState?: ITrialReduxFormState;

    trial?: Dtos.Trial;
    lookups?: Dtos.Lookup[];

    loadingTrial?: boolean;
    loadTrialSuccess?: boolean;
    loadTrialFailure?: boolean;

    savingTrial?: boolean;
    saveTrialSuccess?: boolean;
    saveTrialFailure?: boolean;


    validationFailures?: Dtos.ResponseError[];
    permissions?: Dtos.PersonnelTmdPermissions[];

    onClose?: Function;
    user?: Dtos.User;
}

interface ITrialEmailReminderSettingsActions {
    setModalState?: TrialsPageActions.ITrialFormsPageSetReminderEmailSettingsModalViewStateActionCreator;
    saveTrial?: TrialActions.ITrialSaveActionCreator;
    
    changeForm?: typeof actions.change;
}

type TrialEmailReminderSettingsProps = ITrialEmailReminderSettingsProps & ITrialEmailReminderSettingsActions;

const reduxFormName: string = "reduxForms.trial";

class TrialEmailReminderSettings extends React.PureComponent<TrialEmailReminderSettingsProps, any> {

    constructor(props: TrialEmailReminderSettingsProps) {
        super(props);

        this.onCancel = this.onCancel.bind(this);
    }

    componentDidUpdate(prevProps: TrialEmailReminderSettingsProps, prevState) {
        const {
            savingTrial,
            saveTrialSuccess,
            validationFailures,
        } = this.props;

        if (!savingTrial && prevProps.savingTrial) {
            if (saveTrialSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);
                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);
                }
                this.onCancel();
            }
            else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }
    }


    showTrialForm(): boolean {
        return true;
    }

    render() {
        return <SubPageLayout loading={false}>
            {this.renderContent()}
        </SubPageLayout>;
    }

    renderContent() {
        const {
            form,
            trialId,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            permissions,
            savingTrial
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermissionFor(trialId, undefined, permissions, Dtos.Permission.TrialUpdate, Dtos.Permission.TrialCreate);
        const formDisabled: boolean = !hasPermission || savingTrial || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            reminderEmailGroup: {
                type: RegistrationComponent.RegistrationGroupFormControls,
                components: {
                    sendReminderEmail: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: CheckboxFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            label: "Send Email Reminders"
                        },
                        inputLabel: " ",
                        size: 1
                    },
                    sendReminderAfterDays: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: NumberFormInput,
                        inputDisabled: formDisabled,
                        size: 1
                    },
                    sendReminderStandardEmailTemplateId: {
                        type: RegistrationComponent.RegistrationControl,
                        inputType: SelectFormInput,
                        inputDisabled: formDisabled,
                        inputProps: {
                            options: generateOptionsFromLookup(findLookup("Trial", "SendReminderStandardEmailTemplateId", lookups), form.sendReminderStandardEmailTemplateId)
                        },
                        size: 1,
                        changeAction: (model, value, form, parentModel) => {
                            changeForm(model, value);
                            changeForm(reduxFormName + ".sendReminderStandardEmailTemplate", null);
                        },
                    },
                }
            },
        };

        return <div>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "trial", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2">Cancel</button>
                </div>
            </Form>
        </div>
    }

    renderErrorAlert(): any {
        return <div>
            The form was <strong> not </strong> saved.
        </div>
    }

    renderWarningAlert(): any {
        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>
    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.TrialForm) {
        const {
            saveTrial
        } = this.props;

        if (data) {
            let changes: Dtos.Trial = {} as Dtos.Trial;
            saveTrial(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.TrialForm) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        if (this.props.onClose) { this.props.onClose() }
    }
}

const mapStateToProps = (state: ITmdState, ownProps: TrialEmailReminderSettingsProps): ITrialEmailReminderSettingsProps => {

    let trial: Dtos.Trial | undefined = !(state.trials.formData instanceof Array) ? state.trials.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {
        trialId: state.trialsPage.trialId,

        trial: trial!,

        loadingTrial: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,
        loadTrialSuccess: state.trials.loadState && state.trials.loadState.status == RequestState.Success,
        loadTrialFailure: state.trials.loadState && state.trials.loadState.status == RequestState.Failure,

        savingTrial: state.trials.saveState && state.trials.saveState.status == RequestState.Pending,
        saveTrialSuccess: state.trials.saveState && state.trials.saveState.status == RequestState.Success,
        saveTrialFailure: state.trials.saveState && state.trials.saveState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: state.trials.lookups,

        form: state.reduxForms.trial,
        formState: state.trials.formState,
        formProperties: state.trials.formProperties,

        reduxFormState: state.reduxForms.formState.trial,
        validationFailures: trial ?
            state.trials.validationFailures :
            undefined,

    };
};

const mapDispatchToProps = (dispatch): ITrialEmailReminderSettingsActions => {
    return {
        setModalState: bindActionCreators(TrialsPageActions.setReminderEmailSettingsModalViewState, dispatch),
        saveTrial: bindActionCreators(TrialActions.SaveTrial, dispatch),

        changeForm: bindActionCreators(actions.change, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialEmailReminderSettings);
