import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialTrialGroupItemState: Dtos.TrialGroupItem & IRegistrationFormState = {
    id: null,
    trialGroupId: null,
    trialGroup: null,
    groupItemId: null,
    groupItem: null,
    version: null,
    collectionInstanceId: null,
    collectionInstance: null,
    active: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false
}

export interface ITrialGroupItemReduxFormState {
    id: IFieldState<number>,
    trialGroupId: IFieldState<number>,
    trialGroup: IFieldState<Dtos.TrialGroup>,
    groupItemId: IFieldState<number>,
    groupItem: IFieldState<Dtos.GroupItem>,
    version: IFieldState<string>,
    collectionInstanceId: IFieldState<number>,
    collectionInstance: IFieldState<Dtos.CollectionInstance>,
    active: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>,
}
