import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const trialFormsApi = {
    getTrialForms: (trialId: number, institutionId: number): Observable<Dtos.GetTrialFormsResponse> => {
        //Create the request.
        let request: Dtos.GetTrialForms = new Dtos.GetTrialForms();
        request.trialId = trialId;
        request.institutionId = institutionId;

        //Send request.
        const response: Promise<Dtos.GetTrialFormsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrialFormsCurrentUser: (): Observable<Dtos.GetTrialFormsByCurrentUserResponse> => {
        //Create the request.
        let request: Dtos.GetTrialFormsByCurrentUser = new Dtos.GetTrialFormsByCurrentUser();

        //Send request.
        const response: Promise<Dtos.GetTrialFormsByCurrentUserResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrialFormById: (id: number): Observable<Dtos.GetTrialFormByIdResponse> => {
        //Create the request.
        let request: Dtos.GetTrialFormById = new Dtos.GetTrialFormById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetTrialFormByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrialForm: (trialForm: Dtos.TrialForm): Observable<Dtos.SetTrialFormResponse> => {

        let request: Dtos.SetTrialForm = new Dtos.SetTrialForm();

        request.trialForm = trialForm;

        // send request
        const response: Promise<Dtos.SetTrialFormResponse> = client.post(request);

        return Observable.from(response);

    },
    createTrialForm: (trialId: number, institutionId: number, formTemplateId: number): Observable<Dtos.CreateTrialFormResponse> => {
        //Create the request.
        let request: Dtos.CreateTrialForm = new Dtos.CreateTrialForm();

        request.trialId = trialId;
        request.institutionId = institutionId;
        request.formTemplateId = formTemplateId;

        //Send request.
        const response: Promise<Dtos.CreateTrialFormResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    createVersion: (trialFormId: number, version: string, copyData: boolean): Observable<Dtos.CreateTrialFormVersionResponse> => {
        //Create the request.
        let request: Dtos.CreateTrialFormVersion = new Dtos.CreateTrialFormVersion();

        request.trialFormId = trialFormId;
        request.version = version;
        request.copyData = copyData;

        //Send request.
        const response: Promise<Dtos.CreateTrialFormVersionResponse> = client.post(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrialFormReport: (id: number, version: string): Observable<Dtos.GetTrialFormReportResponse> => {
        //Create the request.
        let request: Dtos.GetTrialFormReport = new Dtos.GetTrialFormReport();

        request.id = id;
        request.version = version;

        //Send request.
        const response: Promise<Dtos.GetTrialFormReportResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setTrialFormReportJustified: (id: number, measurementReport: Dtos.MeasurementReport): Observable<Dtos.SetTrialFormDataJustifiedResponse> => {
        //Create the request.
        let request: Dtos.SetTrialFormDataJustified = new Dtos.SetTrialFormDataJustified();

        request.id = id;
        request.measurementReport = measurementReport;

        //Send request.
        const response: Promise<Dtos.SetTrialFormDataJustifiedResponse> = client.post(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    sendReminderEmail: (id: number): Observable<Dtos.SendReminderEmailResponse> => {
        //Create the request.
        let request: Dtos.SendReminderEmail = new Dtos.SendReminderEmail();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.SendReminderEmailResponse> = client.post(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}