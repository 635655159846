import * as React from 'react';
import './TrialInstitutionItemTemplatesPage.scss';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import { TruncatedCell } from "../../../components/layouts/index";
import * as TrialInstitutionItemTemplateActions from '../../../actions/trialInstitutionItemTemplate';
//import * as TrialInstitutionSettingsPageActions from '../../../actions/pages/trialInstitutionSettingsPage';
import * as ToggleActions from '../../../actions/pages/togglePage';
import * as PagingActions from '../../../actions/pages/pagingPage';
import * as ToggleHelper from "../../../helpers/toggleHelper";
import { TogglePageItem } from '../../../reducers/pages/togglePage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { FormFilterInput } from '../../../components/form';
import { ItemSettingsLayout } from '../../index'

interface ITrialInstitutionItemTemplatesPageProps {

    location: Location;
    history: History;

    trialId: number;
    trialInstitutionItemTemplates: Dtos.TrialInstitutionItemTemplate[];
    trialInstitutionItemTemplatesByCategory: Dtos.Category[];
    formFilter: IFormFilter;
    lookups: Dtos.Lookup[];

    loadingTrialInstitutionItemTemplates: boolean;
    loadTrialInstitutionItemTemplatesSuccess: boolean;
    loadTrialInstitutionItemTemplatesFailure: boolean;

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];
    toggleItems: TogglePageItem[];

    pageSize: number;
}

interface ITrialInstitutionItemTemplatesPageActions {
    clearTrialInstitutionItemTemplates: TrialInstitutionItemTemplateActions.ITrialInstitutionItemTemplateClearActionCreator;
    loadTrialInstitutionItemTemplates: TrialInstitutionItemTemplateActions.ITrialInstitutionItemTemplateLoadActionCreator;

    //setTrialInstitution: TrialInstitutionSettingsPageActions.ITrialInstitutionSettingsPageSetTrialInstitutionActionCreator;
    //setTrial: TrialInstitutionSettingsPageActions.ITrialInstitutionSettingsPageSetTrialActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    filter: TrialInstitutionItemTemplateActions.ITrialInstitutionItemTemplateFilterActionCreator;

    toggleExpand: ToggleActions.IToggleExpandedStateActionCreator;

    setPageSize: PagingActions.IPagingPageSizeChangedStateActionCreator;
}

type TrialInstitutionItemTemplatesPageProps = ITrialInstitutionItemTemplatesPageProps & ITrialInstitutionItemTemplatesPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}

class TrialInstitutionItemTemplatesPage extends React.PureComponent<TrialInstitutionItemTemplatesPageProps, any> {

    constructor(props: TrialInstitutionItemTemplatesPageProps) {
        super(props);

        this.clear = this.clear.bind(this);
    }

    componentDidMount() {
        const {
            loadTrialInstitutionItemTemplates,
            trialId
        } = this.props;

        loadTrialInstitutionItemTemplates(trialId);
    }

    componentDidUpdate(prevProps: TrialInstitutionItemTemplatesPageProps, prevState) {
        const {
            trialId,
            loadTrialInstitutionItemTemplates
        } = this.props;

        if (prevProps.trialId != trialId) {
            loadTrialInstitutionItemTemplates(trialId);
        }
    }

    componentWillUnmount() {
        this.clear();
    }

    clear() {
        this.props.clearTrialInstitutionItemTemplates();
    }

    show(): boolean {

        const {
            loadingTrialInstitutionItemTemplates,
            loadTrialInstitutionItemTemplatesSuccess
        } = this.props

        if (!loadingTrialInstitutionItemTemplates && loadTrialInstitutionItemTemplatesSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrialInstitutionItemTemplates,
            permissions
        } = this.props;

        return <ItemSettingsLayout
            permissions={permissions}
            permission={Dtos.Permission.TrialInstitutionItemTemplatesView}
            loading={loadingTrialInstitutionItemTemplates}
        >
            {this.renderContent()}
        </ItemSettingsLayout>;
    }

    renderContent() {
        const {
            formFilter,
            permissions,
            lookups,
            trialId,
            //setTrial
        } = this.props;

        var trialOptions = generateOptionsFromLookup(findLookup("TrialInstitutionItemTemplate", "TrialId", lookups), trialId);
        var setTrial = (trialId) => { } // placeholder

        return <div>
            <div className="row">
                <div className={"col-auto mb-2"}>
                    <h2>Trial Institution Item Templates</h2>
                </div>
                <div className="col" style={{ textAlign: 'right' }}>
                    {(false && trialOptions && trialOptions.length > 0) && (
                        <div className="mr-2 d-inline">
                            <select className="form-control py-1 px-2 m-0 " style={{ maxWidth: '250px', display: 'inline-table' }} value={trialId} onChange={e => setTrial(parseInt(e.target.value))}>
                                <option value={0}>Apply to all trials</option>
                                {trialOptions.map((trialOption, indexSection) => (
                                    <option key={"trialInstitution-selection-" + trialOption.value} value={trialOption.value}>{trialOption.label}</option>
                                ))}
                            </select>
                        </div>
                    )}
                    <FormFilterInput formFilter={formFilter} onFilter={e => this.props.filter(e)} />
                    {AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialInstitutionItemTemplatesCreate) && (
                        <Link className={"btn btn-primary"} url={"/settings/item/itemtemplates/create"}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.show() ?
                    "Loading Item Templates" :
                    null
            }
            {
                this.show() ?
                    <div className="mt-2">
                        {this.createTableByCategory()}
                    </div> :
                    null
            }
        </div>
    }

    createTableByCategory() {

        const {
            trialInstitutionItemTemplatesByCategory,
            toggleItems,
            toggleExpand,
            pageSize,
            setPageSize
        } = this.props

        if (!trialInstitutionItemTemplatesByCategory || trialInstitutionItemTemplatesByCategory.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no template items to view.</span>
            </div>
        }

        const showPagination = false;

        return <div>
            {trialInstitutionItemTemplatesByCategory.map((item, index) => {
                const key = "trial-institution-item-instances-page-" + item.id;
                const expanded = ToggleHelper.isToggleExpanded(toggleItems, key);
                return (
                    <div key={"trial-institution-item-instances-category" + item.id}>
                        <div className={tableHeaderClass} style={tableStyleClass} onClick={() => toggleExpand(key)}>
                            <div>
                                <FontAwesomeIcon icon={!expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" />
                            </div>
                            <span>{item.value}</span>
                        </div>
                        {(expanded && item.trialInstitutionItemTemplates) && (
                            <ReactTable
                                data={item.trialInstitutionItemTemplates}
                                pageSizeOptions={PAGE_SIZE_OPTIONS}
                                defaultPageSize={item.trialInstitutionItemTemplates.length}
                                pageSize={showPagination ? undefined : item.trialInstitutionItemTemplates.length}
                                showPagination={showPagination}
                                className="-striped -highlight -clickable mb-3"
                                noDataText="There are currently no template trial items for this category"
                                resizable={false}
                                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                                    return {
                                        onClick: (e, handleOriginal) => {

                                            const url: string = "/settings/item/itemtemplates/" + rowInfo.original.id;
                                            this.props.navigate(url);

                                            if (handleOriginal) {
                                                handleOriginal()
                                            }

                                        },
                                        style: {
                                            color: (rowInfo && rowInfo.original && rowInfo.original.active) ? '#212529' : '#9e9e9e'
                                        }
                                    }
                                }}
                                columns={
                                    [
                                        {
                                            id: "id",
                                            Header: ' ',
                                            accessor: "id",
                                            className: "d-flex align-items-center justify-content-center",
                                            maxWidth: 50,
                                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.LOOKUP} fixedWidth />
                                        },
                                        {
                                            id: "name",
                                            Header: 'Name',
                                            accessor: 'name',
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            Cell: (props) => <TruncatedCell value={props.value} />
                                        },
                                        {
                                            id: "description",
                                            Header: "Description",
                                            accessor: "description",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left",
                                            Cell: (props) => <TruncatedCell value={props.value} />
                                        }
                                    ]
                                }
                            />
                        )}
                    </div>
                )
            }
            )}
        </div>

    }


    createTable() {

        const {
            trialInstitutionItemTemplates,
            pageSize,
            setPageSize
        } = this.props

        if (!trialInstitutionItemTemplates || trialInstitutionItemTemplates.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no item templates to view.</span>
            </div>
        }

        const showPagination = trialInstitutionItemTemplates.length > pageSize;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={trialInstitutionItemTemplates}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? pageSize : trialInstitutionItemTemplates.length}
                pageSize={showPagination ? pageSize : trialInstitutionItemTemplates.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no item templates to display"
                resizable={false}
                onPageSizeChange={val => setPageSize(val)}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {

                            const url: string = "/settings/item/itemtemplates/" + rowInfo.original.id;
                            this.props.navigate(url);

                            if (handleOriginal) {
                                handleOriginal()
                            }

                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.LOOKUP} fixedWidth />
                    },
                    {
                        id: "name",
                        Header: 'Name',
                        accessor: 'name',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    },
                    {
                        id: "category",
                        Header: 'Category',
                        accessor: 'category.value',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    },
                    {
                        id: "initialVersion",
                        Header: 'Version',
                        accessor: 'initialVersion',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        width: 200
                    }
                ]}
            />
        </div>

    }

}

const mapStateToProps = (state: ITmdState, ownProps: TrialInstitutionItemTemplatesPageProps): ITrialInstitutionItemTemplatesPageProps => {

    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {
        location: state.routing.location,
        history: ownProps.history,

        trialId: undefined,//state.trialInstitutionSettingsPage.trialId!,
        trialInstitutionItemTemplates: state.trialInstitutionItemTemplate.formFilteredData!,
        trialInstitutionItemTemplatesByCategory: state.trialInstitutionItemTemplate.formFilteredDataGrouped,
        formFilter: state.trialInstitutionItemTemplate.formFilter,
        lookups: state.trialInstitutionItemTemplate.lookups,

        loadingTrialInstitutionItemTemplates: state.trialInstitutionItemTemplate.loadState && state.trialInstitutionItemTemplate.loadState.status == RequestState.Pending,
        loadTrialInstitutionItemTemplatesSuccess: state.trialInstitutionItemTemplate.loadState && state.trialInstitutionItemTemplate.loadState.status == RequestState.Success,
        loadTrialInstitutionItemTemplatesFailure: state.trialInstitutionItemTemplate.loadState && state.trialInstitutionItemTemplate.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        toggleItems: state.togglePage.items,

        pageSize: state.pagingPage.pageSize,
    };
};

const mapDispatchToProps = (dispatch): ITrialInstitutionItemTemplatesPageActions => {
    return {
        clearTrialInstitutionItemTemplates: bindActionCreators(TrialInstitutionItemTemplateActions.Clear, dispatch),
        loadTrialInstitutionItemTemplates: bindActionCreators(TrialInstitutionItemTemplateActions.LoadTrialInstitutionItemTemplate, dispatch),

        //setTrial: bindActionCreators(TrialInstitutionSettingsPageActions.SetTrial, dispatch),

        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        toggleExpand: bindActionCreators(ToggleActions.ToggleExpanded, dispatch),

        setPageSize: bindActionCreators(PagingActions.PageSizeChanged, dispatch),

        filter: bindActionCreators(TrialInstitutionItemTemplateActions.Filter, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialInstitutionItemTemplatesPage);
