import * as React from 'react';
import './TrialGroupsPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import * as TrialActions from '../../../actions/trial';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { TrialLayout } from '../../index';

interface ITrialGroupsPageParams {
    trialId: number;
}

interface ITrialGroupsPageProps {

    location: Location;
    trialId: number;
    match: match<ITrialGroupsPageParams>;
    history: History;

    // From state
    trial: Dtos.Trial;
    trialRoles: Dtos.RoleType[];
    trialGroups: Dtos.TrialGroup[],
    loadingTrial: boolean;
    loadTrialSuccess: boolean;
    loadTrialFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface ITrialGroupsPageActions {
    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type TrialGroupsPageProps = ITrialGroupsPageProps & ITrialGroupsPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class TrialGroupsPage extends React.PureComponent<TrialGroupsPageProps, any> {

    constructor(props: TrialGroupsPageProps) {
        super(props);

        this.clearTrial = this.clearTrial.bind(this);
    }

    componentDidMount() {
        const {
            loadTrial,
            navigate,
            navigateReplace,
            trialId
        } = this.props;

        loadTrial(trialId);
    }

    componentWillReceiveProps(nextProps: TrialGroupsPageProps) {
    }

    componentDidUpdate(prevProps: TrialGroupsPageProps, prevState) {
        const {
            navigateReplace,
            loadTrial
        } = this.props
    }

    componentWillUpdate(nextProps: TrialGroupsPageProps) {
    }

    componentWillUnmount() {
        this.clearTrial();
    }

    clearTrial() {
        this.props.clearTrial();
    }

    showTrial(): boolean {
        const {
            loadingTrial,
            loadTrialSuccess
        } = this.props

        if (!loadingTrial && loadTrialSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            trial,
            loadingTrial,
            loadTrialSuccess,
            loadTrialFailure,
            clearTrial,
            navigate,
            permissions,
            trialRoles
        } = this.props;

        return (
            <TrialLayout loading={loadingTrial} permission={Dtos.Permission.TrialGroupView }>{this.renderContent()}</TrialLayout>
        );
    }

    renderContent() {
        const {
            trial,
            loadingTrial,
            loadTrialSuccess,
            loadTrialFailure,
            clearTrial,
            navigate,
            permissions,
            trialRoles
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-9 mb-2"}>
                    <h2>Trial Groups</h2>
                </div>
                <div className="col-3" style={{ textAlign: 'right' }}>
                    {(trial && trial.id > 0 && AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialGroupCreate, trial.id)) && (
                        <Link className={"btn btn-primary"} url={"/trials/" + trial.id + '/groups/create'}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.showTrial() ?
                    "Loading Trial Groups" :
                    null
            }
            {
                this.showTrial() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {

        const {
            trial,
            trialGroups
        } = this.props

        if (!trialGroups || trialGroups.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no trial groups</span>
            </div>
        }

        const showPagination = trialGroups.length > DEFAULT_PAGE_SIZE;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={trialGroups}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : trialGroups.length}
                pageSize={showPagination ? undefined : trialGroups.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no trial groups to display"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {

                            const url: string = "/trials/" + trial.id + "/groups/" + rowInfo.original.id;
                            this.props.navigate(url);
                            if (handleOriginal) {
                                handleOriginal()
                            }

                        }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.GROUP} fixedWidth />
                    },
                    {
                        id: "name",
                        Header: 'Name',
                        accessor: 'group.name',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    }
                ]}
            />
        </div>

    }

}


const mapStateToProps = (state: ITmdState, ownProps: TrialGroupsPageProps): ITrialGroupsPageProps => {

    let trial: Dtos.Trial | undefined = !(state.trials.formData instanceof Array) ? state.trials.formData : undefined;
    let trialGroups: Dtos.TrialGroup[] | undefined = trial ? trial.trialGroups.filter(f => f.active) : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trial: trial!,
        trialRoles: state.trials.formRoles,
        trialGroups: trialGroups!,

        loadingTrial: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,
        loadTrialSuccess: state.trials.loadState && state.trials.loadState.status == RequestState.Success,
        loadTrialFailure: state.trials.loadState && state.trials.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
    };
};

const mapDispatchToProps = (dispatch): ITrialGroupsPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialGroupsPage);
