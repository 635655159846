import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadTrackerMilestone: ITrackerMilestoneLoadActionCreator = () => {
        return {
            type: ACTION_TRACKERMILESTONE_LOAD
        }
    },
    LoadTrackerMilestoneById: ITrackerMilestoneLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TRACKERMILESTONE_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ITrackerMilestoneLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_TRACKERMILESTONE_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrackerMilestoneLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRACKERMILESTONE_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTrackerMilestone: ITrackerMilestoneSaveActionCreator = (formData) => {
        return {
            type: ACTION_TRACKERMILESTONE_SAVE,
            formData
        }
    },
    SaveSuccess: ITrackerMilestoneSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRACKERMILESTONE_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrackerMilestoneSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRACKERMILESTONE_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTrackerMilestone: ITrackerMilestoneCreateActionCreator = () => {

        return {
            type: ACTION_TRACKERMILESTONE_CREATE
        }
    },
    CreateSuccess: ITrackerMilestoneCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_TRACKERMILESTONE_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: ITrackerMilestoneCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRACKERMILESTONE_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: ITrackerMilestoneClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRACKERMILESTONE_CLEAR,
            propertiesToClear
        }
    },
    Filter: ITrackerMilestoneFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRACKERMILESTONE_FILTER,
            formFilter
        }
    };



export const
    ACTION_TRACKERMILESTONE_LOAD = "ACTION_TRACKERMILESTONE_LOAD",
    ACTION_TRACKERMILESTONE_LOAD_BY_ID = "ACTION_TRACKERMILESTONE_LOAD_BY_ID",
    ACTION_TRACKERMILESTONE_LOAD_SUCCESS = "ACTION_TRACKERMILESTONE_LOAD_SUCCESS",
    ACTION_TRACKERMILESTONE_LOAD_FAILURE = "ACTION_TRACKERMILESTONE_LOAD_FAILURE",

    ACTION_TRACKERMILESTONE_SAVE = "ACTION_TRACKERMILESTONE_SAVE",
    ACTION_TRACKERMILESTONE_SAVE_SUCCESS = "ACTION_TRACKERMILESTONE_SAVE_SUCCESS",
    ACTION_TRACKERMILESTONE_SAVE_FAILURE = "ACTION_TRACKERMILESTONE_SAVE_FAILURE",

    ACTION_TRACKERMILESTONE_CREATE = "ACTION_TRACKERMILESTONE_CREATE",
    ACTION_TRACKERMILESTONE_CREATE_SUCCESS = "ACTION_TRACKERMILESTONE_CREATE_SUCCESS",
    ACTION_TRACKERMILESTONE_CREATE_FAILURE = "ACTION_TRACKERMILESTONE_CREATE_FAILURE",

    ACTION_TRACKERMILESTONE_CLEAR = "ACTION_TRACKERMILESTONE_CLEAR",

    ACTION_TRACKERMILESTONE_FILTER = "ACTION_TRACKERMILESTONE_FILTER";

export interface ITrackerMilestoneLoadAction {
    type: "ACTION_TRACKERMILESTONE_LOAD";
}

export interface ITrackerMilestoneLoadActionCreator {
    (): ITrackerMilestoneLoadAction;
}

export interface ITrackerMilestoneLoadSuccessAction {
    type: "ACTION_TRACKERMILESTONE_LOAD_SUCCESS";
    formData: Dtos.TrackerMilestone | Dtos.TrackerMilestone[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrackerMilestoneLoadSuccessActionCreator {
    (
        formData: Dtos.TrackerMilestone | Dtos.TrackerMilestone[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrackerMilestoneLoadSuccessAction;
}

export interface ITrackerMilestoneLoadFailureAction {
    type: "ACTION_TRACKERMILESTONE_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrackerMilestoneLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrackerMilestoneLoadFailureAction;
}

export interface ITrackerMilestoneLoadByIdAction {
    type: "ACTION_TRACKERMILESTONE_LOAD_BY_ID";
    id: number;
}

export interface ITrackerMilestoneLoadByIdActionCreator {
    (id: number): ITrackerMilestoneLoadByIdAction;
}


export interface ITrackerMilestoneSaveAction {
    type: "ACTION_TRACKERMILESTONE_SAVE";
    formData: Dtos.TrackerMilestone;

}

export interface ITrackerMilestoneSaveActionCreator {
    (
        formData: Dtos.TrackerMilestone
    ): ITrackerMilestoneSaveAction;
}

export interface ITrackerMilestoneSaveSuccessAction {
    type: "ACTION_TRACKERMILESTONE_SAVE_SUCCESS";
    formData: Dtos.TrackerMilestone;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrackerMilestoneSaveSuccessActionCreator {
    (
        formData: Dtos.TrackerMilestone,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrackerMilestoneSaveSuccessAction;
}

export interface ITrackerMilestoneSaveFailureAction {
    type: "ACTION_TRACKERMILESTONE_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrackerMilestoneSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrackerMilestoneSaveFailureAction;
}

export interface ITrackerMilestoneCreateAction {
    type: "ACTION_TRACKERMILESTONE_CREATE"
}

export interface ITrackerMilestoneCreateActionCreator {
    (): ITrackerMilestoneCreateAction
}

export interface ITrackerMilestoneCreateSuccessAction {
    type: "ACTION_TRACKERMILESTONE_CREATE_SUCCESS";
    formData: Dtos.TrackerMilestone;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface ITrackerMilestoneCreateSuccessActionCreator {
    (
        formData: Dtos.TrackerMilestone,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): ITrackerMilestoneCreateSuccessAction;
}

export interface ITrackerMilestoneCreateFailureAction {
    type: "ACTION_TRACKERMILESTONE_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrackerMilestoneCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrackerMilestoneCreateFailureAction;
}

export interface ITrackerMilestoneClearAction {
    type: "ACTION_TRACKERMILESTONE_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrackerMilestoneClearActionCreator {
    (propertiesToClear?: string[]): ITrackerMilestoneClearAction;
}

export interface ITrackerMilestoneFilterActionCreator {
    (formFilter: IFormFilter): ITrackerMilestoneFilterAction;
}

export interface ITrackerMilestoneFilterAction {
    type: "ACTION_TRACKERMILESTONE_FILTER";
    formFilter: IFormFilter;
}

export type TrackerMilestoneAction =
    ITrackerMilestoneLoadAction |
    ITrackerMilestoneLoadByIdAction |
    ITrackerMilestoneLoadFailureAction |
    ITrackerMilestoneLoadSuccessAction |
    ITrackerMilestoneSaveAction |
    ITrackerMilestoneSaveSuccessAction |
    ITrackerMilestoneSaveFailureAction |
    ITrackerMilestoneCreateAction |
    ITrackerMilestoneCreateSuccessAction |
    ITrackerMilestoneCreateFailureAction |
    ITrackerMilestoneClearAction |
    ITrackerMilestoneFilterAction;
