import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_NOTETYPES_LOAD,
    ACTION_NOTETYPES_LOAD_BY_ID,

    ACTION_NOTETYPES_SAVE,

    ACTION_NOTETYPES_CREATE,

    INoteTypeLoadAction,
    INoteTypeLoadByIdAction,
    INoteTypeLoadFailureAction,
    INoteTypeLoadSuccessAction,

    INoteTypeSaveAction,
    INoteTypeSaveSuccessAction,
    INoteTypeSaveFailureAction,

    INoteTypeCreateAction,
    INoteTypeCreateSuccessAction,
    INoteTypeCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/noteType";
import { noteTypesApi } from "../services/noteTypes";


export const
    getNoteTypesEpic = (action$): Observable<INoteTypeLoadSuccessAction | INoteTypeLoadFailureAction> => {
        return action$
            .ofType(ACTION_NOTETYPES_LOAD)
            .mergeMap((action: INoteTypeLoadAction) => {
                return noteTypesApi
                    .getNoteTypes()
                    .map(response =>
                        LoadSuccess(response.noteTypes, undefined, undefined, [], [], response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getNoteTypeByIdEpic = (action$): Observable<INoteTypeLoadSuccessAction | INoteTypeLoadFailureAction> => {
        return action$
            .ofType(ACTION_NOTETYPES_LOAD_BY_ID)
            .mergeMap((action: INoteTypeLoadByIdAction) => {
                return noteTypesApi
                    .getNoteTypeById(action.id)
                    .map(response =>
                        LoadSuccess(response.noteType, response.formState, response.formProperties, response.formRoles, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setNoteTypeEpic = (action$): Observable<INoteTypeSaveSuccessAction | INoteTypeSaveFailureAction> => {
        return action$
            .ofType(ACTION_NOTETYPES_SAVE)
            .mergeMap((action: INoteTypeSaveAction) => {
                return noteTypesApi
                    .setNoteType(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.noteType, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createNoteTypeEpic = (action$): Observable<INoteTypeCreateSuccessAction | INoteTypeCreateFailureAction> => {
        return action$
            .ofType(ACTION_NOTETYPES_CREATE)
            .mergeMap((action: INoteTypeCreateAction) => {
                return noteTypesApi
                    .createNoteType()
                    .map(response =>
                        CreateSuccess(
                            response.noteType,
                            response.formState,
                            response.formProperties,
                            response.formRoles,
                            response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



