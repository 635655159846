import * as React from 'react';

import * as classNames from "classnames";
import './TrialInstitutionNotesPage.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";

import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { ITrialInstitutionReduxFormState, initialTrialInstitutionState } from "../../../reducers/reactReduxForms/trialInstitution";
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";

import * as TrialInstitutionActions from '../../../actions/trialInstitution';
import * as TrialActions from '../../../actions/trial';
import * as ToggleActions from '../../../actions/pages/togglePage';
import * as ToggleHelper from "../../../helpers/toggleHelper";
import { TogglePageItem } from '../../../reducers/pages/togglePage';

import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../../components/form';
import { TrialInstitutionLayout } from '../../index';

interface ITrialInstitutionNotesPageParams {
    trialId: number;
    trialInstitutionId: number;
}

interface ITrialInstitutionNotesPageProps {

    form: Dtos.TrialInstitution & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITrialInstitutionReduxFormState;


    location: Location;
    trialId: number;
    trialInstitutionId: number;
    match: match<ITrialInstitutionNotesPageParams>;
    history: History;

    // From state
    trialInstitution: Dtos.TrialInstitution;

    loadingTrialInstitution: boolean;
    loadTrialInstitutionSuccess: boolean;
    loadTrialInstitutionFailure: boolean;

    user: Dtos.User,
    permissions?: Dtos.PersonnelTmdPermissions[];
    toggleItems: TogglePageItem[]
}

interface ITrialInstitutionNotesPageActions {
    clearTrialInstitution: TrialInstitutionActions.ITrialInstitutionClearActionCreator;
    loadTrialInstitution: TrialInstitutionActions.ITrialInstitutionLoadByIdActionCreator;

    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    toggleExpand: ToggleActions.IToggleExpandedStateActionCreator;
}

type TrialInstitutionNotesPageProps = ITrialInstitutionNotesPageProps & ITrialInstitutionNotesPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class TrialInstitutionNotesPage extends React.PureComponent<TrialInstitutionNotesPageProps, any> {

    constructor(props: TrialInstitutionNotesPageProps) {
        super(props);

        this.clear = this.clear.bind(this);
    }

    componentDidMount() {
        const {
            loadTrial,
            loadTrialInstitution,
            navigate,
            navigateReplace,
            trialId,
            trialInstitutionId
        } = this.props;

        if (trialInstitutionId) {
            loadTrialInstitution(trialInstitutionId);

            if (trialId) {
                loadTrial(trialId);
            }
        } else {
            // redirect
            navigate("/trials/" + trialId + "/institutions");
        }
    }

    componentWillReceiveProps(nextProps: TrialInstitutionNotesPageProps) {
    }

    componentDidUpdate(prevProps: TrialInstitutionNotesPageProps, prevState) {
        const {
            trialId,
            trialInstitutionId,
            navigateReplace,
            loadTrial,
            loadTrialInstitution,
        } = this.props

        if (trialInstitutionId && prevProps.trialInstitutionId != trialInstitutionId) {
            loadTrialInstitution(trialInstitutionId);
        }

        if (trialId && prevProps.trialId != trialId) {
            loadTrial(trialId);
        }
    }

    componentWillUpdate(nextProps: TrialInstitutionNotesPageProps) {
    }

    componentWillUnmount() {
        this.clear();
    }

    clear() {
        this.props.clearTrialInstitution();
        this.props.clearTrial();
    }

    show(): boolean {
        const {
            loadingTrialInstitution,
            loadTrialInstitutionSuccess
        } = this.props

        if (!loadingTrialInstitution && loadTrialInstitutionSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrialInstitution,
        } = this.props

        return <TrialInstitutionLayout
            loading={loadingTrialInstitution}
            permission={Dtos.Permission.TrialNoteView}>
            {this.renderContent()}
        </TrialInstitutionLayout>;
    }

    renderContent() {
        const {
            trialId,
            trialInstitutionId,
            trialInstitution,
            navigate,
            permissions
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-9 mb-2"}>
                    <h2>Notes</h2>
                </div>
                <div className="col-3" style={{ textAlign: 'right' }}>
                    {(trialInstitution && trialInstitution.trialId > 0 &&
                        AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialNoteCreate, trialInstitution.trialId, trialInstitution.institutionId)) && (
                        <Link className={"btn btn-primary"} url={"/trials/" + trialId + "/trialinstitution/" + trialInstitutionId + '/notes/create'}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.show() ?
                    "Loading Note" :
                    null
            }
            {
                this.show() ?
                    <div className="mt-2">
                        {this.renderTable()}
                    </div> :
                    null
            }
        </div>
    }


    toggle(noteTypeNotes: Dtos.NoteTypeNotes) {

    }

    renderTable() {
        const {
            trialId,
            trialInstitution,
            toggleItems,
            toggleExpand
        } = this.props;

        if (!trialInstitution || !trialInstitution.notes || trialInstitution.notes.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no notes to view.</span>
            </div>
        }

        const showPagination = false; //personnelRoleSummaries.length > DEFAULT_PAGE_SIZE;

        return <div>
            {trialInstitution && trialInstitution.notes && trialInstitution.notes.map((item, index) => {
                const key = "trial-institution-notes-page-" + item.noteTypeId;
                const expanded = ToggleHelper.isToggleExpanded(toggleItems, key);
                return (
                    <div key={"note-type" + index}>
                        <div className={tableHeaderClass} style={tableStyleClass} onClick={() => toggleExpand(key)}>
                            <div>
                                <FontAwesomeIcon icon={!expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" />
                            </div>
                            <span>{item.noteType.value}</span>
                        </div>
                        {expanded && (
                            <ReactTable
                                data={item.notes}
                                pageSizeOptions={PAGE_SIZE_OPTIONS}
                                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : item.notes.length}
                                pageSize={showPagination ? undefined : item.notes.length}
                                showPagination={showPagination}
                                className="-striped -highlight -clickable mb-3"
                                noDataText="There are currently no notes for this type"
                                resizable={false}
                                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                                    return {
                                        onClick: (e, handleOriginal) => {
                                            console.log(rowInfo);
                                            const url: string = "/trials/" + trialId + "/trialinstitution/" + trialInstitution.id + '/notes/' + rowInfo.original.id;
                                            this.props.navigate(url);

                                            if (handleOriginal) {
                                                handleOriginal()
                                            }

                                        },
                                        style: {
                                            color: (rowInfo && rowInfo.original && rowInfo.original.active) ? '#212529' : '#9e9e9e'
                                        }
                                    }
                                }}
                                columns={
                                    [
                                        {
                                            id: "id",
                                            Header: ' ',
                                            accessor: "id",
                                            className: "d-flex align-items-center justify-content-center",
                                            maxWidth: 50,
                                            Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.NOTE} fixedWidth />
                                        },
                                        {
                                            id: "title",
                                            Header: "Title",
                                            accessor: "title",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left"
                                        },
                                        {
                                            id: "comments",
                                            Header: "Comments",
                                            accessor: "comments",
                                            className: "d-flex align-items-left justify-content-left",
                                            headerClassName: "text-left"
                                        },
                                        {
                                            id: "dateEntered",
                                            Header: "Date Entered",
                                            accessor: "dateEntered",
                                            className: "d-flex align-items-center justify-content-center",
                                            maxWidth: 180,
                                            Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                                        }
                                    ]
                                }
                            />
                        )}
                    </div>
                )
            }
            )}
        </div>
    }

}


const mapStateToProps = (state: ITmdState, ownProps: TrialInstitutionNotesPageProps): ITrialInstitutionNotesPageProps => {

    let trialInstitution: Dtos.TrialInstitution | undefined = !(state.trialInstitution.formData instanceof Array) ? state.trialInstitution.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {
        form: state.reduxForms.trialInstitution,
        formState: state.trialInstitution.formState,
        formProperties: state.trialInstitution.formProperties,
        reduxFormState: state.reduxForms.formState.trialInstitution,

        match: ownProps.match,
        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,
        trialInstitutionId: ownProps.match && ownProps.match.params.trialInstitutionId.toString() != "create" ? ownProps.match.params.trialInstitutionId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trialInstitution: trialInstitution!,

        loadingTrialInstitution: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Pending,
        loadTrialInstitutionSuccess: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Success,
        loadTrialInstitutionFailure: state.trialInstitution.loadState && state.trialInstitution.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        toggleItems: state.togglePage.items
    };
};

const mapDispatchToProps = (dispatch): ITrialInstitutionNotesPageActions => {
    return {

        loadTrialInstitution: bindActionCreators(TrialInstitutionActions.LoadTrialInstitutionById, dispatch),
        clearTrialInstitution: bindActionCreators(TrialInstitutionActions.Clear, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch),

        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        toggleExpand: bindActionCreators(ToggleActions.ToggleExpanded, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialInstitutionNotesPage);
