import './PrimitiveFormDesigner.scss';
import * as Dtos from '../../../../dtos/Tmd.dtos';

import * as React from "react";
import * as classNames from "classnames";
import { IBaseFormInputProps } from '../';
import { actions, FieldAction, ChangeOptions, ModelAction } from 'react-redux-form';

import { FontAwesomeIcons, FontAwesomeIcon } from "../../../../constants/fontAwesomeIcons";

import { initialItemComponentPrimitiveOptionState } from "../../../../reducers/reactReduxForms/itemComponent";

import { DatePickerFormInput, TextFormInput, TextAreaFormInput, CheckboxFormInput, SelectFormInput, DatePickerFormInputFabric, NumberFormInput } from '../../inputs';

export interface IPrimitiveFormDesignerProps {
    item?: Dtos.ItemComponentPrimitive;
    index: number;
    feedbackIcon?: string;
    className?: string;
    label?: string;
    name: string;
    id: string;
    changeAction?: (name: string, value: any) => void;
    onFocus?: () => FieldAction;
    onShowRules?: Function;
    onUp?: Function;
    onDown?: Function;
    // value: any;
    disabled?: boolean;
    focused?: boolean;
    valid?: boolean;
    invalid?: boolean;
    hasSoftError?: boolean;
    hasHardError?: boolean;
}

class PrimitiveFormDesigner extends React.PureComponent<IPrimitiveFormDesignerProps, any> {

    renderComponentContainer() {

    }

    render() {
        const {
            index,
            className,
            item,
            disabled,
            focused,
            id,
            invalid,
            label,
            name,
            onFocus,
            valid,
            feedbackIcon,
            hasSoftError,
            hasHardError
        } = this.props;

        const derivedClass = className ? className : "form-control";

        let componentDesign;

        switch (item.itemPrimitive) {
            case Dtos.ItemPrimitive.Label:
                componentDesign = <PrimitiveFormDesignerContainer
                    header={"Label"}
                    description={"Display a label without any input controls"}
                    item={item}
                    onRemove={() => this.props.changeAction("active", false)}
                    onUp={this.props.onUp}
                    onDown={this.props.onDown}
                    disabled={disabled}>
                    <div className="row">
                        <div className="col-3">
                            <label>Label</label>
                        </div>
                        <div className="col-9">
                            <input
                                autoFocus={false}
                                value={item.label}
                                type={"text"}
                                className={classNames(
                                    derivedClass)
                                }
                                name={name}
                                id={id}
                                onChange={(event) => { this.props.changeAction("label", event.target.value ? event.target.value : null) }}
                                placeholder={"Enter the label..."}
                                maxLength={100}
                                disabled={disabled}
                            />
                        </div>
                    </div>

                </PrimitiveFormDesignerContainer>;
                break;
            case Dtos.ItemPrimitive.Text:
                componentDesign = <PrimitiveFormDesignerContainer
                    header={"Text"}
                    description={"Text box that can accept a single line of text"}
                    item={item}
                    onShowRules={this.props.onShowRules}
                    onRemove={() => this.props.changeAction("active", false)}
                    onUp={this.props.onUp}
                    onDown={this.props.onDown}
                    disabled={disabled}>
                    <div className="row">
                        <div className="col-3">
                            <label>Label</label>
                        </div>
                        <div className="col-9">
                            <input
                                autoFocus={false}
                                value={item.label}
                                type={"text"}
                                className={classNames(
                                    derivedClass)
                                }
                                name={name}
                                id={id}
                                placeholder={"Optional..."}
                                onChange={(event) => { this.props.changeAction("label", event.target.value ? event.target.value : null) }}
                                maxLength={100}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                </PrimitiveFormDesignerContainer>;
                break;
            case Dtos.ItemPrimitive.Comments:
                componentDesign = <PrimitiveFormDesignerContainer
                    header={"Comment"}
                    description={"Text box that can accept multiple lines of text"}
                    item={item}
                    onShowRules={this.props.onShowRules}
                    onRemove={() => this.props.changeAction("active", false)}
                    onUp={this.props.onUp}
                    onDown={this.props.onDown}
                    disabled={disabled}>
                    <div className="row">
                        <div className="col-3">
                            <label>Label</label>
                        </div>
                        <div className="col-9">
                            <input
                                autoFocus={false}
                                value={item.label}
                                type={"text"}
                                className={classNames(
                                    derivedClass)
                                }
                                name={name}
                                id={id}
                                onChange={(event) => { this.props.changeAction("label", event.target.value ? event.target.value : null) }}
                                placeholder={"Optional..."}
                                maxLength={100}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                </PrimitiveFormDesignerContainer>;
                break;
            case Dtos.ItemPrimitive.Date:
                componentDesign = <PrimitiveFormDesignerContainer
                    header={"Date"}
                    item={item}
                    onShowRules={this.props.onShowRules}
                    onRemove={() => this.props.changeAction("active", false)}
                    onUp={this.props.onUp}
                    onDown={this.props.onDown}
                    disabled={disabled}>
                    <div className="row">
                        <div className="col-3">
                            <label>Label</label>
                        </div>
                        <div className="col-9">
                            <input
                                autoFocus={false}
                                value={item.label}
                                type={"text"}
                                className={classNames(
                                    derivedClass)
                                }
                                name={name}
                                id={id}
                                onChange={(event) => { this.props.changeAction("label", event.target.value ? event.target.value : null) }}
                                placeholder={"Optional..."}
                                maxLength={100}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                </PrimitiveFormDesignerContainer>;
                break;
            case Dtos.ItemPrimitive.Checkbox:
                componentDesign = <PrimitiveFormDesignerContainer
                    header={"Checkbox"}
                    description={"Yes/no tick box"}
                    item={item}
                    onShowRules={this.props.onShowRules}
                    onRemove={() => this.props.changeAction("active", false)}
                    onUp={this.props.onUp}
                    onDown={this.props.onDown}
                    disabled={disabled}>
                    <div className="row">
                        <div className="col-3">
                            <label>Label</label>
                        </div>
                        <div className="col-9">
                            <input
                                autoFocus={false}
                                value={item.label}
                                type={"text"}
                                className={classNames(
                                    derivedClass)
                                }
                                name={name}
                                id={id}
                                onChange={(event) => { this.props.changeAction("label", event.target.value ? event.target.value : null) }}
                                placeholder={"Enter label..."}
                                maxLength={100}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                </PrimitiveFormDesignerContainer>;
                break;
            case Dtos.ItemPrimitive.Documents:
                componentDesign = <PrimitiveFormDesignerContainer
                    header={"Documents"}
                    description={"Upload multiple documents"}
                    item={item}
                    onShowRules={this.props.onShowRules}
                    onRemove={() => this.props.changeAction("active", false)}
                    onUp={this.props.onUp}
                    onDown={this.props.onDown}
                    disabled={disabled}>
                    <div className="row">
                        <div className="col-3">
                            <label>Label</label>
                        </div>
                        <div className="col-9">
                            <input
                                autoFocus={false}
                                value={item.label}
                                type={"text"}
                                className={classNames(
                                    derivedClass)
                                }
                                name={name}
                                id={id}
                                onChange={(event) => { this.props.changeAction("label", event.target.value ? event.target.value : null) }}
                                placeholder={"Optional..."}
                                maxLength={100}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                </PrimitiveFormDesignerContainer>;
                break;
            case Dtos.ItemPrimitive.Numeric:
                componentDesign = <PrimitiveFormDesignerContainer
                    header={"Numeric"}
                    description={"Text box that can accept numbers only"}
                    item={item}
                    onShowRules={this.props.onShowRules}
                    onRemove={() => this.props.changeAction("active", false)}
                    disabled={disabled}>
                    <div className="row">
                        <div className="col-3">
                            <label>Label</label>
                        </div>
                        <div className="col-9">
                            <input
                                autoFocus={false}
                                value={item.label}
                                type={"text"}
                                className={classNames(
                                    derivedClass)
                                }
                                name={name}
                                id={id}
                                onChange={(event) => { this.props.changeAction("label", event.target.value ? event.target.value : null) }}
                                placeholder={"Optional..."}
                                maxLength={100}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                </PrimitiveFormDesignerContainer>;
                break;
            case Dtos.ItemPrimitive.Select:
                componentDesign = <PrimitiveFormDesignerContainer
                    header={"Select"}
                    description={"Drop down list that allows the selection of a single value"}
                    item={item}
                    onShowRules={this.props.onShowRules}
                    onRemove={() => this.props.changeAction("active", false)}
                    onUp={this.props.onUp}
                    onDown={this.props.onDown}
                    disabled={disabled}>
                    <div className="row">
                        <div className="col-3">
                            <label>Label</label>
                        </div>
                        <div className="col-9">
                            <input
                                autoFocus={false}
                                value={item.label}
                                type={"text"}
                                className={classNames(
                                    derivedClass)
                                }
                                name={name}
                                id={id}
                                onChange={(event) => { this.props.changeAction("label", event.target.value ? event.target.value : null) }}
                                placeholder={"Optional..."}
                                maxLength={100}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                        </div>
                        <div className="col-9 generic-form-field pt-4">
                            <CheckboxFormInput name={"measure"} id={"measure"} value={item.measure}
                                onChange={(v, o) => {
                                    this.props.changeAction("measure", !item.measure);
                                    return null;
                                }}
                                additionalContent={""}
                                disabled={disabled}
                                hideLabel={false} label={"Measure"} />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-3">
                            <label>Selection options<br />(sorted by rank then name)</label>
                        </div>
                        <div className="col-9">
                            <div className="primitive-options">
                                {item.itemComponentPrimitiveOptions && item.itemComponentPrimitiveOptions.length == 0 && (
                                    <p>No options added</p>
                                )}
                                {item.itemComponentPrimitiveOptions && item.itemComponentPrimitiveOptions.map((option, index) => {
                                    return <div className="row" style={{ marginBottom: '0.2rem' }} key={"primitive-option-" + index.toString()}>
                                        <div className="col-6">
                                            <TextFormInput name={"value[" + index.toString() + "]"} id={"value[" + index.toString() + "]"}
                                                value={option.value}
                                                onChange={(v, o) => {
                                                    this.props.changeAction("itemComponentPrimitiveOptions[" + index.toString() + "].value", v);
                                                    return null;
                                                }}
                                                placeholder={"Enter text..."}
                                                disabled={!option.active} mapProps={{}} />
                                        </div>
                                        <div className="col">
                                            <NumberFormInput name={"rank[" + index.toString() + "]"} id={"rank[" + index.toString() + "]"}
                                                value={option.rank}
                                                disabled={!option.active}
                                                onChange={(v, o) => {
                                                    this.props.changeAction("itemComponentPrimitiveOptions[" + index.toString() + "].rank", v);
                                                    return null;
                                                }}
                                            />
                                        </div>
                                        {item.measure && <div className="col">
                                            <NumberFormInput name={"measurement[" + index.toString() + "]"} id={"measurement[" + index.toString() + "]"}
                                                value={option.measurement}
                                                disabled={!option.active}
                                                onChange={(v, o) => {
                                                    this.props.changeAction("itemComponentPrimitiveOptions[" + index.toString() + "].measurement", v);
                                                    return null;
                                                }}
                                            />
                                        </div> }
                                        <div className="col">
                                            <CheckboxFormInput name={"active[" + index.toString() + "]"} id={"active[" + index.toString() + "]"} value={option.active}
                                                onChange={(v, o) => {
                                                    console.log(v, o)
                                                    this.props.changeAction("itemComponentPrimitiveOptions[" + index.toString() + "].active", v);
                                                    return null;
                                                }}
                                                additionalContent={"test"}
                                                disabled={disabled}
                                                hideLabel={false} label={"Active"} />
                                        </div>
                                    </div>
                                })}
                            </div>
                            <br />
                            <button disabled={disabled}
                                onClick={
                                    () => {
                                        // debugger;
                                        const newItem: Dtos.ItemComponentPrimitiveOption = Object.assign({ ...initialItemComponentPrimitiveOptionState });
                                        newItem.rank = 1;
                                        let array: any[] = [];
                                        if (item.itemComponentPrimitiveOptions.length > 0) {
                                            item.itemComponentPrimitiveOptions.forEach(o => {
                                                if (o.rank >= newItem.rank) {
                                                    newItem.rank = o.rank + 1;
                                                }
                                            })
                                            array = [...item["itemComponentPrimitiveOptions"], newItem];
                                        } else {
                                            array = [newItem];
                                        }

                                        this.props.changeAction("itemComponentPrimitiveOptions", array);
                                    }
                                }
                                type="button"
                                className="btn btn-primary mr-2"
                            >
                                <span>Add Option</span>
                            </button>
                        </div>
                    </div>
                </PrimitiveFormDesignerContainer>;
                break;

        }

        const primative = <div className="primitive-form-designer">
            {componentDesign}
        </div>;

        const hardFeedback = hasHardError && !disabled ?
            <span className={classNames("form-control-feedback block pr-2")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.BAN} fixedWidth />
            </span> :
            null;


        const feedback = (invalid && !disabled) && !hasHardError ?
            <span className={classNames("form-control-feedback pr-2")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE} fixedWidth />
            </span> :
            null;

        const softFeedback = hasSoftError && !disabled && (!invalid) ?
            <span className={classNames("form-control-feedback warning pr-2")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.EXCLAMATION_TRIANGLE} fixedWidth />
            </span> :
            null;

        return <div style={{ position: "relative", width: "100%" }}>
            {primative}
            {hardFeedback}
            {feedback}
            {softFeedback}
        </div>
    }
}

export interface IPrimitiveFormDesignerContainerProps {
    header: string;
    description?: string;
    disabled?: boolean;
    onShowRules?: Function;
    onRemove?: Function;
    onUp?: Function;
    onDown?: Function;
    item?: Dtos.ItemComponentPrimitive;
}

class PrimitiveFormDesignerContainer extends React.PureComponent<IPrimitiveFormDesignerContainerProps, any> {
    constructor(props) {
        super(props);
    }

    render() {

        const {
            header,
            description,
            disabled,
            onShowRules,
            item
        } = this.props

        return (
            <div className="primitive-form-designer-container container-fluid">
                <div className="row">
                    <div className="col-sm">
                        <h6><b>{header}</b>
                            {description && (
                                <span>&nbsp;|&nbsp;{description}</span>
                            )}</h6>
                    </div>
                    <div className="col-sm" style={{ textAlign: 'right' }}>
                        {onShowRules && (
                            <button disabled={disabled || (!item.id || item.id == 0)}
                                onClick={
                                    () => {
                                        this.props.onShowRules(this.props.item);
                                    }
                                }
                                type="button"
                                className="btn btn-outline-secondary mr-2 btn-sm"
                            >
                                <span>Rules</span>
                            </button>
                        )}

                        <button disabled={disabled}
                            onClick={
                                () => {
                                    if (this.props.onUp) {
                                        this.props.onUp(item);
                                    }
                                }
                            }
                            type="button"
                            className="btn btn-outline-secondary mr-2 btn-sm"
                        >
                            <FontAwesomeIcon icon={FontAwesomeIcons.Light.UP} fixedWidth />
                        </button>
                        <button disabled={disabled}
                            onClick={
                                () => {
                                    if (this.props.onDown) {
                                        this.props.onDown(item);
                                    }
                                }
                            }
                            type="button"
                            className="btn btn-outline-secondary mr-2 btn-sm"
                        >
                            <FontAwesomeIcon icon={FontAwesomeIcons.Light.DOWN} fixedWidth />
                        </button>
                        {this.props.onRemove && (
                            <button disabled={disabled}
                                onClick={
                                    () => {
                                        this.props.onRemove();
                                    }
                                }
                                type="button"
                                className="btn btn-outline-secondary btn-sm"
                            >
                                <FontAwesomeIcon icon={FontAwesomeIcons.Light.TRASH} fixedWidth />
                            </button>
                        )}
                    </div>
                </div>
                <hr style={{ marginTop: '6px', marginBottom: 0 }} />
                <div className="row" style={{ marginTop: '5px' }}>
                    <div className="col-sm">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default PrimitiveFormDesigner;