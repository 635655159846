import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_GROUPS_LOAD,
    ACTION_GROUPS_LOAD_BY_ID,

    ACTION_GROUPS_SAVE,

    ACTION_GROUPS_CREATE,

    IGroupLoadAction,
    IGroupLoadByIdAction,
    IGroupLoadFailureAction,
    IGroupLoadSuccessAction,

    IGroupSaveAction,
    IGroupSaveSuccessAction,
    IGroupSaveFailureAction,

    IGroupCreateAction,
    IGroupCreateSuccessAction,
    IGroupCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../actions/groups";
import { groupsApi } from "../services/groups";


export const
    getGroupsEpic = (action$): Observable<IGroupLoadSuccessAction | IGroupLoadFailureAction> => {
        return action$
            .ofType(ACTION_GROUPS_LOAD)
            .mergeMap((action: IGroupLoadAction) => {
                return groupsApi
                    .getGroups()
                    .map(response =>
                        LoadSuccess(response.groups, undefined, undefined, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getGroupByIdEpic = (action$): Observable<IGroupLoadSuccessAction | IGroupLoadFailureAction> => {
        return action$
            .ofType(ACTION_GROUPS_LOAD_BY_ID)
            .mergeMap((action: IGroupLoadByIdAction) => {
                return groupsApi
                    .getGroupById(action.id)
                    .map(response =>
                        LoadSuccess(response.group, response.formState, response.formProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setGroupEpic = (action$): Observable<IGroupSaveSuccessAction | IGroupSaveFailureAction> => {
        return action$
            .ofType(ACTION_GROUPS_SAVE)
            .mergeMap((action: IGroupSaveAction) => {
                return groupsApi
                    .setGroup(action.formData)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.group, response.formState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createGroupEpic = (action$): Observable<IGroupCreateSuccessAction | IGroupCreateFailureAction> => {
        return action$
            .ofType(ACTION_GROUPS_CREATE)
            .mergeMap((action: IGroupCreateAction) => {
                return groupsApi
                    .createGroup()
                    .map(response =>
                        CreateSuccess(response.group, response.formState, response.formProperties, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(CreateFailure(error.responseStatus))
                    )
            });
    }



