import * as React from 'react';
import './TrialGroupPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu, TruncatedCell } from "../../../components/layouts/index";
import { ITrialGroupReduxFormState, initialTrialGroupState } from "../../../reducers/reactReduxForms/trialGroup";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import * as TrialGroupActions from '../../../actions/trialGroup';
import * as TrialActions from '../../../actions/trial';
import * as TrialGroupItemsPageActions from '../../../actions/pages/trialGroupItemsPage';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TrialLayout } from '../../index';

import {
    TrialGroupItemCreateVersion,
    CollectionInstancePage
} from "../../../containers/index";

interface ITrialGroupPageParams {
    trialId: number;
    trialGroupId: number;
}

interface ITrialGroupPageProps {

    form: Dtos.TrialGroup & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITrialGroupReduxFormState;

    location: Location;
    trialId: number;
    trialGroupId: number;
    match: match<ITrialGroupPageParams>;
    history: History;

    editModalOpen: boolean,
    collectionInstanceId: number,
    modalTitle: string,

    createVersionModalOpen: boolean,
    createVersionTrialGroupId: number,
    createVersionGroupItemId: number,

    // From state
    trialGroup: Dtos.TrialGroup;
    groupItemsByCategory: Dtos.GroupItemCategory[];
    trial: Dtos.Trial;
    trialRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];

    loadingTrial: boolean;

    loadingTrialGroup: boolean;
    loadTrialGroupSuccess: boolean;
    loadTrialGroupFailure: boolean;

    savingTrialGroup: boolean;
    saveTrialGroupSuccess: boolean;
    saveTrialGroupFailure: boolean;

    creatingTrialGroup: boolean;
    createTrialGroupSuccess: boolean;
    createTrialGroupFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface ITrialGroupPageActions {
    clearTrialGroup: TrialGroupActions.ITrialGroupClearActionCreator;
    loadTrialGroup: TrialGroupActions.ITrialGroupLoadByIdActionCreator;
    saveTrialGroup: TrialGroupActions.ITrialGroupSaveActionCreator;
    createTrialGroup: TrialGroupActions.ITrialGroupCreateActionCreator;

    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    setEditModelViewState: TrialGroupItemsPageActions.ITrialGroupItemsPageSetEditModalViewStateActionCreator;
    setCreateVersionModelViewState: TrialGroupItemsPageActions.ITrialGroupItemsPageSetCreateVersionModalViewStateActionCreator;
}

type TrialGroupPageProps = ITrialGroupPageProps & ITrialGroupPageActions;

const formName: string = "TrialGroup";
const reduxFormName: string = "reduxForms.trialGroup";
const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}


class TrialGroupPage extends React.PureComponent<TrialGroupPageProps, any> {

    constructor(props: TrialGroupPageProps) {
        super(props);

        this.clearTrialGroup = this.clearTrialGroup.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTrialGroup,
            navigate,
            navigateReplace,
            trialGroupId,
            trialId,
            loadTrial,
            createTrialGroup
        } = this.props;

        if (trialGroupId) {
            loadTrialGroup(trialGroupId);
        } else {
            createTrialGroup(trialId)
        }
        loadTrial(trialId);
    }

    componentWillReceiveProps(nextProps: TrialGroupPageProps) {
    }

    componentDidUpdate(prevProps: TrialGroupPageProps, prevState) {
        const {
            form,
            trialId,
            trialGroupId,
            trialGroup,
            navigateReplace,
            loadTrialGroup,
            loadTrial,
            loadingTrialGroup,
            loadTrialGroupSuccess,
            createTrialGroup,
            creatingTrialGroup,
            createTrialGroupSuccess,
            savingTrialGroup,
            saveTrialGroupSuccess,
            saveTrialGroupFailure,
            validationFailures,
            navigate
        } = this.props;

        if (trialId && prevProps.trialId != trialId) {
            loadTrial(trialId);
        }

        if (trialGroupId && prevProps.trialGroupId != trialGroupId) {
            loadTrialGroup(trialGroupId);
        }
        else if (!trialGroupId && prevProps.trialGroupId) {
            createTrialGroup(trialId);
        }

        if (!loadingTrialGroup && prevProps.loadingTrialGroup) {
            if (loadTrialGroupSuccess) {
                this.loadForm();
            }
            else {

            }
        }


        if (!savingTrialGroup && prevProps.savingTrialGroup) {
            if (saveTrialGroupSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    // toggleMessageModal();

                } else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }
                if (form.saveAndReturn) {
                    navigate("/trials/" + trialId + "/groups")
                } else if (!form.saveAndReturn && trialGroupId && trialGroupId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !trialGroupId && trialGroup && trialGroup.id > 0) {
                    navigate("/trials/" + trialId + "/groups/" + trialGroup.id);
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingTrialGroup && prevProps.creatingTrialGroup) {
            if (createTrialGroupSuccess) {
                this.loadForm();
            }
            else {

            }
        }
    }

    componentWillUpdate(nextProps: TrialGroupPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearTrialGroup();
        loadForm(reduxFormName, initialTrialGroupState);
        resetForm(reduxFormName);
    }

    clearTrialGroup() {
        this.props.clearTrialGroup();
        this.props.clearTrial();
    }

    showTrialGroup(): boolean {
        const {
            loadingTrial,
            loadingTrialGroup,
            loadTrialGroupSuccess
        } = this.props

        if (!loadingTrial && !loadingTrialGroup && loadTrialGroupSuccess) {
            return true;
        }

        return false;
    }

    render() {
        return (
            <TrialLayout loading={this.props.loadingTrial } permission={Dtos.Permission.TrialGroupView}>{this.renderContent()}</TrialLayout>
        );
    }

    renderContent() {
        const {
            form,
            trialGroup,
            trialGroupId,
            loadingTrialGroup,
            loadTrialGroupSuccess,
            loadTrialGroupFailure,
            clearTrialGroup,
            navigate,
            permissions,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingTrialGroup
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.TrialGroupUpdate, Dtos.Permission.TrialGroupCreate);
        const formDisabled: boolean = !hasPermission || savingTrialGroup || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            groupId: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: form && form.id && form.id > 0,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("TrialGroup", "GroupId", lookups), form.groupId)
                }
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            },
        };

        return <div>
            <h1>Trial Group</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "trialGroup", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                {(form && form.id && form.id > 0) &&
                    this.renderTable()
                }


                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
            {
                this.renderItemModal()
            }
            {
                this.renderCreateVersionModal()
            }
        </div>

    }

    renderTable() {
        const {
            trial,
            groupItemsByCategory,
            setEditModelViewState,
            setCreateVersionModelViewState
        } = this.props;

        console.log('trialItemsByCategory', groupItemsByCategory);
        let expanded = true;

        const showPagination = groupItemsByCategory.length > DEFAULT_PAGE_SIZE;

        return <div>
            {groupItemsByCategory.map((item, index) =>
                <div key={"trial-items-category" + item.category.id}>
                    <div className={tableHeaderClass} style={tableStyleClass} onClick={() => this.toggle(item.category.id)}>
                        <div>
                            <FontAwesomeIcon icon={!expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" />
                        </div>
                        <span>{item.category.value}</span>
                    </div>
                    <ReactTable
                        data={item.groupItemSummaries}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : item.groupItemSummaries.length}
                        pageSize={showPagination ? undefined : item.groupItemSummaries.length}
                        showPagination={showPagination}
                        className="-striped -highlight -clickable mb-3"
                        noDataText="There are currently no trial items for this category"
                        resizable={false}
                        getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                            console.log(rowInfo);
                            return {
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo.original.collectionInstanceId && rowInfo.original.collectionInstanceId > 0) {
                                        setEditModelViewState(true, rowInfo.original.collectionInstanceId, rowInfo.original.name + " [" + rowInfo.original.version + "]");
                                    }

                                    if (handleOriginal) {
                                        handleOriginal()
                                    }

                                },
                                style: {
                                    color: (rowInfo && rowInfo.original && rowInfo.original.dataEntered) ? '#212529' : '#9e9e9e'
                                }
                            }
                        }}
                        columns={
                            [
                                {
                                    id: "id",
                                    Header: ' ',
                                    accessor: "id",
                                    className: "d-flex align-items-center justify-content-center",
                                    maxWidth: 50,
                                    Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.TRIALITEM} fixedWidth />
                                },
                                {
                                    id: "name",
                                    Header: "Name",
                                    accessor: "name",
                                    className: "d-flex align-items-left justify-content-left",
                                    headerClassName: "text-left",
                                    Cell: (props) => <TruncatedCell value={props.value} />
                                },
                                {
                                    id: "description",
                                    Header: "Description",
                                    accessor: "description",
                                    className: "d-flex align-items-left justify-content-left",
                                    headerClassName: "text-left",
                                    Cell: (props) => <TruncatedCell value={props.value} />
                                },
                                {
                                    id: "version",
                                    Header: "Version",
                                    className: "d-flex align-items-left justify-content-left",
                                    headerClassName: "text-left",
                                    maxWidth: 320,
                                    style: { whiteSpace: 'break-spaces'},
                                    Cell: row => (
                                        <div>
                                            <span className="ml-5 mr-5 mb-1">{row.original.version}</span>
                                            {row.original.groupItemSummaryVersions.map((item, index) =>
                                                <button disabled={false}
                                                    onClick={
                                                        (e) => {
                                                            e.stopPropagation();
                                                            console.log(row);
                                                            if (item.collectionInstanceId && item.collectionInstanceId > 0) {
                                                                setEditModelViewState(true, item.collectionInstanceId, row.original.name + " [" + item.version + "]");
                                                            }
                                                        }
                                                    }
                                                    type="button"
                                                    className="btn btn-secondary mr-2 mb-1 btn-sm"
                                                >
                                                    <span>{ item.version }</span>
                                                </button>
                                            )}
                                        </div>
                                    )
                                },
                                {
                                    id: "create",
                                    Header: "",
                                    className: "d-flex align-items-right",
                                    maxWidth: 120,
                                    style: { textAlign: 'right' },
                                    Cell: row => (
                                        <div>
                                            {row.original.allowVersions && (
                                                <button disabled={false}
                                                    onClick={
                                                        (e) => {
                                                            e.stopPropagation();
                                                            console.log(row)
                                                            setCreateVersionModelViewState(true, row.original.trialGroupId, row.original.groupItemId)
                                                        }
                                                    }
                                                    type="button"
                                                    className="btn btn-outline-secondary mr-2 btn-sm"
                                                >
                                                    <span>New version</span>
                                                </button>
                                            )}
                                        </div>
                                    )
                                }
                            ]
                        }
                    />
                </div>
            )}
        </div>
    }

    renderItemModal() {
        const {
            trialGroupId,
            trialGroup,
            loadingTrialGroup,
            editModalOpen,
            setEditModelViewState,
            collectionInstanceId,
            loadTrialGroup,
            modalTitle,
            savingTrialGroup,
            permissions,
            formState,
            form
        } = this.props

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.TrialGroupUpdate, Dtos.Permission.TrialGroupCreate);
        const formDisabled: boolean = !hasPermission || savingTrialGroup || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        if (trialGroup && !loadingTrialGroup && collectionInstanceId && collectionInstanceId > 0) {
            return <Modal open={editModalOpen} disableCloseOnOverlayClick={true} onClose={() => { setEditModelViewState(false, null, ""); loadTrialGroup(trialGroupId); }} size={ModalSize.Xl}>
                <div className="modal-header">
                    <h5 className="modal-title">{setModalTitle(undefined, modalTitle)}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => { setEditModelViewState(false, null, ""); loadTrialGroup(trialGroupId); }}>
                        <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                    </button>
                </div>
                <div className="modal-body">
                    <CollectionInstancePage
                        collectionInstanceId={collectionInstanceId}
                        readonly={formDisabled}
                        onClose={() => { setEditModelViewState(false, null, ""); loadTrialGroup(trialGroupId); }}
                    />
                </div>
            </Modal>
        }
    }

    renderCreateVersionModal() {
        const {
            trialGroupId,
            trialGroup,
            loadingTrialGroup,
            createVersionModalOpen,
            setCreateVersionModelViewState,
            loadTrialGroup,
            createVersionTrialGroupId,
            createVersionGroupItemId,
        } = this.props

        if (trialGroup && !loadingTrialGroup) {
            return <Modal open={createVersionModalOpen}
                disableCloseOnOverlayClick={true}
                onClose={() => { setCreateVersionModelViewState(false, null, null); loadTrialGroup(trialGroupId); }}
                size={ModalSize.Md}>
                <div className="modal-header">
                    <h5 className="modal-title">{setModalTitle(undefined, "Create Version")}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => { setCreateVersionModelViewState(false, null, null); loadTrialGroup(trialGroupId); }}>
                        <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                    </button>
                </div>
                <div className="modal-body" style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <TrialGroupItemCreateVersion
                        trialGroupId={createVersionTrialGroupId}
                        groupItemId={createVersionGroupItemId}
                        onClose={() => { setCreateVersionModelViewState(false, null, null); loadTrialGroup(trialGroupId); }} />
                </div>
            </Modal>
        }
        // 
    }


    toggle(categoryId: number) {

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.TrialGroup) {
        const {
            saveTrialGroup,
        } = this.props;

        if (data) {
            let changes: Dtos.TrialGroup = {} as Dtos.TrialGroup;
            saveTrialGroup(Object.assign({ ...data }, changes));
        }
    }

    handleOnSubmitFailed(data: Dtos.TrialGroup) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            trialId,
            navigate
        } = this.props;

        navigate("/trials/" + trialId + "/groups");
    }

    loadForm() {
        const {
            loadForm,
            trialGroup,
        } = this.props

        let extraData: Dtos.TrialGroup = {} as Dtos.TrialGroup;

        loadForm(reduxFormName, Object.assign({ ...trialGroup }, extraData));
    }
}


const mapStateToProps = (state: ITmdState, ownProps: TrialGroupPageProps): ITrialGroupPageProps => {

    let trialGroup: Dtos.TrialGroup | undefined = !(state.trialGroup.formData instanceof Array) ? state.trialGroup.formData : undefined;
    let trial: Dtos.Trial | undefined = !(state.trials.formData instanceof Array) ? state.trials.formData : undefined;
    let groupItemsByCategory: Dtos.GroupItemCategory[] | undefined = trialGroup == undefined ? undefined : trialGroup.groupItemCategories;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,
        trialGroupId: ownProps.match && ownProps.match.params.trialGroupId.toString() != "create" ? ownProps.match.params.trialGroupId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        groupItemsByCategory: groupItemsByCategory!,
        trialGroup: trialGroup!,

        trial: trial!,
        trialRoles: state.trials.formRoles,

        createVersionModalOpen: state.trialGroupItemsPage.createVersionModalOpen,
        createVersionTrialGroupId: state.trialGroupItemsPage.trialGroupId,
        createVersionGroupItemId: state.trialGroupItemsPage.groupItemId,

        editModalOpen: state.trialGroupItemsPage.editModalOpen,
        collectionInstanceId: state.trialGroupItemsPage.collectionInstanceId,
        modalTitle: state.trialGroupItemsPage.name,

        loadingTrialGroup: state.trialGroup.loadState && state.trialGroup.loadState.status == RequestState.Pending,
        loadTrialGroupSuccess: state.trialGroup.loadState && state.trialGroup.loadState.status == RequestState.Success,
        loadTrialGroupFailure: state.trialGroup.loadState && state.trialGroup.loadState.status == RequestState.Failure,

        loadingTrial: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,

        savingTrialGroup: state.trialGroup.saveState && state.trialGroup.saveState.status == RequestState.Pending,
        saveTrialGroupSuccess: state.trialGroup.saveState && state.trialGroup.saveState.status == RequestState.Success,
        saveTrialGroupFailure: state.trialGroup.saveState && state.trialGroup.saveState.status == RequestState.Failure,

        creatingTrialGroup: state.trialGroup.createState && state.trialGroup.createState.status == RequestState.Pending,
        createTrialGroupSuccess: state.trialGroup.createState && state.trialGroup.createState.status == RequestState.Success,
        createTrialGroupFailure: state.trialGroup.createState && state.trialGroup.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: state.trialGroup.lookups,

        form: state.reduxForms.trialGroup,
        formState: state.trialGroup.formState,
        formProperties: state.trialGroup.formProperties,

        reduxFormState: state.reduxForms.formState.trialGroup,
        validationFailures: state.trialGroup.validationFailures,

    };
};

const mapDispatchToProps = (dispatch): ITrialGroupPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadTrialGroup: bindActionCreators(TrialGroupActions.LoadTrialGroupById, dispatch),
        clearTrialGroup: bindActionCreators(TrialGroupActions.Clear, dispatch),

        saveTrialGroup: bindActionCreators(TrialGroupActions.SaveTrialGroup, dispatch),
        createTrialGroup: bindActionCreators(TrialGroupActions.CreateTrialGroup, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch),

        setEditModelViewState: bindActionCreators(TrialGroupItemsPageActions.setEditModalViewState, dispatch),
        setCreateVersionModelViewState: bindActionCreators(TrialGroupItemsPageActions.setCreateVersionModalViewState, dispatch)

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialGroupPage);
