import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadTrialForm: ITrialFormLoadActionCreator = (trialId, institutionId) => {
        return {
            type: ACTION_TRIALFORM_LOAD,
            trialId,
            institutionId
        }
    },
    LoadTrialFormCurrentUser: ITrialFormLoadCurrentUserActionCreator = () => {
        return {
            type: ACTION_TRIALFORM_LOAD_CURRENT_USER
        }
    },
    LoadTrialFormById: ITrialFormLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TRIALFORM_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ITrialFormLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALFORM_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrialFormLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALFORM_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTrialForm: ITrialFormSaveActionCreator = (formData) => {
        return {
            type: ACTION_TRIALFORM_SAVE,
            formData
        }
    },
    SaveSuccess: ITrialFormSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRIALFORM_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrialFormSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALFORM_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTrialForm: ITrialFormCreateActionCreator = (trialId, institutionId, formTemplateId) => {

        return {
            type: ACTION_TRIALFORM_CREATE,
            trialId,
            institutionId,
            formTemplateId
        }
    },
    CreateSuccess: ITrialFormCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_TRIALFORM_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: ITrialFormCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALFORM_CREATE_FAILURE,
            responseStatus
        }
    },

    CreateVersion: ITrialFormCreateVersionActionCreator = (trialFormId, version, copyData) => {

        return {
            type: ACTION_TRIALFORM_CREATEVERSION,
            trialFormId,
            version,
            copyData
        }
    },
    CreateVersionSuccess: ITrialFormCreateVersionSuccessActionCreator = (formData) => {
        return {
            type: ACTION_TRIALFORM_CREATEVERSION_SUCCESS,
            formData
        }
    },
    CreateVersionFailure: ITrialFormCreateVersionFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALFORM_CREATEVERSION_FAILURE,
            responseStatus
        }
    },

    Clear: ITrialFormClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRIALFORM_CLEAR,
            propertiesToClear
        }
    },

    Filter: ITrialFormFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRIALFORM_FILTER,
            formFilter
        }
    },

    LoadTrialFormReport: ITrialFormReportLoadActionCreator = (id, version) => {
        return {
            type: ACTION_TRIALFORM_REPORT_LOAD,
            id,
            version
        }
    },
    LoadTrialFormReportSuccess: ITrialFormReportLoadSuccessActionCreator = (formData, responseStatus) => {
        return {
            type: ACTION_TRIALFORM_REPORT_LOAD_SUCCESS,
            formData,
            responseStatus
        }
    },
    LoadTrialFormReportFailure: ITrialFormReportLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALFORM_REPORT_LOAD_FAILURE,
            responseStatus
        }
    },

    SetTrialFormReportJustified: ITrialFormReportJustifiedActionCreator = (id, measurementReport) => {
        return {
            type: ACTION_TRIALFORM_REPORT_JUSTIFIED,
            id,
            measurementReport
        }
    },
    SetTrialFormReportJustifiedSuccess: ITrialFormReportJustifiedSuccessActionCreator = (formData, responseStatus) => {
        return {
            type: ACTION_TRIALFORM_REPORT_JUSTIFIED_SUCCESS,
            formData,
            responseStatus
        }
    },
    SetTrialFormReportJustifiedFailure: ITrialFormReportJustifiedFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALFORM_REPORT_JUSTIFIED_FAILURE,
            responseStatus
        }
    },

    SendReminderEmail: ITrialFormSendReminderEmailActionCreator = (id) => {
        return {
            type: ACTION_TRIALFORM_SEND_REMINDER_EMAIL,
            id
        }
    },
    SendReminderEmailSuccess: ITrialFormSendReminderEmailSuccessActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALFORM_SEND_REMINDER_EMAIL_SUCCESS,
            responseStatus
        }
    },
    SendReminderEmailFailure: ITrialFormSendReminderEmailFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALFORM_SEND_REMINDER_EMAIL_FAILURE,
            responseStatus
        }
    };


export const
    ACTION_TRIALFORM_LOAD = "ACTION_TRIALFORM_LOAD",
    ACTION_TRIALFORM_LOAD_CURRENT_USER = "ACTION_TRIALFORM_LOAD_CURRENT_USER",
    ACTION_TRIALFORM_LOAD_BY_ID = "ACTION_TRIALFORM_LOAD_BY_ID",
    ACTION_TRIALFORM_LOAD_SUCCESS = "ACTION_TRIALFORM_LOAD_SUCCESS",
    ACTION_TRIALFORM_LOAD_FAILURE = "ACTION_TRIALFORM_LOAD_FAILURE",

    ACTION_TRIALFORM_SAVE = "ACTION_TRIALFORM_SAVE",
    ACTION_TRIALFORM_SAVE_SUCCESS = "ACTION_TRIALFORM_SAVE_SUCCESS",
    ACTION_TRIALFORM_SAVE_FAILURE = "ACTION_TRIALFORM_SAVE_FAILURE",

    ACTION_TRIALFORM_CREATE = "ACTION_TRIALFORM_CREATE",
    ACTION_TRIALFORM_CREATE_SUCCESS = "ACTION_TRIALFORM_CREATE_SUCCESS",
    ACTION_TRIALFORM_CREATE_FAILURE = "ACTION_TRIALFORM_CREATE_FAILURE",

    ACTION_TRIALFORM_CREATEVERSION = "ACTION_TRIALFORM_CREATEVERSION",
    ACTION_TRIALFORM_CREATEVERSION_SUCCESS = "ACTION_TRIALFORM_CREATEVERSION_SUCCESS",
    ACTION_TRIALFORM_CREATEVERSION_FAILURE = "ACTION_TRIALFORM_CREATEVERSION_FAILURE",

    ACTION_TRIALFORM_CLEAR = "ACTION_TRIALFORM_CLEAR",

    ACTION_TRIALFORM_FILTER = "ACTION_TRIALFORM_FILTER",

    ACTION_TRIALFORM_REPORT_LOAD = "ACTION_TRIALFORM_REPORT_LOAD",
    ACTION_TRIALFORM_REPORT_LOAD_SUCCESS = "ACTION_TRIALFORM_REPORT_LOAD_SUCCESS",
    ACTION_TRIALFORM_REPORT_LOAD_FAILURE = "ACTION_TRIALFORM_REPORT_LOAD_FAILURE",

    ACTION_TRIALFORM_REPORT_JUSTIFIED = "ACTION_TRIALFORM_REPORT_JUSTIFIED",
    ACTION_TRIALFORM_REPORT_JUSTIFIED_SUCCESS = "ACTION_TRIALFORM_REPORT_JUSTIFIED_SUCCESS",
    ACTION_TRIALFORM_REPORT_JUSTIFIED_FAILURE = "ACTION_TRIALFORM_REPORT_JUSTIFIED_FAILURE",

    ACTION_TRIALFORM_SEND_REMINDER_EMAIL = "ACTION_TRIALFORM_SEND_REMINDER_EMAIL",
    ACTION_TRIALFORM_SEND_REMINDER_EMAIL_SUCCESS = "ACTION_TRIALFORM_SEND_REMINDER_EMAIL_SUCCESS",
    ACTION_TRIALFORM_SEND_REMINDER_EMAIL_FAILURE = "ACTION_TRIALFORM_SEND_REMINDER_EMAIL_FAILURE";

export interface ITrialFormLoadAction {
    type: "ACTION_TRIALFORM_LOAD";
    trialId: number;
    institutionId: number;
}

export interface ITrialFormLoadActionCreator {
    (trialId: number, institutionId: number): ITrialFormLoadAction;
}

export interface ITrialFormLoadSuccessAction {
    type: "ACTION_TRIALFORM_LOAD_SUCCESS";
    formData: Dtos.TrialForm | Dtos.TrialForm[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialFormLoadSuccessActionCreator {
    (
        formData: Dtos.TrialForm | Dtos.TrialForm[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialFormLoadSuccessAction;
}

export interface ITrialFormLoadFailureAction {
    type: "ACTION_TRIALFORM_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialFormLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialFormLoadFailureAction;
}

export interface ITrialFormLoadByIdAction {
    type: "ACTION_TRIALFORM_LOAD_BY_ID";
    id: number;
}

export interface ITrialFormLoadByIdActionCreator {
    (id: number): ITrialFormLoadByIdAction;
}

export interface ITrialFormLoadCurrentUserAction {
    type: "ACTION_TRIALFORM_LOAD_CURRENT_USER";
}

export interface ITrialFormLoadCurrentUserActionCreator {
    (): ITrialFormLoadCurrentUserAction;
}

export interface ITrialFormSaveAction {
    type: "ACTION_TRIALFORM_SAVE";
    formData: Dtos.TrialForm;

}

export interface ITrialFormSaveActionCreator {
    (
        formData: Dtos.TrialForm
    ): ITrialFormSaveAction;
}

export interface ITrialFormSaveSuccessAction {
    type: "ACTION_TRIALFORM_SAVE_SUCCESS";
    formData: Dtos.TrialForm;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialFormSaveSuccessActionCreator {
    (
        formData: Dtos.TrialForm,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrialFormSaveSuccessAction;
}

export interface ITrialFormSaveFailureAction {
    type: "ACTION_TRIALFORM_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialFormSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialFormSaveFailureAction;
}

export interface ITrialFormCreateAction {
    type: "ACTION_TRIALFORM_CREATE",
    trialId: number,
    institutionId: number,
    formTemplateId: number
}

export interface ITrialFormCreateActionCreator {
    (trialId: number, institutionId: number, formTemplateId: number): ITrialFormCreateAction
}

export interface ITrialFormCreateSuccessAction {
    type: "ACTION_TRIALFORM_CREATE_SUCCESS";
    formData: Dtos.TrialForm;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface ITrialFormCreateSuccessActionCreator {
    (
        formData: Dtos.TrialForm,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): ITrialFormCreateSuccessAction;
}

export interface ITrialFormCreateFailureAction {
    type: "ACTION_TRIALFORM_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialFormCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialFormCreateFailureAction;
}

export interface ITrialFormCreateVersionAction {
    type: "ACTION_TRIALFORM_CREATEVERSION",
    trialFormId: number,
    version: string
    copyData: boolean
}

export interface ITrialFormCreateVersionActionCreator {
    (trialFormId: number, version: string, copyData: boolean): ITrialFormCreateVersionAction
}

export interface ITrialFormCreateVersionSuccessAction {
    type: "ACTION_TRIALFORM_CREATEVERSION_SUCCESS";
    formData: Dtos.TrialForm;
}

export interface ITrialFormCreateVersionSuccessActionCreator {
    (
        formData: Dtos.TrialForm,
    ): ITrialFormCreateVersionSuccessAction;
}

export interface ITrialFormCreateVersionFailureAction {
    type: "ACTION_TRIALFORM_CREATEVERSION_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialFormCreateVersionFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialFormCreateVersionFailureAction;
}

export interface ITrialFormClearAction {
    type: "ACTION_TRIALFORM_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrialFormClearActionCreator {
    (propertiesToClear?: string[]): ITrialFormClearAction;
}

export interface ITrialFormFilterActionCreator {
    (formFilter: IFormFilter): ITrialFormFilterAction;
}

export interface ITrialFormFilterAction {
    type: "ACTION_TRIALFORM_FILTER";
    formFilter: IFormFilter;
}

export interface ITrialFormReportLoadAction {
    type: "ACTION_TRIALFORM_REPORT_LOAD";
    id: number;
    version: string;
}

export interface ITrialFormReportLoadActionCreator {
    (id: number, version: string): ITrialFormReportLoadAction;
}

export interface ITrialFormReportLoadSuccessAction {
    type: "ACTION_TRIALFORM_REPORT_LOAD_SUCCESS";
    formData: Dtos.MeasurementReport[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialFormReportLoadSuccessActionCreator {
    (
        formData: Dtos.MeasurementReport[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialFormReportLoadSuccessAction;
}

export interface ITrialFormReportLoadFailureAction {
    type: "ACTION_TRIALFORM_REPORT_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialFormReportLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialFormReportLoadFailureAction;
}


export interface ITrialFormReportJustifiedAction {
    type: "ACTION_TRIALFORM_REPORT_JUSTIFIED";
    id: number;
    measurementReport: Dtos.MeasurementReport;
}

export interface ITrialFormReportJustifiedActionCreator {
    (id: number, measurementReport: Dtos.MeasurementReport): ITrialFormReportJustifiedAction;
}

export interface ITrialFormReportJustifiedSuccessAction {
    type: "ACTION_TRIALFORM_REPORT_JUSTIFIED_SUCCESS";
    formData: Dtos.MeasurementReport[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialFormReportJustifiedSuccessActionCreator {
    (
        formData: Dtos.MeasurementReport[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialFormReportJustifiedSuccessAction;
}

export interface ITrialFormReportJustifiedFailureAction {
    type: "ACTION_TRIALFORM_REPORT_JUSTIFIED_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialFormReportJustifiedFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialFormReportJustifiedFailureAction;
}

export interface ITrialFormSendReminderEmailAction {
    type: "ACTION_TRIALFORM_SEND_REMINDER_EMAIL";
    id: number;
}

export interface ITrialFormSendReminderEmailActionCreator {
    (id: number): ITrialFormSendReminderEmailAction;
}

export interface ITrialFormSendReminderEmailSuccessAction {
    type: "ACTION_TRIALFORM_SEND_REMINDER_EMAIL_SUCCESS";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialFormSendReminderEmailSuccessActionCreator {
    (
        responseStatus: Dtos.ResponseStatus
    ): ITrialFormSendReminderEmailSuccessAction;
}

export interface ITrialFormSendReminderEmailFailureAction {
    type: "ACTION_TRIALFORM_SEND_REMINDER_EMAIL_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialFormSendReminderEmailFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialFormSendReminderEmailFailureAction;
}

export type TrialFormAction =
    ITrialFormLoadAction |
    ITrialFormLoadCurrentUserAction |
    ITrialFormLoadByIdAction |
    ITrialFormLoadFailureAction |
    ITrialFormLoadSuccessAction |
    ITrialFormSaveAction |
    ITrialFormSaveSuccessAction |
    ITrialFormSaveFailureAction |
    ITrialFormCreateAction |
    ITrialFormCreateSuccessAction |
    ITrialFormCreateFailureAction |
    ITrialFormCreateVersionAction |
    ITrialFormCreateVersionSuccessAction |
    ITrialFormCreateVersionFailureAction |
    ITrialFormClearAction | 
    ITrialFormFilterAction |
    ITrialFormReportLoadAction |
    ITrialFormReportLoadFailureAction |
    ITrialFormReportLoadSuccessAction |
    ITrialFormReportJustifiedAction |
    ITrialFormReportJustifiedFailureAction |
    ITrialFormReportJustifiedSuccessAction |
    ITrialFormSendReminderEmailAction |
    ITrialFormSendReminderEmailFailureAction |
    ITrialFormSendReminderEmailSuccessAction;
