import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const collaboratinggroupsApi = {
    getCollaboratingGroups: (): Observable<Dtos.GetCollaboratingGroupsResponse> => {
        //Create the request.
        let request: Dtos.GetCollaboratingGroups = new Dtos.GetCollaboratingGroups();

        //Send request.
        const response: Promise<Dtos.GetCollaboratingGroupsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getCollaboratingGroupById: (id: number): Observable<Dtos.GetCollaboratingGroupByIdResponse> => {
        //Create the request.
        let request: Dtos.GetCollaboratingGroupById = new Dtos.GetCollaboratingGroupById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetCollaboratingGroupByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setCollaboratingGroup: (collaboratingGroup: Dtos.CollaboratingGroup): Observable<Dtos.SetCollaboratingGroupResponse> => {

        let request: Dtos.SetCollaboratingGroup = new Dtos.SetCollaboratingGroup();

        request.collaboratingGroup = collaboratingGroup;

        // send request
        const response: Promise<Dtos.SetCollaboratingGroupResponse> = client.post(request);

        return Observable.from(response);

    },
    createCollaboratingGroup: (): Observable<Dtos.CreateCollaboratingGroupResponse> => {
        //Create the request.
        let request: Dtos.CreateCollaboratingGroup = new Dtos.CreateCollaboratingGroup();

        //Send request.
        const response: Promise<Dtos.CreateCollaboratingGroupResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}