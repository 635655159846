import * as React from 'react';
import './TrialPresentationTypePage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { ITrialPresentationTypeReduxFormState, initialTrialPresentationTypeState } from "../../../reducers/reactReduxForms/trialPresentationType";
import { RegistrationFormComponent } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, NumberFormInput, CheckboxFormInput } from "../../../components/form/index";
import * as TrialPresentationTypeActions from '../../../actions/trialPresentationTypes';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { NotificationAlert } from "../../../components/common/index";
import { Form, actions } from 'react-redux-form';
import { TrialLookupsLayout } from '../../index';
import * as AuthenticationHelper from '../../../helpers/authentication';

interface ITrialPresentationTypePageParams {
    trialPresentationTypeId: number;
}

interface ITrialPresentationTypePageProps {

    form: Dtos.TrialPresentationType & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITrialPresentationTypeReduxFormState;

    location: Location;
    trialPresentationTypeId: number;
    match: match<ITrialPresentationTypePageParams>;
    history: History;

    // From state
    trialPresentationType: Dtos.TrialPresentationType;
    lookups: Dtos.Lookup[];

    loadingTrialPresentationType: boolean;
    loadTrialPresentationTypeSuccess: boolean;
    loadTrialPresentationTypeFailure: boolean;

    savingTrialPresentationType: boolean;
    saveTrialPresentationTypeSuccess: boolean;
    saveTrialPresentationTypeFailure: boolean;

    creatingTrialPresentationType: boolean;
    createTrialPresentationTypeSuccess: boolean;
    createTrialPresentationTypeFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface ITrialPresentationTypePageActions {
    clearTrialPresentationType: TrialPresentationTypeActions.ITrialPresentationTypeClearActionCreator;
    loadTrialPresentationType: TrialPresentationTypeActions.ITrialPresentationTypeLoadByIdActionCreator;

    saveTrialPresentationType: TrialPresentationTypeActions.ITrialPresentationTypeSaveActionCreator;
    createTrialPresentationType: TrialPresentationTypeActions.ITrialPresentationTypeCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type TrialPresentationTypePageProps = ITrialPresentationTypePageProps & ITrialPresentationTypePageActions;

const reduxFormName: string = "reduxForms.trialPresentationType";

class TrialPresentationTypePage extends React.PureComponent<TrialPresentationTypePageProps, any> {

    constructor(props: TrialPresentationTypePageProps) {
        super(props);

        this.clearTrialPresentationType = this.clearTrialPresentationType.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTrialPresentationType,
            trialPresentationTypeId,
            createTrialPresentationType
        } = this.props;

        if (trialPresentationTypeId) {
            loadTrialPresentationType(trialPresentationTypeId);
        } else if (!trialPresentationTypeId) {
            createTrialPresentationType();
        }
    }

    componentWillReceiveProps(nextProps: TrialPresentationTypePageProps) {
    }

    componentDidUpdate(prevProps: TrialPresentationTypePageProps, prevState) {
        const {
            trialPresentationTypeId,
            trialPresentationType,
            form,
            navigate,
            loadTrialPresentationType,
            loadingTrialPresentationType,
            loadTrialPresentationTypeSuccess,
            createTrialPresentationType,
            creatingTrialPresentationType,
            createTrialPresentationTypeSuccess,
            savingTrialPresentationType,
            saveTrialPresentationTypeSuccess,
            validationFailures
        } = this.props;

        if (trialPresentationTypeId && prevProps.trialPresentationTypeId != trialPresentationTypeId) {
            loadTrialPresentationType(trialPresentationTypeId);
        }
        else if (!trialPresentationTypeId && prevProps.trialPresentationTypeId) {
            createTrialPresentationType();
        }

        if (!loadingTrialPresentationType && prevProps.loadingTrialPresentationType) {
            if (loadTrialPresentationTypeSuccess) {
                this.loadForm();
            }
        }

        if (!savingTrialPresentationType && prevProps.savingTrialPresentationType) {
            if (saveTrialPresentationTypeSuccess) {
                if (validationFailures && validationFailures.length > 0) {
                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {
                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);
                }
                if (form.saveAndReturn) {
                    navigate("/settings/trial/trialPresentationTypes")
                } else if (!form.saveAndReturn && trialPresentationTypeId && trialPresentationTypeId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !trialPresentationTypeId && trialPresentationType && trialPresentationType.id > 0) {
                    navigate("/settings/trial/trialPresentationTypes/" + trialPresentationType.id);
                }
            }
            else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

        if (!creatingTrialPresentationType && prevProps.creatingTrialPresentationType) {
            if (createTrialPresentationTypeSuccess) {
                this.loadForm();
            }
        }
    }

    componentWillUpdate(nextProps: TrialPresentationTypePageProps) {
    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearTrialPresentationType();
        loadForm(reduxFormName, initialTrialPresentationTypeState);
        resetForm(reduxFormName);
    }

    clearTrialPresentationType() {
        this.props.clearTrialPresentationType();
    }

    showTrialPresentationType(): boolean {
        const {
            loadingTrialPresentationType,
            loadTrialPresentationTypeSuccess
        } = this.props

        if (!loadingTrialPresentationType && loadTrialPresentationTypeSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrialPresentationType
        } = this.props;

        return <TrialLookupsLayout loading={loadingTrialPresentationType} permission={Dtos.Permission.TrialPresentationTypesView}>
            {this.renderContent()}
        </TrialLookupsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingTrialPresentationType,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.TrialPresentationTypesUpdate, Dtos.Permission.TrialPresentationTypesCreate);
        const formDisabled: boolean = !hasPermission || savingTrialPresentationType || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            value: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            }
        };

        return <div>
            <h1>Trial Presentation Type</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "trialPresentationType", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.TrialPresentationType) {
        const {
            saveTrialPresentationType
        } = this.props;

        if (data) {
            let changes: Dtos.TrialPresentationType = {} as Dtos.TrialPresentationType;
            saveTrialPresentationType(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.TrialPresentationType) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/trial/trialPresentationTypes");
    }

    loadForm() {
        const {
            loadForm,
            trialPresentationType,
        } = this.props

        let extraData: Dtos.TrialPresentationType = {} as Dtos.TrialPresentationType;

        loadForm(reduxFormName, Object.assign({ ...trialPresentationType }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: TrialPresentationTypePageProps): ITrialPresentationTypePageProps => {

    let trialPresentationType: Dtos.TrialPresentationType | undefined = !(state.trialPresentationTypes.formData instanceof Array) ? state.trialPresentationTypes.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialPresentationTypeId: ownProps.match && ownProps.match.params.trialPresentationTypeId && ownProps.match.params.trialPresentationTypeId.toString() != "create" ? ownProps.match.params.trialPresentationTypeId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trialPresentationType: trialPresentationType!,

        loadingTrialPresentationType: state.trialPresentationTypes.loadState && state.trialPresentationTypes.loadState.status == RequestState.Pending,
        loadTrialPresentationTypeSuccess: state.trialPresentationTypes.loadState && state.trialPresentationTypes.loadState.status == RequestState.Success,
        loadTrialPresentationTypeFailure: state.trialPresentationTypes.loadState && state.trialPresentationTypes.loadState.status == RequestState.Failure,

        savingTrialPresentationType: state.trialPresentationTypes.saveState && state.trialPresentationTypes.saveState.status == RequestState.Pending,
        saveTrialPresentationTypeSuccess: state.trialPresentationTypes.saveState && state.trialPresentationTypes.saveState.status == RequestState.Success,
        saveTrialPresentationTypeFailure: state.trialPresentationTypes.saveState && state.trialPresentationTypes.saveState.status == RequestState.Failure,

        creatingTrialPresentationType: state.trialPresentationTypes.createState && state.trialPresentationTypes.createState.status == RequestState.Pending,
        createTrialPresentationTypeSuccess: state.trialPresentationTypes.createState && state.trialPresentationTypes.createState.status == RequestState.Success,
        createTrialPresentationTypeFailure: state.trialPresentationTypes.createState && state.trialPresentationTypes.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: [],

        form: state.reduxForms.trialPresentationType,
        formState: state.trialPresentationTypes.formState,
        formProperties: state.trialPresentationTypes.formProperties,

        reduxFormState: state.reduxForms.formState.trialPresentationType,
        validationFailures: trialPresentationType ?
            state.trialPresentationTypes.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): ITrialPresentationTypePageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadTrialPresentationType: bindActionCreators(TrialPresentationTypeActions.LoadTrialPresentationTypeById, dispatch),
        clearTrialPresentationType: bindActionCreators(TrialPresentationTypeActions.Clear, dispatch),

        saveTrialPresentationType: bindActionCreators(TrialPresentationTypeActions.SaveTrialPresentationType, dispatch),
        createTrialPresentationType: bindActionCreators(TrialPresentationTypeActions.CreateTrialPresentationType, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialPresentationTypePage);
