import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Tmd.dtos';

import {
    ACTION_TRIALGROUPITEM_LOAD_BY_ID,
    ACTION_TRIALGROUPITEM_LOAD_FAILURE,
    ACTION_TRIALGROUPITEM_LOAD_SUCCESS,

    ACTION_TRIALGROUPITEM_SAVE,
    ACTION_TRIALGROUPITEM_SAVE_SUCCESS,
    ACTION_TRIALGROUPITEM_SAVE_FAILURE,

    ACTION_TRIALGROUPITEM_CREATE,
    ACTION_TRIALGROUPITEM_CREATE_SUCCESS,
    ACTION_TRIALGROUPITEM_CREATE_FAILURE,

    ACTION_TRIALGROUPITEM_CLEAR,
    TrialGroupItemAction
} from '../actions/trialGroupItem';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState } from "./index";
import { updateFailureIndexToId } from "../helpers/formErrorHelper";

export interface ITrialGroupItemState {
    formData: Dtos.TrialGroupItem | Dtos.TrialGroupItem[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    validationFailures: Dtos.ResponseError[];
    lookups: Dtos.Lookup[],
    loadState: IRequestState;
    saveState: IRequestState;
    updateState: IRequestState;
    createState: IRequestState;
}

const initialState: ITrialGroupItemState = {
    formData: undefined,
    formState: undefined,
    formProperties: undefined,
    validationFailures: undefined,
    lookups: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    updateState: {
        status: RequestState.None
    } as IRequestState,
    createState: {
        status: RequestState.None
    } as IRequestState
};


function loading(state: ITrialGroupItemState, isLoading: boolean): ITrialGroupItemState {
    const newState: ITrialGroupItemState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(
    state: ITrialGroupItemState,
    formData: Dtos.TrialGroupItem | Dtos.TrialGroupItem[],
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[],
    responseStatus: Dtos.ResponseStatus
): ITrialGroupItemState {
    const newState: ITrialGroupItemState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        loadState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function loadFailure(state: ITrialGroupItemState, responseStatus: Dtos.ResponseStatus): ITrialGroupItemState {
    const newState: ITrialGroupItemState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: ITrialGroupItemState, propertiesToClear?: string[]): ITrialGroupItemState {

    if (!propertiesToClear) {
        const newState: ITrialGroupItemState = update(initialState, {});
        return newState;
    }

    let stateModifier: any = {};

    propertiesToClear.forEach(propertyToClear => {
        stateModifier[propertyToClear] = { $set: initialState[propertyToClear] }
    });

    const newState = update(state, stateModifier);

    return newState;
}

function saving(state: ITrialGroupItemState, isSaving: boolean): ITrialGroupItemState {
    const newState: ITrialGroupItemState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function saveSuccess(
    state: ITrialGroupItemState,
    formData: Dtos.TrialGroupItem | Dtos.TrialGroupItem[],
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus
): ITrialGroupItemState {
    const newState: ITrialGroupItemState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        saveState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function saveFailure(state: ITrialGroupItemState, responseStatus: Dtos.ResponseStatus): ITrialGroupItemState {
    const newState: ITrialGroupItemState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                meta: responseStatus.meta
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, state.formData) : undefined
        }
    });

    return newState;
}

function creating(state: ITrialGroupItemState, isCreating: boolean): ITrialGroupItemState {
    const newState: ITrialGroupItemState = update(state, {
        createState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function createSuccess(
    state: ITrialGroupItemState,
    formData: Dtos.TrialGroupItem,
    formState: Dtos.FormState,
    formProperties: Dtos.FormProperty[],
    lookups: Dtos.Lookup[]
): ITrialGroupItemState {
    const newState: ITrialGroupItemState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        createState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function createFailure(state: ITrialGroupItemState, responseStatus: Dtos.ResponseStatus): ITrialGroupItemState {
    const newState: ITrialGroupItemState = update(state, {
        createState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

const TrialGroupItemReducer: Reducer<ITrialGroupItemState> = (state: ITrialGroupItemState = initialState, action: TrialGroupItemAction) => {
    switch (action.type) {
        case ACTION_TRIALGROUPITEM_LOAD_BY_ID:
            return loading(state, true);
        case ACTION_TRIALGROUPITEM_LOAD_SUCCESS:
            return loadSuccess(state, action.formData, action.formState, action.formProperties, action.lookups, action.responseStatus);
        case ACTION_TRIALGROUPITEM_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_TRIALGROUPITEM_CLEAR:
            return clear(state, action.propertiesToClear);
        case ACTION_TRIALGROUPITEM_SAVE:
            return saving(state, true);
        case ACTION_TRIALGROUPITEM_SAVE_SUCCESS:
            return saveSuccess(state, action.formData, action.formState, action.responseStatus);
        case ACTION_TRIALGROUPITEM_SAVE_FAILURE:
            return saveFailure(state, action.responseStatus);
        case ACTION_TRIALGROUPITEM_CREATE:
            return creating(state, true);
        case ACTION_TRIALGROUPITEM_CREATE_SUCCESS:
            return createSuccess(state, action.formData, action.formState, action.formProperties, action.lookups);
        case ACTION_TRIALGROUPITEM_CREATE_FAILURE:
            return createFailure(state, action.responseStatus);
    }

    return state;
}

export default TrialGroupItemReducer;