import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const institutionCodingsApi = {
    getInstitutionCodings: (): Observable<Dtos.GetInstitutionCodingsResponse> => {
        //Create the request.
        let request: Dtos.GetInstitutionCodings = new Dtos.GetInstitutionCodings();

        //Send request.
        const response: Promise<Dtos.GetInstitutionCodingsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getInstitutionCodingById: (id: number): Observable<Dtos.GetInstitutionCodingByIdResponse> => {
        //Create the request.
        let request: Dtos.GetInstitutionCodingById = new Dtos.GetInstitutionCodingById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetInstitutionCodingByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setInstitutionCoding: (institutionCoding: Dtos.InstitutionCoding): Observable<Dtos.SetInstitutionCodingResponse> => {

        let request: Dtos.SetInstitutionCoding = new Dtos.SetInstitutionCoding();

        request.institutionCoding = institutionCoding;

        // send request
        const response: Promise<Dtos.SetInstitutionCodingResponse> = client.post(request);

        return Observable.from(response);

    },
    createInstitutionCoding: (): Observable<Dtos.CreateInstitutionCodingResponse> => {
        //Create the request.
        let request: Dtos.CreateInstitutionCoding = new Dtos.CreateInstitutionCoding();

        //Send request.
        const response: Promise<Dtos.CreateInstitutionCodingResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}