import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import { ACTION_HOME_LOAD,
    IHomeLoadAction,
    IHomeLoadFailureAction,
    IHomeLoadSuccessAction,
    LoadSuccess,
    LoadFailure
} from "../actions/home";
import { homeApi } from "../services/home";


export const
    getHomeEpic = (action$): Observable<IHomeLoadSuccessAction | IHomeLoadFailureAction> => {
        return action$
            .ofType(ACTION_HOME_LOAD)
            .mergeMap((action: IHomeLoadAction) => {
                return homeApi
                    .getHome()
                    .map(response =>
                        LoadSuccess(response.home)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    }
   