import * as Dtos from "../dtos/Tmd.dtos";
import { IFormFilter } from "../reducers/index";

export const
    LoadTrialInstitution: ITrialInstitutionLoadActionCreator = () => {
        return {
            type: ACTION_TRIALINSTITUTION_LOAD
        }
    },
    LoadTrialInstitutionById: ITrialInstitutionLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TRIALINSTITUTION_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ITrialInstitutionLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALINSTITUTION_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrialInstitutionLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALINSTITUTION_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTrialInstitution: ITrialInstitutionSaveActionCreator = (formData) => {
        return {
            type: ACTION_TRIALINSTITUTION_SAVE,
            formData
        }
    },
    SaveSuccess: ITrialInstitutionSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRIALINSTITUTION_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrialInstitutionSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALINSTITUTION_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTrialInstitution: ITrialInstitutionCreateActionCreator = (trialId) => {

        return {
            type: ACTION_TRIALINSTITUTION_CREATE,
            trialId
        }
    },
    CreateSuccess: ITrialInstitutionCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_TRIALINSTITUTION_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: ITrialInstitutionCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALINSTITUTION_CREATE_FAILURE,
            responseStatus
        }
    },

    Clear: ITrialInstitutionClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRIALINSTITUTION_CLEAR,
            propertiesToClear
        }
    },

    Filter: ITrialInstitutionFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRIALINSTITUTION_FILTER,
            formFilter
        }
    },
    PersonnelRolesFilter: ITrialInstitutionPersonnelRolesFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRIALINSTITUTION_PERSONNEL_ROLES_FILTER,
            formFilter
        }
    };



export const
    ACTION_TRIALINSTITUTION_LOAD = "ACTION_TRIALINSTITUTION_LOAD",
    ACTION_TRIALINSTITUTION_LOAD_BY_ID = "ACTION_TRIALINSTITUTION_LOAD_BY_ID",
    ACTION_TRIALINSTITUTION_LOAD_SUCCESS = "ACTION_TRIALINSTITUTION_LOAD_SUCCESS",
    ACTION_TRIALINSTITUTION_LOAD_FAILURE = "ACTION_TRIALINSTITUTION_LOAD_FAILURE",

    ACTION_TRIALINSTITUTION_SAVE = "ACTION_TRIALINSTITUTION_SAVE",
    ACTION_TRIALINSTITUTION_SAVE_SUCCESS = "ACTION_TRIALINSTITUTION_SAVE_SUCCESS",
    ACTION_TRIALINSTITUTION_SAVE_FAILURE = "ACTION_TRIALINSTITUTION_SAVE_FAILURE",

    ACTION_TRIALINSTITUTION_CREATE = "ACTION_TRIALINSTITUTION_CREATE",
    ACTION_TRIALINSTITUTION_CREATE_SUCCESS = "ACTION_TRIALINSTITUTION_CREATE_SUCCESS",
    ACTION_TRIALINSTITUTION_CREATE_FAILURE = "ACTION_TRIALINSTITUTION_CREATE_FAILURE",

    ACTION_TRIALINSTITUTION_CLEAR = "ACTION_TRIALINSTITUTION_CLEAR",

    ACTION_TRIALINSTITUTION_FILTER = "ACTION_TRIALINSTITUTION_FILTER",
    ACTION_TRIALINSTITUTION_PERSONNEL_ROLES_FILTER = "ACTION_TRIALINSTITUTION_PERSONNEL_ROLES_FILTER";

export interface ITrialInstitutionLoadAction {
    type: "ACTION_TRIALINSTITUTION_LOAD";
}

export interface ITrialInstitutionLoadActionCreator {
    (): ITrialInstitutionLoadAction;
}

export interface ITrialInstitutionLoadSuccessAction {
    type: "ACTION_TRIALINSTITUTION_LOAD_SUCCESS";
    formData: Dtos.TrialInstitution | Dtos.TrialInstitution[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialInstitutionLoadSuccessActionCreator {
    (
        formData: Dtos.TrialInstitution | Dtos.TrialInstitution[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialInstitutionLoadSuccessAction;
}

export interface ITrialInstitutionLoadFailureAction {
    type: "ACTION_TRIALINSTITUTION_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialInstitutionLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialInstitutionLoadFailureAction;
}

export interface ITrialInstitutionLoadByIdAction {
    type: "ACTION_TRIALINSTITUTION_LOAD_BY_ID";
    id: number;
}

export interface ITrialInstitutionLoadByIdActionCreator {
    (id: number): ITrialInstitutionLoadByIdAction;
}


export interface ITrialInstitutionSaveAction {
    type: "ACTION_TRIALINSTITUTION_SAVE";
    formData: Dtos.TrialInstitution;

}

export interface ITrialInstitutionSaveActionCreator {
    (
        formData: Dtos.TrialInstitution
    ): ITrialInstitutionSaveAction;
}

export interface ITrialInstitutionSaveSuccessAction {
    type: "ACTION_TRIALINSTITUTION_SAVE_SUCCESS";
    formData: Dtos.TrialInstitution;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialInstitutionSaveSuccessActionCreator {
    (
        formData: Dtos.TrialInstitution,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrialInstitutionSaveSuccessAction;
}

export interface ITrialInstitutionSaveFailureAction {
    type: "ACTION_TRIALINSTITUTION_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialInstitutionSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialInstitutionSaveFailureAction;
}

export interface ITrialInstitutionCreateAction {
    type: "ACTION_TRIALINSTITUTION_CREATE",
    trialId: number
}

export interface ITrialInstitutionCreateActionCreator {
    (trialId: number): ITrialInstitutionCreateAction
}

export interface ITrialInstitutionCreateSuccessAction {
    type: "ACTION_TRIALINSTITUTION_CREATE_SUCCESS";
    formData: Dtos.TrialInstitution;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface ITrialInstitutionCreateSuccessActionCreator {
    (
        formData: Dtos.TrialInstitution,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): ITrialInstitutionCreateSuccessAction;
}

export interface ITrialInstitutionCreateFailureAction {
    type: "ACTION_TRIALINSTITUTION_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialInstitutionCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialInstitutionCreateFailureAction;
}

export interface ITrialInstitutionClearAction {
    type: "ACTION_TRIALINSTITUTION_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrialInstitutionClearActionCreator {
    (propertiesToClear?: string[]): ITrialInstitutionClearAction;
}

export interface ITrialInstitutionFilterActionCreator {
    (formFilter: IFormFilter): ITrialInstitutionFilterAction;
}

export interface ITrialInstitutionFilterAction {
    type: "ACTION_TRIALINSTITUTION_FILTER";
    formFilter: IFormFilter;
}

export interface ITrialInstitutionPersonnelRolesFilterActionCreator {
    (formFilter: IFormFilter): ITrialInstitutionPersonnelRolesFilterAction;
}

export interface ITrialInstitutionPersonnelRolesFilterAction {
    type: "ACTION_TRIALINSTITUTION_PERSONNEL_ROLES_FILTER";
    formFilter: IFormFilter;
}

export type TrialInstitutionAction =
    ITrialInstitutionLoadAction |
    ITrialInstitutionLoadByIdAction |
    ITrialInstitutionLoadFailureAction |
    ITrialInstitutionLoadSuccessAction |
    ITrialInstitutionSaveAction |
    ITrialInstitutionSaveSuccessAction |
    ITrialInstitutionSaveFailureAction |
    ITrialInstitutionCreateAction |
    ITrialInstitutionCreateSuccessAction |
    ITrialInstitutionCreateFailureAction |
    ITrialInstitutionClearAction | 
    ITrialInstitutionFilterAction |
    ITrialInstitutionPersonnelRolesFilterAction;
