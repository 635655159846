import * as React from 'react';

import * as classNames from "classnames";
import './StatesPage.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';



import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";

import * as StateActions from '../../../actions/states';
import * as PagingActions from '../../../actions/pages/pagingPage';

import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput, FormFilterInput } from '../../../components/form';

import { LookupsLayout } from '../../index';

interface IStatesPageProps {

    location: Location;
    history: History;

    // From state
    formFilter: IFormFilter;
    states: Dtos.State[];
    //activeStates: Dtos.State[];
    loadingStates: boolean;
    loadStatesSuccess: boolean;
    loadStatesFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    formRoles?: Dtos.RoleType[];
}

interface IStatesPageActions {
    clearStates: StateActions.IStateClearActionCreator;
    loadStates: StateActions.IStateLoadActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    filter: StateActions.IStateFilterActionCreator;
}

type StatesPageProps = IStatesPageProps & IStatesPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}

class StatesPage extends React.PureComponent<StatesPageProps, any> {

    constructor(props: StatesPageProps) {
        super(props);

        this.clearStates = this.clearStates.bind(this);
    }

    componentDidMount() {
        const {
            loadStates
        } = this.props;

        loadStates();
    }

    componentWillReceiveProps(nextProps: StatesPageProps) {
    }

    componentDidUpdate(prevProps: StatesPageProps, prevState) {
        const {
            navigateReplace
        } = this.props
    }

    componentWillUpdate(nextProps: StatesPageProps) {
    }

    componentWillUnmount() {
        this.clearStates();
    }

    clearStates() {
        this.props.clearStates();
    }

    showStates(): boolean {
        const {
            loadingStates,
            loadStatesSuccess
        } = this.props

        if (!loadingStates && loadStatesSuccess) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingStates
        } = this.props;

        return <LookupsLayout loading={loadingStates} permission={Dtos.Permission.StatesView}>
            {this.renderContent()}
        </LookupsLayout>;
    }

    renderContent() {
        const {
            permissions
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-6 mb-2"}>
                    <h2>States</h2>
                </div>
                <div className="col-6" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e) } />
                    
                    {AuthenticationHelper.hasPermission(permissions, Dtos.Permission.StatesCreate) && (
                        <Link className={"btn btn-primary"} url={"/settings/general/states/create"}>Create</Link>
                    )}
                </div>
            </div>
            {
                !this.showStates() ?
                    "Loading States" :
                    null
            }
            {
                this.showStates() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {

        const {
            states,
            formRoles
        } = this.props

        if (!states || states.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no states to view.</span>
            </div>
        }

        const showPagination = states.length > DEFAULT_PAGE_SIZE;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={states}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : states.length}
                pageSize={showPagination ? undefined : states.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no states to display"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                                const url: string = "/settings/general/states/" + rowInfo.original.id;
                                this.props.navigate(url);

                                if (handleOriginal) {
                                    handleOriginal()
                                }
                            }
                    }
                }}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <FontAwesomeIcon icon={FontAwesomeIcons.Light.LOOKUP} fixedWidth />
                    },
                    {
                        id: "value",
                        Header: 'Name',
                        accessor: 'value',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left"
                    }
                ]}
            />
        </div>

    }

}

//const filterStatesByActive = (states: Dtos.State[], active: boolean): Dtos.State[] => {

//    if (states) {
//        return states.filter(p => p.active == active);
//    }

//    return [];
//}

//const allStates = (states: Dtos.State[]): Dtos.State[] => {

//    if (states) {
//        return states;
//    }

//    return [];
//}

const mapStateToProps = (state: ITmdState, ownProps: StatesPageProps): IStatesPageProps => {

    //const states: Dtos.State[] | undefined =
    //    state.states.formData instanceof Array ?
    //        allStates(state.states.formData) :
    //        undefined
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        history: ownProps.history,
        location: state.routing.location,

        //states: states!,
        formFilter: state.states.formFilter,
        states: state.states.formFilteredData, //filterStatesByActive(states!, true),

        loadingStates: state.states.loadState && state.states.loadState.status == RequestState.Pending,
        loadStatesSuccess: state.states.loadState && state.states.loadState.status == RequestState.Success,
        loadStatesFailure: state.states.loadState && state.states.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles
    };
};

const mapDispatchToProps = (dispatch): IStatesPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadStates: bindActionCreators(StateActions.LoadState, dispatch),
        clearStates: bindActionCreators(StateActions.Clear, dispatch),

        filter: bindActionCreators(StateActions.Filter, dispatch),
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(StatesPage);
