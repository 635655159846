import * as Dtos from '../../dtos/Tmd.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialInstitutionState: Dtos.Institution & IRegistrationFormState = {
    id: null,
    name: null,
    code: null,
    countryId: null,
    country: null,
    stateId: null,
    state: null,
    stateOther: null,
    active: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false,
    trialInstitutions: [],
    personnelRoleSummaries: [],
    display: null,
    notes: [],
    website: null,
    address: null,
    address2: null,
    suburb: null,
    postcode: null,
    mailAddress: null,
    mailAddress2: null,
    mailSuburb: null,
    mailPostcode: null,
    mailStateId: null,
    mailState: null,
    mailStateOther: null,
    mailCountryId: null,
    mailCountry: null,
    phone: null,
    fax: null,
    compactusNumber: null,
    additionalInformation: null,
    approvedForParticipation: null,
    institutionTypeId: null,
    institutionType: null,
    formerNames: null,
    otherNames: null,
    abn: null,
    siteLocationId: null,
    siteLocation: null,
    researchGovernanceOfficeName: null,
    facilityAllianceMemberId: null,
    facilityAllianceMember: null,
    keyContactId: null,
    keyContact: null,
    keyContactEmail: null,
    sourceDocuments: [],
    legalName: null,
    agreementAddressOptionId: null,
    //addressOption: null,
    contractingPartyAddress: null,
    contractingPartyAddress2: null,
    contractingPartySuburb: null,
    contractingPartyPostcode: null,
    contractingPartyStateId: null,
    contractingPartyState: null,
    contractingPartyStateOther: null,
    contractingPartyCountryId: null,
    contractingPartyCountry: null,
    acn: null,
    researchGovernanceOfficePhone: null,
    researchGovernanceOfficeWebsite: null,
    researchGovernanceOfficeEmail: null,
    researchGovernanceOfficeIsNMAScheme: null,
    acceptElectronicSignature: null,
    departments: []
}

export interface IInstitutionReduxFormState {
    id: IFieldState<number>,
    name: IFieldState<string>,
    code: IFieldState<number>,
    countryId: IFieldState<number>,
    country: IFieldState<Dtos.Country>,
    stateId: IFieldState<number>,
    state: IFieldState<Dtos.State>,
    stateOther: IFieldState<string>,
    active: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    message: IFieldState<string>,
    openedModals: IFieldState<string[]>
    trialInstitutions: IFieldState<Dtos.TrialInstitution[]>,
    personnelRoleSummaries: IFieldState<Dtos.PersonnelRoleSummary[]>,
    notes: IFieldState<Dtos.NoteTypeNotes[]>,
    website: IFieldState<string>,
    address: IFieldState<string>,
    address2: IFieldState<string>,
    suburb: IFieldState<string>,
    postcode: IFieldState<string>,
    mailAddress: IFieldState<string>,
    mailAddress2: IFieldState<string>,
    mailSuburb: IFieldState<string>,
    mailPostcode: IFieldState<string>,
    mailStateId: IFieldState<number>,
    mailStateOther: IFieldState<string>,
    mailCountryId: IFieldState<number>,
    phone: IFieldState<string>,
    fax: IFieldState<string>,
    compactusNumber: IFieldState<string>,
    additionalInformation: IFieldState<string>,
    approvedForParticipation: IFieldState<boolean>,
    institutionTypeId: IFieldState<number>,
    institutionType: IFieldState<Dtos.InstitutionType>,
    formerNames: IFieldState<string>,
    otherNames: IFieldState<string>,
    abn: IFieldState<string>,
    siteLocationId: IFieldState<number>,
    siteLocation: IFieldState<Dtos.SiteLocation>,
    researchGovernanceOfficeName: IFieldState<string>,
    facilityAllianceMemberId: IFieldState<number>,
    facilityAllianceMember: IFieldState<Dtos.FacilityAllianceMember>,
    keyContactId: IFieldState<number>,
    keyContact: IFieldState<Dtos.KeyContact>,
    keyContactEmail: IFieldState<string>,
    sourceDocuments: IFieldState<Dtos.SourceDocument[]>,
    legalName: IFieldState<string>,
    agreementAddressOptionId: IFieldState<number>,
    contractingPartyAddress: IFieldState<string>,
    contractingPartyAddress2: IFieldState<string>,
    contractingPartySuburb: IFieldState<string>,
    contractingPartyPostcode: IFieldState<string>,
    contractingPartyStateId: IFieldState<number>,
    contractingPartyStateOther: IFieldState<string>,
    contractingPartyCountryId: IFieldState<number>,
    acn: IFieldState<string>,
    researchGovernanceOfficePhone: IFieldState<string>,
    researchGovernanceOfficeWebsite: IFieldState<string>,
    researchGovernanceOfficeEmail: IFieldState<string>,
    researchGovernanceOfficeIsNMAScheme: IFieldState<number>,
    acceptElectronicSignature: IFieldState<number>,
    departments: IFieldState<Dtos.Department[]>,
}
