import * as React from 'react';
import './FormTemplateFileTypePage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { IFormTemplateFileTypeReduxFormState, initialFormTemplateFileTypeState } from "../../../reducers/reactReduxForms/formTemplateFileType";
import { RegistrationFormComponent } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, NumberFormInput, CheckboxFormInput } from "../../../components/form/index";
import * as FormTemplateFileTypeActions from '../../../actions/formTemplateFileTypes';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { NotificationAlert } from "../../../components/common/index";
import { Form, actions } from 'react-redux-form';
import { QALookupsLayout } from '../../index';
import * as AuthenticationHelper from '../../../helpers/authentication';

interface IFormTemplateFileTypePageParams {
    formTemplateFileTypeId: number;
}

interface IFormTemplateFileTypePageProps {

    form: Dtos.FormTemplateFileType & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: IFormTemplateFileTypeReduxFormState;

    location: Location;
    formTemplateFileTypeId: number;
    match: match<IFormTemplateFileTypePageParams>;
    history: History;

    // From state
    formTemplateFileType: Dtos.FormTemplateFileType;
    lookups: Dtos.Lookup[];

    loadingFormTemplateFileType: boolean;
    loadFormTemplateFileTypeSuccess: boolean;
    loadFormTemplateFileTypeFailure: boolean;

    savingFormTemplateFileType: boolean;
    saveFormTemplateFileTypeSuccess: boolean;
    saveFormTemplateFileTypeFailure: boolean;

    creatingFormTemplateFileType: boolean;
    createFormTemplateFileTypeSuccess: boolean;
    createFormTemplateFileTypeFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface IFormTemplateFileTypePageActions {
    clearFormTemplateFileType: FormTemplateFileTypeActions.IFormTemplateFileTypeClearActionCreator;
    loadFormTemplateFileType: FormTemplateFileTypeActions.IFormTemplateFileTypeLoadByIdActionCreator;

    saveFormTemplateFileType: FormTemplateFileTypeActions.IFormTemplateFileTypeSaveActionCreator;
    createFormTemplateFileType: FormTemplateFileTypeActions.IFormTemplateFileTypeCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type FormTemplateFileTypePageProps = IFormTemplateFileTypePageProps & IFormTemplateFileTypePageActions;

const reduxFormName: string = "reduxForms.formTemplateFileType";

class FormTemplateFileTypePage extends React.PureComponent<FormTemplateFileTypePageProps, any> {

    constructor(props: FormTemplateFileTypePageProps) {
        super(props);

        this.clearFormTemplateFileType = this.clearFormTemplateFileType.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadFormTemplateFileType,
            formTemplateFileTypeId,
            createFormTemplateFileType
        } = this.props;

        if (formTemplateFileTypeId) {
            loadFormTemplateFileType(formTemplateFileTypeId);
        } else if (!formTemplateFileTypeId) {
            createFormTemplateFileType();
        }
    }

    componentWillReceiveProps(nextProps: FormTemplateFileTypePageProps) {
    }

    componentDidUpdate(prevProps: FormTemplateFileTypePageProps, prevState) {
        const {
            formTemplateFileTypeId,
            formTemplateFileType,
            form,
            navigate,
            loadFormTemplateFileType,
            loadingFormTemplateFileType,
            loadFormTemplateFileTypeSuccess,
            createFormTemplateFileType,
            creatingFormTemplateFileType,
            createFormTemplateFileTypeSuccess,
            savingFormTemplateFileType,
            saveFormTemplateFileTypeSuccess,
            validationFailures
        } = this.props;

        if (formTemplateFileTypeId && prevProps.formTemplateFileTypeId != formTemplateFileTypeId) {
            loadFormTemplateFileType(formTemplateFileTypeId);
        }
        else if (!formTemplateFileTypeId && prevProps.formTemplateFileTypeId) {
            createFormTemplateFileType();
        }

        if (!loadingFormTemplateFileType && prevProps.loadingFormTemplateFileType) {
            if (loadFormTemplateFileTypeSuccess) {
                this.loadForm();
            }
        }

        if (!savingFormTemplateFileType && prevProps.savingFormTemplateFileType) {
            if (saveFormTemplateFileTypeSuccess) {
                if (validationFailures && validationFailures.length > 0) {
                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {
                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);
                }
                if (form.saveAndReturn) {
                    navigate("/settings/qa/formTemplateFileTypes")
                } else if (!form.saveAndReturn && formTemplateFileTypeId && formTemplateFileTypeId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !formTemplateFileTypeId && formTemplateFileType && formTemplateFileType.id > 0) {
                    navigate("/settings/qa/formTemplateFileTypes/" + formTemplateFileType.id);
                }
            }
            else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

        if (!creatingFormTemplateFileType && prevProps.creatingFormTemplateFileType) {
            if (createFormTemplateFileTypeSuccess) {
                this.loadForm();
            }
        }
    }

    componentWillUpdate(nextProps: FormTemplateFileTypePageProps) {
    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearFormTemplateFileType();
        loadForm(reduxFormName, initialFormTemplateFileTypeState);
        resetForm(reduxFormName);
    }

    clearFormTemplateFileType() {
        this.props.clearFormTemplateFileType();
    }

    showFormTemplateFileType(): boolean {
        const {
            loadingFormTemplateFileType,
            loadFormTemplateFileTypeSuccess
        } = this.props

        if (!loadingFormTemplateFileType && loadFormTemplateFileTypeSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingFormTemplateFileType
        } = this.props;

        return <QALookupsLayout loading={loadingFormTemplateFileType} permission={Dtos.Permission.FormTemplateFileTypesView}>
            {this.renderContent()}
        </QALookupsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingFormTemplateFileType,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.FormTemplateFileTypesUpdate, Dtos.Permission.FormTemplateFileTypesCreate);
        const formDisabled: boolean = !hasPermission || savingFormTemplateFileType || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            value: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            }
        };

        return <div>
            <h1>File Type</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "formTemplateFileType", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.FormTemplateFileType) {
        const {
            saveFormTemplateFileType
        } = this.props;

        if (data) {
            let changes: Dtos.FormTemplateFileType = {} as Dtos.FormTemplateFileType;
            saveFormTemplateFileType(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.FormTemplateFileType) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/qa/formTemplateFileTypes");
    }

    loadForm() {
        const {
            loadForm,
            formTemplateFileType,
        } = this.props

        let extraData: Dtos.FormTemplateFileType = {} as Dtos.FormTemplateFileType;

        loadForm(reduxFormName, Object.assign({ ...formTemplateFileType }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: FormTemplateFileTypePageProps): IFormTemplateFileTypePageProps => {

    let formTemplateFileType: Dtos.FormTemplateFileType | undefined = !(state.formTemplateFileTypes.formData instanceof Array) ? state.formTemplateFileTypes.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        formTemplateFileTypeId: ownProps.match && ownProps.match.params.formTemplateFileTypeId && ownProps.match.params.formTemplateFileTypeId.toString() != "create" ? ownProps.match.params.formTemplateFileTypeId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        formTemplateFileType: formTemplateFileType!,

        loadingFormTemplateFileType: state.formTemplateFileTypes.loadState && state.formTemplateFileTypes.loadState.status == RequestState.Pending,
        loadFormTemplateFileTypeSuccess: state.formTemplateFileTypes.loadState && state.formTemplateFileTypes.loadState.status == RequestState.Success,
        loadFormTemplateFileTypeFailure: state.formTemplateFileTypes.loadState && state.formTemplateFileTypes.loadState.status == RequestState.Failure,

        savingFormTemplateFileType: state.formTemplateFileTypes.saveState && state.formTemplateFileTypes.saveState.status == RequestState.Pending,
        saveFormTemplateFileTypeSuccess: state.formTemplateFileTypes.saveState && state.formTemplateFileTypes.saveState.status == RequestState.Success,
        saveFormTemplateFileTypeFailure: state.formTemplateFileTypes.saveState && state.formTemplateFileTypes.saveState.status == RequestState.Failure,

        creatingFormTemplateFileType: state.formTemplateFileTypes.createState && state.formTemplateFileTypes.createState.status == RequestState.Pending,
        createFormTemplateFileTypeSuccess: state.formTemplateFileTypes.createState && state.formTemplateFileTypes.createState.status == RequestState.Success,
        createFormTemplateFileTypeFailure: state.formTemplateFileTypes.createState && state.formTemplateFileTypes.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: [],

        form: state.reduxForms.formTemplateFileType,
        formState: state.formTemplateFileTypes.formState,
        formProperties: state.formTemplateFileTypes.formProperties,

        reduxFormState: state.reduxForms.formState.formTemplateFileType,
        validationFailures: formTemplateFileType ?
            state.formTemplateFileTypes.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): IFormTemplateFileTypePageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadFormTemplateFileType: bindActionCreators(FormTemplateFileTypeActions.LoadFormTemplateFileTypeById, dispatch),
        clearFormTemplateFileType: bindActionCreators(FormTemplateFileTypeActions.Clear, dispatch),

        saveFormTemplateFileType: bindActionCreators(FormTemplateFileTypeActions.SaveFormTemplateFileType, dispatch),
        createFormTemplateFileType: bindActionCreators(FormTemplateFileTypeActions.CreateFormTemplateFileType, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(FormTemplateFileTypePage);
