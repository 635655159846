import * as React from 'react';
import './TrialInstitutionsPage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import ReactTable, { Column, RowInfo } from 'react-table';
import * as ReactTooltip from 'react-tooltip';
import * as TrialActions from '../../../actions/trial';
import * as TrialInstitutionActions from '../../../actions/trialInstitution';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState, IFormFilter } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup, findLookupValue } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import * as AuthenticationHelper from '../../../helpers/authentication';
import { TextAreaFormInput, FormFilterInput } from '../../../components/form';
import { TrialLayout } from '../../index';

interface ITrialInstitutionsPageParams {
    trialId: number;
}

interface ITrialInstitutionsPageProps {

    location: Location;
    trialId: number;
    match: match<ITrialInstitutionsPageParams>;
    history: History;

    // From state
    formFilter: IFormFilter;
    trial: Dtos.Trial;
    trialRoles: Dtos.RoleType[];
    trialInstitutions: Dtos.TrialInstitution[],
    loadingTrial: boolean;
    loadTrialSuccess: boolean;
    loadTrialFailure: boolean;

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
    lookups: Dtos.Lookup[];
    formRoles: Dtos.RoleType[];
}

interface ITrialInstitutionsPageActions {
    clearTrial: TrialActions.ITrialClearActionCreator;
    loadTrial: TrialActions.ITrialLoadByIdActionCreator;

    filter: TrialInstitutionActions.ITrialInstitutionFilterActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type TrialInstitutionsPageProps = ITrialInstitutionsPageProps & ITrialInstitutionsPageActions;

class TrialInstitutionsPage extends React.PureComponent<TrialInstitutionsPageProps, any> {

    constructor(props: TrialInstitutionsPageProps) {
        super(props);

        this.clearTrial = this.clearTrial.bind(this);
    }

    componentDidMount() {
        const {
            loadTrial,
            trialId
        } = this.props;

        loadTrial(trialId);
    }

    componentWillUnmount() {
        this.clearTrial();
    }

    clearTrial() {
        this.props.clearTrial();
    }

    showTrial(): boolean {
        const {
            loadingTrial,
            loadTrialSuccess
        } = this.props

        if (!loadingTrial && loadTrialSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrial
        } = this.props

        return (
            <TrialLayout loading={loadingTrial} permission={Dtos.Permission.TrialInstitutionView}>{this.renderContent()}</TrialLayout>
        );
    }

    renderContent() {
        const {
            trial,
            permissions,
            trialId
        } = this.props;

        return <div>
            <div className="row">
                <div className={"col-6 mb-2"}>
                    <h2>Trial Institutions</h2>
                </div>
                <div className="col-6" style={{ textAlign: 'right' }}>
                    <FormFilterInput formFilter={this.props.formFilter} onFilter={e => this.props.filter(e)} />
                    {(AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialInstitutionCreate, trialId, null) && trial && trial.id > 0) && (
                        <Link className={"btn btn-primary"} url={"/trials/" + trial.id + '/trialinstitution/create/details'}>Add Institution</Link>
                    )}
                </div>
            </div>
            {
                !this.showTrial() ?
                    "Loading Trial Institutions" :
                    null
            }
            {
                this.showTrial() ?
                    <div className="patient-tables mt-2">
                        {
                            this.createTable()
                        }
                    </div> :
                    null
            }
        </div>
    }

    createTable() {
        const {
            trialInstitutions,
            permissions,
            lookups,
        } = this.props

        if (!trialInstitutions || trialInstitutions.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no trial groups</span>
            </div>
        }

        const showPagination = trialInstitutions.length > DEFAULT_PAGE_SIZE;

        return <div className="table-wrapper mb-3">
            <ReactTable
                data={trialInstitutions}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : trialInstitutions.length}
                pageSize={showPagination ? undefined : trialInstitutions.length}
                showPagination={showPagination}
                className="-striped -highlight -clickable mb-3"
                noDataText="There are currently no trial groups to display"
                resizable={false}
                getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            if (column.id == "personnelCount" && AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialPersonnelRoleView, rowInfo.original.trialId, rowInfo.original.institutionId)) {
                                const url: string = "/trials/" + rowInfo.original.trialId + "/trialinstitution/" + rowInfo.original.id + "/personnel";
                                this.props.navigate(url);
                            } else if (AuthenticationHelper.hasPermission(permissions, Dtos.Permission.TrialInstitutionView, rowInfo.original.trialId, rowInfo.original.institutionId)) {
                                const url: string = "/trials/" + rowInfo.original.trialId + "/trialinstitution/" + rowInfo.original.id + "/details";
                                this.props.navigate(url);
                            }


                            if (handleOriginal) {
                                handleOriginal()
                            }

                        }
                    }
                }}
                defaultSorted={[
                    {
                        id: "name",
                        desc: false
                    }
                ]}
                columns={[
                    {
                        id: "id",
                        Header: ' ',
                        accessor: "id",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 50,
                        Cell: (props) => <div className="large-small-icon"><FontAwesomeIcon className="large-icon" icon={FontAwesomeIcons.Light.TRIAL} fixedWidth />{ (props.original.parentInstitutionId && props.original.parentInstitutionId > 0) ? <FontAwesomeIcon className="medium-icon" icon={FontAwesomeIcons.Light.INSTITUTION} fixedWidth /> : <></> }<FontAwesomeIcon className="small-icon" icon={FontAwesomeIcons.Light.INSTITUTION} fixedWidth /></div>
                    },
                    {
                        id: "name",
                        Header: 'Name',
                        accessor: 'institution.name',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        Cell: (props) => (props.original.parentInstitutionId && props.original.parentInstitutionId > 0) ? (<span>{props.value}&nbsp;<small className="text-muted">primary site&nbsp;<i className="text-muted">{props.original.parentInstitution.name}</i></small></span>) : (<span>{props.value}</span>)
                    },
                    {
                        id: "code",
                        Header: 'Code',
                        accessor: 'institution.code',
                        className: "d-flex align-items-left justify-content-left",
                        headerClassName: "text-left",
                        maxWidth: 100
                    },
                    {
                        id: "trialInstitutionStatusId",
                        Header: 'Status',
                        accessor: 'trialInstitutionStatusId',
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 180,
                        Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{findLookupValue("TrialInstitution", "TrialInstitutionStatusId", props.value, lookups)}</span><ReactTooltip /></div>
                    },
                    {
                        id: "personnelCount",
                        Header: 'Roles',
                        accessor: 'personnelCount',
                        maxWidth: 80,
                        className: "d-flex align-items-center justify-content-center",
                        Cell: (props) => <div><span className="text-info"><u>{props.value || "0"}</u></span></div>
                    },
                    {
                        id: "dateActivated",
                        Header: "Date Activated",
                        accessor: "dateActivated",
                        className: "d-flex align-items-center justify-content-center",
                        maxWidth: 180,
                        Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                    }
                ]}
            />
        </div>

    }


}


const trialInstitutionActiveFilter = (items: Dtos.TrialInstitution[], filter: IFormFilter): Dtos.TrialInstitution[] => {

    if (items && filter) {
        if (!filter.showInActive) {
            items = items.filter(f => f.active && f.institution.active);
        }
        if (filter.search.length > 0) {
            items = items.filter(f => f.institutionDisplay.toLowerCase().indexOf(filter.search.toLowerCase()) > -1);
        }
        return items;
    }

    return [];
}


const mapStateToProps = (state: ITmdState, ownProps: TrialInstitutionsPageProps): ITrialInstitutionsPageProps => {

    let trial: Dtos.Trial | undefined = !(state.trials.formData instanceof Array) ? state.trials.formData : undefined;
    let trialInstitutions: Dtos.TrialInstitution[] | undefined = trial ? trial.trialInstitutions : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialId: ownProps.match ? ownProps.match.params.trialId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        formFilter: state.trialInstitution.formFilter,
        trial: trial!,
        trialRoles: state.trials.formRoles,
        trialInstitutions: trialInstitutionActiveFilter(trialInstitutions!, state.trialInstitution.formFilter!),
        lookups: state.trials.lookups,

        loadingTrial: state.trials.loadState && state.trials.loadState.status == RequestState.Pending,
        loadTrialSuccess: state.trials.loadState && state.trials.loadState.status == RequestState.Success,
        loadTrialFailure: state.trials.loadState && state.trials.loadState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        formRoles: state.user.formRoles,
    };
};

const mapDispatchToProps = (dispatch): ITrialInstitutionsPageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        filter: bindActionCreators(TrialInstitutionActions.Filter, dispatch),

        loadTrial: bindActionCreators(TrialActions.LoadTrialById, dispatch),
        clearTrial: bindActionCreators(TrialActions.Clear, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialInstitutionsPage);
