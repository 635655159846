import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Tmd.dtos";

export const searchApi = {
    search: (searchFor: string): Observable<Dtos.SearchResponse> => {

        let request: Dtos.Search = new Dtos.Search();

        request.searchFor = searchFor;

        // send request
        const response: Promise<Dtos.SearchResponse> = client.post(request);

        return Observable.from(response);

    }
}