import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadTrackerType: ITrackerTypeLoadActionCreator = () => {
        return {
            type: ACTION_TRACKERTYPE_LOAD
        }
    },
    LoadTrackerTypeById: ITrackerTypeLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TRACKERTYPE_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ITrackerTypeLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_TRACKERTYPE_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrackerTypeLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRACKERTYPE_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTrackerType: ITrackerTypeSaveActionCreator = (formData) => {
        return {
            type: ACTION_TRACKERTYPE_SAVE,
            formData
        }
    },
    SaveSuccess: ITrackerTypeSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRACKERTYPE_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrackerTypeSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRACKERTYPE_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTrackerType: ITrackerTypeCreateActionCreator = () => {

        return {
            type: ACTION_TRACKERTYPE_CREATE
        }
    },
    CreateSuccess: ITrackerTypeCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_TRACKERTYPE_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: ITrackerTypeCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRACKERTYPE_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: ITrackerTypeClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRACKERTYPE_CLEAR,
            propertiesToClear
        }
    },
    Filter: ITrackerTypeFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRACKERTYPE_FILTER,
            formFilter
        }
    };



export const
    ACTION_TRACKERTYPE_LOAD = "ACTION_TRACKERTYPE_LOAD",
    ACTION_TRACKERTYPE_LOAD_BY_ID = "ACTION_TRACKERTYPE_LOAD_BY_ID",
    ACTION_TRACKERTYPE_LOAD_SUCCESS = "ACTION_TRACKERTYPE_LOAD_SUCCESS",
    ACTION_TRACKERTYPE_LOAD_FAILURE = "ACTION_TRACKERTYPE_LOAD_FAILURE",

    ACTION_TRACKERTYPE_SAVE = "ACTION_TRACKERTYPE_SAVE",
    ACTION_TRACKERTYPE_SAVE_SUCCESS = "ACTION_TRACKERTYPE_SAVE_SUCCESS",
    ACTION_TRACKERTYPE_SAVE_FAILURE = "ACTION_TRACKERTYPE_SAVE_FAILURE",

    ACTION_TRACKERTYPE_CREATE = "ACTION_TRACKERTYPE_CREATE",
    ACTION_TRACKERTYPE_CREATE_SUCCESS = "ACTION_TRACKERTYPE_CREATE_SUCCESS",
    ACTION_TRACKERTYPE_CREATE_FAILURE = "ACTION_TRACKERTYPE_CREATE_FAILURE",

    ACTION_TRACKERTYPE_CLEAR = "ACTION_TRACKERTYPE_CLEAR",

    ACTION_TRACKERTYPE_FILTER = "ACTION_TRACKERTYPE_FILTER";

export interface ITrackerTypeLoadAction {
    type: "ACTION_TRACKERTYPE_LOAD";
}

export interface ITrackerTypeLoadActionCreator {
    (): ITrackerTypeLoadAction;
}

export interface ITrackerTypeLoadSuccessAction {
    type: "ACTION_TRACKERTYPE_LOAD_SUCCESS";
    formData: Dtos.TrackerType | Dtos.TrackerType[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrackerTypeLoadSuccessActionCreator {
    (
        formData: Dtos.TrackerType | Dtos.TrackerType[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrackerTypeLoadSuccessAction;
}

export interface ITrackerTypeLoadFailureAction {
    type: "ACTION_TRACKERTYPE_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrackerTypeLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrackerTypeLoadFailureAction;
}

export interface ITrackerTypeLoadByIdAction {
    type: "ACTION_TRACKERTYPE_LOAD_BY_ID";
    id: number;
}

export interface ITrackerTypeLoadByIdActionCreator {
    (id: number): ITrackerTypeLoadByIdAction;
}


export interface ITrackerTypeSaveAction {
    type: "ACTION_TRACKERTYPE_SAVE";
    formData: Dtos.TrackerType;

}

export interface ITrackerTypeSaveActionCreator {
    (
        formData: Dtos.TrackerType
    ): ITrackerTypeSaveAction;
}

export interface ITrackerTypeSaveSuccessAction {
    type: "ACTION_TRACKERTYPE_SAVE_SUCCESS";
    formData: Dtos.TrackerType;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrackerTypeSaveSuccessActionCreator {
    (
        formData: Dtos.TrackerType,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrackerTypeSaveSuccessAction;
}

export interface ITrackerTypeSaveFailureAction {
    type: "ACTION_TRACKERTYPE_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrackerTypeSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrackerTypeSaveFailureAction;
}

export interface ITrackerTypeCreateAction {
    type: "ACTION_TRACKERTYPE_CREATE"
}

export interface ITrackerTypeCreateActionCreator {
    (): ITrackerTypeCreateAction
}

export interface ITrackerTypeCreateSuccessAction {
    type: "ACTION_TRACKERTYPE_CREATE_SUCCESS";
    formData: Dtos.TrackerType;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface ITrackerTypeCreateSuccessActionCreator {
    (
        formData: Dtos.TrackerType,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): ITrackerTypeCreateSuccessAction;
}

export interface ITrackerTypeCreateFailureAction {
    type: "ACTION_TRACKERTYPE_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrackerTypeCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrackerTypeCreateFailureAction;
}

export interface ITrackerTypeClearAction {
    type: "ACTION_TRACKERTYPE_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrackerTypeClearActionCreator {
    (propertiesToClear?: string[]): ITrackerTypeClearAction;
}

export interface ITrackerTypeFilterActionCreator {
    (formFilter: IFormFilter): ITrackerTypeFilterAction;
}

export interface ITrackerTypeFilterAction {
    type: "ACTION_TRACKERTYPE_FILTER";
    formFilter: IFormFilter;
}

export type TrackerTypeAction =
    ITrackerTypeLoadAction |
    ITrackerTypeLoadByIdAction |
    ITrackerTypeLoadFailureAction |
    ITrackerTypeLoadSuccessAction |
    ITrackerTypeSaveAction |
    ITrackerTypeSaveSuccessAction |
    ITrackerTypeSaveFailureAction |
    ITrackerTypeCreateAction |
    ITrackerTypeCreateSuccessAction |
    ITrackerTypeCreateFailureAction |
    ITrackerTypeClearAction |
    ITrackerTypeFilterAction;
