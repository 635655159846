import * as React from 'react';
import './TrialPublicationTypePage.scss';
import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { ITrialPublicationTypeReduxFormState, initialTrialPublicationTypeState } from "../../../reducers/reactReduxForms/trialPublicationType";
import { RegistrationFormComponent } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent } from "../../../helpers/formHelper";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { TextFormInput, NumberFormInput, CheckboxFormInput } from "../../../components/form/index";
import * as TrialPublicationTypeActions from '../../../actions/trialPublicationTypes';
import * as Dtos from '../../../dtos/Tmd.dtos';
import { ITmdState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { NotificationAlert } from "../../../components/common/index";
import { Form, actions } from 'react-redux-form';
import { TrialLookupsLayout } from '../../index';
import * as AuthenticationHelper from '../../../helpers/authentication';

interface ITrialPublicationTypePageParams {
    trialPublicationTypeId: number;
}

interface ITrialPublicationTypePageProps {

    form: Dtos.TrialPublicationType & IRegistrationFormState;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    reduxFormState: ITrialPublicationTypeReduxFormState;

    location: Location;
    trialPublicationTypeId: number;
    match: match<ITrialPublicationTypePageParams>;
    history: History;

    // From state
    trialPublicationType: Dtos.TrialPublicationType;
    lookups: Dtos.Lookup[];

    loadingTrialPublicationType: boolean;
    loadTrialPublicationTypeSuccess: boolean;
    loadTrialPublicationTypeFailure: boolean;

    savingTrialPublicationType: boolean;
    saveTrialPublicationTypeSuccess: boolean;
    saveTrialPublicationTypeFailure: boolean;

    creatingTrialPublicationType: boolean;
    createTrialPublicationTypeSuccess: boolean;
    createTrialPublicationTypeFailure: boolean;

    validationFailures: Dtos.ResponseError[];

    user: Dtos.User;
    permissions?: Dtos.PersonnelTmdPermissions[];
}

interface ITrialPublicationTypePageActions {
    clearTrialPublicationType: TrialPublicationTypeActions.ITrialPublicationTypeClearActionCreator;
    loadTrialPublicationType: TrialPublicationTypeActions.ITrialPublicationTypeLoadByIdActionCreator;

    saveTrialPublicationType: TrialPublicationTypeActions.ITrialPublicationTypeSaveActionCreator;
    createTrialPublicationType: TrialPublicationTypeActions.ITrialPublicationTypeCreateActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type TrialPublicationTypePageProps = ITrialPublicationTypePageProps & ITrialPublicationTypePageActions;

const reduxFormName: string = "reduxForms.trialPublicationType";

class TrialPublicationTypePage extends React.PureComponent<TrialPublicationTypePageProps, any> {

    constructor(props: TrialPublicationTypePageProps) {
        super(props);

        this.clearTrialPublicationType = this.clearTrialPublicationType.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadTrialPublicationType,
            trialPublicationTypeId,
            createTrialPublicationType
        } = this.props;

        if (trialPublicationTypeId) {
            loadTrialPublicationType(trialPublicationTypeId);
        } else if (!trialPublicationTypeId) {
            createTrialPublicationType();
        }
    }

    componentWillReceiveProps(nextProps: TrialPublicationTypePageProps) {
    }

    componentDidUpdate(prevProps: TrialPublicationTypePageProps, prevState) {
        const {
            trialPublicationTypeId,
            trialPublicationType,
            form,
            navigate,
            loadTrialPublicationType,
            loadingTrialPublicationType,
            loadTrialPublicationTypeSuccess,
            createTrialPublicationType,
            creatingTrialPublicationType,
            createTrialPublicationTypeSuccess,
            savingTrialPublicationType,
            saveTrialPublicationTypeSuccess,
            validationFailures
        } = this.props;

        if (trialPublicationTypeId && prevProps.trialPublicationTypeId != trialPublicationTypeId) {
            loadTrialPublicationType(trialPublicationTypeId);
        }
        else if (!trialPublicationTypeId && prevProps.trialPublicationTypeId) {
            createTrialPublicationType();
        }

        if (!loadingTrialPublicationType && prevProps.loadingTrialPublicationType) {
            if (loadTrialPublicationTypeSuccess) {
                this.loadForm();
            }
        }

        if (!savingTrialPublicationType && prevProps.savingTrialPublicationType) {
            if (saveTrialPublicationTypeSuccess) {
                if (validationFailures && validationFailures.length > 0) {
                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                } else {
                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);
                }
                if (form.saveAndReturn) {
                    navigate("/settings/trial/trialPublicationTypes")
                } else if (!form.saveAndReturn && trialPublicationTypeId && trialPublicationTypeId > 0) {
                    this.loadForm();
                } else if (!form.saveAndReturn && !trialPublicationTypeId && trialPublicationType && trialPublicationType.id > 0) {
                    navigate("/settings/trial/trialPublicationTypes/" + trialPublicationType.id);
                }
            }
            else {
                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );
            }
        }

        if (!creatingTrialPublicationType && prevProps.creatingTrialPublicationType) {
            if (createTrialPublicationTypeSuccess) {
                this.loadForm();
            }
        }
    }

    componentWillUpdate(nextProps: TrialPublicationTypePageProps) {
    }

    componentWillUnmount() {
        const {
            resetForm,
            loadForm
        } = this.props

        this.clearTrialPublicationType();
        loadForm(reduxFormName, initialTrialPublicationTypeState);
        resetForm(reduxFormName);
    }

    clearTrialPublicationType() {
        this.props.clearTrialPublicationType();
    }

    showTrialPublicationType(): boolean {
        const {
            loadingTrialPublicationType,
            loadTrialPublicationTypeSuccess
        } = this.props

        if (!loadingTrialPublicationType && loadTrialPublicationTypeSuccess) {
            return true;
        }

        return false;
    }

    render() {
        const {
            loadingTrialPublicationType
        } = this.props;

        return <TrialLookupsLayout loading={loadingTrialPublicationType} permission={Dtos.Permission.TrialPublicationTypesView}>
            {this.renderContent()}
        </TrialLookupsLayout>;
    }

    renderContent() {
        const {
            form,
            lookups,
            validationFailures,
            formProperties,
            formState,
            changeForm,
            savingTrialPublicationType,
            permissions
        } = this.props;

        const hasPermission: boolean = AuthenticationHelper.hasCreateEditPermission(form, permissions, Dtos.Permission.TrialPublicationTypesUpdate, Dtos.Permission.TrialPublicationTypesCreate);
        const formDisabled: boolean = !hasPermission || savingTrialPublicationType || (formState && formState.formStatus == Dtos.FormStatus.Disabled);

        let formComponents: { [index: string]: RegistrationFormComponent } = {
            value: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            rank: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
            },
            active: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Active"
                },
                inputLabel: " ",
            }
        };

        return <div>
            <h1>Trial Publication Type</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "trialPublicationType", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>

        </div>

    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleOnSubmit(data: Dtos.TrialPublicationType) {
        const {
            saveTrialPublicationType
        } = this.props;

        if (data) {
            let changes: Dtos.TrialPublicationType = {} as Dtos.TrialPublicationType;
            saveTrialPublicationType(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.TrialPublicationType) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/settings/trial/trialPublicationTypes");
    }

    loadForm() {
        const {
            loadForm,
            trialPublicationType,
        } = this.props

        let extraData: Dtos.TrialPublicationType = {} as Dtos.TrialPublicationType;

        loadForm(reduxFormName, Object.assign({ ...trialPublicationType }, extraData));
    }

}


const mapStateToProps = (state: ITmdState, ownProps: TrialPublicationTypePageProps): ITrialPublicationTypePageProps => {

    let trialPublicationType: Dtos.TrialPublicationType | undefined = !(state.trialPublicationTypes.formData instanceof Array) ? state.trialPublicationTypes.formData : undefined;
    let permissions = state.user.userSummary ? state.user.userSummary.permissions : [];

    return {

        match: ownProps.match,
        trialPublicationTypeId: ownProps.match && ownProps.match.params.trialPublicationTypeId && ownProps.match.params.trialPublicationTypeId.toString() != "create" ? ownProps.match.params.trialPublicationTypeId : undefined!,

        history: ownProps.history,
        location: state.routing.location,

        trialPublicationType: trialPublicationType!,

        loadingTrialPublicationType: state.trialPublicationTypes.loadState && state.trialPublicationTypes.loadState.status == RequestState.Pending,
        loadTrialPublicationTypeSuccess: state.trialPublicationTypes.loadState && state.trialPublicationTypes.loadState.status == RequestState.Success,
        loadTrialPublicationTypeFailure: state.trialPublicationTypes.loadState && state.trialPublicationTypes.loadState.status == RequestState.Failure,

        savingTrialPublicationType: state.trialPublicationTypes.saveState && state.trialPublicationTypes.saveState.status == RequestState.Pending,
        saveTrialPublicationTypeSuccess: state.trialPublicationTypes.saveState && state.trialPublicationTypes.saveState.status == RequestState.Success,
        saveTrialPublicationTypeFailure: state.trialPublicationTypes.saveState && state.trialPublicationTypes.saveState.status == RequestState.Failure,

        creatingTrialPublicationType: state.trialPublicationTypes.createState && state.trialPublicationTypes.createState.status == RequestState.Pending,
        createTrialPublicationTypeSuccess: state.trialPublicationTypes.createState && state.trialPublicationTypes.createState.status == RequestState.Success,
        createTrialPublicationTypeFailure: state.trialPublicationTypes.createState && state.trialPublicationTypes.createState.status == RequestState.Failure,

        user: state.user.data,
        permissions: permissions,
        lookups: [],

        form: state.reduxForms.trialPublicationType,
        formState: state.trialPublicationTypes.formState,
        formProperties: state.trialPublicationTypes.formProperties,

        reduxFormState: state.reduxForms.formState.trialPublicationType,
        validationFailures: trialPublicationType ?
            state.trialPublicationTypes.validationFailures :
            undefined,
    };
};

const mapDispatchToProps = (dispatch): ITrialPublicationTypePageActions => {
    return {
        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        loadTrialPublicationType: bindActionCreators(TrialPublicationTypeActions.LoadTrialPublicationTypeById, dispatch),
        clearTrialPublicationType: bindActionCreators(TrialPublicationTypeActions.Clear, dispatch),

        saveTrialPublicationType: bindActionCreators(TrialPublicationTypeActions.SaveTrialPublicationType, dispatch),
        createTrialPublicationType: bindActionCreators(TrialPublicationTypeActions.CreateTrialPublicationType, dispatch),

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(TrialPublicationTypePage);
