import * as Dtos from "../../dtos/Tmd.dtos";

export const
    setAddModalViewState: ITrialItemInstancesPageSetAddModalViewStateActionCreator = (open) => {
        return {
            type: ACTION_TRIALITEMINSTANCES_PAGE_SET_ADD_MODAL_VIEW_STATE,
            open
        }
    },
    setEditModalViewState: ITrialItemInstancesPageSetEditModalViewStateActionCreator = (open, collectionInstanceId, name, description) => {
        return {
            type: ACTION_TRIALITEMINSTANCES_PAGE_SET_EDIT_MODAL_VIEW_STATE,
            open,
            collectionInstanceId,
            name,
            description
        }
    },
    setCreateVersionModalViewState: ITrialItemInstancesPageSetCreateVersionModalViewStateActionCreator = (open, trialId, templateTrialItemId, instanceNumber) => {
        return {
            type: ACTION_TRIALITEMINSTANCES_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE,
            open,
            trialId,
            templateTrialItemId,
            instanceNumber
        }
    }

export const
    ACTION_TRIALITEMINSTANCES_PAGE_SET_ADD_MODAL_VIEW_STATE = "ACTION_TRIALITEMINSTANCES_PAGE_SET_ADD_MODAL_VIEW_STATE",
    ACTION_TRIALITEMINSTANCES_PAGE_SET_EDIT_MODAL_VIEW_STATE = "ACTION_TRIALITEMINSTANCES_PAGE_SET_EDIT_MODAL_VIEW_STATE",
    ACTION_TRIALITEMINSTANCES_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE = "ACTION_TRIALITEMINSTANCES_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE"

/* Actions */

export interface ITrialItemInstancesPageSetAddModalViewStateAction {
    type: "ACTION_TRIALITEMINSTANCES_PAGE_SET_ADD_MODAL_VIEW_STATE",
    open: boolean;
}

export interface ITrialItemInstancesPageSetEditModalViewStateAction {
    type: "ACTION_TRIALITEMINSTANCES_PAGE_SET_EDIT_MODAL_VIEW_STATE",
    open: boolean;
    collectionInstanceId: number;
    name: string;
    description: string;
}


export interface ITrialItemInstancesPageSetCreateVersionModalViewStateAction {
    type: "ACTION_TRIALITEMINSTANCES_PAGE_SET_CREATEVERSION_MODAL_VIEW_STATE",
    open: boolean;
    trialId: number;
    templateTrialItemId: number;
    instanceNumber: number;
}

/* Action Creators */

export interface ITrialItemInstancesPageSetAddModalViewStateActionCreator {
    (
        open: boolean,
    ): ITrialItemInstancesPageSetAddModalViewStateAction;
}

export interface ITrialItemInstancesPageSetEditModalViewStateActionCreator {
    (
        open: boolean,
        collectionIntanceId: number,
        name: string,
        description: string
    ): ITrialItemInstancesPageSetEditModalViewStateAction;
}

export interface ITrialItemInstancesPageSetCreateVersionModalViewStateActionCreator {
    (
        open: boolean,
        trialId: number,
        templateTrialItemId: number,
        instanceNumber: number
    ): ITrialItemInstancesPageSetCreateVersionModalViewStateAction;
}


/* Final Export of actions */
export type TrialItemInstancesPageAction =
    ITrialItemInstancesPageSetAddModalViewStateAction |
    ITrialItemInstancesPageSetEditModalViewStateAction |
    ITrialItemInstancesPageSetCreateVersionModalViewStateAction;
