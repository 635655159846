import * as Dtos from "../dtos/Tmd.dtos";
import {  IFormFilter } from "../reducers/index";

export const
    LoadTrialPublicationType: ITrialPublicationTypeLoadActionCreator = () => {
        return {
            type: ACTION_TRIALPUBLICATIONTYPES_LOAD
        }
    },
    LoadTrialPublicationTypeById: ITrialPublicationTypeLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_TRIALPUBLICATIONTYPES_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: ITrialPublicationTypeLoadSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups, responseStatus) => {
        return {
            type: ACTION_TRIALPUBLICATIONTYPES_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups,
            responseStatus
        }
    },
    LoadFailure: ITrialPublicationTypeLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALPUBLICATIONTYPES_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveTrialPublicationType: ITrialPublicationTypeSaveActionCreator = (formData) => {
        return {
            type: ACTION_TRIALPUBLICATIONTYPES_SAVE,
            formData
        }
    },
    SaveSuccess: ITrialPublicationTypeSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_TRIALPUBLICATIONTYPES_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus
        }
    },
    SaveFailure: ITrialPublicationTypeSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALPUBLICATIONTYPES_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateTrialPublicationType: ITrialPublicationTypeCreateActionCreator = () => {

        return {
            type: ACTION_TRIALPUBLICATIONTYPES_CREATE
        }
    },
    CreateSuccess: ITrialPublicationTypeCreateSuccessActionCreator = (formData, formState, formProperties, formRoles, lookups) => {
        return {
            type: ACTION_TRIALPUBLICATIONTYPES_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            formRoles,
            lookups
        }
    },
    CreateFailure: ITrialPublicationTypeCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIALPUBLICATIONTYPES_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: ITrialPublicationTypeClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRIALPUBLICATIONTYPES_CLEAR,
            propertiesToClear
        }
    },
    Filter: ITrialPublicationTypeFilterActionCreator = (formFilter) => {
        return {
            type: ACTION_TRIALPUBLICATIONTYPES_FILTER,
            formFilter
        }
    };



export const
    ACTION_TRIALPUBLICATIONTYPES_LOAD = "ACTION_TRIALPUBLICATIONTYPES_LOAD",
    ACTION_TRIALPUBLICATIONTYPES_LOAD_BY_ID = "ACTION_TRIALPUBLICATIONTYPES_LOAD_BY_ID",
    ACTION_TRIALPUBLICATIONTYPES_LOAD_SUCCESS = "ACTION_TRIALPUBLICATIONTYPES_LOAD_SUCCESS",
    ACTION_TRIALPUBLICATIONTYPES_LOAD_FAILURE = "ACTION_TRIALPUBLICATIONTYPES_LOAD_FAILURE",

    ACTION_TRIALPUBLICATIONTYPES_SAVE = "ACTION_TRIALPUBLICATIONTYPES_SAVE",
    ACTION_TRIALPUBLICATIONTYPES_SAVE_SUCCESS = "ACTION_TRIALPUBLICATIONTYPES_SAVE_SUCCESS",
    ACTION_TRIALPUBLICATIONTYPES_SAVE_FAILURE = "ACTION_TRIALPUBLICATIONTYPES_SAVE_FAILURE",

    ACTION_TRIALPUBLICATIONTYPES_CREATE = "ACTION_TRIALPUBLICATIONTYPES_CREATE",
    ACTION_TRIALPUBLICATIONTYPES_CREATE_SUCCESS = "ACTION_TRIALPUBLICATIONTYPES_CREATE_SUCCESS",
    ACTION_TRIALPUBLICATIONTYPES_CREATE_FAILURE = "ACTION_TRIALPUBLICATIONTYPES_CREATE_FAILURE",

    ACTION_TRIALPUBLICATIONTYPES_CLEAR = "ACTION_TRIALPUBLICATIONTYPES_CLEAR",

    ACTION_TRIALPUBLICATIONTYPES_FILTER = "ACTION_TRIALPUBLICATIONTYPES_FILTER";

export interface ITrialPublicationTypeLoadAction {
    type: "ACTION_TRIALPUBLICATIONTYPES_LOAD";
}

export interface ITrialPublicationTypeLoadActionCreator {
    (): ITrialPublicationTypeLoadAction;
}

export interface ITrialPublicationTypeLoadSuccessAction {
    type: "ACTION_TRIALPUBLICATIONTYPES_LOAD_SUCCESS";
    formData: Dtos.TrialPublicationType | Dtos.TrialPublicationType[];
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialPublicationTypeLoadSuccessActionCreator {
    (
        formData: Dtos.TrialPublicationType | Dtos.TrialPublicationType[],
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ITrialPublicationTypeLoadSuccessAction;
}

export interface ITrialPublicationTypeLoadFailureAction {
    type: "ACTION_TRIALPUBLICATIONTYPES_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialPublicationTypeLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialPublicationTypeLoadFailureAction;
}

export interface ITrialPublicationTypeLoadByIdAction {
    type: "ACTION_TRIALPUBLICATIONTYPES_LOAD_BY_ID";
    id: number;
}

export interface ITrialPublicationTypeLoadByIdActionCreator {
    (id: number): ITrialPublicationTypeLoadByIdAction;
}


export interface ITrialPublicationTypeSaveAction {
    type: "ACTION_TRIALPUBLICATIONTYPES_SAVE";
    formData: Dtos.TrialPublicationType;

}

export interface ITrialPublicationTypeSaveActionCreator {
    (
        formData: Dtos.TrialPublicationType
    ): ITrialPublicationTypeSaveAction;
}

export interface ITrialPublicationTypeSaveSuccessAction {
    type: "ACTION_TRIALPUBLICATIONTYPES_SAVE_SUCCESS";
    formData: Dtos.TrialPublicationType;
    formState: Dtos.FormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialPublicationTypeSaveSuccessActionCreator {
    (
        formData: Dtos.TrialPublicationType,
        formState: Dtos.FormState,
        responseStatus: Dtos.ResponseStatus
    ): ITrialPublicationTypeSaveSuccessAction;
}

export interface ITrialPublicationTypeSaveFailureAction {
    type: "ACTION_TRIALPUBLICATIONTYPES_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialPublicationTypeSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialPublicationTypeSaveFailureAction;
}

export interface ITrialPublicationTypeCreateAction {
    type: "ACTION_TRIALPUBLICATIONTYPES_CREATE"
}

export interface ITrialPublicationTypeCreateActionCreator {
    (): ITrialPublicationTypeCreateAction
}

export interface ITrialPublicationTypeCreateSuccessAction {
    type: "ACTION_TRIALPUBLICATIONTYPES_CREATE_SUCCESS";
    formData: Dtos.TrialPublicationType;
    formState: Dtos.FormState;
    formProperties: Dtos.FormProperty[];
    formRoles: Dtos.RoleType[];
    lookups: Dtos.Lookup[];
}

export interface ITrialPublicationTypeCreateSuccessActionCreator {
    (
        formData: Dtos.TrialPublicationType,
        formState: Dtos.FormState,
        formProperties: Dtos.FormProperty[],
        formRoles: Dtos.RoleType[],
        lookups: Dtos.Lookup[]
    ): ITrialPublicationTypeCreateSuccessAction;
}

export interface ITrialPublicationTypeCreateFailureAction {
    type: "ACTION_TRIALPUBLICATIONTYPES_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ITrialPublicationTypeCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialPublicationTypeCreateFailureAction;
}

export interface ITrialPublicationTypeClearAction {
    type: "ACTION_TRIALPUBLICATIONTYPES_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrialPublicationTypeClearActionCreator {
    (propertiesToClear?: string[]): ITrialPublicationTypeClearAction;
}

export interface ITrialPublicationTypeFilterActionCreator {
    (formFilter: IFormFilter): ITrialPublicationTypeFilterAction;
}

export interface ITrialPublicationTypeFilterAction {
    type: "ACTION_TRIALPUBLICATIONTYPES_FILTER";
    formFilter: IFormFilter;
}

export type TrialPublicationTypeAction =
    ITrialPublicationTypeLoadAction |
    ITrialPublicationTypeLoadByIdAction |
    ITrialPublicationTypeLoadFailureAction |
    ITrialPublicationTypeLoadSuccessAction |
    ITrialPublicationTypeSaveAction |
    ITrialPublicationTypeSaveSuccessAction |
    ITrialPublicationTypeSaveFailureAction |
    ITrialPublicationTypeCreateAction |
    ITrialPublicationTypeCreateSuccessAction |
    ITrialPublicationTypeCreateFailureAction |
    ITrialPublicationTypeClearAction |
    ITrialPublicationTypeFilterAction;
